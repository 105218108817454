import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'



export default class MemberQuestionnaire extends Model {
    static entity = 'MemberQuestionnaire'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            MemberFirstName: this.string(null).nullable(),
            MemberLastName: this.string(null).nullable(),
            AddSpouse: this.boolean(null).nullable(),
            SpouseFirstName: this.string(null).nullable(),
            SpouseLastName: this.string(null).nullable(),
            MemberDuration: this.string(null).nullable(),
            InvolvedFrom: this.string(null).nullable(),
            AttendMeeting: this.string(null).nullable(),
            ReadNewsLetters: this.string(null).nullable(),
            AILMeeting: this.string(null).nullable(),

            ADnDBenifit: this.boolean(null).nullable(),
            ADnDBenifitIssueToday: this.boolean(null).nullable(),

            AILPlusProgram: this.boolean(null).nullable(),
            AILPlusProgramIssueToday: this.boolean(null).nullable(),

            FamilyInfoWillKit: this.boolean(null).nullable(),
            FamilyInfoWillKitIssueToday: this.boolean(null).nullable(),

            ChildSafeKit: this.boolean(null).nullable(),
            ChildSafeKitIssueToday: this.boolean(null).nullable(),

            NeedAnalysis: this.boolean(null).nullable(),
            NeedAnalysisIssueToday: this.boolean(null).nullable(),

            LifeInsMedQuestions: this.boolean(null).nullable(),
            LifeInsMedQuestionsIssueToday: this.boolean(null).nullable(),
            
            PresentationGroupId: this.string(null).nullable(),
            IsFormSubmited: this.boolean(null).nullable(),
            IsVideoPlayed: this.boolean(false)
        }
    }

    public Id !: any
    public MemberFirstName!: string
    public MemberLastName!: string
    public AddSpouse!: boolean
    public SpouseFirstName!: string
    public SpouseLastName!: string

    public MemberDuration!: string
    public InvolvedFrom!: string
    public AttendMeeting!: string
    public publicReadNewsLetters!: string
    public publicAILMeeting!: string

    public publicADnDBenifit!: boolean
    public ADnDBenifitIssueToday!: boolean

    public AILPlusProgram!: boolean
    public AILPlusProgramIssueToday!: boolean

    public FamilyInfoWillKit!: boolean
    public FamilyInfoWillKitIssueToday!: boolean

    public ChildSafeKit!: boolean
    public ChildSafeKitIssueToday!: boolean

    public NeedAnalysis!: boolean
    public NeedAnalysisIssueToday!: boolean

    public LifeInsMedQuestions!: boolean
    public LifeInsMedQuestionsIssueToday!: boolean

    public PresentationGroupId!: string
    public IsFormSubmited!: boolean
    public IsVideoPlayed!: boolean
}