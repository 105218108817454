

























































































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  props: ["BenefitSummary", "Person"],
})
export default class PerProcedureCancerComponent extends Vue {
  data() {
    return {
      monthlytab: 0,
      key: 0,
    };
  }
}
