
































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import moment from "moment";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";

@Component({})
export default class FreedomOfChoiceComponent extends Vue {
	private isDialogOpen: boolean = false;
	private imgLoaded: number = 0;
	data() {
		return {
			UserName: "",
		};
	}
	created() {
		this.isDialogOpen = true;
		this.$store.state.loader = true;
		this.imgLoaded = 0;
		// setTimeout(() => {
		// 	this.$data.UserName = GetAscFullNameWithCamelCase();
		// 	this.$store.state.loader = false;
		// }, 1500);
	}
	handleLoad() {
		this.imgLoaded++;
		if (this.imgLoaded === 6) {
			setTimeout(() => {
				this.$data.UserName = GetAscFullNameWithCamelCase();
				this.$store.state.loader = false;
			}, 1500);
		}
	}
	clickedOutside() {
		this.isDialogOpen = false;
		this.$emit("closefreedomofchoice");
	}
	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("closefreedomofchoice");
		}, 500);
	}
}
