










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import MenuComponent from "../components/menu.vue";
import PrimaryPerson from "../entities/PrimaryPerson";
import Presentation from "../entities/Presentation";
import moment from "moment";
import SyncService from "../services/SyncService";
import SyncModel from "../models/SyncModel";
import {
	setPersistedState,
	getPersistedState,
	deletePersistedState,
} from "../helpers/StateMapper";
import GenericKeyPair from "@/models/GenericKeyPair";
import {
	ConvertIntoAmountFormatWithDecimal,
	groupBy,
	LoadBenefitSummaryAsPerPlan,
	LoadPlansForFinishPresentation,
} from "../helpers/CalculationHelper";
import { changeLocale } from "@/plugins/i18n";
import {
	GetApiDataOrCheckLocal,
	GetLocalDataOrCallAPI,
	GetRequestWithAuthorization,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import { GetAssociateId, GetFullName } from "@/helpers/APIExtentions";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { PresentationScreen } from "@/enumerations/PresentationScreen";
import { SyncPresentations } from "@/helpers/SyncHelper";
import PresentationSync from "@/helpers/PresentationSync";
import { PrsentationEntityToDTO } from "@/helpers/MappingHelper";
import { APIService } from "@/services/APIService";
import { EntityHelper } from "@/helpers/EntityHelper";
import { PresentationType } from "../enumerations/PresentationType";
import LoggingHelper from "../helpers/LoggingHelper";
import LoggingConstants from "../constants/LoggingConstants";
import { EventBusLayout } from "../bus/EventBusLayout";
import { AddMaskingToNumber } from "@/helpers/UIHelper";
import { Base64ToArrayBuffer, Camelize } from "@/helpers/ArrayHelper";
import Spouse from "@/entities/Spouse";
import VuePdfApp from "vue-pdf-app";
import { MemberLookupType } from "@/enumerations/MemberLookupType";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
import GiftCertificatePdfHtmlForBenefitPreview from "@/components/popups/GiftCertificatePdfHtmlForBenefitPreview.vue";
// let listofPresentationType = JSON.parse(
//   JSON.stringify(require("../assets/pre-fill-data/presentation-types.json"))
// );

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		ReferralPopup,
		VuePdfApp,
		GiftCertificatePdfHtmlForBenefitPreview,
	},
})
export default class FinishPresentation extends Vue {
	private sendnocost: boolean = false;
	private preDisable: boolean = true;
	private nextDisable: boolean = false;
	private disabledNavButton: boolean = false;
	private PageNumbers: any = [1];
	private Person: any;
	private FieldTrainee: any = "";
	private PresType: string = "";
	//private Presentation: any;
	private PresentationTypes: any = [];
	private SelectedPresentationType: any = "";
	private PremiumTypeDesc: string = "";
	private PremiumAmountDesc: string = "";
	private PresTimeDuration: string = "";
	private UserEmails: any = [];
	private ScreenRequirmenttoCompletePres: any = null;
	private ShowPremium = true;
	private allPresentationTypes: any = [];
	private NoCostMaterialsTimeList = [];
	private statesList: any = [];
	private PhoneRequiredVal: boolean = false;
	private EmailRequiredVal: boolean = false;
	private SeleectedMaterialsForPreview: any = [];
	private EmailMaterialAttachments: any;
	private EmailPresentationAttachments: any;
	private NeedAnalysisAttached: boolean = false;
	private BenefitSummaryAttached: boolean = false;
	private BenefitAttachmentDisabled: boolean = false;
	private VideoMaterialCount: number = 0;
	private MaterialsForPreview: any = [];
	private MaterialsArrayForCount: any = [];
	private EmailArrayForCount: any = [];
	private MaterialsArray: any = [];
	private StateId: number = 0;
	private ValidPrimaryEmail: boolean = true;
	private ValidSpouseEmail: boolean = true;
	private SelectedGroupCodeCount: number = 0;
	private AgentFullName: string = "";
	private User: any;
	private IsSeniorMedicalSelected: boolean = false;
	private PrimaryTobaccoUse: boolean = false;
	private SpouseTobaccoUse: boolean = false;
	private PrimaryCancer: boolean = false;
	private SpouseCancer: boolean = false;
	private PrimaryHeart: boolean = false;
	private SpouseHeart: boolean = false;
	private bsRates: any = null;
	private lstStates: any = null;
	private SubTypes: any = [];
	private SelectedSubType: any = null;
	private DisabledSubType: boolean = true;
	private IsDemoOrTraininingPresentation: boolean = false;

	private ALPAmount: string = "";
	private AHPAmount: string = "";

	private ssprogram: boolean = false;
	private somemodal: boolean = false;

	private ResetReferrals: boolean = false;

	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;

	@Prop({ default: "false" })
	FromNoCost!: string;

	@Prop({ default: "false" })
	showWhatHappenError!: string;

	$refs!: {
		fpform: any;
		reportCardRow: any;
		needAnalysisRow: any;
		benefitsSummaryRow: any;
		presentPlanRow: any;
		planGeneratorRow: any;
		personemailfield: any;
		personfirstnamefield: any;
	};

	data() {
		return {
			Referrals: {},
			finishscroll: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			preDisable: true,
			nextDisable: false,
			CurrentPageNumbers: 1,
			Childrens: [],
			totalPageCount: 4,
			componentKey: 0,
			valid: false,
			products: this.products,
			Presentation: {},
			presentationTypeRules: [(v: any) => !!v || "Market Type is required"],
			EmailRules: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return false;
					}
				},
			],
			currentPresentationToPrint: { Presentation: {}, BenefitSummary: {} },
			BenefitSummary: {},
			RequiredZip: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						var zipFormat = this.$data.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			ZipFormat: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						if (v.trim().length == 0) return true;
						var zipFormat = this.$data.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			PhoneRules: [(v: any) => (!!v && v.length > 13) || "Phone must be valid"],
			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => !!v.trim() || "Field is required",
			],
			RequiredVideoReview: [
				(v: any) => {
					if (v != undefined) return true;
					else return false;
				},
			],
			planoptions: null,
			CurrentSelectedPlan: null,
			isOnlyOnePlanChecked: true,
			canvaslist: [],
			pagescale: "page-fit",
			PdfUrl: "",
			AdAnddPdfUrl: "",
			AdAnddPdfBase64Url: "",
			config: { toolbar: false },
		};
	}

	CompleteUserList: any = [];
	createAdAnddCertificatePdf() {
		if (
			this.$data.Presentation != null &&
			this.$data.Presentation.LeadId != undefined &&
			this.$data.Presentation.LeadId != "" &&
			this.$data.Presentation.LeadId != "0"
		) {
			this.showFlipbook = false;
			var parameters: GenericKeyPair[] = [];
			parameters.push(
				new GenericKeyPair("leadId", this.$data.Presentation.LeadId)
			);
			parameters.push(new GenericKeyPair("associateid", GetAssociateId()));
			parameters.push(new GenericKeyPair("certType", 1));

			var RequestTime = moment(new Date()).utc();

			GetRequestWithAuthorization(
				ApiEndPointConstants.GetInfoCertificateUrlEndPoint,
				parameters,
				10000
			)
				.then((res: any) => {
					if (
						res != null &&
						res.Result != undefined &&
						res.Result.FileData != undefined
					) {
						this.$data.AdAnddPdfUrl = res.Result.FileData;
						this.showAdanddPdf = true;
					} else {
						this.$data.AdAnddPdfUrl = "";
						this.showAdanddPdf = false;
					}
				})
				.catch((ex: any) => {});
		}
	}
	async LoadUserList() {
		let parameters: GenericKeyPair[] = [];
		GetRequestWithAuthorization(
			ApiEndPointConstants.UserListEndpoint,
			parameters
		).then((res: any) => {
			if (
				res != undefined &&
				res.Result != undefined &&
				res.Result.length > 0
			) {
				this.CompleteUserList = res.Result;
				this.CheckUserExist(undefined);
				// if (this.$data.Presentation.FieldTraining != "ALONE") {
				//   this.FieldTrainee = this.$data.Presentation.FieldTrainee;
				//   this.FillList(this.FieldTrainee, true);
				// }
			}
		});
	}

	FillList(value: string, updateList: boolean) {
		let element = document.getElementById(
			"UserListAutoComplete"
		) as HTMLInputElement;
		if (element != null) {
			element.value = value;
			if (element.value != undefined && element.value != "")
				this.UserEmails = [element.value];
			//if (updateList) this.CheckUserExist(undefined);
		}
	}

	CheckUserExist(evt: any) {
		if (evt != undefined && (evt.key == "ArrowUp" || evt.key == "ArrowDown"))
			return;
		let element = document.getElementById(
			"UserListAutoComplete"
		) as HTMLInputElement;
		if (element != null) {
			var text = element.value;
			if (text != undefined && text.length > 1) {
				text = text.toLowerCase();
				this.UserEmails = this.CompleteUserList.filter((x: any) => {
					if (
						x.UserInfo != undefined &&
						x.UserInfo.FullName &&
						x.UserInfo.FullName.toLowerCase().includes(text)
					)
						return x;
				});
			} else if (text != undefined && text.length <= 1) {
				this.UserEmails = [];
			}
		}
	}

	async FetchPreFillData() {
		var res = await getPersistedState(IndexDBConstants.PresentationTypesKey);

		var k = this.$data.Presentation.PresentedTo.GenderTypeId;
		this.allPresentationTypes = res;

		this.allPresentationTypes.find((pres: any) => {
			if (pres.Id === this.$data.Presentation.PresentationTypeId) {
				this.PresType = pres.Name;
				if (pres.SubTypes != null && pres.SubTypes.length > 0) {
					this.SelectedSubType = null;
					this.SubTypes = pres.SubTypes?.slice(0);
					this.FilterSubTypesUponAlphaAccess(pres.Id);
					this.DisabledSubType = false;
				}
			}
		});

		this.PresentationTypes = this.allPresentationTypes.filter(function (
			item: any
		) {
			return item.id != 13;
		});
	}

	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		window.addEventListener("beforeunload", this.onCloseBrowser);
		var self = this;
		EventBusLayout.$on("RoutedToEvt", this.FinishPresentationRoutedToEvt);
		GetLocalDataOrCallAPI(
			ApiEndPointConstants.RequiredPresentationStepConfigEndpoint,
			[],
			IndexDBConstants.RequiredPresentationStepConfigKey
		).then((result) => {
			self.ScreenRequirmenttoCompletePres = result;
		});
		this.LoadUserList();
		getPersistedState("StateList").then((res: any) => {
			this.statesList = res;
		});
		if (this.FromNoCost == "true") {
			this.ShowPremium = false;
		}
		this.Person = PrimaryPerson.query().withAll().last();
		this.$data.Presentation = Presentation.query().withAllRecursive().last();
		this.createAdAnddCertificatePdf();
		this.IsSeniorMedicalSelected = !this.$data.Presentation.isSuperCombo;
		if (
			this.$data.Presentation.MedicalQuestionsNA != undefined &&
			this.$data.Presentation.MedicalQuestionsNA != ""
		) {
			var MedicalQuestions = JSON.parse(
				this.$data.Presentation.MedicalQuestionsNA
			);
			if (MedicalQuestions.length > 0) {
				let TobaccoQuestion = MedicalQuestions.find((x: any) => {
					if (
						x.IsForSenior == this.IsSeniorMedicalSelected &&
						x.QuestionEn.toString().includes("TOBACCO")
					) {
						return x;
					}
				});
				if (TobaccoQuestion != undefined) {
					this.PrimaryTobaccoUse =
						TobaccoQuestion.PrimaryValue == null
							? false
							: TobaccoQuestion.PrimaryValue;
					this.SpouseTobaccoUse =
						TobaccoQuestion.SpouseValue == null
							? false
							: TobaccoQuestion.SpouseValue;
				}
				let CancerQuestion = MedicalQuestions.find((x: any) => {
					if (x.QuestionEn.toString().includes("CANCER")) {
						return x;
					}
				});
				if (CancerQuestion != undefined) {
					this.PrimaryCancer =
						CancerQuestion.PrimaryValue == null
							? false
							: CancerQuestion.PrimaryValue;
					this.SpouseCancer =
						CancerQuestion.SpouseValue == null
							? false
							: CancerQuestion.SpouseValue;
				}
				let HeartQuestion = MedicalQuestions.find((x: any) => {
					if (x.QuestionEn.toString().includes("HEART")) {
						return x;
					}
				});
				if (HeartQuestion != undefined) {
					this.PrimaryHeart =
						HeartQuestion == null ? false : HeartQuestion.PrimaryValue;
					this.SpouseHeart =
						HeartQuestion == null ? false : HeartQuestion.SpouseValue;
				}
			}
		}
		this.$data.currentPresentationToPrint.Presentation =
			this.$data.Presentation;
		var bsRatesPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.BenefitSummaryRatesEndpoint,
			[],
			IndexDBConstants.BenefitSummaryRatesKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);
		var plan: any;
		if (this.$data.Presentation) {
			if (this.$data.Presentation.PlanOptions) {
				this.$data.Plans = JSON.parse(this.$data.Presentation.PlanOptions);
				if (this.$data.Plans.length > 0) {
					var plan = this.$data.Plans.find((x: any) => x.isSelected == true);
				}
			}

			// if (this.$data.Presentation.CurrentPlan) {
			// 		this.$data.Plans.find((x: any) => x.isSelected == true),
			// 		true
			// } else {
			// 	this.loadPlan(this.$data.Plans[0], true);
			// }
		}
		if (plan != undefined) {
			var self = this;
			Promise.all([bsRatesPromise, stateListPromise]).then((values) => {
				self.bsRates = values[0];
				self.lstStates = values[1];
				LoadBenefitSummaryAsPerPlan(
					this.bsRates,
					this.lstStates,
					this.$data.BenefitSummary,
					plan,
					this.$data.Presentation,
					this.Person,
					"--",
					false
				);
			});
		}
		if (
			this.Person != undefined &&
			this.Person.ChildrenString != undefined &&
			this.Person.ChildrenString != ""
		) {
			this.$data.Childrens = JSON.parse(this.Person.ChildrenString);
			if (this.$data.Childrens.length > 0) {
				this.$data.Childrens = this.$data.Childrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				);
			}
		}
		this.StateId = this.$data.Presentation.StateId;
		var CurrentUser = localStorage.getItem("user");
		var license;
		var licenseStateCode;
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			var UserDetail = userObj?.UserDetail;
			if (
				UserDetail != undefined &&
				UserDetail.Licenses != undefined &&
				UserDetail.Licenses.length > 0
			) {
				var lic = undefined;
				if (this.StateId != 0) {
					var lic = UserDetail.Licenses.filter(
						(i: any) => i.StateId == this.StateId
					);
				}

				if (lic == undefined || lic.length == 0) {
					lic = UserDetail.Licenses.filter(
						(i: { IsPrimary: boolean }) => i.IsPrimary == true
					);
				}

				if (lic != undefined && lic.length > 0) {
					license = lic[0].LicenseNumber;
					licenseStateCode = lic[0].StateCode;
				} else {
					license = "";
					licenseStateCode = "";
				}
			} else {
				license = "";
				licenseStateCode = "";
			}
			this.User = {
				FirstName: userObj.UserDetail.FirstName,
				LastName: userObj.UserDetail.LastName,
				UserName: userObj.UserName,
				UserId: userObj.UserDetail.UserId,
				AgentNo: userObj.UserDetail.AgentNumber,
				PhoneNo: AddMaskingToNumber(userObj.UserDetail.Phone),
				EmailAddress: userObj.UserDetail.Email,
				MmsServiceAllowed: userObj.UserDetail.MmsServiceAllowed,
				MessageCount: userObj.UserDetail.MessageCount,
				TrainingMessageCount: userObj.UserDetail.TrainingMessageCount,
				PresentTime: userObj.UserDetail.PresentTime,
				TimeZoneOffset: userObj.UserDetail.TimeZoneOffset,
				TwilioNumber: userObj.UserDetail.TwilioNumber,
				HasAlphaAccess: userObj.UserDetail.VeteranSmbAllowed,
				LicNo: license,
				LicState: licenseStateCode,
			};
			this.AgentFullName = (
				this.User.FirstName +
				" " +
				this.User.LastName
			).trim();
		}
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation.EmailMaterialsAttachments != undefined &&
			this.$data.Presentation.EmailMaterialsAttachments != ""
		) {
			this.EmailMaterialAttachments = JSON.parse(
				this.$data.Presentation.EmailMaterialsAttachments
			);
		} else {
			this.EmailMaterialAttachments = [];
		}
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation.EmailPresComponentAttachments != undefined &&
			this.$data.Presentation.EmailPresComponentAttachments != ""
		) {
			this.EmailPresentationAttachments = JSON.parse(
				this.$data.Presentation.EmailPresComponentAttachments
			);
		} else {
			this.EmailPresentationAttachments = [];
		}
		// if (
		// 	this.$data.Presentation != undefined &&
		// 	this.$data.Presentation.EmailPresComponentAttachments != undefined &&
		// 	this.$data.Presentation.EmailPresComponentAttachments != ""
		// ) {
		// 	this.EmailPresentationAttachments = JSON.parse(
		// 		this.$data.Presentation.EmailPresComponentAttachments
		// 	);
		// } else {
		// 	this.EmailPresentationAttachments = [];
		// }
		if (
			this.$data.Presentation.PlanOptions &&
			this.$data.Presentation.PlanOptions != ""
		) {
			this.$data.planoptions = JSON.parse(this.$data.Presentation.PlanOptions);
			var checkdPlans = this.$data.planoptions.filter(
				(x: any) => x.IsChecked == true
			);
			if (checkdPlans != undefined && checkdPlans.length > 1)
				this.$data.isOnlyOnePlanChecked = false;
			if (this.$data.Presentation.CurrentPlan) {
				this.loadplan(
					this.$data.planoptions.find(
						(x: any) => x.Name == this.$data.Presentation.CurrentPlan
					)
				);
			} else {
				this.loadplan(this.$data.planoptions[0]);
			}
		}
		if (
			this.$data.Presentation.NoCostMaterialsTime != undefined &&
			this.$data.Presentation.NoCostMaterialsTime != ""
		)
			this.NoCostMaterialsTimeList = JSON.parse(
				this.$data.Presentation.NoCostMaterialsTime
			);

		if (
			this.NoCostMaterialsTimeList != undefined &&
			this.NoCostMaterialsTimeList.length > 0
		) {
			this.NoCostMaterialsTimeList.forEach((element: any) => {
				element.TimeString = moment(element.time).utc().format("HH:mm:ss");
			});
		}

		if (
			this.$data.Presentation != null &&
			this.$data.Presentation != undefined
		) {
			this.IsDemoOrTraininingPresentation =
				this.$data.Presentation.PresentationTypeId == PresentationType.Demo ||
				this.$data.Presentation.MemberLookupTypeId == MemberLookupType.Demo ||
				this.$data.Presentation.MemberLookupTypeId == MemberLookupType.Training;
		}

		this.FetchPreFillData();
		this.PresTimeDuration = this.dateDifference(
			new Date().toLocaleString(),
			this.$data.Presentation.StartTime
		).toString();
		this.FieldTrainee = this.$data.Presentation.FieldTrainee;
		this.PopulatePremiumType(this.$data.Presentation.PremiumTypeId);
		this.FetchMaterialForReferral();
	}

	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined || amt == "") return "0.00";
			else if (typeof amt == "string") {
				return ConvertIntoAmountFormatWithDecimal(amt);
			} else return ConvertIntoAmountFormatWithDecimal(amt.toFixed(2));
		} catch (ex: any) {
			return 0;
		}
	}

	next() {
		let sliderContainer =
			document.getElementsByClassName("previewarea")[0].children;
		for (let item = 0; item < sliderContainer.length; item++) {
			let active = document.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item++;
				sliderContainer[item].classList.add("active");
				let pageItem = item;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				this.preDisable = false;
				if (item == sliderContainer.length - 1) {
					this.nextDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 100);
	}

	prev(NewAttachmentSelectedorModalClosed: boolean = false) {
		let sliderContainer =
			document.getElementsByClassName("previewarea")[0].children;
		let getActive = document.getElementsByClassName("previewarea")[0];

		for (let item = sliderContainer.length - 1; item >= 0; item--) {
			let active = getActive.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				if (!NewAttachmentSelectedorModalClosed) {
					item--;
				} else {
					item = 0;
				}

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				sliderContainer[item].classList.add("active");
				this.nextDisable = false;
				if (item == 0) {
					this.preDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 100);
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	FinishPresentationRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.FinishPresentationRoutedToEvt);
	}

	showWhappenErrorTxt() {
		if (
			(this.$data.Presentation.WhatHappened == "undefined" ||
				this.$data.Presentation.WhatHappened == false) &&
			this.showWhatHappenError == "true"
		)
			return true;
		else return false;
	}
	mounted() {
		if (this.$data.planoptions) {
			LoadPlansForFinishPresentation(this.$data.planoptions);
		}

		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation.FieldTraining != "ALONE"
		) {
			this.FillList(this.FieldTrainee, false);
		}
	}

	private allProducts: any = {};
	private allMatchingproducts: any = [];

	FetchMaterialForReferral() {
		getPersistedState("Materials").then((res: any) => {
			this.allProducts = res;
			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined ||
							x.CountryId == this.$data.Presentation.CountryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId ==
								this.$data.Presentation.PresentationTypeId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);

			this.allMatchingproductsReferral = JSON.parse(
				JSON.stringify(this.allMatchingproducts)
			);
		});
	}

	loadplan(plan: any) {
		var pre = this.$data.Presentation.PremiumTypeId;
		this.$data.planoptions.forEach((plan: any) => {
			plan.Selected = false;
		});
		this.$data.CurrentSelectedPlan = plan;
		this.$data.CurrentSelectedPlan.Selected = true;
		this.$data.Presentation.CurrentPlan = this.$data.CurrentSelectedPlan.Name;
		var planOptionPremPlan = JSON.parse(JSON.stringify(plan.PremiumPlan));
		if (planOptionPremPlan != undefined) {
			this.$data.Presentation.NeedAmount = planOptionPremPlan.needPremium;
			if (this.Person != undefined) {
				this.Person.DollarADayAmount = planOptionPremPlan.PrimaryDollarADay;
				if (this.Person.Spouse != undefined)
					this.Person.Spouse.DollarADayAmount =
						planOptionPremPlan.SpouseDollarADay;
			}
		}
		this.PopulatePremiumType(pre);
		LoggingHelper.getInstance().LogEvent(LoggingConstants.PlanOptionChange);
	}

	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		var RefInit: any = {};
		if (this.$data.Presentation.Referrals) {
			RefInit = JSON.parse(this.$data.Presentation.Referrals);
		} else {
			RefInit = {
				ReferredBy: "",
				ReferredByLastName: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: "",
				ReferralsList: [],
				CCEmail: [],
			};
		}
		if (RefInit.ReferralsList.length > 0) {
			RefInit.ReferralsList.forEach((referral: any) => {
				referral.StateObj = this.statesList.find(
					(x: any) => x.StateCode == referral.State
				);
			});
		}
		if (RefInit.ReferredBy == undefined || RefInit.ReferredBy == "")
			RefInit.ReferredBy = this.Person?.FirstName;

		if (
			RefInit.ReferredByLastName == undefined ||
			RefInit.ReferredByLastName == ""
		)
			RefInit.ReferredByLastName = this.Person?.LastName;

		if (RefInit.Phone == undefined || RefInit.Phone == "")
			RefInit.Phone = this.Person?.Phone;
		if (RefInit.Email == undefined || RefInit.Email == "")
			RefInit.Email = this.Person?.Email;
		if (
			this.$data.Presentation.PresentedGroup != undefined &&
			this.$data.Presentation.PresentedGroup != ""
		)
			RefInit.Organization = this.$data.Presentation.PresentedGroup;
		if (RefInit.Date == undefined || RefInit.Date == "")
			RefInit.Date = moment(new Date()).format("MM/DD/YYYY");

		this.$data.Referrals = RefInit;
		if (newValue == true) {
			this.ResetReferrals = !this.ResetReferrals;
		}
	}

	CloseReferralPopup() {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
	}

	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;

		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;

		if (referral.City != null && referral.City.trim() != "") return false;

		if (referral.State != null && referral.State.trim() != "") return false;

		if (referral.Phone != null && referral.Phone.trim() != "") return false;

		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;

		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;

		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;

		return true;
	}

	SaveReferral(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.$data.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.$data.Presentation.Id,
				data: this.$data.Presentation,
			});
		}
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		LoggingHelper.getInstance().LogEvent(LoggingConstants.SponsorShipPopupSave);
	}
	SaveReferralsWithoutClosingPopup(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.$data.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.$data.Presentation.Id,
				data: this.$data.Presentation,
			});
		}
	}

	async RoutedTo(route: String, params: any = null, fromView: boolean = false) {
		this.sendnocost = false;
		if (
			(route == "/benefitssummary" || route == "BenefitsSummary") &&
			(params == null || params == undefined)
		) {
			EventBusLayout.$emit("BenefitSummaryRoute", true);
		} else if (
			(route == "/benefitssummary" || route == "BenefitsSummary") &&
			params != null &&
			params != undefined
		) {
			EventBusLayout.$emit("BenefitSummaryRoute", false);
		}
		if (route == "/ssprogram") {
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";
			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		if (this.FieldTrainee != undefined && typeof this.FieldTrainee != "string")
			this.$data.Presentation.FieldTrainee = this.FieldTrainee;
		else this.$data.Presentation.FieldTrainee = this.FieldTrainee;

		if (route != "StartPresentation") {
			this.$data.Presentation.EmailMaterialsAttachments = JSON.stringify(
				this.EmailMaterialAttachments
			);
			this.$data.Presentation.EmailPresComponentAttachments = JSON.stringify(
				this.EmailPresentationAttachments
			);
			await Presentation.update({
				where: this.$data.Presentation.Id,
				data: this.$data.Presentation,
			});
			await PrimaryPerson.update({
				where: this.Person.Id,
				data: this.Person,
			});
			let saveData = new GenericKeyPair(
				"CurrentPresentation",
				Presentation.query().withAllRecursive().last()
			);
			setPersistedState(saveData);
			if (!params && route != "/StartPresentation") {
				this.$router.push(route.toString());
				return;
			}
			this.$store.state.loader = true;

			this.$router.push({
				name: route.toString(),
				params: params,
			});
		} else {
			await this.CheckAndSavePresentation(false);
		}

		this.$store.state.loader = false;

		if (fromView)
			LoggingHelper.getInstance().LogEvent(LoggingConstants.NavigateTo + route);
	}

	PopulatePremiumType(premTypeId: any) {
		if (
			this.$data.Presentation.isNeedAnalysisCompleted ||
			this.$data.Presentation.isPlanGeneratorCompleted
		) {
			switch (premTypeId) {
				case 1:
					this.PremiumTypeDesc = this.$t(
						"FinishPresentation.HourPower"
					).toString();
					this.PremiumAmountDesc = "";
					if (this.$data.Presentation.PremiumPlan) {
						if (this.$data.Presentation.PremiumPlan.HPPremiumType == 1) {
							this.PremiumAmountDesc = this.PremiumAmountDesc.concat(
								this.$t("FinishPresentation.Combined").toString()
							);
						} else {
							this.PremiumAmountDesc = this.PremiumAmountDesc.concat(
								this.$t("FinishPresentation.Custom").toString()
							);
						}
					}
					this.PremiumAmountDesc = this.PremiumAmountDesc.concat(
						" $",
						ConvertIntoAmountFormatWithDecimal(
							(
								Number(
									EntityHelper.CalculateMonthlyPremium(
										this.$data.CurrentSelectedPlan
									)
								) / 4.333
							).toFixed(2),
							true,
							true
						) + this.$t("FinishPresentation.PerWeek")
					);
					break;
				case 2:
					this.PremiumTypeDesc = this.$t(
						"FinishPresentation.Monthly"
					).toString();
					this.PremiumAmountDesc = "";
					if (this.$data.Presentation.PremiumPlan) {
						if (this.$data.Presentation.PremiumPlan.MonthlyPremiumType == 1) {
							this.PremiumAmountDesc = this.PremiumAmountDesc.concat(
								this.$t("FinishPresentation.Combined").toString()
							);
						} else {
							this.PremiumAmountDesc = this.PremiumAmountDesc.concat(
								this.$t("FinishPresentation.Custom").toString()
							);
						}
					}
					this.PremiumAmountDesc = this.PremiumAmountDesc.concat(
						" $",
						ConvertIntoAmountFormatWithDecimal(
							EntityHelper.CalculateMonthlyPremium(
								this.$data.CurrentSelectedPlan
							),
							true,
							true
						) + this.$t("FinishPresentation.PerMonth")
					);
					break;
				case 3:
					this.PremiumTypeDesc = this.$t(
						"FinishPresentation.DollarADay"
					).toString();
					// var dollarADayAmountCombined =
					//   this.$data.Presentation.PresentedTo.DollarADayAmount +
					//   this.$data.Presentation.PresentedTo.Spouse.DollarADayAmount;

					var dollarADayAmountCombined = this.Person.DollarADayAmount;

					if (this.Person.isSpouseForDADExists)
						dollarADayAmountCombined += this.Person.Spouse.DollarADayAmount;

					this.PremiumAmountDesc =
						this.$t("FinishPresentation.Combined").toString() +
						" $" +
						ConvertIntoAmountFormatWithDecimal(
							(
								Number(
									EntityHelper.CalculateMonthlyPremium(
										this.$data.CurrentSelectedPlan
									)
								) / 30
							).toFixed(2),
							true,
							true
						) +
						this.$t("FinishPresentation.PerDay");
					break;
				case 4:
					this.PremiumTypeDesc = this.$t("FinishPresentation.Need").toString();
					this.PremiumAmountDesc =
						this.$data.Presentation.NeedAmount +
						this.$t("FinishPresentation.AnnualIncome");
					break;
				default:
					if (this.PresType == "VETERAN") this.PremiumTypeDesc = "DOLLAR A DAY";
					else this.PremiumTypeDesc = "HOUR POWER";
					this.PremiumAmountDesc = "(Combined DOLLAR)";
					break;
			}
		} else {
			this.PremiumTypeDesc = "JUST NO COST ";
			this.PremiumAmountDesc = "";
		}

		// if (this.$data.Presentation.isNeedAnalysisCompleted) {
		//   this.ALPAmount = "1,677,00";
		//   this.AHPAmount = "1,47,00";
		// } else {
		//   this.ALPAmount = "0";
		//   this.AHPAmount = "0";
		// }
	}

	async FinishPresentation() {
		if (
			typeof this.$data.Presentation.WhatHappened == "undefined" ||
			this.$data.Presentation.WhatHappened == false
		) {
			this.$store.state.messageText = this.$t(
				"FinishPresentation.SelectWhatHappened"
			);
			this.$store.state.showMessage = true;
			return;
		}

		if (this.$data.Presentation.WhatHappened != "TRAINING") {
			if (!this.$data.valid) {
				this.$refs.fpform.validate();
				this.$store.state.messageText = this.$t("FinishPresentation.AllFields");
				this.$store.state.showMessage = true;
				return;
			}

			if (
				(this.Person.Phone == undefined ||
					this.Person.Phone == "" ||
					this.Person.Phone == "(") &&
				(this.Person.Email == undefined || this.Person.Email == "")
			) {
				if (this.Person.Phone == undefined || this.Person.Phone == "") {
					this.PhoneRequiredVal = true;
				} else {
					this.EmailRequiredVal = true;
				}
				this.$store.state.messageText = this.$t(
					"FinishPresentation.PhnEmailReq"
				);
				this.$store.state.showMessage = true;
				return false;
			}
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (this.Person.Email != "" && !pattern.test(this.Person.Email)) {
				this.$store.state.messageText = this.$t(
					"FinishPresentation.EmailInvalid"
				);
				this.$store.state.showMessage = true;
				this.EmailRequiredVal = true;
				return false;
			}
		}
		if (!this.isEmailSelected) {
			this.$store.state.messageText = this.$t(
				"FinishPresentation.SelEmailTraining"
			);
			this.$store.state.showMessage = true;
			return;
		}

		if (
			!this.DisabledSubType &&
			(this.$data.Presentation.SubType == undefined ||
				this.$data.Presentation.SubType == "")
		) {
			this.$store.state.messageText = this.$t("FinishPresentation.SelSubType");
			this.$store.state.showMessage = true;
			return;
		}

		var valReq = this.CheckRequiredScreenCompleted();
		if (!valReq) return;

		if (this.FieldTrainee != undefined)
			this.$data.Presentation.FieldTrainee = this.FieldTrainee;
		if (this.$data.Presentation.CurrentStepCompleted <= 5) {
			this.$data.Presentation.CurrentStepCompleted = 5;
		}
		if (this.$data.Presentation.CurrentPlan) {
			this.$data.planoptions.forEach((x: any) => {
				if (
					x.Name == this.$data.Presentation.CurrentPlan &&
					x.isSelected == true &&
					x.BenefitsCalculation == undefined &&
					Object.keys(this.$data.BenefitSummary).length != 0
				) {
					x.BenefitsCalculation = this.$data.BenefitSummary;
				}
			});
		}
		this.$data.Presentation.PlanOptions = JSON.stringify(
			this.$data.planoptions
		);
		LoggingHelper.getInstance().LogEvent(
			LoggingConstants.PresentationCompleted
		);
		await this.CheckAndSavePresentation(true);
	}

	async CheckAndSavePresentation(fromComplete: boolean) {
		this.$data.Presentation.EndTime = new Date().toLocaleString();
		await Presentation.update({
			where: this.$data.Presentation.Id,
			data: this.$data.Presentation,
		});
		await PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		if (window.navigator.onLine) {
			setTimeout(() => {
				var completePresentation = Presentation.query()
					.withAllRecursive()
					.last();

				this.$store.state.loader = true;

				this.SavePresentation(completePresentation, fromComplete);
			}, 100);
		} else {
			let saveData = new GenericKeyPair(
				"CurrentPresentation",
				Presentation.query().withAllRecursive().last()
			);
			await setPersistedState(saveData);
			setTimeout(() => {
				this.$router.push("/dashboard");

				PresentationSync.getInstance().SyncPresentation();
			}, 500);
		}
	}

	SavePresentation(completePresentation: any, fromComplete: boolean) {
		try {
			completePresentation.SGNumber = this.$store.state.SGNumber;
			var mapPresDTO = PrsentationEntityToDTO(completePresentation);
			var GenericKeyPairobj: GenericKeyPair[] = [];
			APIService.SavePresentation(mapPresDTO, GenericKeyPairobj).then(
				async (res: any) => {
					if (res != undefined && res.data == true) {
						deletePersistedState("CurrentPresentation");
						Presentation.deleteAll();
						PrimaryPerson.deleteAll();
						Spouse.deleteAll();
					} else {
						let saveData = new GenericKeyPair(
							"CurrentPresentation",
							completePresentation
						);
						setPersistedState(saveData);
					}
					this.RedirectToCompScreen(fromComplete);
				},
				(ex: any) => {
					let saveData = new GenericKeyPair(
						"CurrentPresentation",
						completePresentation
					);
					setPersistedState(saveData);
					this.RedirectToCompScreen(fromComplete);
				}
			);
		} catch (ex: any) {
			console.log("Presentation syncing failed : " + ex.message);
			this.SendEmail(completePresentation);
			let saveData = new GenericKeyPair(
				"CurrentPresentation",
				completePresentation
			);
			setPersistedState(saveData);
			this.RedirectToCompScreen(fromComplete);
		}
	}

	SendEmail(completePresentation: any) {
		try {
			if (completePresentation != null && completePresentation != undefined) {
				const dataa = JSON.stringify(completePresentation);
				var objectData: any = {};
				objectData.FileData = dataa;
				objectData.Description = "AutoGenerated Unsync Presentation Log";
				PostRequestWithAuthorization(
					ApiEndPointConstants.HelpEndpoint,
					[],
					objectData
				)
					.then((res: any) => {
						// this.$data.isLoading = false;
						if (res != undefined) {
							if ((res.Result = true)) {
								console.log(
									"AutoGenerated Unsync Presentation Log submitted Successfully"
								);
								// this.$store.state.messageText = "Log submitted Successfully";
								// this.$store.state.showMessage = true;
								// this.$emit("ClosePopup", null);
							}
						}
					})
					.catch((ex: any) => {
						// this.$data.isLoading = false;
						console.log("Unable to send AutoGenerated Unsync Presentation Log");
					});
			}
		} catch (error: any) {
			console.log("AutoGenerated Unsync Presentation Log Sending failed");
		}
	}

	RedirectToCompScreen(fromComplete: boolean) {
		setTimeout(() => {
			if (fromComplete) this.$router.push("/dashboard");
			else this.$router.push("/StartPresentation");
			this.$store.state.loader = false;
		}, 500);
	}

	SendDatatoServer(route: String) {
		let presentation = getPersistedState("CurrentPresentation");
		let syncModel = new SyncModel(
			"Presentation" + new Date().toLocaleTimeString(),
			JSON.stringify(presentation)
		);
		SyncService.SyncData(syncModel);
	}

	dateDifference(date1: any, date2: any) {
		var ms = moment(date1, "MM/DD/YYYY HH:mm:ss tt").diff(
			moment(date2, "MM/DD/YYYY HH:mm:ss tt")
		);
		var d = moment.duration(ms);
		var s = this.millisToMinutesAndSeconds(ms);
		return s;
	}

	millisToMinutesAndSeconds(millis: any) {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		if (minutes > 59) {
			var hour = minutes / 60;
			minutes = minutes % 60;
			return hour.toFixed() + ":" + minutes + ":" + seconds;
		}
		var addZero = "";
		if (seconds.toString().length == 1) addZero = "0";
		return minutes + ":" + addZero + seconds;
	}

	changePresentationType(a: any) {
		this.SelectedPresentationType = this.PresentationTypes.find(
			(i: { Id: any }) => i.Id === a.Id
		);

		this.PresType = a;
		// if (
		//   this.SelectedPresentationType.source != null &&
		//   this.SelectedPresentationType.source.length > 0
		// ) {
		//   this.SelectedSubType = null;
		//   this.SubTypes = this.SelectedPresentationType.source;
		//   this.DisabledSubType = false;
		// } else {
		//   this.SubTypes = [];
		//   this.SelectedSubType = "";
		//   this.DisabledSubType = true;
		// }
		if (
			this.SelectedPresentationType.SubTypes != null &&
			this.SelectedPresentationType.SubTypes.length > 0
		) {
			this.SelectedSubType = null;
			this.SubTypes = this.SelectedPresentationType.SubTypes?.slice(0);
			this.FilterSubTypesUponAlphaAccess(this.SelectedPresentationType.Id);
			this.DisabledSubType = false;
			this.$data.SubtypeValid = false;
		} else {
			this.SubTypes = [];
			this.SelectedSubType = "";
			this.DisabledSubType = true;
			this.$data.SubtypeValid = true;
		}
	}

	private isEmailSelected: boolean = true;
	@Watch("Presentation.FieldTraining")
	OnFieldTrainingChanged(newValue: string, oldValue: string) {
		if (newValue == "WITHSOMEONE") {
			if (this.FieldTrainee == undefined || this.FieldTrainee == "")
				this.isEmailSelected = false;
			else this.isEmailSelected = true;
		} else {
			this.isEmailSelected = true;
			this.FieldTrainee = "";
		}
	}

	FieldTraineeEmailChange(evt: any) {
		if (this.FieldTrainee != undefined && this.FieldTrainee != "")
			this.isEmailSelected = true;
		else this.isEmailSelected = false;
		this.UserEmails = [evt];
		if (evt != undefined) {
			this.$data.Presentation.FieldTrainingAssociateId = evt.AssociateId;
			this.FieldTrainee = evt.UserInfo.FullName;
		}
	}

	CheckRequiredScreenCompleted() {
		if (!this.SelectedPresentationType) {
			this.SelectedPresentationType = this.PresentationTypes.find(
				(i: any) => i.Name === this.PresType
			);
		}
		var RequiredListScreen = this.ScreenRequirmenttoCompletePres.filter(
			(x: any) =>
				(x.PresentationTypeId == this.SelectedPresentationType.Id ||
					x.PresentationTypeId == 0) &&
				(x.GroupId == this.$data.Presentation.PresentedGroupId ||
					x.GroupId == 0)
		);
		var errorMessage = "";
		if (RequiredListScreen != undefined && RequiredListScreen.length > 0) {
			RequiredListScreen.forEach((element: any) => {
				switch (element.ScreenId) {
					case PresentationScreen.ReportCard:
						if (
							!this.$data.Presentation.isReportCardCompleted &&
							this.$data.Presentation.WhatHappened != "TRAINING"
						) {
							this.$refs.reportCardRow.classList.remove("shake-horizontal");
							setTimeout(
								() =>
									this.$refs.reportCardRow.classList.add("shake-horizontal"),
								200
							);
							this.$refs.reportCardRow.classList.add("error--text");
							this.$refs.reportCardRow.classList.add("fw-600");
							errorMessage += element.ErrorMessage + "\n";
						}
						break;
					case PresentationScreen.NeedAnalysis:
						if (!this.$data.Presentation.isNeedAnalysisCompleted) {
							this.$refs.needAnalysisRow.classList.remove("shake-horizontal");
							setTimeout(
								() =>
									this.$refs.needAnalysisRow.classList.add("shake-horizontal"),
								200
							);
							this.$refs.needAnalysisRow.classList.add("error--text");
							this.$refs.needAnalysisRow.classList.add("fw-600");
							errorMessage += element.ErrorMessage + "\n";
						}
						break;
					case PresentationScreen.PlanGenerator:
						if (!this.$data.Presentation.isPlanGeneratorCompleted) {
							this.$refs.planGeneratorRow.classList.remove("shake-horizontal");
							setTimeout(
								() =>
									this.$refs.planGeneratorRow.classList.add("shake-horizontal"),
								200
							);
							this.$refs.planGeneratorRow.classList.add("error--text");
							this.$refs.planGeneratorRow.classList.add("fw-600");
							errorMessage += element.ErrorMessage + "\n";
						}
						break;
					case PresentationScreen.BenefitSummary:
						if (!this.$data.Presentation.isBenefitsSummaryCompleted) {
							this.$refs.benefitsSummaryRow.classList.remove(
								"shake-horizontal"
							);
							setTimeout(
								() =>
									this.$refs.benefitsSummaryRow.classList.add(
										"shake-horizontal"
									),
								200
							);
							this.$refs.benefitsSummaryRow.classList.add("error--text");
							this.$refs.benefitsSummaryRow.classList.add("fw-600");
							errorMessage += element.ErrorMessage + "\n";
						}
						break;
					case PresentationScreen.PresentPlan:
						if (!this.$data.Presentation.isPresentPlanCompleted) {
							this.$refs.presentPlanRow.classList.remove("shake-horizontal");
							setTimeout(
								() =>
									this.$refs.presentPlanRow.classList.add("shake-horizontal"),
								200
							);
							this.$refs.presentPlanRow.classList.add("error--text");
							this.$refs.presentPlanRow.classList.add("fw-600");
							errorMessage += element.ErrorMessage + "\n";
						}
						break;
				}
			});
		}
		if (errorMessage != "") {
			// this.$store.state.messageText = errorMessage;
			// this.$store.state.showMessage = true;
			return false;
		} else {
			return true;
		}
	}
	SaveWillKitDataToPresentation(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			this.$data.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			Presentation.update({
				where: this.$data.Presentation.Id,
				data: this.$data.Presentation,
			});
		}
	}
	//private allProducts: any = [];
	private allPresTypes: any = [];
	//private allMatchingproducts: any;
	private allMatchingproductsReferral: any;
	private products: any;
	private states: any = [];
	private itemLessCount: number = 3;
	LoadPresData() {
		if (
			this.$data.Presentation.TimeOnNeedAnalysis != undefined &&
			this.$data.Presentation.TimeOnNeedAnalysis != "" &&
			parseInt(this.$data.Presentation.TimeOnNeedAnalysis) != 0
		) {
			this.NeedAnalysisAttached = true;
			if (!this.EmailPresentationAttachments.includes("NeedAnalysis")) {
				this.EmailPresentationAttachments.push("NeedAnalysis");
			}
		} else {
			this.NeedAnalysisAttached = false;
			if (this.EmailPresentationAttachments.includes("NeedAnalysis")) {
				this.EmailPresentationAttachments.splice(
					this.EmailPresentationAttachments.indexOf(
						this.EmailPresentationAttachments.find((x: any) => {
							x == "NeedAnalysis";
						})
					),
					1
				);
			}
		}

		if (
			this.$data.Presentation.AnyProductChecked != undefined &&
			this.$data.Presentation.AnyProductChecked != null &&
			this.$data.Presentation.AnyProductChecked != false
		) {
			this.BenefitSummaryAttached =
				this.EmailPresentationAttachments.includes("BenifitSummary");
			this.BenefitAttachmentDisabled = false;
		} else {
			this.BenefitAttachmentDisabled = true;
			this.BenefitSummaryAttached = false;
			if (this.EmailPresentationAttachments.includes("BenifitSummary")) {
				this.EmailPresentationAttachments.splice(
					this.EmailPresentationAttachments.indexOf(
						this.EmailPresentationAttachments.find((x: any) => {
							x == "BenifitSummary";
						})
					),
					1
				);
			}
		}
	}
	async LoadData() {
		this.$store.state.loader = true;
		var res = await getPersistedState(IndexDBConstants.PresentationTypesKey);
		this.allPresTypes = res;

		if (!this.SelectedPresentationType) {
			this.SelectedPresentationType = this.PresentationTypes.find(
				(i: any) => i.Name === this.PresType
			);
		}

		var stateSel = this.statesList.filter((x: any) => {
			if (x.Name == this.$data.Presentation.State) return x;
		});
		var state: any = {};
		if (stateSel != undefined && stateSel.length > 0) {
			state = stateSel[0];
		}

		this.fetchMaterialProducts(
			this.SelectedPresentationType,
			state.CountryId,
			this.$data.Presentation.LanguageId
		);
		this.$store.state.loader = false;
	}

	fetchMaterialProducts(presType: any, countryId: number, languageId: number) {
		getPersistedState("Materials").then((res) => {
			this.allProducts = res;
			var langId = JSON.parse(JSON.stringify(languageId));

			if (langId == 48) langId = 41;

			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined || x.CountryId == countryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == presType.Id) &&
						(x.LanguageId == undefined || x.LanguageId == langId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);

			// this.allMatchingproductsReferral = JSON.parse(
			// 	JSON.stringify(this.allMatchingproducts)
			// );

			if (presType.Id == PresentationType.Veteran) {
				if (
					this.$data.Presentation?.SubType.toUpperCase() !=
					"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
				}
				if (
					this.$data.Presentation?.SubType.toUpperCase() ==
						"SERVICE MEMBER BENEFITS LEAD" ||
					this.$data.Presentation?.SubType.toUpperCase() ==
						"BURIAL & WILL KIT FOR VETERANS"
				) {
					let GiftCertificateIndex = this.allMatchingproducts.indexOf(
						this.allMatchingproducts.find(
							(x: any) => x.Name == "$2000 GIFT CERTIFICATE"
						)
					);
					if (GiftCertificateIndex) {
						this.allMatchingproducts.splice(GiftCertificateIndex, 1);
					}

					let GroupAdndCertificate = this.allMatchingproducts.indexOf(
						this.allMatchingproducts.find(
							(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
						)
					);
					if (GroupAdndCertificate) {
						this.allMatchingproducts.splice(GroupAdndCertificate, 1);
					}
				} else if (
					this.$data.Presentation?.SubType.toUpperCase() == "RETURN CARD"
				) {
					let GiftCertificateIndex = this.allMatchingproducts.indexOf(
						this.allMatchingproducts.find(
							(x: any) => x.Name == "$2000 GIFT CERTIFICATE"
						)
					);
					if (GiftCertificateIndex) {
						this.allMatchingproducts.splice(GiftCertificateIndex, 1);
					}
				} else if (
					this.$data.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD" ||
					this.$data.Presentation?.SubType.toUpperCase() ==
						"VETERAN FAMILY LEAD"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
				} else if (
					this.$data.Presentation?.SubType.toUpperCase() ==
					"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
							)
						),
						1
					);

					this.allMatchingproducts = this.allMatchingproducts.filter(
						(x: any) => x.ShowOnExpand == false
					);

					this.allMatchingproducts.forEach((material: any) => {
						if (material.Name.toUpperCase() == "VETERAN SURVEY") {
							material.MaterialName = "SURVEY FOR VETERANS & THEIR FAMILIES";
						}
						if (material.Name.toUpperCase() == "SMB - 3 IMPORTANT FACTS") {
							material.ShowOnExpand = true;
						}
						if (material.Name.toUpperCase() == "$2000 GIFT CERTIFICATE") {
							material.ShowOnExpand = true;
						}
					});

					this.allMatchingproducts = this.allMatchingproducts.sort(
						(a: any, b: any) => a.ShowOnExpand - b.ShowOnExpand
					);

					var a = this.allMatchingproducts[this.allMatchingproducts.length - 1];
					this.allMatchingproducts[this.allMatchingproducts.length - 1] =
						this.allMatchingproducts[this.allMatchingproducts.length - 2];
					this.allMatchingproducts[this.allMatchingproducts.length - 2] = a;
				}
			}

			if (presType.Id == PresentationType.ServiceMemberBenefits) {
				if (
					this.$data.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD" ||
					this.$data.Presentation?.SubType.toUpperCase() ==
						"VETERAN FAMILY LEAD"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == true
							)
						),
						1
					);
				} else if (
					this.$data.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD (ALPHA)" ||
					this.$data.Presentation?.SubType.toUpperCase() ==
						"VETERAN FAMILY LEAD (ALPHA)" ||
					this.$data.Presentation?.SubType.toUpperCase() == "CIVILIAN" ||
					this.$data.Presentation?.SubType.toUpperCase() ==
						"SERVICE MEMBER BENEFIT NOTIFICATION"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
							)
						),
						1
					);

					this.allMatchingproducts = this.allMatchingproducts.filter(
						(x: any) => x.ShowOnExpand == false
					);

					this.allMatchingproducts.forEach((material: any) => {
						if (material.Name.toUpperCase() == "VETERAN SURVEY") {
							material.MaterialName = "SURVEY FOR VETERANS & THEIR FAMILIES";
						}
						if (material.Name.toUpperCase() == "SMB - 3 IMPORTANT FACTS") {
							material.ShowOnExpand = true;
						}
						if (material.Name.toUpperCase() == "$2000 GIFT CERTIFICATE") {
							material.ShowOnExpand = true;
						}
					});

					this.allMatchingproducts = this.allMatchingproducts.sort(
						(a: any, b: any) => a.ShowOnExpand - b.ShowOnExpand
					);
				}
			}

			let MemberSurveyIndex = this.allMatchingproducts.indexOf(
				this.allMatchingproducts.find((x: any) => x.Name == "MEMBER SURVEY")
			);
			if (MemberSurveyIndex >= 0) {
				this.allMatchingproducts.splice(MemberSurveyIndex, 1);
			}

			let MemberQuestionnaireIndex = this.allMatchingproducts.indexOf(
				this.allMatchingproducts.find(
					(x: any) => x.Name == "MEMBER QUESTIONNAIRE"
				)
			);
			if (MemberQuestionnaireIndex >= 0) {
				this.allMatchingproducts.splice(MemberQuestionnaireIndex, 1);
			}

			let LwtpsNoCostLawIndex = this.allMatchingproducts.indexOf(
				this.allMatchingproducts.find(
					(x: any) => x.Name == "LWTPS - NO COST LAW"
				)
			);
			if (LwtpsNoCostLawIndex >= 0) {
				this.allMatchingproducts.splice(LwtpsNoCostLawIndex, 1);
			}

			let LwtpsVeteranIndex = this.allMatchingproducts.indexOf(
				this.allMatchingproducts.find((x: any) => x.Name == "VETERAN SURVEY")
			);
			if (LwtpsVeteranIndex >= 0) {
				this.allMatchingproducts.splice(LwtpsVeteranIndex, 1);
			}

			this.itemLessCount = parseInt(
				this.allMatchingproducts
					.filter((x: any) => x.ShowOnExpand == false)
					.length.toString()
			);

			if (
				presType.Id != 6 &&
				this.$data.Presentation?.SubType.toUpperCase() != "RETURN CARD"
			) {
				let MemberQuestionnaireIndex = this.allMatchingproducts.indexOf(
					this.allMatchingproducts.find(
						(x: any) => x.Name == "MEMBER QUESTIONNAIRE"
					)
				);
				if (MemberQuestionnaireIndex >= 0) {
					this.allMatchingproducts.splice(MemberQuestionnaireIndex, 1);
				}
			}
			for (let i = 0; i < this.allMatchingproducts.length; i++) {
				var found = false;
				var giftFound;
				if (this.allMatchingproducts[i].Checked == undefined) {
					for (let j = 0; j < this.EmailMaterialAttachments.length; j++) {
						found =
							this.EmailMaterialAttachments[j].Name ==
							this.allMatchingproducts[i].MaterialName;
						if (found) break;
					}
					if (
						(this.allMatchingproducts[i].MaterialName ==
							"GROUP AD&D CERTIFICATE" &&
							this.$data.Presentation.BenefitAndDocumentEmailFirstTimeOpen ==
								true) ||
						this.allMatchingproducts[i].MaterialName == "$2000 GIFT CERTIFICATE"
					) {
						giftFound = this.EmailMaterialAttachments.indexOf(
							this.EmailMaterialAttachments.find((x: any) => {
								if (x.Name == this.allMatchingproducts[i].MaterialName)
									return x;
							})
						);
						if (giftFound == -1) {
							this.EmailMaterialAttachments.push({
								Name: this.allMatchingproducts[i].MaterialName,
								Id: this.allMatchingproducts[i].Id,
								Index: i,
								Image: this.allMatchingproducts[i].FlipbookPages[1],
								IsVideo: this.allMatchingproducts[i].MaterialTypeId == 2,
							});
						}
						Vue.set(this.allMatchingproducts[i], "Checked", true);
					} else if (this.allMatchingproducts[i].MaterialTypeId == 2 && found) {
						this.VideoMaterialCount += 1;
						Vue.set(this.allMatchingproducts[i], "Checked", true);
					} else {
						Vue.set(this.allMatchingproducts[i], "Checked", found);
					}
				}
			}
			this.products = JSON.parse(
				JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
			);
		});
	}
	async SendNoCostBenefitsClicked() {
		var ValidEmail = false;
		const emailpattern =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (this.Person.FirstName == null || this.Person.FirstName.trim() == "") {
			this.$refs.personfirstnamefield.$el.classList.add("error--text");
		}
		if (this.Person.Email == null || this.Person.Email.trim() == "") {
			this.$refs.personemailfield.$el.classList.add("error--text");
		} else {
			ValidEmail = emailpattern.test(this.Person.Email);
			if (!ValidEmail) {
				this.$refs.personemailfield.$el.classList.add("error--text");
			}
		}
		if (!this.sendnocost) {
			if (
				this.Person.FirstName != null &&
				this.Person.FirstName.trim() != "" &&
				this.Person.Email != null &&
				this.Person.Email.trim() != "" &&
				ValidEmail
			) {
				if (
					this.$data.Presentation.BenefitAndDocumentEmailFirstTimeOpen ==
						null &&
					this.$data.Presentation.BenefitAndDocumentEmailFirstTimeOpen ==
						undefined
				) {
					this.$data.Presentation.BenefitAndDocumentEmailFirstTimeOpen = true;
				} else {
					this.$data.Presentation.BenefitAndDocumentEmailFirstTimeOpen = false;
				}
				await this.LoadData();
				this.LoadPresData();
				this.VideoMaterialCount = 0;
				var groupCode = this.$store.state.SGNumber;
				if (groupCode != null && groupCode != undefined && groupCode != "") {
					this.displayGroup();
				} else {
					this.SelectedGroupCodeCount = 0;
				}
				if (
					this.$data.AdAnddPdfUrl != undefined &&
					this.$data.AdAnddPdfUrl != ""
				) {
					this.$data.AdAnddPdfBase64Url = `data:application/pdf;base64,${this.$data.AdAnddPdfUrl}#view=Fit&navpanes=0&toolbar=0&scrollbar=0`;
				} else {
					this.$data.AdAnddPdfBase64Url = "";
				}
				this.sendnocost = true;
				this.ValidPrimaryEmail = true;
				this.ValidSpouseEmail = true;
			} else {
				this.$store.state.messageText = this.$t("FinishPresentation.AllFields");
				this.$store.state.showMessage = true;
			}
		}
	}

	get IsVeteranSMBAlpha() {
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation.PresentationTypeId == PresentationType.Veteran &&
			this.$data.Presentation.PresentationSubTypeId ==
				PresentationSubType.ServiceMemberBenefitsLeadAlpha
		) {
			return true;
		}
		return false;
	}

	get IsServiceMemberBenefitsAlpha() {
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation.PresentationTypeId ==
				PresentationType.ServiceMemberBenefits &&
			(this.$data.Presentation.PresentationSubTypeId ==
				PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
				this.$data.Presentation.PresentationSubTypeId ==
					PresentationSubType.SMBVeteranFamilyLeadAlpha ||
				this.$data.Presentation.PresentationSubTypeId ==
					PresentationSubType.Civilian ||
				this.$data.Presentation.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitNotification)
		) {
			return true;
		}
		return false;
	}

	get IsVideoReviewRequired() {
		if (
			this.$data.Presentation != undefined &&
			(this.$data.Presentation.WhatHappened == "TRAINING" ||
				this.$data.Presentation.PresentationTypeId == PresentationType.Demo ||
				this.$data.Presentation.MemberLookupTypeId == MemberLookupType.Demo ||
				this.$data.Presentation.MemberLookupTypeId == MemberLookupType.Training)
		) {
			return false;
		}
		return true;
	}

	get RecommendedTotalCoverageValue() {
		var RecommendedTotalCoverage = 0;
		RecommendedTotalCoverage =
			Number.parseFloat(
				this.CombinedAnnualIncomeValue.toString().replaceAll(",", "")
			) * 10;
		return ConvertIntoAmountFormatWithDecimal(
			RecommendedTotalCoverage.toFixed(2)
		);
	}
	get CombinedAnnualIncomeValue() {
		var CombinedAnnualIncome = 0;
		if (this.Person != undefined) {
			CombinedAnnualIncome = Number(
				+(this.Person.AnnualIncome != undefined
					? Number.parseFloat(
							this.$data.Presentation?.PresentedTo.AnnualIncome.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
					+(this.Person.Spouse.AnnualIncome != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.Spouse.AnnualIncome.toString().replaceAll(
									",",
									""
								)
						  )
						: 0)
			);
		}
		return ConvertIntoAmountFormatWithDecimal(CombinedAnnualIncome.toFixed(2));
	}
	get CoverageNeededValue() {
		var CoverageNeeded = 0;
		CoverageNeeded =
			Number.parseFloat(
				this.RecommendedTotalCoverageValue.toString().replaceAll(",", "")
			) -
			Number.parseFloat(
				this.ExistingLifeInsuranceValue.toString().replaceAll(",", "")
			);
		return ConvertIntoAmountFormatWithDecimal(CoverageNeeded.toFixed(2));
	}
	get ExistingLifeInsuranceValue() {
		var tempExistingLifeIns = 0;
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation?.PresentedTo != undefined
		) {
			tempExistingLifeIns = Number(
				(
					+(this.$data.Presentation?.PresentedTo
						.InsuranceThroughWorkWholeLife != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.InsuranceThroughWorkWholeLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo.InsuranceThroughWorkTermLife !=
					undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.InsuranceThroughWorkTermLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo
						.InsuranceOutsideWorkWholeLife != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.InsuranceOutsideWorkWholeLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo.InsuranceOutsideWorkTermLife !=
					undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.InsuranceOutsideWorkTermLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo.Spouse
						.InsuranceThroughWorkWholeLife != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.Spouse.InsuranceThroughWorkWholeLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo.Spouse
						.InsuranceThroughWorkTermLife != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.Spouse.InsuranceThroughWorkTermLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo.Spouse
						.InsuranceOutsideWorkWholeLife != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0) +
					+(this.$data.Presentation?.PresentedTo.Spouse
						.InsuranceOutsideWorkTermLife != undefined
						? Number.parseFloat(
								this.$data.Presentation?.PresentedTo.Spouse.InsuranceOutsideWorkTermLife.toString().replaceAll(
									",",
									""
								)
						  )
						: 0)
				).toFixed()
			);
		}
		return ConvertIntoAmountFormatWithDecimal(tempExistingLifeIns.toFixed(2));
	}
	get PrimaryDOBandAge() {
		var PrimaryDOBandAgeString = "";
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation?.PresentedTo != undefined
		) {
			var Dob = this.Person.DateOfBirth;
			var Age = this.Person.AgePerDOB;
			if (Dob != undefined && Dob != "" && Age != undefined)
				PrimaryDOBandAgeString += `${Dob} (${Age})`;
		} else if (Dob != undefined && Dob != "" && Age == undefined) {
			Age = EntityHelper.calculate_age(Dob);
			PrimaryDOBandAgeString += `${Dob} (${Age})`;
		}
		return PrimaryDOBandAgeString;
	}
	get SpouseDobAndAge() {
		var SpouseDOBandAgeString = "";
		if (
			this.$data.Presentation != undefined &&
			this.$data.Presentation?.PresentedTo != undefined &&
			this.$data.Presentation?.PresentedTo?.Spouse != undefined
		) {
			var Dob = this.Person.Spouse.DateOfBirth;
			var Age = this.Person.SpouseAgePerDOB;

			if (Dob != undefined && Dob != "" && Age != undefined) {
				SpouseDOBandAgeString += `${Dob} (${Age})`;
			} else if (Dob != undefined && Dob != "" && Age == undefined) {
				Age = EntityHelper.calculate_age(Dob);
				SpouseDOBandAgeString += `${Dob} (${Age})`;
			}
		}
		return SpouseDOBandAgeString;
	}
	get getIntroductoryNames() {
		if (this.sendnocost) {
			var IntroductoryNames = "";
			var EmptyPrimaryName =
				this.Person.FirstName == undefined ||
				this.Person.FirstName.trim() == "";

			var EmptySpouseName =
				this.Person.Spouse?.FirstName == undefined ||
				this.Person.Spouse?.FirstName == "";

			if (!EmptyPrimaryName && !EmptySpouseName) {
				IntroductoryNames += `${this.Person.FirstName.trim()} & ${this.Person.Spouse?.FirstName.trim()},`;
			} else if (!EmptyPrimaryName && EmptySpouseName) {
				IntroductoryNames += `${this.Person.FirstName.trim()},`;
			} else if (EmptyPrimaryName && !EmptySpouseName) {
				IntroductoryNames += `${this.Person.Spouse.FirstName.trim()},`;
			}

			return IntroductoryNames;
		}
	}

	displayGroup() {
		// if (item == undefined || item == "") {
		// 	this.$store.state.messageText = this.$t("NoCostBenefits.SelectGroupCode");
		// 	this.$store.state.showMessage = true;
		// 	EventBusLayout.$emit("CloseGroup", false);
		// 	return;
		// }

		// this.DownloadWillKitPDF = false;
		this.SelectedGroupCodeCount = 0;
		this.flipBookPages = [];
		this.showFlipbook = false;
		// this.$store.state.loader = true;
		// this.convertPDFToImage = true;
		// this.isGroupLetter = true;
		var groupCode = this.$store.state.SGNumber;
		var parameters: GenericKeyPair[] = [];
		if (typeof groupCode == "string") {
			parameters.push(new GenericKeyPair("groupCode", groupCode.toUpperCase()));
		}
		GetRequestWithAuthorization(
			ApiEndPointConstants.GroupLetterPDFUrlEndPoint,
			parameters
		).then((res) => {
			if (res.status && res.Result != undefined && res.Result != "") {
				// this.$data.overlay = !this.$data.overlay;
				this.SelectedGroupCodeCount = 1;
				this.$data.PdfUrl = res.Result;
				this.showPDF = true;
				this.showFlipbook = true;
				// EventBusLayout.$emit("CloseGroup", true);
			} else {
				// this.$data.overlay = false;
				// this.$store.state.loader = false;
				// this.$store.state.messageText = this.$t(
				// 	"NoCostBenefits.InvalidGroupCode"
				// );
				// this.$store.state.showMessage = true;
				// EventBusLayout.$emit("CloseGroup", false);
			}
		});
		//LoggingHelper.getInstance().LogEvent(LoggingConstants.GroupLetteropened);

		// this.$data.PdfUrl =
		// 	"https://hp-pro-materials-dev.s3.amazonaws.com/static/PDF/GROUP-ADnD-CERTIFICATE.pdf";
		// this.showPDF = true;
		// this.showFlipbook = true;
	}
	CloseServiceModal() {
		this.prev(true);
		this.sendnocost = false;
	}

	moreLessTapped() {
		if (this.products.length == this.itemLessCount) {
			this.products = JSON.parse(JSON.stringify(this.allMatchingproducts));
		} else {
			this.products = JSON.parse(
				JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
			);
		}
		this.forceRerender();
	}
	async SendBenefitsEmailCall(BenefitEmailRequest: any) {
		this.sendnocost = false;
		this.$store.state.loader = true;
		var GenericKeyPairobj: GenericKeyPair[] = [];
		APIService.SendBenefitAndDocumentEmail(
			BenefitEmailRequest,
			GenericKeyPairobj
		).then((res: any) => {
			if (res != undefined && res.data != undefined) {
				this.$store.state.loader = false;
				this.$store.state.messageText =
					"Email will be sent once presentation is completed.";
				this.$store.state.showMessage = true;
				if (this.$data.Presentation.BenefitAndDocumentEmailSentCount != null) {
					this.$data.Presentation.BenefitAndDocumentEmailSentCount += 1;
				} else {
					this.$data.Presentation.BenefitAndDocumentEmailSentCount = 1;
				}
			}
		});
	}
	async SendBenefitsAndDocumentsEmail() {
		if (this.Person.Email.length > 0) {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			this.ValidPrimaryEmail = pattern.test(this.Person.Email);
		} else {
			this.ValidPrimaryEmail = false;
		}
		if (this.Person.Spouse.Email.length > 0) {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			this.ValidSpouseEmail = pattern.test(this.Person.Spouse.Email);
		} else {
			this.ValidSpouseEmail = true;
		}
		if (this.ValidPrimaryEmail && this.ValidSpouseEmail) {
			let req = this.CreateBenefitsAttachmentRequest();
			await this.SendBenefitsEmailCall(req);
		} else {
			this.$store.state.messageText = this.$t("FinishPresentation.AllFields");
			this.$store.state.showMessage = true;
		}
	}
	CreateBenefitsAttachmentRequest() {
		var Request: any = {};
		var tempPresentation = Presentation.query().withAllRecursive().last();
		var tempPerson = PrimaryPerson.query().withAllRecursive().last();
		Request.LeadId =
			tempPresentation?.LeadId != undefined ? tempPresentation?.LeadId : "";
		Request.PresentationGuid = tempPresentation?.Id;

		Request.LanguageId = tempPresentation?.LanguageId;
		Request.CountryId = tempPresentation?.CountryId;
		Request.PrimaryFirstName = this.Person?.FirstName;
		Request.PrimaryLastName = this.Person?.LastName;
		Request.PrimaryEmail = this.Person?.Email;
		Request.SpouseFirstName = tempPerson?.Spouse.FirstName;
		Request.SpouseLastName = tempPerson?.Spouse.LastName;
		Request.SpouseEmail = tempPerson?.Spouse.Email;
		Request.AgentFullName =
			Camelize(this.User.FirstName).trim() +
			" " +
			Camelize(this.User.LastName).trim();
		Request.AgentPhoneNo = this.User.PhoneNo;
		Request.AgentEmail = this.User.EmailAddress;
		//Request.AgentLicenseNo = this.User.LicNo;
		Request.AgentLicenseNo =
			this.User.LicNo.toUpperCase() + " " + this.User.LicState.toUpperCase();
		//Request.AgentLicenseState = this.User.LicState;
		Request.IsNeedAnalysisSelected = this.NeedAnalysisAttached;
		Request.IsBenefitSummarySelected = this.BenefitSummaryAttached;
		Request.IsNotificationLetterSelected =
			this.$store.state.SGNumber != undefined &&
			this.$store.state.SGNumber != ""
				? true
				: false;
		Request.GroupCode =
			this.$store.state.SGNumber != undefined &&
			this.$store.state.SGNumber != ""
				? this.$store.state.SGNumber
				: "";
		Request.SelectedMaterialsList = this.EmailMaterialAttachments;
		return Request;
	}
	SpouseEmailChanged() {
		Spouse.update({
			Id: this.Person.Spouse.Id,
			data: this.Person.Spouse,
		});
	}
	ValidateServiceModalEmail(FieldName: string) {
		if (FieldName == "PrimaryEmail") {
			if (this.Person.Email.length > 0) {
				const pattern =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				this.ValidPrimaryEmail = pattern.test(this.Person.Email);
			} else {
				this.ValidPrimaryEmail = false;
			}
		} else if (FieldName == "SpouseEmail") {
			if (this.Person.Spouse.Email.length > 0) {
				const pattern =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				this.ValidSpouseEmail = pattern.test(this.Person.Spouse.Email);
			} else {
				this.ValidSpouseEmail = true;
			}
		}
	}

	FilterSubTypesUponAlphaAccess(PresentationTypeId: number) {
		if (
			this.SubTypes != undefined &&
			this.SubTypes.length > 0 &&
			PresentationTypeId == PresentationType.Veteran
		) {
			let ServiceMemberBenefitsLeadIndex = this.SubTypes.indexOf(
				this.SubTypes.find((x: any) => x.Name == "SERVICE MEMBER BENEFITS LEAD")
			);
			if (ServiceMemberBenefitsLeadIndex > -1) {
				this.SubTypes.splice(ServiceMemberBenefitsLeadIndex, 1);
			}
			let ServiceMemberBenefitsLeadAlphaIndex = this.SubTypes.indexOf(
				this.SubTypes.find(
					(x: any) => x.Name == "SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				)
			);
			if (ServiceMemberBenefitsLeadAlphaIndex > -1) {
				this.SubTypes.splice(ServiceMemberBenefitsLeadAlphaIndex, 1);
			}
		}

		if (
			this.SubTypes != undefined &&
			this.SubTypes.length > 0 &&
			PresentationTypeId == PresentationType.ServiceMemberBenefits &&
			this.User != undefined
		) {
			if (this.User.HasAlphaAccess) {
				let SponsoredVeteranLeadIndex = this.SubTypes.indexOf(
					this.SubTypes.find((x: any) => x.Name == "SPONSORED VETERAN LEAD")
				);
				if (SponsoredVeteranLeadIndex > -1) {
					this.SubTypes.splice(SponsoredVeteranLeadIndex, 1);
				}
				let VeteranFamilyLeadIndex = this.SubTypes.indexOf(
					this.SubTypes.find((x: any) => x.Name == "VETERAN FAMILY LEAD")
				);
				if (VeteranFamilyLeadIndex > -1) {
					this.SubTypes.splice(VeteranFamilyLeadIndex, 1);
				}
			} else {
				let SponsoredVeteranLeadAlphaIndex = this.SubTypes.indexOf(
					this.SubTypes.find(
						(x: any) => x.Name == "SPONSORED VETERAN LEAD (ALPHA)"
					)
				);
				if (SponsoredVeteranLeadAlphaIndex > -1) {
					this.SubTypes.splice(SponsoredVeteranLeadAlphaIndex, 1);
				}
				let VeteranFamilyLeadAlphaIndex = this.SubTypes.indexOf(
					this.SubTypes.find(
						(x: any) => x.Name == "VETERAN FAMILY LEAD (ALPHA)"
					)
				);
				if (VeteranFamilyLeadAlphaIndex > -1) {
					this.SubTypes.splice(VeteranFamilyLeadAlphaIndex, 1);
				}
				let CivilianIndex = this.SubTypes.indexOf(
					this.SubTypes.find((x: any) => x.Name == "CIVILIAN")
				);
				if (CivilianIndex > -1) {
					this.SubTypes.splice(CivilianIndex, 1);
				}
				let ServiceMemberBenefitNotificationIndex = this.SubTypes.indexOf(
					this.SubTypes.find(
						(x: any) => x.Name == "SERVICE MEMBER BENEFIT NOTIFICATION"
					)
				);
				if (ServiceMemberBenefitNotificationIndex > -1) {
					this.SubTypes.splice(ServiceMemberBenefitNotificationIndex, 1);
				}
			}
		}
	}

	SelectPresComponentsPreview(evt: any, attachmentname: string) {
		if (evt == true) {
			this.EmailPresentationAttachments.push(attachmentname);
		} else {
			this.EmailPresentationAttachments.splice(
				this.EmailPresentationAttachments.indexOf(
					this.EmailPresentationAttachments.find((x: any) => {
						x == attachmentname;
					})
				),
				1
			);
		}
		this.prev(true);
	}
	selectMaterialForPreview(evt: any, material: any) {
		var selectedIndex = this.allMatchingproducts.indexOf(
			this.allMatchingproducts.find((x: any) => x.Id == material.Id)
		);
		if (evt == true) {
			let PreviewImage;
			if (material.FlipbookPages != null) {
				PreviewImage = material.FlipbookPages[1];
			} else {
				if (material.MaterialTypeId == 2) {
					PreviewImage = "";
				} else {
					PreviewImage = material.Link;
				}
			}
			this.EmailMaterialAttachments.push({
				Name: material.MaterialName,
				Id: material.Id,
				Index: selectedIndex,
				Image: PreviewImage,
				IsVideo: material.MaterialTypeId == 2,
			});
			if (material.MaterialTypeId == 2) {
				this.VideoMaterialCount += 1;
			}
			this.allMatchingproducts[selectedIndex].Checked = true;
		} else {
			this.EmailMaterialAttachments.splice(
				this.EmailMaterialAttachments.indexOf(
					this.EmailMaterialAttachments.find(
						(x: any) => x.Name == material.MaterialName
					)
				),
				1
			);
			if (material.MaterialTypeId == 2) {
				this.VideoMaterialCount -= 1;
			}
			this.allMatchingproducts[selectedIndex].Checked = false;
		}
		this.prev(true);
		this.EmailMaterialAttachments.sort((a: any, b: any) => a.Index - b.Index);
	}
	get getMaterialAttachments() {
		this.MaterialsArray = this.EmailMaterialAttachments;
		this.MaterialsForPreview = [...this.MaterialsArray];
		var VideoMaterialIndex = this.MaterialsForPreview.indexOf(
			this.MaterialsForPreview.find((x: any) => {
				if (x.IsVideo == true) return x;
			})
		);
		if (VideoMaterialIndex >= 0) {
			this.MaterialsForPreview.splice(VideoMaterialIndex, 1);
		}
		return this.MaterialsForPreview;
	}
	get PageNumbersArray() {
		this.PageNumbers = [1];
		this.MaterialsArrayForCount = this.EmailMaterialAttachments;
		this.EmailArrayForCount = this.EmailPresentationAttachments;
		// var GroupCodeCount = this.SelectedGroupCodeCount;
		// var VideoCount = this.VideoMaterialCount;
		var sum =
			this.EmailArrayForCount.length +
			this.MaterialsArrayForCount.length -
			this.VideoMaterialCount +
			this.SelectedGroupCodeCount;
		if (this.PageNumbers.length > 0) {
			for (let i = 0; i < sum; i++) {
				this.PageNumbers.push(
					this.PageNumbers[this.PageNumbers.length - 1] + 1
				);
			}
		}
		return this.PageNumbers;
	}

	forceRerender() {
		this.$data.componentKey += 1;
	}

	private flipBookPages: Array<any> = [
		"",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/1.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/2.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/3.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/4.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/5.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/6.jpg",
	];
	private showPDF: Boolean = false;
	private showAdanddPdf: Boolean = false;
	private showFlipbook: Boolean = false;

	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}
	func(evt: any) {}
	func1(evt: any) {}
	async pagesRendered1(pdfApp: any) {
		pdfApp.pdfViewer.currentScaleValue = "page-fit";
		console.log("PageRendering");
		console.log(pdfApp);
		let pagecount = pdfApp.pagesCount;
		console.log(pagecount);
		var pageNumber = 0.5;
		this.$data.canvaslist = [];
		for (var i = 1; i <= pdfApp.pagesCount; i++) {
			this.$data.canvaslist.push({ id: "canvas-" + i });
		}
		var temppages: any = [];
		this.flipBookPages = [];
		this.flipBookPages.push("");
		for (var j = 1; j <= pdfApp.pagesCount; j++) {
			var page = await pdfApp.pdfDocument.getPage(j);
			var scale = 1;
			var viewport = page.getViewport({
				scale: scale,
			});

			// Prepare canvas using PDF page dimensions
			var canvas: any = document.getElementById(
				"canvas-" + (page._pageIndex + 1)
			);
			var context = canvas.getContext("2d");
			canvas.height = viewport.height;
			canvas.width = viewport.width;

			// Render PDF page into canvas context
			var renderContext = {
				canvasContext: context,
				viewport: viewport,
			};
			await page.render(renderContext).promise;
			temppages.push({ index: j, url: canvas.toDataURL() });
		}

		temppages = temppages.sort(this.sort);
		this.flipBookPages.push(...temppages.map((a: any) => a.url));
		this.showPDF = false;
		this.$store.state.loader = false;
		this.showFlipbook = true;
		setTimeout(() => {
			var PdfShown = document.getElementById("viewer");
			if (PdfShown != undefined) {
				if (PdfShown.children != undefined) {
					if (PdfShown.children.length > 1) {
						for (let index = PdfShown.children.length - 1; index > 0; index--) {
							PdfShown.removeChild(PdfShown.children[index]);
						}
					}
				}
			}
		}, 250);
	}

	async pagesRendered(pdfApp: any) {
		pdfApp.pdfViewer.currentScaleValue = "page-fit";
		console.log("PageRendering");
		console.log(pdfApp);
		let pagecount = pdfApp.pagesCount;
		console.log(pagecount);
		var pageNumber = 0.5;
		this.$data.canvaslist = [];
		for (var i = 1; i <= pdfApp.pagesCount; i++) {
			this.$data.canvaslist.push({ id: "canvas-" + i });
		}
		var temppages: any = [];
		this.flipBookPages = [];
		this.flipBookPages.push("");
		for (var j = 1; j <= pdfApp.pagesCount; j++) {
			var page = await pdfApp.pdfDocument.getPage(j);
			var scale = 1;
			var viewport = page.getViewport({
				scale: scale,
			});

			// Prepare canvas using PDF page dimensions
			var canvas: any = document.getElementById(
				"canvas-" + (page._pageIndex + 1)
			);
			var context = canvas.getContext("2d");
			canvas.height = viewport.height;
			canvas.width = viewport.width;

			// Render PDF page into canvas context
			var renderContext = {
				canvasContext: context,
				viewport: viewport,
			};
			await page.render(renderContext).promise;
			temppages.push({ index: j, url: canvas.toDataURL() });
		}

		temppages = temppages.sort(this.sort);
		this.flipBookPages.push(...temppages.map((a: any) => a.url));
		this.showPDF = false;
		this.$store.state.loader = false;
		this.showFlipbook = true;
		setTimeout(() => {
			var PdfShown = document.getElementById("viewer");
			if (PdfShown != undefined) {
				if (PdfShown.children != undefined) {
					if (PdfShown.children.length > 1) {
						for (let index = PdfShown.children.length - 1; index > 0; index--) {
							PdfShown.removeChild(PdfShown.children[index]);
						}
					}
				}
			}
		}, 250);
	}

	sort(a: any, b: any) {
		if (a.index < b.index) return -1;
		if (a.index > b.index) return 1;
		return 0;
	}
}
