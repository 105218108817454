

























































































































































































































import { Component, Vue } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import { namespace } from "vuex-class";
import SpProgram from "./icons/sp-program.vue";
import { SyncPresentations } from "@/helpers/SyncHelper";
import PresentationSyncing from "@/helpers/PresentationSync";
import GeneralConstants from "@/constants/GeneralConstants";
import { APIService } from "@/services/APIService";
import GenericKeyPair from "@/models/GenericKeyPair";
import { setPersistedState } from "@/helpers/StateMapper";
import GamePlanEntity from "../entities/GamePlan";
const Auth = namespace("Auth");

@Component({
	components: {
		ShortUserDetailView,
		SpProgram,
	},
})
export default class AppBar extends Vue {
	SyncGameplanList() {
		this.$store.state.loader = true;
		let CurrentTime = new Date();
		let SelectedYear = CurrentTime.getFullYear();
		let years = [SelectedYear - 1, SelectedYear, SelectedYear + 1];
		APIService.GetGamePlansByYear(years).then((res: any) => {
			if (res != undefined && res.Result != undefined) {
				this.$store.state.loader = false;
				let GamePlanList = new GenericKeyPair("GamePlans", res.Result);
				setPersistedState(GamePlanList);
				GamePlanEntity.deleteAll();
				res.Result.forEach((element: any) => {
					GamePlanEntity.insertOrUpdate({ data: element });
				});
			}
		});
	}
	@Auth.Action
	private signOut!: () => void;
	get navBarTitle() {
		if (this.$route != undefined && this.$route.name != undefined) {
			if (
				this.$route.name == "PlanGenerator" &&
				this.$route.params.initiatePrePlan
			) {
				return this.$t("PlanGenerator.PrePlanHeading");
			}
			return this.$t(this.$route.name + ".Heading");

			//return this.$route.meta.navTitle;
			// if (this.$route != undefined && this.$route.meta != undefined) {
			//   return this.$route.meta.navTitle;
			// if (this.$route != undefined && this.$route.meta != undefined) {
			//   return this.$route.meta.navTitle;
		} else return "";
	}

	get isPresentationPage() {
		let routes = [
			"/",
			"/login",
			// "/StartPresentation",
			"/dashboard",
			"/gameplan",
			"/profile",
			"/presentationhistory",
			"/presentationhistorydetail",
			// "/home",
		];

		if (routes.includes(this.$route.path)) {
			return false;
		}

		return true;
	}

	get showLogout() {
		if (this.$route != undefined && this.$route.meta != undefined)
			return this.$route.meta.showLogout;
		else return false;
	}
	logOut() {
		this.signOut();
		this.$router.push("/");
	}

	isDevelopment() {
		if (process.env.NODE_ENV === "development") return true;
		return false;
	}

	async SyncClick() {
		await PresentationSyncing.getInstance().SyncPresentation();
		this.GetNewWebVersion();
	}
	// async SendMessageAgain() {
	//  var test = {};
	//  var GenericKeyPairobj: GenericKeyPair[] = [];
	//  await APIService.ProcessClientOrGroupMessage(test, GenericKeyPairobj).then(
	//      async (res: any) => {
	//          if (res != undefined && res.data != null && res.data == true) {
	//              this.$store.state.loader = false;
	//              this.$store.state.messageText = "Message Processed";
	//              this.$store.state.showMessage = true;
	//          } else {
	//              this.$store.state.loader = false;
	//              this.$store.state.messageText = "Unable to Process Message";
	//              this.$store.state.showMessage = true;
	//          }
	//      }
	//  );
	// }

	async SendEmail() {
		var test = {};
		var GenericKeyPairobj: GenericKeyPair[] = [];
		await APIService.ProcessBenefitsAndDocumentEmail(
			test,
			GenericKeyPairobj
		).then(async (res: any) => {
			if (res != undefined && res.data != null && res.data == true) {
				this.$store.state.loader = false;
				this.$store.state.messageText = "Email Processed";
				this.$store.state.showMessage = true;
			} else {
				this.$store.state.loader = false;
				this.$store.state.messageText = "Unable to Process Email";
				this.$store.state.showMessage = true;
			}
		});
	}

	GetNewWebVersion() {
		if (
			this.$route.name != undefined &&
			PresentationSyncing.getInstance().isSyncing == false
		) {
			if (!GeneralConstants.PresentationTypesPages.includes(this.$route.name)) {
				APIService.GetCurrentAppVersion().then((res: any) => {
					if (
						res != undefined &&
						res.Result != undefined &&
						res.Result > process.env?.VUE_APP_VERSION
					)
						window.location.reload();
				});
			}
		}
	}
}
