

































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Plan } from "../models/Plan";
import {
	CalculateMBD,
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
	CalculateCoverageFromMBD,
	CalculateCoverageFromMBDByCoverageType,
	RoundUpper,
} from "../helpers/CalculationHelper";
import RateBookEntitity from "@/entities/RateBookEntity";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";

@Component({
	props: [
		"Plan",
		"HasChild",
		"Products",
		"IsWHLSelected",
		"IsBasicWHLSelected",
		"IsRC1Selected",
		"IsRC2Selected",
		"RC1PresentAs",
		"RC2PresentAs",
		"MonthlyRent",
		"Age",
		"SpouseAge",
		"Gender",
		"Own",
		"OwnAmount",
		"SpouseGender",
		"Wage",
		"WageType",
		"TobaccoUse",
		"PresentAsDefaultId",
		"State",
		"PrioritizeDaily",
		"Person",
		"IsPrePlan",
		"InterDependentOnDifferentProduct",
		"InterDependentProductSelection",
		"HasSpouse",
		"truncateProperties",
		"AllProducts",
		"StatesList",
		"RatesList",
		"isOnPopup",
		"IsA71000Selected",
	],
})
export default class PlanBox extends Vue {
	private currentProduct: any = {};
	private isConverageChange: boolean = false;
	private isMonthlyChange: boolean = false;
	private isDailyChange: boolean = false;
	private isWeeklyChange: boolean = false;
	private isAnnualChange: boolean = false;
	private formatwithZeros: boolean = false;
	private listofAllProduct: any = null;
	private allStates: any = null;
	private listofProducts: any = null;
	private listOfOptions: any = [];
	private listOfCoverageOptions: any = [];
	private chequeimg: boolean = false;
	private RateList: any = null;
	private numeral = require("numeral");
	private DisableOptionField: boolean = false;
	ShowCoverageItems: boolean = true;

	@Watch("PlanData.Plan.CompleteOption")
	OnOptionChange() {
		if (this.$data.PlanData.Plan.CompleteOption != undefined) {
			this.$data.PlanData.PlanOption = this.$data.PlanData.Plan.CompleteOption
				.Name
				? this.$data.PlanData.Plan.CompleteOption.Name
				: this.$data.PlanData.Plan.CompleteOption.PGName;
		}
	}

	@Watch("Age")
	OnAgeChange() {
		this.$data.PlanData.Plan.Age = this.$props.Age;
		this.checkProductAvailable(false);
	}

	@Watch("SpouseAge")
	OnSpouseAgeChange() {
		if (
			this.$data.PlanData.ProductId == 19 &&
			!this.$data.PlanData.IsSpouse &&
			this.$data.PlanData.Plan.CompleteOption.Id == 2
		) {
			this.CalculateMBDRate();
		}
	}

	@Watch("PlanData.Selected")
	OnSelectionChanged(newValue: any, oldValue: any) {
		if (
			this.$data.PlanData.IsWHL &&
			(this.currentProduct.Id == 1 || this.currentProduct.Id == 2)
		) {
			this.$data.PlanData.WHLSelected = newValue;
		} else {
			this.$data.PlanData.WHLSelected = false;
		}
		if (
			newValue == true &&
			this.$data.PlanData != undefined &&
			this.$data.PlanData.Plan != undefined &&
			this.$data.PlanData.Plan.CompleteProduct != null &&
			this.$data.PlanData.Plan.CompleteProduct.RemoveAllSpouseProduct == true
		) {
			this.$emit("RemoveAllSpousePlan", null);
		}
	}

	getStateCodefromName(name: string) {
		var state = this.allStates.find((x: any) => x.Name == name);
		if (state) return state.StateCode;

		return "";
	}

	@Watch("Gender")
	OnGenderChange() {
		this.$data.PlanData.Plan.Gender = this.$props.Gender;
		this.checkProductAvailable(false);
	}

	@Watch("SpouseGender")
	OnSpouseGenderChange() {
		if (
			this.$data.PlanData.ProductId == 19 &&
			!this.$data.PlanData.IsSpouse &&
			this.$data.PlanData.Plan.CompleteOption.Id == 2
		) {
			this.CalculateMBDRate();
		}
	}

	@Watch("Wage")
	OnWageChange() {
		this.$data.PlanData.Plan.Wage = this.$props.Wage;
		this.populateCoverageforRC();
	}

	@Watch("WageType")
	OnWageTypeChange() {
		this.$data.PlanData.Plan.WageType = this.$props.WageType;
		this.populateCoverageforRC();
	}

	@Watch("TobaccoUse")
	OnTobaccoChange() {
		this.$data.PlanData.Plan.TobaccoUse = this.$props.TobaccoUse;
		this.checkProductAvailable(false);
	}

	@Watch("State")
	OnStateChange() {
		this.$data.PlanData.Plan.State = this.getStateCodefromName(
			this.$props.State
		);
		this.checkProductAvailable(false);
	}

	@Watch("IsWHLSelected")
	OnIsWHLSelectedChange() {
		if (this.$data.PlanData.IsWHL) return;
		this.checkProductAvailable(false);
		this.checkAddAnnualRateCondition();
	}

	@Watch("IsBasicWHLSelected")
	OnIsBasicWHLSelectedChange() {
		if (this.$data.PlanData.IsWHL) return;
		this.checkProductAvailable(false);
		this.checkAddAnnualRateCondition();
	}

	@Watch("IsRC1Selected")
	OnIsRC1SelectedChange() {
		if (this.$data.PlanData.IsWHL) return;
		this.checkProductAvailable(false);
		this.checkAddAnnualRateCondition();
	}

	@Watch("IsRC2Selected")
	OnIsRC2SelectedChange() {
		if (this.$data.PlanData.IsWHL) return;
		this.checkProductAvailable(false);
		this.checkAddAnnualRateCondition();
	}

	@Watch("RC1PresentAs")
	OnRC1PresentAsChange() {
		this.loadPresentAsOptions(false, false);
	}

	@Watch("RC2PresentAs")
	OnRC2PresentAsChange() {
		this.loadPresentAsOptions(false, true);
	}

	@Watch("HasChild")
	OnHasChildChange() {
		this.$data.PlanData.Plan.hasChildren = this.$props.HasChild;
		this.checkProductAvailable(false);
	}

	@Watch("HasSpouse")
	OnHasSpouseChange() {
		this.ChangeOptionList();
		this.checkProductAvailable(false);
	}

	@Watch("listOfOptions")
	OnOptionsChanged() {
		if (!this.$data.PlanData.IsWHL) {
			//this.ShowCoverageItems = true;
			return;
		}
		this.checkWHLProductNameInOptionsList();
	}

	@Watch("currentProduct")
	OnProductChanged() {
		this.$data.PlanData.Plan.CurrentProduct = this.currentProduct;
		if (this.$data.PlanData.IsWHL && this.$data.PlanData.Plan.CompleteOption) {
			this.$data.PlanData.Plan.CompleteOption = this.currentProduct;
		}
	}
	countDecimals(value: number) {
		if (Math.floor(value) === value) return 0;
		return value.toString().split(".")[1].length || 0;
	}

	@Watch("PlanData.Plan.MDB")
	OnMDBChange() {
		var mbd = this.$data.PlanData.Plan.MDB;
		if (this.formatwithZeros) {
			mbd = Number(mbd.toString().replaceAll(",", ""));
			var decCount = this.countDecimals(Number(mbd));
			if (decCount <= 2) {
				mbd = mbd.toFixed(2);
			}
		}

		this.$data.MDBString = ConvertIntoAmountFormatWithDecimal(
			mbd,
			true,
			this.formatwithZeros
		);

		// if (this.currentProduct.Id == 1 && this.$data.PlanData.Plan.MDB == 83.2)

		if (this.formatwithZeros) this.formatwithZeros = false;
		var mbd = this.$data.PlanData.Plan.MDB;
		if (typeof mbd.replaceAll == "function") {
			//mbd = mbd.includes(",");
			mbd = mbd.replaceAll(",", "");
		}
		if (!this.isDailyChange) this.$data.DailyCharge = (mbd / 30).toFixed(2);

		if (!this.isAnnualChange)
			if (!this.isAnnualChange) this.$data.AnnualCharge = (mbd * 12).toFixed(2);
		if (!this.isWeeklyChange)
			this.$data.WeeklyCharge = (mbd / 4.333).toFixed(2);

		this.$emit("ChangeMBDEvent", null);
	}

	@Watch("PGName")
	OnPGNameChange(newValue: string, oldValue: string) {
		this.$data.PlanData.Plan.PGName = newValue;

		this.ShowCoverageItems =
			newValue == "WHL(R)" ||
			newValue == "LPU65" ||
			newValue == "SRGWL" ||
			newValue == "LESWL" ||
			newValue == "ADB";
	}

	@Watch("PlanData.Plan.CompleteProduct")
	OnCompleteProductChange() {
		this.$data.PGName = this.$data.PlanData.Plan.CompleteProduct.PGName;
	}

	@Watch("PlanData.Plan.CompleteProduct.PGName")
	OnCompleteProductPGNameChange() {
		this.$data.PGName = this.$data.PlanData.Plan.CompleteProduct.PGName;
	}

	@Watch("PlanData.Plan.Coverage")
	OnCoverageChange() {
		if (
			this.$data.PlanData.Plan.Coverage != undefined &&
			typeof this.$data.PlanData.Plan.Coverage == "string" &&
			this.$data.PlanData.Plan.Coverage.includes(".")
		)
			this.$data.CoverageString = ConvertIntoAmountFormatWithDecimal(
				this.$data.PlanData.Plan.Coverage
			);
		else
			try {
				this.$data.CoverageString = ConvertIntoAmountFormat(
					this.$data.PlanData.Plan.Coverage.toString()
				);
			} catch (ex: any) {
				console.log("Error on Changing Coverage" + ex);
			}
	}
	@Watch("InterDependentProductSelection")
	OnInterDependentProductSelectionChanged() {
		if (
			this.$props.InterDependentOnDifferentProduct == true &&
			this.$props.InterDependentProductSelection
		)
			this.$data.PlanData.Selected = false;
		this.CalculateMBDRate();
	}

	OnCoverageAmountChange(evt: any) {
		this.isCoverageInputChange = false;
		console.log(evt);
		this.$data.PlanData.Plan.Coverage = evt;
		this.CoverageChange();
		this.isCoverageFocus = false;
	}

	compareProduct(a: any, b: any) {
		if (a.SortOrder < b.SortOrder) return -1;
		if (a.SortOrder > b.SortOrder) return 1;
		return 0;
	}
	data() {
		return {
			optiondropdownkey: 0,
			PGName: "",
			PlanData: this.$props.Plan,
			PrioritizeDailyData: this.$props.PrioritizeDaily,
			productavailable: false,
			CoverageString: "",
			MDBString: "",
			MonthlyCharge: "",
			DailyCharge: "",
			AnnualCharge: "",
			WeeklyCharge: "",
			A71000SelectedAtDefault: this.$props.IsA71000Selected,
			A71000SelectedExcludeStates: [],
			A71000CheckedValue: false,
		};
	}

	$refs!: {
		tfCoverage: any;
		optionDropDown: any;
	};

	checkWHLProductNameInOptionsList() {
		if (this.listOfOptions == null) return;
		var isAnyWHLOption = this.listOfOptions.find((x: any) => x.Id == 1);
		if (!isAnyWHLOption) {
			return;
		}
		this.ShowCoverageItems = true;
		var CoverageValue = Number.parseFloat(this.$data.PlanData.Plan.Coverage);
		if (typeof this.$data.PlanData.Plan.Coverage == "string")
			CoverageValue = Number.parseFloat(
				this.$data.PlanData.Plan.Coverage.replaceAll(",", "")
			);
		let coverageOption: any = undefined;
		if (this.$data.PlanData.Plan.isChild) {
			var CoverageConfig = isAnyWHLOption.ChildCoverageConfig;
			coverageOption = CoverageConfig.filter(
				(x: any) =>
					CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
			);
		} else if (this.$data.PlanData.Plan.isOld) {
			var CoverageConfig = isAnyWHLOption.OldCoverageConfig;
			coverageOption = CoverageConfig.filter(
				(x: any) =>
					CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
			);
			if (coverageOption == undefined || coverageOption.length == 0) {
				coverageOption = [CoverageConfig[0]];
			}
		} else {
			var CoverageConfig = isAnyWHLOption.AdultCoverageConfig;
			coverageOption = CoverageConfig.filter(
				(x: any) =>
					CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
			);
		}
		if (
			coverageOption != undefined &&
			coverageOption.length > 0 &&
			coverageOption[0] != undefined
		) {
			this.$data.PlanData.Plan.CurrentCoverageType =
				coverageOption[0].CoverageTypeId;
			if (this.currentProduct != undefined && this.currentProduct.Id == 1)
				this.ShowCoverageItems = coverageOption[0].ShowCoverageItems;
			setTimeout(() => {
				if (
					this.currentProduct != undefined &&
					this.currentProduct.Id == isAnyWHLOption.Id
				) {
					isAnyWHLOption.PGName = coverageOption[0].PGName;
				} else {
					isAnyWHLOption.PGName = coverageOption[0].PGName;
				}
			}, 50);
		}
	}

	checkAddAnnualRateCondition() {
		var compProd = this.$data.PlanData.Plan.CompleteProduct;
		if (compProd.Id != 6) return;

		var isRC1 = this.$data.PlanData.PresentAsDefaultId == 3;

		if (isRC1) {
			if (!this.$props.IsBasicWHLSelected) {
				if (this.$props.IsRC1Selected) {
					this.$data.PlanData.Plan.AddAnnualRate = true;
				}
			} else {
				this.$data.PlanData.Plan.AddAnnualRate = false;
			}
		} else {
			if (!this.$props.IsBasicWHLSelected) {
				if (this.$props.IsRC2Selected) {
					this.$data.PlanData.Plan.AddAnnualRate = !this.$props.IsRC1Selected;
				}
			} else {
				this.$data.PlanData.Plan.AddAnnualRate = false;
			}
		}

		this.CalculateMBDRate();
	}

	populateCoverageforRC() {
		var wage = this.$data.PlanData.Plan.Wage;
		if (wage) {
			wage = Number(wage.toString().replaceAll(",", ""));
		}
		var compProd = this.$data.PlanData.Plan.CompleteProduct;
		if (wage <= 0 || wage == undefined) wage = 0;
		if (compProd.Id != 6) return;
		var monthlyWage = 0;
		switch (this.$data.PlanData.Plan.WageType.Id) {
			case 1:
				monthlyWage = wage * 8 * 5 * 4.3333;
				break;
			case 2:
				monthlyWage = wage * 4.3333;
				break;
			case 3:
				monthlyWage = wage;
				break;
			case 4:
				monthlyWage = wage / 12;
				break;
		}
		var presentAsOptionSelected = this.$data.PlanData.Plan.PresentAs;
		if (
			presentAsOptionSelected == "HOUSE PAYMENT RIDER" &&
			!this.$props.IsPrePlan
		) {
			if (this.$props.MonthlyRent)
				monthlyWage = Number(this.$props.MonthlyRent.replaceAll(",", ""));
			else monthlyWage = 0;
		}

		if (this.$data.PlanData.Plan.CompleteOption) {
			monthlyWage =
				monthlyWage * this.$data.PlanData.Plan.CompleteOption.NoOfMonths;
		} else if (this.$props.IsPrePlan) {
			monthlyWage = monthlyWage * 6;
		}

		if (
			presentAsOptionSelected == "FINAL EXPENSE" ||
			presentAsOptionSelected == "CREDIT CARD DEBT"
		) {
			if (!this.$props.IsPrePlan) {
				if (this.$props.Person.AnnualIncome)
					monthlyWage = Number(
						this.$props.Person.AnnualIncome.replaceAll(",", "")
					);
				else monthlyWage = 0;
			} else {
				monthlyWage = 20000;
			}
		}

		if (presentAsOptionSelected == "MORTGAGE PROTECTION") {
			if (!this.$props.IsPrePlan) {
				var own =
					this.$props.Own &&
					this.$props.OwnAmount &&
					Number(this.$props.OwnAmount.toString().replaceAll(",", "")) > 0;
				if (own)
					monthlyWage = Number(
						this.$props.OwnAmount.toString().replaceAll(",", "")
					);
				else monthlyWage = 0;
			} else {
				monthlyWage = 20000;
			}
		}

		if (compProd.MinCoverage && monthlyWage < compProd.MinCoverage) {
			monthlyWage = compProd.MinCoverage;
		}

		setTimeout(() => {
			this.$data.PlanData.Plan.Coverage = RoundUpper(monthlyWage);
			this.CalculateMBDRate();
		}, 0);
	}

	loadCoverageForADB() {
		var compProd = this.$data.PlanData.Plan.CompleteProduct;
		if (compProd.Id != 14) return;
		var monthlyWage = 0;
		var presentAsOptionSelected = this.$data.PlanData.Plan.PresentAs;
		if (
			presentAsOptionSelected == "MONTHLY INCOME" ||
			presentAsOptionSelected == "HOUSE PAYMENT RIDER"
		) {
			if (!this.$props.IsPrePlan) {
				if (this.$props.Person.AnnualIncome)
					monthlyWage = Number(
						this.$props.Person.AnnualIncome.replaceAll(",", "")
					);
				else monthlyWage = 0;
			} else {
				monthlyWage = 20000;
			}
		} else {
			return;
		}

		if (compProd.MinCoverage && monthlyWage < compProd.MinCoverage) {
			monthlyWage = compProd.MinCoverage;
		}

		setTimeout(() => {
			this.$data.PlanData.Plan.Coverage = RoundUpper(monthlyWage);
			this.CalculateMBDRate();
		}, 0);
	}

	get showAnnualRate() {
		return (
			this.$data.PlanData.Plan.AddAnnualRate && this.$data.PlanData.Selected
		);
	}
	ClientSelectionChange(CurrentProduct: any) {
		if (CurrentProduct.Plan?.CompleteProduct?.Id == 17) {
			this.$data.A71000SelectedAtDefault = false;
			this.$data.A71000CheckedValue = this.$data.PlanData.Selected;
		}
	}

	changePresentAs() {
		var selPresValue = this.$data.PlanData.Plan.PresentAsOptions.find(
			(x: any) => x.Name == this.$data.PlanData.Plan.PresentAs
		);
		if (selPresValue != undefined && selPresValue.DisableDuration == true)
			this.DisableOptionField = true;
		else this.DisableOptionField = false;
		this.populateCoverageforRC();
		this.loadCoverageForADB();
	}

	ChangeOptionList() {
		if (this.$data.PlanData.Plan.CompleteProduct != undefined) {
			if (this.$data.PlanData.IsWHL) {
				this.loadWHLOptions();
				return;
			}
			if (this.$data.PlanData.Plan.TobaccoUse == true) {
				this.listOfOptions =
					this.$data.PlanData.Plan.CompleteProduct.TU.Option.filter(
						(x: any) =>
							this.$data.PlanData.Plan.Age >= x.MinAge &&
							this.$data.PlanData.Plan.Age <= x.MaxAge &&
							(this.$data.PlanData.Plan.State == undefined ||
								x.ExcludeStates == undefined ||
								x.ExcludeStates.length == 0 ||
								!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
							(this.$data.PlanData.Plan.State == undefined ||
								x.IncludedStates == undefined ||
								x.IncludedStates.length == 0 ||
								x.IncludedStates.includes(this.$data.PlanData.Plan.State)) &&
							(x.DependOnChildren == undefined ||
								x.DependOnChildren == false ||
								x.DependOnChildren == this.$data.PlanData.Plan.hasChildren) &&
							(x.DependOnSpouse == undefined ||
								x.DependOnSpouse == false ||
								x.DependOnSpouse == this.$props.HasSpouse) &&
							(x.ShowOnSpouse == undefined ||
								(!this.$data.PlanData.IsSpouse && x.ShowOnSpouse == false) ||
								(this.$data.PlanData.IsSpouse && x.ShowOnSpouse == true)) &&
							(x.DependOnChildrenOrSpouse == undefined ||
								x.DependOnChildrenOrSpouse == false ||
								((this.$props.HasSpouse ||
									this.$data.PlanData.Plan.hasChildren) &&
									x.DependOnChildrenOrSpouse == true))
					);
			} else {
				this.listOfOptions =
					this.$data.PlanData.Plan.CompleteProduct.NTU.Option.filter(
						(x: any) =>
							this.$data.PlanData.Plan.Age >= x.MinAge &&
							this.$data.PlanData.Plan.Age <= x.MaxAge &&
							(this.$data.PlanData.Plan.State == undefined ||
								x.ExcludeStates == undefined ||
								x.ExcludeStates.length == 0 ||
								!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
							(this.$data.PlanData.Plan.State == undefined ||
								x.IncludedStates == undefined ||
								x.IncludedStates.length == 0 ||
								x.IncludedStates.includes(this.$data.PlanData.Plan.State)) &&
							(x.DependOnChildren == undefined ||
								x.DependOnChildren == false ||
								x.DependOnChildren == this.$data.PlanData.Plan.hasChildren) &&
							(x.DependOnSpouse == undefined ||
								x.DependOnSpouse == false ||
								x.DependOnSpouse == this.$props.HasSpouse) &&
							(x.ShowOnSpouse == undefined ||
								(!this.$data.PlanData.IsSpouse && x.ShowOnSpouse == false) ||
								(this.$data.PlanData.IsSpouse && x.ShowOnSpouse == true)) &&
							(x.DependOnChildrenOrSpouse == undefined ||
								x.DependOnChildrenOrSpouse == false ||
								((this.$props.HasSpouse ||
									this.$data.PlanData.Plan.hasChildren) &&
									x.DependOnChildrenOrSpouse == true))
					);
			}
			if (
				this.listOfOptions != undefined &&
				this.listOfOptions.length > 0 &&
				this.listOfOptions[0] != undefined
			) {
				if (this.$data.PlanData.Plan.CompleteProduct.AgeDependOnState == true) {
					var removeOptions: any = [];
					this.listOfOptions.forEach((optionelement: any) => {
						if (optionelement.AgeDependOnState) {
							var age = Number.parseInt(this.$data.PlanData.Plan.Age);
							var selectedState = optionelement.StateAge.filter(
								(x: any) =>
									x.State == this.$data.PlanData.Plan.State &&
									(age < x.MinAge || age > x.MaxAge)
							);
							if (selectedState != undefined && selectedState.length > 0) {
								removeOptions.push(optionelement);
							}
						}
					});

					if (removeOptions.length > 0) {
						removeOptions.forEach((element: any) => {
							const index = this.listOfOptions.indexOf(element);
							if (index > -1) {
								this.listOfOptions.splice(index, 1);
							}
						});
					}
				}

				if (
					this.$data.PlanData.Plan.CompleteOption == undefined ||
					this.$data.PlanData.Plan.CompleteProduct.ChangeOptionOnSelection ||
					this.listOfOptions.find(
						(x: any) => x.Name == this.$data.PlanData.Plan.CompleteOption.Name
					) == undefined
				) {
					var selOpt: any = undefined;
					if (
						this.$data.PlanData.Plan.CompleteProduct.ShowLastItem == true &&
						this.$data.PlanData.Plan.CompleteOption != undefined
					) {
						selOpt = this.listOfOptions[this.listOfOptions.length - 1];
					} else {
						if (
							this.$data.PlanData.Plan.CompleteProduct.Id == 6 ||
							this.$data.PlanData.Plan.CompleteProduct.Id == 14
						) {
							selOpt = this.listOfOptions[1];
						} else {
							selOpt = this.listOfOptions[0];
						}
					}
					this.$data.PlanData.Plan.CompleteOption = selOpt;
					this.populateCoverageforRC();
					if (
						this.$data.PlanData.Plan.CompleteProduct.CoverageDependOnOption ==
						true
					) {
						if (
							this.$data.PlanData.Plan.CompleteOption != undefined &&
							this.$data.PlanData.Plan.CompleteOption.Coverage != undefined
						) {
							this.$data.PlanData.Plan.Coverage =
								this.$data.PlanData.Plan.CompleteOption.Coverage;
							this.OnCoverageChange();
						}
					}
				}

				this.CalculateMBDRate();
			}
		} else {
			this.$data.PlanData.Plan.CompleteOption = undefined;
		}
	}

	loadWHLOptions(checkAutoSelect: boolean = true) {
		this.listOfOptions = null;
		this.listOfOptions = JSON.parse(
			JSON.stringify(
				this.listofProducts.filter(
					(x: any) => x.IsWHL == true && x.Id != this.currentProduct.Id
				)
			)
		);

		var isAnyAutoSelect =
			this.listofProducts.find(
				(x: any) => x.IsWHL == true && x.AutoSelect == true
			) != null && checkAutoSelect;

		if (isAnyAutoSelect) {
			this.currentProduct = this.listofProducts.find(
				(x: any) => x.IsWHL == true && x.AutoSelect == true
			);
			this.$data.PGName = this.currentProduct.PGName;
			this.listOfOptions = JSON.parse(
				JSON.stringify(
					this.listofProducts.filter(
						(x: any) => x.IsWHL == true && x.Id != this.currentProduct.Id
					)
				)
			);

			if (this.currentProduct.ShowAutoCompleteDropdown) {
				this.ListOfCoverageItems = this.currentProduct.CoveregaItems;

				this.FilterCoverageAutoCompleteList();
			}
		}

		var isCurrentProductExist =
			this.listofProducts.filter(
				(x: any) => x.IsWHL == true && x.Id == this.currentProduct.Id
			).length > 0;

		if (
			!isCurrentProductExist &&
			this.listOfOptions != null &&
			this.listOfOptions.length > 0
		) {
			this.currentProduct = this.listOfOptions[0];
			this.$data.PGName = this.currentProduct.PGName;
			this.listOfOptions = JSON.parse(
				JSON.stringify(
					this.listofProducts.filter(
						(x: any) => x.IsWHL == true && x.Id != this.currentProduct.Id
					)
				)
			);
		}

		var isAnyWHLProductRemoved = false;
		this.listOfOptions.forEach((elementProd: any) => {
			var removeOptions: any = [];
			if (elementProd.StateSpecificAgeExclude == true) {
				var age = Number.parseInt(this.$data.PlanData.Plan.Age);
				var selectedState = elementProd.StateAgeExcludeLimits.filter(
					(x: any) =>
						x.State == this.$data.PlanData.Plan.State &&
						age >= x.MinAge &&
						age <= x.MaxAge
				);

				if (selectedState != undefined && selectedState.length > 0) {
					removeOptions.push(elementProd);
				}
			}
			if (removeOptions.length > 0) {
				removeOptions.forEach((element: any) => {
					const index = this.listOfOptions.indexOf(element);
					if (index > -1) {
						this.listOfOptions.splice(index, 1);
					}
				});
				this.DisableOptionField = true;
				isAnyWHLProductRemoved = true;
			} else {
				this.DisableOptionField = false;
			}
		});

		if (isAnyWHLProductRemoved == false) {
			if (
				this.currentProduct != undefined &&
				this.currentProduct.StateSpecificAgeExclude == true
			) {
				var age = Number.parseInt(this.$data.PlanData.Plan.Age);
				var selectedState = this.currentProduct.StateAgeExcludeLimits.filter(
					(x: any) =>
						x.State == this.$data.PlanData.Plan.State &&
						age >= x.MinAge &&
						age <= x.MaxAge
				);
				if (
					selectedState != undefined &&
					selectedState.length > 0 &&
					this.listOfOptions != undefined &&
					this.listOfOptions.length > 0
				) {
					this.currentProduct = this.listOfOptions[0];

					//this.$data.PlanData.Plan.CompleteProduct = this.listOfOptions[0];

					//this.$data.PGName = this.currentProduct.PGName;
					//this.DisableOptionField = true;
					//this.loadWHLOptions(true);
					if (this.listOfOptions.length == 1) {
						//setTimeout(() =>
						this.listOfOptions = [];
						this.DisableOptionField = true;
					}
					//  , 50);
				} else if (selectedState != undefined && selectedState.length > 0) {
					this.$data.productavailable = false;
					this.$data.PlanData.Selected = false;
					this.$data.PlanData.ProductAvailable = this.$data.productavailable;
				}
				//  else if (
				//   this.currentProduct != undefined &&
				//   this.listOfOptions == undefined &&
				//   this.listOfOptions.length == 0
				// ) {
				//   this.$data.productavailable = false;
				// }
			}
		}
		this.CheckDefaultCoverage(false, isAnyAutoSelect);
	}

	CoverageOptionChange() {
		var loadOptionId =
			this.$data.PlanData.Plan.CompleteCoverageOption.LoadOptionId;
		var currentOptions = JSON.parse(JSON.stringify(this.listOfOptions));
		if (
			loadOptionId &&
			loadOptionId != this.$data.PlanData.Plan.CompleteOption.Id
		) {
			this.listOfOptions = null;
			setTimeout(() => {
				this.listOfOptions = currentOptions;
				var selOpt: any = this.listOfOptions.find(
					(x: any) => x.Id == loadOptionId
				);
				this.$data.PlanData.Plan.CompleteOption = selOpt;
				this.OptionChange();
				this.CalculateMBDRate();
			}, 10);
		} else {
			this.CalculateMBDRate();
		}

		this.CalculateMBDRate();
	}

	ChangeCoverageOptionList() {
		if (
			this.$data.PlanData.Plan.CompleteProduct != undefined &&
			this.$data.PlanData.Plan.CompleteProduct.CoverageDropdownOptions
		) {
			this.listOfCoverageOptions =
				this.$data.PlanData.Plan.CompleteProduct.CoverageDropdownOptions.filter(
					(x: any) =>
						((x.MinAge == undefined && x.MinAge == undefined) ||
							(this.$data.PlanData.Plan.Age >= x.MinAge &&
								this.$data.PlanData.Plan.Age <= x.MaxAge)) &&
						(this.$data.PlanData.Plan.State == undefined ||
							x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
						(this.$data.PlanData.Plan.State == undefined ||
							x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.$data.PlanData.Plan.State))
				);
			if (
				this.listOfCoverageOptions != undefined &&
				this.listOfCoverageOptions.length > 0 &&
				this.listOfCoverageOptions[0] != undefined
			) {
				if (this.$data.PlanData.Plan.CompleteProduct.AgeDependOnState == true) {
					var removeOptions: any = [];
					this.listOfCoverageOptions.forEach((optionelement: any) => {
						if (optionelement.AgeDependOnState) {
							var age = Number.parseInt(this.$data.PlanData.Plan.Age);
							var selectedState = optionelement.StateAge.filter(
								(x: any) =>
									x.State == this.$data.PlanData.Plan.State &&
									(age < x.MinAge || age > x.MaxAge)
							);
							if (selectedState != undefined && selectedState.length > 0) {
								removeOptions.push(optionelement);
							}
						}
					});

					if (removeOptions.length > 0) {
						removeOptions.forEach((element: any) => {
							const index = this.listOfOptions.indexOf(element);
							if (index > -1) {
								this.listOfOptions.splice(index, 1);
							}
						});
					}
				}

				if (
					this.$data.PlanData.Plan.CompleteCoverageOption == undefined ||
					this.$data.PlanData.Plan.CompleteProduct.ChangeOptionOnSelection ||
					this.listOfCoverageOptions.find(
						(x: any) =>
							x.Name == this.$data.PlanData.Plan.CompleteCoverageOption.Name
					) == undefined
				) {
					var selOpt: any = this.listOfCoverageOptions[0];
					this.$data.PlanData.Plan.CompleteCoverageOption = selOpt;
				}

				this.CalculateMBDRate();
			}
		} else {
			this.$data.PlanData.Plan.CompleteCoverageOption = undefined;
		}
	}

	CalculateMBDRate() {
		if (this.$data.PlanData.IsWHL) {
			this.CalculateMDBRateforWHL();
			return;
		}

		if (
			this.$data.PlanData.Plan.CompleteProduct != undefined &&
			this.$data.PlanData.Plan.CompleteProduct != undefined &&
			this.$data.PlanData.Plan.CompleteOption != undefined &&
			this.$data.PlanData.Plan.Gender != undefined &&
			this.$data.PlanData.Plan.Gender != ""
		) {
			var optionTypeId = 0;
			if (!this.$data.PlanData.Plan.CompleteProduct.MBDDependOnOption) {
				if (this.$data.PlanData.Plan.TobaccoUse)
					optionTypeId = this.$data.PlanData.Plan.CompleteProduct.TU.OptionId;
				else
					optionTypeId = this.$data.PlanData.Plan.CompleteProduct.NTU.OptionId;
			}

			if (
				this.$data.PlanData.Plan.CompleteProduct.showCoverageDropdown &&
				this.$data.PlanData.Plan.CompleteCoverageOption
			) {
				this.$data.PlanData.Plan.CurrentCoverageType =
					this.$data.PlanData.Plan.CompleteCoverageOption.Id;
			}

			var age: any = this.getAgeAsPerProduct();

			var specificList = this.RateList.filter(
				(x: any) =>
					(x.Age == age ||
						(x.AgeRange && x.AgeRange.includes(parseInt(age)))) &&
					x.ProductListId == this.$data.PlanData.Plan.CompleteProduct.Id &&
					(x.OptionId == optionTypeId ||
						x.OptionId == this.$data.PlanData.Plan.CompleteOption.Id) &&
					(this.$data.PlanData.Plan.CompleteOption.DependOnCoverageAmount ==
						false ||
						x.CoverageTypeId == this.$data.PlanData.Plan.CurrentCoverageType) &&
					this.$data.PlanData.Plan.State != undefined &&
					(x.State == this.$data.PlanData.Plan.State ||
						(x.StateList &&
							x.StateList.includes(this.$data.PlanData.Plan.State)))
			);

			if (specificList == undefined || specificList.length == 0) {
				var specificList = this.RateList.filter(
					(x: any) =>
						(x.Age == age ||
							(x.AgeRange && x.AgeRange.includes(parseInt(age)))) &&
						x.ProductListId == this.$data.PlanData.Plan.CompleteProduct.Id &&
						(x.OptionId == optionTypeId ||
							x.OptionId == this.$data.PlanData.Plan.CompleteOption.Id) &&
						(this.$data.PlanData.Plan.CompleteOption.DependOnCoverageAmount ==
							false ||
							x.CoverageTypeId ==
								this.$data.PlanData.Plan.CurrentCoverageType) &&
						x.State == ""
				);
			}
			if (specificList != undefined && specificList.length > 0) {
				let coverage: string = "";
				if (
					this.$data.PlanData.Plan.Coverage != undefined &&
					typeof this.$data.PlanData.Plan.Coverage == "string"
				)
					coverage = this.$data.PlanData.Plan.Coverage.replaceAll(",", "");
				else coverage = this.$data.PlanData.Plan.Coverage;
				if (this.$data.PlanData.Plan.CompleteProduct.StaticMBDRate == true) {
					if (this.$data.PlanData.Plan.Gender == "MALE") {
						this.$data.PlanData.Plan.MDB = specificList[0].MaleRate.toString();
						this.OnMDBChange();
						this.formatwithZeros = true;
					} else {
						this.$data.PlanData.Plan.MDB =
							specificList[0].FemaleRate.toString();
						this.OnMDBChange();
						this.formatwithZeros = true;
					}
				} else {
					if (this.$data.PlanData.Plan.Gender == "MALE") {
						this.$data.PlanData.Plan.MDB = CalculateMBD(
							Number.parseFloat(coverage),
							this.$data.PlanData.Plan.AddAnnualRate
								? this.$data.PlanData.Plan.CompleteProduct.AnnualRate
								: 0,
							specificList[0].MaleRate
						).toString();

						// if (
						//   this.currentProduct.Id == 1 &&
						//   this.$data.PlanData.Plan.MDB == 83.2
						// )

						this.OnMDBChange();
						this.formatwithZeros = true;
					} else {
						this.$data.PlanData.Plan.MDB = CalculateMBD(
							Number.parseFloat(coverage),
							this.$data.PlanData.Plan.AddAnnualRate
								? this.$data.PlanData.Plan.CompleteProduct.AnnualRate
								: 0,
							specificList[0].FemaleRate
						).toString();
						this.OnMDBChange();
						this.formatwithZeros = true;
					}
				}
			} else {
				this.$data.PlanData.Plan.MDB = "0.00";
				this.OnMDBChange();
				this.formatwithZeros = true;
			}
		}
	}

	getAgeAsPerProduct() {
		if (
			this.$data.PlanData.ProductId != 19 ||
			this.$data.PlanData.IsSpouse ||
			(this.$data.PlanData.Plan.CompleteOption.Id != 2 &&
				this.$data.PlanData.Plan.CompleteOption.Id != 4)
		)
			return this.$data.PlanData.Plan.Age;

		var age = 0;

		if (
			(this.$props.Gender == "MALE" && this.$props.SpouseGender == "MALE") ||
			(this.$props.Gender == "MALE" && this.$props.SpouseGender == "FEMALE") ||
			(this.$props.Gender == "FEMALE" && this.$props.SpouseGender == "FEMALE")
		) {
			return this.$props.Age;
		}

		if (this.$props.Gender == "FEMALE" && this.$props.SpouseGender == "MALE") {
			return this.$props.SpouseAge;
		}

		return this.$props.Age;
	}

	created() {
		if (!this.$props.AllProducts) return;
		this.loadCreated();
	}

	loadCreated() {
		this.listofProducts = JSON.parse(JSON.stringify(this.$props.AllProducts))
			.filter(
				(x: any) =>
					x.ShowInRateBookOnly == undefined || x.ShowInRateBookOnly == false
			)
			.sort(this.compareProduct);

		this.listofAllProduct = JSON.parse(JSON.stringify(this.$props.AllProducts))
			.filter(
				(x: any) =>
					x.ShowInRateBookOnly == undefined || x.ShowInRateBookOnly == false
			)
			.sort(this.compareProduct);
		this.RateList = this.$props.RatesList;
		this.allStates = this.$props.StatesList;
		this.loadProduct(this.$data.PlanData.ProductId);
		// setTimeout(() => {
		this.populateCoverageforRC();
		// }, 0);

		setTimeout(() => {
			if (this.$data.PlanData.CurrentProduct && this.$data.PlanData.IsWHL) {
				this.$data.PlanData.Plan.CurrentProduct =
					this.$data.PlanData.CurrentProduct;
				this.$data.currentProduct = this.$data.PlanData.CurrentProduct;
				this.loadWHLOptions(false);
				setTimeout(() => {
					this.$data.PGName = this.$data.currentProduct.PGName;
				}, 0);
			}

			if (this.$data.PlanData.Option) {
				if (
					(!this.$data.PlanData.Option.DependOnSpouse ||
						this.$data.PlanData.Option.DependOnSpouse ==
							this.$props.HasSpouse) &&
					(!this.$data.PlanData.Option.DependOnChildren ||
						this.$data.PlanData.Option.DependOnChildren ==
							this.$data.PlanData.Plan.hasChildren)
				) {
					if (typeof this.$data.PlanData.Option == "string") {
						this.$data.PlanData.Plan.CompleteOption = this.listOfOptions.find(
							(x: any) => x.Name == this.$data.PlanData.Option
						);
					} else {
						this.$data.PlanData.Plan.CompleteOption =
							this.$data.PlanData.Option;
					}
				}
				this.$data.optiondropdownkey++;
			}

			if (this.$data.PlanData.CoverageOption) {
				this.$data.PlanData.Plan.CompleteCoverageOption =
					this.$data.PlanData.CoverageOption;
			}

			if (this.$data.PlanData.Coverage) {
				this.$data.PlanData.Plan.Coverage = this.$data.PlanData.Coverage;
			}

			if (this.$data.PlanData.MBD) {
				this.$data.PlanData.Plan.MDB = this.$data.PlanData.MBD;
				this.CalculateMBDRate();
				this.formatwithZeros = true;
			}
			if (
				this.$data.PlanData.PresentAs &&
				this.$data.PlanData.Plan.PresentAsOptions != undefined
			) {
				var contains = this.$data.PlanData.Plan.PresentAsOptions.find(
					(x: any) => x.Name == this.$data.PlanData.PresentAs
				);
				if (contains)
					this.$data.PlanData.Plan.PresentAs = this.$data.PlanData.PresentAs;
				else {
					this.$data.PlanData.Selected = false;
				}

				var selPresValue = this.$data.PlanData.Plan.PresentAsOptions.find(
					(x: any) => x.Name == this.$data.PlanData.Plan.PresentAs
				);
				if (selPresValue != undefined && selPresValue.DisableDuration == true)
					this.DisableOptionField = true;
				else this.DisableOptionField = false;
			}

			if (
				this.$data.PlanData.Plan.CompleteProduct != undefined &&
				this.$data.PlanData.Plan.CompleteProduct.Id == 17 &&
				this.$data.PlanData.Plan.CompleteOption != undefined &&
				this.listOfOptions != undefined &&
				this.listOfOptions.length > 0 &&
				this.listOfOptions.find(
					(x: any) =>
						x.Name == this.$data.PlanData.Plan.CompleteOption.Name &&
						x.Id == this.$data.PlanData.Plan.CompleteOption.Id
				) == undefined
			) {
				this.$data.PlanData.Plan.CompleteOption = this.listOfOptions[0];
				this.OptionChange();
			}

			setTimeout(() => {
				if (this.$data.PlanData.PGName) {
					this.$data.PGName = this.$data.PlanData.PGName;
				}
				if (this.$data.PlanData.IsWHL) {
					this.CheckDefaultCoverage();
				}
			}, 10);

			this.checkAddAnnualRateCondition();
		}, 0);
	}

	getOptionForWHLProduct() {
		var selectedOption: any = {};
		if (this.currentProduct != undefined) {
			var lstOptions: any;
			if (this.$data.PlanData.Plan.TobaccoUse == true) {
				lstOptions = this.currentProduct.TU.Option.filter(
					(x: any) =>
						this.$data.PlanData.Plan.Age >= x.MinAge &&
						this.$data.PlanData.Plan.Age <= x.MaxAge &&
						(this.$data.PlanData.Plan.State == undefined ||
							x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
						(this.$data.PlanData.Plan.State == undefined ||
							x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.$data.PlanData.Plan.State))
				);
			} else {
				lstOptions = this.currentProduct.NTU.Option.filter(
					(x: any) =>
						this.$data.PlanData.Plan.Age >= x.MinAge &&
						this.$data.PlanData.Plan.Age <= x.MaxAge &&
						(this.$data.PlanData.Plan.State == undefined ||
							x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
						(this.$data.PlanData.Plan.State == undefined ||
							x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.$data.PlanData.Plan.State))
				);
			}
			if (
				lstOptions != undefined &&
				lstOptions.length > 0 &&
				lstOptions[0] != undefined
			) {
				if (this.currentProduct.AgeDependOnState == true) {
					var removeOptions: any = [];
					lstOptions.forEach((optionelement: any) => {
						if (optionelement.AgeDependOnState) {
							var age = Number.parseInt(this.$data.PlanData.Plan.Age);
							var selectedState = optionelement.StateAge.filter(
								(x: any) =>
									x.State == this.$data.PlanData.Plan.State &&
									(age < x.MinAge || age > x.MaxAge)
							);
							if (selectedState != undefined && selectedState.length > 0) {
								removeOptions.push(optionelement);
							}
						}
					});

					if (removeOptions.length > 0) {
						removeOptions.forEach((element: any) => {
							const index = lstOptions.indexOf(element);
							if (index > -1) {
								lstOptions.splice(index, 1);
							}
						});
					}
				}

				if (
					this.$data.PlanData.Plan.CompleteOption == undefined ||
					this.currentProduct.ChangeOptionOnSelection ||
					lstOptions.find(
						(x: any) => x.Name == this.$data.PlanData.Plan.CompleteOption.Name
					) == undefined
				) {
					var selOpt: any = lstOptions[0];
					selectedOption = selOpt;
				}
			}
		} else {
			selectedOption = undefined;
		}

		return selectedOption;
	}

	CalculateMDBRateforWHL() {
		if (
			this.currentProduct != undefined &&
			this.currentProduct != undefined &&
			this.$data.PlanData.Plan.Gender != undefined &&
			this.$data.PlanData.Plan.Gender != ""
		) {
			var option: any = this.getOptionForWHLProduct();
			var optionTypeId = 0;
			if (!this.currentProduct.MBDDependOnOption) {
				if (this.$data.PlanData.Plan.TobaccoUse)
					optionTypeId = this.currentProduct.TU.OptionId;
				else optionTypeId = this.currentProduct.NTU.OptionId;
			}

			if (this.$data.CoverageString != this.$data.PlanData.Plan.Coverage)
				this.CoverageChange(false);

			if (
				this.currentProduct.showCoverageDropdown &&
				this.$data.PlanData.Plan.CompleteCoverageOption
			) {
				this.$data.PlanData.Plan.CurrentCoverageType =
					this.$data.PlanData.Plan.CompleteCoverageOption.Id;
			}

			var specificList = this.RateList.filter(
				(x: any) =>
					(x.Age == this.$data.PlanData.Plan.Age ||
						(x.AgeRange &&
							x.AgeRange.includes(parseInt(this.$data.PlanData.Plan.Age)))) &&
					x.ProductListId == this.currentProduct.Id &&
					(x.OptionId == optionTypeId || x.OptionId == option.Id) &&
					(option.DependOnCoverageAmount == false ||
						x.CoverageTypeId == this.$data.PlanData.Plan.CurrentCoverageType) &&
					(x.State == this.$data.PlanData.Plan.State ||
						(x.StateList &&
							x.StateList.includes(this.$data.PlanData.Plan.State)))
			);

			if (specificList == undefined || specificList.length == 0) {
				var specificList = this.RateList.filter(
					(x: any) =>
						(x.Age == this.$data.PlanData.Plan.Age ||
							(x.AgeRange &&
								x.AgeRange.includes(parseInt(this.$data.PlanData.Plan.Age)))) &&
						x.ProductListId == this.currentProduct.Id &&
						(x.OptionId == optionTypeId || x.OptionId == option.Id) &&
						(option.DependOnCoverageAmount == false ||
							x.CoverageTypeId ==
								this.$data.PlanData.Plan.CurrentCoverageType) &&
						x.State == ""
				);
			}

			if (specificList != undefined && specificList.length > 0) {
				let coverage: string = "";
				if (
					this.$data.PlanData.Plan.Coverage != undefined &&
					typeof this.$data.PlanData.Plan.Coverage == "string"
				)
					coverage = this.$data.PlanData.Plan.Coverage.replace(",", "");
				else coverage = this.$data.PlanData.Plan.Coverage;
				if (this.currentProduct.StaticMBDRate == true) {
					if (this.$data.PlanData.Plan.Gender == "MALE") {
						this.$data.PlanData.Plan.MDB = specificList[0].MaleRate.toString();

						this.OnMDBChange();
						this.formatwithZeros = true;
					} else {
						this.$data.PlanData.Plan.MDB =
							specificList[0].FemaleRate.toString();
						this.OnMDBChange();
						this.formatwithZeros = true;
					}
				} else {
					if (this.$data.PlanData.Plan.Gender == "MALE") {
						this.$data.PlanData.Plan.MDB = CalculateMBD(
							Number.parseFloat(coverage),
							this.$data.PlanData.Plan.AddAnnualRate
								? this.currentProduct.AnnualRate
								: 0,
							specificList[0].MaleRate
						).toString();
						// if (
						//   this.currentProduct.Id == 1 &&
						//   this.$data.PlanData.Plan.MDB == 83.2
						// )

						this.OnMDBChange();
						this.formatwithZeros = true;
					} else {
						this.$data.PlanData.Plan.MDB = CalculateMBD(
							Number.parseFloat(coverage),
							this.$data.PlanData.Plan.AddAnnualRate
								? this.currentProduct.AnnualRate
								: 0,
							specificList[0].FemaleRate
						).toString();

						this.OnMDBChange();
						this.formatwithZeros = true;
					}
				}
			} else {
				this.$data.PlanData.Plan.MDB = "0.00";

				this.OnMDBChange();
				this.formatwithZeros = true;
			}
		}
	}

	loadProduct(productId: any) {
		this.$data.PlanData.Plan = new RateBookEntitity();
		this.$data.PlanData.Plan.Age = this.$props.Age;
		this.$data.PlanData.Plan.Gender = this.$props.Gender;
		this.$data.PlanData.Plan.Wage = this.$props.Wage;
		this.$data.PlanData.Plan.WageType = this.$props.WageType;
		this.$data.PlanData.Plan.TobaccoUse = this.$props.TobaccoUse;
		this.$data.PlanData.Plan.State = this.getStateCodefromName(
			this.$props.State
		);

		this.$data.PlanData.Plan.hasChildren = this.$props.HasChild;
		this.$data.PlanData.Plan.CompleteProduct = this.listofAllProduct.find(
			(elm: any) => elm.Id == productId
		);
		this.currentProduct = this.$data.PlanData.Plan.CompleteProduct;
		var isRC1 = this.$data.PlanData.PresentAsDefaultId == 3;
		this.loadPresentAsOptions(true, isRC1);
		this.checkProductAvailable(true);
		this.ChangeOptionList();
		this.ChangeCoverageOptionList();
		this.CoverageChange();
		this.CheckDefaultCoverage();
		this.CalculateMBDRate();
		this.OnInterDependentProductSelectionChanged();
	}

	loadPresentAsOptions(loadDefault: boolean = true, changeRC1: any = null) {
		var own =
			this.$props.Own &&
			this.$props.OwnAmount &&
			Number(this.$props.OwnAmount.toString().replaceAll(",", "")) > 0;
		var isRCProduct = this.$data.PlanData.ProductId == 6;
		var currentOption = this.$data.PlanData.Plan.PresentAs;
		var compProd = this.$data.PlanData.Plan.CompleteProduct;
		if (!own && compProd.PresentAsOptions) {
			compProd.PresentAsOptions = compProd.PresentAsOptions.filter(
				(x: any) => !x.IsDependentOnOWN
			);
		}

		if (compProd.PresentAsOptions) {
			if (isRCProduct) {
				var isRC1 = this.$data.PlanData.PresentAsDefaultId == 3;
				var isRC2 = this.$data.PlanData.PresentAsDefaultId == 4;
				if (isRC1 && changeRC1 == true) {
					this.$data.PlanData.Plan.PresentAsOptions =
						compProd.PresentAsOptions.filter(
							(x: any) => x.Name != this.$props.RC2PresentAs
						);
				} else if (isRC2 && changeRC1 == false) {
					this.$data.PlanData.Plan.PresentAsOptions =
						compProd.PresentAsOptions.filter(
							(x: any) => x.Name != this.$props.RC1PresentAs
						);
				}
			} else {
				this.$data.PlanData.Plan.PresentAsOptions = compProd.PresentAsOptions;
			}
			if (loadDefault == true) {
				var opt = this.$data.PlanData.Plan.PresentAsOptions.find(
					(x: any) => x.Id == this.$props.PresentAsDefaultId
				);
				if (opt) {
					this.$data.PlanData.Plan.PresentAs = opt.Name;
				}
			}
			var selPresValue = this.$data.PlanData.Plan.PresentAsOptions.find(
				(x: any) => x.Name == this.$data.PlanData.Plan.PresentAs
			);
			if (selPresValue != undefined && selPresValue.DisableDuration == true)
				this.DisableOptionField = true;
			else this.DisableOptionField = false;
		}
	}

	checkProductAvailable(onLoad: boolean) {
		var age = this.$data.PlanData.Plan.Age;
		if (age === "") age = -1;
		if (this.$data.PlanData.Plan.TobaccoUse) {
			this.listofProducts = this.listofAllProduct
				.filter(
					(x: any) =>
						x.ShowAsASeperateProduct == true &&
						age >= x.TU.AgeLimit.Min &&
						age <= x.TU.AgeLimit.Max &&
						(!x.DependOnChild ||
							x.DependOnChild == this.$data.PlanData.Plan.hasChildren) &&
						(x.DependOnSpouse == undefined ||
							x.DependOnSpouse == false ||
							x.DependOnSpouse == this.$props.HasSpouse) &&
						(x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
						(x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.$data.PlanData.Plan.State))
				)
				.sort(this.compareProduct);
		} else {
			this.listofProducts = this.listofAllProduct
				.filter(
					(x: any) =>
						x.ShowAsASeperateProduct == true &&
						age >= x.NTU.AgeLimit.Min &&
						age <= x.NTU.AgeLimit.Max &&
						(!x.DependOnChild ||
							x.DependOnChild == this.$data.PlanData.Plan.hasChildren) &&
						(x.DependOnSpouse == undefined ||
							x.DependOnSpouse == false ||
							x.DependOnSpouse == this.$props.HasSpouse) &&
						(x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.$data.PlanData.Plan.State)) &&
						(x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.$data.PlanData.Plan.State))
				)
				.sort(this.compareProduct);
		}

		if (this.listofProducts != undefined && this.listofProducts.length > 0) {
			var removeproduct: any = [];
			this.listofProducts.forEach((productelement: any) => {
				if (productelement.AgeDependOnState) {
					var age = Number.parseInt(this.$data.PlanData.Plan.Age);
					var selectedState = productelement.StateAge.filter(
						(x: any) =>
							x.State == this.$data.PlanData.Plan.State &&
							(age < x.MinAge || age > x.MaxAge)
					);
					if (selectedState != undefined && selectedState.length > 0) {
						removeproduct.push(productelement);
					}
				}

				// if (productelement.StateSpecificAgeExclude == true) {

				//   var age = Number.parseInt(this.$data.PlanData.Plan.Age);
				//   var selectedState = productelement.StateAgeExcludeLimits.filter(
				//     (x: any) =>
				//       x.State == this.$data.PlanData.Plan.State &&
				//       age >= x.MinAge &&
				//       age <= x.MaxAge
				//   );
				//   if (selectedState != undefined && selectedState.length > 0) {
				//     removeproduct.push(productelement);
				//   }
				// }
			});

			if (removeproduct.length > 0) {
				removeproduct.forEach((element: any) => {
					const index = this.listofProducts.indexOf(element);
					if (index > -1) {
						this.listofProducts.splice(index, 1);
					}
				});
			}
		}

		var previousValue = JSON.parse(JSON.stringify(this.$data.productavailable));

		this.$data.productavailable =
			this.listofProducts.find(
				(x: any) => x.Id == this.$data.PlanData.Plan.CompleteProduct.Id
			) != null;

		if (
			this.$data.productavailable &&
			this.$data.PlanData.Plan.CompleteProduct.availableOnlyIfRCorWHLIsSelected
		) {
			this.$data.productavailable =
				this.$props.IsWHLSelected ||
				this.$props.IsBasicWHLSelected ||
				this.$props.IsRC1Selected ||
				this.$props.IsRC2Selected;
		}
		if (
			(!this.$data.productavailable || this.$data.productavailable == false) &&
			previousValue != this.$data.productavailable &&
			onLoad == false
		) {
			this.$data.PlanData.Selected = false;
		} else if (
			(this.$data.productavailable != undefined ||
				this.$data.productavailable == false) &&
			this.$data.PlanData.Plan.CompleteProduct.Id == 12 &&
			this.$props.HasSpouse == false
		) {
			this.$data.PlanData.Selected = false;

			if (this.$data.PlanData.Plan.CompleteProduct != undefined)
				console.log(
					"Selection false check product Id: " +
						this.$data.PlanData.Plan.CompleteProduct.Id
				);
		}

		if (this.$data.PlanData.Plan.CompleteProduct.ShowAutoCompleteDropdown) {
			this.ListOfCoverageItems = this.currentProduct.CoveregaItems;

			this.FilterCoverageAutoCompleteList();
		}

		this.ChangeOptionList();
		this.ChangeCoverageOptionList();
		this.CoverageChange();
		this.CheckDefaultCoverage();
		this.CalculateMBDRate();

		this.$data.PlanData.ProductAvailable = this.$data.productavailable;
		if (this.$data.PlanData?.Plan?.CompleteProduct?.Id == 17) {
			this.$data.A71000SelectedExcludeStates =
				this.$data.PlanData?.Plan?.CompleteProduct?.ExcludeStates;
			if (!this.$data.PlanData.ProductAvailable) {
				this.$data.PlanData.Selected = false;
			} else {
				if (
					this.$data.A71000SelectedExcludeStates.length > 0 &&
					!this.$data.A71000SelectedExcludeStates.includes(
						this.$data.PlanData.Plan.State
					)
				) {
					if (this.$data.A71000SelectedAtDefault) {
						this.$data.PlanData.Selected = true;
					} else {
						this.$data.PlanData.Selected = this.$data.A71000CheckedValue;
					}
				}
			}
		}
	}

	private ListOfCoverageItems: any = [];

	@Watch("PlanData.Coverage")
	OnCoverageChanged(newValue: number, oldValue: number) {
		if (!this.isConverageChange) {
			this.$data.Coverage = ConvertIntoAmountFormatWithDecimal(
				Number(newValue).toFixed(2)
			);
		} else {
			this.isConverageChange = false;
		}
	}

	@Watch("PlanData.MonthlyCharge")
	OnUMonthlyChanged(newValue: number, oldValue: number) {
		if (!this.isMonthlyChange) {
			this.$data.MonthlyCharge = ConvertIntoAmountFormatWithDecimal(
				newValue.toFixed(2)
			);
		} else {
			this.isMonthlyChange = false;
		}
	}

	@Watch("PlanData.DailyCharge")
	OnDailyChanged(newValue: number, oldValue: number) {
		if (!this.isDailyChange) {
			this.$data.DailyCharge = ConvertIntoAmountFormatWithDecimal(
				newValue.toFixed(2)
			);
		} else {
			this.isDailyChange = false;
		}
	}

	@Watch("PlanData.AnnualCharge")
	OnAnnualChanged(newValue: number, oldValue: number) {
		if (!this.isAnnualChange) {
			this.$data.AnnualCharge = ConvertIntoAmountFormatWithDecimal(
				newValue.toFixed(2)
			);
		} else {
			this.isAnnualChange = false;
		}
	}

	CoverageEnter(evt: any) {
		this.CheckDefaultCoverage(true);
	}

	isCoverageFocus: boolean = false;

	CoverageFocus(evt: any) {
		if (
			this.$data.PlanData.Plan.Coverage != undefined &&
			typeof this.$data.PlanData.Plan.Coverage == "string"
		)
			this.$data.PlanData.Plan.Coverage =
				this.$data.PlanData.Plan.Coverage.replaceAll(",", "");
		setTimeout(() => {
			this.isCoverageFocus = false;
		}, 50);
	}

	onPasteCoverage(evt: any) {
		var log = evt.clipboardData.getData("text/plain");
		var hasCharacter = /^[a-zA-Z()]+$/.test(log);
		var hasDot = log.includes(".");
		if (hasCharacter || hasDot) {
			evt.preventDefault();
			return false;
		} else {
			setTimeout(() => this.CoverageChangeEvent(false), 100);
			return true;
		}
	}

	private isCoverageInputChange: boolean = false;

	CoverageUnfocus(evt: any) {
		if (
			this.$data.PlanData.Plan.CompleteProduct.ShowAutoCompleteDropdown ==
				undefined ||
			!this.$data.PlanData.Plan.CompleteProduct.ShowAutoCompleteDropdown
		) {
			this.CheckDefaultCoverage();

			var value = ConvertIntoAmountFormat(this.$data.PlanData.Plan.Coverage);
			evt.target.value = value;
			this.isCoverageFocus = false;
		} else {
			setTimeout(() => {
				this.CheckDefaultCoverage();

				var value = ConvertIntoAmountFormat(this.$data.PlanData.Plan.Coverage);
				evt.target.value = value;
			}, 300);
		}
	}
	CheckDefaultCoverage(
		makeSimpleAmount: boolean = false,
		forceDefaultCoverage: boolean = false
	) {
		var covValue = null;
		if (
			this.$data.PlanData.Plan.Coverage != undefined &&
			typeof this.$data.PlanData.Plan.Coverage == "string"
		) {
			covValue = Number.parseFloat(
				this.$data.PlanData.Plan.Coverage.replaceAll(",", "")
			);
			if (isNaN(covValue)) covValue = null;
		} else {
			covValue = Number.parseFloat(this.$data.PlanData.Plan.Coverage);
			if (isNaN(covValue)) covValue = null;
		}
		var isNeedToCalculateCoverage = false;
		var numberValue: any = undefined;
		if (this.currentProduct != undefined) {
			if (this.currentProduct.MinCoverageDependOnAge == true) {
				if (
					this.$data.PlanData.Plan.isOld &&
					covValue &&
					covValue < this.currentProduct.OldCoverageConfig[0].MinCoverage
				) {
					numberValue = this.currentProduct.OldCoverageConfig[0].MinCoverage;
					isNeedToCalculateCoverage = true;
				} else if ((!covValue && covValue != 0) || forceDefaultCoverage) {
					numberValue = this.currentProduct.DefaultCoverage;
					isNeedToCalculateCoverage = true;
				} else if (
					this.currentProduct.MinCoverage &&
					covValue < Number.parseFloat(this.currentProduct.MinCoverage)
				) {
					numberValue = this.currentProduct.MinCoverage;
					isNeedToCalculateCoverage = true;
				}
			} else if ((!covValue && covValue != 0) || forceDefaultCoverage) {
				numberValue = this.currentProduct.DefaultCoverage;
				isNeedToCalculateCoverage = true;
				if (
					this.currentProduct.hasSeperateMaxCoverageAgeSpecific == true &&
					forceDefaultCoverage == false
				) {
					setTimeout(() => {
						this.CheckDefaultCoverage();
					}, 200);
				}
			} else if (this.currentProduct.hasStateSpecificMinCoverage == true) {
				if (this.currentProduct.StateSpecifiMinCoverage.length > 0) {
					var stateSpecificMinCoverage =
						this.currentProduct.StateSpecifiMinCoverage.filter((x: any) => {
							if (x.State == this.$data.PlanData.Plan.State) return x;
						});
					if (
						stateSpecificMinCoverage != undefined &&
						stateSpecificMinCoverage.length > 0
					) {
						var firstElem = stateSpecificMinCoverage[0];
						if (
							firstElem.MinCoverage &&
							covValue < Number.parseFloat(firstElem.MinCoverage)
						) {
							numberValue = firstElem.MinCoverage;
							isNeedToCalculateCoverage = true;
						}
					} else if (
						this.currentProduct.MinCoverage &&
						covValue < Number.parseFloat(this.currentProduct.MinCoverage)
					) {
						numberValue = this.currentProduct.MinCoverage;
						isNeedToCalculateCoverage = true;
					}
				} else if (
					this.currentProduct.MinCoverage &&
					covValue < Number.parseFloat(this.currentProduct.MinCoverage)
				) {
					numberValue = this.currentProduct.MinCoverage;
					isNeedToCalculateCoverage = true;
				}
			} else if (
				this.currentProduct.MinCoverage &&
				covValue < Number.parseFloat(this.currentProduct.MinCoverage) &&
				this.currentProduct.hasStateSpecificMinCoverage != true
			) {
				numberValue = this.currentProduct.MinCoverage;
				isNeedToCalculateCoverage = true;
			}

			if (
				this.currentProduct.MaxCoverage != 0 &&
				covValue &&
				covValue > this.currentProduct.MaxCoverage &&
				this.currentProduct.hasSeperateMaxCoverageAgeSpecific != true
			) {
				numberValue = this.currentProduct.MaxCoverage;
				isNeedToCalculateCoverage = true;
			} else if (
				this.currentProduct.MaxCoverage != 0 &&
				covValue &&
				this.currentProduct.hasSeperateMaxCoverageAgeSpecific == true &&
				this.currentProduct.SpecificMaxConverage != undefined
			) {
				if (
					this.currentProduct.SpecificMaxConverage != undefined &&
					this.$data.PlanData.Plan.Age >=
						this.currentProduct.SpecificMaxConverage.MinAge &&
					this.$data.PlanData.Plan.Age <=
						this.currentProduct.SpecificMaxConverage.MaxAge &&
					covValue > this.currentProduct.SpecificMaxConverage.MaxCoverage
				) {
					numberValue = this.currentProduct.SpecificMaxConverage.MaxCoverage;
					isNeedToCalculateCoverage = true;
				} else if (
					this.currentProduct.MaxCoverage != 0 &&
					covValue &&
					covValue > this.currentProduct.MaxCoverage
				) {
					numberValue = this.currentProduct.MaxCoverage;
					isNeedToCalculateCoverage = true;
				}
			}
		}

		if (isNeedToCalculateCoverage) {
			try {
				this.$data.PlanData.Plan.Coverage = numberValue.toFixed();
				this.OnCoverageChange();
			} catch {
				this.$data.PlanData.Plan.Coverage = numberValue;
				this.OnCoverageChange();
			}
			var kval: string;
			if (makeSimpleAmount) kval = this.$data.PlanData.Plan.Coverage;
			else kval = ConvertIntoAmountFormat(this.$data.PlanData.Plan.Coverage);
			this.CoverageChange();
			if (this.$refs.tfCoverage != undefined)
				this.$refs.tfCoverage.internalValue = kval;
		}
	}

	CoverageChange(calculateMBD: boolean = true) {
		if (!this.$data.PlanData.IsWHL) {
			if (calculateMBD) this.CalculateMBDRate();
			this.$emit("CoverageChangeEvt", "");
			return;
		}
		if (
			this.$data.PlanData.IsWHL &&
			this.currentProduct.Id != this.$data.PlanData.Plan.CompleteProduct.Id
		) {
			setTimeout(() => {
				this.$data.PGName = this.currentProduct.PGName;
			}, 10);
			if (calculateMBD) this.CalculateMBDRate();
			this.$emit("CoverageChangeEvt", "");

			return;
		}

		let DependedOnCovProducts = this.listofProducts.filter((x: any) => {
			if (x.ChangeProductOnCoverageChange == true) return x;
		});

		var CoverageValue = 0;
		if (typeof this.$data.PlanData.Plan.Coverage == "string")
			CoverageValue = Number.parseFloat(
				this.$data.PlanData.Plan.Coverage.replaceAll(",", "")
			);
		else CoverageValue = Number.parseFloat(this.$data.PlanData.Plan.Coverage);

		DependedOnCovProducts.forEach((element: any) => {
			let coverageOption: any = undefined;
			if (this.$data.PlanData.Plan.isChild) {
				var CoverageConfig = element.ChildCoverageConfig;
				coverageOption = CoverageConfig.filter(
					(x: any) =>
						CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
				);
			} else if (this.$data.PlanData.Plan.isOld) {
				var CoverageConfig = element.OldCoverageConfig;
				coverageOption = CoverageConfig.filter(
					(x: any) =>
						CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
				);
				if (coverageOption == undefined || coverageOption.length == 0) {
					if (CoverageValue < CoverageConfig[0].MinCoverage)
						coverageOption = [CoverageConfig[0]];
					else coverageOption = [CoverageConfig[CoverageConfig.length - 1]];
				}
			} else {
				var CoverageConfig = element.AdultCoverageConfig;
				coverageOption = CoverageConfig.filter(
					(x: any) =>
						CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
				);
			}
			if (
				coverageOption != undefined &&
				coverageOption.length > 0 &&
				coverageOption[0] != undefined
			) {
				//this.ShowCoverageItems = coverageOption[0].ShowCoverageItems;
				this.$data.PlanData.Plan.CurrentCoverageType =
					coverageOption[0].CoverageTypeId;
				setTimeout(() => {
					if (
						this.currentProduct != undefined &&
						this.currentProduct.Id == element.Id
					) {
						this.$data.PGName = coverageOption[0].PGName;
					} else {
						this.$data.PGName = coverageOption[0].PGName;
					}
				}, 50);
			}
		});

		if (calculateMBD) this.CalculateMBDRate();
		this.$emit("CoverageChangeEvt", "");
	}

	MontlyChange(value: any) {
		if (value != undefined && value != "") {
			if (typeof value != "number") value = value.replaceAll(",", "");
			this.$data.PlanData.MonthlyCharge = Number.parseFloat(value);
		} else {
			this.$data.PlanData.MonthlyCharge = 0;
		}
		this.isMonthlyChange = true;
	}

	DailyChange(value: any) {
		if (value != undefined && value != "") {
			if (typeof value != "number") value = value.replaceAll(",", "");
			this.$data.PlanData.Plan.DailyCharge = Number.parseFloat(value);
		} else {
			this.$data.PlanData.Plan.DailyCharge = 0;
		}
		this.isDailyChange = true;
		this.$data.MDBString = (this.$data.PlanData.Plan.DailyCharge * 30).toFixed(
			2
		);
		this.MBDChangeEvent();
	}

	WeeklyChange(value: any) {
		if (value != undefined && value != "") {
			if (typeof value != "number") value = value.replaceAll(",", "");
			this.$data.PlanData.Plan.WeeklyCharge = Number.parseFloat(value);
		} else {
			this.$data.PlanData.Plan.WeeklyCharge = 0;
		}
		this.isWeeklyChange = true;
		this.$data.MDBString = (
			this.$data.PlanData.Plan.WeeklyCharge * 4.333
		).toFixed(2);
		this.MBDChangeEvent();
	}

	AnnualChange(value: any) {
		if (value != undefined && value != "") {
			if (typeof value != "number") value = value.replaceAll(",", "");
			this.$data.AnnualCharge = Number.parseFloat(value);
		} else {
			this.$data.AnnualCharge = 0;
		}
		this.isAnnualChange = true;
		this.$data.MDBString = (this.$data.AnnualCharge / 12).toFixed(2);
		this.MBDChangeEvent();
	}

	OptionChange() {
		if (this.$data.PlanData.IsWHL) {
			setTimeout(() => {
				this.$data.PGName = this.$data.PlanData.Plan.CompleteOption.PGName;
				this.currentProduct = this.$data.PlanData.Plan.CompleteOption;

				this.loadWHLOptions(false);
				if (this.currentProduct.ShowAutoCompleteDropdown) {
					this.isCoverageInputChange = true;
					this.ListOfCoverageItems = this.currentProduct.CoveregaItems;
					this.FilterCoverageAutoCompleteList();
				}
				//var k = JSON.stringify(this.$data.CoverageString);
				this.OnSelectionChanged(true, false);
				this.CoverageChangeEvent(false);
				//this.checkWHLProductNameInOptionsList();
			}, 40);
			this.CalculateMBDRate();
			this.$refs.optionDropDown.$el.blur();
			this.$refs.optionDropDown.blur();

			return;
		}

		if (
			this.$data.PlanData.Plan.CompleteOption != undefined &&
			this.$data.PlanData.Plan.CompleteProduct != undefined &&
			this.$data.PlanData.Plan.CompleteProduct.CoverageDependOnOption == true
		) {
			this.$data.PlanData.Plan.Coverage =
				this.$data.PlanData.Plan.CompleteOption.Coverage;
		}

		if (
			this.$data.PlanData.Plan.CompleteProduct != undefined &&
			this.$data.PlanData.Plan.CompleteProduct.Id == 6
		) {
			this.populateCoverageforRC();
		}

		var loadOptionId =
			this.$data.PlanData.Plan.CompleteOption.LoadCoverageOptionId;
		var currentOptions: any = {};
		if (this.listOfCoverageOptions) {
			currentOptions = JSON.parse(JSON.stringify(this.listOfCoverageOptions));
		}
		if (
			loadOptionId &&
			this.$data.PlanData.Plan.CompleteCoverageOption &&
			loadOptionId != this.$data.PlanData.Plan.CompleteCoverageOption.Id
		) {
			this.listOfCoverageOptions = null;
			setTimeout(() => {
				this.listOfCoverageOptions = currentOptions;
				var selOpt: any = this.listOfCoverageOptions.find(
					(x: any) => x.Id == loadOptionId
				);
				this.$data.PlanData.Plan.CompleteCoverageOption = selOpt;
				this.CoverageOptionChange();
				this.CalculateMBDRate();
			}, 10);
		} else {
			this.CalculateMBDRate();
		}
	}

	SelectionChanged(evt: any) {
		this.$emit("ChangeMBDEvent", null);
	}

	FilterCoverageAutoCompleteList() {
		if (
			this.currentProduct.hasSeperateMaxCoverageAgeSpecific == true &&
			this.currentProduct.SpecificMaxConverage != undefined
		) {
			if (
				this.currentProduct.SpecificMaxConverage != undefined &&
				this.$data.PlanData.Plan.Age >=
					this.currentProduct.SpecificMaxConverage.MinAge &&
				this.$data.PlanData.Plan.Age <=
					this.currentProduct.SpecificMaxConverage.MaxAge
			) {
				this.ListOfCoverageItems = this.ListOfCoverageItems.filter((x: any) => {
					if (
						x.CoverageNumber <=
						this.currentProduct.SpecificMaxConverage.MaxCoverage
					)
						return x;
				});
				//var itemEmpty = this.ListOfCoverageItems((x:any) => )
				//console.log("Filter List" + this.ListOfCoverageItems);
			}
		}

		if (this.currentProduct.hasStateSpecificMinCoverage == true) {
			if (this.currentProduct.StateSpecifiMinCoverage.length > 0) {
				var stateSpecificMinCoverage =
					this.currentProduct.StateSpecifiMinCoverage.filter((x: any) => {
						if (x.State == this.$data.PlanData.Plan.State) return x;
					});
				if (
					stateSpecificMinCoverage != undefined &&
					stateSpecificMinCoverage.length > 0
				) {
					var firstElem = stateSpecificMinCoverage[0];
					this.ListOfCoverageItems = this.ListOfCoverageItems.filter(
						(x: any) => {
							//if
							return x.CoverageNumber >= firstElem.MinCoverage;
							//return x;
						}
					);
				}
			}
		}
	}

	isNumber(evt: any, variableName: string) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else if (charCode == 46 && this.$data[variableName].includes(".")) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	AmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value == "") {
			this.ApplyValuebyFieldString(fieldString, "0.00");
		} else if (value != undefined) {
			if (value.includes(".")) {
				var splitedvalue = value.split(".");
				evt.target.value = "";
				evt.target.value += splitedvalue[0] + ".";
				if (
					splitedvalue[1] != undefined &&
					splitedvalue[1] != "" &&
					splitedvalue[1].length > 1
				) {
					evt.target.value += splitedvalue[1];
				} else if (
					splitedvalue[1] != undefined &&
					splitedvalue[1].length == 1
				) {
					evt.target.value += splitedvalue[1] + "0";
				} else if (
					splitedvalue[1] != undefined &&
					splitedvalue[1].length == 0
				) {
					evt.target.value += "00";
				}
				this.ApplyValuebyFieldString(fieldString, evt.target.value);
			} else {
				this.ApplyValuebyFieldString(
					fieldString,
					this.numeral(evt.target.value).format("0,0") + ".00"
				);
			}
		}
		this.isAnnualChange = false;
		this.isWeeklyChange = false;
		this.isDailyChange = false;
		this.CheckDefaultCoverage();
	}

	ApplyValuebyFieldString(fieldString: string, value: string) {
		var properties = fieldString.split(".");
		var setPropertyvalue: any = this.$data;
		if (properties != undefined && properties.length > 0) {
			var i = 1;
			properties.forEach((element: any) => {
				if (i == properties.length) {
					setPropertyvalue[element] = value;
				} else {
					setPropertyvalue = setPropertyvalue[element];
				}
				i++;
			});
		}
	}

	CoverageChangeEvent(fromUI: boolean) {
		if (
			this.$data.CoverageString != undefined &&
			typeof this.$data.CoverageString == "string"
		)
			this.$data.PlanData.Plan.Coverage = this.$data.CoverageString.replace(
				",",
				""
			);
		else this.$data.PlanData.Plan.Coverage = this.$data.CoverageString;

		if (this.currentProduct.ShowAutoCompleteDropdown) {
			this.isCoverageInputChange = true;
			this.ListOfCoverageItems = this.currentProduct.CoveregaItems;

			this.FilterCoverageAutoCompleteList();
		}
		this.CoverageChange();
	}

	isNumberOnly(evt: any, variableName: string) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			evt.preventDefault();
		} else if (charCode == 46 && this.$data[variableName].includes(".")) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	previousString: Number = 0.0;

	public MBDChangeExt(valueMbd: string, status: string) {
		this.$data.MDBString = valueMbd;
		if (status == "negative") this.previousString = 100000;
		this.MBDChangeEvent();
		//this.OnMDBChange();
	}

	MBDChangeEvent() {
		var mbd: any = "";
		if (
			this.$data.MDBString != undefined &&
			typeof this.$data.MDBString == "string"
		)
			mbd = this.$data.MDBString.replace(",", "");
		else mbd = this.$data.MDBString;

		if (this.previousString > Number.parseFloat(this.$data.MDBString))
			this.MBDChange(false);
		else this.MBDChange(true);
		this.previousString = mbd;
	}

	onPasteAmount(evt: any) {
		var log = evt.clipboardData.getData("text/plain");
		var hasCharacter = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(log);
		if (!hasCharacter) {
			evt.preventDefault();
			return false;
		} else {
			setTimeout(() => this.MBDChangeEvent(), 100);
			return true;
		}
	}

	MBDUnfocus(evt: any) {
		this.CheckDefaultCoverage();
		var mbd = this.$data.PlanData.Plan.MDB;
		mbd = Number(mbd.toString().replaceAll(",", ""));
		var decCount = this.countDecimals(Number(mbd));
		if (decCount <= 2) {
			mbd = mbd.toFixed(2);
		}

		var value = ConvertIntoAmountFormatWithDecimal(mbd.toString(), true, true);
		evt.target.value = value;
	}

	MBDEnter(evt: any) {
		this.CheckDefaultCoverage(true);
	}

	MBDFocus(evt: any) {
		if (
			this.$data.PlanData.Plan.Coverage != undefined &&
			typeof this.$data.PlanData.Plan.Coverage == "string"
		)
			this.$data.PlanData.Plan.Coverage =
				this.$data.PlanData.Plan.Coverage.replaceAll(",", "");
	}

	MBDChange(hasIncreased: boolean) {
		var option: any = this.$data.PlanData.Plan.CompleteOption;
		if (this.$data.PlanData.IsWHL) {
			option = this.getOptionForWHLProduct();
		}

		if (
			this.currentProduct != undefined &&
			option != undefined &&
			this.$data.PlanData.Plan.Gender != undefined &&
			this.$data.PlanData.Plan.Gender != ""
		) {
			var otherCoverageTypeId = hasIncreased
				? this.$data.PlanData.Plan.CurrentCoverageType + 1
				: this.$data.PlanData.Plan.CurrentCoverageType - 1;

			var optionTypeId = 0;
			if (!this.currentProduct.MBDDependOnOption) {
				if (this.$data.PlanData.Plan.TobaccoUse)
					optionTypeId = this.currentProduct.TU.OptionId;
				else optionTypeId = this.currentProduct.NTU.OptionId;
			}
			var specificList = this.RateList.filter(
				(x: any) =>
					(x.Age == this.$data.PlanData.Plan.Age ||
						(x.AgeRange &&
							x.AgeRange.includes(parseInt(this.$data.PlanData.Plan.Age)))) &&
					x.ProductListId == this.currentProduct.Id &&
					(x.OptionId == optionTypeId || x.OptionId == option.Id) &&
					(option.DependOnCoverageAmount == false ||
						x.CoverageTypeId == this.$data.PlanData.Plan.CurrentCoverageType ||
						x.CoverageTypeId == otherCoverageTypeId) &&
					x.State == this.$data.PlanData.Plan.State
			);

			if (specificList == undefined || specificList.length == 0) {
				var specificList = this.RateList.filter(
					(x: any) =>
						(x.Age == this.$data.PlanData.Plan.Age ||
							(x.AgeRange &&
								x.AgeRange.includes(parseInt(this.$data.PlanData.Plan.Age)))) &&
						x.ProductListId == this.currentProduct.Id &&
						(x.OptionId == optionTypeId || x.OptionId == option.Id) &&
						(option.DependOnCoverageAmount == false ||
							x.CoverageTypeId ==
								this.$data.PlanData.Plan.CurrentCoverageType ||
							x.CoverageTypeId == otherCoverageTypeId) &&
						x.State == ""
				);
			}

			if (specificList != undefined && specificList.length > 0) {
				let mbd: string = "";
				if (
					this.$data.MDBString != undefined &&
					typeof this.$data.MDBString == "string"
				)
					mbd = this.$data.MDBString.replace(",", "");
				else mbd = this.$data.MDBString;

				if (option.DependOnCoverageAmount) {
					if (specificList.length > 1) {
						var maxValue =
							otherCoverageTypeId > this.$data.PlanData.Plan.CurrentCoverageType
								? otherCoverageTypeId
								: this.$data.PlanData.Plan.CurrentCoverageType;
						var breachValue: any;
						if (this.$data.PlanData.Plan.isChild)
							breachValue = this.currentProduct.ChildCoverageConfig.filter(
								(x: any) => x.CoverageTypeId == maxValue
							);
						else if (this.$data.PlanData.Plan.isOld)
							breachValue = this.currentProduct.OldCoverageConfig.filter(
								(x: any) => x.CoverageTypeId == maxValue
							);
						else
							breachValue = this.currentProduct.AdultCoverageConfig.filter(
								(x: any) => x.CoverageTypeId == maxValue
							);
						let breachNumber: number = 0;
						if (breachValue != undefined && breachValue.length > 0) {
							breachNumber = breachValue[0].MinCoverage;
							//this.ShowCoverageItems = breachValue[0].ShowCoverageItems;
						}

						if (this.$data.PlanData.Plan.Gender == "MALE")
							this.$data.PlanData.Plan.Coverage =
								CalculateCoverageFromMBDByCoverageType(
									Number.parseFloat(mbd),
									this.$data.PlanData.Plan.AddAnnualRate
										? this.currentProduct.AnnualRate
										: 0,
									specificList[0].MaleRate,
									specificList[1].MaleRate,
									breachNumber
								).toString();
						else {
							this.$data.PlanData.Plan.Coverage =
								CalculateCoverageFromMBDByCoverageType(
									Number.parseFloat(mbd),
									this.$data.PlanData.Plan.AddAnnualRate
										? this.currentProduct.AnnualRate
										: 0,
									specificList[0].FemaleRate,
									specificList[1].FemaleRate,
									breachNumber
								).toString();
						}
					} else {
						if (this.$data.PlanData.Plan.Gender == "MALE")
							this.$data.PlanData.Plan.Coverage =
								CalculateCoverageFromMBDByCoverageType(
									Number.parseFloat(mbd),
									this.$data.PlanData.Plan.AddAnnualRate
										? this.currentProduct.AnnualRate
										: 0,
									specificList[0].MaleRate,
									0,
									0
								).toString();
						else {
							this.$data.PlanData.Plan.Coverage =
								CalculateCoverageFromMBDByCoverageType(
									Number.parseFloat(mbd),
									this.$data.PlanData.Plan.AddAnnualRate
										? this.currentProduct.AnnualRate
										: 0,
									specificList[0].FemaleRate,
									0,
									0
								).toString();
						}
					}
				} else {
					if (this.$data.PlanData.Plan.Gender == "MALE") {
						this.$data.PlanData.Plan.Coverage = CalculateCoverageFromMBD(
							Number.parseFloat(mbd),
							this.$data.PlanData.Plan.AddAnnualRate
								? this.currentProduct.AnnualRate
								: 0,
							specificList[0].MaleRate
						).toString();
						this.OnCoverageChange();
					} else {
						this.$data.PlanData.Plan.Coverage = CalculateCoverageFromMBD(
							Number.parseFloat(mbd),
							this.$data.PlanData.Plan.AddAnnualRate
								? this.currentProduct.AnnualRate
								: 0,
							specificList[0].FemaleRate
						).toString();
						this.OnCoverageChange();
					}
				}

				this.$data.PlanData.Plan.MDB = mbd;

				this.OnMDBChange();
				this.CoverageChange(false);
			} else {
				this.$data.PlanData.Plan.Coverage = "0";
				this.OnCoverageChange();
			}
		}
	}
}
