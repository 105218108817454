























































































import User from "../entities/User";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AppBar from "../components/AppBar.vue";
import AppBarFooter from "../components/AppBarFooter.vue";
import {
	GetRequestWithAuthorization,
	GetRequestWithAuthorizationFromAccessToken,
} from "../helpers/APIHelper";
import { FetchUserLevelData } from "../helpers/SyncHelper";
import { ApiEndPointConstants } from "../constants/ApiEndPointConstants";
import { isLoggedIn } from "../helpers/APIExtentions";
import GenericKeyPair from "../models/GenericKeyPair";
import LoggingHelper from "../helpers/LoggingHelper";
import LoggingConstants from "../constants/LoggingConstants";
import moment from "moment";
import { setPersistedState } from "@/helpers/StateMapper";
var languages = require("../assets/pre-fill-data/language-list.json");
const Auth = namespace("Auth");

@Component({
	components: { AppBar, AppBarFooter },
})
export default class Login extends Vue {
	@Auth.Action
	private login!: (data: any) => Promise<any>;
	private user: any = { username: "", password: "" };
	private loading: boolean = false;
	private snackbar: boolean = false;
	private message: string = "";
	private valid: boolean = false;

	@Auth.Getter
	private isLoggedIn!: boolean;

	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);

		if (this.isLoggedIn) {
			this.$router.push("/home");
		}
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	data() {
		return {
			loading3: false,
			NameRules: [
				(v: any) => !!v || "Name is required",
				(v: any) => v.length <= 20 || "Name must be less than 20 characters",
			],
		};
	}
	handleLogin() {
		this.$data.loading3 = true;
		this.loading = true;
		if (this.user.username && this.user.password) {
			this.login(this.user).then(
				(data) => {
					if (data != null && data.status && window.navigator.onLine) {
						this.getCurrentUserInfo(data.Result);
						this.getCurrentUserImage(data.Result);
					} else if (data != null && data.status && !window.navigator.onLine) {
						this.$router.push("/home");
					} else if (data != null && !data.status) {
						this.message = data.Result.error_description;
						this.$data.loading3 = false;
						this.snackbar = true;
					} else {
						this.message = "Invalid Credentials";
						this.$data.loading3 = false;
						this.snackbar = true;
						this.$data.loading3 = false;
					}
					User.deleteAll();
					User.insert({
						data: {
							Name: "Test",
							Username: this.user.username,
							Password: this.user.password,
						},
					}).then(() => {
						//this.$data.loading3 = false;
					});
				},
				(error) => {
					this.loading = false;
					this.message = error;
					this.$data.loading3 = false;
				}
			);
		} else {
			if (!this.user.username && !this.user.password) {
				this.message = "Please provide username and password";
				this.snackbar = true;
			}
			if (this.user.username && !this.user.password) {
				this.message = "Please provide password";
				this.snackbar = true;
			}

			if (!this.user.username && this.user.password) {
				this.message = "Please provide username";
				this.snackbar = true;
			}
			this.$data.loading3 = false;
		}
	}

	getCurrentUserInfo(accessInfo: any) {
		// this.login(this.user).then((data) => {});

		let AuthorizationToken: string = "";
		if (accessInfo != undefined) AuthorizationToken = accessInfo.access_token;
		GetRequestWithAuthorizationFromAccessToken(
			ApiEndPointConstants.HpProCurrentUserInfoEndpoint,
			[],
			AuthorizationToken
		)
			.then((res) => {
				if (res != undefined && res.status == true) {
					localStorage.setItem("user", JSON.stringify(accessInfo));
					var userdetl = res.Result;
					userdetl.PresentTime = moment()
						.utcOffset(60 * userdetl.TimeZoneOffset)
						.format("MMMM DD YYYY");
					if (
						userdetl != undefined &&
						res.Result.ShowCompanyPolicyAfterLogin
						// (res.Result.ShowEditProfileAfterLogin ||
						//   res.Result.ShowAgentAgreementAfterLogin)
					) {
						this.message =
							"You need to login on Planet first to verify your profile";
						localStorage.removeItem("user");
						this.snackbar = true;
						this.$data.loading3 = false;
					} else if (
						userdetl != undefined &&
						userdetl.ForcePasswordChange == true
					) {
						this.message =
							"Your password has expired. You need to reset your password through Planet Application.";
						localStorage.removeItem("user");
						this.snackbar = true;
						this.$data.loading3 = false;
					} else if (userdetl != undefined) {
						this.SaveUserDetail(userdetl);
					}
					//FetchUserLevelData();
				} else {
					this.message = "Unable to fetch User Information";
					localStorage.removeItem("user");
					this.snackbar = true;
					this.$data.loading3 = false;
				}
			})
			.catch((ex) => {});
	}
	getCurrentUserImage(accessInfo: any) {
		let AuthorizationToken: string = "";
		if (accessInfo != undefined) AuthorizationToken = accessInfo.access_token;
		GetRequestWithAuthorizationFromAccessToken(
			ApiEndPointConstants.CurrentUserProfileImageEndPoint,
			[],
			AuthorizationToken
		)
			.then((res) => {
				// var saveData = new GenericKeyPair(
				// 	"profileImage",
				// 	res.Result.ProfileImageBase64Data
				// );
				// setPersistedState(saveData);
				// localStorage.setItem(
				// 	"profileImage1",
				// 	res.Result.ProfileImageBase64Data
				// );
				this.$store.state.ProfileImageString = res.Result;
				this.$store.state.ProfileImage = true;
			})
			.catch((ex) => {});
	}

	CheckUserAccess() {
		console.log("Checking User Access");
		if (isLoggedIn()) {
			let parameters: GenericKeyPair[] = [];
			GetRequestWithAuthorization(
				ApiEndPointConstants.UserAccessCheckEndPoint,
				parameters
			)
				.then(async (res) => {
					if (res != undefined && res.status) {
						var reslt = res.Result;
						if (reslt != undefined) {
							console.log("UserAccess Response: " + reslt);
							if (reslt) {
								this.getCurrentUserInfo(null);
							} else {
								this.message =
									"This user doesn't have access to login in this application";
								localStorage.removeItem("user");
								this.snackbar = true;
								this.$data.loading3 = false;
							}
						}
					} else {
						console.log(res.ErrorMessage);
					}
				})
				.catch((ex) => {});
		}
	}

	SaveUserDetail(userdetl: any) {
		User.deleteAll();
		User.insert({
			data: {
				Name: userdetl.FirstName + " " + userdetl.LastName,
				UserId: userdetl.UserId,
			},
		});

		var user = localStorage.getItem("user");
		if (user != undefined) {
			let userObject = JSON.parse(user);
			// if (
			// 	userdetl.ProfileImageBase64Data != undefined &&
			// 	userdetl.ProfileImageBase64Data != ""
			// ) {
			// 	localStorage.setItem("profileImage", userdetl.ProfileImageBase64Data);
			// 	userdetl.ProfileImageBase64Data = "";
			// }

			userObject.UserName = this.user.username;
			userObject.UserDetail = userdetl;
			//console.log("Saving User Save user detail");
			localStorage.setItem("user", JSON.stringify(userObject));
			this.$router.push("/home");
			this.$data.loading3 = false;
			LoggingHelper.getInstance().LogEvent(
				LoggingConstants.LoggedInSuccessfully
			);
		}
		if (userdetl.LanguageId != undefined && userdetl.LanguageId != 0) {
			var selectedLanguage = languages.filter((x: any) => {
				if (x.id == userdetl.LanguageId) return x;
			});
			if (selectedLanguage != undefined && selectedLanguage.length > 0) {
				localStorage.setItem("currentLanguageFlag", selectedLanguage[0].flag);
			} else localStorage.setItem("currentLanguageFlag", "en");
		} else localStorage.setItem("currentLanguageFlag", "en");
	}
}
