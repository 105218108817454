

























































import { Component, Vue } from "vue-property-decorator";
import AppBar from "../../components/AppBar.vue";
import LeftMenu from "../../components/LeftMenu.vue";
import AppBarFooter from "../../components/AppBarFooter.vue";
import User from "../../entities/User";
import { EventBusLayout } from "../../bus/EventBusLayout";
import Presentation from "@/entities/Presentation";
import { PresentationType } from "@/enumerations/PresentationType";
import { PresentationSubType } from "@/enumerations/PresentationSubType";

@Component({
	components: {
		AppBar,
		AppBarFooter,
		LeftMenu,
	},
})
export default class Layout extends Vue {
	private CurrentPresentation: any = {};
	private IsVeteranType: boolean = false;
	private IsVeteranSMBAlphaType: boolean = false;
	private IsMcGruffType: boolean = false;
	private IsPrePlanPage: boolean = false;
	private IsUnionType: boolean = false;
	private IsPOSType: boolean = false;
	private IsAssociationType: boolean = false;
	private IsGlobeLifeType: boolean = false;
	private IsServiceMemberBenefitsType: boolean = false;
	private IsServiceMemberBenefitsSVLAlphaType: boolean = false;
	private IsServiceMemberBenefitsVFLAlphaType: boolean = false;
	get snackbar() {
		return this.$store.state.showMessage;
	}

	get loader() {
		return this.$store.state.loader;
	}

	created() {}

	set loader(value: boolean) {
		this.$store.state.loader = value;
	}
	set snackbar(value: boolean) {
		this.$store.state.showMessage = value;
	}
	get message() {
		return this.$store.state.messageText;
	}
	get BackgroundImagesOnPresentationType() {
		var DefaultType = true;
		this.IsVeteranType = false;
		this.IsVeteranSMBAlphaType = false;
		this.IsMcGruffType = false;
		this.IsUnionType = false;
		this.IsPOSType = false;
		this.IsAssociationType = false;
		this.IsGlobeLifeType = false;
		this.IsServiceMemberBenefitsType = false;
		this.IsServiceMemberBenefitsSVLAlphaType = false;
		this.IsServiceMemberBenefitsVFLAlphaType = false;
		this.CurrentPresentation = Presentation.query().last();
		if (this.CurrentPresentation != null) {
			this.IsVeteranType =
				PresentationType.Veteran == this.CurrentPresentation.PresentationTypeId;
			this.IsVeteranSMBAlphaType =
				PresentationType.Veteran ==
					this.CurrentPresentation.PresentationTypeId &&
				PresentationSubType.ServiceMemberBenefitsLeadAlpha ==
					this.CurrentPresentation.PresentationSubTypeId;
			this.IsMcGruffType =
				PresentationType.McGruffSafeKids ==
				this.CurrentPresentation.PresentationTypeId;
			this.IsUnionType =
				PresentationType.Union == this.CurrentPresentation.PresentationTypeId;
			this.IsPOSType =
				PresentationType.POS == this.CurrentPresentation.PresentationTypeId;
			this.IsAssociationType =
				PresentationType.Association ==
				this.CurrentPresentation.PresentationTypeId;
			this.IsGlobeLifeType =
				PresentationType.GlobeLife ==
				this.CurrentPresentation.PresentationTypeId;
			this.IsServiceMemberBenefitsType =
				PresentationType.ServiceMemberBenefits ==
				this.CurrentPresentation.PresentationTypeId;
			this.IsServiceMemberBenefitsSVLAlphaType =
				PresentationType.ServiceMemberBenefits ==
					this.CurrentPresentation.PresentationTypeId &&
				(PresentationSubType.SMBSponsoredVeteranLeadAlpha ==
					this.CurrentPresentation.PresentationSubTypeId ||
					PresentationSubType.ServiceMemberBenefitNotification ==
						this.CurrentPresentation.PresentationSubTypeId);
			this.IsServiceMemberBenefitsVFLAlphaType =
				PresentationType.ServiceMemberBenefits ==
					this.CurrentPresentation.PresentationTypeId &&
				(PresentationSubType.SMBVeteranFamilyLeadAlpha ==
					this.CurrentPresentation.PresentationSubTypeId ||
					PresentationSubType.Civilian ==
						this.CurrentPresentation.PresentationSubTypeId);
			if (
				(this.IsVeteranType ||
					this.IsVeteranSMBAlphaType ||
					this.IsMcGruffType ||
					this.IsUnionType ||
					this.IsPOSType ||
					this.IsAssociationType ||
					this.IsGlobeLifeType ||
					this.IsServiceMemberBenefitsType ||
					this.IsServiceMemberBenefitsSVLAlphaType ||
					this.IsServiceMemberBenefitsVFLAlphaType) &&
				!Boolean(this.$route.params.showallMaterials) &&
				!Boolean(this.$route.params.initiatePrePlan)
			) {
				DefaultType = false;
			} else {
				this.IsVeteranType = false;
				this.IsVeteranSMBAlphaType = false;
				this.IsMcGruffType = false;
				this.IsUnionType = false;
				this.IsPOSType = false;
				this.IsAssociationType = false;
				this.IsGlobeLifeType = false;
				this.IsServiceMemberBenefitsType = false;
				this.IsServiceMemberBenefitsSVLAlphaType = false;
				this.IsServiceMemberBenefitsVFLAlphaType = false;
				this.IsPrePlanPage = false;
				DefaultType = true;
			}
		}
		this.IsPrePlanPage = Boolean(this.$route.params.initiatePrePlan);
		if (this.IsPrePlanPage) {
			this.IsVeteranType = false;
			this.IsVeteranSMBAlphaType = false;
			this.IsMcGruffType = false;
			this.IsUnionType = false;
			this.IsPOSType = false;
			this.IsAssociationType = false;
			this.IsGlobeLifeType = false;
			this.IsServiceMemberBenefitsType = false;
			this.IsServiceMemberBenefitsSVLAlphaType = false;
			this.IsServiceMemberBenefitsVFLAlphaType = false;
			DefaultType = false;
		}
		return DefaultType;
	}
	get Largesnackbar() {
		return this.$store.state.Largesnackbar;
	}
	set Largesnackbar(value: boolean) {
		this.$store.state.Largesnackbar = value;
	}
	//  RoutedToEvt(data: any) {
	//      EventBusLayout.$emit('RoutedToEvt',data);
	//   }
	// RouteWithParams(data: any){
	//   EventBusLayout.$emit('RouteWithParams',data);
	// }
	// SavePrePlan(data: any){
	//   EventBusLayout.$emit('SavePrePlan',data);
	// }
	// Action(data: any){
	//   EventBusLayout.$emit('Action',data);
	// }
}
