































































































































































































































































































































































































import moment from "moment";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { gsap } from "gsap";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Spouse from "@/entities/Spouse";
//import PresentationWillKitVeterans from "@/entities/PresentationWillKitVeterans";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { GetApiDataOrCheckLocal } from "@/helpers/APIHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";
import Presentation from "@/entities/Presentation";
let RelationshipList = require("@/assets/pre-fill-data/relation_sponsor.json");
import VueHtml2pdf from "vue-html2pdf";
import { PrefillDocumentType } from "@/enumerations/PrefillDocumentType";
import { videoPlayer } from "vue-video-player";

@Component({ components: { VueHtml2pdf, videoPlayer } })
export default class GiftCertificatePdfHtml extends Vue {
	private preDisable: boolean = true;
	private nextDisable: boolean = false;
	// private pageNumbers: Number = 1;
	// private totalNumbers: Number = 1;
	// private totalPages: any = 0;
	private personNotifiedItems: boolean = true;
	private veteransInfoItems: boolean = true;
	private insurancePolItems: boolean = true;
	private funeralInstItems: boolean = true;
	private disabledNavButton: boolean = false;
	private Person: any;
	private Spouse: any;
	//private PresentationWillKitVeterans: any;
	private ReferralsOtherService: any[] = [];
	private ReferralsOtherServiceCount: number = 1;
	private Offices: any = [];
	private StateList: any = [];
	private stateListPromise: any = [];
	private freedomchoice: boolean = false;
	private additionalGroup: any = [];
	private serviceGroup: any = [];
	private UserNameForFredomofChoise = GetAscFullNameWithCamelCase();
	private BranchItems = require("../../assets/pre-fill-data/branch-of-service-types.json");
	private InvalidEmail = false;
	private PageNumbers: any = [1, 2];
	private Presentation: any = [];
	private RelationshiptoSp: any = RelationshipList;
	private ShowVideo: boolean = false;
	private InitialPerson: any;
	private InitialSpouse: any;
	private InitialVeteranObj: any;

	private FirstTimePopupShown: boolean = false;
	private AnyChangesMade: boolean = false;
	private VimeoVideoUrl: string = "";
	private ShowVimeoVideo: boolean = false;

	@Prop({ default: undefined })
	private PresentationWillKitVeterans: any;

	data() {
		return {
			willKey: 1,
			City: "",
			State: "",
			UserName: "",
			BeneficiaryRelation: "",
			familylength: 1,
			additionallength: 1,
			FamilyPdfTab: null,
			preDisable: true,
			nextDisable: false,
			CurrentPageNumbers: 1,
			totalPageCount: 1,
			PagesZoomIndex: [],
			VitalStatSectionDisabled: true,
			SpouseVitalStatSectionDisabled: true,
			VeteranInfoSectionDisabled: true,
			VeteranInfoSectionDisabledService: true,
			PersonNotifySectionDisabled: true,
			GiftCertificateSectionDisabled: true,
			pdfoptions: {
				margin: 3,
				filename: "hehehe.pdf",
				orientation: "landscape",
				pagebreak: { mode: ["css"] },
				html2canvas: { useCORS: true, scale: 4 },
			},
			playerOptions: {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: "static/ailvideo.mp4",
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			},
			DOBRules: [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						// const pattern =
						//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			],
		};
	}

	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}

	created() {
		this.Presentation = Presentation.query().withAllRecursive().last();
		if (
			this.PresentationWillKitVeterans != undefined &&
			typeof this.PresentationWillKitVeterans == "string" &&
			this.PresentationWillKitVeterans != ""
		) {
			this.PresentationWillKitVeterans = JSON.parse(
				this.PresentationWillKitVeterans
			);
			this.$data.BeneficiaryRelation =
				this.PresentationWillKitVeterans.BeneficiaryRelation;
		}
		this.LoadDropDown();
		this.Person = PrimaryPerson.query().withAll().last();
		this.Spouse = Spouse.query().last();

		setTimeout(() => {
			let sliderContainer =
				document.getElementsByClassName("PdfHtmlContainer")[0].children;
			for (let index = 0; index < this.$data.totalPageCount; index++) {
				this.$data.PagesZoomIndex.push(0);
			}
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
			this.InitialSpouse = JSON.stringify(this.Spouse);
			this.Person.Spouse = this.Spouse;
			this.InitialPerson = JSON.stringify(this.Person);
		}, 100);

		// setTimeout(() => {
		// 	this.startdrag();
		// }, 1000);

		var _keydownCallback = (event: any) => {
			if (event.code == "Escape") {
				if (this.ShowVideo) {
					this.ShowVideo = false;
				} else if (this.ShowVimeoVideo) {
					this.ShowVimeoVideo = false;
				}
			}
		};
		document.addEventListener("keyup", _keydownCallback);
	}

	async LoadDropDown() {
		this.$data.UserName = GetAscFullNameWithCamelCase();
		this.StateList = await getPersistedState("StateList");
		this.stateListPromise = this.StateList?.filter(
			(x: any) => x.CountryId != 3
		);
		if (
			this.PresentationWillKitVeterans != undefined &&
			this.PresentationWillKitVeterans != ""
		) {
			if (
				this.Person != undefined &&
				this.Person.State != undefined &&
				this.Person.State !== ""
			) {
				if (this.Person.State == 0) {
					this.Person.State = this.stateListPromise?.find(
						(x: any) => x.Name == this.Presentation.State
					)?.StateId;
				}
				this.PresentationWillKitVeterans.State = this.stateListPromise.find(
					(x: any) => x.StateId == this.Person.State
				);
				this.InitialVeteranObj = JSON.stringify(
					this.PresentationWillKitVeterans
				);
				if (
					this.PresentationWillKitVeterans.State != undefined &&
					typeof this.PresentationWillKitVeterans.State != "string"
				) {
					this.PresentationWillKitVeterans.State =
						this.PresentationWillKitVeterans.State.StateCode;
					this.$data.State = this.PresentationWillKitVeterans.State;
				}
			}
			if (this.Person.City != undefined && this.Person.City != "") {
				this.PresentationWillKitVeterans.City = this.Person.City;
				this.$data.City = this.PresentationWillKitVeterans.City;
				this.InitialVeteranObj = JSON.stringify(
					this.PresentationWillKitVeterans
				);
			}
		} else {
			if (
				this.Person != undefined &&
				this.Person.State != undefined &&
				this.Person.State !== ""
			) {
				if (this.Person.State == 0) {
					this.Person.State = this.stateListPromise?.find(
						(x: any) => x.Name == this.Presentation.State
					)?.StateId;
				}
				this.$data.State = this.stateListPromise.find(
					(x: any) => x.StateId == this.Person.State
				);
				if (
					this.$data.State != undefined &&
					typeof this.$data.State != "string"
				) {
					this.$data.State = this.$data.State.StateCode;
				}
			}
			if (this.Person.City != undefined && this.Person.City != "") {
				this.$data.City = this.Person.City;
			}
		}
	}

	ZoomContainerClick(pageIndex: number) {
		var currentPageZoomIndex = this.$data.PagesZoomIndex[pageIndex];
		if (currentPageZoomIndex >= 2) this.$data.PagesZoomIndex[pageIndex] = 0;
		else
			this.$data.PagesZoomIndex[pageIndex] =
				this.$data.PagesZoomIndex[pageIndex] + 1;

		this.$data.PagesZoomIndex = JSON.parse(
			JSON.stringify(this.$data.PagesZoomIndex)
		);
	}

	async PrimaryPersonUpdate(evt: any) {
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		this.$data.GiftCertificateSectionDisabled =
			!this.$data.GiftCertificateSectionDisabled;
		this.$data.GiftCertificateSectionDisabled =
			!this.$data.GiftCertificateSectionDisabled;
		this.FirstTimePopupShown = false;
	}

	private isPersonDOBValid = true;
	private isSpouseDOBValid = true;

	PrimaryPersonDateUpdate(evt: any) {
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear().toString();
		var yearString = "20[02][0-" + currentYear[currentYear.length - 1] + "]";
		var stringRegex =
			"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
			yearString +
			")\\b$";
		var regex = new RegExp(stringRegex);
		// const pattern =
		//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
		var test = regex.test(evt.target.value); // || "Invalid DOB.";

		this.isPersonDOBValid = test;

		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		this.FirstTimePopupShown = false;
	}

	SpouseUpdate(updateUI: boolean = true) {
		Spouse.update({
			where: this.Spouse.Id,
			data: this.Spouse,
		});
		this.Person.Spouse = this.Spouse;
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});

		if (updateUI) {
			this.$data.SpouseVitalStatSectionDisabled = true;
			this.$data.SpouseVitalStatSectionDisabled = false;
		}

		//this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SpouseDateDOBUpdate(evt: any) {
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear().toString();
		var yearString = "20[02][0-" + currentYear[currentYear.length - 1] + "]";
		var stringRegex =
			"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
			yearString +
			")\\b$";
		var regex = new RegExp(stringRegex);
		// const pattern =
		//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
		var test = regex.test(evt.target.value); // || "Invalid DOB.";

		this.isSpouseDOBValid = test;

		this.SpouseUpdate();
		this.FirstTimePopupShown = false;
	}

	next() {
		let sliderContainer =
			document.getElementsByClassName("PdfHtmlContainer")[0].children;

		gsap.from(".PdfHtmlPage", {
			opacity: 0,
			x: 500,
			duration: 0.4,
		});

		for (let item = 0; item < sliderContainer.length; item++) {
			let active = document.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item++;
				sliderContainer[item].classList.add("active");
				let pageItem = item;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				this.preDisable = false;
				if (item == sliderContainer.length - 1) {
					this.nextDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 400);
	}

	prev() {
		let sliderContainer =
			document.getElementsByClassName("PdfHtmlContainer")[0].children;
		let getActive = document.getElementsByClassName("PdfHtmlContainer")[0];

		gsap.from(".PdfHtmlPage", {
			opacity: 0,
			x: -500,
		});
		for (let item = sliderContainer.length - 1; item >= 0; item--) {
			let active = getActive.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item--;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				sliderContainer[item].classList.add("active");
				this.nextDisable = false;
				if (item == 0) {
					this.preDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 400);
	}

	ContainerDrag(evt: any) {
		const ele: any = document.getElementById("container");

		const dx = evt.clientX - ele.scrollTop;
		const dy = evt.clientY - ele.scrollLeft;

		// Scroll the element
		ele.scrollTop = ele.clientTop - dy;
		ele.scrollLeft = ele.clientLeft - dx;

		evt.preventDefault();
	}

	PreventClickCmd(evt: any) {
		evt.stopPropagation();
		//evt.preventDefault();
	}

	startdrag() {
		const ele: any = document.getElementById("container");
		ele.style.cursor = "grab";

		let pos = { top: 0, left: 0, x: 0, y: 0 };

		const mouseDownHandler = function (e: any) {
			ele.style.cursor = "grabbing";
			ele.style.userSelect = "none";

			pos = {
				left: ele.scrollLeft,
				top: ele.scrollTop,
				// Get the current mouse position
				x: e.clientX,
				y: e.clientY,
			};

			document.addEventListener("mousemove", mouseMoveHandler);
			document.addEventListener("mouseup", mouseUpHandler);
		};

		const mouseMoveHandler = function (e: any) {
			// How far the mouse has been moved
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			// Scroll the element
			e.scrollTop = pos.top - dy;
			e.scrollLeft = pos.left - dx;
		};

		const mouseUpHandler = function () {
			ele.style.cursor = "grab";
			ele.style.removeProperty("user-select");

			document.removeEventListener("mousemove", mouseMoveHandler);
			document.removeEventListener("mouseup", mouseUpHandler);
		};

		// Attach the handler
		ele.addEventListener("mousedown", mouseDownHandler);
	}

	get DateCertificateDelivered() {
		var dateCertificateDelivered = "";
		if (
			this.Presentation != undefined &&
			this.Presentation.StartTime != undefined
		) {
			dateCertificateDelivered = moment(this.Presentation.StartTime).format(
				"MMMM DD YYYY"
			);
		}
		return dateCertificateDelivered;
	}

	get AgencyName() {
		return "AO - AIL Division";
	}

	get AgencyPhone() {
		return "(800) 433-3405";
	}

	EnabledGiftCertificateSectionClick(evt: any) {
		this.$data.GiftCertificateSectionDisabled = false;
		evt.stopPropagation();
	}

	SaveGiftCertificateSectionClick(evt: any) {
		this.$store.state.messageText = this.$t(
			"SponsorshipProgram.willKitDataSaved"
		);
		this.$store.state.showMessage = true;
		this.SaveReferals();
		this.$data.GiftCertificateSectionDisabled = true;
		evt.stopPropagation();
		this.SaveWillKitWithPresentation();
	}

	SaveWillKitWithPresentation() {
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveWillKitWithPresentationBlur() {
		this.$data.GiftCertificateSectionDisabled =
			!this.$data.GiftCertificateSectionDisabled;
		this.$data.GiftCertificateSectionDisabled =
			!this.$data.GiftCertificateSectionDisabled;
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveReferals() {
		this.PresentationWillKitVeterans.ReferralsOtherService = JSON.stringify(
			this.ReferralsOtherService
		);

		// PresentationWillKitVeterans.update({
		//   where: this.PresentationWillKitVeterans.Id,
		//   data: this.PresentationWillKitVeterans,
		// });
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	$refs!: {
		html2Pdf: any;
		videoPlayer: any;
	};

	DownloadPDF() {
		this.$store.state.loader = true;
		this.SpouseUpdate();
		this.SaveReferals();
		// this.SaveWillKitWithPresentation
		setTimeout(() => {
			if (
				this.Person != undefined &&
				this.Person.FirstName != undefined &&
				this.Person.FirstName != ""
			)
				this.$data.pdfoptions.filename =
					"$2000 Gift Certificate - " + this.Person.FirstName;
			else this.$data.pdfoptions.filename = "$2000 Gift Certificate";
			this.$refs.html2Pdf.downloadPdf().then(() => {
				this.$store.state.loader = false;
			});
			this.FirstTimePopupShown = true;
		}, 500);
	}

	public isClosable(): boolean {
		if (
			((JSON.stringify(this.PresentationWillKitVeterans) !=
				this.InitialVeteranObj &&
				this.InitialVeteranObj != "") ||
				JSON.stringify(this.Person) != this.InitialPerson ||
				JSON.stringify(this.Spouse) != this.InitialSpouse ||
				(this.Presentation.LeadId != undefined &&
					this.Presentation.LeadId != "" &&
					this.Presentation.isWillKitFormDownloaded == false)) &&
			this.FirstTimePopupShown == false
		) {
			if (this.Presentation.LeadId != undefined) {
				this.Presentation = Presentation.query().withAllRecursive().last();
				this.Presentation.isWillKitFormDownloaded = true;
				this.FirstTimePopupShown = true;
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
			}
			return false;
		} else if (
			JSON.stringify(this.PresentationWillKitVeterans) ==
				this.InitialVeteranObj &&
			this.InitialVeteranObj != ""
		) {
			this.Presentation = Presentation.query().withAllRecursive().last();
			if (
				this.Presentation.PresentationWillKitVeterans != null &&
				this.Presentation.PresentationWillKitVeterans != undefined &&
				this.Presentation.PresentationWillKitVeterans != "" &&
				this.Presentation.PresentationWillKitVeterans !=
					JSON.stringify(this.PresentationWillKitVeterans)
			) {
				this.Presentation.PresentationWillKitVeterans = JSON.stringify(
					this.PresentationWillKitVeterans
				);
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
			}
		}
		return true;
	}
}
