






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import {
	GetApiDataOrCheckLocal,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import { ConvertIntoAmountFormat } from "@/helpers/CalculationHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import moment from "moment";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import spProgram from "../icons/sp-program.vue";
// import { MmsTemplateComponent } from "../popups/MmsTemplate.vue";
// import MmsTemplateComponent from "../popups/MmsTemplate.vue";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { groupBy } from "@/helpers/ArrayHelper";
import { changeLocale } from "@/plugins/i18n";
import { GetUserCountry } from "@/helpers/APIExtentions";
import { APIService } from "@/services/APIService";
import { PresentationType } from "@/enumerations/PresentationType";
import PresentationWillKitVeterans from "@/entities/PresentationWillKitVeterans";
import Presentation from "@/entities/Presentation";
import { MemberLookupType } from "../../enumerations/MemberLookupType";
import PrimaryPerson from "@/entities/PrimaryPerson";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
import { Camelize } from "@/helpers/ArrayHelper";
import { AddMaskingToNumber } from "@/helpers/UIHelper";
import { EntityHelper } from "@/helpers/EntityHelper";
let RelationshipList = require("@/assets/pre-fill-data/relation_sponsor.json");

var template123: any =
	"<!DOCTYPE html><html><head><title>Page Title</title></head><body><h1>This is a Heading</h1><p>This is a paragraph.</p></body></html>";

@Component({
	components: { spProgram },
})
export default class ReferralPopup extends Vue {
	$refs!: {
		emailfield: any;
		phonefield: any;
		referredByfield: any;
		ReferralForm: any;
		MessagePreviewRef: any;
	};

	private User: any;
	private htmlTemplates = require("@/assets/pre-fill-data/MmsTemplates.json");
	private RelationshiptoSp: any = RelationshipList;
	// private templateasd: any = htmlTemplates;
	private indexId = 1;
	private phoneIndexId = 1;
	private groupPhoneIndexId = 1;
	private GroupMMSClicked = false;
	private GiftAmount: string = "0";
	private SponsorDOBPicker = "1980-01-01";
	private isRefFormValid: boolean = false;
	private IsGlobeLifeType: boolean = false;
	private showImage: Boolean = false;
	private imgSource: string = "";
	private allProducts: any = [];
	private allMatchingproducts: any;
	private itemLessCount: number = 3;
	private products: any;
	private showCCRefereal: boolean = true;
	private ccemailPopUp: boolean = false;
	private sponsornotification: boolean = false;
	private groupsponsornotification: boolean = false;
	private groupimagenotification: boolean = false;
	private clientimagenotification: boolean = false;
	private SelectedGroupIndex: number = 0;
	private nonce: any = 0;
	private menu: any = false;
	private items: any = [];
	private combomodel: any = [];
	private ShowHomeVisibility: boolean = false;
	private isActive: boolean = false;
	private RefData: any = [];
	private confirmSaveDialog: boolean = false;
	private StateList: any = [];
	private AdultChildrenforRef: any = [];

	private ReferralsFamily: any = [];
	private ReferralsChildrenFamily: any = [];
	private ReferralsAdditional: any = [];
	private ReferralsService: any = [];
	private ReferralsOtherService: any = [];
	private ReferralsMembers: any = [];
	private WillKitChildren: any = [];
	private Presentation: any = [];
	private GroupTextPhones: any = [];
	private ReferralChecked: boolean = true;
	private ReferralFirstName: string = "";
	private ReferralLastName: string = "";
	private ReferralPhone: string = "";
	private ReferralState: string = "";
	private ReferralCity: string = "";
	private ReferralSignificantOther: string = "";
	private ReferralRelToSponsor: string = "";
	private ReferralBranchOfService: string = "";
	private IsConfirmationAgentValid: boolean = true;
	private IsConfirmationSponsorValid: boolean = true;
	private IsGroupConfirmationAgentValid: boolean = true;
	private IsGroupConfirmationSponsorValid: boolean = true;
	private IsGroupConfirmationReferralValid: boolean = true;
	private IsLeadAlreadySent: boolean = false;
	private MessagePreview: string = "";
	private genderedRelationshipsList = require("@/assets/pre-fill-data/gendered_relations.json");
	private TemplateforPreview: string = "";
	private IntroductoryMessage: string = "";
	private ImageMessagePreview: string = "";
	private ImageIntroductoryMessage: string = "";
	private ClientImageintrocutoryMessage: string = "";
	// private IsGroupSent: boolean = false;
	// private IsGroupRecentlySent: boolean = false;
	private SMSAndMMSLimit: number = process.env.VUE_APP_SMSANDMMS_LIMIT;
	private TrainingSMSAndMMSLimit: number =
		process.env.VUE_APP_TRAINING_SMSANDMMS_LIMIT;
	private IsGroupSmsEditable: boolean = false;

	@Prop({ default: undefined })
	private PresentationWillKitVeterans: any;

	@Prop({ default: undefined })
	private PresentationFamilyCare: any;

	@Prop({ default: false })
	private isClonedPresentation: boolean = false;

	@Prop()
	private CountryId: number = 0;

	@Prop()
	private PresentationTypeId: any = "";

	@Prop()
	private MemberLookupTypeId: any = "";

	@Prop({ default: undefined })
	private ReferralWatch: any = {};

	@Prop({ default: undefined })
	private ResetToDefault: any = [];

	@Prop({ default: false })
	private ShowBranchOfService: any = false;

	@Prop()
	private NoCostBenefitsList: any = [];

	@Prop()
	private HideBenefitsRow: any = false;

	private ReferralGroupCode: any = [];

	private BranchOfServiceItems = require("../../assets/pre-fill-data/branch-of-service-types.json");

	private AllSelectedMaterialElements: any = [];

	private stateListPromise: any = [];

	RelationshiptoSpchange(event: any, itemRef: any) {
		if (event.text != undefined) itemRef.RelToSponsor = event.text;
		else itemRef.RelToSponsor = event;
	}

	Statechange(event: any, itemRef: any) {
		if (event != undefined) itemRef.State = event.StateCode;
	}

	clientMMStemplateimage() {
		var Url = "";
		if (
			this.Presentation?.PresentationTypeId != null &&
			this.Presentation?.PresentationTypeId != undefined
		) {
			switch (this.Presentation?.PresentationTypeId) {
				case PresentationType.McGruffSafeKids:
					return require(`@/assets/McGruffSafeKids(US).jpg`);
				case PresentationType.Union:
					if (this.Presentation?.CountryId == 2) {
						return require(`@/assets/Union(CA).jpg`);
					} else {
						return require(`@/assets/Union(US).jpg`);
					}
				case PresentationType.Association:
					if (this.Presentation?.CountryId == 2) {
						return require(`@/assets/Association(CA).jpg`);
					} else {
						return require(`@/assets/Association(US).jpg`);
					}
				case PresentationType.CreditUnion:
					return require(`@/assets/CreditUnion(US).jpg`);
				case PresentationType.Veteran:
					if (
						this.Presentation?.PresentationSubTypeId ==
						PresentationSubType.ServiceMemberBenefitsLeadAlpha
					) {
						return require(`@/assets/Veteran_SMBA.jpg`);
					}
					return require(`@/assets/Veteran.jpg`);
				case PresentationType.WillKitNoCostLaw:
					if (this.Presentation?.CountryId == 2) {
						return require(`@/assets/WillKitNoCostLaw(CA).jpg`);
					} else {
						return require(`@/assets/WillKitNoCostLaw(US).jpg`);
					}
				case PresentationType.POS:
					if (this.Presentation?.CountryId == 2) {
						return require(`@/assets/POS(CA).jpg`);
					} else {
						return require(`@/assets/POS(US).jpg`);
					}
				case PresentationType.Referral:
					if (this.Presentation?.CountryId == 2) {
						return require(`@/assets/Referral(CA).jpg`);
					} else {
						return require(`@/assets/Referral(US).jpg`);
					}
				case PresentationType.GlobeLife:
					if (this.Presentation?.CountryId == 2) {
						return require(`@/assets/GlobeLife(CA).jpg`);
					} else {
						return require(`@/assets/GlobeLife(US).jpg`);
					}
				case PresentationType.ServiceMemberBenefits:
					if (
						this.Presentation?.PresentationSubTypeId ==
							PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
						this.Presentation?.PresentationSubTypeId ==
							PresentationSubType.SMBVeteranFamilyLeadAlpha ||
						this.Presentation?.PresentationSubTypeId ==
							PresentationSubType.Civilian ||
						this.Presentation?.PresentationSubTypeId ==
							PresentationSubType.ServiceMemberBenefitNotification
					) {
						return require(`@/assets/Veteran_SMBA.jpg`);
					}
					return require(`@/assets/Veteran.jpg`);
				default:
					Url = "";
					break;
			}
		}

		return Url;
	}

	// groupMMStemplateimage() {
	// 	var Url = "";
	// 	if (
	// 		this.Presentation?.PresentationTypeId != null &&
	// 		this.Presentation?.PresentationTypeId != undefined
	// 	) {
	// 		switch (this.Presentation?.PresentationTypeId) {
	// 			case PresentationType.McGruffSafeKids:
	// 				return require(`@/assets/McGruffSafeKids(US).jpg`);
	// 			case PresentationType.Union:
	// 				if (this.Presentation?.CountryId == 2) {
	// 					return require(`@/assets/Union(CA).jpg`);
	// 				} else {
	// 					return require(`@/assets/Union(US).jpg`);
	// 				}
	// 			case PresentationType.Association:
	// 				if (this.Presentation?.CountryId == 2) {
	// 					return require(`@/assets/Association(CA).jpg`);
	// 				} else {
	// 					return require(`@/assets/Association(US).jpg`);
	// 				}
	// 			case PresentationType.CreditUnion:
	// 				return require(`@/assets/CreditUnion(US).jpg`);
	// 			case PresentationType.Veteran:
	// 				if (
	// 					this.Presentation?.PresentationSubTypeId != 6 &&
	// 					this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex] !=
	// 						undefined
	// 				) {
	// 					if (
	// 						(this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
	// 							.GroupCode != undefined &&
	// 							this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
	// 								.GroupCode != null &&
	// 							this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
	// 								.GroupCode == "IGSVL") ||
	// 						(this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
	// 							.BranchOfService != undefined &&
	// 							this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
	// 								.BranchOfService != null &&
	// 							this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
	// 								.BranchOfService != "")
	// 					) {
	// 						return require(`@/assets/Veteran_IGSVL.jpg`);
	// 					} else {
	// 						return require(`@/assets/Veteran.jpg`);
	// 					}
	// 				} else {
	// 					return require(`@/assets/Veteran.jpg`);
	// 				}
	// 			case PresentationType.WillKitNoCostLaw:
	// 				if (this.Presentation?.CountryId == 2) {
	// 					return require(`@/assets/WillKitNoCostLaw(CA).jpg`);
	// 				} else {
	// 					return require(`@/assets/WillKitNoCostLaw(US).jpg`);
	// 				}
	// 			default:
	// 				Url = "";
	// 				break;
	// 		}
	// 	}

	// 	return Url;
	// }

	CreatePreviewTemplates() {
		var pres = Presentation.query().last();
		var SelectedPresentationSubTypeId: any;
		var IsDemoOrTraininingPresentation =
			this.PresentationTypeId == PresentationType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Training;
		var SelectedGroupCode =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].GroupCode;
		IsDemoOrTraininingPresentation =
			SelectedGroupCode == "IGTRN" ? true : IsDemoOrTraininingPresentation;
		var ReferralName =
			Camelize(this.ReferralFirstName).trim() +
			" " +
			Camelize(this.ReferralLastName).trim();
		var AgentName = Camelize(
			this.User.FirstName + " " + this.User.LastName
		).trim();
		var TwilioNumber = AddMaskingToNumber(
			this.User.TwilioNumber?.length == 12
				? this.User.TwilioNumber.substring(2)
				: this.User.TwilioNumber.substring(1)
		);
		var AgentPhone = AddMaskingToNumber(this.$data.TempReferrals.AgentPhoneNo);
		this.ImageMessagePreview = `Congratulations, your sponsorship has been processed. Please see above.<br /><br />
									For faster service please contact ${AgentName} at <span  class="text-white-space">${AgentPhone}</span>.`;
		this.ImageIntroductoryMessage = `The Receipt Notification text will be sent to <b>${ReferralName.trim()}</b>. The number it will be sent from is
		<span  class="text-white-space">${TwilioNumber}</span>`;
		switch (pres?.PresentationTypeId) {
			case PresentationType.Veteran: {
				if (
					pres?.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitsLeadAlpha
				) {
					SelectedPresentationSubTypeId =
						PresentationSubType.ServiceMemberBenefitsLeadAlpha;
				} else if (
					pres?.PresentationSubTypeId != PresentationSubType.VeteranFamilyLead
				) {
					if (
						SelectedGroupCode == "IGSVL" ||
						(this.ReferralBranchOfService != null &&
							this.ReferralBranchOfService != "")
					) {
						SelectedPresentationSubTypeId =
							PresentationSubType.SponsoredVeteranLead;
					} else if (
						SelectedGroupCode != "IGSVL" &&
						(this.ReferralBranchOfService == null ||
							this.ReferralBranchOfService == "")
					) {
						SelectedPresentationSubTypeId =
							PresentationSubType.VeteranFamilyLead;
					}
				} else {
					SelectedPresentationSubTypeId = PresentationSubType.VeteranFamilyLead;
				}
				break;
			}
			case PresentationType.ServiceMemberBenefits: {
				if (
					pres?.PresentationSubTypeId ==
						PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
					pres?.PresentationSubTypeId ==
						PresentationSubType.SMBVeteranFamilyLeadAlpha ||
					pres?.PresentationSubTypeId == PresentationSubType.Civilian ||
					pres?.PresentationSubTypeId ==
						PresentationSubType.ServiceMemberBenefitNotification
				) {
					SelectedPresentationSubTypeId = pres?.PresentationSubTypeId;
				} else if (
					SelectedGroupCode == "IGSVL" ||
					(this.ReferralBranchOfService != null &&
						this.ReferralBranchOfService != "")
				) {
					SelectedPresentationSubTypeId =
						PresentationSubType.SMBSponsoredVeteranLead;
				} else if (
					SelectedGroupCode != "IGSVL" &&
					(this.ReferralBranchOfService == null ||
						this.ReferralBranchOfService == "")
				) {
					SelectedPresentationSubTypeId =
						PresentationSubType.SMBVeteranFamilyLead;
				} else {
					SelectedPresentationSubTypeId = pres?.PresentationSubTypeId;
				}
				break;
			}
			case PresentationType.McGruffSafeKids:
			case PresentationType.Union:
			case PresentationType.Association:
			case PresentationType.CreditUnion:
			case PresentationType.WillKitNoCostLaw: {
				SelectedPresentationSubTypeId = pres?.PresentationSubTypeId;
				break;
			}
			default: {
				SelectedPresentationSubTypeId = pres?.PresentationSubTypeId;
				break;
			}
		}
		let TemplateFound = this.htmlTemplates.filter((x: any) => {
			let countryIdAvailable = true;
			if (x.CountryId != null) {
				countryIdAvailable = pres?.CountryId == x.CountryId;
			}
			if (
				x.PresentationTypeId == pres?.PresentationTypeId &&
				x.PresentationSubTypeId == SelectedPresentationSubTypeId &&
				countryIdAvailable &&
				x.IsTraining == IsDemoOrTraininingPresentation
			)
				return x;
		});
		var date = moment();
		var currentDate = date.format("MM/DD/YYYY hh:mm A");
		console.log(currentDate);
		if (TemplateFound.length > 0) {
			this.TemplateforPreview = TemplateFound[0].HtmlText;
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{ProcessedDate}}",
				currentDate
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{ActivationDate}}",
				"PENDING ACTIVATION"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{FirstName}}",
				this.ReferralFirstName != null && this.ReferralFirstName.trim() != ""
					? Camelize(this.ReferralFirstName)
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{SigOther}}",
				this.ReferralSignificantOther != null &&
					this.ReferralSignificantOther.trim() != ""
					? Camelize(this.ReferralSignificantOther)
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{City}}",
				this.ReferralCity != null && this.ReferralCity.trim() != ""
					? Camelize(this.ReferralCity)
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{LastName}}",
				this.ReferralLastName != null && this.ReferralLastName.trim() != ""
					? Camelize(this.ReferralLastName)
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{Phone}}",
				this.ReferralPhone != null && this.ReferralPhone.trim() != ""
					? this.ReferralPhone
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{State}}",
				this.ReferralState != null && this.ReferralState.trim() != ""
					? this.ReferralState
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{RelToSponsor}}",
				this.ReferralRelToSponsor != null &&
					this.ReferralRelToSponsor.trim() != ""
					? Camelize(this.ReferralRelToSponsor)
					: "&nbsp;"
			);
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{BranchOfService}}",
				this.ReferralBranchOfService != null &&
					this.ReferralBranchOfService.trim() != ""
					? Camelize(this.ReferralBranchOfService)
					: "&nbsp;"
			);
			let SponsorsName =
				this.$data.TempReferrals.ReferredBy +
				" " +
				this.$data.TempReferrals.ReferredByLastName;
			this.TemplateforPreview = this.TemplateforPreview.replace(
				"{{SponsorName}}",
				SponsorsName != null && SponsorsName.trim() != ""
					? Camelize(SponsorsName)
					: "&nbsp;"
			);
		}
	}
	AvoidEnter(event: any) {
		if (event.key == "Enter") {
			event.preventDefault();
		}
	}
	get showsponsorshipHeading() {
		// var alreadyexistcount = 0;
		if (
			this.$data.TempReferrals != undefined &&
			this.$data.TempReferrals.ReferralsList != undefined
		) {
			if (this.$data.TempReferrals.ReferralsList.length > 0) {
				// this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
				// 	if (element.AlreadyExist) {
				// 		alreadyexistcount += 1;
				// 	}
				// });
				// if (
				// 	this.$data.TempReferrals.ReferralsList.length != alreadyexistcount
				// ) {
				return true;
				// }
				// return false;
			}
			return false;
		}
		return false;
	}
	get IsImageSentTimeoutPassed() {
		if (this.$data.TempReferrals.IsImageRecentlySent != undefined) {
			this.$data.TempReferrals.IsImageRecentlySent =
				this.$store.state.isImageSentRecently;
			return this.$data.TempReferrals.IsImageRecentlySent;
		}
		return false;
	}
	get IsMessageSentTimeoutPassed() {
		if (this.$data.TempReferrals.IsRecentlySent != undefined) {
			this.$data.TempReferrals.IsRecentlySent =
				this.$store.state.isMessageSentRecently;
			return this.$data.TempReferrals.IsRecentlySent;
		}
		return false;
	}
	get RemoveButtonFromStartPresentation() {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (CurrentRoute == "/StartPresentation") return true;
		else return false;
	}

	IsRouteChangedBeforeTimeout() {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (CurrentRoute != this.$store.state.CurrentPath) {
			if (
				this.$data.TempReferrals != undefined &&
				this.$data.TempReferrals.ReferralsList != undefined
			) {
				this.$data.TempReferrals.ReferralsList.forEach((val: any) => {
					if (val.IsRecentlySent != undefined) {
						val.IsRecentlySent = false;
						val.IsEmailRecentlySent = false;
					}
					if (val.IsImageRecentlySent != undefined)
						val.IsImageRecentlySent = false;
				});
			}
			this.$store.state.CurrentPath = CurrentRoute;
		}
	}

	get MMSButtonEnableOnPresntationType() {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			(this.Presentation?.PresentationTypeId == PresentationType.Referral ||
				this.Presentation?.PresentationTypeId == PresentationType.POS ||
				this.Presentation?.PresentationTypeId == PresentationType.Veteran ||
				this.Presentation?.PresentationTypeId ==
					PresentationType.McGruffSafeKids ||
				this.Presentation?.PresentationTypeId == PresentationType.CreditUnion ||
				this.Presentation?.PresentationTypeId == PresentationType.Union ||
				this.Presentation?.PresentationTypeId == PresentationType.Association ||
				this.Presentation?.PresentationTypeId ==
					PresentationType.WillKitNoCostLaw ||
				this.Presentation?.PresentationTypeId == PresentationType.GlobeLife ||
				this.Presentation?.PresentationTypeId ==
					PresentationType.ServiceMemberBenefits) &&
			CurrentRoute != "/StartPresentation"
		) {
			return true;
		}
		return false;
	}

	get GetMmsServiceAllowedOnState() {
		if (this.Presentation?.PresentationTypeId == PresentationType.GlobeLife) {
			if (this.Presentation?.State != "New York") return true;
			else {
				return false;
			}
		}
		return true;
	}

	get GetMmsServiceAllowed() {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (CurrentRoute == "/StartPresentation" || !this.User.MmsServiceAllowed) {
			return false;
		} else {
			return true;
		}
	}

	get ClientMmsButtonEnable() {
		if (
			// this.User != null &&
			// this.User != undefined &&
			// this.User.PhoneNo != null &&
			// this.User.PhoneNo != undefined &&
			// this.User.PhoneNo != "" &&
			this.$data.TempReferrals.ReferredBy != null &&
			this.$data.TempReferrals.ReferredBy != undefined &&
			this.$data.TempReferrals.ReferredBy.trim() != ""
		) {
			return true;
		}
		return false;
	}
	ValidateAndSendClientMessage(ImageModal: boolean = false) {
		var timeNow = moment()
			.utcOffset(60 * this.User.TimeZoneOffset)
			.format("MMMM DD YYYY");
		if (
			moment(timeNow, "MMMM DD YYYY").diff(
				moment(this.User.PresentTime, "MMMM DD YYYY")
			) > 0
		) {
			let CurrentUser = localStorage.getItem("user");
			if (CurrentUser != undefined) {
				this.User.MessageCount = 0;
				this.User.TrainingMessageCount = 0;
				this.User.PresentTime = moment()
					.utcOffset(60 * this.User.TimeZoneOffset)
					.format("MMMM DD YYYY");
				var userObj = JSON.parse(CurrentUser);
				userObj.UserDetail.MessageCount = this.User.MessageCount;
				userObj.UserDetail.TrainingMessageCount =
					this.User.TrainingMessageCount;
				userObj.UserDetail.PresentTime = this.User.PresentTime;
			}
			localStorage.setItem("user", JSON.stringify(userObj));
		}
		var IsDemoOrTraininingPresentation =
			this.PresentationTypeId == PresentationType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Training;
		if (
			(this.User.MessageCount >= this.SMSAndMMSLimit &&
				!IsDemoOrTraininingPresentation) ||
			(IsDemoOrTraininingPresentation &&
				this.User.TrainingMessageCount >= this.TrainingSMSAndMMSLimit)
		) {
			this.$store.state.messageText = "MMS Limit for today has been exceeded.";
			this.$store.state.showMessage = true;
		} else {
			// var invalid =
			// 	this.$data.TempReferrals.Phone.length <= 13 ||
			// 	this.$data.TempReferrals.Phone.trim() == "";
			// if (invalid) {
			// 	this.$refs.phonefield.$el.classList.add("error--text");
			// }
			if (
				this.$data.TempReferrals.ReferredBy == null ||
				this.$data.TempReferrals.ReferredBy.trim() == ""
			) {
				this.$refs.referredByfield.$el.classList.add("error--text");
			} else if (
				!(
					this.User.PhoneNo != null &&
					this.User.PhoneNo != undefined &&
					this.User.PhoneNo != ""
				)
			) {
				this.$store.state.messageText = this.$t("General.AgentNumberError");
				this.$store.state.showMessage = true;
			} else {
				this.$refs.phonefield.$el.classList.remove("error--text");
				this.$refs.referredByfield.$el.classList.remove("error--text");
				if (ImageModal == true) this.ImageNotificationModal();
				else this.NotificationModal();
			}
		}
	}

	async ValidateAndSendSponsorMessage(
		referral: any,
		GroupIndex: number,
		ImageModal: boolean = false
	) {
		this.IsLeadAlreadySent = referral.AlreadyExist;
		this.GroupMMSClicked = true;
		this.SelectedGroupIndex = GroupIndex;
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex] !=
				undefined &&
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.GroupCode != undefined &&
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.GroupCode != null
		)
			var SelectedGroupCode =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.GroupCode;
		var timeNow = moment()
			.utcOffset(60 * this.User.TimeZoneOffset)
			.format("MMMM DD YYYY");
		if (
			moment(timeNow, "MMMM DD YYYY").diff(
				moment(this.User.PresentTime, "MMMM DD YYYY")
			) > 0
		) {
			let CurrentUser = localStorage.getItem("user");
			if (CurrentUser != undefined) {
				this.User.MessageCount = 0;
				this.User.TrainingMessageCount = 0;
				this.User.PresentTime = moment()
					.utcOffset(60 * this.User.TimeZoneOffset)
					.format("MMMM DD YYYY");
				var userObj = JSON.parse(CurrentUser);
				userObj.UserDetail.MessageCount = this.User.MessageCount;
				userObj.UserDetail.TrainingMessageCount =
					this.User.TrainingMessageCount;
				userObj.UserDetail.PresentTime = this.User.PresentTime;
			}
			localStorage.setItem("user", JSON.stringify(userObj));
		}
		var IsDemoOrTraininingPresentation =
			this.PresentationTypeId == PresentationType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Training ||
			SelectedGroupCode == "IGTRN";
		if (
			(this.User.MessageCount >= this.SMSAndMMSLimit &&
				!IsDemoOrTraininingPresentation) ||
			(IsDemoOrTraininingPresentation &&
				this.User.TrainingMessageCount >= this.TrainingSMSAndMMSLimit)
		) {
			this.$store.state.messageText = "MMS Limit for today has been exceeded.";
			this.$store.state.showMessage = true;
		} else {
			// var invalid =
			// 	this.$data.TempReferrals.Phone.length <= 13 ||
			// 	this.$data.TempReferrals.Phone.trim() == "";
			var invalidreferral =
				referral.FirstName == null || referral.FirstName.trim() == "";

			// if (invalid) {
			// 	this.$refs.phonefield.$el.classList.add("error--text");
			// }
			if (
				this.$data.TempReferrals.ReferredBy == null ||
				this.$data.TempReferrals.ReferredBy.trim() == ""
			) {
				this.$refs.referredByfield.$el.classList.add("error--text");
			} else if (invalidreferral) {
			} else if (
				!(
					this.User.PhoneNo != null &&
					this.User.PhoneNo != undefined &&
					this.User.PhoneNo != ""
				)
			) {
				this.$store.state.messageText = this.$t("General.AgentNumberError");
				this.$store.state.showMessage = true;
			} else {
				//this.$refs.phonefield.$el.classList.remove("error--text");
				this.$refs.referredByfield.$el.classList.remove("error--text");
				this.SelectedGroupIndex = GroupIndex;
				this.GroupMMSClicked = false;
				if (ImageModal == true) this.GroupImageNotificationModal();
				else this.GroupNotificationModal();
			}
		}
	}

	AddExtraNumber() {
		if (this.$data.TempReferrals.ExtraPhone == undefined)
			Vue.set(this.$data.TempReferrals, "ExtraPhone", []);
		if (
			this.$data.TempReferrals.ExtraPhone != undefined &&
			this.$data.TempReferrals.ExtraPhone != null &&
			this.$data.TempReferrals.ExtraPhone.length > 0
		) {
			var res =
				this.$data.TempReferrals.ExtraPhone[
					this.$data.TempReferrals.ExtraPhone.length - 1
				];
			this.phoneIndexId = res.Id + 1;
		}
		this.$data.TempReferrals.ExtraPhone.push({
			Id: this.phoneIndexId,
			Name: "",
			PhoneNo: "",
			Checked: false,
			IsNameValid: true,
			IsPhoneValid: true,
		});
	}
	AddGroupExtraNumber() {
		// if (
		// 	this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
		// 		.GroupExtraPhone == undefined
		// )
		// 	Vue.set(
		// 		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
		// 		"GroupExtraPhone",
		// 		[]
		// 	);
		// if (
		// 	this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
		// 		.GroupExtraPhone != undefined &&
		// 	this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
		// 		.GroupExtraPhone != null &&
		// 	this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
		// 		.GroupExtraPhone.length > 0
		// ) {
		// 	var res =
		// 		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
		// 			.GroupExtraPhone[
		// 			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
		// 				.GroupExtraPhone.length - 1
		// 		];
		// 	this.groupPhoneIndexId = res.Id + 1;
		// }
		if (
			this.GroupTextPhones != undefined &&
			this.GroupTextPhones != null &&
			this.GroupTextPhones.length > 0
		) {
			var res = this.GroupTextPhones[this.GroupTextPhones.length - 1];
			this.groupPhoneIndexId = res.Id + 1;
		}
		this.GroupTextPhones.push({
			Id: this.groupPhoneIndexId,
			Name: "",
			PhoneNo: "",
			Checked: false,
			IsNameValid: true,
			IsPhoneValid: true,
		});
	}

	CreateClientSponserRequest(IsClientText: boolean, IsMMS: boolean = false) {
		var Request: any = {};
		// let pres = Presentation.query().withAllRecursive().last();
		this.Presentation = Presentation.query().withAllRecursive().last();
		var Person = PrimaryPerson.query().withAllRecursive().last();
		Request.PresentationGuid = this.Presentation.Id;
		Request.IsClientText = IsClientText;
		Request.Date = this.$data.TempReferrals.Date;
		Request.Email = this.$data.TempReferrals.Email;
		Request.Organization = this.$data.TempReferrals.Organization;
		Request.LeadId =
			this.Presentation?.LeadId != undefined ? this.Presentation?.LeadId : "";
		Request.PresentationTypeId =
			this.Presentation?.PresentationTypeId != undefined &&
			this.Presentation?.PresentationTypeId != null
				? this.Presentation?.PresentationTypeId
				: null;
		Request.PresentationSubTypeId =
			this.Presentation?.PresentationSubTypeId != undefined &&
			this.Presentation?.PresentationSubTypeId != null
				? this.Presentation?.PresentationSubTypeId
				: null;
		Request.PresentationGroupName =
			this.Presentation?.PresentedGroup != undefined &&
			this.Presentation?.PresentedGroup != null
				? this.Presentation?.PresentedGroup
				: null;
		Request.PresentationGroupId =
			this.Presentation?.PresentedGroupId != undefined &&
			this.Presentation?.PresentedGroupId != null
				? this.Presentation?.PresentedGroupId
				: null;
		Request.CountryId =
			this.Presentation?.CountryId != undefined &&
			this.Presentation?.CountryId != null
				? this.Presentation?.CountryId
				: null;
		Request.IsTraining =
			this.PresentationTypeId == PresentationType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Demo ||
			this.MemberLookupTypeId == MemberLookupType.Training;
		// Request.SavedLeadID = this.$data.TempReferrals.SavedLeadID;
		if (
			this.Presentation?.MemberSurvey != "" &&
			this.Presentation?.MemberSurvey != undefined
		) {
			Request.CreditUnionInstitution = JSON.parse(
				this.Presentation?.MemberSurvey
			).CreditUnion;
		}
		Request.ClientLastName = this.$data.TempReferrals.ReferredByLastName;
		Request.ClientFirstName = this.$data.TempReferrals.ReferredBy;
		Request.ClientPhone = this.$data.TempReferrals.Phone;
		Request.AgentName = this.User.FirstName + " " + this.User.LastName;
		Request.AgentEmail = this.User.EmailAddress;
		Request.AgentPhone = AddMaskingToNumber(
			this.$data.TempReferrals.AgentPhoneNo
		);
		if (Person != null && Person != undefined) {
			Request.ClientGender = Person.Gender;
		} else {
			Request.ClientGender = null;
		}
		Request.PhoneList = [];
		Request.ReferralsList = [];

		Request.PhoneList.push({
			Name:
				this.$data.TempReferrals.ReferredBy +
				" " +
				this.$data.TempReferrals.ReferredByLastName,
			PhoneNo: this.$data.TempReferrals.Phone,
			Checked: true,
		});

		if (IsClientText) {
			// if (
			// 	this.$data.TempReferrals.SponsorshipGuid != null &&
			// 	this.$data.TempReferrals.SponsorshipGuid != undefined
			// ) {
			// 	Request.SponsorshipReferralGuid =
			// 		this.$data.TempReferrals.SponsorshipGuid;
			// } else {
			// 	this.$data.TempReferrals.SponsorshipGuid = EntityHelper.uuid();
			// 	Request.SponsorshipReferralGuid =
			// 		this.$data.TempReferrals.SponsorshipGuid;
			// }

			Request.SponsorshipReferralGuid =
				this.$data.TempReferrals.SponsorshipGuid;
			if (
				this.$data.TempReferrals != undefined &&
				this.$data.TempReferrals.ExtraPhone != undefined &&
				this.$data.TempReferrals.ExtraPhone.length > 0
			) {
				let PhoneList = this.$data.TempReferrals.ExtraPhone;
				PhoneList.forEach((element: any) => {
					if (element != undefined) {
						let extranumbers: any = {};
						extranumbers.Name = element.Name;
						extranumbers.PhoneNo = element.PhoneNo;
						extranumbers.Checked = element.Checked;
						if (extranumbers.Checked == true)
							Request.PhoneList.push(extranumbers);
					}
				});
			}

			if (
				this.$data.TempReferrals != undefined &&
				this.$data.TempReferrals.ReferralsList != undefined &&
				this.$data.TempReferrals.ReferralsList.length > 0
			) {
				let RefList = this.$data.TempReferrals.ReferralsList;
				RefList.forEach((element: any) => {
					if (element != undefined) {
						let sponsered: any = {};
						sponsered.FirstName = element.FirstName;
						sponsered.LastName = element.LastName;
						sponsered.City = element.City;
						sponsered.isFromWillKit = element.isFromWillKit;
						sponsered.isActivated = element.isActivated;
						sponsered.State = element.State;
						sponsered.StateObj = element.StateObj;
						sponsered.Phone = element.Phone;
						sponsered.RelToSponsor = element.RelToSponsor;
						// if (!IsMMS) {
						// 	let genderedRelation = sponsered.RelToSponsor;
						// 	let Person = PrimaryPerson.query().withAllRecursive().last();
						// 	if (
						// 		this.genderedRelationshipsList[genderedRelation] != undefined
						// 	) {
						// 		if (this.genderedRelationshipsList[genderedRelation] != "N/A") {
						// 			if (
						// 				Person != null &&
						// 				Person != undefined &&
						// 				Person.Gender != undefined &&
						// 				Person.Gender != null &&
						// 				Person.Gender != ""
						// 			) {
						// 				sponsered.RelToSponsor =
						// 					this.genderedRelationshipsList[genderedRelation][
						// 						Person.Gender
						// 					];
						// 			} else sponsered.RelToSponsor = "";
						// 		}
						// 	} else {
						// 		sponsered.RelToSponsor = "";
						// 	}
						// }

						//sponsered.RelToObject: {},
						sponsered.Occupation = element.Occupation;
						sponsered.SignificantOther = element.SignificantOther;
						sponsered.isFavorite = element.isFavorite;
						//sponsered.SelectedBenefits: JSON.parse(
						// sponsered.	JSON.stringify(this.AllSelectedMaterialElements)
						// sponsered.),
						sponsered.AllCheck = element.AllCheckl;
						sponsered.Notes = element.Notes;
						sponsered.AlreadyExist = element.AlreadyExist;
						//sponsered.NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
						sponsered.BranchOfService = element.BranchOfService;
						sponsered.CompleteGroup = element.CompleteGroup;
						sponsered.GroupId = element.GroupId;
						sponsered.GroupCode = element.GroupCode;

						Request.ReferralsList.push(sponsered);
					}
				});
			}
		} else {
			if (
				this.$data.TempReferrals != undefined &&
				this.$data.TempReferrals.ReferralsList != undefined &&
				this.$data.TempReferrals.ReferralsList.length > 0
			) {
				if (
					this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex] !=
					undefined
				) {
					let RefList =
						this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex];
					Request.SponsorshipReferralGuid = RefList.ReferralGuid;

					if (RefList.ReferralChecked == true) {
						Request.PhoneList.push({
							Name: RefList.FirstName + " " + RefList.LastName,
							PhoneNo: RefList.Phone,
							Checked: RefList.ReferralChecked,
						});
					}

					if (
						RefList.GroupExtraPhone != undefined &&
						RefList.GroupExtraPhone.length > 0
					) {
						let PhoneList = RefList.GroupExtraPhone;
						PhoneList.forEach((element: any) => {
							if (element != undefined) {
								let extranumbers: any = {};
								extranumbers.Name = element.Name;
								extranumbers.PhoneNo = element.PhoneNo;
								extranumbers.Checked = element.Checked;
								if (extranumbers.Checked == true)
									Request.PhoneList.push(extranumbers);
							}
						});
					}
					// if(this.IsGroupSmsEditable){
					// Request.smsInfo =  this.GetEditedGroupSMS();
					// }
					// else{
					// 	Request.smsInfo = this.MessagePreview;
					// }
					Request.smsInfo = IsMMS ? "" : this.MessagePreview;

					// RefList.forEach((element: any) => {
					// 	if (element != undefined && !this.isReferralEmpty(element)) {
					let sponsered: any = {};
					let VeteranBenefits = [
						"$2,000 AD&D Benefit",
						"Burial & Will Kit",
						"Health Service Discount Card",
					];
					sponsered.FirstName = RefList.FirstName;
					sponsered.LastName = RefList.LastName;
					sponsered.City = RefList.City;
					sponsered.isFromWillKit = RefList.isFromWillKit;
					sponsered.isActivated = RefList.isActivated;
					sponsered.State = RefList.State;
					sponsered.StateObj = RefList.StateObj;
					sponsered.Phone = RefList.Phone;
					sponsered.RelToSponsor = RefList.RelToSponsor;
					if (!IsMMS) {
						let genderedRelation = sponsered.RelToSponsor;
						// let Person = PrimaryPerson.query().withAllRecursive().last();
						if (genderedRelation != undefined) {
							for (let key in this.genderedRelationshipsList) {
								if (key.toLowerCase() == genderedRelation.toLowerCase()) {
									genderedRelation = key;
									break;
								}
							}
						}
						if (
							genderedRelation != undefined &&
							this.genderedRelationshipsList[genderedRelation] != undefined
						) {
							if (this.genderedRelationshipsList[genderedRelation] != "N/A") {
								if (
									Request.ClientGender != undefined &&
									Request.ClientGender != null &&
									Request.ClientGender != ""
								) {
									sponsered.RelToSponsor =
										this.genderedRelationshipsList[genderedRelation][
											Request.ClientGender
										];
								} else sponsered.RelToSponsor = "";
							}
						} else {
							sponsered.RelToSponsor = "";
						}
					}

					//sponsered.RelToObject: {},
					sponsered.Occupation = RefList.Occupation;
					sponsered.SignificantOther = RefList.SignificantOther;
					sponsered.isFavorite = RefList.isFavorite;
					// sponsered.	JSON.stringify(this.AllSelectedMaterialElements)
					// sponsered.),
					sponsered.AllCheck = RefList.AllCheck;
					sponsered.ReferralChecked = RefList.ReferralChecked;
					sponsered.Notes = RefList.Notes;
					sponsered.AlreadyExist = RefList.AlreadyExist;
					//sponsered.NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
					sponsered.BranchOfService = RefList.BranchOfService;
					sponsered.CompleteGroup = RefList.CompleteGroup;
					sponsered.GroupId = RefList.GroupId;
					sponsered.GroupCode = RefList.GroupCode;

					Request.SelectedGroupCodeId = RefList.GroupId;
					Request.SelectedGroupCode = RefList.GroupCode;
					sponsered.SelectedBenefits =
						Request.PresentationTypeId != PresentationType.Veteran &&
						Request.PresentationTypeId != PresentationType.ServiceMemberBenefits
							? RefList.SelectedBenefits != undefined
								? RefList.SelectedBenefits
								: []
							: VeteranBenefits;
					Request.IsTraining =
						Request.SelectedGroupCode == "IGTRN" ? true : Request.IsTraining;
					Request.ReferralsList.push(sponsered);
				}
			}
		}
		return Request;
	}

	async SendClientorGroupMMSCall(ClientSMSRequest: any) {
		this.$store.state.loader = true;
		var GenericKeyPairobj: GenericKeyPair[] = [];
		var circleloader2 = document.getElementsByClassName("circle-loader2");
		var circleloader3 = document.getElementsByClassName("circle-loader3");
		await APIService.SendClientOrGroupMMS(
			ClientSMSRequest,
			GenericKeyPairobj
		).then(async (res: any) => {
			if (ClientSMSRequest.IsClientText) {
				this.$data.TempReferrals.IsImageSent = true;
				this.$data.TempReferrals.IsImageRecentlySent = true;
				this.$store.state.isImageSentRecently =
					this.$data.TempReferrals.IsImageRecentlySent;
				circleloader2[0].classList.add("load-complete");
			} else {
				this.$data.IsGroupImageSent = true;
				this.$data.IsGroupImageRecentlySent = true;
				this.$store.state.CurrentPath = this.$router.currentRoute.fullPath;
				this.$data.TempReferrals.ReferralsList[
					this.SelectedGroupIndex
				].IsImageSent = this.$data.IsGroupImageSent;
				this.$data.TempReferrals.ReferralsList[
					this.SelectedGroupIndex
				].IsImageRecentlySent = this.$data.IsGroupImageRecentlySent;
				circleloader3[0].classList.add("load-complete");
			}
			if (
				res != undefined &&
				res.data != null &&
				res.data.MessageQeued == true
			) {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("General.MessageQueued");
				this.$store.state.showMessage = true;
				if (ClientSMSRequest.IsClientText) {
					this.$data.TempReferrals.ClientMMSLimitCount += 1;
				}
				if (
					res.data.MessageCount != null &&
					res.data.MessageCount != undefined
				) {
					this.User.MessageCount = res.data.MessageCount;
				}
				if (
					res.data.TrainingMessageCount != null &&
					res.data.TrainingMessageCount != undefined
				) {
					this.User.TrainingMessageCount = res.data.TrainingMessageCount;
				}

				let CurrentUser = localStorage.getItem("user");
				if (CurrentUser != undefined) {
					var userObj = JSON.parse(CurrentUser);
					userObj.UserDetail.MessageCount = this.User.MessageCount;
					userObj.UserDetail.TrainingMessageCount =
						this.User.TrainingMessageCount;
				}
				localStorage.setItem("user", JSON.stringify(userObj));
			} else if (
				res != undefined &&
				res.data != null &&
				res.data.MessageQeued == false &&
				res.data.Result == "STOP"
			) {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("General.MessageOptedOut");
				this.$store.state.showMessage = true;
			} else {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("General.MessageUnQueued");
				this.$store.state.showMessage = true;
			}

			setTimeout(() => {
				this.isActive = false;
				this.clientimagenotification = false;
				this.groupimagenotification = false;
			}, 2000);
		});
	}
	async SendClientorGroupSMSCall(ClientSMSRequest: any) {
		this.$store.state.loader = true;
		var GenericKeyPairobj: GenericKeyPair[] = [];
		var circleloader = document.getElementsByClassName("circle-loader");
		var circleloader1 = document.getElementsByClassName("circle-loader1");
		await APIService.SendClientOrGroupSMS(
			ClientSMSRequest,
			GenericKeyPairobj
		).then(async (res: any) => {
			if (ClientSMSRequest.IsClientText) {
				this.$data.TempReferrals.IsSent = true;
				this.$data.TempReferrals.IsRecentlySent = true;
				this.$store.state.isMessageSentRecently =
					this.$data.TempReferrals.IsRecentlySent;
				circleloader[0].classList.add("load-complete");
			} else {
				this.$data.IsGroupSent = true;
				this.$data.IsGroupRecentlySent = true;
				this.$store.state.CurrentPath = this.$router.currentRoute.fullPath;
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].IsSent =
					this.$data.IsGroupSent;
				this.$data.TempReferrals.ReferralsList[
					this.SelectedGroupIndex
				].IsRecentlySent = this.$data.IsGroupRecentlySent;
				circleloader1[0].classList.add("load-complete");
			}

			if (
				res != undefined &&
				res.data != null &&
				res.data.MessageQeued == true
			) {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("General.MessageQueued");
				this.$store.state.showMessage = true;
				if (ClientSMSRequest.IsClientText) {
					this.$data.TempReferrals.ClientSMSLimitCount += 1;
				} else {
					this.$data.TempReferrals.ReferralsList[
						this.SelectedGroupIndex
					].GroupSMSLimitCount += 1;
				}
				if (
					res.data.MessageCount != null &&
					res.data.MessageCount != undefined
				) {
					this.User.MessageCount = res.data.MessageCount;
				}
				if (
					res.data.TrainingMessageCount != null &&
					res.data.TrainingMessageCount != undefined
				) {
					this.User.TrainingMessageCount = res.data.TrainingMessageCount;
				}

				let CurrentUser = localStorage.getItem("user");
				if (CurrentUser != undefined) {
					var userObj = JSON.parse(CurrentUser);
					userObj.UserDetail.MessageCount = this.User.MessageCount;
					userObj.UserDetail.TrainingMessageCount =
						this.User.TrainingMessageCount;
				}
				localStorage.setItem("user", JSON.stringify(userObj));
			} else if (
				res != undefined &&
				res.data != null &&
				res.data.MessageQeued == false &&
				res.data.Result == "STOP"
			) {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("General.MessageOptedOut");
				this.$store.state.showMessage = true;
			} else {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("General.MessageUnQueued");
				this.$store.state.showMessage = true;
			}

			setTimeout(() => {
				this.isActive = false;
				this.sponsornotification = false;
				// this.groupsponsornotification = false;
			}, 2000);
		});
	}

	async SendClientorGroupEmailCall(ClientSMSRequest: any) {
		this.$store.state.loader = true;
		var GenericKeyPairobj: GenericKeyPair[] = [];
		var circleloader = document.getElementsByClassName("circle-loader");
		var circleloader1 = document.getElementsByClassName("circle-loader1");
		await APIService.SendClientOrGroupEmail(
			ClientSMSRequest,
			GenericKeyPairobj
		).then(async (res: any) => {
			this.$data.IsGroupEmailSent = true;
			this.$data.IsGroupEmailRecentlySent = true;
			this.$store.state.CurrentPath = this.$router.currentRoute.fullPath;
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsEmailSent = this.$data.IsGroupEmailSent;
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsEmailRecentlySent = this.$data.IsGroupEmailRecentlySent;
			circleloader1[0].classList.add("load-complete");
			if (res != undefined && res.data != null && res.data == true) {
				this.$store.state.loader = false;
				this.$store.state.messageText = "Email Sent Successfully";
				this.$store.state.showMessage = true;
				// if (ClientSMSRequest.IsClientText) {
				// 	this.$data.TempReferrals.ClientSMSLimitCount += 0;
				// } else {
				// 	this.$data.TempReferrals.ReferralsList[
				// 		this.SelectedGroupIndex
				// 	].GroupSMSLimitCount += 0;
				// }
				// if (
				// 	res.data.MessageCount != null &&
				// 	res.data.MessageCount != undefined
				// ) {
				// 	this.User.MessageCount = res.data.MessageCount;
				// }
				// if (
				// 	res.data.TrainingMessageCount != null &&
				// 	res.data.TrainingMessageCount != undefined
				// ) {
				// 	this.User.TrainingMessageCount = res.data.TrainingMessageCount;
				// }

				// let CurrentUser = localStorage.getItem("user");
				// if (CurrentUser != undefined) {
				// 	var userObj = JSON.parse(CurrentUser);
				// 	userObj.UserDetail.MessageCount = this.User.MessageCount;
				// 	userObj.UserDetail.TrainingMessageCount =
				// 		this.User.TrainingMessageCount;
				// }
				// localStorage.setItem("user", JSON.stringify(userObj));
			} else {
				this.$store.state.loader = false;
				this.$store.state.messageText = "Unable to Send Email";
				this.$store.state.showMessage = true;
			}

			setTimeout(() => {
				this.isActive = false;
				this.sponsornotification = false;
				// this.groupsponsornotification = false;
			}, 2000);
		});
	}

	isReferralEmpty(Referrals: any) {
		if (
			Referrals.FirstName != undefined &&
			Referrals.LastName != undefined &&
			Referrals.City != undefined &&
			Referrals != "" &&
			Referrals.State != undefined &&
			Referrals.Phone != undefined &&
			Referrals.FirstName != "" &&
			Referrals.LastName != "" &&
			Referrals.City != "" &&
			Referrals != "" &&
			Referrals.State != "" &&
			Referrals.Phone != ""
		)
			return false;
		return true;
	}

	async getSponsorDropdownVal() {}

	SponsorBenefitChange() {
		var GiftCardLength = 0;
		var obj = this.$data.TempReferrals.ReferralsList;
		if (obj != undefined) {
			obj.forEach(function (value: any) {
				if (value.SelectedBenefits != undefined) {
					if (
						value.SelectedBenefits.indexOf("$2000 GIFT CERTIFICATE") > -1 &&
						value.isActivated == true
					) {
						GiftCardLength++;
					}
				}
			});
		}

		if (GiftCardLength > 0) {
			var giftedAmount = GiftCardLength * 2000;
			this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
		} else {
			this.GiftAmount = ConvertIntoAmountFormat(0);
		}
	}

	OpenLeadInbox() {
		window.open(process.env.VUE_APP_LeadInbox_LINK, "_blank");
	}

	async loadDropdown() {
		this.StateList = await getPersistedState("StateList");
		this.stateListPromise = this.StateList.filter((x: any) => x.CountryId != 3);
	}

	ItemCheckClick(item: any, evt: any) {
		item.Selected = evt;
	}

	OnBenefitsBlurred(item: any, evt: any) {
		var selItems = item;
		if (selItems.SelectedBenefits != undefined) {
			var allExist = true;
			this.AllSelectedMaterialElements.forEach((itemAllMater: any) => {
				var exist = selItems.SelectedBenefits.find(
					(x: any) => x == itemAllMater
				);
				if (exist == undefined) {
					allExist = false;
				}
			});
			item.AllCheck = allExist;
		}
	}

	@Watch("ResetToDefault")
	OnResetChange(newValue: any, oldValue: any) {
		this.loadDropdown();
		if (
			this.ReferralWatch != undefined &&
			this.ReferralWatch.ReferredBy != undefined
		) {
			if (
				!(
					this.ReferralWatch.SponsorshipGuid != null &&
					this.ReferralWatch.SponsorshipGuid != undefined
				)
			) {
				this.ReferralWatch.SponsorshipGuid = EntityHelper.uuid();
			}
			this.$data.Referrals = this.ReferralWatch;
			this.$data.Referrals.Date = moment(new Date()).format("MMMM DD, YYYY");
		} else {
			this.$data.Referrals = {
				SponsorshipGuid: EntityHelper.uuid(),
				ReferredBy: "",
				ReferredByLastName: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: moment(new Date()).format("MMMM DD, YYYY"),
				ReferralsList: [],
				CCEmail: [],
				ExtraPhone: [],
			};
		}

		this.$nextTick(() => this.GetWillKitRef());
		//this.$nextTick(() => this.GetFamilyCareRef());
		this.$nextTick(() => this.GetAdultChildrenRef());
		this.$data.TempReferrals = JSON.parse(JSON.stringify(this.$data.Referrals));
		this.IsRouteChangedBeforeTimeout();
		this.RefData = JSON.parse(JSON.stringify(this.$data.Referrals));
		var giftedAmount = this.$data.TempReferrals.ReferralsList.length * 2000;
		this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
		this.FilterNoCostListonSubType();
		this.SponsorBenefitChange();
		this.OnNoCostMaterialChange(this.NoCostBenefitsList, undefined);
		this.$refs.emailfield.$el.classList.remove("error--text");
		// this.$data.Referrals.ReferralsList.forEach((element: any) => {
		// 	if (element.SelectedBenefits == undefined)
		// 		element.SelectedBenefits = JSON.parse(
		// 			JSON.stringify(this.AllSelectedMaterialElements)
		// 		);
		// });
	}

	FilterNoCostListonSubType() {
		if (this.Presentation?.PresentationTypeId == PresentationType.Veteran) {
			if (
				this.Presentation?.SubType.toUpperCase() ==
				"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
			) {
				var threeimpind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "3 IMPORTANT FACTS"
				);
				var familyinfoguidevetind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
				);

				if (threeimpind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(threeimpind),
						1
					);
				}

				if (familyinfoguidevetind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(familyinfoguidevetind),
						1
					);
				}

				this.NoCostBenefitsList.forEach((material: any) => {
					if (material.Name.toUpperCase() == "VETERAN SURVEY") {
						material.Name = "SURVEY FOR VETERANS & THEIR FAMILIES";
					}
				});
			} else {
				var smbbenifitind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
				);
				var smbthreeimpind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
				);
				var smbnovetleftbehindind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
				);

				if (smbbenifitind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(smbbenifitind),
						1
					);
				}
				if (smbthreeimpind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(smbthreeimpind),
						1
					);
				}
				if (smbnovetleftbehindind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(smbnovetleftbehindind),
						1
					);
				}
			}
		} else if (
			this.Presentation?.PresentationTypeId ==
			PresentationType.ServiceMemberBenefits
		) {
			if (
				this.Presentation?.SubType.toUpperCase() ==
					"SPONSORED VETERAN LEAD (ALPHA)" ||
				this.Presentation?.SubType.toUpperCase() ==
					"VETERAN FAMILY LEAD (ALPHA)" ||
				this.Presentation?.SubType.toUpperCase() == "CIVILIAN" ||
				this.Presentation?.SubType.toUpperCase() ==
					"SERVICE MEMBER BENEFIT NOTIFICATION"
			) {
				var threeimpind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "3 IMPORTANT FACTS"
				);

				var familyinfoguidevetind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
				);

				var giftcertificateind = this.NoCostBenefitsList.find(
					(x: any) =>
						x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
				);

				if (threeimpind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(threeimpind),
						1
					);
				}

				if (familyinfoguidevetind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(familyinfoguidevetind),
						1
					);
				}

				if (giftcertificateind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(giftcertificateind),
						1
					);
				}

				this.NoCostBenefitsList.forEach((material: any) => {
					if (material.Name.toUpperCase() == "VETERAN SURVEY") {
						material.Name = "SURVEY FOR VETERANS & THEIR FAMILIES";
					}
				});
			} else {
				var smbbenifitind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
				);

				var smbthreeimpind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
				);

				var smbnovetleftbehindind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
				);

				var giftcertificateind = this.NoCostBenefitsList.find(
					(x: any) => x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == true
				);

				if (smbbenifitind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(smbbenifitind),
						1
					);
				}

				if (smbthreeimpind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(smbthreeimpind),
						1
					);
				}

				if (smbnovetleftbehindind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(smbnovetleftbehindind),
						1
					);
				}

				if (giftcertificateind != undefined) {
					this.NoCostBenefitsList.splice(
						this.NoCostBenefitsList.indexOf(giftcertificateind),
						1
					);
				}
			}
		}
	}
	OnNoCostMaterialChange(newValue: any, oldValue: any) {
		if (newValue != undefined && newValue.length > 0) {
			this.AllSelectedMaterialElements = [];

			newValue.forEach((nocostMat: any) => {
				if (nocostMat.MaterialName == "$2000 GIFT CERTIFICATE") {
					nocostMat.ShowOnExpand = false;
					nocostMat.SortOrder = 0;
				}
				if (
					nocostMat.ShowOnExpand == false &&
					nocostMat.ExcludeFromReferrral == false
				) {
					nocostMat.Selected = true;
					this.AllSelectedMaterialElements.push(nocostMat.Name);
				} else {
					nocostMat.Selected = false;
				}
			});

			newValue.sort((a: any, b: any) => a.SortOrder - b.SortOrder);

			this.AllSelectedMaterialElements = JSON.parse(
				JSON.stringify(this.AllSelectedMaterialElements)
			);
		}
	}

	AllCheckChange(evt: any, itemRef: any) {
		if (evt == true) {
			this.OnNoCostMaterialChange(itemRef.NoCostBenefitsList, undefined);
			itemRef.SelectedBenefits = JSON.parse(
				JSON.stringify(this.AllSelectedMaterialElements)
			);
		} else {
			itemRef.NoCostBenefitsList.forEach((elementNoCost: any) => {
				elementNoCost.Selected = false;
			});
			itemRef.SelectedBenefits = undefined;
		}
		this.SponsorBenefitChange();
	}

	data() {
		return {
			TempReferrals: {},
			notesdialog: false,
			disabledSaveBtn: false,
			Referrals: undefined,
			SponsorDatePopup: false,
			Notes: undefined,
			EmailLoader: false,
			RequiredGroup: [(v: any) => !!v || "Field is required"],
			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => (!!v && !!v.trim()) || "Field is required",
				(v: any) =>
					(!!v && v.length <= 50) || "Field must be less than 50 characters",
			],
			PhoneRules: [(v: any) => (!!v && v.length > 13) || "Phone must be valid"],
			EmailRules: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return false;
					}
				},
			],
			activator: null,
			attach: null,
			editing: null,
			editingIndex: -1,
			items: [],
			nonce: 1,
			menu: false,
			model: [],
			x: 0,
			search: null,
			y: 0,
			IsGroupSent: false,
			IsGroupRecentlySent: false,
			IsGroupEmailSent: false,
			IsGroupEmailRecentlySent: false,
			IsGroupImageSent: false,
			IsGroupImageRecentlySent: false,
		};
	}

	@Watch("combomodel")
	onComboModelChange(val: any, prev: any) {
		if (val.length === prev.length) return;
		this.combomodel = val.map((v: any) => {
			if (typeof v === "string") {
				var text = v;
				var valid =
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(text) ||
					text.trim() == "";
				if (valid) {
					v = {
						text: v,
						color: "#1f1f1f",
					};
					this.items.push(v);
					this.nonce++;
				}
			}
			return v;
		});
	}

	edit(index: any, item: any) {
		if (!this.$data.editing) {
			this.$data.editing = item;
			this.$data.editingIndex = index;
		} else {
			this.$data.editing = null;
			this.$data.editingIndex = -1;
		}
	}
	filter(item: any, queryText: any, itemText: any) {
		if (item.header) return false;

		const hasValue = (val: any) => (val != null ? val : "");

		const text = hasValue(itemText);
		const query = hasValue(queryText);

		return (
			text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
		);
	}

	addReferral() {
		this.GroupMMSClicked = false;
		if (this.$data.TempReferrals.ReferralsList == undefined)
			this.$data.TempReferrals.ReferralsList = [];
		if (this.NoCostBenefitsList.length == 0) {
			this.NoCostBenefitsList = this.getSponsorDropdownVal();
		}
		var filteredBeneftis = this.NoCostBenefitsList.filter(
			(x: any) =>
				x.ExcludeFromReferrral == undefined || x.ExcludeFromReferrral == false
		);
		var InitialCompleteGroup = undefined;
		if (
			this.ReferralGroupCode != undefined &&
			this.ReferralGroupCode.length == 1
		) {
			InitialCompleteGroup = this.ReferralGroupCode[0];
		}
		if (
			this.$data.TempReferrals.ReferralsList != undefined &&
			this.$data.TempReferrals.ReferralsList != null &&
			this.$data.TempReferrals.ReferralsList.length > 0
		) {
			var res =
				this.$data.TempReferrals.ReferralsList[
					this.$data.TempReferrals.ReferralsList.length - 1
				];
			this.indexId = res.Id + 1;
		}
		this.$data.TempReferrals.ReferralsList.push({
			Id: this.indexId,
			ReferralGuid: EntityHelper.uuid(),
			FirstName: "",
			LastName: "",
			City: "",
			isFromWillKit: false,
			isActivated: true,
			State: "",
			StateObj: "",
			Phone: "",
			RelToSponsor: "",
			RelToObject: {},
			Occupation: "",
			SignificantOther: "",
			isFavorite: false,
			SelectedBenefits: JSON.parse(
				JSON.stringify(this.AllSelectedMaterialElements)
			),
			AllCheck: true,
			Notes: "",
			AlreadyExist: false,
			NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
			BranchOfService: "",
			CompleteGroup: InitialCompleteGroup,
			GroupId: InitialCompleteGroup != null ? InitialCompleteGroup.Id : null,
			GroupCode:
				InitialCompleteGroup != null ? InitialCompleteGroup.GroupCode : null,
		});
		this.indexId = this.indexId + 1;

		var giftedAmount = this.$data.TempReferrals.ReferralsList.length * 2000;
		this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
		this.SponsorBenefitChange();

		var svview = document.getElementsByClassName("formscroll");
		if (
			svview != undefined &&
			svview.length > 0 &&
			svview[0].children.length > 0
		) {
			{
				setTimeout(() => {
					svview[0].children[svview[0].children.length - 1].scrollIntoView(
						true
					);
				}, 50);
			}
		}
	}

	addReferralWillKit(obj: any) {
		if (this.$data.TempReferrals.ReferralsList == undefined)
			this.$data.TempReferrals.ReferralsList = [];
		if (this.NoCostBenefitsList.length == 0) {
			this.NoCostBenefitsList = this.getSponsorDropdownVal();
		}
		var filteredBeneftis = this.NoCostBenefitsList.filter(
			(x: any) =>
				x.ExcludeFromReferrral == undefined || x.ExcludeFromReferrral == false
		);
		var InitialCompleteGroup = undefined;
		if (
			this.ReferralGroupCode != undefined &&
			this.ReferralGroupCode.length == 1
		) {
			InitialCompleteGroup = this.ReferralGroupCode[0];
		}

		let addsponser = true;
		if (
			this.$data.TempReferrals.ReferralsList != undefined &&
			this.$data.TempReferrals.ReferralsList != null &&
			this.$data.TempReferrals.ReferralsList.length > 0
		) {
			for (
				let index = 0;
				index < this.$data.TempReferrals.ReferralsList.length;
				index++
			) {
				const element = this.$data.TempReferrals.ReferralsList[index];

				if (
					element.isActivated &&
					element.AlreadyExist &&
					element.FirstName == obj.FirstName &&
					element.LastName == obj.LastName
				) {
					addsponser = false;
				}
			}

			if (addsponser) {
				var res =
					this.$data.TempReferrals.ReferralsList[
						this.$data.TempReferrals.ReferralsList.length - 1
					];
				this.indexId = res.Id + 1;
			}
		}

		let SignificantOther = null;
		if (
			obj.SignificantOther != undefined &&
			obj.SignificantOtherLast != undefined
		) {
			SignificantOther = obj.SignificantOther + " " + obj.SignificantOtherLast;
		}
		if (addsponser) {
			this.$data.TempReferrals.ReferralsList.push({
				Id: this.indexId,
				ReferralGuid: EntityHelper.uuid(),
				FirstName: obj.FirstName,
				LastName: obj.LastName,
				City: obj.City,
				isFromWillKit: true,
				isActivated: false,
				State: obj?.State?.StateCode != undefined ? obj.State.StateCode : "",
				StateObj: obj?.State != undefined ? obj.State : null,
				Phone: obj.Phone,
				RelToSponsor:
					obj.RelationshipObj != undefined ? obj.RelationshipObj : "",
				RelToObject: {},
				Occupation: "",
				SignificantOther: SignificantOther != null ? SignificantOther : " ",
				isFavorite: false,
				SelectedBenefits: JSON.parse(
					JSON.stringify(this.AllSelectedMaterialElements)
				),
				AllCheck: true,
				Notes: "",
				AlreadyExist: false,
				NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
				BranchOfService: obj.Branch,
				CompleteGroup: InitialCompleteGroup,
				GroupId: obj.GroupId,
				GroupCode: obj.GroupCode,
			});
			this.indexId = this.indexId + 1;
		}

		var giftedAmount = this.$data.TempReferrals.ReferralsList.length * 2000;
		this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
		this.SponsorBenefitChange();

		var svview = document.getElementsByClassName("formscroll");
		if (
			svview != undefined &&
			svview.length > 0 &&
			svview[0].children.length > 0
		) {
			{
				setTimeout(() => {
					svview[0].children[svview[0].children.length - 1].scrollIntoView(
						true
					);
				}, 50);
			}
		}
	}
	addReferralFamilyCare(obj: any) {
		if (this.$data.TempReferrals.ReferralsList == undefined)
			this.$data.TempReferrals.ReferralsList = [];
		if (this.NoCostBenefitsList.length == 0) {
			this.NoCostBenefitsList = this.getSponsorDropdownVal();
		}
		var filteredBeneftis = this.NoCostBenefitsList.filter(
			(x: any) =>
				x.ExcludeFromReferrral == undefined || x.ExcludeFromReferrral == false
		);
		var InitialCompleteGroup = undefined;
		if (
			this.ReferralGroupCode != undefined &&
			this.ReferralGroupCode.length == 1
		) {
			InitialCompleteGroup = this.ReferralGroupCode[0];
		}
		let addsponser = true;
		let removeindex = null;
		if (
			this.$data.TempReferrals.ReferralsList != undefined &&
			this.$data.TempReferrals.ReferralsList != null &&
			this.$data.TempReferrals.ReferralsList.length > 0
		) {
			for (
				let index = 0;
				index < this.$data.TempReferrals.ReferralsList.length;
				index++
			) {
				const element = this.$data.TempReferrals.ReferralsList[index];
				// if (!element.AlreadyExist) {
				var state = obj.State == "" ? "" : obj.State.StateCode;
				if (
					element.FirstName == obj.FirstName &&
					element.LastName == obj.LastName &&
					element.City == obj.City &&
					element.State == state &&
					element.ReltoSponsor == obj.ReltoSponsor &&
					element.Occupation == obj.Occupation
				) {
					addsponser = false;
				}

				if (
					element.FirstName == obj.FirstName &&
					element.LastName == obj.LastName
				) {
					removeindex = index;
					addsponser = false;
				} else if (removeindex != null) {
					addsponser = false;
				}
				// }
			}
			if (
				removeindex != null &&
				!this.$data.TempReferrals.ReferralsList[removeindex].isActivated &&
				!this.$data.TempReferrals.ReferralsList[removeindex].AlreadyExist
			) {
				this.$data.TempReferrals.ReferralsList.splice(removeindex, 1);
				addsponser = true;
			}
			// removesponser.forEach((element: any) => {
			// 	this.$data.TempReferrals.ReferralsList.splice(element, 1);
			// });
			if (addsponser) {
				var res =
					this.$data.TempReferrals.ReferralsList[
						this.$data.TempReferrals.ReferralsList.length - 1
					];

				if (res != undefined) {
					this.indexId = res.Id + 1;
				}
				//this.indexId = res.Id + 1;
			}
			// var res =
			// 	this.$data.TempReferrals.ReferralsList[
			// 		this.$data.TempReferrals.ReferralsList.length - 1
			// 	];
			// this.indexId = res.Id + 1;
		}

		if (addsponser) {
			this.$data.TempReferrals.ReferralsList.push({
				Id: this.indexId,
				ReferralGuid: EntityHelper.uuid(),
				FirstName: obj.FirstName,
				LastName: obj.LastName,
				City: obj.City,
				isFromFamilyCare: true,
				//isFromWillKit: true,
				isActivated: false,
				State: obj.State.StateCode,
				StateObj: obj.State,
				Phone: obj.Phone,
				// RelToSponsor: obj.RelationshipObj,
				RelToObject: {},
				Occupation: obj.Occupation,
				// SignificantOther: obj.SignificantOther + " " + obj.SignificantOtherLast,
				isFavorite: false,
				SelectedBenefits: JSON.parse(
					JSON.stringify(this.AllSelectedMaterialElements)
				),
				AllCheck: true,
				Notes: "",
				AlreadyExist: false,
				NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
				BranchOfService: "",
				CompleteGroup: InitialCompleteGroup,
				GroupId: InitialCompleteGroup != null ? InitialCompleteGroup.Id : null,
				GroupCode:
					InitialCompleteGroup != null ? InitialCompleteGroup.GroupCode : null,
			});
			this.indexId = this.indexId + 1;
		}
		// this.$data.TempReferrals.ReferralsList.push({
		// 	Id: this.indexId,
		//	ReferralGuid: EntityHelper.uuid(),
		// 	FirstName: obj.FirstName,
		// 	LastName: obj.LastName,
		// 	City: obj.City,
		// 	isFromFamilyCare: true,
		// 	isActivated: false,
		// 	State: obj.State.StateCode,
		// 	StateObj: obj.State,
		// 	Phone: obj.Phone,
		// 	// RelToSponsor: obj.RelationshipObj,
		// 	RelToObject: {},
		// 	Occupation: obj.Occupation,
		// 	// SignificantOther: obj.SignificantOther + " " + obj.SignificantOtherLast,
		// 	isFavorite: false,
		// 	SelectedBenefits: JSON.parse(
		// 		JSON.stringify(this.AllSelectedMaterialElements)
		// 	),
		// 	AllCheck: true,
		// 	Notes: "",
		// 	AlreadyExist: false,
		// 	NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
		// 	BranchOfService: "",
		// 	CompleteGroup: InitialCompleteGroup,
		// 	GroupId: InitialCompleteGroup != null ? InitialCompleteGroup.Id : null,
		// 	GroupCode:
		// 		InitialCompleteGroup != null ? InitialCompleteGroup.GroupCode : null,
		// });
		// this.indexId = this.indexId + 1;

		var giftedAmount = this.$data.TempReferrals.ReferralsList.length * 2000;
		this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
		this.SponsorBenefitChange();

		var svview = document.getElementsByClassName("formscroll");
		if (
			svview != undefined &&
			svview.length > 0 &&
			svview[0].children.length > 0
		) {
			{
				setTimeout(() => {
					svview[0].children[svview[0].children.length - 1].scrollIntoView(
						true
					);
				}, 50);
			}
		}
	}
	async created() {
		this.SponsorDOBPicker = moment(new Date()).format("MMMM DD, YYYY");
		let CurrentUser = localStorage.getItem("user");
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			this.User = {
				FirstName: userObj.UserDetail.FirstName,
				LastName: userObj.UserDetail.LastName,
				UserName: userObj.UserName,
				UserId: userObj.UserDetail.UserId,
				AgentNo: userObj.UserDetail.AgentNumber,
				LicNo: "",
				LicState: "",
				PhoneNo: userObj.UserDetail.Phone,
				EmailAddress: userObj.UserDetail.Email,
				MmsServiceAllowed: userObj.UserDetail.MmsServiceAllowed,
				MessageCount: userObj.UserDetail.MessageCount,
				TrainingMessageCount: userObj.UserDetail.TrainingMessageCount,
				PresentTime: userObj.UserDetail.PresentTime,
				TimeZoneOffset: userObj.UserDetail.TimeZoneOffset,
				TwilioNumber: userObj.UserDetail.TwilioNumber,
			};
		}
		this.Presentation = Presentation.query().withAllRecursive().last();
		// this.AgentPhoneNo = this.User.PhoneNo;
	}

	private SelectedItemForNotes: any = undefined;

	ShowNotesDialog(item: any) {
		this.$data.notesdialog = true;
		this.$data.Notes = item.Notes;
		if (item.AlreadyExist) this.$data.disabledSaveBtn = true;
		else this.$data.disabledSaveBtn = false;
		this.SelectedItemForNotes = item;
	}
	saveNotes() {
		this.$data.notesdialog = false;
		//this.$data.Notes = item.Notes;
		this.SelectedItemForNotes.Notes = this.$data.Notes;
	}

	SponsorDOBChange() {
		if (
			this.$data.TempReferrals.Date != undefined &&
			this.$data.TempReferrals.Date != ""
		) {
			this.SponsorDOBPicker = moment(this.$data.TempReferrals.Date).format(
				"MMMM DD, YYYY"
			);
		}
	}

	GetAdultChildrenRef() {
		this.loadDropdown();
		this.GroupMMSClicked = false;
		let Person = PrimaryPerson.query().withAllRecursive().last();
		if (
			Person != null &&
			Person != undefined &&
			Person.AdultChildrenString != undefined &&
			Person.AdultChildrenString != ""
		) {
			this.AdultChildrenforRef = JSON.parse(Person.AdultChildrenString);
			//let children = JSON.parse(Person.AdultChildrenString);

			var ReferralsChildrenobj = JSON.parse(
				JSON.stringify(this.AdultChildrenforRef)
			);
			ReferralsChildrenobj.forEach((element: any) => {
				if (element.FirstName != "") element.ReferralAdded = true;
			});

			this.$data.TempReferrals.ReferralsList =
				this.$data.TempReferrals.ReferralsList.filter((x: any) => {
					if (
						x.isFromWillKit == true ||
						x.AlreadyExist == true
						// ||
						// (x.isActivated == true &&
						// 	x.AlreadyExist == true &&
						// 	x.isFromFamilyCare == true)
					)
						return x;
				});

			this.AdultChildrenforRef.forEach((element: any) => {
				element.Occupation = "";
				// element.State.StateCode =
				// 	element.State.StateCode != undefined ? element.State.StateCode : "";
				this.addReferralFamilyCare(element);
			});
		} else {
			this.$data.TempReferrals.ReferralsList =
				this.$data.TempReferrals.ReferralsList.filter((x: any) => {
					if (
						x.isFromWillKit == true ||
						x.AlreadyExist == true
						// ||
						// (x.isActivated == true &&
						// 	x.AlreadyExist == true &&
						// 	x.isFromFamilyCare == true)
					)
						return x;
				});
		}
	}

	GetFamilyCareRef() {
		this.loadDropdown();
		this.GroupMMSClicked = false;
		if (
			this.PresentationFamilyCare != undefined &&
			this.PresentationFamilyCare != "" &&
			typeof this.PresentationFamilyCare == "string"
		) {
			this.PresentationFamilyCare = JSON.parse(this.PresentationFamilyCare);
		}
		if (
			this.PresentationFamilyCare != undefined &&
			this.PresentationFamilyCare != null
		) {
			if (
				this.PresentationFamilyCare != undefined &&
				this.PresentationFamilyCare != null
			) {
				if (this.PresentationFamilyCare.AdultChildrenString != "") {
					this.AdultChildrenforRef = JSON.parse(
						this.PresentationFamilyCare.AdultChildrenString
					);
				}
				var ReferralsChildrenobj = JSON.parse(
					JSON.stringify(this.AdultChildrenforRef)
				);
				ReferralsChildrenobj.forEach((element: any) => {
					if (element.FirstName != "") element.ReferralAdded = true;
				});

				// this.AdultChildrenforRef = this.AdultChildrenforRef.filter(
				// 	(x: any) => x.ReferralAdded == false && x.FirstName != ""
				// );
				this.$data.TempReferrals.ReferralsList =
					this.$data.TempReferrals.ReferralsList.filter((x: any) => {
						if (
							x.isFromWillKit == true ||
							x.AlreadyExist == true
							// ||
							// (x.isActivated == true &&
							// 	x.AlreadyExist == true &&
							// 	x.isFromFamilyCare == true)
						)
							return x;
					});
				// this.$data.TempReferrals.ReferralsList =
				// 	this.$data.TempReferrals.ReferralsList.filter((x: any) => {
				// 		x.isActivated != true;
				// 	});
				this.AdultChildrenforRef.forEach((element: any) => {
					element.Occupation = "";
					// element.State.StateCode =
					// 	element.State.StateCode != undefined ? element.State.StateCode : "";
					this.addReferralFamilyCare(element);
				});
				this.$emit("SaveFamilyCareInfo", this.PresentationFamilyCare);
			}
		}
	}

	GetWillKitRef() {
		// this.Presentation = Presentation.query().last();
		this.loadDropdown();
		//this.PresentationWillKitVeterans = JSON.parse(this.Presentation.PresentationWillKitVeterans);
		if (
			this.PresentationWillKitVeterans != undefined &&
			this.PresentationWillKitVeterans != "" &&
			typeof this.PresentationWillKitVeterans == "string"
		)
			this.PresentationWillKitVeterans = JSON.parse(
				this.PresentationWillKitVeterans
			);
		if (
			this.PresentationWillKitVeterans != undefined &&
			this.PresentationWillKitVeterans != null
		) {
			if (
				this.PresentationWillKitVeterans.ReferralsFamily != "" &&
				this.PresentationWillKitVeterans.ReferralsFamily != null
			) {
				this.ReferralsFamily = JSON.parse(
					this.PresentationWillKitVeterans.ReferralsFamily
				);
				var ReferralsFamilyobj = JSON.parse(
					JSON.stringify(this.ReferralsFamily)
				);
				ReferralsFamilyobj.forEach((refItem: any) => {
					if (refItem.FirstName != "") refItem.ReferralAdded = true;
				});

				this.ReferralsFamily = this.ReferralsFamily.filter(
					(x: any) => x.ReferralAdded == false && x.FirstName != ""
				);

				this.ReferralsFamily.forEach((refItem: any) => {
					this.addReferralWillKit(refItem);
				});
			}

			if (
				this.PresentationWillKitVeterans.ReferralsAdditional != "" &&
				this.PresentationWillKitVeterans.ReferralsAdditional != null
			) {
				this.ReferralsAdditional = JSON.parse(
					this.PresentationWillKitVeterans.ReferralsAdditional
				);
				var ReferralsAdditionalobj = JSON.parse(
					JSON.stringify(this.ReferralsAdditional)
				);

				ReferralsAdditionalobj.forEach((refItem: any) => {
					if (refItem.FirstName != "") refItem.ReferralAdded = true;
				});

				this.ReferralsAdditional = this.ReferralsAdditional.filter(
					(x: any) => x.ReferralAdded == false && x.FirstName != ""
				);

				this.ReferralsAdditional.forEach((refItem: any) => {
					this.addReferralWillKit(refItem);
				});
			}

			if (
				this.PresentationWillKitVeterans.ReferralsService != "" &&
				this.PresentationWillKitVeterans.ReferralsService != null
			) {
				this.ReferralsService = JSON.parse(
					this.PresentationWillKitVeterans.ReferralsService
				);
				var ReferralsServiceObj = JSON.parse(
					JSON.stringify(this.ReferralsService)
				);
				ReferralsServiceObj.forEach((refItem: any) => {
					if (refItem.FirstName != "") refItem.ReferralAdded = true;
				});

				this.ReferralsService = this.ReferralsService.filter(
					(x: any) => x.ReferralAdded == false && x.FirstName != ""
				);

				this.ReferralsService.forEach((refItem: any) => {
					this.addReferralWillKit(refItem);
				});
			}

			if (
				this.PresentationWillKitVeterans.ReferralsOtherService != "" &&
				this.PresentationWillKitVeterans.ReferralsOtherService != null
			) {
				this.ReferralsOtherService = JSON.parse(
					this.PresentationWillKitVeterans.ReferralsOtherService
				);
				var ReferralsOtherServiceObj = JSON.parse(
					JSON.stringify(this.ReferralsOtherService)
				);
				ReferralsOtherServiceObj.forEach((refItem: any) => {
					if (refItem.FirstName != "") refItem.ReferralAdded = true;
				});

				this.ReferralsOtherService = this.ReferralsOtherService.filter(
					(x: any) => x.ReferralAdded == false && x.FirstName != ""
				);

				this.ReferralsOtherService.forEach((refItem: any) => {
					this.addReferralWillKit(refItem);
				});
			}
			if (
				this.PresentationWillKitVeterans.ReferralsMembers != "" &&
				this.PresentationWillKitVeterans.ReferralsMembers != null
			) {
				this.ReferralsMembers = JSON.parse(
					this.PresentationWillKitVeterans.ReferralsMembers
				);
				var ReferralsMembersobj = JSON.parse(
					JSON.stringify(this.ReferralsMembers)
				);

				ReferralsMembersobj.forEach((refItem: any) => {
					if (refItem.FirstName != "") refItem.ReferralAdded = true;
				});

				this.ReferralsMembers = this.ReferralsMembers.filter(
					(x: any) => x.ReferralAdded == false && x.FirstName != ""
				);

				this.ReferralsMembers.forEach((refItem: any) => {
					this.addReferralWillKit(refItem);
				});
			}

			if (
				this.PresentationWillKitVeterans.ReferralsChildrenFamily != "" &&
				this.PresentationWillKitVeterans.ReferralsChildrenFamily != null
			) {
				this.ReferralsChildrenFamily = JSON.parse(
					this.PresentationWillKitVeterans.ReferralsChildrenFamily
				);
				var ReferralsChildrenFamilyobj = JSON.parse(
					JSON.stringify(this.ReferralsChildrenFamily)
				);

				ReferralsChildrenFamilyobj.forEach((refItem: any) => {
					if (refItem.FirstName != "") refItem.ReferralAdded = true;
				});

				this.ReferralsChildrenFamily = this.ReferralsChildrenFamily.filter(
					(x: any) => x.ReferralAdded == false && x.FirstName != ""
				);

				this.ReferralsChildrenFamily.forEach((refItem: any) => {
					this.addReferralWillKit(refItem);
				});
			}

			this.PresentationWillKitVeterans.ReferralsFamily =
				JSON.stringify(ReferralsFamilyobj);
			this.PresentationWillKitVeterans.ReferralsAdditional = JSON.stringify(
				ReferralsAdditionalobj
			);
			this.PresentationWillKitVeterans.ReferralsService =
				JSON.stringify(ReferralsServiceObj);
			this.PresentationWillKitVeterans.ReferralsOtherService = JSON.stringify(
				ReferralsOtherServiceObj
			);
			this.PresentationWillKitVeterans.ReferralsMembers =
				JSON.stringify(ReferralsMembersobj);
			this.PresentationWillKitVeterans.ReferralsChildrenFamily = JSON.stringify(
				ReferralsChildrenFamilyobj
			);
			this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		}
	}

	deleteReferral(itm: any) {
		this.$data.TempReferrals.ReferralsList =
			this.$data.TempReferrals.ReferralsList.filter(function (item: any) {
				return item.Id !== itm.Id;
			});

		var giftedAmount = this.$data.TempReferrals.ReferralsList.length * 2000;
		this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
		this.SponsorBenefitChange();
	}

	isActivateChange() {
		this.SponsorBenefitChange();
	}
	saveSponsorDOB(date: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MMMM DD, YYYY") : "";
		this.$data.TempReferrals.Date = tempdate;
		this.$data.SponsorDatePopup = false;
	}

	validate(value: string, type: string) {
		if (type == "email") {
			var valid =
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
				value.trim() == "";
			if (!valid) this.$refs.emailfield.$el.classList.add("error--text");
			else this.$refs.emailfield.$el.classList.remove("error--text");
		}
		if (type == "phone") {
			var valid = value.length > 13 || value.trim() == "";
			if (!valid) this.$refs.phonefield.$el.classList.add("error--text");
			else this.$refs.phonefield.$el.classList.remove("error--text");
		}
	}

	saveReferral() {
		if (this.$refs.phonefield.$el.classList.contains("error--text")) {
			this.$store.state.messageText = "Please enter a valid Phone Number.";
			this.$store.state.showMessage = true;
			return;
		}

		if (this.$refs.emailfield.$el.classList.contains("error--text")) {
			this.$store.state.messageText = "Please enter a valid Email Address.";
			this.$store.state.showMessage = true;
			return;
		}
		if (!this.isRefFormValid) {
			this.$refs.ReferralForm?.validate();

			return;
		}

		var result = this.$data.TempReferrals.ReferralsList.find(
			(x: any) => x.GroupId == 0 && x.AlreadyExist == false
		);

		if (result != undefined && result != null) this.confirmSaveDialog = true;
		else this.saveReferralAPICall();
	}

	saveReferralAPICall() {
		this.$store.state.loader = true;
		var nonEmptyReferrals: any = [];
		this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
			if (!this.isEmpty(element)) {
				nonEmptyReferrals.push(element);
			}
		});

		this.$data.TempReferrals.ReferralsList = nonEmptyReferrals;
		this.$data.Referrals = JSON.parse(JSON.stringify(this.$data.TempReferrals));
		this.$emit("SaveReferrals", JSON.stringify(this.$data.Referrals));
		if (
			this.PresentationTypeId == undefined ||
			this.PresentationTypeId == null ||
			this.PresentationTypeId == ""
		) {
			var result = this.$data.TempReferrals.ReferralsList.find(
				(x: any) => x.AlreadyExist == false
			);
			if (result != null) {
				var promises: Promise<any>[] = [];
				var GenericKeyPairobj: GenericKeyPair[] = [];
				promises.push(
					APIService.SaveRefferals(
						this.$data.TempReferrals,
						GenericKeyPairobj
					).then(async (res: any) => {
						this.$store.state.loader = false;

						if (
							res != undefined &&
							res.data != null &&
							res.data.SavedLeadID != null
						) {
							this.$store.state.messageText = this.$t(
								"StartPresentation.SponsorSaveText"
							);
							this.$store.state.Largesnackbar = true;
						}
					})
				);
			} else {
				this.$store.state.loader = false;
			}
		} else {
			var result = this.$data.TempReferrals.ReferralsList.find(
				(x: any) => x.AlreadyExist == false && x.isActivated == true
			);

			if (
				result != null &&
				this.PresentationTypeId != PresentationType.Demo &&
				this.MemberLookupTypeId != MemberLookupType.Demo &&
				this.MemberLookupTypeId != MemberLookupType.Training
			) {
				var promises: Promise<any>[] = [];
				var GenericKeyPairobj: GenericKeyPair[] = [];
				promises.push(
					APIService.SaveRefferals(
						this.$data.TempReferrals,
						GenericKeyPairobj
					).then(async (res: any) => {
						this.$store.state.loader = false;
						if (
							res != undefined &&
							res.data != null &&
							res.data.SavedLeadID != null
						) {
							if (this.NoCostBenefitsList.length == 0) {
								this.NoCostBenefitsList = this.getSponsorDropdownVal();
							}
							var filteredBeneftis = this.NoCostBenefitsList.filter(
								(x: any) =>
									x.ExcludeFromReferrral == undefined ||
									x.ExcludeFromReferrral == false
							);

							this.$store.state.messageText = this.$t(
								"StartPresentation.SponsorSaveText"
							);
							this.$store.state.Largesnackbar = true;
							res.data.Date = moment(new Date()).format("MMMM DD, YYYY");
							this.$data.TempReferrals = res.data;
							if (
								this.$data.TempReferrals.ExtraPhone != undefined &&
								this.$data.TempReferrals.ExtraPhone.length > 0
							) {
								for (
									let i = 0;
									i < this.$data.TempReferrals.ExtraPhone.length;
									i++
								) {
									if (
										this.$data.TempReferrals.ExtraPhone[i].IsNameValid ==
										undefined
									)
										Vue.set(
											this.$data.TempReferrals.ExtraPhone[i],
											"IsNameValid",
											true
										);
									if (
										this.$data.TempReferrals.ExtraPhone[i].IsPhoneValid ==
										undefined
									)
										Vue.set(
											this.$data.TempReferrals.ExtraPhone[i],
											"IsPhoneValid",
											true
										);
								}
							}
							if (
								this.$data.TempReferrals.ReferralsList != undefined &&
								this.$data.TempReferrals.ReferralsList != null
							) {
								this.$data.TempReferrals.ReferralsList.forEach(
									(refItem: any) => {
										if (refItem.CompleteGroup == undefined) {
											refItem.CompleteGroup = this.ReferralGroupCode.find(
												(x: any) =>
													x.GroupCode == refItem.GroupCode ||
													x.Id == refItem.GroupId
											);
											if (
												refItem.GroupCode == undefined ||
												refItem.GroupCode == ""
											)
												refItem.GroupCode = refItem.CompleteGroup?.GroupCode;
											refItem.NoCostBenefitsList = JSON.parse(
												JSON.stringify(filteredBeneftis)
											);
										}
										refItem.StateObj = this.StateList.find(
											(x: any) => x.StateCode == refItem.State
										);
									}
								);
							}

							this.$data.Referrals = JSON.parse(
								JSON.stringify(this.$data.TempReferrals)
							);

							this.$emit("SaveReferrals", JSON.stringify(this.$data.Referrals));
						}
					})
				);
			} else {
				this.$store.state.loader = false;
			}

			if (
				this.PresentationTypeId == PresentationType.Demo ||
				this.MemberLookupTypeId == MemberLookupType.Demo ||
				this.MemberLookupTypeId == MemberLookupType.Training
			) {
				var result = this.$data.TempReferrals.ReferralsList.find(
					(x: any) => x.AlreadyExist == false
				);

				if (result != null) {
					this.$store.state.messageText = this.$t(
						"StartPresentation.SponsorSaveText"
					);
					this.$store.state.Largesnackbar = true;
				}

				if (this.$data.TempReferrals.ReferralsList != undefined) {
					this.$data.TempReferrals.ReferralsList.forEach((refItem: any) => {
						refItem.AlreadyExist = true;
					});
					this.$data.Referrals = JSON.parse(
						JSON.stringify(this.$data.TempReferrals)
					);

					this.$emit("SaveReferrals", JSON.stringify(this.$data.Referrals));
				}
			}
		}
	}

	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;

		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;

		if (referral.City != null && referral.City.trim() != "") return false;

		if (referral.State != null && referral.State.trim() != "") return false;

		if (referral.Phone != null && referral.Phone.trim() != "") return false;

		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;

		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;

		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;

		return true;
	}

	ClosePopup() {
		// if(this.ShowHomeVisibility || JSON.stringify(this.RefData) == JSON.stringify(this.$data.TempReferrals)){
		//   this.$refs.emailfield.$el.classList.remove("error--text");
		//   this.$emit("ClosePopup");
		// }
		// else
		// {
		//   this.ShowHomeVisibility = true;
		// }
		this.saveReferral();
	}

	GroupSelected(groupItem: any, refItem: any) {
		if (groupItem != undefined && refItem != undefined) {
			refItem.GroupId = groupItem.Id;
			refItem.GroupCode = groupItem.GroupCode;
		}
	}

	async LoadGroupCodes() {
		var countryidval: any = 0;
		var presentationidval = 0;
		if (
			this.CountryId == undefined ||
			this.CountryId == null ||
			this.CountryId == 0
		) {
			countryidval = await GetUserCountry();
		} else {
			countryidval = this.CountryId;
		}
		if (
			this.PresentationTypeId == undefined ||
			this.PresentationTypeId == null ||
			this.PresentationTypeId == ""
		) {
			presentationidval = 0;
		} else {
			presentationidval = this.PresentationTypeId;
		}

		let parameters: GenericKeyPair[] = [];
		this.$store.state.loader = true;
		GetApiDataOrCheckLocal(
			ApiEndPointConstants.ReferralGroupCodeEndpoint,
			parameters,
			IndexDBConstants.ReferralGroupCode
		)
			.then((res: any) => {
				this.$store.state.loader = false;
				if (res != undefined) {
					var filRes = res.filter((x: any) => {
						if (
							x.PresentationTypeId == presentationidval &&
							(x.CountryId == undefined || x.CountryId == countryidval)
						)
							return x;
					});

					this.ReferralGroupCode = filRes;
				}
				this.FillGroupDetailsOfReferrals();
			})
			.catch();
	}

	FillGroupDetailsOfReferrals() {
		if (
			this.ReferralGroupCode != undefined &&
			this.ReferralGroupCode.length > 0
		) {
			if (
				this.$data.TempReferrals != undefined &&
				this.$data.TempReferrals.ReferralsList != undefined &&
				this.$data.TempReferrals.ReferralsList.length > 0
			) {
				this.$data.TempReferrals.ReferralsList.forEach((refItem: any) => {
					if (refItem.CompleteGroup == undefined) {
						refItem.CompleteGroup = this.ReferralGroupCode.find(
							(x: any) =>
								x.GroupCode == refItem.GroupCode || x.Id == refItem.GroupId
						);
						if (refItem.GroupCode == undefined || refItem.GroupCode == "")
							refItem.GroupCode = refItem.CompleteGroup?.GroupCode;
					}
				});
			}
		}
	}

	mounted() {
		this.loadDropdown();
		this.$nextTick(() => {
			setTimeout(() => {
				this.LoadGroupCodes();
			}, 0);
			if (
				this.ReferralWatch != undefined &&
				this.ReferralWatch.ReferredBy != undefined
			) {
				this.$data.Referrals = this.ReferralWatch;
			} else {
				this.$data.Referrals = {
					SponsorshipGuid: EntityHelper.uuid(),
					ReferredBy: "",
					ReferredByLastName: "",
					Organization: "",
					Phone: "",
					Email: "",
					Date: moment(new Date()).format("MMMM DD, YYYY"),
					ReferralsList: [],
					CCEmail: [],
					ExtraPhone: [],
				};
			}

			this.$nextTick(() => this.GetWillKitRef());
			var giftedAmount = this.$data.Referrals.ReferralsList.length * 2000;
			this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
			if (!this.HideBenefitsRow) this.SponsorBenefitChange();
		});
	}

	displayProduct() {
		var DocType = "SPONSORSHIP PROGRAM";

		var sponsershipimage = this.NoCostBenefitsList.filter(
			(x: any) => x.Name === DocType
		);

		if (sponsershipimage != undefined && sponsershipimage.length > 0) {
			var imgsc = "";
			sponsershipimage.forEach(function (value: any) {
				imgsc = value.Link;
			});

			this.imgSource = imgsc;
			this.showImage = true;
		} else {
			getPersistedState("Materials").then((res: any) => {
				if (res != undefined && res.length > 0) {
					this.allProducts = res;

					this.allProducts.forEach((matProd: any) => {
						if (matProd.CountryCode != undefined && matProd.CountryCode != "") {
							matProd.MaterialName =
								matProd.MaterialName + " - " + matProd.CountryCode;
						}
					});

					var allProd = this.allProducts
						.filter((x: any) => x.MaterialId != undefined)
						.sort((a: any, b: any) => a.priority - b.priority);

					var groupedElements = groupBy(allProd, "MaterialId");
					var materials: any = [];

					for (var prop in groupedElements) {
						materials.push(groupedElements[prop][0]);
					}

					this.allMatchingproducts = materials;

					this.itemLessCount = parseInt(
						this.allMatchingproducts
							.filter((x: any) => x.ShowOnExpand == false)
							.length.toString()
					);
				}
				var DocType = "SPONSORSHIP PROGRAM - ";
				if (this.CountryId == 2) {
					DocType += "CA";
				} else {
					DocType += "US";
				}

				var sponsershipimage = this.allMatchingproducts.filter(
					(x: any) => x.MaterialName === DocType
				);

				sponsershipimage = sponsershipimage.filter(
					(x: any) => x.PresentationTypeId == 10
				);
				var imgsc = "";
				sponsershipimage.forEach(function (value: any) {
					imgsc = value.Link;
				});

				this.imgSource = imgsc;
				this.showImage = true;
			});
		}
	}

	EmptyCommand(evt: any) {
		this.showImage = false;
	}

	EmailSponsorShip(evt: any) {
		if (this.$refs.phonefield.$el.classList.contains("error--text")) {
			this.$store.state.messageText = "Please enter a valid Phone Number.";
			this.$store.state.showMessage = true;
			return;
		}

		if (
			this.$data.TempReferrals.Email == undefined ||
			this.$data.TempReferrals.Email == "" ||
			this.$data.TempReferrals.Email.trim() == ""
		) {
			this.$store.state.messageText = this.$t(
				"NoCostBenefits.PleaseSelectEmail"
			);
			this.$refs.emailfield.$el.classList.add("error--text");
			this.$store.state.showMessage = true;
			return;
		}

		if (this.$refs.emailfield.$el.classList.contains("error--text")) {
			this.$store.state.messageText = "Please enter a valid Email Address.";
			this.$store.state.showMessage = true;
			return;
		}
		if (!this.isRefFormValid) {
			this.$refs.ReferralForm?.validate();
			return;
		}

		this.ccemailPopUp = true;
	}

	SendEmailSponsorShip(evt: any) {
		setTimeout(() => {
			this.$data.TempReferrals.CCEmail = [];

			var ccemail = this.items;
			this.items.forEach((email: any) => {
				this.$data.TempReferrals.CCEmail.push(email.text);
			});

			this.$data.EmailLoader = true;
			var params: GenericKeyPair[] = [];
			PostRequestWithAuthorization(
				ApiEndPointConstants.EmailSponsorshipEndpoint,
				params,
				this.$data.TempReferrals
			)
				.then((res: any) => {
					if (res != undefined && res.data == true) {
						this.$store.state.messageText = "Email has been sent successfully";
						this.$store.state.showMessage = true;
					}
					this.$data.EmailLoader = false;

					this.items = [];
					this.ccemailPopUp = false;
					this.combomodel = [];
				})
				.catch((ex: any) => {
					this.$data.EmailLoader = false;
				});
		}, 1000);
	}

	DeleteItem(item: any, parent: any) {
		const index = this.items.indexOf(item, 0);
		if (index > -1) {
			this.items.splice(index, 1);
			parent.selectItem(item);
		}
	}

	ClosingCCEmailPopup() {
		this.items = [];
		this.ccemailPopUp = false;
		this.combomodel = [];
	}

	ImageNotificationModal() {
		if (this.$data.TempReferrals.AgentPhoneNo == undefined) {
			Vue.set(this.$data.TempReferrals, "AgentPhoneNo", this.User.PhoneNo);
		}
		if (this.$data.TempReferrals.IsImageSent == undefined) {
			Vue.set(this.$data.TempReferrals, "IsImageSent", false);
		}
		if (this.$data.TempReferrals.IsImageRecentlySent == undefined) {
			Vue.set(this.$data.TempReferrals, "IsImageRecentlySent", false);
		}
		if (this.$data.TempReferrals.ClientMMSLimitCount == undefined) {
			Vue.set(this.$data.TempReferrals, "ClientMMSLimitCount", 0);
		}
		this.createClientMessagePreview();
		this.IsConfirmationSponsorValid = true;
		this.clientimagenotification = true;
	}

	createClientMessagePreview() {
		var ReferredByFirstName = Camelize(
			this.$data.TempReferrals.ReferredBy
		).trim();
		var ReferredByLastName = Camelize(
			this.$data.TempReferrals.ReferredByLastName
		).trim();
		var ReferredByName = ReferredByFirstName + " " + ReferredByLastName;
		var TwilioNumber = AddMaskingToNumber(
			this.User.TwilioNumber.length == 12
				? this.User.TwilioNumber.substring(2)
				: this.User.TwilioNumber.substring(1)
		);
		this.ClientImageintrocutoryMessage = `The Receipt Notification text will be sent to <b>${ReferredByName.trim()}</b>. The number it will be sent from is <span  class="text-white-space">${TwilioNumber}</span>.`;
	}

	NotificationModal() {
		if (this.$data.TempReferrals.AgentPhoneNo == undefined) {
			Vue.set(this.$data.TempReferrals, "AgentPhoneNo", this.User.PhoneNo);
		}
		if (this.$data.TempReferrals.IsSent == undefined) {
			Vue.set(this.$data.TempReferrals, "IsSent", false);
		}
		if (this.$data.TempReferrals.IsRecentlySent == undefined) {
			Vue.set(this.$data.TempReferrals, "IsRecentlySent", false);
		}
		if (this.$data.TempReferrals.ClientSMSLimitCount == undefined) {
			Vue.set(this.$data.TempReferrals, "ClientSMSLimitCount", 0);
		}
		if (
			this.$data.TempReferrals.ExtraPhone != undefined &&
			this.$data.TempReferrals.ExtraPhone.length > 0
		) {
			for (let i = 0; i < this.$data.TempReferrals.ExtraPhone.length; i++) {
				this.$data.TempReferrals.ExtraPhone[i].IsNameValid = true;
				this.$data.TempReferrals.ExtraPhone[i].IsPhoneValid = true;
			}
		}
		this.IsConfirmationAgentValid = true;
		this.IsConfirmationSponsorValid = true;
		this.sponsornotification = true;
		this.isActive = false;
	}
	GroupImageNotificationModal() {
		if (this.$data.TempReferrals.AgentPhoneNo == undefined) {
			Vue.set(this.$data.TempReferrals, "AgentPhoneNo", this.User.PhoneNo);
		}
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.IsImageSent == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],

				"IsImageSent",

				false
			);
		}

		this.$data.IsGroupImageSent =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsImageSent;
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.IsImageRecentlySent == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"IsImageRecentlySent",
				false
			);
		}
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.GroupSMSLimitCount == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"GroupMMSLimitCount",
				0
			);
		}
		this.$data.IsGroupImageRecentlySent =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsImageRecentlySent;
		this.ReferralFirstName =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].FirstName;
		this.ReferralLastName =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].LastName;
		this.ReferralPhone =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone;
		this.ReferralState =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].State;
		this.ReferralCity =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].City;
		this.ReferralSignificantOther =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].SignificantOther;
		this.ReferralRelToSponsor =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].RelToSponsor;
		this.ReferralBranchOfService =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].BranchOfService;
		this.IsGroupConfirmationReferralValid = true;
		this.groupimagenotification = true;
		this.isActive = false;
		this.CreatePreviewTemplates();
	}
	GroupNotificationModal() {
		if (this.$data.TempReferrals.AgentPhoneNo == undefined) {
			Vue.set(this.$data.TempReferrals, "AgentPhoneNo", this.User.PhoneNo);
		}
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].IsSent ==
			undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"IsSent",
				false
			);
		}
		this.$data.IsGroupSent =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].IsSent;
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.IsRecentlySent == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"IsRecentlySent",
				false
			);
		}
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.IsEmailSent == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"IsEmailSent",
				false
			);
		}
		this.$data.IsGroupEmailSent =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsEmailSent;
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.IsEmailRecentlySent == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"IsEmailRecentlySent",
				false
			);
		}
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.GroupSMSLimitCount == undefined
		) {
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"GroupSMSLimitCount",
				0
			);
		}
		this.$data.IsGroupRecentlySent =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsRecentlySent;
		this.$data.IsGroupEmailRecentlySent =
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].IsEmailRecentlySent;
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.GroupExtraPhone == undefined
		) {
			this.GroupTextPhones = [];
			this.ReferralChecked = true;
		} else {
			this.GroupTextPhones =
				this.$data.TempReferrals.ReferralsList[
					this.SelectedGroupIndex
				].GroupExtraPhone;
			this.ReferralChecked =
				this.$data.TempReferrals.ReferralsList[
					this.SelectedGroupIndex
				].ReferralChecked;
		}

		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.ReferralChecked == undefined
		) {
			this.ReferralChecked = true;
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"ReferralChecked",
				this.ReferralChecked
			);
		}
		this.ReferralFirstName =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].FirstName;
		this.ReferralLastName =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].LastName;
		this.ReferralPhone =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone;
		if (this.GroupTextPhones != undefined && this.GroupTextPhones.length > 0) {
			for (let i = 0; i < this.GroupTextPhones.length; i++) {
				this.GroupTextPhones[i].IsNameValid = true;
				this.GroupTextPhones[i].IsPhoneValid = true;
			}
		}
		this.MessagePreview = "";
		this.IsGroupSmsEditable = false;
		this.createPrevieMessageForModal();
		this.IsGroupConfirmationAgentValid = true;
		this.IsGroupConfirmationSponsorValid = true;
		this.IsGroupConfirmationReferralValid = true;
		this.groupsponsornotification = true;
		this.isActive = false;
	}
	OpenGroupSmsEdit() {
		this.IsGroupSmsEditable = true;
		// this.MessagePreview = "";
		// this.createPrevieMessageForModal();
		this.$nextTick(() => this.$refs.MessagePreviewRef.$refs.input.focus());
	}
	GetEditedGroupSMS() {
		var EditedMessage = document.getElementById("EditableMessage");
		var AgentName = Camelize(
			this.User.FirstName + " " + this.User.LastName
		).trim();
		if (EditedMessage?.innerHTML != undefined) {
			var EditedAgentName = EditedMessage.querySelectorAll(
				"#EditedAgentName"
			)[0] as HTMLElement | null;
			if (EditedAgentName?.innerText.trim() == "") {
				EditedAgentName.innerText = AgentName;
			}
		}
		this.IsGroupSmsEditable = false;
		return EditedMessage?.innerText;
	}
	createPrevieMessageForModal() {
		var PresentationInfo = Presentation.query().withAllRecursive().last();
		var Person = PrimaryPerson.query().withAllRecursive().last();
		var ClientGender;
		if (Person != null && Person != undefined) {
			ClientGender = Person.Gender;
		} else {
			ClientGender = null;
		}

		var ReferredByFirstName = `${Camelize(
			this.$data.TempReferrals.ReferredBy
		).trim()}`;
		var ReferredByLastName = `${Camelize(
			this.$data.TempReferrals.ReferredByLastName
		).trim()}`;
		var SponsoredFirstName = this.ReferralFirstName;
		var ReferredByName = `${ReferredByFirstName + " " + ReferredByLastName}`;
		var AgentName = Camelize(
			this.User.FirstName.trim() + " " + this.User.LastName.trim()
		).trim();
		var AgentPhone = this.$data.TempReferrals.AgentPhoneNo;
		// if (
		// 	this.$data.TempReferrals.AgentPhoneNo != null ||
		// 	this.$data.TempReferrals.AgentPhoneNo != undefined ||
		// 	this.$data.TempReferrals.AgentPhoneNo != ""
		// ) {
		// 	var formatted = AgentPhone.replace(/\D/g, "").match(
		// 		/(\d{0,3})(\d{0,3})(\d{0,4})/
		// 	);
		// 	var number = !formatted[2]
		// 		? formatted[1]
		// 		: "(" +
		// 		  formatted[1] +
		// 		  ") " +
		// 		  formatted[2] +
		// 		  (formatted[3] ? "-" + formatted[3] : "");
		// 	if (number != null && number != undefined && number != "") {
		// 		AgentPhone = number;
		// 	}
		// }
		AgentPhone = AddMaskingToNumber(this.$data.TempReferrals.AgentPhoneNo);
		var SelectedGroupCode =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].GroupCode;
		var RelToSponsor =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.RelToSponsor;
		var BranchOfService =
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.BranchOfService;
		let genderedRelation = RelToSponsor;
		// let Person = PrimaryPerson.query().withAllRecursive().last();
		if (genderedRelation != undefined) {
			for (let key in this.genderedRelationshipsList) {
				if (key.toLowerCase() == genderedRelation.toLowerCase()) {
					genderedRelation = key;
					break;
				}
			}
		}
		if (
			genderedRelation != undefined &&
			this.genderedRelationshipsList[genderedRelation] != undefined
		) {
			if (this.genderedRelationshipsList[genderedRelation] != "N/A") {
				if (
					ClientGender != undefined &&
					ClientGender != null &&
					ClientGender != ""
				) {
					RelToSponsor =
						this.genderedRelationshipsList[genderedRelation][ClientGender];
				} else RelToSponsor = "";
			}
		} else {
			RelToSponsor = "";
		}
		this.IntroductoryMessage = `The sponsorship notification text will be <b>sent to you, ${AgentName}.</b> Once received please forward to both parties in a group text, and have the member respond with a <b>“Thank You. Call ${Camelize(
			this.User.FirstName
		).trim()}”</b>`;
		SponsoredFirstName = `${Camelize(SponsoredFirstName).trim()}`;
		this.IsGlobeLifeType =
			PresentationType.GlobeLife == PresentationInfo?.PresentationTypeId;
		this.MessagePreview += `Hi ${SponsoredFirstName}, this is ${AgentName} with ${
			this.IsGlobeLifeType ? `Globe Life - American Income Division` : `AIL`
		}. `;
		if (PresentationInfo != undefined) {
			var presentationTypeName = Camelize(
				PresentationInfo?.PresentationTypeString
			);
			switch (PresentationInfo?.PresentationTypeId) {
				case PresentationType.Veteran: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? ` for your ${RelToSponsor}, `
							: " ";
					this.MessagePreview += `I handle benefits for Veterans and their families${RelToSponsor}${ReferredByName.trim()}. `;
					if (
						PresentationInfo?.PresentationSubTypeId !=
						PresentationSubType.VeteranFamilyLead
					) {
						if (
							SelectedGroupCode == "IGSVL" ||
							SelectedGroupCode == "IGTRN" ||
							(BranchOfService != null && BranchOfService != "")
						) {
							this.MessagePreview += `${ReferredByFirstName} was able to request a burial and will kit through their veteran organization and was able to sponsor you to get yours activated as well.`;
						} else if (
							SelectedGroupCode != "IGSVL" &&
							(BranchOfService == null || BranchOfService == "")
						) {
							this.MessagePreview += `${ReferredByFirstName} was able to request the burial and will kit through their veteran organization and was able to sponsor you for some benefits even though you’re not a veteran!`;
						}
					} else {
						this.MessagePreview += `${ReferredByFirstName} was able to extend some benefits out to you through their family’s veterans organization and it's my job to get those activated.`;
					}
					break;
				}
				case PresentationType.ServiceMemberBenefits: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? ` for your ${RelToSponsor}, `
							: " ";
					this.MessagePreview += `I handle benefits for Veterans and their families${RelToSponsor}${ReferredByName.trim()}. `;
					if (
						SelectedGroupCode == "IGSVL" ||
						SelectedGroupCode == "IGTRN" ||
						(BranchOfService != null && BranchOfService != "")
					) {
						this.MessagePreview += `${ReferredByFirstName} was able to request a burial and will kit through their veteran organization and was able to sponsor you to get yours activated as well.`;
					} else if (
						SelectedGroupCode != "IGSVL" &&
						(BranchOfService == null || BranchOfService == "")
					) {
						this.MessagePreview += `${ReferredByFirstName} was able to request the burial and will kit through their veteran organization and was able to sponsor you for some benefits even though you’re not a veteran!`;
					}
					break;
				}
				case PresentationType.CreditUnion:
				case PresentationType.Union:
				case PresentationType.Association: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? `and for your ${RelToSponsor}, `
							: "";
					this.MessagePreview += `I handle benefits for ${presentationTypeName.trim()} members ${RelToSponsor}${ReferredByName.trim()}. ${ReferredByFirstName} was able to extend some benefits out to you through the ${presentationTypeName.trim()} and AIL and it’s my job to get those activated and review them with you.`;
					break;
				}
				case PresentationType.McGruffSafeKids: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? ` Your ${RelToSponsor}, `
							: " ";
					this.MessagePreview += `I handle the McGruff Safe Kids ID Kits through the National Crime Prevention Council.${RelToSponsor}${ReferredByName.trim()} was able to complete the Safe Kids ID kit through the NCPC & AIL and was able to sponsor you to get yours done at no cost as well.`;
					break;
				}
				case PresentationType.WillKitNoCostLaw: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? ` for your ${RelToSponsor}, `
							: " ";
					this.MessagePreview += `I handle the Last Will & Testament preparation${RelToSponsor}${ReferredByName.trim()}. ${ReferredByFirstName} was able to complete the will kit through No Cost Law and was able to sponsor you to get yours done at no cost as well.`;
					break;
				}
				case PresentationType.Referral:
				case PresentationType.POS:
				case PresentationType.GlobeLife: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? `Your ${RelToSponsor}, `
							: "";
					this.MessagePreview += `${RelToSponsor}${ReferredByName.trim()} was able to extend some benefits out to you${
						this.IsGlobeLifeType ? `` : ` through AIL`
					} and it’s my job to get those activated.`;
					break;
				}
				default: {
					RelToSponsor =
						RelToSponsor != null && RelToSponsor != ""
							? ` for your ${RelToSponsor}, `
							: " ";
					this.MessagePreview += `I handle benefits${RelToSponsor}${ReferredByName.trim()}. ${ReferredByFirstName} was able to extend some benefits out to you and it is just my job to get those activated and review those with you.`;
					break;
				}
			}
			AgentPhone = `${AgentPhone}`;
			this.MessagePreview += `\r\n\r\nI will be reaching out shortly to review those with you! For faster service please call me at ${AgentPhone} or let me know when is the best time to reach you.`;
		}
	}
	CloseClientImageConfirmationModal() {
		this.clientimagenotification = false;
	}
	CloseClientConfirmationModal() {
		if (this.$data.TempReferrals.ExtraPhone != undefined) {
			this.$data.TempReferrals.ExtraPhone =
				this.$data.TempReferrals.ExtraPhone.filter((x: any) => {
					if (x.Name != "" || x.PhoneNo != "") return x;
				});
		}
		this.sponsornotification = false;
	}
	CloseGroupImageConfirmationModal() {
		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].FirstName =
			this.ReferralFirstName;
		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].LastName =
			this.ReferralLastName;
		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone =
			this.ReferralPhone;
		this.groupimagenotification = false;
	}
	CloseGroupConfirmationModal() {
		this.GroupTextPhones = this.GroupTextPhones.filter((x: any) => {
			if (x.Name != "" || x.PhoneNo != "") return x;
		});
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.GroupExtraPhone == undefined
		)
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"GroupExtraPhone",
				[]
			);
		this.$data.TempReferrals.ReferralsList[
			this.SelectedGroupIndex
		].GroupExtraPhone = this.GroupTextPhones;
		if (
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
				.ReferralChecked == undefined
		)
			Vue.set(
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
				"ReferralChecked",
				true
			);
		this.$data.TempReferrals.ReferralsList[
			this.SelectedGroupIndex
		].ReferralChecked = this.ReferralChecked;
		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].FirstName =
			this.ReferralFirstName;
		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].LastName =
			this.ReferralLastName;
		this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone =
			this.ReferralPhone;
		this.groupsponsornotification = false;
	}
	ExtraNumbersChecked(item: any) {
		item.IsNameValid = true;
		item.IsPhoneValid = true;
	}
	ExtraGroupNumbersChecked(item: any) {
		item.IsNameValid = true;
		item.IsPhoneValid = true;
	}
	async SendClientImage() {
		var validitycheck = true;
		// if (
		// 	this.$data.TempReferrals.AgentPhoneNo == null ||
		// 	this.$data.TempReferrals.AgentPhoneNo.length <= 13 ||
		// 	this.$data.TempReferrals.AgentPhoneNo.trim() == ""
		// ) {
		// 	this.IsConfirmationAgentValid = false;
		// 	validitycheck = false;
		// } else this.IsConfirmationAgentValid = true;
		if (
			this.$data.TempReferrals.Phone == null ||
			this.$data.TempReferrals.Phone.length <= 13 ||
			this.$data.TempReferrals.Phone.trim() == ""
		) {
			this.IsConfirmationSponsorValid = false;
			validitycheck = false;
		}
		if (validitycheck) {
			var circleloader = document.getElementsByClassName("circle-loader");
			this.isActive = !this.isActive;
			let req = this.CreateClientSponserRequest(true, true);
			await this.SendClientorGroupMMSCall(req);
			setTimeout(() => {
				this.$store.state.isImageSentRecently = false;
				// this.$emit("SaveReferrals", JSON.stringify(this.$data.TempReferrals));
				this.$emit(
					"SaveReferralWithoutClosingPopup",
					JSON.stringify(this.$data.TempReferrals)
				);
			}, 30000);
		}
	}
	async SendClientText() {
		var validitycheck = true;
		if (this.$data.TempReferrals.ExtraPhone != undefined) {
			for (let i = 0; i < this.$data.TempReferrals.ExtraPhone.length; i++) {
				if (this.$data.TempReferrals.ExtraPhone[i].Checked) {
					if (this.$data.TempReferrals.ExtraPhone[i].Name == "") {
						this.$data.TempReferrals.ExtraPhone[i].IsNameValid = false;
						validitycheck = false;
					} else this.$data.TempReferrals.ExtraPhone[i].IsNameValid = true;
					if (
						this.$data.TempReferrals.ExtraPhone[i].PhoneNo == "" ||
						this.$data.TempReferrals.ExtraPhone[i].PhoneNo.length <= 13 ||
						this.$data.TempReferrals.ExtraPhone[i].PhoneNo.trim() == ""
					) {
						this.$data.TempReferrals.ExtraPhone[i].IsPhoneValid = false;
						validitycheck = false;
					} else this.$data.TempReferrals.ExtraPhone[i].IsPhoneValid = true;
				}
			}
		}
		if (
			this.$data.TempReferrals.AgentPhoneNo == null ||
			this.$data.TempReferrals.AgentPhoneNo.length <= 13 ||
			this.$data.TempReferrals.AgentPhoneNo.trim() == ""
		) {
			this.IsConfirmationAgentValid = false;
			validitycheck = false;
		} else this.IsConfirmationAgentValid = true;
		if (
			this.$data.TempReferrals.Phone == null ||
			this.$data.TempReferrals.Phone.length <= 13 ||
			this.$data.TempReferrals.Phone.trim() == ""
		) {
			this.IsConfirmationSponsorValid = false;
			validitycheck = false;
		} else this.IsConfirmationSponsorValid = true;
		if (validitycheck) {
			if (this.$data.TempReferrals.ExtraPhone != undefined) {
				this.$data.TempReferrals.ExtraPhone =
					this.$data.TempReferrals.ExtraPhone.filter((x: any) => {
						if (x.Name != "" || x.PhoneNo != "") return x;
					});
			}
			var circleloader = document.getElementsByClassName("circle-loader");
			this.isActive = !this.isActive;
			let req = this.CreateClientSponserRequest(true);
			await this.SendClientorGroupSMSCall(req);

			// setTimeout(() => {
			// 	this.$data.TempReferrals.IsSent = true;
			// 	this.$data.TempReferrals.IsRecentlySent = true;
			// 	circleloader[0].classList.add("load-complete");
			// }, 2000);
			// setTimeout(() => {
			// 	this.isActive = false;
			// 	this.sponsornotification = false;
			// }, 3000);
			setTimeout(() => {
				this.$store.state.isMessageSentRecently = false;
				// this.$emit("SaveReferrals", JSON.stringify(this.$data.TempReferrals));
				this.$emit(
					"SaveReferralWithoutClosingPopup",
					JSON.stringify(this.$data.TempReferrals)
				);
			}, 30000);
		}
	}
	async SendGroupImage() {
		var validitycheck = true;
		if (this.ReferralChecked == true) {
			if (
				this.ReferralPhone == null ||
				this.ReferralPhone.length <= 13 ||
				this.ReferralPhone.trim() == ""
			) {
				validitycheck = false;
				this.IsGroupConfirmationReferralValid = false;
			} else this.IsGroupConfirmationReferralValid = true;
		}
		// if (
		// 	this.$data.TempReferrals.AgentPhoneNo == null ||
		// 	this.$data.TempReferrals.AgentPhoneNo.length <= 13 ||
		// 	this.$data.TempReferrals.AgentPhoneNo.trim() == ""
		// ) {
		// 	this.IsGroupConfirmationAgentValid = false;
		// 	validitycheck = false;
		// } else this.IsGroupConfirmationAgentValid = true;

		if (validitycheck) {
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].FirstName = this.ReferralFirstName;
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].LastName =
				this.ReferralLastName;
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone =
				this.ReferralPhone;
			// this.groupsponsornotification = false;
			this.isActive = !this.isActive;
			var phoneNo =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone;
			var firstName =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.FirstName;
			var lastName =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.LastName;
			let req = this.CreateClientSponserRequest(false, true);
			await this.SendClientorGroupMMSCall(req);
			setTimeout(() => {
				var ImageSentupdated = false;
				this.$data.IsGroupImageRecentlySent = false;
				this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
					if (element.Phone == phoneNo) {
						element.IsImageRecentlySent = this.$data.IsGroupImageRecentlySent;
						ImageSentupdated = true;
					}
				});
				if (!ImageSentupdated) {
					this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
						if (
							element.FirstName == firstName &&
							element.LastName == lastName
						) {
							element.IsImageRecentlySent = this.$data.IsGroupImageRecentlySent;
						}
					});
				}
				this.$emit(
					"SaveReferralWithoutClosingPopup",
					JSON.stringify(this.$data.TempReferrals)
				);
				// this.$emit("SaveReferrals", JSON.stringify(this.$data.TempReferrals));
			}, 30000);
		}
	}
	async SendGroupText(isEmail: boolean = false) {
		var validitycheck = true;
		if (this.GroupTextPhones != undefined) {
			for (let i = 0; i < this.GroupTextPhones.length; i++) {
				if (this.GroupTextPhones[i].Checked) {
					if (this.GroupTextPhones[i].Name == "") {
						this.GroupTextPhones[i].IsNameValid = false;
						validitycheck = false;
					} else this.GroupTextPhones[i].IsNameValid = true;
					if (
						this.GroupTextPhones[i].PhoneNo == "" ||
						this.GroupTextPhones[i].PhoneNo.length <= 13 ||
						this.GroupTextPhones[i].PhoneNo.trim() == ""
					) {
						this.GroupTextPhones[i].IsPhoneValid = false;
						validitycheck = false;
					} else this.GroupTextPhones[i].IsPhoneValid = true;
				}
			}
		}
		if (this.ReferralChecked == true) {
			if (
				this.ReferralPhone == null ||
				this.ReferralPhone.length <= 13 ||
				this.ReferralPhone.trim() == ""
			) {
				validitycheck = false;
				this.IsGroupConfirmationReferralValid = false;
			} else this.IsGroupConfirmationReferralValid = true;
		}
		// if (
		// 	this.$data.TempReferrals.AgentPhoneNo == null ||
		// 	this.$data.TempReferrals.AgentPhoneNo.length <= 13 ||
		// 	this.$data.TempReferrals.AgentPhoneNo.trim() == ""
		// ) {
		// 	this.IsGroupConfirmationAgentValid = false;
		// 	validitycheck = false;
		// } else this.IsGroupConfirmationAgentValid = true;
		if (
			this.$data.TempReferrals.Phone == null ||
			this.$data.TempReferrals.Phone.length <= 13 ||
			this.$data.TempReferrals.Phone.trim() == ""
		) {
			this.IsGroupConfirmationSponsorValid = false;
			validitycheck = false;
		} else this.IsGroupConfirmationSponsorValid = true;

		if (
			this.MessagePreview == undefined ||
			this.MessagePreview == null ||
			this.MessagePreview.trim() == ""
		) {
			validitycheck = false;
		}
		if (validitycheck) {
			this.GroupTextPhones = this.GroupTextPhones.filter((x: any) => {
				if (x.Name != "" || x.PhoneNo != "") return x;
			});
			if (
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.GroupExtraPhone == undefined
			)
				Vue.set(
					this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
					"GroupExtraPhone",
					[]
				);
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].GroupExtraPhone = this.GroupTextPhones;
			if (
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.ReferralChecked == undefined
			)
				Vue.set(
					this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex],
					"ReferralChecked",
					true
				);
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].ReferralChecked = this.ReferralChecked;
			this.$data.TempReferrals.ReferralsList[
				this.SelectedGroupIndex
			].FirstName = this.ReferralFirstName;
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].LastName =
				this.ReferralLastName;
			this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone =
				this.ReferralPhone;
			// this.groupsponsornotification = false;
			this.isActive = !this.isActive;
			var phoneNo =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].Phone;
			var firstName =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.FirstName;
			var lastName =
				this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex]
					.LastName;
			let req = this.CreateClientSponserRequest(false);
			if (isEmail == true) {
				await this.SendClientorGroupEmailCall(req);
				setTimeout(() => {
					var EmailSentupdated = false;
					this.$data.IsGroupEmailRecentlySent = false;
					this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
						if (element.Phone == phoneNo) {
							element.IsEmailRecentlySent = this.$data.IsGroupEmailRecentlySent;
							EmailSentupdated = true;
						}
					});
					if (!EmailSentupdated) {
						this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
							if (
								element.FirstName == firstName &&
								element.LastName == lastName
							) {
								element.IsEmailRecentlySent =
									this.$data.IsGroupEmailRecentlySent;
							}
						});
					}
					this.$emit(
						"SaveReferralWithoutClosingPopup",
						JSON.stringify(this.$data.TempReferrals)
					);
					// this.$emit("SaveReferrals", JSON.stringify(this.$data.TempReferrals));
				}, 30000);
			} else {
				await this.SendClientorGroupSMSCall(req);
				setTimeout(() => {
					var ImageSentupdated = false;
					this.$data.IsGroupRecentlySent = false;
					this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
						if (element.Phone == phoneNo) {
							element.IsRecentlySent = this.$data.IsGroupRecentlySent;
							ImageSentupdated = true;
						}
					});
					if (!ImageSentupdated) {
						this.$data.TempReferrals.ReferralsList.forEach((element: any) => {
							if (
								element.FirstName == firstName &&
								element.LastName == lastName
							) {
								element.IsRecentlySent = this.$data.IsGroupRecentlySent;
							}
						});
					}
					this.$emit(
						"SaveReferralWithoutClosingPopup",
						JSON.stringify(this.$data.TempReferrals)
					);
					// this.$emit("SaveReferrals", JSON.stringify(this.$data.TempReferrals));
				}, 30000);
			}

			// setTimeout(() => {
			// 	this.$data.IsGroupSent = true;
			// 	this.$data.IsGroupRecentlySent = true;
			// 	this.$data.TempReferrals.ReferralsList[this.SelectedGroupIndex].IsSent =
			// 		this.$data.IsGroupSent;
			// 	this.$data.TempReferrals.ReferralsList[
			// 		this.SelectedGroupIndex
			// 	].IsRecentlySent = this.$data.IsGroupRecentlySent;
			// 	circleloader[0].classList.add("load-complete");
			// }, 2000);
			// setTimeout(() => {
			// 	this.isActive = false;
			// 	this.groupsponsornotification = false;
			// }, 3000);
		}
	}

	ValidateConfirmationFields(item: any, fieldString: string) {
		if (fieldString == "AgentNum") {
			if (item == null || item.length <= 13 || item.trim() == "")
				this.IsConfirmationAgentValid = false;
			else this.IsConfirmationAgentValid = true;
		}
		if (fieldString == "SponsoredNum") {
			if (item == null || item.length <= 13 || item.trim() == "")
				this.IsConfirmationSponsorValid = false;
			else this.IsConfirmationSponsorValid = true;
		}
		if (fieldString == "ExtraPhoneNo") {
			if (
				item.PhoneNo == null ||
				item.PhoneNo.length <= 13 ||
				item.PhoneNo.trim() == ""
			)
				item.IsPhoneValid = false;
			else item.IsPhoneValid = true;
		}
		if (fieldString == "ExtraPhoneName") {
			if (item.Name == "") item.IsNameValid = false;
			else item.IsNameValid = true;
		}
	}
	ValidateGroupConfirmationFields(item: any, fieldString: string) {
		if (fieldString == "GroupAgentNum") {
			if (item == null || item.length <= 13 || item.trim() == "")
				this.IsGroupConfirmationAgentValid = false;
			else this.IsGroupConfirmationAgentValid = true;
		}
		if (fieldString == "GroupSponsoredNum") {
			if (item == null || item.length <= 13 || item.trim() == "")
				this.IsGroupConfirmationSponsorValid = false;
			else this.IsGroupConfirmationSponsorValid = true;
		}
		if (fieldString == "GroupExtraPhoneNo") {
			if (
				item.PhoneNo == null ||
				item.PhoneNo.length <= 13 ||
				item.PhoneNo.trim() == ""
			)
				item.IsPhoneValid = false;
			else item.IsPhoneValid = true;
		}
		if (fieldString == "GroupExtraPhoneName") {
			if (item.Name == "") item.IsNameValid = false;
			else item.IsNameValid = true;
		}
		if (fieldString == "GroupReferralPhone") {
			if (item == null || item.length <= 13 || item.trim() == "")
				this.IsGroupConfirmationReferralValid = false;
			else this.IsGroupConfirmationReferralValid = true;
		}
	}
}
