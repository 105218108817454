

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import {
	LoadPremiumAmountForPrinting,
	LoadPrintPDFData,
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
	ConvertIntoAmountFormatWithNegativeDecimal,
} from "../helpers/CalculationHelper";
import MenuComponent from "../components/menu.vue";
import Pres from "../entities/Presentation";
import { CopyTextToClipBoard } from "../helpers/UIHelper";
import Presentation from "../entities/Presentation";
import VueHtml2pdf from "vue-html2pdf";
import { EntityHelper } from "../helpers/EntityHelper";
import User from "@/entities/User";
let placeholderuser = require("../assets/placeholderuser.png");
import JsonViewer from "vue-json-viewer";
import {
	GetRequestWithAuthorization,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { APIService } from "@/services/APIService";
import { ChartType } from "../enumerations/ChartType";
import BarChart from "../components/charts/BarChart.vue";
import PieChart from "../components/charts/Pie.vue";

import {
	AmountFormatAllFields,
	GetProductNameById,
	PresentationDTOToEntity,
} from "@/helpers/MappingHelper";
import {
	GetAscFullName,
	GetFullName,
	GetPrimaryLicense,
} from "@/helpers/APIExtentions";
import { getPersistedState } from "@/helpers/StateMapper";
import { PresentationType } from "../enumerations/PresentationType";
import PresentationSync from "@/helpers/PresentationSync";
import { GetUserEmail } from "../helpers/APIExtentions";
import LoggingHelper from "@/helpers/LoggingHelper";
import LoggingConstants from "@/constants/LoggingConstants";
import moment from "moment";
import { EventBusLayout } from "@/bus/EventBusLayout";
import { WhatHappened } from "@/enumerations/WhatHappened";
import NeedsSurvey from "../components/popups/NeedsSurvey.vue";
import ProductSummary from "@/components/popups/ProductSummary.vue";
import TSheet from "@/components/popups/TSheet.vue";
import ReportCard from "@/components/popups/ReportCard.vue";
import { HpProHouseHoldType } from "@/enumerations/HpProHouseHoldType";
import {
	GetHEXColorByHouseHold,
	GetHEXColorByPresentationSubType,
	GetHEXColorByPresentationType,
	GetHEXColorByPresentationWhatHappened,
	UpdatePresentationHouseHoldCount,
	UpdatePresentationSubTypeCount,
	UpdatePresentationTypeCount,
	UpdatePresentationWhatHappenedCount,
} from "@/helpers/DashboardHelper";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
let MonthListData: any = require("../assets/pre-fill-data/month-list.json");
import { WhatHappenedDiscription } from "@/enumerations/WhatHappened";

@Component({
	components: {
		JsonViewer,
		ShortUserDetailView,
		MenuComponent,
		VueHtml2pdf,
		BarChart,
		PieChart,
		NeedsSurvey,
		TSheet,
		ProductSummary,
		ReportCard,
	},
})
export default class PresentationHistory extends Vue {
	private presentationsa: any = [];
	private userDetail: any;
	private CurrentMonth: string = "";
	private bsRates: any = null;
	private lstStates: any = null;
	private generatedCode!: string;
	private imgSource!: string;
	private Name: string = GetFullName();
	private AscName: string = GetAscFullName();
	private UserEmail = " ( " + GetUserEmail() + " )";
	private LicenseState: string = "";
	private LicenseNo: string = GetPrimaryLicense();
	private UnionCode: string = "OPEIU 277";
	private CurrentDate = new Date();
	private ShowPresentationType: boolean = false;
	private ShowDispositionType: boolean = false;
	private ShowHouseHoldType: boolean = false;
	private ShowPresentationSubType: boolean = false;
	private showchartCounter: number = 0;
	private ShowPresentationPopUp: boolean = false;
	private MonthList: any = [];
	private FilterText: any = "";
	private PresentationTypeListForUpdate: any = [];
	private MainPresentationTypeList: any = [];
	private PresentationGroupsList: any = [];
	private DispositionTypeListForUpdate: any = [];

	private selectedMonth: any;
	private selectedYear: string = "";
	private MonthsOfYear: any = [
		{ id: 1, month: "January" },
		{ id: 2, month: "February" },
		{ id: 3, month: "March" },
		{ id: 4, month: "April" },
		{ id: 5, month: "May" },
		{ id: 6, month: "June" },
		{ id: 7, month: "July" },
		{ id: 8, month: "August" },
		{ id: 9, month: "September" },
		{ id: 10, month: "October" },
		{ id: 11, month: "November" },
		{ id: 12, month: "December" },
	];

	ConvertIntoAmountFormatWithDecimalUI(event: any) {
		if (event == null) return "0.00";
		return ConvertIntoAmountFormatWithNegativeDecimal(event.toFixed(2));
	}
	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		this.$store.state.loader = true;
		EventBusLayout.$on("RoutedToEvt", this.PresHistoryDetailRoutedToEvt);
		// this.PresentationTypeListForUpdate.push({ Presentation });
		var bsRatesPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.BenefitSummaryRatesEndpoint,
			[],
			IndexDBConstants.BenefitSummaryRatesKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);
		var self = this;
		Promise.all([bsRatesPromise, stateListPromise]).then(async (values) => {
			self.bsRates = values[0];
			self.lstStates = values[1];

			let dateMonth = new Date().getMonth() + 1;
			this.CurrentMonth = new Date().toLocaleString("en-us", { month: "long" });

			var dataURL = this.$store.state.ProfileImageString;
			this.ProductsList = await getPersistedState("ProductsList");

			if (dataURL != null && dataURL != undefined) this.imgSource = dataURL;
			else if (this.$route.path != "/home" && this.$route.path != "/training") {
				this.imgSource = placeholderuser;
			} else {
				this.imgSource = placeholderuser;
			}
			let CurrentUser = localStorage.getItem("user");
			if (CurrentUser != undefined) {
				var userObj = JSON.parse(CurrentUser);
				this.userDetail = userObj.UserDetail;
				if (this.userDetail != undefined) {
					getPersistedState("UnionCodeList").then((res: any) => {
						if (this.userDetail != undefined && this.userDetail.UnionId != 0) {
							this.UnionCode = res.filter(
								(x: any) => x.Key == this.userDetail.UnionId
							)[0].Value;
						}
					});
				}
			}

			var user = User.query().last();
			if (user && user.FirstName) {
				this.Name = user.fullShrinkname;
				this.UnionCode = user.Union;
				if (
					user.LicenseStates != null &&
					user.LicenseStates != undefined &&
					user.LicenseStates != ""
				) {
					var licStates = JSON.parse(user.LicenseStates);
					var lic = licStates.find(
						(i: { Primary: boolean }) => i.Primary == true
					);
					if (lic) {
						this.LicenseNo = lic.Number;
						this.LicenseState = lic.State;
					}
				}
			}
			this.CreateMonthList();
			this.CreatePresentationList();
			this.CreateGroupList();
			this.CreateDispositionList();
			this.LoadUserList();
			await this.FetchPresentations(
				0,
				this.selectedMonth.id,
				Number(this.selectedMonth.Year)
			);
			this.$store.state.loader = false;
		});
	}

	GetCamelizePlanOption(Option: string) {
		return EntityHelper.capitalizeTheFirstLetterOfEachWord(Option) || Option;
	}

	GetItemCoverage(item: any) {
		if (
			item.Plan.CompleteProduct != undefined &&
			(item.Plan.CompleteProduct.Id == 15 ||
				item.Plan.CompleteProduct.Id == 18 ||
				item.Plan.CompleteProduct.Id == 19)
		) {
			var coverage = item.Plan.CompleteProduct.Id == 19 ? "" : 0;
			try {
				var coverageList = this.ProductsList.find(
					(x: any) => x.Id == item.Plan.CompleteProduct.Id
				).CoverageDropdownOptions.map((x: any) => {
					return x.Value;
				});

				if (
					coverageList != undefined &&
					coverageList.length > 0 &&
					item.Plan.CompleteCoverageOption.Name != undefined &&
					item.Plan.CompleteCoverageOption.Name != ""
				) {
					var selectedCoverage =
						coverageList[Number(item.Plan.CompleteCoverageOption.Name) - 1];

					if (selectedCoverage != undefined && selectedCoverage != null) {
						if (
							item.Plan.CompleteProduct.Id == 15 ||
							item.Plan.CompleteProduct.Id == 18
						) {
							coverage = `$${this.ConvertintoAmountFormatUI(selectedCoverage)}`;
						} else if (item.Plan.CompleteProduct.Id == 19) {
							coverage = `${selectedCoverage} Unit`;
						}
					}
				}
				return coverage;
			} catch (ex: any) {
				return coverage;
			}
		}
		return `$${this.ConvertintoAmountFormatUI(item.Coverage)}`;
	}

	async LoadUserList() {
		let parameters: GenericKeyPair[] = [];
		GetRequestWithAuthorization(
			ApiEndPointConstants.UserListEndpoint,
			parameters
		).then((res: any) => {
			if (
				res != undefined &&
				res.Result != undefined &&
				res.Result.length > 0
			) {
				this.CompleteUserList = res.Result;
				// if (this.$data.Presentation.FieldTraining != "ALONE") {
				//   this.FieldTrainee = this.$data.Presentation.FieldTrainee;
				//   this.FillList(this.FieldTrainee, true);
				// }
			}
		});
	}

	RCDataExists(plan: any) {
		var HomePayment = false;
		var MonthlyIncome = false;
		if (plan != null && plan != undefined) {
			if (
				plan.PrimaryProducts != undefined &&
				plan.PrimaryProducts != null &&
				plan.PrimaryProducts.length > 0
			) {
				plan.PrimaryProducts.forEach((x: any) => {
					if (x.Plan != undefined && x.Plan.PresentAs == "MONTHLY INCOME") {
						this.ShowRCSection = true;
						MonthlyIncome = true;
					} else if (
						x.Plan != undefined &&
						x.Plan.PresentAs == "HOUSE PAYMENT RIDER"
					) {
						this.ShowRCSection = true;
						HomePayment = true;
					}
				});
			}
			if (
				plan.SpouseProducts != undefined &&
				plan.SpouseProducts != null &&
				plan.SpouseProducts.length > 0 &&
				!this.ShowRCSection
			) {
				plan.SpouseProducts.forEach((x: any) => {
					if (x.Plan != undefined && x.Plan.PresentAs == "MONTHLY INCOME") {
						this.ShowRCSection = true;
						MonthlyIncome = true;
					} else if (
						x.Plan != undefined &&
						x.Plan.PresentAs == "HOUSE PAYMENT RIDER"
					) {
						this.ShowRCSection = true;
						HomePayment = true;
					}
				});
			}
		}
		if (MonthlyIncome) {
			this.$data.currentPresentationToPrint.BenefitSummary.RCHeading =
				"MONTHLY INCOME";
		} else if (HomePayment) {
			this.$data.currentPresentationToPrint.BenefitSummary.RCHeading =
				"HOME PAYMENT";
		}
	}

	PresHistoryDetailRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.PresHistoryDetailRoutedToEvt);
		window.removeEventListener("wheel", this.Updatehistorycount);
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined) return 0;
			else if (typeof amt == "string")
				return ConvertIntoAmountFormatWithDecimal(amt);
			else return ConvertIntoAmountFormatWithDecimal(amt.toFixed(2));
		} catch (ex: any) {
			return 0;
		}
	}

	removeChartData(item: any) {
		if (item.isRemoved == true) item.isRemoved = false;
		else item.isRemoved = true;
		var k = JSON.parse(JSON.stringify(this.$data.PopupChartTypeData));
		var k = k.filter(
			(x: any) => x.isRemoved == undefined || x.isRemoved == false
		);
		this.$data.PopupChartData = this.FillChartDataFromAPIData(k);
	}

	ChartClick(chartData: any, popupHeading: string, chartDataArray: any) {
		if (popupHeading === "Presentation Sub Types Chart")
			this.ShowPresentationPopUp = true;
		else this.ShowPresentationPopUp = false;

		this.$data.PieChartPopup = true;
		this.$data.popupChartHeading = popupHeading;
		this.$data.PopupChartData = chartData;
		this.$data.PopupChartLabels = chartData.labels;
		if (chartDataArray != undefined) {
			chartDataArray.forEach((element: any) => {
				element.isRemoved = false;
			});
		}
		this.$data.PopupChartTypeData = chartDataArray.sort(
			(a: any, b: any) => b.Count - a.Count
		);
	}

	UpdateDefaultChart(chartType: any) {
		if (chartType == 1) {
			if (this.ShowPresentationType == true) {
				this.showchartCounter--;
				this.ShowPresentationType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.showchartCounter++;
					this.ShowPresentationType = true;
				}
			}
		} else if (chartType == 2) {
			if (this.ShowDispositionType == true) {
				this.showchartCounter--;
				this.ShowDispositionType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowDispositionType = true;
					this.showchartCounter++;
				}
			}
		} else if (chartType == 3) {
			if (this.ShowHouseHoldType == true) {
				this.showchartCounter--;
				this.ShowHouseHoldType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowHouseHoldType = true;
					this.showchartCounter++;
				}
			}
		} else if (chartType == 4) {
			if (this.ShowPresentationSubType == true) {
				this.showchartCounter--;
				this.ShowPresentationSubType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowPresentationSubType = true;
					this.showchartCounter++;
				}
			}
		}
	}

	GetLeadSource(presentation: any) {
		var GroupCode = "";
		if (
			presentation != undefined &&
			presentation.PresentationGroupId != null &&
			presentation.PresentationGroupId != 0
		) {
			if (
				this.PresentationGroupsList != undefined &&
				this.PresentationGroupsList.length > 0
			) {
				let Group = this.PresentationGroupsList.find((x: any) => {
					if (x.Id == presentation.PresentationGroupId) return x;
				});
				if (Group != undefined) {
					GroupCode = Group.GroupCode;
				}
			}
		}
		return GroupCode;
	}

	get ChangePresentationDisabled() {
		var flag = false;
		if (!this.HideSubTypeDropDown) {
			if (!this.PresentationChanged && !this.PresentationSubChanged) {
				flag = true;
			}
		} else {
			if (!this.PresentationChanged) {
				flag = true;
			}
		}
		return flag;
	}

	get ValidPresentations() {
		var presentationsList = [];
		if (
			this.$data.FilteredPresentations != undefined &&
			this.$data.FilteredPresentations.length > 0
		) {
			presentationsList = this.$data.FilteredPresentations.filter(
				(pres: any) => {
					if (
						pres.PresentationStatus != "INCOMPLETE" &&
						pres.WhatHappened != WhatHappened.Training &&
						pres.WhatHappened != WhatHappened.Postponed &&
						pres.WhatHappened != WhatHappened.NeedsAnalysisOnly
					) {
						return pres;
					}
				}
			);
		}
		return presentationsList;
	}

	get GetTotalPresentations() {
		var presentationcount = 0;
		this.SelectedPresentationsList = this.GetSelectedPresentations();
		if (this.SelectedPresentationsList != null) {
			return this.SelectedPresentationsList.length;
		}
		return presentationcount;
	}

	get GetTotalALP() {
		var totalAlp = 0;
		if (
			this.SelectedPresentationsList != null &&
			this.SelectedPresentationsList.length > 0
		) {
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (
					pres.WhatHappened == WhatHappened.Enrollment &&
					pres.TotalALP != null
				) {
					totalAlp += pres.TotalALP;
				}
			});
			return totalAlp > 0 ? totalAlp : 0;
		}
		return totalAlp;
	}

	get GetCloseRatio() {
		var CloseRatio = 0;
		if (this.SelectedPresentationsList.length > 0 && this.GetTotalSales > 0) {
			CloseRatio = (this.GetTotalSales / this.GetTotalPresentations) * 100;
			return CloseRatio > 0
				? ConvertIntoAmountFormatWithDecimal(CloseRatio.toFixed(2).toString())
				: 0;
		}
		return CloseRatio;
	}

	get GetTotalSales() {
		var salescount = 0;
		if (
			this.SelectedPresentationsList != null &&
			this.SelectedPresentationsList.length > 0
		) {
			var enrolledpresentations = this.SelectedPresentationsList.filter(
				(x: any) => x.WhatHappened == WhatHappened.Enrollment
			);
			return enrolledpresentations.length;
		}

		return salescount;
	}

	get GetAlpPerSale() {
		var AlpPerSale = 0.0;
		if (this.GetTotalALP > 0 && this.GetTotalSales > 0) {
			AlpPerSale = this.GetTotalALP / this.GetTotalSales;
			return AlpPerSale > 0 ? AlpPerSale : 0.0;
		}
		return AlpPerSale;
	}

	get GetAhpPerSale() {
		var AhpPerSale = 0.0;
		if (this.SelectedPresentationsList != null) {
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (
					pres.WhatHappened == WhatHappened.Enrollment &&
					pres.TotalAHP != null
				) {
					AhpPerSale += pres.TotalAHP;
				}
			});
			if (this.GetTotalSales != null) {
				return AhpPerSale > 0 ? AhpPerSale / this.GetTotalSales : 0.0;
			}
			return 0.0;
		}
		return AhpPerSale;
	}

	get GetPlusLeadsCollected() {
		var plusLeadCollected = 0;
		if (
			this.SelectedPresentationsList != null &&
			this.SelectedPresentationsList.length > 0
		) {
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (pres.TotalReferals != null) {
					plusLeadCollected += pres.TotalReferals;
				}
			});
			return plusLeadCollected > 0 ? plusLeadCollected : 0;
		}
		return plusLeadCollected;
	}

	get GetPlusLeadsPerPresentation() {
		var plusLeadsPerPresentation = 0;
		if (
			this.SelectedPresentationsList.length > 0 &&
			this.GetPlusLeadsCollected > 0
		) {
			plusLeadsPerPresentation =
				this.GetPlusLeadsCollected / this.GetTotalPresentations;
			return plusLeadsPerPresentation > 0
				? ConvertIntoAmountFormatWithDecimal(
						plusLeadsPerPresentation.toFixed(2).toString()
				  )
				: 0;
		}
		return plusLeadsPerPresentation;
	}

	get GetTimePerPresentations() {
		var timePerPresentation = "00:00:00";

		if (
			this.SelectedPresentationsList != null &&
			this.SelectedPresentationsList.length > 0
		) {
			var totalseconds = 0;
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (pres.TotalTimeSeconds != null) {
					totalseconds += pres.TotalTimeSeconds;
				}
			});
			var TimeinSeconds = totalseconds / this.GetTotalPresentations;
			return TimeinSeconds > 0
				? new Date(TimeinSeconds * 1000).toISOString().slice(11, 19)
				: "00:00:00";
		}
		return timePerPresentation;
	}

	get ShowRecord() {
		var arr = this.$data.FilteredPresentations;
		if (arr != null || arr != undefined) {
			if (arr.length != this.PresentationHistoryTotalCount) {
				setTimeout(() => {
					const ele: any = document.getElementById("pres-history1");

					ele.scrollTop = 0;
				}, 10);
				this.historyCount = 0;
			}
			this.PresentationHistoryTotalCount = arr.length;
		}
		var val = 10;
		if (this.historyCount == 0) {
			this.ShowHistory = [];
		}
		if (this.historyCount + val > this.PresentationHistoryTotalCount) {
			val = this.PresentationHistoryTotalCount - this.historyCount;
		}
		if (arr != null && arr.length > 0) {
			this.ShowHistory = this.ShowHistory.concat(
				arr.slice(this.historyCount, this.historyCount + val)
			);
		}

		return this.ShowHistory;
	}

	IsNeedAnalysisPresentation(presentation: any) {
		var flag = false;
		if (presentation.WhatHappened == WhatHappened.NeedsAnalysisOnly) {
			flag = true;
		}
		return flag;
	}

	OnPresentationsScroll(event: any) {
		window.addEventListener("wheel", this.Updatehistorycount);
	}

	Updatehistorycount(event: any) {
		if (event.deltaY > 0) {
			setTimeout(() => {
				const ele: any = document.getElementById("pres-history1");
				var test = ele.scrollTop + ele.offsetHeight >= ele.scrollHeight;
				if (test) {
					this.historyCount += 10;
				}
			}, 10);
		}
	}

	get GetPresentationTypeChartData() {
		let PresentationTypeChartData: any = [];
		this.PresentationType = [];
		if (this.SelectedPresentationsList != null) {
			if (this.PresentationCountByPresType[PresentationType.Referral] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Referral),
					Count: this.PresentationCountByPresType[PresentationType.Referral],
					Title: "REFERRAL",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Referral,
				// 	Name: "REFERRAL",
				// });
			}
			if (
				this.PresentationCountByPresType[PresentationType.McGruffSafeKids] > 0
			) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.McGruffSafeKids
					),
					Count:
						this.PresentationCountByPresType[PresentationType.McGruffSafeKids],
					Title: "MCGRUFF SAFE KIDS",
				});
				this.PresentationType.push({
					Id: PresentationType.McGruffSafeKids,
					Name: "MCGRUFF SAFE KIDS",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.ChildSafe] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.ChildSafe),
					Count: this.PresentationCountByPresType[PresentationType.ChildSafe],
					Title: "CHILD SAFE",
				});
				this.PresentationType.push({
					Id: PresentationType.ChildSafe,
					Name: "CHILD SAFE",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.POS] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.POS),
					Count: this.PresentationCountByPresType[PresentationType.POS],
					Title: "P.O.S",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.POS,
				// 	Name: "P.O.S",
				// });
			}
			if (this.PresentationCountByPresType[PresentationType.Union] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Union),
					Count: this.PresentationCountByPresType[PresentationType.Union],
					Title: "UNION",
				});
				this.PresentationType.push({
					Id: PresentationType.Union,
					Name: "UNION",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.Association] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Association),
					Count: this.PresentationCountByPresType[PresentationType.Association],
					Title: "ASSOCIATION",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Association,
				// 	Name: "ASSOCIATION",
				// });
			}
			if (this.PresentationCountByPresType[PresentationType.CreditUnion] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.CreditUnion),
					Count: this.PresentationCountByPresType[PresentationType.CreditUnion],
					Title: "CREDIT UNION",
				});
				this.PresentationType.push({
					Id: PresentationType.CreditUnion,
					Name: "CREDIT UNION",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.Other] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Other),
					Count: this.PresentationCountByPresType[PresentationType.Other],
					Title: "OTHER",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Other,
				// 	Name: "OTHER",
				// });
			}

			if (this.PresentationCountByPresType[PresentationType.Veteran] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Veteran),
					Count: this.PresentationCountByPresType[PresentationType.Veteran],
					Title: "VETERAN",
				});
				this.PresentationType.push({
					Id: PresentationType.Veteran,
					Name: "VETERAN",
				});
			}
			if (
				this.PresentationCountByPresType[PresentationType.WillKitNoCostLaw] > 0
			) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.WillKitNoCostLaw
					),
					Count:
						this.PresentationCountByPresType[PresentationType.WillKitNoCostLaw],
					Title: "WILL KIT (NO COST LAW)",
				});
				this.PresentationType.push({
					Id: PresentationType.WillKitNoCostLaw,
					Name: "WILL KIT (NO COST LAW)",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.FinalExpense] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.FinalExpense
					),
					Count:
						this.PresentationCountByPresType[PresentationType.FinalExpense],
					Title: "FINAL EXPENSE",
				});
				this.PresentationType.push({
					Id: PresentationType.FinalExpense,
					Name: "FINAL EXPENSE",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.Demo] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Demo),
					Count: this.PresentationCountByPresType[PresentationType.Demo],
					Title: "DEMO",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Demo,
				// 	Name: "DEMO",
				// });
			}
			if (this.PresentationCountByPresType[PresentationType.GlobeLife] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.GlobeLife),
					Count: this.PresentationCountByPresType[PresentationType.GlobeLife],
					Title: "GLOBE LIFE",
				});
				this.PresentationType.push({
					Id: PresentationType.GlobeLife,
					Name: "GLOBE LIFE",
				});
			}
			if (
				this.PresentationCountByPresType[
					PresentationType.ServiceMemberBenefits
				] > 0
			) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.ServiceMemberBenefits
					),
					Count:
						this.PresentationCountByPresType[
							PresentationType.ServiceMemberBenefits
						],
					Title: "SERVICE MEMBER BENEFITS",
				});
				this.PresentationType.push({
					Id: PresentationType.ServiceMemberBenefits,
					Name: "SERVICE MEMBER BENEFITS",
				});
			}
			this.SelectedPresentationType =
				this.PresentationType.length > 0 ? this.PresentationType[0] : null;
			this.presentationsStats.PresentationTypeChartData =
				PresentationTypeChartData;
			return this.FillChartDataFromAPIData(PresentationTypeChartData);
		}
		this.presentationsStats.PresentationTypeChartData =
			PresentationTypeChartData;
		return this.FillChartDataFromAPIData(PresentationTypeChartData);
	}

	get GetDispositionChartData() {
		let DispositionChartData: any = [];
		if (this.SelectedPresentationsList != null) {
			if (this.PresentationCountByWhatHappened[WhatHappened.Enrollment] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.Enrollment
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Enrollment],
					Title: "ENROLLMENT",
				});
			}

			if (
				this.PresentationCountByWhatHappened[WhatHappened.NotInterested] > 0
			) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.NotInterested
					),
					Count:
						this.PresentationCountByWhatHappened[WhatHappened.NotInterested],
					Title: "NOT INTERESTED",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Think] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(WhatHappened.Think),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Think],
					Title: "THINK",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.JustNoCost] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.JustNoCost
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.JustNoCost],
					Title: "JUST NO COST",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Qualify] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(WhatHappened.Qualify),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Qualify],
					Title: "QUALIFY",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Training] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.Training
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Training],
					Title: "TRAINING",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Afford] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(WhatHappened.Afford),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Afford],
					Title: "AFFORD",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Postponed] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.Postponed
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Postponed],
					Title: "POSTPONED",
				});
			}
			if (
				this.PresentationCountByWhatHappened[WhatHappened.NeedsAnalysisOnly] > 0
			) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.NeedsAnalysisOnly
					),
					Count:
						this.PresentationCountByWhatHappened[
							WhatHappened.NeedsAnalysisOnly
						],
					Title: "NEEDS ANALYSIS ONLY",
				});
			}

			this.presentationsStats.DispositionChartData = DispositionChartData;
			return this.FillChartDataFromAPIData(DispositionChartData);
		}
		this.presentationsStats.DispositionChartData = DispositionChartData;
		return this.FillChartDataFromAPIData(DispositionChartData);
	}

	get GetHouseHoldTypeChartData() {
		let HouseHoldTypeChartData: any = [];
		if (this.SelectedPresentationsList != null) {
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.single] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.single),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.single],
					Title: "single",
				});
			}
			if (
				this.PresentationCountByHouseHold[HpProHouseHoldType.single_parent] > 0
			) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.single_parent),
					Count:
						this.PresentationCountByHouseHold[HpProHouseHoldType.single_parent],
					Title: "Single Parent",
				});
			}
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.married] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.married),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.married],
					Title: "Married",
				});
			}
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.family] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.family),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.family],
					Title: "Family",
				});
			}
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.retired] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.retired),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.retired],
					Title: "Retired",
				});
			}
			if (
				this.PresentationCountByHouseHold[HpProHouseHoldType.single_retired] > 0
			) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.single_retired),
					Count:
						this.PresentationCountByHouseHold[
							HpProHouseHoldType.single_retired
						],
					Title: "Single/ Retired",
				});
			}
			if (
				this.PresentationCountByHouseHold[HpProHouseHoldType.married_retired] >
				0
			) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.married_retired),
					Count:
						this.PresentationCountByHouseHold[
							HpProHouseHoldType.married_retired
						],
					Title: "Married/ Retired",
				});
			}
			this.presentationsStats.HouseHoldTypeChartData = HouseHoldTypeChartData;
			return this.FillChartDataFromAPIData(HouseHoldTypeChartData);
		}
		this.presentationsStats.HouseHoldTypeChartData = HouseHoldTypeChartData;
		return this.FillChartDataFromAPIData(HouseHoldTypeChartData);
	}

	get GetPresentationSubTypeChartData() {
		let PresentationSubTypeChartData: any = [];
		this.PresentationCountBySubType = [];
		if (
			this.SelectedPresentationsList != null &&
			this.SelectedPresentationType != null
		) {
			let selectedPrestypePresentations = this.SelectedPresentationsList.filter(
				(x: any) => this.SelectedPresentationType.Id == x.PresentationTypeId
			);
			selectedPrestypePresentations.forEach((x: any) => {
				console.log(
					x.PrimaryFirstName,
					x.PresentationStatus,
					x.PresentationTypeId,
					x.PresentationSubTypeId
				);
			});
			if (selectedPrestypePresentations.length > 0) {
				selectedPrestypePresentations.forEach((pres: any) => {
					if (this.SelectedPresentationType.Id == pres.PresentationTypeId) {
						UpdatePresentationSubTypeCount(
							pres.PresentationSubTypeId,
							this.PresentationCountBySubType
						);
					}
				});
				if (this.PresentationCountBySubType.length > 0) {
					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineMCGruffLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineMCGruffLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineMCGruffLead
								],
							Title: "Online McGruff Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredMcGruffLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredMcGruffLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredMcGruffLead
								],
							Title: "Sponsored McGruff Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineChildSafeLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineChildSafeLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineChildSafeLead
								],
							Title: "Online Child Safe Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredChildSafeLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredChildSafeLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredChildSafeLead
								],
							Title: "Sponsored Child Safe Lead",
						});
					}
					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredVeteranLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredVeteranLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredVeteranLead
								],
							Title: "Sponsored Veteran Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.VeteranFamilyLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.VeteranFamilyLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.VeteranFamilyLead
								],
							Title: "Veteran Family Lead",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.ReturnCard] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReturnCard
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.ReturnCard],
							Title: "Return Card",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.BurielWillKitForVeteran
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.BurielWillKitForVeteran
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.BurielWillKitForVeteran
								],
							Title: "Buriel Will Kit For Veteran",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredNoCostLaw
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredNoCostLaw
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredNoCostLaw
								],
							Title: "Sponsored No Cost Law",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineNoCostLaw
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineNoCostLaw
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineNoCostLaw
								],
							Title: "Online No Cost Law",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredFinalExpense
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredFinalExpense
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredFinalExpense
								],
							Title: "Sponsored Final Expense",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineFinalExpense
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineFinalExpense
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineFinalExpense
								],
							Title: "Online Final Expense",
						});
					}

					if (this.PresentationCountBySubType[PresentationSubType.Afford] > 0) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Afford
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.Afford],
							Title: "Afford",
						});
					}

					if (this.PresentationCountBySubType[PresentationSubType.Think] > 0) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Think
							),
							Count: this.PresentationCountBySubType[PresentationSubType.Think],
							Title: "Think",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.Qualify] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Qualify
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.Qualify],
							Title: "Qualify",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.NotInterested] >
						0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.NotInterested
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.NotInterested
								],
							Title: "Not Interested",
						});
					}

					if (this.PresentationCountBySubType[PresentationSubType.Sale] > 0) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Sale
							),
							Count: this.PresentationCountBySubType[PresentationSubType.Sale],
							Title: "Sale",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ReportFormRequest
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReportFormRequest
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ReportFormRequest
								],
							Title: "Report Form Request",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredCreditUnionBenefits
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredCreditUnionBenefits
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredCreditUnionBenefits
								],
							Title: "Sponsored Credit Union Benefits",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineCreditUnionBenefits
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineCreditUnionBenefits
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineCreditUnionBenefits
								],
							Title: "Online Credit Union Benefits USA",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineCreditUnionBenefitsCA
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineCreditUnionBenefitsCA
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineCreditUnionBenefitsCA
								],
							Title: "Online Credit Union Benefits CA",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ReturnCardNoCost
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReturnCardNoCost
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ReturnCardNoCost
								],
							Title: "Return Card",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ServiceMemberBenefitsLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ServiceMemberBenefitsLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ServiceMemberBenefitsLead
								],
							Title: "Service Member Benefits Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ServiceMemberBenefitsLeadAlpha
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ServiceMemberBenefitsLeadAlpha
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ServiceMemberBenefitsLeadAlpha
								],
							Title: "Service Member Benefits Lead (Alpha)",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.MarketUnionAssocMember
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.MarketUnionAssocMember
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.MarketUnionAssocMember
								],
							Title: "Market Union/Assoc Member",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.MarketUnionAssocFamilyFriend
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.MarketUnionAssocFamilyFriend
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.MarketUnionAssocFamilyFriend
								],
							Title: "Market Union/Assoc Family/Friend",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ReturnCardUnion
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReturnCardUnion
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ReturnCardUnion
								],
							Title: "Return Card",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.GlobeWebApplication
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobeWebApplication
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.GlobeWebApplication
								],
							Title: "Globe Web Application",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.GlobeCustomer] >
						0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobeCustomer
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.GlobeCustomer
								],
							Title: "Globe Customer",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.GlobePlus] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobePlus
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.GlobePlus],
							Title: "Globe Plus",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.GlobeInquiry] >
						0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobeInquiry
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.GlobeInquiry
								],
							Title: "Globe Inquiry",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBSponsoredVeteranLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBSponsoredVeteranLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBSponsoredVeteranLead
								],
							Title: "Sponsored Veteran Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBVeteranFamilyLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBVeteranFamilyLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBVeteranFamilyLead
								],
							Title: "Veteran Family Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBSponsoredVeteranLeadAlpha
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBSponsoredVeteranLeadAlpha
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBSponsoredVeteranLeadAlpha
								],
							Title: "Sponsored Veteran Lead (Alpha)",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBVeteranFamilyLeadAlpha
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBVeteranFamilyLeadAlpha
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBVeteranFamilyLeadAlpha
								],
							Title: "Veteran Family Lead (Alpha)",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.Civilian] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Civilian
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.Civilian],
							Title: "Civilian",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ServiceMemberBenefitNotification
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ServiceMemberBenefitNotification
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ServiceMemberBenefitNotification
								],
							Title: "Service Member Benefit Notification",
						});
					}
				}
			}

			this.presentationsStats.PresentationSubTypeChartData =
				PresentationSubTypeChartData;
			return this.FillChartDataFromAPIData(PresentationSubTypeChartData);
		}
		this.presentationsStats.PresentationSubTypeChartData =
			PresentationSubTypeChartData;
		return this.FillChartDataFromAPIData(PresentationSubTypeChartData);
	}

	GetDispositionList(presentation: any) {
		this.DispositionTypeListForUpdate = [];
		if (presentation.WhatHappened == WhatHappened.NeedsAnalysisOnly) {
			WhatHappenedDiscription.forEach((description, value) => {
				if (
					description.toLowerCase() ==
					WhatHappenedDiscription.get(
						WhatHappened.NeedsAnalysisOnly
					)?.toLowerCase()
				) {
					this.DispositionTypeListForUpdate.push({
						Whathappened: value,
						PresentationStatus: description.toUpperCase(),
					});
				}
			});
		} else {
			WhatHappenedDiscription.forEach((description, value) => {
				if (
					description.toLowerCase() !=
					WhatHappenedDiscription.get(WhatHappened.Training)?.toLowerCase()
				) {
					this.DispositionTypeListForUpdate.push({
						Whathappened: value,
						PresentationStatus: description.toUpperCase(),
					});
				}
			});
		}
	}

	GetSelectedPresentations() {
		this.PresentationCountByPresType = [];
		this.PresentationCountByWhatHappened = [];
		this.PresentationCountByHouseHold = [];

		if (this.$data.FilteredPresentations != undefined) {
			let selectedpresentations: any = [];
			this.$data.FilteredPresentations.forEach((pres: any) => {
				if (
					pres.PresentationStatus != "INCOMPLETE" &&
					pres.WhatHappened != WhatHappened.Training &&
					pres.WhatHappened != WhatHappened.Postponed &&
					pres.WhatHappened != WhatHappened.NeedsAnalysisOnly
				) {
					selectedpresentations.push(pres);
					this.PresentationCountByPresType = UpdatePresentationTypeCount(
						pres.PresentationTypeId,
						this.PresentationCountByPresType
					);
					this.PresentationCountByWhatHappened =
						UpdatePresentationWhatHappenedCount(
							pres.WhatHappened,
							this.PresentationCountByWhatHappened
						);
					this.PresentationCountByHouseHold = UpdatePresentationHouseHoldCount(
						pres.HouseHoldType,
						this.PresentationCountByHouseHold
					);
				}
			});
			return selectedpresentations;
		}
		return null;
	}

	cancelneedssurvey() {
		this.ShowNeedsSurvey = false;
	}

	canceltsheet() {
		this.ShowTSheet = false;
	}

	cancelproductsummary() {
		this.ShowProductSummary = false;
	}

	cancelreportcard() {
		this.ShowReportCard = false;
	}

	PresentationFilter(evt: any) {
		this.$data.FilteredPresentations = this.$data.Presentations;
		if (evt != "") {
			this.$data.FilteredPresentations = this.$data.Presentations.filter(
				(x: any) => {
					if (
						x.PrimaryFullName.toLowerCase().includes(evt.toLowerCase()) ||
						x.PresentationStatus.toLowerCase().includes(evt.toLowerCase()) ||
						x.PresentationTypeString.toLowerCase().includes(evt.toLowerCase())
					) {
						return x;
					}
				}
			);
		}
	}

	async ShowNeedsSurveyModal(pres: any) {
		await this.RetrieveDataForModalorDownload(pres, false);
		this.ShowNeedsSurvey = true;
	}

	async ShowTSheetModal(pres: any) {
		await this.RetrieveDataForModalorDownload(pres, false);
		if (this.$data.currentPresentationToPrint.BenefitSummary != undefined) {
			if (
				Object.keys(this.$data.currentPresentationToPrint.BenefitSummary)
					.length > 0
			) {
				this.ShowTSheet = true;
			} else {
				this.$store.state.messageText = "No Data Available";
				this.$store.state.showMessage = true;
			}
		}
	}

	async ShowProductSummaryModal(pres: any) {
		await this.RetrieveDataForModalorDownload(pres, false);
		if (this.$data.currentPresentationToPrint.BenefitSummary != undefined) {
			if (
				Object.keys(this.$data.currentPresentationToPrint.BenefitSummary)
					.length > 0
			) {
				this.ShowProductSummary = true;
			} else {
				this.$store.state.messageText = "No Data Available";
				this.$store.state.showMessage = true;
			}
		}
	}

	async ShowReportCardModal(pres: any) {
		await this.RetrieveDataForModalorDownload(pres, false);
		this.ShowReportCard = true;
	}

	async RetrieveDataForModalorDownload(
		presentation: any,
		retrievefordownload: boolean
	) {
		this.$data.currentPresentationToPrint = {
			Presentation: {},
			BenefitSummary: {},
		};
		if (!window.navigator.onLine) {
			this.$store.state.messageText = this.$t("General.NeedActiveConnect");
			this.$store.state.showMessage = true;
			return;
		}
		if (retrievefordownload) {
			this.$data.presKey++;
		}
		this.$store.state.loader = true;
		await APIService.GetPresentationById(presentation.Id)
			.then(async (res: any) => {
				if (res != undefined && res.Result != undefined && res.Result != null) {
					if (
						res.Result.ReportCardOptions != undefined &&
						res.Result.ReportCardOptions != null &&
						res.Result.ReportCardOptions.length > 0
					) {
						this.ReportCardOptions = res.Result.ReportCardOptions;
					}
					var pres: Presentation = await PresentationDTOToEntity(res.Result);
					this.$data.currentPresentationToPrint.Presentation = pres;
					if (
						this.$data.currentPresentationToPrint.Presentation.FieldTraining ==
							"WITHSOMEONE" &&
						this.$data.currentPresentationToPrint.Presentation
							.FieldTrainingAssociateId != null
					) {
						if (
							this.CompleteUserList != undefined &&
							this.CompleteUserList.length > 0
						) {
							var FieldTrainingUser = this.CompleteUserList.find((x: any) => {
								if (
									x.AssociateId ==
									this.$data.currentPresentationToPrint.Presentation
										.FieldTrainingAssociateId
								) {
									return x;
								}
							});
						}
					}
					if (FieldTrainingUser != undefined) {
						this.$data.currentPresentationToPrint.Presentation.FieldTrainee =
							FieldTrainingUser.UserInfo != undefined
								? FieldTrainingUser.UserInfo.FullName?.toUpperCase()
								: "";
					}
					LoadPremiumAmountForPrinting(this.$data.currentPresentationToPrint);
					if (
						res.Result.PlanOptions != undefined &&
						res.Result.PlanOptions.length > 0
					) {
						var benSummary = res.Result.PlanOptions[0].BenefitsCalculation;
						if (benSummary != undefined) {
							this.$data.currentPresentationToPrint.BenefitSummary =
								AmountFormatAllFields(benSummary);
							if (
								this.$data.currentPresentationToPrint.BenefitSummary
									.RCHeading == null
							) {
								this.ShowRCSection = false;
								this.RCDataExists(res.Result.PlanOptions[0]);
							}
						}
						this.$data.currentPresentationToPrint.MBD =
							res.Result.PlanOptions[0].MBD;
						this.$data.currentPresentationToPrint.TotalAP =
							res.Result.PlanOptions[0].TotalAP;

						await GetProductNameById(
							res.Result.PlanOptions[0].PrimaryProducts,
							res.Result.PlanOptions[0].SpouseProducts,
							res.Result?.PrimaryDOBAndAge,
							res.Result?.SpouseDOBAndAge
						);
						this.$data.currentPresentationToPrint.BenefitSummary.PrimaryProducts =
							res.Result.PlanOptions[0].PrimaryProducts;
						this.ShowHospitalBenefits = false;
						if (
							this.$data.currentPresentationToPrint.BenefitSummary
								.PrimaryProducts != undefined &&
							this.$data.currentPresentationToPrint.BenefitSummary
								.PrimaryProducts != null &&
							this.$data.currentPresentationToPrint.BenefitSummary
								.PrimaryProducts.length > 0
						) {
							var A71000Selected =
								this.$data.currentPresentationToPrint.BenefitSummary.PrimaryProducts.find(
									(x: any) => {
										if (x.ProductId == 17 && x.Selected == true) {
											return x;
										}
									}
								);
							if (A71000Selected != undefined && A71000Selected != null) {
								this.ShowHospitalBenefits = true;
							}
						}
						this.$data.currentPresentationToPrint.BenefitSummary.SpouseProducts =
							res.Result.PlanOptions[0].SpouseProducts;
						// this.$data.currentPresentationToPrint.Presentation =
						//   res.Result.PlanOptions;
					}
					if (
						this.$data.currentPresentationToPrint.Presentation != undefined &&
						this.$data.currentPresentationToPrint.Presentation.PresentedTo !=
							undefined &&
						this.$data.currentPresentationToPrint.Presentation.PresentedTo
							.ChildrenString != undefined &&
						this.$data.currentPresentationToPrint.Presentation.PresentedTo
							.ChildrenString != ""
					)
						this.$data.Childrens = JSON.parse(
							this.$data.currentPresentationToPrint.Presentation.PresentedTo
								.ChildrenString
						);
					this.$data.OwnTrue =
						this.$data.currentPresentationToPrint.Presentation.PresentedTo.OwnHome;
					this.$data.RentTrue =
						this.$data.currentPresentationToPrint.Presentation.PresentedTo.RentHome;
					if (retrievefordownload) {
						this.$data.pdfoptions.filename =
							EntityHelper.capitalizeTheFirstLetterOfEachWord(
								this.$data.currentPresentationToPrint.Presentation.PresentedTo
									.GetNameHolder
							).replaceAll(".", "") +
							"-" +
							this.$data.currentPresentationToPrint.Presentation
								.PresentationDay;
						(".pdf");
						setTimeout(() => {
							this.$refs.html2Pdf.generatePdf();
							this.$store.state.loader = false;
						}, 3000);
					} else {
						this.$store.state.loader = false;
					}
				} else {
					this.$store.state.messageText = retrievefordownload
						? "Unable To Download Presentation. Please Try Again."
						: "Unable To Fetch Data, Please Try Again.";
					this.$store.state.loader = false;
					this.$store.state.showMessage = true;
				}
			})
			.catch((ex) => {
				this.$store.state.messageText = retrievefordownload
					? "Unable To Download Presentation. Please Try Again."
					: "Unable To Fetch Data, Please Try Again.";
				this.$store.state.loader = false;
				this.$store.state.showMessage = true;
			});
	}

	@Watch("chartselectiondialog")
	OnPopupClosed() {
		if (this.$data.chartselectiondialog == false) {
			if (this.showchartCounter == 0) {
				this.ShowPresentationType = true;
				this.ShowDispositionType = true;
				this.showchartCounter = 2;
			}

			var ChartList: any = [];
			if (this.ShowPresentationType == true)
				ChartList.push(ChartType.Presentation_Type);
			if (this.ShowDispositionType == true)
				ChartList.push(ChartType.Disposition_Type);
			if (this.ShowHouseHoldType == true)
				ChartList.push(ChartType.Household_Type);
			if (this.ShowPresentationSubType == true)
				ChartList.push(ChartType.Presentation_Subtypes);

			var params: GenericKeyPair[] = [];
			PostRequestWithAuthorization(
				ApiEndPointConstants.SaveDefaultChartEndPoint,
				params,
				ChartList
			);
		}
	}

	async DownloadPresentation(presentation: any) {
		this.RetrieveDataForModalorDownload(presentation, true);
	}

	CreateMonthList() {
		var selectedYear = moment().year().toString();
		var CurrentMonth = (moment().month() + 1).toString();
		if (
			this.$route.params != undefined &&
			Object.keys(this.$route.params)?.length != 0 &&
			this.$route.params.year != undefined
		) {
			selectedYear = this.$route.params.year.toString();
			CurrentMonth = moment().month(this.$route.params.month).format("M");
		}

		for (let i = 0; i < this.MonthsOfYear.length; i++) {
			this.MonthList.push({
				id: this.MonthsOfYear[i].id,
				text: `${this.MonthsOfYear[i].month} - ${selectedYear}`,
				Year: selectedYear,
			});
		}
		this.selectedMonth = this.MonthList.find((x: any) => {
			if (x.id == CurrentMonth) return x;
		});
	}

	async CreatePresentationList() {
		var allPresTypes: any = await getPersistedState("PresentationTypesVeteran");
		this.MainPresentationTypeList = allPresTypes.filter((x: any) => {
			if (x.Name != "PRO LEAD") {
				return x;
			}
		});
	}

	async CreateGroupList() {
		this.PresentationGroupsList = await getPersistedState("PresentationGroups");
	}

	CreateDispositionList() {
		WhatHappenedDiscription.forEach((discription, value) => {
			if (discription.toLowerCase() != "training") {
				this.DispositionTypeListForUpdate.push({
					Whathappened: value,
					PresentationStatus: discription.toUpperCase(),
				});
			}
		});
	}

	FilterSubTypesUponAlphaAccess(PresentationTypeId: number) {
		if (
			this.SubTypes != undefined &&
			this.SubTypes.length > 0 &&
			PresentationTypeId == PresentationType.Veteran
		) {
			let ServiceMemberBenefitsLeadIndex = this.SubTypes.indexOf(
				this.SubTypes.find((x: any) => x.Name == "SERVICE MEMBER BENEFITS LEAD")
			);
			if (ServiceMemberBenefitsLeadIndex > -1) {
				this.SubTypes.splice(ServiceMemberBenefitsLeadIndex, 1);
			}
			let ServiceMemberBenefitsLeadAlphaIndex = this.SubTypes.indexOf(
				this.SubTypes.find(
					(x: any) => x.Name == "SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				)
			);
			if (ServiceMemberBenefitsLeadAlphaIndex > -1) {
				this.SubTypes.splice(ServiceMemberBenefitsLeadAlphaIndex, 1);
			}
		}

		if (
			this.SubTypes != undefined &&
			this.SubTypes.length > 0 &&
			PresentationTypeId == PresentationType.ServiceMemberBenefits &&
			this.userDetail != undefined
		) {
			if (this.userDetail.VeteranSmbAllowed) {
				let SponsoredVeteranLeadIndex = this.SubTypes.indexOf(
					this.SubTypes.find((x: any) => x.Name == "SPONSORED VETERAN LEAD")
				);
				if (SponsoredVeteranLeadIndex > -1) {
					this.SubTypes.splice(SponsoredVeteranLeadIndex, 1);
				}
				let VeteranFamilyLeadIndex = this.SubTypes.indexOf(
					this.SubTypes.find((x: any) => x.Name == "VETERAN FAMILY LEAD")
				);
				if (VeteranFamilyLeadIndex > -1) {
					this.SubTypes.splice(VeteranFamilyLeadIndex, 1);
				}
			} else {
				let SponsoredVeteranLeadAlphaIndex = this.SubTypes.indexOf(
					this.SubTypes.find(
						(x: any) => x.Name == "SPONSORED VETERAN LEAD (ALPHA)"
					)
				);
				if (SponsoredVeteranLeadAlphaIndex > -1) {
					this.SubTypes.splice(SponsoredVeteranLeadAlphaIndex, 1);
				}
				let VeteranFamilyLeadAlphaIndex = this.SubTypes.indexOf(
					this.SubTypes.find(
						(x: any) => x.Name == "VETERAN FAMILY LEAD (ALPHA)"
					)
				);
				if (VeteranFamilyLeadAlphaIndex > -1) {
					this.SubTypes.splice(VeteranFamilyLeadAlphaIndex, 1);
				}
				let CivilianIndex = this.SubTypes.indexOf(
					this.SubTypes.find((x: any) => x.Name == "CIVILIAN")
				);
				if (CivilianIndex > -1) {
					this.SubTypes.splice(CivilianIndex, 1);
				}
				let ServiceMemberBenefitNotificationIndex = this.SubTypes.indexOf(
					this.SubTypes.find(
						(x: any) => x.Name == "SERVICE MEMBER BENEFIT NOTIFICATION"
					)
				);
				if (ServiceMemberBenefitNotificationIndex > -1) {
					this.SubTypes.splice(ServiceMemberBenefitNotificationIndex, 1);
				}
			}
		}
	}

	ChangePresentationTypeFromHistory(evt: any) {
		this.HideSubTypeDropDown = true;
		var presType = evt;
		this.PresentationSelectedForChange.PresentationTypeId = presType.Id;
		this.PresentationSelectedForChange.PresentationTypeString = presType.Name;
		this.SubTypes = presType.SubTypes?.slice(0);
		this.FilterSubTypesUponAlphaAccess(presType.Id);
		this.PresentationSelectedForChange.PresentationSubTypeId = null;
		this.PresentationSelectedForChange.PresentationSubTypeString = "";
		if (this.SubTypes != null && this.SubTypes.length > 0) {
			this.HideSubTypeDropDown = false;
		}
		if (presType.Id == this.PresentationCheckForEdit.PresentationTypeId) {
			this.PresentationChanged = false;
		} else {
			this.PresentationChanged = true;
		}
	}

	ChangePresentationSubTypeFromHistory(evt: any) {
		var presSubType = evt;
		this.PresentationSelectedForChange.PresentationSubTypeId = presSubType.Id;
		this.PresentationSelectedForChange.PresentationSubTypeString =
			presSubType.Name;
		if (presSubType.Id == this.PresentationCheckForEdit.PresentationSubTypeId) {
			this.PresentationSubChanged = false;
		} else {
			this.PresentationSubChanged = true;
		}
	}

	PresentationDetailChange() {
		if (
			!this.HideSubTypeDropDown &&
			(this.PresentationSelectedForChange.PresentationSubTypeString == null ||
				this.PresentationSelectedForChange.PresentationSubTypeString == "")
		) {
			this.$store.state.messageText = "Please Select Presentation Sub Type";
			this.$store.state.showMessage = true;
			return;
		}
		this.$data.Presentations.find((element: any) => {
			if (element.Id == this.PresentationCheckForEdit.Id) {
				element.WhatHappened = this.PresentationSelectedForChange.WhatHappened;
				element.PresentationStatus =
					this.PresentationSelectedForChange.PresentationStatus;
				element.PresentationTypeId =
					this.PresentationSelectedForChange.PresentationTypeId;
				element.PresentationTypeString =
					this.PresentationSelectedForChange.PresentationTypeString;
				element.PresentationSubTypeId =
					this.PresentationSelectedForChange.PresentationSubTypeId;
				element.PresentationSubTypeString =
					this.PresentationSelectedForChange.PresentationSubTypeString;
				element.HistoryNotes =
					this.PresentationSelectedForChange.PresentationHistoryNotes;
				this.$store.state.loader = true;
				var PresDTO = {
					PresentationId: element.Id,
					PresentationTypeId: element.PresentationTypeId,
					PresentationSubTypeId: element.PresentationSubTypeId,
					WhatHappen: element.WhatHappened,
					HistoryNotes: element.HistoryNotes,
				};
				var GenericKeyPairobj: GenericKeyPair[] = [];
				APIService.UpdatePresentationDetail(PresDTO, GenericKeyPairobj).then(
					async (res: any) => {
						if (res != undefined && res.data != null && res.data == true) {
							this.$store.state.loader = false;
							this.PresentationFilter(this.FilterText);
						} else {
							this.$store.state.loader = false;
						}
					}
				);
				this.EditPresentationTypedialog = false;
				this.EditDispositionTypedialog = false;
				this.PresentationNotesDialog = false;
			}
		});
	}

	DispositionTypeChangeFromHistory(evt: any) {
		var presStatus = evt;
		this.PresentationSelectedForChange.WhatHappened = presStatus.Whathappened;
		this.PresentationSelectedForChange.PresentationStatus =
			presStatus.PresentationStatus;
		if (presStatus.Whathappened == this.PresentationCheckForEdit.WhatHappened) {
			this.DispositionChanged = false;
		} else {
			this.DispositionChanged = true;
		}
	}

	ShowEditDispositionTypeOrPresentationType(presentation: any, dialog: string) {
		this.PresentationCheckForEdit = presentation;
		this.PresentationSelectedForChange.PresentationStatus =
			presentation.PresentationStatus;
		this.PresentationSelectedForChange.WhatHappened = presentation.WhatHappened;
		this.PresentationSelectedForChange.PresentationTypeString =
			presentation.PresentationTypeString;
		this.PresentationSelectedForChange.PresentationTypeId =
			presentation.PresentationTypeId;
		this.PresentationSelectedForChange.PresentationSubTypeString =
			presentation.PresentationSubTypeString;
		this.PresentationSelectedForChange.PresentationSubTypeId =
			presentation.PresentationSubTypeId;
		this.PresentationSelectedForChange.PresentationHistoryNotes =
			presentation.HistoryNotes;
		if (
			this.MainPresentationTypeList != undefined &&
			this.MainPresentationTypeList.length > 0
		) {
			if (presentation.CountryId == 2) {
				this.PresentationTypeListForUpdate =
					this.MainPresentationTypeList.filter((x: any) => {
						if (x.ExcludeCountry != 2) return x;
					});
			} else {
				this.PresentationTypeListForUpdate =
					this.MainPresentationTypeList.slice(0);
			}
		}
		var SelectedPresentationInitially = this.MainPresentationTypeList.find(
			(x: any) => {
				if (this.PresentationCheckForEdit.PresentationTypeId == x.Id) {
					return x;
				}
			}
		);
		this.SubTypes = SelectedPresentationInitially.SubTypes?.slice(0);
		this.FilterSubTypesUponAlphaAccess(SelectedPresentationInitially.Id);
		if (this.SubTypes != undefined && this.SubTypes.length > 0) {
			this.HideSubTypeDropDown = false;
		} else {
			this.HideSubTypeDropDown = true;
		}
		if (dialog == "Presentation") {
			this.PresentationChanged = false;
			this.PresentationSubChanged = false;
			this.EditPresentationTypedialog = true;
		} else if (dialog == "Disposition") {
			this.GetDispositionList(presentation);
			this.DispositionChanged = false;
			this.EditDispositionTypedialog = true;
		} else if (dialog == "HistoryNotes") {
			this.HistoryNotesChanged = false;
			this.PresentationNotesDialog = true;
		}
	}

	ChangeNotesFromHistory() {
		if (
			this.PresentationSelectedForChange.PresentationHistoryNotes ==
			this.PresentationCheckForEdit.HistoryNotes
		) {
			this.HistoryNotesChanged = false;
			this.PresentationNotesDialog = false;
		} else {
			this.HistoryNotesChanged = true;
			this.PresentationDetailChange();
		}
	}

	SelectedMonthChange(evt: any) {
		this.$data.Presentations = [];
		this.FilterText = "";
		this.CurrentMonth = this.selectedMonth.text.replace(
			` - ${this.selectedMonth.Year}`,
			""
		);
		this.FetchPresentations(
			0,
			this.selectedMonth.id,
			Number(this.selectedMonth.Year)
		);
		var date = new Date();
		this.$data.CalendarValue = new Date(
			date.getFullYear(),
			this.selectedMonth.id - 1,
			date.getDay()
		);
	}

	// async ChangePresentationType(evt: any) {
	// 	this.$store.state.loader = true;
	// 	var params: GenericKeyPair[] = [];
	// 	params.push(
	// 		new GenericKeyPair("PresentationTypeId", this.SelectedPresentationType.Id)
	// 	);
	// 	params.push(new GenericKeyPair("month", this.selectedMonth.id));
	// 	var presentations = await GetRequestWithAuthorization(
	// 		"Presentation/GetPresentationsStatsByPresentation",
	// 		params
	// 	);
	// 	this.$data.PresentationSubChartData = this.FillChartDataFromAPIData(
	// 		presentations.Result
	// 	);

	// 	this.presentationsStats.PresentationSubTypeChartData = presentations.Result;

	// 	this.$store.state.loader = false;
	// }

	// async ChangePresentationTypeSingle(evt: any) {
	// 	this.$store.state.loader = true;
	// 	var params: GenericKeyPair[] = [];
	// 	params.push(
	// 		new GenericKeyPair("PresentationTypeId", this.SelectedPresentationType.Id)
	// 	);
	// 	params.push(new GenericKeyPair("month", this.selectedMonth.id));
	// 	var presentations = await GetRequestWithAuthorization(
	// 		"Presentation/GetPresentationsStatsByPresentation",
	// 		params
	// 	);
	// 	this.$data.PresentationSubChartData = this.FillChartDataFromAPIData(
	// 		presentations.Result
	// 	);

	// 	this.$data.PopupChartData = this.$data.PresentationSubChartData;
	// 	this.$data.PopupChartLabels = this.$data.PopupChartData.labels;
	// 	if (presentations.Result != undefined) {
	// 		presentations.Result.forEach((element: any) => {
	// 			element.isRemoved = false;
	// 		});
	// 	}
	// 	this.$data.PopupChartTypeData = presentations.Result;

	// 	this.$store.state.loader = false;
	// }

	data() {
		return {
			chartselectiondialog: false,
			presentationcodescroll: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			CalendarValue: undefined,
			presKey: 1,
			currentPresentationToPrint: { Presentation: {}, BenefitSummary: {} },
			presentationcode: false,
			presentationData: false,
			PieChartPopup: false,
			presentationJSON: "",
			dashboardscroll: {
				bar: {
					opacity: "0.75",
					minSize: ".3",
					background: "#00568b",
				},
			},
			Presentations: this.presentationsa,
			FilteredPresentations: [],
			pdfoptions: {
				margin: 6,
				filename: "hehehe.pdf",
				// pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
			},
			Childrens: [],
			OwnTrue: false,
			RentTrue: false,
			popupChartHeading: "",
			PresTypeChartData: {},
			DispositionChartData: {},
			PresentationSubChartData: {},
			HouseHoldTypeChartData: {},
			PopupChartData: {},
			PopupChartTypeData: [],
			PopupChartLabels: [],
			chartOptions: {
				responsive: true,
			},
		};
	}

	RoutedTo(route: String) {
		if (route != "/presentationhistory") this.$router.push(route.toString());
	}

	async generateCode(presentation: any) {
		this.generatedCode = presentation.PresentationCode;
		this.$data.presentationcode = true;
		LoggingHelper.getInstance().LogEvent(LoggingConstants.PresentationClone);
	}

	showPresentationData(presentation: any) {
		this.$data.presKey++;
		this.$store.state.loader = true;
		APIService.GetPresentationById(presentation.Id).then((res: any) => {
			if (res != undefined && res.Result != undefined) {
				this.$data.presentationData = true;
				this.$data.presentationJSON = res.Result;
				this.$store.state.loader = false;
			}
		});
	}
	TogglePieCarousal() {
		if (this.$refs.pieCarousal != undefined) {
			if (this.$refs.pieCarousal.hasNext) this.$refs.pieCarousal.next();
			else this.$refs.pieCarousal.prev();
		}
	}

	FillChartDataFromAPIData(APIData: any) {
		if (APIData != undefined && APIData.length > 0) {
			var labels: any = [];
			var labelCount: any = [];
			var labelColors: any = [];

			APIData.forEach((element: any) => {
				labels.push(element.Title);
				labelCount.push(element.Count);
				labelColors.push(element.ColorHEX);
			});
			return {
				labels: labels,
				datasets: [
					{
						borderColor: "#F1F1F1",
						borderWidth: 0.5,
						data: labelCount,
						backgroundColor: labelColors,
					},
				],
			};
		} else {
			return {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [labelColors],
					},
				],
			};
		}
	}

	async OnCopyCode() {
		await CopyTextToClipBoard(this.generatedCode.toString());
		this.$store.state.messageText = "Copied to clipboard";
		this.$store.state.showMessage = true;
		this.$data.presentationcode = false;
	}

	private TotalRecord: number = 0;
	private localRecord: number = 0;
	private PresentationType: any = [];
	private SelectedPresentationType: any = [];
	private CompleteUserList: any = [];
	private ShowHospitalBenefits: boolean = false;
	private ShowRCSection: boolean = false;
	private ShowNeedsSurvey: boolean = false;
	private ShowProductSummary: boolean = false;
	private ShowTSheet: boolean = false;
	private ShowReportCard: boolean = false;
	private PresentationCountByPresType: any = [];
	private PresentationCountByWhatHappened: any = [];
	private PresentationCountByHouseHold: any = [];
	private PresentationCountBySubType: any = [];
	private SelectedPresentationsList: any = [];
	private EditPresentationTypedialog: boolean = false;
	private EditDispositionTypedialog: boolean = false;
	private PresentationCheckForEdit: any = {};
	private PresentationSelectedForChange: any = {};
	private PresentationHistoryTotalCount: number = 0;
	private historyCount: number = 0;
	private ShowHistory: any = [];
	private SubTypes: any;
	private HideSubTypeDropDown: boolean = false;
	private ProductsList: any = [];
	private DispositionChanged: boolean = false;
	private PresentationChanged: boolean = false;
	private PresentationSubChanged: boolean = false;
	private HistoryNotesChanged: boolean = false;
	private PresentationNotesDialog: boolean = false;
	private PresentationHistoryNotes: string = "";
	private PresentationForNotes: any;
	private ReportCardOptions: any = [];

	private presentationsStats: any = [];
	async FetchPresentations(skip: number, month: number, year: number) {
		if (skip != 0) skip = skip - this.localRecord;
		this.$store.state.loader = true;
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair("month", month));
		params.push(new GenericKeyPair("year", year));
		var presentations = await GetRequestWithAuthorization(
			"Presentation/GetPresentationsShortDetailForSpecificMonth",
			params
		);
		this.$data.Presentations = [];
		if (presentations != undefined && presentations.Result != undefined) {
			if (
				presentations.Result.PresentationShortDetails != null &&
				presentations.Result.PresentationShortDetails != undefined &&
				presentations.Result.PresentationShortDetails.length > 0
			) {
				this.$data.Presentations.push(
					...presentations.Result.PresentationShortDetails
				);
			}
			this.$data.FilteredPresentations = this.$data.Presentations;

			if (
				presentations.Result.presentationStats != undefined &&
				presentations.Result.presentationStats != null &&
				presentations.Result.presentationStats != ""
			) {
				this.presentationsStats = presentations.Result.presentationStats;
			}

			if (
				presentations.Result.PresentationDefaultChart != undefined &&
				presentations.Result.PresentationDefaultChart.length > 0
			) {
				var DefaultArray = presentations.Result.PresentationDefaultChart;
				this.showchartCounter = 0;
				DefaultArray.forEach((productelement: number) => {
					this.showchartCounter++;
					if (productelement == ChartType.Presentation_Type)
						this.ShowPresentationType = true;
					if (productelement == ChartType.Disposition_Type)
						this.ShowDispositionType = true;
					if (productelement == ChartType.Household_Type)
						this.ShowHouseHoldType = true;
					if (productelement == ChartType.Presentation_Subtypes)
						this.ShowPresentationSubType = true;
				});
			} else {
				this.ShowPresentationType = true;
				this.ShowDispositionType = true;
				this.showchartCounter = 2;
			}
		}

		this.$store.state.loader = false;
	}

	async LoadUnsyncedToo(presentations: any) {
		this.$data.Presentations = [];
		var presentationList: any = await getPersistedState("PresentationList");
		if (presentationList != undefined && presentationList.length > 0) {
			presentationList = presentationList.filter((x: any) => {
				if (
					this.selectedMonth.id ==
						Number(moment(String(x.StartTime)).format("MM")) &&
					this.selectedMonth.Year ==
						Number(moment(String(x.StartTime)).format("YYYY"))
				)
					return x;
			});

			this.TotalRecord += presentationList.length;
			this.localRecord != presentationList.length;
			presentationList.forEach((element: any) => {
				var pres = new Presentation(element);
				element.StartTimeString = pres.StartTimeString;
				element.PrimaryFullName = pres.PresentedTo?.fullname;
				element.StepCompleted = pres.CurrentStepCompleted;
				if (element.StepCompleted == 5)
					element.PresentationStatus = element.WhatHappened;
				else element.PresentationStatus = "INCOMPLETE";
				element.Unsynchronized = true;

				if (pres.PlanOptions != undefined && pres.PlanOptions != "") {
					var planOpt = JSON.parse(pres.PlanOptions);
					if (planOpt != undefined && planOpt.length > 0) {
						element.PlanOptions = [];
						element.PlanOptions.push({});
						element.TotalALP = planOpt.find(
							(x: any) => x.isSelected == true
						)?.TotalALP;
						element.TotalAHP = planOpt.find(
							(x: any) => x.isSelected == true
						)?.TotalAHP;
					} else {
						element.PlanOptions = [];
						element.PlanOptions.push({});
						element.TotalALP = 0;
					}
				}
			});
			this.$data.Presentations.push(...presentationList);
			this.$data.Presentations.push(...presentations);
		} else {
			this.$data.Presentations.push(...presentations);
		}
	}

	SyncPresentations() {
		PresentationSync.getInstance().SyncPresentation();
	}

	get ProfilePicture() {
		var dataURL;
		if (this.$store.state.ProfileImage == true)
			dataURL = this.$store.state.ProfileImageString;
		if (dataURL != null && dataURL != undefined && dataURL != "")
			this.imgSource = dataURL;
		else if (this.$route.path != "/home" && this.$route.path != "/training") {
			this.imgSource = placeholderuser;
		} else {
			this.imgSource = placeholderuser;
		}
		return this.imgSource;
	}

	$refs!: {
		fullData: any;
		html2Pdf: any;
		pieCarousal: any;
	};
}
