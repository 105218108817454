import { EntityHelper } from '@/helpers/EntityHelper';
import { GetParticularLanguageString } from '@/plugins/i18n';
import { Model } from '@vuex-orm/core';
import FamilyCareSurveyChildren from './FamilyCareSurveyChildren';
import FamilyCareSurveyProperty from './FamilyCareSurveyProperty';
import FamilyCareSurveyInsurance from './FamilyCareSurveyInsurance';
import FamilyCareSurveyRelativeInfo from './FamilyCareSurveyRelativeInfo';

export default class FamilyCareSurvey extends Model {
	static entity = 'FamilyCareSurvey';
	static primaryKey = 'Id';

	static fields() {
		return {
			Id: this.uid(() => EntityHelper.uuid()),
			HouseHoldType: this.string(null).nullable(),
			LifeChangingEvent: this.attr([]).nullable(),
			PrimaryFirstName: this.string(''),
			PrimaryLastName: this.string(''),
			PrimaryDateofBirth: this.string(null).nullable(),
			PrimaryAgePerDOB: this.number(null).nullable(),
			PrimaryOccupation: this.string(''),
			PrimaryGender: this.string(''),
			PrimaryEmployment: this.string(null).nullable(),
			PrimaryAnnualIncome: this.string(null).nullable(),
			PrimaryInsuranceThroughWork: this.string(""),
			PrimaryInsuranceOutsideWork: this.string(""),
			SpouseFirstName: this.string(''),
			SpouseLastName: this.string(''),
			SpouseDateofBirth: this.string(null).nullable(),
			SpouseAgePerDOB: this.number(null).nullable(),
			SpouseOccupation: this.string(''),
			SpouseGender : this.string(''),
			SpouseEmployment: this.string(null).nullable(),
			SpouseAnnualIncome: this.string(null).nullable(),
			SpouseInsuranceThroughWork: this.string(""),
			SpouseInsuranceOutsideWork: this.string(""),
			CheckingAccountChecked: this.boolean(false),
			CheckingAccountCount: this.number(null).nullable(),
			SavingAccountChecked: this.boolean(false),
			SavingAccountCount: this.number(null).nullable(),
			InvestmentAccountChecked: this.boolean(false),
			InvestmentAccountCount: this.number(null).nullable(),
			NoBankAccountChecked: this.boolean(false),
			DependentChildrenString: this.string(""),
			AdultChildrenString: this.string(""),
			FamilyCareSurveyPropertyString: this.string(""),
			IsFormSubmited: this.boolean(null).nullable(),
			IsVeteranSurveyFirstTimeOpen: this.boolean(true).nullable(),
			FamilyCarePropertySum: this.string("").nullable(),
			FamilyCarePropertyOwn: this.boolean(null).nullable(),
			FamilyCarePropertyRent: this.boolean(null).nullable(),
			FamilyCarePropertyMortgage: this.string(null).nullable(),
			FamilyCarePropertyRate: this.string(null).nullable(),
			FamilyCarePropertyYrsRemaining: this.string(null).nullable(),
			SumPrimaryInsuranceThroughWorkWholeLife: this.string(null).nullable(),
			SumPrimaryInsuranceThroughWorkTermLife: this.string(null).nullable(),
			SumPrimaryInsuranceThroughWorkADB: this.string(null).nullable(),
			SumPrimaryInsuranceOutsideWorkWholeLife: this.string(null).nullable(),
			SumPrimaryInsuranceOutsideWorkTermLife: this.string(null).nullable(),
			SumPrimaryInsuranceOutsideWorkADB: this.string(null).nullable(),
			SumSpouseInsuranceThroughWorkWholeLife: this.string(null).nullable(),
			SumSpouseInsuranceThroughWorkTermLife: this.string(null).nullable(),
			SumSpouseInsuranceThroughWorkADB: this.string(null).nullable(),
			SumSpouseInsuranceOutsideWorkWholeLife: this.string(null).nullable(),
			SumSpouseInsuranceOutsideWorkTermLife: this.string(null).nullable(),
			SumSpouseInsuranceOutsideWorkADB: this.string(null).nullable(),
			Notes: this.string(null).nullable(),
			BranchOfService: this.string(null).nullable(),
			CurrentVeteranStatus: this.string(null).nullable(),
			VSOMembership: this.string(null).nullable(),
			CurrentVSOMember: this.attr([]).nullable(),
			HonorableService: this.boolean(null).nullable(),
			ServiceInWar: this.boolean(null).nullable(),
			YesContactMe: this.boolean(null).nullable(),
			WarService: this.attr([]).nullable(),
			RankRateAtDischarge: this.string(null).nullable(),
			VALifeCoverage: this.string(null).nullable(),
			CampaignMedalsReceived: this.attr([]).nullable(),
			PrimaryFuneralType: this.string(null).nullable(),
			SpouseFuneralType: this.string(null).nullable(),
			PrimaryCemeteryType: this.string(null).nullable(),
			SpouseCemeteryType: this.string(null).nullable(),
			IsVeteran: this.boolean(false).nullable(),
			VideoClicked: this.boolean(false).nullable(),
			HasCheckingAccount: this.boolean(false).nullable(),
			HasSavingAccount: this.boolean(false).nullable(),
			IsPrimaryCivilian: this.boolean(null).nullable(),
			IsPrimaryVeteran: this.boolean(null).nullable(),
			IsPrimaryRelativeOfVeteran: this.boolean(null).nullable(),
			IsSpouseVeteran: this.boolean(false).nullable(),
			SpouseBranchOfService: this.string(null).nullable(),
			SpouseCurrentVeteranStatus: this.string(null).nullable(),
			SpouseCampaignMedalsReceived: this.attr([]).nullable(),
			SpouseWarService: this.attr([]).nullable(),
			SpouseRankRateAtDischarge:this.string(null).nullable(),
			SpouseVALifeCoverage: this.string(null).nullable(),
			isPlanCalculationNeeded:this.boolean(false),
			FamilyCareVeteranRelativeInfoString: this.string('').nullable(),
			FamilyCareSurveyChildren_Id: this.attr(null),
			FamilyCareSurveyChildren: this.hasMany(FamilyCareSurveyChildren, 'FamilyCareSurveyChildren_Id'),
			FamilyCareSurveyProperty_Id: this.attr(null),
			FamilyCareSurveyProperty: this.hasMany(FamilyCareSurveyProperty, 'FamilyCareSurveyChildren_Id'),
			FamilyCareSurveyInsurance_Id: this.attr(null),
			FamilyCareSurveyInsurance: this.hasMany(FamilyCareSurveyInsurance,'FamilyCareSurveyInsurance_Id'),
			FamilyCareSurveyRelativeInfo_Id: this.attr(null),
			FamilyCareSurveyRelativeInfo: this.hasOne(FamilyCareSurveyRelativeInfo,'FamilyCareSurveyRelativeInfo_Id')
		};
	}
	public Id!: string
	public HouseHoldType!: string
	public LifeChangingEvent!: any
	public PrimaryFirstName!: string
	public PrimaryLastName!: string
	public PrimaryDateofBirth!: string
	public PrimaryAgePerDOB!: number
	public PrimaryOccupation!: string
	public PrimaryGender!: string
    public PrimaryEmployment!: string
    public PrimaryAnnualIncome!: string
    public PrimaryInsuranceThroughWork!: string
    public PrimaryInsuranceOutsideWork!: string
    public SpouseFirstName!: string
    public SpouseLastName!: string
    public SpouseDateofBirth!: string
	public SpouseAgePerDOB!: number
    public SpouseOccupation!: string
    public SpouseGender!: string
    public SpouseEmployment!: string
    public SpouseAnnualIncome!: string
    public SpouseInsuranceThroughWork!: string
    public SpouseInsuranceOutsideWork!: string
    public CheckingAccountChecked!: boolean
    public CheckingAccountCount!: number
    public SavingAccountChecked!: boolean
    public SavingAccountCount!: number
    public InvestmentAccountChecked!: boolean
    public InvestmentAccountCount!: number
    public NoBankAccountChecked!: boolean
	public DependentChildrenString!: string
	public AdultChildrenString!: string
	public FamilyCareSurveyPropertyString!: string
	public IsFormSubmited: boolean = false
	public IsVeteranSurveyFirstTimeOpen!: boolean
	public FamilyCarePropertySum!: string
	public FamilyCarePropertyOwn: boolean = false;
	public FamilyCarePropertyRent: boolean = false;
	public FamilyCarePropertyMortgage!: string
	public FamilyCarePropertyRate!: string
	public FamilyCarePropertyYrsRemaining!: string
	public SumPrimaryInsuranceThroughWorkWholeLife!: string
	public SumPrimaryInsuranceThroughWorkTermLife!: string
	public SumPrimaryInsuranceThroughWorkADB!: string
	public SumPrimaryInsuranceOutsideWorkWholeLife!: string
	public SumPrimaryInsuranceOutsideWorkTermLife!: string
	public SumPrimaryInsuranceOutsideWorkADB!: string		
	public SumSpouseInsuranceThroughWorkWholeLife!: string
	public SumSpouseInsuranceThroughWorkTermLife!: string
	public SumSpouseInsuranceThroughWorkADB!: string
	public SumSpouseInsuranceOutsideWorkWholeLife!: string
	public SumSpouseInsuranceOutsideWorkTermLife!: string	
	public SumSpouseInsuranceOutsideWorkADB!: string
	public FamilyCareSurveyChildren_Id!: string
	public Notes!: string
	public BranchOfService!: string	
	public CurrentVeteranStatus!: string
	public VSOMembership!: string 		
	public CurrentVSOMember!: any 	
	public HonorableService!: boolean	
	public ServiceInWar!: boolean 		
	public YesContactMe!: boolean 		
	public WarService!: any 			
	public RankRateAtDischarge!: string 
	public VALifeCoverage!: string
	public CampaignMedalsReceived!: any
	public PrimaryFuneralType!: string 	
	public SpouseFuneralType!: string 	
	public PrimaryCemeteryType!: string 
	public SpouseCemeteryType!: string 
	public IsVeteran: boolean = false
	public VideoClicked: boolean = false
	public HasCheckingAccount!: boolean
	public HasSavingAccount!: boolean
	public IsPrimaryCivilian!: boolean
	public IsPrimaryRelativeOfVeteran!: boolean
	public IsSpouseVeteran!: any 
	public SpouseBranchOfService!: string
	public SpouseCurrentVeteranStatus!: string
	public SpouseCampaignMedalsReceived!: any 
	public SpouseWarService!: any
	public SpouseRankRateAtDischarge!: string
	public SpouseVALifeCoverage!: string
	public FamilyCareVeteranRelativeInfoString!: string
	public isPlanCalculationNeeded!:boolean
	public FamilyCareSurveyChildren !: FamilyCareSurveyChildren
	public FamilyCareSurveyProperty_Id!: string
	public FamilyCareSurveyProperty !: FamilyCareSurveyProperty
	public FamilyCareSurveyInsurance_Id!: string
	public FamilyCareSurveyInsurance!: FamilyCareSurveyInsurance
	public FamilyCareSurveyRelativeInfo_Id!: string
	public FamilyCareSurveyRelativeInfo!: FamilyCareSurveyRelativeInfo

	get GetPrimaryNameHolder() {
        if (this.PrimaryFirstName != null && this.PrimaryFirstName != "") {
            return this.PrimaryFirstName;
        }
        else if (this.PrimaryLastName != null && this.PrimaryLastName != "") {
            return this.PrimaryLastName;
        }
        else {
            return GetParticularLanguageString("NeedAnalysis.Primary");
        }
    }

	get GetSpouseNameHolder() {
        if (this.SpouseFirstName != null && this.SpouseFirstName != "") {
            return this.SpouseFirstName;
        }
        else if (this.SpouseLastName != null && this.SpouseLastName != "") {
            return this.SpouseLastName;
        }
        else {
            return GetParticularLanguageString("NeedAnalysis.Spouse");
        }
    }

	get PrimaryDateOfBirthTemp() {
        return this.PrimaryDateofBirth;
    }

    set PrimaryDateOfBirthTemp(value: string) {
        this.PrimaryDateofBirth = value;
        this.PrimaryAgePerDOB = EntityHelper.calculate_age(this.PrimaryDateofBirth);
    }

	get SpouseDateOfBirthTemp() {
        return this.SpouseDateofBirth;
    }

    set SpouseDateOfBirthTemp(value: string) {
        this.SpouseDateofBirth = value;
        this.SpouseAgePerDOB = EntityHelper.calculate_age(this.SpouseDateofBirth);
    }
}
