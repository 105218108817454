export enum PrefillDocumentType {
	VeteransBurialandWillKit = 1,
	FamilyInfoGuide,
	FinancialInfoGuide,
	FamilyInfoGuideandWillKit,
	LastWillAndTestament,
	ThreeImportantFacts,
	FamilyInfoGuideVeteran,
	FamilyInfoGuideMcgruff,
	FamilyInfoGuideUnion,
	FamilyInfoGuideAssociation,
	WeNeedYourHelpMcgruff,
	SmbBenefitsAndBurialGuide,
	ThreeImportantFactsSmb,
	NoVeteranLeftBehindProgram,
	GiftCertificate
}
