import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import homeicon from '@/components/icons/home-icon.vue';
import baricon from '@/components/icons/bar-icon.vue';
import threebaricon from '@/components/icons/three-bar-icon.vue';
import bookmedicalicon from '@/components/icons/book-medical-icon.vue';
import calculatoricon from '@/components/icons/calculator-icon.vue';
import checkdoubleicon from '@/components/icons/check-double-icon.vue';
import dotcircleicon from '@/components/icons/dot-circle-icon.vue';
import fileicon from '@/components/icons/file-icon.vue';
import fileplusicon from '@/components/icons/file-plus-icon.vue';
import filesearchicon from '@/components/icons/file-search-icon.vue';
import foldericon from '@/components/icons/folder-icon.vue';
import windowicon from '@/components/icons/window-icon.vue';
import logouticon from '@/components/icons/logout-icon.vue';
import presentationicon from '@/components/icons/presentation-icon.vue';
import handshakeicon from '@/components/icons/handshake-icon.vue';
import hourglassicon from '@/components/icons/hour-glass-icon.vue';
import deleteicon from '@/components/icons/delete-icon.vue';
import downloadicon from '@/components/icons/download-icon.vue';
import downloadicon2 from '@/components/icons/download-icon2.vue';
import editicon from '@/components/icons/edit-icon.vue';
import eappicon from '@/components/icons/eapp-icon.vue';
import bookmarkicon from '@/components/icons/bookmark-icon.vue';
import rightarrowicon from '@/components/icons/right-arrow-icon.vue';
import cross from '@/components/icons/cross.vue';
import plus from '@/components/icons/plus.vue';
import pluscircle from '@/components/icons/plus-cricle.vue';
import benefitsummary from '@/components/icons/benefit-summary-icon.vue';
import medicalquestion from '@/components/icons/medical-question-icon.vue';
import planethomeicon from '@/components/icons/Dashboard/planet-home-icon.vue';
import profileicon from '@/components/icons/Dashboard/profile-icon.vue';
import dashboardicon from '@/components/icons/Dashboard/dashboard-icon.vue';
import gameplansicon from '@/components/icons/Dashboard/gameplan-icon.vue';
import preshistoryicon from '@/components/icons/Dashboard/pres-history-icon.vue';
import trainingicon from '@/components/icons/Dashboard/training-icon.vue';
import launchappicon from '@/components/icons/Dashboard/luanchapp-icon.vue';
import managementicon from '@/components/icons/Dashboard/management-icon.vue';
import eappiconmenu from '@/components/icons/Dashboard/EappIcon.vue';
import addbookicon from '@/components/icons/addbook-icon.vue';
import clockicon from '@/components/icons/clock-icon.vue';
import searchusericon from '@/components/icons/searchuser-icon.vue';
import spiralicon from '@/components/icons/spiral-icon.vue';
import usericon from '@/components/icons/user-icon.vue';
import usericon2 from '@/components/icons/user-icon2.vue';
import usericon3 from '@/components/icons/user-icon3.vue';
import calendericon from '@/components/icons/calender-icon.vue';
import starfilledicon from '@/components/icons/star-filled.vue';
import staroutlinedicon from '@/components/icons/star-outlined.vue';
import saveicon from '@/components/icons/save-icon.vue';
import reloadicon from '@/components/icons/reload-icon.vue';
import cameraicon from '@/components/icons/camera-icon.vue';
import firstpageicon from '@/components/icons/first-page-icon.vue';
import lastpageicon from '@/components/icons/last-page-icon.vue';
import prevpageicon from '@/components/icons/prev-page-icon.vue';
import nextpageicon from '@/components/icons/next-page-icon.vue';
import zoominpageicon from '@/components/icons/zoomin-page-icon.vue';
import zoomoutpageicon from '@/components/icons/zoomout-page-icon.vue';
import AOicon from '@/components/icons/AO-icon.vue';
import checkboxchecked from '@/components/icons/checkbox-checked.vue';
import checkboxunchecked from '@/components/icons/checkbox-unchecked.vue';
import nocostna from '@/components/icons/nocost-na.vue';
import tvicon from '@/components/icons/tv-icon.vue';
import infoicon from '@/components/icons/info-icon.vue';
import infoiconoutlined from '@/components/icons/info-icon-outlined.vue';
import pollicon from '@/components/icons/poll-icon.vue';
import playicon from '@/components/icons/play-icon.vue';
import playicon1 from '@/components/icons/play-icon1.vue';
import listarrowicon from '@/components/icons/listarrow-icon.vue';
import searchicon from '@/components/icons/search-icon.vue';
import docreadicon from '@/components/icons/docread-icon.vue';
import docprocessicon from '@/components/icons/docprocess-icon.vue';
import docarrowicon from '@/components/icons/docarrow-icon.vue';
import achivementicon from '@/components/icons/achivement-icon.vue';
import codeicon from '@/components/icons/code-icon.vue';
import spprogram from '@/components/icons/sp-program.vue';
import deviceconnected from '@/components/icons/device-connected.vue';
import deviceconnectedchecked from '@/components/icons/device-connected-checked.vue';
import reciept from '@/components/icons/reciept.vue';
import accordion from '@/components/icons/accordion.vue';
import recieptChecked from '@/components/icons/recieptChecked.vue';
import favoriteheartoutline from '@/components/icons/favorite-heart-outline.vue';
import favoriteheartfilled from '@/components/icons/favorite-heart-filled.vue';
import roundedadd from '@/components/icons/rounded-add.vue';
import roundinfo from '@/components/icons/round-info.vue';
import rubbishbindelete from '@/components/icons/rubbish-bin-delete.vue';
import savebutton from '@/components/icons/save-button.vue';
import writeemailenvelope from '@/components/icons/write-email-envelope.vue';
import presentplan from '@/components/icons/present-plan.vue';
import reportform from '@/components/icons/report-form.vue';
import staryellow from '@/components/icons/star-yellow.vue';
import angleleft from '@/components/icons/angle-left.vue';
import angleright from '@/components/icons/angle-right.vue';
import angleleft1 from '@/components/icons/angle-left1.vue';
import angleright1 from '@/components/icons/angle-right1.vue';
import dropdown from '@/components/icons/dropdown.vue';
import trash from '@/components/icons/trash.vue';
import staroutlinedicon2 from '@/components/icons/star-outlined2.vue';
import starfilledicon2 from '@/components/icons/star-filled2.vue';
import eappsummary from '@/components/icons/EappSummary.vue';
import beneficiary from '@/components/icons/beneficiary.vue';
import showpresicon from '@/components/icons/show-pres-icon.vue';
import comingsoon from '@/components/icons/coming-soon-icon.vue';
import cementingvideo from '@/components/icons/cementing-video-icon.vue';
import protectionicon from '@/components/icons/protection-icon.vue';
import email from '@/components/icons/email.vue';
import facebook from '@/components/icons/facebook.vue';
import foccertificate from '@/components/icons/foccertificate.vue';
import instagram from '@/components/icons/instagram.vue';
import linkedin from '@/components/icons/linkedin.vue';
import notes from '@/components/icons/notes.vue';
import pencil from '@/components/icons/pencil.vue';
import pencil1 from '@/components/icons/pencil1.vue';
import pencil2 from '@/components/icons/pencil2.vue';
import print from '@/components/icons/print.vue';
import rewardbedge from '@/components/icons/rewardbedge.vue';
import twitterbird from '@/components/icons/twitter-bird.vue';
import usergroup from '@/components/icons/usergroup.vue';
import usersocialaccount from '@/components/icons/usersocialaccount.vue';
import diaryicon from '@/components/icons/diaryicon.vue';
import contacticon from '@/components/icons/contact-info.vue';
import employeeicon from '@/components/icons/employee-info.vue';
import roundinfoicon from '@/components/icons/round-icon-outlined.vue';
import headerreload from '@/components/icons/header-reload.vue';
import greentick from '@/components/icons/greentick.vue';
import tick from '@/components/icons/tick.vue';
import LeftMenuClosed from '@/components/icons/LeftMenuClosed.vue';
import LeftMenuOpened from '@/components/icons/LeftMenuOpened.vue';
import AOWhite from '@/components/icons/ao-white.vue';
import PlanGenAdd from '@/components/icons/PlanGen-Add.vue';
import PlanGenList from '@/components/icons/PlanGen-List.vue';
import PlanGenCheck from '@/components/icons/PlanGen-Check.vue';
import Expense from '@/components/icons/expense.vue';
import sendicon from '@/components/icons/sendicon.vue';
import arrowrotateclock from '@/components/icons/arrow-rotate-clock.vue';
import resenticon from '@/components/icons/resent-icon.vue';

// import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				base: '#f2f5f7',
				primary: '#30363c',
				secondary: '#838c96',
				secondaryAlt: '#707070',
				secondaryAlt1: '#616161',
				secondaryLight: '#bac0c6',
				secondaryDark: '#bfbfbf',
				secondaryxDark: '#c4c4c4',
				secondaryxxDark: '#DCE0E4',
				secondaryxLight: '#d9e4ec',
				secondaryxxLight: '#E6EAED',
				secondaryxxxLight: '#e1e1e1',
				secondaryxxxxLight: '#F5F5F5',
				successLight: '#8ea392',
				successxLight: '#b8d0c0',
				success: '#2D9547',
				successDark: '#138138',
				successxDark: '#319b42',
				disabled: '#e7e8e8',
				accentxxxDark: '#1A4681',
				accentxxDark: '#002850',
				accentxDark: '#2D749F',
				accentDark: '#5a92b3',
				accentMedium: '#063A7C',
				accent: '#00558c',
				accentLight: '#B3CDDC',
				accentxLight: '#c9dbe6',
				accentxxLight: '#1A6698',
				accentxxxLight: '#5e94b6',
				error: '#FF2C2C',
				errorLight: '#fd9292',
				errorxLight: '#FFDDDD',
				errorDark: '#8F2327',
				black: '#333333',
				blackdark: '#000000',
				white: '#ffffff',
				yellow: '#FFD438',
				red: '#FF0000',
				planopt1: '#e0f0e2',
				planopt2: '#ccdde8',
				planopt3: '#ededed',
				planopt4: '#f9e1e1',
				docbg: '#dce3ec',
				creditunion: '#a0c5d9',
				familycare: '#28364F',
				familycarebg: '#00708C',
				familycare1: '#E5E5E5',
				familycare2: '#f9f9f9',
				familycareveteran: '#841518',
				familycareveteran1: '#144B6D',
				footerbg: '#031525',
				expensemodal: '#5E5E5E',
				groupemail: '#d9d9d9'
			}
		}
	},
	icons: {
		values: {
			PlanGenList: {
				component: PlanGenList
			},
			resenticon: {
				component: resenticon
			},
			arrowrotateclock: {
				component: arrowrotateclock
			},
			Expense: {
				component: Expense
			},
			sendicon: {
				component: sendicon
			},
			PlanGenAdd: {
				component: PlanGenAdd
			},
			PlanGenCheck: {
				component: PlanGenCheck
			},
			roundinfoicon: {
				component: roundinfoicon
			},
			accordion: {
				component: accordion
			},
			employeeicon: {
				component: employeeicon
			},
			contacticon: {
				component: contacticon
			},
			usergroup: {
				component: usergroup
			},
			headerreload: {
				component: headerreload
			},
			usersocialaccount: {
				component: usersocialaccount
			},
			email: {
				component: email
			},
			facebook: {
				component: facebook
			},
			protectionicon: {
				component: protectionicon
			},
			foccertificate: {
				component: foccertificate
			},
			instagram: {
				component: instagram
			},
			linkedin: {
				component: linkedin
			},
			notes: {
				component: notes
			},
			pencil: {
				component: pencil
			},
			pencil1: {
				component: pencil1
			},
			pencil2: {
				component: pencil2
			},
			print: {
				component: print
			},
			rewardbedge: {
				component: rewardbedge
			},
			twitterbird: {
				component: twitterbird
			},
			presentplan: {
				component: presentplan
			},
			reportform: {
				component: reportform
			},
			favoriteheartoutline: {
				component: favoriteheartoutline
			},
			favoriteheartfilled: {
				component: favoriteheartfilled
			},
			roundedadd: {
				component: roundedadd
			},
			deviceconnected: {
				component: deviceconnected
			},
			deviceconnectedchecked: {
				component: deviceconnectedchecked
			},
			reciept: {
				component: reciept
			},
			recieptChecked: {
				component: recieptChecked
			},
			roundinfo: {
				component: roundinfo
			},
			rubbishbindelete: {
				component: rubbishbindelete
			},
			writeemailenvelope: {
				component: writeemailenvelope
			},
			savebutton: {
				component: savebutton
			},
			spprogram: {
				component: spprogram
			},
			codeicon: {
				component: codeicon
			},
			playicon: {
				component: playicon
			},
			playicon1: {
				component: playicon1
			},
			infoicon: {
				component: infoicon
			},
			checkboxOn: {
				component: checkboxchecked
			},
			checkboxOff: {
				component: checkboxunchecked
			},
			radioOn: {
				component: checkboxchecked
			},
			radioOff: {
				component: checkboxunchecked
			},
			AOicon: {
				component: AOicon
			},
			firstpageicon: {
				component: firstpageicon
			},
			lastpageicon: {
				component: lastpageicon
			},
			prevpageicon: {
				component: prevpageicon
			},
			nextpageicon: {
				component: nextpageicon
			},
			zoominpageicon: {
				component: zoominpageicon
			},
			zoomoutpageicon: {
				component: zoomoutpageicon
			},

			cameraicon: {
				component: cameraicon
			},
			reloadicon: {
				component: reloadicon
			},
			saveicon: {
				component: saveicon
			},
			starfilledicon: {
				component: starfilledicon
			},
			staroutlinedicon: {
				component: staroutlinedicon
			},
			calendericon: {
				component: calendericon
			},
			addbookicon: {
				component: addbookicon
			},
			clockicon: {
				component: clockicon
			},
			searchusericon: {
				component: searchusericon
			},
			spiralicon: {
				component: spiralicon
			},
			usericon: {
				component: usericon
			},
			usericon2: {
				component: usericon2
			},
			usericon3: {
				component: usericon3
			},
			planethomeicon: {
				component: planethomeicon
			},
			profileicon: {
				component: profileicon
			},
			dashboardicon: {
				component: dashboardicon
			},
			gameplansicon: {
				component: gameplansicon
			},
			preshistoryicon: {
				component: preshistoryicon
			},
			trainingicon: {
				component: trainingicon
			},
			launchappicon: {
				component: launchappicon
			},
			managementicon: {
				component: managementicon
			},
			eappiconmenu: {
				component: eappiconmenu
			},
			plus: {
				component: plus
			},
			pluscircle: {
				component: pluscircle
			},
			cross: {
				component: cross
			},
			homeicon: {
				component: homeicon
			},
			baricon: {
				component: baricon
			},
			threebaricon: {
				component: threebaricon
			},
			bookmedicalicon: {
				component: bookmedicalicon
			},
			calculatoricon: {
				component: calculatoricon
			},
			checkdoubleicon: {
				component: checkdoubleicon
			},
			dotcircleicon: {
				component: dotcircleicon
			},
			fileicon: {
				component: fileicon
			},
			fileplusicon: {
				component: fileplusicon
			},
			filesearchicon: {
				component: filesearchicon
			},
			foldericon: {
				component: foldericon
			},
			windowicon: {
				component: windowicon
			},
			logouticon: {
				component: logouticon
			},
			presentationicon: {
				component: presentationicon
			},
			handshakeicon: {
				component: handshakeicon
			},
			hourglassicon: {
				component: hourglassicon
			},
			deleteicon: {
				component: deleteicon
			},
			downloadicon: {
				component: downloadicon
			},
			downloadicon2: {
				component: downloadicon2
			},
			editicon: {
				component: editicon
			},
			eappicon: {
				component: eappicon
			},
			bookmarkicon: {
				component: bookmarkicon
			},
			rightarrowicon: {
				component: rightarrowicon
			},
			medicalquestion: {
				component: medicalquestion
			},
			benefitsummary: {
				component: benefitsummary
			},
			nocostna: {
				component: nocostna
			},
			tvicon: {
				component: tvicon
			},
			achivementicon: {
				component: achivementicon
			},
			docarrowicon: {
				component: docarrowicon
			},
			docprocessicon: {
				component: docprocessicon
			},
			docreadicon: {
				component: docreadicon
			},
			searchicon: {
				component: searchicon
			},
			listarrowicon: {
				component: listarrowicon
			},
			infoiconoutlined: {
				component: infoiconoutlined
			},
			pollicon: {
				component: pollicon
			},
			staryellow: {
				component: staryellow
			},
			prev: {
				component: angleleft
			},
			next: {
				component: angleright
			},
			prev1: {
				component: angleleft1
			},
			next1: {
				component: angleright1
			},
			ratingEmpty: {
				component: staroutlinedicon2
			},
			ratingFull: {
				component: starfilledicon2
			},
			dropdown: {
				component: dropdown
			},
			trash: {
				component: trash
			},
			eappsummary: {
				component: eappsummary
			},
			beneficiary: {
				component: beneficiary
			},
			showpresicon: {
				component: showpresicon
			},
			comingsoon: {
				component: comingsoon
			},
			cementingvideo: {
				component: cementingvideo
			},
			diaryicon: {
				component: diaryicon
			},
			greentick: {
				component: greentick
			},
			tick: {
				component: tick
			},
			LeftMenuOpened: {
				component: LeftMenuOpened
			},
			LeftMenuClosed: {
				component: LeftMenuClosed
			},
			AOWhite: {
				component: AOWhite
			}
		}
	}
});
