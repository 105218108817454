import { EntityHelper } from '@/helpers/EntityHelper';
import { Model } from '@vuex-orm/core';
import FamilyCareSurvey from './FamilyCareSurvey';

export default class FamilyCareSurveyProperty extends Model {
	static entity = 'FamilyCareSurveyProperty';
	static primaryKey = 'Id';
    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            OwnOrRent: this.string(null).nullable(),
            MonthlyRent: this.string(null).nullable(),
            MortgageBalance: this.string(null).nullable(),
            MortgageInterestRate: this.string(null).nullable(),
            MortgageYearsRemaining: this.string(null).nullable(),
            OwnHome: this.boolean(false).nullable(),
            RentHome: this.boolean(false).nullable(),
            FamilyCareSurvey_Id: this.attr(null),
            Parent: this.belongsTo(FamilyCareSurvey, "FamilyCareSurvey_Id")
        }
    }
    public Id !: string
    public OwnOrRent!: string
    public MonthlyRent !: string
    public MortgageBalance !: string
    public MortgageInterestRate!: string
    public MortgageYearsRemaining!: string
    public OwnHome: boolean = false
    public RentHome: boolean = false
    public FamilyCareSurvey_Id!: string
}