export enum PresentationType {
	Referral = 1,
	McGruffSafeKids = 2,
	ChildSafe = 3,
	POS = 4,
	Union = 5,
	Association = 6,
	CreditUnion = 7,
	Other = 8,
	Veteran = 9,
	WillKitNoCostLaw = 10,
	FinalExpense = 11,
	Demo = 12,
	ProLead = 13,
	GlobeLife = 14,
	ServiceMemberBenefits = 15
}
