








































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import moment from "moment";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { EntityHelper } from "@/helpers/EntityHelper";
import { ConvertIntoAmountFormatWithDecimal } from "@/helpers/CalculationHelper";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class ProductSummary extends Vue {
	private isDialogOpen: boolean = false;
	private ProductsList: any = [];

	@Prop({ default: undefined })
	private PresentationData: any;

	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelproductsummary");
		}, 500);
	}

	cancel() {
		this.$emit("cancelproductsummary");
	}

	data() {
		return {};
	}

	async created() {
		this.isDialogOpen = true;
		this.ProductsList = await getPersistedState("ProductsList");
	}

	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined) return 0;
			else if (typeof amt == "string")
				return ConvertIntoAmountFormatWithDecimal(amt);
			else return ConvertIntoAmountFormatWithDecimal(amt.toFixed(2));
		} catch (ex: any) {
			return 0;
		}
	}

	GetCamelizePlanOption(Option: string) {
		return EntityHelper.capitalizeTheFirstLetterOfEachWord(Option) || Option;
	}

	GetItemCoverage(item: any) {
		if (
			item.Plan.CompleteProduct != undefined &&
			(item.Plan.CompleteProduct.Id == 15 ||
				item.Plan.CompleteProduct.Id == 18 ||
				item.Plan.CompleteProduct.Id == 19)
		) {
			var coverage = item.Plan.CompleteProduct.Id == 19 ? "" : 0;
			try {
				var coverageList = this.ProductsList.find(
					(x: any) => x.Id == item.Plan.CompleteProduct.Id
				).CoverageDropdownOptions.map((x: any) => {
					return x.Value;
				});

				if (
					coverageList != undefined &&
					coverageList.length > 0 &&
					item.Plan.CompleteCoverageOption.Name != undefined &&
					item.Plan.CompleteCoverageOption.Name != ""
				) {
					var selectedCoverage =
						coverageList[Number(item.Plan.CompleteCoverageOption.Name) - 1];

					if (selectedCoverage != undefined && selectedCoverage != null) {
						if (
							item.Plan.CompleteProduct.Id == 15 ||
							item.Plan.CompleteProduct.Id == 18
						) {
							coverage = `$${this.ConvertintoAmountFormatUI(selectedCoverage)}`;
						} else if (item.Plan.CompleteProduct.Id == 19) {
							coverage = `${selectedCoverage} Unit`;
						}
					}
				}
				return coverage;
			} catch (ex: any) {
				return coverage;
			}
		}
		return `$${this.ConvertintoAmountFormatUI(item.Coverage)}`;
	}
}
