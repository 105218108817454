







































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import MemberQuestionnaire from "@/entities/MemberQuestionnaire";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";
import moment from "moment";
import { getPersistedState } from "@/helpers/StateMapper";
import { EventBusLayout } from "@/bus/EventBusLayout";
let RCMemberQuestionnaire = require("@/assets/pre-fill-data/Questionnaire.json");

@Component
export default class Questionnaire extends Vue {
	$refs!: {
		ReferralForm: any;
	};
	private UserNameForFredomofChoise = GetAscFullNameWithCamelCase();

	// private IsMemberofUnionOrVeteran: boolean = false;
	// private MEMBERBENEFITS: any = null;
	// private MEMBERBENEFITSMoreInfo: boolean = false;
	private TodayDate: string = moment(new Date()).format("MM / DD / YYYY");
	private AttendMeeting: any = RCMemberQuestionnaire.AttendMeeting;
	private ReadNewsLetters: any = RCMemberQuestionnaire.ReadNewsLetters;
	private AILMeeting: any = RCMemberQuestionnaire.AILMeeting;

	private isRefFormValid: boolean = false;
	private isFormValidated: boolean = true;

	private ShowVideo: boolean = false;

	data() {
		return {
			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => (!!v && !!v.trim()) || "Field is required",
				(v: any) =>
					(!!v && v.length <= 50) || "Field must be less than 50 characters",
			],
		};
	}
	@Prop({ default: new MemberQuestionnaire() })
	private QuestionnaireObj: any;

	@Prop()
	private SelectedGroupCode: any;
	@Prop()
	private SelectedGroupName: any;
	@Prop()
	private SelectedGroupVideo: any;
	@Prop()
	private PresentationTypeId: any;

	created() {
		EventBusLayout.$on("CloseForm", this.CloseForm);
		this.AttendMeeting = RCMemberQuestionnaire.AttendMeeting;
		this.ReadNewsLetters = RCMemberQuestionnaire.ReadNewsLetters;

		// var _keydownCallback = (event: any) => {
		// 	if (event.code == "Escape") {

		// 	}
		// };
	}

	beforeDestroy() {
		EventBusLayout.$off("CloseForm", this.CloseForm);
	}

	get MemberDuration() {
		if (this.PresentationTypeId == 9) {
			return RCMemberQuestionnaire.VeteranMemberDuration;
		} else {
			return RCMemberQuestionnaire.MemberDuration;
		}
	}
	get InvolvedFrom() {
		if (this.PresentationTypeId == 9) {
			return RCMemberQuestionnaire.VeteranInvolvedFrom;
		} else {
			return RCMemberQuestionnaire.InvolvedFrom;
		}
	}
	get GroupPlaceholder() {
		if (
			this.SelectedGroupName != undefined &&
			this.SelectedGroupName != "" &&
			this.SelectedGroupName.trim() != ""
		)
			return this.SelectedGroupName;
		else return this.SelectedGroupCode;
	}

	get IsGroupNameExist() {
		if (
			this.SelectedGroupName != undefined &&
			this.SelectedGroupName != "" &&
			this.SelectedGroupName.trim() != ""
		)
			return true;
		else return false;
	}
	get IsGroupCodeSelected() {
		if (
			this.SelectedGroupCode != null &&
			this.SelectedGroupCode != undefined &&
			this.SelectedGroupCode != ""
		) {
			return true;
		}
		return false;
	}
	get GetMEMBERBENEFITSPositive() {
		return this.GetMEMBERBENEFITS == true;
	}

	get GetMEMBERBENEFITSNegative() {
		return this.GetMEMBERBENEFITS == false;
	}
	get GetMEMBERBENEFITSIssueToday() {
		if (
			this.QuestionnaireObj.ADnDBenifitIssueToday &&
			this.QuestionnaireObj.AILPlusProgramIssueToday &&
			this.QuestionnaireObj.FamilyInfoWillKitIssueToday &&
			this.QuestionnaireObj.ChildSafeKitIssueToday &&
			this.QuestionnaireObj.NeedAnalysisIssueToday &&
			this.QuestionnaireObj.LifeInsMedQuestionsIssueToday
		)
			return true;
		if (
			this.QuestionnaireObj.ADnDBenifitIssueToday == false &&
			this.QuestionnaireObj.AILPlusProgramIssueToday == false &&
			this.QuestionnaireObj.FamilyInfoWillKitIssueToday == false &&
			this.QuestionnaireObj.ChildSafeKitIssueToday == false &&
			this.QuestionnaireObj.NeedAnalysisIssueToday == false &&
			this.QuestionnaireObj.LifeInsMedQuestionsIssueToday
		)
			return false;

		return null;
	}

	set GetMEMBERBENEFITSIssueToday(newValue: any) {
		if (newValue == true) {
			this.QuestionnaireObj.ADnDBenifitIssueToday = true;
			this.QuestionnaireObj.AILPlusProgramIssueToday = true;
			this.QuestionnaireObj.FamilyInfoWillKitIssueToday = true;
			this.QuestionnaireObj.ChildSafeKitIssueToday = true;
			this.QuestionnaireObj.NeedAnalysisIssueToday = true;
			this.QuestionnaireObj.LifeInsMedQuestionsIssueToday = true;
		} else {
			this.QuestionnaireObj.ADnDBenifitIssueToday = false;
			this.QuestionnaireObj.AILPlusProgramIssueToday = false;
			this.QuestionnaireObj.FamilyInfoWillKitIssueToday = false;
			this.QuestionnaireObj.ChildSafeKitIssueToday = false;
			this.QuestionnaireObj.NeedAnalysisIssueToday = false;
			this.QuestionnaireObj.LifeInsMedQuestionsIssueToday = false;
		}
	}

	isVideoOpened() {
		return this.ShowVideo;
	}

	GetMEMBERBENEFITSClick(value: any) {
		if (this.GetMEMBERBENEFITS == value) {
			this.QuestionnaireObj.ADnDBenifit = undefined;
			this.QuestionnaireObj.AILPlusProgram = undefined;
			this.QuestionnaireObj.FamilyInfoWillKit = undefined;
			this.QuestionnaireObj.ChildSafeKit = undefined;
			this.QuestionnaireObj.NeedAnalysis = undefined;
			this.QuestionnaireObj.LifeInsMedQuestions = undefined;
		} else if (value == true) {
			this.QuestionnaireObj.ADnDBenifit = true;
			this.QuestionnaireObj.AILPlusProgram = true;
			this.QuestionnaireObj.FamilyInfoWillKit = true;
			this.QuestionnaireObj.ChildSafeKit = true;
			this.QuestionnaireObj.NeedAnalysis = true;
			this.QuestionnaireObj.LifeInsMedQuestions = true;
		} else {
			this.QuestionnaireObj.ADnDBenifit = false;
			this.QuestionnaireObj.AILPlusProgram = false;
			this.QuestionnaireObj.FamilyInfoWillKit = false;
			this.QuestionnaireObj.ChildSafeKit = false;
			this.QuestionnaireObj.NeedAnalysis = false;
			this.QuestionnaireObj.LifeInsMedQuestions = false;
		}
		this.saveData();
	}

	get GetMEMBERBENEFITS() {
		if (
			this.QuestionnaireObj.ADnDBenifit &&
			this.QuestionnaireObj.AILPlusProgram &&
			this.QuestionnaireObj.FamilyInfoWillKit &&
			this.QuestionnaireObj.ChildSafeKit &&
			this.QuestionnaireObj.NeedAnalysis &&
			this.QuestionnaireObj.LifeInsMedQuestions
		)
			return true;
		else if (
			this.QuestionnaireObj.ADnDBenifit == false &&
			this.QuestionnaireObj.AILPlusProgram == false &&
			this.QuestionnaireObj.FamilyInfoWillKit == false &&
			this.QuestionnaireObj.ChildSafeKit == false &&
			this.QuestionnaireObj.NeedAnalysis == false &&
			this.QuestionnaireObj.LifeInsMedQuestions == false
		)
			return false;

		return null;
	}

	set GetMEMBERBENEFITS(newValue: any) {
		if (newValue == true) {
			this.QuestionnaireObj.ADnDBenifit = true;
			this.QuestionnaireObj.AILPlusProgram = true;
			this.QuestionnaireObj.FamilyInfoWillKit = true;
			this.QuestionnaireObj.ChildSafeKit = true;
			this.QuestionnaireObj.NeedAnalysis = true;
			this.QuestionnaireObj.LifeInsMedQuestions = true;
		} else {
			this.QuestionnaireObj.ADnDBenifit = false;
			this.QuestionnaireObj.AILPlusProgram = false;
			this.QuestionnaireObj.FamilyInfoWillKit = false;
			this.QuestionnaireObj.ChildSafeKit = false;
			this.QuestionnaireObj.NeedAnalysis = false;
			this.QuestionnaireObj.LifeInsMedQuestions = false;
		}
	}

	saveData() {
		var test = this.SelectedGroupCode;
		this.$emit("SaveQuestionnaire", this.QuestionnaireObj, false);
	}

	saveDataBTNPress() {
		if (this.isFormValidated == true && this.$refs.ReferralForm?.validate()) {
			this.QuestionnaireObj.IsFormSubmited = true;
			this.CloseFormInside(); //this.$emit("SaveQuestionnaire", this.QuestionnaireObj, true);
			this.$store.state.messageText = "Member Questionnaire Saved";
			this.$store.state.showMessage = true;
		} else {
			setTimeout(() => {
				var svview = document.getElementsByClassName("error--text");
				if (svview != undefined && svview.length > 0) {
					{
						svview[0].scrollIntoView(true);
					}
				} else {
					var svview = document.getElementsByClassName("SponsorCheckValidate");
					if (svview != undefined && svview.length > 0) {
						{
							svview[0].scrollIntoView(true);
						}
					}
				}
			}, 500);
			this.$store.state.messageText = "Required Fields Missing";
			this.$store.state.showMessage = true;
		}
	}

	CloseForm(Obj: any) {
		this.$emit("SaveQuestionnaire", this.QuestionnaireObj, true);
	}

	CloseFormInside() {
		this.$emit("SaveQuestionnaire", this.QuestionnaireObj, true);
	}

	PlayVideo() {
		if (this.QuestionnaireObj != undefined)
			this.QuestionnaireObj.IsVideoPlayed = true;
		this.ShowVideo = true;
	}

	CloseVideo() {
		this.ShowVideo = false;
	}
}
