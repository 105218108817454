














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Report from "@/entities/Report";
import { setPersistedState } from "@/helpers/StateMapper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { Component, Watch, Vue } from "vue-property-decorator";
import MenuComponent from "../components/menu.vue";
import SponsershipCard from "../components/SponsershipCard.vue";
import Sponsership from "../entities/Sponsership";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import Spouse from "../entities/Spouse";
import { getPersistedState } from "../helpers/StateMapper";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import moment from "moment";
import { PresentationType } from "@/enumerations/PresentationType";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
import LoggingHelper from "@/helpers/LoggingHelper";
import { EventBusLayout } from "../bus/EventBusLayout";

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		SponsershipCard,
		ReferralPopup,
	},
})
export default class ReportForm extends Vue {
	private Person: any;
	private Presentation: any;
	private allPresTypes: any = [];
	private allReportConfigs: any = [];
	private pageLoadedTime: any;
	private backgroundimage: string = "";
	private showOptionPopup: boolean = false;
	private valid: boolean = false;
	private CareerFormValid: boolean = false;
	private VSOValid: boolean = false;
	private Groups: any = [];
	private PhoneRequiredVal: boolean = false;
	private EmailRequiredVal: boolean = false;
	private MemberCareerSponser: Sponsership = new Sponsership();
	private MemberSpouseSponser: Sponsership = new Sponsership();
	private noCostReportConfigs: any = [];
	private permanentReportConfigs: any = [];
	private moreInfoReportConfigs: any = [];
	private overlay: boolean = false;
	private OtherCareerOption: boolean = false;
	private statesList: any = [];
	private currentItem: any;
	private ssprogram: boolean = false;
	private somemodal: boolean = false;
	private basicDetailRequired: boolean = false;
	private VSOQuestionRequired: boolean = false;
	private ResetReferrals: boolean = false;
	private browserCloseEvent: boolean = false;

	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;

	private SponserShipList: Sponsership[] = [];
	$refs!: {
		reportForm: any;
		groupComboBox: any;
		groupNameComboBox: any;
		CareerSponsorForm: any;
		VSOForm: any;
	};

	LoadDropDown() {
		new Promise(async () => {
			this.$store.state.loader = true;
			var allgrps = await getPersistedState("PresentationGroups");
			this.Groups = allgrps;

			var statesfromDB = await getPersistedState("StateList");

			this.statesList = statesfromDB;
			var state = this.statesList.filter((x: any) => {
				if (x.Name == this.Presentation.State) return x;
			});

			var allReportOptions: any = await getPersistedState("ReportOptions");

			if (allReportOptions != undefined && allReportOptions.length > 0) {
				var countryId = this.Presentation.CountryId;
				var presentationId = this.Presentation.PresentationTypeId;
				var presentationSubTypeId = this.Presentation.PresentationSubTypeId;

				var CurrrentScenarioReportOptions = allReportOptions.filter(
					(x: any) => {
						if (
							x.PresentationTypeId == presentationId &&
							(x.CountryId == undefined || x.CountryId == countryId)
						)
							return x;
					}
				);

				if (
					CurrrentScenarioReportOptions != undefined &&
					CurrrentScenarioReportOptions.length > 0
				) {
					this.noCostReportConfigs = CurrrentScenarioReportOptions.filter(
						(x: any) => x.ReportCardSectionTypeId == 1
					).sort((a: any, b: any) => a.SortOrder - b.SortOrder);

					this.permanentReportConfigs = CurrrentScenarioReportOptions.filter(
						(x: any) => x.ReportCardSectionTypeId == 2
					).sort((a: any, b: any) => a.SortOrder - b.SortOrder);

					this.moreInfoReportConfigs = CurrrentScenarioReportOptions.filter(
						(x: any) => x.ReportCardSectionTypeId == 3
					).sort((a: any, b: any) => a.SortOrder - b.SortOrder);

					if (this.noCostReportConfigs != undefined) {
						if (
							(presentationId == PresentationType.Veteran &&
								presentationSubTypeId !=
									PresentationSubType.ServiceMemberBenefitsLeadAlpha) ||
							(presentationId == PresentationType.ServiceMemberBenefits &&
								presentationSubTypeId !=
									PresentationSubType.SMBSponsoredVeteranLeadAlpha &&
								presentationSubTypeId !=
									PresentationSubType.SMBVeteranFamilyLeadAlpha &&
								presentationSubTypeId != PresentationSubType.Civilian &&
								presentationSubTypeId !=
									PresentationSubType.ServiceMemberBenefitNotification)
						) {
							this.noCostReportConfigs = this.noCostReportConfigs
								.filter((x: any) => x.ReportOptionId != 20)
								.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
						}
					}

					if (this.moreInfoReportConfigs != undefined) {
						if (
							(presentationId == PresentationType.Veteran &&
								presentationSubTypeId ==
									PresentationSubType.ServiceMemberBenefitsLeadAlpha) ||
							(presentationId == PresentationType.ServiceMemberBenefits &&
								(presentationSubTypeId ==
									PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
									presentationSubTypeId ==
										PresentationSubType.SMBVeteranFamilyLeadAlpha ||
									presentationSubTypeId == PresentationSubType.Civilian ||
									presentationSubTypeId ==
										PresentationSubType.ServiceMemberBenefitNotification))
						) {
							this.moreInfoReportConfigs = this.moreInfoReportConfigs
								.filter(
									(x: any) => x.ReportOptionId != 17 && x.ReportOptionId != 18
								)
								.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
						}

						var k = this.moreInfoReportConfigs.find(
							(x: any) => x.Name == "BECOMING A "
						);
						if (k != undefined) {
							k.ShowVSOBox = true;
						}
					}
				}
			}

			this.allPresTypes = await getPersistedState(
				IndexDBConstants.PresentationTypesKey
			);
			if (this.allPresTypes != undefined && this.allPresTypes.length > 0) {
				var currPresType = this.allPresTypes.find(
					(x: any) => x.Id == this.Presentation.PresentationTypeId
				);
				this.backgroundimage = currPresType?.ReportBackgroundImage;
			}
			this.$store.state.loader = false;
		});
	}

	FillCareerUpgadePersons(fromRefresh: boolean) {
		if (this.Person.Sponsership != undefined && this.Person.Sponsership != "") {
			this.MemberCareerSponser = JSON.parse(this.Person.Sponsership);
		} else if (this.Person != undefined) {
			this.MemberCareerSponser.FirstName = this.Person.FirstName;
			this.MemberCareerSponser.LastName = this.Person.LastName;
			this.MemberCareerSponser.Phone = this.Person.Phone;
			this.MemberCareerSponser.Email = this.Person.Email;
		}

		if (
			this.Person.Spouse.Sponsership != undefined &&
			this.Person.Spouse.Sponsership != ""
		) {
			this.MemberSpouseSponser = JSON.parse(this.Person.Spouse.Sponsership);
		} else if (this.Person.Spouse != undefined) {
			this.MemberSpouseSponser.FirstName = this.Person.Spouse.FirstName;
			this.MemberSpouseSponser.LastName = this.Person.Spouse.LastName;
			this.MemberSpouseSponser.Phone = "";
			this.MemberSpouseSponser.Email = "";
			this.MemberSpouseSponser.City = "";
		}

		if (
			this.Presentation.AllSponserships == undefined ||
			this.Presentation.AllSponserships.length == 0
		) {
			this.SponserShipList = [];
			this.SponserShipList.push(new Sponsership());
		} else {
			this.SponserShipList = JSON.parse(this.Presentation.AllSponserships);
			this.OtherCareerOption = this.SponserShipList[0].MemberCareerOption;
		}
	}

	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		window.addEventListener("beforeunload", this.onCloseBrowser);
		EventBusLayout.$on("RoutedToEvt", this.ReportFormRoutedToEvt);
		EventBusLayout.$on("Action", this.ReportFormAction);
		EventBusLayout.$on("UpdatedSGNumber", this.UpdatedSGNumber);
		this.pageLoadedTime = new Date();
		this.Person = PrimaryPerson.query().withAll().last();
		this.Presentation = Presentation.query().withAll().last();
		if (this.Presentation != undefined) {
			// this.$data.SGNumber = this.Presentation.SGNumber;
			// this.$data.Group = this.Presentation.PresentedGroup;

			if (
				this.$data.SGNumber == null ||
				this.$data.SGNumber == undefined ||
				this.$data.SGNumber == ""
			) {
				this.$data.SGNumber = this.$store.state.SGNumber;
				this.Presentation.SGNumber = this.$store.state.SGNumber;
			}

			if (
				this.$data.Group == null ||
				this.$data.Group == undefined ||
				this.$data.Group == ""
			) {
				this.$data.Group = this.$store.state.PresentedGroup;
				this.Presentation.PresentedGroup = this.$store.state.PresentedGroup;
			}
		}
		// var presType = this.allPresTypes.find(
		//   (x: any) => x.id == this.Presentation.PresentationTypeId
		// );
		// this.backgroundimage = presType.report_image;

		this.LoadDropDown();
		this.loadReportData(false);
		this.FillCareerUpgadePersons(false);
		this.FetchMaterialForReferral();
		var _keydownCallback = (event: any) => {
			if (event.code == "Escape") {
				this.overlay = false;
				this.$data.careerupgradeshown = false;
				this.$data.VSOpopupshown = false;
				if (this.currentItem)
					this.currentItem.Class = "reportformmodal successDark d-none";
			}
		};

		document.addEventListener("keyup", _keydownCallback);

		//if (this.$data.Report == undefined) this.$data.Report = new Report();
	}
	forceRerender() {
		this.$data.componentKey += 1;
	}

	onCloseBrowser(event: any) {
		this.browserCloseEvent = true;
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				// this.$router.push({
				//     name: "FinishPresentation",
				//     params: { showWhatHappenError: "true" },
				//   });
				this.RoutedTo("/finishpresentation");
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}
	ReportFormRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	ReportFormAction(action: any) {
		this.Action(action);
	}
	UpdatedSGNumber(action: any) {
		if (
			this.$store.state.SGNumber == null ||
			this.$store.state.SGNumber == undefined ||
			this.$store.state.SGNumber == ""
		) {
			this.$data.SGNumber = "";
			this.$data.Group = "";
		} else {
			var list = this.Groups.filter(
				(x: any) =>
					x.GroupCode != undefined &&
					x.GroupCode.includes(this.$store.state.SGNumber)
			);
			if (list != undefined && list.length >= 1) {
				this.$data.SGNumber = list[0];
				this.changeGroup("SGNumber");
			}
		}
	}

	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.ReportFormRoutedToEvt);
		EventBusLayout.$off("Action", this.ReportFormAction);
		EventBusLayout.$off("UpdatedSGNumber", this.UpdatedSGNumber);
	}

	RefreshVSOQuestionnaire(item: any) {
		this.$refs.VSOForm[0].reset();
		this.VSOQuestionRequired = false;
		item.selected = null;
		item.bold = false;
		this.$data.Report.IsVSOQuestionnaireCompleted = false;
		this.$data.Report.HonorableService = null;
		this.$data.Report.ServiceInWar = null;
	}

	RefreshCareerUpgrade() {
		//this.FillCareerUpgadePersons(true);

		this.MemberCareerSponser = new Sponsership();
		this.MemberSpouseSponser = new Sponsership();
		this.SponserShipList = [];
		this.SponserShipList.push(new Sponsership());
		this.OtherCareerOption = false;
	}

	data() {
		return {
			ops: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			componentKey: 0,
			Report: null,
			counrteousRating: null,
			programofValue: false,
			timeRating: null,
			RepCourtiousVali: "white",
			RepTimeVali: "white",
			ProgValueVali: "white",
			Group: null,
			SGNumber: null,
			Referrals: {},

			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => !!v.trim() || "Field is required",
			],
			RequiredGroup: [(v: any) => !!v || "Field is required"],
			PrimaryRadioRequired: [(v: any) => v != undefined || "Field is required"],
			careerupgradeshown: false,
			VSOpopupshown: false,
			PhoneRules: [(v: any) => (!!v && v.length > 13) || "Phone must be valid"],
			PhoneRulesNotRequired: [
				(v: any) =>
					v == undefined || v == "" || v.length > 13 || "Phone must be valid",
			],
			EmailRules: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return false;
					}
				},
			],
			EmailRulesNotRequired: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return true;
					}
				},
			],
			RequiredZip: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						var zipFormat = this.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			ZipFormat: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						if (v.trim().length == 0) return true;
						var zipFormat = this.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
		};
	}
	reset() {
		this.$refs.reportForm.reset();
		this.$data.timeRating = null;
		this.$data.counrteousRating = null;
	}

	loadReportData(reset: boolean) {
		var presType = this.allPresTypes.find(
			(x: any) => x.id == this.Presentation.PresentationTypeId
		);
		var state = this.statesList.find(
			(x: any) => x.Name == this.Presentation.State
		);

		this.$data.Report = Report.query()
			.where((report: any) => {
				return report.Id == this.Presentation.Report_Id;
			})
			.first();
		this.$data.Report.FirstName = this.Person.FirstName;
		this.$data.Report.LastName = this.Person.LastName;
		this.$data.Report.Zip = this.Person.Zip;
		this.$data.Report.Phone = this.Person.Phone;
		this.$data.Report.Email = this.Person.Email;
		if (
			this.Presentation != undefined &&
			this.Presentation.FamilyCareSurvey != "" &&
			this.Presentation.FamilyCareSurvey != undefined
		) {
			this.$data.Report.HonorableService = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).HonorableService;
			this.$data.Report.ServiceInWar = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).ServiceInWar;
		}
		if (this.$data.Report.NoCostBenefits) {
			this.noCostReportConfigs = JSON.parse(this.$data.Report.NoCostBenefits);
		} else if (reset) {
			this.noCostReportConfigs.forEach(function (obj: any) {
				obj.selected = false;
			});
		}

		if (this.$data.Report.PermanentCoverages) {
			this.permanentReportConfigs = JSON.parse(
				this.$data.Report.PermanentCoverages
			);
		} else if (reset) {
			this.permanentReportConfigs.forEach(function (obj: any) {
				obj.selected = false;
			});
		}
		if (this.$data.Report.MoreInfoOn) {
			this.moreInfoReportConfigs = JSON.parse(this.$data.Report.MoreInfoOn);
		} else if (reset) {
			this.moreInfoReportConfigs.forEach(function (obj: any) {
				obj.selected = false;
			});
		}
	}

	RoutedTo(route: String) {
		if (route == "/ssprogram") {
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";
			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}

		window.removeEventListener("beforeunload", this.onCloseBrowser);

		if (!this.Presentation) {
			this.$router.push(route.toString());
			return;
		}

		if (this.Presentation.CurrentStepCompleted <= 4) {
			this.Presentation.CurrentStepCompleted = 4;
		}
		this.Presentation.TimeOnReportCard = (
			(parseInt(this.Presentation.TimeOnReportCard) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});

		if (this.browserCloseEvent) {
			this.browserCloseEvent = false;
			window.removeEventListener("beforeunload", this.onCloseBrowser);
			this.$router.push({
				name: "FinishPresentation",
				params: { showWhatHappenError: "true" },
			});
		} else {
			this.$router.push(route.toString());
		}
	}

	private allProducts: any = {};
	private allMatchingproducts: any = [];

	FetchMaterialForReferral() {
		getPersistedState("Materials").then((res: any) => {
			this.allProducts = res;
			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined ||
							x.CountryId == this.Presentation.CountryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == this.Presentation.PresentationTypeId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);
		});
	}

	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		var RefInit: any = {};
		if (this.Presentation.Referrals) {
			RefInit = JSON.parse(this.Presentation.Referrals);
		} else {
			RefInit = {
				ReferredBy: "",
				ReferredByLastName: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: "",
				ReferralsList: [],
				CCEmail: [],
			};
		}
		if (RefInit.ReferralsList.length > 0) {
			RefInit.ReferralsList.forEach((referral: any) => {
				referral.StateObj = this.statesList.find(
					(x: any) => x.StateCode == referral.State
				);
			});
		}
		if (RefInit.ReferredBy == undefined || RefInit.ReferredBy == "")
			RefInit.ReferredBy = this.Person?.FirstName;

		if (
			RefInit.ReferredByLastName == undefined ||
			RefInit.ReferredByLastName == ""
		)
			RefInit.ReferredByLastName = this.Person?.LastName;
		if (RefInit.Phone == undefined || RefInit.Phone == "")
			RefInit.Phone = this.Person?.Phone;
		if (RefInit.Email == undefined || RefInit.Email == "")
			RefInit.Email = this.Person?.Email;
		if (
			this.Presentation.PresentedGroup != undefined &&
			this.Presentation.PresentedGroup != ""
		)
			RefInit.Organization = this.Presentation.PresentedGroup;
		if (RefInit.Date == undefined || RefInit.Date == "")
			RefInit.Date = moment(new Date()).format("MM/DD/YYYY");

		this.$data.Referrals = RefInit;
		if (newValue == true) {
			this.ResetReferrals = !this.ResetReferrals;
		}
	}

	CloseReferralPopup() {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
	}

	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;

		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;

		if (referral.City != null && referral.City.trim() != "") return false;

		if (referral.State != null && referral.State.trim() != "") return false;

		if (referral.Phone != null && referral.Phone.trim() != "") return false;

		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;

		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;

		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;

		return true;
	}

	SaveReferral(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
	}
	SaveReferralsWithoutClosingPopup(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	Action(action: string) {
		if (action == "materials") this.RoutedTo("/nocostbenefits");
	}

	loadCheckBoxPopup(item: any) {
		item.selected = !item.selected;
		if (item.selected) item.isBold = true;
		else item.isBold = false;
	}

	CheckBoxChange(item: any) {
		if (item.selected) item.isBold = true;
		else item.isBold = false;
	}

	CancelClick() {
		this.Presentation.TimeOnReportCard = (
			(parseInt(this.Presentation.TimeOnReportCard) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});
		this.$router.go(-1);
	}

	CompleteClick() {
		this.Presentation.TimeOnReportCard = (
			(parseInt(this.Presentation.TimeOnReportCard) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		var res = this.ValidateAndInsertData();

		if (res) {
			this.$router.go(-1);
		}
	}

	ValidateAndInsertData(): boolean {
		let isRatingValid = true;
		this.basicDetailRequired = false;
		if (this.$data.Report.CourteousRating == 0) {
			this.$data.RepCourtiousVali = "red";
			isRatingValid = false;
		}

		if (this.$data.Report.TimeRating == 0) {
			this.$data.RepTimeVali = "red";
			isRatingValid = false;
		}

		if (this.$data.Report.ProgramValueRating == 0) {
			this.$data.ProgValueVali = "red";
			isRatingValid = false;
		}
		if (
			(this.Presentation.PresentationTypeId == this.VeteranPresentation ||
				this.Presentation.PresentationTypeId ==
					this.ServiceMemberBenefitsPresentation) &&
			this.$data.Report.BelongtoVSO == undefined
		) {
			this.basicDetailRequired = true;
			isRatingValid = false;
		}

		if (!this.valid || !isRatingValid) {
			this.$refs.reportForm.validate();
			this.$store.state.messageText = this.$t("Report Form.ValidationMsg");
			this.$store.state.showMessage = true;
			return false;
		}

		// if (
		//   (this.$data.Report.Phone == undefined ||
		//     this.$data.Report.Phone == "" ||
		//     this.$data.Report.Phone == "(") &&
		//   (this.$data.Report.Email == undefined || this.$data.Report.Email == "")
		// ) {
		//   if (
		//     this.$data.Report.Phone == undefined ||
		//     this.$data.Report.Phone == ""
		//   ) {
		//     this.PhoneRequiredVal = true;
		//   } else {
		//     this.EmailRequiredVal = true;
		//   }
		//   this.$store.state.messageText = "Either Phone or Email is Required";
		//   this.$store.state.showMessage = true;
		//   return false;
		// }

		const pattern =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (
			this.$data.Report.Email != "" &&
			!pattern.test(this.$data.Report.Email)
		) {
			this.$store.state.messageText = this.$t(
				"FinishPresentation.EmailInvalid"
			);
			this.$store.state.showMessage = true;
			this.EmailRequiredVal = true;
			return false;
		}

		if (
			this.Presentation.PresentationTypeId == this.VeteranPresentation ||
			this.Presentation.PresentationTypeId ==
				this.ServiceMemberBenefitsPresentation
		) {
			var VSOQuestionnaire = {
				BelongtoVSO: this.$data.Report.BelongtoVSO,
				HonorableService: this.$data.Report.HonorableService,
				ServiceInWar: this.$data.Report.ServiceInWar,
				IsVSOQuestionnaireCompleted:
					this.$data.Report.IsVSOQuestionnaireCompleted,
			};

			this.$data.Report.VSOQuestionnaire = JSON.stringify(VSOQuestionnaire);
		}
		this.Presentation.isReportCardCompleted = true;

		this.$data.Report.NoCostBenefits = JSON.stringify(this.noCostReportConfigs);
		this.$data.Report.PermanentCoverages = JSON.stringify(
			this.permanentReportConfigs
		);
		this.$data.Report.MoreInfoOn = JSON.stringify(this.moreInfoReportConfigs);
		Report.update({
			where: this.$data.Report.Id,
			data: this.$data.Report,
		});

		this.Person.FirstName = this.$data.Report.FirstName;
		this.Person.LastName = this.$data.Report.LastName;
		this.Person.Zip = this.$data.Report.Zip;
		this.Person.Phone = this.$data.Report.Phone;
		this.Person.Email = this.$data.Report.Email;

		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});

		if (typeof this.$data.SGNumber == "string") {
			this.Presentation.SGNumber = this.$data.SGNumber;
			this.$store.state.SGNumber = this.$data.SGNumber;
		} else if (this.$data.SGNumber != undefined) {
			this.Presentation.SGNumber = this.$data.SGNumber.GroupCode;
			this.$store.state.SGNumber = this.$data.SGNumber.GroupCode;
		}

		if (typeof this.$data.Group == "string") {
			this.Presentation.PresentedGroup = this.$data.Group;
			this.$store.state.PresentedGroup = this.$data.Group;
		} else if (this.$data.Group != undefined) {
			this.Presentation.PresentedGroup = this.$data.Group?.GroupName;
			this.$store.state.PresentedGroup = this.$data.Group?.GroupName;
		}
		if (
			this.Presentation.FamilyCareSurvey != undefined &&
			this.Presentation.FamilyCareSurvey != ""
		) {
			var FamilyCareSurvey = JSON.parse(this.Presentation.FamilyCareSurvey);
			FamilyCareSurvey.HonorableService = this.$data.Report.HonorableService;
			FamilyCareSurvey.ServiceInWar = this.$data.Report.ServiceInWar;
			this.Presentation.FamilyCareSurvey = JSON.stringify(FamilyCareSurvey);
		}

		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});

		let saveData = new GenericKeyPair(
			"CurrentPresentation",
			Presentation.query().withAllRecursive().last()
		);
		setPersistedState(saveData);
		// saveData = new GenericKeyPair(
		//   "PresentationsList",
		//   JSON.stringify(Presentation.query().withAllRecursive().all())
		// );
		// setPersistedState(saveData);

		return true;
	}

	AddAnotherSponser() {
		this.SponserShipList.push(new Sponsership());

		var svview = document.getElementsByClassName("formscroll");
		if (
			svview != undefined &&
			svview.length > 0 &&
			svview[0].children.length > 0
		) {
			{
				setTimeout(() => {
					svview[0].children[svview[0].children.length - 1].scrollIntoView(
						true
					);
				}, 50);
			}
		}
	}

	ChangedRating(rating: any, propertyValidationName: any) {
		if (rating != undefined && rating > 0) {
			var proprty = this.hasOwnProperty(propertyValidationName);
			if (proprty && this != undefined) {
				this.$data[propertyValidationName] = "white";
			}
		}
	}
	SaveVSOQuestionnaire(item: any) {
		this.VSOQuestionRequired = false;
		if (
			this.$data.Report.HonorableService != undefined &&
			this.$data.Report.HonorableService != null &&
			this.$data.Report.ServiceInWar != undefined &&
			this.$data.Report.ServiceInWar != null
		) {
			this.$data.Report.IsVSOQuestionnaireCompleted = true;
			this.$data.VSOpopupshown = false;
			this.overlay = false;
			this.currentItem.Class = "vsoformmodal successDark d-none";
			item.selected = true;
			item.bold = true;
			this.$store.state.messageText = this.$t("Report Form.VSOSave");
			this.$store.state.showMessage = true;
			this.forceRerender();
		} else {
			this.VSOQuestionRequired = true;
		}

		if (this.VSOQuestionRequired) {
			this.$refs.VSOForm[0].validate();
			return;
		}
	}

	SaveCareerSponsers() {
		if (!this.CareerFormValid) {
			this.$refs.CareerSponsorForm.validate();
			this.$store.state.messageText = this.$t("Report Form.FillFields");
			this.$store.state.showMessage = true;
			return;
		}

		if (this.MemberCareerSponser != undefined) {
			this.Person.Sponsership = JSON.stringify(this.MemberCareerSponser);
			PrimaryPerson.update({
				where: this.Person.Id,
				data: this.Person,
			}).then(() => {});
		}

		if (this.MemberSpouseSponser != undefined) {
			this.Person.Spouse.Sponsership = JSON.stringify(this.MemberSpouseSponser);
			Spouse.update({
				where: this.Person.Spouse.Id,
				data: this.Person.Spouse,
			}).then(() => {});
		}

		if (this.SponserShipList != undefined && this.SponserShipList.length > 0) {
			this.SponserShipList[0].MemberCareerOption = this.OtherCareerOption;
			this.Presentation.AllSponserships = JSON.stringify(this.SponserShipList);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}

		this.$data.careerupgradeshown = false;
		this.overlay = false;
		this.currentItem.Class = "reportformmodal successDark d-none";
	}

	changeGroup(type: string) {
		if (type == "SGNumber" && typeof this.$data.SGNumber != "string") {
			this.$data.Group = this.$data.SGNumber;
			this.$store.state.SGNumber = this.$data.SGNumber.GroupCode;
			this.$store.state.PresentedGroup = this.$data.SGNumber.GroupName;
		} else if (type == "SGNumber" && this.$data.SGNumber == "") {
			this.$store.state.SGNumber = "";
			// this.$store.state.PresentedGroup = this.$data.SGNumber.GroupName;
		} else if (typeof this.$data.Group != "string") {
			this.$data.SGNumber = this.$data.Group;
			this.$store.state.SGNumber = this.$data.Group.GroupCode;
			this.$store.state.PresentedGroup = this.$data.Group.GroupName;
		}
		if (this.$data.Group != undefined) {
			//this.Presentation.PresentedGroup = this.$data.Group.GroupCode;
			this.Presentation.PresentedGroupId = this.$data.Group.Id;
		}
	}

	ShowCareerPopupClick(item: any) {
		if (!item.ShowCandidateBox) {
			this.$data.careerupgradeshown = false;
			return;
		}
		item.selected = !item.selected;
		this.overlay = true;
		item.Class = "reportformmodal successDark showmemberpopup";
		this.currentItem = item;

		var svview = document.getElementsByClassName("formscroll");
		if (svview != undefined && svview.length > 0) {
			{
				setTimeout(() => {
					svview[0].scrollIntoView(true);
				}, 100);
			}
		}
	}

	ShowVSOPopupClick(item: any) {
		if (!item.ShowVSOBox) {
			this.$data.VSOpopupshown = false;
			return;
		}

		item.selected = !item.selected;
		this.overlay = true;
		item.Class = "vsoformmodal showmemberpopup";
		this.currentItem = item;

		// var svview = document.getElementsByClassName("formscroll");
		// if (svview != undefined && svview.length > 0) {
		// 	{
		// 		setTimeout(() => {
		// 			svview[0].scrollIntoView(true);
		// 		}, 100);
		// 	}
		// }
	}

	OverlayClick() {
		this.overlay = false;
		this.currentItem.Class = "reportformmodal successDark d-none";
	}

	GroupSGBlur(v: any, text: any) {
		var text = this.$refs.groupComboBox.lazySearch.toUpperCase();

		if (text != undefined && text != "") {
			var list = this.Groups.filter(
				(x: any) => x.GroupCode != undefined && x.GroupCode.includes(text)
			);
			if (list != undefined && list.length >= 1) {
				this.$data.SGNumber = list[0];
				this.changeGroup("SGNumber");
			}
		}
		this.$refs.groupComboBox.isMenuActive = false;
	}

	GroupNameBlur(v: any, text: any) {
		var text = this.$refs.groupNameComboBox.lazySearch.toUpperCase();
		if (text != undefined && text != "") {
			var list = this.Groups.filter(
				(x: any) => x.GroupName != undefined && x.GroupName.includes(text)
			);
			if (list != undefined && list.length >= 1) {
				this.$data.Group = list[0];
				this.changeGroup("Group");
			}
		}
		this.$refs.groupNameComboBox.isMenuActive = false;
	}
	SaveWillKitDataToPresentation(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			this.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}
}
