










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import MemberSurvey from "@/entities/MemberSurvey";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";
import moment from "moment";
import { getPersistedState } from "@/helpers/StateMapper";
import { EventBusLayout } from "@/bus/EventBusLayout";
let RelationshipList = require("@/assets/pre-fill-data/relation_sponsor.json");
let CreditMemberHowLong = require("@/assets/pre-fill-data/CreditMemberHowLong.json");

@Component
export default class CreditUnion extends Vue {
	$refs!: {
		ReferralForm: any;
	};
	private UserNameForFredomofChoise = GetAscFullNameWithCamelCase();

	private onOnlineServiceUse: any = null;
	private onOnlineServiceMoreInfo: boolean = false;
	private DEPOSITPRODUCTSCheck: any = null;
	private DEPOSITPRODUCTSMoreInfo: boolean = false;
	private LoanProductUse: any = null;
	private LoanProductMoreInfo: boolean = false;
	private IsMemberofUnionOrVeteran: boolean = false;
	private MEMBERBENEFITS: any = null;
	private MEMBERBENEFITSMoreInfo: boolean = false;
	private TodayDate: string = moment(new Date()).format("MM / DD / YYYY");
	private RelationshipDrop: any = RelationshipList;
	private MemberDuration: any = CreditMemberHowLong.filter(
		(x: any) => x.Name == "0 (benefits were extended to me)"
	);
	private CreditUnionDropDown: any = [];
	private ShowSponsor: boolean = false;
	private isRefFormValid: boolean = false;

	private OnlineBanking: any = null;
	private MobileBanking: any = null;
	private EStatements: any = null;
	private BillPay: any = null;
	private BranchService: any = null;
	private CheckingAccount: any = null;
	private SavingsAccount: any = null;
	private CertificateOfDepositAccount: any = null;
	private MoneyMarketAccount: any = null;
	private IRARetirementAccount: any = null;
	private PersonalCreditCard: any = null;
	private HomeLoans: any = null;
	private AutoEV: any = null;
	private PersonalLineOfCredit: any = null;
	private ADnDBenefit: any = null;
	private FinancialInformationGuide: any = null;
	private NoCostLast: any = null;
	private ChildSafeIDKits: any = null;
	private NeedsAnalysisSurvey: any = null;
	private FinalExpenseProgram: any = null;
	private isFormValidated: boolean = true;
	private PhoneNumberEmail: any = null;
	private MemberFirstName: any = null;
	private MemberLastName: any = null;
	private SpouseFirstName: any = null;
	private SpouseLastName: any = null;
	private MemberDurationval: any = null;
	private MemberEmail: any = null;

	data() {
		return {
			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => (!!v && !!v.trim()) || "Field is required",
				(v: any) =>
					(!!v && v.length <= 50) || "Field must be less than 50 characters",
			],
		};
	}
	@Prop({ default: new MemberSurvey() })
	private MemberSurveyObj: any;

	created() {
		EventBusLayout.$on("CloseForm", this.CloseForm);
		if (
			this.MemberSurveyObj.CreditUnion == "" ||
			this.MemberSurveyObj.CreditUnion == null
		) {
			this.MemberDuration = CreditMemberHowLong.filter(
				(x: any) => x.Name == "0 (benefits were extended to me)"
			);
		} else {
			this.MemberDuration = CreditMemberHowLong;
		}

		if (this.CreditUnionDropDown.length == 0) {
			this.getDropdownVal();
		}

		// setTimeout(() => {
		//   if (this.getonOnlineServiceUse == true)
		//   {
		//     this.getonOnlineServiceUse = this.getonOnlineServiceUse;
		//     this.GetDEPOSITPRODUCTSCheck = this.GetDEPOSITPRODUCTSCheck;
		//     this.GetDEPOSITPRODUCTSCheck = this.GetLoanProductUse;
		//     this.GetMEMBERBENEFITS = this.GetMEMBERBENEFITS;

		//   }
		// }, 1000)
	}

	beforeDestroy() {
		EventBusLayout.$off("CloseForm", this.CloseForm);
	}

	get getonOnlineServiceUsePositive() {
		return this.getonOnlineServiceUse;
	}

	get getonOnlineServiceUseNegative() {
		return this.getonOnlineServiceUse == false;
	}

	async getDropdownVal() {
		this.CreditUnionDropDown = await getPersistedState("CreditUnion");
	}

	get getonOnlineServiceUse() {
		// setTimeout(() => {
		//   this.getonOnlineServiceUseNegative = undefined;
		//   this.getonOnlineServiceUsePositive = undefined;

		// }, 500)
		if (
			this.MemberSurveyObj.OnlineBanking &&
			this.MemberSurveyObj.MobileBanking &&
			this.MemberSurveyObj.EStatement &&
			this.MemberSurveyObj.BillPay &&
			this.MemberSurveyObj.BranchService
		)
			return true;
		else if (
			this.MemberSurveyObj.OnlineBanking == false &&
			this.MemberSurveyObj.MobileBanking == false &&
			this.MemberSurveyObj.EStatement == false &&
			this.MemberSurveyObj.BillPay == false &&
			this.MemberSurveyObj.BranchService == false
		)
			return false;
		else return undefined;
	}

	set getonOnlineServiceUse(value: any) {
		if (value == true) {
			this.MemberSurveyObj.OnlineBanking = true;
			this.MemberSurveyObj.MobileBanking = true;
			this.MemberSurveyObj.EStatement = true;
			this.MemberSurveyObj.BillPay = true;
			this.MemberSurveyObj.BranchService = true;
		} else {
			this.MemberSurveyObj.OnlineBanking = false;
			this.MemberSurveyObj.MobileBanking = false;
			this.MemberSurveyObj.EStatement = false;
			this.MemberSurveyObj.BillPay = false;
			this.MemberSurveyObj.BranchService = false;
		}
		//   setTimeout(() => {
		//     this.getonOnlineServiceUseNegative = undefined;
		//     this.getonOnlineServiceUsePositive = undefined;

		// }, 500)
	}

	getonOnlineServiceUseClick(value: any) {
		if (this.getonOnlineServiceUse == value) {
			this.MemberSurveyObj.OnlineBanking = undefined;
			this.MemberSurveyObj.MobileBanking = undefined;
			this.MemberSurveyObj.EStatement = undefined;
			this.MemberSurveyObj.BillPay = undefined;
			this.MemberSurveyObj.BranchService = undefined;
		} else if (value == true) {
			this.MemberSurveyObj.OnlineBanking = true;
			this.MemberSurveyObj.MobileBanking = true;
			this.MemberSurveyObj.EStatement = true;
			this.MemberSurveyObj.BillPay = true;
			this.MemberSurveyObj.BranchService = true;
		} else {
			this.MemberSurveyObj.OnlineBanking = false;
			this.MemberSurveyObj.MobileBanking = false;
			this.MemberSurveyObj.EStatement = false;
			this.MemberSurveyObj.BillPay = false;
			this.MemberSurveyObj.BranchService = false;
		}
		this.saveData();
	}

	get getonOnlineServiceUseMoreInfo() {
		if (
			this.MemberSurveyObj.OnlineBankingMoreInfo &&
			this.MemberSurveyObj.MobileBankingMoreInfo &&
			this.MemberSurveyObj.EStatementMoreInfo &&
			this.MemberSurveyObj.BillPayMoreInfo &&
			this.MemberSurveyObj.BranchServiceMoreinfo
		)
			return true;
		else if (
			this.MemberSurveyObj.OnlineBankingMoreInfo == false &&
			this.MemberSurveyObj.MobileBankingMoreInfo == false &&
			this.MemberSurveyObj.EStatementMoreInfo == false &&
			this.MemberSurveyObj.BillPayMoreInfo == false &&
			this.MemberSurveyObj.BranchServiceMoreinfo == false
		)
			return false;
		else return null;
	}

	set getonOnlineServiceUseMoreInfo(value: any) {
		if (value == true) {
			this.MemberSurveyObj.OnlineBankingMoreInfo = true;
			this.MemberSurveyObj.MobileBankingMoreInfo = true;
			this.MemberSurveyObj.EStatementMoreInfo = true;
			this.MemberSurveyObj.BillPayMoreInfo = true;
			this.MemberSurveyObj.BranchServiceMoreinfo = true;
		} else {
			this.MemberSurveyObj.OnlineBankingMoreInfo = false;
			this.MemberSurveyObj.MobileBankingMoreInfo = false;
			this.MemberSurveyObj.EStatementMoreInfo = false;
			this.MemberSurveyObj.BillPayMoreInfo = false;
			this.MemberSurveyObj.BranchServiceMoreinfo = false;
		}
	}

	CreditUnionChange(event: any) {
		if (event != undefined && event.Name != undefined) {
			this.MemberSurveyObj.CreditUnion = event.Name;
		}

		if (
			this.MemberSurveyObj.CreditUnion == "" ||
			this.MemberSurveyObj.CreditUnion == null
		) {
			this.MemberDuration = CreditMemberHowLong.filter(
				(x: any) => x.Name == "0 (benefits were extended to me)"
			);
		} else {
			this.MemberDuration = CreditMemberHowLong;
		}
		this.saveData();
	}

	MemberDurationChange(event: any) {
		if (event != undefined && event.Name != undefined) {
			this.MemberSurveyObj.MemberDuration = event.Name;
		}
		this.saveData();
	}

	RelationShipChange(event: any) {
		if (event != undefined && event.text != undefined) {
			this.MemberSurveyObj.RelationShip = event.text;
		}
		this.saveData();
	}

	@Watch("MemberSurveyObj.IsMemberofUnionOrVeteran")
	onMemberOFUnionChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) this.IsMemberofUnionOrVeteran = false;
	}

	@Watch("IsMemberofUnionOrVeteran")
	onMemberOFUnionUnClickChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) this.MemberSurveyObj.IsMemberofUnionOrVeteran = false;
	}
	@Watch("MemberSurveyObj.Experince")
	onRatingChange(newValue: number, oldValue: number) {
		this.saveData();
	}

	@Watch("MemberSurveyObj.GroupName")
	onGroupNameChange(newValue: string, oldValue: string) {
		if (newValue != undefined && newValue != "") {
			this.MemberSurveyObj.IsMemberofUnionOrVeteran = true;
			this.IsMemberofUnionOrVeteran = false;
		}
	}

	@Watch("onOnlineServiceUse")
	onOnlineServiceUseChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) {
			this.MemberSurveyObj.OnlineBanking = true;
			this.MemberSurveyObj.MobileBanking = true;
			this.MemberSurveyObj.EStatement = true;
			this.MemberSurveyObj.BillPay = true;
			this.MemberSurveyObj.BranchService = true;
		} else {
			this.MemberSurveyObj.OnlineBanking = false;
			this.MemberSurveyObj.MobileBanking = false;
			this.MemberSurveyObj.EStatement = false;
			this.MemberSurveyObj.BillPay = false;
			this.MemberSurveyObj.BranchService = false;
		}
	}

	@Watch("onOnlineServiceMoreInfo")
	onOnlineServiceMoreInfoChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) {
			this.MemberSurveyObj.OnlineBankingMoreInfo = true;
			this.MemberSurveyObj.MobileBankingMoreInfo = true;
			this.MemberSurveyObj.EStatementMoreInfo = true;
			this.MemberSurveyObj.BillPayMoreInfo = true;
			this.MemberSurveyObj.BranchServiceMoreinfo = true;
		} else {
			this.MemberSurveyObj.OnlineBankingMoreInfo = false;
			this.MemberSurveyObj.MobileBankingMoreInfo = false;
			this.MemberSurveyObj.EStatementMoreInfo = false;
			this.MemberSurveyObj.BillPayMoreInfo = false;
			this.MemberSurveyObj.BranchServiceMoreinfo = false;
		}
	}

	get GetDEPOSITPRODUCTSCheckPositive() {
		return this.GetDEPOSITPRODUCTSCheck;
	}

	get GetDEPOSITPRODUCTSCheckNegative() {
		return this.GetDEPOSITPRODUCTSCheck == false;
	}

	get GetDEPOSITPRODUCTSCheck() {
		if (
			this.MemberSurveyObj.CheckingAccount &&
			this.MemberSurveyObj.SavingAccount &&
			this.MemberSurveyObj.CertificateOfDeposit &&
			this.MemberSurveyObj.MoneyMarketAccount &&
			this.MemberSurveyObj.IRARetirement
		)
			return true;
		else if (
			this.MemberSurveyObj.CheckingAccount == false &&
			this.MemberSurveyObj.SavingAccount == false &&
			this.MemberSurveyObj.CertificateOfDeposit == false &&
			this.MemberSurveyObj.MoneyMarketAccount == false &&
			this.MemberSurveyObj.IRARetirement == false
		)
			return false;

		return null;
	}

	set GetDEPOSITPRODUCTSCheck(value: any) {
		if (value == true) {
			this.MemberSurveyObj.CheckingAccount = true;
			this.MemberSurveyObj.SavingAccount = true;
			this.MemberSurveyObj.CertificateOfDeposit = true;
			this.MemberSurveyObj.MoneyMarketAccount = true;
			this.MemberSurveyObj.IRARetirement = true;
		} else {
			this.MemberSurveyObj.CheckingAccount = false;
			this.MemberSurveyObj.SavingAccount = false;
			this.MemberSurveyObj.CertificateOfDeposit = false;
			this.MemberSurveyObj.MoneyMarketAccount = false;
			this.MemberSurveyObj.IRARetirement = false;
		}
	}

	GetDEPOSITPRODUCTSCheckClick(value: any) {
		if (this.GetDEPOSITPRODUCTSCheck == value) {
			this.MemberSurveyObj.CheckingAccount = undefined;
			this.MemberSurveyObj.SavingAccount = undefined;
			this.MemberSurveyObj.CertificateOfDeposit = undefined;
			this.MemberSurveyObj.MoneyMarketAccount = undefined;
			this.MemberSurveyObj.IRARetirement = undefined;
		} else if (value == true) {
			this.MemberSurveyObj.CheckingAccount = true;
			this.MemberSurveyObj.SavingAccount = true;
			this.MemberSurveyObj.CertificateOfDeposit = true;
			this.MemberSurveyObj.MoneyMarketAccount = true;
			this.MemberSurveyObj.IRARetirement = true;
		} else {
			this.MemberSurveyObj.CheckingAccount = false;
			this.MemberSurveyObj.SavingAccount = false;
			this.MemberSurveyObj.CertificateOfDeposit = false;
			this.MemberSurveyObj.MoneyMarketAccount = false;
			this.MemberSurveyObj.IRARetirement = false;
		}
		this.saveData();
	}

	get GetDEPOSITPRODUCTSChecks() {
		if (
			this.MemberSurveyObj.CheckingAccountMoreinfo &&
			this.MemberSurveyObj.SavingAccountMoreinfo &&
			this.MemberSurveyObj.CertificateOfDepositMoreInfo &&
			this.MemberSurveyObj.MoneyMarketAccountMoreInfo &&
			this.MemberSurveyObj.IRARetirementMoreInfo
		)
			return true;
		else if (
			this.MemberSurveyObj.CheckingAccountMoreinfo == false &&
			this.MemberSurveyObj.SavingAccountMoreinfo == false &&
			this.MemberSurveyObj.CertificateOfDepositMoreInfo == false &&
			this.MemberSurveyObj.MoneyMarketAccountMoreInfo == false &&
			this.MemberSurveyObj.IRARetirementMoreInfo == false
		)
			return false;

		return null;
	}

	set GetDEPOSITPRODUCTSChecks(value: any) {
		if (value == true) {
			this.MemberSurveyObj.CheckingAccountMoreinfo = true;
			this.MemberSurveyObj.SavingAccountMoreinfo = true;
			this.MemberSurveyObj.CertificateOfDepositMoreInfo = true;
			this.MemberSurveyObj.MoneyMarketAccountMoreInfo = true;
			this.MemberSurveyObj.IRARetirementMoreInfo = true;
		} else {
			this.MemberSurveyObj.CheckingAccountMoreinfo = false;
			this.MemberSurveyObj.SavingAccountMoreinfo = false;
			this.MemberSurveyObj.CertificateOfDepositMoreInfo = false;
			this.MemberSurveyObj.MoneyMarketAccountMoreInfo = false;
			this.MemberSurveyObj.IRARetirementMoreInfo = false;
		}
	}

	get GetLoanProductUsePositive() {
		return this.GetLoanProductUse;
	}

	get GetLoanProductUseNegative() {
		return this.GetLoanProductUse == false;
	}

	get GetLoanProductUse() {
		if (
			this.MemberSurveyObj.PersonlCreditCard &&
			this.MemberSurveyObj.HomeLoan &&
			this.MemberSurveyObj.AutoEveLoan &&
			this.MemberSurveyObj.PersonalLineOfCredit
		)
			return true;
		else if (
			this.MemberSurveyObj.PersonlCreditCard == false &&
			this.MemberSurveyObj.HomeLoan == false &&
			this.MemberSurveyObj.AutoEveLoan == false &&
			this.MemberSurveyObj.PersonalLineOfCredit == false
		)
			return false;

		return null;
	}

	set GetLoanProductUse(value: any) {
		if (value == true) {
			this.MemberSurveyObj.PersonlCreditCard = true;
			this.MemberSurveyObj.HomeLoan = true;
			this.MemberSurveyObj.AutoEveLoan = true;
			this.MemberSurveyObj.PersonalLineOfCredit = true;
		} else {
			this.MemberSurveyObj.PersonlCreditCard = false;
			this.MemberSurveyObj.HomeLoan = false;
			this.MemberSurveyObj.AutoEveLoan = false;
			this.MemberSurveyObj.PersonalLineOfCredit = false;
		}
	}

	GetLoanProductUseClick(value: any) {
		if (this.GetLoanProductUse == value) {
			this.MemberSurveyObj.PersonlCreditCard = undefined;
			this.MemberSurveyObj.HomeLoan = undefined;
			this.MemberSurveyObj.AutoEveLoan = undefined;
			this.MemberSurveyObj.PersonalLineOfCredit = undefined;
		} else if (value == true) {
			this.MemberSurveyObj.PersonlCreditCard = true;
			this.MemberSurveyObj.HomeLoan = true;
			this.MemberSurveyObj.AutoEveLoan = true;
			this.MemberSurveyObj.PersonalLineOfCredit = true;
		} else {
			this.MemberSurveyObj.PersonlCreditCard = false;
			this.MemberSurveyObj.HomeLoan = false;
			this.MemberSurveyObj.AutoEveLoan = false;
			this.MemberSurveyObj.PersonalLineOfCredit = false;
		}
		this.saveData();
	}

	get GetLoanProductMoreInfo() {
		if (
			this.MemberSurveyObj.PersonalCreditCardMoreInfo &&
			this.MemberSurveyObj.HomeLoanMoreInfo &&
			this.MemberSurveyObj.AutoEveLoanMoreInfo &&
			this.MemberSurveyObj.PersonalLineOfCreditMoreInfo
		)
			return true;
		else if (
			this.MemberSurveyObj.PersonalCreditCardMoreInfo == false &&
			this.MemberSurveyObj.HomeLoanMoreInfo == false &&
			this.MemberSurveyObj.AutoEveLoanMoreInfo == false &&
			this.MemberSurveyObj.PersonalLineOfCreditMoreInfo == false
		)
			return false;

		return null;
	}

	set GetLoanProductMoreInfo(newValue: any) {
		if (newValue == true) {
			this.MemberSurveyObj.PersonalCreditCardMoreInfo = true;
			this.MemberSurveyObj.HomeLoanMoreInfo = true;
			this.MemberSurveyObj.AutoEveLoanMoreInfo = true;
			this.MemberSurveyObj.PersonalLineOfCreditMoreInfo = true;
		} else {
			this.MemberSurveyObj.PersonalCreditCardMoreInfo = false;
			this.MemberSurveyObj.HomeLoanMoreInfo = false;
			this.MemberSurveyObj.AutoEveLoanMoreInfo = false;
			this.MemberSurveyObj.PersonalLineOfCreditMoreInfo = false;
		}
	}

	get GetMEMBERBENEFITSPositive() {
		return this.GetMEMBERBENEFITS;
	}

	get GetMEMBERBENEFITSNegative() {
		return this.GetMEMBERBENEFITS == false;
	}

	get GetMEMBERBENEFITS() {
		if (
			this.MemberSurveyObj.ADnDBenifit &&
			this.MemberSurveyObj.FIG &&
			this.MemberSurveyObj.NoCostLastWill &&
			this.MemberSurveyObj.ChildSafeKit &&
			this.MemberSurveyObj.NeedAnalysis &&
			this.MemberSurveyObj.FinalExpense
		)
			return true;
		else if (
			this.MemberSurveyObj.ADnDBenifit == false &&
			this.MemberSurveyObj.FIG == false &&
			this.MemberSurveyObj.NoCostLastWill == false &&
			this.MemberSurveyObj.ChildSafeKit == false &&
			this.MemberSurveyObj.NeedAnalysis == false &&
			this.MemberSurveyObj.FinalExpense == false
		)
			return false;

		return null;
	}

	set GetMEMBERBENEFITS(newValue: any) {
		if (newValue == true) {
			this.MemberSurveyObj.ADnDBenifit = true;
			this.MemberSurveyObj.FIG = true;
			this.MemberSurveyObj.NoCostLastWill = true;
			this.MemberSurveyObj.ChildSafeKit = true;
			this.MemberSurveyObj.NeedAnalysis = true;
			this.MemberSurveyObj.FinalExpense = true;
		} else {
			this.MemberSurveyObj.ADnDBenifit = false;
			this.MemberSurveyObj.FIG = false;
			this.MemberSurveyObj.NoCostLastWill = false;
			this.MemberSurveyObj.ChildSafeKit = false;
			this.MemberSurveyObj.NeedAnalysis = false;
			this.MemberSurveyObj.FinalExpense = false;
		}
	}

	GetMEMBERBENEFITSClick(value: any) {
		if (this.GetMEMBERBENEFITS == value) {
			this.MemberSurveyObj.ADnDBenifit = undefined;
			this.MemberSurveyObj.FIG = undefined;
			this.MemberSurveyObj.NoCostLastWill = undefined;
			this.MemberSurveyObj.ChildSafeKit = undefined;
			this.MemberSurveyObj.NeedAnalysis = undefined;
			this.MemberSurveyObj.FinalExpense = undefined;
		} else if (value == true) {
			this.MemberSurveyObj.ADnDBenifit = true;
			this.MemberSurveyObj.FIG = true;
			this.MemberSurveyObj.NoCostLastWill = true;
			this.MemberSurveyObj.ChildSafeKit = true;
			this.MemberSurveyObj.NeedAnalysis = true;
			this.MemberSurveyObj.FinalExpense = true;
		} else {
			this.MemberSurveyObj.ADnDBenifit = false;
			this.MemberSurveyObj.FIG = false;
			this.MemberSurveyObj.NoCostLastWill = false;
			this.MemberSurveyObj.ChildSafeKit = false;
			this.MemberSurveyObj.NeedAnalysis = false;
			this.MemberSurveyObj.FinalExpense = false;
		}
		this.saveData();
	}

	get GetMEMBERBENEFITSMoreInfo() {
		if (
			this.MemberSurveyObj.ADnDBenifitIssueToday &&
			this.MemberSurveyObj.FIGIssueToday &&
			this.MemberSurveyObj.NoCostLastWillIssueToday &&
			this.MemberSurveyObj.ChildSafeKitIssueToday &&
			this.MemberSurveyObj.NeedAnalysisIssueToday &&
			this.MemberSurveyObj.FinalExpenseIssueToday
		)
			return true;
		if (
			this.MemberSurveyObj.ADnDBenifitIssueToday == false &&
			this.MemberSurveyObj.FIGIssueToday == false &&
			this.MemberSurveyObj.NoCostLastWillIssueToday == false &&
			this.MemberSurveyObj.ChildSafeKitIssueToday == false &&
			this.MemberSurveyObj.NeedAnalysisIssueToday == false &&
			this.MemberSurveyObj.FinalExpenseIssueToday
		)
			return false;

		return null;
	}

	set GetMEMBERBENEFITSMoreInfo(newValue: any) {
		if (newValue == true) {
			this.MemberSurveyObj.ADnDBenifitIssueToday = true;
			this.MemberSurveyObj.FIGIssueToday = true;
			this.MemberSurveyObj.NoCostLastWillIssueToday = true;
			this.MemberSurveyObj.ChildSafeKitIssueToday = true;
			this.MemberSurveyObj.NeedAnalysisIssueToday = true;
			this.MemberSurveyObj.FinalExpenseIssueToday = true;
		} else {
			this.MemberSurveyObj.ADnDBenifitIssueToday = false;
			this.MemberSurveyObj.FIGIssueToday = false;
			this.MemberSurveyObj.NoCostLastWillIssueToday = false;
			this.MemberSurveyObj.ChildSafeKitIssueToday = false;
			this.MemberSurveyObj.NeedAnalysisIssueToday = false;
			this.MemberSurveyObj.FinalExpenseIssueToday = false;
		}
	}

	get GetMemberSurveyObj() {
		if (
			this.MemberSurveyObj.CreditUnion == "" ||
			this.MemberSurveyObj.CreditUnion == null
		)
			return true;
	}
	saveData() {
		this.$emit("SaveMemberSurvey", this.MemberSurveyObj, false);
	}

	saveDataBTNPress() {
		this.checkFormValidation();
		if (this.isFormValidated == true && this.$refs.ReferralForm?.validate()) {
			this.MemberSurveyObj.IsFormSubmited = true;
			this.CloseFormInside(); //this.$emit("SaveMemberSurvey", this.MemberSurveyObj, true);
			this.$store.state.messageText = "Member Survey Saved";
			this.$store.state.showMessage = true;
		} else {
			setTimeout(() => {
				var svview = document.getElementsByClassName("error--text");
				if (svview != undefined && svview.length > 0) {
					{
						svview[0].scrollIntoView(true);
					}
				} else {
					var svview = document.getElementsByClassName("SponsorCheckValidate");
					if (svview != undefined && svview.length > 0) {
						{
							svview[0].scrollIntoView(true);
						}
					}
				}
			}, 500);
			this.$store.state.messageText = "Required Fields Missing";
			this.$store.state.showMessage = true;
		}
	}

	saveBTNPress() {
		this.checkFormValidation();
		if (this.isFormValidated == true && this.$refs.ReferralForm?.validate()) {
			this.CloseFormInside(); //this.$emit("SaveMemberSurvey", this.MemberSurveyObj, true);
			this.$store.state.messageText = "Member Survey Saved";
			this.$store.state.showMessage = true;
		} else {
			setTimeout(() => {
				var svview = document.getElementsByClassName("error--text");
				if (svview != undefined && svview.length > 0) {
					{
						svview[0].scrollIntoView(true);
					}
				} else {
					var svview = document.getElementsByClassName("SponsorCheckValidate");
					if (svview != undefined && svview.length > 0) {
						{
							svview[0].scrollIntoView(true);
						}
					}
				}
			}, 500);
			this.$store.state.messageText = "Required Fields Missing";
			this.$store.state.showMessage = true;
		}
	}

	checkFormValidation() {
		this.isFormValidated = true;

		if (
			this.MemberSurveyObj.MemberFirstName == null ||
			this.MemberSurveyObj.MemberFirstName.trim() == ""
		) {
			this.MemberFirstName = true;
			this.isFormValidated = false;
		} else {
			this.MemberFirstName = false;
		}

		if (
			this.MemberSurveyObj.MemberLastName == null ||
			this.MemberSurveyObj.MemberLastName.trim() == ""
		) {
			this.MemberLastName = true;
			this.isFormValidated = false;
		} else {
			this.MemberLastName = false;
		}

		if (this.MemberSurveyObj.SpouseFirstName == null)
			this.MemberSurveyObj.SpouseFirstName = "";
		if (
			this.MemberSurveyObj.AddSpouse &&
			(this.MemberSurveyObj.SpouseFirstName == null ||
				this.MemberSurveyObj.SpouseFirstName.trim() == "")
		) {
			this.SpouseFirstName = true;
			this.isFormValidated = false;
		} else {
			this.SpouseFirstName = false;
		}

		if (this.MemberSurveyObj.SpouseLastName == null)
			this.MemberSurveyObj.SpouseLastName = "";
		if (
			this.MemberSurveyObj.AddSpouse &&
			(this.MemberSurveyObj.SpouseLastName == null ||
				this.MemberSurveyObj.SpouseLastName.trim() == "")
		) {
			this.SpouseLastName = true;
			this.isFormValidated = false;
		} else {
			this.SpouseLastName = false;
		}

		if (
			this.MemberSurveyObj.OnlineBanking == null &&
			(this.MemberSurveyObj.OnlineBankingMoreInfo == null ||
				this.MemberSurveyObj.OnlineBankingMoreInfo == false)
		) {
			this.OnlineBanking = true;
			this.isFormValidated = false;
		} else this.OnlineBanking = false;

		if (
			this.MemberSurveyObj.MobileBanking == null &&
			(this.MemberSurveyObj.MobileBankingMoreInfo == null ||
				this.MemberSurveyObj.MobileBankingMoreInfo == false)
		) {
			this.MobileBanking = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.MobileBanking = false;

		if (
			this.MemberSurveyObj.EStatement == null &&
			(this.MemberSurveyObj.EStatementMoreInfo == null ||
				this.MemberSurveyObj.EStatementMoreInfo == false)
		) {
			this.EStatements = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.EStatements = false;

		if (
			this.MemberSurveyObj.BillPay == null &&
			(this.MemberSurveyObj.BillPayMoreInfo == null ||
				this.MemberSurveyObj.BillPayMoreInfo == false)
		) {
			this.BillPay = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.BillPay = false;

		if (
			this.MemberSurveyObj.BranchService == null &&
			(this.MemberSurveyObj.BranchServiceMoreinfo == null ||
				this.MemberSurveyObj.BranchServiceMoreinfo == false)
		) {
			this.BranchService = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.BranchService = false;

		if (
			this.MemberSurveyObj.CheckingAccount == null &&
			(this.MemberSurveyObj.CheckingAccountMoreinfo == null ||
				this.MemberSurveyObj.CheckingAccountMoreinfo == false)
		) {
			this.CheckingAccount = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.CheckingAccount = false;

		if (
			this.MemberSurveyObj.SavingAccount == null &&
			(this.MemberSurveyObj.SavingAccountMoreinfo == null ||
				this.MemberSurveyObj.SavingAccountMoreinfo == false)
		) {
			this.SavingsAccount = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.SavingsAccount = false;

		if (
			this.MemberSurveyObj.CertificateOfDeposit == null &&
			(this.MemberSurveyObj.CertificateOfDepositMoreInfo == null ||
				this.MemberSurveyObj.CertificateOfDepositMoreInfo == false)
		) {
			this.CertificateOfDepositAccount = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.CertificateOfDepositAccount = false;

		if (
			this.MemberSurveyObj.MoneyMarketAccount == null &&
			(this.MemberSurveyObj.MoneyMarketAccountMoreInfo == null ||
				this.MemberSurveyObj.MoneyMarketAccountMoreInfo == false)
		) {
			this.MoneyMarketAccount = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.MoneyMarketAccount = false;

		if (
			this.MemberSurveyObj.IRARetirement == null &&
			(this.MemberSurveyObj.IRARetirementMoreInfo == null ||
				this.MemberSurveyObj.IRARetirementMoreInfo == false)
		) {
			this.IRARetirementAccount = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.IRARetirementAccount = false;

		if (
			this.MemberSurveyObj.PersonlCreditCard == null &&
			(this.MemberSurveyObj.PersonalCreditCardMoreInfo == null ||
				this.MemberSurveyObj.PersonalCreditCardMoreInfo == false)
		) {
			this.PersonalCreditCard = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.PersonalCreditCard = false;

		if (
			this.MemberSurveyObj.HomeLoan == null &&
			(this.MemberSurveyObj.HomeLoanMoreInfo == null ||
				this.MemberSurveyObj.HomeLoanMoreInfo == false)
		) {
			this.HomeLoans = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.HomeLoans = false;

		if (
			this.MemberSurveyObj.AutoEveLoan == null &&
			(this.MemberSurveyObj.AutoEveLoanMoreInfo == null ||
				this.MemberSurveyObj.AutoEveLoanMoreInfo == false)
		) {
			this.AutoEV = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.AutoEV = false;

		if (
			this.MemberSurveyObj.PersonalLineOfCredit == null &&
			(this.MemberSurveyObj.PersonalLineOfCreditMoreInfo == null ||
				this.MemberSurveyObj.PersonalLineOfCreditMoreInfo == false)
		) {
			this.PersonalLineOfCredit = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.PersonalLineOfCredit = false;

		if (
			this.MemberSurveyObj.ADnDBenifit == null &&
			(this.MemberSurveyObj.ADnDBenifitIssueToday == null ||
				this.MemberSurveyObj.ADnDBenifitIssueToday == false)
		) {
			this.ADnDBenefit = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.ADnDBenefit = false;

		if (
			this.MemberSurveyObj.FIG == null &&
			(this.MemberSurveyObj.FIGIssueToday == null ||
				this.MemberSurveyObj.FIGIssueToday == false)
		) {
			this.FinancialInformationGuide = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.FinancialInformationGuide = false;

		if (
			this.MemberSurveyObj.NoCostLastWill == null &&
			(this.MemberSurveyObj.NoCostLastWillIssueToday == null ||
				this.MemberSurveyObj.NoCostLastWillIssueToday == false)
		) {
			this.NoCostLast = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.NoCostLast = false;

		if (
			this.MemberSurveyObj.ChildSafeKit == null &&
			(this.MemberSurveyObj.ChildSafeKitIssueToday == null ||
				this.MemberSurveyObj.ChildSafeKitIssueToday == false)
		) {
			this.ChildSafeIDKits = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.ChildSafeIDKits = false;

		if (
			this.MemberSurveyObj.NeedAnalysis == null &&
			(this.MemberSurveyObj.NeedAnalysisIssueToday == null ||
				this.MemberSurveyObj.NeedAnalysisIssueToday == false)
		) {
			this.NeedsAnalysisSurvey = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.NeedsAnalysisSurvey = false;

		if (
			this.MemberSurveyObj.FinalExpense == null &&
			(this.MemberSurveyObj.FinalExpenseIssueToday == null ||
				this.MemberSurveyObj.FinalExpenseIssueToday == false)
		) {
			this.FinalExpenseProgram = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else this.FinalExpenseProgram = false;

		if (this.MemberSurveyObj.PhoneNumber == null)
			this.MemberSurveyObj.PhoneNumber = "";
		if (this.MemberSurveyObj.Email == null) this.MemberSurveyObj.Email = "";

		if (
			this.MemberSurveyObj.PhoneNumber.trim() == "" &&
			this.MemberSurveyObj.Email.trim() == ""
		) {
			this.MemberEmail = true;
			if (this.isFormValidated == true) this.isFormValidated = false;
		} else {
			this.MemberEmail = false;
		}

		if (
			this.MemberSurveyObj.MemberDuration == null ||
			this.MemberSurveyObj.MemberDuration.trim() == ""
		) {
			this.MemberDurationval = true;
			this.isFormValidated = false;
		} else {
			this.MemberDurationval = false;
		}
	}

	CloseForm(Obj: any) {
		this.$emit("SaveMemberSurvey", this.MemberSurveyObj, true);
	}

	CloseFormInside() {
		this.$emit("SaveMemberSurvey", this.MemberSurveyObj, true);
	}

	get CreditFinancialPlaceholder() {
		if (
			this.MemberSurveyObj != undefined &&
			(this.MemberSurveyObj.CreditUnion == "N/A" ||
				this.MemberSurveyObj.MemberDuration ==
					"0 (benefits were extended to me)")
		)
			return this.MemberSurveyObj.CurrentFinancialInstitution;
		else return this.MemberSurveyObj.CreditUnion;
	}
}
