import { Model, Uid } from '@vuex-orm/core'

export default class PlanSummary extends Model {
    static entity = 'PlanSummary'
    static primaryKey = 'id'


    static fields() {
        return {
            id: this.attr(null),
            EmergencyRoomBenfits!: this.number(0),
            DailyHospitalBenfits!: this.number(0),
            IntensiveCareBenefits!: this.number(0),
            CauseOfDeathAmt!: this.number(0),
            AccidentAmt!: this.number(0),
            AutoAccidentAmt!: this.number(0),
            CommonCarrier: this.number(0),
            MonthlyIncome: this.number(0),
            TotalMonths: this.number(0),
            hasPreProcedureCancerPlan: this.boolean(false),
            hasLumpSumCancerPlan: this.boolean(false),
            hasRCPlan: this.boolean(false),
            BeneficiaryAmount: this.number(0)
        }
    }

    public EmergencyRoomBenfits!: number
    public DailyHospitalBenfits!: number
    public IntensiveCareBenefits!: number
    public CauseOfDeathAmt!: number
    public AccidentAmt!: number
    public AutoAccidentAmt!: number
    public CommonCarrier!: number
    public MonthlyIncome!: number
    public TotalMonths!: number
    public hasPreProcedureCancerPlan!: boolean;
    public hasLumpSumCancerPlan!: boolean;
    public hasRCPlan!: boolean;
    public BeneficiaryAmount!: number;
}