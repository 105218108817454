

















































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import moment from "moment";
import { getPersistedState } from "../../helpers/StateMapper";
import { IndexDBConstants } from "../../constants/IndexDBConstants";
import {
  FormPostRequestWithAuthorization,
  PostRequestWithAuthorization,
} from "../../helpers/APIHelper";
import { ApiEndPointConstants } from "../../constants/ApiEndPointConstants";
import LoggingHelper from "../../helpers/LoggingHelper";

@Component({})
export default class HelpPopup extends Vue {
  data() {
    return {
      UserName: "",
      Description: "",
      FileData: {},
      FileName: "",
      DisableSave: false,
      isLoading: false,
      monthlytab: "",
      UserRoleDialog: false,
      UserLogText: "",
      UserLogTextHtml: "",
      isLoadingUserLog: false,
      daysLogMaintained: "",
      helptab: 0,
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      },
      lastDaysItems: [],
      SelectedDate: {},
    };
  }

  @Prop()
  private PopupVisibility: boolean = false;

  created() {
    this.FetchPresentationDataAndSaveAsFile();
    this.$data.daysLogMaintained = process.env?.VUE_APP_LOG_DURATION;
  }

  @Watch("PopupVisibility")
  OnPopupVisibilityChange(newValue: boolean, oldValye: boolean) {
    if (newValue == true) this.$data.Description = "";
  }

  FetchPresentationDataAndSaveAsFile() {
    getPersistedState(IndexDBConstants.PresentationList).then((res: any) => {
      if (res) {
        this.$data.DisableSave = false;
        const dataa = JSON.stringify(res);
        this.$data.FileData = dataa; //new Blob([dataa], { type: "text/plain" });
        this.$data.FileName = "log-" + new Date();
      } else {
        this.$data.DisableSave = true;
        this.$data.FileData = "[]";
      }
    });
  }

  ShowUserLogDialog() {
    this.$data.UserRoleDialog = true;
    //this.FetchUserLogs();
  }

  SendEmail() {
    var objectData: any = {};
    objectData.FileData = this.$data.FileData;
    objectData.Description = this.$data.Description;
    this.$data.isLoading = true;
    PostRequestWithAuthorization(
      ApiEndPointConstants.HelpEndpoint,
      [],
      objectData
    )
      .then((res: any) => {
        this.$data.isLoading = false;
        if (res != undefined) {
          if ((res.Result = true)) {
            this.$store.state.messageText = "Log submitted Successfully";
            this.$store.state.showMessage = true;
            this.$emit("ClosePopup", null);
          }
        }
      })
      .catch((ex: any) => {
        this.$data.isLoading = false;
      });
  }

  PopulateLastParticularDays() {
    var maxLogDays = process.env?.VUE_APP_LOG_DURATION;
    for (var i = 0; i < maxLogDays; i++) {
      var date = new Date();
      date.setDate(date.getDate() - i);
      date.setHours(0, 0, 0, 0);
      this.$data.lastDaysItems.push({
        Date: date,
        DateString: moment(date).format("MM/DD/YYYY"),
      });
    }
    this.$data.SelectedDate = this.$data.lastDaysItems[0];
    this.OnDateChange(this.$data.SelectedDate);
  }

  SendLogsEmail() {
    var objectData: any = {};
    objectData.FileData = this.$data.UserLogText;
    objectData.Description = "";
    objectData.isUserEventLogFile = true;
    this.$data.isLoadingUserLog = true;
    PostRequestWithAuthorization(
      ApiEndPointConstants.HelpEndpoint,
      [],
      objectData
    )
      .then((res: any) => {
        this.$data.isLoadingUserLog = false;
        if (res != undefined) {
          if ((res.Result = true)) {
            this.$store.state.messageText = "User Log submitted Successfully";
            this.$store.state.showMessage = true;
            //this.$data.UserRoleDialog = false;
            this.$emit("ClosePopup", null);
          }
        }
      })
      .catch((ex: any) => {
        this.$data.isLoading = false;
      });
  }

  async FetchUserLogs(startTime: Date, endTime: Date) {
    var compLogs: any = await LoggingHelper.getInstance().GetAllUserLogs();

    var completeLogTextHtml: any = "";
    var completeLogText: any = "";
    if (compLogs != undefined) {
      compLogs = compLogs.filter((x: any) => {
        var date = new Date(x.Time);
        if (date > startTime && date < endTime) return x;
      });
      compLogs = compLogs.sort(this.compareLog);
      compLogs.forEach((element: any) => {
        var date = moment(new Date(element.Time)).format("ddd MM/DD/YYYY");

        completeLogTextHtml +=
          "<li>" + date + "\t\t <b>" + element.Data + "</b></li>";
        completeLogText += element.Time + "\t\t" + element.Data + "\n";
      });
    }
    console.log(completeLogTextHtml);
    this.$data.UserLogTextHtml = completeLogTextHtml;
    this.$data.UserLogText = completeLogText;
  }

  async DeleteAllUserLogs() {
    await LoggingHelper.getInstance().DeleteAllLogs();
    this.$store.state.messageText = "User Log Deleted Successfully";
    this.$store.state.showMessage = true;
    var date = new Date(this.$data.lastDaysItems[0].Date);
    var enddate = new Date(this.$data.lastDaysItems[0].Date);
    enddate.setDate(enddate.getDate() + 1);
    await this.FetchUserLogs(date, enddate);
  }

  compareLog(a: any, b: any) {
    if (a.Time < b.Time) return 1;
    if (a.Time > b.Time) return -1;
    return 0;
  }

  TabChange(evt: any) {
    if (this.$data.helptab == 1) this.PopulateLastParticularDays();
  }

  async OnDateChange(evt: any) {
    var date = new Date(evt.Date);
    var enddate = new Date(evt.Date);
    enddate.setDate(enddate.getDate() + 1);
    await this.FetchUserLogs(date, enddate);
  }

  ClosePopup() {
    this.$emit("ClosePopup", null);
  }
}
