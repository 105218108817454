import { Model } from '@vuex-orm/core'
import { EntityHelper } from '@/helpers/EntityHelper'
import Presentation from './Presentation'

export default class PremiumPlanEntity extends Model {
  static entity = 'PremiumPlanEntity'
  static primaryKey = 'Id'

  static fields() {
    return {
      Id: this.uid(() => EntityHelper.uuid()),
      PremiumTypeId: this.number(0),
      FinalDailyPremium: this.number(0),
      FinalMonthlyPremium: this.number(0),
      FinalYearlyPremium: this.number(0),
      UsedDailyPremium: this.number(0),
      UsedMonthlyPremium: this.number(0),
      UsedYearlyPremium: this.number(0),
      RemainingDailyPremium: this.number(0),
      RemainingMonthlyPremium: this.number(0),
      RemainingYearlyPremium: this.number(0),
      HPPremiumType: this.number(0),
      MonthlyPremiumType: this.number(0),
      CustomHPPremium: this.string(0),
      CustomMonthlyPremium: this.string(0)
    }
  }

  public PremiumTypeId!: number;

  public FinalDailyPremium!: number;
  public FinalMonthlyPremium!: number;
  public FinalYearlyPremium!: number;

  public UsedDailyPremium!: number;
  public UsedMonthlyPremium!: number;
  public UsedYearlyPremium!: number;

  public RemainingDailyPremium!: number;
  public RemainingMonthlyPremium!: number;
  public RemainingYearlyPremium!: number;

  public HPPremiumType!: Number;
  public MonthlyPremiumType!: number;

  public CustomHPPremium!: string;
  public CustomMonthlyPremium!: string;

}