import { EntityHelper } from '@/helpers/EntityHelper';
import { Model } from '@vuex-orm/core';
import FamilyCareSurvey from './FamilyCareSurvey';

export default class FamilyCareSurveyInsurance extends Model {
	static entity = 'FamilyCareSurveyProperty';
	static primaryKey = 'Id';
	static fields() {
		return {
			Id: this.uid(() => EntityHelper.uuid()),
			InsuranceType: this.string('').nullable(),
			IsPrimary: this.boolean(false),
			InsuranceThroughWork: this.boolean(false),
			InsuranceOutsideWork: this.boolean(false),
			InsuranceAmount: this.string(null).nullable(),
		};
	}
    public Id!: string
	public InsuranceType!: string
	public IsPrimary: boolean = false
	public InsuranceThroughWork: boolean = false
	public InsuranceOutsideWork: boolean = false
	public InsuranceAmount!: string 
}
