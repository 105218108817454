export class ApiEndPointConstants {
	static SyncVersionEndpoint: string = 'HpPro/GetSyncVersion';
	static PresentationTypesEndpoint: string = 'HpPro/GetAllPresentationTypesVETERAN';
	static AssociateCodedHeirarchyEndpoint: string = 'Associate/GetCompleteAssociateCodedHierarchyWithTerm';
	static MaterialsEndpoint: string = 'HpPro/GetAllMaterials';
	static PresentationGroupsEndpoint: string = 'HpPro/GetAllPresentationGroups';
	static AppInitialDataEndpoint: string = 'HpProUser/GetAppInitialData';
	static MedicalQuestionsEndpoint: string = 'HpPro/GetAllMedicalQuestions';
	static ReportOptionsEndpoint: string = 'HpPro/GetAllReportOptions';
	static MilestonesOptionEndpoint: string = 'HpPro/GetAllMilestonesOption';
	static SyncPresentationEndpoint: string = 'Presentation/SyncPresentation';
	static AddReferralEndpoint: string = 'Presentation/AddReferralLeads';
	static CurrentUserInfoEndpoint: string = 'User/GetCurrentUserInfo';
	static CurrentUserProfileImageEndPoint: string = 'HpProUser/GetCurrentUserProfileImage';
	static HpProCurrentUserInfoEndpoint: string = 'HpProUser/GetCurrentUserInfo';
	static FlipbookGroupEndpoint: string = 'HpPro/GetFlipbookPageByGroupId';
	static GroupLetterPDFUrlEndPoint: string = 'HpPro/GetGroupLetterPDFUrl';
	static GetTrainingPDFEndPoint: string = 'HpPro/GetFileFromS3Bucket';
	static UploadImageEndpoint: string = 'HpProUser/UploadImage';
	static UserAccessCheckEndPoint: string = 'HpProUser/CheckUserAccess';
	static CreditUnionEndpoint: string = 'HpPro/GetCreditUnionValue';
	static StateListEndpoint: string = 'HpPro/GetBenefitSummaryRatesList';
	static ProductsListEndpoint: string = 'HpPro/GetProductsList';
	static RatesListEndpoint: string = 'HpPro/GetRatesList';
	static BenefitSummaryRatesEndpoint: string = 'HpPro/GetBenefitSummaryRatesList';
	static UWQuestionaireTemplatesEndpoint: string = 'HpPro/GetAllUWQuestionaireTemplates';
	static GeneralMedicalQuestionEndpoint: string = 'HpPro/GetAllGeneralQuestions';
	static ReferralGroupCodeEndpoint: string = 'HpPro/GetReferralGroups';
	static EmailSponsorshipEndpoint: string = 'Presentation/SendSponsorshipEmail';
	static ClientOrGroupSMSEndpoint: string = 'HpProMessaging/SendClientOrGroupSMS';
	static ClientOrGroupMMSEndpoint: string = 'HpProMessaging/SendClientOrGroupMMS';
	static ClientOrGroupEmailEndpoint: string = 'HpProMessaging/SendClientOrGroupEmail';
	static BenefitsAndDocumentEmailEndpoint: string = 'Presentation/SendBenefitsAndDocumentEmail';

	static UserListEndpoint: string = 'User/GetUserListWithCaching';
	static HelpEndpoint: string = 'Presentation/SendLog';
	static SaveDefaultChartEndPoint: string = 'Presentation/InsertChartData';
	static GetClientNamesEndPoint: string = 'Lead/GetFilteredAssignedLeads';
	static GetLeadDetailEndPoint: string = 'Lead/GetLeadDetailForHpPro';
	static GetInfoCertificateUrlEndPoint: string = 'LeadCertificate/OpenLeadCertificate';
	static PostADNDErrorLogEndPoint: string = 'Presentation/InsertADNDLog';
	static GetCurrentAppVersionEndPoint: string = 'HpPro/GetHpProCurrentVersion';

	static RequiredPresentationStepConfigEndpoint: string = 'HpPro/GetAllRequiredPresentationStepConfig';
	static AddGamePlanEndPoint: string = 'GamePlan/AddGamePlan';
	static UpdateGamePlanEndPoint: string = 'GamePlan/UpdateGamePlan';
	static GetGamePlansByYearEndPoint: string = 'GamePlan/GetGamePlansByYear';
	static GetPresentationDetailByIdEndPoint: string = 'Presentation/GetPresentationDetailById';
}
