var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"childrengrid"},[_c('v-card-title',{staticClass:"py-0 borderBottom-1 border-secondaryLight"},[_c('v-row',{staticClass:"align-center my-0"},[_c('v-col',{staticClass:"accent--text lg--text fw-600"},[_vm._v(_vm._s(_vm.$t("NeedAnalysis.AddChildren")))])],1)],1),_c('vue-scroll',[_c('v-card-text',{staticClass:"dailogcontainer"},_vm._l((_vm.lstChildren),function(item,index){return _c('v-row',{key:index,staticClass:"align-center my-0"},[(index < 5)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"align-center mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fw-600 sm--text text-sm-right pr-2",attrs:{"cols":"12","sm":"auto"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.truncateProperties},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.FirstName"))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.FirstName")))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-text-field',{staticClass:"materialgroup min-wd-11vw max-wd-11vw",class:'children_FName_' + (index + 1),attrs:{"outlined":"","dense":"","flat":"","maxlength":"35","hide-details":"","solo":""},model:{value:(item.FirstName),callback:function ($$v) {_vm.$set(item, "FirstName", $$v)},expression:"item.FirstName"}})],1)],1)],1):_vm._e(),(index < 5)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"align-center mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fw-600 sm--text text-sm-right pr-2",attrs:{"cols":"12","sm":"auto"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.truncateProperties},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.LastName"))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.LastName")))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-text-field',{staticClass:"materialgroup min-wd-11vw max-wd-11vw",class:'children_LName_' + (index + 1),attrs:{"outlined":"","dense":"","flat":"","hide-details":"","solo":"","maxlength":"35"},model:{value:(item.LastName),callback:function ($$v) {_vm.$set(item, "LastName", $$v)},expression:"item.LastName"}})],1)],1)],1):_vm._e(),(index < 5)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"align-center mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fw-600 sm--text text-sm-right pr-2",attrs:{"cols":"12","sm":"auto"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.truncateProperties},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.Dob"))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.Dob")))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"materialgroup input-center min-wd-9vw max-wd-9vw",class:'children_DOB_' + (index + 1),attrs:{"outlined":"","placeholder":_vm.$t('NeedAnalysis.MMDDYY'),"dense":"","flat":"","hide-details":"","solo":""},on:{"change":function($event){return _vm.DOBChange(item)}},model:{value:(item.DateOfBirth),callback:function ($$v) {_vm.$set(item, "DateOfBirth", $$v)},expression:"item.DateOfBirth"}},'v-text-field',_vm.attrs,false),_vm.on))],1)],1)],1):_vm._e(),(index < 5)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"align-center mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fw-600 sm--text text-sm-right pr-2",attrs:{"cols":"12","sm":"auto"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.truncateProperties},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.Gender"))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("NeedAnalysis.Gender")))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-select',{staticClass:"materialgroup min-wd-9vw max-wd-9vw",class:'children_Gender_' + (index + 1),attrs:{"outlined":"","dense":"","flat":"","hide-details":"","items":_vm.gender,"solo":""},model:{value:(item.Gender),callback:function ($$v) {_vm.$set(item, "Gender", $$v)},expression:"item.Gender"}})],1)],1)],1):_vm._e(),(index < 5)?_c('v-col',{attrs:{"cols":"auto"}},[(index == _vm.lstChildren.length - 1 && _vm.lstChildren.length <= 4)?_c('v-btn',{class:'children_Add_' + (index + 1),attrs:{"small":"","icon":"","color":"accentDark","ripple":false},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{staticClass:"addlisence"},[_vm._v("$plus")])],1):_c('v-btn',{class:'children_Remove_' + (index + 1),attrs:{"icon":"","color":"errorLight","small":"","ripple":false},on:{"click":function($event){return _vm.remove(index)}}},[_c('v-icon',[_vm._v("$cross")])],1)],1):_vm._e()],1)}),1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"mx-auto pt-0",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',[_c('v-btn',{staticClass:"sm--text ChildrenCancel",attrs:{"block":"","small":"","dense":"","outlined":"","color":"accent","text":""},on:{"click":function($event){return _vm.$emit('cancelchildren')}}},[_vm._v(_vm._s(_vm.$t("NeedAnalysis.Cancel")))])],1),_c('v-col',[_c('v-btn',{staticClass:"white--text sm--text ChildrenSave",attrs:{"block":"","small":"","dense":"","filled":"","color":"accent"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("NeedAnalysis.Save")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }