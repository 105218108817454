var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"width":"max-content"},attrs:{"width":"80vw","scrollable":""},on:{"click:outside":function($event){return _vm.cancel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.isEscapePressed()}},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-1 ln-normal px-8 borderBottom-1 border-secondaryLight"},[_c('v-row',{staticClass:"align-center my-0"},[_c('v-col',{staticClass:"lg--text fw-600"},[_vm._v("Product Summary")]),_c('v-col',{staticClass:"col-auto cursor-pointer"},[_c('v-icon',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("$cross")])],1)],1)],1),_c('v-card-text',{staticClass:"px-8 browserscroll"},[(_vm.PresentationData.BenefitSummary)?_c('v-row',[_c('v-col',{staticClass:"pt-3 d-flex flex-column min-h-100p",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"my-1 flex-0"},[(_vm.PresentationData.Presentation.PresentedTo)?_c('v-col',{staticClass:"text-center xxlg--text fw-700 black--text pb-0 text-capitalize text-uppercase"},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : "")+"   ")])]):_vm._e()],1),_vm._l((_vm.PresentationData.BenefitSummary
							.PrimaryProducts),function(item,index){return _c('v-row',{key:index,staticClass:"secondaryxxLight align-center ma-0 mb-2"},[_c('v-col',{staticClass:"fw-700 planpadding md--text black--text pr-0",attrs:{"cols":"4"}},[_vm._v(_vm._s(item.Plan.PGName)+" "),(item.Plan.PresentAs != '')?_c('div',{staticClass:"xss--text"},[_vm._v(" "+_vm._s(item.Plan.PresentAs)+" ")]):_vm._e()]),_c('v-col',{staticClass:"sm--text planpadding black--text pr-0",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.GetItemCoverage(item))+" ")]),_c('v-col',{staticClass:"sm--text planpadding black--text pr-0",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(item.Plan != undefined && item.Plan.Option != null ? _vm.GetCamelizePlanOption(item.Plan.Option) : "")+" ")]),_c('v-col',{staticClass:"sm--text planpadding black--text pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.MBD != undefined && item.MBD != "" ? ("$" + (_vm.ConvertintoAmountFormatUI(item.MBD)) + " MBD") : "")+" ")])],1)})],2),_c('v-col',{staticClass:"pt-3 d-flex flex-column min-h-100p",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"my-1 flex-0"},[(
								_vm.PresentationData.Presentation.PresentedTo &&
								_vm.PresentationData.Presentation.PresentedTo.Spouse
							)?_c('v-col',{staticClass:"text-center xxlg--text fw-700 black--text pb-0 text-truncate text-capitalize text-uppercase"},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : "")+" ")])]):_vm._e()],1),_vm._l((_vm.PresentationData.BenefitSummary
							.SpouseProducts),function(item,index){return _c('v-row',{key:index,staticClass:"secondaryxxLight align-center ma-0 mb-2"},[_c('v-col',{staticClass:"fw-700 planpadding md--text black--text pr-0",attrs:{"cols":"4"}},[_vm._v(_vm._s(item.Plan.PGName)+" "),(item.Plan.PresentAs != '')?_c('div',{staticClass:"xss--text"},[_vm._v(" "+_vm._s(item.Plan.PresentAs)+" ")]):_vm._e()]),_c('v-col',{staticClass:"sm--text planpadding black--text pr-0",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.GetItemCoverage(item))+" ")]),_c('v-col',{staticClass:"sm--text planpadding black--text pr-0",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(item.Plan != undefined && item.Plan.Option != null ? _vm.GetCamelizePlanOption(item.Plan.Option) : "")+" ")]),_c('v-col',{staticClass:"sm--text planpadding black--text pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.MBD != undefined && item.MBD != "" ? ("$" + (_vm.ConvertintoAmountFormatUI(item.MBD)) + " MBD") : "")+" ")])],1)})],2)],1):_vm._e(),(_vm.PresentationData.BenefitSummary)?_c('v-row',{staticClass:"align-center justify-center mt-0"},[_c('v-col',{staticClass:"xlg--text black--text text-center"},[_c('v-row',{staticClass:"align-center justify-center xxxlg--text fw-600 accent--text",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-6 pt-0",attrs:{"cols":"auto"}},[_vm._v(" TOTAL MBD: ")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_vm._v(" $"),(
									_vm.PresentationData.MBD != undefined &&
									_vm.PresentationData.MBD != ''
								)?_c('span',[_vm._v(_vm._s(_vm.ConvertintoAmountFormatUI(_vm.PresentationData.MBD)))]):_c('span',[_vm._v("0.00")])])],1)],1),_c('v-col',{staticClass:"xlg--text black--text text-center"},[_c('v-row',{staticClass:"align-center justify-center xxxlg--text fw-600 accent--text",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-6 pt-0",attrs:{"cols":"auto"}},[_vm._v(" TOTAL AP: ")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_vm._v(" $"),(
									_vm.PresentationData.TotalAP != undefined &&
									_vm.PresentationData.TotalAP != ''
								)?_c('span',[_vm._v(_vm._s(_vm.ConvertintoAmountFormatUI(_vm.PresentationData.TotalAP)))]):_c('span',[_vm._v("0.00")])])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }