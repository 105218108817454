






























































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Children from "@/entities/Children";
import moment from "moment";
import FamilyCareSurveyProperty from "@/entities/FamilyCareSurveyProperty";
import {
	ConvertIntoAmountFormatWithDecimal,
	GetDecimalvaluefromString,
} from "@/helpers/CalculationHelper";
import Expenses from "@/entities/Expenses";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class AddFamilyCareSurveyPropertyComponent extends Vue {
	private person: any;
	private gender = listofGenderTypes;
	private addProperties: boolean = false;
	private basicDetailRequired: boolean = false;
	private isDialogOpen: boolean = false;
	private totalExpenses: string = "";

	@Prop({ default: "Input Property" })
	lstProperties!: Array<FamilyCareSurveyProperty>;

	@Prop({ default: "Input Property" })
	truncateProperties!: boolean;

	@Prop({ default: false })
	IsVeteranAssessment!: boolean;

	@Prop({ default: "Input Property" })
	lstExpenses!: Expenses;

	@Prop({ default: "0.00" })
	monthlyIncome!: string;

	data() {
		return {
			componentKey: 0,
			lstProperties: this.lstProperties,
			lstExpenses: this.lstExpenses,
			Required: [(v: any) => !!v || "Field is required"],
			RequiredNumber: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			],
			RequiredInterestNumber: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0 && parseFloat(v) <= 100.0)
						return true;
					else return false;
				},
			],
		};
	}

	$refs!: {
		ReferralForm: any;
	};
	created() {
		this.isDialogOpen = true;
		this.basicDetailRequired = true;
		// this.lstProperties.forEach((element) => {
		// 	this.DOBChange(element);
		// });
	}

	add() {
		this.lstProperties.push(new FamilyCareSurveyProperty());
		// setTimeout(() => {
		// 	this.lstProperties.push(new FamilyCareSurveyProperty());
		// }, 50);
		if (this.$data.lstProperties.length < this.lstProperties.length) {
			this.$data.lstProperties.push(new FamilyCareSurveyProperty());
		}
	}

	DOBChange(item: any) {
		if (item.DateOfBirth != undefined && item.DateOfBirth != "") {
			item.DOBPicker = moment(item.DateOfBirth).format("YYYY-MM-DD");
		}
	}

	saveDOB(date: any, item: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MM/DD/YYYY") : "";
		item.DateOfBirth = tempdate;
		item.DOBPopup = false;
	}

	save() {
		let exponseDisposalObj = Object.assign({
			TotalExpense: this.totalExpenses,
			ExpensesList: this.lstExpenses,
		});
		this.$emit("saveExpenses", exponseDisposalObj);
	}
	cancel() {
		let exponseDisposalObj = Object.assign({
			TotalExpense: this.totalExpenses,
			ExpensesList: this.lstExpenses,
		});
		this.$emit("cancelExpenses", exponseDisposalObj);
	}
	clearAll() {
		this.lstExpenses = new Expenses();
		this.$data.lstExpenses = this.lstExpenses;
	}

	isEscapePressed() {
		this.isDialogOpen = false;
		let exponseDisposalObj = Object.assign({
			TotalExpense: this.totalExpenses,
			ExpensesList: this.lstExpenses,
		});
		setTimeout(() => {
			this.$emit("cancelExpenses", exponseDisposalObj);
		}, 500);
	}

	remove(id: number) {
		this.lstProperties.splice(id, 1);
		// if (typeof this.$data.lstProperties[id] === "undefined") {
		// 	// does not exist
		// } else {
		// 	this.$data.lstProperties.splice(id, 1);
		// }
		// setTimeout(() => {
		// 	this.lstProperties.splice(id, 1);
		// }, 50);
	}

	PrecentageAmountFieldBlur(evt: any, valuae: any) {
		let value = evt.target.value;
		if (value == "") {
			this.ApplyValuebyFieldString(valuae, "0%");
		} else
			this.ApplyValuebyFieldString(
				valuae,
				Number.parseFloat(evt.target.value).toFixed(2) + "%"
			);
	}

	PrecentageAmountFieldFocus(evt: any, index: any, valuae: any) {
		let value = evt.target.value;
		if (value != undefined)
			this.ApplyValuebyFieldString(valuae, value.replace("%", ""));
		setTimeout(() => {
			evt.target.select();
		}, 50);
	}
	isInterestNumber(evt: any) {
		var isFieldSelected;
		var field = document.getElementById(
			"InterestRateField"
		) as HTMLInputElement | null;
		if (field != undefined) {
			if (typeof field.selectionStart == "number") {
				isFieldSelected =
					field.selectionStart == 0 && field.selectionEnd == field.value.length;
			}
		}
		if (!isFieldSelected) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46
			) {
				evt.preventDefault();
			} else {
				var resValue = evt.target.value + evt.key;
				if (resValue <= 100) return true;
				else {
					evt.preventDefault();
				}
				// if((this.Person.MortgageInterestRate<=99.9 && this.Person.MortgageInterestRate*10 < 100) || evt.key == '.')
				//   return true;
				// else
				//   evt.preventDefault();
			}
		}
	}
	isNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	InputAmountField(evt: any) {
		if (
			evt.target != undefined &&
			evt.target.value != undefined &&
			evt.target.value != ""
		) {
			if (evt.target.value != "0") {
				var previusSelectionIndex = evt.target.selectionStart;
				evt.target.value = ConvertIntoAmountFormatWithDecimal(evt.target.value);
				var newSelectionIndex = evt.target.selectionStart;
				if (newSelectionIndex > previusSelectionIndex + 1) {
					evt.target.setSelectionRange(
						previusSelectionIndex,
						previusSelectionIndex
					);
				}
			}
		}
	}

	ApplyValuebyFieldString(fieldString: string, value: string) {
		var properties = fieldString.split(".");
		var setPropertyvalue: any = this.$data;
		if (properties != undefined && properties.length > 0) {
			var i = 1;
			properties.forEach((element: any) => {
				if (i == properties.length) {
					setPropertyvalue[element] = value;
				} else {
					setPropertyvalue = setPropertyvalue[element];
				}
				i++;
			});
		}
	}

	AmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}

		if (value == "") {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		} else {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal(
					Number(value.replaceAll(",", "")).toFixed(2),
					true,
					true
				)
			);
		}
		//this.forceRerender();
	}

	// forceRerender() {
	// 	this.$data.componentKey += 1;
	// }
	get getDiscretionaryAmount() {
		let expenseAmount = 0;
		let discretionaryValue;
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.HousePayment);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Utilities);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.CableInternet);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.CellPhone);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.CarPayment);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.CarInsurance);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Gas);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Travel);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.MinDebtPay);
		expenseAmount += GetDecimalvaluefromString(
			this.lstExpenses.FoodEntertainment
		);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Insurance);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.ChildCare);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Savings);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Retirement);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Misc1);
		expenseAmount += GetDecimalvaluefromString(this.lstExpenses.Misc2);
		discretionaryValue =
			GetDecimalvaluefromString(this.monthlyIncome) - expenseAmount;
		if (discretionaryValue < 0) {
			discretionaryValue = 0;
		}
		this.totalExpenses = ConvertIntoAmountFormatWithDecimal(
			expenseAmount.toFixed(2).toString()
		);
		return ConvertIntoAmountFormatWithDecimal(
			discretionaryValue.toFixed(2).toString()
		);
	}
}
