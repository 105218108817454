export default class GeneralConstants {

    static PresentationTypesPages = [
    "NeedAnalysis"
    , "PlanGenerator"
    , "BenefitsSummary"
    , "PresentPlan"
    , "FinishPresentation"
    , "No Cost Benefits"
    , "Report Form"];

}