



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import MenuComponent from "../components/menu.vue";
import AddChildrenComponent from "../components/popups/AddChildren.vue";
import UWPopup from "../components/popups/UWPopup.vue";
import MedicalQuestions from "../components/popups/MedicalQuestions.vue";
import PrimaryPerson from "../entities/PrimaryPerson";
import Spouse from "../entities/Spouse";
import Children from "@/entities/Children";
import Presentation from "../entities/Presentation";
import AddFamilyCareInsuranceComponent from "../components/popups/AddFamilyCareSurveyInsurance.vue";
import AddFamilyCareSurveyPropertyComponent from "../components/popups/AddFamilyCareSurveyProperty.vue";
import AddExpenseperMonthComponent from "../components/popups/AddExpenseperMonth.vue";

import GenericKeyPair from "../models/GenericKeyPair";
import { getPersistedState, setPersistedState } from "../helpers/StateMapper";
import deleteIconVue from "@/components/icons/delete-icon.vue";
import PremiumPlan from "../models/PremiumPlan";
import PremiumPlanEntity from "../entities/PremiumPlanEntity";
import {
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
	RoundUpperDecimal,
} from "../helpers/CalculationHelper";
import moment from "moment";
import { EntityHelper } from "@/helpers/EntityHelper";
import i18n from "../plugins/i18n";
import { APIService } from "@/services/APIService";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import { PresentationType } from "@/enumerations/PresentationType";
import LoggingHelper from "@/helpers/LoggingHelper";
import { EventBusLayout } from "../bus/EventBusLayout";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import {
	HpProHouseHoldType,
	HpProHouseHoldTypeDiscription,
} from "@/enumerations/HpProHouseHoldType";
import { GetDecimalvaluefromString } from "@/helpers/CalculationHelper";
import FamilyCareSurveyInsurance from "@/entities/FamilyCareSurveyInsurance";
import FamilyCareSurveyProperty from "@/entities/FamilyCareSurveyProperty";
import Expenses from "@/entities/Expenses";

let listofGenderTypes = require("../assets/pre-fill-data/gender-types.json");
let listofpremiumApproachTypes = require("../assets/pre-fill-data/premium-approach-type.json");

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		AddChildrenComponent,
		UWPopup,
		ReferralPopup,
		MedicalQuestions,
		AddFamilyCareSurveyPropertyComponent,
		AddFamilyCareInsuranceComponent,
		AddExpenseperMonthComponent,
	},
})
export default class NeedAnalysis extends Vue {
	private addInsurance: boolean = false;
	private addProperties: boolean = false;
	private addExpense: boolean = false;
	private Person: any;
	private Presentation: any;
	private GenderTypes = listofGenderTypes;
	private PremiumApproaches = listofpremiumApproachTypes;
	private valid: boolean = false;
	private medicalvalid: boolean = false;
	private personalvalid: boolean = false;
	private incomevalid: boolean = false;
	private addchildren: boolean = false;
	private underwriting: boolean = false;
	private notesdialog: boolean = false;
	private coverageDialog: boolean = false;
	private personActivePicker: any = null;
	private spouseActivePicker: any = null;
	private ListOfWagesTypes: any = require("../assets/pre-fill-data/wage-types.json");
	private personDOBPopup: boolean = false;
	private spouseDOBPopup: boolean = false;
	private Childrens: Array<Children> = [];
	private TempChildrens: Array<Children> = [];
	private Notes: string = "";
	private selectedUWType: string = "";
	private pageLoadedTime: any;
	private SpecificMedicalQuestionList: any = [];
	private SelectedWageType = this.ListOfWagesTypes[0];
	private isSuperCombo: boolean = true;
	private isDollarADay: boolean = false;
	private isNeedPremium: boolean = false;
	private validationChecked: boolean = false;
	private CompleteDollarADayOption = require("../assets/pre-fill-data/dollar-a-day-options.json");
	private DollarADayOptions: any = [];
	private SpouseDollarADayOptions: any = [];
	private NeedOptions = require("../assets/pre-fill-data/need-options.json");
	private medicalQuestionList: any = null;
	private numeral = require("numeral");
	private primaryWageValue: any = null;
	private spouseWageValue: any = null;
	private basicDetailRequired: boolean = false;
	private ssprogram: boolean = false;
	private statesList: any = [];
	private somemodal: boolean = false;
	private ResetReferrals: boolean = false;
	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;
	private SelectedPresentationType: any = {};
	private viewRecommendation: boolean = false;
	private showsurvey: boolean = false;
	private showincome: boolean = false;
	private SurveyLoader: boolean = false;
	private IncomeLoader: boolean = false;
	private RouteWithoutOpeningSurvey: boolean = false;
	private RouteWithoutOpeningIncome: boolean = false;
	private listofHouseHoldType: any = [];

	private medicalQuestionPopup: boolean = false;
	private IsNeedAnalysisUpdated: boolean = false;
	private PrimaryInsuranceThroughWorkList: Array<FamilyCareSurveyInsurance> =
		[];
	private TempPrimaryInsuranceThroughWorkList: Array<FamilyCareSurveyInsurance> =
		[];
	private PrimaryInsuranceOutsideWorkList: Array<FamilyCareSurveyInsurance> =
		[];
	private TempPrimaryInsuranceOutsideWorkList: Array<FamilyCareSurveyInsurance> =
		[];
	private SpouseInsuranceThroughWorkList: Array<FamilyCareSurveyInsurance> = [];
	private TempSpouseInsuranceThroughWorkList: Array<FamilyCareSurveyInsurance> =
		[];
	private SpouseInsuranceOutsideWorkList: Array<FamilyCareSurveyInsurance> = [];
	private TempSpouseInsuranceOutsideWorkList: Array<FamilyCareSurveyInsurance> =
		[];
	private NeedAnalysisPropertiesList: Array<FamilyCareSurveyProperty> = [];
	private TempNeedAnalysisPropertiesList: Array<FamilyCareSurveyProperty> = [];
	private isRentSelected: boolean = false;
	private NeedAnalysisExpenses: any;
	private TempNeedAnalysisExpenses: any;
	private CombinedMonthlyIncome: string = "";
	private ExpenseList: any = {};

	$refs!: {
		emailfield: any;
		phonefield: any;
		personDOB: any;
		spouseDOB: any;
		naForm: any;
		dobTextField: any;
		QuestionsDiv: any;
		PersonalInfoDiv: any;
		IncomeDiv: any;
	};

	// Hard coded Values
	private CombinedPremiumAmount: number = 0;
	private ExistingLifetIns: string = "0";
	private RecommendedLifeIns: string = "0";
	private RemainingLifeIns: string = "0";
	private AnnualIncome: string = "0";
	private changeWageType: boolean = false;
	private browserCloseEvent: boolean = false;

	spouseMedicalQuestionChanged(question: any) {
		this.NeedAnalysisFieldsUpdate();
		if (!question.QuestionEn.includes("TOBACCO")) return;
		this.Person.Spouse.AnyTobaccoUse = question.SpouseValue;
		this.Presentation.isPlanCalculationNeeded = true;
	}

	primaryMedicalQuestionChanged(question: any) {
		this.NeedAnalysisFieldsUpdate();
		if (!question.QuestionEn.includes("TOBACCO")) return;
		this.Person.AnyTobaccoUse = question.PrimaryValue;
		this.Presentation.isPlanCalculationNeeded = true;
	}

	async FetchAndLoadValidations() {
		var presentationTypes: any = await getPersistedState(
			IndexDBConstants.PresentationTypesKey
		);
		if (presentationTypes != null && presentationTypes.length > 0) {
			let presType = presentationTypes.filter((x: any) => {
				if (x.Id == this.Presentation.PresentationTypeId) return x;
			});
			if (presType != null && presType.length > 0) {
				this.basicDetailRequired = presType[0].NeedBasicInfo;
			}
		}
	}

	capitalizeFirstLetter(string: string) {
		string = string.toLowerCase();
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	@Watch("addExpense")
	OnaddExpense(e: boolean) {
		if (e == true) {
			if (this.NeedAnalysisExpenses == null) {
				this.NeedAnalysisExpenses = new Expenses();
			}
			this.TempNeedAnalysisExpenses = JSON.parse(
				JSON.stringify(this.NeedAnalysisExpenses)
			);
			let combinedIncome =
				GetDecimalvaluefromString(
					this.getMonthlyIncomeAsPerWageType(this.Person.Wage)
				) +
				GetDecimalvaluefromString(
					this.getMonthlyIncomeAsPerWageType(this.Person.Spouse.Wage)
				);
			this.CombinedMonthlyIncome = ConvertIntoAmountFormatWithDecimal(
				combinedIncome.toFixed(2).toString(),
				true,
				true
			);
			this.NeedAnalysisFieldsUpdate();
		}
	}
	@Watch("addInsurance")
	OnaddInsurance(e: boolean) {
		if (e == true) {
			if (
				this.PrimaryInsuranceThroughWorkList == null ||
				this.PrimaryInsuranceThroughWorkList.length < 1
			) {
				this.PrimaryInsuranceThroughWorkList.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}

			if (
				this.PrimaryInsuranceOutsideWorkList == null ||
				this.PrimaryInsuranceOutsideWorkList.length < 1
			) {
				this.PrimaryInsuranceOutsideWorkList.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}

			if (
				this.SpouseInsuranceThroughWorkList == null ||
				this.SpouseInsuranceThroughWorkList.length < 1
			) {
				this.SpouseInsuranceThroughWorkList.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}

			if (
				this.SpouseInsuranceOutsideWorkList == null ||
				this.SpouseInsuranceOutsideWorkList.length < 1
			) {
				this.SpouseInsuranceOutsideWorkList.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}
			this.TempPrimaryInsuranceThroughWorkList = JSON.parse(
				JSON.stringify(this.PrimaryInsuranceThroughWorkList)
			);
			this.TempPrimaryInsuranceOutsideWorkList = JSON.parse(
				JSON.stringify(this.PrimaryInsuranceOutsideWorkList)
			);
			this.TempSpouseInsuranceThroughWorkList = JSON.parse(
				JSON.stringify(this.SpouseInsuranceThroughWorkList)
			);
			this.TempSpouseInsuranceOutsideWorkList = JSON.parse(
				JSON.stringify(this.SpouseInsuranceOutsideWorkList)
			);
			this.NeedAnalysisFieldsUpdate();
		}
	}
	@Watch("addProperties")
	OnaddProperties(e: boolean) {
		if (e == true) {
			if (
				this.NeedAnalysisPropertiesList == null ||
				this.NeedAnalysisPropertiesList.length < 1
			) {
				this.NeedAnalysisPropertiesList.push(new FamilyCareSurveyProperty());
			}
			this.TempNeedAnalysisPropertiesList = JSON.parse(
				JSON.stringify(this.NeedAnalysisPropertiesList)
			);
			this.NeedAnalysisFieldsUpdate();
		} else {
		}
	}
	@Watch("Person.Wage")
	OnPersonWageChanged() {
		this.Person.AnnualIncome = this.getAnnualIncomeAsPerWageType(
			this.Person.Wage
		);
	}

	@Watch("Person.Spouse.Wage")
	OnSpouseWageChanged() {
		this.Person.Spouse.AnnualIncome = this.getAnnualIncomeAsPerWageType(
			this.Person.Spouse.Wage
		);
	}
	getMonthlyIncomeAsPerWageType(wage: any) {
		var wageNumber = 0;
		if (wage) wageNumber = Number(wage.replaceAll(",", ""));
		switch (this.SelectedWageType.Id) {
			case 1:
				wageNumber = wageNumber * 8 * 5 * 4.333;
				break;
			case 2:
				wageNumber = wageNumber * 4.333;
				break;
			case 3:
				wageNumber = wageNumber;
				break;
			case 4:
				wageNumber = wageNumber / 12;
				break;
		}

		return ConvertIntoAmountFormatWithDecimal(wageNumber.toFixed(2).toString());
	}
	getAnnualIncomeAsPerWageType(wage: any) {
		var wageNumber = 0;
		if (wage) wageNumber = Number(wage.replaceAll(",", ""));
		switch (this.SelectedWageType.Id) {
			case 1:
				wageNumber = wageNumber * 8 * 5 * 4.333 * 12;
				break;
			case 2:
				wageNumber = wageNumber * 4.333 * 12;
				break;
			case 3:
				wageNumber = wageNumber * 12;
				break;
			case 4:
				wageNumber = wageNumber;
				break;
		}

		return ConvertIntoAmountFormatWithDecimal(wageNumber.toFixed(2).toString());
	}

	private personYearlySalary = 0;
	private spouseYearlySalary = 0;

	OnWageChanged(item: any) {
		this.$data.wageMenu = false;
		var previousSelectedWage = this.SelectedWageType;
		if (previousSelectedWage.id != item.Id) {
			this.NeedAnalysisFieldsUpdate();
		}
		var primaryWage = 0;
		if (this.Person.Wage)
			primaryWage = Number(this.Person.Wage.toString().replaceAll(",", ""));
		var spouseWage = 0;
		if (this.Person.Spouse.Wage)
			spouseWage = Number(
				this.Person.Spouse.Wage.toString().replaceAll(",", "")
			);

		if (this.primaryWageValue) {
			primaryWage = this.primaryWageValue;
		}

		if (this.spouseWageValue) {
			spouseWage = this.spouseWageValue;
		}

		var perHourPrimaryValue = primaryWage / this.SelectedWageType.HourValue;
		var perHourSpouseValue = spouseWage / this.SelectedWageType.HourValue;
		this.SelectedWageType = item;

		this.personYearlySalary = perHourPrimaryValue * 2080;
		this.spouseYearlySalary = perHourSpouseValue * 2080;

		perHourPrimaryValue = perHourPrimaryValue * this.SelectedWageType.HourValue;
		perHourSpouseValue = perHourSpouseValue * this.SelectedWageType.HourValue;

		this.primaryWageValue = perHourPrimaryValue;
		this.spouseWageValue = perHourSpouseValue;

		this.Person.Wage = ConvertIntoAmountFormatWithDecimal(
			RoundUpperDecimal(perHourPrimaryValue).toFixed(2),
			true,
			true
		);
		this.Person.Spouse.Wage = ConvertIntoAmountFormatWithDecimal(
			RoundUpperDecimal(perHourSpouseValue).toFixed(2),
			true,
			true
		);

		this.Presentation.WageType = this.SelectedWageType.Name;
		// if (this.SelectedWageType.PremiumTypeId != 0) {
		//   this.Presentation.PremiumPlan.PremiumTypeId =
		//     this.SelectedWageType.PremiumTypeId;
		// }

		// var selApprh = this.PremiumApproaches.filter((x: any) => {
		//   if (x.id == this.SelectedWageType.PremiumTypeId) return x;
		// });

		// if (selApprh != undefined && selApprh.length > 0) {
		//   this.$data.SelectedPremiumType = selApprh[0];
		// }
		this.SetPremiumPlanAnnualIncome(false);
	}

	SetPremiumPlanAnnualIncome(firsttime: boolean) {
		var tempPersonAnnualIncome = 0;
		if (
			typeof this.Person.AnnualIncome == "string" &&
			this.personYearlySalary == 0 &&
			!this.Presentation.WageValuesCustomized
		)
			tempPersonAnnualIncome = this.Person.AnnualIncome.replaceAll(",", "");
		else tempPersonAnnualIncome = this.personYearlySalary;

		if (isNaN(tempPersonAnnualIncome)) tempPersonAnnualIncome = 0;

		var tempSpouseAnnualIncome = 0;
		if (
			typeof this.Person.Spouse.AnnualIncome == "string" &&
			this.spouseYearlySalary == 0 &&
			!this.Presentation.WageValuesCustomized
		)
			tempSpouseAnnualIncome = this.Person.Spouse.AnnualIncome.replaceAll(
				",",
				""
			);
		else {
			tempSpouseAnnualIncome = this.spouseYearlySalary;
		}

		if (isNaN(tempSpouseAnnualIncome)) tempSpouseAnnualIncome = 0;

		if (isNaN(this.Presentation.PremiumPlan.CombinedTotalIncome))
			this.Presentation.PremiumPlan.CombinedTotalIncome = 0;

		this.Presentation.PremiumPlan.CombinedTotalIncome = Number(
			(+tempPersonAnnualIncome + +tempSpouseAnnualIncome).toFixed()
		);
	}

	created() {
		window.addEventListener("resize", this.mywindowresized);

		window.addEventListener("beforeunload", this.onCloseBrowser);
		LoggingHelper.getInstance().addBrowserCloseEvent();

		this.pageLoadedTime = new Date();
		this.Person = PrimaryPerson.query().withAll().last();
		this.FetchMaterialForReferral();
		this.CreateHouseHoldTypeList();
		EventBusLayout.$on("RoutedToEvt", this.NeedAnalysisRoutedToEvt);
		EventBusLayout.$on("Action", this.NeedAnalysisAction);
		getPersistedState("StateList").then((res: any) => {
			this.statesList = res;
		});
		// this.$data.primaryhppremium = this.Person.Premium;
		// this.$data.primarymonthlypremium = this.Person.Premium;
		// this.$data.spousehppremium = this.Person.Spouse.Premium;
		// this.$data.spousemonthlypremium = this.Person.Spouse.Premium;
		this.Presentation = Presentation.query().withAllRecursive().last();
		if (
			this.Presentation.WageType != undefined &&
			this.Presentation.WageType != ""
		)
			this.SelectedWageType = this.ListOfWagesTypes.filter(
				(x: any) => x.Name == this.Presentation.WageType
			)[0];
		this.Notes =
			this.Presentation.NANotes != null &&
			this.Presentation.NANotes != undefined &&
			this.Presentation.NANotes != "null"
				? this.Presentation.NANotes
				: "";
		if (
			this.Person.ChildrenString != undefined &&
			this.Person.ChildrenString != ""
		) {
			this.Childrens = JSON.parse(this.Person.ChildrenString);
		}
		if (
			this.Person.ExpenseString != undefined &&
			this.Person.ExpenseString != ""
		) {
			this.NeedAnalysisExpenses = JSON.parse(this.Person.ExpenseString);
		}
		if (
			this.Person.InsuranceThroughWorkString != undefined &&
			this.Person.InsuranceThroughWorkString != ""
		) {
			this.PrimaryInsuranceThroughWorkList = JSON.parse(
				this.Person.InsuranceThroughWorkString
			);
		}
		if (
			this.Person.InsuranceOutsideWorkString != undefined &&
			this.Person.InsuranceOutsideWorkString != ""
		) {
			this.PrimaryInsuranceOutsideWorkList = JSON.parse(
				this.Person.InsuranceOutsideWorkString
			);
		}
		if (this.Person.Spouse != undefined) {
			if (
				this.Person.Spouse.InsuranceThroughWorkString != undefined &&
				this.Person.Spouse.InsuranceThroughWorkString != ""
			) {
				this.SpouseInsuranceThroughWorkList = JSON.parse(
					this.Person.Spouse.InsuranceThroughWorkString
				);
			}
			if (
				this.Person.Spouse.InsuranceOutsideWorkString != undefined &&
				this.Person.Spouse.InsuranceOutsideWorkString != ""
			) {
				this.SpouseInsuranceOutsideWorkList = JSON.parse(
					this.Person.Spouse.InsuranceOutsideWorkString
				);
			}
		}
		if (
			this.Person.NeedAnalysisPropertiesString != undefined &&
			this.Person.NeedAnalysisPropertiesString != "" &&
			this.Person.NeedAnalysisPropertiesString != "[]"
		) {
			this.NeedAnalysisPropertiesList = JSON.parse(
				this.Person.NeedAnalysisPropertiesString
			);
		} else {
			this.Person.MortgageBalance = null;
			this.Person.MortgageInterestRate = null;
			this.Person.MortgageYearsRemaining = null;
			this.Person.MonthlyRent = null;
			this.Person.OwnHome = null;
			this.Person.RentHome = null;
		}
		// if (this.Childrens == null || this.Childrens.length < 1) {
		// 	this.Childrens.push(
		// 		new Children(),
		// 		new Children(),
		// 		new Children(),
		// 		new Children()
		// 	);
		// }
		if (!this.Presentation.PremiumPlan) {
			this.Presentation.PremiumPlan = new PremiumPlanEntity();
			var premiumPlan = PremiumPlanEntity.insert({
				data: this.Presentation.PremiumPlan,
			});
		}

		this.FetchAndLoadValidations();
		this.$data.SelectedPremiumType =
			this.PremiumApproaches[this.Presentation.PremiumTypeId - 1];

		this.$data.NeedPremium = this.Presentation.NeedAmount.toString();
		this.PremiumApproachChange(true);

		this.LoadMedicalQuestion();

		this.isSuperCombo = this.Presentation.isSuperCombo;

		this.PrimaryDOBChange(false);
		this.SpouseDOBChange(false);
		this.UpdatePremium();
		this.LoadMedQues();
	}
	openInsurance() {
		this.addInsurance = true;
	}
	UpdatePremium() {
		if (this.Presentation != undefined) {
			if (!this.Presentation.PremiumValuesCustomized) {
				if (this.Person.Wage != null && this.Person.Wage != "") {
					this.setPremiumasPerWageType(
						Number(this.Person.Wage.replaceAll(",", "")),
						"Primary"
					);
				}
				if (this.Person.Spouse.Wage != null && this.Person.Spouse.Wage != "") {
					this.setPremiumasPerWageType(
						Number(this.Person.Spouse.Wage.replaceAll(",", "")),
						"Spouse"
					);
				}
			}
		}
	}

	LoadMedQues() {
		GetLocalDataOrCallAPI(
			ApiEndPointConstants.GeneralMedicalQuestionEndpoint,
			[],
			IndexDBConstants.GeneralMedicalQuestionKey
		).then((medQs: any) => {
			if (medQs != null) {
				var result = medQs.reduce(function (r: any, e: any) {
					r[e.QuestionType] = medQs.filter(
						(x: any) => x.QuestionType == e.QuestionType
					);
					return r;
				}, {});
				result.senior = result.Senior.reduce(function (r: any, e: any) {
					r[e.Section] = result.Senior.filter(
						(x: any) => x.Section == e.Section
					);
					return r;
				}, {});
				result.supercombo = result.SuperCombo.reduce(function (r: any, e: any) {
					r[e.Section] = result.SuperCombo.filter(
						(x: any) => x.Section == e.Section
					);
					return r;
				}, {});
				this.$data.medicalquestions = JSON.parse(JSON.stringify(result));
			}
		});
	}

	onCloseBrowser(event: any) {
		this.browserCloseEvent = true;
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				// this.$router.push({
				//     name: "FinishPresentation",
				//     params: { showWhatHappenError: "true" },
				//   });
				this.RoutedTo("/finishpresentation");
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	private allProducts: any = {};
	private allMatchingproducts: any = [];
	FetchMaterialForReferral() {
		getPersistedState("Materials").then((res: any) => {
			this.allProducts = res;
			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined ||
							x.CountryId == this.Presentation.CountryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == this.Presentation.PresentationTypeId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);
		});
	}
	CreateHouseHoldTypeList() {
		HpProHouseHoldTypeDiscription.forEach((discription, value) => {
			if (discription.toLowerCase() != "retired")
				this.listofHouseHoldType.push({
					description: discription.toLowerCase(),
					value: value,
				});
		});
	}
	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		var RefInit: any = {};
		if (this.Presentation.Referrals) {
			RefInit = JSON.parse(this.Presentation.Referrals);
		} else {
			RefInit = {
				ReferredBy: "",
				ReferredByLastName: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: "",
				ReferralsList: [],
				CCEmail: [],
			};
		}
		if (RefInit.ReferralsList.length > 0) {
			RefInit.ReferralsList.forEach((referral: any) => {
				referral.StateObj = this.statesList.find(
					(x: any) => x.StateCode == referral.State
				);
			});
		}
		if (RefInit.ReferredBy == undefined || RefInit.ReferredBy == "")
			RefInit.ReferredBy = this.Person?.FirstName;

		if (
			RefInit.ReferredByLastName == undefined ||
			RefInit.ReferredByLastName == ""
		)
			RefInit.ReferredByLastName = this.Person?.LastName;

		if (RefInit.Phone == undefined || RefInit.Phone == "")
			RefInit.Phone = this.Person?.Phone;
		if (RefInit.Email == undefined || RefInit.Email == "")
			RefInit.Email = this.Person?.Email;
		if (
			this.Presentation.PresentedGroup != undefined &&
			this.Presentation.PresentedGroup != ""
		)
			RefInit.Organization = this.Presentation.PresentedGroup;
		if (RefInit.Date == undefined || RefInit.Date == "")
			RefInit.Date = moment(new Date()).format("MM/DD/YYYY");
		this.$data.Referrals = RefInit;
		if (newValue == true) {
			this.ResetReferrals = !this.ResetReferrals;
		}
	}
	CloseReferralPopup() {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;
		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;
		if (referral.City != null && referral.City.trim() != "") return false;
		if (referral.State != null && referral.State.trim() != "") return false;
		if (referral.Phone != null && referral.Phone.trim() != "") return false;
		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;
		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;
		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;
		return true;
	}
	SaveReferral(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});
			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}

	SaveReferralsWithoutClosingPopup(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});
			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	async LoadMedicalQuestion() {
		var medicalQuesList = await getPersistedState("MedicalQuestions");

		if (this.Presentation.MedicalQuestionsNA != "") {
			this.medicalQuestionList = JSON.parse(
				this.Presentation.MedicalQuestionsNA
			);
		}

		if (!this.medicalQuestionList) {
			this.medicalQuestionList = medicalQuesList;
		}

		this.SpecificMedicalQuestionList = this.medicalQuestionList.filter(
			(x: any) => x.IsForSenior == !this.isSuperCombo
		);

		if (this.Presentation.isNeedAnalysisCompleted) {
			this.SpecificMedicalQuestionList.forEach((question: any) => {
				if (question.QuestionEn.toString().includes("TOBACCO")) {
					question.PrimaryValue = this.Person.AnyTobaccoUse;
					question.SpouseValue = this.Person.Spouse.AnyTobaccoUse;
				}
			});
		}

		this.DollarADayOptions = this.CompleteDollarADayOption.filter((x: any) => {
			if (x.ShowOnlyinSpouse == undefined || x.ShowOnlyinSpouse.false)
				return true;
		});

		this.SpouseDollarADayOptions = this.CompleteDollarADayOption;
	}

	mounted() {
		this.$data.addlanguageclasses = this.Presentation.LanguageFlag != "en";
	}

	closeUWPopup(str: any) {
		this.underwriting = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}

	// @Watch("Person.Premium")
	// OnPremiumChanged(newValue: string, oldValue: string) {
	//   if (!newValue) return;
	//   var re = new RegExp("^\\d{1,4}?(\\.\\d{0,2}){0,1}$");
	//   if (!re.test(newValue)) {
	//     setTimeout(async () => {
	//       this.Person.Premium = oldValue;
	//     }, 0.1);
	//   }
	// }

	@Watch("Person.Spouse.FirstName")
	OnSpouseFirstNameChange(newValue: string, oldValue: string) {
		if (
			(newValue == undefined || newValue == "") &&
			(this.Person.Spouse.LastName == undefined ||
				this.Person.Spouse.LastName == "")
		) {
			this.$data.SpouseRequired = [];
			this.$data.SpouseRequiredNumber = [];
			this.$data.SpouseRadioRequired = [];
			this.$data.SpouseDOBRequired = [];
		} else if (this.$data.SpouseRequired.length == 0) {
			this.$data.SpouseRequired = [(v: any) => !!v || "Field is required"];
			this.$data.SpouseRadioRequired = [
				(v: any) => v != undefined || "Field is required",
			];
			this.$data.SpouseDOBRequired = [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			];
			this.$data.SpouseRequiredNumber = [
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			];
		}
	}

	@Watch("Person.Spouse.LastName")
	OnSpouseLastNameChange(newValue: string, oldValue: string) {
		if (
			(newValue == undefined || newValue == "") &&
			(this.Person.Spouse.FirstName == undefined ||
				this.Person.Spouse.FirstName == "")
		) {
			this.$data.SpouseRequired = [];
			this.$data.SpouseRequiredNumber = [];
			this.$data.SpouseRadioRequired = [];
			this.$data.SpouseDOBRequired = [];
		} else if (this.$data.SpouseRequired.length == 0) {
			this.$data.SpouseRequired = [(v: any) => !!v || "Field is required"];
			this.$data.SpouseRadioRequired = [
				(v: any) => v != undefined || "Field is required",
			];
			this.$data.SpouseDOBRequired = [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			];
			this.$data.SpouseRequiredNumber = [
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			];
		}
	}

	// @Watch("Person.Spouse.Premium")
	// OnSpousePremiumChanged(newValue: string, oldValue: string) {
	//   if (!newValue) return;
	//   var re = new RegExp("^\\d{1,4}?(\\.\\d{0,2}){0,1}$");
	//   if (!re.test(newValue)) {
	//     setTimeout(async () => {
	//       this.Person.Spouse.Premium = oldValue;
	//     }, 0.1);
	//   }
	// }

	@Watch("addchildren")
	OnAddchildren(e: boolean) {
		if (e == true) {
			if (this.Childrens == null || this.Childrens.length < 1) {
				this.Childrens.push(
					new Children(),
					new Children(),
					new Children(),
					new Children()
				);
			}
			this.TempChildrens = JSON.parse(JSON.stringify(this.Childrens));
			this.TempChildrens.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);
		} else {
		}
	}

	@Watch("notesdialog")
	OnNotesDialogChanged(isOpening: boolean) {
		if (isOpening) {
			this.Notes = this.Presentation.NANotes;
		}
	}
	get getDisposalIncome() {
		var DisposalIncome;
		// DisposalIncome =
		if (this.Person.Wage == "" && this.Person.Spouse.Wage == "") {
			return "";
		} else {
			DisposalIncome =
				GetDecimalvaluefromString(
					this.getMonthlyIncomeAsPerWageType(this.Person.Wage)
				) +
				GetDecimalvaluefromString(
					this.getMonthlyIncomeAsPerWageType(this.Person.Spouse.Wage)
				) -
				GetDecimalvaluefromString(this.Person.AnnualExpense);
			if (DisposalIncome < 0) {
				DisposalIncome = 0;
			}
			return ConvertIntoAmountFormatWithDecimal(
				DisposalIncome.toFixed(2).toString(),
				true,
				true
			);
		}
	}
	get PersonAge() {
		if (this.Person.DateOfBirth != null && this.Person.DateOfBirth != undefined)
			return EntityHelper.calculate_age(this.Person.DateOfBirth);
	}
	get ShowHomeLoanBalance() {
		if (
			this.Person.OwnHome &&
			Number(this.Person.MortgageBalance.toString().replaceAll(",", "")) > 0
		) {
			return true;
		}
		return false;
	}

	get ShowMortgageYearsRemaining() {
		if (this.ShowHomeLoanBalance && this.Person.MortgageYearsRemaining > 0) {
			return true;
		}
		return false;
	}

	get GetAnnualAssessmentConsideration() {
		var tempPersonAnnualIncome = 0;
		if (typeof this.Person.AnnualIncome == "string")
			tempPersonAnnualIncome = this.Person.AnnualIncome.replaceAll(",", "");
		var tempSpouseAnnualIncome = 0;
		if (typeof this.Person.Spouse.AnnualIncome == "string")
			tempSpouseAnnualIncome = this.Person.Spouse.AnnualIncome.replaceAll(
				",",
				""
			);
		var tempincome = Number(
			(+tempPersonAnnualIncome + +tempSpouseAnnualIncome).toFixed()
		);
		var tempRecommendedLifeIns = tempincome * 10;
		var tempExistingLifeIns = Number(
			(
				+(this.Person.InsuranceThroughWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceThroughWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.InsuranceThroughWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceThroughWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.InsuranceOutsideWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceOutsideWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.InsuranceOutsideWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceOutsideWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceThroughWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceThroughWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceThroughWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceThroughWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceOutsideWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceOutsideWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceOutsideWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceOutsideWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0)
			).toFixed()
		);
		var tempRemainingIns = +tempRecommendedLifeIns - +tempExistingLifeIns;
		return ConvertIntoAmountFormat(tempRemainingIns.toFixed());
	}

	get GetShowAssessmentResults() {
		let assessment = Number.parseFloat(
			this.GetAnnualAssessmentConsideration.replaceAll(",", "")
		);
		if (assessment > 0) {
			return true;
		}
		return false;
	}

	get GetAssessmentResults() {
		let assessment = Number.parseFloat(
			this.GetAnnualAssessmentConsideration.replaceAll(",", "")
		);
		if (this.Person.Checking && assessment > 0) {
			return true;
		}
		return false;
	}

	get SuperComboOrSenior() {
		if (
			this.Person.DateOfBirth != null &&
			this.Person.DateOfBirth != undefined
		) {
			var AgePerDOB = EntityHelper.calculate_age(this.Person.DateOfBirth);
			if (AgePerDOB != null && AgePerDOB != undefined) {
				if (AgePerDOB <= 60) return true;
				else return false;
			}
		}
		return true;
	}
	get MedicalQuestionsFilled() {
		// var flag = true;
		// if (this.SpecificMedicalQuestionList.length == 0) {
		// 	flag = true;
		// } else {
		// 	this.SpecificMedicalQuestionList.forEach((element: any) => {
		// 		if (element.PrimaryValue == null || element.PrimaryValue == undefined)
		// 			flag = false;
		// 	});
		// }
		// return flag;
		if (this.SpecificMedicalQuestionList.length != 0 && this.medicalvalid)
			return true;
		else return false;
	}
	get RowPrimary() {
		return (
			this.Person.FirstName.length > 0 ||
			this.Person.LastName.length > 0 ||
			(this.Person.DateOfBirth != "" && this.Person.DateOfBirth != null) ||
			this.Person.Occupation != "" ||
			this.Person.Gender != ""
		);
	}

	get RowEmail() {
		return (
			this.Person.Email.length > 0 ||
			this.Person.Phone.length > 0 ||
			this.Person.Zip.length > 0
		);
	}

	get RowSpouse() {
		return (
			this.Person.Spouse.FirstName.length > 0 ||
			this.Person.Spouse.LastName.length > 0 ||
			(this.Person.Spouse.DateOfBirth != "" &&
				this.Person.Spouse.DateOfBirth != null) ||
			this.Person.Spouse.Occupation != "" ||
			this.Person.Spouse.Gender != ""
		);
	}

	get RowChildren() {
		return (
			this.Person.HasChildrenUnder18 == true ||
			this.Person.HasChildrenUnder18 == false
		);
	}

	get RowThroughWork() {
		return (
			this.Person.InsuranceThroughWorkWholeLife != null ||
			this.Person.Spouse.InsuranceThroughWorkWholeLife != null ||
			this.Person.InsuranceThroughWorkTermLife != null ||
			this.Person.Spouse.InsuranceThroughWorkTermLife != null
		);
	}

	get RowOutSideOfWork() {
		return (
			this.Person.InsuranceOutsideWorkWholeLife != null ||
			this.Person.Spouse.InsuranceOutsideWorkWholeLife != null ||
			this.Person.InsuranceOutsideWorkTermLife != null ||
			this.Person.Spouse.InsuranceOutsideWorkTermLife != null
		);
	}

	get RowOwnYourHome() {
		return (
			this.Person.OwnHome || this.Person.RentHome || this.Person.MonthlyRent > 0
		);
		//return this.Person.OwnOrRent != null || this.Person.MonthlyRent > 0;
	}

	get RowMortgageBalance() {
		return (
			this.Person.MortgageBalance != null ||
			this.Person.MortgageInterestRate != null ||
			this.Person.MortgageYearsRemaining != null
		);
	}

	get RowCaseOfDeath() {
		return (
			this.Person.HomeInsuranceInCaseOfDeathAmount != null ||
			this.Person.ProvidedChildCollegeEducation != null
		);
	}

	get RowCollegeEducation() {
		return (
			this.Person.ProvidedChildCollegeEducation != null ||
			this.Person.ProvidedChildCollegeEducationAmount != null
		);
	}

	get RowHouseHold() {
		return this.Person.HouseHoldType != null;
	}

	get RowExpense() {
		return this.Person.AnnualExpense != null;
	}
	get RowWage() {
		return this.Person.Wage != null || this.Person.Spouse.Wage != null;
	}
	get RowDisposal() {
		return this.getDisposalIncome != null;
	}

	get RowPremiumApproach() {
		return (
			this.Person.Wage != null ||
			this.Person.Spouse.Wage != null ||
			this.Person.AnnualExpense != null
		);
	}

	CalculateCoverageLimits() {
		this.coverageDialog = !this.coverageDialog;

		this.CombinedPremiumAmount =
			+this.Person.Premium + +this.Person.Spouse.Premium;

		var tempPersonAnnualIncome = 0;
		if (typeof this.Person.AnnualIncome == "string")
			tempPersonAnnualIncome = this.Person.AnnualIncome.replaceAll(",", "");

		var tempSpouseAnnualIncome = 0;
		if (typeof this.Person.Spouse.AnnualIncome == "string")
			tempSpouseAnnualIncome = this.Person.Spouse.AnnualIncome.replaceAll(
				",",
				""
			);

		var tempincome = Number(
			(+tempPersonAnnualIncome + +tempSpouseAnnualIncome).toFixed()
		);

		this.AnnualIncome = ConvertIntoAmountFormat(tempincome.toFixed());

		var tempRecommendedLifeIns = tempincome * 10;
		this.RecommendedLifeIns = ConvertIntoAmountFormat(
			tempRecommendedLifeIns.toFixed()
		);

		var tempExistingLifeIns = Number(
			(
				+(this.Person.InsuranceThroughWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceThroughWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.InsuranceThroughWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceThroughWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.InsuranceOutsideWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceOutsideWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.InsuranceOutsideWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.InsuranceOutsideWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceThroughWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceThroughWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceThroughWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceThroughWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceOutsideWorkWholeLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceOutsideWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.Person.Spouse.InsuranceOutsideWorkTermLife != undefined
					? Number.parseFloat(
							this.Person.Spouse.InsuranceOutsideWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0)
			).toFixed()
		);

		this.ExistingLifetIns = ConvertIntoAmountFormat(
			tempExistingLifeIns.toFixed()
		);

		var tempRemainingIns = +tempRecommendedLifeIns - +tempExistingLifeIns;

		this.RemainingLifeIns = ConvertIntoAmountFormat(tempRemainingIns.toFixed());
	}

	destroyed() {
		window.removeEventListener("resize", this.mywindowresized);
	}
	mywindowresized(e: any) {
		this.$data.showconditionaltooltip =
			this.$data.addlanguageclasses && window.outerWidth < 1200;
	}
	data() {
		return {
			wageMenu: false,
			Referrals: {},
			addlanguageclasses: false,
			showconditionaltooltip: false,
			selected: undefined,
			personActivePicker: this.personActivePicker,
			spouseActivePicker: this.spouseActivePicker,
			personDOBPopup: this.personDOBPopup,
			spouseDOBPopup: this.spouseDOBPopup,
			Person: this.Person,
			Presentation: this.Presentation,
			NeedAnalysisExpenses: this.NeedAnalysisExpenses,
			SelectedPremiumType: {},
			NeedPremium: {},
			NameRules: [
				(v: any) => !!v || "Name is required",
				(v: any) => !!v.trim() || "Name is required",
				(v: any) => v.length <= 35 || "Name must be less than 20 characters",
			],
			SpouseNameRules: [
				(v: any) => v.length <= 35 || "Name must be less than 20 characters",
			],
			Required: [(v: any) => !!v || "Field is required"],
			SpouseRequired: [],
			DOBRequired: [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						// const pattern =
						//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			],
			PropertyRequired: [
				(v: any) => {
					if (v != undefined && !v) {
						return true;
					}
					return false;
				},
			],
			PrimaryRadioRequired: [(v: any) => v != undefined || "Field is required"],
			SpouseRadioRequired: [],
			RequiredHousehold: [
				(v: any) => {
					if (v != undefined && v >= 0) return true;
					else return false;
				},
			],
			RequiredNumber: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			],
			RequiredZip: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						var zipFormat = this.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			ZipFormat: [
				(v: any) => {
					if (v != undefined) {
						if (v.trim().length == 0) return true;
						var zipFormat = this.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			SpouseRequiredNumber: [],
			SpouseDOBRequired: [],
			InputRules: [(v: any) => v.length <= 20 || "Input must be valid"],
			NumericRules: [
				[
					(v: any) => (v || "").length <= 5 || "Max 20 characters",
					(v: any) => {
						const pattern = /^[0-9]*$/;
						return pattern.test(v) || "Invalid digits.";
					},
				],
			],
			medicalquestions: [],
			EmailRules: [
				(v: any) =>
					(!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
					"E-mail must be valid",
			],
			PhoneRules: [(v: any) => (!!v && v.length > 13) || "Phone must be valid"],
			RequiredInterestNumber: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0 && parseFloat(v) <= 100.0)
						return true;
					else return false;
				},
			],
		};
	}
	changePresentationType(a: any) {}
	// 	  beforeMount() {
	//     EventBusLayout.$on('sidebarclick',this.myCallbackFunction);
	//   }

	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.NeedAnalysisRoutedToEvt);
		EventBusLayout.$off("Action", this.NeedAnalysisAction);
	}

	NeedAnalysisRoutedToEvt(route: string) {
		this.RoutedTo(route);
	}
	NeedAnalysisAction(action: any) {
		this.Action(action);
	}
	RoutedTo(route: String) {
		if (this.Person.HouseHoldType == undefined) {
			this.Person.HouseHoldType = this.getHouseHoldType;
		}
		if (route == "/ssprogram") {
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";
			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}
		window.removeEventListener("beforeunload", this.onCloseBrowser);

		this.Presentation.TimeOnNeedAnalysis = (
			(parseInt(this.Presentation.TimeOnNeedAnalysis) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		if (this.$data.SelectedPremiumType != undefined) {
			if (this.Presentation.PremiumPlan != undefined) {
				this.Presentation.PremiumPlan.PremiumTypeId =
					this.$data.SelectedPremiumType.id;

				this.Presentation.PremiumPlan.FinalDailyPremium =
					this.Person.DollarADayAmount + this.Person.Spouse.DollarADayAmount;

				PremiumPlanEntity.update({
					where: this.Presentation.PremiumPlan.Id,
					data: this.Presentation.PremiumPlan,
				});
			}
			this.Presentation.PremiumTypeId = this.$data.SelectedPremiumType.id;

			this.Presentation.NeedAmount = this.$data.NeedPremium;
		}

		this.Presentation.isSuperCombo = this.isSuperCombo;
		this.Presentation.MedicalQuestionsNA = JSON.stringify(
			this.medicalQuestionList
		);
		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});
		if (this.Childrens != null && this.Childrens.length > 0) {
			this.Person.ChildrenString = JSON.stringify(this.Childrens);
		}
		this.Person.AgePerDOB = EntityHelper.calculate_age(this.Person.DateOfBirth);
		var premType = this.$data.SelectedPremiumType;

		// if (premType && premType.id == "1") {
		//   this.Person.Premium = this.$data.primaryhppremium;
		//   this.Person.Spouse.Premium = this.$data.spousehppremium;
		// }

		// if (premType && premType.id == "2") {
		//   this.Person.Premium = this.$data.primarymonthlypremium;
		//   this.Person.Spouse.Premium = this.$data.spousemonthlypremium;
		// }

		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		if (this.Person.Spouse.DateOfBirth != undefined) {
			this.Person.Spouse.AgePerDOB = EntityHelper.calculate_age(
				this.Person.Spouse.DateOfBirth
			);
		}

		Spouse.update({
			where: this.Person.Spouse.Id,
			data: this.Person.Spouse,
		});

		if (this.Person.Childrens != null) {
			this.Person.Childrens.forEach((element: Children) => {
				Children.delete(element.Id);
			});
		}

		// if (this.Childrens != null && this.Childrens.length > 0) {
		//   this.Childrens.forEach((element) => {
		//     element.Parent_Id = this.Person.Id;
		//   });
		//   Children.insert({ data: this.Childrens });
		// }

		if (route == "/plangenerator") {
			this.validationChecked = true;
			this.RouteWithoutOpeningSurvey = false;
			this.RouteWithoutOpeningIncome = false;
			var validEmail =
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.Person.Email) &&
				this.Person.Email.trim() != "";
			var validPhone = this.Person.Phone.length > 13;
			if (
				this.$refs.emailfield != undefined &&
				this.$refs.phonefield != undefined
			) {
				if (!validEmail && this.basicDetailRequired) {
					this.$refs.emailfield.$el.classList.add("error--text");
				} else this.$refs.emailfield.$el.classList.remove("error--text");
				if (!validPhone && this.basicDetailRequired) {
					this.$refs.phonefield.$el.classList.add("error--text");
				} else this.$refs.phonefield.$el.classList.remove("error--text");
			}
			if (
				!this.medicalvalid ||
				!this.personalvalid ||
				!this.incomevalid ||
				((!validEmail || !validPhone) && this.basicDetailRequired)
			) {
				this.$refs.QuestionsDiv.validate();
				// this.$refs.naForm.validate();
				this.$refs.PersonalInfoDiv.validate();
				this.$refs.IncomeDiv.validate();
				if (!this.personalvalid) {
					this.ValidateShowSurveyButton();
				} else if (this.personalvalid && !this.incomevalid) {
					this.ValidateShowIncomeButton();
				}
				this.$store.state.messageText = this.$t("NeedAnalysis.RequiredError");
				this.$store.state.showMessage = true;
				return;
			} else {
				this.ValidateShowSurveyButton();
				this.ValidateShowIncomeButton();
			}
			if (this.Presentation.CurrentStepCompleted <= 1) {
				this.Presentation.CurrentStepCompleted = 1;
			}
			this.Presentation.isNeedAnalysisCompleted = true;

			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		let saveData = new GenericKeyPair(
			"CurrentPresentation",
			Presentation.query().withAllRecursive().last()
		);
		setPersistedState(saveData);
		// saveData = new GenericKeyPair(
		//   "PresentationsList",
		//   JSON.stringify(Presentation.query().withAllRecursive().all())
		// );
		// setPersistedState(saveData);

		//
		if (this.browserCloseEvent) {
			this.browserCloseEvent = false;
			window.removeEventListener("beforeunload", this.onCloseBrowser);
			this.$router.push({
				name: "FinishPresentation",
				params: { showWhatHappenError: "true" },
			});
		} else {
			this.$router.push(route.toString());
		}
	}

	SpouseDOBClick() {
		this.Person.Spouse.DateOfBirthTemp = "01/01/1980";
	}
	saveSpouseDOB(date: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MM/DD/YYYY") : "";
		this.Person.Spouse.DateOfBirthTemp = tempdate;
		this.spouseDOBPopup = false;
	}
	saveChildren(items: any) {
		this.addchildren = false;
		this.Childrens = JSON.parse(
			JSON.stringify(
				this.TempChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);

		var children = this.Childrens.find(
			(x: any) => x.FirstName != "" || x.LastName != ""
		);
		if (JSON.stringify(children) != this.Person.ChildrenString) {
			this.NeedAnalysisFieldsUpdate();
		}
		if (children) {
			this.Person.ChildrenString = JSON.stringify(children);
			this.Person.HasChildrenUnder18 = true;
		} else {
			this.Person.ChildrenString = "";
			this.Person.HasChildrenUnder18 = false;
		}
	}
	cancelchildren() {
		this.addchildren = false;
		this.Childrens = JSON.parse(
			JSON.stringify(
				this.TempChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);

		var children = this.Childrens.find(
			(x: any) => x.FirstName != "" || x.LastName != ""
		);
		if (JSON.stringify(children) != this.Person.ChildrenString) {
			this.NeedAnalysisFieldsUpdate();
		}
		if (children) {
			this.Person.ChildrenString = JSON.stringify(children);
			this.Person.HasChildrenUnder18 = true;
		} else {
			this.Person.ChildrenString = "";
			this.Person.HasChildrenUnder18 = false;
		}
	}
	cancelInsurance() {
		this.PrimaryInsuranceThroughWorkList = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceThroughWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.PrimaryInsuranceOutsideWorkList = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceOutsideWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceThroughWorkList = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceThroughWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceOutsideWorkList = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceOutsideWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.Person.InsuranceThroughWorkString = JSON.stringify(
			this.PrimaryInsuranceThroughWorkList
		);
		this.Person.InsuranceOutsideWorkString = JSON.stringify(
			this.PrimaryInsuranceOutsideWorkList
		);
		this.Person.Spouse.InsuranceThroughWorkString = JSON.stringify(
			this.SpouseInsuranceThroughWorkList
		);
		this.Person.Spouse.InsuranceOutsideWorkString = JSON.stringify(
			this.SpouseInsuranceOutsideWorkList
		);
		this.GetPrimaryADB;
		this.addInsurance = false;
	}
	saveInsurance() {
		this.PrimaryInsuranceThroughWorkList = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceThroughWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.PrimaryInsuranceOutsideWorkList = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceOutsideWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceThroughWorkList = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceThroughWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceOutsideWorkList = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceOutsideWorkList.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.Person.InsuranceThroughWorkString = JSON.stringify(
			this.PrimaryInsuranceThroughWorkList
		);
		this.Person.InsuranceOutsideWorkString = JSON.stringify(
			this.PrimaryInsuranceOutsideWorkList
		);
		this.Person.Spouse.InsuranceThroughWorkString = JSON.stringify(
			this.SpouseInsuranceThroughWorkList
		);
		this.Person.Spouse.InsuranceOutsideWorkString = JSON.stringify(
			this.SpouseInsuranceOutsideWorkList
		);
		this.GetPrimaryADB;
		this.addInsurance = false;
	}
	saveProperties(items: any) {
		this.NeedAnalysisPropertiesList = JSON.parse(
			JSON.stringify(
				// this.TempFamilyCareProperties
				this.TempNeedAnalysisPropertiesList.filter(
					(x: any) =>
						(x.OwnOrRent != null && x.OwnOrRent != "") ||
						(x.MonthlyRent != null && x.MonthlyRent != "") ||
						(x.MortgageBalance != null && x.MortgageBalance != "") ||
						(x.MortgageInterestRate != null && x.MortgageInterestRate != "") ||
						(x.MortgageYearsRemaining != null && x.MortgageYearsRemaining != "")
				)
			)
		);

		this.Person.NeedAnalysisPropertiesString = JSON.stringify(
			this.NeedAnalysisPropertiesList
		);
		this.ValidateProperties(this.NeedAnalysisPropertiesList);
		this.GetTotalPayment;
		this.addProperties = false;
	}
	saveExpenses(evt: any) {
		if (evt != undefined) {
			if (this.Person.AnnualExpense != evt.TotalExpense) {
				this.Presentation.isPlanCalculationNeeded = true;
			}
			this.Person.AnnualExpense = evt.TotalExpense;
			this.ExpenseList = evt.ExpensesList;
		}
		if (
			this.ExpenseList != undefined &&
			this.ExpenseList != null &&
			Object.keys(this.ExpenseList).length > 0
		) {
			this.NeedAnalysisExpenses = JSON.parse(JSON.stringify(this.ExpenseList));
			this.Person.ExpenseString = JSON.stringify(this.NeedAnalysisExpenses);
		}
		this.addExpense = false;
	}
	cancelExpenses(evt: any) {
		if (evt != undefined) {
			if (this.Person.AnnualExpense != evt.TotalExpense) {
				this.Presentation.isPlanCalculationNeeded = true;
			}
			this.Person.AnnualExpense = evt.TotalExpense;
			this.ExpenseList = evt.ExpensesList;
		}
		if (
			this.ExpenseList != undefined &&
			this.ExpenseList != null &&
			Object.keys(this.ExpenseList).length > 0
		) {
			this.NeedAnalysisExpenses = JSON.parse(JSON.stringify(this.ExpenseList));
			this.Person.ExpenseString = JSON.stringify(this.NeedAnalysisExpenses);
		}
		this.addExpense = false;
	}
	cancelProperties() {
		this.NeedAnalysisPropertiesList = JSON.parse(
			JSON.stringify(
				// this.TempFamilyCareProperties
				this.TempNeedAnalysisPropertiesList.filter(
					(x: any) =>
						(x.OwnOrRent != null && x.OwnOrRent != "") ||
						(x.MonthlyRent != null && x.MonthlyRent != "") ||
						(x.MortgageBalance != null && x.MortgageBalance != "") ||
						(x.MortgageInterestRate != null && x.MortgageInterestRate != "") ||
						(x.MortgageYearsRemaining != null && x.MortgageYearsRemaining != "")
				)
			)
		);
		this.Person.NeedAnalysisPropertiesString = JSON.stringify(
			this.NeedAnalysisPropertiesList
		);
		this.ValidateProperties(this.NeedAnalysisPropertiesList);
		this.GetTotalPayment;
		this.addProperties = false;
	}

	validate(value: string, type: string) {
		if (!this.basicDetailRequired && value == "") {
			return;
		}

		if (type == "email") {
			var valid =
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) &&
				this.Person.Email.trim() != "";
			if (!valid) this.$refs.emailfield.$el.classList.add("error--text");
			else this.$refs.emailfield.$el.classList.remove("error--text");
		}
		if (type == "phone") {
			var valid = value.length > 13;
			if (!valid) this.$refs.phonefield.$el.classList.add("error--text");
			else this.$refs.phonefield.$el.classList.remove("error--text");
		}
	}

	savePersonDOB(date: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MM/DD/YYYY") : "";
		this.Person.DateOfBirthTemp = tempdate;
		this.personDOBPopup = false;
	}

	Action(actionName: String) {
		if (actionName == "underwriting") {
			this.underwriting = true;
			this.$store.state.OpenedPopupName = "underwriting";
			return;
		} else if (actionName == "medical") {
			this.medicalQuestionPopup = true;
			this.$store.state.OpenedPopupName = "medical";
			return;
		}
	}

	isNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	mask(evt: any) {
		var re = new RegExp("^\\d{1,3}?(\\.\\d{0,2}){0,1}$");
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (re.test(evt.target.value + String.fromCharCode(charCode))) {
			return true;
		} else {
			evt.preventDefault();
			return;
		}
	}

	saveNotes() {
		this.Presentation.NANotes = this.Notes;
		this.notesdialog = false;
	}

	InputAmountField(evt: any, fieldName: string = "") {
		if (
			evt.target != undefined &&
			evt.target.value != undefined &&
			evt.target.value != ""
		) {
			if (evt.target.value != "0") {
				var previusSelectionIndex = evt.target.selectionStart;
				evt.target.value = ConvertIntoAmountFormatWithDecimal(evt.target.value);
				var newSelectionIndex = evt.target.selectionStart;
				if (newSelectionIndex > previusSelectionIndex + 1) {
					evt.target.setSelectionRange(
						previusSelectionIndex,
						previusSelectionIndex
					);
				}
			}
			if (fieldName == "Person.Wage") {
				this.primaryWageValue = null;
			}
			if (fieldName == "Person.Spouse.Wage") {
				this.spouseWageValue = null;
			}
		}
	}

	ApplyValuebyFieldString(fieldString: string, value: string) {
		var properties = fieldString.split(".");
		var setPropertyvalue: any = this.$data;
		if (properties != undefined && properties.length > 0) {
			var i = 1;
			properties.forEach((element: any) => {
				if (i == properties.length) {
					setPropertyvalue[element] = value;
				} else {
					setPropertyvalue = setPropertyvalue[element];
				}
				i++;
			});
		}
	}

	AmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}
		var newValue = value;

		if (
			fieldString == "Person.Wage" &&
			!this.Presentation.PremiumValuesCustomized
		) {
			this.setPremiumasPerWageType(
				Number(value.replaceAll(",", "")),
				"Primary"
			);
		}
		if (
			fieldString == "Person.Spouse.Wage" &&
			!this.Presentation.PremiumValuesCustomized
		) {
			this.setPremiumasPerWageType(Number(value.replaceAll(",", "")), "Spouse");
		}
		if (value == "") {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		} else {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal(
					Number(value.replaceAll(",", "")).toFixed(2),
					true,
					true
				)
			);
		}

		// if (value == "") {
		//   this.ApplyValuebyFieldString(fieldString, "0");
		// } else if (value != undefined) {
		//   if (value.includes(".")) {
		//     var splitedvalue = value.split(".");
		//     newValue = "";
		//     newValue += splitedvalue[0] + ".";
		//     if (
		//       splitedvalue[1] != undefined &&
		//       splitedvalue[1] != "" &&
		//       splitedvalue[1].length > 1
		//     ) {
		//       newValue += splitedvalue[1];
		//     } else if (
		//       splitedvalue[1] != undefined &&
		//       splitedvalue[1].length == 1
		//     ) {
		//       newValue += splitedvalue[1] + "0";
		//     } else if (
		//       splitedvalue[1] != undefined &&
		//       splitedvalue[1].length == 0
		//     ) {
		//       newValue += "00";
		//     }
		//     this.ApplyValuebyFieldString(fieldString, newValue);
		//   } else {
		//     this.ApplyValuebyFieldString(
		//       fieldString,
		//       this.numeral(newValue).format("0,0")
		//     );
		//     //evt.target.value = ;
		//   }
		// }
	}

	setPremiumasPerWageType(wage: Number, type: string) {
		var premium: any;
		var monthlyPremium: any;
		switch (this.SelectedWageType.Id) {
			case 1:
				premium = RoundUpperDecimal(Number(wage));
				monthlyPremium = RoundUpperDecimal(premium * 4.3333);

				break;
			case 2:
				premium = RoundUpperDecimal(Number(wage) / 40);
				monthlyPremium = RoundUpperDecimal(premium * 4.3333);

				break;
			case 3:
				premium = RoundUpperDecimal(Number(wage) / (40 * 4.3333));
				monthlyPremium = RoundUpperDecimal(premium * 4.3333);

				break;
			case 4:
				premium = RoundUpperDecimal(Number(wage) / (8 * 5 * 4.3333 * 12));
				monthlyPremium = RoundUpperDecimal(premium * 4.3333);
				break;
		}

		if (type == "Primary") {
			this.Person.Premium = ConvertIntoAmountFormatWithDecimal(
				premium.toFixed(2),
				true,
				true
			);
			this.Person.MonthlyPremium = ConvertIntoAmountFormatWithDecimal(
				monthlyPremium.toFixed(2),
				true,
				true
			);
		} else {
			this.Person.Spouse.Premium = ConvertIntoAmountFormatWithDecimal(
				premium.toFixed(2),
				true,
				true
			);
			this.Person.Spouse.MonthlyPremium = ConvertIntoAmountFormatWithDecimal(
				monthlyPremium.toFixed(2),
				true,
				true
			);
		}
	}

	// ConvertIntoAmountFormatWithDecimal(value: string) {
	//   var string = "";
	//   if (value != undefined && value.includes(".")) {
	//     var splitedvalue = value.split(".");
	//     string = this.numeral(splitedvalue[0]).format("0,0") + ".";

	//     if (splitedvalue[1] != undefined && splitedvalue[1] != "") {
	//       string += splitedvalue[1];
	//     }
	//     return string;
	//   }

	//   string = this.numeral(value).format("0,0");
	//   return string;
	// }
	isInterestNumber(evt: any) {
		var isFieldSelected;
		var field = document.getElementById(
			"MaxInterestRate"
		) as HTMLInputElement | null;
		if (field != undefined) {
			if (typeof field.selectionStart == "number") {
				isFieldSelected =
					field.selectionStart == 0 && field.selectionEnd == field.value.length;
			}
		}
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		}
		if (!isFieldSelected) {
			var resValue = evt.target.value + evt.key;
			if (resValue <= 100) return true;
			else {
				evt.preventDefault();
			}
			// if((this.Person.MortgageInterestRate<=99.9 && this.Person.MortgageInterestRate*10 < 100) || evt.key == '.')
			//   return true;
			// else
			//   evt.preventDefault();
		}
	}
	PrecentageAmountFieldBlur(evt: any, valuae: any) {
		let value = evt.target.value;
		if (value == "") {
			this.ApplyValuebyFieldString(valuae, "0%");
		} else
			this.ApplyValuebyFieldString(
				valuae,
				Number.parseFloat(evt.target.value).toFixed(2) + "%"
			);
	}

	PrecentageAmountFieldFocus(evt: any, valuae: any) {
		let value = evt.target.value;
		if (value != undefined)
			this.ApplyValuebyFieldString(valuae, value.replace("%", ""));
		setTimeout(() => {
			evt.target.select();
		}, 50);
	}

	SwitchSeniorOption(type: any) {
		let PreviousCheck = this.isSuperCombo;
		this.validationChecked = false;
		this.RouteWithoutOpeningSurvey = false;
		if (type == "senior") this.isSuperCombo = false;
		else this.isSuperCombo = true;
		if (PreviousCheck != this.isSuperCombo) {
			this.$refs?.QuestionsDiv?.resetValidation();
			this.NeedAnalysisFieldsUpdate();
		}
		this.SpecificMedicalQuestionList = this.medicalQuestionList.filter(
			(x: any) => x.IsForSenior == !this.isSuperCombo
		);
		if (this.Presentation.isNeedAnalysisCompleted) {
			this.SpecificMedicalQuestionList.forEach((question: any) => {
				if (question.QuestionEn.toString().includes("TOBACCO")) {
					question.PrimaryValue = this.Person.AnyTobaccoUse;
					question.SpouseValue = this.Person.Spouse.AnyTobaccoUse;
				}
			});
		}

		if (this.Presentation.isNeedAnalysisCompleted) {
			setTimeout(() => {
				this.SpecificMedicalQuestionList.forEach((question: any) => {
					if (question.Question.toString().includes("TOBACCO")) {
						question.PrimaryValue = this.Person.AnyTobaccoUse;
						question.SpouseValue = this.Person.Spouse.AnyTobaccoUse;
					}
				});
			}, 0);
		}
	}
	ValidateShowSurveyButton() {
		if (
			this.MedicalQuestionsFilled &&
			!this.showsurvey &&
			!this.personalvalid
		) {
			this.RouteWithoutOpeningSurvey = true;
			let objDiv = document.getElementById("ShowSurveyButton");
			if (objDiv != null) {
				objDiv.classList.remove("shake-horizontal");
				setTimeout(() => {
					objDiv?.classList.add("shake-horizontal");
				}, 200);
			}
		}
	}
	ValidateShowIncomeButton() {
		if (this.personalvalid && !this.showincome && !this.incomevalid) {
			this.RouteWithoutOpeningIncome = true;
			let objDiv = document.getElementById("ShowIncomeButton");
			if (objDiv != null) {
				objDiv.classList.remove("shake-horizontal");
				setTimeout(() => {
					objDiv?.classList.add("shake-horizontal");
				}, 200);
			}
		}
	}
	CalculateInsurances(insurance: any) {
		let WHL: any;
		let Term: any;
		let Adb: any;
		insurance.forEach((element: any) => {
			switch (element.InsuranceType) {
				case "WHL":
					if (element.InsuranceAmount != null) {
						WHL =
							WHL != undefined
								? (WHL += Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  ))
								: Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  );
					}

					break;
				case "TERM":
					if (element.InsuranceAmount != null) {
						Term =
							Term != undefined
								? (Term += Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  ))
								: Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  );
					}

					break;
				case "ADB":
					if (element.InsuranceAmount != null) {
						Adb =
							Adb != undefined
								? (Adb += Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  ))
								: Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  );
					}

					break;
				default:
					break;
			}
		});

		let Result = "";
		// WHL = isNaN(WHL) ? 0 : WHL;
		// Term = isNaN(Term) ? 0 : Term;
		// Adb = isNaN(Adb) ? 0 : Adb;

		Result =
			WHL == null || WHL == undefined
				? Result.concat("|")
				: Result.concat(WHL.toString(), "|");
		Result =
			Term == null || Term == undefined
				? Result.concat("|")
				: Result.concat(Term.toString(), "|");
		Result =
			Adb == null || Adb == undefined
				? Result.concat("|")
				: Result.concat(Adb.toString(), "|");

		return Result;
	}
	ValidateProperties(properties: any) {
		this.isRentSelected = true;
		if (properties.length > 0) {
			// this.isPropertyAdded = false;
			for (let index = 0; index < properties.length; index++) {
				const element = properties[index];
				if (element.OwnOrRent == "OWN") {
					this.isRentSelected = false;
					// this.isRentOwnChecked = true;
					if (
						element.MonthlyRent == 0 ||
						(element.MonthlyRent != null && element.MortgageBalance == 0) ||
						(element.MonthlyRent != null &&
							element.MortgageBalance != null &&
							element.MortgageInterestRate != null &&
							element.MortgageYearsRemaining != null)
					) {
						// this.isPropertyAdded = true;
						break;
					}
				} else if (element.OwnOrRent == "RENT") {
					if (this.isRentSelected) {
						this.isRentSelected = true;
						// this.isRentOwnChecked = true;
					}
					if (element.MonthlyRent != null) {
						let monthRent = Number.parseFloat(
							element.MonthlyRent.replaceAll(",", "")
						);
						if (monthRent == 0 || monthRent > 0) {
							// this.isPropertyAdded = true;
							break;
						}
					}
				}
			}
		} else {
			// this.isPropertyAdded = false;
			this.isRentSelected = false;
			this.Person.OwnHome = null;
			this.Person.RentHome = null;
			this.Person.MortgageBalance = null;
			this.Person.MortgageInterestRate = null;
			this.Person.MortgageYearsRemaining = null;
		}
	}
	get GetPrimaryADB() {
		if (
			this.PrimaryInsuranceThroughWorkList != null &&
			this.PrimaryInsuranceThroughWorkList != undefined &&
			this.PrimaryInsuranceThroughWorkList.length > 0
		) {
			let test = this.CalculateInsurances(
				this.PrimaryInsuranceThroughWorkList
			).split("|");
			// this.SumPrimaryInsuranceThroughWorkWHL =
			// 	test[0] != ""
			// 		? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
			// 		: null;
			// this.SumPrimaryInsuranceThroughWorkTERM =
			// 	test[1] != ""
			// 		? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
			// 		: null;
			// this.SumPrimaryInsuranceThroughWorkADB =
			// 	test[2] != ""
			// 		? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
			// 		: null;
			this.Person.InsuranceThroughWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.Person.InsuranceThroughWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.Person.InsuranceThroughWorkAccidental =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}

		if (
			this.PrimaryInsuranceOutsideWorkList != null &&
			this.PrimaryInsuranceOutsideWorkList != undefined &&
			this.PrimaryInsuranceOutsideWorkList.length > 0
		) {
			let test = this.CalculateInsurances(
				this.PrimaryInsuranceOutsideWorkList
			).split("|");
			this.Person.InsuranceOutsideWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.Person.InsuranceOutsideWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.Person.InsuranceOutsideWorkAccidental =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}

		if (
			this.SpouseInsuranceThroughWorkList != null &&
			this.SpouseInsuranceThroughWorkList != undefined &&
			this.SpouseInsuranceThroughWorkList.length > 0
		) {
			let test = this.CalculateInsurances(
				this.SpouseInsuranceThroughWorkList
			).split("|");
			this.Person.Spouse.InsuranceThroughWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.Person.Spouse.InsuranceThroughWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.Person.Spouse.InsuranceThroughWorkAccidental =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}

		if (
			this.SpouseInsuranceOutsideWorkList != null &&
			this.SpouseInsuranceOutsideWorkList != undefined &&
			this.SpouseInsuranceOutsideWorkList.length > 0
		) {
			let test = this.CalculateInsurances(
				this.SpouseInsuranceOutsideWorkList
			).split("|");
			this.Person.Spouse.InsuranceOutsideWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.Person.Spouse.InsuranceOutsideWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.Person.Spouse.InsuranceOutsideWorkAccidental =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}

		return this.Person.InsuranceThroughWorkAccidental;
	}
	get GetTotalPayment() {
		if (
			this.NeedAnalysisPropertiesList != null &&
			this.NeedAnalysisPropertiesList != undefined &&
			this.NeedAnalysisPropertiesList.length > 0
		) {
			let own = false;
			let rent = false;
			let rentpayment = false;
			let rentmortgage = false;
			let rentrate = false;
			let rentyears = false;
			let payment = 0;
			let Mortgage = 0;
			let Rate = 0;
			let YrsRemaining = 0;
			this.NeedAnalysisPropertiesList.forEach((property) => {
				if (property.OwnOrRent != null) {
					if (property.OwnOrRent != null) {
						own = !own && property.OwnOrRent == "OWN" ? true : own;
						rent = !rent && property.OwnOrRent == "RENT" ? true : rent;
					}
					//own and rent not available check

					if (property.MonthlyRent != null) {
						payment += Number.parseFloat(
							property.MonthlyRent.replaceAll(",", "")
						);
					}
					if (property.MortgageBalance != null) {
						Mortgage += Number.parseFloat(
							property.MortgageBalance.replaceAll(",", "")
						);
					}
					if (property.MortgageInterestRate != null) {
						let propertiesInterestrate = Number.parseFloat(
							property.MortgageInterestRate.replaceAll(",", "").replaceAll(
								"%",
								""
							)
						);
						Rate =
							Rate < propertiesInterestrate ? propertiesInterestrate : Rate;
					}
					if (property.MortgageYearsRemaining != null) {
						YrsRemaining =
							YrsRemaining <
							Number.parseFloat(
								property.MortgageYearsRemaining.replaceAll(",", "")
							)
								? Number.parseFloat(
										property.MortgageYearsRemaining.replaceAll(",", "")
								  )
								: YrsRemaining;
					}
					// if (property.OwnOrRent == "RENT") {
					if (property.MonthlyRent != null) rentpayment = true;
					if (property.MortgageBalance != null) rentmortgage = true;
					if (property.MortgageInterestRate != null) rentrate = true;
					if (property.MortgageYearsRemaining != null) rentyears = true;
					// }
				}
			});

			this.Person.MonthlyRent = rentpayment
				? ConvertIntoAmountFormatWithDecimal(payment.toFixed(2).toString())
				: null;
			this.Person.OwnHome = own;
			this.Person.RentHome = rent;
			let OwnHomeWithZeroMonthlyRent =
				this.Person.MonthlyRent == "0.00" && this.Person.OwnHome;

			if (this.isRentSelected && rentmortgage == false) {
				this.Person.MortgageBalance = null;
			} else {
				this.Person.MortgageBalance =
					rentmortgage || OwnHomeWithZeroMonthlyRent
						? ConvertIntoAmountFormatWithDecimal(Mortgage.toFixed(2).toString())
						: null;
			}
			if (this.isRentSelected && rentrate == false) {
				this.Person.MortgageInterestRate = null;
			} else {
				this.Person.MortgageInterestRate =
					rentrate || OwnHomeWithZeroMonthlyRent
						? Rate.toFixed(2).toString() + "%"
						: null;
			}
			if (this.isRentSelected && rentyears == false) {
				this.Person.MortgageYearsRemaining = null;
			} else {
				this.Person.MortgageYearsRemaining =
					rentyears || OwnHomeWithZeroMonthlyRent
						? YrsRemaining.toString()
						: null;
			}
			return this.Person.MonthlyRent;
		}
		this.Person.MortgageBalance = null;
		this.Person.MortgageInterestRate = null;
		this.Person.MortgageYearsRemaining = null;
		this.Person.MonthlyRent = null;
		this.Person.OwnHome = null;
		this.Person.RentHome = null;
		return null;
	}
	get getMaxLengthasPerWageType() {
		switch (this.SelectedWageType.Id) {
			case 1:
				return 5;
			case 2:
				return 6;
			case 3:
				return 7;
			case 4:
				return 9;
		}
	}

	get getHouseHoldType() {
		if (this.Person != undefined) {
			if (
				this.Person.HouseHoldType != undefined &&
				this.Person.HouseHoldType != null &&
				this.Person.HouseHoldType !== ""
			) {
				return this.Person.HouseHoldType;
			}
			if (
				(this.Person.FirstName != undefined && this.Person.FirstName != "") ||
				(this.Person.LastName != undefined && this.Person.LastName != "")
			) {
				if (this.Person.isSpouseExists) {
					return this.Person.HasChildrenUnder18
						? this.listofHouseHoldType[HpProHouseHoldType.family]?.value
						: this.listofHouseHoldType[HpProHouseHoldType.married]?.value;
				}
				return this.Person.HasChildrenUnder18
					? this.listofHouseHoldType[HpProHouseHoldType.single_parent]?.value
					: this.listofHouseHoldType[HpProHouseHoldType.single]?.value;
			}
		}
		return null;
	}

	PremiumApproachChange(onCreate: boolean = false) {
		if (!onCreate) this.NeedAnalysisFieldsUpdate();
		if (
			this.$data.SelectedPremiumType != undefined &&
			this.$data.SelectedPremiumType.id == 3
		) {
			this.isDollarADay = true;
		} else {
			this.isDollarADay = false;
		}

		if (
			this.$data.SelectedPremiumType != undefined &&
			this.$data.SelectedPremiumType.id == 4
		) {
			this.isNeedPremium = true;
		} else {
			this.isNeedPremium = false;
		}
		if (this.changeWageType) {
			var selwage = this.ListOfWagesTypes.filter(
				(x: any) => x.PremiumTypeId == this.$data.SelectedPremiumType.id
			);
			if (selwage != undefined && selwage.length > 0) {
				this.OnWageChanged(selwage[0]);
			}
		} else {
			this.changeWageType = true;
		}
	}

	private PrimaryDOBPicker = "1980-01-01";
	PrimaryDOBChange(isUpdated: boolean) {
		if (
			this.Person.DateOfBirthTemp != undefined &&
			this.Person.DateOfBirthTemp != ""
		) {
			if (isUpdated) {
				this.Presentation.isPlanCalculationNeeded = true;
			}
			this.PrimaryDOBPicker = moment(this.Person.DateOfBirthTemp).format(
				"YYYY-MM-DD"
			);
		}
		// if (
		// 	this.Person.DateOfBirth != null &&
		// 	this.Person.DateOfBirth != undefined
		// ) {
		// 	var AgePerDOB = EntityHelper.calculate_age(this.Person.DateOfBirth);
		// 	if (AgePerDOB != null && AgePerDOB != undefined) {
		// 		if (AgePerDOB > 60) this.SwitchSeniorOption("senior");
		// 		else this.SwitchSeniorOption("supercombo");
		// 	} else this.SwitchSeniorOption("supercombo");
		// }
	}

	PrimaryDOBChangeBlur() {
		//     if (
		//       this.Person.DateOfBirthTemp != undefined &&
		//       this.Person.DateOfBirthTemp != ""
		//     ) {
		//       var primDate = moment(this.Person.DateOfBirthTemp);
		//       if (primDate > moment(new Date()))
		//       {
		// this.$refs.dobTextField.
		//       }
		//     }
	}

	private SpouseDOBPicker = "1980-01-01";
	SpouseDOBChange(isUpdated: boolean) {
		if (
			this.Person.Spouse.DateOfBirthTemp != undefined &&
			this.Person.Spouse.DateOfBirthTemp != ""
		) {
			if (isUpdated) {
				this.Presentation.isPlanCalculationNeeded = true;
			}
			this.SpouseDOBPicker = moment(this.Person.Spouse.DateOfBirthTemp).format(
				"YYYY-MM-DD"
			);
			this.Person.Spouse.DateOfBirthTemp = this.Person.Spouse.DateOfBirth;
		}
	}

	OnPremiumValueChange(evt: any) {
		if (this.Presentation != undefined)
			this.Presentation.PremiumValuesCustomized = true;

		this.InputAmountField(evt);
	}

	DisableSpecialCharacterKetdown(event: any) {
		if (/^\W$/.test(event.key)) {
			event.preventDefault();
		}
	}

	SaveWillKitDataToPresentation(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			this.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}
	PrimaryGenderUpdate() {
		this.NeedAnalysisFieldsUpdate();
		this.Presentation.isPlanCalculationNeeded = true;
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
	}
	SpouseGenderUpdate() {
		this.Presentation.isPlanCalculationNeeded = true;
	}
	showSurvey() {
		if (this.MedicalQuestionsFilled) {
			this.SurveyLoader = true;
			setTimeout(() => {
				this.showsurvey = true;
				this.SurveyLoader = false;
				// let objDiv = document.getElementById("FamilyCareDiv");
				// if (objDiv != null) objDiv.scrollTop = objDiv.scrollHeight;
			}, 2000);
		} else {
			this.$refs.QuestionsDiv.validate();
		}
	}
	showIncome() {
		if (this.personalvalid) {
			this.IncomeLoader = true;
			setTimeout(() => {
				this.showincome = true;
				this.IncomeLoader = false;
			}, 2000);
		} else {
			this.$refs.PersonalInfoDiv.validate();
			this.$refs.QuestionsDiv.validate();
		}
	}
	showRecommendation() {
		var validEmail =
			/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.Person.Email) &&
			this.Person.Email.trim() != "";
		var validPhone = this.Person.Phone.length > 13;
		if (
			this.$refs.emailfield != undefined &&
			this.$refs.phonefield != undefined
		) {
			if (!validEmail && this.basicDetailRequired) {
				this.$refs.emailfield.$el.classList.add("error--text");
			} else this.$refs.emailfield.$el.classList.remove("error--text");
			if (!validPhone && this.basicDetailRequired) {
				this.$refs.phonefield.$el.classList.add("error--text");
			} else this.$refs.phonefield.$el.classList.remove("error--text");
		}
		if (
			!this.medicalvalid ||
			!this.personalvalid ||
			!this.incomevalid ||
			((!validEmail || !validPhone) && this.basicDetailRequired)
		) {
			this.$refs.QuestionsDiv.validate();
			// this.$refs.naForm.validate();
			this.$refs.PersonalInfoDiv.validate();
			this.$refs.IncomeDiv.validate();
			// if (!this.valid) {
			// 	this.ValidateShowSurveyButton();
			// }
			this.$store.state.messageText = this.$t("NeedAnalysis.RequiredError");
			this.$store.state.showMessage = true;
			return;
		}

		this.viewRecommendation = true;

		var familyloader = document.getElementsByClassName("familyloader");
		var recommenditem = document.getElementsByClassName("recommenditem");
		setTimeout(function () {
			let objDiv = document.getElementById("FamilyCareDiv");
			if (objDiv != null) objDiv.scrollTop = objDiv.scrollHeight;
		}, 100);
		setTimeout(function () {
			if (familyloader.length > 0) {
				familyloader[0].classList.add("d-none");
			}
			if (recommenditem.length > 0) {
				recommenditem[0].classList.remove("d-none");
				setTimeout(function () {
					let objDiv = document.getElementById("FamilyCareDiv");
					if (objDiv != null) objDiv.scrollTop = objDiv.scrollHeight;
					var items = document.getElementsByClassName("fade-item");
					for (let i = 0; i < items.length; ++i) {
						fadeIn(items[i], i * 200);
					}

					function fadeIn(item: any, delay: any) {
						setTimeout(() => {
							item.classList.add("slide-in-bottom");
						}, delay);
					}
				}, 200);
			}
		}, 3000);
	}
	closeRecommendation() {
		this.viewRecommendation = false;
	}
	CloseMedQuesPopup(medicalQuestions: any) {
		this.$data.medicalquestions = medicalQuestions;
		this.medicalQuestionPopup = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	NeedAnalysisFieldsUpdate() {
		if (!this.IsNeedAnalysisUpdated) {
			this.IsNeedAnalysisUpdated = true;
			EventBusLayout.$emit("NeedAnalysisupdated");
		}
	}
	HouseHoldTypeChange(HouseHoldType: number) {
		if (this.Person != undefined && HouseHoldType != undefined) {
			this.Person.HouseHoldType = HouseHoldType;
		}
	}
	AnnualExpenseChanged(AnnualExpenseUpdated: any) {
		if (this.Person != undefined && AnnualExpenseUpdated != undefined) {
			this.Presentation.isPlanCalculationNeeded = true;
		}
	}
}
