














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import moment from "moment";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { EntityHelper } from "@/helpers/EntityHelper";
import PrimaryPerson from "../../entities/PrimaryPerson";
import {
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
} from "@/helpers/CalculationHelper";
import {
	GetAscFullName,
	GetFullName,
	GetUserEmail,
} from "@/helpers/APIExtentions";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class NeedsSurvey extends Vue {
	private Childrens: any = [];
	private isDialogOpen: boolean = false;
	private Name: string = GetFullName();
	private AscName: string = GetAscFullName();
	private UserEmail = " ( " + GetUserEmail() + " )";

	@Prop({ default: undefined })
	private PresentationData: any;

	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelneedssurvey");
		}, 500);
	}

	cancel() {
		this.$emit("cancelneedssurvey");
	}
	// async loadStates() {
	// 	this.stateListPromise = await getPersistedState("StateList");
	// }

	data() {
		return {};
	}

	created() {
		if (
			this.PresentationData.Presentation != undefined &&
			this.PresentationData.Presentation.PresentedTo != undefined &&
			this.PresentationData.Presentation.PresentedTo.ChildrenString !=
				undefined &&
			this.PresentationData.Presentation.PresentedTo.ChildrenString != ""
		) {
			this.Childrens = JSON.parse(
				this.PresentationData.Presentation.PresentedTo.ChildrenString
			);
		}
		this.isDialogOpen = true;
	}
	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined) return 0;
			else if (typeof amt == "string")
				return ConvertIntoAmountFormatWithDecimal(amt);
			else return ConvertIntoAmountFormatWithDecimal(amt.toFixed(2));
		} catch (ex: any) {
			return 0;
		}
	}
}
