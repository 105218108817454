































































































































































































































































































































































































import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import {
	ConvertIntoAmountFormatWithDecimal,
	ConvertIntoAmountFormatWithNegativeDecimal,
	GetDecimalvaluefromString,
	GetPrimaryFuneralType,
	GetSpouseFuneralType,
	IsPrimaryVeteranAndHonorablyDischarged,
	IsSpouseVeteranAndHonorablyDischarged,
	RoundUpper,
} from "@/helpers/CalculationHelper";
import { getPersistedState } from "@/helpers/StateMapper";
import { CountryType } from "@/enumerations/CountryType";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
let lstRates = require("../../assets/pre-fill-data/burial-and-cremation-rates.json");
@Component
export default class FuneralExpenseComponent extends Vue {
	private BurialAndCremationValuesList = lstRates;
	private States: any = [];
	private selectedState: any = "";
	private SelectedStateId = 0;
	private Funeraltypes: any = ["Burial", "Cremation"];
	private SelectedPrimaryFuneralType: any = "";
	private SelectedSpouseFuneralType: any = "";
	private Person: any;
	private Presentation: any;

	data() {
		return {
			tab: null,
		};
	}
	@Prop({ default: null })
	private PlanId: any;

	async created() {
		this.$data.tab = 0;
		this.Presentation = Presentation.query().withAllRecursive().last();
		this.Person = PrimaryPerson.query().withAll().last();
		var res = await getPersistedState("StateList");
		this.States = res;
		this.SelectedPrimaryFuneralType = this.PrimaryFuneralType;
		this.SelectedSpouseFuneralType = this.SpouseFuneralType;
		if (
			this.Presentation != undefined &&
			this.Presentation.State != undefined
		) {
			this.selectedState = this.States.find(
				(x: any) => x.Name == this.Presentation.State
			);
		}
	}
	stateChanged() {}
	FuneralTypeChanged() {}

	changeTab(tabIndex: number) {
		this.$data.tab = tabIndex;
	}

	get getIsSpouseExist() {
		if (this.Person != null && this.Person != undefined) {
			return this.Person.isSpouseExists;
		}
		return false;
	}

	get getPrimaryBurialOrCremationValue() {
		if (
			this.BurialAndCremationValuesList != undefined &&
			this.BurialAndCremationValuesList.length > 0 &&
			this.selectedState != ""
		) {
			var StateCode = this.selectedState.StateCode;
			var value = this.BurialAndCremationValuesList.filter((x: any) => {
				if (x.StateCode == StateCode) {
					return x;
				}
			});
			if (value != undefined) {
				if (this.SelectedPrimaryFuneralType != undefined) {
					switch (this.SelectedPrimaryFuneralType.toLowerCase()) {
						case "Cremation".toLowerCase():
						case "Other".toLowerCase():
							var FuneralRate =
								value.length != 0
									? value[0].Cremation
									: this.selectedState.CountryId == CountryType.UnitedStates
									? "6,280"
									: "3,500";
							break;
						case "Burial".toLowerCase():
							var FuneralRate =
								value.length != 0
									? value[0].Burial
									: this.selectedState.CountryId == CountryType.UnitedStates
									? "8,300"
									: "9,150";
							break;
						default:
							var FuneralRate =
								value.length != 0
									? value[0].Burial
									: this.selectedState.CountryId == CountryType.UnitedStates
									? "8,300"
									: "9,150";
					}
				}
				return Number(FuneralRate.replaceAll(",", ""));
			}
			return 0.0;
		}
		return 0.0;
	}

	get getSpouseBurialOrCremationValue() {
		if (
			this.BurialAndCremationValuesList != undefined &&
			this.BurialAndCremationValuesList.length > 0 &&
			this.selectedState != ""
		) {
			var StateCode = this.selectedState.StateCode;
			var value = this.BurialAndCremationValuesList.filter((x: any) => {
				if (x.StateCode == StateCode) {
					return x;
				}
			});
			if (value != undefined) {
				if (this.SelectedSpouseFuneralType != undefined) {
					switch (this.SelectedSpouseFuneralType.toLowerCase()) {
						case "Cremation".toLowerCase():
						case "Other".toLowerCase():
							var FuneralRate =
								value.length != 0
									? value[0].Cremation
									: this.selectedState.CountryId == CountryType.UnitedStates
									? "6,280"
									: "3,500";
							break;
						case "Burial".toLowerCase():
							var FuneralRate =
								value.length != 0
									? value[0].Burial
									: this.selectedState.CountryId == CountryType.UnitedStates
									? "8,300"
									: "9,150";
							break;
						default:
							var FuneralRate =
								value.length != 0
									? value[0].Burial
									: this.selectedState.CountryId == CountryType.UnitedStates
									? "8,300"
									: "9,150";
					}
				}
				return Number(FuneralRate.replaceAll(",", ""));
			}
			return 0.0;
		}
		return 0.0;
	}
	get IsPrimaryHonorableDischarged() {
		if (this.Presentation != null && this.Presentation != undefined) {
			return IsPrimaryVeteranAndHonorablyDischarged(
				this.Presentation.FamilyCareSurvey,
				this.Presentation.PresentationWillKitVeterans
			);
		}
		return false;
	}
	get IsSpouseHonorableDischarged() {
		if (this.Presentation != null && this.Presentation != undefined) {
			return IsSpouseVeteranAndHonorablyDischarged(
				this.Presentation.FamilyCareSurvey,
				this.Presentation.PresentationWillKitVeterans
			);
		}
		return false;
	}

	get PrimaryFuneralType() {
		if (this.Presentation != null && this.Presentation != undefined) {
			return GetPrimaryFuneralType(
				this.Presentation.FamilyCareSurvey,
				this.Presentation.PresentationWillKitVeterans
			);
		}
		return "";
	}
	get SpouseFuneralType() {
		if (this.Presentation != null && this.Presentation != undefined) {
			return GetSpouseFuneralType(
				this.Presentation.FamilyCareSurvey,
				this.Presentation.PresentationWillKitVeterans
			);
		}
		return "";
	}

	get GetAnnualExpense() {
		if (this.Person != null && this.Person != undefined) {
			return this.Person.AnnualExpense != undefined &&
				this.Person.AnnualExpense != ""
				? Number(this.Person.AnnualExpense.replaceAll(",", ""))
				: 0;
		}
		return 0;
	}
	get GetAnnualExpenseMultiplier() {
		if (this.Presentation != null && this.Presentation != undefined) {
			let currentPlanId = null;
			if (this.PlanId != null) {
				currentPlanId = this.PlanId;
			} else {
				let plans = JSON.parse(this.Presentation.PlanOptions);
				if (plans != undefined && plans != null && plans.length > 0) {
					var plan = plans.find((x: any) => {
						if (x.isSelected == true) {
							return x;
						}
					});
					currentPlanId = plan != undefined && plan != null ? plan.Id : null;
				}
			}

			if (currentPlanId != null) {
				if (currentPlanId == 1) {
					return 3;
				}
				if (currentPlanId == 2) {
					return 1.5;
				} else {
					return 0;
				}
			}
		}
		return 0;
	}

	get EstimatedFinalExpenses() {
		return RoundUpper(this.GetAnnualExpense * this.GetAnnualExpenseMultiplier);
		// if (
		// 	this.Presentation != null &&
		// 	this.Presentation != undefined &&
		// 	this.Person != null &&
		// 	this.Person != undefined
		// ) {
		// 	let currentPlanId = null;
		// 	var ExpenseValue =
		// 		this.Person.AnnualExpense != undefined &&
		// 		this.Person.AnnualExpense != ""
		// 			? Number(this.Person.AnnualExpense.replaceAll(",", ""))
		// 			: 0;

		// 	if (this.PlanId != null) {
		// 		currentPlanId = this.PlanId;
		// 	} else {
		// 		let plans = JSON.parse(this.Presentation.PlanOptions);
		// 		if (plans != undefined && plans != null && plans.length > 0) {
		// 			var plan = plans.find((x: any) => {
		// 				if (x.isSelected == true) {
		// 					return x;
		// 				}
		// 			});
		// 			currentPlanId = plan != undefined && plan != null ? plan.Id : null;
		// 		}
		// 	}

		// 	if (currentPlanId != null) {
		// 		if (currentPlanId == 1) {
		// 			return ExpenseValue * 3;
		// 		}
		// 		if (currentPlanId == 2) {
		// 			return ExpenseValue * 1.5;
		// 		} else {
		// 			return 0.0;
		// 		}
		// 	}

		// 	return 0.0;
		// }
		// return 0.0;
	}

	get PrimaryFinalExpenseAmount() {
		return this.IsPrimaryHonorableDischarged
			? this.getPrimaryBurialOrCremationValue +
					this.EstimatedFinalExpenses -
					948 -
					231
			: this.getPrimaryBurialOrCremationValue + this.EstimatedFinalExpenses;
	}
	get SpouseFinalExpenseAmount() {
		return this.IsSpouseHonorableDischarged
			? this.getSpouseBurialOrCremationValue +
					this.EstimatedFinalExpenses -
					948 -
					231
			: this.getSpouseBurialOrCremationValue + this.EstimatedFinalExpenses;
	}
	close() {
		this.$emit("ClosePopup", null);
	}

	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined) return 0;
			else if (typeof amt == "string")
				return ConvertIntoAmountFormatWithNegativeDecimal(amt, false);
			else
				return ConvertIntoAmountFormatWithNegativeDecimal(
					amt.toFixed(2),
					false
				);
		} catch (ex: any) {
			return 0;
		}
	}
	ConvertintoAmountFormatExpense(amt: any) {
		try {
			if (amt == undefined) return 0;
			var result = amt - Math.floor(amt) !== 0;
			if (result) {
				return ConvertIntoAmountFormatWithNegativeDecimal(amt.toFixed(2));
			} else {
				return ConvertIntoAmountFormatWithNegativeDecimal(
					amt.toFixed(2),
					false
				);
			}
		} catch (ex: any) {
			return 0;
		}
	}
}
