import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'
import Children from './Children'
import Presentation from './Presentation'
import PlanSummary from './PlanSummary'
import Spouse from './Spouse'
import { PlanEntity, PersonPlan } from './PlanEntity'
import { GetParticularLanguageString } from '@/plugins/i18n'
import { GenderType } from '@/enumerations/GenderType'
import { Camelize } from '@/helpers/ArrayHelper'
import AdultChildren from './AdultChildren'
import { MaritalStatus } from '@/enumerations/MaritalStatus'
export default class PrimaryPerson extends Model {
    static entity = 'primarypersons'
    static primaryKey = 'Id'
    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            FirstName: this.string(''),
            LastName: this.string(''),
            MiddleName: this.string(null).nullable(),
            SurName: this.string(null).nullable(),
            NickName: this.string(null).nullable(),
            PlaceOfBirth : this.string(null).nullable(),
            DateOfBirth: this.string(null).nullable(),
            Occupation: this.string(''),
            Gender: this.string(''),
            MaritalStatus: this.string(null).nullable(),
            IsNonBinary: this.boolean(null),
            InsuranceThroughWorkWholeLife: this.string(null).nullable(),
            InsuranceThroughWorkTermLife: this.string(null).nullable(),
            InsuranceOutsideWorkWholeLife: this.string(null).nullable(),
            InsuranceOutsideWorkTermLife: this.string(null).nullable(),
            InsuranceThroughWorkAccidental: this.string(null).nullable(),
            InsuranceOutsideWorkAccidental: this.string(null).nullable(),
            PremiumApproach: this.string('Hour Power'),
            PremiumApproachId: this.number(1),
            Premium: this.number(null).nullable(),
            Email: this.string(''),
            Phone: this.string(''),
            BeneficiaryName: this.string(''),
            Zip: this.string(''),
            Presentation_Id: this.attr(null),
            Presentation: this.belongsTo(Presentation, "Presentation_Id"),
            Spouse_Id: this.attr(null),
            Spouse: this.belongsTo(Spouse, 'Spouse_Id'),
            HasChildrenUnder18: this.boolean(null).nullable(),
            Childrens: this.hasMany(Children, 'Parent_Id'),
            AdultChildrens: this.hasMany(AdultChildren, 'Parent_Id'),
            // OwnOrRent: this.string(null).nullable(),
            MonthlyRent: this.string(null).nullable(),
            MortgageBalance: this.string(null).nullable(),
            MortgageInterestRate: this.string(null).nullable(),
            MortgageYearsRemaining: this.string(null).nullable(),
            HomeInsuranceInCaseOfDeath: this.string(null).nullable(),
            HomeInsuranceInCaseOfDeathAmount: this.string(null).nullable(),
            ProvidedChildCollegeEducation: this.string(null).nullable(),
            ProvidedChildCollegeEducationAmount: this.string(null).nullable(),
            Checking: this.boolean(false),
            Saving: this.boolean(false),
            AnyTobaccoUse: this.boolean(false),
            HadCancer: this.boolean(false),
            HadHeartDisease: this.boolean(false),
            Retired: this.string(null).nullable(),
            Income: this.string(null).nullable(),
            Plans: this.belongsToMany(PlanEntity, PersonPlan, 'personId', 'planId'),
            PlanSummary_Id: this.attr(null),
            PlanSummary: this.belongsTo(PlanSummary, "PlanSummary_Id"),
            ChildrenPlanSummary_Id: this.attr(null),
            ChildrenPlanSummary: this.belongsTo(PlanSummary, "ChildrenPlanSummary_Id"),
            Sponsership: this.string(""),
            Wage: this.string(""),
            ChildrenString: this.string(""),
            AdultChildrenString: this.string(""),
            AgePerDOB: this.number(null).nullable(),
            AnnualIncome: this.string(null).nullable(),
            AnnualExpense: this.string(null).nullable(),
            PremiumCalculated: this.boolean(false),
            MonthlyPremium: this.string(null).nullable(),
            DollarADayAmount: this.number(3),
            DOBAndAgeString: this.string(''),
            City: this.string(''),
            State: this.number(0),
            Address: this.string(null).nullable(),
            HouseHoldType: this.number(null).nullable(),
            InsuranceThroughWorkString: this.string(""),
			InsuranceOutsideWorkString: this.string(""),
            NeedAnalysisPropertiesString: this.string(""),
            OwnHome: this.boolean(null).nullable(),
			RentHome: this.boolean(null).nullable(),
            ExpenseString: this.string(""),
            SponsorName: this.string(''),
            SponsorRelation: this.string(''),
        }
    }
    // get Age() {
    //     if (this.DateOfBirth == null || this.DateOfBirth == undefined || isNaN(EntityHelper.calculate_age(new Date(this.DateOfBirth))))
    //         return 0;
    //     this.AgePerDOB = EntityHelper.calculate_age(new Date(this.DateOfBirth));
    //     return this.AgePerDOB;
    // }
    // set Age(value: number) {
    //     this.AgePerDOB = value;
    // }
    get DateOfBirthTemp() {
        return this.DateOfBirth;
    }
    set DateOfBirthTemp(value: string) {
        this.DateOfBirth = value;
        this.AgePerDOB = EntityHelper.calculate_age(this.DateOfBirth);
    }
    get CombinedPremium() {
        return this.Premium + this.Spouse.Premium;
    }
    public Id!: any
    public Presentation_Id!: any
    public MortgageBalance!: any
    public BeneficiaryName!: string
    public Spouse_Id!: any
    public FirstName!: string
    public DollarADayAmount!: number
    public AgePerDOB!: number
    public LastName!: string
    public MiddleName!: string
    public SurName!: string
    public NickName!: string
    public PlaceOfBirth!: string
    public DateOfBirth!: string
    // public OwnOrRent!: string
    public Occupation!: string
    public Gender!: string
    public MaritalStatus!: string
    public IsNonBinary!: boolean
    public InsuranceThroughWorkWholeLife!: string
    public InsuranceThroughWorkTermLife!: string
    public InsuranceOutsideWorkWholeLife!: string
    public InsuranceOutsideWorkTermLife!: string
    public InsuranceThroughWorkAccidental!: string
    public InsuranceOutsideWorkAccidental!: string
    public PremiumApproach!: string
    public Premium!: number
    public Email!: string
    public Phone!: string
    public Zip!: string
    public Spouse!: Spouse
    public Childrens: Array<Children> = [];
    public AnyHealthIssues: boolean = false
    public AnyPrescriptionMedication!: boolean
    public AnyTobaccoUse!: boolean
    public HadCancer!: boolean;
    public HadHeartDisease!: boolean;
    public AnyArrests!: boolean
    public HasChildrenUnder18: any
    public Checking!: boolean
    public PremiumCalculated!: boolean
    public Saving!: boolean
    public MonthlyRent!: string
    public MoPremiumApproachrtgageBalance!: string
    public MortgageInterestRate!: string
    public MortgageYearsRemaining!: string
    public HomeInsuranceInCaseOfDeath!: string
    public HomeInsuranceInCaseOfDeathAmount!: string
    public ProvidedChildCollegeEducation!: string
    public ProvidedChildCollegeEducationAmount!: string
    public Retired!: number
    public Income!: string
    public PremiumApproachId!: number
    public Plans!: any
    public PlanSummary_Id!: any
    public PlanSummary!: PlanSummary
    public Sponsership!: string
    public MortgageAmount!: number
    public Wage!: string
    public ChildrenString!: string
    public AdultChildrenString!: string
    public AnnualIncome!: string
    public AnnualExpense!: string
    public MonthlyPremium!: number
    public DOBAndAgeString!: string
    public isSpouseForDADExistsPreviously!: boolean
    public City !: string
    public State !: string
    public Address !: string
    public HouseHoldType !: number
    public InsuranceThroughWorkString !: string
    public InsuranceOutsideWorkString !: string
    public NeedAnalysisPropertiesString !: string
    public OwnHome !: boolean;
	public RentHome !: boolean;
    public ExpenseString !: string
    public SponsorName!: string
    public SponsorRelation!: string
    get GetNameHolder() {
        if (this.FirstName != null && this.FirstName != "") {
            return this.FirstName;
        }
        else if (this.LastName != null && this.LastName != "") {
            return this.LastName;
        }
        else {
            return GetParticularLanguageString("NeedAnalysis.Primary");
        }
    }
    get isSpouseExists() {
        if (this.Spouse != null && (this.Spouse.FirstName != "" || this.Spouse.LastName != "")) {
            return true;
        }
        else
            return false;
    }
    get isSpouseForDADExists() {
        if (this.Spouse == null || isNaN(this.Spouse.AgePerDOB) || this.Spouse.AgePerDOB == 0 || this.Spouse.Gender == '' || this.Spouse.Gender == undefined || this.Spouse.AgePerDOB == null) {
            if (this.isSpouseForDADExistsPreviously != false) {
                this.isSpouseForDADExistsPreviously = false;
                if (this.DollarADayAmount == 2.5)
                    this.DollarADayAmount = 3.00;
                if (this.Spouse.DollarADayAmount == 2.5)
                    this.Spouse.DollarADayAmount = 0;
            }
            return false;
        }
        else {
            if (this.isSpouseForDADExistsPreviously != true) {
                this.isSpouseForDADExistsPreviously = true;
                if (this.DollarADayAmount == 3.00)
                    this.DollarADayAmount = 2.5;
                if (this.Spouse.DollarADayAmount == 0)
                    this.Spouse.DollarADayAmount = 2.5;
            }
            return true;
        }
    }
    get isSpousePGExists() {
        if (this.Spouse != null && (this.Spouse.FirstName != "") && !isNaN(this.Spouse.AgePerDOB) && this.Spouse.AgePerDOB != 0) {
            return true;
        }
        else
            return false;
    }
    get isSpousePGExistsForA71000() {
        if (this.Spouse != null && (this.Spouse.FirstName != "" || this.Spouse.LastName != "") || (this.Spouse.AgePerDOB != undefined && !isNaN(this.Spouse.AgePerDOB) && this.Spouse.AgePerDOB != 0) ||
            (this.Spouse.Gender != undefined && this.Spouse.Gender != '')) {
            return true;
        }
        else
            return false;
    }
    get isSpouseExistForCalculation() {
        if (this.Spouse != null && !isNaN(this.Spouse.AgePerDOB) && this.Spouse.AgePerDOB != 0 && this.Spouse.AgePerDOB != null && this.Spouse.Gender != '') {
            return true;
        }
        else
            return false;
    }
    get isSpouseExistForBS() {
        if (this.Spouse == null || isNaN(this.Spouse.AgePerDOB) || this.Spouse.AgePerDOB == 0 || this.Spouse.Gender == '' || this.Spouse.Gender == undefined || this.Spouse.AgePerDOB == null) {
            return false;
        }
        else
            return true;
    }
    get isSpouseExistForRider() {
        if (this.Spouse != null && this.AgePerDOB > 17 && this.AgePerDOB < 61 && !isNaN(this.Spouse.AgePerDOB) && this.Spouse.AgePerDOB != 0 && this.Spouse.AgePerDOB != null && this.Spouse.Gender != '') {
            return true;
        }
        else
            return false;
    }
    get fullname() {
        var name = this.FirstName;
        if (this.LastName != undefined && this.LastName != "")
            name += " " + this.LastName;
        return name;
    }
    get fullnameCamelize() {
        var fullName = this.fullname;
        return Camelize(fullName);
    }
    get GenderTypeId() {
        if (this.Gender == GenderType[GenderType.MALE])
            return GenderType.MALE.toFixed();
        else if (this.Gender == GenderType[GenderType.FEMALE])
            return GenderType.FEMALE.toFixed();
        else return 0;
    }
    get MaritialStatusTypeId(){
        if (this.MaritalStatus == MaritalStatus[MaritalStatus.single])
            return MaritalStatus.single;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.married]){
            return MaritalStatus.married
        }
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.divorced])
            return MaritalStatus.divorced;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.widowed])
            return MaritalStatus.widowed;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.separated])
            return MaritalStatus.separated;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.domestic_partner])
            return MaritalStatus.domestic_partner;
        else return 0;
    }
}