import { IndexDBConstants } from "@/constants/IndexDBConstants";
import GenericKeyPair from "@/models/GenericKeyPair";
import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Spouse from "@/entities/Spouse";
import { deletePersistedState, getPersistedState, setPersistedState } from "./StateMapper";
import { isJson } from "./ArrayHelper";

export default class LoggingHelper {

    private static _instance: LoggingHelper = new LoggingHelper();
    private isBrowerCloseEventRegistered: boolean = false;

    constructor() {
        if (LoggingHelper._instance) {
            throw new Error("Error: Instantiation failed: Use LoggingHelper.getInstance() instead of new.");
        }
        LoggingHelper._instance = this;
    }

    public static getInstance(): LoggingHelper {
        this.CheckLoginStatus();
        return LoggingHelper._instance;
    }

    public static  CheckLoginStatus(){
        var TokenExpireStatus = false;
        const storedUser = localStorage.getItem('user');
        if (storedUser != undefined && storedUser != "" && isJson(storedUser)) {
            var userDetail = JSON.parse(storedUser);
           var LoginDate = new Date(userDetail['.issued']).toLocaleString();                   
           var LoggedDate = new Date(LoginDate).getTime() / 1000;
         
           var expiretokenHour = process.env.VUE_APP_API_LogOutTime;
           if(expiretokenHour == undefined){
            localStorage.removeItem('user');
            deletePersistedState("AssociateHierarchy");
            deletePersistedState('PresentationList');
            window.location.href = (window.origin);
           }
           var TokenExpireString =  new Date().toLocaleDateString() + " " + expiretokenHour + ":00:00"
           var tokenexpireDates = new Date(TokenExpireString)
         
            var TokenExpireDate = new Date(tokenexpireDates).getTime() / 1000;
            var CurrentDate = new Date().getTime() / 1000;

                if(LoggedDate <= TokenExpireDate){
                        if(CurrentDate >= TokenExpireDate){
                            TokenExpireStatus = true;
                        }
                        else{
                            TokenExpireStatus = false;
                        }
                }
                else{
                    TokenExpireStatus = false;
                }

        

           if( TokenExpireStatus)
           {
            
  
                localStorage.removeItem('user');
                deletePersistedState("AssociateHierarchy");
                deletePersistedState('PresentationList');
                Presentation.deleteAll();
                PrimaryPerson.deleteAll();
                Spouse.deleteAll();
                window.location.href = (window.origin);
             }
  
        }  


    }


    LogEvent(text: string) {
        try {
            var datenow = new Date().toUTCString();
            var comText = process.env?.VUE_APP_BUILD_VERSION + "\t" + text;
            getPersistedState(IndexDBConstants.LogEvent).then((res: any) => {
                if (res == undefined || res == "") {
                    var elem = { Time: datenow, Data: comText };
                    setPersistedState(new GenericKeyPair(IndexDBConstants.LogEvent, [elem]))
                }
                else {
                    var elem = { Time: datenow, Data: comText };
                    res.push(elem)
                    setPersistedState(new GenericKeyPair(IndexDBConstants.LogEvent, res))
                }
            })
            
        }
        catch (ex: any) {
            console.log("Error Saving Log", ex);
        }
    }

    LogEvents(texts: string[]) {
        try {
            var datenow = new Date().toUTCString();
            if (texts != undefined && texts.length > 0) {
                var elem: any = [];
                texts.forEach((text: any) => {
                    var comText = process.env?.VUE_APP_BUILD_VERSION + "\t" + text;
                    elem.push({ Time: datenow, Data: comText });
                });
                getPersistedState(IndexDBConstants.LogEvent).then((res: any) => {
                    if (res == undefined || res == "") {
                        setPersistedState(new GenericKeyPair(IndexDBConstants.LogEvent, elem))
                    }
                    else {
                        res.push(...elem)
                        setPersistedState(new GenericKeyPair(IndexDBConstants.LogEvent, res))
                    }
                })
            }
        }
        catch (ex: any) {
            console.log("Error Saving Log", ex);
        }
    }

    DeleteLogForMorethanParticularDays() {
        try {
            getPersistedState(IndexDBConstants.LogEvent).then((res: any) => {
                if (res != undefined) {
                    var datestr = (new Date()).toUTCString();
                    var date = new Date(datestr);
                    date.setDate(date.getDate() - process?.env?.VUE_APP_LOG_DURATION);
                    res = res.filter((x: any) => {
                        var dateItem = new Date(x.Time);
                        if (dateItem > date) return x;

                    })
                    setPersistedState(new GenericKeyPair(IndexDBConstants.LogEvent, res));
                }
            })
        }
        catch (ex: any) {
            console.log("Error Deleting Log", ex);
        }
    }

    deleteBrowserCloseEvent(){
        this.isBrowerCloseEventRegistered = false;
    }

    addBrowserCloseEvent(){
        this.isBrowerCloseEventRegistered = true;
    }

    GetBrowserCloseEvent(){

        return this.isBrowerCloseEventRegistered;
    }



    async GetAllUserLogs() {
        try {
            return await getPersistedState(IndexDBConstants.LogEvent);
        }
        catch (ex: any) {
            console.log("Error Fetching Log", ex);
        }
    }

    async DeleteAllLogs() {
        try {
            return await deletePersistedState(IndexDBConstants.LogEvent);
        }
        catch (ex: any) {
            console.log("Error Deleting All Logs", ex);
        }
    }

    CheckStorageSize() {
        console.log("INDEXED DB Size: " + navigator.storage.estimate());
    }

}
