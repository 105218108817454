import { Model } from '@vuex-orm/core';
import { EntityHelper } from '@/helpers/EntityHelper';

export default class Goals extends Model {
	static entity = 'Goals';
	static primaryKey = 'Id';
	static fields() {
		return {
			Id: this.uid(() => EntityHelper.uuid()),
            MyWhy: this.attr([]).nullable(),
            CareerGoals: this.attr([]).nullable(),
			MilestonesOption: this.string('')
		};
	}
    Id : any
    MyWhy !: any
    CareerGoals !: any
	MilestonesOption !: any
}
