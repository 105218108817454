



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import Presentation from "../entities/Presentation";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import { ConvertIntoAmountFormat } from "@/helpers/CalculationHelper";
let listofpremiumApproachTypes = require("../assets/pre-fill-data/premium-approach-type.json");
let lstPresTypes = require("../assets/pre-fill-data/presentation-types.json");
import VueHtml2pdf from "vue-html2pdf";

@Component({
  components: {
    ShortUserDetailView,
    VueHtml2pdf,
  },
})
export default class PrintPresentationPage extends Vue {
  private Presentation: any;
  private PresPremiumApproach: any;
  private CombinedPremiumAmount: number = 0;
  private ExistingLifetIns: string = "0";
  private RecommendedLifeIns: string = "0";
  private RemainingLifeIns: string = "0";
  private AnnualIncome: string = "0";
  private PresType: string = "";
  created() {
    this.Presentation = Presentation.query()
      .whereId(this.$route.query.presid.toString())
      .withAllRecursive()
      .last();
    this.PresPremiumApproach = listofpremiumApproachTypes.find(
      (x: any) => x.id == this.Presentation.PremiumTypeId
    );
    this.PresType = lstPresTypes.find((x: any) => {
      if (x.id == this.Presentation.PresentationTypeId) return x;
    });
    this.CalculateCoverageLimits();
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.html2Pdf.generatePdf();
    });
  }

  $refs!: {
    html2Pdf: any;
  };

  data() {
    return {
      pdfoptions: {
        margin: 10,
        // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      },
    };
  }

  CalculateCoverageLimits() {
    this.CombinedPremiumAmount =
      +this.Presentation.PresentedTo.Premium +
      +this.Presentation.PresentedTo.Spouse.Premium;

    var tempincome = Number(
      (this.CombinedPremiumAmount * 8 * 5 * 4.3333 * 12).toFixed()
    );

    this.AnnualIncome = ConvertIntoAmountFormat(tempincome.toFixed());

    var tempRecommendedLifeIns = tempincome * 10;
    this.RecommendedLifeIns = ConvertIntoAmountFormat(
      tempRecommendedLifeIns.toFixed()
    );

    var tempExistingLifeIns = Number(
      (
        +(this.Presentation.PresentedTo.InsuranceThroughWorkWholeLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.InsuranceThroughWorkWholeLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.InsuranceThroughWorkTermLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.InsuranceThroughWorkTermLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.InsuranceOutsideWorkWholeLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.InsuranceOutsideWorkWholeLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.InsuranceOutsideWorkTermLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.InsuranceOutsideWorkTermLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.Spouse.InsuranceThroughWorkWholeLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.Spouse.InsuranceThroughWorkWholeLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.Spouse.InsuranceThroughWorkTermLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.Spouse.InsuranceThroughWorkTermLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife.toString().replace(
                ",",
                ""
              )
            )
          : 0) +
        +(this.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkTermLife !=
        undefined
          ? Number.parseInt(
              this.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkTermLife.toString().replace(
                ",",
                ""
              )
            )
          : 0)
      ).toFixed()
    );

    this.ExistingLifetIns = ConvertIntoAmountFormat(
      tempExistingLifeIns.toFixed()
    );

    var tempRemainingIns = +tempRecommendedLifeIns - +tempExistingLifeIns;

    this.RemainingLifeIns = ConvertIntoAmountFormat(tempRemainingIns.toFixed());
  }
}
