
export default class GenericKeyPair {
    Value: string = '';
    Key: string = '';

    constructor(Key: string, Value: any) {
        this.Key = Key;
        this.Value = Value;
    }

}
