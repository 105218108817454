export default class LoggingConstants {
	static LoggedInSuccessfully: string = 'User Logged in successfully';
	static Logout: string = 'User Logout';
	static PresentationStated: string = 'Presentation Started';
	static PresentationCompleted: string = 'Presentation Completed';
	static NavigateTo: string = 'Navigate to - ';
	static SponsorshipPopupOpened: string = 'Sponsorship popup opened';
	static SponsorshipPopupClosed: string = 'Sponsorship popup closed';
	static SponsorShipPopupSave: string = 'Sponsorship popup saved';
	static PresentationDiscarded: string = 'Presentation discard by Type issue';
	static RatebookOpened: string = 'Ratebook popup opened';
	static RatebookClosed: string = 'Ratebook popup closed';
	static MedicalQuestionOpened: string = 'Medical Question opened';
	static MedicalQuestionClosed: string = 'Medical Question closed';
	static UWPopupOpened: string = 'Underwriting popup opened';
	static UWPopupClosed: string = 'Underwriting Popup closed';
	static FuneralExpenseOpened: string = 'Funeral Expenses popup opened';
	static FuneralExpenseClosed: string = 'Funeral Expenses popup closed';
	static ProfileChanged: string = 'Profile Changed';
	static PreplanUsed: string = 'Preplan use click';
	static RecommendedpopupOpened: string = 'Recommended Coverage Popup Opened';
	static PlanOptionChange: string = 'Plan option changed';
	static AllocateRemainingPerformed: string = 'Allocate Remaining Performed';
	static AdditionalPopupOpened: string = 'Additional Product open';
	static GroupLetteropened: string = 'Group letter opened';
	static GroupLetterVideoopened: string = 'Group letter Video opened';
	static MaterialOpened: string = 'Material opened';
	static ChildrenPopupOpen: string = 'Children popup opened';
	static ChildrenPopupClose: string = 'Children popup Closed';
	static DownloadPresentation: string = 'Presentation Downloaded';
	static PresentationClone: string = 'Presentation Code Copied';
	static ProtectionPopupOpened: string = 'Protection popup opened';
	static ADNDCertificateLoadingIssue: string = 'Unable to load AD&D Certificate';
}
