









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import moment from "moment";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { EntityHelper } from "@/helpers/EntityHelper";
let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class TSheet extends Vue {
	private basicDetailRequired: boolean = false;
	private isDialogOpen: boolean = false;

	@Prop({ default: undefined })
	private PresentationData: any;

	@Prop({ default: false })
	private ShowRCSection: boolean | undefined;

	cancel() {
		this.$emit("canceltsheet");
	}

	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("canceltsheet");
		}, 500);
	}

	data() {
		return {};
	}

	created() {
		this.isDialogOpen = true;
	}

	IsRCExist(Option: string, IsADB: Boolean) {
		var isExist: Boolean = false;
		var productId: number = IsADB ? 14 : 6;
		var presentAs: string = "";
		try {
			if (
				this.PresentationData != undefined &&
				this.PresentationData.BenefitSummary != undefined &&
				Object.keys(this.PresentationData.BenefitSummary).length > 0
			) {
				if (this.PresentationData.BenefitSummary.RCHeading != undefined) {
					presentAs =
						this.PresentationData.BenefitSummary.RCHeading == "MONTHLY INCOME"
							? "MONTHLY INCOME"
							: this.PresentationData.BenefitSummary.RCHeading == "HOME PAYMENT"
							? "HOUSE PAYMENT RIDER"
							: "";
				}

				if (presentAs != undefined && presentAs != "") {
					this.PresentationData.BenefitSummary?.[`${Option}Products`].filter(
						(product: any) => {
							if (
								product != undefined &&
								product.ProductId == productId &&
								product.PresentAs != undefined &&
								product.PresentAs == presentAs
							) {
								isExist = true;
							}
						}
					);
				}
			}
			return isExist;
		} catch (ex: any) {
			return isExist;
		}
	}

	GetRCTenureADB(Option: string) {
		var RCTenureADB = "";
		if (this.PresentationData.BenefitSummary != undefined) {
			RCTenureADB =
				this.PresentationData.BenefitSummary?.[`${Option}RCTenureADB`];
		}
		try {
			var PlanOptions = JSON.parse(
				this.PresentationData.Presentation.PlanOptions
			);
			if (PlanOptions != undefined && PlanOptions.length > 0) {
				RCTenureADB = PlanOptions.find(
					(plan: any) => plan.isSelected == true
				)?.[`${Option}Products`].find(
					(product: any) => product.ProductId == 14
				).Option;
			}
			return RCTenureADB;
		} catch (ex: any) {
			return RCTenureADB;
		}
	}
}
