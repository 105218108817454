import { IndexDBConstants } from "@/constants/IndexDBConstants"
import { GenderType } from "@/enumerations/GenderType"
import { WageType } from "@/enumerations/WageType"
import { getPersistedState } from "./StateMapper"

export function groupBy(array: any, key: string) {
    const result: any = {}
    array.forEach((item: any) => {
        if (!result[item[key]]) {
            result[item[key]] = []
        }
        result[item[key]].push(item)
    })
    return result
}

export function isJson(str: any) {
    if (typeof str !== 'string') return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

export function GetGenderTypeId(gender: string) {
    if (gender == GenderType[GenderType.MALE])
        return GenderType.MALE.toFixed();
    else if (gender == GenderType[GenderType.FEMALE])
        return GenderType.FEMALE.toFixed();
    else return 0;
}

export function GetWageTypeId(wageType: string) {
    if (wageType == undefined) return null;
    if (wageType == WageType[WageType.HOURLY])
        return WageType.HOURLY.toFixed();
    else if (wageType == WageType[WageType.WEEKLY])
        return WageType.WEEKLY.toFixed();
    else if (wageType == WageType[WageType.MONTHLY])
        return WageType.MONTHLY.toFixed();
    else if (wageType == WageType[WageType.YEARLY])
        return WageType.YEARLY.toFixed();
    else return null;
}

export function GetGenderNameById(gender: number) {
    return GenderType[gender];
}


export function StringToBolean(type: string) {
    if (type != undefined && type.toLowerCase() == "yes")
        return true;
    if (type != undefined && type.toLowerCase() == "na")
        return null;
    else return false;
}

export function BooleanToString(value: any) {

    if (value != undefined && value == true)
        return "YES";
    if (value == undefined)
        return "NA";
    else return "NO";
}


export async function GetSyncVersionByType(type: string) {
    if (type != undefined && type != "") {
        var syncVersions: any = await getPersistedState(IndexDBConstants.SyncVersionKey);
        if (syncVersions != undefined && syncVersions.length > 0) {
            var syncTypes = syncVersions.find((x: any) => (x.Name == type));
            if (syncTypes != undefined)
                return syncTypes.Version;
            else return 0;
        }
        else return 0;
    }
    else return 0;
}

export function Camelize(str: any) {
    if (str == undefined) return "";

    str = str.toLowerCase().split(' ');
    var result: string = "";
    str.forEach((element: any) => {
        var camelWord = element.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match: any, index: any) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toUpperCase() : match.toLowerCase();
        });
        if (camelWord == ',' || camelWord == '.') result += camelWord;
        else result += camelWord + ' ';
    });
    return result;
}

export function Base64ToArrayBuffer(base64: any) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}