import { Model } from '@vuex-orm/core';
import { EntityHelper } from '@/helpers/EntityHelper';

export default class Calculator extends Model {
	static entity = 'calculator';
	static primaryKey = 'Id';
	static fields() {
		return {
            Id: this.uid(() => EntityHelper.uuid()),
            IsIncomeSelected: this.boolean(true),
            Income: this.string(null).nullable(),
            Alp: this.string(null).nullable(),
            Contract: this.string(null).nullable(),
            AlpPerSale: this.string(null).nullable(),
            Show: this.string(null).nullable(),
            Bonus: this.string(null).nullable(),
            Close: this.string(null).nullable(),
            Ntg: this.string(null).nullable(),
            SponsorCollection: this.boolean(false),
            Quality: this.boolean(false),
            MarketBackground: this.boolean(false),
            VeteranFamilyLeads: this.boolean(false),
            ScriptMemorization: this.boolean(false),
            ProductKnowledge: this.boolean(false),
            Activity: this.boolean(false),
            MobilePlanet: this.boolean(false),
            Presenting: this.boolean(false),
            AlpPerSaleSelected: this.boolean(false),
            SellingIndividuals: this.boolean(false),
            DownClosing: this.boolean(false),
            SellingSeniors: this.boolean(false),
            PhoneSetting: this.boolean(false),
            HpPro: this.boolean(false),
            LeadershipDevelopment: this.boolean(false),
            EApp: this.boolean(false),
            HowGetPaid: this.boolean(false),
            FieldTraining: this.boolean(false),
            ZoomTime: this.boolean(false),
            ConvertingSponsors: this.boolean(false)
        }
    }
    Id: any
    IsIncomeSelected !: boolean
    Income !: string
    Alp !: string
    Contract !: string
    AlpPerSale !: string
    Show !: string
    Bonus !: string
    Close !: string
    Ntg !: string
    SponsorCollection!: boolean;
    Quality!: boolean;
    MarketBackground!: boolean;
    VeteranFamilyLeads!: boolean;
    ScriptMemorization!: boolean;
    ProductKnowledge!: boolean;
    Activity!: boolean;
    MobilePlanet!: boolean;
    Presenting!: boolean;
    AlpPerSaleSelected!: boolean;
    SellingIndividuals!: boolean;
    DownClosing!: boolean;
    SellingSeniors!: boolean;
    PhoneSetting!: boolean;
    HpPro!: boolean;
    LeadershipDevelopment!: boolean;
    EApp!: boolean;
    HowGetPaid!: boolean;
    FieldTraining!: boolean;
    ZoomTime!: boolean;
    ConvertingSponsors!: boolean
}