import { EntityHelper } from '@/helpers/EntityHelper'
import { Model, Uid } from '@vuex-orm/core'
import Presentation from './Presentation'

export default class User extends Model {
  static entity = 'users'
  static primaryKey = 'Id'

  static fields() {
    return {
      Id: this.uid(() => EntityHelper.uuid()),
      Name: this.string(''),
      Username: this.string(''),
      Password: this.string(''),
      Presentations: this.hasMany(Presentation, "Id"),
      FirstName: this.string(''),
      LastName: this.string(''),
      AgentNo: this.string(''),
      LicenseStates: this.string(''),
      Email: this.string(''),
      Phone: this.string(''),
      Country: this.string(''),
      Territory: this.string(''),
      Office: this.string(''),
      Position: this.string(''),
      Union: this.string(''),
      Language: this.string(''),
      TrainingConfiguration: this.string(''),
      LicenseNo: this.string(''),
      UnionCode: this.string(''),
      UserId: this.number(0),
      ProfilePiclink: this.string('')
    }
  }
  get fullname() {
    return this.FirstName + " " + this.LastName;
  }

  get fullShrinkname() {
    if (this.fullname.length > 20) {
      return this.FirstName[0] + ". " + this.LastName;

      // var name = this.fullname;
      // var words = name.split(' ')
      // if (words != undefined && words.length > 0) {
      //   var tempfullName = "";
      //   var i = 0;
      //   words.forEach(element => {
      //     if (i == 0)
      //       tempfullName += element[0] + ".";
      //     else
      //       tempfullName += " " + element;
      //     i++;
      //   });
      //   return tempfullName;
      // }
      // return this.fullname;
    }
    return this.FirstName + " " + this.LastName;
  }

  public Id!: Uid
  public Name!: string
  public Username!: string
  public Password!: string
  public FirstName!: string
  public LastName!: string
  public AgentNo!: string
  public LicenseStates!: string
  public Email!: string
  public Phone!: string
  public Country!: string
  public Territory!: string
  public Office!: string
  public Position!: string
  public Union!: string
  public Language !: string
  public TrainingConfiguration !: string
  public LicenseNo!: string
  public UnionCode!: string
  public ProfilePiclink!: string
  public UserId!: number
}
