










































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import {
	LoadPremiumAmountForPrinting,
	LoadPrintPDFData,
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
	ConvertIntoAmountFormatWithNegativeDecimal,
} from "../helpers/CalculationHelper";
import MenuComponent from "../components/menu.vue";
import Pres from "../entities/Presentation";
import { CopyTextToClipBoard } from "../helpers/UIHelper";
import Presentation from "../entities/Presentation";
import VueHtml2pdf from "vue-html2pdf";
import { EntityHelper } from "../helpers/EntityHelper";
import User from "@/entities/User";
let placeholderuser = require("../assets/placeholderuser.png");
import JsonViewer from "vue-json-viewer";
import {
	GetRequestWithAuthorization,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { APIService } from "@/services/APIService";
import { ChartType } from "../enumerations/ChartType";
import BarChart from "../components/charts/BarChart.vue";
import PieChart from "../components/charts/Pie.vue";

import {
	AmountFormatAllFields,
	GetProductNameById,
	PresentationDTOToEntity,
} from "@/helpers/MappingHelper";
import {
	GetAscFullName,
	GetFullName,
	GetPrimaryLicense,
} from "@/helpers/APIExtentions";
import { getPersistedState } from "@/helpers/StateMapper";
import { PresentationType } from "../enumerations/PresentationType";
import PresentationSync from "@/helpers/PresentationSync";
import { GetUserEmail } from "../helpers/APIExtentions";
import LoggingHelper from "@/helpers/LoggingHelper";
import LoggingConstants from "@/constants/LoggingConstants";
import moment from "moment";
import { EventBusLayout } from "@/bus/EventBusLayout";
import { GetHEXColorByPresentationSubTypeName } from "@/helpers/DashboardHelper";
import { PresentationSubType } from "@/enumerations/PresentationSubType";

let MonthListData: any = require("../assets/pre-fill-data/month-list.json");

@Component({
	components: {
		JsonViewer,
		ShortUserDetailView,
		MenuComponent,
		VueHtml2pdf,
		BarChart,
		PieChart,
	},
})
export default class PresentationDetail extends Vue {
	private presentationsa: any = [];
	private CurrentMonth: string = "";
	private bsRates: any = null;
	private lstStates: any = null;
	private generatedCode!: string;
	private imgSource!: string;
	private Name: string = GetFullName();
	private AscName: string = GetAscFullName();
	private UserEmail = " ( " + GetUserEmail() + " )";
	private LicenseNo: string = "";
	private LicenseState: string = "";
	private UnionCode: string = GetPrimaryLicense();
	private CurrentDate = new Date();
	private ShowPresentationType: boolean = false;
	private ShowDispositionType: boolean = false;
	private ShowHouseHoldType: boolean = false;
	private ShowPresentationSubType: boolean = false;
	private MonthsOfYear: any = [
		{ id: 1, month: "January" },
		{ id: 2, month: "February" },
		{ id: 3, month: "March" },
		{ id: 4, month: "April" },
		{ id: 5, month: "May" },
		{ id: 6, month: "June" },
		{ id: 7, month: "July" },
		{ id: 8, month: "August" },
		{ id: 9, month: "September" },
		{ id: 10, month: "October" },
		{ id: 11, month: "November" },
		{ id: 12, month: "December" },
	];
	private PresentationMonthlyHistory: any = null;
	private showchartCounter: number = 0;
	private ShowPresentationPopUp: boolean = false;
	private MonthList: any = MonthListData.filter(
		(x: any) => x.id <= this.CurrentDate.getMonth() + 1
	);

	private selectedMonth: any = this.MonthList.find(
		(x: any) => x.id == this.CurrentDate.getMonth() + 1
	);
	private YearList: any = [];
	private SelectedYear: any = null;
	private PresentationTypes: any = [];

	ConvertIntoAmountFormatWithDecimalUI(event: any) {
		if (event == null) return "0.00";
		return ConvertIntoAmountFormatWithNegativeDecimal(event.toFixed(2));
	}
	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		this.$store.state.loader = true;
		EventBusLayout.$on("RoutedToEvt", this.PresHistoryRoutedToEvt);

		var bsRatesPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.BenefitSummaryRatesEndpoint,
			[],
			IndexDBConstants.BenefitSummaryRatesKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);
		var self = this;
		Promise.all([bsRatesPromise, stateListPromise]).then(async (values) => {
			self.bsRates = values[0];
			self.lstStates = values[1];

			// let dateMonth = new Date().getMonth() + 1;
			// this.CurrentMonth = new Date().toLocaleString("en-us", { month: "long" });
			var currentDate = new Date();
			this.SelectedYear = currentDate.getFullYear().toString();

			var dataURL = localStorage.getItem("profileImage");

			if (dataURL != null && dataURL != undefined) this.imgSource = dataURL;
			else if (this.$route.path != "/home" && this.$route.path != "/training") {
				this.imgSource = placeholderuser;
			} else {
				this.imgSource = placeholderuser;
			}

			var user = User.query().last();
			if (user && user.FirstName) {
				this.Name = user.fullShrinkname;
				this.UnionCode = user.Union;
				if (
					user.LicenseStates != null &&
					user.LicenseStates != undefined &&
					user.LicenseStates != ""
				) {
					var licStates = JSON.parse(user.LicenseStates);
					var lic = licStates.find(
						(i: { Primary: boolean }) => i.Primary == true
					);
					if (lic) {
						this.LicenseNo = lic.Number;
						this.LicenseState = lic.State;
					}
				}
			}
			this.CreateYearList();
			var subPresTypes = await getPersistedState("PresentationTypesVeteran");
			this.PresentationTypes = subPresTypes;
			this.PresentationTypes.splice(
				this.PresentationTypes.indexOf(
					this.PresentationTypes.find(
						(i: { Name: any }) => i.Name == "PRO LEAD"
					)
				),
				1
			);

			await this.FetchPresentations(this.SelectedYear);

			this.$store.state.loader = false;
		});
	}

	PresHistoryRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.PresHistoryRoutedToEvt);
	}
	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	CreateYearList() {
		var CurrentUser = localStorage.getItem("user");
		var lastYear = 0;
		var creationYear = 0;
		var currentYear = moment().year();
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			creationYear = moment(userObj.UserDetail.CreateDate).year();
			lastYear = moment().year();
			for (let i = creationYear; i <= lastYear; i++) {
				this.YearList.push(i);
			}
			this.SelectedYear = currentYear;
		}
	}

	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined) return 0.0;
			else if (typeof amt == "string")
				return ConvertIntoAmountFormatWithDecimal(amt);
			else return ConvertIntoAmountFormatWithDecimal(amt.toFixed(2));
		} catch (ex: any) {
			return 0.0;
		}
	}

	removeChartData(item: any) {
		if (item.isRemoved == true) item.isRemoved = false;
		else item.isRemoved = true;
		var k = JSON.parse(JSON.stringify(this.$data.PopupChartTypeData));
		var k = k.filter(
			(x: any) => x.isRemoved == undefined || x.isRemoved == false
		);
		this.$data.PopupChartData = this.FillChartDataFromAPIData(k);
	}

	ChartClick(chartData: any, popupHeading: string, chartDataArray: any) {
		if (popupHeading === "Presentation Sub Types Chart")
			this.ShowPresentationPopUp = true;
		else this.ShowPresentationPopUp = false;

		this.$data.PieChartPopup = true;
		this.$data.popupChartHeading = popupHeading;
		this.$data.PopupChartData = chartData;
		this.$data.PopupChartLabels = chartData.labels;
		if (chartDataArray != undefined) {
			chartDataArray.forEach((element: any) => {
				element.isRemoved = false;
			});
		}
		this.$data.PopupChartTypeData = chartDataArray.sort(
			(a: any, b: any) => b.Count - a.Count
		);
	}

	UpdateDefaultChart(chartType: any) {
		if (chartType == 1) {
			if (this.ShowPresentationType == true) {
				this.showchartCounter--;
				this.ShowPresentationType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.showchartCounter++;
					this.ShowPresentationType = true;
				}
			}
		} else if (chartType == 2) {
			if (this.ShowDispositionType == true) {
				this.showchartCounter--;
				this.ShowDispositionType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowDispositionType = true;
					this.showchartCounter++;
				}
			}
		} else if (chartType == 3) {
			if (this.ShowHouseHoldType == true) {
				this.showchartCounter--;
				this.ShowHouseHoldType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowHouseHoldType = true;
					this.showchartCounter++;
				}
			}
		} else if (chartType == 4) {
			if (this.ShowPresentationSubType == true) {
				this.showchartCounter--;
				this.ShowPresentationSubType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowPresentationSubType = true;
					this.showchartCounter++;
				}
			}
		}
	}

	@Watch("chartselectiondialog")
	OnPopupClosed() {
		if (this.$data.chartselectiondialog == false) {
			if (this.showchartCounter == 0) {
				this.ShowPresentationType = true;
				this.ShowDispositionType = true;
				this.showchartCounter = 2;
			}

			var ChartList: any = [];
			if (this.ShowPresentationType == true)
				ChartList.push(ChartType.Presentation_Type);
			if (this.ShowDispositionType == true)
				ChartList.push(ChartType.Disposition_Type);
			if (this.ShowHouseHoldType == true)
				ChartList.push(ChartType.Household_Type);
			if (this.ShowPresentationSubType == true)
				ChartList.push(ChartType.Presentation_Subtypes);

			var params: GenericKeyPair[] = [];
			PostRequestWithAuthorization(
				ApiEndPointConstants.SaveDefaultChartEndPoint,
				params,
				ChartList
			);
		}
	}

	async DownloadPresentation(presentation: any) {
		if (!window.navigator.onLine) {
			this.$store.state.messageText = this.$t("General.NeedActiveConnect");
			this.$store.state.showMessage = true;
			return;
		}
		this.$data.presKey++;
		this.$store.state.loader = true;
		APIService.GetPresentationById(presentation.Id).then(async (res: any) => {
			if (res != undefined && res.Result != undefined) {
				var pres: Presentation = await PresentationDTOToEntity(res.Result);
				this.$data.currentPresentationToPrint.Presentation = pres;
				LoadPremiumAmountForPrinting(this.$data.currentPresentationToPrint);
				if (
					res.Result.PlanOptions != undefined &&
					res.Result.PlanOptions.length > 0
				) {
					var benSummary = res.Result.PlanOptions[0].BenefitsCalculation;
					if (benSummary != undefined) {
						this.$data.currentPresentationToPrint.BenefitSummary =
							AmountFormatAllFields(benSummary);
					}
					this.$data.currentPresentationToPrint.MBD =
						res.Result.PlanOptions[0].MBD;
					this.$data.currentPresentationToPrint.TotalAP =
						res.Result.PlanOptions[0].TotalAP;

					await GetProductNameById(
						res.Result.PlanOptions[0].PrimaryProducts,
						res.Result.PlanOptions[0].SpouseProducts
					);

					this.$data.currentPresentationToPrint.BenefitSummary.PrimaryProducts =
						res.Result.PlanOptions[0].PrimaryProducts;
					this.$data.currentPresentationToPrint.BenefitSummary.SpouseProducts =
						res.Result.PlanOptions[0].SpouseProducts;
					// this.$data.currentPresentationToPrint.Presentation =
					//   res.Result.PlanOptions;
				}
				if (
					this.$data.currentPresentationToPrint.Presentation != undefined &&
					this.$data.currentPresentationToPrint.Presentation.PresentedTo !=
						undefined &&
					this.$data.currentPresentationToPrint.Presentation.PresentedTo
						.ChildrenString != undefined &&
					this.$data.currentPresentationToPrint.Presentation.PresentedTo
						.ChildrenString != ""
				)
					this.$data.Childrens = JSON.parse(
						this.$data.currentPresentationToPrint.Presentation.PresentedTo
							.ChildrenString
					);
				this.$data.OwnTrue =
					this.$data.currentPresentationToPrint.Presentation.PresentedTo
						.OwnOrRent == "OWN";
				this.$data.RentTrue =
					this.$data.currentPresentationToPrint.Presentation.PresentedTo
						.OwnOrRent == "RENT";
				this.$data.pdfoptions.filename =
					EntityHelper.capitalizeTheFirstLetterOfEachWord(
						this.$data.currentPresentationToPrint.Presentation.PresentedTo
							.GetNameHolder
					).replaceAll(".", "") +
					"-" +
					this.$data.currentPresentationToPrint.Presentation.PresentationDay;
				(".pdf");
				setTimeout(() => {
					this.$refs.html2Pdf.generatePdf();
					this.$store.state.loader = false;
				}, 3000);
			}
		});

		LoggingHelper.getInstance().LogEvent(LoggingConstants.DownloadPresentation);
	}

	async SelectedYearChange(evt: any) {
		this.PresentationMonthlyHistory = null;
		this.presentationsStats = null;
		await this.FetchPresentations(this.SelectedYear);
		// var date = new Date();
		// this.$data.CalendarValue = new Date(
		// 	date.getFullYear(),
		// 	this.selectedMonth.id - 1,
		// 	date.getDay()
		// );
	}

	ChangePresentationType(evt: any) {
		this.$store.state.loader = true;
		this.UpdateChartdataforPresentationSubType();
		this.$data.PresentationSubChartData = this.FillChartDataFromAPIData(
			this.presentationsStats.PresentationSubTypeChartData
		);
		this.$store.state.loader = false;
	}

	ChangePresentationTypeSingle(evt: any) {
		this.$store.state.loader = true;
		this.UpdateChartdataforPresentationSubType();
		this.$data.PresentationSubChartData = this.FillChartDataFromAPIData(
			this.presentationsStats.PresentationSubTypeChartData
		);

		this.$data.PopupChartData = this.$data.PresentationSubChartData;
		this.$data.PopupChartLabels = this.$data.PopupChartData.labels;
		if (this.presentationsStats.PresentationSubTypeChartData != undefined) {
			this.presentationsStats.PresentationSubTypeChartData.forEach(
				(element: any) => {
					element.isRemoved = false;
				}
			);
		}
		this.$data.PopupChartTypeData =
			this.presentationsStats.PresentationSubTypeChartData?.sort(
				(a: any, b: any) => b.Count - a.Count
			);

		this.$store.state.loader = false;
	}

	data() {
		return {
			chartselectiondialog: false,
			presentationcodescroll: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			CalendarValue: undefined,
			presKey: 1,
			currentPresentationToPrint: { Presentation: {}, BenefitSummary: {} },
			presentationcode: false,
			presentationData: false,
			PieChartPopup: false,
			presentationJSON: "",
			dashboardscroll: {
				bar: {
					opacity: "0.75",
					minSize: ".3",
					background: "#00568b",
				},
			},
			Presentations: this.presentationsa,
			pdfoptions: {
				margin: 6,
				filename: "hehehe.pdf",
				// pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
			},
			Childrens: [],
			OwnTrue: false,
			RentTrue: false,
			popupChartHeading: "",
			PresTypeChartData: {},
			DispositionChartData: {},
			PresentationSubChartData: {},
			HouseHoldTypeChartData: {},
			PopupChartData: {},
			PopupChartTypeData: [],
			PopupChartLabels: [],
			chartOptions: {
				responsive: true,
			},
		};
	}

	RoutedTo(route: string, props: any = undefined) {
		if (route != "StartPresentation" && route != "/presentationhistory") {
			if (route == "PresentationHistoryDetail") {
				if (props != undefined) {
					this.$router.push({
						name: route,
						params: props,
					});
				} else {
					this.$router.push(route.toString());
				}
			} else {
				this.$router.push(route.toString());
			}
		}
	}

	async generateCode(presentation: any) {
		this.generatedCode = presentation.PresentationCode;
		this.$data.presentationcode = true;
		LoggingHelper.getInstance().LogEvent(LoggingConstants.PresentationClone);
	}

	showPresentationData(presentation: any) {
		this.$data.presKey++;
		this.$store.state.loader = true;
		APIService.GetPresentationById(presentation.Id).then((res: any) => {
			if (res != undefined && res.Result != undefined) {
				this.$data.presentationData = true;
				this.$data.presentationJSON = res.Result;
				this.$store.state.loader = false;
			}
		});
	}
	TogglePieCarousal() {
		if (this.$refs.pieCarousal != undefined) {
			if (this.$refs.pieCarousal.hasNext) this.$refs.pieCarousal.next();
			else this.$refs.pieCarousal.prev();
		}
	}

	FillChartDataFromAPIData(APIData: any) {
		if (APIData != undefined && APIData.length > 0) {
			var labels: any = [];
			var labelCount: any = [];
			var labelColors: any = [];

			APIData.forEach((element: any) => {
				labels.push(element.Title);
				labelCount.push(element.Count);
				labelColors.push(element.ColorHEX);
			});
			return {
				labels: labels,
				datasets: [
					{
						borderColor: "#F1F1F1",
						borderWidth: 0.5,
						data: labelCount,
						backgroundColor: labelColors,
					},
				],
			};
		} else {
			return {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [labelColors],
					},
				],
			};
		}
	}

	async OnCopyCode() {
		await CopyTextToClipBoard(this.generatedCode.toString());
		this.$store.state.messageText = "Copied to clipboard";
		this.$store.state.showMessage = true;
		this.$data.presentationcode = false;
	}

	private TotalRecord: number = 0;
	private localRecord: number = 0;
	private TotalSales: number = 0;
	private TotalALP: number = 0;
	private CloseRatio = "0";
	private ALPPerSale: number = 0;
	private AHPPerSale: number = 0;
	private PlusLeadCollected: number = 0;
	private PlusLeadPerPresentation = "0";
	private TimePerPresentation = "00:00:00";
	private DayWorked: number = 0;
	private ALPRemaining: number = 0;
	private PresentationType: any = [];
	private SelectedPresentationType: any = [];
	private presentationsStats: any = [];
	private PresentationDefaultCharts: any = [];

	async FetchPresentations(year: number) {
		this.$store.state.loader = true;
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair("year", year));
		var presentations = await GetRequestWithAuthorization(
			"Presentation/GetPresentationsHistoryDetailForSpecificYear",
			params
		);

		if (presentations != undefined && presentations.Result != undefined) {
			this.PresentationMonthlyHistory =
				presentations.Result.PresentationHistoryMonthlyDetail;
			if (
				this.PresentationMonthlyHistory == null ||
				this.PresentationMonthlyHistory == undefined
			) {
				this.CreateMonthlyHistoryObject();
			}
			this.presentationsStats = presentations.Result.presentationStats;
			this.PresentationDefaultCharts =
				presentations.Result.PresentationDefaultChart;
			if (this.PresentationMonthlyHistory != null) {
				this.PresentationMonthlyHistory.forEach((MonthlyDetails: any) => {
					var CloseRatio = 0;
					var AlpPerSale = 0;
					if (
						MonthlyDetails.TotalPresentation > 0 &&
						MonthlyDetails.TotalSales > 0
					) {
						CloseRatio =
							(MonthlyDetails.TotalSales / MonthlyDetails.TotalPresentation) *
							100;
						MonthlyDetails.CloseRatio =
							CloseRatio > 0
								? ConvertIntoAmountFormatWithDecimal(
										CloseRatio.toFixed(2).toString()
								  )
								: 0;
					}

					if (MonthlyDetails.TotalALP > 0 && MonthlyDetails.TotalSales > 0) {
						AlpPerSale = MonthlyDetails.TotalALP / MonthlyDetails.TotalSales;
						MonthlyDetails.AlpPerSale =
							AlpPerSale > 0
								? ConvertIntoAmountFormatWithDecimal(
										AlpPerSale.toFixed(2).toString()
								  )
								: 0;
					}
				});
			}
			if (this.presentationsStats != undefined) {
				this.$data.PresTypeChartData = this.FillChartDataFromAPIData(
					this.presentationsStats.PresentationTypeChartData
				);

				if (
					this.PresentationDefaultCharts != undefined &&
					this.PresentationDefaultCharts.length > 0
				) {
					var DefaultArray = this.PresentationDefaultCharts;
					this.showchartCounter = 0;
					DefaultArray.forEach((productelement: number) => {
						this.showchartCounter++;
						if (productelement == ChartType.Presentation_Type)
							this.ShowPresentationType = true;
						if (productelement == ChartType.Disposition_Type)
							this.ShowDispositionType = true;
						if (productelement == ChartType.Household_Type)
							this.ShowHouseHoldType = true;
						if (productelement == ChartType.Presentation_Subtypes)
							this.ShowPresentationSubType = true;
					});
				} else {
					this.ShowPresentationType = true;
					this.ShowDispositionType = true;
					this.showchartCounter = 2;
				}
				this.PresentationType =
					this.presentationsStats.PresentationTypeDropDown;
				this.SelectedPresentationType =
					this.presentationsStats.SelectedPresentationType;

				this.UpdateChartdataforPresentationSubType();
				this.$data.PresentationSubChartData = this.FillChartDataFromAPIData(
					this.presentationsStats.PresentationSubTypeChartData
				);
				this.$data.DispositionChartData = this.FillChartDataFromAPIData(
					this.presentationsStats.DispositionChartData
				);

				this.$data.PresentationSubChartData = this.FillChartDataFromAPIData(
					this.presentationsStats.PresentationSubTypeChartData
				);

				this.$data.HouseHoldTypeChartData = this.FillChartDataFromAPIData(
					this.presentationsStats.HouseHoldTypeChartData
				);
			}
		}
		this.$store.state.loader = false;
	}

	CreateMonthlyHistoryObject() {
		this.PresentationMonthlyHistory = [];
		for (let i = 0; i < this.MonthsOfYear.length; i++) {
			this.PresentationMonthlyHistory.push({
				AhpPerSale: 0,
				AlpPerSale: 0,
				CloseRatio: 0,
				MonthId: this.MonthsOfYear[i].id,
				MonthName: this.MonthsOfYear[i].month,
				PlusLeadPerPresentation: 0,
				TotalAHP: 0,
				TotalALP: 0,
				TotalPresentation: 0,
				TotalReferals: 0,
				TotalSales: 0,
				TotalTime: 0,
			});
		}
	}

	UpdateChartdataforPresentationSubType() {
		if (
			this.SelectedPresentationType != null &&
			this.SelectedPresentationType != undefined
		) {
			var presType = this.PresentationTypes.find(
				(i: any) => i.Id == this.SelectedPresentationType.Id
			);
			if (
				presType != null &&
				presType != undefined &&
				presType.SubTypes.length > 0
			) {
				this.presentationsStats.PresentationSubTypeChartData = [];
				var presentationSubTypeIdList = presType.SubTypes.map((x: any) => x.Id);
				var SelectedData =
					this.presentationsStats.PresentationSubTypeCountData.filter(
						(x: any) => presentationSubTypeIdList.includes(x.Id)
					);
				SelectedData.forEach((chartdata: any) => {
					var title = presType.SubTypes.filter(
						(x: any) => x.Id == chartdata.Id
					)[0].Name;

					if (title.toUpperCase() == "RETURN CARD") {
						if (presType.Id == 5 || presType.Name == "UNION") {
							var test = {
								Title: title,
								Count: chartdata.Count,
								ColorHEX: GetHEXColorByPresentationSubTypeName(
									title + presType.Name
								),
							};
						} else if (presType.Id == 10 || presType.Name.includes("NO COST")) {
							var test = {
								Title: title,
								Count: chartdata.Count,
								ColorHEX: GetHEXColorByPresentationSubTypeName(
									title + presType.Name
								),
							};
						} else {
							var test = {
								Title: title,
								Count: chartdata.Count,
								ColorHEX: GetHEXColorByPresentationSubTypeName(title),
							};
						}
						this.presentationsStats.PresentationSubTypeChartData.push(test);
					} else {
						var test = {
							Title: title,
							Count: chartdata.Count,
							ColorHEX: GetHEXColorByPresentationSubTypeName(title),
						};
						this.presentationsStats.PresentationSubTypeChartData.push(test);
					}
				});
			}
		}
	}

	get GetTotalPresentations() {
		if (
			this.presentationsStats != undefined &&
			this.presentationsStats.TotalPresentation != null &&
			this.presentationsStats.TotalPresentation != undefined
		) {
			return this.presentationsStats.TotalPresentation;
		}
		return 0;
	}
	get GetTotalSales() {
		if (
			this.presentationsStats != undefined &&
			this.presentationsStats.TotalSales != null &&
			this.presentationsStats.TotalSales != undefined
		) {
			return this.presentationsStats.TotalSales;
		}
		return 0;
	}

	get GetTotalALP() {
		if (
			this.presentationsStats != undefined &&
			this.presentationsStats.TotalALP != null &&
			this.presentationsStats.TotalALP != undefined
		) {
			return this.presentationsStats.TotalALP;
		}
		return 0;
	}

	get GetCloseRatio() {
		var CloseRatio = 0;
		if (this.GetTotalPresentations > 0 && this.GetTotalSales > 0) {
			CloseRatio = (this.GetTotalSales / this.GetTotalPresentations) * 100;
			return CloseRatio > 0
				? ConvertIntoAmountFormatWithDecimal(CloseRatio.toFixed(2).toString())
				: 0;
		}
		return CloseRatio;
	}

	get GetAlpPerSale() {
		var AlpPerSale = 0.0;
		if (this.GetTotalALP > 0 && this.GetTotalSales > 0) {
			AlpPerSale = this.GetTotalALP / this.GetTotalSales;
			return AlpPerSale > 0 ? AlpPerSale : 0.0;
		}
		return AlpPerSale;
	}

	get GetAhpPerSale() {
		var AhpPerSale = 0.0;
		var TotalAHP =
			this.presentationsStats != undefined &&
			this.presentationsStats.TotalAHP != null &&
			this.presentationsStats.TotalAHP != undefined
				? this.presentationsStats.TotalAHP
				: 0;

		if (TotalAHP > 0 && this.GetTotalSales > 0) {
			AhpPerSale = TotalAHP / this.GetTotalSales;
			return AhpPerSale > 0 ? AhpPerSale : 0.0;
		}
		return AhpPerSale;
	}

	get GetPlusLeadsCollected() {
		var plusLeadCollected = 0;
		if (
			this.presentationsStats != undefined &&
			this.presentationsStats.TotalReferals != null &&
			this.presentationsStats.TotalReferals != undefined
		) {
			return this.presentationsStats.TotalReferals;
		}
		return 0;
		// if (this.SelectedPresentationsList != null) {
		// 	this.SelectedPresentationsList.forEach((pres: any) => {
		// 		if (pres.TotalReferals != null) {
		// 			plusLeadCollected += pres.TotalReferals;
		// 		}
		// 	});
		// 	return plusLeadCollected > 0 ? plusLeadCollected : 0;
		// }
		// return plusLeadCollected;
	}

	get GetPlusLeadsPerPresentation() {
		var plusLeadsPerPresentation = 0;
		if (this.GetTotalPresentations > 0 && this.GetPlusLeadsCollected > 0) {
			plusLeadsPerPresentation =
				this.GetPlusLeadsCollected / this.GetTotalPresentations;
			return plusLeadsPerPresentation > 0
				? ConvertIntoAmountFormatWithDecimal(
						plusLeadsPerPresentation.toFixed(2).toString()
				  )
				: 0;
		}
		return plusLeadsPerPresentation;
	}

	get GetTimePerPresentations() {
		var timePerPresentation = "00:00:00";
		var totalseconds = 0;

		// if (this.PresentationMonthlyHistory != null) {
		// 	var totalseconds = 0;
		// 	this.PresentationMonthlyHistory.forEach((pres: any) => {
		// 		if (pres.TotalTimeSeconds != null) {
		// 			totalseconds += pres.TotalTimeSeconds;
		// 		}
		// 	});
		if (
			this.presentationsStats != undefined &&
			this.presentationsStats.TotalTime != null &&
			this.presentationsStats.TotalTime != undefined
		) {
			totalseconds = this.presentationsStats.TotalTime;
			var TimeinSeconds = totalseconds / this.GetTotalPresentations;
			return TimeinSeconds > 0
				? new Date(TimeinSeconds * 1000).toISOString().slice(11, 19)
				: "00:00:00";
		}
		return timePerPresentation;
	}

	$refs!: {
		fullData: any;
		html2Pdf: any;
		pieCarousal: any;
	};
}
