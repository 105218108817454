


























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Sponsership from "../entities/Sponsership";

@Component
export default class SponsershipCard extends Vue {
  @Prop({ default: undefined })
  CareerPerson!: Sponsership;

  data() {
    return {
      PhoneRulesNotRequired: [
        (v: any) =>
          v == undefined || v == "" || v.length > 13 || "Phone must be valid",
      ],
      EmailRules: [
        (value: any) => {
          if (value.length > 0) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          } else {
            return true;
          }
        },
      ],
    };
  }
}
