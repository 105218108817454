import { Model, Uid } from '@vuex-orm/core'

export class PlanEntity extends Model {
    static entity = 'Plans'
    static primaryKey = 'id'


    static fields() {
        return {
            id: this.attr(null),
            PlanId!: this.number(0),
            IsPlanEnabled: this.boolean(false),
            PlanName: this.string(''),
            Coverage: this.number(0),
            MonthlyCharge: this.number(0),
            DailyCharge: this.number(0),
            PlanCode: this.string(""),
            SelectedOption: this.string("")
        }
    }

    public PlanId!: number
    public PlanName!: string;
    public Coverage!: number;
    public MonthlyCharge!: number;
    public DailyCharge!: number;
    public HourlyCharge!: number;
    public PlanCode!: string;
    public IsPlanEnabled!: boolean;
    public SelectedOption!: string;

}

export class PresentationPlans extends Model {
    static entity = 'PresentationPlans'

    static primaryKey = ['planId', 'presentationId']

    static fields() {
        return {
            presentationId: this.uid(),
            planId: this.attr(null)
        }
    }
    public presentationId!: Uid;
    public planId!: any
}

export class PersonPlan extends Model {
    static entity = 'PersonPlan'

    static primaryKey = ['personId', 'planId']

    static fields() {
        return {
            personId: this.attr(null),
            planId: this.attr(null)
        }
    }

    public personId!: Uid;
    public planId!: any
}

export class SpousePlan extends Model {
    static entity = 'SpousePlan'

    static primaryKey = ['spouseId', 'planId']

    static fields() {
        return {
            spouseId: this.attr(null),
            planId: this.attr(null)
        }
    }

    public spouseId!: Uid;
    public planId!: any
}