export enum MaritalStatus {
	single = 1,
	married,
	divorced,
	widowed,
	separated,
	domestic_partner
}

export const MaritalStatusDiscription = new Map<number, string>([
	[ MaritalStatus.single, 'Single' ],
	[ MaritalStatus.married, 'Married' ],
	[ MaritalStatus.divorced, 'Divorced' ],
	[ MaritalStatus.widowed, 'Widowed' ],
	[ MaritalStatus.separated, 'Separated' ],
	[ MaritalStatus.domestic_partner, 'Domestic Partner' ]
]);
