























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component({
	components: {},
})
export default class AppBarFooter extends Vue {}
