export enum HpProHouseHoldType {
	//[Description("Single")]
	single,
	//[Description("Single Parent")]
	single_parent,
	//[Description("Married")]
	married,
	//[Description("Family")]
	family,
	//[Description("Retired")]
	retired,
	//[Description("Single/ Retired")]
	single_retired,
	//[Description("Married/ Retired")]
	married_retired
}

export const HpProHouseHoldTypeDiscription = new Map<number, string>([
	[ HpProHouseHoldType.single, 'Single' ],
	[ HpProHouseHoldType.single_parent, 'Single Parent' ],
	[ HpProHouseHoldType.married, 'Married' ],
	[ HpProHouseHoldType.family, 'Family' ],
	[ HpProHouseHoldType.retired, 'Retired' ],
	[ HpProHouseHoldType.single_retired, 'Single/ Retired' ],
	[ HpProHouseHoldType.married_retired, 'Married/ Retired' ]
]);
