import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import VuexORM from '@vuex-orm/core';
import Auth from './modules/auth.module';
import User from '@/entities/User';
import Presentation from '@/entities/Presentation';
import PrimaryPerson from '@/entities/PrimaryPerson';
import Spouse from '@/entities/Spouse';
import Children from '@/entities/Children';
import AdultChildren from '@/entities/AdultChildren';
import PlanSummary from '@/entities/PlanSummary';
import { PlanEntity, PresentationPlans, PersonPlan, SpousePlan } from '@/entities/PlanEntity';
import PremiumPlanEntity from '@/entities/PremiumPlanEntity';
import { persistencePlugin } from './plugins/persistencePlugin';
import Report from '@/entities/Report';
import Sponsership from '@/entities/Sponsership';
import PresentationWillKitVeterans from '@/entities/PresentationWillKitVeterans';
import Finances from '@/entities/Finances';
import GamePlan from '@/entities/GamePlan';
import Goals from '@/entities/Goals';
import Calculator from '@/entities/Calculator';
import Calendar from '@/entities/Calendar';

Vue.use(Vuex);
const database = new VuexORM.Database();

// Register Models to Database.
database.register(User);
database.register(Presentation);
database.register(PrimaryPerson);
database.register(Spouse);
database.register(Children);
database.register(AdultChildren);
database.register(PlanEntity);
database.register(PresentationPlans);
database.register(PersonPlan);
database.register(SpousePlan);
database.register(PremiumPlanEntity);
database.register(PlanSummary);
database.register(Report);
database.register(Sponsership);
database.register(PresentationWillKitVeterans);
database.register(GamePlan);
database.register(Finances);
database.register(Goals);
database.register(Calculator);
database.register(Calendar);

export default new Vuex.Store({
	plugins: [ VuexORM.install(database), persistencePlugin ],
	state: {
		showMessage: false,
		messageText: '',
		loader: false,
		Largesnackbar: false,
		isMessageSentRecently: false,
		isImageSentRecently: false,
		ProfileImage: false,
		ProfileImageString: '',
		ProfileInfoUpdated: false,
		OpenedPopupName: '',
		SGNumber: '',
		PresentedGroup: '',
		PresentPlanParam: false
	},
	mutations: {
		initialize(state, persistedState) {
			// use the fetched, persisted state.
			// In my case, I was reconstructing an array, so I wrote a handler (overwriteStore) to deal with the data rather simplistically
			//overwriteStore(state, { arrayOfThings: persistedState }); // but do your own thing here.
			Vue.set(state, 'initialized', true);
		}
	},
	actions: {},
	modules: {
		Auth
	}
});
