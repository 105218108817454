





























































































































































































































































































































































































































































































































































































import Presentation from "../entities/Presentation";
import { getPersistedState, setPersistedState } from "../helpers/StateMapper";
import GenericKeyPair from "../models/GenericKeyPair";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Camelize } from "@/helpers/ArrayHelper";
import MenuComponent from "../components/menu.vue";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import Flipbook from "flipbook-vue";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import moment from "moment";
import {
	ConvertIntoAmountFormat,
	RoundUpperDecimal,
} from "../helpers/CalculationHelper";
import {
	GetRequestAsBlob,
	GetRequestWithAuthorization,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import { Base64ToArrayBuffer, groupBy } from "../helpers/ArrayHelper";
import { SetUserLanguage } from "@/plugins/i18n";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { ChecktoAddLangTruncateClass } from "@/plugins/i18n";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import PrimaryPerson from "@/entities/PrimaryPerson";
import { PresentationType } from "../enumerations/PresentationType";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import LoggingHelper from "@/helpers/LoggingHelper";
import LoggingConstants from "@/constants/LoggingConstants";
import { GetAssociateId } from "@/helpers/APIExtentions";
import FamilyPdfHtml from "../components/popups/FamilyPdfHtml.vue";
import McgruffFamilyPdfHtml from "../components/popups/McgruffFamilyPdfHtml.vue";
import UnionFamilyPdfHtml from "../components/popups/UnionFamilyPdfHtml.vue";
import AssociationFamilyPdfHtml from "../components/popups/AssociationFamilyPdfHtml.vue";
import FinancialPdfHtml from "../components/popups/FinancialPdfHtml.vue";
import VeteranBurialAndWillKitPdfHtml from "../components/popups/VeteranBurialAndWillKitPdfHtml.vue";
import VeteranFamilyInfoGuidePdfHtml from "../components/popups/VeteranFamilyInfoGuidePdfHtml.vue";
import BenefitAndBurialGuidePdfHtml from "../components/popups/BenefitAndBurialGuidePdfHtml.vue";
import VeteranLastWillAndTestamentPdfHtml from "../components/popups/VeteranLastWillAndTestamentPdfHtml.vue";
import VeteranThreeImportantFactsSMBPdfHtml from "../components/popups/VeteranThreeImportantFactsSMBPdfHtml.vue";
import VeteranThreeImportantFactsPdfHtml from "../components/popups/VeteranThreeImportantFactsPdfHtml.vue";
import VeteranNoVeteranLeftBehindPdfHtml from "../components/popups/VeteranNoVeteranLeftBehindPdfHtml.vue";
import WeNeedYourHelpMcgruffPdfHtml from "../components/popups/WeNeedYourHelpMcgruffPdfHtml.vue";
import GiftCertificatePdfHtml from "../components/popups/GiftCertificatePdfHtml.vue";
import WillKitPdfHtml from "../components/popups/WillKitPdfHtml.vue";
import CreditUnion from "../components/CreditUnion.vue";
import FamilyCare from "../components/FamilyCare.vue";
import FamilyCareVeteran from "../components/FamilyCareVeteran.vue";
import Questionnaire from "../components/Questionnaire.vue";
import PresentationWillKitVeterans from "@/entities/PresentationWillKitVeterans";
import MemberSurvey from "@/entities/MemberSurvey";
import Spouse from "@/entities/Spouse";
import MemberQuestionnaire from "@/entities/MemberQuestionnaire";
import FamilyCareSurvey from "@/entities/FamilyCareSurvey";
import { EventBusLayout } from "../bus/EventBusLayout";
import { HpProHouseHoldTypeDiscription } from "@/enumerations/HpProHouseHoldType";
import NeedAnalysisPrefillPdfHtml from "@/components/popups/NeedAnalysisPrefillPdfHtml.vue";
import { PrsentationEntityToDTO } from "@/helpers/MappingHelper";
import { APIService } from "@/services/APIService";
import PresentationSync from "@/helpers/PresentationSync";
import { PresentationSubType } from "@/enumerations/PresentationSubType";

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		Flipbook,
		videoPlayer,
		VuePdfApp,
		ReferralPopup,
		FamilyPdfHtml,
		McgruffFamilyPdfHtml,
		UnionFamilyPdfHtml,
		AssociationFamilyPdfHtml,
		VeteranBurialAndWillKitPdfHtml,
		VeteranFamilyInfoGuidePdfHtml,
		BenefitAndBurialGuidePdfHtml,
		VeteranLastWillAndTestamentPdfHtml,
		VeteranThreeImportantFactsPdfHtml,
		VeteranThreeImportantFactsSMBPdfHtml,
		VeteranNoVeteranLeftBehindPdfHtml,
		WeNeedYourHelpMcgruffPdfHtml,
		GiftCertificatePdfHtml,
		WillKitPdfHtml,
		CreditUnion,
		Questionnaire,
		FinancialPdfHtml,
		FamilyCare,
		FamilyCareVeteran,
		NeedAnalysisPrefillPdfHtml,
	},
})
export default class NoCostBenefits extends Vue {
	private Presentation: any = {};
	private pageLoadedTime: any;
	private nocostmodal: boolean = false;
	private absolute: boolean = false;
	private showMatierials: boolean = true;
	private allProducts: any = [];
	private allPresTypes: any = [];
	private allMatchingproducts: any;
	private allMatchingproductsReferral: any;
	private products: any;
	private states: any = [];
	private itemLessCount: number = 3;
	private isVeteran: Boolean = false;
	private report: boolean = false;
	private Groups: any = [];

	private showFlipbook: Boolean = false;
	private showImage: Boolean = false;
	private showVideo: Boolean = false;
	private showGroupVideo: Boolean = false;
	private GroupVideoUrl: string = "";
	private showProgress: Boolean = false;
	private showPDF: Boolean = false;
	private imgSource: string = "";
	private currentIndex: number = -1;
	private backgroundimage: string = "";
	private SelectedGroup: any = null;
	private foregroundimage: string = "";
	private materialsTime: any = [];
	private currentItem: any;
	private ssprogram: boolean = false;
	private GiftAmount: string = "0";
	private showAllMaterials: boolean = false;
	private indexId = 1;
	private ResetReferrals: boolean = false;
	private custompdfhtml: boolean = false;
	private showFamilyPDF: boolean = false;
	private showMcgruffFamilyPDF: boolean = false;
	private showUnionFamilyPDF: boolean = false;
	private showAssociationFamilyPDF: boolean = false;
	private showWeNeedYourHelpMcgruffPDF: boolean = false;
	private showVeteranBurialAndWillKitPDF: boolean = false;
	private showVeteranBenefitAndBurialGuidePDF: boolean = false;
	private showVeteranLastWillAndTestamentPDF: boolean = false;
	private showVeteranThreeImportantFactsPDF: boolean = false;
	private showVeteranThreeImportantFactsSMBPDF: boolean = false;
	private showVeteranNoVeteranLeftBehindPDF: boolean = false;
	private showGiftCertificatePDF: boolean = false;

	private showWillKitPDF: boolean = false;
	private isGroupLetter: boolean = false;
	private browserCloseEvent: boolean = false;
	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;
	private CountryId = 1;

	private PresentationWillKitVeterans: any;
	private PDFUrl: any;
	private fileName: any;
	private DownloadWillKitPDF: boolean = false;
	private MemberSurveyObj: any = new MemberSurvey();
	private FamilyCareObj: any = new FamilyCareSurvey();
	private QuestionnaireObj: any = new MemberQuestionnaire();
	private NeedAnalysisObj: any = new PresentationWillKitVeterans();
	private showMemberSurvey: boolean = false;
	private showFamilyCareSurvey: boolean = false;
	private showFamilyCareSurveyVeteran: boolean = false;
	private showQuestionnaire: boolean = false;
	private ShowDownloadPopup: boolean = false;
	private DownloadPopupText: string = "";
	private ShowFinancialPDF: boolean = false;
	private ShowNeedAnalysisPrefill: boolean = false;
	private Person: any;
	private QuestionnaireGroupCode: string = "";
	private QuestionnaireGroupName: string = "";
	private QuestionnaireVideoUrl: string = "";
	private ChildrenforReferral: any = [];
	private MaterialUpdated: boolean = false;
	private NeedsPerson: any = {};
	private NeedsSpouse: any = {};
	private NeedsPresentation: any = {};
	private _keydownCallback: any;

	private flipBookPages: Array<any> = [
		"",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/1.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/2.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/3.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/4.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/5.jpg",
		"https://raw.githubusercontent.com/ts1/flipbook-vue/master/public/images-large/6.jpg",
	];
	private noCostMissionText: string =
		'"Our Mission Is To Protect <br/> Every Child & Serve All<br/> Working People."';

	@Watch("overlay")
	overlaychanged(newValue: boolean, oldValue: boolean) {
		if (!newValue) {
			this.currentIndex = -1;
			this.currentItem.time = Math.abs(
				(parseInt(this.currentItem.time) || 0) +
					Date.now() -
					this.currentItem.Starttime
			);
			this.currentItem.Starttime = undefined;
			this.currentItem = null;
			this.flipBookPages = [];
			var body: any = document.getElementsByTagName("body");
			body[0].classList.remove("o-hidden");
			this.$data.canvaslist = [];
		} else {
			var body: any = document.getElementsByTagName("body");
			body[0].classList.add("o-hidden");
		}
	}

	@Watch("custompdfhtml")
	CustomPDFChanged(newValue: boolean, oldValue: boolean) {
		if (!newValue) {
			this.currentIndex = -1;
		}
	}

	@Watch("showMemberSurvey")
	OnShowMemberSurveyChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) {
			this.Person = PrimaryPerson.query().withAllRecursive().last();
		} else {
			if (this.Person != undefined) {
				PrimaryPerson.update({
					where: this.Person.Id,
					data: this.Person,
				});

				if (this.Person.Spouse != undefined)
					Spouse.update({
						where: this.Person.Spouse.Id,
						data: this.Person.Spouse,
					});
			}
			this.currentIndex = -1;
		}
	}

	@Watch("showFamilyCareSurvey")
	OnShowFamilyCareSurveyChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) {
			this.Person = PrimaryPerson.query().withAllRecursive().last();
		} else {
			if (this.Person != undefined) {
				PrimaryPerson.update({
					where: this.Person.Id,
					data: this.Person,
				});

				if (this.Person.Spouse != undefined)
					Spouse.update({
						where: this.Person.Spouse.Id,
						data: this.Person.Spouse,
					});
			}
			this.currentIndex = -1;
		}
	}

	@Watch("showFamilyCareSurveyVeteran")
	OnShowFamilyCareSurveyVeteranChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) {
			this.Person = PrimaryPerson.query().withAllRecursive().last();
		} else {
			if (this.Person != undefined) {
				PrimaryPerson.update({
					where: this.Person.Id,
					data: this.Person,
				});

				if (this.Person.Spouse != undefined)
					Spouse.update({
						where: this.Person.Spouse.Id,
						data: this.Person.Spouse,
					});
			}
			this.currentIndex = -1;
		}
	}

	@Watch("showQuestionnaire")
	OnShowQuestionnaireChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) {
			this.Person = PrimaryPerson.query().withAllRecursive().last();
		} else {
			if (this.Person != undefined) {
				PrimaryPerson.update({
					where: this.Person.Id,
					data: this.Person,
				});

				if (this.Person.Spouse != undefined)
					Spouse.update({
						where: this.Person.Spouse.Id,
						data: this.Person.Spouse,
					});
			}
			this.currentIndex = -1;
		}
	}
	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		if (this.Person == undefined)
			this.Person = PrimaryPerson.query().withAllRecursive().last();
		var RefInit: any = {};
		if (this.Presentation.Referrals) {
			RefInit = JSON.parse(this.Presentation.Referrals);
		} else {
			RefInit = {
				ReferredBy: "",
				ReferredByLastName: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: "",
				ReferralsList: [],
				CCEmail: [],
			};
		}
		if (RefInit.ReferralsList.length > 0) {
			RefInit.ReferralsList.forEach((referral: any) => {
				referral.StateObj = this.states.find(
					(x: any) => x.StateCode == referral.State
				);
			});
		}
		if (RefInit.ReferredBy == undefined || RefInit.ReferredBy == "")
			RefInit.ReferredBy = this.Person?.FirstName;

		if (
			RefInit.ReferredByLastName == undefined ||
			RefInit.ReferredByLastName == ""
		)
			RefInit.ReferredByLastName = this.Person?.LastName;

		if (RefInit.Phone == undefined || RefInit.Phone == "")
			RefInit.Phone = this.Person?.Phone;
		if (RefInit.Email == undefined || RefInit.Email == "")
			RefInit.Email = this.Person?.Email;
		if (
			this.Presentation.PresentedGroup != undefined &&
			this.Presentation.PresentedGroup != ""
		)
			RefInit.Organization = this.Presentation.PresentedGroup;
		if (RefInit.Date == undefined || RefInit.Date == "")
			RefInit.Date = moment(new Date()).format("MM/DD/YYYY");
		// if (
		// 	this.Presentation.FamilyCareSurvey != "" &&
		// 	this.Presentation.FamilyCareSurvey != undefined
		// ) {
		// 	var FamilySurvey = JSON.parse(this.Presentation.FamilyCareSurvey);
		// 	if (
		// 		FamilySurvey.AdultChildrenString != undefined &&
		// 		FamilySurvey.AdultChildrenString != ""
		// 	) {
		// 		this.ChildrenforReferral = JSON.parse(FamilySurvey.AdultChildrenString);
		// 	}
		// }

		// if (
		// 	this.ChildrenforReferral.length > 0 &&
		// 	RefInit.ReferralsList.length < 1
		// ) {
		// 	var filteredBeneftis = this.allMatchingproductsReferral.filter(
		// 		(x: any) =>
		// 			x.ExcludeFromReferrral == undefined || x.ExcludeFromReferrral == false
		// 	);
		// 	this.ChildrenforReferral.forEach((element: any) => {
		// 		RefInit.ReferralsList.push({
		// 			Id: RefInit.ReferralsList.length + 1,
		// 			FirstName: element.FirstName,
		// 			LastName: element.LastName,
		// 			City: element.City,
		// 			isFromWillKit: false,
		// 			isActivated: true,
		// 			State: element.State,
		// 			StateObj: element.State.StateCode,
		// 			Phone: element.Phone,
		// 			RelToSponsor: "",
		// 			RelToObject: {},
		// 			Occupation: "",
		// 			SignificantOther: "",
		// 			isFavorite: false,
		// 			AllCheck: true,
		// 			Notes: "",
		// 			AlreadyExist: false,
		// 			NoCostBenefitsList: JSON.parse(JSON.stringify(filteredBeneftis)),
		// 			BranchOfService: "",
		// 		});
		// 	});
		// }
		// if (
		// 	this.Presentation.FamilyCareSurvey.AdultChildrenString != "" &&
		// 	this.Presentation.FamilyCareSurvey.AdultChildrenString != undefined
		// ) {
		// 	this.ChildrenforReferral = JSON.parse(
		// 		this.Presentation.FamilyCareSurvey.AdultChildrenString
		// 	);
		// }
		this.$data.Referrals = RefInit;
		if (newValue == true) {
			this.ResetReferrals = !this.ResetReferrals;
		}
	}

	CloseReferralPopup() {
		//setTimeout(() => {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		//}, 1000);
	}

	private SelectedPresentationType: any = {};

	DownloadPDF(evt: any) {
		evt.stopPropagation();

		if (!window.navigator.onLine) {
			this.$store.state.messageText = this.$t("General.NeedActiveConnect");
			this.$store.state.showMessage = true;
			return;
		}

		if (this.DownloadWillKitPDF == false && this.isGroupLetter) {
			this.$store.state.loader = true;

			GetRequestAsBlob(this.$data.PdfUrl).then((response: any) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement("a");

				fileLink.href = fileURL;
				if (typeof this.SelectedGroup == "string")
					fileLink.setAttribute("download", this.SelectedGroup + ".pdf");
				else
					fileLink.setAttribute(
						"download",
						this.SelectedGroup.GroupCode + ".pdf"
					);

				document.body.appendChild(fileLink);

				fileLink.click();
				this.$store.state.loader = false;
			});
		} else if (this.DownloadWillKitPDF == false && this.showPDF != true) {
			var parameters: GenericKeyPair[] = [];

			parameters.push(new GenericKeyPair("URL", this.$data.PdfUrl));
			this.$store.state.loader = true;
			GetRequestWithAuthorization(
				ApiEndPointConstants.GetTrainingPDFEndPoint,
				parameters
			).then((res) => {
				if (res.Result != null) {
					const downloadLink = document.createElement("a");
					const fileName = this.fileName + ".pdf";
					const linkSource = `data:application/pdf;base64,${res.Result}`;
					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
					this.$store.state.loader = false;
				} else {
					this.$store.state.loader = false;
					this.$store.state.messageText = "File not Found!";
					this.$store.state.showMessage = true;
				}
			});
		} else if (this.showPDF == true) {
			var base64pdf = this.arrayBufferToBase64(this.$data.PdfUrl);
			const downloadLink = document.createElement("a");
			const fileName = this.fileName + ".pdf";
			const linkSource = `data:application/pdf;base64,${base64pdf}`;
			downloadLink.href = linkSource;
			downloadLink.download = "GROUPAD&DCERT.pdf";
			downloadLink.click();
			this.$store.state.loader = false;
		} else {
			if (
				this.$refs.VeteranBurialAndWillKitComp != undefined &&
				this.showVeteranBurialAndWillKitPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.VeteranBurialAndWillKitComp.DownloadPDF();
			} else if (
				this.$refs.VeteranBenefitAndBurialGuideComp != undefined &&
				this.showVeteranBenefitAndBurialGuidePDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.VeteranBenefitAndBurialGuideComp.DownloadPDF();
			} else if (
				this.$refs.VeteranLastWillAndTestamentComp != undefined &&
				this.showVeteranLastWillAndTestamentPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.VeteranLastWillAndTestamentComp.DownloadPDF();
			} else if (
				this.$refs.VeteranThreeImportantFactsComp != undefined &&
				this.showVeteranThreeImportantFactsPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.VeteranThreeImportantFactsComp.DownloadPDF();
			} else if (
				this.$refs.VeteranThreeImportantFactsSMBComp != undefined &&
				this.showVeteranThreeImportantFactsSMBPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.VeteranThreeImportantFactsSMBComp.DownloadPDF();
			} else if (
				this.$refs.VeteranNoVeteranLeftBehindComp != undefined &&
				this.showVeteranNoVeteranLeftBehindPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.VeteranNoVeteranLeftBehindComp.DownloadPDF();
			} else if (
				this.$refs.GiftCertificateComp != undefined &&
				this.showGiftCertificatePDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.GiftCertificateComp.DownloadPDF();
			} else if (
				this.$refs.WillKitComp != undefined &&
				this.showWillKitPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.WillKitComp.DownloadPDF();
			} else if (
				this.$refs.FamilyComp != undefined &&
				this.showFamilyPDF == true
			) {
				this.$refs.FamilyComp.DownloadPDF();
			} else if (
				this.$refs.McgruffFamilyComp != undefined &&
				this.showMcgruffFamilyPDF == true
			) {
				this.$refs.McgruffFamilyComp.DownloadPDF();
			} else if (
				this.$refs.WeNeedYourHelpMcgruffComp != undefined &&
				this.showWeNeedYourHelpMcgruffPDF == true
			) {
				this.ShowDownloadPopup = false;
				this.$refs.WeNeedYourHelpMcgruffComp.DownloadPDF();
			} else if (
				this.$refs.UnionFamilyComp != undefined &&
				this.showUnionFamilyPDF == true
			) {
				this.$refs.UnionFamilyComp.DownloadPDF();
			} else if (
				this.$refs.AssociationFamilyComp != undefined &&
				this.showAssociationFamilyPDF == true
			) {
				this.$refs.AssociationFamilyComp.DownloadPDF();
			} else if (
				this.$refs.FinancialComp != undefined &&
				this.ShowFinancialPDF == true
			)
				this.$refs.FinancialComp.DownloadPDF();
		}
	}

	DownloadStandAlonePDF(evt: any) {
		evt.stopPropagation();

		if (!window.navigator.onLine) {
			this.$store.state.messageText = this.$t("General.NeedActiveConnect");
			this.$store.state.showMessage = true;
			return;
		}

		if (
			this.$refs.NeedAnalysisPrefillComp != undefined &&
			this.ShowNeedAnalysisPrefill == true
		) {
			this.$refs.NeedAnalysisPrefillComp.DownloadPDF();
		}
	}

	async LoadData() {
		this.$store.state.loader = true;
		var res = await getPersistedState(IndexDBConstants.PresentationTypesKey);

		this.allPresTypes = res;

		if (!this.showAllMaterials) {
			this.SelectedPresentationType = this.allPresTypes.find(
				(x: any) => x.Id == this.Presentation.PresentationTypeId
			);
			var stateSel = this.states.filter((x: any) => {
				if (x.Name == this.Presentation.State) return x;
			});
			var state: any = {};
			if (stateSel != undefined && stateSel.length > 0) {
				state = stateSel[0];
			}
			this.isVeteran = this.SelectedPresentationType.Name == "VETERAN";
			this.backgroundimage =
				this.SelectedPresentationType.NoCostBackgroundImage;
			this.foregroundimage =
				this.SelectedPresentationType.NoCostForegroundImage;
			this.fetchMaterialProducts(
				this.SelectedPresentationType,
				state.CountryId,
				this.Presentation.LanguageId
			);

			this.CountryId = state.CountryId;
		} else {
			this.fetchAllMaterialProducts();
		}

		var groupfromDB = await getPersistedState("PresentationGroups");
		this.Groups = groupfromDB;

		// if (
		// 	this.Groups != null &&
		// 	this.Presentation.SGNumber != undefined &&
		// 	this.Presentation != undefined &&
		// 	this.Presentation.SGNumber != ""
		// ) {
		// 	this.SelectedGroup = this.Groups.find(
		// 		(x: any) => x.GroupCode == this.Presentation.SGNumber
		// 	);
		// 	if (this.SelectedGroup != undefined) this.ChangeQuesitonnaireGroups();
		// }

		if (
			this.Groups != null &&
			this.$store.state.SGNumber != undefined &&
			this.$store.state.SGNumber != undefined &&
			this.$store.state.SGNumber != ""
		) {
			this.SelectedGroup = this.Groups.find(
				(x: any) => x.GroupCode == this.$store.state.SGNumber
			);
			if (this.SelectedGroup != undefined) this.ChangeQuesitonnaireGroups();
		}

		if (this.Presentation != undefined && this.SelectedGroup == undefined) {
			this.SelectedGroup = this.Presentation.SGNumber;
			this.ChangeQuesitonnaireGroups();
		}

		this.$store.state.loader = false;
	}

	fetchMaterialProducts(presType: any, countryId: number, languageId: number) {
		getPersistedState("Materials").then((res) => {
			this.allProducts = res;
			var langId = JSON.parse(JSON.stringify(languageId));

			if (langId == 48) langId = 41;

			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined || x.CountryId == countryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == presType.Id) &&
						(x.LanguageId == undefined || x.LanguageId == langId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);

			this.allMatchingproductsReferral = JSON.parse(
				JSON.stringify(this.allMatchingproducts)
			);

			if (presType.Id == PresentationType.Veteran) {
				if (
					this.Presentation?.SubType.toUpperCase() !=
					"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
				}
				if (
					this.Presentation?.SubType.toUpperCase() ==
						"SERVICE MEMBER BENEFITS LEAD" ||
					this.Presentation?.SubType.toUpperCase() ==
						"BURIAL & WILL KIT FOR VETERANS"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "$2000 GIFT CERTIFICATE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
				} else if (this.Presentation?.SubType.toUpperCase() == "RETURN CARD") {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "$2000 GIFT CERTIFICATE"
							)
						),
						1
					);
				} else if (
					this.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD" ||
					this.Presentation?.SubType.toUpperCase() == "VETERAN FAMILY LEAD"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
				} else if (
					this.Presentation?.SubType.toUpperCase() ==
					"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
							)
						),
						1
					);

					this.allMatchingproducts.forEach((material: any) => {
						if (material.Name.toUpperCase() == "VETERAN SURVEY") {
							material.MaterialName = "SURVEY FOR VETERANS & THEIR FAMILIES";
						}
					});
				}
			}

			if (presType.Id == PresentationType.ServiceMemberBenefits) {
				if (
					this.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD" ||
					this.Presentation?.SubType.toUpperCase() == "VETERAN FAMILY LEAD"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == true
							)
						),
						1
					);
				} else if (
					this.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD (ALPHA)" ||
					this.Presentation?.SubType.toUpperCase() ==
						"VETERAN FAMILY LEAD (ALPHA)" ||
					this.Presentation?.SubType.toUpperCase() == "CIVILIAN" ||
					this.Presentation?.SubType.toUpperCase() ==
						"SERVICE MEMBER BENEFIT NOTIFICATION"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
							)
						),
						1
					);

					this.allMatchingproducts.forEach((material: any) => {
						if (material.Name.toUpperCase() == "VETERAN SURVEY") {
							material.MaterialName = "SURVEY FOR VETERANS & THEIR FAMILIES";
						}
					});
				}
			}

			this.itemLessCount = parseInt(
				this.allMatchingproducts
					.filter((x: any) => x.ShowOnExpand == false)
					.length.toString()
			);

			// (!(presType.Id == 5 || presType.Id == 6 || this.Presentation.SubType.toUpperCase() == "RETURN CARD"))
			if (
				presType.Id != 6 &&
				this.Presentation?.SubType.toUpperCase() != "RETURN CARD"
			) {
				this.allMatchingproducts.splice(
					this.allMatchingproducts.indexOf(
						this.allMatchingproducts.find(
							(x: any) => x.Name == "MEMBER QUESTIONNAIRE"
						)
					),
					1
				);
			}
			this.products = JSON.parse(
				JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
			);
		});
	}

	fetchAllMaterialProducts() {
		SetUserLanguage();
		getPersistedState("Materials").then((res: any) => {
			if (res != undefined && res.length > 0) {
				this.allProducts = res;

				this.allProducts.forEach((matProd: any) => {
					if (matProd.CountryCode != undefined && matProd.CountryCode != "") {
						matProd.MaterialName =
							matProd.MaterialName + " - " + matProd.CountryCode;
					}
				});

				var allProd = this.allProducts
					.filter((x: any) => x.MaterialId != undefined)
					.sort((a: any, b: any) => a.priority - b.priority);
				var groupedElements = groupBy(allProd, "MaterialId");
				var materials: any = [];
				for (var prop in groupedElements) {
					materials.push(groupedElements[prop][0]);
				}
				this.allMatchingproducts = materials;
				this.allMatchingproductsReferral = JSON.parse(
					JSON.stringify(this.allMatchingproducts)
				);

				this.itemLessCount = this.allMatchingproducts.length / 2;

				this.itemLessCount = Number(this.itemLessCount.toFixed());

				this.products = JSON.parse(
					JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
				);
			}
		});
	}

	SaveReferral(ref: any) {
		// if (this.$refs.phonefield.$el.classList.contains("error--text")) {
		//   this.$store.state.messageText = "Please enter a valid Phone Number.";
		//   this.$store.state.showMessage = true;
		//   return;
		// }

		// if (this.$refs.emailfield.$el.classList.contains("error--text")) {
		//   this.$store.state.messageText = "Please enter a valid Email Address.";
		//   this.$store.state.showMessage = true;
		//   return;
		// }

		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";

		LoggingHelper.getInstance().LogEvent(LoggingConstants.SponsorShipPopupSave);
	}

	SaveReferralsWithoutClosingPopup(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	displayGroup(item: any) {
		if (item == undefined || item == "") {
			this.$store.state.messageText = this.$t("NoCostBenefits.SelectGroupCode");
			this.$store.state.showMessage = true;
			EventBusLayout.$emit("CloseGroup", false);
			return;
		}

		this.DownloadWillKitPDF = false;
		this.flipBookPages = [];
		this.showFlipbook = false;
		this.$store.state.loader = true;
		this.convertPDFToImage = true;
		this.isGroupLetter = true;

		var parameters: GenericKeyPair[] = [];
		if (typeof item == "string") {
			parameters.push(new GenericKeyPair("groupCode", item.toUpperCase()));
		} else {
			parameters.push(new GenericKeyPair("groupCode", item.GroupCode));
		}
		GetRequestWithAuthorization(
			ApiEndPointConstants.GroupLetterPDFUrlEndPoint,
			parameters
		).then((res) => {
			if (res.status && res.Result != undefined && res.Result != "") {
				this.$data.overlay = !this.$data.overlay;
				this.$data.PdfUrl = res.Result;
				this.showPDF = true;
				this.showFlipbook = true;
				EventBusLayout.$emit("CloseGroup", true);
			} else {
				this.$data.overlay = false;
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t(
					"NoCostBenefits.InvalidGroupCode"
				);
				this.$store.state.showMessage = true;
				EventBusLayout.$emit("CloseGroup", false);
			}
		});
		LoggingHelper.getInstance().LogEvent(LoggingConstants.GroupLetteropened);
	}

	displayGroupVideo(videourl: any) {
		this.showImage = false;
		this.showVideo = false;
		this.showGroupVideo = false;
		this.showFlipbook = false;
		this.showPDF = false;
		this.showVeteranBurialAndWillKitPDF = false;
		this.showVeteranBenefitAndBurialGuidePDF = false;
		this.showVeteranLastWillAndTestamentPDF = false;
		this.showVeteranThreeImportantFactsPDF = false;
		this.showVeteranThreeImportantFactsSMBPDF = false;
		this.showVeteranNoVeteranLeftBehindPDF = false;
		this.showGiftCertificatePDF = false;
		this.showWeNeedYourHelpMcgruffPDF = false;
		this.showWillKitPDF = false;
		this.custompdfhtml = false;
		this.isGroupLetter = false;
		this.GroupVideoUrl = "";

		if (videourl == undefined || videourl == "") {
			this.$store.state.messageText = this.$t("NoCostBenefits.SelectGroupCode");
			this.$store.state.showMessage = true;
			EventBusLayout.$emit("CloseGroupVideo", false);
			return;
		}

		this.$data.overlay = !this.$data.overlay;
		this.showGroupVideo = true;
		this.GroupVideoUrl = videourl;
		EventBusLayout.$emit("CloseGroupVideo", true);
		LoggingHelper.getInstance().LogEvent(
			LoggingConstants.GroupLetterVideoopened
		);
	}

	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;

		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;

		if (referral.City != null && referral.City.trim() != "") return false;

		if (referral.State != null && referral.State.trim() != "") return false;

		if (referral.Phone != null && referral.Phone.trim() != "") return false;

		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;

		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;

		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;

		return true;
	}

	async pagesRendered(pdfApp: any) {
		pdfApp.pdfViewer.currentScaleValue = "page-fit";
		if (this.convertPDFToImage == false) {
		} else {
			var pageNumber = 0.5;
			this.$data.canvaslist = [];
			for (var i = 1; i <= pdfApp.pagesCount; i++) {
				this.$data.canvaslist.push({ id: "canvas-" + i });
			}
			var temppages: any = [];
			this.flipBookPages = [];
			this.flipBookPages.push("");
			for (var j = 1; j <= pdfApp.pagesCount; j++) {
				var page = await pdfApp.pdfDocument.getPage(j);
				var scale = 1;
				var viewport = page.getViewport({
					scale: scale,
				});

				// Prepare canvas using PDF page dimensions
				var canvas: any = document.getElementById(
					"canvas-" + (page._pageIndex + 1)
				);
				var context = canvas.getContext("2d");
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				// Render PDF page into canvas context
				var renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;
				temppages.push({ index: j, url: canvas.toDataURL() });
			}

			temppages = temppages.sort(this.sort);
			this.flipBookPages.push(...temppages.map((a: any) => a.url));
			this.showPDF = false;
			this.$store.state.loader = false;
			this.showFlipbook = true;
		}
	}

	sort(a: any, b: any) {
		if (a.index < b.index) return -1;
		if (a.index > b.index) return 1;
		return 0;
	}

	LoadDropDown() {
		getPersistedState("StateList").then((res) => {
			this.states = res;
			this.LoadData();
		});
	}

	created() {
		this.showAllMaterials = Boolean(this.$route.params.showallMaterials);
		EventBusLayout.$on("RoutedToEvt", this.NoCostBenefitRoutedToEvt);
		EventBusLayout.$on("Action", this.NoCostBenefitAction);
		EventBusLayout.$on("displayProduct", this.NoCostBenefitdisplayProduct);
		EventBusLayout.$on("displayGroup", this.NoCostBenefitdisplayGroup);
		EventBusLayout.$on(
			"displayNeedAnalysisPrefill",
			this.NoCostBenefitdisplayNeedAnalysisPrefill
		);
		EventBusLayout.$on(
			"displayGroupVideo",
			this.NoCostBenefitdisplayGroupVideo
		);
		if (!this.showAllMaterials)
			window.addEventListener("beforeunload", this.onCloseBrowser);

		this.$data.pagescale = "page-fit";
		this.pageLoadedTime = new Date();
		if (!this.showAllMaterials) this.Presentation = Presentation.query().last();
		else this.Presentation = {};
		this.Person = PrimaryPerson.query().withAllRecursive().last();

		this.$data.isClonedPresentation =
			this.Presentation.ParentCode != null &&
			this.Presentation.ParentCode != "";

		this.LoadDropDown();

		if (
			this.Presentation.NoCostMaterialsTime != undefined &&
			this.Presentation.NoCostMaterialsTime != ""
		)
			this.materialsTime = JSON.parse(this.Presentation.NoCostMaterialsTime);

		this._keydownCallback = (event: any) => {
			if (event.code == "Escape") {
				if (this.showQuestionnaire == true) {
					if (
						this.$refs.QuestionnnairComp != undefined &&
						this.$refs.QuestionnnairComp.isVideoOpened()
					)
						this.$refs.QuestionnnairComp.CloseVideo();
					else {
						this.showQuestionnaire = false;
						this.$data.overlay = false;
						this.showMemberSurvey = false;
						this.showFamilyCareSurvey = false;
						this.showFamilyCareSurveyVeteran = false;
						this.currentIndex = -1;
					}
				} else if (
					this.showFamilyCareSurvey ||
					this.showFamilyCareSurveyVeteran
				) {
					//do nothing
				} else {
					this.$data.overlay = false;
					this.CloseMaterial();
					this.showMemberSurvey = false;
					this.showFamilyCareSurvey = false;
					this.showFamilyCareSurveyVeteran = false;
					this.currentIndex = -1;
				}
			}

			if (this.showVideo && event.code == "Space") {
				if (
					this.$refs.videoPlayer != undefined &&
					this.$refs.videoPlayer.player != undefined &&
					!this.$refs.videoPlayer.player.paused()
				)
					this.$refs.videoPlayer.player.pause();
				else if (
					this.$refs.videoPlayer != undefined &&
					this.$refs.videoPlayer.player != undefined
				)
					this.$refs.videoPlayer.player.play_();
				//this.$refs.videoPlayer.pause();
			}

			if (this.showFlipbook && this.$refs.flipbook != undefined) {
				if (event.code == "ArrowLeft") {
					this.$refs.flipbook.flipLeft();
				}

				if (event.code == "ArrowRight") {
					this.$refs.flipbook.flipRight();
				}
			}
		};

		document.addEventListener("keyup", this._keydownCallback);

		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationWillKitVeterans != undefined &&
			this.Presentation.PresentationWillKitVeterans != ""
		)
			this.PresentationWillKitVeterans = JSON.parse(
				this.Presentation.PresentationWillKitVeterans
			);

		if (
			this.Presentation != undefined &&
			this.Presentation.MemberSurvey != undefined &&
			this.Presentation.MemberSurvey != ""
		)
			this.MemberSurveyObj = JSON.parse(this.Presentation.MemberSurvey);

		if (
			this.Presentation != undefined &&
			this.Presentation.MemberQuestionnaire != undefined &&
			this.Presentation.MemberQuestionnaire != ""
		)
			this.QuestionnaireObj = JSON.parse(this.Presentation.MemberQuestionnaire);
		if (
			this.Presentation != undefined &&
			this.Presentation.FamilyCareSurvey != undefined &&
			this.Presentation.FamilyCareSurvey != ""
		)
			this.FamilyCareObj = new FamilyCareSurvey(
				JSON.parse(this.Presentation.FamilyCareSurvey)
			);
	}
	NoCostBenefitdisplayGroup(obj: any) {
		this.displayGroup(obj);
	}
	NoCostBenefitdisplayNeedAnalysisPrefill() {
		this.custompdfhtml = false;
		this.DownloadWillKitPDF = false;
		this.NeedsPerson = PrimaryPerson.query().last();
		this.NeedsSpouse = Spouse.query().last();

		if (this.NeedsSpouse == undefined) {
			this.NeedsSpouse = new Spouse();
			Spouse.insert({
				data: this.NeedsSpouse,
			});
			this.NeedsSpouse = Spouse.query().last();
		}
		if (this.NeedsPerson == undefined) {
			this.NeedsPerson = new PrimaryPerson();
			this.NeedsPerson.Spouse_Id = Spouse.query().last()?.$id;
			PrimaryPerson.insert({
				data: this.NeedsPerson,
			});
			this.NeedsPerson = PrimaryPerson.query().last();
		}
		this.NeedsPresentation = Presentation.query().withAllRecursive().last();
		if (this.NeedsPresentation == undefined) {
			this.NeedsPresentation = new Presentation();
			this.NeedsPresentation.StartTime = new Date().toLocaleString();
			this.NeedsPresentation.Person_Id = PrimaryPerson.query().last()?.$id;
			this.NeedsPresentation.WhatHappened = "NEEDS ANALYSIS ONLY";
			Presentation.insert({
				data: this.NeedsPresentation,
			});
			this.NeedsPresentation = Presentation.query().last();
		}
		this.ShowNeedAnalysisPrefill = true;
		EventBusLayout.$emit("CloseNeedsAnalysisPrefill", true);
	}
	NoCostBenefitdisplayGroupVideo(obj: any) {
		this.displayGroupVideo(obj);
	}
	NoCostBenefitdisplayProduct(obj: any) {
		this.displayProduct(obj[0], obj[1]);
	}

	NoCostBenefitRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}

	NoCostBenefitAction(action: any) {
		this.Action(action);
	}

	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.NoCostBenefitRoutedToEvt);
		EventBusLayout.$off("Action", this.NoCostBenefitAction);
		EventBusLayout.$off("displayProduct", this.NoCostBenefitdisplayProduct);
		EventBusLayout.$off("displayGroup", this.NoCostBenefitdisplayGroup);
		EventBusLayout.$off(
			"displayGroupVideo",
			this.NoCostBenefitdisplayGroupVideo
		);
		document.removeEventListener("keyup", this._keydownCallback);
	}

	ChangeQuesitonnaireGroups() {
		if (this.Presentation?.Id != null && this.Presentation?.Id != undefined) {
			if (
				this.Groups != undefined &&
				this.$store.state.SGNumber != undefined &&
				this.$store.state.SGNumber != null
				// &&
				// this.$store.state.SGNumber != ""
			) {
				// if (
				// 	this.Presentation.SGNumber == undefined ||
				// 	this.Presentation.SGNumber == null ||
				// 	this.Presentation.SGNumber == ""
				// ) {
				this.Presentation.SGNumber = this.$store.state.SGNumber;
				// }
				var selGroup = this.Groups.find(
					(x: any) => x.GroupCode == this.$store.state.SGNumber
				);

				if (selGroup != undefined) {
					this.QuestionnaireGroupCode = selGroup.GroupCode;
					this.QuestionnaireGroupName = selGroup.GroupName;
					this.QuestionnaireVideoUrl = selGroup.VideoUrl;
				} else {
					if (this.SelectedGroup.GroupCode != undefined) {
						this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
						this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
					} else this.QuestionnaireGroupCode = this.SelectedGroup;
					this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
				}
			} else if (
				this.Presentation.BackupPresentedGroupId != undefined &&
				this.Presentation.BackupPresentedGroupId != 0 &&
				this.Groups != undefined
			) {
				var selGroup = this.Groups.find(
					(x: any) => x.Id == this.Presentation.BackupPresentedGroupId
				);
				if (selGroup != undefined) {
					this.QuestionnaireGroupCode = selGroup.GroupCode;
					this.QuestionnaireGroupName = selGroup.GroupName;
					this.QuestionnaireVideoUrl = selGroup.VideoUrl;
				} else {
					if (this.SelectedGroup.GroupCode != undefined) {
						this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
						this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
					} else this.QuestionnaireGroupCode = this.SelectedGroup;
					this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
				}
			}
			//one condition removed
		} else {
			if (
				this.Groups != undefined &&
				this.$store.state.SGNumber != undefined &&
				this.$store.state.SGNumber != ""
			) {
				var selGroup = this.Groups.find(
					(x: any) => x.GroupCode == this.$store.state.SGNumber
				);

				if (selGroup != undefined) {
					this.QuestionnaireGroupCode = selGroup.GroupCode;
					this.QuestionnaireGroupName = selGroup.GroupName;
					this.QuestionnaireVideoUrl = selGroup.VideoUrl;
				} else {
					if (this.SelectedGroup.GroupCode != undefined) {
						this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
						this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
					} else this.QuestionnaireGroupCode = this.SelectedGroup;
					this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
				}
			}
		}
		// if (this.SelectedGroup == undefined || this.SelectedGroup == "") {
		// 	if (
		// 		this.Presentation.BackupPresentedGroupId != undefined &&
		// 		this.Presentation.BackupPresentedGroupId != 0 &&
		// 		this.Groups != undefined
		// 	) {
		// 		var selGroup = this.Groups.find(
		// 			(x: any) => x.Id == this.Presentation.BackupPresentedGroupId
		// 		);
		// 		if (selGroup != undefined) {
		// 			this.QuestionnaireGroupCode = selGroup.GroupCode;
		// 			this.QuestionnaireGroupName = selGroup.GroupName;
		// 			this.QuestionnaireVideoUrl = selGroup.VideoUrl;
		// 		} else {
		// 			if (this.SelectedGroup.GroupCode != undefined) {
		// 				this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
		// 				this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
		// 			} else this.QuestionnaireGroupCode = this.SelectedGroup;
		// 			this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
		// 		}
		// 	} else if (
		// 		this.Presentation?.Id != null &&
		// 		this.Presentation?.Id != undefined &&
		// 		this.Groups != undefined &&
		// 		this.$store.state.SGNumber != undefined &&
		// 		this.$store.state.SGNumber != ""
		// 	) {
		// 		if (
		// 			this.Presentation.SGNumber == undefined ||
		// 			this.Presentation.SGNumber == null ||
		// 			this.Presentation.SGNumber == ""
		// 		) {
		// 			this.Presentation.SGNumber = this.$store.state.SGNumber;
		// 		}
		// 		var selGroup = this.Groups.find(
		// 			(x: any) => x.GroupCode == this.Presentation.SGNumber
		// 		);

		// 		if (selGroup != undefined) {
		// 			this.QuestionnaireGroupCode = selGroup.GroupCode;
		// 			this.QuestionnaireGroupName = selGroup.GroupName;
		// 			this.QuestionnaireVideoUrl = selGroup.VideoUrl;
		// 		} else {
		// 			if (this.SelectedGroup.GroupCode != undefined) {
		// 				this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
		// 				this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
		// 			} else this.QuestionnaireGroupCode = this.SelectedGroup;
		// 			this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
		// 		}
		// 	} else {
		// 		if (
		// 			this.$store.state.SGNumber != undefined &&
		// 			this.$store.state.SGNumber != ""
		// 		) {
		// 			var selGroup = this.Groups.find(
		// 				(x: any) => x.GroupCode == this.$store.state.SGNumber
		// 			);
		// 			if (selGroup != undefined) {
		// 				this.SelectedGroup = selGroup;
		// 			}
		// 		}
		// 		if (
		// 			this.SelectedGroup != undefined &&
		// 			this.SelectedGroup.GroupCode != undefined
		// 		) {
		// 			this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
		// 			this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
		// 		} else this.QuestionnaireGroupCode = this.SelectedGroup;
		// 		this.QuestionnaireGroupName = this.SelectedGroup.GroupName;
		// 	}
		// } else {
		// 	if (this.SelectedGroup.GroupCode != undefined) {
		// 		this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
		// 		this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
		// 	} else this.QuestionnaireGroupCode = this.SelectedGroup;
		// 	this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
		// }
	}

	onCloseBrowser(event: any) {
		this.browserCloseEvent = true;
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.RoutedTo("/finishpresentation");
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	moreLessTapped() {
		if (this.products.length == this.itemLessCount) {
			this.products = JSON.parse(JSON.stringify(this.allMatchingproducts));
		} else {
			this.products = JSON.parse(
				JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
			);
		}
	}

	validate(value: string, type: string) {
		if (type == "email") {
			var valid =
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
				value.trim() == "";
			if (!valid) this.$refs.emailfield.$el.classList.add("error--text");
			else this.$refs.emailfield.$el.classList.remove("error--text");
		}
		if (type == "phone") {
			var valid = value.length > 13 || value.trim() == "";
			if (!valid) this.$refs.phonefield.$el.classList.add("error--text");
			else this.$refs.phonefield.$el.classList.remove("error--text");
		}
	}

	RoutedTo(route: String) {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		if (route == "/ssprogram") {
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";
			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}

		window.removeEventListener("beforeunload", this.onCloseBrowser);

		this.Presentation.TimeOnNoCostBenefits = (
			(parseInt(this.Presentation.TimeOnNoCostBenefits) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		if (typeof this.SelectedGroup == "string") {
			this.Presentation.SGNumber = this.SelectedGroup;
		} else if (
			this.SelectedGroup != undefined &&
			this.SelectedGroup.GroupCode != undefined
		) {
			if (
				this.$store.state.SGNumber != null &&
				this.$store.state.SGNumber != undefined &&
				this.$store.state.SGNumber != ""
			) {
				this.Presentation.SGNumber = this.$store.state.SGNumber;
			} else {
				this.Presentation.SGNumber = this.SelectedGroup.GroupCode;
			}
		}

		this.Presentation.NoCostMaterialsTime = JSON.stringify(this.materialsTime);

		this.materialsTime.forEach((element: any) => {
			console.log(element.material.name + element.time);
		});

		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});

		if (
			this.Person != undefined &&
			(this.showMemberSurvey == true ||
				this.showQuestionnaire == true ||
				this.showFamilyCareSurvey == true ||
				this.showFamilyCareSurveyVeteran == true)
		) {
			PrimaryPerson.update({
				where: this.Person.Id,
				data: this.Person,
			});

			if (this.Person.Spouse != undefined)
				Spouse.update({
					where: this.Person.Spouse.Id,
					data: this.Person.Spouse,
				});
		}

		let pres = Presentation.query().withAllRecursive().last();
		if (pres?.WhatHappened != "NEEDS ANALYSIS ONLY") {
			let saveData = new GenericKeyPair(
				"CurrentPresentation",
				Presentation.query().withAllRecursive().last()
			);
			setPersistedState(saveData);
		}

		// saveData = new GenericKeyPair(
		//   "PresentationsList",
		//   JSON.stringify(Presentation.query().withAllRecursive().all())
		// );
		// setPersistedState(saveData);

		if (route == "/finishpresentation") {
			if (this.browserCloseEvent) {
				this.browserCloseEvent = false;
				window.removeEventListener("beforeunload", this.onCloseBrowser);
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true", FromNoCost: "true" },
				});
			} else {
				this.$router.push({
					name: "FinishPresentation",
					params: { FromNoCost: "true" },
				});
			}
		} else {
			this.$router.push(route.toString());
		}
	}
	mounted() {
		this.$data.pagescale = "page-fit";
	}
	data() {
		return {
			ops: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			canvaslist: [],
			isClonedPresentation: false,
			SponsorDatePopup: false,
			Referrals: {},
			pagescale: "page-fit",
			overlay: false,
			products: this.products,
			numPages: undefined,
			playerOptions: {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: "static/ailvideo.mp4",
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			},
			PdfUrl: "",
			config: {
				toolbar: {
					toolbarViewerRight: false,
					secondaryToolbarToggle: false,
				},
			},
		};
	}

	$refs!: {
		videoPlayer: any;
		flipbook: any;
		groupComboBox: any;
		emailfield: any;
		phonefield: any;
		pdfViewer: any;
		VeteranBurialAndWillKitComp: any;
		VeteranBenefitAndBurialGuideComp: any;
		VeteranLastWillAndTestamentComp: any;
		VeteranThreeImportantFactsComp: any;
		VeteranThreeImportantFactsSMBComp: any;
		VeteranNoVeteranLeftBehindComp: any;
		GiftCertificateComp: any;
		WillKitComp: any;
		FamilyComp: any;
		McgruffFamilyComp: any;
		UnionFamilyComp: any;
		AssociationFamilyComp: any;
		FinancialComp: any;
		QuestionnnairComp: any;
		WeNeedYourHelpMcgruffComp: any;
		NeedAnalysisPrefillComp: any;
	};

	get player() {
		return this.$refs.videoPlayer.player;
	}

	get IsVeteranSMBAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId == PresentationType.Veteran &&
			this.Presentation.PresentationSubTypeId ==
				PresentationSubType.ServiceMemberBenefitsLeadAlpha
		) {
			return true;
		}
		return false;
	}

	get IsServiceMemberBenefitsAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId ==
				PresentationType.ServiceMemberBenefits &&
			(this.Presentation.PresentationSubTypeId ==
				PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.SMBVeteranFamilyLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.Civilian ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitNotification)
		) {
			return true;
		}
		return false;
	}

	private convertPDFToImage: boolean = false;

	displayProduct(item: any, index: number) {
		this.showImage = false;
		this.showVideo = false;
		this.showGroupVideo = false;
		this.showFlipbook = false;
		this.showPDF = false;
		this.showVeteranBurialAndWillKitPDF = false;
		this.showVeteranBenefitAndBurialGuidePDF = false;
		this.showVeteranLastWillAndTestamentPDF = false;
		this.showVeteranThreeImportantFactsPDF = false;
		this.showVeteranThreeImportantFactsSMBPDF = false;
		this.showVeteranNoVeteranLeftBehindPDF = false;
		this.showGiftCertificatePDF = false;
		this.showWeNeedYourHelpMcgruffPDF = false;
		this.showWillKitPDF = false;
		this.custompdfhtml = false;
		this.isGroupLetter = false;

		this.currentIndex = index;
		this.currentItem = this.materialsTime.find((x: any) => {
			if (x.material.id == item.Id) return x;
		});

		if (!this.currentItem) {
			this.currentItem = { material: item, Starttime: new Date(), time: 0 };
			this.materialsTime.push(this.currentItem);
		} else {
			this.currentItem.Starttime = new Date();
		}
		this.showMemberSurvey = false;
		this.showFamilyCareSurvey = false;
		this.showFamilyCareSurveyVeteran = false;
		this.showQuestionnaire = false;
		this.$data.overlay = false;
		switch (item.MaterialTypeId) {
			case 4:
				this.$data.overlay = !this.$data.overlay;
				this.imgSource = item.Link;
				this.showImage = true;
				break;
			case 1:
				this.$data.overlay = !this.$data.overlay;
				this.$data.PdfUrl = item.Link;
				this.showPDF = true;
				this.convertPDFToImage = false;
				break;
			case 2:
				this.$data.overlay = !this.$data.overlay;
				this.$data.playerOptions = {
					// videojs options
					muted: true,
					language: "en",
					playbackRates: [0.7, 1.0, 1.5, 2.0],
					sources: [
						{
							type: "video/mp4",
							src: item.Link,
						},
					],
					autoplay: true,
					poster: "/static/images/author.jpg",
				};
				this.showVideo = true;

				break;
			case 3:
				if (
					item.IsEditable == true &&
					this.showAllMaterials == false &&
					item.PrefillDocumentTypeId != undefined
				) {
					if (
						this.Presentation.PresentationWillKitVeterans == "" ||
						this.Presentation.PresentationWillKitVeterans == "{}"
					)
						this.Presentation.PresentationWillKitVeterans = JSON.stringify(
							new PresentationWillKitVeterans()
						);
					this.PresentationWillKitVeterans = JSON.parse(
						this.Presentation.PresentationWillKitVeterans
					);
					this.custompdfhtml = true;

					// this.PresentationWillKitVeterans.DocumentTypeId =
					// 	item.PrefillDocumentTypeId;

					if (
						this.PresentationWillKitVeterans?.DocumentTypeIdList?.indexOf(
							item.PrefillDocumentTypeId
						) == -1
					) {
						this.PresentationWillKitVeterans?.DocumentTypeIdList?.push(
							item.PrefillDocumentTypeId
						);
					}
					this.Presentation.PresentationWillKitVeterans = JSON.stringify(
						this.PresentationWillKitVeterans
					);
					Presentation.update({
						where: this.Presentation.Id,
						data: this.Presentation,
					});
					switch (item.PrefillDocumentTypeId) {
						// case 1:
						// 	this.showVeteranBurialAndWillKitPDF = true;
						// 	this.DownloadWillKitPDF = true;
						// 	break;
						case 2:
							this.showFamilyPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 3:
							this.ShowFinancialPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 4:
							this.showWillKitPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 5:
							this.showVeteranLastWillAndTestamentPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 6:
							this.showVeteranThreeImportantFactsPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 7:
							this.showVeteranBurialAndWillKitPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 8:
							this.showMcgruffFamilyPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 9:
							this.showUnionFamilyPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 10:
							this.showAssociationFamilyPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 11:
							this.showWeNeedYourHelpMcgruffPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 12:
							this.showVeteranBenefitAndBurialGuidePDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 13:
							this.showVeteranThreeImportantFactsSMBPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 14:
							this.showVeteranNoVeteranLeftBehindPDF = true;
							this.DownloadWillKitPDF = true;
							break;
						case 15:
							this.showGiftCertificatePDF = true;
							this.DownloadWillKitPDF = true;
							break;
					}
				} else {
					this.DownloadWillKitPDF = false;
					this.$data.PdfUrl = item.Link;
					this.fileName = item.Name;
					this.$data.overlay = !this.$data.overlay;
					var languageFlipbook =
						item["flipbookpages-" + this.Presentation.LanguageFlag];
					if (languageFlipbook != undefined && languageFlipbook.length > 0) {
						this.flipBookPages = languageFlipbook;
					} else {
						this.flipBookPages = item.FlipbookPages;
					}
					this.showFlipbook = true;
				}
				break;
			case 5:
				//this.$data.overlay = true;
				if (this.showAllMaterials == false) {
					this.DownloadWillKitPDF = true;
					if (this.Presentation.PresentationWillKitVeterans == "")
						this.Presentation.PresentationWillKitVeterans = JSON.stringify(
							new PresentationWillKitVeterans()
						);
					this.PresentationWillKitVeterans = JSON.parse(
						this.Presentation.PresentationWillKitVeterans
					);

					this.custompdfhtml = true;
					if (this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) {
						this.showVeteranBenefitAndBurialGuidePDF = true;
					} else {
						this.showVeteranBurialAndWillKitPDF = true;
					}
				} else {
					this.$data.PdfUrl = item.Link;
					this.$data.overlay = !this.$data.overlay;
					var languageFlipbook =
						item["flipbookpages-" + this.Presentation.LanguageFlag];
					if (languageFlipbook != undefined && languageFlipbook.length > 0) {
						this.flipBookPages = languageFlipbook;
					} else {
						this.flipBookPages = item.FlipbookPages;
					}
					this.showFlipbook = true;
				}
				break;
			case 6:
				if (
					this.Presentation != null &&
					this.Presentation.LeadId != undefined &&
					this.Presentation.LeadId != "" &&
					this.Presentation.LeadId != "0"
				) {
					this.flipBookPages = [];
					this.showFlipbook = false;
					this.$store.state.loader = true;
					var parameters: GenericKeyPair[] = [];
					parameters.push(
						new GenericKeyPair("leadId", this.Presentation.LeadId)
					);
					parameters.push(new GenericKeyPair("associateid", GetAssociateId()));
					parameters.push(new GenericKeyPair("certType", 1));

					var RequestTime = moment(new Date()).utc();

					GetRequestWithAuthorization(
						ApiEndPointConstants.GetInfoCertificateUrlEndPoint,
						parameters,
						10000
					)
						.then((res: any) => {
							this.$store.state.loader = false;
							this.$data.overlay = !this.$data.overlay;

							if (
								res != null &&
								res.Result != undefined &&
								res.Result.FileData != undefined
							) {
								this.$data.overlay = true;
								this.$data.PdfUrl = Base64ToArrayBuffer(res.Result.FileData);
								this.showPDF = true;
								this.convertPDFToImage = false;
							} else {
								if (res != undefined && res.status == false) {
									LoggingHelper.getInstance().LogEvent(
										LoggingConstants.ADNDCertificateLoadingIssue
									);
									this.LogADNDCertificateError(
										this.Presentation.LeadId,
										RequestTime
									);
								}
								this.$data.PdfUrl = item.Link;
								this.fileName = item.Name;

								var languageFlipbook =
									item["flipbookpages-" + this.Presentation.LanguageFlag];
								if (
									languageFlipbook != undefined &&
									languageFlipbook.length > 0
								) {
									this.flipBookPages = languageFlipbook;
								} else {
									this.flipBookPages = item.FlipbookPages;
								}
								this.showFlipbook = true;
							}
						})
						.catch((ex: any) => {});
				} else {
					this.$data.PdfUrl = item.Link;
					this.fileName = item.Name;
					this.$data.overlay = !this.$data.overlay;
					var languageFlipbook =
						item["flipbookpages-" + this.Presentation.LanguageFlag];
					if (languageFlipbook != undefined && languageFlipbook.length > 0) {
						this.flipBookPages = languageFlipbook;
					} else {
						this.flipBookPages = item.FlipbookPages;
					}
					this.showFlipbook = true;
				}
				break;
			case 7:
				if (item.Name == "LWTPS - NO COST LAW") {
					this.showFamilyCareSurvey = true;
				} else if (item.Name == "VETERAN SURVEY") {
					this.showFamilyCareSurveyVeteran = true;
				} else {
					this.showMemberSurvey = true;
				}

				break;

			case 8:
				this.showQuestionnaire = true;
				this.ChangeQuesitonnaireGroups();
				break;
		}
		LoggingHelper.getInstance().LogEvent(LoggingConstants.MaterialOpened);
	}

	arrayBufferToBase64(buffer: any) {
		var binary = "";
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}

	LogADNDCertificateError(LeadId: number, RequestTime: any) {
		var objectData: any = {};
		objectData.LeadId = LeadId;
		objectData.RequestTime = RequestTime;
		objectData.TerminateTime = moment(new Date()).utc();

		PostRequestWithAuthorization(
			ApiEndPointConstants.PostADNDErrorLogEndPoint,
			[],
			objectData
		)
			.then((res: any) => {})
			.catch((ex: any) => {});
	}

	Action(actionName: String) {
		if (actionName == "materials") {
			this.showMatierials = !this.showMatierials;
			return;
		}

		if (actionName == "report") {
			this.report = !this.report;
			return;
		}
	}

	CloseOverlay() {
		//evt.stopImmediatePropagation();
		// this.$data.overlay = !this.$data.overlay;
		this.$data.overlay = false;
		this.CloseMaterial();
	}

	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}

	CloseCustomPDFPopup() {
		// this.DownloadPopupText = Camelize(this.currentItem.material.MaterialName);
		// if (this.showVeteranBurialAndWillKitPDF == true) {
		// 	if (this.$refs.VeteranBurialAndWillKitComp.isClosable() == true) {
		// 		this.custompdfhtml = false;
		// 		this.showVeteranBurialAndWillKitPDF = false;
		// 	} else this.ShowDownloadPopup = true;
		// } else if (this.showVeteranLastWillAndTestamentPDF == true) {
		// 	if (this.$refs.VeteranLastWillAndTestamentComp.isClosable() == true) {
		// 		this.custompdfhtml = false;
		// 		this.showVeteranLastWillAndTestamentPDF = false;
		// 	} else this.ShowDownloadPopup = true;
		// } else if (this.showVeteranThreeImportantFactsPDF == true) {
		// 	if (this.$refs.VeteranThreeImportantFactsComp.isClosable() == true) {
		// 		this.custompdfhtml = false;
		// 		this.showVeteranThreeImportantFactsPDF = false;
		// 	} else this.ShowDownloadPopup = true;
		// } else if (this.showWillKitPDF == true) {
		// 	// if (this.$refs.WillKitComp.isClosable() == true) {
		// 	this.custompdfhtml = false;
		// 	this.showWillKitPDF = false;
		// 	// } // else this.ShowDownloadPopup = true;
		// } else {
		// 	this.custompdfhtml = false;
		// 	this.showVeteranBurialAndWillKitPDF = false;
		// 	this.ShowFinancialPDF = false;
		// 	this.showFamilyPDF = false;
		// }

		this.custompdfhtml = false;
		this.showVeteranBurialAndWillKitPDF = false;
		this.showVeteranLastWillAndTestamentPDF = false;
		this.showVeteranThreeImportantFactsPDF = false;
		this.showVeteranBenefitAndBurialGuidePDF = false;
		this.showVeteranThreeImportantFactsSMBPDF = false;
		this.showVeteranNoVeteranLeftBehindPDF = false;
		this.showWillKitPDF = false;
		this.ShowFinancialPDF = false;
		this.showFamilyPDF = false;
		this.showMcgruffFamilyPDF = false;
		this.showUnionFamilyPDF = false;
		this.showAssociationFamilyPDF = false;
		this.showWeNeedYourHelpMcgruffPDF = false;
		this.ShowNeedAnalysisPrefill = false;

		if (!this.custompdfhtml) {
			this.CloseMaterial();
		}
	}

	GroupSGBlur(v: any, text: any) {
		var previouslySelectedGroupCode: any = undefined;
		if (this.SelectedGroup != undefined) {
			previouslySelectedGroupCode = JSON.parse(
				JSON.stringify(this.SelectedGroup)
			);
		}
		var text = this.$refs.groupComboBox.lazySearch.toUpperCase();
		if (text != undefined && text != "") {
			var list = this.Groups.filter(
				(x: any) =>
					x.GroupCode != undefined &&
					x.GroupCode.toUpperCase() == text.toUpperCase()
			);
			if (list != undefined && list.length >= 1) {
				//console.log("Setting new value: " + list[0]);
				this.SelectedGroup = list[0];
				if (
					this.SelectedGroup != undefined &&
					this.$data.Referrals != undefined
				) {
					this.$data.Referrals.Organization = this.SelectedGroup.GroupName;
					this.Presentation.PresentedGroup = this.SelectedGroup.GroupName;
					//this.$data.Referrals = this.$data.Referrals;
				}
			} else {
				//console.log("Setting Previosuly value: " + previouslySelectedGroupCode);

				setTimeout(() => {
					this.SelectedGroup = previouslySelectedGroupCode;
					if (previouslySelectedGroupCode != undefined)
						this.Presentation.PresentedGroup =
							previouslySelectedGroupCode.GroupName;
					this.ChangeQuesitonnaireGroups();
				}, 50);
			}
		}

		this.$refs.groupComboBox.isMenuActive = false;
	}

	GroupChange(evt: any) {
		if (evt != undefined) {
			this.Presentation.PresentedGroup = evt.GroupName;
		}
		this.ChangeQuesitonnaireGroups();
	}

	SaveWillKitDataToPresentationForGiftCertificate(
		vetWillkitObj: any,
		IsGiftCertificateFirstTimeOpen: boolean
	) {
		if (
			this.Presentation != undefined &&
			this.Presentation.IsGiftCertificateFirstTimeOpen != undefined
		) {
			this.Presentation.IsGiftCertificateFirstTimeOpen =
				IsGiftCertificateFirstTimeOpen;
		}
		this.SaveWillKitDataToPresentation(vetWillkitObj);
	}

	SaveWillKitDataToPresentation(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			if (
				this.Presentation.FamilyCareSurvey != undefined &&
				this.Presentation.FamilyCareSurvey != ""
			) {
				var FamilyCare = JSON.parse(this.Presentation.FamilyCareSurvey);
				FamilyCare.PrimaryFuneralType = vetWillkitObj.FuneralType;
				FamilyCare.PrimaryCemeteryType = vetWillkitObj.VeteransType;
				FamilyCare.SpouseFuneralType = vetWillkitObj.SpouseFuneralType;
				FamilyCare.SpouseCemeteryType = vetWillkitObj.SpouseVeteransType;
				FamilyCare.BranchOfService = vetWillkitObj.BranchOfService;
				FamilyCare.CurrentVeteranStatus = vetWillkitObj.DischargeStatus;
				FamilyCare.CampaignMedalsReceived =
					vetWillkitObj.CampaignMedalsReceived;
				FamilyCare.WarService = vetWillkitObj.NameOfWar;
				FamilyCare.RankRateAtDischarge = vetWillkitObj.RankRateAtDischarge;
				FamilyCare.VSOMembership = vetWillkitObj.VSOMembership;
				FamilyCare.CurrentVSOMember = vetWillkitObj.CurrentVSOMember;
				this.Presentation.FamilyCareSurvey = JSON.stringify(FamilyCare);
			}
			if (
				vetWillkitObj.isPlanCalculationNeeded &&
				!this.Presentation.isPlanCalculationNeeded
			) {
				this.Presentation.isPlanCalculationNeeded =
					vetWillkitObj.isPlanCalculationNeeded;
			}
			if (
				this.Presentation.PresentationWillKitVeterans !=
					JSON.stringify(vetWillkitObj) &&
				!this.MaterialUpdated
			) {
				this.MaterialUpdated = true;
				EventBusLayout.$emit("DataUpdatedfromMaterial");
			}
			this.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
			this.Person = PrimaryPerson.query().last();

			if (
				vetWillkitObj.WillKitChildren != null &&
				vetWillkitObj.WillKitChildren != undefined &&
				vetWillkitObj.WillKitChildren != ""
			) {
				let children = JSON.parse(vetWillkitObj.WillKitChildren);
				// let deseasedChildren = children.filter(
				// 	(x: any) => x.FirstName != "" && x.AgePerDOB != null && !x.IsLiving
				// );
				// children = children.filter(
				// 	(x: any) =>
				// 		x.FirstName != "" &&
				// 		x.AgePerDOB != null &&
				// 		(x.IsLiving == null || x.IsLiving)
				// );

				let adultchildren: any = [];
				let depchildren: any = [];
				if (
					this.Person.AdultChildrenString != undefined &&
					this.Person.AdultChildrenString != ""
				) {
					adultchildren = JSON.parse(this.Person.AdultChildrenString);
				}
				if (
					this.Person.ChildrenString != undefined &&
					this.Person.ChildrenString != ""
				) {
					depchildren = JSON.parse(this.Person.ChildrenString);
				}
				// if (deseasedChildren.length > 0) {
				// 	deseasedChildren.forEach((child: any) => {
				// 		var depfiltered = depchildren.filter(function (x: any) {
				// 			return x.Id == child.Id;
				// 		});
				// 		if (depfiltered.length > 0) {
				// 			depchildren.splice(
				// 				depchildren.indexOf(
				// 					depchildren.find((x: any) => x.Id == child.Id)
				// 				),
				// 				1
				// 			);
				// 		}

				// 		var adultfiltered = adultchildren.filter(function (x: any) {
				// 			return x.Id == child.Id;
				// 		});
				// 		if (adultfiltered.length > 0) {
				// 			adultchildren.splice(
				// 				adultchildren.indexOf(
				// 					adultchildren.find((x: any) => x.Id == child.Id)
				// 				),
				// 				1
				// 			);
				// 		}
				// 	});
				// }
				children.forEach((child: any) => {
					var depfiltered = depchildren.filter(function (x: any) {
						return x.Id == child.Id;
					});
					if (depfiltered.length > 0) {
						depchildren.splice(
							depchildren.indexOf(
								depchildren.find((x: any) => x.Id == child.Id)
							),
							1
						);
					}

					var adultfiltered = adultchildren.filter(function (x: any) {
						return x.Id == child.Id;
					});
					if (adultfiltered.length > 0) {
						adultchildren.splice(
							adultchildren.indexOf(
								adultchildren.find((x: any) => x.Id == child.Id)
							),
							1
						);
					}

					if (
						child.FirstName != "" &&
						child.AgePerDOB != null &&
						(child.IsLiving == null || child.IsLiving)
					) {
						if (child.AgePerDOB < 18) {
							depchildren.push(child);
						} else {
							adultchildren.push(child);
						}
					}
				});

				if (depchildren.length > 0) {
					depchildren.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);
					this.Person.HasChildrenUnder18 = true;
					this.Person.ChildrenString = JSON.stringify(depchildren);
				} else {
					this.Person.HasChildrenUnder18 = false;
					this.Person.ChildrenString = "";
				}

				if (adultchildren.length > 0) {
					adultchildren.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);
					this.Person.AdultChildrenString = JSON.stringify(adultchildren);
				} else {
					this.Person.AdultChildrenString = "";
				}
			}

			PrimaryPerson.update({
				where: this.Person.Id,
				data: this.Person,
			});
		}
	}

	SaveWillKitDataToPresentationOnly(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			if (
				this.Presentation.FamilyCareSurvey != undefined &&
				this.Presentation.FamilyCareSurvey != ""
			) {
				var FamilyCare = JSON.parse(this.Presentation.FamilyCareSurvey);
				FamilyCare.PrimaryFuneralType = vetWillkitObj.FuneralType;
				FamilyCare.PrimaryCemeteryType = vetWillkitObj.VeteransType;
				FamilyCare.BranchOfService = vetWillkitObj.BranchOfService;
				FamilyCare.WarService = vetWillkitObj.NameOfWar;
				FamilyCare.RankRateAtDischarge = vetWillkitObj.RankRateAtDischarge;
				this.Presentation.FamilyCareSurvey = JSON.stringify(FamilyCare);
			}
			this.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			if (this.Presentation.PresentationWillKitVeterans == "{}")
				this.Presentation.PresentationWillKitVeterans = "";
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}
	SaveFamilyCareDatatoPresentation(obj: any) {
		if (obj != undefined) {
			this.Presentation.PresentationFamilyCareSurvey = JSON.stringify(obj);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	SaveQuestionnaireData(Questionnaire: any, CloseWindow: boolean) {
		if (Questionnaire != undefined) {
			Questionnaire.PresentationGroupId = this.Presentation.PresentedGroupId;

			if (this.Presentation.MemberQuestionnaire != "") {
				if (
					this.Presentation.MemberQuestionnaire !=
						JSON.stringify(Questionnaire) &&
					!this.MaterialUpdated
				) {
					this.MaterialUpdated = true;
					EventBusLayout.$emit("DataUpdatedfromMaterial");
				}
			} else {
				var QuestionnaireObj = new MemberQuestionnaire();
				QuestionnaireObj.Id = Questionnaire.Id;
				if (JSON.stringify(QuestionnaireObj) != JSON.stringify(Questionnaire)) {
					this.MaterialUpdated = true;
					EventBusLayout.$emit("DataUpdatedfromMaterial");
				}
			}
			this.Presentation.MemberQuestionnaire = JSON.stringify(Questionnaire);
			let pres = Presentation.query().withAllRecursive().last();
			if (pres?.WhatHappened != "NEEDS ANALYSIS ONLY") {
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
				this.ChangePresentationFromCreditUnionOrQuestionnaire(Questionnaire);
			}
		}

		if (CloseWindow) {
			this.showQuestionnaire = false;
			this.CloseMaterial();
		}
	}

	SaveMemberSurveyData(MemberSurvery: any, CloseWindow: boolean) {
		if (MemberSurvery != undefined) {
			if (this.Presentation.MemberSurvey != "") {
				if (
					this.Presentation.MemberSurvey != JSON.stringify(MemberSurvery) &&
					!this.MaterialUpdated
				) {
					this.MaterialUpdated = true;
					EventBusLayout.$emit("DataUpdatedfromMaterial");
				}
			} else {
				var msObj = new MemberSurvey();
				msObj.Id = MemberSurvery.Id;
				if (JSON.stringify(msObj) != JSON.stringify(MemberSurvery)) {
					this.MaterialUpdated = true;
					EventBusLayout.$emit("DataUpdatedfromMaterial");
				}
			}
			this.Presentation.MemberSurvey = JSON.stringify(MemberSurvery);
			let pres = Presentation.query().withAllRecursive().last();
			if (pres?.WhatHappened != "NEEDS ANALYSIS ONLY") {
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
				this.ChangePresentationFromCreditUnionOrQuestionnaire(MemberSurvery);
			}
		}

		if (CloseWindow) {
			this.showMemberSurvey = false;
			this.CloseMaterial();
		}
	}
	SaveNeedsOnlyPresentationData(PresentationData: any, CloseWindow: boolean) {
		Spouse.update({
			where: PresentationData.PresentedTo.Spouse.Id,
			data: PresentationData.PresentedTo.Spouse,
		});
		PrimaryPerson.update({
			where: PresentationData.PresentedTo.Id,
			data: PresentationData.PresentedTo,
		});
		Presentation.update({
			where: PresentationData.Pres.Id,
			data: PresentationData.Pres,
		});
		if (CloseWindow) {
			this.ShowNeedAnalysisPrefill = false;
			this.CloseMaterial();
		}
	}
	ClearNeedsOnlyPresentationData(CloseWindow: boolean) {
		this.DeleteDataFromNeedsEntities();
		if (CloseWindow) {
			this.ShowNeedAnalysisPrefill = false;
			this.CloseMaterial();
		}
	}
	SyncNeedsAnalysisOnlyPresentationFromNoCost() {
		var NeedAnalysisPresentation = Presentation.query()
			.withAllRecursive()
			.last();
		this.CheckAndSavePresentation(NeedAnalysisPresentation);
	}
	async CheckAndSavePresentation(Pres: any) {
		if (window.navigator.onLine) {
			setTimeout(() => {
				this.$store.state.loader = true;
				this.SavePresentation(Pres);
			}, 100);
		} else {
			let saveData = new GenericKeyPair("CurrentPresentation", Pres);
			await setPersistedState(saveData);
			setTimeout(() => {
				this.$router.push("/dashboard");
				PresentationSync.getInstance().SyncPresentation();
			}, 500);
			this.DeleteDataFromNeedsEntities();
		}
	}
	SavePresentation(completePresentation: any) {
		try {
			var mapPresDTO = PrsentationEntityToDTO(completePresentation);
			var GenericKeyPairobj: GenericKeyPair[] = [];
			this.DeleteDataFromNeedsEntities();

			APIService.SavePresentation(mapPresDTO, GenericKeyPairobj).then(
				async (res: any) => {
					if (res != undefined && res.data == true) {
					} else {
						console.log("unable to sync");
						let saveData = new GenericKeyPair(
							"CurrentPresentation",
							completePresentation
						);
						setPersistedState(saveData);
					}
					this.RedirectToDashboardScreen();
				},
				(ex: any) => {
					let saveData = new GenericKeyPair(
						"CurrentPresentation",
						completePresentation
					);
					setPersistedState(saveData);
					this.RedirectToDashboardScreen();
				}
			);
		} catch (ex: any) {
			console.log("Presentation syncing failed : " + ex.message);
			let saveData = new GenericKeyPair(
				"CurrentPresentation",
				completePresentation
			);
			setPersistedState(saveData);
			this.RedirectToDashboardScreen();
		}
	}

	DeleteDataFromNeedsEntities() {
		Presentation.deleteAll();
		PrimaryPerson.deleteAll();
		Spouse.deleteAll();
	}

	RedirectToDashboardScreen() {
		setTimeout(() => {
			this.$router.push("/dashboard");
			this.$store.state.loader = false;
		}, 500);
	}

	SaveFamilyCareSurveyData(FamilyCareSurvery: any, CloseWindow: boolean) {
		if (FamilyCareSurvery != undefined) {
			if (
				this.Presentation.PresentationWillKitVeterans != undefined &&
				this.Presentation.PresentationWillKitVeterans != ""
			) {
				var WillkitVet = JSON.parse(
					this.Presentation.PresentationWillKitVeterans
				);
				WillkitVet.FuneralType = FamilyCareSurvery.PrimaryFuneralType;
				WillkitVet.SpouseFuneralType = FamilyCareSurvery.SpouseFuneralType;
				WillkitVet.VeteransType = FamilyCareSurvery.PrimaryCemeteryType;
				WillkitVet.SpouseVeteransType = FamilyCareSurvery.SpouseCemeteryType;
				WillkitVet.BranchOfService = FamilyCareSurvery.BranchOfService;
				WillkitVet.DischargeStatus = FamilyCareSurvery.CurrentVeteranStatus;
				WillkitVet.CampaignMedalsReceived =
					FamilyCareSurvery.CampaignMedalsReceived;
				WillkitVet.NameOfWar = FamilyCareSurvery.WarService;
				WillkitVet.RankRateAtDischarge = FamilyCareSurvery.RankRateAtDischarge;
				WillkitVet.VSOMembership = FamilyCareSurvery.VSOMembership;
				WillkitVet.CurrentVSOMember = FamilyCareSurvery.CurrentVSOMember;
				this.Presentation.PresentationWillKitVeterans =
					JSON.stringify(WillkitVet);
			}

			if (
				FamilyCareSurvery.isPlanCalculationNeeded &&
				!this.Presentation.isPlanCalculationNeeded
			) {
				this.Presentation.isPlanCalculationNeeded =
					FamilyCareSurvery.isPlanCalculationNeeded;
			}

			if (this.Presentation.FamilyCareSurvey != "") {
				if (
					this.Presentation.FamilyCareSurvey !=
						JSON.stringify(FamilyCareSurvery) &&
					!this.MaterialUpdated
				) {
					this.MaterialUpdated = true;
					EventBusLayout.$emit("DataUpdatedfromMaterial");
				}
			} else {
				var fcObj = new FamilyCareSurvey();
				fcObj.Id = FamilyCareSurvery.Id;
				fcObj.IsVeteran = FamilyCareSurvery.IsVeteran;
				if (JSON.stringify(fcObj) != JSON.stringify(FamilyCareSurvery)) {
					this.MaterialUpdated = true;
					EventBusLayout.$emit("DataUpdatedfromMaterial");
				}
			}
			// if (
			// 	this.Presentation.FamilyCareSurvey !=
			// 		JSON.stringify(FamilyCareSurvery) &&
			// 	this.Presentation.FamilyCareSurvey != "" &&
			// 	!this.MaterialUpdated
			// ) {
			// 	this.MaterialUpdated = true;
			// 	EventBusLayout.$emit("DataUpdatedfromMaterial");
			// }
			if (Object.keys(this.Presentation)?.length == 0) {
				this.Presentation = new Presentation();
			}
			this.Presentation.FamilyCareSurvey = JSON.stringify(FamilyCareSurvery);
			let pres = Presentation.query().withAllRecursive().last();
			if (pres?.WhatHappened != "NEEDS ANALYSIS ONLY") {
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
				this.ChangePresentationFromFamilyCareSurvey(FamilyCareSurvery);
			}
		}

		if (CloseWindow) {
			this.showFamilyCareSurvey = false;
			this.showFamilyCareSurveyVeteran = false;
			this.CloseMaterial();
		}
	}

	ChangePresentationFromCreditUnionOrQuestionnaire(memberSurvey: any) {
		if (this.Person != undefined) {
			if (
				memberSurvey.MemberFirstName != undefined &&
				memberSurvey.MemberFirstName != ""
			)
				this.Person.FirstName = memberSurvey.MemberFirstName;
			if (
				memberSurvey.MemberLastName != undefined &&
				memberSurvey.MemberLastName != ""
			)
				this.Person.LastName = memberSurvey.MemberLastName;
			if (memberSurvey.Email != undefined && memberSurvey.Email != "")
				this.Person.Email = memberSurvey.Email;
			if (
				memberSurvey.PhoneNumber != undefined &&
				memberSurvey.PhoneNumber != ""
			)
				this.Person.Phone = memberSurvey.PhoneNumber;
			if (this.Person.Spouse != undefined) {
				if (
					memberSurvey.SpouseFirstName != undefined &&
					memberSurvey.SpouseFirstName != ""
				)
					this.Person.Spouse.FirstName = memberSurvey.SpouseFirstName;
				if (
					memberSurvey.SpouseLastName != undefined &&
					memberSurvey.SpouseLastName != ""
				)
					this.Person.Spouse.LastName = memberSurvey.SpouseLastName;
			}
		}
	}
	CloseMaterial() {
		this.$data.overlay = false;
		EventBusLayout.$emit("CloseMaterial", true);
	}
	ChangePresentationFromFamilyCareSurvey(FamilyCare: any) {
		let wageType: any;
		if (this.Person != undefined) {
			if (
				FamilyCare.PrimaryFirstName != undefined &&
				FamilyCare.PrimaryFirstName != ""
			)
				this.Person.FirstName = FamilyCare.PrimaryFirstName;
			if (
				FamilyCare.PrimaryLastName != undefined &&
				FamilyCare.PrimaryLastName != ""
			)
				this.Person.LastName = FamilyCare.PrimaryLastName;
			if (FamilyCare.PrimaryEmail != undefined && FamilyCare.PrimaryEmail != "")
				this.Person.Email = FamilyCare.PrimaryEmail;
			if (
				FamilyCare.PrimaryDateofBirth != undefined &&
				FamilyCare.PrimaryDateofBirth != ""
			)
				this.Person.DateOfBirth = FamilyCare.PrimaryDateofBirth;
			if (
				FamilyCare.PrimaryGender != undefined &&
				FamilyCare.PrimaryGender != ""
			)
				this.Person.Gender = FamilyCare.PrimaryGender;
			if (
				FamilyCare.PrimaryOccupation != undefined &&
				FamilyCare.PrimaryOccupation != ""
			)
				this.Person.Occupation = FamilyCare.PrimaryOccupation;
			if (FamilyCare.HouseHoldType != null && FamilyCare.HouseHoldType != "") {
				HpProHouseHoldTypeDiscription.forEach((discription, value) => {
					if (discription.toLowerCase() == FamilyCare.HouseHoldType) {
						this.Person.HouseHoldType = value;
					}
				});
			}
			if (
				FamilyCare.SumPrimaryInsuranceThroughWorkWholeLife != null &&
				FamilyCare.SumPrimaryInsuranceThroughWorkWholeLife != undefined
			) {
				this.Person.InsuranceThroughWorkWholeLife =
					FamilyCare.SumPrimaryInsuranceThroughWorkWholeLife;
			}
			if (
				FamilyCare.SumPrimaryInsuranceThroughWorkTermLife != null &&
				FamilyCare.SumPrimaryInsuranceThroughWorkTermLife != undefined
			) {
				this.Person.InsuranceThroughWorkTermLife =
					FamilyCare.SumPrimaryInsuranceThroughWorkTermLife;
			}
			if (
				FamilyCare.SumPrimaryInsuranceOutsideWorkWholeLife != null &&
				FamilyCare.SumPrimaryInsuranceOutsideWorkWholeLife != undefined
			) {
				this.Person.InsuranceOutsideWorkWholeLife =
					FamilyCare.SumPrimaryInsuranceOutsideWorkWholeLife;
			}
			if (
				FamilyCare.SumPrimaryInsuranceOutsideWorkTermLife != null &&
				FamilyCare.SumPrimaryInsuranceOutsideWorkTermLife != undefined
			) {
				this.Person.InsuranceOutsideWorkTermLife =
					FamilyCare.SumPrimaryInsuranceOutsideWorkTermLife;
			}
			if (
				FamilyCare.PrimaryInsuranceThroughWork != undefined &&
				FamilyCare.PrimaryInsuranceThroughWork != ""
			) {
				this.Person.InsuranceThroughWorkString =
					FamilyCare.PrimaryInsuranceThroughWork;
			}
			if (
				FamilyCare.PrimaryInsuranceOutsideWork != undefined &&
				FamilyCare.PrimaryInsuranceOutsideWork != ""
			) {
				this.Person.InsuranceOutsideWorkString =
					FamilyCare.PrimaryInsuranceOutsideWork;
			}
			if (
				FamilyCare.SpouseInsuranceThroughWork != undefined &&
				FamilyCare.SpouseInsuranceThroughWork != ""
			) {
				this.Person.Spouse.InsuranceThroughWorkString =
					FamilyCare.SpouseInsuranceThroughWork;
			}
			if (
				FamilyCare.SpouseInsuranceOutsideWork != undefined &&
				FamilyCare.SpouseInsuranceOutsideWork != ""
			) {
				this.Person.Spouse.InsuranceOutsideWorkString =
					FamilyCare.SpouseInsuranceOutsideWork;
			}
			if (
				FamilyCare.FamilyCareSurveyPropertyString != undefined &&
				FamilyCare.FamilyCareSurveyPropertyString != ""
			) {
				this.Person.NeedAnalysisPropertiesString =
					FamilyCare.FamilyCareSurveyPropertyString;
			}
			if (
				FamilyCare.PrimaryAnnualIncome != null &&
				FamilyCare.PrimaryAnnualIncome != undefined
			) {
				if (
					this.Presentation != null &&
					this.Presentation != undefined &&
					this.Presentation.WageType != null &&
					this.Presentation.WageType != ""
				) {
					wageType = this.Presentation.WageType;
				} else {
					wageType = "HOURLY";
				}

				switch (wageType) {
					case "HOURLY":
						this.Person.Wage = RoundUpperDecimal(
							Number(
								FamilyCare.PrimaryAnnualIncome.replaceAll(",", "") /
									8 /
									5 /
									4.333 /
									12
							)
						).toFixed(2);
						break;
					case "WEEKLY":
						this.Person.Wage = RoundUpperDecimal(
							Number(
								FamilyCare.PrimaryAnnualIncome.replaceAll(",", "") / 4.333 / 12
							)
						).toFixed(2);
						break;
					case "MONTHLY":
						this.Person.Wage = RoundUpperDecimal(
							Number(FamilyCare.PrimaryAnnualIncome.replaceAll(",", "") / 12)
						).toFixed(2);
						break;
					case "YEARLY":
						this.Person.Wage = RoundUpperDecimal(
							Number(FamilyCare.PrimaryAnnualIncome.replaceAll(",", ""))
						).toFixed(2);
						break;
				}
				// this.Person.Wage = (
				// 	Number(FamilyCare.PrimaryAnnualIncome.replaceAll(",", "")) /
				// 	12 /
				// 	4.3333 /
				// 	5 /
				// 	8
				// ).toFixed(2);
			}
			this.Person.Checking = FamilyCare.HasCheckingAccount;
			this.Person.Saving = FamilyCare.HasSavingAccount;
			if (
				FamilyCare.DependentChildrenString != undefined &&
				FamilyCare.DependentChildrenString != "" &&
				FamilyCare.DependentChildrenString != "[]"
			) {
				this.Person.HasChildrenUnder18 = true;
				let children = JSON.parse(FamilyCare.DependentChildrenString);

				// if (children.length > 5) {
				// 	children.splice(5, children.length - 5);
				// 	this.Person.ChildrenString = JSON.stringify(children);
				// } else {
				this.Person.ChildrenString = FamilyCare.DependentChildrenString;
				// }
			} else {
				this.Person.HasChildrenUnder18 = false;
				this.Person.ChildrenString = "";
			}
			if (
				FamilyCare.AdultChildrenString != undefined &&
				FamilyCare.AdultChildrenString != "" &&
				FamilyCare.AdultChildrenString != "[]"
			) {
				//this.Person.HasChildrenUnder18 = true;
				let children = JSON.parse(FamilyCare.AdultChildrenString);
				children.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);
				if (children.length > 0) {
					this.Person.AdultChildrenString = JSON.stringify(children);
				} else {
					this.Person.AdultChildrenString = "";
				}
			} else {
				//this.Person.HasChildrenUnder18 = false;
				this.Person.AdultChildrenString = "";
			}

			if (
				FamilyCare.FamilyCarePropertyOwn ||
				FamilyCare.FamilyCarePropertyRent
			) {
				// if (
				// 	(FamilyCare.FamilyCarePropertyOwn &&
				// 		FamilyCare.FamilyCarePropertyRent) ||
				// 	(FamilyCare.FamilyCarePropertyOwn &&
				// 		!FamilyCare.FamilyCarePropertyRent)
				// ) {
				// 	this.Person.OwnOrRent = "OWN";
				// } else if (
				// 	!FamilyCare.FamilyCarePropertyOwn &&
				// 	FamilyCare.FamilyCarePropertyRent
				// ) {
				// 	this.Person.OwnOrRent = "RENT";
				// }
				this.Person.OwnHome = FamilyCare.FamilyCarePropertyOwn;
				this.Person.RentHome = FamilyCare.FamilyCarePropertyRent;
				if (
					FamilyCare.FamilyCarePropertySum != undefined &&
					FamilyCare.FamilyCarePropertySum != ""
				) {
					this.Person.MonthlyRent = FamilyCare.FamilyCarePropertySum;
				}
				if (
					FamilyCare.FamilyCarePropertyMortgage != undefined &&
					FamilyCare.FamilyCarePropertyMortgage != ""
				) {
					this.Person.MortgageBalance = FamilyCare.FamilyCarePropertyMortgage;
				}
				if (
					FamilyCare.FamilyCarePropertyRate != undefined &&
					FamilyCare.FamilyCarePropertyRate != ""
				) {
					this.Person.MortgageInterestRate = FamilyCare.FamilyCarePropertyRate;
				}
				if (
					FamilyCare.FamilyCarePropertyYrsRemaining != undefined &&
					FamilyCare.FamilyCarePropertyYrsRemaining != ""
				) {
					this.Person.MortgageYearsRemaining =
						FamilyCare.FamilyCarePropertyYrsRemaining;
				}
			}
			if (this.Person.Spouse != undefined) {
				if (
					FamilyCare.SpouseFirstName != undefined &&
					FamilyCare.SpouseFirstName != ""
				)
					this.Person.Spouse.FirstName = FamilyCare.SpouseFirstName;
				if (
					FamilyCare.SpouseLastName != undefined &&
					FamilyCare.SpouseLastName != ""
				)
					this.Person.Spouse.LastName = FamilyCare.SpouseLastName;
				if (
					FamilyCare.SpouseDateofBirth != undefined &&
					FamilyCare.SpouseDateofBirth != ""
				)
					this.Person.Spouse.DateOfBirth = FamilyCare.SpouseDateofBirth;
				if (
					FamilyCare.SpouseGender != undefined &&
					FamilyCare.SpouseGender != ""
				)
					this.Person.Spouse.Gender = FamilyCare.SpouseGender;
				if (
					FamilyCare.SpouseOccupation != undefined &&
					FamilyCare.SpouseOccupation != ""
				)
					this.Person.Spouse.Occupation = FamilyCare.SpouseOccupation;
				if (
					FamilyCare.SumSpouseInsuranceThroughWorkWholeLife != null &&
					FamilyCare.SumSpouseInsuranceThroughWorkWholeLife != undefined
				) {
					this.Person.Spouse.InsuranceThroughWorkWholeLife =
						FamilyCare.SumSpouseInsuranceThroughWorkWholeLife;
				}
				if (
					FamilyCare.SumSpouseInsuranceThroughWorkTermLife != null &&
					FamilyCare.SumSpouseInsuranceThroughWorkTermLife != undefined
				) {
					this.Person.Spouse.InsuranceThroughWorkTermLife =
						FamilyCare.SumSpouseInsuranceThroughWorkTermLife;
				}
				if (
					FamilyCare.SumSpouseInsuranceOutsideWorkWholeLife != null &&
					FamilyCare.SumSpouseInsuranceOutsideWorkWholeLife != undefined
				) {
					this.Person.Spouse.InsuranceOutsideWorkWholeLife =
						FamilyCare.SumSpouseInsuranceOutsideWorkWholeLife;
				}
				if (
					FamilyCare.SumSpouseInsuranceOutsideWorkTermLife != null &&
					FamilyCare.SumSpouseInsuranceOutsideWorkTermLife != undefined
				) {
					this.Person.Spouse.InsuranceOutsideWorkTermLife =
						FamilyCare.SumSpouseInsuranceOutsideWorkTermLife;
				}
				if (
					FamilyCare.SpouseAnnualIncome != null &&
					FamilyCare.SpouseAnnualIncome != undefined
				) {
					switch (wageType) {
						case "HOURLY":
							this.Person.Spouse.Wage = RoundUpperDecimal(
								Number(
									FamilyCare.SpouseAnnualIncome.replaceAll(",", "") /
										8 /
										5 /
										4.333 /
										12
								)
							).toFixed(2);
							break;
						case "WEEKLY":
							this.Person.Spouse.Wage = RoundUpperDecimal(
								Number(
									FamilyCare.SpouseAnnualIncome.replaceAll(",", "") / 4.333 / 12
								)
							).toFixed(2);
							break;
						case "MONTHLY":
							this.Person.Spouse.Wage = RoundUpperDecimal(
								Number(FamilyCare.SpouseAnnualIncome.replaceAll(",", "") / 12)
							).toFixed(2);
							break;
						case "YEARLY":
							this.Person.Spouse.Wage = RoundUpperDecimal(
								Number(FamilyCare.SpouseAnnualIncome.replaceAll(",", ""))
							).toFixed(2);
							break;
					}
					// this.Person.Spouse.Wage = (
					// 	Number(FamilyCare.SpouseAnnualIncome.replaceAll(",", "")) /
					// 	12 /
					// 	4.3333 /
					// 	5 /
					// 	8
					// ).toFixed(2);
				}
			}

			PrimaryPerson.update({
				where: this.Person.Id,
				data: this.Person,
			});
		}
	}
	CloseForm() {
		EventBusLayout.$emit("CloseForm", true);
		this.CloseMaterial();
	}
}
