var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"width":"max-content"},attrs:{"width":"60vw","scrollable":""},on:{"click:outside":function($event){return _vm.cancel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.isEscapePressed()}},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('v-card',{staticClass:"childrengrid"},[_c('v-card-title',{staticClass:"py-1 px-8 ln-normal borderBottom-1 border-secondaryLight"},[_c('v-row',{staticClass:"align-center my-0"},[_c('v-col',{staticClass:"lg--text fw-600"},[_vm._v("T-Sheet ")]),_c('v-col',{staticClass:"col-auto cursor-pointer"},[_c('v-icon',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("$cross")])],1)],1)],1),_c('v-card-text',{staticClass:"browserscroll pb-0 px-8"},[_c('v-row',{staticClass:"NotificationLetters xs--text ln-normal white min-h-100p",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"white blackdark--text py-3"},[_c('table',{staticClass:"benefitssummarypreview",staticStyle:{"width":"100%","font-size":"0.6vw"}},[(_vm.PresentationData.BenefitSummary.ShowHospitalBenefits)?_c('tr',[_c('td',[_c('table',{staticStyle:{"width":"100%","border-collapse":"collapse"}},[_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])]}}],null,false,808589139)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"600","text-transform":"uppercase","font-size":"0.8vw","height":"2vw"}},[_vm._v(" Hospital Benefits - Accident ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.ShowSpouse && _vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : ""))])]}}],null,false,2007546722)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.ShowSpouse && _vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize"}},[_vm._v(" Children ")]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])]}}],null,false,808589139)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.PrimaryEmergencyRoomBenefits == '--',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryEmergencyRoomBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryEmergencyRoomBenefits)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Emergency Room Benefit "),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" for treatment within 72 hours of accident ")])]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.SpouseEmergencyRoomBenefits == '--',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .SpouseEmergencyRoomBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .SpouseEmergencyRoomBenefits)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.ChildrenEmergencyRoomBenefits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .ChildrenEmergencyRoomBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .ChildrenEmergencyRoomBenefits))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.PrimaryEmergencyRoomBenefits == '--',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryEmergencyRoomBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryEmergencyRoomBenefits)+" ")])])]):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.PrimaryDailyHospitalBenfits == '--',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryDailyHospitalBenfits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryDailyHospitalBenfits)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Daily Hospital Benefit "),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" up to 365 days ")])]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.SpouseDailyHospitalBenfits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .SpouseDailyHospitalBenfits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .SpouseDailyHospitalBenfits)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.ChildrenDailyHospitalBenfits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .ChildrenDailyHospitalBenfits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .ChildrenDailyHospitalBenfits))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryDailyHospitalBenfits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryDailyHospitalBenfits)+" ")])])]):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.PrimaryIntensiveCareBenefits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .PrimaryIntensiveCareBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryIntensiveCareBenefits)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Intensive Care Benefit "),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" up to 14 days ")])]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.SpouseIntensiveCareBenefits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .SpouseIntensiveCareBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .SpouseIntensiveCareBenefits)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.ChildrenIntensiveCareBenefits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .ChildrenIntensiveCareBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .ChildrenIntensiveCareBenefits))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.PrimaryIntensiveCareBenefits == '--',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .PrimaryIntensiveCareBenefits == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryIntensiveCareBenefits)+" ")])])]):_vm._e()])])])]):_vm._e(),(_vm.PresentationData.BenefitSummary.ShowFreedomOfChoice)?_c('tr',[_c('td',[_c('table',{staticStyle:{"width":"100%","border-collapse":"collapse","margin-top":"1vw"}},[_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])]}}],null,false,808589139)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"600","text-transform":"uppercase","font-size":"0.8vw","height":"2vw","color":"#00558c"}},[_vm._v(" Freedom of Choice ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.ShowSpouse && _vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : ""))])]}}],null,false,2007546722)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.ShowSpouse && _vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize"}},[_vm._v(" Children ")]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])]}}],null,false,808589139)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focanydeathcausePrimary == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcausePrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcausePrimary)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Freedom of Choice "),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" any cause of death ")])]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focanydeathcauseSpouse == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcauseSpouse == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcauseSpouse)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focanydeathcauseChildren == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcauseChildren == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcauseChildren))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focanydeathcausePrimary == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcausePrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focanydeathcausePrimary)+" ")])])]):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focaccidentalPrimary == '',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalPrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalPrimary)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Accident ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focaccidentalSpouse == '',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalSpouse == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalSpouse)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focaccidentalChildren == '',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalChildren == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalChildren))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.focaccidentalPrimary == '',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalPrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .focaccidentalPrimary)+" ")])])]):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary.focautoPrimary ==
														'',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.focautoPrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.focautoPrimary)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Auto Accident ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary.focautoSpouse ==
														'',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.focautoSpouse == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.focautoSpouse)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary.focautoChildren ==
														'',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.focautoChildren == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.focautoChildren))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary.focautoPrimary ==
														'',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.focautoPrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.focautoPrimary)+" ")])])]):_vm._e()]),_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.foccommmoncarrierPrimary == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierPrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierPrimary)+" ")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" Common Carrier ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.foccommmoncarrierSpouse == '',
												}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierSpouse == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierSpouse)+" ")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.foccommmoncarrierChildren == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierChildren == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierChildren))])])]),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('u',{class:{
													'text-decoration-none':
														_vm.PresentationData.BenefitSummary
															.foccommmoncarrierPrimary == '',
												}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierPrimary == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .foccommmoncarrierPrimary)+" ")])])]):_vm._e()])])])]):_vm._e(),(_vm.PresentationData.BenefitSummary.ShowRCAmount)?_c('tr',[_c('td',[_c('table',{staticStyle:{"width":"100%","border-collapse":"collapse","margin-top":"1vw"}},[(_vm.ShowRCSection)?_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])]}}],null,false,808589139)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"600","text-transform":"uppercase","font-size":"0.8vw","vertical-align":"middle","color":"#00558c"}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.RCHeading)+" ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.ShowSpouse && _vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : ""))])]}}],null,false,2007546722)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.ShowSpouse && _vm.PresentationData.Presentation.PresentedTo.Spouse ? _vm.PresentationData.Presentation.PresentedTo.Spouse.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize"}}),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"bottom","text-transform":"capitalize","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"1px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])]}}],null,false,808589139)},[_c('span',[_vm._v(_vm._s(_vm.PresentationData.Presentation.PresentedTo ? _vm.PresentationData.Presentation.PresentedTo.GetNameHolder.toLowerCase() : ""))])])],1):_vm._e()]):_vm._e(),(_vm.PresentationData.BenefitSummary.ShowUnemploymentRow)?_c('tr',{staticClass:"hello2"},[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[(_vm.IsRCExist('Primary', false))?_c('span',[_c('span',[_c('u',{class:{
															'text-decoration-none':
																_vm.PresentationData.BenefitSummary
																	.PrimaryRCAmount == '--',
														}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryRCAmount == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryRCAmount)+" ")])])]),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.PrimaryRCTenure)+" ")])]):_c('span',[_c('u',[_vm._v("$0.00")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}}),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[(_vm.IsRCExist('Spouse', false))?_c('span',[_c('span',[_c('u',{class:{
															'text-decoration-none':
																_vm.PresentationData.BenefitSummary
																	.SpouseRCAmount == '--',
														}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .SpouseRCAmount == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.SpouseRCAmount)+" ")])])]),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.SpouseRCTenure)+" ")])]):_c('span',[_c('u',[_vm._v("$0.00")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}}),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('span',[_c('u',{class:{
														'text-decoration-none':
															_vm.PresentationData.BenefitSummary
																.PrimaryRCAmount == '--',
													}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.PrimaryRCAmount == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary.PrimaryRCAmount)+" ")])])]),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.PrimaryRCTenure)+" ")])]):_vm._e()]):_vm._e(),(_vm.PresentationData.BenefitSummary.ShowADBRow)?_c('tr',[(
												_vm.PresentationData.BenefitSummary.ShowSpouse ||
												_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[(_vm.IsRCExist('Primary', true))?_c('span',[_c('span',[_c('u',{class:{
															'text-decoration-none':
																_vm.PresentationData.BenefitSummary
																	.PrimaryRCAmountADB == '--',
														}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryRCAmountADB == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryRCAmountADB)+" ")])])]),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.GetRCTenureADB("Primary"))+" ")])]):_c('span',[_c('u',[_vm._v("$0.00")])])]):_vm._e(),_c('td',{staticStyle:{"width":"44.5%","text-align":"center","font-weight":"400","font-size":"0.75vw","vertical-align":"middle"}},[_vm._v(" From Accident Only ")]),(_vm.PresentationData.BenefitSummary.ShowSpouse)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[(_vm.IsRCExist('Spouse', true))?_c('span',[_c('span',[_c('u',{class:{
															'text-decoration-none':
																_vm.PresentationData.BenefitSummary
																	.SpouseRCTenureADB == '--',
														}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary .SpouseRCTenureADB == "--" ? "" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .SpouseRCTenureADB)+" ")])])]),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.GetRCTenureADB("Spouse"))+" ")])]):_c('span',[_c('u',[_vm._v("$0.00")])])]):_vm._e(),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.PresentationData.BenefitSummary.ShowChildren),expression:"PresentationData.BenefitSummary.ShowChildren"}],staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}}),(
												!_vm.PresentationData.BenefitSummary.ShowSpouse &&
												!_vm.PresentationData.BenefitSummary.ShowChildren
											)?_c('td',{staticStyle:{"width":"18.5%","text-align":"center","font-weight":"600","font-size":"0.75vw","vertical-align":"middle"}},[_c('span',[_c('u',{class:{
														'text-decoration-none':
															_vm.PresentationData.BenefitSummary
																.PrimaryRCAmountADB == '',
													}},[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryRCAmountADB == "" ? "--" : "$")),_c('span',[_vm._v(_vm._s(_vm.PresentationData.BenefitSummary .PrimaryRCAmountADB)+" ")])])]),_c('div',{staticStyle:{"font-size":"0.5vw","font-weight":"400","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.PresentationData.BenefitSummary.PrimaryRCTenureADB)+" ")])]):_vm._e()]):_vm._e()])])]):_vm._e()])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }