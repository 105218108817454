import { Model } from '@vuex-orm/core';
import { EntityHelper } from '@/helpers/EntityHelper';

export default class Expenses extends Model {
	static entity = 'Expenses';
	static primaryKey = 'Id';
	static fields() {
		return {
			Id: this.uid(() => EntityHelper.uuid()),
			HousePayment: this.string(null).nullable(),
			Utilities: this.string(null).nullable(),
			CableInternet: this.string(null).nullable(),
			CellPhone: this.string(null).nullable(),
			CarPayment: this.string(null).nullable(),
			CarInsurance: this.string(null).nullable(),
			Gas: this.string(null).nullable(),
			Travel: this.string(null).nullable(),
			MinDebtPay: this.string(null).nullable(),
			FoodEntertainment: this.string(null).nullable(),
			Insurance: this.string(null).nullable(),
			ChildCare: this.string(null).nullable(),
            Savings: this.string(null).nullable(),
            Retirement: this.string(null).nullable(),
			Misc1: this.string(null).nullable(),
			Misc2: this.string(null).nullable(),
		};
	}
            Id: any
			HousePayment !:  string
			Utilities !:  string
			CableInternet !:  string
			CellPhone !:  string
			CarPayment !:  string
			CarInsurance !:  string
			Gas !:  string
			Travel !:  string
			MinDebtPay !:  string
			FoodEntertainment !:  string
			Insurance !:  string
			ChildCare !:  string
            Savings !: string
            Retirement !: string
			Misc1 !:  string
			Misc2 !:  string
}
