














import { Component, Prop, Vue } from "vue-property-decorator";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

@Component({
  components: {
    Bar,
  },
})
export default class BarChart extends Vue {
  @Prop({ default: "" })
  bindedLicense!: string;

  @Prop({ default: "bar-chart" })
  private chartId!: any;

  @Prop({ default: 400 })
  private width!: any;

  @Prop({ default: 400 })
  private height!: any;

  @Prop({ default: "" })
  private cssClasses!: any;

  @Prop({ default: {} })
  private styles!: any;

  @Prop({ default: {} })
  private plugins!: any;

  data() {
    return {
      chartData: {
        labels: ["January", "February", "March"],
        datasets: [
          {
            data: [40, 20, 12],
            backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  }
}
