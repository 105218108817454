





















































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Children from "@/entities/Children";
import moment from "moment";
import { EntityHelper } from "@/helpers/EntityHelper";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class AddChildrenComponent extends Vue {
	private person: any;
	private gender = listofGenderTypes;

	@Prop({ default: "Input Property" })
	lstChildren!: Array<Children>;

	@Prop({ default: "Input Property" })
	truncateProperties!: boolean;

	add() {
		this.lstChildren.push(new Children());
		// setTimeout(() => {
		//   this.lstChildren.push(new Children());
		// }, 500);
	}

	DOBChange(item: any) {
		if (item.DateOfBirth != undefined && item.DateOfBirth != "") {
			item.DOBPicker = moment(item.DateOfBirth).format("YYYY-MM-DD");
			item.AgePerDOB = EntityHelper.calculate_age(item.DateOfBirth);
		}
	}

	saveDOB(date: any, item: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MM/DD/YYYY") : "";
		item.DateOfBirth = tempdate;
		item.DOBPopup = false;
	}

	save() {
		this.$emit("saveChildren");
	}
	remove(id: number) {
		this.lstChildren.splice(id, 1);
		// setTimeout(() => {
		//   this.lstChildren.splice(id, 1);
		// }, 500);
	}

	created() {
		this.lstChildren.forEach((element) => {
			this.DOBChange(element);
		});
	}
}
