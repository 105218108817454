<template>
  <div>
    <label> Error 404: Page Not Found </label>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "NotFoundPage",
});
</script>
