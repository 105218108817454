
export enum BenefitsSummaryPopupType {
    PerProcedureCancer,
    LumpSumCancer,
    CashValue,
    PaidUpBenefits,
    TerminalIllnessRider,
    StrikeWaiver,
    LayoffWaiver,
    BenefitsGuarenteed,
    DeathBenefit
}