import moment from "moment";
import { ConvertIntoAmountFormatWithDecimal } from "../helpers/CalculationHelper";
export module EntityHelper {

    export function uuid(): string | number {
        const uuidv4 = require("uuid/v4")
        return uuidv4();
    }
    export function calculate_age(dob: string) {
        if (moment(dob, "MM/DD/YYYY", true).isValid() == false)
            return 0;
        var d = new Date(dob);
        var diff_ms = Date.now() - d.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    export function capitalizeTheFirstLetterOfEachWord(words: string) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }
    export function CalculateMonthlyPremium(plan: any) {
        var monthly = 0;

        var PrimaryProducts = [];
        var SpouseProducts = [];

        var products = plan.Products.filter((x: any) => x.Selected == true);
        if (products && products.length > 0) {
            PrimaryProducts.push(...products);
        }

        var primarySelected = plan.PrimaryProducts.filter((x: any) => x.Selected == true);
        if (primarySelected && primarySelected.length > 0) {
            PrimaryProducts.push(...primarySelected);
        }

        var primaryALPSelected = plan.AdditionalProduct.PrimaryProducts.ALPProduct.filter((x: any) => x.Selected == true);
        if (primaryALPSelected && primaryALPSelected.length > 0) {
            PrimaryProducts.push(...primaryALPSelected);
        }

        var primaryAHPSelected = plan.AdditionalProduct.PrimaryProducts.AHPProduct.filter((x: any) => x.Selected == true);
        if (primaryAHPSelected && primaryAHPSelected.length > 0) {
            PrimaryProducts.push(...primaryAHPSelected);
        }
        var spouseSelected = plan.SpouseProducts.filter((x: any) => x.Selected == true);
        if (spouseSelected && spouseSelected.length > 0) {
            SpouseProducts.push(...spouseSelected);
        }

        var spouseALPSelected = plan.AdditionalProduct.SpouseProducts.ALPProduct.filter((x: any) => x.Selected == true);
        if (spouseALPSelected && spouseALPSelected.length > 0) {
            SpouseProducts.push(...spouseALPSelected);
        }

        var spouseAHPSelected = plan.AdditionalProduct.SpouseProducts.AHPProduct.filter((x: any) => x.Selected == true);
        if (spouseAHPSelected && spouseAHPSelected.length > 0) {
            SpouseProducts.push(...spouseAHPSelected);
        }

        if (PrimaryProducts && PrimaryProducts.length > 0) {
            PrimaryProducts.forEach((prod: any) => {
                monthly += AddMBD(prod.MBD);
            });
        }

        if (SpouseProducts && SpouseProducts.length > 0) {
            SpouseProducts.forEach((prod: any) => {
                monthly += AddMBD(prod.MBD);
            });
        }


        return ConvertIntoAmountFormatWithDecimal(monthly.toFixed(2).toString());
    }

    export function AddMBD(MBD: any) {
        if (MBD != undefined && typeof MBD == "string") {
            MBD = MBD.replace(",", "");
        }
        if (isNaN(MBD) || MBD == "") MBD = 0;

        var MBDNumber = Number.parseFloat(MBD);
        return MBDNumber;
    }
}