

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { warn } from "vue-class-component/lib/util";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import RateBookEntity from "../entities/RateBookEntity";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
let lstBSRates = require("../assets/pre-fill-data/bs-rates.json");

import User from "../entities/User";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import PerProcedureCancerComponent from "../components/BenefitSummary/PerProcedureCancer.vue";
import LumpSumCancerComponent from "../components/BenefitSummary/LumpSumCancer.vue";
import CashValueComponent from "../components/BenefitSummary/CashValue.vue";
import PaidBenefitComponent from "../components/BenefitSummary/PaidBenefit.vue";
import TerminalIllnessComponent from "../components/BenefitSummary/TerminalIllness.vue";
import DeathBenefitComponent from "../components/BenefitSummary/DeathBenefit.vue";
import StrikeWaiverComponent from "../components/BenefitSummary/StrikeWaiver.vue";
import LayOffWaiverComponent from "../components/BenefitSummary/LayOffWaiver.vue";
import BenefitGuranteedComponent from "../components/BenefitSummary/BenefitGuranteed.vue";
import {
	CalculateMBD,
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
	CalculateCoverageFromMBD,
	CalculateCoverageFromMBDByCoverageType,
} from "../helpers/CalculationHelper";
import { EntityHelper } from "../helpers/EntityHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { BenefitsSummaryPopupType } from "@/enumerations/BenefitsSummaryPopupType";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Spouse from "@/entities/Spouse";

@Component({
	components: {
		videoPlayer,
		PerProcedureCancerComponent,
		LumpSumCancerComponent,
		CashValueComponent,
		PaidBenefitComponent,
		TerminalIllnessComponent,
		DeathBenefitComponent,
		StrikeWaiverComponent,
		LayOffWaiverComponent,
		BenefitGuranteedComponent,
	},
})
export default class RateBookBox extends Vue {
	private listofGenderTypes = require("../assets/pre-fill-data/gender-types.json");
	private listofAllProduct: any = null;
	private allStates: any = null;
	private listofProducts: any = null;
	private listOfOptions: any = [];
	private listOfCoverageOptions: any = [];
	private chequeimg: boolean = false;
	private RateList: any = null;
	private InfoPopupHeading = "";
	private user: any;
	private insurancePopupOpt: boolean = false;
	private BenefitsSummaryPopupType: any = {};
	private Person: PrimaryPerson = new PrimaryPerson();
	private formatMBD = true;
	private SelectedBenefitsSummaryPopupType: any =
		BenefitsSummaryPopupType.PerProcedureCancer;
	private ShowHospitalBenefits: boolean = false;
	// private CoverageString = "";
	// private MDBString = "";

	@Prop({ default: undefined })
	Plan!: RateBookEntity;

	@Prop({ default: "Input Property" })
	truncateProperties!: boolean;

	data() {
		return {
			selected: undefined,
			ops: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			CoverageString: "",
			BenefitSummary: {},
			MDBString: "",
			showProductInfo: false,
			showPopup: false,
			ShowVideo: false,
			playerOptions: {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: "static/ailvideo.mp4",
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			},
		};
	}

	@Watch("showPopup")
	OnPopupVisibiltyChange(newValue: boolean, oldValue: boolean) {
		if (!newValue) {
			this.$data.ShowVideo = false;
			this.$data.showProductInfo = false;
		}
	}

	@Watch("Plan.CompleteProduct")
	OnProductChange() {
		if (this.Plan.CompleteProduct != undefined) {
			this.Plan.Product = this.Plan.CompleteProduct.Name;
		}
		this.ChangeOptionList();
		this.ChangeCoverageOptionList();
		this.CheckDefaultCoverage();
	}

	@Watch("Plan.CompleteOption")
	OnOptionChange() {
		this.Plan.Option = this.Plan.CompleteOption.Name;
	}

	@Watch("Plan.MDB")
	OnMDBChange() {
		var mbd: any = this.Plan.MDB;
		if (this.formatMBD) {
			mbd = Number(mbd.toString().replaceAll(",", ""));
			mbd = mbd.toFixed(2);
		}

		this.$data.MDBString = ConvertIntoAmountFormatWithDecimal(
			mbd,
			true,
			this.formatMBD
		);
		this.formatMBD = false;
	}

	@Watch("Plan.Coverage")
	OnCoverageChange() {
		if (
			this.Plan.Coverage != undefined &&
			typeof this.Plan.Coverage == "string" &&
			this.Plan.Coverage.includes(".")
		)
			this.$data.CoverageString = ConvertIntoAmountFormatWithDecimal(
				this.Plan.Coverage
			);
		else
			this.$data.CoverageString = ConvertIntoAmountFormat(this.Plan.Coverage);
	}

	ChangeOptionList() {
		if (this.Plan.CompleteProduct != undefined) {
			if (this.Plan.TobaccoUse == true) {
				this.listOfOptions = this.Plan.CompleteProduct.TU.Option.filter(
					(x: any) =>
						this.Plan.Age >= x.MinAge &&
						this.Plan.Age <= x.MaxAge &&
						(this.Plan.State == undefined ||
							x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.Plan.State)) &&
						(this.Plan.State == undefined ||
							x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.Plan.State)) &&
						(x.DependOnChildren == undefined ||
							x.DependOnChildren == false ||
							x.DependOnChildren == this.Plan.hasChildren) &&
						(x.ShowOnSpouse == undefined || x.ShowOnSpouse == false)
				);
			} else {
				this.listOfOptions = this.Plan.CompleteProduct.NTU.Option.filter(
					(x: any) =>
						this.Plan.Age >= x.MinAge &&
						this.Plan.Age <= x.MaxAge &&
						(this.Plan.State == undefined ||
							x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.Plan.State)) &&
						(this.Plan.State == undefined ||
							x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.Plan.State)) &&
						(x.DependOnChildren == undefined ||
							x.DependOnChildren == false ||
							x.DependOnChildren == this.Plan.hasChildren) &&
						(x.ShowOnSpouse == undefined || x.ShowOnSpouse == false)
				);
			}
			if (
				this.listOfOptions != undefined &&
				this.listOfOptions.length > 0 &&
				this.listOfOptions[0] != undefined
			) {
				if (this.Plan.CompleteProduct.AgeDependOnState == true) {
					var removeOptions: any = [];
					this.listOfOptions.forEach((optionelement: any) => {
						if (optionelement.AgeDependOnState) {
							var age = Number.parseInt(this.Plan.Age);
							var selectedState = optionelement.StateAge.filter(
								(x: any) =>
									x.State == this.Plan.State &&
									(age < x.MinAge || age > x.MaxAge)
							);
							if (selectedState != undefined && selectedState.length > 0) {
								removeOptions.push(optionelement);
							}
						}
					});

					if (removeOptions.length > 0) {
						removeOptions.forEach((element: any) => {
							const index = this.listOfOptions.indexOf(element);
							if (index > -1) {
								this.listOfOptions.splice(index, 1);
							}
						});
					}
				}

				if (
					this.Plan.CompleteOption == undefined ||
					this.Plan.CompleteProduct.ChangeOptionOnSelection ||
					this.listOfOptions.find(
						(x: any) =>
							x.Name == this.Plan.CompleteOption.Name &&
							x.Id == this.Plan.CompleteOption.Id
					) == undefined
				) {
					var selOpt: any;

					if (
						this.Plan.CompleteProduct.ShowLastItem == true &&
						this.Plan.CompleteOption != undefined
					) {
						selOpt = this.listOfOptions[this.listOfOptions.length - 1];
					} else {
						if (
							this.Plan.CompleteProduct.Id == 6 ||
							this.Plan.CompleteProduct.Id == 14
						) {
							selOpt = this.listOfOptions[1];
						} else {
							selOpt = this.listOfOptions[0];
						}
					}

					this.Plan.CompleteOption = selOpt;
					if (this.Plan.CompleteProduct.CoverageDependOnOption == true) {
						this.Plan.Coverage = this.Plan.CompleteOption.Coverage;
					}
				}
				this.CalculateMBDRate();
			}
		} else {
			this.Plan.CompleteOption = undefined;
		}
	}

	ChangeCoverageOptionList() {
		if (
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteProduct.CoverageDropdownOptions
		) {
			this.listOfCoverageOptions =
				this.Plan.CompleteProduct.CoverageDropdownOptions.filter(
					(x: any) =>
						this.Plan.Age >= x.MinAge &&
						this.Plan.Age <= x.MaxAge &&
						(this.Plan.State == undefined ||
							x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.Plan.State)) &&
						(this.Plan.State == undefined ||
							x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.Plan.State)) &&
						(x.ShowOnSpouse == undefined || x.ShowOnSpouse == false)
				);
			if (
				this.listOfCoverageOptions != undefined &&
				this.listOfCoverageOptions.length > 0 &&
				this.listOfCoverageOptions[0] != undefined
			) {
				if (this.Plan.CompleteProduct.AgeDependOnState == true) {
					var removeOptions: any = [];
					this.listOfCoverageOptions.forEach((optionelement: any) => {
						if (optionelement.AgeDependOnState) {
							var age = Number.parseInt(this.Plan.Age);
							var selectedState = optionelement.StateAge.filter(
								(x: any) =>
									x.State == this.Plan.State &&
									(age < x.MinAge || age > x.MaxAge)
							);
							if (selectedState != undefined && selectedState.length > 0) {
								removeOptions.push(optionelement);
							}
						}
					});

					if (removeOptions.length > 0) {
						removeOptions.forEach((element: any) => {
							const index = this.listOfOptions.indexOf(element);
							if (index > -1) {
								this.listOfOptions.splice(index, 1);
							}
						});
					}
				}

				if (
					this.Plan.CompleteCoverageOption == undefined ||
					this.Plan.CompleteProduct.ChangeOptionOnSelection ||
					this.listOfCoverageOptions.find(
						(x: any) => x.Name == this.Plan.CompleteCoverageOption.Name
					) == undefined
				) {
					var selOpt: any = this.listOfCoverageOptions[0];
					this.Plan.CompleteCoverageOption = selOpt;
				}

				this.CalculateMBDRate();
			}
		} else {
			this.Plan.CompleteCoverageOption = undefined;
		}
	}

	chequeCount: number = 0;
	ChequeAmountInWords: string = "";
	ChequeAmount: string = "";

	$refs!: {
		videoPlayer: any;
		comboProduct: any;
		tfCoverage: any;
		chequeLeftBtn: any;
		chequeRightBtn: any;
		chequeWindow: any;
		lumsumpopup: any;
		perprocpopup: any;
	};

	created() {
		var self = this;
		this.$store.state.loader = true;

		var productsListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.ProductsListEndpoint,
			[],
			IndexDBConstants.ProductsListKey
		);

		var ratesListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.RatesListEndpoint,
			[],
			IndexDBConstants.RatesListKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);

		Promise.all([productsListPromise, ratesListPromise, stateListPromise]).then(
			(values) => {
				self.listofProducts = JSON.parse(
					JSON.stringify(require("../assets/pre-fill-data/product-list.json"))
				)
					.filter(
						(x: any) =>
							(x.ShowInRateBookOnly == undefined ||
								x.ShowInRateBookOnly != false) &&
							x.ShowAsASeperateProduct == true
					)
					.sort(this.compareProduct);

				self.listofAllProduct = JSON.parse(
					JSON.stringify(require("../assets/pre-fill-data/product-list.json"))
				)
					.filter(
						(x: any) =>
							x.ShowInRateBookOnly == undefined || x.ShowInRateBookOnly != false
					)
					.sort(this.compareProduct);

				self.RateList = values[1];
				self.allStates = values[2];
				if (this.Plan == undefined) this.Plan = new RateBookEntity();
				var _keydownCallback = (event: any) => {
					if (event.code == "Escape") {
						this.$data.overlay = false;
					}

					if (this.$data.ShowVideo && event.code == "Space") {
						if (
							this.$refs.videoPlayer != undefined &&
							this.$refs.videoPlayer.player != undefined &&
							!this.$refs.videoPlayer.player.paused()
						)
							this.$refs.videoPlayer.player.pause();
						else if (
							this.$refs.videoPlayer != undefined &&
							this.$refs.videoPlayer.player != undefined
						)
							this.$refs.videoPlayer.player.play_();
						//this.$refs.videoPlayer.pause();
					}
					if (this.chequeimg) {
						if (
							event.code == "ArrowLeft" &&
							this.$refs.chequeLeftBtn != undefined
						) {
							this.$refs.chequeLeftBtn.$el.click();
						}

						if (
							event.code == "ArrowRight" &&
							this.$refs.chequeRightBtn != undefined
						) {
							this.$refs.chequeRightBtn.$el.click();
						}
					}
				};

				document.addEventListener("keyup", _keydownCallback);
				this.$store.state.loader = false;
			}
		);
	}

	compareProduct(a: any, b: any) {
		if (a.SortOrder < b.SortOrder) return -1;
		if (a.SortOrder > b.SortOrder) return 1;
		return 0;
	}

	isNumberOnly(evt: any, variableName: string) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		//console.log(charCode);
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			evt.preventDefault();
		} else if (charCode == 46 && this.$data[variableName].includes(".")) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	ShowOutlineOfCoverage() {
		if (
			this.Plan.CompleteOption != undefined &&
			this.Plan.CompleteOption.Heading != undefined
		) {
			if (
				this.Plan.CompleteOption.Heading != undefined &&
				this.Plan.CompleteOption.Heading != ""
			)
				this.InfoPopupHeading = this.Plan.CompleteOption.Heading;
			else this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		} else {
			this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		}

		if (!this.Plan.CompleteProduct.OutlineOfCoverageConfig) return;
		var outlineofCoverageConfig =
			this.Plan.CompleteProduct.OutlineOfCoverageConfig.filter(
				(x: any) =>
					(x.ExcludeStates == undefined ||
						x.ExcludeStates.length == 0 ||
						!x.ExcludeStates.includes(this.Plan.State)) &&
					(x.IncludedStates == undefined ||
						x.IncludedStates.length == 0 ||
						x.IncludedStates.includes(this.Plan.State))
			);

		if (!outlineofCoverageConfig) return;

		if (this.Plan.CompleteProduct.CheckOptionsInOutlineOfCoverage) {
			outlineofCoverageConfig = outlineofCoverageConfig.filter((x: any) =>
				x.OptionsIds.includes(this.Plan.CompleteOption.Id)
			);
		}

		if (this.Plan.CompleteProduct.CheckCoverageOptionsInOutlineOfCoverage) {
			outlineofCoverageConfig = outlineofCoverageConfig.filter((x: any) =>
				x.OptionsIds.includes(this.Plan.CompleteCoverageOption.Id)
			);
		}

		window.open(outlineofCoverageConfig[0].url, "_blank");
	}

	ShowBenefitSummary() {
		this.ShowHospitalBenefits = false;
		if (
			this.Plan.CompleteOption != undefined &&
			this.Plan.CompleteOption.Heading != undefined
		) {
			if (
				this.Plan.CompleteOption.Heading != undefined &&
				this.Plan.CompleteOption.Heading != ""
			)
				this.InfoPopupHeading = this.Plan.CompleteOption.Heading;
			else this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		} else {
			this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		}
		this.$data.selected = undefined;
		var currentProduct = this.Plan.CompleteProduct;
		var presState = this.Plan.State;
		var benefitSummary: any = {};
		benefitSummary.focanydeathcausePrimary = 0;
		benefitSummary.focanydeathcauseSpouse = 0;
		benefitSummary.focanydeathcauseChildren = 0;
		benefitSummary.focaccidentalPrimary = 0;
		benefitSummary.focaccidentalSpouse = 0;
		benefitSummary.focaccidentalChildren = 0;
		benefitSummary.focautoPrimary = 0;
		benefitSummary.focautoSpouse = 0;
		benefitSummary.focautoChildren = 0;
		benefitSummary.foccommmoncarrierPrimary = 0;
		benefitSummary.foccommmoncarrierSpouse = 0;
		benefitSummary.foccommmoncarrierChildren = 0;
		benefitSummary.PrimaryEmergencyRoomBenefits = "--";
		benefitSummary.PrimaryDailyHospitalBenfits = "--";
		benefitSummary.PrimaryIntensiveCareBenefits = "--";
		benefitSummary.SpouseEmergencyRoomBenefits = "--";
		benefitSummary.SpouseDailyHospitalBenfits = "--";
		benefitSummary.SpouseIntensiveCareBenefits = "--";
		benefitSummary.ChildrenEmergencyRoomBenefits = "--";
		benefitSummary.ChildrenDailyHospitalBenfits = "--";
		benefitSummary.ChildrenIntensiveCareBenefits = "--";
		var lstOfProductIdsToShowTSheet = [1, 2, 3, 4, 12, 13, 14, 15, 17];
		var lstWHLProducts = [1, 2, 3, 4];
		if (this.Plan.hasChildren) {
			benefitSummary.ShowChildren = true;
		}

		if (lstOfProductIdsToShowTSheet.includes(currentProduct.Id)) {
			benefitSummary.ShowTSheet = true;
			if (lstWHLProducts.includes(currentProduct.Id)) {
				benefitSummary.focanydeathcausePrimary = Number(
					this.Plan.Coverage.toString().replaceAll(",", "")
				);
			} else if (currentProduct.Id == 12) {
				benefitSummary.focanydeathcauseSpouse = Number(
					this.Plan.Coverage.toString().replaceAll(",", "")
				);
			} else if (currentProduct.Id == 13) {
				benefitSummary.focanydeathcauseChildren = Number(
					this.Plan.Coverage.toString().replaceAll(",", "")
				);
			} else if (currentProduct.Id == 14) {
				benefitSummary.focaccidentalPrimary += Number(
					this.Plan.Coverage.toString().replaceAll(",", "")
				);
				benefitSummary.focautoPrimary += Number(
					this.Plan.Coverage.toString().replaceAll(",", "")
				);
				benefitSummary.foccommmoncarrierPrimary += Number(
					this.Plan.Coverage.toString().replaceAll(",", "")
				);
			} else if (currentProduct.Id == 15) {
				benefitSummary.focaccidentalPrimary += Number(
					this.Plan.CompleteCoverageOption.Value.toString().replaceAll(",", "")
				);
				benefitSummary.focautoPrimary +=
					Number(
						this.Plan.CompleteCoverageOption.Value.toString().replaceAll(
							",",
							""
						)
					) * 2.5;
				benefitSummary.foccommmoncarrierPrimary +=
					Number(
						this.Plan.CompleteCoverageOption.Value.toString().replaceAll(
							",",
							""
						)
					) * 5;
			} else if (currentProduct.Id == 17) {
				this.ShowHospitalBenefits = true;
				var ahpRates = lstBSRates.find(
					(x: any) =>
						x.OptionIds &&
						x.OptionIds.includes(this.Plan.CompleteOption.Id) &&
						(!x.IncludedStates ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(presState)) &&
						(!x.ExcludedStates ||
							x.ExcludedStates.length == 0 ||
							!x.ExcludedStates.includes(presState))
				);

				benefitSummary.PrimaryEmergencyRoomBenefits = ahpRates.ERB;
				benefitSummary.PrimaryDailyHospitalBenfits = ahpRates.DHB;
				benefitSummary.PrimaryIntensiveCareBenefits = ahpRates.ICB;
				benefitSummary.focaccidentalPrimary = ahpRates.Accident;
				benefitSummary.focautoPrimary = ahpRates.Auto;
				benefitSummary.foccommmoncarrierPrimary = ahpRates.Common;

				if (this.Plan.CompleteOption.Id > 4) {
					benefitSummary.SpouseEmergencyRoomBenefits = ahpRates.ERB;
					benefitSummary.SpouseDailyHospitalBenfits = ahpRates.DHB;
					benefitSummary.SpouseIntensiveCareBenefits = ahpRates.ICB;
					benefitSummary.focaccidentalSpouse = ahpRates.Accident;
					benefitSummary.focautoSpouse = ahpRates.Auto;
					benefitSummary.foccommmoncarrierSpouse = ahpRates.Common;

					if (benefitSummary.ShowChildren) {
						benefitSummary.ChildrenEmergencyRoomBenefits = ahpRates.ERB;
						benefitSummary.ChildrenDailyHospitalBenfits = ahpRates.DHB;
						benefitSummary.ChildrenIntensiveCareBenefits = ahpRates.ICB;
						benefitSummary.focaccidentalChildren = ahpRates.ChildAccident;
						benefitSummary.focautoChildren = ahpRates.ChildAuto;
						benefitSummary.foccommmoncarrierChildren = ahpRates.ChildCommon;
					} else {
						benefitSummary.ChildrenEmergencyRoomBenefits = "--";
						benefitSummary.ChildrenDailyHospitalBenfits = "--";
						benefitSummary.ChildrenIntensiveCareBenefits = "--";
					}
				} else {
					benefitSummary.SpouseEmergencyRoomBenefits = "--";
					benefitSummary.SpouseDailyHospitalBenfits = "--";
					benefitSummary.SpouseIntensiveCareBenefits = "--";
					benefitSummary.ChildrenEmergencyRoomBenefits = "--";
					benefitSummary.ChildrenDailyHospitalBenfits = "--";
					benefitSummary.ChildrenIntensiveCareBenefits = "--";
				}
			}

			if (
				benefitSummary.PrimaryIntensiveCareBenefits != 0 &&
				benefitSummary.PrimaryIntensiveCareBenefits != "--"
			) {
				benefitSummary.PrimaryIntensiveCareBenefits =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.PrimaryIntensiveCareBenefits.toString()
					);
			}

			if (
				benefitSummary.SpouseIntensiveCareBenefits != 0 &&
				benefitSummary.SpouseIntensiveCareBenefits != "--"
			) {
				benefitSummary.SpouseIntensiveCareBenefits =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.SpouseIntensiveCareBenefits.toString()
					);
			}

			if (
				benefitSummary.ChildrenIntensiveCareBenefits != 0 &&
				benefitSummary.ChildrenIntensiveCareBenefits != "--"
			) {
				benefitSummary.ChildrenIntensiveCareBenefits =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.ChildrenIntensiveCareBenefits.toString()
					);
			}
			if (benefitSummary.focanydeathcausePrimary != 0) {
				benefitSummary.focanydeathcausePrimary =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.focanydeathcausePrimary.toFixed(2)
					);
			} else {
				benefitSummary.focanydeathcausePrimary = "";
			}
			if (benefitSummary.focanydeathcauseSpouse != 0) {
				benefitSummary.focanydeathcauseSpouse =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.focanydeathcauseSpouse.toFixed(2)
					);
			} else {
				benefitSummary.focanydeathcauseSpouse = "";
			}

			if (benefitSummary.focanydeathcauseChildren != 0) {
				benefitSummary.focanydeathcauseChildren =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.focanydeathcauseChildren.toFixed(2)
					);
			} else {
				benefitSummary.focanydeathcauseChildren = "";
			}

			if (benefitSummary.focaccidentalPrimary != 0) {
				benefitSummary.focaccidentalPrimary =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.focaccidentalPrimary.toFixed(2)
					);
			} else {
				benefitSummary.focaccidentalPrimary = "";
			}
			if (benefitSummary.focaccidentalSpouse != 0) {
				benefitSummary.focaccidentalSpouse = ConvertIntoAmountFormatWithDecimal(
					benefitSummary.focaccidentalSpouse.toFixed(2)
				);
			} else {
				benefitSummary.focaccidentalSpouse = "";
			}

			if (benefitSummary.focaccidentalChildren != 0) {
				benefitSummary.focaccidentalChildren =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.focaccidentalChildren.toFixed(2)
					);
			} else {
				benefitSummary.focaccidentalChildren = "";
			}

			if (benefitSummary.focautoPrimary != 0) {
				benefitSummary.focautoPrimary = ConvertIntoAmountFormatWithDecimal(
					benefitSummary.focautoPrimary.toFixed(2)
				);
			} else {
				benefitSummary.focautoPrimary = "";
			}

			if (benefitSummary.focautoSpouse != 0) {
				benefitSummary.focautoSpouse = ConvertIntoAmountFormatWithDecimal(
					benefitSummary.focautoSpouse.toFixed(2)
				);
			} else {
				benefitSummary.focautoSpouse = "";
			}
			if (benefitSummary.focautoChildren != 0) {
				benefitSummary.focautoChildren = ConvertIntoAmountFormatWithDecimal(
					benefitSummary.focautoChildren.toFixed(2)
				);
			} else {
				benefitSummary.focautoChildren = "";
			}
			if (benefitSummary.foccommmoncarrierPrimary != 0) {
				benefitSummary.foccommmoncarrierPrimary =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.foccommmoncarrierPrimary.toFixed(2)
					);
			} else {
				benefitSummary.foccommmoncarrierPrimary = "";
			}
			if (benefitSummary.foccommmoncarrierSpouse != 0) {
				benefitSummary.foccommmoncarrierSpouse =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.foccommmoncarrierSpouse.toFixed(2)
					);
			} else {
				benefitSummary.foccommmoncarrierSpouse = "";
			}
			if (benefitSummary.foccommmoncarrierChildren != 0) {
				benefitSummary.foccommmoncarrierChildren =
					ConvertIntoAmountFormatWithDecimal(
						benefitSummary.foccommmoncarrierChildren.toFixed(2)
					);
			} else {
				benefitSummary.foccommmoncarrierChildren = "";
			}

			this.insurancePopupOpt = true;
		} else if (this.Plan.CompleteProduct.Type == "TERM") {
			if (this.Plan.CompleteOption != undefined) {
				this.chequeCount = this.Plan.CompleteOption.NoOfMonths;

				var coverageTemp = this.Plan.Coverage;

				if (typeof this.Plan.Coverage == "string")
					coverageTemp = this.Plan.Coverage.replace(",", "");
				var chequeAmountNumber =
					Number.parseInt(coverageTemp) / this.Plan.CompleteOption.NoOfMonths;
				if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
				this.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
					chequeAmountNumber.toFixed(2)
				);
				var converter = require("number-to-words");
				var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
				var decimalValueStr = (decimalValue * 100).toFixed();

				if (Number.parseInt(decimalValueStr) > 0) {
					this.ChequeAmountInWords =
						EntityHelper.capitalizeTheFirstLetterOfEachWord(
							converter.toWords(chequeAmountNumber)
						) +
						" and " +
						decimalValueStr +
						"/100";
				} else {
					this.ChequeAmountInWords =
						EntityHelper.capitalizeTheFirstLetterOfEachWord(
							converter.toWords(chequeAmountNumber)
						) + " only";
				}
			}
			this.chequeimg = true;
			if (this.$refs.chequeWindow != undefined) {
				this.$refs.chequeWindow.updateMultiple();
			}
		} else if (currentProduct.Id == 18) {
			this.SelectedBenefitsSummaryPopupType =
				BenefitsSummaryPopupType.LumpSumCancer;
			benefitSummary.PrimaryLumpSumCoverage =
				this.Plan.CompleteCoverageOption.Name;
			benefitSummary.PrimaryLumpSumText = "Primary";
			if (this.Plan.CompleteOption.Id == 2) {
				benefitSummary.PrimaryLumpSumText =
					benefitSummary.PrimaryLumpSumText + " & Children";
			}
			benefitSummary.ShowPrimaryLumpSum = true;
			benefitSummary.HideLumpSumTabs = true;
			setTimeout(() => {
				if (this.$refs.lumsumpopup) {
					this.$refs.lumsumpopup.$data.key++;
					this.$refs.lumsumpopup.$props.BenefitSummary =
						this.$data.BenefitSummary;
				}
			}, 10);

			this.insurancePopupOpt = true;
		} else if (currentProduct.Id == 19) {
			this.SelectedBenefitsSummaryPopupType =
				BenefitsSummaryPopupType.PerProcedureCancer;
			benefitSummary.ShowPerProcPrimary = true;
			if (benefitSummary.ShowPerProcPrimary) {
				var bsCNMRates = lstBSRates.find(
					(x: any) =>
						x.ProductListId == 19 &&
						(!x.IncludedStates ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(presState)) &&
						(!x.ExcludedStates ||
							x.ExcludedStates.length == 0 ||
							!x.ExcludedStates.includes(presState))
				);
				var benefit = bsCNMRates.benefits;
				var presentCNM: any = {
					display: {
						hospital: {
							title: "HOSPITAL",
							note: "##hospital-a## / Day for Twelve Days ##hospital-b## / Day thereafter",
						},
						drugs: {
							title: "DRUGS AND MEDICINES",
							note: "10% of hospital confinement benefit",
						},
						surgical: {
							title: "SURGICAL",
							note: "##surgical-a## to ##surgical-b## per operation as outlined in Schedule of Operations",
						},
						anesthesia: {
							title: "ANESTHESIA",
							note: "Not to exceed ##anesthesia-a## per operation",
						},
						radiation: {
							title: "RADIATION THERAPY & CHEMOTHERAPY",
							note: "Usual and Customary Charge",
						},
						nursing: {
							title: "NURSING",
							note: "##nursing-a## / Day when required",
						},
						attending: {
							title: "ATTENDING PHYSICIAN",
							note: "##attending-a## / Day",
						},
						plasma: { title: "BLOOD PLASMA", note: "Usual & Customary Charge" },
						ambulance: {
							title: "AMBULANCE",
							note: "Not to exceed ##ambulance-a## each confinement",
						},
						transportation: {
							title: "TRANSPORTATION",
							note: "Usual & Customary Charge",
						},
						// extended: { title: "EXTENDED BENEFITS", note: "##extended-a## per month beginning on the 91st day of continuous hospital confinement" }
					},
				};

				var presentCNMPrimary = JSON.parse(JSON.stringify(presentCNM));
				presentCNMPrimary.totalMax = 0;
				for (const [key, value] of Object.entries(presentCNMPrimary.display)) {
					if (!presentCNMPrimary.display[key]) {
						continue;
					}
					var ben = presentCNMPrimary.display[key];
					if (benefit[key]) {
						ben.note = benefit[key].a
							? ben.note.replace(
									"##" + key + "-" + "a##",
									benefit[key].a[
										this.Plan.CompleteCoverageOption.Value.toString()
									]
							  )
							: ben.note;
						ben.note = benefit[key].b
							? ben.note.replace(
									"##" + key + "-" + "b##",
									benefit[key].b[
										this.Plan.CompleteCoverageOption.Value.toString()
									]
							  )
							: ben.note;
						ben.max =
							benefit[key].max[
								this.Plan.CompleteCoverageOption.Value.toString()
							];
						var max = parseFloat(
							ben.max.replaceAll(",", "").replaceAll("$", "")
						);
						if (isNaN(max)) max = 0;
						presentCNMPrimary.totalMax += max;
					} else {
						delete presentCNMPrimary.display[key];
					}
				}
				presentCNMPrimary.totalMax = ConvertIntoAmountFormatWithDecimal(
					presentCNMPrimary.totalMax.toFixed(2).toString(),
					true,
					true
				);

				benefitSummary.PerProcPrimary = presentCNMPrimary;
				benefitSummary.PerProcPrimary.Units =
					this.Plan.CompleteCoverageOption.Value.toString();

				switch (this.Plan.CompleteOption.Id) {
					case 1:
						benefitSummary.PerProcPrimary.NameText = "Primary";
						break;

					case 2:
						benefitSummary.PerProcPrimary.NameText = "Primary & Spouse";

						break;

					case 3:
						benefitSummary.PerProcPrimary.NameText = "Primary & Children.";
						break;

					case 4:
						benefitSummary.PerProcPrimary.NameText =
							"Primary & Spouse & Children.";
						break;
				}
			}

			benefitSummary.HidePerProcTabs = true;

			setTimeout(() => {
				if (this.$refs.perprocpopup) {
					this.$refs.perprocpopup.$data.key++;
					this.$refs.perprocpopup.$props.BenefitSummary =
						this.$data.BenefitSummary;
				}
			}, 10);
			this.insurancePopupOpt = true;
		}

		this.$data.BenefitSummary = benefitSummary;
	}

	isNumber(evt: any, variableName: string) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		//console.log(charCode);
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else if (charCode == 46 && this.$data[variableName].includes(".")) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	AgeKeyPress(evt: any) {
		try {
			if (evt.key == "ArrowUp") {
				this.Plan.Age++;
				this.PopulateProductsPlan();
			} else if (evt.key == "ArrowDown" && this.Plan.Age > 0) {
				this.Plan.Age--;
				this.PopulateProductsPlan();
			}
		} catch (ex: any) {}
	}

	onPasteAge(evt: any) {
		var log = evt.clipboardData.getData("text/plain");
		var hasDot = log.includes(".");
		if (hasDot) {
			evt.preventDefault();
			return false;
		} else {
			setTimeout(() => this.CoverageChangeEvent(), 100);
			return true;
		}
	}

	onPasteCoverage(evt: any) {
		var log = evt.clipboardData.getData("text/plain");
		var hasCharacter = /^[a-zA-Z()]+$/.test(log);
		var hasDot = log.includes(".");
		if (hasCharacter || hasDot) {
			evt.preventDefault();
			return false;
		} else {
			setTimeout(() => this.CoverageChangeEvent(), 100);
			return true;
		}
	}

	onPasteMBD(evt: any) {
		var log = evt.clipboardData.getData("text/plain");
		var hasCharacter = /^[a-zA-Z()]+$/.test(log);
		if (hasCharacter) {
			evt.preventDefault();
			return false;
		} else {
			setTimeout(() => this.MBDChangeEvent(), 100);
			return true;
		}
	}

	productionDescription: string = "";

	showInfoPopup() {
		this.$data.showProductInfo = true;
		this.$data.showPopup = true;
		this.$data.ShowVideo = false;

		if (
			this.Plan.CompleteOption != undefined &&
			this.Plan.CompleteOption.Heading != undefined
		) {
			this.productionDescription = this.Plan.CompleteOption.Description;
			if (
				this.Plan.CompleteOption.Heading != undefined &&
				this.Plan.CompleteOption.Heading != ""
			)
				this.InfoPopupHeading = this.Plan.CompleteOption.Heading;
			else this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		} else {
			this.productionDescription = this.Plan.CompleteProduct.Description;
			this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		}
	}

	ShowVideoPopup() {
		this.$data.ShowVideo = true;
		this.$data.showPopup = true;
		this.$data.showProductInfo = false;

		if (this.Plan.CompleteOption != undefined) {
			if (
				this.Plan.CompleteOption.Heading != undefined &&
				this.Plan.CompleteOption.Heading != ""
			)
				this.InfoPopupHeading = this.Plan.CompleteOption.Heading;
			else this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		} else {
			this.InfoPopupHeading = this.Plan.CompleteProduct.Name;
		}
	}

	ClosePopup() {
		this.$data.showProductInfo = false;
		this.$data.ShowVideo = false;
		this.$data.showPopup = false;
	}

	CoverageUnfocus(evt: any) {
		this.CheckDefaultCoverage();

		var value = ConvertIntoAmountFormat(this.Plan.Coverage);
		evt.target.value = value;
		// this.$data.MDBString = ConvertIntoAmountFormatWithDecimal(
		//   this.Plan.MDB,
		//   true,
		//   true
		// );
	}

	MBDUnfocus(evt: any) {
		this.CheckDefaultCoverage();
		var value = ConvertIntoAmountFormatWithDecimal(
			Number(this.Plan.MDB.toString().replaceAll(",", "")).toFixed(2),
			true,
			true
		);
		evt.target.value = value;
	}

	CoverageEnter(evt: any) {
		this.CheckDefaultCoverage(true);
	}

	MBDEnter(evt: any) {
		this.CheckDefaultCoverage(true);
	}

	OptionChange() {
		if (
			this.Plan.CompleteOption != undefined &&
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteProduct.CoverageDependOnOption == true
		) {
			this.Plan.Coverage = this.Plan.CompleteOption.Coverage;
		}

		var loadOptionId = this.Plan.CompleteOption.LoadCoverageOptionId;
		var currentOptions: any = {};
		if (this.listOfCoverageOptions) {
			currentOptions = JSON.parse(JSON.stringify(this.listOfCoverageOptions));
		}
		if (
			loadOptionId &&
			this.Plan.CompleteCoverageOption &&
			loadOptionId != this.Plan.CompleteCoverageOption.Id
		) {
			this.listOfCoverageOptions = null;
			setTimeout(() => {
				this.listOfCoverageOptions = currentOptions;
				var selOpt: any = this.listOfCoverageOptions.find(
					(x: any) => x.Id == loadOptionId
				);
				this.Plan.CompleteCoverageOption = selOpt;
				this.CoverageOptionChange();
			}, 10);
		} else {
			this.CalculateMBDRate();
		}

		this.CalculateMBDRate();
	}

	CoverageOptionChange() {
		var loadOptionId = this.Plan.CompleteCoverageOption.LoadOptionId;
		var currentOptions = JSON.parse(JSON.stringify(this.listOfOptions));
		if (loadOptionId && loadOptionId != this.Plan.CompleteOption.Id) {
			this.listOfOptions = null;
			setTimeout(() => {
				this.listOfOptions = currentOptions;
				var selOpt: any = this.listOfOptions.find(
					(x: any) => x.Id == loadOptionId
				);
				this.Plan.CompleteOption = selOpt;
				this.OptionChange();
			}, 10);
		} else {
			this.CalculateMBDRate();
		}
	}

	ProductChange() {
		this.ChangeOptionList();
		this.ChangeCoverageOptionList();
		if (this.Plan.CompleteProduct != undefined) {
			//this.Plan.Coverage = this.Plan.CompleteProduct.DefaultCoverage;
			if (this.Plan.CompleteProduct.MinCoverageDependOnAge == true)
				this.CheckDefaultCoverage();
			else this.Plan.Coverage = this.Plan.CompleteProduct.DefaultCoverage;

			this.CoverageChange();
		}
	}

	previousString: Number = 0;
	MBDChangeEvent() {
		if (
			this.$data.MDBString != undefined &&
			this.previousString < Number.parseFloat(this.$data.MDBString)
		)
			this.MBDChange(true);
		else this.MBDChange(false);
	}

	MBDChange(hasIncreased: boolean) {
		if (
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteOption != undefined &&
			this.Plan.Gender != undefined &&
			this.Plan.Gender != ""
		) {
			// var otherCoverageTypeId = hasIncreased
			//   ? this.Plan.CurrentCoverageType + 1
			//   : this.Plan.CurrentCoverageType - 1;

			var optionTypeId = 0;
			if (!this.Plan.CompleteProduct.MBDDependOnOption) {
				if (this.Plan.TobaccoUse)
					optionTypeId = this.Plan.CompleteProduct.TU.OptionId;
				else optionTypeId = this.Plan.CompleteProduct.NTU.OptionId;
			}
			var specificList = this.RateList.filter(
				(x: any) =>
					(x.Age == this.Plan.Age ||
						(x.AgeRange && x.AgeRange.includes(parseInt(this.Plan.Age)))) &&
					x.ProductListId == this.Plan.CompleteProduct.Id &&
					(x.OptionId == optionTypeId ||
						x.OptionId == this.Plan.CompleteOption.Id) &&
					(this.Plan.CompleteOption.DependOnCoverageAmount == false ||
						x.CoverageTypeId == this.Plan.CurrentCoverageType) &&
					x.State == this.Plan.State
			);

			if (specificList == undefined || specificList.length == 0) {
				var specificList = this.RateList.filter(
					(x: any) =>
						(x.Age == this.Plan.Age ||
							(x.AgeRange && x.AgeRange.includes(parseInt(this.Plan.Age)))) &&
						x.ProductListId == this.Plan.CompleteProduct.Id &&
						(x.OptionId == optionTypeId ||
							x.OptionId == this.Plan.CompleteOption.Id) &&
						(this.Plan.CompleteOption.DependOnCoverageAmount == false ||
							x.CoverageTypeId == this.Plan.CurrentCoverageType) &&
						x.State == ""
				);
			}

			if (specificList != undefined && specificList.length > 0) {
				let mbd: string = "";
				if (
					this.$data.MDBString != undefined &&
					typeof this.$data.MDBString == "string"
				)
					mbd = this.$data.MDBString.replace(",", "");
				else mbd = this.$data.MDBString;

				// if (this.Plan.CompleteOption.DependOnCoverageAmount) {
				//   if (specificList.length > 1) {
				//     var maxValue =
				//       otherCoverageTypeId > this.Plan.CurrentCoverageType
				//         ? otherCoverageTypeId
				//         : this.Plan.CurrentCoverageType;
				//     var breachValue: any;
				//     if (this.Plan.isChild)
				//       breachValue =
				//         this.Plan.CompleteProduct.ChildCoverageConfig.filter(
				//           (x: any) => x.CoverageTypeId == maxValue
				//         );
				//     else if (this.Plan.isOld)
				//       breachValue = this.Plan.CompleteProduct.OldCoverageConfig.filter(
				//         (x: any) => x.CoverageTypeId == maxValue
				//       );
				//     else
				//       breachValue =
				//         this.Plan.CompleteProduct.AdultCoverageConfig.filter(
				//           (x: any) => x.CoverageTypeId == maxValue
				//         );
				//     let breachNumber: number = 0;
				//     if (breachValue != undefined && breachValue.length > 0) {
				//       breachNumber = breachValue[0].MinCoverage;
				//     }

				//     if (this.Plan.Gender == "MALE")
				//       this.Plan.Coverage = CalculateCoverageFromMBDByCoverageType(
				//         Number.parseFloat(mbd),
				//         0,
				//         specificList[0].MaleRate,
				//         specificList[1].MaleRate,
				//         breachNumber
				//       ).toString();
				//     else {
				//       this.Plan.Coverage = CalculateCoverageFromMBDByCoverageType(
				//         Number.parseFloat(mbd),
				//         0,
				//         specificList[0].FemaleRate,
				//         specificList[1].FemaleRate,
				//         breachNumber
				//       ).toString();
				//     }
				//   } else {
				//     if (this.Plan.Gender == "MALE")
				//       this.Plan.Coverage = CalculateCoverageFromMBDByCoverageType(
				//         Number.parseFloat(mbd),
				//         0,
				//         specificList[0].MaleRate,
				//         0,
				//         0
				//       ).toString();
				//     else {
				//       this.Plan.Coverage = CalculateCoverageFromMBDByCoverageType(
				//         Number.parseFloat(mbd),
				//         0,
				//         specificList[0].FemaleRate,
				//         0,
				//         0
				//       ).toString();
				//     }
				//   }
				// } else {
				if (this.Plan.Gender == "MALE")
					this.Plan.Coverage = CalculateCoverageFromMBD(
						Number.parseFloat(mbd),
						0,
						specificList[0].MaleRate
					).toString();
				else {
					this.Plan.Coverage = CalculateCoverageFromMBD(
						Number.parseFloat(mbd),
						0,
						specificList[0].FemaleRate
					).toString();
				}
				//}

				this.Plan.MDB = mbd;
				this.CoverageChange(false);
			} else {
				this.Plan.Coverage = "0";
			}
		}
	}

	CheckDefaultCoverage(
		makeSimpleAmount: boolean = false,
		forceDefault: boolean = false
	) {
		var covValue = null;
		if (
			this.Plan.Coverage != undefined &&
			typeof this.Plan.Coverage == "string"
		)
			covValue = Number.parseFloat(this.Plan.Coverage.replace(",", ""));
		else covValue = Number.parseFloat(this.Plan.Coverage);

		if (isNaN(covValue)) {
			covValue = null;
		}
		var isNeedToCalculateCoverage = false;
		var numberValue: any = undefined;
		if (
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteOption != undefined
		) {
			if (
				!covValue ||
				(forceDefault &&
					this.Plan.CompleteProduct != undefined &&
					this.Plan.CompleteProduct.CoverageDependOnOption != true)
			) {
				numberValue = this.Plan.CompleteProduct.DefaultCoverage;
				isNeedToCalculateCoverage = true;

				if (
					this.Plan.CompleteProduct.hasSeperateMaxCoverageAgeSpecific == true
				) {
					setTimeout(() => {
						this.CheckDefaultCoverage();
					}, 20);
				}
			} else if (this.Plan.CompleteProduct.MinCoverageDependOnAge == true) {
				if (
					this.Plan.isOld &&
					covValue &&
					covValue < this.Plan.CompleteProduct.OldCoverageConfig[0].MinCoverage
				) {
					numberValue =
						this.Plan.CompleteProduct.OldCoverageConfig[0].MinCoverage;
					isNeedToCalculateCoverage = true;
				} else if (!covValue) {
					numberValue = this.Plan.CompleteProduct.DefaultCoverage;
					isNeedToCalculateCoverage = true;
				} else if (
					this.Plan.CompleteProduct.MinCoverage &&
					covValue < Number.parseFloat(this.Plan.CompleteProduct.MinCoverage)
				) {
					numberValue = this.Plan.CompleteProduct.MinCoverage;
					isNeedToCalculateCoverage = true;
				}
			} else if (
				this.Plan.CompleteProduct.hasStateSpecificMinCoverage == true
			) {
				if (this.Plan.CompleteProduct.StateSpecifiMinCoverage.length > 0) {
					var stateSpecificMinCoverage =
						this.Plan.CompleteProduct.StateSpecifiMinCoverage.filter(
							(x: any) => {
								if (x.State == this.Plan.State) return x;
							}
						);
					if (
						stateSpecificMinCoverage != undefined &&
						stateSpecificMinCoverage.length > 0
					) {
						var firstElem = stateSpecificMinCoverage[0];
						if (
							firstElem.MinCoverage &&
							covValue < Number.parseFloat(firstElem.MinCoverage)
						) {
							numberValue = firstElem.MinCoverage;
							isNeedToCalculateCoverage = true;
						}
					} else if (
						this.Plan.CompleteProduct.MinCoverage &&
						covValue < Number.parseFloat(this.Plan.CompleteProduct.MinCoverage)
					) {
						numberValue = this.Plan.CompleteProduct.MinCoverage;
						isNeedToCalculateCoverage = true;
					}
				} else if (
					this.Plan.CompleteProduct.MinCoverage &&
					covValue < Number.parseFloat(this.Plan.CompleteProduct.MinCoverage)
				) {
					numberValue = this.Plan.CompleteProduct.MinCoverage;
					isNeedToCalculateCoverage = true;
				}
			} else if (
				this.Plan.CompleteProduct.MinCoverage &&
				covValue < Number.parseFloat(this.Plan.CompleteProduct.MinCoverage)
			) {
				numberValue = this.Plan.CompleteProduct.MinCoverage;
				isNeedToCalculateCoverage = true;
			}

			if (
				this.Plan.CompleteProduct.MaxCoverage != 0 &&
				covValue &&
				covValue > this.Plan.CompleteProduct.MaxCoverage &&
				this.Plan.CompleteProduct.hasSeperateMaxCoverageAgeSpecific != true
			) {
				numberValue = this.Plan.CompleteProduct.MaxCoverage;
				isNeedToCalculateCoverage = true;
			} else if (
				this.Plan.CompleteProduct.MaxCoverage != 0 &&
				covValue &&
				this.Plan.CompleteProduct.hasSeperateMaxCoverageAgeSpecific == true &&
				this.Plan.CompleteProduct.SpecificMaxConverage != undefined
			) {
				if (
					this.Plan.CompleteProduct.SpecificMaxConverage != undefined &&
					this.Plan.Age >=
						this.Plan.CompleteProduct.SpecificMaxConverage.MinAge &&
					this.Plan.Age <=
						this.Plan.CompleteProduct.SpecificMaxConverage.MaxAge &&
					covValue > this.Plan.CompleteProduct.SpecificMaxConverage.MaxCoverage
				) {
					numberValue =
						this.Plan.CompleteProduct.SpecificMaxConverage.MaxCoverage;
					isNeedToCalculateCoverage = true;
				} else if (
					this.Plan.CompleteProduct.MaxCoverage != 0 &&
					covValue &&
					covValue > this.Plan.CompleteProduct.MaxCoverage
				) {
					numberValue = this.Plan.CompleteProduct.MaxCoverage;
					isNeedToCalculateCoverage = true;
				}
			}
		}

		if (isNeedToCalculateCoverage) {
			try {
				this.Plan.Coverage = numberValue.toFixed();
			} catch {
				this.Plan.Coverage = numberValue;
			}
			var kval: string;
			if (makeSimpleAmount) kval = this.Plan.Coverage;
			else kval = ConvertIntoAmountFormat(this.Plan.Coverage);
			this.CoverageChange();
			this.$refs.tfCoverage.internalValue = kval;
		}
	}

	CoverageFocus(evt: any) {
		if (
			this.Plan.Coverage != undefined &&
			typeof this.Plan.Coverage == "string"
		)
			this.Plan.Coverage = this.Plan.Coverage.replace(",", "");
	}

	MBDFocus(evt: any) {
		if (
			this.Plan.Coverage != undefined &&
			typeof this.Plan.Coverage == "string"
		)
			this.Plan.Coverage = this.Plan.Coverage.replace(",", "");
	}

	CoverageChangeEvent() {
		if (
			this.$data.CoverageString != undefined &&
			typeof this.$data.CoverageString == "string"
		)
			this.Plan.Coverage = this.$data.CoverageString.replace(",", "");
		else this.Plan.Coverage = this.$data.CoverageString;
		this.CoverageChange();
	}

	CoverageChange(calculateMBD: boolean = true) {
		// let DependedOnCovProducts = this.listofProducts.filter((x: any) => {
		//   if (x.ChangeProductOnCoverageChange == true) return x;
		// });
		var CoverageValue = Number.parseFloat(this.Plan.Coverage);
		if (typeof this.Plan.Coverage == "string")
			CoverageValue = Number.parseFloat(this.Plan.Coverage.replace(",", ""));

		// DependedOnCovProducts.forEach((element: any) => {
		//   let coverageOption: any = undefined;
		//   if (this.Plan.isChild) {
		//     var CoverageConfig = element.ChildCoverageConfig;
		//     coverageOption = CoverageConfig.filter(
		//       (x: any) =>
		//         CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
		//     );
		//   } else if (this.Plan.isOld) {
		//     var CoverageConfig = element.OldCoverageConfig;
		//     coverageOption = CoverageConfig.filter(
		//       (x: any) =>
		//         CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
		//     );
		//     if (coverageOption == undefined || coverageOption.length == 0) {
		//       coverageOption = [CoverageConfig[0]];
		//     }
		//   } else {
		//     var CoverageConfig = element.AdultCoverageConfig;
		//     coverageOption = CoverageConfig.filter(
		//       (x: any) =>
		//         CoverageValue >= x.MinCoverage && CoverageValue <= x.MaxCoverage
		//     );
		//   }
		//   if (
		//     coverageOption != undefined &&
		//     coverageOption.length > 0 &&
		//     coverageOption[0] != undefined
		//   ) {
		//     this.Plan.CurrentCoverageType = coverageOption[0].CoverageTypeId;
		//     setTimeout(() => {
		//       if (
		//         this.Plan.CompleteProduct != undefined &&
		//         this.Plan.CompleteProduct.Id == element.Id
		//       ) {
		//         element.DisplayName = coverageOption[0].DisplayName;
		//         this.Plan.CompleteProduct = element;
		//         this.$refs.comboProduct.internalSearch = element.DisplayName;
		//       } else {
		//         element.DisplayName = coverageOption[0].DisplayName;
		//       }
		//       // this.Plan.CompleteProduct.DisplayName = coverageOption[0].DisplayName;
		//     }, 50);
		//   }
		// });
		if (
			this.Plan.CompleteOption != undefined &&
			this.Plan.CompleteOption.DependOnCoverageAmount
		) {
			this.Plan.CurrentCoverageType = this.Plan.CompleteProduct.CoverageTypeId;
		}

		if (calculateMBD) this.CalculateMBDRate();
	}

	CalculateMBDRate() {
		if (
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteProduct != undefined &&
			this.Plan.CompleteOption != undefined &&
			this.Plan.Gender != undefined &&
			this.Plan.Gender != ""
		) {
			var optionTypeId = 0;
			if (!this.Plan.CompleteProduct.MBDDependOnOption) {
				if (this.Plan.TobaccoUse)
					optionTypeId = this.Plan.CompleteProduct.TU.OptionId;
				else optionTypeId = this.Plan.CompleteProduct.NTU.OptionId;
			}

			if (
				this.Plan.CompleteProduct.showCoverageDropdown &&
				this.Plan.CompleteCoverageOption
			) {
				this.Plan.CurrentCoverageType = this.Plan.CompleteCoverageOption.Id;
			}

			var specificList = this.RateList.filter(
				(x: any) =>
					(x.Age == this.Plan.Age ||
						(x.AgeRange && x.AgeRange.includes(parseInt(this.Plan.Age)))) &&
					x.ProductListId == this.Plan.CompleteProduct.Id &&
					(x.OptionId == optionTypeId ||
						x.OptionId == this.Plan.CompleteOption.Id) &&
					(this.Plan.CompleteOption.DependOnCoverageAmount == false ||
						x.CoverageTypeId == this.Plan.CurrentCoverageType) &&
					(x.State == this.Plan.State ||
						(x.StateList && x.StateList.includes(this.Plan.State)))
			);

			if (specificList == undefined || specificList.length == 0) {
				var specificList = this.RateList.filter(
					(x: any) =>
						(x.Age == this.Plan.Age ||
							(x.AgeRange && x.AgeRange.includes(parseInt(this.Plan.Age)))) &&
						x.ProductListId == this.Plan.CompleteProduct.Id &&
						(x.OptionId == optionTypeId ||
							x.OptionId == this.Plan.CompleteOption.Id) &&
						(this.Plan.CompleteOption.DependOnCoverageAmount == false ||
							x.CoverageTypeId == this.Plan.CurrentCoverageType) &&
						x.State == ""
				);
			}

			if (specificList != undefined && specificList.length > 0) {
				let coverage: string = "";
				if (
					this.Plan.Coverage != undefined &&
					typeof this.Plan.Coverage == "string"
				)
					coverage = this.Plan.Coverage.replace(",", "");
				else coverage = this.Plan.Coverage;
				if (this.Plan.CompleteProduct.StaticMBDRate == true) {
					if (this.Plan.Gender == "MALE") {
						this.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
							specificList[0].MaleRate.toFixed(2).toString(),
							true,
							true
						);
						this.formatMBD = true;
					} else {
						this.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
							specificList[0].FemaleRate.toFixed(2).toString(),
							true,
							true
						);
						this.formatMBD = true;
					}
				} else {
					if (this.Plan.Gender == "MALE") {
						this.Plan.MDB = CalculateMBD(
							Number.parseFloat(coverage),
							0,
							specificList[0].MaleRate
						).toString();
						this.formatMBD = true;
					} else {
						this.Plan.MDB = CalculateMBD(
							Number.parseFloat(coverage),
							0,
							specificList[0].FemaleRate
						).toString();
						this.formatMBD = true;
					}
				}
			} else {
				this.Plan.MDB = "0.00";
				this.formatMBD = true;
			}
		}
	}

	AgeChange() {
		this.PopulateProductsPlan();
	}

	PopulateProductsPlan() {
		var currentSelectedProduct = this.Plan.CompleteProduct;
		var age = this.Plan.Age;
		if (age === "" && age != 0) age = -1;
		if (this.Plan.TobaccoUse) {
			this.listofProducts = this.listofAllProduct
				.filter(
					(x: any) =>
						x.ShowAsASeperateProduct == true &&
						age >= x.TU.AgeLimit.Min &&
						age <= x.TU.AgeLimit.Max &&
						(!x.DependOnChild || x.DependOnChild == this.Plan.hasChildren) &&
						(x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.Plan.State)) &&
						(x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.Plan.State))
				)
				.sort(this.compareProduct);
		} else {
			this.listofProducts = this.listofAllProduct
				.filter(
					(x: any) =>
						x.ShowAsASeperateProduct == true &&
						age >= x.NTU.AgeLimit.Min &&
						age <= x.NTU.AgeLimit.Max &&
						(!x.DependOnChild || x.DependOnChild == this.Plan.hasChildren) &&
						(x.ExcludeStates == undefined ||
							x.ExcludeStates.length == 0 ||
							!x.ExcludeStates.includes(this.Plan.State)) &&
						(x.IncludedStates == undefined ||
							x.IncludedStates.length == 0 ||
							x.IncludedStates.includes(this.Plan.State))
				)
				.sort(this.compareProduct);
		}

		if (this.listofProducts != undefined && this.listofProducts.length > 0) {
			var removeproduct: any = [];
			this.listofProducts.forEach((productelement: any) => {
				if (productelement.AgeDependOnState) {
					var age = Number.parseInt(this.Plan.Age);
					var selectedState = productelement.StateAge.filter(
						(x: any) =>
							x.State == this.Plan.State && (age < x.MinAge || age > x.MaxAge)
					);
					if (selectedState != undefined && selectedState.length > 0) {
						removeproduct.push(productelement);
					}
				}
				if (productelement.StateSpecificAgeExclude == true) {
					var age = Number.parseInt(this.Plan.Age);
					var selectedState = productelement.StateAgeExcludeLimits.filter(
						(x: any) =>
							x.State == this.Plan.State && age >= x.MinAge && age <= x.MaxAge
					);
					if (selectedState != undefined && selectedState.length > 0) {
						removeproduct.push(productelement);
					}
				}
			});

			if (removeproduct.length > 0) {
				removeproduct.forEach((element: any) => {
					const index = this.listofProducts.indexOf(element);
					if (index > -1) {
						this.listofProducts.splice(index, 1);
					}
				});
			}

			if (this.Plan.CompleteProduct != undefined) {
				if (this.listofProducts.indexOf(this.Plan.CompleteProduct) == -1) {
					this.Plan.CompleteProduct = undefined;
				}
			}
			if (
				(this.Plan.CompleteProduct == undefined ||
					this.Plan.CompleteProduct == null) &&
				this.listofProducts &&
				currentSelectedProduct != undefined &&
				currentSelectedProduct.CoverageTypeId
			) {
				var listHasCurrentSelectedProduct = this.listofProducts.find(
					(x: any) => x.CoverageTypeId == currentSelectedProduct.CoverageTypeId
				);
				this.Plan.CompleteProduct = listHasCurrentSelectedProduct;
			}
			this.ChangeOptionList();
			this.ChangeCoverageOptionList();
			this.CoverageChange();
			this.CheckDefaultCoverage(false, true);
		} else {
			this.Plan.CompleteProduct = undefined;
			this.ChangeOptionList();
			this.ChangeCoverageOptionList();
		}

		this.CoverageChange();
		this.CalculateMBDRate();
	}
}
