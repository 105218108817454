






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import PrimaryPerson from "../entities/PrimaryPerson";
import Presentation from "../entities/Presentation";
import { Plan, GetEntityFromPlan } from "../models/Plan";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MenuComponent from "../components/menu.vue";
import PlanBox from "../components/PlanBox.vue";
import AddChildrenComponent from "../components/popups/AddChildren.vue";
import PremiumPlan from "../models/PremiumPlan";
import Children from "../entities/Children";
import { getPersistedState, setPersistedState } from "../helpers/StateMapper";

import Spouse from "../entities/Spouse";
import GenericKeyPair from "../models/GenericKeyPair";
import PremiumPlanEntity from "../entities/PremiumPlanEntity";
import {
	CalculateBenefits,
	CalculateDailyChange,
	CalculateMonthlyChange,
	CalculateHourlyChange,
	CalculatePlanSelectionChanged,
	CalculateCoverageChange,
	CalculatePlanOptionChange,
	RoundUpperDecimal,
	IsPrimaryVeteranAndHonorablyDischarged,
	IsSpouseVeteranAndHonorablyDischarged,
	CalculateCoveregeOnSubType,
} from "../helpers/CalculationHelper";
import { PlanCategoryType } from "../enumerations/PlanCategoryType";
import PlanSummary from "../entities/PlanSummary";
import {
	PlanEntity,
	PresentationPlans,
	PersonPlan,
	SpousePlan,
} from "../entities/PlanEntity";
import BenefitsSummary from "./BenefitsSummaryPage.vue";
import { EntityHelper } from "../helpers/EntityHelper";

import { warn } from "vue-class-component/lib/util";
import RateBookBox from "../components/RateBookBox.vue";
import RateBookEntity from "../entities/RateBookEntity";
import {
	ConvertIntoAmountFormatWithDecimal,
	ConvertIntoAmountFormat,
} from "../helpers/CalculationHelper";
import User from "@/entities/User";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import LoggingHelper from "../helpers/LoggingHelper";
import LoggingConstants from "../constants/LoggingConstants";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import moment from "moment";
import { PresentationType } from "@/enumerations/PresentationType";
import { EventBusLayout } from "../bus/EventBusLayout";
import FuneralExpense from "../components/popups/FuneralExpense.vue";
import { PresentationSubType } from "@/enumerations/PresentationSubType";

let listofGenderTypes = require("../assets/pre-fill-data/gender-types.json");
let listofUnitTypes = require("../assets/pre-fill-data/unit-types.json");
let listofpremiumApproach = require("../assets/pre-fill-data/premium-approach-type.json");
let listofsmb_PrimaryPlans = require("../assets/pre-fill-data/Smb_PrimaryOnly.json");
let listofsmb_PriSPCPlans = require("../assets/pre-fill-data/Smb_PrimaryWithSPC.json");
let listofsmb_SRGWL_And_LESWL_Plans = require("../assets/pre-fill-data/Smb_SRGWL_And_LESWL.json");

@Component({
	components: {
		MenuComponent,
		PlanBox,
		RateBookBox,
		AddChildrenComponent,
		ReferralPopup,
		FuneralExpense,
	},
})
export default class PlanGenerator extends Vue {
	private planloaded: number = -1;
	private Person: any;
	private Presentation: any;
	private SmbPrimaryPlan = listofsmb_PrimaryPlans;
	private SmbPriSPCPlan = listofsmb_PriSPCPlans;
	private SmbSrgwlAndLeswl = listofsmb_SRGWL_And_LESWL_Plans;
	private lstStates: any = null;
	private RateList: any = null;
	private AllProducts: any = null;
	private GenderTypes = listofGenderTypes;
	private UnitTypes = listofUnitTypes;
	private addchildren: boolean = false;
	private addtionalproducts: boolean = false;
	private allocateremaining: boolean = false;
	private CombinedPremiumAmount: number = 0;
	private isCustomPremiumChecked: Boolean = false;
	private isCombinedPremiumTypeChecked: Boolean = true;
	private PremiumPlan!: PremiumPlan;
	private Childrens: Array<Children> = [];
	private TempChildrens: Array<Children> = [];
	private Protections: Array<any> = [];
	private pageLoadedTime: any;
	private planInitialized: boolean = false;
	private ListOfWagesTypes: any = require("../assets/pre-fill-data/wage-types.json");
	private ShowWeeklyOption: boolean = true;
	private allocatablePrimaryProducts: Array<any> = [];
	private selectedAllocatableProducts: Array<any> = [];
	private showWHLBothCheckBox: boolean = false;
	private WHLBothCheckBox: boolean = false;
	private show10YRCBothCheckBox: boolean = false;
	private YRCBothCheckBox: boolean = false;
	private allocatableSpouseProducts: Array<any> = [];
	private allocatableAmount: Number = 0;
	private showAnnualRateLegend: boolean = false;
	private PremiumApproaches = require("../assets/pre-fill-data/premium-approach-type.json");
	private SelectedWageType = this.ListOfWagesTypes[0];
	NoOfRateBookRows: number[] = [];
	ListOfRatePlan: RateBookEntity[] = [];
	private showRateBook: Boolean = false;
	private isPrePlan: boolean = false;
	private prePlanPresentationTypes: any = [];
	private prePlanStates: any = null;
	private prePlanSelectedPresenation: any;
	private primaryWageValue: any = null;
	private spouseWageValue: any = null;
	private changeWageType: boolean = false;
	private personAgeExist: boolean = false;
	private reallocatecounter: number = 1;
	private ssprogram: boolean = false;
	private somemodal: boolean = false;
	private ResetReferrals: boolean = false;
	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;
	private browserCloseEvent: boolean = false;
	private AnyProductSelected: boolean = false;
	private IsMexicanStateSelected: boolean = false;
	private funeralexpense: boolean = false;
	private LeswlAllowedStates = [
		"Massachusetts",
		"Minnesota",
		"New Hampshire",
		"New Jersey",
		"North Carolina",
		"Pennsylvania",
		"Washington",
		"West Virginia",
	];

	$refs!: {
		presPlanbox: any;
		primaryPlanBox: any;
		spousePlanBox: any;
		primaryAHPPlanBox: any;
		primaryALPPlanBox: any;
		spouseAHPPlanBox: any;
		spouseAddPlanBox: any;
		funeralExpensePopup: any;
	};

	get ShowSuccessorErrorText() {
		if (Number(this.PremiumPlan.RemainingMonthlyPremium) == 0) return "";

		if (Number(this.PremiumPlan.RemainingMonthlyPremium) > 0)
			return "success--text";

		if (Number(this.PremiumPlan.RemainingMonthlyPremium) < 0)
			return "error--text";
		return "";
	}

	get IsVeteranSMBAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId == PresentationType.Veteran &&
			this.Presentation.PresentationSubTypeId ==
				PresentationSubType.ServiceMemberBenefitsLeadAlpha
		) {
			return true;
		}
		return false;
	}

	get IsServiceMemberBenefitsAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId ==
				PresentationType.ServiceMemberBenefits &&
			(this.Presentation.PresentationSubTypeId ==
				PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.SMBVeteranFamilyLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.Civilian ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitNotification)
		) {
			return true;
		}
		return false;
	}

	AddNewRateRow() {
		if (
			this.NoOfRateBookRows == undefined ||
			this.NoOfRateBookRows.length == 0
		) {
			this.NoOfRateBookRows = [];
			this.NoOfRateBookRows.push(this.NoOfRateBookRows.length);
		} else {
			var lastRowItem = this.NoOfRateBookRows[this.NoOfRateBookRows.length - 1];
			this.NoOfRateBookRows.push(lastRowItem + 2);
		}
		var rate1 = new RateBookEntity();
		var rate2 = new RateBookEntity();
		var user = User.query().last();

		if (user) {
			var state = this.lstStates.find((x: any) => {
				if (x.Name == user?.Territory) return x;
			});
			if (state) {
				rate1.State = state.StateCode;
				rate2.State = state.StateCode;
			}
		}

		if (this.Presentation.State != undefined && this.Presentation.State != "") {
			var state = this.lstStates.find((x: any) => {
				if (x.Name == this.Presentation.State) return x;
			});
			if (state) {
				rate1.State = state.StateCode;
				rate2.State = state.StateCode;
			}
		}

		if (this.ListOfRatePlan == undefined) this.ListOfRatePlan = [];
		this.ListOfRatePlan.push(rate1);
		this.ListOfRatePlan.push(rate2);
		var svview = document.getElementsByClassName("formscroll");
		if (
			svview != undefined &&
			svview.length > 0 &&
			svview[0].children.length > 0
		) {
			{
				setTimeout(() => {
					svview[0].children[svview[0].children.length - 1].scrollIntoView(
						true
					);
				}, 50);
			}
		}
	}
	private personYearlySalary = 0;
	private spouseYearlySalary = 0;

	capitalizeFirstLetter(string: string) {
		string = string.toLowerCase();
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	OnWageChanged(item: any) {
		this.$data.wageMenu = false;
		var previousSelectedWage = this.SelectedWageType;
		var primaryWage = 0;
		if (this.Person.Wage)
			primaryWage = Number(this.Person.Wage.toString().replaceAll(",", ""));
		var spouseWage = 0;
		if (this.Person.Spouse.Wage)
			spouseWage = Number(
				this.Person.Spouse.Wage.toString().replaceAll(",", "")
			);

		if (this.primaryWageValue) {
			primaryWage = this.primaryWageValue;
		}

		if (this.spouseWageValue) {
			spouseWage = this.spouseWageValue;
		}

		var perHourPrimaryValue = primaryWage / this.SelectedWageType.HourValue;
		var perHourSpouseValue = spouseWage / this.SelectedWageType.HourValue;
		this.SelectedWageType = item;

		this.personYearlySalary = perHourPrimaryValue * 2080;
		this.spouseYearlySalary = perHourSpouseValue * 2080;

		perHourPrimaryValue = perHourPrimaryValue * this.SelectedWageType.HourValue;
		perHourSpouseValue = perHourSpouseValue * this.SelectedWageType.HourValue;

		this.primaryWageValue = perHourPrimaryValue;
		this.spouseWageValue = perHourSpouseValue;

		this.Person.Wage = ConvertIntoAmountFormatWithDecimal(
			RoundUpperDecimal(perHourPrimaryValue).toFixed(2).toString(),
			true,
			true
		);
		this.Person.Spouse.Wage = ConvertIntoAmountFormatWithDecimal(
			RoundUpperDecimal(perHourSpouseValue).toFixed(2).toString(),
			true,
			true
		);

		this.Presentation.WageType = this.SelectedWageType.Name;

		// if (this.SelectedWageType.PremiumTypeId != 0)
		//   this.PremiumPlan.PremiumTypeId = this.SelectedWageType.PremiumTypeId;

		// var selApprh = this.PremiumApproaches.filter((x: any) => {
		//   if (x.id == this.SelectedWageType.PremiumTypeId) return x;
		// });

		// if (selApprh != undefined && selApprh.length > 0) {
		//   this.SelectedPremiumType = selApprh[0];
		// }
		this.SetPremiumPlanAnnualIncome(false);
	}

	@Watch("PremiumPlan.PremiumTypeId")
	OnPremiumPlanChange(e: any, p: any) {
		var selPremiumAprh = listofpremiumApproach.filter((x: any) => x.id == e);

		if (selPremiumAprh != undefined && selPremiumAprh.length > 0) {
			this.ShowWeeklyOption = selPremiumAprh[0].showWeekly;
		}

		if (this.changeWageType) {
			var selwage = this.ListOfWagesTypes.filter(
				(x: any) => x.PremiumTypeId == e
			);
			if (selwage != undefined && selwage.length > 0) {
				this.OnWageChanged(selwage[0]);
			}
		} else {
			this.changeWageType = true;
		}
	}

	@Watch("Person.Spouse.FirstName")
	OnSpouseFirstNameChange() {
		this.dollaradaychanged();
		this.SpouseDetailChange();
	}
	@Watch("Person.AgePerDOB")
	OnPrimaryAgeChange() {
		if (
			this.isPrePlan &&
			this.$data.PrimaryProducts != null &&
			this.$data.PrimaryProducts.length > 0 &&
			this.Person.AgePerDOB < 60 &&
			this.$data.PrimaryProducts[0].Selected == true
		) {
			this.$data.PrimaryProducts[0].WHLSelected = true;
		} else if (
			this.$data.PrimaryProducts != null &&
			this.$data.PrimaryProducts.length > 0
		) {
			this.$data.PrimaryProducts[0].WHLSelected = false;
		}
	}

	@Watch("Person.Spouse.AgePerDOB")
	OnSpouseAgeChange() {
		if (this.SelectedPremiumType != undefined && this.SelectedPremiumType) {
			var getDADExisist = this.Person.isSpouseForDADExists;
		}
		this.dollaradaychanged();
		this.SpouseDetailChange();

		if (
			this.isPrePlan &&
			this.$data.SpouseProducts != null &&
			this.$data.SpouseProducts.length > 0 &&
			this.Person.Spouse.AgePerDOB < 60 &&
			this.$data.SpouseProducts[0].Selected == true
		) {
			this.$data.SpouseProducts[0].WHLSelected = true;
		} else if (
			this.$data.SpouseProducts != null &&
			this.$data.SpouseProducts.length > 0
		) {
			this.$data.SpouseProducts[0].WHLSelected = false;
		}
	}

	@Watch("Person.Spouse.Gender")
	OnSpouseGenderChange() {
		if (this.SelectedPremiumType != undefined && this.SelectedPremiumType) {
			var getDADExisist = this.Person.isSpouseForDADExists;
		}
		this.dollaradaychanged();
		this.SpouseDetailChange();
	}

	@Watch("showPlanOptions")
	OnPlanOptionsPopupClose(newValue: boolean, oldValue: boolean) {
		var index = 1;
		this.$data.Plans.forEach((plan: any) => {
			if (plan.Name.trim() == "" || !plan.Name) {
				plan.Name = "PLAN OPTION " + index.toString();
			}
			index++;
		});
	}

	@Watch("addchildren")
	OnAddchildren(e: boolean) {
		if (e == true) {
			if (this.Childrens == null || this.Childrens.length < 1) {
				this.Childrens.push(
					new Children(),
					new Children(),
					new Children(),
					new Children()
				);
			}
			this.TempChildrens = JSON.parse(JSON.stringify(this.Childrens));
		} else {
		}
	}

	AgeKeyPress(evt: any, val: any) {
		try {
			if (evt.key == "ArrowUp") {
				val.AgePerDOB++;
			} else if (evt.key == "ArrowDown" && val.AgePerDOB > 0) {
				val.AgePerDOB--;
			}
		} catch (ex: any) {}
	}

	public PlanSelectionChanged(tmpPlan: any) {
		//CalculatePlanSelectionChanged(this.PremiumPlan, tmpPlan);
	}

	saveChildren(items: any) {
		LoggingHelper.getInstance().LogEvent(LoggingConstants.ChildrenPopupClose);
		this.addchildren = false;
		this.Childrens = JSON.parse(
			JSON.stringify(
				this.TempChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);
		// if (this.Childrens.length == 0) {
		// 	this.Person.HasChildrenUnder18 = false;
		// }
	}
	cancelchildren() {
		this.addchildren = false;
		LoggingHelper.getInstance().LogEvent(LoggingConstants.ChildrenPopupClose);
		this.Childrens = JSON.parse(
			JSON.stringify(
				this.TempChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);
		// if (this.Childrens.length == 0) {
		// 	this.Person.HasChildrenUnder18 = false;
		// }
	}

	private SelectedPremiumType: any = {};
	PremiumApproachChange(evt: any) {
		if (evt != undefined && evt.id == 4) {
			this.wageChanged(false, "");
			this.SetPremiumPlanAnnualIncome(false);
		}

		this.PremiumPlan.PremiumTypeId = Number.parseInt(evt.id);
		this.Presentation.PremiumTypeId = Number.parseInt(evt.id);
	}

	premiumneedchange() {
		this.Presentation.NeedAmount = this.PremiumPlan.NeedPremium;
	}

	get remainingMonthly() {
		if (Number(this.PremiumPlan.RemainingMonthlyPremium) == 0) return 0.0;

		if (Number(this.PremiumPlan.RemainingMonthlyPremium) > 0)
			return "+" + this.PremiumPlan.RemainingMonthlyPremium;

		return this.PremiumPlan.RemainingMonthlyPremium;
	}

	get divColorForPlan() {
		var a = this.$data.refreshKey;
		var plans = this.$data.Plans.filter((x: any) => x.IsChecked == true);

		if (plans.length > 1) return this.$data.CurrentSelectedPlan.divid;
		return "";
	}

	getCoverage(coverage: any) {
		if (
			coverage != undefined &&
			typeof coverage == "string" &&
			coverage.includes(".")
		)
			return ConvertIntoAmountFormatWithDecimal(coverage);
		else
			try {
				return ConvertIntoAmountFormat(coverage.toString());
			} catch (ex: any) {
				console.log("Error on Changing Coverage" + ex);
			}

		return ConvertIntoAmountFormatWithDecimal(coverage);
	}
	loadAllocateRemainingPremiumPopup() {
		this.selectedAllocatableProducts = [];
		this.allocatablePrimaryProducts = [];
		this.allocatableSpouseProducts = [];
		this.showWHLBothCheckBox = false;
		this.WHLBothCheckBox = false;
		this.YRCBothCheckBox = false;
		this.show10YRCBothCheckBox = false;
		var whlPrimaryProduct = this.$data.PrimaryProducts.find((x: any) => {
			if (x.IsWHL == true && x.Selected == true && x.ProductAvailable != false)
				return x;
		});

		var whlSpouseProduct = this.Person.isSpouseExistForCalculation
			? this.$data.SpouseProducts.find((x: any) => {
					if (
						x.IsWHL == true &&
						x.Selected == true &&
						x.ProductAvailable != false
					)
						return x;
			  })
			: undefined;

		var amountToAllocate = Number(this.PremiumPlan.RemainingMonthlyPremium);

		if (whlPrimaryProduct) {
			var temp = JSON.parse(JSON.stringify(whlPrimaryProduct));
			temp.Selected = false;
			temp.Plan.CurrentProduct.PGName = temp.Plan.PGName;
			temp.ShowCheckBox = amountToAllocate != 0;

			if (amountToAllocate < 0) {
				var whlCov = Number(temp.Plan.Coverage.toString().replaceAll(",", ""));
				temp.ShowCheckBox =
					whlCov > Number(temp.Plan.CurrentProduct.MinCoverage);

				if (temp.Plan.CurrentProduct.hasStateSpecificMinCoverage == true) {
					if (temp.Plan.CurrentProduct.StateSpecifiMinCoverage.length > 0) {
						var stateSpecificMinCoverage =
							temp.Plan.CurrentProduct.StateSpecifiMinCoverage.filter(
								(x: any) => {
									if (x.State == temp.Plan.State) return x;
								}
							);
						if (
							stateSpecificMinCoverage != undefined &&
							stateSpecificMinCoverage.length > 0
						) {
							var firstElem = stateSpecificMinCoverage[0];

							if (whlCov <= firstElem.MinCoverage) temp.ShowCheckBox = false;
						}
					}
				}
			} else if (amountToAllocate > 0) {
				var whlCov = Number(temp.Plan.Coverage.toString().replaceAll(",", ""));
				temp.ShowCheckBox =
					whlCov < Number(temp.Plan.CurrentProduct.MaxCoverage);

				if (
					temp.Plan.CurrentProduct.hasSeperateMaxCoverageAgeSpecific == true &&
					temp.Plan.CurrentProduct.SpecificMaxConverage != undefined
				) {
					if (
						temp.Plan.CurrentProduct.SpecificMaxConverage != undefined &&
						this.Person.AgePerDOB >=
							temp.Plan.CurrentProduct.SpecificMaxConverage.MinAge &&
						this.Person.AgePerDOB <=
							temp.Plan.CurrentProduct.SpecificMaxConverage.MaxAge &&
						whlCov >= temp.Plan.CurrentProduct.SpecificMaxConverage.MaxCoverage
					) {
						temp.ShowCheckBox = false;
					}
				}
			}
			temp.Plan.Coverage = this.getCoverage(temp.Plan.Coverage);
			if (typeof temp.Plan.MDB == "string")
				temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
					Number(temp.Plan.MDB.replaceAll(",", "")).toFixed(2),
					true,
					true
				);
			else
				temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
					Number(temp.Plan.MDB).toFixed(2),
					true,
					true
				);
			this.allocatablePrimaryProducts.push(temp);
		} else {
			this.allocatablePrimaryProducts.push({ isEmpty: true });
		}

		if (whlSpouseProduct) {
			var temp = JSON.parse(JSON.stringify(whlSpouseProduct));
			temp.Selected = false;
			temp.Plan.CurrentProduct.PGName = temp.Plan.PGName;
			temp.ShowCheckBox = amountToAllocate != 0;

			if (amountToAllocate < 0) {
				var whlCov = Number(temp.Plan.Coverage.toString().replaceAll(",", ""));
				temp.ShowCheckBox =
					whlCov > Number(temp.Plan.CurrentProduct.MinCoverage);

				if (temp.Plan.CurrentProduct.hasStateSpecificMinCoverage == true) {
					if (temp.Plan.CurrentProduct.StateSpecifiMinCoverage.length > 0) {
						var stateSpecificMinCoverage =
							temp.Plan.CurrentProduct.StateSpecifiMinCoverage.filter(
								(x: any) => {
									if (x.State == temp.Plan.State) return x;
								}
							);
						if (
							stateSpecificMinCoverage != undefined &&
							stateSpecificMinCoverage.length > 0
						) {
							var firstElem = stateSpecificMinCoverage[0];

							if (whlCov <= firstElem.MinCoverage) temp.ShowCheckBox = false;
						}
					}
				}
			} else if (amountToAllocate > 0) {
				var whlCov = (temp.ShowCheckBox = Number(
					temp.Plan.Coverage.toString().replaceAll(",", "")
				));
				temp.ShowCheckBox =
					whlCov < Number(temp.Plan.CurrentProduct.MaxCoverage);

				if (
					temp.Plan.CurrentProduct.hasSeperateMaxCoverageAgeSpecific == true &&
					temp.Plan.CurrentProduct.SpecificMaxConverage != undefined
				) {
					if (
						temp.Plan.CurrentProduct.SpecificMaxConverage != undefined &&
						this.Person.AgePerDOB >=
							temp.Plan.CurrentProduct.SpecificMaxConverage.MinAge &&
						this.Person.AgePerDOB <=
							temp.Plan.CurrentProduct.SpecificMaxConverage.MaxAge &&
						whlCov >= temp.Plan.CurrentProduct.SpecificMaxConverage.MaxCoverage
					) {
						temp.ShowCheckBox = false;
					}
				}
			}
			temp.Plan.Coverage = this.getCoverage(temp.Plan.Coverage);
			if (typeof temp.Plan.MDB == "string")
				temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
					Number(temp.Plan.MDB.replaceAll(",", "")).toFixed(2),
					true,
					true
				);
			else
				temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
					Number(temp.Plan.MDB).toFixed(2),
					true,
					true
				);
			this.allocatableSpouseProducts.push(temp);
		} else {
			this.allocatableSpouseProducts.push({ isEmpty: true });
		}
		this.showWHLBothCheckBox =
			this.allocatablePrimaryProducts[0].ShowCheckBox &&
			this.allocatableSpouseProducts[0].ShowCheckBox;

		var YRC1PrimaryProducts = this.$data.PrimaryProducts.filter((x: any) => {
			if (x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false)
				return x;
		});

		var showLegend = false;
		var showRCCheckBox = false;

		if (YRC1PrimaryProducts && YRC1PrimaryProducts.length > 0) {
			YRC1PrimaryProducts.forEach((element: any) => {
				var temp = JSON.parse(JSON.stringify(element));
				temp.Selected = false;
				temp.ShowCheckBox = amountToAllocate != 0;

				if (amountToAllocate < 0) {
					temp.ShowCheckBox =
						Number(temp.Plan.Coverage.toString().replaceAll(",", "")) >
						Number(temp.Plan.CurrentProduct.MinCoverage);
				} else if (amountToAllocate > 0) {
					temp.ShowCheckBox =
						Number(temp.Plan.Coverage.toString().replaceAll(",", "")) <
						Number(temp.Plan.CurrentProduct.MaxCoverage);
				}
				showRCCheckBox = temp.ShowCheckBox;
				if (temp.Plan.AddAnnualRate) showLegend = temp.Plan.AddAnnualRate;
				temp.Plan.Coverage = this.getCoverage(temp.Plan.Coverage);
				if (typeof temp.Plan.MDB == "string")
					temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
						Number(temp.Plan.MDB.replaceAll(",", "")).toFixed(2),
						true,
						true
					);
				else
					temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
						Number(temp.Plan.MDB).toFixed(2),
						true,
						true
					);
				this.allocatablePrimaryProducts.push(temp);
			});
		} else {
			this.allocatablePrimaryProducts.push({ isEmpty: true });
		}

		var YRC1SpouseProducts = this.$data.SpouseProducts.filter((x: any) => {
			if (x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false)
				return x;
		});

		var addRCProd = this.$data.SpouseAdditionalALPProducts.find(
			(x: any) =>
				x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false
		);

		if (addRCProd) YRC1SpouseProducts.push(addRCProd);

		if (YRC1SpouseProducts && YRC1SpouseProducts.length > 0) {
			YRC1SpouseProducts.forEach((element: any) => {
				var temp = JSON.parse(JSON.stringify(element));
				temp.Selected = false;
				temp.ShowCheckBox = amountToAllocate != 0;

				if (amountToAllocate < 0) {
					temp.ShowCheckBox =
						Number(temp.Plan.Coverage.toString().replaceAll(",", "")) >
						Number(temp.Plan.CurrentProduct.MinCoverage);
				} else if (amountToAllocate > 0) {
					temp.ShowCheckBox =
						Number(temp.Plan.Coverage.toString().replaceAll(",", "")) <
						Number(temp.Plan.CurrentProduct.MaxCoverage);
				}
				showRCCheckBox = showRCCheckBox && temp.ShowCheckBox;
				if (temp.Plan.AddAnnualRate) showLegend = temp.Plan.AddAnnualRate;
				temp.Plan.Coverage = this.getCoverage(temp.Plan.Coverage);
				if (typeof temp.Plan.MDB == "string")
					temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
						Number(temp.Plan.MDB.replaceAll(",", "")).toFixed(2),
						true,
						true
					);
				else
					temp.Plan.MDB = ConvertIntoAmountFormatWithDecimal(
						Number(temp.Plan.MDB).toFixed(2),
						true,
						true
					);
				this.allocatableSpouseProducts.push(temp);
			});
		} else {
			this.allocatableSpouseProducts.push({ isEmpty: true });
			showRCCheckBox = false;
		}

		this.show10YRCBothCheckBox = showRCCheckBox;

		this.showAnnualRateLegend = showLegend;

		this.allocateremaining = true;
	}

	showProtectionsPopUP(event: any) {
		if (this.Protections.length > 0) {
			LoggingHelper.getInstance().LogEvent(
				LoggingConstants.ProtectionPopupOpened
			);
			this.$data.showProtections = true;
		} else {
			this.$store.state.messageText = "Please select Market Type first.";
			this.$store.state.showMessage = true;
			this.$data.showProtections = false;
		}
	}

	changeAddMBDForSelectedAllocatableProducts() {
		var RemainingAllocation: number = 0.0;
		this.allocatablePrimaryProducts.forEach((element: any) => {
			element.AdditionalMBD = 0;
		});

		this.allocatableSpouseProducts.forEach((element: any) => {
			element.AdditionalMBD = 0;
			element.IsSpouse = true;
		});

		var primarySelectedProducts = this.allocatablePrimaryProducts.filter(
			(x: any) => x.Selected == true
		);

		var spouseSelectedProducts = this.allocatableSpouseProducts.filter(
			(x: any) => x.Selected == true
		);

		this.selectedAllocatableProducts = [];
		if (primarySelectedProducts) {
			this.selectedAllocatableProducts.push(...primarySelectedProducts);
		}
		if (spouseSelectedProducts) {
			this.selectedAllocatableProducts.push(...spouseSelectedProducts);
		}

		if (
			!this.selectedAllocatableProducts ||
			this.selectedAllocatableProducts.length == 0
		)
			return;

		var additionalMBDToAdd = 0;
		this.allocatableAmount = Number(this.PremiumPlan.RemainingMonthlyPremium);

		if (this.allocatableAmount < 0) {
			additionalMBDToAdd = Number(
				(Number(this.allocatableAmount) * -1) /
					this.selectedAllocatableProducts.length
			);
		} else {
			additionalMBDToAdd = Number(
				Number(this.allocatableAmount) / this.selectedAllocatableProducts.length
			);
		}

		if (this.allocatableAmount < 0) {
			RemainingAllocation = Number(
				(
					Number(this.PremiumPlan.RemainingMonthlyPremium) +
					Number(
						(
							Number(additionalMBDToAdd.toFixed(2)) *
							this.selectedAllocatableProducts.length
						).toFixed(2)
					)
				).toFixed(2)
			);
		} else {
			RemainingAllocation = Number(
				(
					Number(this.PremiumPlan.RemainingMonthlyPremium) -
					Number(
						(
							Number(additionalMBDToAdd.toFixed(2)) *
							this.selectedAllocatableProducts.length
						).toFixed(2)
					)
				).toFixed(2)
			);
		}
		this.selectedAllocatableProducts.forEach((element: any) => {
			if (!element.isEmpty)
				element.AdditionalMBDString = ConvertIntoAmountFormatWithDecimal(
					Number(additionalMBDToAdd + RemainingAllocation)
						.toFixed(2)
						.toString()
				);

			element.AdditionalMBD = additionalMBDToAdd + RemainingAllocation;
			if (RemainingAllocation != 0) {
				RemainingAllocation = 0;
			}
			//  ConvertIntoAmountFormatWithDecimal(
			//   additionalMBDToAdd.toString()
			// );
		});
	}

	whlBothCheckboxChanged(event: any) {
		this.allocatablePrimaryProducts.forEach((element: any) => {
			if (element.IsWHL && element.ShowCheckBox) {
				element.Selected = event;
			}
		});

		this.allocatableSpouseProducts.forEach((element: any) => {
			if (element.IsWHL && element.ShowCheckBox) {
				element.Selected = event;
			}
		});
		this.changeAddMBDForSelectedAllocatableProducts();
	}

	yrcBothCheckboxChanged(event: any) {
		this.allocatablePrimaryProducts.forEach((element: any) => {
			if (!element.IsWHL && element.ShowCheckBox) {
				element.Selected = event;
			}
		});

		this.allocatableSpouseProducts.forEach((element: any) => {
			if (!element.IsWHL && element.ShowCheckBox) {
				element.Selected = event;
			}
		});
		this.changeAddMBDForSelectedAllocatableProducts();
	}

	allocateRemainingMBD() {
		var selectedProducts = this.selectedAllocatableProducts;

		var add = this.ShowSuccessorErrorText == "success--text";
		var isPrimaryWHLSelected: any = {};
		var isPrimaryRC1Selected: any = {};
		var isPrimaryRC2Selected: any = {};
		this.$data.PrimaryProducts.forEach((element: any) => {
			if (element.IsWHL) {
				isPrimaryWHLSelected = selectedProducts.find(
					(x: any) => x.IsWHL && !x.IsSpouse
				);

				if (isPrimaryWHLSelected) {
					{
						element.AllocStatus = this.addRemoveMBD(
							add,
							element,
							isPrimaryWHLSelected.AdditionalMBD
						);
					}
				}
			} else {
				isPrimaryRC1Selected = selectedProducts.find(
					(x: any) =>
						x.ProductId == 6 && x.PresentAsDefaultId == 3 && !x.IsSpouse
				);

				if (
					isPrimaryRC1Selected &&
					element.ProductId == 6 &&
					element.PresentAsDefaultId == 3
				) {
					element.AllocStatus = this.addRemoveMBD(
						add,
						element,
						isPrimaryRC1Selected.AdditionalMBD
					);
				}
				isPrimaryRC2Selected = selectedProducts.find(
					(x: any) =>
						x.ProductId == 6 && x.PresentAsDefaultId == 4 && !x.IsSpouse
				);
				if (
					isPrimaryRC2Selected &&
					element.ProductId == 6 &&
					element.PresentAsDefaultId == 4
				) {
					element.AllocStatus = this.addRemoveMBD(
						add,
						element,
						isPrimaryRC2Selected.AdditionalMBD
					);
				}
			}
		});
		var isSpouseWHLSelected: any = {};
		var isSpouseRC1Selected: any = {};
		var isSpouseRC2Selected: any = {};
		this.$data.SpouseProducts.forEach((element: any) => {
			if (element.IsWHL) {
				isSpouseWHLSelected = selectedProducts.find(
					(x: any) => x.IsWHL && x.IsSpouse
				);

				if (isSpouseWHLSelected) {
					element.AllocStatus = this.addRemoveMBD(
						add,
						element,
						isSpouseWHLSelected.AdditionalMBD
					);
				}
			} else {
				isSpouseRC1Selected = selectedProducts.find(
					(x: any) =>
						x.ProductId == 6 && x.PresentAsDefaultId == 3 && x.IsSpouse
				);

				if (
					isSpouseRC1Selected &&
					element.ProductId == 6 &&
					element.PresentAsDefaultId == 3
				) {
					element.AllocStatus = this.addRemoveMBD(
						add,
						element,
						isSpouseRC1Selected.AdditionalMBD
					);
				}
			}
		});

		isSpouseRC2Selected = selectedProducts.find(
			(x: any) => x.ProductId == 6 && x.PresentAsDefaultId == 4 && x.IsSpouse
		);

		var spouseAdditionalProduct = this.$data.SpouseAdditionalALPProducts.find(
			(x: any) => x.ProductId == 6
		);
		if (isSpouseRC2Selected) {
			isSpouseRC2Selected.AllocStatus = this.addRemoveMBD(
				add,
				spouseAdditionalProduct,
				isSpouseRC2Selected.AdditionalMBD
			);
		}

		this.$refs.primaryPlanBox.forEach((element: any) => {
			var notify = false;
			if (element.$data.PlanData.IsWHL && isPrimaryWHLSelected) {
				notify = true;
			} else if (element.$data.PlanData.ProductId == 6) {
				if (
					element.$data.PlanData.PresentAsDefaultId == 3 &&
					isPrimaryRC1Selected
				) {
					notify = true;
				} else if (
					element.$data.PlanData.PresentAsDefaultId == 4 &&
					isPrimaryRC2Selected
				) {
					notify = true;
				}
			}
			if (notify == true) {
				element.MBDChangeExt(
					element.$data.PlanData.Plan.MDB,
					element.$data.PlanData.AllocStatus
				);
				element.CheckDefaultCoverage();
			}
		});
		this.$refs.spousePlanBox.forEach((element: any) => {
			var notify = false;
			if (element.PlanData.IsWHL && isSpouseWHLSelected) {
				notify = true;
			} else if (element.PlanData.ProductId == 6 && isSpouseRC1Selected) {
				notify = true;
			}

			if (notify == true) {
				// element.OnMDBChange();
				// element.MBDChangeEvent();
				element.MBDChangeExt(
					element.$data.PlanData.Plan.MDB,
					element.$data.PlanData.AllocStatus
				);
				element.CheckDefaultCoverage();
			}
		});

		if (this.$refs.spouseAddPlanBox) {
			this.$refs.spouseAddPlanBox.forEach((element: any) => {
				var notify = false;
				if (element.PlanData.ProductId == 6 && isSpouseRC2Selected) {
					notify = true;
				}

				if (notify == true) {
					element.OnMDBChange();
					element.MBDChangeEvent();
					element.CheckDefaultCoverage();
				}
			});
		}
		if (
			this.PremiumPlan.RemainingMonthlyPremium != "0.00" &&
			this.reallocatecounter < 5
		) {
			this.reallocatecounter = this.reallocatecounter + 1;
			this.changeAddMBDForSelectedAllocatableProducts();
			this.allocateRemainingMBD();
		} else {
			this.reallocatecounter = 0;
		}
		this.loadAllocateRemainingPremiumPopup();
		LoggingHelper.getInstance().LogEvent(
			LoggingConstants.AllocateRemainingPerformed
		);
	}

	addRemoveMBD(add: boolean, element: any, mbdToAddRemove: any) {
		if (add) {
			element.Plan.MDB = (
				Number(element.Plan.MDB) + Number(mbdToAddRemove)
			).toFixed(2);
			return "positive";
		} else {
			if (Number(mbdToAddRemove) > Number(element.Plan.MDB)) {
				{
					element.Plan.MDB = 0;
					return "negative";
				}
			} else {
				element.Plan.MDB = (
					Number(element.Plan.MDB) - Number(mbdToAddRemove)
				).toFixed(2);
				return "negative";
			}
		}
	}

	loadProtectionsPerPresentationType(presId: number) {
		this.Protections = [];
		var pres = this.prePlanPresentationTypes.find((x: any) => x.Id == presId);
		if (!pres || !pres.Protections) return;
		this.Protections = JSON.parse(JSON.stringify(pres.Protections));
	}

	onPasteAge(evt: any) {
		var log = evt.clipboardData.getData("text/plain");
		var hasDot = log.includes(".");
		if (hasDot) {
			evt.preventDefault();
			return false;
		} else {
			return true;
		}
	}

	mounted() {
		this.$data.addlanguageclasses = this.$i18n.locale != "en";
	}

	created() {
		this.$store.state.loader = true;
		this.pageLoadedTime = new Date();
		this.Person = PrimaryPerson.query().withAllRecursive().last();
		this.Presentation = Presentation.query().withAllRecursive().last();
		this.isPrePlan = Boolean(this.$route.params.initiatePrePlan);
		this.IsMexicanStateSelected = this.Presentation?.State == "New Mexico";
		EventBusLayout.$on("RoutedToEvt", this.PlanGeneratorRoutedToEvt);
		EventBusLayout.$on("RouteWithParams", this.PlanGeneratorRouteWithParams);
		EventBusLayout.$on("SavePrePlan", this.PlanGeneratorSavePrePlan);
		EventBusLayout.$on("Action", this.PlanGeneratorAction);
		this.FetchMaterialForReferral();
		if (this.isPrePlan) {
			var loadplanfromdb = Boolean(this.$route.params.loadplanfromdb);
			if (loadplanfromdb) {
				this.loadPrePlan(this.$route.params.preplan);
			} else {
				this.loadPrePlan();
			}
			this.apiCalls();
			return;
		}

		var _keydownCallback = (event: any) => {
			if (event.code == "Escape") {
				this.addtionalproducts = false;
			}
		};

		document.addEventListener("keyup", _keydownCallback);

		if (!this.isPrePlan) {
			window.addEventListener("beforeunload", this.onCloseBrowser);
		}

		this.initiatePlanGenerator();
		EventBusLayout.$emit(
			"PlanGeneratorProductsSelected",
			this.AnyProductSelected
		);
		this.apiCalls();
	}

	onCloseBrowser(event: any) {
		this.browserCloseEvent = true;
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.RoutedTo("/finishpresentation");
			}
		}
		event.preventDefault();
		event.returnValue = "";
	}
	PlanGeneratorRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	PlanGeneratorRouteWithParams(obj: any) {
		this.RoutedTo("", obj);
	}
	PlanGeneratorSavePrePlan(name: any) {
		this.SavePrePlan(name, true);
	}
	PlanGeneratorAction(action: any) {
		this.Action(action);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.PlanGeneratorRoutedToEvt);
		EventBusLayout.$off("RouteWithParams", this.PlanGeneratorRouteWithParams);
		EventBusLayout.$off("SavePrePlan", this.PlanGeneratorSavePrePlan);
		EventBusLayout.$off("Action", this.PlanGeneratorAction);
	}

	private allProducts: any = {};
	private allMatchingproducts: any = [];

	FetchMaterialForReferral() {
		getPersistedState("Materials").then((res: any) => {
			this.allProducts = res;
			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined ||
							x.CountryId == this.Presentation.CountryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == this.Presentation.PresentationTypeId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);
		});
	}

	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		var RefInit: any = {};
		if (this.Presentation.Referrals) {
			RefInit = JSON.parse(this.Presentation.Referrals);
		} else {
			RefInit = {
				ReferredBy: "",
				ReferredByLastName: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: "",
				ReferralsList: [],
				CCEmail: [],
			};
		}

		if (RefInit.ReferralsList.length > 0) {
			RefInit.ReferralsList.forEach((referral: any) => {
				referral.StateObj = this.lstStates.find(
					(x: any) => x.StateCode == referral.State
				);
			});
		}

		if (RefInit.ReferredBy == undefined || RefInit.ReferredBy == "")
			RefInit.ReferredBy = this.Person?.FirstName;

		if (
			RefInit.ReferredByLastName == undefined ||
			RefInit.ReferredByLastName == ""
		)
			RefInit.ReferredByLastName = this.Person?.LastName;
		if (RefInit.Phone == undefined || RefInit.Phone == "")
			RefInit.Phone = this.Person?.Phone;
		if (RefInit.Email == undefined || RefInit.Email == "")
			RefInit.Email = this.Person?.Email;
		if (
			this.Presentation.PresentedGroup != undefined &&
			this.Presentation.PresentedGroup != ""
		)
			RefInit.Organization = this.Presentation.PresentedGroup;
		if (RefInit.Date == undefined || RefInit.Date == "")
			RefInit.Date = moment(new Date()).format("MM/DD/YYYY");

		this.$data.Referrals = RefInit;
		if (newValue == true) {
			this.ResetReferrals = !this.ResetReferrals;
		}
	}

	CloseReferralPopup() {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}

	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;

		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;

		if (referral.City != null && referral.City.trim() != "") return false;

		if (referral.State != null && referral.State.trim() != "") return false;

		if (referral.Phone != null && referral.Phone.trim() != "") return false;

		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;

		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;

		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;

		return true;
	}

	SaveReferral(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	SaveReferralsWithoutClosingPopup(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	apiCalls() {
		var self = this;
		var productsListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.ProductsListEndpoint,
			[],
			IndexDBConstants.ProductsListKey
		);

		var ratesListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.RatesListEndpoint,
			[],
			IndexDBConstants.RatesListKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);

		var presentationTypeList = GetLocalDataOrCallAPI(
			ApiEndPointConstants.PresentationTypesEndpoint,
			[],
			IndexDBConstants.PresentationTypesKey
		);

		Promise.all([
			productsListPromise,
			ratesListPromise,
			stateListPromise,
			presentationTypeList,
		]).then((values) => {
			self.AllProducts = JSON.parse(
				JSON.stringify(require("../assets/pre-fill-data/product-list.json"))
			)
				.filter(
					(x: any) =>
						x.ShowInRateBookOnly == undefined || x.ShowInRateBookOnly == false
				)
				.sort(this.compareProduct);
			self.RateList = values[1];
			self.lstStates = values[2];
			self.prePlanStates = values[2];
			self.prePlanPresentationTypes = values[3];
			self.prePlanPresentationTypes.splice(
				self.prePlanPresentationTypes.indexOf(
					self.prePlanPresentationTypes.filter((x: any) => x.Name == "PRO LEAD")
				),
				1
			);

			if (
				this.Presentation.Protections &&
				this.Presentation.Protections != ""
			) {
				this.Protections = JSON.parse(this.Presentation.Protections);
			} else {
				this.loadProtectionsPerPresentationType(
					this.Presentation.PresentationTypeId
				);
			}
			setTimeout(() => {
				this.$refs.presPlanbox.forEach((element: any) => {
					element.loadCreated();
				});
				this.$refs.primaryPlanBox.forEach((element: any) => {
					element.loadCreated();
				});
				this.$refs.spousePlanBox.forEach((element: any) => {
					element.loadCreated();
				});

				this.$refs.primaryAHPPlanBox.forEach((element: any) => {
					element.loadCreated();
				});

				this.$refs.primaryALPPlanBox.forEach((element: any) => {
					element.loadCreated();
				});

				this.$refs.spouseAHPPlanBox.forEach((element: any) => {
					element.loadCreated();
				});

				this.$refs.spouseAddPlanBox.forEach((element: any) => {
					element.loadCreated();
				});
				if (
					!(this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) &&
					this.planloaded >= 0
				) {
					this.$store.state.loader = false;
				}
			}, 10);
		});
	}

	compareProduct(a: any, b: any) {
		if (a.SortOrder < b.SortOrder) return -1;
		if (a.SortOrder > b.SortOrder) return 1;
		return 0;
	}

	loadPrePlan(preplan: any = null) {
		this.$data.Plans = [
			{
				Id: 1,
				IsChecked: true,
				Name: "PLAN OPTION 1",
				divid: "planopt1",
				isSelected: true,
			},
			{
				Id: 2,
				IsChecked: false,
				Name: "PLAN OPTION 2",
				divid: "planopt2",
				isSelected: false,
				IsCloned: false,
			},
			{
				Id: 3,
				IsChecked: false,
				Name: "PLAN OPTION 3",
				divid: "planopt3",
				isSelected: false,
				IsCloned: false,
			},
			{
				Id: 4,
				IsChecked: false,
				Name: "PLAN OPTION 4",
				divid: "planopt4",
				isSelected: false,
				IsCloned: false,
			},
		];

		this.planInitialized = false;

		setTimeout(() => {
			if (preplan) {
				if (typeof preplan.State == "string") {
					this.$data.prePlanSelectedState = preplan.State;
					this.IsMexicanStateSelected = preplan.State == "NM";
				} else {
					this.$data.prePlanSelectedState = preplan.State.StateCode;
					this.IsMexicanStateSelected = preplan.State.StateCode == "NM";
				}
			} else {
				this.$data.prePlanSelectedState = null;
			}
		}, 500);
		this.prePlanSelectedPresenation = preplan ? preplan.PresentationType : null;
		this.prePlanPresentationTypes = this.prePlanPresentationTypes.filter(
			(x: any) => x.id != 13
		);
		if (preplan) {
			this.Person = new PrimaryPerson(preplan.Person);
			this.Presentation = JSON.parse(preplan.Presentation);
			this.Presentation.PlanOptions = preplan.Plans;
			this.Presentation.Protections = preplan.Protections;
		} else {
			this.Person = new PrimaryPerson();
			this.Person.Premium = 0;
			this.Person.Wage = "25.00";
			//this.Person.AnnualIncome = "39988";
			//this.Person.MonthlyRent = "3333.333333333333333";
			this.Person.Spouse = new Spouse();
			this.Person.Spouse.Premium = 0;
			this.Person.Spouse.Wage = "25.00";
			//this.Person.Spouse.AnnualIncome = "39988";
			this.Presentation = new Presentation();
			this.Presentation.PremiumTypeId = 1;
			this.Presentation.Premium = 0;

			this.Childrens = [];
			this.wageChanged(false);
		}

		this.initiatePlanGenerator();
	}

	usePrePlan() {
		this.SavePrePlan(false);

		var prms: any = { initiatePrePlan: true };
		this.$router.push({ name: "StartPresentation", params: prms });
		LoggingHelper.getInstance().LogEvent(LoggingConstants.PreplanUsed);
	}

	SavePrePlan(event: any = null, includetimout = false) {
		this.$store.state.loader = true;
		setTimeout(
			() => {
				this.saveCurrentSelectedPlan();
				let medQuesList = require("../assets/pre-fill-data/medical-question-NA.json");

				var medQuestions = medQuesList.filter(
					(x: any) => x.Type == "supercombo"
				);

				medQuestions.forEach((question: any) => {
					if (question.QuestionEn.toString().includes("TOBACCO")) {
						question.PrimaryValue = this.Person.AnyTobaccoUse;
						question.SpouseValue = this.Person.Spouse.AnyTobaccoUse;
					}
				});

				this.Presentation.MedicalQuestionsNA = JSON.stringify(medQuestions);
				this.Presentation.CurrentPlan = this.$data.CurrentSelectedPlan.Name;
				if (this.Childrens && this.Childrens.length > 0)
					this.Person.ChildrenString = JSON.stringify(this.Childrens);
				if (this.prePlanSelectedPresenation)
					this.Presentation.PresentationTypeId =
						this.prePlanSelectedPresenation.Id;
				var prePlanData = {
					Presentation: JSON.stringify(this.Presentation),
					Person: this.Person,
					Spouse: this.Person.Spouse,
					Protections: JSON.stringify(this.Protections),
					Plans: JSON.stringify(this.$data.Plans),
					PresentationType: this.prePlanSelectedPresenation,
					State: this.$data.prePlanSelectedState,
				};

				let saveData = new GenericKeyPair(
					"PrePlan",
					JSON.stringify(prePlanData)
				);
				setPersistedState(saveData);

				if (event) {
					this.$router.push({
						name: event,
						params: {
							initiatePrePlan: "true",
							ExcludeFromPresentationCycle: "true",
							preplan: JSON.stringify(prePlanData),
						},
					});
				}
				this.$store.state.loader = false;
			},
			includetimout ? 1000 : 0
		);
	}

	@Watch("Person.Wage")
	OnPersonWageChanged() {
		this.Person.AnnualIncome = this.getAnnualIncomeAsPerWageType(
			this.Person.Wage
		);
	}

	@Watch("Person.Spouse.Wage")
	OnSpouseWageChanged() {
		this.Person.Spouse.AnnualIncome = this.getAnnualIncomeAsPerWageType(
			this.Person.Spouse.Wage
		);
	}

	getAnnualIncomeAsPerWageType(wage: any) {
		var wageNumber = 0;
		if (wage) wageNumber = Number(wage.toString().replaceAll(",", ""));
		switch (this.SelectedWageType.Id) {
			case 1:
				wageNumber = wageNumber * 8 * 5 * 4.3333 * 12;
				break;
			case 2:
				wageNumber = wageNumber * 4.3333 * 12;
				break;
			case 3:
				wageNumber = wageNumber * 12;
				break;
			case 4:
				wageNumber = wageNumber;
				break;
		}

		return ConvertIntoAmountFormatWithDecimal(wageNumber.toFixed(2).toString());
	}

	wageChanged(fromUI: boolean, fieldName: string = "") {
		if (fieldName == "Person.Wage") {
			this.primaryWageValue = null;
			this.Person.Wage = ConvertIntoAmountFormatWithDecimal(this.Person.Wage);
		}
		if (fieldName == "Person.Spouse.Wage") {
			this.spouseWageValue = null;
			this.Person.Spouse.Wage = ConvertIntoAmountFormatWithDecimal(
				this.Person.Spouse.Wage
			);
		}

		if (fromUI && this.Presentation != undefined) {
			this.Presentation.WageValuesCustomized = true;
		}
		// if (
		//   (!this.isPrePlan && this.PremiumPlan.PremiumTypeId != 4) ||
		//   !this.SelectedWageType
		// )
		//   return;
		var wagePrimary = Number(this.Person.Wage.replaceAll(",", ""));
		var wageSpouse = Number(this.Person.Spouse.Wage.replaceAll(",", ""));
		var premiumPrimary = 0;
		var premiumSpouse = 0;
		switch (this.SelectedWageType.Id) {
			case 1:
				premiumPrimary = Number(wagePrimary);
				premiumSpouse = Number(wageSpouse);
				break;
			case 2:
				premiumPrimary = Number(wagePrimary) / (8 * 5);
				premiumSpouse = Number(wageSpouse) / (8 * 5);
				break;
			case 3:
				premiumPrimary = Number(wagePrimary) / (8 * 5 * 4.3333);
				premiumSpouse = Number(wageSpouse) / (8 * 5 * 4.3333);
				break;
			case 4:
				premiumPrimary = Number(wagePrimary) / (8 * 5 * 4.3333 * 12);
				premiumSpouse = Number(wageSpouse) / (8 * 5 * 4.3333 * 12);
				break;
		}

		if (isNaN(premiumPrimary)) premiumPrimary = 0;
		if (isNaN(premiumSpouse)) premiumSpouse = 0;
		this.Person.Premium = premiumPrimary;
		this.Person.MonthlyPremium = (premiumPrimary * 4.3333).toFixed(2);
		this.Person.Spouse.Premium = premiumSpouse;
		this.Person.Spouse.MonthlyPremium = (premiumSpouse * 4.3333).toFixed(2);

		// var dollarADayCombAmt = this.Person.DollarADayAmount;

		// if (this.Person.isSpouseForDADExists)
		//   dollarADayCombAmt += this.Person.Spouse.DollarADayAmount;

		if (!this.PremiumPlan) {
			this.PremiumPlan = new PremiumPlan(
				this.Person.Premium != undefined ? this.Person.Premium : 0,
				this.Person.Spouse.Premium != undefined
					? this.Person.Spouse.Premium
					: 0,
				this.Person.MonthlyPremium != undefined
					? Number(this.Person.MonthlyPremium)
					: 0,
				this.Person.Spouse.MonthlyPremium != undefined
					? Number(this.Person.Spouse.MonthlyPremium)
					: 0,
				this.Person.DollarADayAmount,
				this.Person.Spouse.DollarADayAmount,
				this.Person.isSpouseForDADExists
			);
		} else {
			//this.OnWageChanged(this.SelectedWageType);
			var perHourPrimaryValue = wagePrimary / this.SelectedWageType.HourValue;
			var perHourSpouseValue = wageSpouse / this.SelectedWageType.HourValue;
			this.personYearlySalary = perHourPrimaryValue * 2080;
			this.spouseYearlySalary = perHourSpouseValue * 2080;
		}

		if (this.PremiumPlan.PremiumTypeId == 4)
			this.SetPremiumPlanAnnualIncome(false);

		//if (this.isPrePlan) {
		this.PremiumPlan.ChangePremiumPlan(
			this.Person.Premium,
			this.Person.Spouse.Premium,
			this.Person.isSpouseForDADExists
		);
		this.PremiumPlan.ChangePremiumPlanMonthly(
			this.Person.MonthlyPremium != undefined
				? Number(this.Person.MonthlyPremium.replaceAll(",", ""))
				: 0,
			this.Person.Spouse.MonthlyPremium != undefined
				? Number(this.Person.Spouse.MonthlyPremium.replaceAll(",", ""))
				: 0,
			this.Person.isSpouseForDADExists
		);

		if (fromUI) {
			if (fieldName == "Person.Wage") {
				this.Person.AnnualIncome = this.personYearlySalary;
			}
			if (fieldName == "Person.Spouse.Wage") {
				this.Person.Spouse.AnnualIncome = this.spouseYearlySalary;
			}
		}
	}

	SpouseDetailChange() {
		if (this.PremiumPlan != undefined) {
			this.PremiumPlan.ChangePremiumPlan(
				this.Person.Premium,
				this.Person.Spouse.Premium,
				this.Person.isSpouseForDADExists
			);
			this.PremiumPlan.ChangePremiumPlanMonthly(
				this.Person.MonthlyPremium != undefined
					? Number(this.Person.MonthlyPremium.replaceAll(",", ""))
					: 0,
				this.Person.Spouse.MonthlyPremium != undefined
					? Number(this.Person.Spouse.MonthlyPremium.replaceAll(",", ""))
					: 0,
				this.Person.isSpouseForDADExists
			);
			// this.PremiumPlan.ChangePremiumPlanMonthly(
			//   Number(this.Person.MonthlyPremium),
			//   Number(this.Person.Spouse.MonthlyPremium),
			//   this.Person.isSpouseForDADExists
			// );
		}
	}

	get SpouseRiderIntedependebility() {
		return (
			this.$data.SpouseProducts[0].Selected ||
			this.$data.SpouseProducts[1].Selected ||
			this.$data.SpouseAdditionalALPProducts[0].Selected
		);
	}

	prePlanPresTypeChanged() {
		this.loadProtectionsPerPresentationType(this.prePlanSelectedPresenation.Id);

		if (this.prePlanSelectedPresenation.DefaultPremiumTypeId != undefined) {
			this.SelectedPremiumType = this.PremiumApproaches.filter((x: any) => {
				if (x.id == this.prePlanSelectedPresenation.DefaultPremiumTypeId)
					return x;
			})[0];
			//this.SetPremiumPlanAnnualIncome(true);
			if (
				this.PremiumPlan != undefined &&
				this.SelectedPremiumType != undefined
			) {
				this.PremiumPlan.PremiumTypeId = this.SelectedPremiumType.id;
				this.PremiumApproachChange(this.SelectedPremiumType);
			}
		}
	}

	prePlanStateChanged() {
		this.Presentation.State = this.$data.prePlanSelectedState.Name;
		this.IsMexicanStateSelected =
			this.$data.prePlanSelectedState.Name == "New Mexico";
		this.$refs.presPlanbox.forEach((element: any) => {
			element.$data.PlanData.Plan.State =
				this.$data.prePlanSelectedState.StateCode;
			element.checkProductAvailable();
		});
		this.$refs.primaryPlanBox.forEach((element: any) => {
			element.$data.PlanData.Plan.State =
				this.$data.prePlanSelectedState.StateCode;
			element.checkProductAvailable();
		});
		this.$refs.spousePlanBox.forEach((element: any) => {
			element.$data.PlanData.Plan.State =
				this.$data.prePlanSelectedState.StateCode;
			element.checkProductAvailable();
		});
	}

	get IsPrimaryHonorableDischarged() {
		if (this.Presentation != null && this.Presentation != undefined) {
			return IsPrimaryVeteranAndHonorablyDischarged(
				this.Presentation.FamilyCareSurvey,
				this.Presentation.PresentationWillKitVeterans
			);
		}
		return false;
	}
	get IsSpouseHonorableDischarged() {
		if (this.Presentation != null && this.Presentation != undefined) {
			return IsSpouseVeteranAndHonorablyDischarged(
				this.Presentation.FamilyCareSurvey,
				this.Presentation.PresentationWillKitVeterans
			);
		}
		return false;
	}

	get PrimaryFuneralType() {
		var FuneralType = "Burial";
		if (
			this.Presentation != undefined &&
			this.Presentation.FamilyCareSurvey != undefined &&
			this.Presentation.FamilyCareSurvey != ""
		) {
			var FamilyCare = JSON.parse(this.Presentation.FamilyCareSurvey);
			return (FuneralType =
				FamilyCare.PrimaryFuneralType == "Cremation" ||
				FamilyCare.PrimaryFuneralType == "Other"
					? "Cremation"
					: "Burial");
		}

		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationWillKitVeterans != undefined &&
			this.Presentation.PresentationWillKitVeterans != ""
		) {
			var WillKit = JSON.parse(this.Presentation.PresentationWillKitVeterans);
			return (FuneralType =
				WillKit.FuneralType == "Cremation" || WillKit.FuneralType == "Other"
					? "Cremation"
					: "Burial");
		}
		return FuneralType;
	}

	get SpouseFuneralType() {
		var FuneralType = "Burial";
		if (
			this.Presentation != undefined &&
			this.Presentation.FamilyCareSurvey != undefined &&
			this.Presentation.FamilyCareSurvey != ""
		) {
			var FamilyCare = JSON.parse(this.Presentation.FamilyCareSurvey);
			return (FuneralType =
				FamilyCare.SpouseFuneralType == "Cremation" ||
				FamilyCare.SpouseFuneralType == "Other"
					? "Cremation"
					: "Burial");
		}
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationWillKitVeterans != undefined &&
			this.Presentation.PresentationWillKitVeterans != ""
		) {
			var WillKit = JSON.parse(this.Presentation.PresentationWillKitVeterans);
			return (FuneralType =
				WillKit.SpouseFuneralType == "Cremation" ||
				WillKit.SpouseFuneralType == "Other"
					? "Cremation"
					: "Burial");
		}
		return FuneralType;
	}

	CoverageValue(planId: any, isPrimary: boolean) {
		if (isPrimary) {
			return Number(
				CalculateCoveregeOnSubType(
					this.IsPrimaryHonorableDischarged,
					this.PrimaryFuneralType,
					planId,
					this.Person.AnnualExpense,
					this.Presentation.State
				)
			);
		} else {
			return Number(
				CalculateCoveregeOnSubType(
					this.IsSpouseHonorableDischarged,
					this.SpouseFuneralType,
					planId,
					this.Person.AnnualExpense,
					this.Presentation.State
				)
			);
		}

		return 0;
	}

	@Watch("planloaded")
	OnplanloadedChange(newValue: any, oldValue: any) {
		if (newValue == 0) {
			this.$data.Plans[0].PrimaryProducts[0].Coverage = this.CoverageValue(
				this.$data.Plans[0].Id,
				true
			);
			this.$data.Plans[0].PrimaryProducts[0].Plan.Coverage = this.CoverageValue(
				this.$data.Plans[0].Id,
				true
			);

			if (this.Person.isSpouseExists) {
				this.$data.Plans[0].SpouseProducts[0].Coverage = this.CoverageValue(
					this.$data.Plans[0].Id,
					false
				);
				this.$data.Plans[0].SpouseProducts[0].Plan.Coverage =
					this.CoverageValue(this.$data.Plans[0].Id, false);
			}
			this.loadPlan(this.$data.Plans[0]);
		} else if (newValue == 1) {
			this.$data.Plans[0].isSelected = false;
			this.$data.Plans[2].isSelected = true;
			this.$data.Plans[2].PrimaryProducts[0].Coverage = this.CoverageValue(
				this.$data.Plans[2].Id,
				true
			);
			this.$data.Plans[2].PrimaryProducts[0].Plan.Coverage = this.CoverageValue(
				this.$data.Plans[2].Id,
				true
			);

			if (this.Person.isSpouseExists) {
				this.$data.Plans[2].SpouseProducts[0].Coverage = this.CoverageValue(
					this.$data.Plans[2].Id,
					false
				);
			}

			this.loadPlan(this.$data.Plans[2]);
		} else if (newValue == 2) {
			this.$data.Plans[2].isSelected = false;
			this.$data.Plans[1].isSelected = true;
			this.$data.Plans[1].PrimaryProducts[0].Coverage = this.CoverageValue(
				this.$data.Plans[1].Id,
				true
			);

			if (this.Person.isSpouseExists) {
				this.$data.Plans[1].SpouseProducts[0].Coverage = this.CoverageValue(
					this.$data.Plans[1].Id,
					false
				);
			}
			this.loadPlan(this.$data.Plans[1]);
		} else if (newValue > 2) {
			this.$store.state.loader = false;
		}
	}

	initiatePlanGenerator() {
		if (
			this.Person.ChildrenString != undefined &&
			this.Person.ChildrenString != ""
		) {
			this.Childrens = JSON.parse(this.Person.ChildrenString);
		}
		if (this.Childrens == null || this.Childrens.length < 1) {
			this.Childrens.push(
				new Children(),
				new Children(),
				new Children(),
				new Children()
			);
		}

		if (this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) {
			if (
				this.Presentation.PlanOptions &&
				!this.Presentation.isPlanCalculationNeeded
			) {
				this.$data.Plans = JSON.parse(this.Presentation.PlanOptions);
			} else {
				if (
					this.Presentation.PlanOptions &&
					this.Presentation.isPlanCalculationNeeded
				) {
					console.log("Plans Calculated");
					this.Presentation.isPlanCalculationNeeded = false;
					this.Presentation.PlanOptions = null;
					this.Presentation.CurrentPlan = "";
					this.Presentation.PremiumPlan = null;
					this.Presentation.PremiumPlanEntity_Id = null;
				} else {
					this.Presentation.isPlanCalculationNeeded = false;
				}
				if (this.planloaded == -1) {
					if (
						this.Person.HasChildrenUnder18 ||
						this.Person.isSpouseExistForBS
					) {
						this.$data.Plans = JSON.parse(JSON.stringify(this.SmbPriSPCPlan));
					} else {
						this.$data.Plans = JSON.parse(JSON.stringify(this.SmbPrimaryPlan));
					}
					let oldageplan = null;
					if (
						this.Person.AgePerDOB >= 60 ||
						(this.Person.isSpouseExists && this.Person.Spouse.AgePerDOB >= 60)
					) {
						if (
							this.Presentation.State != undefined &&
							this.Presentation.State != ""
						) {
							if (this.LeswlAllowedStates.includes(this.Presentation.State)) {
								oldageplan = this.SmbSrgwlAndLeswl[1];
							} else {
								oldageplan = this.SmbSrgwlAndLeswl[0];
							}
						} else {
							oldageplan = this.SmbSrgwlAndLeswl[0];
						}

						for (let index = 0; index < 3; index++) {
							if (
								this.Person.AgePerDOB >= 60 &&
								this.CoverageValue(this.$data.Plans[index].Id, true) <= 34999
							) {
								console.log("PrimaryPlanUpdated{0}", index);
								this.$data.Plans[index].PrimaryProducts[0] = JSON.parse(
									JSON.stringify(oldageplan)
								);
							}
							if (
								this.Person.isSpouseExists &&
								this.Person.Spouse.AgePerDOB >= 60 &&
								this.CoverageValue(this.$data.Plans[index].Id, false) <= 34999
							) {
								console.log("SpousePlanUpdated{0}", index);
								this.$data.Plans[index].SpouseProducts[0] = JSON.parse(
									JSON.stringify(oldageplan)
								);
							}
						}

						console.log("After Update");
						console.log(this.$data.Plans);
					}
				}
			}

			if (!this.planInitialized) this.InitializePlans();

			if (this.Presentation.CurrentPlan) {
				this.$data.CurrentSelectedPlan = this.$data.Plans.find(
					(x: any) => x.Name == this.Presentation.CurrentPlan
				);
			} else {
				this.$data.CurrentSelectedPlan = this.$data.Plans.find(
					(x: any) => x.isSelected == true
				);
				if (this.$data.CurrentSelectedPlan?.Id == undefined) {
					this.$data.CurrentSelectedPlan = this.$data.Plans[0];
				}
			}

			if (this.$data.CurrentSelectedPlan?.Id == undefined) {
				this.$data.CurrentSelectedPlan = this.$data.Plans[0];
			}

			this.$data.Products = null;
			this.$data.PrimaryProducts = null;
			this.$data.SpouseProducts = null;
			this.$data.PrimaryAdditionalAHPProducts = null;
			this.$data.PrimaryAdditionalALPProducts = null;
			this.$data.SpouseAdditionalAHPProducts = null;
			this.$data.SpouseAdditionalALPProducts = null;
			setTimeout(() => {
				this.$data.Products = this.$data.CurrentSelectedPlan.Products;
				this.$data.PrimaryProducts =
					this.$data.CurrentSelectedPlan.PrimaryProducts;
				this.$data.SpouseProducts =
					this.$data.CurrentSelectedPlan.SpouseProducts;
				this.$data.PrimaryAdditionalAHPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.PrimaryProducts.AHPProduct;
				this.$data.PrimaryAdditionalALPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.PrimaryProducts.ALPProduct;
				this.$data.SpouseAdditionalAHPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.SpouseProducts.AHPProduct;
				this.$data.SpouseAdditionalALPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.SpouseProducts.ALPProduct;

				if (
					this.$data.PrimaryProducts != null &&
					this.$data.PrimaryProducts.length > 0 &&
					this.Person.AgePerDOB < 60 &&
					this.$data.PrimaryProducts[0].Selected == true
				) {
					this.$data.PrimaryProducts[0].WHLSelected = true;
				} else if (
					this.$data.PrimaryProducts != null &&
					this.$data.PrimaryProducts.length > 0
				) {
					this.$data.PrimaryProducts[0].WHLSelected = false;
				}
				if (
					this.$data.SpouseProducts != null &&
					this.$data.SpouseProducts.length > 0 &&
					this.Person.Spouse.AgePerDOB < 60 &&
					this.$data.SpouseProducts[0].Selected == true
				) {
					this.$data.SpouseProducts[0].WHLSelected = true;
				} else if (
					this.$data.SpouseProducts != null &&
					this.$data.SpouseProducts.length > 0
				) {
					this.$data.SpouseProducts[0].WHLSelected = false;
				}

				setTimeout(() => {
					if (!this.Presentation.PlanOptions) {
						this.planloaded++;
					} else {
						this.planloaded = 3;
					}
				}, 300);
			}, 10);
			this.planOptionCheckedUnchecked();
		} else {
			if (this.Presentation.PlanOptions) {
				this.$data.Plans = JSON.parse(this.Presentation.PlanOptions);
			}

			if (!this.planInitialized) this.InitializePlans();

			if (this.Presentation.CurrentPlan) {
				this.$data.CurrentSelectedPlan = this.$data.Plans.find(
					(x: any) => x.Name == this.Presentation.CurrentPlan
				);
			} else {
				this.$data.CurrentSelectedPlan = this.$data.Plans.find(
					(x: any) => x.isSelected == true
				);
				if (this.$data.CurrentSelectedPlan?.Id == undefined) {
					this.$data.CurrentSelectedPlan = this.$data.Plans[0];
				}
			}

			if (!this.Presentation.PlanOptions) {
				this.$data.Plans.forEach((plan: any) => {
					plan.Products = [
						{
							Id: 1,
							Selected: true,
							ProductId: 17,
							Plan: {},
							WHLSelected: false,
						},
					];
					plan.PrimaryProducts = [
						{
							Id: 1,
							Selected: true,
							ProductId: 1,
							IsWHL: true,
							PresentAsDefaultId: 1,
							Plan: {},
							WHLSelected: false,
							CoverageMenuTop: true,
						},
						{
							Id: 2,
							Selected: false,
							ProductId: 6,
							PresentAsDefaultId: 3,
							Plan: {},
							WHLSelected: false,
						},
						{
							Id: 3,
							Selected: false,
							ProductId: 6,
							PresentAsDefaultId: 4,
							Plan: {},
							WHLSelected: false,
						},
						{
							Id: 4,
							Selected: false,
							ProductId: 14,
							PresentAsDefaultId: 1,
							Plan: {},
							WHLSelected: false,
							CoverageMenuTop: true,
						},
						{
							Id: 5,
							Selected: false,
							ProductId: 13,
							Plan: {},
							WHLSelected: false,
							CoverageMenuTop: true,
						},
					];
					plan.SpouseProducts = [
						{
							Id: 1,
							Selected: true,
							PresentAsDefaultId: 1,
							IsWHL: true,
							ProductId: 1,
							Plan: {},
							InterDependentOnDifferentProduct: true,
							WHLSelected: false,
							CoverageMenuTop: false,
						},
						{
							Id: 2,
							Selected: false,
							IsSpouse: true,
							ProductId: 6,
							PresentAsDefaultId: 3,
							Plan: {},
							WHLSelected: false,
							CoverageMenuTop: true,
						},
						{
							Id: 4,
							Selected: false,
							ProductId: 14,
							PresentAsDefaultId: 1,
							Plan: {},
							WHLSelected: false,
							CoverageMenuTop: false,
						},
					];
					plan.AdditionalProduct = {};
					plan.AdditionalProduct.PrimaryProducts = {};
					plan.AdditionalProduct.PrimaryProducts.AHPProduct = [
						{
							Id: 1,
							Selected: false,
							PresentAsDefaultId: 1,
							ProductId: 19,
							Plan: {},
							WHLSelected: false,
						},
						{
							Id: 2,
							Selected: false,
							PresentAsDefaultId: 1,
							ProductId: 18,
							Plan: {},
							WHLSelected: false,
						},
					];
					plan.AdditionalProduct.PrimaryProducts.ALPProduct = [
						{
							Id: 3,
							Selected: false,
							PresentAsDefaultId: 1,
							ProductId: 15,
							Plan: {},
							WHLSelected: false,
							VariableDependOnSpouse: false,
						},
						{
							Id: 4,
							Selected: false,
							PresentAsDefaultId: 1,
							ProductId: 12,
							Plan: {},
							InterDependentOnDifferentProduct: true,
							WHLSelected: false,
							VariableDependOnSpouse: true,
						},
					];
					plan.AdditionalProduct.SpouseProducts = {};
					plan.AdditionalProduct.SpouseProducts.AHPProduct = [
						{
							Id: 1,
							Selected: false,
							PresentAsDefaultId: 1,
							ProductId: 19,
							IsSpouse: true,
							Plan: {},
							WHLSelected: false,
						},
						{
							Id: 2,
							Selected: false,
							PresentAsDefaultId: 1,
							ProductId: 18,
							IsSpouse: true,
							Plan: {},
							WHLSelected: false,
						},
					];
					plan.AdditionalProduct.SpouseProducts.ALPProduct = [
						{
							Id: 3,
							Selected: false,
							IsSpouse: true,
							ProductId: 6,
							PresentAsDefaultId: 4,
							Plan: {},
							WHLSelected: true,
						},
						{
							Id: 4,
							Selected: false,
							PresentAsDefaultId: 1,
							IsSpouse: true,
							ProductId: 15,
							Plan: {},
							WHLSelected: true,
						},
					];
				});
			}

			this.$data.Products = null;
			this.$data.PrimaryProducts = null;
			this.$data.SpouseProducts = null;
			this.$data.PrimaryAdditionalAHPProducts = null;
			this.$data.PrimaryAdditionalALPProducts = null;
			this.$data.SpouseAdditionalAHPProducts = null;
			this.$data.SpouseAdditionalALPProducts = null;
			setTimeout(() => {
				this.$data.Products = this.$data.CurrentSelectedPlan.Products;
				this.$data.PrimaryProducts =
					this.$data.CurrentSelectedPlan.PrimaryProducts;
				this.$data.SpouseProducts =
					this.$data.CurrentSelectedPlan.SpouseProducts;
				this.$data.PrimaryAdditionalAHPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.PrimaryProducts.AHPProduct;
				this.$data.PrimaryAdditionalALPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.PrimaryProducts.ALPProduct;
				this.$data.SpouseAdditionalAHPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.SpouseProducts.AHPProduct;
				this.$data.SpouseAdditionalALPProducts =
					this.$data.CurrentSelectedPlan.AdditionalProduct.SpouseProducts.ALPProduct;

				if (
					this.$data.PrimaryProducts != null &&
					this.$data.PrimaryProducts.length > 0 &&
					this.Person.AgePerDOB < 60 &&
					this.$data.PrimaryProducts[0].Selected == true
				) {
					this.$data.PrimaryProducts[0].WHLSelected = true;
				} else if (
					this.$data.PrimaryProducts != null &&
					this.$data.PrimaryProducts.length > 0
				) {
					this.$data.PrimaryProducts[0].WHLSelected = false;
				}

				if (
					this.$data.SpouseProducts != null &&
					this.$data.SpouseProducts.length > 0 &&
					this.Person.Spouse.AgePerDOB < 60 &&
					this.$data.SpouseProducts[0].Selected == true
				) {
					this.$data.SpouseProducts[0].WHLSelected = true;
				} else if (
					this.$data.SpouseProducts != null &&
					this.$data.SpouseProducts.length > 0
				) {
					this.$data.SpouseProducts[0].WHLSelected = false;
				}
				this.planloaded = 3;
			}, 10);

			this.planOptionCheckedUnchecked();
		}
	}

	get AllowUsePrePlan() {
		if (!this.isPrePlan) return true;

		var primaryAdded =
			this.$data.prePlanSelectedState != null &&
			this.Person.AgePerDOB != null &&
			this.Person.AgePerDOB != "" &&
			this.Person.AgePerDOB != 0 &&
			!isNaN(this.Person.AgePerDOB) &&
			this.Person.AgePerDOB > 0 &&
			this.Person.AgePerDOB <= 80 &&
			this.Person.Gender != "";

		return primaryAdded;
	}

	data() {
		return {
			refreshKey: 0,
			ops: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			addlanguageclasses: false,
			prePlanSelectedState: null,
			wageMenu: false,
			showPlanTabs: false,
			disableplancheckbox: false,
			Person: this.Person,
			PremiumPlan: this.PremiumPlan,
			Referrals: {},
			showPlanOptions: false,
			showProtections: false,
			CurrentSelectedPlan: {},
			Products: null,
			PrimaryProducts: null,
			SpouseProducts: null,
			PrimaryAdditionalAHPProducts: null,
			PrimaryAdditionalALPProducts: null,
			SpouseAdditionalAHPProducts: null,
			SpouseAdditionalALPProducts: null,
			Plans: [
				{
					Id: 1,
					IsChecked: true,
					Name: "PLAN OPTION 1",
					divid: "planopt1",
					isSelected: true,
				},
				{
					Id: 2,
					IsChecked: false,
					Name: "PLAN OPTION 2",
					divid: "planopt2",
					IsCloned: false,
					isSelected: false,
				},
				{
					Id: 3,
					IsChecked: false,
					Name: "PLAN OPTION 3",
					divid: "planopt3",
					IsCloned: false,
					isSelected: false,
				},
				{
					Id: 4,
					IsChecked: false,
					Name: "PLAN OPTION 4",
					divid: "planopt4",
					isSelected: false,
					IsCloned: false,
				},
			],
		};
	}

	planOptionCheckedUnchecked(item: any = null) {
		this.$data.showPlanTabs =
			this.$data.Plans.filter((x: any) => x.IsChecked == true).length > 1;
		this.$data.disableplancheckbox =
			this.$data.Plans.filter((x: any) => x.IsChecked == true).length == 1;
		if (item && item.Id > 1 && item.IsChecked == true && !item.IsCloned) {
			this.saveCurrentSelectedPlan();
			var currTempItem = JSON.parse(JSON.stringify(item));
			var plan = this.$data.Plans[item.Id - 2];

			if (item.Id == 3 && plan.IsChecked == false) {
				plan = this.$data.Plans[0];
			}
			item = JSON.parse(JSON.stringify(plan));
			item.IsChecked = currTempItem.IsChecked;
			item.isSelected = currTempItem.isSelected;
			item.Name = currTempItem.Name;
			item.Id = currTempItem.Id;
			item.divid = currTempItem.divid;
			item.IsCloned = true;
			this.CalculateMonthlyPremium(item);
			this.$data.Plans[item.Id - 1] = JSON.parse(JSON.stringify(item));
		}
		this.$data.refreshKey++;
	}

	FillPremiumPlanofPresentation() {
		if (this.PremiumPlan != undefined) {
			if (
				this.Presentation.PremiumPlan == null ||
				this.Presentation.PremiumPlan == undefined
			) {
				let premiumPlanEntity: PremiumPlanEntity = new PremiumPlanEntity();

				premiumPlanEntity.PremiumTypeId = this.PremiumPlan.PremiumTypeId;
				this.Presentation.PremiumTypeId = this.PremiumPlan.PremiumTypeId;

				premiumPlanEntity.FinalDailyPremium =
					this.PremiumPlan.FinalDailyPremium;
				premiumPlanEntity.FinalMonthlyPremium =
					this.PremiumPlan.FinalMonthlyPremium;

				premiumPlanEntity.HPPremiumType = this.PremiumPlan.HPPremiumType;
				premiumPlanEntity.MonthlyPremiumType =
					this.PremiumPlan.MonthlyPremiumType;
				premiumPlanEntity.CustomHPPremium = this.PremiumPlan.CustomHPPremium;
				premiumPlanEntity.CustomMonthlyPremium =
					this.PremiumPlan.CustomMonthlyPremium;

				this.Presentation.PremiumPlan = premiumPlanEntity;

				var premiumPlan = PremiumPlanEntity.insert({
					data: this.Presentation.PremiumPlan,
				});
				this.Presentation.NeedAmount = this.PremiumPlan.NeedPremium;
				this.Presentation.PremiumPlan = PremiumPlanEntity.query().last();
			} else {
				let premiumPlanEntity: PremiumPlanEntity =
					this.Presentation.PremiumPlan;

				premiumPlanEntity.PremiumTypeId = this.PremiumPlan.PremiumTypeId;

				premiumPlanEntity.FinalDailyPremium =
					this.PremiumPlan.FinalDailyPremium;
				premiumPlanEntity.FinalMonthlyPremium =
					this.PremiumPlan.FinalMonthlyPremium;
				premiumPlanEntity.HPPremiumType = this.PremiumPlan.HPPremiumType;
				premiumPlanEntity.MonthlyPremiumType =
					this.PremiumPlan.MonthlyPremiumType;
				premiumPlanEntity.CustomHPPremium = ConvertIntoAmountFormatWithDecimal(
					Number(this.PremiumPlan.CustomHPPremium).toFixed(2),
					true,
					true
				);
				premiumPlanEntity.CustomMonthlyPremium =
					this.PremiumPlan.CustomMonthlyPremium;
				this.Presentation.PremiumPlan = premiumPlanEntity;

				this.Presentation.NeedAmount = this.PremiumPlan.NeedPremium;
				var premiumPlan = PremiumPlanEntity.update({
					data: this.Presentation.PremiumPlan,
				});
			}
		}
	}

	CalculateMonthlyPremium(plan: any) {
		return EntityHelper.CalculateMonthlyPremium(plan);
	}

	Action(actionName: String) {
		if (actionName == "ratebook") {
			this.showRateBook = true;
			this.$store.state.OpenedPopupName = "ratebook";
			this.NoOfRateBookRows = [];
			this.ListOfRatePlan = [];
			this.AddNewRateRow();
			LoggingHelper.getInstance().LogEvent(LoggingConstants.RatebookOpened);
			return;
		}
		if (actionName == "funeralexpense") {
			this.funeralexpense = true;
			this.$store.state.OpenedPopupName = "funeralexpense";
			LoggingHelper.getInstance().LogEvent(
				LoggingConstants.FuneralExpenseOpened
			);
			return;
		}
	}
	updateGeneralClass(plan: any) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		} else {
			return plan.Plan.CurrentProduct.PGName.replace("(", "_")
				.replace(")", "")
				.replace(" ", "_");
		}
	}
	updatePrimaryClass(plan: any, index: number) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		} else {
			if (plan.Plan.CurrentProduct.PGName == "10YRC") {
				return "pr-" + plan.Plan.CurrentProduct.PGName + "_" + index;
			} else {
				return (
					"pr-" +
					plan.Plan.CurrentProduct.PGName.split("(")[0].replace(" ", "_")
				);
			}
		}
	}
	updateSpouseClass(plan: any) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		} else {
			return (
				"sp-" + plan.Plan.CurrentProduct.PGName.split("(")[0].replace(" ", "_")
			);
		}
	}
	updatePrimaryAHPClass(plan: any) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		} else {
			return (
				"pr-AHP-" +
				plan.Plan.CurrentProduct.PGName.split("(")[0].replace(" ", "_")
			);
		}
	}
	updatePrimaryALPClass(plan: any) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		}
		return (
			"pr-ALP-" +
			plan.Plan.CurrentProduct.PGName.split("(")[0].replace(" ", "_")
		);
	}
	updateSpouseAHPClass(plan: any) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		} else {
			return (
				"sp-AHP-" +
				plan.Plan.CurrentProduct.PGName.split("(")[0].replace(" ", "_")
			);
		}
	}
	updateSpouseALPClass(plan: any) {
		if (plan.Plan.CurrentProduct == undefined) {
			return "";
		} else {
			return (
				"sp-ALP-" +
				plan.Plan.CurrentProduct.PGName.split("(")[0].replace(" ", "_")
			);
		}
	}
	RoutedTo(route: String, routeobj: any = null) {
		if (route == "/ssprogram") {
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";
			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}

		window.removeEventListener("beforeunload", this.onCloseBrowser);

		if (this.isPrePlan) {
			if (route == "StartPresentation") this.$router.push(route?.toString());
			else return;
		}

		this.FillPremiumPlanofPresentation();

		this.Presentation.PremiumPlanEntity_Id = this.Presentation.PremiumPlan?.$id;

		this.Presentation.PremiumTypeId = this.PremiumPlan.PremiumTypeId;
		if (this.Presentation.PremiumTypeId == 2) {
			this.Presentation.CombinedPremiumAmount =
				this.PremiumPlan.FinalMonthlyPremium;
		} else if (this.Presentation.PremiumTypeId == 1) {
			this.Presentation.CombinedPremiumAmount =
				this.PremiumPlan.FinalWeeklyPremium;
		} else {
			this.Presentation.CombinedPremiumAmount =
				this.PremiumPlan.FinalDailyPremium;
		}

		this.Presentation.isPlanGeneratorCompleted = true;

		if (this.Presentation.CurrentStepCompleted <= 2) {
			this.Presentation.CurrentStepCompleted = 2;
		}
		this.Presentation.TimeOnPlanGenerator = (
			(parseInt(this.Presentation.TimeOnPlanGenerator) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		this.$store.state.loader = true;

		setTimeout(() => {
			this.$store.state.loader = false;
			this.SavePlans(route, routeobj);
		}, 1000);
	}

	UpdateObjects(route: String, routeobj: any = null) {
		this.saveCurrentSelectedPlan();
		this.Presentation.PlanOptions = JSON.stringify(this.$data.Plans);
		this.Presentation.Protections = JSON.stringify(this.Protections);
		this.Presentation.CurrentPlan = this.$data.CurrentSelectedPlan.Name;
		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		}).finally(() => {
			if (this.Childrens != null && this.Childrens.length > 0) {
				this.Person.ChildrenString = JSON.stringify(this.Childrens);
			} else {
				this.Person.ChildrenString = "";
			}
			PrimaryPerson.update({
				where: this.Person.Id,
				data: this.Person,
			}).then(() => {
				Spouse.update({
					where: this.Person.Spouse.Id,
					data: this.Person.Spouse,
				}).finally(() => {
					let fullPreswithDetail = Presentation.query()
						.withAllRecursive()
						.last();

					let saveData = new GenericKeyPair(
						"CurrentPresentation",
						JSON.stringify(fullPreswithDetail)
					);
					setPersistedState(saveData);
					// saveData = new GenericKeyPair(
					//   "PresentationsList",
					//   JSON.stringify(Presentation.query().withAllRecursive().all())
					// );
					// setPersistedState(saveData);
					if (routeobj == null) {
						if (this.browserCloseEvent) {
							this.browserCloseEvent = false;
							window.removeEventListener("beforeunload", this.onCloseBrowser);
							this.$router.push({
								name: "FinishPresentation",
								params: { showWhatHappenError: "true" },
							});
						} else {
							this.$router.push(route.toString());
						}
					} else {
						this.$router.push({ name: routeobj.name, params: routeobj.params });
					}
				});
			});
		});
	}

	isNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			return true;
		}
	}
	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}

	loadPlan(plan: any) {
		if (!plan.IsChecked) {
			this.$store.state.messageText = this.$t("PlanGenerator.SelectPlanFirst");
			this.$store.state.showMessage = true;
			return;
		}

		this.UnSelectAllPlans();
		setTimeout(() => {
			this.$data.CurrentSelectedPlan.Products = this.$data.Products;
			this.$data.CurrentSelectedPlan.PrimaryProducts =
				this.$data.PrimaryProducts;
			this.$data.CurrentSelectedPlan.SpouseProducts = this.$data.SpouseProducts;

			this.$data.Products = null;
			this.$data.PrimaryProducts = null;
			this.$data.SpouseProducts = null;
			this.$data.PrimaryAdditionalAHPProducts = null;
			this.$data.SpouseAdditionalAHPProducts = null;
			this.$data.PrimaryAdditionalALPProducts = null;
			this.$data.SpouseAdditionalALPProducts = null;
			this.saveCurrentSelectedPlan();
		}, 0);
		setTimeout(() => {
			this.$data.CurrentSelectedPlan = plan;
			this.$data.Products = plan.Products;
			this.$data.PrimaryProducts = plan.PrimaryProducts;
			this.$data.SpouseProducts = plan.SpouseProducts;
			this.$data.PrimaryAdditionalAHPProducts =
				plan.AdditionalProduct.PrimaryProducts.AHPProduct;
			this.$data.SpouseAdditionalAHPProducts =
				plan.AdditionalProduct.SpouseProducts.AHPProduct;
			this.$data.PrimaryAdditionalALPProducts =
				plan.AdditionalProduct.PrimaryProducts.ALPProduct;
			this.$data.SpouseAdditionalALPProducts =
				plan.AdditionalProduct.SpouseProducts.ALPProduct;
			var planOptionPremPlan = JSON.parse(JSON.stringify(plan.PremiumPlan));
			if (planOptionPremPlan != undefined) {
				this.PremiumPlan.HPPremiumType = planOptionPremPlan.hPPremiumType;
				this.PremiumPlan.MonthlyPremiumType =
					planOptionPremPlan.monthlyPremiumType;
				this.PremiumPlan.CustomHPPremium = planOptionPremPlan.customHPPremium;
				this.PremiumPlan.CustomMonthlyPremium =
					planOptionPremPlan.customMonthlyPremium;
				this.PremiumPlan.PrimaryDollarADay =
					planOptionPremPlan.PrimaryDollarADay;
				this.PremiumPlan.SpouseDollarADay = planOptionPremPlan.SpouseDollarADay;
				this.PremiumPlan.NeedPremium = planOptionPremPlan.needPremium;
				this.Presentation.NeedAmount = planOptionPremPlan.needPremium;
				if (this.Person != undefined) {
					this.Person.DollarADayAmount = planOptionPremPlan.PrimaryDollarADay;
					if (this.Person.Spouse != undefined)
						this.Person.Spouse.DollarADayAmount =
							planOptionPremPlan.SpouseDollarADay;
					this.PremiumPlan.ChangeDollarADay(
						planOptionPremPlan.PrimaryDollarADay,
						planOptionPremPlan.SpouseDollarADay,
						this.Person.isSpouseForDADExists
					);
				}
				//this.PremiumPlan.CalculateFinalPremium();
			}
			plan.isSelected = true;
			setTimeout(() => {
				if (this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) {
					this.planloaded++;
				}
			}, 800);
		}, 0);

		LoggingHelper.getInstance().LogEvent(LoggingConstants.PlanOptionChange);
	}

	UnSelectAllPlans() {
		if (this.$data.Plans != undefined && this.$data.Plans.length > 0) {
			this.$data.Plans.forEach((pln: any) => {
				pln.isSelected = false;
			});
		}
	}

	get getMaxLengthasPerWageType() {
		switch (this.SelectedWageType.Id) {
			case 1:
				return 5;
			case 2:
				return 6;
			case 3:
				return 7;
			case 4:
				return 9;
		}
	}

	personWageFocus() {
		this.Person.Wage = this.Person.Wage.replaceAll(",", "");
		this.Person.Wage = this.Person.Wage.replaceAll(".00", "");
		const last = this.Person.Wage.charAt(this.Person.Wage.length - 1);

		if (last === "0" && this.Person.Wage.includes(".")) {
			this.Person.Wage = this.Person.Wage.substring(
				0,
				this.Person.Wage.length - 1
			);
		}
	}

	spouseWageFocus() {
		this.Person.Spouse.Wage = this.Person.Spouse.Wage.replaceAll(",", "");
		this.Person.Spouse.Wage = this.Person.Spouse.Wage.replaceAll(".00", "");
		const last = this.Person.Spouse.Wage.charAt(
			this.Person.Spouse.Wage.length - 1
		);

		if (last === "0" && this.Person.Spouse.Wage.includes(".")) {
			this.Person.Spouse.Wage = this.Person.Spouse.Wage.substring(
				0,
				this.Person.Spouse.Wage.length - 1
			);
		}
	}

	get checkedPlans() {
		var a = this.$data.refreshKey;
		return this.$data.Plans.filter((x: any) => x.IsChecked == true);
	}

	saveCurrentSelectedPlan() {
		this.$data.CurrentSelectedPlan.Products.forEach((product: any) => {
			product.Coverage = product.Plan.Coverage;
			product.MBD = product.Plan.MDB;
			product.Option = product.Plan.CompleteOption;
			product.CurrentProduct = product.Plan.CurrentProduct;
			product.PresentAs = product.Plan.PresentAs;

			// if (product.ProductAvailable == false || product.Plan.isProductDisabled == true)
			//   product.Selected = false;
		});

		this.$data.CurrentSelectedPlan.PrimaryProducts.forEach((product: any) => {
			product.Coverage = product.Plan.Coverage;
			product.MBD = product.Plan.MDB;
			product.Option = product.Plan.CompleteOption;
			product.CurrentProduct = product.Plan.CurrentProduct;
			product.PresentAs = product.Plan.PresentAs;
			product.PGName = product.Plan.PGName;

			// if (product.ProductAvailable == false || product.Plan.isProductDisabled == true)
			// {  product.Selected = false;

			// }
		});

		this.$data.CurrentSelectedPlan.SpouseProducts.forEach((product: any) => {
			product.Coverage = product.Plan.Coverage;
			product.MBD = product.Plan.MDB;
			product.CurrentProduct = product.Plan.CurrentProduct;
			product.Option = product.Plan.CompleteOption;
			product.PresentAs = product.Plan.PresentAs;
			product.PGName = product.Plan.PGName;

			// if (product.ProductAvailable == false || product.Plan.isProductDisabled == true)
			//   product.Selected = false;
		});

		this.$data.CurrentSelectedPlan.AdditionalProduct.PrimaryProducts.AHPProduct.forEach(
			(product: any) => {
				product.Coverage = product.Plan.Coverage;
				product.MBD = product.Plan.MDB;
				product.Option = product.Plan.CompleteOption;
				product.CoverageOption = product.Plan.CompleteCoverageOption;
				product.CurrentProduct = product.Plan.CurrentProduct;
				product.PresentAs = product.Plan.PresentAs;

				// if (product.ProductAvailable == false || product.Plan.isProductDisabled == true)
				//   product.Selected = false;
			}
		);

		this.$data.CurrentSelectedPlan.AdditionalProduct.PrimaryProducts.ALPProduct.forEach(
			(product: any) => {
				product.Coverage = product.Plan.Coverage;
				product.MBD = product.Plan.MDB;
				product.Option = product.Plan.CompleteOption;
				product.CoverageOption = product.Plan.CompleteCoverageOption;
				product.CurrentProduct = product.Plan.CurrentProduct;
				product.PresentAs = product.Plan.PresentAs;
			}
		);

		this.$data.CurrentSelectedPlan.AdditionalProduct.SpouseProducts.AHPProduct.forEach(
			(product: any) => {
				product.Coverage = product.Plan.Coverage;
				product.MBD = product.Plan.MDB;
				product.Option = product.Plan.CompleteOption;
				product.CoverageOption = product.Plan.CompleteCoverageOption;
				product.CurrentProduct = product.Plan.CurrentProduct;
				product.PresentAs = product.Plan.PresentAs;
			}
		);

		this.$data.CurrentSelectedPlan.AdditionalProduct.SpouseProducts.ALPProduct.forEach(
			(product: any) => {
				product.Coverage = product.Plan.Coverage;
				product.MBD = product.Plan.MDB;
				product.Option = product.Plan.CompleteOption;
				product.CoverageOption = product.Plan.CompleteCoverageOption;
				product.CurrentProduct = product.Plan.CurrentProduct;
				product.PresentAs = product.Plan.PresentAs;
			}
		);
		this.$data.CurrentSelectedPlan.PremiumPlan = JSON.parse(
			JSON.stringify(this.PremiumPlan)
		);
	}

	CalculateWageByAnnual(annualIncome: any) {
		var income = annualIncome;
		if (typeof annualIncome == "string")
			income = annualIncome.replaceAll(",", "");

		var incomeNumber =
			Number.parseFloat(income) / +this.SelectedWageType.YearValue;
		return RoundUpperDecimal(incomeNumber);
	}

	SetPremiumPlanAnnualIncome(firsttime: boolean) {
		var tempPersonAnnualIncome = 0;
		if (
			typeof this.Person.AnnualIncome == "string" &&
			//this.personYearlySalary == 0 &&
			!this.Presentation.WageValuesCustomized
		)
			tempPersonAnnualIncome = this.Person.AnnualIncome.replaceAll(",", "");
		else tempPersonAnnualIncome = this.personYearlySalary;

		if (isNaN(tempPersonAnnualIncome)) tempPersonAnnualIncome = 0;

		var tempSpouseAnnualIncome = 0;
		if (
			typeof this.Person.Spouse.AnnualIncome == "string" &&
			//this.spouseYearlySalary == 0 &&
			!this.Presentation.WageValuesCustomized
		)
			tempSpouseAnnualIncome = this.Person.Spouse.AnnualIncome.replaceAll(
				",",
				""
			);
		else {
			tempSpouseAnnualIncome = this.spouseYearlySalary;
		}

		if (isNaN(tempSpouseAnnualIncome)) tempSpouseAnnualIncome = 0;

		if (isNaN(this.PremiumPlan.CombinedTotalIncome))
			this.PremiumPlan.CombinedTotalIncome = 0;

		this.PremiumPlan.CombinedTotalIncome = Number(
			(+tempPersonAnnualIncome + +tempSpouseAnnualIncome).toFixed()
		);
	}

	InitializePlans() {
		if (
			this.Presentation.WageType != undefined &&
			this.Presentation.WageType != ""
		) {
			this.SelectedWageType = this.ListOfWagesTypes.filter(
				(x: any) => x.Name == this.Presentation.WageType
			)[0];
		} else if (
			this.Presentation.PremiumTypeId != undefined &&
			this.Presentation.PremiumTypeId != 0
		) {
			var selWageType = this.ListOfWagesTypes.filter(
				(x: any) => x.PremiumTypeId == this.Presentation.PremiumTypeId
			);
			if (selWageType != undefined && selWageType.length > 0) {
				this.SelectedWageType = selWageType[0];
			}
			this.Presentation.WageType = this.SelectedWageType.Name;
		} else this.Presentation.WageType = this.SelectedWageType.Name;

		// var dollarADayCombAmt = this.Person.DollarADayAmount;

		// if (this.Person.isSpouseForDADExists)
		//   dollarADayCombAmt += this.Person.Spouse.DollarADayAmount;
		//var k = this.Person.Spouse.MonthlyPremium.replaceAll(",", "");

		this.PremiumPlan = new PremiumPlan(
			this.Person.Premium != undefined ? this.Person.Premium : 0,
			this.Person.Spouse.Premium != undefined ? this.Person.Spouse.Premium : 0,
			this.Person.MonthlyPremium != undefined
				? Number(this.Person.MonthlyPremium.replaceAll(",", ""))
				: 0,
			this.Person.Spouse.MonthlyPremium != undefined
				? Number(this.Person.Spouse.MonthlyPremium.replaceAll(",", ""))
				: 0,
			this.Person.DollarADayAmount,
			this.Person.Spouse.DollarADayAmount,
			this.Person.isSpouseForDADExists
		);

		if (this.Presentation.PremiumTypeId == 3) {
		} else if (this.Presentation.PremiumTypeId == 4) {
			var selDD = this.PremiumPlan.NeedOptions.filter(
				(x) => x.text == this.Presentation.NeedAmount
			);
			if (selDD != undefined && selDD.length > 0)
				this.PremiumPlan.NeedPremium = selDD[0].text;
		}

		if (
			this.Presentation.PremiumPlan != undefined &&
			this.Presentation.PremiumPlan.HPPremiumType == 2
		) {
			if (this.Presentation.PremiumPlan.CustomHPPremium != undefined)
				this.PremiumPlan.CustomHPPremium =
					this.Presentation.PremiumPlan.CustomHPPremium;
		}
		if (this.Presentation.PremiumPlan != undefined) {
			this.PremiumPlan.PremiumTypeId =
				this.Presentation.PremiumPlan.PremiumTypeId;
			this.PremiumPlan.HPPremiumType =
				this.Presentation.PremiumPlan.HPPremiumType;

			this.PremiumPlan.MonthlyPremiumType =
				this.Presentation.PremiumPlan.MonthlyPremiumType;
			if (this.Presentation.CustomAmountCustomized) {
				this.PremiumPlan.CustomHPPremium =
					this.Presentation.PremiumPlan.CustomHPPremium;
				this.PremiumPlan.CustomMonthlyPremium =
					this.Presentation.PremiumPlan.CustomMonthlyPremium;
			}
		} else {
			this.PremiumPlan.PremiumTypeId = this.Presentation.PremiumTypeId;
		}

		if (this.PremiumPlan.PremiumTypeId != 0) {
			this.SelectedPremiumType = this.PremiumApproaches.filter((x: any) => {
				if (x.id == this.PremiumPlan.PremiumTypeId) return x;
			})[0];
		}

		this.SetPremiumPlanAnnualIncome(true);
		this.planInitialized = true;
	}

	SavePlans(route: String, routeobj: any = null) {
		this.SaveGeneralPlansFunction(() => {
			this.SavePrimaryPlansFunction(() => {
				this.SaveSpousePlansFunction(() => this.UpdateObjects(route, routeobj));
			});
		});
	}

	SaveGeneralPlansFunction(AfterExecute: () => void): boolean {
		let isValid: boolean = true;
		PresentationPlans.delete((record: any) => {
			return record.presentationId == this.Presentation.$id;
		})
			.then(() => {})
			.finally(() => {
				if (isValid) AfterExecute();
			});
		return true;
	}

	SavePrimaryPlansFunction(AfterExecute: () => void) {
		let isValid: boolean = true;
		PersonPlan.delete((record: any) => {
			return record.personId == this.Person.$id;
		})
			.then(() => {})
			.finally(() => {
				if (isValid) AfterExecute();
			});
	}

	SaveSpousePlansFunction(AfterExecute: () => void): boolean {
		let isValid: boolean = true;
		SpousePlan.delete((record: any) => {
			return record.spouseId == this.Person.Spouse.$id;
		})
			.then(() => {})
			.finally(() => {
				if (isValid) AfterExecute();
			});
		return true;
	}

	private usedMBD: number = 0;

	CalculateUsedMBD() {
		this.usedMBD = 0;
		var flag = false;
		var A71000AvailbleWithSelection = false;
		this.$data.Products.forEach((element: any) => {
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.CompleteProduct != undefined) {
					if (element.Plan.CompleteProduct.Id == 17) {
						A71000AvailbleWithSelection = true;
					}
				}
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});

		this.$data.PrimaryProducts.forEach((element: any) => {
			if (element.Plan != undefined) {
				if (element.Plan.CompleteProduct != undefined) {
					if (element.Plan.CompleteProduct.Id == 14) {
						if (A71000AvailbleWithSelection) {
							element.Plan.PresentAsOptions[0].Name = "WITH A71000";
						} else {
							element.Plan.PresentAsOptions[0].Name =
								"AS ACCIDENTAL DEATH BENEFIT";
						}
						element.Plan.PresentAs =
							element.Plan.PresentAs == "WITH A71000" ||
							element.Plan.PresentAs == "AS ACCIDENTAL DEATH BENEFIT"
								? element.Plan.PresentAsOptions[0].Name
								: element.Plan.PresentAs;
					}
				}
			}
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});
		this.$data.SpouseProducts.forEach((element: any) => {
			if (element.Plan != undefined) {
				if (element.Plan.CompleteProduct != undefined) {
					if (element.Plan.CompleteProduct.Id == 14) {
						if (A71000AvailbleWithSelection) {
							element.Plan.PresentAsOptions[0].Name = "WITH A71000";
						} else {
							element.Plan.PresentAsOptions[0].Name =
								"AS ACCIDENTAL DEATH BENEFIT";
						}
						element.Plan.PresentAs =
							element.Plan.PresentAs == "WITH A71000" ||
							element.Plan.PresentAs == "AS ACCIDENTAL DEATH BENEFIT"
								? element.Plan.PresentAsOptions[0].Name
								: element.Plan.PresentAs;
					}
				}
			}
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});

		this.$data.PrimaryAdditionalAHPProducts.forEach((element: any) => {
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});

		this.$data.SpouseAdditionalAHPProducts.forEach((element: any) => {
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});

		this.$data.PrimaryAdditionalALPProducts.forEach((element: any) => {
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});

		this.$data.SpouseAdditionalALPProducts.forEach((element: any) => {
			if (element.Selected && element.Plan != undefined) {
				if (element.Plan.isProductDisabled != undefined) {
					if (!element.Plan.isProductDisabled) flag = true;
				}
				this.AddUsedMBD(element.Plan.MDB);
			}
		});
		if (this.AnyProductSelected != flag) {
			this.AnyProductSelected = flag;
			EventBusLayout.$emit(
				"PlanGeneratorProductsSelected",
				this.AnyProductSelected
			);
			this.Presentation.AnyProductChecked = this.AnyProductSelected;
		}
		this.PremiumPlan.UsedMonthlyPremium = this.usedMBD;
		this.PremiumPlan.UsedYearlyPremium = this.usedMBD * 12;
		this.PremiumPlan.UsedWeeklyPremium = this.usedMBD / 4.333;
		this.PremiumPlan.UsedDailyPremium = this.usedMBD / 30;
	}

	AmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}
		var newValue = value;
		if (value == "") {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		} else {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal(
					Number(value.replaceAll(",", "")).toFixed(2),
					true,
					true
				)
			);
		}
	}

	ApplyValuebyFieldString(fieldString: string, value: string) {
		var properties = fieldString.split(".");
		var setPropertyvalue: any = this.$data;
		if (properties != undefined && properties.length > 0) {
			var i = 1;
			properties.forEach((element: any) => {
				if (i == properties.length) {
					setPropertyvalue[element] = value;
				} else {
					setPropertyvalue = setPropertyvalue[element];
				}
				i++;
			});
		}
	}

	AddUsedMBD(MBD: any) {
		if (MBD != undefined && typeof MBD == "string") {
			MBD = MBD.replace(",", "");
		}
		if (isNaN(MBD) || MBD == "") MBD = 0;

		var MBDNumber = Number.parseFloat(MBD);
		this.usedMBD = this.usedMBD + MBDNumber;
	}

	dollaradaychanged() {
		this.PremiumPlan.ChangeDollarADay(
			this.Person.DollarADayAmount,
			this.Person.Spouse.DollarADayAmount,
			this.Person.isSpouseForDADExists
		);
		// var dollarADayCombAmt = this.Person.DollarADayAmount;

		// if (this.Person.isSpouseForDADExists)
		//   dollarADayCombAmt += this.Person.Spouse.DollarADayAmount;
		// if (this.PremiumPlan != undefined)
		//   this.PremiumPlan.DollarADayPremium = dollarADayCombAmt;
	}

	CustomAmountChange(evt: any) {
		if (this.Presentation != undefined) {
			this.Presentation.CustomAmountCustomized = true;
		}

		evt.target.value = ConvertIntoAmountFormatWithDecimal(
			Number(evt.target.value).toFixed(2),
			true,
			true
		);
	}

	RemoveAllSpousePlan() {
		if (
			this.$data.SpouseProducts != undefined &&
			this.$data.SpouseProducts.length != 0
		) {
			this.$data.SpouseProducts.forEach((element: any) => {
				element.Selected = false;
			});
		}

		if (
			this.$data.SpouseAdditionalAHPProducts != undefined &&
			this.$data.SpouseAdditionalAHPProducts.length != 0
		) {
			this.$data.SpouseAdditionalAHPProducts.forEach((element: any) => {
				element.Selected = false;
			});
		}
		if (
			this.$data.SpouseAdditionalALPProducts != undefined &&
			this.$data.SpouseAdditionalALPProducts.length != 0
		) {
			this.$data.SpouseAdditionalALPProducts.forEach((element: any) => {
				element.Selected = false;
			});
		}
	}

	CloseRatebook() {
		this.showRateBook = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
		LoggingHelper.getInstance().LogEvent(LoggingConstants.RatebookClosed);
	}
	closeBurialCremationExpense() {
		this.funeralexpense = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
		LoggingHelper.getInstance().LogEvent(LoggingConstants.FuneralExpenseOpened);
	}

	ShowChildrenPopup() {
		this.addchildren = true;
		LoggingHelper.getInstance().LogEvent(LoggingConstants.ChildrenPopupOpen);
	}

	ShowAdditionalPopup() {
		this.addtionalproducts = true;
		LoggingHelper.getInstance().LogEvent(
			LoggingConstants.AdditionalPopupOpened
		);
	}
	SaveWillKitDataToPresentation(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			this.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}
	PrimaryGenderUpdate() {
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
	}
}
