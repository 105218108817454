// export enum FamilyCareVeteranHouseHoldType {
// 	//[Description("Single")]
// 	single,
// 	//[Description("Single Parent")]
// 	single_parent,
// 	//[Description("Married")]
// 	married,
// 	//[Description("Family")]
// 	family,
// 	//[Description("Retired")]
// 	retired
// }

// export const FamilyCareVeteranHouseHoldTypeDiscription = new Map<number, string>([
// 	[ FamilyCareVeteranHouseHoldType.single, 'Single' ],
// 	[ FamilyCareVeteranHouseHoldType.single_parent, 'Single Parent' ],
// 	[ FamilyCareVeteranHouseHoldType.married, 'Married' ],
// 	[ FamilyCareVeteranHouseHoldType.family, 'Family' ],
// 	[ FamilyCareVeteranHouseHoldType.retired, 'Retired' ]
// ]);

// export enum FamilyCareVeteranBranchOfService {
// 	//[Description("Army")]
// 	army,
// 	//[Description("Marine Corps")]
// 	marine_corps,
// 	//[Description("Navy")]
// 	navy,
// 	//[Description("Air Force")]
// 	air_force,
// 	//[Description("Space Force")]
// 	space_force,
// 	//[Description("Coast Guard")]
// 	coast_guard,
// 	//[Description("Army National Guard")]
// 	army_national_guard,
// 	//[Description("Air National Guard")]
// 	air_national_guard,
// 	//[Description("Civilian")]
// 	civilian,
// 	//[Description("Family Member of a Veteran")]
// 	family_member_of_a_veteran
// }

// export const FamilyCareVeteranBranchOfServiceDiscription = new Map<number, string>([
// 	[ FamilyCareVeteranBranchOfService.army, 'Army' ],
// 	[ FamilyCareVeteranBranchOfService.marine_corps, 'Marine Corps' ],
// 	[ FamilyCareVeteranBranchOfService.navy, 'Navy' ],
// 	[ FamilyCareVeteranBranchOfService.air_force, 'Air Force' ],
// 	[ FamilyCareVeteranBranchOfService.space_force, 'Space Force' ],
// 	[ FamilyCareVeteranBranchOfService.coast_guard, 'Coast Guard' ],
// 	[ FamilyCareVeteranBranchOfService.army_national_guard, 'Army National Guard' ],
// 	[ FamilyCareVeteranBranchOfService.air_national_guard, 'Air National Guard' ],
// 	[ FamilyCareVeteranBranchOfService.civilian, 'Civilian' ],
// 	[ FamilyCareVeteranBranchOfService.family_member_of_a_veteran, 'Family Member of a Veteran' ]
// ]);

export enum FamilyCareVeteranCurrentStatus {
	//[Description("Honorable Discharge")]
	honorable_discharge,
	//[Description("General, Under Honorable Conditions")]
	general_under_honorable_conditions,
	//[Description("Under Other than Honorable Conditions")]
	under_other_than_honorable_conditions,
	//[Description("Bad Conduct")]
	bad_conduct,
	//[Description("Dishonorable Discharge")]
	dishonorable_discharge,
	//[Description("Active Duty")]
	active_duty,
	//[Description("Not A Veteran")]
	not_a_veteran
}

export const FamilyCareVeteranCurrentStatusDiscription = new Map<number, string>([
	[ FamilyCareVeteranCurrentStatus.honorable_discharge, 'Honorable Discharge' ],
	[ FamilyCareVeteranCurrentStatus.general_under_honorable_conditions, 'General, Under Honorable Conditions' ],
	[ FamilyCareVeteranCurrentStatus.under_other_than_honorable_conditions, 'Under Other than Honorable Conditions' ],
	[ FamilyCareVeteranCurrentStatus.bad_conduct, 'Bad Conduct' ],
	[ FamilyCareVeteranCurrentStatus.dishonorable_discharge, 'Dishonorable Discharge' ],
	[ FamilyCareVeteranCurrentStatus.active_duty, 'Active Duty' ],
	[ FamilyCareVeteranCurrentStatus.not_a_veteran, 'Not A Veteran' ]
]);

export enum FamilyCareVeteranVSOMembership {
	//[Description("Not A Member")]
	not_a_member,
	//[Description("At Large")]
	at_large,
	//[Description("Continuous")]
	continuous,
	//[Description("Life")]
	life,
	//[Description("New")]
	new,
	//[Description("New-Life")]
	new_life,
	//[Description("Reinstated")]
	reinstated,
	//[Description("Reinstated-Life")]
	reinstated_life
}

export const FamilyCareVeteranVSOMembershipDiscription = new Map<number, string>([
	[ FamilyCareVeteranVSOMembership.not_a_member, 'Not A Member' ],
	[ FamilyCareVeteranVSOMembership.at_large, 'At Large' ],
	[ FamilyCareVeteranVSOMembership.continuous, 'Continuous' ],
	[ FamilyCareVeteranVSOMembership.life, 'Life' ],
	[ FamilyCareVeteranVSOMembership.new_life, 'New-Life' ],
	[ FamilyCareVeteranVSOMembership.reinstated, 'Reinstated' ],
	[ FamilyCareVeteranVSOMembership.reinstated_life, 'Reinstated-Life' ]
]);

export enum FamilyCareVeteranWarService {
	na,
	afghanistan,
	albania,
	bosnia,
	cambodia,
	chad,
	china,
	congo,
	cyprus,
	dominican_republic,
	east_timor,
	egypt,
	el_salvador,
	europe,
	guinea_bissau,
	haiti,
	horn_of_africa,
	iran,
	iraq,
	italy,
	kosovo,
	lebanon,
	liberia,
	liberia_and_senegal,
	libya,
	palestine,
	panama,
	persian_gulf,
	sierra_leone,
	sinai,
	somalia,
	south_korea,
	syria,
	thailand,
	trieste,
	vietnam,
	west_africa,
	zaire
}

export const FamilyCareVeteranWarServiceDescription = new Map<number, string>([
	[ FamilyCareVeteranWarService.na, 'N/A' ],
	[ FamilyCareVeteranWarService.afghanistan, 'Afghanistan' ],
	[ FamilyCareVeteranWarService.albania, 'Albania' ],
	[ FamilyCareVeteranWarService.bosnia, 'Bosnia' ],
	[ FamilyCareVeteranWarService.cambodia, 'Cambodia' ],
	[ FamilyCareVeteranWarService.chad, 'Chad' ],
	[ FamilyCareVeteranWarService.china, 'China' ],
	[ FamilyCareVeteranWarService.congo, 'Congo' ],
	[ FamilyCareVeteranWarService.cyprus, 'Cyprus' ],
	[ FamilyCareVeteranWarService.dominican_republic, 'Dominican Republic' ],
	[ FamilyCareVeteranWarService.east_timor, 'East Timor' ],
	[ FamilyCareVeteranWarService.egypt, 'Egypt' ],
	[ FamilyCareVeteranWarService.el_salvador, 'El Salvador' ],
	[ FamilyCareVeteranWarService.europe, 'Europe' ],
	[ FamilyCareVeteranWarService.guinea_bissau, 'Guinea-Bissau' ],
	[ FamilyCareVeteranWarService.haiti, 'Haiti' ],
	[ FamilyCareVeteranWarService.horn_of_africa, 'Horn Of Africa' ],
	[ FamilyCareVeteranWarService.iran, 'Iran' ],
	[ FamilyCareVeteranWarService.iraq, 'Iraq' ],
	[ FamilyCareVeteranWarService.italy, 'Italy' ],
	[ FamilyCareVeteranWarService.kosovo, 'Kosovo' ],
	[ FamilyCareVeteranWarService.lebanon, 'Lebanon' ],
	[ FamilyCareVeteranWarService.liberia, 'Liberia' ],
	[ FamilyCareVeteranWarService.liberia_and_senegal, 'Liberia And Senegal' ],
	[ FamilyCareVeteranWarService.libya, 'Libya' ],
	[ FamilyCareVeteranWarService.palestine, 'Palestine' ],
	[ FamilyCareVeteranWarService.panama, 'Panama' ],
	[ FamilyCareVeteranWarService.persian_gulf, 'Persian Gulf' ],
	[ FamilyCareVeteranWarService.sierra_leone, 'Sierra Leone' ],
	[ FamilyCareVeteranWarService.sinai, 'Sinai' ],
	[ FamilyCareVeteranWarService.somalia, 'Somalia' ],
	[ FamilyCareVeteranWarService.south_korea, 'South Korea' ],
	[ FamilyCareVeteranWarService.syria, 'Syria' ],
	[ FamilyCareVeteranWarService.thailand, 'Thailand' ],
	[ FamilyCareVeteranWarService.trieste, 'Trieste' ],
	[ FamilyCareVeteranWarService.vietnam, 'Vietnam' ],
	[ FamilyCareVeteranWarService.west_africa, 'West Africa' ],
	[ FamilyCareVeteranWarService.zaire, 'Zaire' ]
]);

export enum FamilyCareVeteranCurrentVSOMember {
	na,
	african_american_ptsd_association,
	alabama_department_of_veterans_affairs,
	american_ex_pow,
	american_legion,
	american_red_cross,
	american_veterans,
	amgi_forum,
	arizona_department_of_veterans_services,
	arkansas_department_of_veterans_affairs,
	armed_forces_services_corporation,
	army_and_navy_union_usa,
	blinded_veterans_association,
	california_department_of_veterans_services,
	catholic_war_veterans_of_the_usa,
	colorado_division_of_veterans_affairs,
	commonwealth_of_the_northern_mariana_islands_division,
	connecticut_department_of_veterans_affairs,
	dale_k_graham_veterans_foundation,
	delaware_commission_of_veterans_affairs,
	disabled_american_veterans,
	fleet_reserve_association,
	florida_department_of_veterans_affairs,
	georgia_department_of_veterans_service,
	gold_star_wives_of_america_inc,
	green_beret_foundation,
	guam_office_of_veterans_affairs,
	hawaii_office_of_veterans_services,
	idaho_division_of_veterans_services,
	illinois_department_of_veterans_affairs,
	indiana_department_of_veterans_affairs,
	iowa_department_of_veterans_affairs,
	italian_american_war_veterans_of_the_us_inc,
	jewish_war_veterans_of_the_usa,
	kansas_commission_on_veterans_affairs,
	kentucky_department_of_veterans_affairs,
	legion_of_valor_of_the_usa_inc,
	louisiana_department_of_veterans_affairs,
	maine_veterans_services,
	marine_corps_league,
	maryland_department_of_veterans_affairs,
	massachusetts_department_of_veterans_services,
	michigan_veterans_affairs_agency,
	military_officers_association_of_america,
	minnesota_department_of_veterans_affairs,
	mississippi_veterans_affairs,
	missouri_veterans_commission,
	montana_veterans_affairs_mvad,
	national_association_for_black_veterans_inc,
	national_association_of_county_veterans_service,
	national_law_school_veterans_clinic_consortium,
	national_veterans_legal_services_program,
	national_veterans_organization_of_america,
	navajo_nation_veterans_administration,
	navy_mutual_aid_association,
	nebraska_department_of_veterans_affairs,
	nevada_department_of_veterans_services,
	new_hampshire_division_of_veteran_services,
	new_jersey_department_of_military_and_veterans_aff,
	new_mexico_department_of_veterans_services,
	new_york_state_division_of_veterans_affairs,
	north_carolina_dept_military_and_veterans_affairs,
	north_dakota_department_veterans_affairs,
	office_of_veterans_affairs_american_samoa_governme,
	ohio_department_of_veterans_services,
	oklahoma_department_of_veterans_affairs,
	oregon_department_of_veterans_affairs,
	organization_name,
	paralyzed_veterans_of_america,
	pennsylvania_department_of_military_and_veterans_a,
	polish_legion_of_american_veterans,
	puerto_rico_veterans_advocate_office,
	rhode_island_office_of_veterans_affairs_riova,
	south_dakota_department_of_veterans_affairs,
	swords_to_plowshares,
	tennessee_department_of_veterans_services,
	texas_veterans_commission,
	the_retired_enlisted_association,
	the_south_carolina_department_of_veterans_affairs,
	united_spanish_war_veterans,
	united_spinal_association,
	utah_department_of_veterans_and_military_affairs,
	vermont_office_of_veterans_affairs,
	veterans_of_foreign_wars,
	vietnam_veterans_of_america,
	virginia_department_of_veterans_services,
	virgin_islands_office_of_veterans_affairs,
	washington_department_of_veterans_affairs,
	west_virginia_dept_of_veterans_assistance,
	wisconsin_department_of_veterans_affairs,
	wounded_warrior_project,
	wyoming_veterans_commission
}

export const FamilyCareVeteranCurrentVSOMemberDescription = new Map<number, string>([
	[ FamilyCareVeteranCurrentVSOMember.na, 'N/A' ],
	[ FamilyCareVeteranCurrentVSOMember.african_american_ptsd_association, 'African American PTSD Association' ],
	[ FamilyCareVeteranCurrentVSOMember.alabama_department_of_veterans_affairs, 'Alabama Department of Veteran Affairs' ],
	[ FamilyCareVeteranCurrentVSOMember.american_ex_pow, 'American Ex-POW' ],
	[ FamilyCareVeteranCurrentVSOMember.american_legion, 'American Legion' ],
	[ FamilyCareVeteranCurrentVSOMember.american_red_cross, 'American Red Cross' ],
	[ FamilyCareVeteranCurrentVSOMember.american_veterans, 'American Veterans' ],
	[ FamilyCareVeteranCurrentVSOMember.amgi_forum, 'Am.GI Forum' ],
	[
		FamilyCareVeteranCurrentVSOMember.arizona_department_of_veterans_services,
		'Arizona Department of Veterans Services'
	],
	[
		FamilyCareVeteranCurrentVSOMember.arkansas_department_of_veterans_affairs,
		'Arkansas Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.armed_forces_services_corporation, 'Armed Forces Services Corporation' ],
	[ FamilyCareVeteranCurrentVSOMember.army_and_navy_union_usa, 'Army and Navy Union, USA' ],
	[ FamilyCareVeteranCurrentVSOMember.blinded_veterans_association, 'Blinded Veterans Association' ],
	[
		FamilyCareVeteranCurrentVSOMember.california_department_of_veterans_services,
		'California Department of Veterans Services'
	],
	[ FamilyCareVeteranCurrentVSOMember.catholic_war_veterans_of_the_usa, 'Catholic War Veterans of the USA' ],
	[ FamilyCareVeteranCurrentVSOMember.colorado_division_of_veterans_affairs, 'Colorado Division of Veterans Affairs' ],
	[
		FamilyCareVeteranCurrentVSOMember.commonwealth_of_the_northern_mariana_islands_division,
		'Commonwealth of the Northern Mariana Islands Divis'
	],
	[
		FamilyCareVeteranCurrentVSOMember.connecticut_department_of_veterans_affairs,
		'Connecticut Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.dale_k_graham_veterans_foundation, 'Dale K. Graham Veterans Foundation' ],
	[
		FamilyCareVeteranCurrentVSOMember.delaware_commission_of_veterans_affairs,
		'Delaware Commission of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.disabled_american_veterans, 'Disabled American Veterans' ],
	[ FamilyCareVeteranCurrentVSOMember.fleet_reserve_association, 'Fleet Reserve Association' ],
	[
		FamilyCareVeteranCurrentVSOMember.florida_department_of_veterans_affairs,
		'Florida Department of Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.georgia_department_of_veterans_service,
		'Georgia Department of Veterans Service'
	],
	[ FamilyCareVeteranCurrentVSOMember.gold_star_wives_of_america_inc, 'Gold Star Wives of America, Inc.' ],
	[ FamilyCareVeteranCurrentVSOMember.green_beret_foundation, 'Green Beret Foundation' ],
	[ FamilyCareVeteranCurrentVSOMember.guam_office_of_veterans_affairs, 'Guam Office of Veterans Affairs' ],
	[ FamilyCareVeteranCurrentVSOMember.hawaii_office_of_veterans_services, 'Hawaii Office of Veterans Services' ],
	[ FamilyCareVeteranCurrentVSOMember.idaho_division_of_veterans_services, 'Idaho Division of Veterans Services' ],
	[
		FamilyCareVeteranCurrentVSOMember.illinois_department_of_veterans_affairs,
		'Illinois Department of Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.indiana_department_of_veterans_affairs,
		'Indiana Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.iowa_department_of_veterans_affairs, 'Iowa Department of Veterans Affairs' ],
	[
		FamilyCareVeteranCurrentVSOMember.italian_american_war_veterans_of_the_us_inc,
		'Italian American War Veterans of the US, Inc.'
	],
	[ FamilyCareVeteranCurrentVSOMember.jewish_war_veterans_of_the_usa, 'Jewish War Veterans of the USA' ],
	[ FamilyCareVeteranCurrentVSOMember.kansas_commission_on_veterans_affairs, "Kansas Commission on Veterans' Affairs" ],
	[
		FamilyCareVeteranCurrentVSOMember.kentucky_department_of_veterans_affairs,
		'Kentucky Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.legion_of_valor_of_the_usa_inc, 'Legion of Valor of the USA, Inc.' ],
	[
		FamilyCareVeteranCurrentVSOMember.louisiana_department_of_veterans_affairs,
		'Louisiana Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.maine_veterans_services, "Maine Veterans' Services" ],
	[ FamilyCareVeteranCurrentVSOMember.marine_corps_league, 'Marine Corps League' ],
	[
		FamilyCareVeteranCurrentVSOMember.maryland_department_of_veterans_affairs,
		'Maryland Department of Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.massachusetts_department_of_veterans_services,
		"Massachusetts Department of Veterans' Services"
	],
	[ FamilyCareVeteranCurrentVSOMember.michigan_veterans_affairs_agency, 'Michigan Veterans Affairs Agency' ],
	[
		FamilyCareVeteranCurrentVSOMember.military_officers_association_of_america,
		'Military Officers Association of America'
	],
	[
		FamilyCareVeteranCurrentVSOMember.minnesota_department_of_veterans_affairs,
		'Minnesota Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.mississippi_veterans_affairs, 'Mississippi Veterans Affairs' ],
	[ FamilyCareVeteranCurrentVSOMember.missouri_veterans_commission, 'Missouri Veterans Commission' ],
	[ FamilyCareVeteranCurrentVSOMember.montana_veterans_affairs_mvad, 'Montana Veterans Affairs (MVAD)' ],
	[
		FamilyCareVeteranCurrentVSOMember.national_association_for_black_veterans_inc,
		'National Association for Black Veterans, Inc.'
	],
	[
		FamilyCareVeteranCurrentVSOMember.national_association_of_county_veterans_service,
		'National Association of County Veterans Service'
	],
	[
		FamilyCareVeteranCurrentVSOMember.national_law_school_veterans_clinic_consortium,
		'National Law School Veterans Clinic Consortium'
	],
	[
		FamilyCareVeteranCurrentVSOMember.national_veterans_legal_services_program,
		'National Veterans Legal Services Program'
	],
	[
		FamilyCareVeteranCurrentVSOMember.national_veterans_organization_of_america,
		'National Veterans Organization of America'
	],
	[ FamilyCareVeteranCurrentVSOMember.navajo_nation_veterans_administration, 'Navajo Nation Veterans Administration' ],
	[ FamilyCareVeteranCurrentVSOMember.navy_mutual_aid_association, 'Navy Mutual Aid Association' ],
	[
		FamilyCareVeteranCurrentVSOMember.nebraska_department_of_veterans_affairs,
		'Nebraska Department of Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.nevada_department_of_veterans_services,
		'Nevada Department of Veterans Services'
	],
	[
		FamilyCareVeteranCurrentVSOMember.new_hampshire_division_of_veteran_services,
		'New Hampshire Division of Veteran Services'
	],
	[
		FamilyCareVeteranCurrentVSOMember.new_jersey_department_of_military_and_veterans_aff,
		'New Jersey Department of Military and Veterans Aff'
	],
	[
		FamilyCareVeteranCurrentVSOMember.new_mexico_department_of_veterans_services,
		'New Mexico Department of Veterans Services'
	],
	[
		FamilyCareVeteranCurrentVSOMember.new_york_state_division_of_veterans_affairs,
		"New York State Division of Veterans' Affairs"
	],
	[
		FamilyCareVeteranCurrentVSOMember.north_carolina_dept_military_and_veterans_affairs,
		'North Carolina Dept Military and Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.north_dakota_department_veterans_affairs,
		'North Dakota Department Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.office_of_veterans_affairs_american_samoa_governme,
		'Office of Veterans Affairs American Samoa Governme'
	],
	[ FamilyCareVeteranCurrentVSOMember.ohio_department_of_veterans_services, 'Ohio Department of Veterans Services' ],
	[
		FamilyCareVeteranCurrentVSOMember.oklahoma_department_of_veterans_affairs,
		'Oklahoma Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.oregon_department_of_veterans_affairs, 'Oregon Department of Veterans Affairs' ],
	[ FamilyCareVeteranCurrentVSOMember.organization_name, 'Organization Name' ],
	[ FamilyCareVeteranCurrentVSOMember.paralyzed_veterans_of_america, 'Paralyzed Veterans of America' ],
	[
		FamilyCareVeteranCurrentVSOMember.pennsylvania_department_of_military_and_veterans_a,
		'Pennsylvania Department of Military and Veterans A'
	],
	[ FamilyCareVeteranCurrentVSOMember.polish_legion_of_american_veterans, 'Polish Legion of American Veterans' ],
	[ FamilyCareVeteranCurrentVSOMember.puerto_rico_veterans_advocate_office, 'Puerto Rico Veterans Advocate Office' ],
	[
		FamilyCareVeteranCurrentVSOMember.rhode_island_office_of_veterans_affairs_riova,
		'Rhode Island Office of Veterans Affairs (RIOVA)'
	],
	[
		FamilyCareVeteranCurrentVSOMember.south_dakota_department_of_veterans_affairs,
		'South Dakota Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.swords_to_plowshares, 'Swords to Plowshares' ],
	[
		FamilyCareVeteranCurrentVSOMember.tennessee_department_of_veterans_services,
		'Tennessee Department of Veterans Services'
	],
	[ FamilyCareVeteranCurrentVSOMember.texas_veterans_commission, 'Texas Veterans Commission' ],
	[ FamilyCareVeteranCurrentVSOMember.the_retired_enlisted_association, 'The Retired Enlisted Association' ],
	[
		FamilyCareVeteranCurrentVSOMember.the_south_carolina_department_of_veterans_affairs,
		'The South Carolina Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.united_spanish_war_veterans, 'United Spanish War Veterans' ],
	[ FamilyCareVeteranCurrentVSOMember.united_spinal_association, 'United Spinal Association' ],
	[
		FamilyCareVeteranCurrentVSOMember.utah_department_of_veterans_and_military_affairs,
		'Utah Department of Veterans and Military Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.vermont_office_of_veterans_affairs, 'Vermont Office of Veterans Affairs' ],
	[ FamilyCareVeteranCurrentVSOMember.veterans_of_foreign_wars, 'Veterans of Foreign Wars' ],
	[ FamilyCareVeteranCurrentVSOMember.vietnam_veterans_of_america, 'Vietnam Veterans of America' ],
	[
		FamilyCareVeteranCurrentVSOMember.virginia_department_of_veterans_services,
		'Virginia Department of Veterans Services'
	],
	[
		FamilyCareVeteranCurrentVSOMember.virgin_islands_office_of_veterans_affairs,
		'Virgin Islands Office of Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.washington_department_of_veterans_affairs,
		'Washington Department of Veterans Affairs'
	],
	[
		FamilyCareVeteranCurrentVSOMember.west_virginia_dept_of_veterans_assistance,
		'West Virginia Dept of Veterans Assistance'
	],
	[
		FamilyCareVeteranCurrentVSOMember.wisconsin_department_of_veterans_affairs,
		'Wisconsin Department of Veterans Affairs'
	],
	[ FamilyCareVeteranCurrentVSOMember.wounded_warrior_project, 'Wounded Warrior Project' ],
	[ FamilyCareVeteranCurrentVSOMember.wyoming_veterans_commission, 'Wyoming Veterans Commission' ]
]);

export enum FamilyCareVeteranRankRateAtDischarge {
	admiral,
	airman,
	airman_basic,
	airman_first_class,
	brigadier_general,
	captain,
	chief_master_sergeant,
	chief_master_sergeant_of_the_space_force,
	chief_petty_officer,
	chief_warrant_officer_2,
	chief_warrant_officer_3,
	chief_warrant_officer_4,
	chief_warrant_officer_5,
	colonel,
	commandant_of_the_coast_guard,
	commander,
	command_sergeant_major,
	corporal,
	ensign,
	first_lieutenant,
	first_sergeant,
	first_sergeant_chief_master_sergeant,
	first_sergeant_master_sergeant,
	first_sergeant_senior_master_sergeant,
	fleet_admiral,
	general,
	general_of_the_air_force,
	gunnery_sergeant,
	junior_grade,
	lance_corporal,
	lieutenant,
	lieutenant_colonel,
	lieutenant_commander,
	lieutenant_general,
	major,
	major_general,
	master_chief_petty_officer,
	master_chief_petty_officer_of_the_navy,
	master_gunnery_sergeant,
	master_sergeant,
	master_warrant_officer,
	master_warrant_officer_5,
	air_force_chief_of_staff_general,
	army_chief_of_staff,
	chief_of_naval_operations,
	commandant_of_the_marine_corps,
	petty_officer_first_class,
	petty_officer_second_class,
	petty_officer_third_class,
	private,
	private_first_class,
	private_recruit,
	rear_admiral_commodore,
	rear_admiral_upper_half,
	seaman,
	seaman_apprentice,
	seaman_recruit,
	second_lieutenant,
	senior_airman,
	senior_chief_petty_officer,
	senior_master_sergeant,
	sergeant,
	sergeant_first_class,
	sergeant_major,
	sergeant_major_of_the_army,
	special,
	special_chief_master_sergeant_of_the_air_force,
	specialist,
	specialist_1,
	specialist_2,
	specialist_3,
	specialist_4,
	special_master_chief_petty_officer_of_the_navy,
	special_sergeant_major_of_the_marine_corps,
	staff_sergeant,
	technical_sergeant,
	vice_admiral,
	warrant_officer,
	warrant_officer_1,
	warrant_officer_2,
	warrant_officer_3,
	warrant_officer_4
}
export const FamilyCareVeteranRankRateAtDischargeDescription = new Map<number, string>([
	[ FamilyCareVeteranRankRateAtDischarge.admiral, 'Admiral' ],
	[ FamilyCareVeteranRankRateAtDischarge.airman, 'Airman ' ],
	[ FamilyCareVeteranRankRateAtDischarge.airman_basic, 'Airman Basic' ],
	[ FamilyCareVeteranRankRateAtDischarge.airman_first_class, 'Airman First Class' ],
	[ FamilyCareVeteranRankRateAtDischarge.brigadier_general, 'Brigadier General ' ],
	[ FamilyCareVeteranRankRateAtDischarge.captain, 'Captain' ],
	[ FamilyCareVeteranRankRateAtDischarge.chief_master_sergeant, 'Chief Master Sergeant' ],
	[
		FamilyCareVeteranRankRateAtDischarge.chief_master_sergeant_of_the_space_force,
		'Chief Master Sergeant of the Space Force'
	],
	[ FamilyCareVeteranRankRateAtDischarge.chief_petty_officer, 'Chief Petty Officer' ],
	[ FamilyCareVeteranRankRateAtDischarge.chief_warrant_officer_2, 'Chief Warrant Officer 2' ],
	[ FamilyCareVeteranRankRateAtDischarge.chief_warrant_officer_3, 'Chief Warrant Officer 3' ],
	[ FamilyCareVeteranRankRateAtDischarge.chief_warrant_officer_4, 'Chief Warrant Officer 4' ],
	[ FamilyCareVeteranRankRateAtDischarge.chief_warrant_officer_5, 'Chief Warrant Officer 5' ],
	[ FamilyCareVeteranRankRateAtDischarge.colonel, 'Colonel' ],
	[ FamilyCareVeteranRankRateAtDischarge.commandant_of_the_coast_guard, 'Commandant of the Coast Guard ' ],
	[ FamilyCareVeteranRankRateAtDischarge.commander, 'Commander ' ],
	[ FamilyCareVeteranRankRateAtDischarge.command_sergeant_major, 'Command Sergeant Major ' ],
	[ FamilyCareVeteranRankRateAtDischarge.corporal, 'Corporal' ],
	[ FamilyCareVeteranRankRateAtDischarge.ensign, 'Ensign ' ],
	[ FamilyCareVeteranRankRateAtDischarge.first_lieutenant, 'First Lieutenant' ],
	[ FamilyCareVeteranRankRateAtDischarge.first_sergeant, 'First Sergeant' ],
	[
		FamilyCareVeteranRankRateAtDischarge.first_sergeant_chief_master_sergeant,
		'First Sergeant (Chief Master Sergeant)'
	],
	[ FamilyCareVeteranRankRateAtDischarge.first_sergeant_master_sergeant, 'First Sergeant (Master Sergeant)' ],
	[
		FamilyCareVeteranRankRateAtDischarge.first_sergeant_senior_master_sergeant,
		'First Sergeant (Senior Master Sergeant) '
	],
	[ FamilyCareVeteranRankRateAtDischarge.fleet_admiral, 'Fleet Admiral ' ],
	[ FamilyCareVeteranRankRateAtDischarge.general, 'General' ],
	[ FamilyCareVeteranRankRateAtDischarge.general_of_the_air_force, 'General of the Air Force ' ],
	[ FamilyCareVeteranRankRateAtDischarge.gunnery_sergeant, 'Gunnery Sergeant' ],
	[ FamilyCareVeteranRankRateAtDischarge.junior_grade, 'Junior Grade' ],
	[ FamilyCareVeteranRankRateAtDischarge.lance_corporal, 'Lance Corporal' ],
	[ FamilyCareVeteranRankRateAtDischarge.lieutenant, 'Lieutenant' ],
	[ FamilyCareVeteranRankRateAtDischarge.lieutenant_colonel, 'Lieutenant Colonel' ],
	[ FamilyCareVeteranRankRateAtDischarge.lieutenant_commander, 'Lieutenant Commander ' ],
	[ FamilyCareVeteranRankRateAtDischarge.lieutenant_general, 'Lieutenant General' ],
	[ FamilyCareVeteranRankRateAtDischarge.major, 'Major' ],
	[ FamilyCareVeteranRankRateAtDischarge.major_general, 'Major General ' ],
	[ FamilyCareVeteranRankRateAtDischarge.master_chief_petty_officer, 'Master Chief Petty Officer' ],
	[
		FamilyCareVeteranRankRateAtDischarge.master_chief_petty_officer_of_the_navy,
		'Master Chief Petty Officer of the Navy'
	],
	[ FamilyCareVeteranRankRateAtDischarge.master_gunnery_sergeant, 'Master Gunnery Sergeant' ],
	[ FamilyCareVeteranRankRateAtDischarge.master_sergeant, 'Master Sergeant ' ],
	[ FamilyCareVeteranRankRateAtDischarge.master_warrant_officer, 'Master Warrant Officer ' ],
	[ FamilyCareVeteranRankRateAtDischarge.master_warrant_officer_5, 'Master Warrant Officer 5 ' ],
	[ FamilyCareVeteranRankRateAtDischarge.air_force_chief_of_staff_general, 'Air Force Chief of Staff General' ],
	[ FamilyCareVeteranRankRateAtDischarge.army_chief_of_staff, 'Army Chief of Staff' ],
	[ FamilyCareVeteranRankRateAtDischarge.chief_of_naval_operations, 'Chief of Naval Operations' ],
	[ FamilyCareVeteranRankRateAtDischarge.commandant_of_the_marine_corps, 'Commandant of the Marine Corps' ],
	[ FamilyCareVeteranRankRateAtDischarge.petty_officer_first_class, 'Petty Officer First Class' ],
	[ FamilyCareVeteranRankRateAtDischarge.petty_officer_second_class, 'Petty Officer Second Class' ],
	[ FamilyCareVeteranRankRateAtDischarge.petty_officer_third_class, 'Petty Officer Third Class' ],
	[ FamilyCareVeteranRankRateAtDischarge.private, 'Private' ],
	[ FamilyCareVeteranRankRateAtDischarge.private_first_class, 'Private First Class' ],
	[ FamilyCareVeteranRankRateAtDischarge.private_recruit, 'Private (Recruit) ' ],
	[ FamilyCareVeteranRankRateAtDischarge.rear_admiral_commodore, 'Rear Admiral (Commodore) ' ],
	[ FamilyCareVeteranRankRateAtDischarge.rear_admiral_upper_half, 'Rear Admiral (Upper Half)' ],
	[ FamilyCareVeteranRankRateAtDischarge.seaman, 'Seaman ' ],
	[ FamilyCareVeteranRankRateAtDischarge.seaman_apprentice, 'Seaman Apprentice ' ],
	[ FamilyCareVeteranRankRateAtDischarge.seaman_recruit, 'Seaman Recruit' ],
	[ FamilyCareVeteranRankRateAtDischarge.second_lieutenant, 'Second Lieutenant ' ],
	[ FamilyCareVeteranRankRateAtDischarge.senior_airman, 'Senior Airman ' ],
	[ FamilyCareVeteranRankRateAtDischarge.senior_chief_petty_officer, 'Senior Chief Petty Officer' ],
	[ FamilyCareVeteranRankRateAtDischarge.senior_master_sergeant, 'Senior Master Sergeant ' ],
	[ FamilyCareVeteranRankRateAtDischarge.sergeant, 'Sergeant' ],
	[ FamilyCareVeteranRankRateAtDischarge.sergeant_first_class, 'Sergeant First Class ' ],
	[ FamilyCareVeteranRankRateAtDischarge.sergeant_major, 'Sergeant Major' ],
	[ FamilyCareVeteranRankRateAtDischarge.sergeant_major_of_the_army, 'Sergeant Major of the Army' ],
	[ FamilyCareVeteranRankRateAtDischarge.special, 'Special' ],
	[
		FamilyCareVeteranRankRateAtDischarge.special_chief_master_sergeant_of_the_air_force,
		'Special Chief Master Sergeant of the Air Force'
	],
	[ FamilyCareVeteranRankRateAtDischarge.specialist, 'Specialist' ],
	[ FamilyCareVeteranRankRateAtDischarge.specialist_1, 'Specialist 1' ],
	[ FamilyCareVeteranRankRateAtDischarge.specialist_2, 'Specialist 2' ],
	[ FamilyCareVeteranRankRateAtDischarge.specialist_3, 'Specialist 3' ],
	[ FamilyCareVeteranRankRateAtDischarge.specialist_4, 'Specialist 4' ],
	[
		FamilyCareVeteranRankRateAtDischarge.special_master_chief_petty_officer_of_the_navy,
		'Special Master Chief Petty Officer of the Navy'
	],
	[
		FamilyCareVeteranRankRateAtDischarge.special_sergeant_major_of_the_marine_corps,
		'Special Sergeant Major of the Marine Corps '
	],
	[ FamilyCareVeteranRankRateAtDischarge.staff_sergeant, 'Staff Sergeant' ],
	[ FamilyCareVeteranRankRateAtDischarge.technical_sergeant, 'Technical Sergeant' ],
	[ FamilyCareVeteranRankRateAtDischarge.vice_admiral, 'Vice Admiral' ],
	[ FamilyCareVeteranRankRateAtDischarge.warrant_officer, 'Warrant Officer ' ],
	[ FamilyCareVeteranRankRateAtDischarge.warrant_officer_1, 'Warrant Officer 1 ' ],
	[ FamilyCareVeteranRankRateAtDischarge.warrant_officer_2, 'Warrant Officer 2 ' ],
	[ FamilyCareVeteranRankRateAtDischarge.warrant_officer_3, 'Warrant Officer 3 ' ],
	[ FamilyCareVeteranRankRateAtDischarge.warrant_officer_4, 'Warrant Officer 4 ' ]
]);

export enum FamilyCareVeteranVALifeInsuranceCoverage {
	//[Description("Veterans Group Life Insurance")]
	veterans_group_life_insurance,
	//[Description("Veterans Affairs Life Insurance")]
	veterans_affairs_life_insurance,
	//[Description("No Coverage")]
	no_coverage
}

export const FamilyCareVeteranVALifeInsuranceCoverageDescription = new Map<number, string>([
	[ FamilyCareVeteranVALifeInsuranceCoverage.veterans_group_life_insurance, 'Veterans Group Life Insurance' ],
	[ FamilyCareVeteranVALifeInsuranceCoverage.veterans_affairs_life_insurance, 'Veterans Affairs Life Insurance' ],
	[ FamilyCareVeteranVALifeInsuranceCoverage.no_coverage, 'No Coverage' ]
]);

export enum FamilyCareVeteranRelationShipToYou {
	//[Description("Parent")]
	parent,
	//[Description("Grandparent")]
	grandparent,
	//[Description("Child")]
	child,
	//[Description("Sibling")]
	sibling,
	//[Description("Widow/Widower")]
	widow_widower
}

export const FamilyCareVeteranRelationShipToYouDiscription = new Map<number, string>([
	[ FamilyCareVeteranRelationShipToYou.parent, 'Parent' ],
	[ FamilyCareVeteranRelationShipToYou.grandparent, 'Grandparent' ],
	[ FamilyCareVeteranRelationShipToYou.child, 'Child' ],
	[ FamilyCareVeteranRelationShipToYou.sibling, 'Sibling' ],
	[ FamilyCareVeteranRelationShipToYou.widow_widower, 'Widow/Widower' ]
]);

export enum FamilyCareVeteranCampaignMedalsReceived {
	//[Description("None")]
	none,
	//[Description("China Service Medal")]
	china_service_medal,
	//[Description("American Defense Service Medal")]
	american_defense_service_medal,
	//[Description("European-African-Middle Eastern Campaign Medal")]
	european_african_middle_eastern_campaign_medal,
	//[Description("American Campaign Medal")]
	american_campaign_medal,
	//[Description("Asiatic-Pacific Campaign Medal")]
	asiatic_pacific_campaign_medal,
	//[Description("Army of Occupation Medal")]
	army_of_occupation_medal,
	//[Description("Navy Occupation Service Medal")]
	navy_occupation_service_medal,
	//[Description("Korean Service Medal")]
	korean_service_medal,
	//[Description("Navy Expeditionary Medal")]
	navy_expeditionary_medal,
	//[Description("Marine Corps Expeditionary Medal")]
	marine_corps_expeditionary_medal,
	//[Description("Vietnam Service Medal")]
	vietnam_service_medal,
	//[Description("Armed Forces Expeditionary Medal")]
	armed_forces_expeditionary_medal,
	//[Description("Southwest Asia Service Medal")]
	southwest_asia_service_medal,
	//[Description("Kosovo Campaign Medal")]
	kosovo_campaign_medal,
	//[Description("Combat Infantryman Badge")]
	combat_infantryman_badge,
	//[Description("Combat Medical Badge")]
	combat_medical_badge,
	//[Description("Combat Action Ribbon")]
	combat_action_ribbon,
	//[Description("Combat Action Medal")]
	combat_action_medal,
	//[Description("Combat Action Badge")]
	combat_action_badge,
	//[Description("SSBN Deterrent Patrol Insignia")]
	ssbn_deterrent_patrol_insignia,
	//[Description("Korea Defense Service Medal")]
	korea_defense_service_medal,
	//[Description("Global War On Terrorism Expeditionary Medal")]
	global_war_on_terrorism_expeditionary_medal,
	//[Description("Afghanistan Campaign Medal")]
	afghanistan_campaign_medal,
	//[Description("Iraq Campaign Medal")]
	iraq_campaign_medal,
	//[Description("Inherent Resolve Campaign Medal")]
	inherent_resolve_campaign_medal,
	//[Description("Air and Space Expeditionary Service Ribbon")]
	air_and_space_expeditionary_service_ribbon,
	//[Description("Korea Duty")]
	korea_duty,
	//[Description("Hostile Fire Pay or Imminent Danger Pay")]
	hostile_fire_pay_or_imminent_danger_pay
}

export const FamilyCareVeteranCampaignMedalsReceivedDescription = new Map<number, string>([
	[ FamilyCareVeteranCampaignMedalsReceived.none, 'None' ],
	[ FamilyCareVeteranCampaignMedalsReceived.china_service_medal, 'China Service Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.american_defense_service_medal, 'American Defense Service Medal' ],
	[
		FamilyCareVeteranCampaignMedalsReceived.european_african_middle_eastern_campaign_medal,
		'European-African-Middle Eastern Campaign Medal'
	],
	[ FamilyCareVeteranCampaignMedalsReceived.american_campaign_medal, 'American Campaign Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.asiatic_pacific_campaign_medal, 'Asiatic-Pacific Campaign Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.army_of_occupation_medal, 'Army of Occupation Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.navy_occupation_service_medal, 'Navy Occupation Service Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.korean_service_medal, 'Korean Service Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.navy_expeditionary_medal, 'Navy Expeditionary Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.marine_corps_expeditionary_medal, 'Marine Corps Expeditionary Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.vietnam_service_medal, 'Vietnam Service Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.armed_forces_expeditionary_medal, 'Armed Forces Expeditionary Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.southwest_asia_service_medal, 'Southwest Asia Service Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.kosovo_campaign_medal, 'Kosovo Campaign Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.combat_infantryman_badge, 'Combat Infantryman Badge' ],
	[ FamilyCareVeteranCampaignMedalsReceived.combat_medical_badge, 'Combat Medical Badge' ],
	[ FamilyCareVeteranCampaignMedalsReceived.combat_action_ribbon, 'Combat Action Ribbon' ],
	[ FamilyCareVeteranCampaignMedalsReceived.combat_action_medal, 'Combat Action Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.combat_action_badge, 'Combat Action Badge' ],
	[ FamilyCareVeteranCampaignMedalsReceived.ssbn_deterrent_patrol_insignia, 'SSBN Deterrent Patrol Insignia' ],
	[ FamilyCareVeteranCampaignMedalsReceived.korea_defense_service_medal, 'Korea Defense Service Medal' ],
	[
		FamilyCareVeteranCampaignMedalsReceived.global_war_on_terrorism_expeditionary_medal,
		'Global War On Terrorism Expeditionary Medal'
	],
	[ FamilyCareVeteranCampaignMedalsReceived.afghanistan_campaign_medal, 'Afghanistan Campaign Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.iraq_campaign_medal, 'Iraq Campaign Medal' ],
	[ FamilyCareVeteranCampaignMedalsReceived.inherent_resolve_campaign_medal, 'Inherent Resolve Campaign Medal' ],
	[
		FamilyCareVeteranCampaignMedalsReceived.air_and_space_expeditionary_service_ribbon,
		'Air and Space Expeditionary Service Ribbon'
	],
	[ FamilyCareVeteranCampaignMedalsReceived.korea_duty, 'Korea Duty' ],
	[ FamilyCareVeteranCampaignMedalsReceived.korea_duty, 'Hostile Fire Pay Or Imminent Danger Pay' ]
]);

export enum FamilyCareVeteranAuxiliaryMembership {
	//[Description("Not A Member")]
	not_a_member,
	//[Description("At Large")]
	at_large,
	//[Description("Continuous")]
	continuous,
	//[Description("Life")]
	life,
	//[Description("New")]
	new,
	//[Description("New-Life")]
	new_life,
	//[Description("Reinstated")]
	reinstated,
	//[Description("Reinstated-Life")]
	reinstated_life
}

export const FamilyCareVeteranAuxiliaryMembershipDiscription = new Map<number, string>([
	[ FamilyCareVeteranVSOMembership.not_a_member, 'Not A Member' ],
	[ FamilyCareVeteranVSOMembership.at_large, 'At Large' ],
	[ FamilyCareVeteranVSOMembership.continuous, 'Continuous' ],
	[ FamilyCareVeteranVSOMembership.life, 'Life' ],
	[ FamilyCareVeteranVSOMembership.new_life, 'New-Life' ],
	[ FamilyCareVeteranVSOMembership.reinstated, 'Reinstated' ],
	[ FamilyCareVeteranVSOMembership.reinstated_life, 'Reinstated-Life' ]
]);
