import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'
import PrimaryPerson from './PrimaryPerson'
import { PlanEntity, SpousePlan } from './PlanEntity'
import PlanSummary from './PlanSummary'
import Sponsership from './Sponsership'
import { GetParticularLanguageString } from '@/plugins/i18n'
import { GenderType } from '@/enumerations/GenderType'
import { Camelize } from '@/helpers/ArrayHelper'
import { MaritalStatus } from '@/enumerations/MaritalStatus'

export default class Spouse extends Model {
    static entity = 'spouses'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            FirstName: this.string(''),
            LastName: this.string(''),
            MiddleName: this.string(null).nullable(),
            SurName: this.string(null).nullable(),
            NickName: this.string(null).nullable(),
            PlaceOfBirth : this.string(null).nullable(),
            DateOfBirth: this.string(null).nullable(),
            Occupation: this.string(''),
            Gender: this.string(''),
            MaritalStatus: this.string(null).nullable(),
            IsNonBinary: this.boolean(null),
            InsuranceThroughWorkWholeLife: this.string(null).nullable(),
            InsuranceThroughWorkTermLife: this.string(null).nullable(),
            InsuranceOutsideWorkWholeLife: this.string(null).nullable(),
            InsuranceOutsideWorkTermLife: this.string(null).nullable(),
            InsuranceThroughWorkAccidental: this.string(null).nullable(),
            InsuranceOutsideWorkAccidental: this.string(null).nullable(),
            Premium: this.number(null).nullable(),
            Primary_Id: this.attr(null),
            AnyTobaccoUse: this.boolean(false),
            HadCancer: this.boolean(false),
            HadHeartDisease: this.boolean(false),
            Primary: this.belongsTo(PrimaryPerson, "Primary_Id"),
            Plans: this.belongsToMany(PlanEntity, SpousePlan, 'spouseId', 'planId'),
            PlanSummary_Id: this.attr(null),
            PlanSummary: this.belongsTo(PlanSummary, "PlanSummary_Id"),
            Sponsership: this.string(''),
            Wage: this.string(""),
            AgePerDOB: this.number(null).nullable(),
            DollarADayAmount: this.number(0),
            AnnualIncome: this.string(null).nullable(),
            PremiumCalculated: this.boolean(false),
            MonthlyPremium: this.string(null).nullable(),
            DOBAndAgeString: this.string(''),
            City: this.string(''),
            State: this.number(0),
            Address: this.string(null).nullable(),
            Email: this.string('').nullable(),
            Phone: this.string(''),
            Zip: this.string(''),
            InsuranceThroughWorkString: this.string(""),
			InsuranceOutsideWorkString: this.string(""),
        }
    }

    // get Age() {
    //     if (this.DateOfBirth == null || this.DateOfBirth == undefined || isNaN(EntityHelper.calculate_age(new Date(this.DateOfBirth))))
    //         return 0;

    //     this.AgePerDOB = EntityHelper.calculate_age(new Date(this.DateOfBirth));
    //     return this.AgePerDOB;
    // }

    // set Age(value: number) {
    //     this.AgePerDOB = value;
    // }

    get DateOfBirthTemp() {
        return this.DateOfBirth;
    }

    set DateOfBirthTemp(value: string) {
        this.DateOfBirth = value;
        this.AgePerDOB = EntityHelper.calculate_age(this.DateOfBirth);
    }

    public DateOfBirth!: string
    public Premium!: number
    public FirstName!: string
    public DollarADayAmount!: number
    public AgePerDOB!: number
    public PlanSummary_Id!: any
    public Plans!: any
    public Id!: any
    public PlanSummary!: PlanSummary
    public AnyTobaccoUse!: Boolean
    public HadCancer!: boolean;
    public HadHeartDisease!: boolean;
    public PremiumCalculated!: Boolean
    public Sponsership!: string
    public LastName!: string
    public MiddleName!: string
    public SurName!: string
    public NickName!: string
    public PlaceOfBirth!: string
    public Occupation!: string
    public Gender!: string
    public MaritalStatus!: string
    public IsNonBinary!: boolean
    public AnnualIncome!: string
    public MonthlyPremium!: number
    public InsuranceThroughWorkWholeLife!: string
    public InsuranceThroughWorkTermLife!: string
    public InsuranceOutsideWorkWholeLife!: string
    public InsuranceOutsideWorkTermLife!: string
    public InsuranceThroughWorkAccidental!: string
    public InsuranceOutsideWorkAccidental!: string
    public Wage!: string
    public DOBAndAgeString!: string
    public City !: string
    public State !: string
    public Address !: string
    public Email !: string
    public Phone !: string
    public Zip!: string
    public InsuranceThroughWorkString !: string
    public InsuranceOutsideWorkString !: string

    get GetNameHolder() {
        if (this.FirstName != null && this.FirstName != "") {
            return this.FirstName;
        }
        else if (this.LastName != null && this.LastName != "") {
            return this.LastName;
        }
        else {
            return GetParticularLanguageString("NeedAnalysis.Spouse");
        }
    }

    get fullname() {
        return this.FirstName + " " + this.LastName;
    }
    get fullnameCamelize() {
        var fullName = this.fullname;
        return Camelize(fullName);
    }

    get GenderTypeId() {
        if (this.Gender == GenderType[GenderType.MALE])
            return GenderType.MALE.toFixed();
        else if (this.Gender == GenderType[GenderType.FEMALE])
            return GenderType.FEMALE.toFixed();
        else return 0;
    }

    get MaritialStatusTypeId(){
        if (this.MaritalStatus == MaritalStatus[MaritalStatus.single])
            return MaritalStatus.single;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.married]){
            return MaritalStatus.married
        }
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.divorced])
            return MaritalStatus.divorced;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.widowed])
            return MaritalStatus.widowed;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.separated])
            return MaritalStatus.separated;
        else if (this.MaritalStatus == MaritalStatus[MaritalStatus.domestic_partner])
            return MaritalStatus.domestic_partner;
        else return 0;
    }
}