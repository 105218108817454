import { IndexDBConstants } from "@/constants/IndexDBConstants";
import PremiumPlanEntity from "@/entities/PremiumPlanEntity";
import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Report from "@/entities/Report";
import Spouse from "@/entities/Spouse";
import PremiumPlan from "@/models/PremiumPlan";
import moment from "moment";
import { GetHpProUserId } from "./APIExtentions";
import { BooleanToString, GetGenderNameById, GetGenderTypeId, GetWageTypeId, StringToBolean } from "./ArrayHelper";
import { getPersistedState } from "./StateMapper";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { ConvertIntoAmountFormatWithDecimal } from "./CalculationHelper";
import MemberSurvey from "@/entities/MemberSurvey";
import { MemberLookupType } from "../enumerations/MemberLookupType";
import MemberQuestionnaire from "@/entities/MemberQuestionnaire";
import FamilyCareSurvey from "@/entities/FamilyCareSurvey";
import { EntityHelper } from "@/helpers/EntityHelper";

export function PrsentationEntityToDTO(presentation: Presentation) {
    var presDTO: any = {};
    presDTO.PresentationGuid = presentation.Id;
    presDTO.CountryId = presentation.CountryId;
    presDTO.EndTime = presentation.EndTime;
    presDTO.FieldTrainingTypeId = presentation.FieldTrainingTypeId();
    presDTO.IsBenefitsSummaryCompleted = presentation.isBenefitsSummaryCompleted;
    presDTO.IsNeedAnalysisCompleted = presentation.isNeedAnalysisCompleted;
    presDTO.IsPlanGeneratorCompleted = presentation.isPlanGeneratorCompleted;
    presDTO.IsReportCardCompleted = presentation.isReportCardCompleted;
    presDTO.IsSenior = !presentation.isSuperCombo;
    // presDTO.Language = presentation.Language;
    presDTO.LeadId = presentation.LeadId;
    presDTO.NANotes = presentation.NANotes;
    presDTO.FPNotes = presentation.FPNotes;
    presDTO.ParentId = presentation.ParentCode;
    presDTO.PremiumTypeId = presentation.PremiumTypeId;
    presDTO.PresentationCode = presentation.Code;
    presDTO.PresentationTypeId = presentation.PresentationTypeId;
    presDTO.PresentedBy = GetHpProUserId();
    presDTO.PresentedGroupId = presentation.PresentedGroupId;
    presDTO.StartTime = presentation.StartTime;
    presDTO.StateId = presentation.StateId;
    // presDTO.LanguageId = presentation.LanguageId
    presDTO.StepCompleted = presentation.CurrentStepCompleted;
    presDTO.WhatHappenedString = presentation.WhatHappened;
    presDTO.VideoReviewCompleted = presentation.VideoReviewCompleted;
    presDTO.IsNoCostCompleted = presentation.isNoCostCompleted;
    presDTO.IsPresentPlanCompleted = presentation.isPresentPlanCompleted;
    presDTO.NeedAnalysisDurationTicks = presentation.TimeOnNeedAnalysis;
    presDTO.BenefitsSummaryDurationTicks = presentation.TimeOnBenefitSummary;
    presDTO.NoCostDurationTicks = presentation.TimeOnNoCostBenefits;
    presDTO.PlanGeneratorDurationTicks = presentation.TimeOnPlanGenerator;
    presDTO.PresentPlanDurationTicks = presentation.TimeOnPresentPlan;
    presDTO.ReportCardDurationTicks = presentation.TimeOnReportCard;
    presDTO.PresentationSubTypeId = presentation.PresentationSubTypeId;
    presDTO.WageTypeId = GetWageTypeId(presentation.WageType);
    presDTO.RateListVersion = presentation.RateListVersion;
    presDTO.ProductListVersion = presentation.ProductListVersion;
    presDTO.BenefitsSummaryCalculationVersion = presentation.BenefitsSummaryCalculationVersion;
    presDTO.PremiumPlan = presentation.PremiumPlan;
    presDTO.LicenseId = presentation.LicenseId;
    presDTO.AgentNumber = presentation.AgentCode;
    presDTO.FieldTrainingAssociateId = presentation.FieldTrainingAssociateId;
    presDTO.SGNumber = presentation.SGNumber;
    presDTO.Language = presentation.LanguageId;
    presDTO.MemberLookupTypeId = presentation.MemberLookupTypeId;
    presDTO.BenefitAndDocumentEmailCount = presentation.BenefitAndDocumentEmailSentCount;

    if (presentation.Referrals != undefined && presentation.Referrals != '') {
        try 
        {
            var presReferrals = JSON.parse(presentation.Referrals);
            presDTO.SponserShips = {};
            presDTO.SponserShips.SponsorshipGuid = presReferrals.SponsorshipGuid;
            presDTO.SponserShips.Date = presReferrals.Date;
            presDTO.SponserShips.Email = presReferrals.Email;
            presDTO.SponserShips.Organization = presReferrals.Organization;
            presDTO.SponserShips.Phone = presReferrals.Phone;
            presDTO.SponserShips.SavedLeadID = presReferrals.SavedLeadID;
            presDTO.SponserShips.ReferredByLastName = presReferrals.ReferredByLastName;
            presDTO.SponserShips.ReferredBy = presReferrals.ReferredBy;
            presDTO.SponserShips.AgentPhoneNo = presReferrals.AgentPhoneNo;
            presDTO.SponserShips.IsSent = presReferrals.IsSent;
            presDTO.SponserShips.ExtraPhone = [];
            if (presReferrals != undefined && presReferrals.ExtraPhone != undefined && presReferrals.ExtraPhone.length > 0){
                presReferrals.ExtraPhone.forEach((presRef: any) => {
                        presDTO.SponserShips.ExtraPhone.push({Name:presRef.Name,
                            Checked:presRef.Checked,
                            PhoneNo:presRef.PhoneNo
                        })
                });

            }
            presDTO.SponserShips.ReferralsList = [];
            if (presReferrals != undefined && presReferrals.ReferralsList != undefined && presReferrals.ReferralsList.length > 0)

                presReferrals.ReferralsList.forEach((presRef: any) => {
                    if (presRef != undefined && !isReferralEmpty(presRef))
                        presDTO.SponserShips.ReferralsList.push(presRef)
            });
        } catch (ex: any) {
            console.log("Fail to Parse or Update Referrals");
        }
    }
    if (presentation.BSMedicalQuestions != undefined && presentation.BSMedicalQuestions != "") {
        try {
            var genMedQues = JSON.parse(presentation.BSMedicalQuestions);
            presDTO.BSMedicalQuestion = [];
            if (genMedQues.senior != undefined) {
                for (var prop in genMedQues.senior) {
                    presDTO.BSMedicalQuestion.push(...genMedQues.senior[prop]);
                }
            }
            if (genMedQues.supercombo != undefined) {
                for (var prop in genMedQues.supercombo) {
                    presDTO.BSMedicalQuestion.push(...genMedQues.supercombo[prop]);
                }
            }
        } catch (ex: any) {
            console.log("Fail to Parse or Update BSMedicalQuestions");
        }
    }

    if (presentation.PlanOptions != undefined && presentation.PlanOptions != "") {
        try {
            var PrimaryProductsDisabled = presentation.PresentedTo.AgePerDOB == null  || presentation.PresentedTo.Gender == null || presentation.PresentedTo.Gender == ""
            var SpouseProductsDisabled = presentation.PresentedTo.Spouse.AgePerDOB == null ||  presentation.PresentedTo.Spouse.Gender == null || presentation.PresentedTo.Spouse.Gender == ""
            presDTO.PlanOptions = JSON.parse(presentation.PlanOptions);
            if(presDTO.PlanOptions != undefined && presDTO.PlanOptions.length>0){
                presDTO.PlanOptions.forEach((element:any)=>{
                    if(element.isSelected){
                        element.Products.forEach((prod: any)=>{
                            if(PrimaryProductsDisabled)
                            prod.Selected = false;
                        })
                        element.PrimaryProducts.forEach((Primaryprod: any)=>{
                            if(PrimaryProductsDisabled)
                            Primaryprod.Selected = false;
                        })
                        element.SpouseProducts.forEach((Spouseprod: any)=>{
                            if(SpouseProductsDisabled)
                            Spouseprod.Selected = false;
                        })
                    }
                })
            }
        } catch (ex: any) {
            console.log("Fail to Parse or Update PlanOptions");
        }
    }

    if (presentation.Report != undefined) {
        presDTO.BeneficiaryName = presentation.Report.BeneficiaryName;
        presDTO.ProgramValueRating = presentation.Report.ProgramValueRating;
        presDTO.RepRespectRating = presentation.Report.TimeRating;
        presDTO.PresentationCourteousRating = presentation.Report.CourteousRating;
        presDTO.RcComments = presentation.Report.Comments;
    }

    if (presentation.MedicalQuestionsNA != undefined && presentation.MedicalQuestionsNA != "") {
        try {
            var NAQuestions = JSON.parse(presentation.MedicalQuestionsNA);
            if (NAQuestions != undefined && NAQuestions.length > 0) {
                presDTO.NeedAnalysisQuestions = [];
                NAQuestions.forEach((element: any) => {
                    presDTO.NeedAnalysisQuestions.push(element);
                });
            }
        } catch (ex: any) {
            console.log("Fail to Parse or Update MedicalQuestionsNA");
        }
    }

    presDTO.CareerUpgrades = [];
    if (presentation.AllSponserships != undefined && presentation.AllSponserships != '') {
        try {
            var allSponsor = JSON.parse(presentation.AllSponserships);
            allSponsor.forEach((careerUpg: any) => {
                if (careerUpg != undefined && !isCareerUpgradeEmpty(careerUpg))
                    presDTO.CareerUpgrades.push(careerUpg)
            });
        } catch (error) {
            console.log("Fail to Parse or Update AllSponserships");
        }
    }
    
    if (presentation.PresentedTo != undefined) {
        presDTO.HasSpouse = presentation.PresentedTo.isSpouseExistForCalculation;
        presDTO.BeneficiaryName = presentation.PresentedTo.BeneficiaryName;
        presDTO.PrimaryFirstName = presentation.PresentedTo.FirstName;
        presDTO.PrimaryLastName = presentation.PresentedTo.LastName;

        presDTO.Email = presentation.PresentedTo.Email;
        presDTO.PrimaryDateOfBirth = presentation.PresentedTo.DateOfBirth;

        presDTO.PrimaryOccupation = presentation.PresentedTo.Occupation;
        presDTO.SpouseOccupation = presentation.PresentedTo.Occupation;
        presDTO.PrimaryGenderTypeId = presentation.PresentedTo.GenderTypeId;

        presDTO.PrimaryPhone = presentation.PresentedTo.Phone;
        presDTO.PrimaryZip = presentation.PresentedTo.Zip;

        presDTO.PrimaryMiddleName = presentation.PresentedTo.MiddleName;
        presDTO.PrimarySurname = presentation.PresentedTo.SurName;
        presDTO.PrimaryNickName = presentation.PresentedTo.NickName;
        presDTO.PrimaryPlaceOfBirth = presentation.PresentedTo.PlaceOfBirth;
        presDTO.PrimaryAddress = presentation.PresentedTo.Address;
        presDTO.PrimaryCity = presentation.PresentedTo.City;
        presDTO.PrimaryState = presentation.PresentedTo.State;
        presDTO.PrimaryMaritalStatus = presentation.PresentedTo.MaritialStatusTypeId;
        presDTO.PrimaryIsNonBinary = presentation.PresentedTo.IsNonBinary;

        presDTO.HasCheckingAccount = presentation.PresentedTo.Checking;
        presDTO.HasSavingAccount = presentation.PresentedTo.Saving;
        presDTO.HomeInsuranceInCaseOfDeathAmount = presentation.PresentedTo.HomeInsuranceInCaseOfDeathAmount;
        presDTO.Income = presentation.PresentedTo.Income;
        presDTO.IsRetired = presentation.PresentedTo.Retired;
        presDTO.HouseHoldType = presentation.PresentedTo.HouseHoldType;
        presDTO.MonthlyRent = presentation.PresentedTo.MonthlyRent;
        presDTO.MortgageBalance = presentation.PresentedTo.MortgageBalance;
        presDTO.MortgageInterestRate = presentation.PresentedTo.MortgageInterestRate;
        presDTO.MortgageYearsRemaining = presentation.PresentedTo.MortgageYearsRemaining;
        presDTO.OwnHome =  presentation.PresentedTo.OwnHome;
        presDTO.RentHome = presentation.PresentedTo.RentHome;
        presDTO.PrimaryInsuranceOutsideWorkTermLife = presentation.PresentedTo.InsuranceOutsideWorkTermLife;
        presDTO.PrimaryInsuranceOutsideWorkWholeLife = presentation.PresentedTo.InsuranceOutsideWorkWholeLife;
        presDTO.PrimaryInsuranceOutsideWorkAccidental = presentation.PresentedTo.InsuranceOutsideWorkAccidental;
        presDTO.PrimaryInsuranceThroughWorkTermLife = presentation.PresentedTo.InsuranceThroughWorkTermLife;
        presDTO.PrimaryInsuranceThroughWorkWholeLife = presentation.PresentedTo.InsuranceThroughWorkWholeLife;
        presDTO.PrimaryInsuranceThroughWorkAccidental = presentation.PresentedTo.InsuranceThroughWorkAccidental;
        presDTO.PrimaryPremiumAmount = presentation.PresentedTo.Premium;
        presDTO.ProvidedChildCollegeEducation = StringToBolean(presentation.PresentedTo.ProvidedChildCollegeEducation);
        presDTO.ProvidedChildCollegeEducationAmount = presentation.PresentedTo.ProvidedChildCollegeEducationAmount;
        presDTO.InsuranceToPayOffYouHome = StringToBolean(presentation.PresentedTo.HomeInsuranceInCaseOfDeath);
        
        presDTO.Expense = presentation.PresentedTo.AnnualExpense;
        presDTO.hasChildren = presentation.PresentedTo.HasChildrenUnder18;
        presDTO.PrimaryWage = presentation.PresentedTo.Wage;
        presDTO.NeedPercentage = presentation.NeedAmount;

        if (presentation.PresentedTo.Sponsership != undefined && presentation.PresentedTo.Sponsership != '') {
            try {
                var primarySponsor = JSON.parse(presentation.PresentedTo.Sponsership);
                primarySponsor.SponsorType = 1;
                if (primarySponsor != undefined && !isCareerUpgradeEmpty(primarySponsor))
                    presDTO.CareerUpgrades.push(primarySponsor)
            } catch (ex: any) {
                console.log("Fail to Parse or Update PresentedTo Sponsership");
            }
        }

        if (presentation.PresentedTo.ChildrenString != undefined && presentation.PresentedTo.ChildrenString != "") {
            try {
                var childrenList = JSON.parse(presentation.PresentedTo.ChildrenString);
                if (childrenList != undefined && childrenList.length > 0) {
                    presDTO.Childrens = [];
                    childrenList.forEach((element: any) => {
                        if ((element.FirstName != undefined && element.FirstName != "") ||
                            (element.LastName != undefined && element.LastName != "")) {
                            element.GenderTypeId = GetGenderTypeId(element.Gender);
                            presDTO.Childrens.push(element);
                        }
                    });
                }
            } catch (ex: any) {
                console.log("Fail to Parse or Update PresentedTo ChildrenString");
            }
        }
        presDTO.NeedAnalysisExpenses = {}
        if(presentation.PresentedTo.ExpenseString != undefined && presentation.PresentedTo.ExpenseString != ""){
            try {
                presDTO.NeedAnalysisExpenses = JSON.parse(presentation.PresentedTo.ExpenseString);
            } catch (ex: any) {
                console.log("Fail to Parse or Update PresentedTo ExpenseString");
            }
            
        }
        presDTO.NeedAnalysisProperties = []
        if (presentation.PresentedTo.NeedAnalysisPropertiesString != undefined && presentation.PresentedTo.NeedAnalysisPropertiesString != ""){
            try {
                 var NeedAnalysisPropertyList = JSON.parse(presentation.PresentedTo.NeedAnalysisPropertiesString);
            if(NeedAnalysisPropertyList != undefined && NeedAnalysisPropertyList.length>0){
                NeedAnalysisPropertyList.forEach((element: any)=>{
                    if(element.OwnOrRent == 'OWN'){
                        element.OwnHome = true;
                    }
                    else if(element.OwnOrRent == 'RENT'){
                        element.RentHome = true;
                    }
                    presDTO.NeedAnalysisProperties.push(element);
                })
            }
            } catch (ex: any) {
                console.log("Fail to Parse or Update PresentedTo NeedAnalysisPropertiesString");
            }
           
        }
        presDTO.NeedAnalysisInsuranceList = []
        if(presentation.PresentedTo.InsuranceThroughWorkString != undefined && presentation.PresentedTo.InsuranceThroughWorkString != ""){
            try {
                var NeedAnalysisPrimaryThroughWorkList = JSON.parse(presentation.PresentedTo.InsuranceThroughWorkString);
                if(NeedAnalysisPrimaryThroughWorkList != undefined && NeedAnalysisPrimaryThroughWorkList.length>0){
                    NeedAnalysisPrimaryThroughWorkList.forEach((element:any)=>{
                        element.InsuranceThroughWork = true
                        element.IsPrimary = true
                        presDTO.NeedAnalysisInsuranceList.push(element)
                    })
                }
            } catch (ex: any) {
                console.log("Fail to Parse or Update PresentedTo InsuranceThroughWorkString");
            }
           
        }
        if(presentation.PresentedTo.InsuranceOutsideWorkString != undefined && presentation.PresentedTo.InsuranceOutsideWorkString != ""){
            try {
                var NeedAnalysisPrimaryOutisdeWorkList = JSON.parse(presentation.PresentedTo.InsuranceOutsideWorkString);
                if(NeedAnalysisPrimaryOutisdeWorkList != undefined && NeedAnalysisPrimaryOutisdeWorkList.length>0){
                    NeedAnalysisPrimaryOutisdeWorkList.forEach((element:any)=>{
                        element.InsuranceOutsideWork = true
                        element.IsPrimary = true;
                        presDTO.NeedAnalysisInsuranceList.push(element)
                    })
                }
            } catch (ex: any) {
                console.log("Fail to Parse or Update PresentedTo InsuranceOutsideWorkString");
            }
           
        }

        if (presentation.PresentedTo.Spouse != undefined) {
            presDTO.SpouseGenderTypeId = presentation.PresentedTo.Spouse.GenderTypeId;
            presDTO.SpouseFirstName = presentation.PresentedTo.Spouse.FirstName;
            presDTO.SpouseLastName = presentation.PresentedTo.Spouse.LastName;
            presDTO.SpouseDateOfBirth = presentation.PresentedTo.Spouse.DateOfBirth;
            presDTO.SpouseEmail = presentation.PresentedTo.Spouse.Email;
            presDTO.SpouseMiddleName = presentation.PresentedTo.Spouse.MiddleName;
            presDTO.SpouseSurName = presentation.PresentedTo.Spouse.SurName;
            presDTO.SpouseNickName = presentation.PresentedTo.Spouse.NickName;
            presDTO.SpousePlaceOfBirth = presentation.PresentedTo.Spouse.PlaceOfBirth;
            presDTO.SpouseAddress = presentation.PresentedTo.Spouse.Address;
            presDTO.SpouseCity = presentation.PresentedTo.Spouse.City;
            presDTO.SpouseState = presentation.PresentedTo.Spouse.State;
            presDTO.SpouseMaritalStatus = presentation.PresentedTo.Spouse.MaritialStatusTypeId;
            presDTO.SpouseIsNonBinary = presentation.PresentedTo.Spouse.IsNonBinary;
            presDTO.SpouseInsuranceOutsideWorkTermLife = presentation.PresentedTo.Spouse.InsuranceOutsideWorkTermLife;
            presDTO.SpouseInsuranceOutsideWorkWholeLife = presentation.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife;
            presDTO.SpouseInsuranceOutsideWorkAccidental = presentation.PresentedTo.Spouse.InsuranceOutsideWorkAccidental;
            presDTO.SpouseInsuranceThroughWorkTermLife = presentation.PresentedTo.Spouse.InsuranceThroughWorkTermLife;
            presDTO.SpouseInsuranceThroughWorkWholeLife = presentation.PresentedTo.Spouse.InsuranceThroughWorkWholeLife;
            presDTO.SpouseInsuranceThroughWorkAccidental = presentation.PresentedTo.Spouse.InsuranceThroughWorkAccidental;
            presDTO.SpousePremiumAmount = presentation.PresentedTo.Spouse.Premium;
            presDTO.SpouseWage = presentation.PresentedTo.Spouse.Wage;
            presDTO.SpouseOccupation = presentation.PresentedTo.Spouse.Occupation
            presDTO.SpousePhone = presentation.PresentedTo.Spouse.Phone;
            presDTO.SpouseZip = presentation.PresentedTo.Spouse.Zip;

            if (presentation.PresentedTo.Spouse.Sponsership != undefined && presentation.PresentedTo.Spouse.Sponsership != '') {
                try {
                    var spouseSponsor = JSON.parse(presentation.PresentedTo.Spouse.Sponsership);
                    spouseSponsor.SponsorType = 2;
                    if (spouseSponsor != undefined && !isCareerUpgradeEmpty(spouseSponsor))
                        presDTO.CareerUpgrades.push(spouseSponsor) 
                } catch (ex: any) {
                    console.log("Fail to Parse or Update PresentedTo Spouse Sponsership");
                }
            }
            if(presentation.PresentedTo.Spouse.InsuranceThroughWorkString != undefined && presentation.PresentedTo.Spouse.InsuranceThroughWorkString != ""){
                try {
                    var NeedAnalysisSpouseThorughWorkList = JSON.parse(presentation.PresentedTo.Spouse.InsuranceThroughWorkString);
                    if(NeedAnalysisSpouseThorughWorkList != undefined && NeedAnalysisSpouseThorughWorkList.length>0){
                        NeedAnalysisSpouseThorughWorkList.forEach((element: any)=>{
                            element.InsuranceThroughWork = true;
                            element.IsPrimary = false;
                            presDTO.NeedAnalysisInsuranceList.push(element)
                        })
                    }
                } catch (ex: any) {
                    console.log("Fail to Parse or Update PresentedTo Spouse InsuranceThroughWorkString");
                }
            
            }
            if(presentation.PresentedTo.Spouse.InsuranceOutsideWorkString != undefined && presentation.PresentedTo.Spouse.InsuranceOutsideWorkString != ""){
                try {
                    var NeedAnalysisSpouseOutsideWorkList = JSON.parse(presentation.PresentedTo.Spouse.InsuranceOutsideWorkString);
                    if(NeedAnalysisSpouseOutsideWorkList != undefined && NeedAnalysisSpouseOutsideWorkList.length>0){
                        NeedAnalysisSpouseOutsideWorkList.forEach((element: any)=>{
                            element.InsuranceOutsideWork = true;
                            element.IsPrimary = false;
                            presDTO.NeedAnalysisInsuranceList.push(element);
                        })
                    }
                } catch (ex: any) {
                    console.log("Fail to Parse or Update PresentedTo Spouse InsuranceOutsideWorkString");
                }
            }
        }
    }
    presDTO.ReportCardOptions = [];
    if (presentation.Report) {
        if (presentation.Report.NoCostBenefits != undefined && presentation.Report.NoCostBenefits != "" && presentation.Report.NoCostBenefits != "null") {
            try 
            {
                var nocostOptions = JSON.parse(presentation.Report.NoCostBenefits);
                nocostOptions.forEach((option: any) => {
                    presDTO.ReportCardOptions.push({
                        ReportCardConfigId: option.ReportOptionId,
                        Value: option.selected
                    });
                });
            } catch (ex: any) {
                console.log("Fail to Parse or Update Report NoCostBenefits");
            }
        }

        if (presentation.Report.PermanentCoverages != undefined && presentation.Report.PermanentCoverages != "" && presentation.Report.PermanentCoverages != "null") {
            try {
                var permCovOptions = JSON.parse(presentation.Report.PermanentCoverages);
                permCovOptions.forEach((option: any) => {
                    presDTO.ReportCardOptions.push({
                        ReportCardConfigId: option.ReportOptionId,
                        Value: option.selected
                    });
                });
            } catch (ex: any) {
                console.log("Fail to Parse or Update Report PermanentCoverages");
            }
        }

        if (presentation.Report.MoreInfoOn != undefined && presentation.Report.MoreInfoOn != "" && presentation.Report.MoreInfoOn != "null") {
            try {
                var moreInfoOptions = JSON.parse(presentation.Report.MoreInfoOn);
                moreInfoOptions.forEach((option: any) => {
                    presDTO.ReportCardOptions.push({
                        ReportCardConfigId: option.ReportOptionId,
                        Value: option.selected
                    });
                });
            } catch (ex: any) {
                console.log("Fail to Parse or Update Report MoreInfoOn");
            }
        }
        
        if (presentation.Report.VSOQuestionnaire != undefined && presentation.Report.VSOQuestionnaire != "" && presentation.Report.VSOQuestionnaire != "null") {
            try {
                var VSOQuestionnaire = JSON.parse(presentation.Report.VSOQuestionnaire);
                presDTO.ReportCardVSOOptions = VSOQuestionnaire;
            } catch (ex: any) {
                console.log("Fail to Parse or Update Report VSOQuestionnaire");
            }
        }
    }

    if (presentation.PresentationWillKitVeterans != undefined && presentation.PresentationWillKitVeterans != null && presentation.PresentationWillKitVeterans != "") {
        try {
            if(presDTO.BenefitAndDocumentEmailCount != null && presDTO.BenefitAndDocumentEmailCount > 0){
                presDTO.BenefitsAndDocumentPrefill = {}
                presDTO.BenefitsAndDocumentPrefill.PresentationPrefillString = presentation.PresentationWillKitVeterans;
            }
            presDTO.VeteranWillKitInfo = JSON.parse(presentation.PresentationWillKitVeterans);

            if (presDTO.VeteranWillKitInfo.State != undefined && typeof presDTO.VeteranWillKitInfo.State != "string") {
                if (presDTO.VeteranWillKitInfo.State.StateCode != undefined) {
                    presDTO.VeteranWillKitInfo.State = presDTO.VeteranWillKitInfo.State.StateCode
                }
                else {
                    presDTO.VeteranWillKitInfo.State == "";
                }
            }

            var PresentationWillKitVeterans: any = presDTO.VeteranWillKitInfo;
            if (PresentationWillKitVeterans.ReferralsAdditional != undefined && PresentationWillKitVeterans.ReferralsAdditional != null && PresentationWillKitVeterans.ReferralsAdditional != ""){
                try {
                    presDTO.VeteranWillKitInfo.ReferralsAdditional = JSON.parse(PresentationWillKitVeterans.ReferralsAdditional);
                } catch (ex:any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo ReferralsAdditional");
                }
            }

            if (PresentationWillKitVeterans.ReferralsFamily != undefined && PresentationWillKitVeterans.ReferralsFamily != null && PresentationWillKitVeterans.ReferralsFamily != ""){
                try {
                    presDTO.VeteranWillKitInfo.ReferralsFamily = JSON.parse(PresentationWillKitVeterans.ReferralsFamily);
                } catch (ex: any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo ReferralsFamily");
                }
            }

            if (PresentationWillKitVeterans.ReferralsService != undefined && PresentationWillKitVeterans.ReferralsService != null && PresentationWillKitVeterans.ReferralsService != ""){
                try {
                    presDTO.VeteranWillKitInfo.ReferralsService = JSON.parse(PresentationWillKitVeterans.ReferralsService);
                } catch (ex:any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo ReferralsService");
                }
            }
            
            if (PresentationWillKitVeterans.ReferralsOtherService != undefined && PresentationWillKitVeterans.ReferralsOtherService != null && PresentationWillKitVeterans.ReferralsOtherService != ""){
                try {
                    presDTO.VeteranWillKitInfo.ReferralsOtherService = JSON.parse(PresentationWillKitVeterans.ReferralsOtherService);
                } catch (ex: any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo ReferralsOtherService");
                }
            }

            if (PresentationWillKitVeterans.ReferralsMembers != undefined && PresentationWillKitVeterans.ReferralsMembers != null && PresentationWillKitVeterans.ReferralsMembers != ""){
                try {
                    presDTO.VeteranWillKitInfo.ReferralsMembers = JSON.parse(PresentationWillKitVeterans.ReferralsMembers);
                } catch (ex: any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo ReferralsMembers");
                }
            }

            if (PresentationWillKitVeterans.ReferralsChildrenFamily != undefined && PresentationWillKitVeterans.ReferralsChildrenFamily != null && PresentationWillKitVeterans.ReferralsChildrenFamily != ""){
                try {
                    presDTO.VeteranWillKitInfo.ReferralsChildrenFamily = JSON.parse(PresentationWillKitVeterans.ReferralsChildrenFamily);
                } catch (ex: any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo ReferralsChildrenFamily");
                }
            }
            
            if(PresentationWillKitVeterans.UnionAssociationMemberInfo != undefined && PresentationWillKitVeterans.UnionAssociationMemberInfo != ""){
                try{
                    presDTO.VeteranWillKitInfo.UnionAssociationMemberInfo = JSON.parse(PresentationWillKitVeterans.UnionAssociationMemberInfo);
                }
                catch(ex: any){
                    console.log("Fail to Parse or Update VeteranWillKitInfo UnionAssociationMemberInfo");
                }
            }
                
            if(PresentationWillKitVeterans.WillKitChildren != undefined && PresentationWillKitVeterans.WillKitChildren != null && PresentationWillKitVeterans.WillKitChildren != "")
            {
                presDTO.VeteranWillKitInfo.WillKitChildren = PresentationWillKitVeterans.WillKitChildren.slice(0)
                var WillKitChildrenArray: any = []
                try {
                    var PrefillChildren = JSON.parse(PresentationWillKitVeterans.WillKitChildren);
                    PrefillChildren.forEach((element:any)=>{
                        if (element.DateOfBirth != undefined && element.DateOfBirth != "") {
                            if(element.AgePerDOB>=18){
                                element.IsAdult = true
                            }
                            else {
                                element.IsAdult = false
                            }
                        }  
                        element.GenderTypeId = null;
                        WillKitChildrenArray.push(element)
                    })
                } catch (ex: any) {
                    console.log("Fail to Parse or Update VeteranWillKitInfo WillKitChildren");
                }
                
                presDTO.VeteranWillKitInfo.WillKitChildren = WillKitChildrenArray
            }
        } catch (ex: any) {
            console.log("Fail to Parse or Update PresentationWillKitVeterans");
        }        
    }

    if (presentation.MemberSurvey != undefined && presentation.MemberSurvey != null && presentation.MemberSurvey != "") {
        try {
            presDTO.CreditUnionSurvey = JSON.parse(presentation.MemberSurvey);
        } catch (ex: any) {
            console.log("Fail to Parse or Update MemberSurvey");
        }
    }
    
    if (presentation.MemberQuestionnaire != undefined && presentation.MemberQuestionnaire != null && presentation.MemberQuestionnaire != "") {
        try {
            presDTO.ReturnCardQuestionnaire = JSON.parse(presentation.MemberQuestionnaire);
        } catch (ex: any) {
            console.log("Fail to Parse or Update ReturnCardQuestionnaire");
        }        
    }

    if (presentation.FamilyCareSurvey != undefined && presentation.FamilyCareSurvey != null && presentation.FamilyCareSurvey != "") {
        try {
            presDTO.FamilyCareSurvey = JSON.parse(presentation.FamilyCareSurvey);
            presDTO.FamilyCareSurvey.PrimaryGenderTypeId = GetGenderTypeId(presDTO.FamilyCareSurvey.PrimaryGender)
            presDTO.FamilyCareSurvey.SpouseGenderTypeId = GetGenderTypeId(presDTO.FamilyCareSurvey.SpouseGender)

            if (presDTO.FamilyCareSurvey.IsPrimaryCivilian ) {
                presDTO.FamilyCareSurvey.IsPrimaryVeteran = null;
                presDTO.FamilyCareSurvey.IsPrimaryRelativeOfVeteran = null;
            }
            else if(presDTO.FamilyCareSurvey.IsPrimaryVeteran != null && presDTO.FamilyCareSurvey.IsPrimaryVeteran != undefined){
                presDTO.FamilyCareSurvey.IsPrimaryRelativeOfVeteran = !presDTO.FamilyCareSurvey.IsPrimaryVeteran
            }
            
            presDTO.FamilyCareSurvey.Childrens = [];
            if (presDTO.FamilyCareSurvey.DependentChildrenString != undefined && presDTO.FamilyCareSurvey.DependentChildrenString != "") {
                try {
                    var FamiyCareChildrenList = JSON.parse(presDTO.FamilyCareSurvey.DependentChildrenString);
                    if (FamiyCareChildrenList != undefined && FamiyCareChildrenList.length > 0) {
                        FamiyCareChildrenList.forEach((element: any) => {
                            if ((element.FirstName != undefined && element.FirstName != "") ||
                                (element.LastName != undefined && element.LastName != "")) {
                                element.GenderTypeId = GetGenderTypeId(element.Gender);
                                presDTO.FamilyCareSurvey.Childrens.push(element);
                            }
                        });
                    }    
                } catch (ex: any) {
                    console.log("Fail to Parse or Update FamilyCareSurvey");
                }
            }

            if (presDTO.FamilyCareSurvey.AdultChildrenString != undefined && presDTO.FamilyCareSurvey.AdultChildrenString != "") {
                try {
                    var FamiyCareAdultChildrenList = JSON.parse(presDTO.FamilyCareSurvey.AdultChildrenString);
                    if (FamiyCareAdultChildrenList != undefined && FamiyCareAdultChildrenList.length > 0) {
                        FamiyCareAdultChildrenList.forEach((element: any) => {
                            if ((element.FirstName != undefined && element.FirstName != "") ||
                                (element.LastName != undefined && element.LastName != "")) {
                                element.GenderTypeId = GetGenderTypeId(element.Gender);
                                element.IsAdult = true
                                element.State = element.State.StateCode
                                presDTO.FamilyCareSurvey.Childrens.push(element);
                            }
                        });
                    }    
                } catch (ex: any) {
                    
                }
            }

            presDTO.FamilyCareSurvey.Properties = []
            if (presDTO.FamilyCareSurvey.FamilyCareSurveyPropertyString != undefined && presDTO.FamilyCareSurvey.FamilyCareSurveyPropertyString != ""){
                try {
                    var FamilyCarePropertyList = JSON.parse(presDTO.FamilyCareSurvey.FamilyCareSurveyPropertyString);
                    if(FamilyCarePropertyList != undefined && FamilyCarePropertyList.length>0){
                        FamilyCarePropertyList.forEach((element: any)=>{
                            if(element.OwnOrRent == 'OWN'){
                                element.OwnHome = true;
                            }
                            else if(element.OwnOrRent == 'RENT'){
                                element.RentHome = true;
                            }
                            presDTO.FamilyCareSurvey.Properties.push(element);
                        })
                    }    
                } catch (ex: any) {
                    
                }
                
            }

            presDTO.FamilyCareSurvey.InsuranceList = []
            if(presDTO.FamilyCareSurvey.PrimaryInsuranceThroughWork != undefined && presDTO.FamilyCareSurvey.PrimaryInsuranceThroughWork != ""){
                try {
                    var PrimaryThroughWorkList = JSON.parse(presDTO.FamilyCareSurvey.PrimaryInsuranceThroughWork);
                    if(PrimaryThroughWorkList != undefined && PrimaryThroughWorkList.length>0){
                        PrimaryThroughWorkList.forEach((element:any)=>{
                            element.InsuranceThroughWork = true
                            element.IsPrimary = true
                            presDTO.FamilyCareSurvey.InsuranceList.push(element)
                        })
                    }    
                } catch (ex: any) {
                    
                }
            }

            if(presDTO.FamilyCareSurvey.PrimaryInsuranceOutsideWork != undefined && presDTO.FamilyCareSurvey.PrimaryInsuranceOutsideWork != ""){
                try {
                    var PrimaryOutsideWorkList = JSON.parse(presDTO.FamilyCareSurvey.PrimaryInsuranceOutsideWork)
                    PrimaryOutsideWorkList.forEach((element:any)=>{
                        element.InsuranceOutsideWork = true
                        element.IsPrimary = true
                        presDTO.FamilyCareSurvey.InsuranceList.push(element)
                    })    
                } catch (ex: any) {
                    
                }
            }

            if(presDTO.FamilyCareSurvey.SpouseInsuranceThroughWork != undefined && presDTO.FamilyCareSurvey.SpouseInsuranceThroughWork != ""){
                try {
                    var SpouseThroughWorkList = JSON.parse(presDTO.FamilyCareSurvey.SpouseInsuranceThroughWork)
                    SpouseThroughWorkList.forEach((element:any)=>{
                        element.InsuranceThroughWork = true
                        element.IsPrimary = false
                        presDTO.FamilyCareSurvey.InsuranceList.push(element)
                    })
                } catch (ex: any) {
                    
                }
            }

            if(presDTO.FamilyCareSurvey.SpouseInsuranceOutsideWork != undefined && presDTO.FamilyCareSurvey.SpouseInsuranceOutsideWork != ""){
                try {
                    var SpouseOutsideWorkList = JSON.parse(presDTO.FamilyCareSurvey.SpouseInsuranceOutsideWork)
                    SpouseOutsideWorkList.forEach((element:any)=>{
                        element.InsuranceOutsideWork = true
                        element.IsPrimary = false
                        presDTO.FamilyCareSurvey.InsuranceList.push(element)
                    })    
                } catch (ex: any) {
                    
                }
            }
            if(presDTO.FamilyCareSurvey.FamilyCareVeteranRelativeInfoString != undefined && presDTO.FamilyCareSurvey.FamilyCareVeteranRelativeInfoString != ''){
                try {
                    presDTO.FamilyCareSurvey.FamilyCareVeteranRelativeInfo = JSON.parse(presDTO.FamilyCareSurvey.FamilyCareVeteranRelativeInfoString)
                } catch (ex: any) {
                    console.log("Unable to Parse FamilyCareVeteranRelativeInfoString");
                }
            }
        } catch (ex:any) {
            
        }
    }
    return presDTO;
}

export async function PresentationDTOToEntity(presDTO: any) {
    var presentity: Presentation = new Presentation();
    presentity.CountryId = presDTO.CountryId;
    presentity.EndTime = presDTO.EndTime;
    presentity.isPresentPlanCompleted = presDTO.IsPresentPlanCompleted;
    presentity.isBenefitsSummaryCompleted = presDTO.IsBenefitsSummaryCompleted;
    presentity.isNeedAnalysisCompleted = presDTO.IsNeedAnalysisCompleted;
    presentity.isPlanGeneratorCompleted = presDTO.IsPlanGeneratorCompleted;
    presentity.isReportCardCompleted = presDTO.IsReportCardCompleted;
    presentity.isSuperCombo = !presDTO.IsSenior;
    presentity.Language = presDTO.Language;
    presentity.LanguageId = presDTO.Language;
    presentity.LeadId = presDTO.LeadId;
    presentity.NANotes = presDTO.NANotes;
    presentity.FPNotes = presDTO.FPNotes;
    presentity.PremiumTypeId = presDTO.PremiumTypeId;
    presentity.Code = presDTO.PresentationCode;
    presentity.PresentationTypeId = presDTO.PresentationTypeId;
    presentity.PresentationSubTypeId = presDTO.PresentationSubTypeId;
    presentity.PresentedGroupId = presDTO.PresentedGroupId;
    presentity.PresentedBy = GetHpProUserId();
    var languageFlat = require("../assets/pre-fill-data/language-list.json").find((x: any) => x.id == presDTO.Language);
    presentity.LanguageFlag = languageFlat?.flag;
    presentity.Language =  languageFlat?.text;
    presentity.Report = new Report();
    presentity.Report.BeneficiaryName = presDTO.BeneficiaryName;
    presentity.Report.ProgramValueRating = presDTO.ProgramValueRating;
    presentity.Report.RepRespectRating = presDTO.RepRespectRating;
    presentity.Report.Comments = presDTO.RcComments;
    presentity.Report.PresentationCourteousRating = presDTO.PresentationCourteousRating;
    if(presDTO.ReportCardVSOOptions != null && presDTO.ReportCardVSOOptions != undefined)
    {
    presentity.Report.VSOQuestionnaire = JSON.stringify(presDTO.ReportCardVSOOptions)
    }
    presentity.StartTime = presDTO.StartTime;
    presentity.StateId = presDTO.StateId;
    presentity.State = presDTO.StateName;
    presentity.CurrentStepCompleted = presDTO.StepCompleted;
    presentity.WhatHappened = presDTO.WhatHappened;
    presentity.TotalTimeString = presDTO.TotalTimeString;
    presentity.TimeOnNeedAnalysis = presDTO.NeedAnalysisDurationTicks;
    presentity.TimeOnBenefitSummary = presDTO.BenefitsSummaryDurationTicks;
    presentity.TimeOnNoCostBenefits = presDTO.NoCostDurationTicks;
    presentity.TimeOnPlanGenerator = presDTO.PlanGeneratorDurationTicks;
    presentity.TimeOnPresentPlan = presDTO.PresentPlanDurationTicks;
    presentity.TimeOnReportCard = presDTO.ReportCardDurationTicks;
    presentity.PresentationTypeString = presDTO.PresentationTypeString;
    presentity.WhatHappened = presDTO.WhatHappenedString;
    presentity.CombinedAnnualIncome = presDTO.CombinedAnnualIncome;
    presentity.ExistingCoverage = presDTO.ExistingCoverage;
    presentity.RecommendedCoverage = presDTO.RecommendedCoverage;
    presentity.RemainingCoverage = presDTO.RemainingCoverage;
    presentity.SubType = presDTO.PresentationSubTypeString;
    presentity.WageType = presDTO.WageTypeId;
    presentity.LicenseId = presDTO.LicenseId;
    presentity.AgentCode = presDTO.AgentNumber;
    presentity.FieldTraining = presDTO.FieldTrainingTypeId == 0 ? "ALONE" : "WITHSOMEONE";
    presentity.FieldTrainingAssociateId = presDTO.FieldTrainingAssociateId;
    
    if (presDTO.SponserShips != undefined) {
        presentity.Referrals = JSON.stringify(presDTO.SponserShips);
    }

    if (presDTO.CareerUpgrades != undefined && presDTO.CareerUpgrades.length > 0) {
        var OtherCareerUpgrades = presDTO.CareerUpgrades.filter((x: any) => x.SponsorType == 3);
        presentity.AllSponserships = JSON.stringify(OtherCareerUpgrades);

    }

    if (presDTO.PremiumPlan != undefined) {
        presentity.NeedAmount = presDTO.PremiumPlan.NeedPercentage;
    }
    var medicalQuestions: any = await GetLocalDataOrCallAPI(
        ApiEndPointConstants.MedicalQuestionsEndpoint,
        [],
        IndexDBConstants.MedicalQuestionsKey
    );
    if (presDTO.NeedAnalysisQuestions && presDTO.NeedAnalysisQuestions.length > 0) {

        medicalQuestions.forEach((question: any) => {
            var quesInDB = presDTO.NeedAnalysisQuestions.find((x: any) => x.Id == question.Id);
            if (quesInDB != null && quesInDB != undefined) {
                question.PrimaryValue = quesInDB.PrimaryValue;
                question.SpouseValue = quesInDB.SpouseValue;
            }
        });
    }

    var medQs: any = await GetLocalDataOrCallAPI(
        ApiEndPointConstants.GeneralMedicalQuestionEndpoint,
        [],
        IndexDBConstants.GeneralMedicalQuestionKey
    );
    if (presDTO.BSMedicalQuestion != undefined && presDTO.BSMedicalQuestion != null) {

        medQs.forEach((question1: any) => {
            var quesInDB = presDTO.BSMedicalQuestion.find((x: any) => x.Id == question1.Id);
            console.log(quesInDB);
            if (quesInDB != undefined && quesInDB != null) {
                question1.Checked = quesInDB.Checked;

                if (quesInDB.GeneralMedicalQuestionFields.length > 0) {
                    question1.GeneralMedicalQuestionFields = quesInDB.GeneralMedicalQuestionFields;
                }

            }

            if (quesInDB != undefined && quesInDB != null && question1.GeneralMedicalSubQuestions.length > 0) {

                question1.GeneralMedicalSubQuestions.forEach((Subquestion: any) => {

                    var sub = quesInDB.GeneralMedicalSubQuestions.find((x: any) => x.GeneralMedicalQuestionId == Subquestion.Id);
                    if (sub != undefined && sub != null) {

                        Subquestion.Checked = sub.Checked;
                    }

                });
            }

        });
    }

    if (medQs != null) {
        var result = medQs.reduce(function (r: any, e: any) {
            r[e.QuestionType] = medQs.filter(
                (x: any) => x.QuestionType == e.QuestionType
            );
            return r;
        }, {});
        result.senior = result.Senior.reduce(function (r: any, e: any) {
            r[e.Section] = result.Senior.filter(
                (x: any) => x.Section == e.Section
            );
            return r;
        }, {});
        result.supercombo = result.SuperCombo.reduce(function (r: any, e: any) {
            r[e.Section] = result.SuperCombo.filter(
                (x: any) => x.Section == e.Section
            );
            return r;
        }, {});

        console.log(result);
        presentity.BSMedicalQuestions = JSON.stringify(result);
    }
    //   });



    presentity.MedicalQuestionsNA = JSON.stringify(medicalQuestions);
    var primaryTobaccoValue: boolean = false;
    var spouseTobaccoValue: boolean = false;
    var primaryCancerValue: boolean = false;
    var spouseCancerValue: boolean = false;
    var primaryHeartValue: boolean = false;
    var spouseHeartValue: boolean = false;

    if (presentity.MedicalQuestionsNA != undefined && presentity.MedicalQuestionsNA != '') {
        var questions = JSON.parse(presentity.MedicalQuestionsNA);
        var tobaccoquestion = questions.find((x: any) => x.QuestionEn != undefined && x.QuestionEn.includes("TOBACCO") && x.IsForSenior == !presentity.isSuperCombo);
        if (tobaccoquestion != undefined) {
            primaryTobaccoValue = tobaccoquestion.PrimaryValue;
            spouseTobaccoValue = tobaccoquestion.SpouseValue;
        }
        var cancerquestion = questions.find((x:any)=>x.QuestionEn != undefined && x.QuestionEn.includes("CANCER"));
        if (cancerquestion != undefined) {
					primaryCancerValue =
						cancerquestion.PrimaryValue == null
							? false
							: cancerquestion.PrimaryValue;
					spouseCancerValue =
						cancerquestion.SpouseValue == null
							? false
							: cancerquestion.SpouseValue;
				}
        var heartquestion = questions.find((x:any)=>x.QuestionEn != undefined && x.QuestionEn.includes("HEART"));
        if (heartquestion != undefined) {
					primaryHeartValue =
						heartquestion == null ? false : heartquestion.PrimaryValue;
					spouseHeartValue =
						heartquestion == null ? false : heartquestion.SpouseValue;
				}
    }
    presentity.PresentedTo = new PrimaryPerson();
    if (presentity.PresentedTo != undefined) {
        presentity.PresentedTo.FirstName = presDTO.PrimaryFirstName;
        presentity.PresentedTo.LastName = presDTO.PrimaryLastName;
        presentity.PresentedTo.Email = presDTO.Email;
        presentity.PresentedTo.DateOfBirth = presDTO.PrimaryDOBAndAge.split(" ")[0];
        presentity.PresentedTo.Occupation = presDTO.PrimaryOccupation;
        presentity.PresentedTo.Phone = presDTO.PrimaryPhone;
        presentity.PresentedTo.Zip = presDTO.PrimaryZip;
        presentity.PresentedTo.Checking = presDTO.HasCheckingAccount;
        presentity.PresentedTo.Saving = presDTO.HasSavingAccount;
        presentity.PresentedTo.HomeInsuranceInCaseOfDeathAmount = presDTO.HomeInsuranceInCaseOfDeathAmount;
        presentity.PresentedTo.Income = presDTO.Income;
        presentity.PresentedTo.Retired = presDTO.IsRetired;
        presentity.PresentedTo.HouseHoldType = presDTO.HouseHoldType;
        presentity.PresentedTo.HomeInsuranceInCaseOfDeath = presDTO.InsuranceToPayOffYouHome;
        presentity.PresentedTo.ProvidedChildCollegeEducation = BooleanToString(presDTO.ProvidedChildCollegeEducation);
        presentity.PresentedTo.MonthlyRent = presDTO.MonthlyRent;
        presentity.PresentedTo.MortgageBalance = presDTO.MortgageBalance;
        presentity.PresentedTo.MortgageInterestRate = presDTO.MortgageInterestRate;
        presentity.PresentedTo.MortgageYearsRemaining = presDTO.MortgageYearsRemaining;
        presentity.PresentedTo.HasChildrenUnder18 = presDTO.hasChildren;
        presentity.PresentedTo.OwnHome = presDTO.OwnHome ;
        presentity.PresentedTo.RentHome = presDTO.RentHome;  
        presentity.PresentedTo.InsuranceOutsideWorkTermLife = presDTO.PrimaryInsuranceOutsideWorkTermLife;
        presentity.PresentedTo.InsuranceOutsideWorkWholeLife = presDTO.PrimaryInsuranceOutsideWorkWholeLife;
        presentity.PresentedTo.InsuranceThroughWorkTermLife = presDTO.PrimaryInsuranceThroughWorkTermLife;
        presentity.PresentedTo.InsuranceThroughWorkWholeLife = presDTO.PrimaryInsuranceThroughWorkWholeLife;
        presentity.PresentedTo.Premium = presDTO.PrimaryPremiumAmount;
        presentity.PresentedTo.MonthlyPremium = presDTO.PrimaryPremiumAmount;
        presentity.PresentedTo.BeneficiaryName = presDTO.BeneficiaryName;
        presentity.PresentedTo.AnnualExpense = presDTO.Expense;
        presentity.PresentedTo.ProvidedChildCollegeEducation = BooleanToString(presDTO.ProvidedChildCollegeEducation);
        presentity.PresentedTo.HomeInsuranceInCaseOfDeath = BooleanToString(presDTO.InsuranceToPayOffYouHome);
        presentity.PresentedTo.ProvidedChildCollegeEducationAmount = presDTO.ProvidedChildCollegeEducationAmount;
        presentity.PresentedTo.DOBAndAgeString = presDTO.PrimaryDOBAndAge;
        presentity.PresentedTo.Wage = presDTO.PrimaryWage;
        presentity.PresentedTo.Gender = GetGenderNameById(presDTO.PrimaryGenderTypeId);
        presentity.PresentedTo.AnnualIncome = presDTO.PrimaryAnnualIncome;
        presentity.PresentedTo.DollarADayAmount = presDTO.PremiumPlan?.PrimaryDollarAday;
        presentity.PresentedTo.AnyTobaccoUse = primaryTobaccoValue;
        presentity.PresentedTo.HadCancer = primaryCancerValue;
        presentity.PresentedTo.HadHeartDisease = spouseCancerValue;
        presentity.PresentedTo.Spouse = new Spouse();

        if (presDTO.Childrens != undefined && presDTO.Childrens.length > 0) {
            presentity.PresentedTo.HasChildrenUnder18 = true;

            presDTO.Childrens.forEach((child: any) => {
                child.Gender = GetGenderNameById(child.GenderTypeId);
                if (child.DateOfBirth != undefined)
                    child.DateOfBirth = moment(String(child.DateOfBirth)).format('MM/DD/YYYY')
            });
            presentity.PresentedTo.ChildrenString = JSON.stringify(presDTO.Childrens);
        }
        if (presentity.PresentedTo.Spouse != undefined) {

            presentity.PresentedTo.Spouse.FirstName = presDTO.SpouseFirstName;
            presentity.PresentedTo.Spouse.LastName = presDTO.SpouseLastName;
            presentity.PresentedTo.Spouse.DateOfBirth = presDTO.SpouseDOBAndAge.split(" ")[0];
            presentity.PresentedTo.Spouse.InsuranceOutsideWorkTermLife = presDTO.SpouseInsuranceOutsideWorkTermLife;
            presentity.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife = presDTO.SpouseInsuranceOutsideWorkWholeLife;
            presentity.PresentedTo.Spouse.InsuranceThroughWorkTermLife = presDTO.SpouseInsuranceThroughWorkTermLife;
            presentity.PresentedTo.Spouse.InsuranceThroughWorkWholeLife = presDTO.SpouseInsuranceThroughWorkWholeLife;
            presentity.PresentedTo.Spouse.Premium = presDTO.SpousePremiumAmount;
            presentity.PresentedTo.Spouse.MonthlyPremium = presDTO.SpousePremiumAmount;
            presentity.PresentedTo.Spouse.DOBAndAgeString = presDTO.SpouseDOBAndAge;
            presentity.PresentedTo.Spouse.Wage = presDTO.SpouseWage;
            presentity.PresentedTo.Spouse.Gender = GetGenderNameById(presDTO.SpouseGenderTypeId);
            presentity.PresentedTo.Spouse.AnnualIncome = presDTO.SpouseAnnualIncome;
            presentity.PresentedTo.Spouse.Occupation = presDTO.SpouseOccupation;
            presentity.PresentedTo.Spouse.DollarADayAmount = presDTO.PremiumPlan?.SpouseDollarAday;

            presentity.PresentedTo.Spouse.AnyTobaccoUse = spouseTobaccoValue;
            presentity.PresentedTo.Spouse.HadCancer = primaryCancerValue;
            presentity.PresentedTo.Spouse.HadHeartDisease = spouseHeartValue;
        }
        presentity.PremiumPlan = new PremiumPlanEntity();
        if (presDTO.PremiumPlan) {
            presentity.PremiumPlan.PremiumTypeId = presentity.PremiumTypeId;
            presentity.PremiumPlan.CustomHPPremium = presDTO.PremiumPlan.CombinedHPPremium;
            presentity.PremiumPlan.CustomHPPremium = presDTO.PremiumPlan.CustomHPPremium;
            presentity.PremiumPlan.CustomMonthlyPremium = presDTO.PremiumPlan.CombinedMonthly;
            presentity.PremiumPlan.CustomMonthlyPremium = presDTO.PremiumPlan.CustomMonthlyPremium;
            presentity.PremiumPlan.CustomMonthlyPremium = presDTO.PremiumPlan.CustomMonthlyPremium;
            presentity.PremiumPlan.FinalMonthlyPremium = presDTO.PremiumPlan.FinalMonthlyPremium;
            presentity.PremiumPlan.HPPremiumType = presDTO.PremiumPlan.HPPremiumType;
            presentity.PremiumPlan.MonthlyPremiumType = presDTO.PremiumPlan.MonthlyPremiumType;
            presentity.PremiumPlan.RemainingMonthlyPremium = presDTO.PremiumPlan.RemainingMonthlyPremium;
            presentity.PremiumPlan.UsedMonthlyPremium = presDTO.PremiumPlan.UsedMonthlyPremium;
        }


        if (presDTO.CareerUpgrades != undefined && presDTO.CareerUpgrades.length > 0) {

            presDTO.CareerUpgrades.forEach((CareerUpgrades: any) => {
                if (CareerUpgrades.SponsorType == 1) {
                    presentity.PresentedTo.Sponsership = JSON.stringify(CareerUpgrades);

                }
                else if (CareerUpgrades.SponsorType == 2) {
                    presentity.PresentedTo.Spouse.Sponsership = JSON.stringify(CareerUpgrades);

                }
            });


        }

    }

    if (presDTO.PlanOptions && presDTO.PlanOptions.length > 0) {
        var plans: any = [
            {
                Id: 1,
                IsChecked: true,
                Name: "PLAN OPTION 1",
                divid: "planopt1",
                isSelected: true,
            },
            {
                Id: 2,
                IsChecked: false,
                Name: "PLAN OPTION 2",
                divid: "planopt2",
                isSelected: false,
                IsCloned: false,
            },
            {
                Id: 3,
                IsChecked: false,
                Name: "PLAN OPTION 3",
                divid: "planopt3",
                isSelected: false,
                IsCloned: false,
            },
            {
                Id: 4,
                IsChecked: false,
                Name: "PLAN OPTION 4",
                divid: "planopt4",
                isSelected: false,
                IsCloned: false,
            },
        ];

        plans.forEach((plan: any) => {
            plan.Products = [
                {
                    Id: 1,
                    Selected: false,
                    ProductId: 17,
                    Plan: {},
                },
            ];
            plan.PrimaryProducts = [
                {
                    Id: 1,
                    Selected: false,
                    ProductId: 1,
                    IsWHL: true,
                    PresentAsDefaultId: 1,
                    Plan: {},
                },
                {
                    Id: 2,
                    Selected: false,
                    ProductId: 6,
                    PresentAsDefaultId: 3,
                    Plan: {},
                },
                {
                    Id: 3,
                    Selected: false,
                    ProductId: 6,
                    PresentAsDefaultId: 4,
                    Plan: {},
                },
                {
                    Id: 4,
                    Selected: false,
                    ProductId: 14,
                    PresentAsDefaultId: 1,
                    Plan: {},
                },
                {
                    Id: 5,
                    Selected: false,
                    ProductId: 13,
                    Plan: {},
                },
            ];
            plan.SpouseProducts = [
                {
                    Id: 1,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    IsWHL: true,
                    ProductId: 1,
                    Plan: {},
                    InterDependentOnDifferentProduct: true,
                },
                {
                    Id: 2,
                    Selected: false,
                    IsSpouse: true,
                    ProductId: 6,
                    PresentAsDefaultId: 3,
                    Plan: {},
                },
                {
                    Id: 4,
                    Selected: false,
                    ProductId: 14,
                    PresentAsDefaultId: 1,
                    Plan: {},
                },
            ];
            plan.AdditionalProduct = {};
            plan.AdditionalProduct.PrimaryProducts = {};
            plan.AdditionalProduct.PrimaryProducts.AHPProduct = [
                {
                    Id: 1,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    ProductId: 19,
                    Plan: {},
                },
                {
                    Id: 2,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    ProductId: 18,
                    Plan: {},
                },
            ];
            plan.AdditionalProduct.PrimaryProducts.ALPProduct = [
                {
                    Id: 3,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    ProductId: 15,
                    Plan: {},
                },
                {
                    Id: 4,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    ProductId: 12,
                    Plan: {},
                    InterDependentOnDifferentProduct: true,
                },
            ];
            plan.AdditionalProduct.SpouseProducts = {};
            plan.AdditionalProduct.SpouseProducts.AHPProduct = [
                {
                    Id: 1,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    ProductId: 19,
                    IsSpouse: true,
                    Plan: {},
                },
                {
                    Id: 2,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    ProductId: 18,
                    IsSpouse: true,
                    Plan: {},
                },
            ];
            plan.AdditionalProduct.SpouseProducts.ALPProduct = [
                {
                    Id: 3,
                    Selected: false,
                    IsSpouse: true,
                    ProductId: 6,
                    PresentAsDefaultId: 4,
                    Plan: {},
                },
                {
                    Id: 4,
                    Selected: false,
                    PresentAsDefaultId: 1,
                    IsSpouse: true,
                    ProductId: 15,
                    Plan: {},
                },
            ];
        });

        for (var i = 0; i < presDTO.PlanOptions.length; i++) {
            var plan = plans[i];
            var planDTO = presDTO.PlanOptions[i];
            plan.IsChecked = planDTO.IsChecked;
            plan.MBD = planDTO.MBD;
            plan.Name = planDTO.Name;
            plan.isSelected = planDTO.isSelected;
            plan.PremiumPlan = planDTO.PremiumPlan;

            //A71000 Load
            var A71 = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 17);
            if (A71 != undefined) {
                plan.Products[0].Coverage = A71.Coverage;
                plan.Products[0].MBD = A71.MBD;
                plan.Products[0].Option = A71.Plan.Option;
                plan.Products[0].CurrentProduct = A71.Plan.CurrentProduct;
                plan.Products[0].PresentAs = A71.Plan.PresentAs;
                plan.Products[0].PGName = A71.Plan.PGName;
                plan.Products[0].Selected = A71.Selected;
                plan.Products[0].Plan = A71.Plan
            }

            //WHL Primary 
            var WHLPr = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 1);
            if (WHLPr != undefined) {
                plan.PrimaryProducts[0].Coverage = WHLPr.Coverage;
                plan.PrimaryProducts[0].MBD = WHLPr.MBD;
                plan.PrimaryProducts[0].Option = WHLPr.Plan.Option;
                plan.PrimaryProducts[0].CurrentProduct = WHLPr.Plan.CurrentProduct;
                plan.PrimaryProducts[0].PresentAs = WHLPr.Plan.PresentAs;
                plan.PrimaryProducts[0].PGName = WHLPr.Plan.PGName;
                plan.PrimaryProducts[0].Selected = WHLPr.Selected;
                plan.PrimaryProducts[0].Plan = WHLPr.Plan;
            }

            //10YRC1 Primary
            var YRC1Pr = planDTO.PrimaryProducts[2];
            if (YRC1Pr != undefined) {
                plan.PrimaryProducts[1].Coverage = YRC1Pr.Coverage;
                plan.PrimaryProducts[1].MBD = YRC1Pr.MBD;
                plan.PrimaryProducts[1].Option = YRC1Pr.Plan.Option;
                plan.PrimaryProducts[1].CurrentProduct = YRC1Pr.Plan.CurrentProduct;
                plan.PrimaryProducts[1].PresentAs = YRC1Pr.Plan.PresentAs;
                plan.PrimaryProducts[1].PGName = YRC1Pr.Plan.PGName;
                plan.PrimaryProducts[1].Selected = YRC1Pr.Selected;
                plan.PrimaryProducts[1].Plan = YRC1Pr.Plan;
            }



            //10YRC2 Primary
            var YRC2Pr = planDTO.PrimaryProducts[3];
            if (YRC2Pr != undefined) {
                plan.PrimaryProducts[2].Coverage = YRC2Pr.Coverage;
                plan.PrimaryProducts[2].MBD = YRC2Pr.MBD;
                plan.PrimaryProducts[2].Option = YRC2Pr.Plan.Option;
                plan.PrimaryProducts[2].CurrentProduct = YRC2Pr.Plan.CurrentProduct;
                plan.PrimaryProducts[2].PresentAs = YRC2Pr.Plan.PresentAs;
                plan.PrimaryProducts[2].PGName = YRC2Pr.Plan.PGName;
                plan.PrimaryProducts[2].Selected = YRC2Pr.Selected;
                plan.PrimaryProducts[2].Plan = YRC2Pr.Plan;
            }
            //ADB Primary
            var adbpr = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 14);
            if (adbpr != undefined) {
                plan.PrimaryProducts[3].Coverage = adbpr.Coverage;
                plan.PrimaryProducts[3].MBD = adbpr.MBD;
                plan.PrimaryProducts[3].Option = adbpr.Plan.Option;
                plan.PrimaryProducts[3].CurrentProduct = adbpr.Plan.CurrentProduct;
                plan.PrimaryProducts[3].PresentAs = adbpr.Plan.PresentAs;
                plan.PrimaryProducts[3].PGName = adbpr.Plan.PGName;
                plan.PrimaryProducts[3].Selected = adbpr.Selected;
                plan.PrimaryProducts[2].Plan = adbpr.Plan;
            }
            //Child Rider Primary
            var chlPr = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 13);
            if (chlPr != undefined) {
                plan.PrimaryProducts[4].Coverage = chlPr.Coverage;
                plan.PrimaryProducts[4].MBD = chlPr.MBD;
                plan.PrimaryProducts[4].Option = chlPr.Plan.Option;
                plan.PrimaryProducts[4].CurrentProduct = chlPr.Plan.CurrentProduct;
                plan.PrimaryProducts[4].PresentAs = chlPr.Plan.PresentAs;
                plan.PrimaryProducts[4].PGName = chlPr.Plan.PGName;
                plan.PrimaryProducts[4].Selected = chlPr.Selected;
                plan.PrimaryProducts[4].Plan = chlPr.Plan;
            }
            //CNM Primary
            var cnmPr = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 19);
            if (cnmPr != undefined) {
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].Coverage = cnmPr.Coverage;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].MBD = cnmPr.MBD;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].Option = cnmPr.Plan.Option;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].CurrentProduct = cnmPr.Plan.CurrentProduct;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].PresentAs = cnmPr.Plan.PresentAs;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].PGName = cnmPr.Plan.PGName;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].Selected = cnmPr.Selected;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[0].Plan = cnmPr.Plan;
            }
            //C20000 Primary
            var c2Pr = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 18);
            if (c2Pr != undefined) {
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].Coverage = c2Pr.Coverage;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].MBD = c2Pr.MBD;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].Option = c2Pr.Plan.Option;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].CurrentProduct = c2Pr.Plan.CurrentProduct;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].PresentAs = c2Pr.Plan.PresentAs;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].PGName = c2Pr.Plan.PGName;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].Selected = c2Pr.Selected;
                plan.AdditionalProduct.PrimaryProducts.AHPProduct[1].Plan = c2Pr.Plan;
            }
            //B20000 Primary
            var b2Pr = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 15);
            if (b2Pr != undefined) {
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].Coverage = b2Pr.Coverage;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].MBD = b2Pr.MBD;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].Option = b2Pr.Plan.Option;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].CurrentProduct = b2Pr.Plan.CurrentProduct;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].PresentAs = b2Pr.Plan.PresentAs;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].PGName = b2Pr.Plan.PGName;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].Selected = b2Pr.Selected;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[0].Plan = b2Pr.Plan;
            }

            //Spouse Rider Primary
            var spRider = planDTO.PrimaryProducts.find((x: any) => x.ProductId == 12);
            if (spRider != undefined) {
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].Coverage = spRider.Coverage;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].MBD = spRider.MBD;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].Option = spRider.Plan.Option;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].CurrentProduct = spRider.Plan.CurrentProduct;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].PresentAs = spRider.Plan.PresentAs;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].PGName = spRider.Plan.PGName;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].Selected = spRider.Selected;
                plan.AdditionalProduct.PrimaryProducts.ALPProduct[1].Plan = spRider.Plan;
            }
            //WHL Spouse 
            var WHLSp = planDTO.SpouseProducts.find((x: any) => x.ProductId == 1);
            if (WHLSp != undefined) {
                plan.SpouseProducts[0].Coverage = WHLSp.Coverage;
                plan.SpouseProducts[0].MBD = WHLSp.MBD;
                plan.SpouseProducts[0].Option = WHLSp.Plan.Option;
                plan.SpouseProducts[0].CurrentProduct = WHLSp.Plan.CurrentProduct;
                plan.SpouseProducts[0].PresentAs = WHLSp.Plan.PresentAs;
                plan.SpouseProducts[0].PGName = WHLSp.Plan.PGName;
                plan.SpouseProducts[0].Selected = WHLSp.Selected;
                plan.SpouseProducts[0].Plan = WHLSp.Plan;
            }
            //10YRC1 Spouse
            var YRC1Sp = planDTO.SpouseProducts[1];
            if (YRC1Sp != undefined) {
                plan.SpouseProducts[1].Coverage = YRC1Sp.Coverage;
                plan.SpouseProducts[1].MBD = YRC1Sp.MBD;
                plan.SpouseProducts[1].Option = YRC1Sp.Plan.Option;
                plan.SpouseProducts[1].CurrentProduct = YRC1Sp.Plan.CurrentProduct;
                plan.SpouseProducts[1].PresentAs = YRC1Sp.Plan.PresentAs;
                plan.SpouseProducts[1].PGName = YRC1Sp.Plan.PGName;
                plan.SpouseProducts[1].Selected = YRC1Sp.Selected;
                plan.SpouseProducts[1].Plan = YRC1Sp.Plan;
            }
            //ADB Spouse
            var adbsp = planDTO.SpouseProducts.find((x: any) => x.ProductId == 14);
            if (adbsp != undefined) {
                plan.SpouseProducts[2].Coverage = adbsp.Coverage;
                plan.SpouseProducts[2].MBD = adbsp.MBD;
                plan.SpouseProducts[2].Option = adbsp.Plan.Option;
                plan.SpouseProducts[2].CurrentProduct = adbsp.Plan.CurrentProduct;
                plan.SpouseProducts[2].PresentAs = adbsp.Plan.PresentAs;
                plan.SpouseProducts[2].PGName = adbsp.Plan.PGName;
                plan.SpouseProducts[2].Selected = adbsp.Selected;
                plan.SpouseProducts[2].Plan = adbsp.Plan;
            }

            //CNM Spouse
            var cnmSp = planDTO.SpouseProducts.find((x: any) => x.ProductId == 19);
            if (cnmSp != undefined) {
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].Coverage = cnmSp.Coverage;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].MBD = cnmSp.MBD;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].Option = cnmSp.Plan.Option;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].CurrentProduct = cnmSp.Plan.CurrentProduct;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].PresentAs = cnmSp.Plan.PresentAs;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].PGName = cnmSp.Plan.PGName;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].Selected = cnmSp.Selected;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[0].Plan = cnmSp.Plan;
            }
            //C20000 Spouse
            var c2Sp = planDTO.SpouseProducts.find((x: any) => x.ProductId == 18);
            if (c2Sp != undefined) {
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].Coverage = c2Sp.Coverage;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].MBD = c2Sp.MBD;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].Option = c2Sp.Plan.Option;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].CurrentProduct = c2Sp.Plan.CurrentProduct;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].PresentAs = c2Sp.Plan.PresentAs;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].PGName = c2Sp.Plan.PGName;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].Selected = c2Sp.Selected;
                plan.AdditionalProduct.SpouseProducts.AHPProduct[1].Plan = c2Sp.Plan;
            }
            //YRC2 Spouse
            var YRC2Sp = planDTO.SpouseProducts[5];
            if (YRC2Sp != undefined) {
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].Coverage = YRC2Sp.Coverage;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].MBD = YRC2Sp.MBD;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].Option = YRC2Sp.Plan.Option;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].CurrentProduct = YRC2Sp.Plan.CurrentProduct;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].PresentAs = YRC2Sp.Plan.PresentAs;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].PGName = YRC2Sp.Plan.PGName;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].Selected = YRC2Sp.Selected;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[0].Plan = YRC2Sp.Plan;
            }

            //B20000 Spouse
            var b2Sp = planDTO.SpouseProducts.find((x: any) => x.ProductId == 15);
            if (b2Sp != undefined) {
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].Coverage = b2Sp.Coverage;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].MBD = b2Sp.MBD;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].Option = b2Sp.Plan.Option;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].CurrentProduct = b2Sp.Plan.CurrentProduct;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].PresentAs = b2Sp.Plan.PresentAs;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].PGName = b2Sp.Plan.PGName;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].Selected = b2Sp.Selected;
                plan.AdditionalProduct.SpouseProducts.ALPProduct[1].Plan = b2Sp.Plan;
            }
        }

        presentity.PlanOptions = JSON.stringify(plans);
    }
    return presentity;
}

function isReferralEmpty(Referrals: any) {
    if (Referrals.FirstName != undefined && Referrals.LastName != undefined && Referrals.City != undefined && Referrals != '' && Referrals.State != undefined && Referrals.Phone != undefined && Referrals.FirstName != '' && Referrals.LastName != '' && Referrals.City != '' && Referrals != '' && Referrals.State != '' && Referrals.Phone != '')
        return false;
    return true;
}

function isCareerUpgradeEmpty(careerUpg: any) {
    if (careerUpg.FirstName != undefined && careerUpg.LastName != undefined && careerUpg.Phone != undefined && careerUpg.City != undefined && careerUpg.Email != undefined && careerUpg.FirstName != '' && careerUpg.LastName != '' && careerUpg.Phone != '' && careerUpg.City != '' && careerUpg.Email != '')
        return false;
    return true;
}

export async function GetProductNameById(primaryproducts: any, spouseproducts: any, primarydobandage?: any, spousedobandage?: any) {
    if ((primaryproducts != null && primaryproducts.length > 0) || (spouseproducts != null && spouseproducts.length > 0)) {
        var productList: any = await getPersistedState(IndexDBConstants.ProductsListKey);

        if (productList != undefined && productList.length > 0) {
            if (primaryproducts != null && primaryproducts.length > 0) {
                primaryproducts.forEach((primaryprod: any) => {
                    var selcProduct = productList.find((x: any) => x.Id == primaryprod.ProductId);
                    if (selcProduct != undefined) {
                        if (primaryprod.Plan == undefined) primaryprod.Plan = {};
                        else {
                            if (primaryprod.ProductId != undefined && primaryprod.ProductId == 1 && primarydobandage != undefined && primarydobandage != '') {
                                var primaryAge = parseInt(primarydobandage.split("(")[1].trim().slice(0, -1));
                                var coverageValue = primaryprod.Coverage;
                                let coverageOption: any = undefined;
                                if (primaryAge != undefined && typeof primaryAge == 'number' && coverageValue != undefined) {
                                    if (primaryAge < 18) {
                                        coverageOption = selcProduct.ChildCoverageConfig.filter((x: any) => coverageValue >= x.MinCoverage && coverageValue <= x.MaxCoverage );
                                    } else if (primaryAge > 59) {
                                        coverageOption = selcProduct.OldCoverageConfig.filter((x: any) => coverageValue >= x.MinCoverage && coverageValue <= x.MaxCoverage );
                                    } else {
                                        coverageOption = selcProduct.AdultCoverageConfig.filter((x: any) => coverageValue >= x.MinCoverage && coverageValue <= x.MaxCoverage );
                                    }
                                }
                                if (coverageOption != undefined && coverageOption.length > 0 && coverageOption[0] != undefined) {
                                    primaryprod.Plan.PGName = coverageOption[0].PGName;
                                } else {
                                    primaryprod.Plan.PGName = selcProduct.PGName;
                                }
                            } else {
                                primaryprod.Plan.PGName = selcProduct.PGName;
                            }
                        }
                    }
                });
            }

            if (spouseproducts != null && spouseproducts.length > 0) {
                spouseproducts.forEach((spouseprod: any) => {
                    var selcProduct = productList.find((x: any) => x.Id == spouseprod.ProductId);
                    if (selcProduct != undefined) {
                        if (spouseprod.Plan == undefined) spouseprod.Plan = {};
                        else {
                            if (spouseprod.ProductId != undefined && spouseprod.ProductId == 1 && spousedobandage != undefined && spousedobandage != '') {
                                var spouseAge = parseInt(spousedobandage.split("(")[1].trim().slice(0, -1));
                                var coverageValue = spouseprod.Coverage;
                                let coverageOption: any = undefined;
                                if (spouseAge != undefined && typeof spouseAge == 'number' && coverageValue != undefined) {
                                    if (spouseAge < 18) {
                                        coverageOption = selcProduct.ChildCoverageConfig.filter((x: any) => coverageValue >= x.MinCoverage && coverageValue <= x.MaxCoverage );
                                    } else if (spouseAge > 59) {
                                        coverageOption = selcProduct.OldCoverageConfig.filter((x: any) => coverageValue >= x.MinCoverage && coverageValue <= x.MaxCoverage );
                                    } else {
                                        coverageOption = selcProduct.AdultCoverageConfig.filter((x: any) => coverageValue >= x.MinCoverage && coverageValue <= x.MaxCoverage );
                                    }
                                }
                                if (coverageOption != undefined && coverageOption.length > 0 && coverageOption[0] != undefined) {
                                    spouseprod.Plan.PGName = coverageOption[0].PGName;
                                } else {
                                    spouseprod.Plan.PGName = selcProduct.PGName;
                                }
                            } else {
                                spouseprod.Plan.PGName = selcProduct.PGName;
                            }
                        }
                    }
                });
            }
        }
    }
}

export function FillPresentationFromLead(presentation: Presentation, person: PrimaryPerson, spouse: Spouse, lead: any) {
    if (lead != undefined && presentation != undefined) {
        presentation.NANotes = lead.Notes;

        if (person != undefined) {
            person.FirstName = (lead.FirstName != null) ? lead.FirstName : "";
            person.LastName = (lead.LastName != null) ? lead.LastName : "";
            person.MiddleName = (lead.MiddleInitial != null) ? lead.MiddleInitial : "";
            person.Occupation = (lead.Occupation != null) ? lead.Occupation : "";
            person.Email = (lead.Email != null) ? lead.Email : "";
            if (lead.DateOfBirth != undefined && lead.DateOfBirth != '')
                person.DateOfBirth = moment(String(lead.DateOfBirth)).format('MM/DD/YYYY')
            if (lead.PrimaryPhone != undefined && lead.PrimaryPhone != null && lead.PrimaryPhone != "") {
                person.Phone = lead.PrimaryPhone.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
            }
            //person.Phone = (lead.PrimaryPhone != null) ? lead.PrimaryPhone : "";
            person.City = (lead.City != null) ? lead.City : "";
            person.State = (lead.StateId != null) ? lead.StateId : "";
            person.Zip = (lead.Zip != null) ? lead.Zip : "";
            person.SponsorName = (lead.Referred != null) ? lead.Referred : "";
            person.SponsorRelation = (lead.Relationship != null) ? lead.Relationship : "";

            if (lead.NoOfChildren != undefined && lead.NoOfChildren > 0)
                person.HasChildrenUnder18 = true;

            if (spouse != undefined) {

                if (lead.Spouse != null) {
                    var SpouseNameFirstName = lead.Spouse.trim();
                    var SpouseNameLastName = ""
                    if (SpouseNameFirstName.indexOf(' ') > 0)
                        SpouseNameLastName = SpouseNameFirstName.substring(SpouseNameFirstName.indexOf(' ') + 1);

                    spouse.FirstName = (SpouseNameFirstName.indexOf(' ') > 0) ? SpouseNameFirstName.substring(0, SpouseNameFirstName.indexOf(' ')) : SpouseNameFirstName;
                    spouse.LastName = (SpouseNameLastName != null) ? SpouseNameLastName : "";
                }

            }

        }

        var memberSurvey = new MemberSurvey();
        memberSurvey.Sponsor = ((lead.FirstName != null) ? lead.FirstName : "") + " " + ((lead.LastName != null) ? lead.LastName : "")
        memberSurvey.RelationShip = ((lead.Relationship != null) ? lead.Relationship : "") + " ";
        memberSurvey.MemberFirstName = lead.FirstName;
        memberSurvey.MemberLastName = lead.LastName;
        if (lead.Spouse != null && lead.Spouse != "") {
            memberSurvey.AddSpouse = true;
            var SpouseNameFirstName = lead.Spouse.trim();
            var SpouseNameLastName = ""
            if (SpouseNameFirstName.indexOf(' ') > 0)
                SpouseNameLastName = SpouseNameFirstName.substring(SpouseNameFirstName.indexOf(' ') + 1);

            memberSurvey.SpouseFirstName = (SpouseNameFirstName.indexOf(' ') > 0) ? SpouseNameFirstName.substring(0, SpouseNameFirstName.indexOf(' ')) : SpouseNameFirstName;
            memberSurvey.SpouseLastName = (SpouseNameLastName != null) ? SpouseNameLastName : "";
        }
        memberSurvey.Email = lead.Email;
        if (lead.PrimaryPhone != undefined && lead.PrimaryPhone != null && lead.PrimaryPhone != "") {
            memberSurvey.PhoneNumber = lead.PrimaryPhone.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
        }
        presentation.MemberSurvey =
            JSON.stringify(memberSurvey);

         
        var RCMemberQuestionnaire = new MemberQuestionnaire();
        RCMemberQuestionnaire.MemberFirstName = lead.FirstName;
        RCMemberQuestionnaire.MemberLastName = lead.LastName;
        if (lead.Spouse != null && lead.Spouse != "") {
            RCMemberQuestionnaire.AddSpouse = true;
            var SpouseNameFirstName = lead.Spouse.trim();
            var SpouseNameLastName = ""
            if (SpouseNameFirstName.indexOf(' ') > 0)
                SpouseNameLastName = SpouseNameFirstName.substring(SpouseNameFirstName.indexOf(' ') + 1);

            RCMemberQuestionnaire.SpouseFirstName = (SpouseNameFirstName.indexOf(' ') > 0) ? SpouseNameFirstName.substring(0, SpouseNameFirstName.indexOf(' ')) : SpouseNameFirstName;
            RCMemberQuestionnaire.SpouseLastName = (SpouseNameLastName != null) ? SpouseNameLastName : "";
        }
        presentation.MemberQuestionnaire =
            JSON.stringify(RCMemberQuestionnaire);
        
        var FamilySurvey = new FamilyCareSurvey();
        FamilySurvey.PrimaryFirstName = lead.FirstName;
        FamilySurvey.PrimaryLastName = lead.LastName;
        if (lead.Spouse != null && lead.Spouse != "") {
            // RCMemberQuestionnaire.AddSpouse = true;
            var SpouseNameFirstName = lead.Spouse.trim();
            var SpouseNameLastName = ""
            if (SpouseNameFirstName.indexOf(' ') > 0)
                SpouseNameLastName = SpouseNameFirstName.substring(SpouseNameFirstName.indexOf(' ') + 1);

            FamilySurvey.SpouseFirstName = (SpouseNameFirstName.indexOf(' ') > 0) ? SpouseNameFirstName.substring(0, SpouseNameFirstName.indexOf(' ')) : SpouseNameFirstName;
            FamilySurvey.SpouseLastName = (SpouseNameLastName != null) ? SpouseNameLastName : "";
        }
        if (lead.DateOfBirth != undefined && lead.DateOfBirth != '')
                FamilySurvey.PrimaryDateofBirth = moment(String(lead.DateOfBirth)).format('MM/DD/YYYY')
        FamilySurvey.PrimaryOccupation = (lead.Occupation != null) ? lead.Occupation : "";
        presentation.FamilyCareSurvey =
            JSON.stringify(FamilySurvey);
        
    }
}


export function AmountFormatAllFields(obj: any) {
    for (var prop in obj) {
        try {
            var propertyValue = obj[prop];
            var isnum = isNaN(propertyValue);
            if (propertyValue != undefined && propertyValue != "" && propertyValue != "--" && typeof propertyValue == "string" && !isnum) {
                obj[prop] = ConvertIntoAmountFormatWithDecimal(propertyValue);
            } else if (propertyValue != undefined &&  typeof propertyValue == "number" && propertyValue != 0 && !isnum) {
                obj[prop] = ConvertIntoAmountFormatWithDecimal(propertyValue.toFixed(2));
            }        
        }
        catch (ex: any) {
            console.log(ex);
        }
    }
    return obj;
}

export function FillPresentationByMemberType(presentation: Presentation, memberLookupType: MemberLookupType) {
    if (presentation != undefined && memberLookupType != undefined) {
        switch (memberLookupType) {
            case MemberLookupType.Demo:
                if (presentation.PresentedTo != undefined) {
                    presentation.PresentedTo.FirstName = "JOHN";
                    presentation.PresentedTo.LastName = "SMITH";
                    if (presentation.PresentedTo.Spouse != undefined) {
                        presentation.PresentedTo.Spouse.FirstName = "MARY";
                        presentation.PresentedTo.Spouse.LastName = "SMITH";
                    }
                }
                presentation.WhatHappened = 'TRAINING';

                break;
            case MemberLookupType.Training:
                presentation.WhatHappened = 'TRAINING';
                break;
        }
    }
}