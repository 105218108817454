import I18n from '@/plugins/i18n';

export async function CopyTextToClipBoard(mytext: string) {
	try {
		await navigator.clipboard.writeText(mytext);
		return true;
	} catch ($e) {
		return false;
	}
}

export function RemoveSpecialCharacterFromNumber(str: string) {
	if (str == undefined) return '';
	var s = str.replace(/[^\d]/g, '');
	return s;
}
export function AddMaskingToNumber(str: string) {
	if (str != null || str != undefined || str != '') {
		var formatted = str.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		if (formatted != null && formatted != undefined) {
			var number = !formatted[2]
				? formatted[1]
				: '(' + formatted[1] + ') ' + formatted[2] + (formatted[3] ? '-' + formatted[3] : '');
			if (number != null && number != undefined && number != '') {
				str = number;
			}
		}
		return str;
	} else {
		return '';
	}
}
export function ChangeDayOptionWithLanguage(option: string) {
	if (option != undefined && typeof option == 'string') {
		option = option.toLowerCase();
		option = option.replaceAll('months', I18n.t('BenefitsSummary.Months').toString());
		option = option.replaceAll('month', I18n.t('BenefitsSummary.Month').toString());
		option = option.replaceAll('years', I18n.t('BenefitsSummary.Years').toString());
		option = option.replaceAll('year', I18n.t('BenefitsSummary.Year').toString());
		option = option.replaceAll('days', I18n.t('BenefitsSummary.Days').toString());
		option = option.replaceAll('day', I18n.t('BenefitsSummary.Day').toString());
	}
	return option;
}
