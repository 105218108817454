

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import {
	LoadPremiumAmountForPrinting,
	LoadPrintPDFData,
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
	ConvertIntoAmountFormatWithNegativeDecimal,
	GetDecimalvaluefromString,
} from "../helpers/CalculationHelper";
import {
	UpdatePresentationWhatHappenedCount,
	UpdatePresentationSubTypeCount,
	UpdatePresentationTypeCount,
	UpdatePresentationHouseHoldCount,
	GetHEXColorByPresentationWhatHappened,
	GetHEXColorByPresentationSubType,
	GetHEXColorByPresentationType,
	GetHEXColorByHouseHold,
} from "../helpers/DashboardHelper";
import PresentationSyncing from "@/helpers/PresentationSync";
import MenuComponent from "../components/menu.vue";
import Pres from "../entities/Presentation";
import { CopyTextToClipBoard } from "../helpers/UIHelper";
import Presentation from "../entities/Presentation";
import VueHtml2pdf from "vue-html2pdf";
import { EntityHelper } from "../helpers/EntityHelper";
import User from "@/entities/User";
let placeholderuser = require("../assets/placeholderuser.png");
import JsonViewer from "vue-json-viewer";
import {
	GetRequestWithAuthorization,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { APIService } from "@/services/APIService";
import { ChartType } from "../enumerations/ChartType";
import BarChart from "../components/charts/BarChart.vue";
import PieChart from "../components/charts/Pie.vue";

import {
	AmountFormatAllFields,
	GetProductNameById,
	PresentationDTOToEntity,
} from "@/helpers/MappingHelper";
import {
	GetAscFullName,
	GetFullName,
	GetPrimaryLicense,
} from "@/helpers/APIExtentions";
import { getPersistedState } from "@/helpers/StateMapper";
import { WhatHappened } from "../enumerations/WhatHappened";
import { PresentationType } from "../enumerations/PresentationType";
import { PresentationSubType } from "../enumerations/PresentationSubType";
import { HpProHouseHoldType } from "../enumerations/HpProHouseHoldType";
import PresentationSync from "@/helpers/PresentationSync";
import { GetUserEmail } from "../helpers/APIExtentions";
import LoggingHelper from "@/helpers/LoggingHelper";
import LoggingConstants from "@/constants/LoggingConstants";
import moment from "moment";
import Calculator from "@/entities/Calculator";
import Calendar from "@/entities/Calendar";
import { EventBusLayout } from "../bus/EventBusLayout";

let MonthListData: any = require("../assets/pre-fill-data/month-list.json");

@Component({
	components: {
		JsonViewer,
		ShortUserDetailView,
		MenuComponent,
		VueHtml2pdf,
		BarChart,
		PieChart,
	},
})
export default class Dashboard extends Vue {
	private TotalRecord: number = 0;
	private localRecord: number = 0;
	private PresentationType: any = [];
	private SelectedPresentationsList: any = [];
	private SelectedPresentationType: any = [];
	private weeksinMonth: any = [];
	private presCountforSpecificDate: any = [];
	private presentationsforSpecificDate: any = [];
	private presentationsStats: any = [];
	private selectedDays: any = [];
	private fieldDaysWorked: number = 0;
	private presentationsGiven: number = 0;
	private TotalFieldDaysWorked: number = 0;
	private PresentationHistoryTotalCount: number = 0;
	private ShowHistory: any = [];
	private GamePlanList: any = [];
	private ProductsList: any = [];
	private Calculator: any = {};
	private Calendar: any = {};
	private GamePlan: any = {};
	private ALPGoal: number = 0;
	private totalMonthDays: number = 0;
	private FieldDaysGoal: number = 0;
	private historyCount: number = 0;

	private presentationsa: any = [];
	private PresentationCountByPresType: any = [];
	private PresentationCountByWhatHappened: any = [];
	private PresentationCountByHouseHold: any = [];
	private PresentationCountBySubType: any = [];
	private CurrentMonth: string = "";
	private bsRates: any = null;
	private lstStates: any = null;
	private generatedCode!: string;
	private imgSource!: string;
	private Name: string = GetFullName();
	private AscName: string = GetAscFullName();
	private UserEmail = " ( " + GetUserEmail() + " )";
	// private LicenseNo: string = "";
	// private LicenseState: string = "";
	private LicenseNo: string = GetPrimaryLicense();
	private UnionCode: string = "OPEIU 277";
	private CurrentDate = new Date();
	private ShowPresentationType: boolean = false;
	private ShowDispositionType: boolean = false;
	private ShowHouseHoldType: boolean = false;
	private ShowPresentationSubType: boolean = false;
	private showchartCounter: number = 0;
	private ShowPresentationPopUp: boolean = false;
	private ShowRCSection: boolean = false;
	private MonthsOfYear: any = [
		{ id: 1, month: "January" },
		{ id: 2, month: "February" },
		{ id: 3, month: "March" },
		{ id: 4, month: "April" },
		{ id: 5, month: "May" },
		{ id: 6, month: "June" },
		{ id: 7, month: "July" },
		{ id: 8, month: "August" },
		{ id: 9, month: "September" },
		{ id: 10, month: "October" },
		{ id: 11, month: "November" },
		{ id: 12, month: "December" },
	];
	private MonthList: any = MonthListData.filter(
		(x: any) => x.id <= this.CurrentDate.getMonth() + 1
	);

	private selectedMonth: any = {};
	private CompleteUserList: any = [];
	private ShowHospitalBenefits: boolean = false;
	private confirmationDialogResendEmail: boolean = false;
	private PresentationForResend: any = {};

	$refs!: {
		fullData: any;
		html2Pdf: any;
		pieCarousal: any;
	};
	private LicenseState: any;

	data() {
		return {
			chartselectiondialog: false,
			presentationcodescroll: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			CalendarValue: undefined,
			presKey: 1,
			currentPresentationToPrint: { Presentation: {}, BenefitSummary: {} },
			presentationcode: false,
			presentationData: false,
			PieChartPopup: false,
			presentationJSON: "",
			dashboardscroll: {
				bar: {
					opacity: "0.75",
					minSize: ".3",
					background: "#00568b",
				},
			},
			Presentations: this.presentationsa,
			pdfoptions: {
				margin: 6,
				filename: "hehehe.pdf",
				// pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
			},
			Childrens: [],
			OwnTrue: false,
			RentTrue: false,
			popupChartHeading: "",
			PresTypeChartData: {},
			DispositionChartData: {},
			PresentationSubChartData: {},
			HouseHoldTypeChartData: {},
			PopupChartData: {},
			PopupChartTypeData: [],
			PopupChartLabels: [],
			chartOptions: {
				responsive: true,
			},
		};
	}

	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		this.$store.state.loader = true;
		EventBusLayout.$on("RoutedToEvt", this.DashboardRoutedToEvt);
		var bsRatesPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.BenefitSummaryRatesEndpoint,
			[],
			IndexDBConstants.BenefitSummaryRatesKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);
		var self = this;
		Promise.all([bsRatesPromise, stateListPromise]).then(async (values) => {
			self.bsRates = values[0];
			self.lstStates = values[1];

			var dataURL = this.$store.state.ProfileImageString;
			this.GamePlanList = await getPersistedState("GamePlans");
			this.ProductsList = await getPersistedState("ProductsList");

			if (dataURL != null && dataURL != undefined) this.imgSource = dataURL;
			else if (this.$route.path != "/home" && this.$route.path != "/training") {
				this.imgSource = placeholderuser;
			} else {
				this.imgSource = placeholderuser;
			}
			let CurrentUser = localStorage.getItem("user");
			if (CurrentUser != undefined) {
				var userObj = JSON.parse(CurrentUser);
				let userDetail = userObj.UserDetail;
				if (userDetail != undefined) {
					getPersistedState("UnionCodeList").then((res: any) => {
						if (userDetail != undefined && userDetail.UnionId != 0) {
							this.UnionCode = res.filter(
								(x: any) => x.Key == userDetail.UnionId
							)[0].Value;
						}
					});
				}
			}
			var user = User.query().last();
			if (user && user.FirstName) {
				this.Name = user.fullShrinkname;
				this.UnionCode = user.Union;
				if (
					user.LicenseStates != null &&
					user.LicenseStates != undefined &&
					user.LicenseStates != ""
				) {
					var licStates = JSON.parse(user.LicenseStates);
					var lic = licStates.find(
						(i: { Primary: boolean }) => i.Primary == true
					);
					if (lic) {
						this.LicenseNo = lic.Number;
						this.LicenseState = lic.State;
					}
				}
			}
			this.fillCalendarDropDown();
			this.totalMonthDays = moment(this.selectedMonth.text).daysInMonth();
			await this.FetchPresentations(
				0,
				this.selectedMonth.id,
				this.selectedMonth.Year
			);
			this.fetchGamePlan();
			this.presCountforSpecificDate = new Array(this.totalMonthDays).fill(0);
			this.fillPresentationDays();
			this.returnDate(this.selectedMonth);
			this.LoadUserList();
			this.$store.state.loader = false;
		});
	}

	GetCamelizePlanOption(Option: string) {
		return EntityHelper.capitalizeTheFirstLetterOfEachWord(Option) || Option;
	}

	GetItemCoverage(item: any) {
		if (
			item.Plan.CompleteProduct != undefined &&
			(item.Plan.CompleteProduct.Id == 15 ||
				item.Plan.CompleteProduct.Id == 18 ||
				item.Plan.CompleteProduct.Id == 19)
		) {
			var coverage = item.Plan.CompleteProduct.Id == 19 ? "" : 0;
			try {
				var coverageList = this.ProductsList.find(
					(x: any) => x.Id == item.Plan.CompleteProduct.Id
				).CoverageDropdownOptions.map((x: any) => {
					return x.Value;
				});

				if (
					coverageList != undefined &&
					coverageList.length > 0 &&
					item.Plan.CompleteCoverageOption.Name != undefined &&
					item.Plan.CompleteCoverageOption.Name != ""
				) {
					var selectedCoverage =
						coverageList[Number(item.Plan.CompleteCoverageOption.Name) - 1];

					if (selectedCoverage != undefined && selectedCoverage != null) {
						if (
							item.Plan.CompleteProduct.Id == 15 ||
							item.Plan.CompleteProduct.Id == 18
						) {
							coverage = `$${this.ConvertintoAmountFormatUI(selectedCoverage)}`;
						} else if (item.Plan.CompleteProduct.Id == 19) {
							coverage = `${selectedCoverage} Unit`;
						}
					}
				}
				return coverage;
			} catch (ex: any) {
				return coverage;
			}
		}
		return `$${this.ConvertintoAmountFormatUI(item.Coverage)}`;
	}

	async LoadUserList() {
		let parameters: GenericKeyPair[] = [];
		GetRequestWithAuthorization(
			ApiEndPointConstants.UserListEndpoint,
			parameters
		).then((res: any) => {
			if (
				res != undefined &&
				res.Result != undefined &&
				res.Result.length > 0
			) {
				this.CompleteUserList = res.Result;
				// if (this.$data.Presentation.FieldTraining != "ALONE") {
				//   this.FieldTrainee = this.$data.Presentation.FieldTrainee;
				//   this.FillList(this.FieldTrainee, true);
				// }
			}
		});
	}

	fillCalendarDropDown() {
		var CurrentUser = localStorage.getItem("user");
		var creationMonth = "";
		var creationYear = 0;
		var lastYearofDropdown = 0;
		var lastMonthofDropdown = 0;
		var currentYear = moment().year();
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			creationMonth = moment(String(userObj.UserDetail.CreateDate)).format(
				"MM"
			);
			creationYear = moment(userObj.UserDetail.CreateDate).year();
		}

		var year = creationYear;
		var months = [];

		if (this.GamePlanList != undefined && this.GamePlanList.length > 0) {
			this.GamePlanList.sort((a: any, b: any) => {
				if (a.Year == b.Year) {
					return a.MonthId < b.MonthId ? -1 : a.MonthId > b.MonthId ? 1 : 0;
				} else {
					return a.Year < b.Year ? -1 : 1;
				}
			});
			if (this.GamePlanList[this.GamePlanList.length - 1].Year > currentYear) {
				lastYearofDropdown =
					this.GamePlanList[this.GamePlanList.length - 1].Year;
				lastMonthofDropdown =
					this.GamePlanList[this.GamePlanList.length - 1].MonthId;
			} else {
				lastYearofDropdown = currentYear;
				lastMonthofDropdown = 12;
			}
		} else {
			lastYearofDropdown = currentYear;
			lastMonthofDropdown = 12;
		}
		for (let year = creationYear; year <= lastYearofDropdown; year++) {
			let monthid = 0;
			if (year == creationYear) {
				monthid = Number(creationMonth);
			} else {
				monthid = 1;
			}
			if (year < lastYearofDropdown) {
				while (monthid <= 12) {
					months.push({
						id: monthid,
						text: this.MonthsOfYear[monthid - 1].month + " - " + year,
						Year: year,
					});
					monthid++;
				}
			} else {
				while (monthid <= lastMonthofDropdown) {
					months.push({
						id: monthid,
						text: this.MonthsOfYear[monthid - 1].month + " - " + year,
						Year: year,
					});
					monthid++;
				}
			}
		}
		this.MonthList = months;
		this.selectedMonth = this.MonthList.find((x: any) => {
			if (
				x.id == this.CurrentDate.getMonth() + 1 &&
				x.Year == this.CurrentDate.getFullYear()
			)
				return x;
		});
		this.CurrentMonth = this.selectedMonth.text;
	}

	ConvertIntoAmountFormatWithDecimalUI(event: any) {
		if (event == null) return "0.00";
		return ConvertIntoAmountFormatWithNegativeDecimal(event.toFixed(2));
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}
	DashboardRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.DashboardRoutedToEvt);
		window.removeEventListener("wheel", this.Updatehistorycount);
	}
	ConvertintoAmountFormatUI(amt: any) {
		try {
			if (amt == undefined) return 0;
			else if (typeof amt == "string")
				return ConvertIntoAmountFormatWithDecimal(amt);
			else return ConvertIntoAmountFormatWithDecimal(amt.toFixed(2));
		} catch (ex: any) {
			return 0;
		}
	}

	removeChartData(item: any) {
		if (item.isRemoved == true) item.isRemoved = false;
		else item.isRemoved = true;
		var k = JSON.parse(JSON.stringify(this.$data.PopupChartTypeData));
		var k = k.filter(
			(x: any) => x.isRemoved == undefined || x.isRemoved == false
		);
		this.$data.PopupChartData = this.FillChartDataFromAPIData(k);
	}

	ChartClick(chartData: any, popupHeading: string, chartDataArray: any) {
		if (popupHeading === "Market Sub Types Chart")
			this.ShowPresentationPopUp = true;
		else this.ShowPresentationPopUp = false;

		this.$data.PieChartPopup = true;
		this.$data.popupChartHeading = popupHeading;
		this.$data.PopupChartData = chartData;
		this.$data.PopupChartLabels = chartData.labels;
		if (chartDataArray != undefined) {
			chartDataArray.forEach((element: any) => {
				element.isRemoved = false;
			});
		}
		this.$data.PopupChartTypeData = chartDataArray.sort(
			(a: any, b: any) => b.Count - a.Count
		);
	}

	returnDate(selectedMonth: any) {
		console.log(selectedMonth);
		this.weeksinMonth = [];
		var year = selectedMonth.Year;
		var totalDays = moment(
			selectedMonth.id + " - " + year,
			"MM-YYYY"
		).daysInMonth();
		var firstDay = moment(selectedMonth.id + " - " + year, "MM-YYYY").startOf(
			"month"
		);
		var weekdays = [];
		var count = [];
		var days = 0;
		var test = Object.assign({ id: 0, day: [], daynumber: [], prescount: [] });
		var weekdays = [];
		var weeknumber, prevweeknumber;
		for (let i = 0; i < totalDays; i++) {
			weeknumber = moment(firstDay)
				.add(i + 1, "days")
				.isoWeek();
			if (weekdays.indexOf(weeknumber) === -1) {
				if (test.day.length > 0) this.weeksinMonth.push(test);
				var test = Object.assign({
					id: 0,
					day: [],
					daynumber: [],
					checked: [],
					allChecked: false,
					prescount: [],
				});
				weekdays.push(weeknumber);
				test.id = weeknumber;
				prevweeknumber = weeknumber;
				console.log(prevweeknumber);
			}

			days = moment(firstDay).add(i, "days").day();
			test.day[days] = moment(firstDay).add(i, "days").format("D");
			test.daynumber[days] = moment(firstDay).add(i, "days").day();
			test.checked[days] = false;
			test.prescount[days] = this.presCountforSpecificDate[i];
			if (i == totalDays - 1) {
				if (test.day.length > 0) this.weeksinMonth.push(test);
			}
		}
		console.log("blurred ", this.weeksinMonth);
		// weekdays.push(days1.split('0'));
		// console.log(weekdays);
		return this.weeksinMonth;
	}

	fillPresentationDays() {
		this.TotalFieldDaysWorked = 0;
		if (this.$data.Presentations != undefined) {
			var presdata = this.$data.Presentations.slice(0);
			var oldDate: any = [];
			var pres: any = Object.assign({
				data: [],
			});
			presdata.forEach((element: any) => {
				let specificDay =
					Number(moment(String(element.StartTime)).format("DD")) - 1;
				if (
					element.PresentationStatus != "INCOMPLETE" &&
					element.PresentationStatus != "TRAINING" &&
					element.PresentationStatus != "DEMO" &&
					element.PresentationStatus != "POSTPONED" &&
					element.PresentationStatus != "NEEDS ANALYSIS ONLY"
				) {
					this.presCountforSpecificDate[specificDay] += 1;
					if (this.presCountforSpecificDate[specificDay] == 1) {
						this.TotalFieldDaysWorked += 1;
					}
				}
			});
		}
	}

	wholeWeekSelected(index: any) {
		for (let i = 0; i < this.weeksinMonth[index].checked.length; i++) {
			if (this.weeksinMonth[index].day[i] != null) {
				this.weeksinMonth[index].checked[i] =
					this.weeksinMonth[index].allChecked;
				if (
					this.weeksinMonth[index].allChecked &&
					this.selectedDays.indexOf(this.weeksinMonth[index].day[i]) === -1
				) {
					// if (this.selectedDays.indexOf(this.weeksinMonth[index].day[i]) === -1)
					this.selectedDays.push(this.weeksinMonth[index].day[i]);
					if (this.weeksinMonth[index].prescount[i] != 0) {
						this.fieldDaysWorked += 1;
						this.presentationsGiven += this.weeksinMonth[index].prescount[i];
					}
				} else if (!this.weeksinMonth[index].allChecked) {
					this.selectedDays.splice(
						this.selectedDays.indexOf(
							this.selectedDays.find(
								(x: any) => x == this.weeksinMonth[index].day[i]
							)
						),
						1
					);
					if (this.weeksinMonth[index].prescount[i] != 0) {
						this.fieldDaysWorked -= 1;
						this.presentationsGiven -= this.weeksinMonth[index].prescount[i];
					}
				}
			}
		}
	}

	oneDaySelected(
		index: any,
		checked: boolean,
		weekday: any,
		presentationcount: any
	) {
		this.weeksinMonth[index].allChecked = this.weeksinMonth[
			index
		].checked.every((element: any) => element === true);
		if (checked) {
			this.selectedDays.push(weekday);
			if (presentationcount != 0) {
				this.fieldDaysWorked += 1;
				this.presentationsGiven += presentationcount;
			}
		} else if (!checked) {
			this.selectedDays.splice(
				this.selectedDays.indexOf(
					this.selectedDays.find((x: any) => x == weekday)
				),
				1
			);
			if (presentationcount != 0) {
				this.fieldDaysWorked -= 1;
				this.presentationsGiven -= presentationcount;
			}
		}
	}

	CalculateALP() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return 0.0;
		}

		return GrossAlp;
	}

	UpdateDefaultChart(chartType: any) {
		if (chartType == 1) {
			if (this.ShowPresentationType == true) {
				this.showchartCounter--;
				this.ShowPresentationType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.showchartCounter++;
					this.ShowPresentationType = true;
				}
			}
		} else if (chartType == 2) {
			if (this.ShowDispositionType == true) {
				this.showchartCounter--;
				this.ShowDispositionType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowDispositionType = true;
					this.showchartCounter++;
				}
			}
		} else if (chartType == 3) {
			if (this.ShowHouseHoldType == true) {
				this.showchartCounter--;
				this.ShowHouseHoldType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowHouseHoldType = true;
					this.showchartCounter++;
				}
			}
		} else if (chartType == 4) {
			if (this.ShowPresentationSubType == true) {
				this.showchartCounter--;
				this.ShowPresentationSubType = false;
			} else {
				if (this.showchartCounter >= 2) {
					this.$store.state.messageText = "Maximum two charts can be selected.";
					this.$store.state.showMessage = true;
				} else {
					this.ShowPresentationSubType = true;
					this.showchartCounter++;
				}
			}
		}
	}

	OpenResendConfirmationDialog(presentation: any) {
		this.PresentationForResend = presentation;
		this.confirmationDialogResendEmail = true;
	}

	ConfirmEmailResend() {
		this.ResendBenefitDocumentEmail(this.PresentationForResend);
		this.confirmationDialogResendEmail = false;
	}

	@Watch("chartselectiondialog")
	OnPopupClosed() {
		if (this.$data.chartselectiondialog == false) {
			if (this.showchartCounter == 0) {
				this.ShowPresentationType = true;
				this.ShowDispositionType = true;
				this.showchartCounter = 2;
			}

			var ChartList: any = [];
			if (this.ShowPresentationType == true)
				ChartList.push(ChartType.Presentation_Type);
			if (this.ShowDispositionType == true)
				ChartList.push(ChartType.Disposition_Type);
			if (this.ShowHouseHoldType == true)
				ChartList.push(ChartType.Household_Type);
			if (this.ShowPresentationSubType == true)
				ChartList.push(ChartType.Presentation_Subtypes);

			if (ChartList.length > 2) {
				ChartList = ChartList.splice(2, ChartList.length - 2);
			}
			var params: GenericKeyPair[] = [];
			PostRequestWithAuthorization(
				ApiEndPointConstants.SaveDefaultChartEndPoint,
				params,
				ChartList
			);
		}
	}
	RCDataExists(plan: any) {
		var HomePayment = false;
		var MonthlyIncome = false;
		if (plan != null && plan != undefined) {
			if (
				plan.PrimaryProducts != undefined &&
				plan.PrimaryProducts != null &&
				plan.PrimaryProducts.length > 0
			) {
				plan.PrimaryProducts.forEach((x: any) => {
					if (x.Plan != undefined && x.Plan.PresentAs == "MONTHLY INCOME") {
						this.ShowRCSection = true;
						MonthlyIncome = true;
					} else if (
						x.Plan != undefined &&
						x.Plan.PresentAs == "HOUSE PAYMENT RIDER"
					) {
						this.ShowRCSection = true;
						HomePayment = true;
					}
				});
			}
			if (
				plan.SpouseProducts != undefined &&
				plan.SpouseProducts != null &&
				plan.SpouseProducts.length > 0 &&
				!this.ShowRCSection
			) {
				plan.SpouseProducts.forEach((x: any) => {
					if (x.Plan != undefined && x.Plan.PresentAs == "MONTHLY INCOME") {
						this.ShowRCSection = true;
						MonthlyIncome = true;
					} else if (
						x.Plan != undefined &&
						x.Plan.PresentAs == "HOUSE PAYMENT RIDER"
					) {
						this.ShowRCSection = true;
						HomePayment = true;
					}
				});
			}
		}
		if (MonthlyIncome) {
			this.$data.currentPresentationToPrint.BenefitSummary.RCHeading =
				"MONTHLY INCOME";
		} else if (HomePayment) {
			this.$data.currentPresentationToPrint.BenefitSummary.RCHeading =
				"HOME PAYMENT";
		}
	}
	async DownloadPresentation(presentation: any) {
		if (!window.navigator.onLine) {
			this.$store.state.messageText = this.$t("General.NeedActiveConnect");
			this.$store.state.showMessage = true;
			return;
		}
		this.$data.presKey++;
		this.$store.state.loader = true;
		APIService.GetPresentationById(presentation.Id)
			.then(async (res: any) => {
				if (res != undefined && res.Result != undefined && res.Result != null) {
					var pres: Presentation = await PresentationDTOToEntity(res.Result);
					this.$data.currentPresentationToPrint.Presentation = pres;
					if (
						this.$data.currentPresentationToPrint.Presentation.FieldTraining ==
							"WITHSOMEONE" &&
						this.$data.currentPresentationToPrint.Presentation
							.FieldTrainingAssociateId != null
					) {
						if (
							this.CompleteUserList != undefined &&
							this.CompleteUserList.length > 0
						) {
							var FieldTrainingUser = this.CompleteUserList.find((x: any) => {
								if (
									x.AssociateId ==
									this.$data.currentPresentationToPrint.Presentation
										.FieldTrainingAssociateId
								) {
									return x;
								}
							});
						}
					}
					if (FieldTrainingUser != undefined) {
						this.$data.currentPresentationToPrint.Presentation.FieldTrainee =
							FieldTrainingUser.UserInfo != undefined
								? FieldTrainingUser.UserInfo.FullName?.toUpperCase()
								: "";
					}
					LoadPremiumAmountForPrinting(this.$data.currentPresentationToPrint);
					if (
						res.Result.PlanOptions != undefined &&
						res.Result.PlanOptions.length > 0
					) {
						var benSummary = res.Result.PlanOptions[0].BenefitsCalculation;
						if (benSummary != undefined) {
							this.$data.currentPresentationToPrint.BenefitSummary =
								AmountFormatAllFields(benSummary);
							if (
								this.$data.currentPresentationToPrint.BenefitSummary
									.RCHeading == null
							) {
								this.ShowRCSection = false;
								this.RCDataExists(res.Result.PlanOptions[0]);
							}
						}
						this.$data.currentPresentationToPrint.MBD =
							res.Result.PlanOptions[0].MBD;
						this.$data.currentPresentationToPrint.TotalAP =
							res.Result.PlanOptions[0].TotalAP;

						await GetProductNameById(
							res.Result.PlanOptions[0].PrimaryProducts,
							res.Result.PlanOptions[0].SpouseProducts,
							res.Result?.PrimaryDOBAndAge,
							res.Result?.SpouseDOBAndAge
						);
						this.$data.currentPresentationToPrint.BenefitSummary.PrimaryProducts =
							res.Result.PlanOptions[0].PrimaryProducts;
						this.ShowHospitalBenefits = false;
						if (
							this.$data.currentPresentationToPrint.BenefitSummary
								.PrimaryProducts != undefined &&
							this.$data.currentPresentationToPrint.BenefitSummary
								.PrimaryProducts != null &&
							this.$data.currentPresentationToPrint.BenefitSummary
								.PrimaryProducts.length > 0
						) {
							var A71000Selected =
								this.$data.currentPresentationToPrint.BenefitSummary.PrimaryProducts.find(
									(x: any) => {
										if (x.ProductId == 17 && x.Selected == true) {
											return x;
										}
									}
								);
							if (A71000Selected != undefined && A71000Selected != null) {
								this.ShowHospitalBenefits = true;
							}
						}
						this.$data.currentPresentationToPrint.BenefitSummary.SpouseProducts =
							res.Result.PlanOptions[0].SpouseProducts;
						// this.$data.currentPresentationToPrint.Presentation =
						//   res.Result.PlanOptions;
					}
					if (
						this.$data.currentPresentationToPrint.Presentation != undefined &&
						this.$data.currentPresentationToPrint.Presentation.PresentedTo !=
							undefined &&
						this.$data.currentPresentationToPrint.Presentation.PresentedTo
							.ChildrenString != undefined &&
						this.$data.currentPresentationToPrint.Presentation.PresentedTo
							.ChildrenString != ""
					)
						this.$data.Childrens = JSON.parse(
							this.$data.currentPresentationToPrint.Presentation.PresentedTo
								.ChildrenString
						);
					this.$data.OwnTrue =
						this.$data.currentPresentationToPrint.Presentation.PresentedTo.OwnHome;
					this.$data.RentTrue =
						this.$data.currentPresentationToPrint.Presentation.PresentedTo.RentHome;
					this.$data.pdfoptions.filename =
						EntityHelper.capitalizeTheFirstLetterOfEachWord(
							this.$data.currentPresentationToPrint.Presentation.PresentedTo
								.GetNameHolder
						).replaceAll(".", "") +
						"-" +
						this.$data.currentPresentationToPrint.Presentation.PresentationDay;
					(".pdf");
					setTimeout(() => {
						this.$refs.html2Pdf.generatePdf();
						this.$store.state.loader = false;
					}, 3000);
				} else {
					this.$store.state.messageText =
						"Unable To Download Presentation. Please Try Again.";
					this.$store.state.loader = false;
					this.$store.state.showMessage = true;
				}
			})
			.catch((ex) => {
				this.$store.state.messageText =
					"Unable To Download Presentation. Please Try Again.";
				this.$store.state.loader = false;
				this.$store.state.showMessage = true;
			});

		LoggingHelper.getInstance().LogEvent(LoggingConstants.DownloadPresentation);
	}

	ResendButtonValid(presentation: any) {
		var CreateTime = moment(String(presentation.StartTime));
		var CurrentTime = moment();
		var diff = CurrentTime.diff(CreateTime, "days");
		if (diff > 60) {
			return false;
		} else {
			return true;
		}
	}

	async ResendBenefitDocumentEmail(presentation: any) {
		if (!window.navigator.onLine) {
			this.$store.state.messageText = this.$t("General.NeedActiveConnect");
			this.$store.state.showMessage = true;
			return;
		}
		var Request: any = {};
		Request.PresentationGuid = presentation.PresentationGuid;
		Request.isEmailResent = true;
		this.$data.presKey++;
		this.$store.state.loader = true;
		var GenericKeyPairobj: GenericKeyPair[] = [];
		APIService.SendBenefitAndDocumentEmail(Request, GenericKeyPairobj).then(
			(res: any) => {
				if (res != undefined && res.data != undefined) {
					this.$store.state.loader = false;
					this.$store.state.messageText = "Email has been sent successfully.";
					this.$store.state.showMessage = true;
					this.ShowRecord.find((element: any) => {
						if (element.PresentationGuid == presentation.PresentationGuid) {
							element.BenefitsAndDocumentEmailCount += 1;
						}
					});
				}
			}
		);
	}

	async SelectedMonthChange(evt: any) {
		this.$data.Presentations = [];
		this.SelectedPresentationsList = [];
		this.CurrentMonth = this.selectedMonth.text;
		await this.FetchPresentations(
			0,
			this.selectedMonth.id,
			this.selectedMonth.Year
		);
		var date = new Date();
		this.$data.CalendarValue = new Date(
			date.getFullYear(),
			this.selectedMonth.id - 1,
			date.getDay()
		);
		this.fieldDaysWorked = 0;
		this.selectedDays = [];
		this.presentationsGiven = 0;
		this.totalMonthDays = moment(this.selectedMonth.text).daysInMonth();
		this.fetchGamePlan();
		this.presCountforSpecificDate = new Array(this.totalMonthDays).fill(0);
		this.fillPresentationDays();
		this.returnDate(this.selectedMonth);
	}

	fetchGamePlan() {
		this.Calculator = new Calculator();
		this.Calendar = new Calendar();
		this.GamePlan = this.GamePlanList.find((x: any) => {
			if (
				x.MonthId == this.selectedMonth.id &&
				x.Year == this.selectedMonth.Year
			)
				return x;
		});
		if (this.GamePlan != undefined) {
			this.Calculator = Object.assign({ ...this.GamePlan.Calculator });
			this.Calendar = Object.assign({ ...this.GamePlan.Calendar });
			this.Calendar.SelectedOptions =
				this.GamePlan.Calendar.SelectedOptions.slice(0);
			this.FieldDaysGoal = this.Calendar.FieldDays;
			this.ALPGoal = this.CalculateALP();
		} else {
			this.Calendar.SelectedOptions = new Array(this.totalMonthDays).fill("F");
			this.FieldDaysGoal = this.totalMonthDays;
			this.ALPGoal = 20000;
		}
	}

	RoutedTo(route: String) {
		if (route != "StartPresentation" && route != "/dashboard")
			this.$router.push(route.toString());
	}

	async generateCode(presentation: any) {
		this.generatedCode = presentation.PresentationCode;
		this.$data.presentationcode = true;
		LoggingHelper.getInstance().LogEvent(LoggingConstants.PresentationClone);
	}

	showPresentationData(presentation: any) {
		this.$data.presKey++;
		this.$store.state.loader = true;
		APIService.GetPresentationById(presentation.Id).then((res: any) => {
			if (res != undefined && res.Result != undefined) {
				this.$data.presentationData = true;
				this.$data.presentationJSON = res.Result;
				this.$store.state.loader = false;
			}
		});
	}
	TogglePieCarousal() {
		if (this.$refs.pieCarousal != undefined) {
			if (this.$refs.pieCarousal.hasNext) this.$refs.pieCarousal.next();
			else this.$refs.pieCarousal.prev();
		}
	}

	FillChartDataFromAPIData(APIData: any) {
		if (APIData != undefined && APIData.length > 0) {
			var labels: any = [];
			var labelCount: any = [];
			var labelColors: any = [];

			APIData.forEach((element: any) => {
				labels.push(element.Title);
				labelCount.push(element.Count);
				labelColors.push(element.ColorHEX);
			});
			return {
				labels: labels,
				datasets: [
					{
						borderColor: "#F1F1F1",
						borderWidth: 0.5,
						data: labelCount,
						backgroundColor: labelColors,
					},
				],
			};
		} else {
			return {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [labelColors],
					},
				],
			};
		}
	}

	async OnCopyCode() {
		await CopyTextToClipBoard(this.generatedCode.toString());
		this.$store.state.messageText = "Copied to clipboard";
		this.$store.state.showMessage = true;
		this.$data.presentationcode = false;
	}
	async SyncClick() {
		await PresentationSyncing.getInstance().SyncPresentation();
		this.$data.Presentations = [];
		await this.FetchPresentations(
			0,
			this.selectedMonth.id,
			this.selectedMonth.Year
		);
	}

	async FetchPresentations(skip: number, month: number, year: number) {
		if (skip != 0) skip = skip - this.localRecord;
		this.$store.state.loader = true;
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair("month", month));
		params.push(new GenericKeyPair("year", year));
		var presentations = await GetRequestWithAuthorization(
			"Presentation/GetPresentationsShortDetailForSpecificMonth",
			params
		);

		if (presentations != undefined && presentations.Result != undefined) {
			this.TotalRecord = presentations.Result.TotalRecords;

			await this.LoadUnsyncedToo(presentations.Result.PresentationShortDetails);
			if (
				presentations.Result.presentationStats != undefined &&
				presentations.Result.presentationStats != null &&
				presentations.Result.presentationStats != ""
			) {
				this.presentationsStats = presentations.Result.presentationStats;
			}

			if (
				presentations.Result.PresentationDefaultChart != undefined &&
				presentations.Result.PresentationDefaultChart.length > 0
			) {
				var DefaultArray = presentations.Result.PresentationDefaultChart;
				this.showchartCounter = 0;
				this.ShowPresentationType = false;
				this.ShowDispositionType = false;
				this.ShowHouseHoldType = false;
				this.ShowPresentationSubType = false;
				DefaultArray.forEach((productelement: number) => {
					this.showchartCounter++;
					if (productelement == ChartType.Presentation_Type)
						this.ShowPresentationType = true;
					if (productelement == ChartType.Disposition_Type)
						this.ShowDispositionType = true;
					if (productelement == ChartType.Household_Type)
						this.ShowHouseHoldType = true;
					if (productelement == ChartType.Presentation_Subtypes)
						this.ShowPresentationSubType = true;
				});
			} else {
				this.ShowPresentationType = true;
				this.ShowDispositionType = true;
				this.showchartCounter = 2;
			}
		} else {
			await this.LoadUnsyncedToo([]);
		}

		this.$store.state.loader = false;
	}

	GetSelectedPresentationHistory() {
		if (this.selectedDays.length > 0 && this.$data.Presentations != undefined) {
			let selectedpresentationshistory = [];
			selectedpresentationshistory = this.$data.Presentations.filter((x: any) =>
				this.selectedDays.includes(moment(String(x.StartTime)).format("D"))
			);
			return selectedpresentationshistory;
		} else if (this.$data.Presentations != undefined) {
			return this.$data.Presentations;
		}
		return null;
	}

	GetSelectedPresentations() {
		this.PresentationCountByPresType = [];
		this.PresentationCountByWhatHappened = [];
		this.PresentationCountByHouseHold = [];

		if (this.selectedDays.length > 0 && this.$data.Presentations != undefined) {
			let selectedpresentations: any = [];
			this.$data.Presentations.forEach((pres: any) => {
				if (
					this.selectedDays.includes(
						moment(String(pres.StartTime)).format("D")
					) &&
					pres.PresentationStatus != "INCOMPLETE" &&
					pres.WhatHappened != WhatHappened.Training &&
					pres.WhatHappened != WhatHappened.Postponed &&
					pres.WhatHappened != WhatHappened.NeedsAnalysisOnly
				) {
					selectedpresentations.push(pres);
					this.PresentationCountByPresType = UpdatePresentationTypeCount(
						pres.PresentationTypeId,
						this.PresentationCountByPresType
					);
					this.PresentationCountByWhatHappened =
						UpdatePresentationWhatHappenedCount(
							pres.WhatHappened,
							this.PresentationCountByWhatHappened
						);
					this.PresentationCountByHouseHold = UpdatePresentationHouseHoldCount(
						pres.HouseHoldType,
						this.PresentationCountByHouseHold
					);
				}
			});
			return selectedpresentations;
		} else if (this.$data.Presentations != undefined) {
			let selectedpresentations: any = [];
			this.$data.Presentations.forEach((pres: any) => {
				if (
					pres.PresentationStatus != "INCOMPLETE" &&
					pres.WhatHappened != WhatHappened.Training &&
					pres.WhatHappened != WhatHappened.Postponed &&
					pres.WhatHappened != WhatHappened.NeedsAnalysisOnly
				) {
					selectedpresentations.push(pres);
					this.PresentationCountByPresType = UpdatePresentationTypeCount(
						pres.PresentationTypeId,
						this.PresentationCountByPresType
					);
					this.PresentationCountByWhatHappened =
						UpdatePresentationWhatHappenedCount(
							pres.WhatHappened,
							this.PresentationCountByWhatHappened
						);
					this.PresentationCountByHouseHold = UpdatePresentationHouseHoldCount(
						pres.HouseHoldType,
						this.PresentationCountByHouseHold
					);
				}
			});
			return selectedpresentations;
		}
		return null;
	}

	IsNeedAnalysisPresentation(presentation: any) {
		var flag = false;
		if (presentation.WhatHappened == WhatHappened.NeedsAnalysisOnly) {
			flag = true;
		}
		return flag;
	}

	get GetPresPerMonth() {
		if (this.GamePlan == undefined) return 60;
		else {
			let PresPerMonth = 0;
			let SalesPerMonth = 0;
			let GrossAlp = 0;
			let NetAlp =
				GetDecimalvaluefromString(this.Calculator.Income) /
				((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
					100 /
					(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
					GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
			GrossAlp =
				NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);

			SalesPerMonth =
				GrossAlp / GetDecimalvaluefromString(this.Calculator.AlpPerSale);
			PresPerMonth =
				SalesPerMonth /
				(GetDecimalvaluefromString(this.Calculator.Close) / 100);

			if (
				isNaN(PresPerMonth) ||
				PresPerMonth == Infinity ||
				PresPerMonth == 0
			) {
				return 60;
			}

			return Math.ceil(PresPerMonth) > 0
				? ConvertIntoAmountFormatWithDecimal(Math.ceil(PresPerMonth).toString())
				: 60;
		}
	}

	get ShowRecord() {
		var arr = this.GetSelectedPresentationHistory();
		if (arr != null || arr != undefined) {
			if (arr.length != this.PresentationHistoryTotalCount) {
				setTimeout(() => {
					const ele: any = document.getElementById("pres-history");

					ele.scrollTop = 0;
				}, 10);
				this.historyCount = 0;
			}
			this.PresentationHistoryTotalCount = arr.length;
		}
		var val = 10;
		if (this.selectedDays.length == 0) {
		}
		if (this.historyCount == 0) {
			this.ShowHistory = [];
		}
		if (this.historyCount + val > this.PresentationHistoryTotalCount) {
			val = this.PresentationHistoryTotalCount - this.historyCount;
		}
		if (arr != null && arr.length > 0) {
			this.ShowHistory = this.ShowHistory.concat(
				arr.slice(this.historyCount, this.historyCount + val)
			);
		}

		return this.ShowHistory;
	}

	get GetPresentationTypeChartData() {
		let PresentationTypeChartData: any = [];
		this.PresentationType = [];
		if (this.SelectedPresentationsList != null) {
			if (this.PresentationCountByPresType[PresentationType.Referral] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Referral),
					Count: this.PresentationCountByPresType[PresentationType.Referral],
					Title: "REFERRAL",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Referral,
				// 	Name: "REFERRAL",
				// });
			}
			if (
				this.PresentationCountByPresType[PresentationType.McGruffSafeKids] > 0
			) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.McGruffSafeKids
					),
					Count:
						this.PresentationCountByPresType[PresentationType.McGruffSafeKids],
					Title: "MCGRUFF SAFE KIDS",
				});
				this.PresentationType.push({
					Id: PresentationType.McGruffSafeKids,
					Name: "MCGRUFF SAFE KIDS",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.ChildSafe] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.ChildSafe),
					Count: this.PresentationCountByPresType[PresentationType.ChildSafe],
					Title: "CHILD SAFE",
				});
				this.PresentationType.push({
					Id: PresentationType.ChildSafe,
					Name: "CHILD SAFE",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.POS] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.POS),
					Count: this.PresentationCountByPresType[PresentationType.POS],
					Title: "P.O.S",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.POS,
				// 	Name: "P.O.S",
				// });
			}
			if (this.PresentationCountByPresType[PresentationType.Union] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Union),
					Count: this.PresentationCountByPresType[PresentationType.Union],
					Title: "UNION",
				});
				this.PresentationType.push({
					Id: PresentationType.Union,
					Name: "UNION",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.Association] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Association),
					Count: this.PresentationCountByPresType[PresentationType.Association],
					Title: "ASSOCIATION",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Association,
				// 	Name: "ASSOCIATION",
				// });
			}
			if (this.PresentationCountByPresType[PresentationType.CreditUnion] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.CreditUnion),
					Count: this.PresentationCountByPresType[PresentationType.CreditUnion],
					Title: "CREDIT UNION",
				});
				this.PresentationType.push({
					Id: PresentationType.CreditUnion,
					Name: "CREDIT UNION",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.Other] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Other),
					Count: this.PresentationCountByPresType[PresentationType.Other],
					Title: "OTHER",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Other,
				// 	Name: "OTHER",
				// });
			}

			if (this.PresentationCountByPresType[PresentationType.Veteran] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Veteran),
					Count: this.PresentationCountByPresType[PresentationType.Veteran],
					Title: "VETERAN",
				});
				this.PresentationType.push({
					Id: PresentationType.Veteran,
					Name: "VETERAN",
				});
			}
			if (
				this.PresentationCountByPresType[PresentationType.WillKitNoCostLaw] > 0
			) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.WillKitNoCostLaw
					),
					Count:
						this.PresentationCountByPresType[PresentationType.WillKitNoCostLaw],
					Title: "WILL KIT (NO COST LAW)",
				});
				this.PresentationType.push({
					Id: PresentationType.WillKitNoCostLaw,
					Name: "WILL KIT (NO COST LAW)",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.FinalExpense] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.FinalExpense
					),
					Count:
						this.PresentationCountByPresType[PresentationType.FinalExpense],
					Title: "FINAL EXPENSE",
				});
				this.PresentationType.push({
					Id: PresentationType.FinalExpense,
					Name: "FINAL EXPENSE",
				});
			}
			if (this.PresentationCountByPresType[PresentationType.Demo] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.Demo),
					Count: this.PresentationCountByPresType[PresentationType.Demo],
					Title: "DEMO",
				});
				// this.PresentationType.push({
				// 	Id: PresentationType.Demo,
				// 	Name: "DEMO",
				// });
			}
			if (this.PresentationCountByPresType[PresentationType.GlobeLife] > 0) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(PresentationType.GlobeLife),
					Count: this.PresentationCountByPresType[PresentationType.GlobeLife],
					Title: "GLOBE LIFE",
				});
				this.PresentationType.push({
					Id: PresentationType.GlobeLife,
					Name: "GLOBE LIFE",
				});
			}
			if (
				this.PresentationCountByPresType[
					PresentationType.ServiceMemberBenefits
				] > 0
			) {
				PresentationTypeChartData.push({
					ColorHEX: GetHEXColorByPresentationType(
						PresentationType.ServiceMemberBenefits
					),
					Count:
						this.PresentationCountByPresType[
							PresentationType.ServiceMemberBenefits
						],
					Title: "SERVICE MEMBER BENEFITS",
				});
				this.PresentationType.push({
					Id: PresentationType.ServiceMemberBenefits,
					Name: "SERVICE MEMBER BENEFITS",
				});
			}

			this.SelectedPresentationType =
				this.PresentationType.length > 0 ? this.PresentationType[0] : null;
			this.presentationsStats.PresentationTypeChartData =
				PresentationTypeChartData;
			return this.FillChartDataFromAPIData(PresentationTypeChartData);
		}
		this.presentationsStats.PresentationTypeChartData =
			PresentationTypeChartData;
		return this.FillChartDataFromAPIData(PresentationTypeChartData);
	}

	get GetDispositionChartData() {
		let DispositionChartData: any = [];
		if (this.SelectedPresentationsList != null) {
			if (this.PresentationCountByWhatHappened[WhatHappened.Enrollment] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.Enrollment
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Enrollment],
					Title: "ENROLLMENT",
				});
			}

			if (
				this.PresentationCountByWhatHappened[WhatHappened.NotInterested] > 0
			) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.NotInterested
					),
					Count:
						this.PresentationCountByWhatHappened[WhatHappened.NotInterested],
					Title: "NOT INTERESTED",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Think] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(WhatHappened.Think),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Think],
					Title: "THINK",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.JustNoCost] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.JustNoCost
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.JustNoCost],
					Title: "JUST NO COST",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Qualify] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(WhatHappened.Qualify),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Qualify],
					Title: "QUALIFY",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Training] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.Training
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Training],
					Title: "TRAINING",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Afford] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(WhatHappened.Afford),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Afford],
					Title: "AFFORD",
				});
			}

			if (this.PresentationCountByWhatHappened[WhatHappened.Postponed] > 0) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.Postponed
					),
					Count: this.PresentationCountByWhatHappened[WhatHappened.Postponed],
					Title: "POSTPONED",
				});
			}
			if (
				this.PresentationCountByWhatHappened[WhatHappened.NeedsAnalysisOnly] > 0
			) {
				DispositionChartData.push({
					ColorHEX: GetHEXColorByPresentationWhatHappened(
						WhatHappened.NeedsAnalysisOnly
					),
					Count:
						this.PresentationCountByWhatHappened[
							WhatHappened.NeedsAnalysisOnly
						],
					Title: "NEEDS ANALYSIS ONLY",
				});
			}

			this.presentationsStats.DispositionChartData = DispositionChartData;
			return this.FillChartDataFromAPIData(DispositionChartData);
		}
		this.presentationsStats.DispositionChartData = DispositionChartData;
		return this.FillChartDataFromAPIData(DispositionChartData);
	}

	get GetHouseHoldTypeChartData() {
		let HouseHoldTypeChartData: any = [];
		if (this.SelectedPresentationsList != null) {
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.single] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.single),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.single],
					Title: "single",
				});
			}
			if (
				this.PresentationCountByHouseHold[HpProHouseHoldType.single_parent] > 0
			) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.single_parent),
					Count:
						this.PresentationCountByHouseHold[HpProHouseHoldType.single_parent],
					Title: "Single Parent",
				});
			}
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.married] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.married),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.married],
					Title: "Married",
				});
			}
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.family] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.family),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.family],
					Title: "Family",
				});
			}
			if (this.PresentationCountByHouseHold[HpProHouseHoldType.retired] > 0) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.retired),
					Count: this.PresentationCountByHouseHold[HpProHouseHoldType.retired],
					Title: "Retired",
				});
			}
			if (
				this.PresentationCountByHouseHold[HpProHouseHoldType.single_retired] > 0
			) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.single_retired),
					Count:
						this.PresentationCountByHouseHold[
							HpProHouseHoldType.single_retired
						],
					Title: "Single/ Retired",
				});
			}
			if (
				this.PresentationCountByHouseHold[HpProHouseHoldType.married_retired] >
				0
			) {
				HouseHoldTypeChartData.push({
					ColorHEX: GetHEXColorByHouseHold(HpProHouseHoldType.married_retired),
					Count:
						this.PresentationCountByHouseHold[
							HpProHouseHoldType.married_retired
						],
					Title: "Married/ Retired",
				});
			}
			this.presentationsStats.HouseHoldTypeChartData = HouseHoldTypeChartData;
			return this.FillChartDataFromAPIData(HouseHoldTypeChartData);
		}
		this.presentationsStats.HouseHoldTypeChartData = HouseHoldTypeChartData;
		return this.FillChartDataFromAPIData(HouseHoldTypeChartData);
	}

	get GetPresentationSubTypeChartData() {
		let PresentationSubTypeChartData: any = [];
		this.PresentationCountBySubType = [];
		if (
			this.SelectedPresentationsList != null &&
			this.SelectedPresentationType != null
		) {
			let selectedPrestypePresentations = this.SelectedPresentationsList.filter(
				(x: any) => this.SelectedPresentationType.Id == x.PresentationTypeId
			);

			if (selectedPrestypePresentations.length > 0) {
				selectedPrestypePresentations.forEach((pres: any) => {
					if (this.SelectedPresentationType.Id == pres.PresentationTypeId) {
						UpdatePresentationSubTypeCount(
							pres.PresentationSubTypeId,
							this.PresentationCountBySubType
						);
					}
				});
				if (this.PresentationCountBySubType.length > 0) {
					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineMCGruffLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineMCGruffLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineMCGruffLead
								],
							Title: "Online McGruff Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredMcGruffLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredMcGruffLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredMcGruffLead
								],
							Title: "Sponsored McGruff Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineChildSafeLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineChildSafeLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineChildSafeLead
								],
							Title: "Online Child Safe Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredChildSafeLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredChildSafeLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredChildSafeLead
								],
							Title: "Sponsored Child Safe Lead",
						});
					}
					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredVeteranLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredVeteranLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredVeteranLead
								],
							Title: "Sponsored Veteran Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.VeteranFamilyLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.VeteranFamilyLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.VeteranFamilyLead
								],
							Title: "Veteran Family Lead",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.ReturnCard] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReturnCard
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.ReturnCard],
							Title: "Return Card",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.BurielWillKitForVeteran
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.BurielWillKitForVeteran
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.BurielWillKitForVeteran
								],
							Title: "Buriel Will Kit For Veteran",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredNoCostLaw
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredNoCostLaw
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredNoCostLaw
								],
							Title: "Sponsored No Cost Law",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineNoCostLaw
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineNoCostLaw
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineNoCostLaw
								],
							Title: "Online No Cost Law",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredFinalExpense
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredFinalExpense
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredFinalExpense
								],
							Title: "Sponsored Final Expense",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineFinalExpense
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineFinalExpense
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineFinalExpense
								],
							Title: "Online Final Expense",
						});
					}

					if (this.PresentationCountBySubType[PresentationSubType.Afford] > 0) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Afford
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.Afford],
							Title: "Afford",
						});
					}

					if (this.PresentationCountBySubType[PresentationSubType.Think] > 0) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Think
							),
							Count: this.PresentationCountBySubType[PresentationSubType.Think],
							Title: "Think",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.Qualify] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Qualify
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.Qualify],
							Title: "Qualify",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.NotInterested] >
						0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.NotInterested
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.NotInterested
								],
							Title: "Not Interested",
						});
					}

					if (this.PresentationCountBySubType[PresentationSubType.Sale] > 0) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Sale
							),
							Count: this.PresentationCountBySubType[PresentationSubType.Sale],
							Title: "Sale",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ReportFormRequest
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReportFormRequest
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ReportFormRequest
								],
							Title: "Report Form Request",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SponsoredCreditUnionBenefits
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SponsoredCreditUnionBenefits
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SponsoredCreditUnionBenefits
								],
							Title: "Sponsored Credit Union Benefits",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineCreditUnionBenefits
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineCreditUnionBenefits
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineCreditUnionBenefits
								],
							Title: "Online Credit Union Benefits USA",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.OnlineCreditUnionBenefitsCA
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.OnlineCreditUnionBenefitsCA
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.OnlineCreditUnionBenefitsCA
								],
							Title: "Online Credit Union Benefits CA",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ReturnCardNoCost
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReturnCardNoCost
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ReturnCardNoCost
								],
							Title: "Return Card",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ServiceMemberBenefitsLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ServiceMemberBenefitsLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ServiceMemberBenefitsLead
								],
							Title: "Service Member Benefits Lead",
						});
					}
					if (
						this.PresentationCountBySubType[
							PresentationSubType.ServiceMemberBenefitsLeadAlpha
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ServiceMemberBenefitsLeadAlpha
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ServiceMemberBenefitsLeadAlpha
								],
							Title: "Service Member Benefits Lead (Alpha)",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.MarketUnionAssocMember
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.MarketUnionAssocMember
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.MarketUnionAssocMember
								],
							Title: "Market Union/Assoc Member",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.MarketUnionAssocFamilyFriend
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.MarketUnionAssocFamilyFriend
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.MarketUnionAssocFamilyFriend
								],
							Title: "Market Union/Assoc Family/Friend",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ReturnCardUnion
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ReturnCardUnion
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ReturnCardUnion
								],
							Title: "Return Card",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.GlobeWebApplication
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobeWebApplication
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.GlobeWebApplication
								],
							Title: "Globe Web Application",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.GlobeCustomer] >
						0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobeCustomer
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.GlobeCustomer
								],
							Title: "Globe Customer",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.GlobePlus] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobePlus
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.GlobePlus],
							Title: "Globe Plus",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.GlobeInquiry] >
						0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.GlobeInquiry
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.GlobeInquiry
								],
							Title: "Globe Inquiry",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBSponsoredVeteranLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBSponsoredVeteranLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBSponsoredVeteranLead
								],
							Title: "Sponsored Veteran Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBVeteranFamilyLead
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBVeteranFamilyLead
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBVeteranFamilyLead
								],
							Title: "Veteran Family Lead",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBSponsoredVeteranLeadAlpha
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBSponsoredVeteranLeadAlpha
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBSponsoredVeteranLeadAlpha
								],
							Title: "Sponsored Veteran Lead (Alpha)",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.SMBVeteranFamilyLeadAlpha
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.SMBVeteranFamilyLeadAlpha
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.SMBVeteranFamilyLeadAlpha
								],
							Title: "Veteran Family Lead (Alpha)",
						});
					}

					if (
						this.PresentationCountBySubType[PresentationSubType.Civilian] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.Civilian
							),
							Count:
								this.PresentationCountBySubType[PresentationSubType.Civilian],
							Title: "Civilian",
						});
					}

					if (
						this.PresentationCountBySubType[
							PresentationSubType.ServiceMemberBenefitNotification
						] > 0
					) {
						PresentationSubTypeChartData.push({
							ColorHEX: GetHEXColorByPresentationSubType(
								PresentationSubType.ServiceMemberBenefitNotification
							),
							Count:
								this.PresentationCountBySubType[
									PresentationSubType.ServiceMemberBenefitNotification
								],
							Title: "Service Member Benefit Notification",
						});
					}
				}
			}

			this.presentationsStats.PresentationSubTypeChartData =
				PresentationSubTypeChartData;
			return this.FillChartDataFromAPIData(PresentationSubTypeChartData);
		}
		this.presentationsStats.PresentationSubTypeChartData =
			PresentationSubTypeChartData;
		return this.FillChartDataFromAPIData(PresentationSubTypeChartData);
	}

	get GetTotalPresentations() {
		var presentationcount = 0;
		this.SelectedPresentationsList = this.GetSelectedPresentations();
		if (this.SelectedPresentationsList != null) {
			return this.SelectedPresentationsList.length;
		}
		return presentationcount;
	}

	get GetTotalSales() {
		var salescount = 0;
		if (this.SelectedPresentationsList != null) {
			var filteredpresentations = this.SelectedPresentationsList.filter(
				(x: any) => x.WhatHappened == WhatHappened.Enrollment
			);
			return filteredpresentations.length;
		}

		return salescount;
	}

	get GetTotalALP() {
		var totalAlp = 0;
		if (this.SelectedPresentationsList != null) {
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (
					pres.WhatHappened == WhatHappened.Enrollment &&
					pres.TotalALP != null
				) {
					totalAlp += pres.TotalALP;
				}
			});
			return totalAlp > 0 ? totalAlp : 0;
		}

		return totalAlp;
	}

	get GetALPRemaining() {
		var remainingAlp = 0.0;
		if (this.ALPGoal != null && this.ALPGoal != Infinity && this.ALPGoal > 0) {
			remainingAlp =
				this.GetTotalALP > 0 ? this.ALPGoal - this.GetTotalALP : this.ALPGoal;
			return remainingAlp >= 0 ? remainingAlp : 0;
		} else {
			remainingAlp = this.GetTotalALP > 0 ? 20000 - this.GetTotalALP : 20000;
			return remainingAlp >= 0 ? remainingAlp : 0;
		}
	}

	get GetCloseRatio() {
		var CloseRatio = 0;
		if (this.GetTotalPresentations > 0 && this.GetTotalSales > 0) {
			CloseRatio = (this.GetTotalSales / this.GetTotalPresentations) * 100;
			return CloseRatio > 0
				? ConvertIntoAmountFormatWithDecimal(CloseRatio.toFixed(2).toString())
				: 0;
		}
		return CloseRatio;
	}

	get GetAlpPerSale() {
		var AlpPerSale = 0.0;
		if (this.GetTotalALP > 0 && this.GetTotalSales > 0) {
			AlpPerSale = this.GetTotalALP / this.GetTotalSales;
			return AlpPerSale > 0 ? AlpPerSale : 0.0;
		}
		return AlpPerSale;
	}

	get GetAhpPerSale() {
		var AhpPerSale = 0.0;
		if (this.SelectedPresentationsList != null) {
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (
					pres.WhatHappened == WhatHappened.Enrollment &&
					pres.TotalAHP != null
				) {
					AhpPerSale += pres.TotalAHP;
				}
			});
			if (this.GetTotalSales != null) {
				return AhpPerSale > 0 ? AhpPerSale / this.GetTotalSales : 0.0;
			}
			return 0.0;
		}
		return AhpPerSale;
	}

	get GetPlusLeadsCollected() {
		var plusLeadCollected = 0;
		if (this.SelectedPresentationsList != null) {
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (pres.TotalReferals != null) {
					plusLeadCollected += pres.TotalReferals;
				}
			});
			return plusLeadCollected > 0 ? plusLeadCollected : 0;
		}
		return plusLeadCollected;
	}

	get GetPlusLeadsPerPresentation() {
		var plusLeadsPerPresentation = 0;
		if (this.GetTotalPresentations > 0 && this.GetPlusLeadsCollected > 0) {
			plusLeadsPerPresentation =
				this.GetPlusLeadsCollected / this.GetTotalPresentations;
			return plusLeadsPerPresentation > 0
				? ConvertIntoAmountFormatWithDecimal(
						plusLeadsPerPresentation.toFixed(2).toString()
				  )
				: 0;
		}
		return plusLeadsPerPresentation;
	}

	get GetTimePerPresentations() {
		var timePerPresentation = "00:00:00";

		if (this.SelectedPresentationsList != null) {
			var totalseconds = 0;
			this.SelectedPresentationsList.forEach((pres: any) => {
				if (pres.TotalTimeSeconds != null) {
					totalseconds += pres.TotalTimeSeconds;
				}
			});
			var TimeinSeconds = totalseconds / this.GetTotalPresentations;
			return TimeinSeconds > 0
				? new Date(TimeinSeconds * 1000).toISOString().slice(11, 19)
				: "00:00:00";
		}
		return timePerPresentation;
	}

	async LoadUnsyncedToo(presentations: any) {
		this.$data.Presentations = [];
		var presentationList: any = await getPersistedState("PresentationList");
		if (presentationList != undefined && presentationList.length > 0) {
			presentationList = presentationList.filter((x: any) => {
				if (
					this.selectedMonth.id ==
						Number(moment(String(x.StartTime)).format("MM")) &&
					this.selectedMonth.Year ==
						Number(moment(String(x.StartTime)).format("YYYY"))
				)
					return x;
			});

			this.TotalRecord += presentationList.length;
			this.localRecord != presentationList.length;
			presentationList.forEach((element: any) => {
				var pres = new Presentation(element);
				element.StartTimeString = pres.StartTimeString;
				element.PrimaryFullName = pres.PresentedTo?.fullname;
				element.StepCompleted = pres.CurrentStepCompleted;
				if (element.StepCompleted == 5)
					element.PresentationStatus = element.WhatHappened;
				else element.PresentationStatus = "INCOMPLETE";
				element.Unsynchronized = true;

				if (pres.PlanOptions != undefined && pres.PlanOptions != "") {
					var planOpt = JSON.parse(pres.PlanOptions);
					if (planOpt != undefined && planOpt.length > 0) {
						element.PlanOptions = [];
						element.PlanOptions.push({});
						element.TotalALP = planOpt.find(
							(x: any) => x.isSelected == true
						)?.TotalALP;
						element.TotalAHP = planOpt.find(
							(x: any) => x.isSelected == true
						)?.TotalAHP;
					} else {
						element.PlanOptions = [];
						element.PlanOptions.push({});
						element.TotalALP = 0;
					}
				}
			});
			this.$data.Presentations.push(...presentationList);
			if (
				presentations != null &&
				presentations != undefined &&
				presentations.length > 0
			) {
				this.$data.Presentations.push(...presentations);
			}
		} else {
			if (
				presentations != null &&
				presentations != undefined &&
				presentations.length > 0
			) {
				this.$data.Presentations.push(...presentations);
			}
		}
	}

	OnPresentationsScroll(event: any) {
		window.addEventListener("wheel", this.Updatehistorycount);
	}

	Updatehistorycount(event: any) {
		if (event.deltaY > 0) {
			setTimeout(() => {
				const ele: any = document.getElementById("pres-history");
				var test = ele.scrollTop + ele.offsetHeight >= ele.scrollHeight;
				if (test) {
					this.historyCount += 10;
				}
			}, 10);
		}
	}
	get ProfilePicture() {
		var dataURL;
		if (this.$store.state.ProfileImage == true)
			dataURL = this.$store.state.ProfileImageString;
		if (dataURL != null && dataURL != undefined && dataURL != "")
			this.imgSource = dataURL;
		else if (this.$route.path != "/home" && this.$route.path != "/training") {
			this.imgSource = placeholderuser;
		} else {
			this.imgSource = placeholderuser;
		}
		return this.imgSource;
	}
}
