export enum WhatHappened {
	Enrollment = 1,
	NotInterested,
	Think,
	JustNoCost,
	Qualify,
	Training,
	Afford,
	Postponed,
	NeedsAnalysisOnly
}

export const WhatHappenedDiscription = new Map<number, string>([
	[ WhatHappened.Enrollment, 'Enrollment' ],
	[ WhatHappened.NotInterested, 'Not Interested' ],
	[ WhatHappened.Think, 'Think' ],
	[ WhatHappened.JustNoCost, 'Just No Cost' ],
	[ WhatHappened.Qualify, 'Qualify' ],
	[ WhatHappened.Training, 'Training' ],
	[ WhatHappened.Afford, 'Afford' ],
	[ WhatHappened.Postponed, 'Postponed' ],
	[ WhatHappened.NeedsAnalysisOnly, 'Needs Analysis Only' ]
]);
