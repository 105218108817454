

























































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import VeteranBurialFacts from "../components/popups/VeteranBurialFacts.vue";
import MedicalQuestions from "../components/popups/MedicalQuestions.vue";
import UWPopup from "../components/popups/UWPopup.vue";
import MenuComponent from "../components/menu.vue";
import { GetSyncVersionByType, isJson } from "../helpers/ArrayHelper";
import { namespace } from "vuex-class";
import { WageType } from "@/enumerations/WageType";
const persistencePlugin = namespace("persistencePlugin");

let lstLanguages = require("../assets/pre-fill-data/language-list.json");

import { setPersistedState, getPersistedState } from "../helpers/StateMapper";
import GenericKeyPair from "../models/GenericKeyPair";
import Presentation from "../entities/Presentation";
import User from "../entities/User";
import PrimaryPerson from "../entities/PrimaryPerson";
import Spouse from "../entities/Spouse";
import { register } from "register-service-worker";
import Report from "../entities/Report";
import { videoPlayer } from "vue-video-player";
import { gsap } from "gsap";
import { SetCookie, GetCookie } from "../helpers/CookieHelper";
import RateBookBox from "../components/RateBookBox.vue";
import RateBookEntity from "../entities/RateBookEntity";
import PlanSummary from "../entities/PlanSummary";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import {
	PersonPlan,
	PlanEntity,
	PresentationPlans,
	SpousePlan,
} from "../entities/PlanEntity";
import Children from "../entities/Children";
import PremiumPlanEntity from "../entities/PremiumPlanEntity";
import checkDoubleIconVue from "../components/icons/check-double-icon.vue";
let placeholderuser = require("../assets/placeholderuser.png");
import {
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
} from "@/helpers/CalculationHelper";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetRequestWithAuthorization } from "@/helpers/APIHelper";
import {
	FillPresentationByMemberType,
	FillPresentationFromLead,
	PresentationDTOToEntity,
} from "@/helpers/MappingHelper";
import i18n, { changeLocale } from "../plugins/i18n";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import moment from "moment";
import LoggingHelper from "../helpers/LoggingHelper";
import LoggingConstants from "../constants/LoggingConstants";
import { GetAgentCode } from "@/helpers/APIExtentions";
import { MemberLookupType } from "@/enumerations/MemberLookupType";
import { PresentationType } from "@/enumerations/PresentationType";
import { EventBusLayout } from "../bus/EventBusLayout";
import { PresentationSubType } from "@/enumerations/PresentationSubType";

@Component({
	components: {
		ShortUserDetailView,
		VeteranBurialFacts,
		MenuComponent,
		MedicalQuestions,
		UWPopup,
		videoPlayer,
		RateBookBox,
		ReferralPopup,
	},
})
export default class StartPresentation extends Vue {
	private Group: any;
	private SelectedLanguage: any = "ENGLISH";
	private PresentationTypes: any = [];
	private allPresentationTypes: any = [];
	private States: any = [];
	private Languages = lstLanguages;
	private Groups: any = [];
	private SubTypes: any = [];
	private SelectedPresentationType: any = "";
	private SelectedSubType: any = null;
	private DisabledSubType: Boolean = true;
	private SGNumber: any;
	private showRateBook: Boolean = false;
	private showVeteranBurialFacts: Boolean = false;
	private showpresentationifo: Boolean = false;
	private user: any;
	private CurrentUser: any;
	private selectedState: any = "";
	private license: string = "";
	private licenseId!: number;
	private UnionCode: string = "";
	private presentationcode: string = "";
	private licStates!: Array<any>;
	private showMedicalQuestions: boolean = false;
	private showUW: boolean = false;
	private showVideo: boolean = false;
	public imgSource: string = "/img/placeholderuser.png";
	private codePresType: string = "";
	private codePresSubType: string = "";
	private presToClone!: any;
	private prePlanData!: any;
	private isPrePlan: boolean = false;
	ListOfRatePlan: RateBookEntity[] = [];
	NoOfRateBookRows: number[] = [];
	LeadId: string = "";
	MemberLookupType: MemberLookupType = MemberLookupType.ByLead;
	private ResetReferrals: boolean = false;
	private allMatchingproducts: any = [];
	private LeadItems: any = [];
	private isClientNameFocus: boolean = false;
	private SelectedLead: any = undefined;
	private PreviousSelectedLead: string = "";
	private IsMexicanStateSelected: boolean = false;

	private ShowOnlyMemberLookup: boolean = true;

	@persistencePlugin.Action
	private persistencePlugin!: (data: any) => Promise<any>;

	private ssprogram: boolean = false;
	private GiftAmount: string = "0";
	private SelectedStateId = 0;

	private apiUserDetail: any = {};
	LeadSelected: boolean = false;
	lastClientNameText: string = "";

	PreMemberLookupData: any = [];

	$refs!: {
		spForm: any;
		groupComboBox: any;
		groupNameComboBox: any;
		videoPlayer: any;
	};

	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		//if (oldValue == undefined) {
		var refinit = {
			ReferredBy: "",
			ReferredByLastName: "",
			Organization: "",
			Phone: "",
			Email: "",
			Date: moment(new Date()).format("MM/DD/YYYY"),
			ReferralsList: [],
			CCEmail: [],
		};
		// if (refinit.Date == undefined || refinit.Date == "")
		//   refinit.Date = moment(new Date()).format("MM/DD/YYYY");
		this.$data.Referrals = refinit;
		//}
		// if (newValue == true) {
		//   this.ResetReferrals = !this.ResetReferrals;
		//   LoggingHelper.getInstance().LogEvent(
		//     LoggingConstants.SponsorshipPopupOpened
		//   );
		// } else {
		//   LoggingHelper.getInstance().LogEvent(
		//     LoggingConstants.SponsorshipPopupClosed
		//   );
		// }
	}

	CloseReferralPopup() {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
	}

	@Watch("presentationcode")
	async OnCodeChange(newValue: number, oldValue: number) {
		this.presToClone = null;
		this.codePresType = "";
		this.codePresSubType = "";
		if (newValue && newValue.toString().length >= 6) {
			// If Presentation is to be cloned
			if (this.SelectedPresentationType.ShowPresentationCode) {
				this.$store.state.loader = true;

				var params: GenericKeyPair[] = [];
				params.push(
					new GenericKeyPair("presentationCode", this.presentationcode)
				);
				this.presToClone = await GetRequestWithAuthorization(
					"Presentation/GetPresentationDetailByPresCode",
					params
				);

				if (!this.presToClone || !this.presToClone.Result) {
					this.$store.state.messageText = this.$t(
						"StartPresentation.PresentationCodeError"
					);
					this.$store.state.showMessage = true;
					this.$store.state.loader = false;
					return;
				}
				console.log(this.presToClone.Result);
				this.presToClone = await PresentationDTOToEntity(
					this.presToClone.Result
				);
				console.log(this.presToClone);
				this.$store.state.loader = false;

				//this.clonePresentation(this.presToClone);
			} else {
				return;
			}

			if (this.presToClone) {
				var presType = this.PresentationTypes.find(
					(i: any) => i.id == this.presToClone?.PresentationTypeId
				);
				var st = this.States.find(
					(x: any) => x.StateId == this.presToClone.StateId
				);
				this.selectedState = st.Name;

				switch (this.presToClone.LanguageFlag) {
					case "en":
						this.SelectedLanguage = "ENGLISH";
						break;
					case "fr":
						this.SelectedLanguage = "FRENCH";
						break;
					case "es":
						this.SelectedLanguage = "SPANISH";
						break;
				}
				this.$i18n.locale = this.presToClone.LanguageFlag;
				//this.SelectedSubType = this.presToClone.SubType;
				this.SGNumber = this.presToClone.SGNumber;
				this.codePresType = this.presToClone?.PresentationTypeString;
				this.codePresSubType = this.presToClone?.SubType;
				this.Group = this.Groups.find(
					(x: any) => x.Id == this.presToClone.PresentedGroupId
				);
				this.changeGroup("Group");
				setTimeout(() => {
					var subt = this.SubTypes.find(
						(x: any) =>
							x.Name.toUpperCase() ==
							this.presToClone.WhatHappened.toUpperCase()
					);

					if (subt) {
						this.SelectedSubType = subt.Name;
						this.SubTypeChange();
					}
				}, 100);
			}
		}
	}
	overflowhidden: boolean = false;
	customEvent() {
		this.overflowhidden = true;
		// var tj = gsap.timeline();

		// tj.to(".circle",{duration:2, x:50})
		//     .to(".circle",{duration:1, y:100})

		gsap.from(".animationSlideUp", {
			opacity: 0,
			y: 550,
			duration: 0.2,
			scaleX: 1,
			scaleY: 1,
		});
		gsap.to(".animationSlideUp", {
			y: 0,
			duration: 0.2,
			scaleX: 1,
			scaleY: 1,
		});
		gsap.to(".animationSlideUp", {
			delay: 0,
			y: 0,
			duration: 0.3,
			scaleX: 1,
			scaleY: 1,
		});

		setTimeout(() => {
			this.overflowhidden = false;
		}, 1200);
	}

	async LoadDropDown() {
		this.$store.state.loader = true;
		var res = await getPersistedState("StateList");
		this.States = res;
		var allPresTypes = (await getPersistedState(
			"PresentationTypesVeteran"
		)) as Array<any>;
		// var subPresTypes = await getPersistedState("PresentationTypesVeteran");

		this.allPresentationTypes = allPresTypes;
		this.PresentationTypes = allPresTypes;

		// if (
		// 	this.MemberLookupType != undefined &&
		// 	this.MemberLookupType != MemberLookupType.ProLead
		// ) {
		// 	this.PresentationTypes = subPresTypes;
		// 	this.PresentationTypes.splice(
		// 		this.PresentationTypes.indexOf(
		// 			this.PresentationTypes.find(
		// 				(i: { Name: any }) => i.Name == "PRO LEAD"
		// 			)
		// 		),
		// 		1
		// 	);
		// } else {
		// 	this.PresentationTypes = subPresTypes;
		// }

		var stateFromCookie = GetCookie("PreviouslySelectedState");
		if (
			stateFromCookie != undefined &&
			stateFromCookie != "" &&
			isJson(stateFromCookie)
		) {
			this.selectedState = JSON.parse(stateFromCookie);
		}
		this.stateChanged();

		var allgrps = await getPersistedState("PresentationGroups");
		this.Groups = allgrps;

		console.log("is Pre Plan : " + this.$route.params.initiatePrePlan);
		this.isPrePlan = Boolean(this.$route.params.initiatePrePlan);
		this.LoadMedQues();
		if (this.isPrePlan && this.SelectedLead != "PRO LEAD") {
			this.initiatePrePlan();
		} else {
			this.$store.state.loader = false;
		}

		getPersistedState("Materials").then((res: any) => {
			this.allMatchingproducts = res
				.filter(
					(x: any) =>
						(x.CountryId == undefined || x.CountryId == 1) &&
						(x.PresentationTypeId == "" || x.PresentationTypeId == 5)
				)
				.sort((a: any, b: any) => a.priority - b.priority);
		});
	}

	LoadMedQues() {
		GetLocalDataOrCallAPI(
			ApiEndPointConstants.GeneralMedicalQuestionEndpoint,
			[],
			IndexDBConstants.GeneralMedicalQuestionKey
		).then((medQs: any) => {
			if (medQs != null) {
				var result = medQs.reduce(function (r: any, e: any) {
					r[e.QuestionType] = medQs.filter(
						(x: any) => x.QuestionType == e.QuestionType
					);
					return r;
				}, {});
				result.senior = result.Senior.reduce(function (r: any, e: any) {
					r[e.Section] = result.Senior.filter(
						(x: any) => x.Section == e.Section
					);
					return r;
				}, {});
				result.supercombo = result.SuperCombo.reduce(function (r: any, e: any) {
					r[e.Section] = result.SuperCombo.filter(
						(x: any) => x.Section == e.Section
					);
					return r;
				}, {});

				this.$data.medicalquestions = JSON.parse(JSON.stringify(result));
			}
		});
	}

	data() {
		return {
			ImageLoading: false,
			loadingStart: false,
			medicalquestions: {},
			SubtypeValid: true,
			valid: false,
			SGNumber: this.SGNumber,
			Group: this.Group,
			Required: [(v: any) => !!v || "Field is required"],
			Referrals: {},
			playerOptions: {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: "static/ailvideo.mp4",
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			},
			PresCodeRules: [
				(value: any) => {
					if (value.length >= 6) {
						return true;
					} else {
						return false;
					}
				},
			],
		};
	}

	closeUWPopup(str: any) {
		this.showUW = false;
		this.$store.state.OpenedPopupName = "";
		LoggingHelper.getInstance().LogEvent(LoggingConstants.UWPopupClosed);
	}

	CloseOverlay() {
		this.showVideo = false;
	}

	changePresentationType(a: any) {
		this.presentationcode = "";
		this.SelectedPresentationType = this.PresentationTypes.find(
			(i: { Id: any }) => i.Id === a.Id
		);
		if (
			this.SelectedPresentationType.SubTypes != null &&
			this.SelectedPresentationType.SubTypes.length > 0
		) {
			this.SelectedSubType = null;
			this.SubTypes = this.SelectedPresentationType.SubTypes.filter(
				(x: any) =>
					x.CountryId == this.selectedState.CountryId || x.CountryId == null
			);

			if (
				this.SubTypes.length > 0 &&
				this.SelectedPresentationType.Id == PresentationType.Veteran
			) {
				this.SubTypes.splice(
					this.SubTypes.indexOf(
						this.SubTypes.find(
							(x: any) => x.Name == "SERVICE MEMBER BENEFITS LEAD"
						)
					),
					1
				);
				this.SubTypes.splice(
					this.SubTypes.indexOf(
						this.SubTypes.find(
							(x: any) => x.Name == "SERVICE MEMBER BENEFITS LEAD (ALPHA)"
						)
					),
					1
				);
			}

			if (
				this.SubTypes.length > 0 &&
				this.SelectedPresentationType.Id ==
					PresentationType.ServiceMemberBenefits &&
				this.CurrentUser != undefined &&
				this.CurrentUser != null
			) {
				if (this.CurrentUser.VeteranSmbAllowed) {
					this.SubTypes.splice(
						this.SubTypes.indexOf(
							this.SubTypes.find((x: any) => x.Name == "SPONSORED VETERAN LEAD")
						),
						1
					);
					this.SubTypes.splice(
						this.SubTypes.indexOf(
							this.SubTypes.find((x: any) => x.Name == "VETERAN FAMILY LEAD")
						),
						1
					);
				} else {
					this.SubTypes.splice(
						this.SubTypes.indexOf(
							this.SubTypes.find(
								(x: any) => x.Name == "SPONSORED VETERAN LEAD (ALPHA)"
							)
						),
						1
					);
					this.SubTypes.splice(
						this.SubTypes.indexOf(
							this.SubTypes.find(
								(x: any) => x.Name == "VETERAN FAMILY LEAD (ALPHA)"
							)
						),
						1
					);
					this.SubTypes.splice(
						this.SubTypes.indexOf(
							this.SubTypes.find((x: any) => x.Name == "CIVILIAN")
						),
						1
					);
					this.SubTypes.splice(
						this.SubTypes.indexOf(
							this.SubTypes.find(
								(x: any) => x.Name == "SERVICE MEMBER BENEFIT NOTIFICATION"
							)
						),
						1
					);
				}
			}
			this.DisabledSubType = false;
			this.$data.SubtypeValid = false;
		} else {
			this.SubTypes = [];
			this.SelectedSubType = "";
			this.DisabledSubType = true;
			this.$data.SubtypeValid = true;
		}
	}
	initiatePrePlan() {
		getPersistedState("PrePlan").then((result: any) => {
			if (result != undefined) {
				setTimeout(() => {
					result = JSON.parse(result);
					this.prePlanData = result;

					this.prePlanData.Presentation = JSON.parse(
						this.prePlanData.Presentation
					);
					if (typeof result.State == "string" && this.States != undefined)
						this.selectedState = this.States.find(
							(x: any) => x.StateCode == result.State
						);
					else this.selectedState = result.State;
					this.IsMexicanStateSelected =
						this.selectedState == "NM" || this.selectedState?.StateCode == "NM";
					if (result.PresentationType) {
						this.SelectedPresentationType = result.PresentationType;
						this.changePresentationType(this.SelectedPresentationType);
					}
					this.$store.state.loader = false;
				}, 500);
			}
		});
	}

	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		var self = this;
		EventBusLayout.$on("RoutedToEvt", this.StartPresentationRoutedToEvt);
		EventBusLayout.$on("Action", this.StartPresentationAction);
		var dataURL = this.$store.state.ProfileImageString;

		if (dataURL != null && dataURL != undefined) this.imgSource = dataURL;
		else if (this.$route.path != "/home" && this.$route.path != "/training") {
			this.imgSource = placeholderuser;
		} else {
			this.imgSource = placeholderuser;
		}

		this.user = User.query().last();
		if (this.user != undefined) {
			this.SelectedLanguage = this.user.Language
				? this.user.Language
				: "ENGLISH";
			this.selectedState = this.user.Territory;
		}
		this.LoadDropDown();

		this.LoadProfileLanguage();
		var _keydownCallback = (event: any) => {
			if (event.code == "Escape") {
				this.showVideo = false;
				this.showpresentationifo = false;
			}

			if (this.showVideo && event.code == "Space") {
				if (
					this.$refs.videoPlayer != undefined &&
					this.$refs.videoPlayer.player != undefined &&
					!this.$refs.videoPlayer.player.paused()
				)
					this.$refs.videoPlayer.player.pause();
				else if (
					this.$refs.videoPlayer != undefined &&
					this.$refs.videoPlayer.player != undefined
				)
					this.$refs.videoPlayer.player.play_();
				//this.$refs.videoPlayer.pause();
			}
		};

		document.addEventListener("keyup", _keydownCallback);

		this.PreviousSelectedLead = "";
		this.PreMemberLookupData.push({
			TypeName: "OTHER",
			MemberLookUpType: MemberLookupType.Other,
			isMemberType: true,
		});
		this.PreMemberLookupData.push({
			TypeName: "TRAINING",
			MemberLookUpType: MemberLookupType.Training,
			isMemberType: true,
		});
		this.PreMemberLookupData.push({
			TypeName: "DEMO",
			MemberLookUpType: MemberLookupType.Demo,
			isMemberType: true,
		});
		// this.PreMemberLookupData.push({
		// 	TypeName: "PRO LEAD",
		// 	MemberLookUpType: MemberLookupType.ProLead,
		// 	isMemberType: true,
		// });

		let Agent = localStorage.getItem("user");
		if (Agent != undefined) {
			var userObj = JSON.parse(Agent);
			this.CurrentUser = userObj.UserDetail;
		}
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}
	StartPresentationRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	StartPresentationAction(action: any) {
		this.Action(action);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.StartPresentationRoutedToEvt);
		EventBusLayout.$off("Action", this.StartPresentationAction);
	}
	closeMedical() {
		LoggingHelper.getInstance().LogEvent(
			LoggingConstants.MedicalQuestionClosed
		);
		this.showMedicalQuestions = false;
		this.$store.state.OpenedPopupName = "";
	}
	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}

	AddNewRateRow() {
		if (
			this.NoOfRateBookRows == undefined ||
			this.NoOfRateBookRows.length == 0
		) {
			this.NoOfRateBookRows = [];
			this.NoOfRateBookRows.push(this.NoOfRateBookRows.length);
		} else {
			var lastRowItem = this.NoOfRateBookRows[this.NoOfRateBookRows.length - 1];
			this.NoOfRateBookRows.push(lastRowItem + 2);
		}
		var rate1 = new RateBookEntity();
		var rate2 = new RateBookEntity();

		if (this.user != undefined) {
			var state = this.States.find((x: any) => {
				if (x.statename == this.user.Territory) return x;
			});
			if (state) {
				rate1.State = state.statecode;
				rate2.State = state.statecode;
			}
		}

		if (this.selectedState != undefined && this.selectedState != "") {
			rate1.State = this.selectedState.StateCode;
			rate2.State = this.selectedState.StateCode;
		}

		if (this.ListOfRatePlan == undefined) this.ListOfRatePlan = [];
		this.ListOfRatePlan.push(rate1);
		this.ListOfRatePlan.push(rate2);
		var svview = document.getElementsByClassName("formscroll");
		if (
			svview != undefined &&
			svview.length > 0 &&
			svview[0].children.length > 0
		) {
			{
				setTimeout(() => {
					svview[0].children[svview[0].children.length - 1].scrollIntoView(
						true
					);
				}, 50);
			}
		}
	}
	stateChanged() {
		//var state = this.States.find((x: any) => x.Name == this.selectedState);
		this.PresentationTypes = this.allPresentationTypes.filter(
			(x: any) =>
				x.ExcludeCountry == undefined ||
				x.ExcludeCountry != this.selectedState.CountryId
		);
		this.IsMexicanStateSelected =
			this.selectedState == "NM" || this.selectedState?.StateCode == "NM";

		// if (
		// 	this.MemberLookupType != undefined &&
		// 	this.MemberLookupType != MemberLookupType.ProLead
		// ) {
		// 	this.PresentationTypes.splice(
		// 		this.PresentationTypes.indexOf(
		// 			this.PresentationTypes.find(
		// 				(i: { Name: any }) => i.Name == "PRO LEAD"
		// 			)
		// 		),
		// 		1
		// 	);
		// }

		if (
			this.SelectedPresentationType != undefined &&
			this.SelectedPresentationType != "" &&
			!this.PresentationTypes.includes(this.SelectedPresentationType)
		) {
			this.SelectedPresentationType = "";
			this.SubTypes = [];
			this.SelectedSubType = "";
			this.DisabledSubType = true;
		}

		if (
			this.apiUserDetail != undefined &&
			this.apiUserDetail.Licenses != undefined &&
			this.apiUserDetail.Licenses.length > 0
		) {
			var lic = undefined;
			if (this.selectedState != undefined && this.selectedState.StateId != 0) {
				var lic = this.apiUserDetail.Licenses.filter(
					(i: any) => i.StateId == this.selectedState.StateId
				);
			}

			if (lic == undefined || lic.length == 0) {
				lic = this.apiUserDetail.Licenses.filter(
					(i: { IsPrimary: boolean }) => i.IsPrimary == true
				);
			}

			if (lic != undefined && lic.length > 0) {
				this.license = lic[0].LicenseNumber + "-" + lic[0].StateCode;
				this.licenseId = lic[0].Id;
			} else {
				this.license = "";
			}
		} else {
			this.license = "";
		}
		EventBusLayout.$emit("LisenceChangeonState", this.license);
	}

	SaveReferral() {
		this.ResetReferrals = !this.ResetReferrals;
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		LoggingHelper.getInstance().LogEvent(LoggingConstants.SponsorShipPopupSave);
	}

	RoutedTo(route: String) {
		if (route == "/ratebook") {
			this.showRateBook = true;
			LoggingHelper.getInstance().LogEvent(LoggingConstants.RatebookOpened);
			return;
		}

		if (route == "/ssprogram") {
			// this.$data.TempReferrals = JSON.parse(
			//   JSON.stringify(this.$data.Referrals)
			// );

			//var giftedAmount = this.$data.TempReferrals.ReferralsList.length * 2000;
			//this.GiftAmount = ConvertIntoAmountFormat(giftedAmount.toFixed());
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";

			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}

		// if (route == "nocostbenefits") {
		//   this.$router.push({
		//     path: route.toString(),
		//     params: {

		//     },
		//   });
		//   return;
		// }

		this.SaveStateCookie();

		this.$router.push({
			path: route.toString(),
		});
	}

	SaveStateCookie() {
		var date = new Date();
		date.setDate(date.getDate() + 21);

		SetCookie("PreviouslySelectedState", JSON.stringify(this.selectedState), 1);
	}

	showVideoPlayer() {
		this.showVideo = true;
	}

	Action(actionName: String) {
		if (actionName == "ratebook") {
			this.showRateBook = true;
			this.$store.state.OpenedPopupName = "ratebook";
			this.NoOfRateBookRows = [];
			this.ListOfRatePlan = [];
			this.AddNewRateRow();
			LoggingHelper.getInstance().LogEvent(LoggingConstants.RatebookOpened);
			return;
		}
		if (actionName == "materials") {
			this.$router.push({
				name: "No Cost Benefits",
				params: {
					showallMaterials: "true",
					ExcludeFromPresentationCycle: "true",
				},
			});
			return;
		}

		if (actionName == "medical") {
			LoggingHelper.getInstance().LogEvent(
				LoggingConstants.MedicalQuestionOpened
			);
			this.showMedicalQuestions = true;
			this.$store.state.OpenedPopupName = "medical";
			return;
		}

		if (actionName == "underwriting") {
			this.showUW = true;
			this.$store.state.OpenedPopupName = "underwriting";
			LoggingHelper.getInstance().LogEvent(LoggingConstants.UWPopupOpened);
			return;
		}
	}

	changeGroup(type: string) {
		if (type == "SGNumber" && typeof this.SGNumber != "string") {
			this.Group = this.SGNumber;
		} else if (typeof this.Group != "string") {
			this.SGNumber = this.Group;
		}
	}

	resetFields() {
		if (this.user != undefined) {
			this.selectedState = this.user.Territory;
		} else {
			var stateFromCookie = GetCookie("PreviouslySelectedState");
			if (stateFromCookie != undefined && stateFromCookie != "") {
				this.selectedState = JSON.parse(stateFromCookie);
			}
		}
		this.SelectedPresentationType = "";
		this.SelectedSubType = "";
		this.SGNumber = null;
		this.Group = null;
		this.presentationcode = "";
		this.LeadId = "";
		this.SelectedLead = "";
		this.PreviousSelectedLead = "";
		this.ShowOnlyMemberLookup = true;
		this.LeadItems = [];
	}

	LanguageChange(evt: any) {
		this.$i18n.locale = evt.flag;
	}

	LoadProfileLanguage() {
		let CurrentUser = localStorage.getItem("user");
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			this.apiUserDetail = userObj.UserDetail;
			if (
				this.Languages != undefined &&
				this.Languages.length > 0 &&
				this.apiUserDetail.LanguageId != 0
			) {
				var languageSelect = this.Languages.filter((x: any) => {
					if (x.id == this.apiUserDetail.LanguageId) return x;
				});

				if (languageSelect != null && languageSelect.length > 0) {
					this.SelectedLanguage = languageSelect[0];
					this.LanguageChange(this.SelectedLanguage);
				}
			}
		}
	}

	async StartPresentation() {
		if (!this.$data.valid) {
			this.$refs.spForm.validate();
			this.$store.state.messageText = this.$t(
				"StartPresentation.RequiredError"
			);
			this.$store.state.showMessage = true;
			return;
		}

		if (
			this.SelectedPresentationType == undefined ||
			this.SelectedPresentationType == ""
		) {
			this.$store.state.messageText = this.$t(
				"StartPresentation.PresentationTypeError"
			);
			this.$store.state.showMessage = true;
			return;
		}

		if (
			!this.DisabledSubType &&
			(this.SelectedSubType == undefined || this.SelectedSubType == "")
		) {
			this.$store.state.messageText = this.$t(
				"StartPresentation.PresentationSubTypeError"
			);
			this.$store.state.showMessage = true;
			return;
		}

		if (
			!this.DisabledSubType &&
			(this.SelectedSubType == undefined || this.SelectedSubType == "")
		) {
			this.$store.state.messageText = this.$t(
				"StartPresentation.PresentationSubTypeError"
			);
			this.$store.state.showMessage = true;
			return;
		}

		if (this.LeadSelected == false) {
			this.$store.state.messageText = this.$t(
				"StartPresentation.MemberLookupError"
			);
			this.$store.state.showMessage = true;
			return;
		}

		Presentation.deleteAll();
		PrimaryPerson.deleteAll();
		Spouse.deleteAll();

		// If Presentation is to be cloned
		if (this.SelectedPresentationType.ShowPresentationCode) {
			this.clonePresentation(this.presToClone);
			return;
		}

		this.SaveStateCookie();
		let tmpPresentation = new Presentation();
		tmpPresentation.StartTime = new Date().toLocaleString();
		tmpPresentation.CurrentStepCompleted = 0;
		tmpPresentation.PresentationTypeId = this.SelectedPresentationType.Id;
		tmpPresentation.PresentationTypeString = this.SelectedPresentationType.Name;
		tmpPresentation.SubType = this.SelectedSubType;
		tmpPresentation.PremiumTypeId =
			this.SelectedPresentationType.DefaultPremiumTypeId;
		if (this.SubTypes != undefined && this.SubTypes.length > 0) {
			tmpPresentation.PresentationSubTypeId = this.SubTypes.find(
				(x: any) => x.Name == this.SelectedSubType
			).Id;
		}
		tmpPresentation.LeadId = this.LeadId;
		tmpPresentation.MemberLookupTypeId = this.MemberLookupType;

		tmpPresentation.BenefitsSummaryCalculationVersion =
			await GetSyncVersionByType(IndexDBConstants.RatesListKey);
		tmpPresentation.ProductListVersion = await GetSyncVersionByType(
			IndexDBConstants.RatesListKey
		);
		tmpPresentation.RateListVersion = await GetSyncVersionByType(
			IndexDBConstants.RatesListKey
		);

		if (typeof this.SGNumber == "string") {
			tmpPresentation.SGNumber = this.SGNumber;
		} else {
			tmpPresentation.SGNumber = this.SGNumber?.GroupCode;
		}
		this.$store.state.SGNumber =
			tmpPresentation.SGNumber != null && tmpPresentation.SGNumber != undefined
				? tmpPresentation.SGNumber
				: "";

		if (typeof this.Group == "string") {
			tmpPresentation.PresentedGroup = this.Group;
		} else {
			tmpPresentation.PresentedGroup = this.Group?.GroupName;
			tmpPresentation.PresentedGroupId = this.Group?.Id;
			tmpPresentation.BackupPresentedGroupId = this.Group?.Id;
		}
		this.$store.state.PresentedGroup =
			tmpPresentation.PresentedGroup != null &&
			tmpPresentation.PresentedGroup != undefined
				? tmpPresentation.PresentedGroup
				: "";

		if (typeof this.selectedState == "string") {
			var selstates = this.States.filter(
				(x: any) => x.Name == this.selectedState
			);
			if (selstates != undefined && selstates.length > 0) {
				tmpPresentation.State = selstates[0].Name;
				tmpPresentation.CountryId = selstates[0].CountryId;
				tmpPresentation.StateId = selstates[0].StateId;
			}
		} else {
			tmpPresentation.State = this.selectedState.Name;
			tmpPresentation.CountryId = this.selectedState.CountryId;
			tmpPresentation.StateId = this.selectedState.StateId;
		}

		tmpPresentation.Language = this.SelectedLanguage;

		if (typeof this.SelectedLanguage == "string") {
			var selLanguage = this.Languages.filter(
				(x: any) => x.text == this.SelectedLanguage
			);
			if (selLanguage != undefined && selLanguage.length > 0) {
				tmpPresentation.LanguageId = selLanguage[0].Id;
			}
			tmpPresentation.Language = this.SelectedLanguage;
		} else {
			tmpPresentation.LanguageId = this.SelectedLanguage.id;
			tmpPresentation.Language = this.SelectedLanguage.text;
			tmpPresentation.LanguageFlag = this.SelectedLanguage.flag;
		}

		var user = User.query().last();
		tmpPresentation.User_Id = user?.$id;
		var spouse: any = {
			FirstName: "",
		};
		if (this.isPrePlan && !this.SelectedPresentationType.ShowPresentationCode) {
			spouse.FirstName = this.prePlanData.Spouse.FirstName;
			spouse.Age = this.prePlanData.Spouse.Age;
			spouse.AgePerDOB = this.prePlanData.Spouse.AgePerDOB;
			spouse.Premium = this.prePlanData.Spouse.Premium;
			spouse.Wage = this.prePlanData.Spouse.Wage;
			spouse.AnnualIncome = this.prePlanData.Spouse.AnnualIncome;
			spouse.Gender = this.prePlanData.Spouse.Gender;
			spouse.AnyTobaccoUse = this.prePlanData.Spouse.AnyTobaccoUse;
			spouse.DollarADayAmount = this.prePlanData.Spouse.DollarADayAmount;
		}

		if (
			spouse != undefined &&
			this.MemberLookupType != undefined &&
			this.MemberLookupType == MemberLookupType.Demo
		) {
			spouse.FirstName = "MARY";
			spouse.LastName = "SMITH";
		}

		Spouse.insert({
			data: spouse,
		});

		var spouseId = Spouse.query().last()?.$id;
		spouse.Id = spouseId;

		var person: any = { FirstName: "", Spouse_Id: spouseId };
		if (this.isPrePlan && !this.SelectedPresentationType.ShowPresentationCode) {
			person.FirstName = this.prePlanData.Person.FirstName;
			person.Age = this.prePlanData.Person.Age;
			spouse.AgePerDOB = this.prePlanData.Spouse.AgePerDOB;
			person.Premium = this.prePlanData.Person.Premium;
			person.Wage = this.prePlanData.Person.Wage;
			person.AnnualIncome = this.prePlanData.Person.AnnualIncome;
			person.Gender = this.prePlanData.Person.Gender;
			person.AnyTobaccoUse = this.prePlanData.Person.AnyTobaccoUse;
			person.HasChildrenUnder18 = this.prePlanData.Person.HasChildrenUnder18;
			person.ChildrenString = this.prePlanData.Person.ChildrenString;
			person.DollarADayAmount = this.prePlanData.Person.DollarADayAmount;
		}

		if (
			person != undefined &&
			this.MemberLookupType != undefined &&
			this.MemberLookupType == MemberLookupType.Demo
		) {
			person.FirstName = "JOHN";
			person.LastName = "SMITH";
		}
		PrimaryPerson.insert({
			data: person,
		});

		Report.insert({
			data: {
				FirstName: "",
			},
		});

		var personId = PrimaryPerson.query().last()?.$id;
		person.Id = personId;
		tmpPresentation.Person_Id = personId;
		tmpPresentation.Report_Id = Report.query().last()?.$id;
		tmpPresentation.LanguageFlag = this.$i18n.locale;

		if (this.MemberLookupType != undefined) {
			FillPresentationByMemberType(tmpPresentation, this.MemberLookupType);
		}
		//tmpPresentation.MedicalQuestionsNA = JSON.stringify(medicalQuestionList);
		if (
			this.isPrePlan &&
			!this.SelectedPresentationType.ShowPresentationCode &&
			tmpPresentation.PresentationSubTypeId !=
				PresentationSubType.ServiceMemberBenefitsLeadAlpha &&
			tmpPresentation.PresentationSubTypeId !=
				PresentationSubType.SMBSponsoredVeteranLeadAlpha &&
			tmpPresentation.PresentationSubTypeId !=
				PresentationSubType.SMBVeteranFamilyLeadAlpha &&
			tmpPresentation.PresentationSubTypeId != PresentationSubType.Civilian &&
			tmpPresentation.PresentationSubTypeId !=
				PresentationSubType.ServiceMemberBenefitNotification
		) {
			tmpPresentation.PremiumTypeId =
				this.prePlanData.Presentation.PremiumTypeId;
			tmpPresentation.NeedAmount = this.prePlanData.Presentation.NeedAmount;
			tmpPresentation.Protections = this.prePlanData.Protections;
			tmpPresentation.PlanOptions = this.prePlanData.Plans;
			tmpPresentation.CurrentPlan = this.prePlanData.Presentation.CurrentPlan;
			tmpPresentation.WageType = this.prePlanData.Presentation.WageType;
			tmpPresentation.CombinedPremiumAmount = this.prePlanData.Person.Premium;
			if (this.prePlanData.Person.isSpouseForDADExistsPreviously)
				tmpPresentation.CombinedPremiumAmount +=
					this.prePlanData.Person.Spouse.Premium;
		}
		tmpPresentation.LicenseNumber = this.license;
		tmpPresentation.AgentCode = GetAgentCode();
		tmpPresentation.LicenseId = this.licenseId;

		if (this.LeadId == undefined || this.LeadId == "") {
			Presentation.insert({ data: tmpPresentation });
			let saveData = new GenericKeyPair(
				"CurrentPresentation",
				Presentation.query().withAllRecursive().last()
			);
			setPersistedState(saveData);
			this.$router.push("/nocostbenefits");

			LoggingHelper.getInstance().LogEvents([
				LoggingConstants.PresentationStated,
				LoggingConstants.NavigateTo + "No cost",
			]);
		} else {
			this.FetchLeadDetail(tmpPresentation, person, spouse);
		}
	}

	clonePresentation(presTobeCloned: any) {
		if (!presTobeCloned) return;
		let tmpPresentation = new Presentation();
		tmpPresentation.StartTime = new Date().toLocaleString();
		tmpPresentation.CurrentStepCompleted = 0;
		tmpPresentation.PresentationTypeId = presTobeCloned.PresentationTypeId;
		tmpPresentation.SubType = this.SelectedSubType;
		if (typeof this.SGNumber == "string") {
			tmpPresentation.SGNumber = this.SGNumber;
		} else {
			tmpPresentation.SGNumber = this.SGNumber?.groupcode;
			tmpPresentation.PresentedGroupId = this.SGNumber?.Id;
		}

		if (typeof this.Group == "string") {
			tmpPresentation.PresentedGroup = this.Group;
		} else {
			tmpPresentation.PresentedGroup = this.Group?.groupname;
			tmpPresentation.PresentedGroupId = this.Group?.Id;
		}

		tmpPresentation.State = this.selectedState;
		tmpPresentation.Language = this.SelectedLanguage;

		tmpPresentation.State = presTobeCloned.State;
		tmpPresentation.CountryId = presTobeCloned.CountryId;
		tmpPresentation.StateId = presTobeCloned.StateId;

		tmpPresentation.LanguageId = presTobeCloned.LanguageId;
		tmpPresentation.Language = presTobeCloned.Language;
		tmpPresentation.LanguageFlag = presTobeCloned.LanguageFlag;
		tmpPresentation.WageType = WageType[presTobeCloned.WageType];
		var user = User.query().last();
		tmpPresentation.User_Id = user?.$id;

		let spouse = new Spouse();
		let person = new PrimaryPerson();
		let planSum = new PlanSummary();
		person.FirstName = "";
		spouse.FirstName = "";
		presTobeCloned.PresentedTo.Id = person.Id;
		if (presTobeCloned.PresentedTo.Spouse) {
			spouse.FirstName = presTobeCloned.PresentedTo.Spouse.FirstName;
			spouse.LastName = presTobeCloned.PresentedTo.Spouse.LastName;
			spouse.DateOfBirth = presTobeCloned.PresentedTo.Spouse.DateOfBirth;
			spouse.Occupation = presTobeCloned.PresentedTo.Spouse.Occupation;
			spouse.Gender = presTobeCloned.PresentedTo.Spouse.Gender;
			spouse.AgePerDOB = presTobeCloned.PresentedTo.Spouse.AgePerDOB;
			spouse.InsuranceThroughWorkWholeLife =
				presTobeCloned.PresentedTo.Spouse.InsuranceThroughWorkWholeLife;
			spouse.InsuranceThroughWorkTermLife =
				presTobeCloned.PresentedTo.Spouse.InsuranceThroughWorkTermLife;
			spouse.InsuranceOutsideWorkWholeLife =
				presTobeCloned.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife;
			spouse.InsuranceOutsideWorkTermLife =
				presTobeCloned.PresentedTo.Spouse.InsuranceOutsideWorkTermLife;
			spouse.Premium = presTobeCloned.PresentedTo.Spouse.Premium;
			spouse.Wage = presTobeCloned.PresentedTo.Spouse.Wage;
			spouse.AnyTobaccoUse = presTobeCloned.PresentedTo.Spouse.AnyTobaccoUse;
			spouse.Sponsership = presTobeCloned.PresentedTo.Spouse.Sponsership;
			spouse.AnnualIncome = presTobeCloned.PresentedTo.Spouse.AnnualIncome;
			spouse.DollarADayAmount =
				presTobeCloned.PresentedTo.Spouse.DollarADayAmount;
			spouse.MonthlyPremium = presTobeCloned.PresentedTo.Spouse.MonthlyPremium;
		} else {
			spouse.Sponsership = "";
		}
		//spouse = JSON.parse(JSON.stringify(presTobeCloned.PresentedTo.Spouse));

		spouse.Plans = {};
		spouse.PlanSummary_Id = null;
		if (presTobeCloned.PresentedTo.Spouse) {
			if (presTobeCloned.PresentedTo.Spouse.PlanSummary) {
				planSum.EmergencyRoomBenfits =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.EmergencyRoomBenfits;
				planSum.DailyHospitalBenfits =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.DailyHospitalBenfits;
				planSum.IntensiveCareBenefits =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.IntensiveCareBenefits;
				planSum.CauseOfDeathAmt =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.CauseOfDeathAmt;
				planSum.AccidentAmt =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.AccidentAmt;
				planSum.AutoAccidentAmt =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.AutoAccidentAmt;
				planSum.CommonCarrier =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.CommonCarrier;
				planSum.MonthlyIncome =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.MonthlyIncome;
				planSum.TotalMonths =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.TotalMonths;
				planSum.hasPreProcedureCancerPlan =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.hasPreProcedureCancerPlan;
				planSum.hasLumpSumCancerPlan =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.hasLumpSumCancerPlan;
				planSum.hasRCPlan =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.hasRCPlan;
				planSum.BeneficiaryAmount =
					presTobeCloned.PresentedTo.Spouse.PlanSummary.BeneficiaryAmount;
				PlanSummary.insert({
					data: planSum,
				});
				spouse.PlanSummary_Id = PlanSummary.query().last()?.$id;
			}
		}
		Spouse.insert({
			data: spouse,
		});
		if (presTobeCloned && presTobeCloned.PresentedTo.Spouse.Plans) {
			presTobeCloned.PresentedTo.Spouse.Plans.forEach((elm: any) => {
				let plan = new PlanEntity();
				plan.PlanId = elm.$id;
				plan.IsPlanEnabled = elm.IsPlanEnabled;
				plan.PlanName = elm.PlanName;
				plan.Coverage = elm.Coverage;
				plan.MonthlyCharge = elm.MonthlyCharge;
				plan.DailyCharge = elm.DailyCharge;
				plan.PlanCode = elm.PlanCode;
				plan.SelectedOption = elm.SelectedOption;
				PlanEntity.insert({ data: plan });

				SpousePlan.insert({
					data: {
						spouseId: Spouse.query().last()?.Id,
						planId: PlanEntity.query().last()?.$id,
					},
				});
			});
		}
		planSum = new PlanSummary();

		presTobeCloned.PresentedTo.Id = person.Id;

		//person = JSON.parse(JSON.stringify(presTobeCloned.PresentedTo));
		if (presTobeCloned.PresentedTo) {
			person.FirstName = presTobeCloned.PresentedTo.FirstName;
			person.LastName = presTobeCloned.PresentedTo.LastName;
			person.DateOfBirth = presTobeCloned.PresentedTo.DateOfBirth;
			person.Occupation = presTobeCloned.PresentedTo.Occupation;
			person.ChildrenString = presTobeCloned.PresentedTo.ChildrenString;
			person.Gender = presTobeCloned.PresentedTo.Gender;
			person.AgePerDOB = presTobeCloned.PresentedTo.AgePerDOB;
			person.Checking = presTobeCloned.PresentedTo.Checking;
			person.Saving = presTobeCloned.PresentedTo.Saving;
			person.InsuranceThroughWorkWholeLife =
				presTobeCloned.PresentedTo.InsuranceThroughWorkWholeLife;
			person.InsuranceThroughWorkTermLife =
				presTobeCloned.PresentedTo.InsuranceThroughWorkTermLife;
			person.InsuranceOutsideWorkWholeLife =
				presTobeCloned.PresentedTo.InsuranceOutsideWorkWholeLife;
			person.InsuranceOutsideWorkTermLife =
				presTobeCloned.PresentedTo.InsuranceOutsideWorkTermLife;
			person.Premium = presTobeCloned.PresentedTo.Premium;
			person.PremiumApproach = presTobeCloned.PresentedTo.PremiumApproach;
			person.Email = presTobeCloned.PresentedTo.Email;
			person.Phone = presTobeCloned.PresentedTo.Phone;
			person.BeneficiaryName = presTobeCloned.PresentedTo.BeneficiaryName;
			person.Zip = presTobeCloned.PresentedTo.Zip;
			person.HasChildrenUnder18 = presTobeCloned.PresentedTo.HasChildrenUnder18;
			person.RentHome = presTobeCloned.PresentedTo.OwnHome;
			person.OwnHome = presTobeCloned.PresentedTo.RentHome;
			person.MonthlyRent = presTobeCloned.PresentedTo.MonthlyRent;
			person.MortgageBalance = presTobeCloned.PresentedTo.MortgageBalance;
			person.MortgageInterestRate =
				presTobeCloned.PresentedTo.MortgageInterestRate;
			person.MortgageYearsRemaining =
				presTobeCloned.PresentedTo.MortgageYearsRemaining;
			person.HomeInsuranceInCaseOfDeath =
				presTobeCloned.PresentedTo.HomeInsuranceInCaseOfDeath;
			person.HomeInsuranceInCaseOfDeathAmount =
				presTobeCloned.PresentedTo.HomeInsuranceInCaseOfDeathAmount;
			person.ProvidedChildCollegeEducation =
				presTobeCloned.PresentedTo.ProvidedChildCollegeEducation;
			person.ProvidedChildCollegeEducationAmount =
				presTobeCloned.PresentedTo.ProvidedChildCollegeEducationAmount;
			person.Retired = presTobeCloned.PresentedTo.Retired;
			person.Income = presTobeCloned.PresentedTo.Income;
			person.PremiumApproachId = presTobeCloned.PresentedTo.PremiumApproachId;
			person.MortgageAmount = presTobeCloned.PresentedTo.MortgageAmount;
			person.AnyTobaccoUse = presTobeCloned.PresentedTo.AnyTobaccoUse;
			person.Sponsership = presTobeCloned.PresentedTo.Sponsership;
			person.Wage = presTobeCloned.PresentedTo.Wage;
			person.Plans = {};
			person.AnnualIncome = presTobeCloned.PresentedTo.AnnualIncome;
			person.MonthlyPremium = presTobeCloned.PresentedTo.MonthlyPremium;
			person.AnnualExpense = presTobeCloned.PresentedTo.AnnualExpense;
			person.DollarADayAmount = presTobeCloned.PresentedTo.DollarADayAmount;
			person.Spouse_Id = Spouse.query().last()?.Id;
		}

		person.PlanSummary_Id = null;
		if (presTobeCloned.PresentedTo.PlanSummary) {
			planSum.EmergencyRoomBenfits =
				presTobeCloned.PresentedTo.PlanSummary.EmergencyRoomBenfits;
			planSum.DailyHospitalBenfits =
				presTobeCloned.PresentedTo.PlanSummary.DailyHospitalBenfits;
			planSum.IntensiveCareBenefits =
				presTobeCloned.PresentedTo.PlanSummary.IntensiveCareBenefits;
			planSum.CauseOfDeathAmt =
				presTobeCloned.PresentedTo.PlanSummary.CauseOfDeathAmt;
			planSum.AccidentAmt = presTobeCloned.PresentedTo.PlanSummary.AccidentAmt;
			planSum.AutoAccidentAmt =
				presTobeCloned.PresentedTo.PlanSummary.AutoAccidentAmt;
			planSum.CommonCarrier =
				presTobeCloned.PresentedTo.PlanSummary.CommonCarrier;
			planSum.MonthlyIncome =
				presTobeCloned.PresentedTo.PlanSummary.MonthlyIncome;
			planSum.TotalMonths = presTobeCloned.PresentedTo.PlanSummary.TotalMonths;
			planSum.hasPreProcedureCancerPlan =
				presTobeCloned.PresentedTo.PlanSummary.hasPreProcedureCancerPlan;
			planSum.hasLumpSumCancerPlan =
				presTobeCloned.PresentedTo.PlanSummary.hasLumpSumCancerPlan;
			planSum.hasRCPlan = presTobeCloned.PresentedTo.PlanSummary.hasRCPlan;
			planSum.BeneficiaryAmount =
				presTobeCloned.PresentedTo.PlanSummary.BeneficiaryAmount;
			PlanSummary.insert({
				data: planSum,
			});
			person.PlanSummary_Id = PlanSummary.query().last()?.$id;
		}

		PrimaryPerson.insert({
			data: person,
		});

		if (presTobeCloned.PresentedTo.Plans) {
			presTobeCloned.PresentedTo.Plans.forEach((elm: any) => {
				let plan = new PlanEntity();
				plan.PlanId = elm.$id;
				plan.IsPlanEnabled = elm.IsPlanEnabled;
				plan.PlanName = elm.PlanName;
				plan.Coverage = elm.Coverage;
				plan.MonthlyCharge = elm.MonthlyCharge;
				plan.DailyCharge = elm.DailyCharge;
				plan.PlanCode = elm.PlanCode;
				plan.SelectedOption = elm.SelectedOption;
				PlanEntity.insert({ data: plan });
				PersonPlan.insert({
					data: {
						personId: PrimaryPerson.query().last()?.Id,
						planId: PlanEntity.query().last()?.$id,
					},
				});
			});
		}

		if (presTobeCloned.PresentedTo.Childrens) {
			presTobeCloned.PresentedTo.Childrens.forEach((elm: any) => {
				let child = new Children();
				child.FirstName = elm.FirstName;
				child.LastName = elm.LastName;
				child.DateOfBirth = elm.DateOfBirth;
				child.Gender = elm.Gender;
				child.Parent_Id = person.Id;
				Children.insert({ data: child });
			});
		}
		Report.insert({
			data: {
				FirstName: "",
			},
		});
		tmpPresentation.Person_Id = PrimaryPerson.query().last()?.$id;
		tmpPresentation.Report_Id = Report.query().last()?.$id;
		//tmpPresentation.MedicalQuestionsNA = JSON.stringify(medicalQuestionList);

		presTobeCloned.Id = tmpPresentation.Id;
		tmpPresentation.PresentationTypeId = presTobeCloned.PresentationTypeId;
		tmpPresentation.PresentedGroup = presTobeCloned.PresentedGroup;
		tmpPresentation.CombinedPremiumAmount =
			presTobeCloned.CombinedPremiumAmount;
		tmpPresentation.PremiumTypeId = presTobeCloned.PremiumTypeId;
		tmpPresentation.SubType = presTobeCloned.SubType;
		tmpPresentation.WhatHappened = presTobeCloned.WhatHappened;
		tmpPresentation.NANotes = presTobeCloned.NANotes;
		tmpPresentation.FPNotes = presTobeCloned.FPNotes;
		tmpPresentation.FieldTrainee = presTobeCloned.FieldTrainee;
		tmpPresentation.FieldTraining = presTobeCloned.FieldTraining;
		tmpPresentation.SGNumber = presTobeCloned.SGNumber;
		tmpPresentation.Language = presTobeCloned.Language;
		tmpPresentation.LanguageFlag = presTobeCloned.LanguageFlag;
		tmpPresentation.State = presTobeCloned.State;
		//tmpPresentation.DollarADayAmount = presTobeCloned.DollarADayAmount;
		tmpPresentation.MedicalQuestionsNA = presTobeCloned.MedicalQuestionsNA;
		tmpPresentation.BSMedicalQuestions = presTobeCloned.BSMedicalQuestions;
		tmpPresentation.isSuperCombo = presTobeCloned.isSuperCombo;
		presTobeCloned.NeedAmount = presTobeCloned.NeedAmount.toString() + "%";
		tmpPresentation.NeedAmount = presTobeCloned.NeedAmount;
		tmpPresentation.PlanOptions = presTobeCloned.PlanOptions;
		tmpPresentation.CurrentPlan = presTobeCloned.CurrentPlan;
		tmpPresentation.Protections = presTobeCloned.Protections;
		tmpPresentation.Referrals = presTobeCloned.Referrals;
		tmpPresentation.AllSponserships = "";
		tmpPresentation.PremiumPlanEntity_Id = null;
		tmpPresentation.Plans = {};
		if (presTobeCloned.PremiumPlan) {
			var premiumPl = new PremiumPlanEntity();
			premiumPl.PremiumTypeId = presTobeCloned.PremiumPlan.PremiumTypeId;
			premiumPl.FinalDailyPremium =
				presTobeCloned.PremiumPlan.FinalDailyPremium;
			premiumPl.FinalMonthlyPremium =
				presTobeCloned.PremiumPlan.FinalMonthlyPremium;
			premiumPl.UsedDailyPremium = presTobeCloned.PremiumPlan.UsedDailyPremium;
			premiumPl.UsedMonthlyPremium =
				presTobeCloned.PremiumPlan.UsedMonthlyPremium;
			premiumPl.RemainingDailyPremium =
				presTobeCloned.PremiumPlan.RemainingDailyPremium;
			premiumPl.RemainingMonthlyPremium =
				presTobeCloned.PremiumPlan.RemainingMonthlyPremium;
			premiumPl.HPPremiumType = presTobeCloned.PremiumPlan.HPPremiumType;
			premiumPl.MonthlyPremiumType =
				presTobeCloned.PremiumPlan.MonthlyPremiumType;
			premiumPl.CustomHPPremium = presTobeCloned.PremiumPlan.CustomHPPremium;
			premiumPl.CustomMonthlyPremium =
				presTobeCloned.PremiumPlan.CustomMonthlyPremium;
			PremiumPlanEntity.insert({ data: premiumPl });
			tmpPresentation.PremiumPlanEntity_Id =
				PremiumPlanEntity.query().last()?.$id;
		}

		if (presTobeCloned.Plans) {
			presTobeCloned.Plans.forEach((elm: any) => {
				let plan = new PlanEntity();
				plan.PlanId = elm.$id;
				plan.IsPlanEnabled = elm.IsPlanEnabled;
				plan.PlanName = elm.PlanName;
				plan.Coverage = elm.Coverage;
				plan.MonthlyCharge = elm.MonthlyCharge;
				plan.DailyCharge = elm.DailyCharge;
				plan.PlanCode = elm.PlanCode;
				plan.SelectedOption = elm.SelectedOption;
				PlanEntity.insert({ data: plan });
				PresentationPlans.insert({
					data: {
						presentationId: tmpPresentation.Id,
						planId: PlanEntity.query().last()?.$id,
					},
				});
			});

			tmpPresentation.Report = null;
			tmpPresentation.CurrentStepCompleted = 0;
			tmpPresentation.StartTime = new Date().toLocaleString();
			tmpPresentation.User_Id = user?.$id;
			tmpPresentation.Person_Id = PrimaryPerson.query().last()?.$id;
			tmpPresentation.Report_Id = Report.query().last()?.$id;
			tmpPresentation.Code = "";
			tmpPresentation.ParentCode = this.presentationcode;
			tmpPresentation.isNeedAnalysisCompleted = false;
			tmpPresentation.isReportCardCompleted = false;
			tmpPresentation.isPlanGeneratorCompleted = false;
			tmpPresentation.isBenefitsSummaryCompleted = false;
			tmpPresentation.TimeOnNoCostBenefits = "";
			tmpPresentation.TimeOnNeedAnalysis = "";
			tmpPresentation.TimeOnPlanGenerator = "";
			tmpPresentation.TimeOnBenefitSummary = "";
			tmpPresentation.TimeOnPresentPlan = "";
			tmpPresentation.TimeOnReportCard = "";
			tmpPresentation.AllSponserships = presTobeCloned.AllSponserships;
			tmpPresentation.NoCostMaterialsTime = "";
		}
		Presentation.insert({ data: tmpPresentation });
		let saveData = new GenericKeyPair(
			"CurrentPresentation",
			Presentation.query().withAllRecursive().last()
		);
		setPersistedState(saveData);
		// saveData = new GenericKeyPair(
		//   "PresentationsList",
		//   JSON.stringify(Presentation.query().withAllRecursive().all())
		// );
		// setPersistedState(saveData);
		this.$router.push("/nocostbenefits");
	}

	GroupSGBlur(v: any, text: any) {
		var text = this.$refs.groupComboBox.lazySearch.toUpperCase();

		if (text != undefined && text != "") {
			var list = this.Groups.filter((x: any) => x.groupcode.includes(text));
			if (list != undefined && list.length == 1) {
				this.SGNumber = list[0];
				this.changeGroup("SGNumber");
			}
		}
		this.$refs.groupComboBox.isMenuActive = false;
	}

	getALP(pres: any) {}

	getAHP(pres: any) {}

	getProductionBreakDown(pres: any) {
		var PremiumAmountDesc = "";
		switch (pres.PremiumTypeId) {
			case 1:
				PremiumAmountDesc = "";
				if (pres.PremiumPlan) {
					if (pres.PremiumPlan.HPPremiumType == 1) {
						PremiumAmountDesc = PremiumAmountDesc.concat(
							this.$t("FinishPresentation.Combined").toString()
						);
					} else {
						PremiumAmountDesc = PremiumAmountDesc.concat(
							this.$t("FinishPresentation.Custom").toString()
						);
					}
				}
				PremiumAmountDesc = PremiumAmountDesc.concat(
					" $",
					ConvertIntoAmountFormatWithDecimal(
						pres.CombinedPremiumAmount.toFixed(2),
						true,
						true
					) + this.$t("FinishPresentation.PerWeek")
				);
				break;
			case 2:
				if (pres.PremiumPlan) {
					if (pres.PremiumPlan.MonthlyPremiumType == 1) {
						PremiumAmountDesc = PremiumAmountDesc.concat(
							this.$t("FinishPresentation.Combined").toString()
						);
					} else {
						PremiumAmountDesc = PremiumAmountDesc.concat(
							this.$t("FinishPresentation.Custom").toString()
						);
					}
				}
				PremiumAmountDesc = PremiumAmountDesc.concat(
					" $",
					ConvertIntoAmountFormatWithDecimal(
						pres.CombinedPremiumAmount.toFixed(2),
						true,
						true
					) + this.$t("FinishPresentation.PerMonth")
				);
				break;
			case 3:
				var dollarADayAmountCombined =
					pres.PresentedTo.DollarADayAmount +
					pres.PresentedTo.Spouse.DollarADayAmount;

				PremiumAmountDesc =
					this.$t("FinishPresentation.Combined").toString() +
					" $" +
					ConvertIntoAmountFormatWithDecimal(
						dollarADayAmountCombined.toFixed(2),
						true,
						true
					) +
					this.$t("FinishPresentation.PerDay");
				break;
			case 4:
				PremiumAmountDesc =
					pres.NeedAmount + this.$t("FinishPresentation.AnnualIncome");
				break;
			default:
				break;
		}
		return PremiumAmountDesc;
	}

	GroupNameBlur(v: any, text: any) {
		var text = this.$refs.groupNameComboBox.lazySearch.toUpperCase();
		if (text != undefined && text != "") {
			var list = this.Groups.filter((x: any) => x.groupname.includes(text));
			if (list != undefined && list.length == 1) {
				this.Group = list[0];
				this.changeGroup("Group");
			}
		}
		this.$refs.groupNameComboBox.isMenuActive = false;
	}

	SubTypeChange() {
		this.$data.SubtypeValid = true;
	}
	isNumberOnly(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			evt.preventDefault();
		} else {
			return true;
		}
	}
	closeRatebook() {
		this.showRateBook = false;
		this.$store.state.OpenedPopupName = "";
		LoggingHelper.getInstance().LogEvent(LoggingConstants.RatebookClosed);
	}

	private skip: number = 0;
	private TotalClientNameCount: number = 0;
	// private previous

	ClientNameChange(evt: any) {
		var text = evt;

		if (text != undefined && text.length <= 1) {
			this.LeadItems = [];
			this.$data.ImageLoading = false;
		}
		if (this.LeadSelected == true) {
			this.LeadSelected = false;
			return;
		}

		// this.LeadId = "";
		// var text = evt;
		// if (this.LeadSelected == true) {
		// 	this.LeadSelected = false;
		// 	return;
		// }
		// if (text != undefined && text.length > 1) {
		// 	this.skip = 0;
		// 	await this.FetchClientNames(text);
		// 	//if (text == this.SelectedLead)
		// }
		// //  else if (text != undefined && text.length == 1) {
		// //   this.UpdateLeadDataPrefill(text);
		// // }
		// else if (text != undefined && text.length <= 1) {
		// 	this.LeadItems = [];
		// 	this.$data.ImageLoading = false;
		// }
		// this.isClientNameFocus = true;
		// //}
	}

	async ClientNameChangeDebounce() {
		if (this.PreviousSelectedLead != this.SelectedLead) {
			this.LeadId = "";
			var text = this.SelectedLead;
			this.PreviousSelectedLead = this.SelectedLead;

			if (text != undefined && text.length > 1) {
				this.skip = 0;
				await this.FetchClientNames(text);
			} else if (text != undefined && text.length <= 1) {
				this.LeadItems = [];
				this.$data.ImageLoading = false;
			}
			this.isClientNameFocus = true;
		}
	}

	async FetchClientNames(text: string) {
		this.lastClientNameText = JSON.stringify(text.toLowerCase());
		this.$data.ImageLoading = true;
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair("filterText", text));
		params.push(new GenericKeyPair("take", 50));
		params.push(new GenericKeyPair("skip", this.skip));

		var res: any = await GetRequestWithAuthorization(
			ApiEndPointConstants.GetClientNamesEndPoint,
			params
		);

		if (this.lastClientNameText == JSON.stringify(text.toLowerCase())) {
			if (
				res != null &&
				res != undefined &&
				res.status != null &&
				res.status != undefined &&
				res.status != false &&
				res.Result != null &&
				res.Result != undefined
			) {
				this.LeadItems = res.Result.PagedRecords;
				this.TotalClientNameCount = res.Result.TotalRecords;
			}

			this.$data.ImageLoading = false;
			this.UpdateLeadDataPrefill(text);
		}
	}

	UpdateLeadDataPrefill(text: string) {
		if (this.LeadItems != undefined) {
			this.LeadItems.push(
				...this.PreMemberLookupData.filter((x: any) =>
					x.TypeName.toLowerCase().includes(text.toLowerCase())
				)
			);
		} else {
			this.LeadItems = this.PreMemberLookupData.filter((x: any) =>
				x.TypeName.toLowerCase().includes(text.toLowerCase())
			);
		}
	}

	async OnLeadSelected(item: any) {
		if (item != undefined) {
			this.LeadSelected = true;

			if (this.ShowOnlyMemberLookup == true) {
				setTimeout(() => {
					this.ShowOnlyMemberLookup = false;
					this.customEvent();
				}, 0);
			}
			this.isClientNameFocus = false;

			if (item.isMemberType == undefined || item.isMemberType == false) {
				this.LeadId = item.LeadId;
				this.SelectedLead =
					item.GroupCode + " - " + item.FirstName + " " + item.LastName;
				this.MemberLookupType = MemberLookupType.ByLead;
				if (item.GroupCode != undefined && item.GroupCode != "") {
					var selGroup = this.Groups.find(
						(x: any) => x.GroupCode == item.GroupCode
					);
					this.SGNumber = item.GroupCode;
					this.Group = item.GroupName;

					if (selGroup != undefined) this.Group = selGroup;

					if (
						selGroup != undefined &&
						selGroup.PresentationTypeId != null &&
						selGroup.PresentationTypeId != undefined &&
						selGroup.PresentationTypeId != ""
					) {
						this.SelectedPresentationType = this.PresentationTypes.find(
							(x: any) => x.Id == selGroup.PresentationTypeId
						);
						if (this.SelectedPresentationType != undefined) {
							this.changePresentationType(this.SelectedPresentationType);
							if (
								selGroup.PresentationTypeSubId != null &&
								selGroup.PresentationTypeSubId != undefined &&
								selGroup.PresentationTypeSubId != ""
							) {
								this.SelectedSubType = this.SubTypes.find(
									(x: any) => x.Id == selGroup.PresentationTypeSubId
								)?.Name;
								if (this.SelectedSubType != undefined) {
									this.SubTypeChange();
								}
							}
						}
					}
				}

				if (
					item.LanguageDescription != undefined &&
					item.LanguageDescription != ""
				) {
					var slLang = this.Languages.find(
						(x: any) => x.ailKeyword == item.LanguageDescription
					);
					if (slLang != undefined) {
						this.SelectedLanguage = slLang;
						this.LanguageChange(this.SelectedLanguage);
					}
				}

				if (item.State != null && item.State != undefined) {
					this.selectedState = this.States.find(
						(x: any) => x.StateCode == item.State
					);
					this.stateChanged();
				} else {
					this.selectedState = undefined;
				}
			} else {
				this.MemberLookupType = item.MemberLookUpType;
				this.SelectedLead = item.TypeName;
				await this.LoadDropDown();
				switch (this.MemberLookupType) {
					case MemberLookupType.Demo:
						this.SelectedPresentationType = this.allPresentationTypes.find(
							(x: any) => x.Id == PresentationType.Demo
						);
						if (this.SelectedPresentationType != undefined)
							this.changePresentationType(this.SelectedPresentationType);
						else {
							this.SelectedPresentationType = "";
							this.SubTypes = [];
						}
						break;
					case MemberLookupType.ProLead:
						this.SelectedPresentationType = this.allPresentationTypes.find(
							(x: any) => x.Name == "PRO LEAD"
						);
						if (this.SelectedPresentationType != undefined)
							this.changePresentationType(this.SelectedPresentationType);
						else {
							this.SelectedPresentationType = "";
							this.SubTypes = [];
						}
						break;
					case MemberLookupType.Other:
						this.SelectedPresentationType = "";
						this.SubTypes = [];
						break;
					case MemberLookupType.Training:
						break;
				}
			}
		}
	}

	ClientNameFocus() {
		setTimeout(() => {
			this.isClientNameFocus = true;
		}, 500);
	}

	ClientNameBlur() {
		this.$data.ImageLoading = false;
	}

	FetchLeadDetail(
		presentation: Presentation,
		person: PrimaryPerson,
		spouse: Spouse
	) {
		this.$data.loadingStart = true;
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair("leadId", this.LeadId));
		GetRequestWithAuthorization(
			ApiEndPointConstants.GetLeadDetailEndPoint,
			params
		).then((res) => {
			if (res != undefined) {
				this.$data.loadingStart = false;
				if (res.Result != undefined) {
					FillPresentationFromLead(presentation, person, spouse, res.Result);
					PrimaryPerson.update({ data: person, where: person.Id });
					Spouse.update({ data: spouse, where: spouse.Id });
					Presentation.insert({ data: presentation });
					let saveData = new GenericKeyPair(
						"CurrentPresentation",
						Presentation.query().withAllRecursive().last()
					);
					setPersistedState(saveData);
					this.$router.push("/nocostbenefits");

					LoggingHelper.getInstance().LogEvents([
						LoggingConstants.PresentationStated,
						LoggingConstants.NavigateTo + "No cost",
					]);
				}
			}
		});
	}

	OnClientScroll(vertical: any, horizontal: any, nativeEvent: any) {
		if (
			vertical.process == 1 &&
			(this.TotalClientNameCount == 0 ||
				this.TotalClientNameCount > this.LeadItems.length)
		) {
			this.$data.ImageLoading = true;
			this.skip = this.LeadItems.length;
			if (this.lastClientNameText != undefined) {
				var text = this.lastClientNameText.replaceAll('"', "");
				this.lastClientNameText = JSON.stringify(text.toLowerCase());
				this.$data.ImageLoading = true;
				var params: GenericKeyPair[] = [];
				params.push(new GenericKeyPair("filterText", text));
				params.push(new GenericKeyPair("take", 50));
				params.push(new GenericKeyPair("skip", this.skip));

				var res: any = GetRequestWithAuthorization(
					ApiEndPointConstants.GetClientNamesEndPoint,
					params
				).then((res: any) => {
					if (this.lastClientNameText == JSON.stringify(text.toLowerCase())) {
						this.LeadItems.push(...res.Result.PagedRecords);
						//this.TotalClientNameCount = res.Result.TotalRecords;
						this.$data.ImageLoading = false;
					}
				});
			}
		}
	}
}
