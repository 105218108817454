












































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
let placeholderuser = require("../../assets/placeholderuser.png");

@Component({
	components: {
		Cropper,
	},
})
export default class ImageCrop extends Vue {
	public img: string = "";
	private defaultButtonText: string = "Choose File";
	private selectedFile: any = null;
	private isSelecting: boolean = false;
	public CroppedImage: any = null;

	$refs!: {
		uploader: any;
	};
	change(imageObj: any) {
		// console.log(imageObj.coordinates.width + " " + imageObj.coordinates.height);
		if (
			imageObj.coordinates != undefined &&
			(imageObj.coordinates.width <= 1 || imageObj.coordinates.height <= 1)
		) {
		} else {
			this.CroppedImage = imageObj.canvas;
		}
	}
	changeFile(e: any) {
		if (
			e.target.files.length > 0 &&
			(e.target.files[0].type == "image/png" ||
				e.target.files[0].type == "image/jpeg" ||
				e.target.files[0].type == "image/jpg" ||
				e.target.files[0].type == "image/bmp")
		) {
			this.selectedFile = e.target.files[0];
			this.img = URL.createObjectURL(this.selectedFile);
		} else {
			this.$store.state.messageText = "Please select correct image format";
			this.$store.state.showMessage = true;
			this.$refs.uploader.value = "";
		}
	}

	get buttonText() {
		return this.selectedFile ? this.selectedFile.name : this.defaultButtonText;
	}

	created() {
		var dataURL = this.$store.state.ProfileImageString;

		if (dataURL != null && dataURL != undefined && dataURL != "")
			this.img = dataURL;
		else {
			//this.img = "img/userimg.b18ce05c.png";
			this.img = placeholderuser;
		}
	}
	onButtonClick() {
		this.isSelecting = true;
		window.addEventListener(
			"focus",
			() => {
				this.isSelecting = false;
			},
			{ once: true }
		);

		this.$refs.uploader.click();
	}
}
