

























































































































































































































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import ImageCrop from "../components/popups/ImageCrop.vue";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import LicenseStatePopup from "../components/popups/LicenseStates.vue";
import MenuComponent from "../components/menu.vue";
let placeholderuser = require("../assets/placeholderuser.png");

let lstLanguages = require("../assets/pre-fill-data/language-list.json");
import { namespace } from "vuex-class";
import { getPersistedState } from "../helpers/StateMapper";
import { PostRequestWithAuthorization } from "../helpers/APIHelper";
import GenericKeyPair from "../models/GenericKeyPair";
import { RemoveSpecialCharacterFromNumber } from "../helpers/UIHelper";
import { changeLocale } from "@/plugins/i18n";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import LoggingHelper from "../helpers/LoggingHelper";
import LoggingConstants from "../constants/LoggingConstants";
import { EventBusLayout } from "../bus/EventBusLayout";

const Auth = namespace("Auth");
@Component({
	components: {
		ShortUserDetailView,
		LicenseStatePopup,
		MenuComponent,
		ImageCrop,
	},
})
export default class ProfilePage extends Vue {
	@Auth.Action
	private signOut!: () => void;
	private User: any;
	private AllStates: any = [];
	private States: any = [];
	private Positions: any = [];
	private Languages = lstLanguages;
	private Countries: any = [];
	private Offices: any = [];
	private Unions: any = [];
	private showLicenseStatePopup: boolean = false;
	private confirmSaveDialog: boolean = false;
	private confirmRouteDialog: boolean = false;
	private showImageCropPopup: boolean = false;
	private LicenseStates: Array<any> = [];
	private TempLicenseStates: Array<any> = [];
	private TempLicenseStates2: Array<any> = [];
	private UserDB!: any;
	private license: string = "";
	private licenseState: string = "";
	private unionCode: string = "";
	private fullname: string = "";
	private imgSource: string = "";
	private routingTo!: string;
	private tempUser: any;
	private isValid: boolean = false;
	private licenseCustomized: boolean = false;
	$refs!: {
		profileImageCropBox: any;
		profileform: any;
	};

	data() {
		return {
			OfficeName: "",
			saveLoading: false,
			saveImageLoading: false,
			EmailRules: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return false;
					}
				},
			],
			PhoneRules: [(v: any) => (!!v && v.length > 13) || "Phone must be valid"],
			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => !!v.trim() || "Field is required",
			],
		};
	}

	private apiUserDetail: any = {};
	created() {
		this.LoadData();
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		EventBusLayout.$on("RoutedToEvt", this.ProfileRoutedToEvt);
		EventBusLayout.$on("Action", this.ProfileAction);
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}

	LoadData() {
		let CurrentUser = localStorage.getItem("user");
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			this.apiUserDetail = userObj.UserDetail;
			this.User = {
				FirstName: this.apiUserDetail.FirstName,
				LastName: this.apiUserDetail.LastName,
				UserName: userObj.UserName,
				UserId: this.apiUserDetail.UserId,
				AgentNo: this.apiUserDetail.AgentNumber,
				LicNo: "",
				LicState: "",
				PhoneNo: this.apiUserDetail.Phone,
				EmailAddress: this.apiUserDetail.Email,
				MmsServiceAllowed: this.apiUserDetail.MmsServiceAllowed,
				MessageCount: this.apiUserDetail.MessageCount,
				TrainingMessageCount: this.apiUserDetail.TrainingMessageCount,
				TimeZoneOffset: this.apiUserDetail.TimeZoneOffset,
			};

			if (
				this.Languages != undefined &&
				this.Languages.length > 0 &&
				this.apiUserDetail.LanguageId != 0
			) {
				var languageSelect = this.Languages.filter((x: any) => {
					if (x.id == this.apiUserDetail.LanguageId) return x;
				});

				if (languageSelect != null && languageSelect.length > 0) {
					this.User.Language = languageSelect[0];
				}
			}

			this.LoadDropdownData(this.apiUserDetail);

			this.fullname = this.User.FirstName + " " + this.User.LastName;
			this.unionCode = this.User.Union;
			this.license = this.User.LicNo;

			this.Positions.find((role: any) => {
				if (
					role.id ===
					(this.apiUserDetail.Roles != undefined &&
					this.apiUserDetail.Roles.length > 0
						? this.apiUserDetail.Roles[0].RoleId
						: 0)
				) {
					this.User.Position = role.position;
				}
			});
			this.LoadImage();
		}
		this.fullname = this.User.FirstName + " " + this.User.LastName;
		this.unionCode = this.User.Union;
		this.license = this.User.LicNo;
		this.licenseState = this.User.LicState;
		this.LoadImage();
		this.TempLicenseStates2 = JSON.parse(JSON.stringify(this.LicenseStates));
		this.tempUser = JSON.parse(JSON.stringify(this.User));

		this.CountryChange(this.User.Country);
	}

	CountryChange(vobj: any) {
		let selectedCountry = this.Countries.filter(
			(x: any) => x.CountryId == this.User.Country.CountryId
		);

		if (selectedCountry != undefined && selectedCountry.length > 0) {
			this.States = this.AllStates.filter(
				(x: any) => x.CountryId == selectedCountry[0].CountryId
			);
		}
	}

	LoadLicenses() {
		if (
			this.apiUserDetail.Licenses != null &&
			this.apiUserDetail.Licenses.length > 0
		) {
			this.User.LicNo = this.apiUserDetail.Licenses[0].LicenseNumber;
			var states = this.AllStates.filter((x: any) => {
				if (x.StateId == this.apiUserDetail.Licenses[0].StateId) return x;
			});
			if (states != null && states.length > 0) {
				this.User.LicState = states[0].StateCode;
			}
			this.LicenseStates = [];
			let indexI: number = 1;
			this.apiUserDetail.Licenses.forEach((element: any) => {
				var tempLicense: any = {};
				var states = this.AllStates.filter((y: any) => {
					if (y.StateId == element.StateId) return y;
				});
				if (states != null && states.length > 0) {
					tempLicense.State = states[0].StateCode;
				}
				tempLicense.Index = indexI;
				tempLicense.Id = element.Id;
				tempLicense.Primary = element.IsPrimary;
				tempLicense.Number = element.LicenseNumber;
				this.LicenseStates.push(tempLicense);
				indexI = indexI + 1;
			});
			this.TempLicenseStates2 = JSON.parse(JSON.stringify(this.LicenseStates));
			this.LoadPrimaryLicense();
		}
	}

	ProfileRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	ProfileAction(action: any) {
		this.Action(action);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.ProfileRoutedToEvt);
		EventBusLayout.$off("Action", this.ProfileAction);
	}

	LoadDropdownData(userObj: any) {
		var self = this;
		getPersistedState("CountryList").then((res) => (self.Countries = res));
		getPersistedState("StateList").then((res) => {
			self.AllStates = res;
			if (self.Countries != undefined && self.Countries.length > 0) {
				if (userObj.StateId != undefined && userObj.StateId != 0) {
					var state = self.AllStates.filter((x: any) => {
						if (x.StateId == userObj.StateId) return x;
					});
					if (state != undefined && state.length > 0) {
						var country = this.Countries.filter((x: any) => {
							if (x.CountryId == state[0].CountryId) return x;
						});
						if (country != null && country.length > 0) {
							self.User.Country = country[0];
							self.CountryChange(this.User.Country);
							self.User.Territory = state[0];
						}
					}
				}
			}
			this.LoadLicenses();
			this.tempUser = JSON.parse(JSON.stringify(this.User));
		});
		getPersistedState("UnionCodeList").then((res) => {
			self.Unions = res;
			if (userObj != undefined && userObj.UnionId != 0) {
				self.User.Union = self.Unions.filter(
					(x: any) => x.Key == userObj.UnionId
				)[0];
				self.unionCode = self.User.Union.Value;
			}
		});
		getPersistedState("OfficeList").then((res) => {
			self.Offices = res;
			var offices = self.Offices.filter((office: any) => {
				if (office.OfficeId == userObj.OfficeId) {
					return office;
				}
			});
			if (offices != null && offices.length > 0) {
				this.$data.OfficeName = offices[0].OfficeName;
			}
		});
		getPersistedState("PositionList").then((res) => (self.Positions = res));
	}

	SaveProfileAPI() {
		var self = this;
		var objUser: any = {};
		objUser.AgentNumber = this.User.AgentNo;
		objUser.Email = this.User.EmailAddress;
		objUser.FirstName = this.User.FirstName;
		objUser.MmsServiceAllowed = this.User.MmsServiceAllowed;
		objUser.MessageCount = this.User.MessageCount;
		objUser.TrainingMessageCount = this.User.TrainingMessageCount;
		objUser.TimeZoneOffset = this.User.TimeZoneOffset;

		objUser.LastName = this.User.LastName;
		objUser.OfficeId = this.apiUserDetail.OfficeId;
		objUser.Phone = RemoveSpecialCharacterFromNumber(this.User.PhoneNo);
		objUser.UserId = this.apiUserDetail.UserId;
		objUser.Id = this.apiUserDetail.Id;

		if (this.User.Language != undefined) {
			objUser.LanguageId = this.User.Language.id;
		}

		if (this.User.Territory != undefined) {
			objUser.StateId = this.User.Territory.StateId;
		}
		if (this.User.Union != undefined) {
			objUser.UnionId = this.User.Union.Key;
		}

		objUser.Licenses = [];
		if (!this.licenseCustomized) this.TempLicenseStates = this.LicenseStates;
		var tempList = JSON.parse(JSON.stringify(this.TempLicenseStates));
		var fillLicenses: any = [];
		if (tempList != null && tempList.length > 0) {
			tempList.forEach((x: any) => {
				if (
					x.Number != undefined &&
					x.Number != "" &&
					x.State != undefined &&
					x.State != ""
				) {
					var tempLicense: any = {};
					var states = this.AllStates.filter((y: any) => {
						if (y.StateCode == x.State) return y;
					});
					if (states != null && states.length > 0) {
						tempLicense.StateId = states[0].StateId;
					}
					tempLicense.Id = x.Id;
					tempLicense.IsPrimary = x.Primary;
					tempLicense.LicenseNumber = x.Number;
					fillLicenses.push(tempLicense);
				}
			});
		}

		objUser.Licenses = fillLicenses;

		this.$data.saveLoading = true;

		let parameters: GenericKeyPair[] = [];
		PostRequestWithAuthorization(
			"HpProUser/UpdateUserProfile",
			parameters,
			objUser
		)
			.then((res: any) => {
				this.$data.saveLoading = false;
				if (res != undefined && res.data != undefined && res.status == 200) {
					self.confirmSaveDialog = false;
					var reslt = res.data;
					var localExistStrge = localStorage.getItem("user");
					if (localExistStrge != undefined) {
						var existingUserItem = JSON.parse(localExistStrge);
						existingUserItem.UserDetail = reslt;
						localStorage.setItem("user", JSON.stringify(existingUserItem));
						this.apiUserDetail = reslt;
						this.tempUser = JSON.parse(JSON.stringify(this.User));
					}
					this.ChangeAppLanguage(reslt.LanguageId);
					self.$store.state.messageText = "Profile updated";
					self.$store.state.showMessage = true;
					self.$store.state.ProfileInfoUpdated = true;
				} else {
					self.$store.state.messageText = "Unable to save Profile";
					self.$store.state.showMessage = true;
				}
			})
			.catch((ex: any) => {
				this.$data.saveLoading = false;
			});
	}

	ChangeAppLanguage(languageId: any) {
		var languageFlag = "en";
		if (languageId != undefined && languageId != 0) {
			var selectedLanguage = this.Languages.filter((x: any) => {
				if (x.id == languageId) return x;
			});
			if (selectedLanguage != undefined && selectedLanguage.length > 0) {
				languageFlag = selectedLanguage[0].flag;
			}
		}
		localStorage.setItem("currentLanguageFlag", languageFlag);
		changeLocale(languageFlag);
	}

	changeUnion() {
		this.User = JSON.parse(JSON.stringify(this.User));
		this.unionCode = this.User.Union.Value;
	}

	confirmSave() {
		//if (this.isProfileInfoChanged())
		if (!this.isValid) {
			this.$refs.profileform.validate();
			return;
		}
		if (this.isValid) this.confirmSaveDialog = true;
	}

	isProfileInfoChanged(): boolean {
		if (
			this.User.FirstName.toLowerCase() != this.tempUser.FirstName.toLowerCase()
		) {
			return true;
		}

		if (
			this.User.LastName.toLowerCase() != this.tempUser.LastName.toLowerCase()
		) {
			return true;
		}

		if (this.User.LicNo.toLowerCase() != this.tempUser.LicNo.toLowerCase()) {
			return true;
		}

		if (
			this.User.LicState.toLowerCase() != this.tempUser.LicState.toLowerCase()
		) {
			return true;
		}

		if (this.LicenseStates?.length != this.TempLicenseStates2?.length) {
			return true;
		}

		if (
			this.User.EmailAddress.toLowerCase() !=
			this.tempUser.EmailAddress.toLowerCase()
		) {
			return true;
		}

		if (
			this.User.PhoneNo.replace(/[^\w\s]/gi, "")
				.replace(" ", "")
				.toLowerCase() !=
			this.tempUser.PhoneNo.replace(/[^\w\s]/gi, "")
				.replace(" ", "")
				.toLowerCase()
		) {
			return true;
		}

		if (
			this.User.Territory != undefined &&
			this.User.Territory.StateId != this.apiUserDetail.StateId
		) {
			return true;
		}

		if (
			this.User.Union != undefined &&
			this.User.Union.Key != this.apiUserDetail.UnionId
		) {
			return true;
		}

		if (
			this.User.Language != undefined &&
			this.User.Language.id != this.apiUserDetail.LanguageId
		) {
			return true;
		}

		return false;
	}

	@Watch("showLicenseStatePopup")
	OnShowLicenseStatePopup(e: boolean) {
		if (e == true) {
			this.licenseCustomized = true;
			this.TempLicenseStates = this.LicenseStates;
		}
	}
	saveLicenseStates() {
		var tempList = JSON.parse(JSON.stringify(this.TempLicenseStates));
		if (
			tempList.length == 0 ||
			tempList.filter((x: any) => x.Primary == true).length > 0
		) {
			this.showLicenseStatePopup = false;
			this.LicenseStates = tempList;
			this.TempLicenseStates2 = tempList;
			this.LoadPrimaryLicense();
		} else {
			this.$store.state.messageText =
				"Please mark any License State as Primary to save.";
			this.$store.state.showMessage = true;
		}
	}

	LoadPrimaryLicense() {
		if (this.LicenseStates != undefined && this.LicenseStates.length > 0) {
			var primaryLic = this.LicenseStates.find((x: any) => x.Primary == true);
			if (primaryLic != null && primaryLic != undefined) {
				this.User.LicNo = primaryLic.Number;
				this.User.LicState = primaryLic.State;
				this.license = primaryLic.Number;
				this.licenseState = primaryLic.State;
			} else {
				this.User.LicNo = "";
				this.User.LicState = "";
				this.license = "";
				this.licenseState = "";
			}
		} else {
			this.User.LicNo = "";
			this.User.LicState = "";
			this.license = "";
			this.licenseState = "";
		}
	}

	cancelLicenseStates() {
		this.showLicenseStatePopup = false;
	}

	Route(route: any) {
		if (route == "/") {
			this.signOut();
		}
		this.$router.push(route);
	}

	RoutedTo(route: string) {
		if (route == "/profile") {
			return;
		}
		this.routingTo = route;
		if (this.isProfileInfoChanged()) {
			this.confirmRouteDialog = true;
			return;
		}
		this.$router.push(route.toString());
	}
	Action(actionname: string) {
		this.routingTo = "/";

		if (actionname == "logOut") {
			if (this.isProfileInfoChanged()) {
				this.confirmRouteDialog = true;
				return;
			}
			this.signOut();
			this.$router.push("/");
		}
	}

	cropImage() {
		this.showImageCropPopup = true;
		var dataURL = this.$store.state.ProfileImageString;

		if (dataURL != null && dataURL != undefined)
			this.$refs.profileImageCropBox.img = dataURL;
		else {
			this.$refs.profileImageCropBox.img = placeholderuser;
		}
	}
	changeName() {
		this.fullname = this.User.FirstName + " " + this.User.LastName;
	}

	SaveProfileImage() {
		this.SubmitImage();
	}

	LoadImage() {
		var dataURL = this.$store.state.ProfileImageString;

		if (dataURL != null && dataURL != undefined && dataURL != "")
			this.imgSource = dataURL;
		//this.imgSource = this.$refs.profileImageCropBox.CroppedImage.toDataURL();
		else {
			this.imgSource = placeholderuser;
		}
	}
	get ProfilePicture() {
		var dataURL;
		if (this.$store.state.ProfileImage == true)
			dataURL = this.$store.state.ProfileImageString;

		if (dataURL != null && dataURL != undefined && dataURL != "")
			this.imgSource = dataURL;
		//this.imgSource = this.$refs.profileImageCropBox.CroppedImage.toDataURL();
		else {
			this.imgSource = placeholderuser;
		}
		return this.imgSource;
	}

	SubmitImage() {
		try {
			var self = this;
			var objUser: any = {};

			var localExistStrge = localStorage.getItem("user");
			if (localExistStrge != undefined) {
				var existingUserItem = JSON.parse(localExistStrge);
				var userDetail = existingUserItem.UserDetail;
				objUser.HpProUserId = userDetail.Id;
				objUser.FileName = "HPProfileImage_" + userDetail.Id + ".jpg";
			}

			var base64Image = this.$refs.profileImageCropBox.CroppedImage.toDataURL();
			objUser.ImageData = base64Image.replace(
				"image/png",
				"image/octet-stream"
			);

			var last2 = base64Image.slice(-2);
			var imageFileSize = 0;
			if (last2 == "") {
				imageFileSize = objUser.ImageData.length * (3 / 4) - 2;
			} else {
				imageFileSize = objUser.ImageData.length * (3 / 4) - 1;
			}
			if (imageFileSize > 4000000) {
				self.$store.state.messageText = "Image Size must not be more than 4 MB";
				self.$store.state.showMessage = true;
				return;
			}

			let parameters: GenericKeyPair[] = [];
			this.$data.saveImageLoading = true;
			LoggingHelper.getInstance()?.LogEvent(LoggingConstants.ProfileChanged);
			PostRequestWithAuthorization(
				ApiEndPointConstants.UploadImageEndpoint,
				parameters,
				objUser
			)
				.then((res: any) => {
					self.$data.saveImageLoading = false;
					this.showImageCropPopup = false;
					if (res != undefined && res.data != undefined && res.status == 200) {
						var reslt = res.data;
						this.$store.state.ProfileImageString =
							this.$refs.profileImageCropBox.CroppedImage.toDataURL();
						self.LoadImage();
						self.$store.state.messageText = "Profile Image updated";
						self.$store.state.showMessage = true;
					} else {
						self.$store.state.messageText = "Unable to save Profile Image";
						self.$store.state.showMessage = true;
					}
				})
				.catch((ex: any) => {
					self.$data.saveImageLoading = false;
				});
		} catch (ex: any) {}
	}
}
