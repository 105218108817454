

















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
let listofStates = require("../../assets/pre-fill-data/state-list.json");

@Component({})
export default class LicenseStateComponent extends Vue {
  private licState: any = {
    Number: "",
    State: "",
    Primary: false,
  };
  private allStates = listofStates;

  @Prop({ default: "Input Property" })
  lstLicenseStates!: Array<any>;

  get states() {
    let filtList = this.lstLicenseStates.map((itemY) => {
      return itemY.State;
    });
    var states = this.allStates.filter(
      (x: any) => !filtList.includes(x.statecode)
    );
    return states;
  }

  add() {
    var lic = {
      Index: this.lstLicenseStates.length + 1,
      Number: this.licState.Number.trim(),
      State: this.licState.State,
      Primary: this.licState.Primary,
    };

    this.lstLicenseStates.push(lic);
    if (lic.Primary) {
      this.changePrimary(lic.Index);
    }
    this.licState = {
      Number: "",
      State: "",
      Primary: false,
    };
  }
  remove(id: number) {
    this.lstLicenseStates.splice(id, 1);
  }

  changePrimary(id: number) {
    var objCurrent: any;
    this.lstLicenseStates.forEach(function (obj) {
      if (obj.Index === id) {
        obj.Primary = true;
        objCurrent = obj;
      } else {
        obj.Primary = false;
      }
    });
    if (this.lstLicenseStates.length > 1) {
      var b = this.lstLicenseStates[0];
      var index = this.lstLicenseStates.findIndex(
        (st: any) => st == objCurrent
      );
      this.lstLicenseStates[0] = this.lstLicenseStates[index];
      this.lstLicenseStates[index] = b;
    }
    this.lstLicenseStates = JSON.parse(JSON.stringify(this.lstLicenseStates));
  }
}
