






































































































































































































import { Component, Vue } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import MenuComponent from "../components/menu.vue";
import { GetRequestWithAuthorization } from "../helpers/APIHelper";
import { changeLocale, ChecktoAddLangTruncateClass } from "../plugins/i18n.js";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import LoggingHelper from "../helpers/LoggingHelper";
import LoggingConstants from "../constants/LoggingConstants";
import { APIService } from "@/services/APIService";
import GenericKeyPair from "@/models/GenericKeyPair";
import { setPersistedState } from "@/helpers/StateMapper";
// import {EventBusLayout} from "../bus/EventBusLayout"

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
	},
})
export default class HomePage extends Vue {
	private router: any;
	private AddLangClasses: any = false;
	created() {
		this.router = this.$router;
		// EventBusLayout.$on('RoutedToEvt',this.HomeRoutedToEvt)
		this.AddLangClasses = ChecktoAddLangTruncateClass();
		LoggingHelper.getInstance()?.LogEvent(LoggingConstants.NavigateTo + "Home");

		window.removeEventListener("beforeunload", this.onCloseBrowser);
		this.GetGamePlanList();
	}

	onCloseBrowser(event: any) {
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				this.$router.push({
					name: "FinishPresentation",
					params: { showWhatHappenError: "true" },
				});
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}
	// 	HomeRoutedToEvt(route: any){
	// 		this.RouteTo(route);
	// 	}
	// 	beforeDestroy() {
	// 	EventBusLayout.$off('RoutedToEvt',this.HomeRoutedToEvt);
	//   }
	GetGamePlanList() {
		let CurrentTime = new Date();
		let SelectedYear = CurrentTime.getFullYear();
		let years = [
			CurrentTime.getFullYear() - 1,
			CurrentTime.getFullYear(),
			CurrentTime.getFullYear() + 1,
		];
		APIService.GetGamePlansByYear(years).then((res: any) => {
			if (res != undefined && res.Result != undefined) {
				let GamePlanList = new GenericKeyPair("GamePlans", res.Result);
				setPersistedState(GamePlanList);
			}
		});
	}

	gotoPlanetAltig() {
		window.open("https://www.planetaltig.com");
	}

	getCurrentUserInfo() {
		// this.login(this.user).then((data) => {});
		GetRequestWithAuthorization(
			ApiEndPointConstants.CurrentUserInfoEndpoint,
			[]
		)
			.then((res) => {
				if (res != undefined && res.status == true) {
				}
			})
			.catch((ex) => {});
	}

	RouteTo(name: string, route: string) {
		this.$router.push(route);
	}
}
