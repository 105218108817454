// import whatever u need from your 'statemapper'... this is just an example.
import { setPersistedState } from '../../helpers/StateMapper';

//decide which mutations you want to listen in on, for persisting app data
const mutationsOfInterest = [
    'doThis',
    'doThat',
    'someOtherThing'
];

const ofInterest = (mutation: any) => {
    return mutationsOfInterest.includes(mutation);
};

export const persistencePlugin = (store: any) => {
    store.subscribe((mutation: any, state: any) => {
        if (ofInterest(mutation.type)) {
            // handover to relevant get/set mappings. straightfwd example would be:
            setPersistedState(state);
        }
    });
};