































































import { EventBusLayout } from "../bus/EventBusLayout";
import User from "@/entities/User";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getPersistedState } from "../helpers/StateMapper";
let ailLog = require("../assets/AO_Logo.svg");
let placeholderuser = require("../assets/placeholderuser.png");

@Component({})
export default class UserShortDetailView extends Vue {
	private user: any;
	private PresentedByText: any = this.$t("General.PresentedBy");
	private Name: string = "User";
	private LicenseNo: string = "";
	private LicenseState: string = "";
	private UnionCode: string = "OPEIU 277";
	private States: any = [];
	public imgSource: string = "../assets/placeholderuser.png";

	@Prop({ default: "" })
	bindedLicense!: string;

	@Prop({ default: "Input Property" })
	bindedUnion!: string;

	@Prop({ default: "Input Property" })
	LanguageFlag!: string;

	@Watch("LanguageFlag")
	OnLanguageFlagChanged(newValue: string, oldValue: string) {
		if (
			this.$route.path == "/profile" ||
			this.$route.path == "/home" ||
			this.$route.path == "/training"
		) {
			this.PresentedByText = "";
		} else {
			this.PresentedByText = this.$t("General.PresentedBy");
		}
	}

	async LoadStates() {
		getPersistedState("StateList").then((res) => {
			this.States = res;
		});
	}

	@Watch("bindedLicense")
	OnLicenseChanged(newValue: string, oldValue: string) {
		this.LicenseNo = newValue ? newValue.split("-")[0] : "";
		this.LicenseState = newValue ? newValue.split("-")[1] : "";
	}

	@Watch("bindedUnion")
	OnUnionChanged(newValue: string, oldValue: string) {
		this.UnionCode = newValue;
	}

	LoadPrefillData() {
		var self = this;
		let CurrentUser = localStorage.getItem("user");
		if (CurrentUser != undefined) {
			var userObj = JSON.parse(CurrentUser);
			let userDetail = userObj.UserDetail;
			this.Name = userDetail.FirstName + " " + userDetail.LastName;
			getPersistedState("UnionCodeList").then((res: any) => {
				if (userDetail != undefined && userDetail.UnionId != 0) {
					self.UnionCode = res.filter(
						(x: any) => x.Key == userDetail.UnionId
					)[0].Value;
				}
			});

			if (userDetail.Licenses != undefined && userDetail.Licenses.length > 0) {
				var lic = undefined;
				var lic = userDetail.Licenses.filter(
					(i: { IsPrimary: boolean }) => i.IsPrimary == true
				);

				if (lic == undefined || lic.length == 0) {
					lic = userDetail.Licenses.filter(
						(i: { IsPrimary: boolean }) => i.IsPrimary == true
					);
				}
				if (lic != undefined && lic.length > 0)
					this.OnLicenseChanged(
						lic[0].LicenseNumber + "-" + lic[0].StateCode,
						""
					);
			}
		}
	}

	created() {
		this.user = User.query().last();
		EventBusLayout.$on("LisenceChangeonState", this.LisenceChange);
		this.LoadPrefillData();

		this.PresentedByText = this.$t("General.PresentedBy");

		var rPath = this.$route.path;
		// if (rPath == "/StartPresentation") {
		//   this.changeLicense();
		// }

		// if (
		// 	this.$route.path == "/profile" ||
		// 	this.$route.path == "/home" ||
		// 	this.$route.path == "/training" ||
		// 	this.$route.path == "/gameplan"
		// ) {
		// 	this.PresentedByText = "";
		// }
		// if (
		// 	this.$route.path == "/home" ||
		// 	this.$route.path == "/training" ||
		// 	this.$route.path == "/gameplan"
		// ) {
		// 	this.UnionCode = "";
		// }

		this.imgSource = placeholderuser;
		// setTimeout(() => {
		// 	var dataURL = localStorage.getItem("profileImage1");
		// 	if (dataURL != null && dataURL != undefined) this.imgSource = dataURL;
		// 	else if (this.$route.path != "/home" && this.$route.path != "/training") {
		// 		this.imgSource = placeholderuser;
		// 	} else {
		// 		this.imgSource = placeholderuser;
		// 	}
		// }, 50);
		if (this.bindedLicense != undefined && this.bindedLicense != "")
			this.OnLicenseChanged(this.bindedLicense, "");

		this.LoadStates();
	}
	get ProfileUpdated() {
		if (this.$store.state.ProfileInfoUpdated == true) {
			this.LoadPrefillData();
		}
		this.$store.state.ProfileInfoUpdated = false;
		return true;
	}
	changeLicense() {
		if (
			this.user != undefined &&
			this.user.LicenseStates != null &&
			this.user.LicenseStates != undefined &&
			this.user.LicenseStates != ""
		) {
			var licStates = JSON.parse(this.user.LicenseStates);
			var state = this.States.find(
				(i: { statename: any }) => i.statename === this.user.Territory
			);
			var lic = licStates.find(
				(i: { State: any }) => i.State === state.statecode
			);
			this.LicenseNo = lic ? lic.Number : "";
			this.LicenseState = lic ? lic.State : "";
		} else {
			this.LicenseNo = "";
			this.LicenseState = "";
		}
	}
	get ProfilePicture() {
		var dataURL;
		if (this.$store.state.ProfileImage == true)
			dataURL = this.$store.state.ProfileImageString;
		if (dataURL != null && dataURL != undefined && dataURL != "")
			this.imgSource = dataURL;
		else if (this.$route.path != "/home" && this.$route.path != "/training") {
			this.imgSource = placeholderuser;
		} else {
			this.imgSource = placeholderuser;
		}
		return this.imgSource;
	}
	LisenceChange(obj: any) {
		this.LicenseNo = obj ? obj.split("-")[0] : "";
		this.LicenseState = obj ? obj.split("-")[1] : "";
	}
	get getPrimaryLisence() {
		if (
			this.$route.name == "Profile" ||
			this.$route.name == "Dashboard" ||
			this.$route.name == "GamePlanPage" ||
			(this.$route.name == "PlanGenerator" &&
				Boolean(this.$route.params.initiatePrePlan) == true) ||
			(this.$route.name == "No Cost Benefits" &&
				Boolean(this.$route.params.showallMaterials) == true) ||
			this.$route.name == "Hour Power Pro"
		) {
			var self = this;
			let CurrentUser = localStorage.getItem("user");
			if (CurrentUser != undefined) {
				var userObj = JSON.parse(CurrentUser);
				let userDetail = userObj.UserDetail;
				if (
					userDetail.Licenses != undefined &&
					userDetail.Licenses.length > 0
				) {
					var lic = undefined;
					var lic = userDetail.Licenses.filter(
						(i: { IsPrimary: boolean }) => i.IsPrimary == true
					);

					if (lic == undefined || lic.length == 0) {
						lic = userDetail.Licenses.filter(
							(i: { IsPrimary: boolean }) => i.IsPrimary == true
						);
					}
					if (lic != undefined && lic.length > 0)
						this.OnLicenseChanged(
							lic[0].LicenseNumber + "-" + lic[0].StateCode,
							""
						);
				}
			}
		}
		return true;
	}
}
