










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { IndexDBConstants } from "@/constants/IndexDBConstants";
import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import { PresentationType } from "@/enumerations/PresentationType";
import { groupBy } from "@/helpers/ArrayHelper";
import { getPersistedState } from "@/helpers/StateMapper";
import { SetUserLanguage } from "@/plugins/i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import Router from "vue-router";
import { namespace } from "vuex-class";
import LoggingConstants from "../constants/LoggingConstants";
import LoggingHelper from "../helpers/LoggingHelper";
import { EventBusLayout } from "../bus/EventBusLayout";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
const Auth = namespace("Auth");

@Component
export default class LeftMenu extends Vue {
	@Auth.Action
	private signOut!: () => void;
	private benefitsummaryclicked: boolean = false;
	private SelectedGroup: any = null;
	private Presentation: any = {};
	private Person: any;
	private SelectedPresentationType: any = {};
	private showMatierials: boolean = false;
	private showSubMenu: boolean = false;
	private Groups: any = [];
	private MessageFrom: string = "";
	private GroupVideoUrl: string = "";
	private showAllMaterials: boolean = false;
	private allProducts: any = [];
	private allPresTypes: any = [];
	private allMatchingproducts: any;
	private allMatchingproductsReferral: any;
	private products: any;
	private states: any = [];
	private itemLessCount: number = 3;
	private CountryId = 1;
	private currentIndex: number = -1;
	private displayButtonClicked: boolean = false;
	private displayVideoButtonClicked: boolean = false;
	private displayNeedAnalysisPrefillButtonClicked: boolean = false;

	// private QuestionnaireGroupCode: string = "";
	// private QuestionnaireGroupName: string = "";
	// private QuestionnaireVideoUrl: string = "";
	private OutsidePresentation: boolean = true;

	private NeedAnalysisDisabled: boolean = true;
	private NeedAnalysisActive: boolean = false;
	private BenefitsSummaryActive: boolean = false;
	private presentationsActive: boolean = false;
	private FinsihPresentationActive: boolean = false;
	private PlanGeneratorActive: boolean = false;
	private PGIconActive: boolean = false;
	private PlanGeneratorDisabled: boolean = true;
	private BenefitsSummaryDisabled: boolean = true;
	private FinsihPresentationDisabled: boolean = true;
	private PGIconDisabled: boolean = true;
	private confirmationDialogHome: boolean = false;
	private confirmationDialogFinish: boolean = false;
	private ReportFormActive: boolean = false;
	private PlanetHomeVisibility: boolean = false;
	private LaunchHPProVisibility: boolean = false;
	private ManagementToolVisibility: boolean = false;
	private EAppVisibility: boolean = false;
	private MyDashboardVisibility: boolean = false;
	private NeedAnalysisVisibilty: boolean = false;
	private GamePlanVisibilty: boolean = false;
	private PresHistoryVisibilty: boolean = false;
	private ProfileVisibilty: boolean = false;
	private PlanGeneratorVisibilty: boolean = false;
	private BenefitsSummaryVisibilty: boolean = false;
	private FinishPresVisibilty: boolean = false;
	private ShowHomeVisibility: boolean = false;
	private BeneficiaryVisibilty: boolean = false;
	private RateBookVisibilty: boolean = false;
	private MaterialsVisibilty: boolean = false;
	private ReportCardVisibilty: boolean = false;
	private UWManualVisibilty: boolean = false;
	private MedicalQuestionVisibilty: boolean = false;
	private PrePlanVisibility: boolean = false;
	private NoCostVisibilty: boolean = false;
	private SPIconVisiblity: boolean = false;
	private PGIconVisiblity: boolean = false;
	private showTraining: boolean = false;
	private showAchievements: boolean = false;
	private EappSummaryVisiblity: boolean = false;
	private CementingVideoVisibilty: boolean = false;
	private ProtectionVisibility: boolean = false;
	private ShowleftMenuList: boolean = true;
	private mini: boolean = true;
	private materialsClose: boolean = true;
	private checkpresentplan: any;
	private isNeedAnalysisUpdated: boolean = false;
	private isNeedAnalysisValidated: boolean = false;
	private planGeneratorProductsSelected: boolean = false;
	private CloseAnyPopupFromOutside: boolean = true;
	// private ClosePresentPopupFromOutside: boolean = true;
	private IsMaterialUpdated: boolean = false;
	data() {
		return {
			isClonedPresentation: false,
			drawer: true,
			mini: true,
		};
	}
	@Prop({ default: false })
	private personexist: any = false;
	// MenuClose(){
	// 	if (this.mini == false) {
	//                 this.mini = true;
	//             }
	// }
	// private ssProgram: boolean = false;

	// data() {
	// 	return {
	// 		ssProgram: false,
	// 	};
	// }

	$refs!: {
		groupComboBox: any;
	};
	created() {
		EventBusLayout.$on("CloseMaterial", this.LeftMenuCloseMaterial);
		EventBusLayout.$on("PresentPlanCheck", this.LeftMenuPresentPlanCheck);
		EventBusLayout.$on("NeedAnalysisupdated", this.LeftMenuNeedAnalysisupdated);
		EventBusLayout.$on(
			"PlanGeneratorProductsSelected",
			this.LeftMenuPlanGeneratorProductsSelected
		);
		EventBusLayout.$on("BenefitSummaryRoute", this.LeftMenuBenefitSummaryRoute);
		EventBusLayout.$on("CloseSubMenuPopups", this.LeftMenuCloseSubMenuPopups);
		EventBusLayout.$on(
			"DataUpdatedfromMaterial",
			this.LeftMenuDataUpdatedfromMaterial
		);
		EventBusLayout.$on("CloseGroup", this.LeftMenuCloseGroup);
		EventBusLayout.$on("CloseGroupVideo", this.LeftMenuCloseGroupVideo);
		EventBusLayout.$on(
			"CloseNeedsAnalysisPrefill",
			this.LeftMenuCloseNeedsAnalysisPrefill
		);
	}
	LeftMenuCloseMaterial(closeBool: any) {
		this.materialsClose = closeBool;
		this.currentIndex = -1;
		this.displayButtonClicked = false;
		this.displayVideoButtonClicked = false;
		this.displayNeedAnalysisPrefillButtonClicked = false;
	}
	LeftMenuPresentPlanCheck(PresentPlanCheck: any) {
		this.checkpresentplan = PresentPlanCheck;
	}
	LeftMenuNeedAnalysisupdated() {
		this.isNeedAnalysisUpdated = true;
		this.PGIconDisabled = true;
		this.BenefitsSummaryDisabled = true;
	}
	LeftMenuPlanGeneratorProductsSelected(selected: any) {
		this.planGeneratorProductsSelected = selected;
		this.isNeedAnalysisUpdated = !selected;
		this.IsMaterialUpdated = !selected;
		this.PGIconDisabled = !selected;
		this.BenefitsSummaryDisabled = !selected;
	}
	LeftMenuCloseSubMenuPopups() {
		this.CloseAnyPopupFromOutside = true;
	}
	LeftMenuBenefitSummaryRoute(benefitClickedBool: any) {
		this.benefitsummaryclicked = benefitClickedBool;
		this.allMatchingproducts = [];
		this.allProducts = [];
		this.products = this.allProducts;
	}
	LeftMenuDataUpdatedfromMaterial() {
		this.IsMaterialUpdated = true;
		this.PGIconDisabled = true;
		this.BenefitsSummaryDisabled = true;
	}
	LeftMenuCloseGroup(groupBool: any) {
		this.displayButtonClicked = groupBool;
		this.materialsClose = !this.displayButtonClicked;
	}
	LeftMenuCloseGroupVideo(groupBool: any) {
		this.displayVideoButtonClicked = groupBool;
		this.materialsClose = !this.displayVideoButtonClicked;
	}
	LeftMenuCloseNeedsAnalysisPrefill(needsBool: any) {
		this.displayNeedAnalysisPrefillButtonClicked = needsBool;
		this.materialsClose = !this.displayNeedAnalysisPrefillButtonClicked;
	}
	logOut() {
		this.signOut();
		this.$router.push("/");
	}
	getMaterialNameWithoutSpaces(item: string) {
		var str = item;
		str = str.replace(/ /g, "");
		return str;
	}

	get IsAlphaPresentationAvailable() {
		let Agent = localStorage.getItem("user");
		var currentUser;
		if (Agent != undefined) {
			var userObj = JSON.parse(Agent);
			currentUser = userObj.UserDetail;
			if (currentUser != null && currentUser != undefined) {
				if (!currentUser.VeteranSmbAllowed) {
					return false;
				}
			}
		}
		return true;
	}

	get IsVeteranSMBAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId == PresentationType.Veteran &&
			this.Presentation.PresentationSubTypeId ==
				PresentationSubType.ServiceMemberBenefitsLeadAlpha
		) {
			return true;
		}
		return false;
	}

	get IsServiceMemberBenefitsAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId ==
				PresentationType.ServiceMemberBenefits &&
			(this.Presentation.PresentationSubTypeId ==
				PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.SMBVeteranFamilyLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.Civilian ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitNotification)
		) {
			return true;
		}
		return false;
	}

	get showLogout() {
		if (
			this.$route != undefined &&
			this.$route.meta != undefined &&
			this.$route.path != "/home"
		)
			return this.$route.meta.showLogout;
		else return false;
	}

	get Checkifvideoexists() {
		if (
			this.Presentation != null &&
			this.Presentation != undefined &&
			this.Presentation.BackupPresentedGroupId != null &&
			this.Presentation.BackupPresentedGroupId != undefined &&
			this.Presentation.BackupPresentedGroupId != "" &&
			this.Groups.length > 0
		) {
			let currentGroup = this.Groups.find(
				(x: any) => x.Id == this.Presentation.BackupPresentedGroupId
			);
			if (
				currentGroup != null &&
				currentGroup != undefined &&
				currentGroup.VideoUrl != null &&
				currentGroup.VideoUrl != undefined &&
				currentGroup.VideoUrl != ""
			) {
				this.MessageFrom = `MESSAGE FROM - <span class=\"fw-700\">${currentGroup.GroupName}</span>`;
				this.GroupVideoUrl = currentGroup.VideoUrl;
				return true;
			}
			return false;
		}
		return false;
	}

	MenuClose() {
		if (this.mini == false) {
			// if (this.materialsClose) {
			// 	this.mini = true;
			// }
			// if (!this.showMatierials) {
			this.mini = true;
			// }
		}
	}

	RouteTo(
		route: string,
		name: string = "",
		props: any = null,
		logName: string = ""
	) {
		this.$store.state.PresentPlanParam = false;
		this.showSubMenu = false;
		if (route != "nocostbenefits" && this.currentIndex == -1) {
			this.allMatchingproducts = [];
			this.allProducts = [];
			this.products = this.allProducts;
		}
		setTimeout(() => {
			this.showSubMenu = true;
		}, 500);
		this.MenuClose();

		if (props == undefined) {
			if (route == "/benefitssummary") this.benefitsummaryclicked = true;
		} else {
			if (props.presentplan == true && route == "/benefitssummary") {
				this.benefitsummaryclicked = false;
			}
		}
		if (logName != "")
			LoggingHelper.getInstance().LogEvent(
				LoggingConstants.NavigateTo + logName
			);
		if (this.$route.name == "Hour Power Pro") {
			this.$router.push(route);
			//this.ConfirmHome();
			return;
		}

		if (
			route == "/StartPresentation" &&
			(this.$route.name == "StartPresentation" ||
				this.$route.name == "Dashboard" ||
				this.$route.name == "Training Page" ||
				this.$route.name == "GamePlanPage")
		) {
			this.$router.push(route);
			//this.ConfirmHome();
			return;
		}

		var showAllMaterials = Boolean(this.$route.params.showallMaterials);

		if (this.$route.name == "Report Form") {
		} else if (
			route == "/StartPresentation" &&
			this.$route.name != "dashboard" &&
			this.$route.name != "Profile" &&
			this.$route.name != "PresentationHistory" &&
			this.$route.name != "PresentationHistoryDetail" &&
			!showAllMaterials
		) {
			this.confirmationDialogHome = true;
			return;
		}

		if (route == "/StartPresentation") {
			this.SelectedGroup = "";
		}

		var ispresentplan = Boolean(this.$route.params.presentplan);
		if (
			route == "/benefitssummary" &&
			this.benefitsummaryclicked &&
			this.$route.path == "/benefitssummary"
		) {
			this.Action("benefitssummary", "Benefits summary opened");
			return;
		}

		if (
			route == "/benefitssummary" &&
			!this.benefitsummaryclicked &&
			this.$route.path == "/benefitssummary"
		) {
			var obj = {
				name: name,
				params: props,
			};
			EventBusLayout.$emit("RouteWithParams", obj);
			return;
		}

		var loadplanfromdb = false;
		if (this.$route.path == "/benefitssummary" && route == "/plangenerator") {
			loadplanfromdb = true;
		}
		var isPrePlan = Boolean(this.$route.params.initiatePrePlan);
		if (isPrePlan && route != "/ssprogram" && route != "/dashboard") {
			props = {
				initiatePrePlan: true,
				ExcludeFromPresentationCycle: true,
				loadplanfromdb: loadplanfromdb,
			};
			EventBusLayout.$emit("SavePrePlan", name);

			if (name == "PlanGenerator") {
				if (loadplanfromdb) {
					getPersistedState("PrePlan").then((result: any) => {
						if (result != undefined) {
							result = JSON.parse(result);
							props.preplan = result;
							this.$router.push({ name: name, params: props });
						}
					});
				} else {
					this.$router.push({ name: name, params: props });
				}
			}
			return;
		}
		if (!props) {
			EventBusLayout.$emit("RoutedToEvt", route);
			// this.$emit("RoutedToEvt", route);
			return;
		}
		if (this.$route.path == "/plangenerator") {
			var obj = {
				name: name,
				params: props,
			};
			EventBusLayout.$emit("RouteWithParams", obj);
			return;
		}

		this.$router.push({ name: name, params: props });
	}

	// ssprogramDialog() {
	// 	this.$emit("ssProgram", true);
	// }

	Action(actionName: string, logName: string = "") {
		this.$store.state.PresentPlanParam = false;
		if (actionName != "beneficiary") {
			this.CloseAnyPopupFromOutside = false;
		}
		if (actionName == "benefitssummary") {
			setTimeout(() => {
				this.showSubMenu = true;
			}, 3000);
		}
		if (actionName == "materials") {
			// this.showMatierials = !this.showMatierials;
			// this.showMatierials = true;

			// if (this.showMatierials) {
			this.LoadDropDown();
			// }

			if (this.$route.path != "/nocostbenefits") {
				return;
			}
		}

		if (actionName == "logOut" && this.$route.path != "/profile") {
			this.logOut();
			LoggingHelper.getInstance().LogEvent(LoggingConstants.Logout);
		}
		if (logName != undefined && logName != "") {
			LoggingHelper.getInstance().LogEvent(logName);
		}
		EventBusLayout.$emit("Action", actionName);
		if (actionName != "materials") {
			this.MenuClose();
		}
	}

	ConfirmHome() {
		this.confirmationDialogHome = false;
		this.Presentation = Presentation.query().last();
		if (this.Presentation != null && this.Presentation != undefined) {
			this.Presentation.SGNumber = this.$store.state.SGNumber;
			this.Presentation.PresentedGroup = this.$store.state.PresentedGroup;

			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		EventBusLayout.$emit("RoutedToEvt", "StartPresentation");
		// this.$store.state.SGNumber = "";
		// this.$store.state.PresentedGroup = "";
		this.SelectedGroup = "";
	}

	// mounted() {
	// 	this.loadicons;
	// }

	get loadicons() {
		var showAll = Boolean(this.$route.params.showallMaterials);
		var isPrePlan = Boolean(this.$route.params.initiatePrePlan);
		this.OutsidePresentation = showAll || isPrePlan;
		this.Presentation = Presentation.query().withAllRecursive().last();
		this.ShowleftMenuList = false;
		this.showAchievements = false;
		this.PlanetHomeVisibility = false;
		this.LaunchHPProVisibility = false;
		this.ManagementToolVisibility = false;
		this.EAppVisibility = false;
		this.MyDashboardVisibility = false;
		this.NeedAnalysisVisibilty = false;
		this.GamePlanVisibilty = false;
		this.PresHistoryVisibilty = false;
		this.ProfileVisibilty = false;
		this.PlanGeneratorVisibilty = false;
		this.BenefitsSummaryVisibilty = false;
		this.FinishPresVisibilty = false;
		this.ShowHomeVisibility = false;
		this.BeneficiaryVisibilty = false;
		this.RateBookVisibilty = false;
		this.MaterialsVisibilty = false;
		this.ReportCardVisibilty = false;
		this.UWManualVisibilty = false;
		this.MedicalQuestionVisibilty = false;
		this.PrePlanVisibility = false;
		this.NoCostVisibilty = false;
		this.SPIconVisiblity = false;
		this.PGIconVisiblity = false;
		this.showTraining = false;
		this.showAchievements = false;
		this.EappSummaryVisiblity = false;
		this.CementingVideoVisibilty = false;
		this.ProtectionVisibility = false;
		this.checkpresentplan = true;
		this.CloseAnyPopupFromOutside = true;

		if (this.$route.name == "StartPresentation") {
			this.ShowHomeVisibility = true;
			this.MyDashboardVisibility = true;
			this.MaterialsVisibilty = true;
			this.SPIconVisiblity = true;
			this.RateBookVisibilty = true;
			this.PrePlanVisibility = true;
			this.MedicalQuestionVisibilty = true;
			this.UWManualVisibilty = true;
			this.planGeneratorProductsSelected = false;
			this.EAppVisibility = true;
		}
		if (this.$route.name == "No Cost Benefits" && showAll) {
			this.ShowHomeVisibility = true;
			this.NoCostVisibilty = true;
			this.MaterialsVisibilty = true;
			// this.SPIconVisiblity = true;
			// this.NeedAnalysisDisabled = false;
			// this.NeedAnalysisVisibilty = true;
			// this.PlanGeneratorVisibilty = true;
			// this.PlanGeneratorDisabled = false;
			// this.PGIconVisiblity = !isPrePlan;
			// this.PGIconDisabled = isPrePlan;
			// this.BenefitsSummaryVisibilty = true;
			// this.BenefitsSummaryDisabled = false;
			// this.ReportCardVisibilty = true;
			// this.FinishPresVisibilty = true;
			// this.FinsihPresentationDisabled = false;
		}

		if (this.$route.name == "No Cost Benefits" && !showAll) {
			this.ShowHomeVisibility = true;
			this.NoCostVisibilty = true;
			this.MaterialsVisibilty = true;
			this.SPIconVisiblity = true;
			this.NeedAnalysisDisabled = false;
			this.NeedAnalysisVisibilty = true;
			this.PlanGeneratorVisibilty = true;
			this.PlanGeneratorDisabled = false;
			this.PGIconVisiblity = !isPrePlan;
			this.PGIconDisabled = isPrePlan;
			this.BenefitsSummaryVisibilty = true;
			this.BenefitsSummaryDisabled = false;
			this.ReportCardVisibilty = true;
			this.FinishPresVisibilty = true;
			this.FinsihPresentationDisabled = false;
			if (this.Presentation != null) {
				// if (this.Presentation.TimeOnNeedAnalysis == 0) {
				// 	this.NeedAnalysisDisabled = false;
				// }
				if (!this.planGeneratorProductsSelected || this.IsMaterialUpdated) {
					this.PlanGeneratorDisabled = true;
					this.PGIconDisabled = true;
					this.BenefitsSummaryDisabled = true;
				}
				// if (this.Presentation.TimeOnPresentPlan == 0) {
				// 	this.PGIconDisabled = false;
				// }
				// if (this.Presentation.TimeOnBenefitSummary == 0) {
				// 	this.BenefitsSummaryDisabled = false;
				// }
			} else {
			}
		}

		if (this.$route.name == "NeedAnalysis") {
			this.UWManualVisibilty = true;
			this.MedicalQuestionVisibilty = true;

			this.ShowHomeVisibility = true;
			this.NoCostVisibilty = true;
			this.MaterialsVisibilty = true;
			this.SPIconVisiblity = true;
			this.NeedAnalysisDisabled = false;
			this.NeedAnalysisVisibilty = true;
			this.PlanGeneratorVisibilty = true;
			this.PlanGeneratorDisabled = false;
			this.PGIconVisiblity = !isPrePlan;
			this.PGIconDisabled = isPrePlan;
			this.BenefitsSummaryVisibilty = true;
			this.BenefitsSummaryDisabled = false;
			this.ReportCardVisibilty = true;
			this.FinishPresVisibilty = true;
			this.FinsihPresentationDisabled = false;
			if (
				!this.planGeneratorProductsSelected ||
				this.isNeedAnalysisUpdated ||
				this.IsMaterialUpdated
			) {
				// this.PlanGeneratorDisabled = true;
				this.PGIconDisabled = true;
				this.BenefitsSummaryDisabled = true;
			}
		}
		if (this.$route.name == "PlanGenerator") {
			var isPrePlan = Boolean(this.$route.params.initiatePrePlan);
			if (isPrePlan) {
				this.ShowHomeVisibility = true;
				this.PrePlanVisibility = true;
				this.PlanGeneratorVisibilty = true;
				this.PlanGeneratorDisabled = false;

				this.BenefitsSummaryVisibilty = true;
				this.BenefitsSummaryDisabled = true;
				this.RateBookVisibilty = true;
			} else {
				this.ShowHomeVisibility = true;
				this.NoCostVisibilty = true;
				this.MaterialsVisibilty = true;
				this.SPIconVisiblity = true;
				this.NeedAnalysisDisabled = false;
				this.NeedAnalysisVisibilty = true;
				this.PlanGeneratorVisibilty = true;
				this.PlanGeneratorDisabled = false;
				this.PGIconVisiblity = !isPrePlan;
				this.PGIconDisabled = isPrePlan;
				this.BenefitsSummaryVisibilty = true;
				this.BenefitsSummaryDisabled = false;
				this.ReportCardVisibilty = true;
				this.FinishPresVisibilty = true;
				this.FinsihPresentationDisabled = false;
				if (!this.planGeneratorProductsSelected) {
					this.PGIconDisabled = true;
					this.BenefitsSummaryDisabled = true;
				}
				// if (this.Presentation.TimeOnPlanGenerator == 0) {
				// 	this.PlanGeneratorDisabled = true;
				// 	this.PGIconDisabled = true;
				// 	this.BenefitsSummaryDisabled = true;
				// }

				// // this.NeedAnalysisDisabled = false;
				// this.PlanGeneratorActive = false;
				// // this.PlanGeneratorDisabled = false;
				// // this.BenefitsSummaryDisabled = false;
				// // this.FinsihPresentationDisabled = false;
				// this.MyDashboardVisibility = false;
				// this.GamePlanVisibilty = false;
				// this.PresHistoryVisibilty = false;
				// this.ProfileVisibilty = false;
				// // this.PlanGeneratorVisibilty = true;
				// // this.PGIconDisabled = false;
				// // this.MaterialsVisibilty = false;
				// this.UWManualVisibilty = false;
				// this.MedicalQuestionVisibilty = false;
				// this.PrePlanVisibility = false;
				// // this.ShowHomeVisibility = true;
				// // this.NeedAnalysisVisibilty = !isPrePlan;
				// // this.PGIconVisiblity = !isPrePlan;
				// // this.BenefitsSummaryVisibilty = true;
				// this.RateBookVisibilty = true;
				// // this.ReportCardVisibilty = !isPrePlan;
				// // this.FinishPresVisibilty = !isPrePlan;
				// // if (!isPrePlan) this.SPIconVisiblity = true;
			}
		}

		if (this.$route.name == "BenefitsSummary") {
			var presentplan =
				this.checkpresentplan && Boolean(this.$route.params.presentplan);

			// if (presentplan && !this.benefitsummaryclicked) {
			if (
				presentplan &&
				this.benefitsummaryclicked &&
				this.$route.name == "BenefitsSummary"
			) {
				this.BeneficiaryVisibilty = true;
				this.ProtectionVisibility = true;
			}

			if (
				!presentplan &&
				!this.benefitsummaryclicked &&
				this.$route.name == "BenefitsSummary"
			) {
				this.UWManualVisibilty = true;
				this.MedicalQuestionVisibilty = true;
				this.CementingVideoVisibilty = true;
				this.ProtectionVisibility = true;
			}

			if (this.OutsidePresentation) {
				this.ShowHomeVisibility = true;
				this.PrePlanVisibility = true;
				this.BenefitsSummaryVisibilty = true;
				this.BenefitsSummaryDisabled = false;
			} else {
				this.ShowHomeVisibility = true;
				this.NoCostVisibilty = true;
				this.MaterialsVisibilty = true;
				this.SPIconVisiblity = true;
				this.NeedAnalysisDisabled = false;
				this.NeedAnalysisVisibilty = true;
				this.PrePlanVisibility = true;
				this.PlanGeneratorVisibilty = true;
				this.PlanGeneratorDisabled = false;
				this.PGIconVisiblity = !isPrePlan;
				this.PGIconDisabled = isPrePlan;
				this.BenefitsSummaryVisibilty = true;
				this.BenefitsSummaryDisabled = false;
				this.ReportCardVisibilty = true;
				this.FinishPresVisibilty = true;
				this.FinsihPresentationDisabled = false;
			}

			// //this.BeneficiaryVisibilty = false;
			// // this.NeedAnalysisDisabled = false;
			// //this.BenefitsSummaryActive = true;
			// // this.PlanGeneratorDisabled = false;
			// //this.BenefitsSummaryDisabled = false;
			// //this.FinsihPresentationDisabled = false;
			// this.MyDashboardVisibility = false;
			// // this.NeedAnalysisVisibilty = false;
			// this.GamePlanVisibilty = false;
			// this.PresHistoryVisibilty = false;
			// this.ProfileVisibilty = false;
			// this.BenefitsSummaryDisabled = false;
			// this.RateBookVisibilty = false;
			// // this.MaterialsVisibilty = false;
			// this.PrePlanVisibility = false;
			// this.BenefitsSummaryVisibilty = true;
			// // this.PlanGeneratorDisabled = false;

			// if (presentplan && !this.benefitsummaryclicked) {
			// 	// this.PlanGeneratorVisibilty = true;
			// 	// this.BenefitsSummaryVisibilty = true;
			// 	this.BeneficiaryVisibilty = true;
			// 	// this.PGIconVisiblity = true;
			// } else {
			// 	// this.PlanGeneratorVisibilty = true;
			// 	this.CementingVideoVisibilty = !isPrePlan;
			// 	this.ShowHomeVisibility = isPrePlan;
			// 	this.EappSummaryVisiblity = !isPrePlan;
			// 	// this.ReportCardVisibilty = !isPrePlan;
			// 	this.UWManualVisibilty = !isPrePlan;
			// 	this.MedicalQuestionVisibilty = !isPrePlan;
			// 	this.FinishPresVisibilty = !isPrePlan;
			// 	// if (!isPrePlan) this.SPIconVisiblity = true;
			// }
			// this.ProtectionVisibility = true;
		}

		if (
			this.$route.name == "FinishPresentation" ||
			this.$route.name == "Report Form"
		) {
			this.ShowHomeVisibility = true;
			this.NoCostVisibilty = true;
			this.MaterialsVisibilty = true;
			this.SPIconVisiblity = true;
			this.NeedAnalysisDisabled = false;
			this.NeedAnalysisVisibilty = true;
			this.PlanGeneratorVisibilty = true;
			this.PlanGeneratorDisabled = false;
			this.PGIconVisiblity = !isPrePlan;
			this.PGIconDisabled = isPrePlan;
			this.BenefitsSummaryVisibilty = true;
			this.BenefitsSummaryDisabled = false;
			this.ReportCardVisibilty = true;
			this.FinishPresVisibilty = true;
			this.FinsihPresentationDisabled = false;

			// this.MyDashboardVisibility = false;
			// this.GamePlanVisibilty = false;
			// this.PresHistoryVisibilty = false;
			// this.ProfileVisibilty = false;
			// this.RateBookVisibilty = false;
			// this.UWManualVisibilty = false;
			// this.MedicalQuestionVisibilty = false;
			// this.PrePlanVisibility = false;
		}

		// if (this.$route.name == "Report Form") {
		// 	this.ShowHomeVisibility = true;
		// 	this.PlanGeneratorVisibilty = true;
		// 	this.PlanGeneratorDisabled = false;
		// 	this.BenefitsSummaryVisibilty = true;
		// 	this.BenefitsSummaryDisabled = false;
		// 	this.ReportCardVisibilty = true;
		// 	this.FinsihPresentationDisabled = false;
		// 	this.NeedAnalysisVisibilty = true;
		// 	this.NoCostVisibilty = true;
		// 	this.NeedAnalysisDisabled = false;
		// 	this.SPIconVisiblity = true;
		// }

		if (this.$route.name == "Profile") {
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.ProfileVisibilty = true;
			this.ShowHomeVisibility = true;
		}

		if (
			this.$route.name == "Dashboard" ||
			this.$route.name == "GamePlanPage" ||
			this.$route.name == "PresentationHistory" ||
			this.$route.name == "PresentationHistoryDetail"
		) {
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.ProfileVisibilty = true;
			this.ShowHomeVisibility = true;
			this.showTraining = true;
			this.showAchievements = true;
		}

		if (this.$route.name == "Training Page") {
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.ProfileVisibilty = true;
			this.ShowHomeVisibility = true;
			this.showTraining = true;
		}

		if (this.$route.name == "Hour Power Pro") {
			this.PlanetHomeVisibility = true;
			this.ProfileVisibilty = true;
			this.showAchievements = true;
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.LaunchHPProVisibility = true;
			this.showTraining = true;
			this.ManagementToolVisibility = true;
			this.EAppVisibility = true;
		}
		this.ShowleftMenuList = true;
		return true;
	}

	// get ShowHomeButton() {
	// 	if (this.$route.name == "Hour Power Pro") {
	// 		return false;
	// 	}

	// 	return true;
	// }

	get ShowMainpageList() {
		let routeslist = [
			"Hour Power Pro",
			"Dashboard",
			"PresentationHistory",
			"PresentationHistoryDetail",
			"Profile",
			"GamePlanPage",
		];
		let currentroute =
			this.$route.name != null && this.$route.name != undefined
				? this.$route.name
				: "";
		if (routeslist.includes(currentroute)) {
			return true;
		}

		return false;
	}

	GroupSGBlur(v: any, text: any) {
		var previouslySelectedGroupCode: any = undefined;
		if (this.SelectedGroup != undefined) {
			previouslySelectedGroupCode = JSON.parse(
				JSON.stringify(this.SelectedGroup)
			);
		}
		var text = this.$refs.groupComboBox.lazySearch.toUpperCase();
		if (text != undefined && text != "") {
			var list = this.Groups.filter(
				(x: any) =>
					x.GroupCode != undefined &&
					x.GroupCode.toUpperCase() == text.toUpperCase()
			);
			if (list != undefined && list.length >= 1) {
				//console.log("Setting new value: " + list[0]);
				this.SelectedGroup = list[0];
				this.$store.state.PresentedGroup = this.SelectedGroup.GroupName;
				this.$store.state.SGNumber = this.SelectedGroup.GroupCode;
				// if (
				// 	this.SelectedGroup != undefined &&
				// 	this.Presentation?.Id != null &&
				// 	this.Presentation?.Id != undefined
				// 	// &&
				// 	// this.$data.Referrals != undefined
				// ) {
				// 	// this.$data.Referrals.Organization = this.SelectedGroup.GroupName;
				// 	// this.Presentation.PresentedGroup = this.SelectedGroup.GroupName;
				// 	// Presentation.update({
				// 	// 	where: this.Presentation.Id,
				// 	// 	data: this.Presentation,
				// 	// });
				// 	//this.$data.Referrals = this.$data.Referrals;
				// }
			} else {
				setTimeout(() => {
					this.SelectedGroup = previouslySelectedGroupCode;
					if (previouslySelectedGroupCode != undefined)
						this.Presentation.PresentedGroup =
							previouslySelectedGroupCode.GroupName;
					//this.ChangeQuesitonnaireGroups();
				}, 50);
			}
		} else {
			this.$store.state.PresentedGroup = "";
			this.$store.state.SGNumber = "";
			this.SelectedGroup = "";
		}

		if (this.$route.path == "/report") {
			EventBusLayout.$emit("UpdatedSGNumber", "SGNumber");
		}

		this.$refs.groupComboBox.isMenuActive = false;
	}

	GroupChange(evt: any) {
		if (evt == undefined) {
			this.$store.state.PresentedGroup = "";
			this.$store.state.SGNumber = "";
		}

		if (this.Presentation?.Id != null && this.Presentation?.Id != undefined) {
			if (evt == undefined) {
				this.$store.state.PresentedGroup = "";
				this.$store.state.SGNumber = "";
			} else {
				this.$store.state.PresentedGroup = evt.GroupName;
				this.$store.state.SGNumber = evt.GroupCode;
			}
		}
		//this.ChangeQuesitonnaireGroups();
	}

	LoadDropDown() {
		this.showAllMaterials =
			Boolean(this.$route.params.showallMaterials) ||
			this.$route.name == "StartPresentation";

		if (!this.showAllMaterials) this.Presentation = Presentation.query().last();
		else this.Presentation = {};
		// this.Person = PrimaryPerson.query().withAllRecursive().last();

		// this.$data.isClonedPresentation =
		// 	this.Presentation.ParentCode != undefined &&
		// 	this.Presentation.ParentCode != null &&
		// 	this.Presentation.ParentCode != "";

		getPersistedState("StateList").then((res) => {
			this.states = res;
			this.LoadData();
		});
	}

	async LoadData() {
		//this.$store.state.loader = true;
		var res = await getPersistedState(IndexDBConstants.PresentationTypesKey);

		this.allPresTypes = res;

		if (!this.showAllMaterials) {
			this.SelectedPresentationType = this.allPresTypes.find(
				(x: any) => x.Id == this.Presentation.PresentationTypeId
			);
			var stateSel = this.states.filter((x: any) => {
				if (x.Name == this.Presentation.State) return x;
			});
			var state: any = {};
			if (stateSel != undefined && stateSel.length > 0) {
				state = stateSel[0];
			}
			// this.isVeteran = this.SelectedPresentationType.Name == "VETERAN";
			// this.backgroundimage =
			// 	this.SelectedPresentationType.NoCostBackgroundImage;
			// this.foregroundimage =
			// 	this.SelectedPresentationType.NoCostForegroundImage;
			this.fetchMaterialProducts(
				this.SelectedPresentationType,
				state.CountryId,
				this.Presentation.LanguageId
			);

			this.CountryId = state.CountryId;
		} else {
			this.fetchAllMaterialProducts();
		}

		this.showMatierials = true;

		var groupfromDB = await getPersistedState("PresentationGroups");
		this.Groups = groupfromDB;

		if (
			this.Groups != null &&
			this.$store.state.SGNumber != undefined &&
			this.$store.state.SGNumber != ""
		) {
			this.SelectedGroup = this.Groups.find(
				(x: any) => x.GroupCode == this.$store.state.SGNumber
			);
			// if (this.SelectedGroup != undefined) this.ChangeQuesitonnaireGroups();
		}

		if (this.Presentation != undefined && this.SelectedGroup == undefined) {
			this.SelectedGroup = this.$store.state.SGNumber;
			// this.ChangeQuesitonnaireGroups();
		}

		//this.$store.state.loader = false;
	}

	fetchMaterialProducts(presType: any, countryId: number, languageId: number) {
		getPersistedState("Materials").then((res) => {
			this.allProducts = res;
			var langId = JSON.parse(JSON.stringify(languageId));

			if (langId == 48) langId = 41;

			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined || x.CountryId == countryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == presType.Id) &&
						(x.LanguageId == undefined || x.LanguageId == langId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);

			this.allMatchingproductsReferral = JSON.parse(
				JSON.stringify(this.allMatchingproducts)
			);

			if (presType.Id == PresentationType.Veteran) {
				if (
					this.Presentation?.SubType.toUpperCase() !=
					"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
				}

				if (
					this.Presentation?.SubType.toUpperCase() ==
						"SERVICE MEMBER BENEFITS LEAD" ||
					this.Presentation?.SubType.toUpperCase() ==
						"BURIAL & WILL KIT FOR VETERANS"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "$2000 GIFT CERTIFICATE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
				} else if (this.Presentation?.SubType.toUpperCase() == "RETURN CARD") {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "$2000 GIFT CERTIFICATE"
							)
						),
						1
					);
				} else if (
					this.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD" ||
					this.Presentation?.SubType.toUpperCase() == "VETERAN FAMILY LEAD"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
				} else if (
					this.Presentation?.SubType.toUpperCase() ==
					"SERVICE MEMBER BENEFITS LEAD (ALPHA)"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "GROUP AD&D CERTIFICATE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
							)
						),
						1
					);

					this.allMatchingproducts = this.allMatchingproducts.filter(
						(x: any) => x.ShowOnExpand == false
					);

					this.allMatchingproducts.forEach((material: any) => {
						if (material.Name.toUpperCase() == "VETERAN SURVEY") {
							material.MaterialName = "SURVEY FOR VETERANS & THEIR FAMILIES";
						}
						if (material.Name.toUpperCase() == "SMB - 3 IMPORTANT FACTS") {
							material.ShowOnExpand = true;
						}
						if (material.Name.toUpperCase() == "$2000 GIFT CERTIFICATE") {
							material.ShowOnExpand = true;
						}
					});

					this.allMatchingproducts = this.allMatchingproducts.sort(
						(a: any, b: any) => a.ShowOnExpand - b.ShowOnExpand
					);

					var a = this.allMatchingproducts[this.allMatchingproducts.length - 1];
					this.allMatchingproducts[this.allMatchingproducts.length - 1] =
						this.allMatchingproducts[this.allMatchingproducts.length - 2];
					this.allMatchingproducts[this.allMatchingproducts.length - 2] = a;
				}
			}

			if (presType.Id == PresentationType.ServiceMemberBenefits) {
				if (
					this.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD" ||
					this.Presentation?.SubType.toUpperCase() == "VETERAN FAMILY LEAD"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == true
							)
						),
						1
					);
				} else if (
					this.Presentation?.SubType.toUpperCase() ==
						"SPONSORED VETERAN LEAD (ALPHA)" ||
					this.Presentation?.SubType.toUpperCase() ==
						"VETERAN FAMILY LEAD (ALPHA)" ||
					this.Presentation?.SubType.toUpperCase() == "CIVILIAN" ||
					this.Presentation?.SubType.toUpperCase() ==
						"SERVICE MEMBER BENEFIT NOTIFICATION"
				) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == false
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "FAMILY INFO. GUIDE - VETERAN"
							)
						),
						1
					);

					this.allMatchingproducts = this.allMatchingproducts.filter(
						(x: any) => x.ShowOnExpand == false
					);

					this.allMatchingproducts.forEach((material: any) => {
						if (material.Name.toUpperCase() == "VETERAN SURVEY") {
							material.MaterialName = "SURVEY FOR VETERANS & THEIR FAMILIES";
						}
						if (material.Name.toUpperCase() == "SMB - 3 IMPORTANT FACTS") {
							material.ShowOnExpand = true;
						}
						if (material.Name.toUpperCase() == "$2000 GIFT CERTIFICATE") {
							material.ShowOnExpand = true;
						}
					});

					this.allMatchingproducts = this.allMatchingproducts.sort(
						(a: any, b: any) => a.ShowOnExpand - b.ShowOnExpand
					);
				}
			}

			this.itemLessCount = parseInt(
				this.allMatchingproducts
					.filter((x: any) => x.ShowOnExpand == false)
					.length.toString()
			);

			// (!(presType.Id == 5 || presType.Id == 6 || this.Presentation.SubType.toUpperCase() == "RETURN CARD"))
			if (
				presType.Id != 6 &&
				this.Presentation?.SubType.toUpperCase() != "RETURN CARD" &&
				this.allMatchingproducts.find(
					(x: any) => x.Name == "MEMBER QUESTIONNAIRE"
				) != undefined
			) {
				this.allMatchingproducts.splice(
					this.allMatchingproducts.indexOf(
						this.allMatchingproducts.find(
							(x: any) => x.Name == "MEMBER QUESTIONNAIRE"
						)
					),
					1
				);
			}

			this.products = JSON.parse(
				JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
			);
		});
	}

	fetchAllMaterialProducts() {
		SetUserLanguage();
		getPersistedState("Materials").then((res: any) => {
			if (res != undefined && res.length > 0) {
				this.allProducts = res;

				this.allProducts.forEach((matProd: any) => {
					if (matProd.CountryCode != undefined && matProd.CountryCode != "") {
						matProd.MaterialName =
							matProd.MaterialName + " - " + matProd.CountryCode;
					}
				});

				var allProd = this.allProducts
					.filter((x: any) => x.MaterialId != undefined)
					.sort((a: any, b: any) => a.priority - b.priority);
				var groupedElements = groupBy(allProd, "MaterialId");
				var materials: any = [];
				for (var prop in groupedElements) {
					materials.push(groupedElements[prop][0]);
				}
				this.allMatchingproducts = materials;

				if (!this.IsAlphaPresentationAvailable) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - BENEFITS & BURIAL GUIDE"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - 3 IMPORTANT FACTS"
							)
						),
						1
					);
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) => x.Name == "SMB - NO VETERAN LEFT BEHIND PROGRAM"
							)
						),
						1
					);
				}
				var EditableGiftCertificate = this.allMatchingproducts.find(
					(x: any) => x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == true
				);
				if (EditableGiftCertificate != undefined) {
					this.allMatchingproducts.splice(
						this.allMatchingproducts.indexOf(
							this.allMatchingproducts.find(
								(x: any) =>
									x.Name == "$2000 GIFT CERTIFICATE" && x.IsEditable == true
							)
						),
						1
					);
				}
				this.allMatchingproductsReferral = JSON.parse(
					JSON.stringify(this.allMatchingproducts)
				);

				this.itemLessCount = this.allMatchingproducts.length / 2;

				this.itemLessCount = Number(this.itemLessCount.toFixed());
				this.products = JSON.parse(
					JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
				);
			}
		});
	}

	// ChangeQuesitonnaireGroups() {
	// 	if (this.SelectedGroup == undefined || this.SelectedGroup == "") {
	// 		if (
	// 			this.Presentation.BackupPresentedGroupId != undefined &&
	// 			this.Presentation.BackupPresentedGroupId != 0 &&
	// 			this.Groups != undefined
	// 		) {
	// 			var selGroup = this.Groups.find(
	// 				(x: any) => x.Id == this.Presentation.BackupPresentedGroupId
	// 			);
	// 			if (selGroup != undefined) {
	// 				this.QuestionnaireGroupCode = selGroup.GroupCode;
	// 				this.QuestionnaireGroupName = selGroup.GroupName;
	// 				this.QuestionnaireVideoUrl = selGroup.VideoUrl;
	// 			} else {
	// 				if (this.SelectedGroup.GroupCode != undefined) {
	// 					this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
	// 					this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
	// 				} else this.QuestionnaireGroupCode = this.SelectedGroup;
	// 				this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
	// 			}
	// 		} else {
	// 			if (
	// 				this.SelectedGroup != undefined &&
	// 				this.SelectedGroup.GroupCode != undefined
	// 			) {
	// 				this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
	// 				this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
	// 			} else this.QuestionnaireGroupCode = this.SelectedGroup;
	// 			this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
	// 		}
	// 	} else {
	// 		if (this.SelectedGroup.GroupCode != undefined) {
	// 			this.QuestionnaireGroupCode = this.SelectedGroup.GroupCode;
	// 			this.QuestionnaireVideoUrl = this.SelectedGroup.VideoUrl;
	// 		} else this.QuestionnaireGroupCode = this.SelectedGroup;
	// 		this.QuestionnaireGroupName = this.Presentation.PresentedGroup;
	// 	}
	// }

	moreLessTapped() {
		this.showMatierials = false;
		if (this.products.length == this.itemLessCount) {
			this.products = JSON.parse(JSON.stringify(this.allMatchingproducts));
		} else {
			this.products = JSON.parse(
				JSON.stringify(this.allMatchingproducts.slice(0, this.itemLessCount))
			);
		}
		this.showMatierials = true;
	}

	displayProduct(item: any, index: number) {
		this.currentIndex = index;
		if (this.$route.name != "No Cost Benefits") {
			if (this.$route.name == "StartPresentation") {
				EventBusLayout.$emit("Action", "materials");
				// this.RouteTo(
				// 	"/nocostbenefits",
				// 	"No Cost Benefits",
				// 	{
				// 		showallMaterials: "true",
				// 		ExcludeFromPresentationCycle: "true",
				// 	},
				// 	"No Cost Benefits"
				// );
			} else {
				this.RouteTo("/nocostbenefits", "", undefined, "No Cost Benefits");
			}

			setTimeout(() => {
				EventBusLayout.$emit("displayProduct", [item, index]);
			}, 1000);
		} else {
			EventBusLayout.$emit("displayProduct", [item, index]);
		}
		this.materialsClose = false;
	}

	displayNeedAnalysisPrefill() {
		if (this.$route.name != "No Cost Benefits") {
			if (this.$route.name == "StartPresentation") {
				EventBusLayout.$emit("Action", "materials");
				// this.RouteTo(
				// 	"/nocostbenefits",
				// 	"No Cost Benefits",
				// 	{
				// 		showallMaterials: "true",
				// 		ExcludeFromPresentationCycle: "true",
				// 	},
				// 	"No Cost Benefits"
				// );
			} else {
				this.RouteTo("/nocostbenefits", "", undefined, "No Cost Benefits");
			}

			setTimeout(() => {
				EventBusLayout.$emit("displayNeedAnalysisPrefill");
			}, 1000);
		} else {
			EventBusLayout.$emit("displayNeedAnalysisPrefill");
		}
		if (!this.displayNeedAnalysisPrefillButtonClicked) this.currentIndex = -1;
		this.materialsClose = false;
	}

	displayGroupVideo(videourl: any) {
		this.currentIndex = 0;
		if (this.$route.name != "No Cost Benefits") {
			if (this.$route.name == "StartPresentation") {
				EventBusLayout.$emit("Action", "materials");
			} else {
				this.RouteTo("/nocostbenefits", "", undefined, "No Cost Benefits");
			}

			setTimeout(() => {
				EventBusLayout.$emit("displayGroupVideo", videourl);
			}, 1000);
		} else {
			EventBusLayout.$emit("displayGroupVideo", videourl);
		}
		if (!this.displayVideoButtonClicked) this.currentIndex = -1;
	}

	displayGroup(item: any) {
		this.currentIndex = 0;
		if (this.$route.name != "No Cost Benefits") {
			if (this.$route.name == "StartPresentation") {
				EventBusLayout.$emit("Action", "materials");
			} else {
				this.RouteTo("/nocostbenefits", "", undefined, "No Cost Benefits");
			}

			setTimeout(() => {
				EventBusLayout.$emit("displayGroup", item);
			}, 1000);
		} else {
			EventBusLayout.$emit("displayGroup", item);
		}
		if (!this.displayButtonClicked) this.currentIndex = -1;
	}
}
