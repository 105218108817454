

































































import { groupBy } from "@/helpers/CalculationHelper";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  props: ["Packages", "IsADB", "PrimaryName", "SpouseName","IsSpouse"],
})
export default class DeathBenefit extends Vue {
  data() {
    return {
      PrimaryPackages: [],
      SpousePackages: [],
      DeathBenefitProduct: [],
    };
  }

  created() {
    this.OnPackagesChanged();
  }

  @Watch("Packages")
  OnPackagesChanged() {
    if (this.$props.Packages != undefined && this.$props.Packages.length > 0) {
      var benefitsGrouped = groupBy(this.$props.Packages, "Title");
      this.$data.DeathBenefitProduct = [];
      for (var prop in benefitsGrouped) {
        var product: any = {};
        product.Title = prop;
        benefitsGrouped[prop].forEach((element: any) => {
          if (element.IsSpouse) product.SpouseAmount = element.Coverage;
          else product.PrimaryAmount = element.Coverage;
          product.isADB = element.isADB;
          if (product.PrimaryAmount == undefined) product.PrimaryAmount = 0;
          if (product.SpouseAmount == undefined) product.SpouseAmount = 0;
        });

        this.$data.DeathBenefitProduct.push(product);
        //materials.push(groupedElements[prop][0]);
      }
      // this.$data.PrimaryPackages = this.$props.Packages.filter(
      //   (x: any) => !x.IsSpouse
      // );
      // this.$data.SpousePackages = this.$props.Packages.filter(
      //   (x: any) => x.IsSpouse == true
      // );
    }
  }
}
