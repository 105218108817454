var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
			_vm.PlanData.Plan.CompleteProduct &&
			(!_vm.PlanData.Plan.CompleteProduct.DependOnChild || _vm.HasChild)
		)?_c('v-row',{staticClass:"align-center mt-0 mx-0 mb-0 mb-xl-3 planrow",class:{
			productDisabled: !_vm.productavailable || _vm.PlanData.Plan.isProductDisabled,
			activeproduct:
				_vm.PlanData.Selected &&
				_vm.productavailable &&
				!_vm.PlanData.Plan.isProductDisabled,
		}},[_c('v-col',{staticClass:"py-2 pl-2",class:{
				'max-wd-4vw': _vm.truncateProperties,
				'max-wd-3vw': !_vm.truncateProperties,
			},attrs:{"cols":"1"}},[_c('v-checkbox',{staticClass:"mediumsvgbtn plangen product-add",attrs:{"dense":"","hide-details":"","disabled":!_vm.productavailable || _vm.PlanData.Plan.isProductDisabled},on:{"change":_vm.SelectionChanged,"click":function($event){return _vm.ClientSelectionChange(_vm.PlanData)}},model:{value:(_vm.PlanData.Selected),callback:function ($$v) {_vm.$set(_vm.PlanData, "Selected", $$v)},expression:"PlanData.Selected"}})],1),_c('v-col',{staticClass:"text-center p-relative py-2 px-1 text-truncate fw-900 max-wd-14vw md1--text"},[_c('div',{staticClass:"d-inline-block ln-intial product-name"},[_vm._v(" "+_vm._s(_vm.PGName)+" "),(_vm.showAnnualRate)?_c('span',{staticClass:"superSet error--text"},[_vm._v(_vm._s(_vm.$t("PlanGenerator.25DollarRate")))]):_vm._e()])]),_c('v-col',{staticClass:"text-center py-2 px-1 wd-10vw"},[(
					!_vm.PlanData.Plan.CompleteProduct ||
					_vm.PlanData.Plan.CompleteProduct.showCoverageField
				)?_c('v-menu',{staticStyle:{"max-height":"100px"},attrs:{"bottom":_vm.PlanData.CoverageMenuTop,"top":!_vm.PlanData.CoverageMenuTop,"content-class":"wagetypepopup","close-on-content-click":false,"offset-y":"","disabled":_vm.PlanData.Plan.CompleteProduct.ShowAutoCompleteDropdown == undefined ||
					_vm.PlanData.Plan.CompleteProduct.ShowAutoCompleteDropdown == false ||
					_vm.ShowCoverageItems == false ||
					_vm.PlanData.Plan.isAmountFieldDisabled ||
					!_vm.PlanData.Selected,"max-height":"200"},on:{"focus":_vm.CoverageFocus},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-text-field',{ref:"tfCoverage",staticClass:"materialgroup product-coverage",attrs:{"outlined":"","prefix":"$","dense":"","flat":"","hide-details":"","solo":"","maxlength":"11","disabled":_vm.PlanData.Plan.isAmountFieldDisabled || !_vm.PlanData.Selected,"readonly":_vm.PlanData.Plan.CompleteProduct != undefined &&
								_vm.PlanData.Plan.CompleteProduct.DisableAmountFields},on:{"keypress":function($event){return _vm.isNumberOnly($event, 'CoverageString')},"input":function($event){return _vm.CoverageChangeEvent(false)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.CoverageEnter($event)},"focus":_vm.CoverageFocus,"paste":_vm.onPasteCoverage,"blur":function($event){return _vm.CoverageUnfocus($event)}},model:{value:(_vm.CoverageString),callback:function ($$v) {_vm.CoverageString=$$v},expression:"CoverageString"}})],1)]}}],null,false,3026053467),model:{value:(_vm.isCoverageFocus),callback:function ($$v) {_vm.isCoverageFocus=$$v},expression:"isCoverageFocus"}},[_c('div',{staticClass:"white"},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.ListOfCoverageItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"pa-0",on:{"click":function($event){return _vm.OnCoverageAmountChange(item.Coverage)}}},[(item)?_c('div',{staticClass:"px-3 py-3 xs--text wd-100 cursor-pointer",class:{ base: item.Coverage == _vm.CoverageString },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.Coverage)+" ")]):_vm._e()])}),1)],1)]):_vm._e(),(
					_vm.PlanData.Plan.CompleteProduct &&
					_vm.PlanData.Plan.CompleteProduct.showCoverageDropdown
				)?_c('v-select',{staticClass:"materialgroup product-coverage",attrs:{"label":"Coverage","item-text":"Name","autocomplete":"","outlined":"","dense":"","flat":"","hide-details":"","solo":"","items":_vm.listOfCoverageOptions,"disabled":_vm.PlanData.Plan.isAmountFieldDisabled || !_vm.PlanData.Selected,"return-object":"","open-on-clear":""},on:{"change":_vm.CoverageOptionChange},model:{value:(_vm.PlanData.Plan.CompleteCoverageOption),callback:function ($$v) {_vm.$set(_vm.PlanData.Plan, "CompleteCoverageOption", $$v)},expression:"PlanData.Plan.CompleteCoverageOption"}}):_vm._e()],1),_c('v-col',{staticClass:"text-center py-2 pl-xl-5 px-1 planoptdiv min-wd-14vw",class:{
				productDisabled: _vm.DisableOptionField,
				'col-xl': _vm.isOnPopup,
				'col-xl-2': !_vm.isOnPopup,
			}},[(!_vm.PlanData.IsWHL && _vm.PlanData.Plan.CompleteProduct.ShowOption)?_c('v-select',{key:_vm.optiondropdownkey,staticClass:"materialgroup product-option",attrs:{"label":"Select","item-text":"Name","autocomplete":"","outlined":"","dense":"","flat":"","menu-props":{ 'content-class': 'customddm' },"hide-details":"","solo":"","items":_vm.listOfOptions,"return-object":"","open-on-clear":"","disabled":_vm.PlanData.Plan.CompleteProduct == null ||
					!_vm.PlanData.Plan.CompleteProduct.ShowOption ||
					_vm.PlanData.Plan.isAmountFieldDisabled ||
					!_vm.PlanData.Selected ||
					_vm.DisableOptionField},on:{"change":_vm.OptionChange},model:{value:(_vm.PlanData.Plan.CompleteOption),callback:function ($$v) {_vm.$set(_vm.PlanData.Plan, "CompleteOption", $$v)},expression:"PlanData.Plan.CompleteOption"}}):_vm._e(),(_vm.PlanData.Plan.CompleteOption != undefined)?_c('span',{staticClass:"product-option"},[_vm._v(_vm._s(_vm.PlanData.Plan.CompleteOption.PGNAME))]):_vm._e(),(_vm.PlanData.IsWHL)?_c('v-select',{ref:"optionDropDown",staticClass:"materialgroup product-option",attrs:{"label":"Select","item-text":"PGName","autocomplete":"","outlined":"","dense":"","flat":"","menu-props":{ 'content-class': 'customddm' },"hide-details":"","solo":"","items":_vm.listOfOptions,"return-object":"","open-on-clear":"","disabled":_vm.PlanData.Plan.CompleteProduct == null ||
					_vm.PlanData.Plan.isAmountFieldDisabled ||
					!_vm.PlanData.Selected},on:{"change":_vm.OptionChange},model:{value:(_vm.PlanData.Plan.CompleteOption),callback:function ($$v) {_vm.$set(_vm.PlanData.Plan, "CompleteOption", $$v)},expression:"PlanData.Plan.CompleteOption"}}):_vm._e()],1),(_vm.PlanData.Plan.CompleteProduct.PresentAs)?_c('v-col',{staticClass:"text-center py-2 px-1 fw-700 text-truncate plangenerator-spacing product-presentas"},[_vm._v(_vm._s(_vm.PlanData.Plan.CompleteProduct.PresentAs)+" ")]):_c('v-col',{staticClass:"text-center py-2 px-1 min-wd-0 planoptdiv"},[_c('div',{staticClass:"px-2 px-xl-4"},[_c('v-select',{staticClass:"materialgroup product-presentas",attrs:{"outlined":"","dense":"","menu-props":{ 'content-class': 'customddm' },"flat":"","items":_vm.PlanData.Plan.PresentAsOptions,"disabled":!_vm.PlanData.Selected,"item-text":"Name","hide-details":"","solo":"","placeholder":"select"},on:{"change":_vm.changePresentAs},model:{value:(_vm.PlanData.Plan.PresentAs),callback:function ($$v) {_vm.$set(_vm.PlanData.Plan, "PresentAs", $$v)},expression:"PlanData.Plan.PresentAs"}})],1)]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.PrioritizeDaily),expression:"!PrioritizeDaily"}],staticClass:"text-center py-2 px-1 max-wd-9vw"},[_c('v-text-field',{staticClass:"materialgroup product-weekly",attrs:{"outlined":"","dense":"","flat":"","hide-details":"","solo":"","prefix":"$","maxlength":"7","disabled":!_vm.PlanData.Selected || _vm.PlanData.Plan.isAmountFieldDisabled,"readonly":(_vm.PlanData.Plan.CompleteProduct != undefined &&
						_vm.PlanData.Plan.CompleteProduct.DisableAmountFields) ||
					_vm.PlanData.Plan.disableMBD},on:{"keypress":function($event){return _vm.isNumber($event)},"paste":_vm.onPasteAmount,"input":_vm.WeeklyChange,"blur":function($event){return _vm.AmountFieldBlur($event, 'DailyCharge')}},model:{value:(_vm.WeeklyCharge),callback:function ($$v) {_vm.WeeklyCharge=$$v},expression:"WeeklyCharge"}})],1),(_vm.PrioritizeDaily)?_c('v-col',{staticClass:"text-center py-2 px-1 max-wd-9vw"},[_c('v-text-field',{staticClass:"materialgroup product-daily",attrs:{"outlined":"","dense":"","flat":"","hide-details":"","solo":"","prefix":"$","maxlength":"7","disabled":!_vm.PlanData.Selected || _vm.PlanData.Plan.isAmountFieldDisabled,"readonly":(_vm.PlanData.Plan.CompleteProduct != undefined &&
						_vm.PlanData.Plan.CompleteProduct.DisableAmountFields) ||
					_vm.PlanData.Plan.disableMBD},on:{"paste":_vm.onPasteAmount,"keypress":function($event){return _vm.isNumber($event)},"input":_vm.DailyChange,"blur":function($event){return _vm.AmountFieldBlur($event, 'DailyCharge')}},model:{value:(_vm.DailyCharge),callback:function ($$v) {_vm.DailyCharge=$$v},expression:"DailyCharge"}})],1):_vm._e(),_c('v-col',{staticClass:"text-center py-2 px-1 max-wd-9vw"},[_c('v-text-field',{staticClass:"materialgroup product-mbd",attrs:{"outlined":"","prefix":"$","dense":"","flat":"","hide-details":"","solo":"","maxlength":"9","disabled":!_vm.PlanData.Selected || _vm.PlanData.Plan.isAmountFieldDisabled,"readonly":(_vm.PlanData.Plan.CompleteProduct != undefined &&
						_vm.PlanData.Plan.CompleteProduct.DisableAmountFields) ||
					_vm.PlanData.Plan.disableMBD},on:{"keypress":function($event){return _vm.isNumber($event, 'MDBString')},"paste":_vm.onPasteAmount,"input":_vm.MBDChangeEvent,"blur":function($event){return _vm.MBDUnfocus($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.MBDEnter($event)},"focus":_vm.MBDFocus},model:{value:(_vm.MDBString),callback:function ($$v) {_vm.MDBString=$$v},expression:"MDBString"}})],1),_c('v-col',{staticClass:"text-center py-2 px-1 max-wd-9vw"},[_c('v-text-field',{staticClass:"materialgroup product-ahp",attrs:{"outlined":"","dense":"","flat":"","hide-details":"","solo":"","prefix":"$","maxlength":"7","disabled":!_vm.PlanData.Selected || _vm.PlanData.Plan.isAmountFieldDisabled,"readonly":(_vm.PlanData.Plan.CompleteProduct != undefined &&
						_vm.PlanData.Plan.CompleteProduct.DisableAmountFields) ||
					_vm.PlanData.Plan.disableMBD},on:{"keypress":function($event){return _vm.isNumber($event)},"paste":_vm.onPasteAmount,"input":_vm.AnnualChange,"blur":function($event){return _vm.AmountFieldBlur($event, 'AnnualCharge')}},model:{value:(_vm.AnnualCharge),callback:function ($$v) {_vm.AnnualCharge=$$v},expression:"AnnualCharge"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }