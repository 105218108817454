











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

@Component({
  components: {
    Pie,
  },
})
export default class PieChart extends Vue {
  @Prop({ default: "" })
  bindedLicense!: string;

  @Prop({ default: "pie-chart" })
  private chartId!: any;

  @Prop({ default: 100 })
  private width!: any;

  @Prop({ default: 100 })
  private height!: any;

  @Prop({ default: "" })
  private cssClasses!: any;

  @Prop({ default: {} })
  private styles!: any;

  @Prop({ default: {} })
  private plugins!: any;

  @Prop({ default: {} })
  private ChartData: any;

  @Prop({ default: {} })
  private ShowLegend: boolean = false;

  // @Watch("ChartData")
  // OnChartDataChange(newValue: any) {
  //   if (this.$refs.pieChart != undefined) this.$refs.pieChart.update();
  // }

  @Watch("ShowLegend")
  OnShowLegendChange(newValue: boolean, oldValue: boolean) {
    if (this.$data.chartOptions != undefined) {
      this.$data.chartOptions = {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              boxWidth: 30,
              padding: 8,
              color: "#000",
              font: {
                family: "Open Sans",
                weight: 700,
                size: 14,
              },
            },
            position: "bottom",
            display: newValue,
          },
          tooltip: true,
        },
      };
    }
  }

  refs!: {
    pieChart: any;
  };

  data() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              boxWidth: 30,
              padding: 8,
              color: "#000",
              font: {
                family: "Open Sans",
                weight: 700,
                size: 14,
              },
            },
            position: "right",
            display: this.ShowLegend,
          },
          tooltip: true,
        },
      },
    };
  }
}
