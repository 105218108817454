import { ConvertIntoAmountFormatWithDecimal, ConvertIntoAmountFormatWithNegativeDecimal } from "@/helpers/CalculationHelper";

export default class PremiumPlan {

    private premiumTypeId: number = 1;

    public ShowDailyPremiumOptions!: boolean;
    public ShowMonthlyPremiumOptions!: boolean;
    public ShowDollarADayOptions!: boolean;
    public ShowNeedOptions!: boolean;

    public CombinedHPPremium: number = 0;
    private customHPPremium: string = "0.00";
    public combinedMonthlyPremium: number = 0;
    private customMonthlyPremium: string = "0.00";
    private dollarADayPremium: number = 0;
    private needPremium: number = 0;

    public FinalDailyPremium: number = 0;
    public FinalWeeklyPremium: number = 0;
    public FinalMonthlyPremium: number = 0;
    public FinalYearlyPremium: number = 0;

    private usedDailyPremium: number = 0;
    private usedWeeklyPremium: number = 0;
    private usedMonthlyPremium: number = 0;
    private usedYearlyPremium: number = 0;

    public RemainingDailyPremium: string = '0';
    public RemainingWeeklyPremium: string = '0';
    public RemainingMonthlyPremium: string = '0';
    public RemainingYearlyPremium: string = '0';

    public combinedTotalIncome: number = 0;

    private hPPremiumType: number = 1;
    private monthlyPremiumType: number = 1;

    public CustomDailyPremiumEnabled: boolean = false;
    public CustomMonthlyPremiumEnabled: boolean = false;

    public DollarADayOptions: any[] = [];
    public SpouseDollarADayOptions: any[] = [];
    public NeedOptions: any[] = [];

    public PrimaryDollarADay: any = 1;
    public SpouseDollarADay: any = 1;

    public get HPPremiumType(): number {
        return this.hPPremiumType;
    }
    public set HPPremiumType(value: number) {
        this.hPPremiumType = value;
        if (value == 1) {
            this.CustomDailyPremiumEnabled = false;
        }
        else {
            this.CustomDailyPremiumEnabled = true;
        }
        this.CalculateFinalPremium();
    }

    get MonthlyPremiumType(): number {
        return this.monthlyPremiumType;
    }
    set MonthlyPremiumType(value: number) {
        this.monthlyPremiumType = value;
        if (value == 1) {
            this.CustomMonthlyPremiumEnabled = false;
        }
        else {
            this.CustomMonthlyPremiumEnabled = true;
        }
        this.CalculateFinalPremium();
    }


    get PremiumTypeId() {
        return this.premiumTypeId;
    }
    set PremiumTypeId(value: number) {
        this.premiumTypeId = value;
        this.ShowPremiumOptionBySelectedPremium(value);
    }

    get CombinedHPPremiumString() {
        return ConvertIntoAmountFormatWithDecimal(this.CombinedHPPremium.toFixed(2), true, true);
    }

    get CustomHPPremium(): string {
        return this.customHPPremium;
    }
    set CustomHPPremium(value: string) {
        this.customHPPremium = value;
        this.CalculateFinalPremium();
    }

    get CombinedTotalIncome(): number {
        return this.combinedTotalIncome;
    }
    set CombinedTotalIncome(value: number) {
        this.combinedTotalIncome = value;
        this.CalculateFinalPremium();
    }

    get FinalDailyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.FinalDailyPremium.toFixed(2).toString(), true, true);

    }

    get FinalWeeklyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.FinalWeeklyPremium.toFixed(2).toString(), true, true);

    }
    get FinalMonthlyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.FinalMonthlyPremium.toFixed(2).toString(), true, true);
    }

    get FinalYearlyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.FinalYearlyPremium.toFixed(2).toString(), true, true);
    }

    get RemainingDailyPremiumString(): string {
        var mbdRem = Number(this.RemainingMonthlyPremium);
        if (mbdRem == 0)
            return "0.00";
        return ConvertIntoAmountFormatWithNegativeDecimal(Number(this.RemainingDailyPremium).toFixed(2).toString(), true, true);

    }

    get RemainingWeeklyPremiumString(): string {
        var mbdRem = Number(this.RemainingWeeklyPremium);
        if (mbdRem == 0)
            return "0.00";
        return ConvertIntoAmountFormatWithNegativeDecimal(Number(this.RemainingWeeklyPremium).toFixed(2).toString(), true, true);

    }

    get RemainingMonthlyPremiumString(): string {
        var mbdRem = Number(this.RemainingMonthlyPremium);
        if (mbdRem == 0)
            return "0.00";
        return ConvertIntoAmountFormatWithNegativeDecimal(Number(this.RemainingMonthlyPremium).toFixed(2).toString(), true, true);
    }


    get RemainingYearlyPremiumString(): string {
        var mbdRem = Number(this.RemainingMonthlyPremium);
        if (mbdRem == 0)
            return "0.00";

        return ConvertIntoAmountFormatWithNegativeDecimal(Number(this.RemainingYearlyPremium).toFixed(2).toString(), true, true);
    }

    get CombinedMonthlyPremiumString() {
        return ConvertIntoAmountFormatWithDecimal(Number(this.combinedMonthlyPremium).toFixed(2).toString(), true, true);
    }

    get CustomMonthlyPremium(): string {
        return this.customMonthlyPremium;
    }
    set CustomMonthlyPremium(value: string) {
        this.customMonthlyPremium = value;
        this.CalculateFinalPremium();
    }
    get DollarADayPremium(): number {
        return this.dollarADayPremium;
    }
    set DollarADayPremium(value: number) {
        this.dollarADayPremium = value;
        this.CalculateFinalPremium();
    }
    get NeedPremium(): number {
        return this.needPremium;
    }
    set NeedPremium(value: number) {
        this.needPremium = value;
        this.CalculateFinalPremium();
    }

    get UsedDailyPremium(): number {
        return this.usedDailyPremium;
    }
    set UsedDailyPremium(value: number) {
        this.usedDailyPremium = Number((value).toFixed(2));
        var remainingDaily = Number((this.FinalDailyPremium - this.UsedDailyPremium).toFixed(2));

        this.RemainingDailyPremium = remainingDaily.toFixed(2);
    }

    get UsedMonthlyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.usedMonthlyPremium.toFixed(2).toString(), true, true);
    }

    get UsedMonthlyPremium(): number {
        return this.usedMonthlyPremium;
    }
    get UsedYearlyPremium(): number {
        return this.usedYearlyPremium;
    }
    set UsedMonthlyPremium(value: number) {
        this.usedMonthlyPremium = Number((value).toFixed(2));
        var remainingMonthly = Number((this.FinalMonthlyPremium - this.UsedMonthlyPremium).toFixed(2));

        this.RemainingMonthlyPremium = remainingMonthly.toFixed(2);
        // if (remainingMonthly > 0)
        //     this.RemainingMonthlyPremium = remainingMonthly.toFixed(2);
        // else
        //     this.RemainingMonthlyPremium = "(" + ((-1) * remainingMonthly).toFixed(2) + ")";
    }
    set UsedYearlyPremium(value: number) {
        this.usedYearlyPremium = Number((value).toFixed(2));
        var remainingYearly = Number((this.FinalYearlyPremium - this.UsedYearlyPremium).toFixed(2));

        this.RemainingYearlyPremium = remainingYearly.toFixed(2);
    }

    get UsedDailyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.usedDailyPremium.toFixed(2).toString(), true, true);
    }


    get UsedWeeklyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.usedWeeklyPremium.toFixed(2).toString(), true, true);
    }

    get UsedYearlyPremiumString(): string {
        return ConvertIntoAmountFormatWithDecimal(this.usedYearlyPremium.toFixed(2).toString(), true, true);
    }

    get UsedWeeklyPremium(): number {
        return this.usedWeeklyPremium;
    }
    set UsedWeeklyPremium(value: number) {
        this.usedWeeklyPremium = Number((value).toFixed(2));
        var remainingWeekly = Number((this.FinalWeeklyPremium - this.usedWeeklyPremium).toFixed(2));

        this.RemainingWeeklyPremium = remainingWeekly.toFixed(2);
    }

    ShowPremiumOptionBySelectedPremium(premTypeId: number) {
        if (premTypeId == 1) {
            this.ShowDailyPremiumOptions = true;
            this.ShowMonthlyPremiumOptions = false;
            this.ShowDollarADayOptions = false;
            this.ShowNeedOptions = false;
        }
        else if (premTypeId == 2) {
            this.ShowDailyPremiumOptions = false;
            this.ShowMonthlyPremiumOptions = true;
            this.ShowDollarADayOptions = false;
            this.ShowNeedOptions = false;
        }
        else if (premTypeId == 3) {
            this.ShowDailyPremiumOptions = false;
            this.ShowMonthlyPremiumOptions = false;
            this.ShowDollarADayOptions = true;
            this.ShowNeedOptions = false;
        }
        else if (premTypeId == 4) {
            this.ShowDailyPremiumOptions = false;
            this.ShowMonthlyPremiumOptions = false;
            this.ShowDollarADayOptions = false;
            this.ShowNeedOptions = true;
        }

        this.CalculateFinalPremium();
    }


    CalculateFinalPremium() {


        switch (this.premiumTypeId) {
            case 1:
                if (this.HPPremiumType == 1)
                    this.AssignFinalValue(this.CombinedHPPremium / 7, this.CombinedHPPremium, this.CombinedHPPremium * 4.333);
                else if (this.HPPremiumType == 2)
                    this.AssignFinalValue(Number(this.customHPPremium.replace(',', '')) / 7, Number(this.customHPPremium.replace(',', '')), Number(this.customHPPremium.replace(',', '')) * 4.333);
                break;
            case 2:
                if (this.MonthlyPremiumType == 1)
                    this.AssignFinalValue((Number(this.combinedMonthlyPremium) / 4.333) / 7, Number(this.combinedMonthlyPremium) / 4.333, this.combinedMonthlyPremium);
                else if (this.MonthlyPremiumType == 2)
                    this.AssignFinalValue((Number(this.customMonthlyPremium.replace(',', '')) / 4.333) / 7, Number(this.customMonthlyPremium.replace(',', '')) / 4.333, Number(this.customMonthlyPremium.replace(',', '')));
                break;
            case 3:
                var montly = this.dollarADayPremium * 365 / 12;
                this.AssignFinalValue(this.dollarADayPremium, montly / 4.333, montly);
                break;
            case 4 || "4":
                var selectedNeedOption = this.NeedOptions.filter((x: any) => {
                    if (x.text == this.NeedPremium)
                        return x;
                })[0];
                var perMonth = (this.CombinedTotalIncome / 12) * (selectedNeedOption.percentage / 100);
                this.AssignFinalValue((perMonth / 4.333) / 7, perMonth / 4.333, perMonth);
                break;
        }

    }



    AssignFinalValue(dailyPrem: number, weeklyPrem: number, monthlyPrem: number) {
        this.FinalDailyPremium = Number(Number(dailyPrem).toFixed(2));
        this.FinalWeeklyPremium = Number(Number(weeklyPrem).toFixed(2));
        this.FinalMonthlyPremium = Number(Number(monthlyPrem).toFixed(2));
        this.FinalYearlyPremium = Number((this.FinalMonthlyPremium * 12).toFixed(2));

        this.RemainingMonthlyPremium = (this.FinalMonthlyPremium - this.usedMonthlyPremium).toFixed(2);
        this.RemainingYearlyPremium = (this.FinalYearlyPremium - this.usedYearlyPremium).toFixed(2);
        this.RemainingDailyPremium = (this.FinalDailyPremium - this.usedDailyPremium).toFixed(2);
        this.RemainingWeeklyPremium = (this.FinalWeeklyPremium - this.usedWeeklyPremium).toFixed(2);
    }

    round10(num: number) {
        return Math.ceil(num / 10) * 10;
    }

    constructor(personPremium: number, spousePremium: number, monthlyPersonPremium: number, monthlySpousePremium: number,
        dollarADayPrimary: any, dollarADaySpouse: any
        , isSpouseExists: boolean) {
        // this.CombinedHPPremium =
        //     (Number.parseFloat((personPremium + spousePremium).toFixed(2)));

        var hpValue = personPremium;
        if (isSpouseExists)
            hpValue += spousePremium;
        this.CombinedHPPremium =
            (Number.parseFloat(hpValue.toFixed(2)));


        //this.combinedMonthlyPremium = Number.parseFloat(((monthlyPersonPremium + monthlySpousePremium).toFixed(2)));

        var monthlyValue = monthlyPersonPremium;
        if (isSpouseExists)
            monthlyValue += monthlySpousePremium;
        this.combinedMonthlyPremium =
            (Number.parseFloat(monthlyValue.toFixed(2)));

        if (this.hPPremiumType != 2)
            this.CustomHPPremium = ConvertIntoAmountFormatWithDecimal(this.CombinedHPPremium.toFixed(2), true, true);

        this.CustomMonthlyPremium = ConvertIntoAmountFormatWithDecimal(this.round10(this.combinedMonthlyPremium).toFixed(2), true, true);
        this.PremiumTypeId = 1;

        var completeDollarADayOptions = require("../assets/pre-fill-data/dollar-a-day-options.json");
        this.DollarADayOptions = completeDollarADayOptions.filter((x: any) => {
            if (x.ShowOnlyinSpouse == undefined || x.ShowOnlyinSpouse.false) return true;
        })

        this.SpouseDollarADayOptions = completeDollarADayOptions;
        this.NeedOptions = require("../assets/pre-fill-data/need-options.json");

        var dollarADayCombAmt = dollarADayPrimary;

        if (isSpouseExists)
            dollarADayCombAmt += dollarADaySpouse;

        this.PrimaryDollarADay = dollarADayPrimary;
        this.SpouseDollarADay = dollarADaySpouse;

        this.DollarADayPremium = dollarADayCombAmt;
        this.NeedPremium = this.NeedOptions[0].text;
    }

    public ChangePremiumPlan(personPremium: number, spousePremium: number, isSpouseExists: boolean) {

        var hpValue = personPremium;
        if (isSpouseExists)
            hpValue += spousePremium;
            
            if(hpValue == null){
                hpValue = 0;
            }

        this.CombinedHPPremium =
            (Number.parseFloat(hpValue.toFixed(2)));
        if (this.hPPremiumType != 2)
            this.CustomHPPremium = ConvertIntoAmountFormatWithDecimal(this.CombinedHPPremium.toFixed(2), true, true);
    }

    public ChangePremiumPlanMonthly(personPremium: number, spousePremium: number, isSpouseExists: boolean) {
        var monthlyValue = personPremium;
        if (isSpouseExists)
            monthlyValue += spousePremium;

            if(monthlyValue == null){
                monthlyValue = 0;
            }

        this.combinedMonthlyPremium =
            (Number.parseFloat(monthlyValue.toFixed(2)));
        if (this.MonthlyPremiumType != 2)
            this.CustomMonthlyPremium = ConvertIntoAmountFormatWithDecimal(this.round10(this.combinedMonthlyPremium).toFixed(2), true, true);
    }

    public ChangeDollarADay(personDOD: number, spouseDOD: number, isSpouseExists: boolean) {
        var dollarADayCombAmt = personDOD;

        this.PrimaryDollarADay = personDOD;
        this.SpouseDollarADay = spouseDOD;

        if (isSpouseExists)
            dollarADayCombAmt += spouseDOD;

        this.DollarADayPremium = dollarADayCombAmt;
    }
}
