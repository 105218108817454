















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import PrimaryPerson from "../entities/PrimaryPerson";
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import MenuComponent from "../components/menu.vue";
import MedicalQuestions from "../components//popups/MedicalQuestions.vue";
import FuneralExpense from "../components/popups/FuneralExpense.vue";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import Presentation from "../entities/Presentation";
import PerProcedureCancerComponent from "../components/BenefitSummary/PerProcedureCancer.vue";
import LumpSumCancerComponent from "../components/BenefitSummary/LumpSumCancer.vue";
import CashValueComponent from "../components/BenefitSummary/CashValue.vue";
import PaidBenefitComponent from "../components/BenefitSummary/PaidBenefit.vue";
import TerminalIllnessComponent from "../components/BenefitSummary/TerminalIllness.vue";
import DeathBenefitComponent from "../components/BenefitSummary/DeathBenefit.vue";
import StrikeWaiverComponent from "../components/BenefitSummary/StrikeWaiver.vue";
import LayOffWaiverComponent from "../components/BenefitSummary/LayOffWaiver.vue";
import BenefitGuranteedComponent from "../components/BenefitSummary/BenefitGuranteed.vue";
import GenericKeyPair from "../models/GenericKeyPair";
import { getPersistedState, setPersistedState } from "../helpers/StateMapper";
import PlanSummary from "../entities/PlanSummary";
import { ChangeDayOptionWithLanguage } from "../helpers/UIHelper";
import {
	CalculateBenefits,
	LoadBenefitSummaryAsPerPlan,
} from "../helpers/CalculationHelper";
import { PlanCategoryType } from "../enumerations/PlanCategoryType";
import { BenefitsSummaryPopupType } from "../enumerations/BenefitsSummaryPopupType";
import { EntityHelper } from "../helpers/EntityHelper";
import PremiumPlanEntity from "../entities/PremiumPlanEntity";
let listofPresentationType = require("../assets/pre-fill-data/presentation-types.json");
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

import UWPopup from "../components/popups/UWPopup.vue";
import User from "@/entities/User";
import { changeLocale } from "@/plugins/i18n";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import Spouse from "@/entities/Spouse";
import { PresentationType } from "@/enumerations/PresentationType";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
import {
	GetAscFullName,
	GetAscFullNameWithCamelCase,
} from "@/helpers/APIExtentions";
import { gsap } from "gsap";
import LoggingHelper from "@/helpers/LoggingHelper";
import LoggingConstants from "@/constants/LoggingConstants";
import ReferralPopup from "../components/popups/ReferralPopup.vue";
import moment from "moment";
import { EventBusLayout } from "../bus/EventBusLayout";
import FreedomOfChoiceComponent from "@/components/popups/FreedomOfChoice.vue";
import VeteranThreeImportantFactsSMBPdfHtml from "@/components/popups/VeteranThreeImportantFactsSMBPdfHtml.vue";
import PresentationWillKitVeterans from "@/entities/PresentationWillKitVeterans";

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		PerProcedureCancerComponent,
		LumpSumCancerComponent,
		CashValueComponent,
		PaidBenefitComponent,
		TerminalIllnessComponent,
		DeathBenefitComponent,
		StrikeWaiverComponent,
		LayOffWaiverComponent,
		BenefitGuranteedComponent,
		MedicalQuestions,
		UWPopup,
		videoPlayer,
		ReferralPopup,
		FreedomOfChoiceComponent,
		FuneralExpense,
		VeteranThreeImportantFactsSMBPdfHtml,
	},
})
export default class BenefitsSummary extends Vue {
	private Person: any;
	private Presentation: any;
	private eappimage: boolean = false;
	private protectionopt: boolean = false;
	private funeralexpense: boolean = false;
	private showVeteranThreeImportantFactsSMBPDF: boolean = false;
	private showVideo: boolean = false;
	private showVimeoVideo: boolean = false;
	private superComboUrl: string = "";
	private chequeimg: boolean = false;
	private chequeimghouse: boolean = false;
	private freedomchoice: boolean = false;
	private medicalquestion: boolean = false;
	private showuw: boolean = false;
	private report: boolean = false;
	private insurancePopupOpt: boolean = false;
	private errorClass: string = "";
	private bsRates: any = null;
	private lstStates: any = null;
	private pageLoadedTime: any;
	private BenefitsSummaryPopupType: any = {};
	private SelectedBenefitsSummaryPopupType: any =
		BenefitsSummaryPopupType.PerProcedureCancer;
	private PopupTitle: string = "";
	private ispreplan: boolean = false;
	private ispresentplan: boolean = false;
	private CementingPopup: boolean = false;

	private BenefitsPrimarySummary: PlanSummary = new PlanSummary();
	private BenefitsSpouseSummary: PlanSummary = new PlanSummary();
	private BenefitsChildrenSummary: PlanSummary = new PlanSummary();
	private backgroundimage: string = "";
	private beneficiaryShown: Boolean = false;
	private DeathBenefit: any = [];
	private loaded: Boolean = false;
	private isDollarADay: boolean = false;
	private ssprogram: boolean = false;
	private somemodal: boolean = false;
	private browserCloseEvent: boolean = false;
	private ResetReferrals: boolean = false;
	private IsVeteranType: boolean = false;
	private IsVeteranSMBAlphaType: boolean = false;
	private IsMcGruffType: boolean = false;
	private IsPOSType: boolean = false;
	private IsUnionType: boolean = false;
	private IsAssociationType: boolean = false;
	private IsGlobeLifeType: boolean = false;
	private IsServiceMemberBenefitsType: boolean = false;
	private IsServiceMemberBenefitsSVLAlphaType: boolean = false;
	private IsServiceMemberBenefitsVFLAlphaType: boolean = false;
	private CountryIdFromPreplan: number = 1;
	private PresentationWillKitVeterans: any;
	private custompdfhtml: boolean = false;
	private _keydownCallback: any;

	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;

	$refs!: {
		perprocpopup: any;
		lumsumpopup: any;
		menubar: any;
		beneficiaryName: any;
		popup_container: any;
		chequeWindow1: any;
		chequeWindow2: any;
		chequeWindow3: any;
		chequeWindow4: any;
		chequeLeftBtn1: any;
		chequeRightBtn1: any;
		chequeLeftBtn2: any;
		chequeRightBtn2: any;
		chequeLeftBtn3: any;
		chequeRightBtn3: any;
		chequeLeftBtn4: any;
		chequeRightBtn4: any;
		medQuestionsPopup: any;
		funeralExpensePopup: any;
		videoPlayer: any;
		itemRowFOCFirst: any;
		VeteranThreeImportantFactsSMBComp: any;
	};
	data() {
		return {
			ops: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			Referrals: {},
			ERBIndex: 0,
			DHBIndex: 0,
			ICBIndex: 0,
			AnyCauseIndex: 0,
			AccidentIndex: 0,
			AutoAccidentIndex: 0,
			CommonCarrierIndex: 0,
			MonthlyIncomeIndex: 0,
			MonthlyIncomeADBIndex: 0,
			animationDivs: [],
			animationDivsList: [],
			UserName: "",
			beneficiaryClass: "d-none",
			medicalquestions: [],
			Plans: [],
			CurrentSelectedPlan: {},
			BenefitSummary: {},
			selected: undefined,
			errorClass: "",
			tab: null,
			monthlytab: 0,
			monthlytab2: 0,
			PrimarySalaryInWords: "",
			SpouseSalaryInWords: "",
			Required: [(v: any) => !!v || "Field is required"],
			SelectedDeathBenefit: {},
			playerOptions: {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: "static/ailvideo.mp4",
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			},
		};
	}

	closeMedical(medicalQuestions: any) {
		this.$data.medicalquestions = medicalQuestions;
		this.medicalquestion = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}

	emptyonnull(value: any) {
		return value == null || value == undefined ? "" : value;
	}

	ChangeDayOptionWithLanguageUI(value: string) {
		return ChangeDayOptionWithLanguage(value);
	}

	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}

	ShowPopup(popupType: BenefitsSummaryPopupType, deathBenefit: any = null) {
		switch (popupType) {
			case BenefitsSummaryPopupType.PerProcedureCancer:
				this.PopupTitle = "Per Procedure Cancer";
				break;
			case BenefitsSummaryPopupType.LumpSumCancer:
				this.PopupTitle = "Lump Sum Cancer";
				break;
			case BenefitsSummaryPopupType.CashValue:
				this.PopupTitle = "Cash Value";
				break;
			case BenefitsSummaryPopupType.PaidUpBenefits:
				this.PopupTitle = "Paid Up Benefits";
				break;
			case BenefitsSummaryPopupType.TerminalIllnessRider:
				this.PopupTitle = "Terminal IllnessRider";
				break;
			case BenefitsSummaryPopupType.StrikeWaiver:
				this.PopupTitle = "Strike Waiver";
				break;
			case BenefitsSummaryPopupType.LayoffWaiver:
				this.PopupTitle = "Lay off Waiver";
				break;
			case BenefitsSummaryPopupType.BenefitsGuarenteed:
				this.PopupTitle = "Benefits Guarenteed";
				break;
			case BenefitsSummaryPopupType.DeathBenefit:
				if (
					deathBenefit != null &&
					deathBenefit != undefined &&
					deathBenefit.length > 0
				) {
					this.DeathBenefit = deathBenefit;
					// var primaryAmount = "0.00";
					// var prim = deathBenefit.find((x: any) => !x.IsSpouse);
					// var isADB = false;
					// if (prim) {
					//   primaryAmount = prim.Coverage;
					//   isADB = prim.Title.includes("ADB");
					// }
					// var spouseAmount = "0.00";
					// var spouse = deathBenefit.find((x: any) => x.IsSpouse == true);
					// if (spouse) {
					//   spouseAmount = spouse.Coverage;
					//   isADB = spouse.Title.includes("ADB");
					// }
					// this.$data.SelectedDeathBenefit = {
					//   PrimaryAmount: primaryAmount,
					//   SpouseAmount: spouseAmount,
					//   IsADB: isADB,
					// };
				}
				this.PopupTitle = "Death Benefits";
				break;
		}
		this.insurancePopupOpt = true;
		this.SelectedBenefitsSummaryPopupType = popupType;
		LoggingHelper.getInstance().LogEvent(this.PopupTitle + " Popup opened");
	}

	showCheque() {
		this.chequeimg = true;
		this.$data.monthlytab = 0;
		if (this.$refs.chequeWindow1 != undefined) {
			this.$refs.chequeWindow1.updateMultiple();
		}
		if (this.$refs.chequeWindow2 != undefined) {
			this.$refs.chequeWindow2.updateMultiple();
		}
	}

	async RoutedTo(route: String, routeobj: any = null) {
		if (route == "/ssprogram") {
			this.ssprogram = true;
			this.$store.state.OpenedPopupName = "ssprogram";
			this.$nextTick(function () {
				this.OnReferralPopupVisibilityChange(false, false);
			});
			var svview = document.getElementsByClassName("formscroll");
			if (svview != undefined && svview.length > 0) {
				{
					setTimeout(() => {
						svview[0].scrollIntoView(true);
					}, 100);
				}
			}
			return;
		}

		window.removeEventListener("beforeunload", this.onCloseBrowser);
		if (routeobj == null) {
			// if (this.browserCloseEvent) {
			// 	this.browserCloseEvent = false;
			// 	window.removeEventListener("beforeunload", this.onCloseBrowser);
			// 	this.$router.push({
			// 		name: "FinishPresentation",
			// 		params: { showWhatHappenError: "true" },
			// 	});
			// } else {
			// 	this.$router.push(route.toString());
			// }
		} else {
			this.$route.params.presentplan = "true";
			this.$store.state.PresentPlanParam = true;
			this.loadbenefitssummary();
			this.loadmounted();
		}
		if (this.ispresentplan) {
			this.Presentation.isPresentPlanCompleted = true;
			this.Presentation.TimeOnPresentPlan = (
				(parseInt(this.Presentation.TimeOnPresentPlan) || 0) +
				Math.abs(Date.now() - this.pageLoadedTime)
			).toString();
		} else {
			this.Presentation.isBenefitsSummaryCompleted = true;
			this.Presentation.TimeOnBenefitSummary = (
				(parseInt(this.Presentation.TimeOnBenefitSummary) || 0) +
				Math.abs(Date.now() - this.pageLoadedTime)
			).toString();
		}
		if (this.Presentation.CurrentStepCompleted <= 3) {
			this.Presentation.CurrentStepCompleted = 3;
		}
		if (
			this.$refs.medQuestionsPopup &&
			this.$refs.medQuestionsPopup.questions
		) {
			this.Presentation.BSMedicalQuestions = JSON.stringify(
				this.$refs.medQuestionsPopup.questions
			);
		} else if (this.$data.medicalquestions) {
			this.Presentation.BSMedicalQuestions = JSON.stringify(
				this.$data.medicalquestions
			);
		}

		if (this.$data.Plans != undefined && this.$data.Plans.length > 0) {
			this.Presentation.PlanOptions = JSON.stringify(this.$data.Plans);
		}

		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});

		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});

		let saveData = new GenericKeyPair(
			"CurrentPresentation",
			Presentation.query().withAllRecursive().last()
		);
		await setPersistedState(saveData);

		if (this.browserCloseEvent) {
			this.browserCloseEvent = false;
			window.removeEventListener("beforeunload", this.onCloseBrowser);
			this.$router.push({
				name: "FinishPresentation",
				params: { showWhatHappenError: "true" },
			});
		} else {
			if (route == "") return;
			else this.$router.push(route.toString());
		}
	}

	Action(action: String) {
		switch (action) {
			case "underwriting":
				this.showuw = true;
				this.$store.state.OpenedPopupName = "underwriting";
				break;
			case "medical":
				this.medicalquestion = true;
				this.$store.state.OpenedPopupName = "medical";
				break;
			case "eappsummary":
				this.eappimage = true;
				this.$store.state.OpenedPopupName = "eappsummary";
				break;
			case "beneficiary":
				this.ShowHideBeneficiary();
				break;
			case "protectionopt":
				this.protectionopt = true;
				this.$store.state.OpenedPopupName = "protectionopt";
				break;
			case "funeralexpense":
				this.funeralexpense = true;
				this.$store.state.OpenedPopupName = "funeralexpense";
				break;
			case "threeimportantfacts":
				if (
					this.Presentation != undefined &&
					this.Presentation.PresentationWillKitVeterans != "" &&
					this.Presentation.PresentationWillKitVeterans != undefined
				) {
					this.PresentationWillKitVeterans = JSON.parse(
						this.Presentation.PresentationWillKitVeterans
					);
				} else {
					this.Presentation.PresentationWillKitVeterans = JSON.stringify(
						new PresentationWillKitVeterans()
					);
					this.PresentationWillKitVeterans = JSON.parse(
						this.Presentation.PresentationWillKitVeterans
					);
				}
				this.showVeteranThreeImportantFactsSMBPDF = true;
				this.custompdfhtml = true;
				this.$store.state.OpenedPopupName = "threeimportantfacts";
				break;
			case "benefitssummary":
				if (this.ispresentplan) {
					this.Presentation.isPresentPlanCompleted = true;
					this.Presentation.TimeOnPresentPlan = (
						(parseInt(this.Presentation.TimeOnPresentPlan) || 0) +
						Math.abs(Date.now() - this.pageLoadedTime)
					).toString();
					Presentation.update({
						where: this.Presentation.Id,
						data: this.Presentation,
					});
				}

				this.loadbenefitssummary(false);
				this.loadmounted();
				EventBusLayout.$emit("PresentPlanCheck", false);
				// this.$refs.menubar.loadicons(false);
				break;
			case "cementingvideo":
				this.CementingPopup = true;
				this.$store.state.OpenedPopupName = "cementingvideo";
				break;
		}
	}

	get IsVeteranSMBAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId == PresentationType.Veteran &&
			this.Presentation.PresentationSubTypeId ==
				PresentationSubType.ServiceMemberBenefitsLeadAlpha
		) {
			return true;
		}
		return false;
	}

	get IsServiceMemberBenefitsAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId ==
				PresentationType.ServiceMemberBenefits &&
			(this.Presentation.PresentationSubTypeId ==
				PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.SMBVeteranFamilyLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.Civilian ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitNotification)
		) {
			return true;
		}
		return false;
	}

	get ShowAccidentRow() {
		if (
			this.Presentation != undefined &&
			(this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) &&
			this.$data.CurrentSelectedPlan != null
		) {
			if (
				this.$data.BenefitSummary.focaccidentalPrimary != "" &&
				this.$data.BenefitSummary.focaccidentalPrimary != "--"
			) {
				return true;
			}
			if (
				this.$data.BenefitSummary.ShowSpouse &&
				this.$data.BenefitSummary.focaccidentalSpouse &&
				this.$data.BenefitSummary.focaccidentalSpouse != "--"
			) {
				return true;
			}
			if (
				this.$data.BenefitSummary.ShowChildren &&
				this.$data.BenefitSummary.focaccidentalChildren &&
				this.$data.BenefitSummary.focaccidentalChildren != "--"
			) {
				return true;
			}
			return false;
		}
		return true;
	}
	get ShowAutoAccidentRow() {
		if (
			this.Presentation != undefined &&
			(this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) &&
			this.$data.CurrentSelectedPlan != null
		) {
			if (
				this.$data.BenefitSummary.focautoPrimary != "" &&
				this.$data.BenefitSummary.focautoPrimary != "--"
			) {
				return true;
			}
			if (
				this.$data.BenefitSummary.ShowSpouse &&
				this.$data.BenefitSummary.focautoSpouse &&
				this.$data.BenefitSummary.focautoSpouse != "--"
			) {
				return true;
			}
			if (
				this.$data.BenefitSummary.ShowChildren &&
				this.$data.BenefitSummary.focautoChildren &&
				this.$data.BenefitSummary.focautoChildren != "--"
			) {
				return true;
			}
			return false;
		}
		return true;
	}
	get ShowCommonCarrierRow() {
		if (
			this.Presentation != undefined &&
			(this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) &&
			this.$data.CurrentSelectedPlan != null
		) {
			if (
				this.$data.BenefitSummary.foccommmoncarrierPrimary != "" &&
				this.$data.BenefitSummary.foccommmoncarrierPrimary != "--"
			) {
				return true;
			}
			if (
				this.$data.BenefitSummary.ShowSpouse &&
				this.$data.BenefitSummary.foccommmoncarrierSpouse &&
				this.$data.BenefitSummary.foccommmoncarrierSpouse != "--"
			) {
				return true;
			}
			if (
				this.$data.BenefitSummary.ShowChildren &&
				this.$data.BenefitSummary.foccommmoncarrierChildren &&
				this.$data.BenefitSummary.foccommmoncarrierChildren != "--"
			) {
				return true;
			}
			return false;
		}
		return true;
	}

	get player() {
		return this.$refs.videoPlayer.player;
	}

	loadVideo(type: string) {
		var url = "";

		if (type == "supercombo") {
			this.superComboUrl =
				"https://player.vimeo.com/video/520035664/?h=c131a61fa1&autoplay=1&loop=1&autopause=0";
			if (this.Presentation != null && !this.ispreplan) {
				if (this.Presentation.CountryId == 2) {
					this.superComboUrl =
						"https://player.vimeo.com/video/520035203?h=c5d5d7ee07&autoplay=1&loop=1&autopause=0";
				}
			} else if (this.ispreplan) {
				if (this.CountryIdFromPreplan == 2) {
					this.superComboUrl =
						"https://player.vimeo.com/video/520035203?h=c5d5d7ee07&autoplay=1&loop=1&autopause=0";
				}
			}
			this.showVimeoVideo = true;
		} else {
			url = process.env.VUE_APP_SENIOR_VIDEO;
			this.$data.playerOptions = {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: url,
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			};
			this.showVideo = true;
		}
	}

	ShowHideBeneficiary() {
		if (this.beneficiaryShown) {
			this.beneficiaryShown = false;
			this.$store.state.OpenedPopupName = "";
			EventBusLayout.$emit("CloseSubMenuPopups");
			this.$data.beneficiaryClass = "slide-out-top";
			setTimeout(() => {
				this.$data.beneficiaryClass = "d-none";
			}, 500);
		} else {
			this.beneficiaryShown = true;
			this.$store.state.OpenedPopupName = "beneficiary";
			this.$data.beneficiaryClass = "slide-in-top";

			setTimeout(() => {
				this.$data.beneficiaryClass = "";
			}, 500);
		}
	}

	LoadMedQues() {
		GetLocalDataOrCallAPI(
			ApiEndPointConstants.GeneralMedicalQuestionEndpoint,
			[],
			IndexDBConstants.GeneralMedicalQuestionKey
		).then((medQs: any) => {
			if (medQs != null) {
				var result = medQs.reduce(function (r: any, e: any) {
					r[e.QuestionType] = medQs.filter(
						(x: any) => x.QuestionType == e.QuestionType
					);
					return r;
				}, {});
				result.senior = result.Senior.reduce(function (r: any, e: any) {
					r[e.Section] = result.Senior.filter(
						(x: any) => x.Section == e.Section
					);
					return r;
				}, {});
				result.supercombo = result.SuperCombo.reduce(function (r: any, e: any) {
					r[e.Section] = result.SuperCombo.filter(
						(x: any) => x.Section == e.Section
					);
					return r;
				}, {});
				this.$data.medicalquestions = JSON.parse(JSON.stringify(result));
			}
		});
	}
	closeFreedomChoice() {
		this.freedomchoice = false;
	}

	created() {
		window.removeEventListener("beforeunload", this.onCloseBrowser);
		if (!this.ispreplan) {
			window.addEventListener("beforeunload", this.onCloseBrowser);
		}

		// changeLocale('es');
		this.$store.state.loader = true;
		this.Person = new PrimaryPerson();
		this.Person.Spouse = new PrimaryPerson(); // PrimaryPerson.query().withAllRecursive().last();
		this.Presentation = new Presentation(); //.query().withAllRecursive().last();
		EventBusLayout.$on("RoutedToEvt", this.BenefitSummaryRoutedToEvt);
		EventBusLayout.$on("Action", this.BenefitSummaryAction);
		EventBusLayout.$on("RouteWithParams", this.BenefitSummaryRouteWithParams);
		var bsRatesPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.BenefitSummaryRatesEndpoint,
			[],
			IndexDBConstants.BenefitSummaryRatesKey
		);

		var stateListPromise = GetLocalDataOrCallAPI(
			ApiEndPointConstants.StateListEndpoint,
			[],
			IndexDBConstants.StateListKey
		);

		this.$data.UserName = GetAscFullNameWithCamelCase();
		this.FetchMaterialForReferral();
		var self = this;
		Promise.all([bsRatesPromise, stateListPromise]).then((values) => {
			self.bsRates = values[0];
			self.lstStates = values[1];
			this.loadbenefitssummary();
			this._keydownCallback = (event: any) => {
				if (event.code == "Escape") {
					if (this.showVeteranThreeImportantFactsSMBPDF) {
						this.CloseThreeImportantFactsSMB();
					}
					this.chequeimg = false;
					this.chequeimghouse = false;
				}

				if (this.chequeimg || this.chequeimghouse) {
					if (event.code == "ArrowLeft") {
						if (this.$refs.chequeLeftBtn1)
							this.$refs.chequeLeftBtn1.$el.click();
						if (this.$refs.chequeLeftBtn2)
							this.$refs.chequeLeftBtn2.$el.click();
						if (this.$refs.chequeLeftBtn3)
							this.$refs.chequeLeftBtn3.$el.click();
						if (this.$refs.chequeLeftBtn4)
							this.$refs.chequeLeftBtn4.$el.click();
					}

					if (event.code == "ArrowRight") {
						if (this.$refs.chequeRightBtn1)
							this.$refs.chequeRightBtn1.$el.click();
						if (this.$refs.chequeRightBtn2)
							this.$refs.chequeRightBtn2.$el.click();
						if (this.$refs.chequeRightBtn3)
							this.$refs.chequeRightBtn3.$el.click();
						if (this.$refs.chequeRightBtn4)
							this.$refs.chequeRightBtn4.$el.click();
					}
					return;
				}
				if (!this.$data.animationDivs) return;

				if (this.protectionopt == true) return;

				if (this.showVeteranThreeImportantFactsSMBPDF == true) return;

				if (
					this.$refs.beneficiaryName == undefined ||
					this.$refs.beneficiaryName.isFocused != true
				) {
					if (
						event.code == "ArrowUp" ||
						event.code == "Delete" ||
						event.code == "Backspace"
					) {
						if (this.$data.currentIndex < 0) {
							this.$data.currentIndex = 0;
							return;
						}

						if (this.$data.currentIndex > this.$data.animationDivs.length - 1) {
							this.$data.currentIndex = this.$data.animationDivs.length - 1;
						}

						var curitem = this.$data.animationDivs[this.$data.currentIndex];
						if (curitem) {
							this.$data.animationDivsList[this.$data.currentIndex].show =
								false;
							this.$data.currentIndex--;
						}
					} else {
						if (this.$data.currentIndex < 0) {
							this.$data.currentIndex = 0;
						}
						if (this.$data.currentIndex > this.$data.animationDivs.length - 1) {
							return;
						}
						var curitem = this.$data.animationDivs[this.$data.currentIndex];
						if (curitem) {
							//curitem.classList.add("show");
							this.$data.animationDivsList[this.$data.currentIndex].show = true;
							this.$data.currentIndex++;
						}
					}
				}
			};
			document.addEventListener("keyup", this._keydownCallback);
			//window.addEventListener('resize', reportWindowSize);
			//window.addEventListener("resize", this.onBodyResize);
			this.loadmounted();
			this.$store.state.loader = false;
		});
	}

	onCloseBrowser(event: any) {
		this.browserCloseEvent = true;
		var CurrentRoute = this.$router.currentRoute.fullPath;
		if (
			CurrentRoute == "/needanalysis" ||
			CurrentRoute == "/finishpresentation" ||
			CurrentRoute == "/plangenerator" ||
			CurrentRoute == "/benefitssummary" ||
			CurrentRoute == "/nocostbenefits" ||
			CurrentRoute == "/report"
		) {
			if (CurrentRoute != "/finishpresentation") {
				// this.$router.push({
				//     name: "FinishPresentation",
				//     params: { showWhatHappenError: "true" },
				//   });
				this.RoutedTo("/finishpresentation");
			}
			event.preventDefault();
			event.returnValue = "";
		}
	}
	BenefitSummaryRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	BenefitSummaryAction(action: any) {
		this.Action(action);
	}
	BenefitSummaryRouteWithParams(obj: any) {
		this.RoutedTo("", obj);
	}
	beforeDestroy() {
		EventBusLayout.$off("RoutedToEvt", this.BenefitSummaryRoutedToEvt);
		EventBusLayout.$off("RouteWithParams", this.BenefitSummaryRouteWithParams);
		EventBusLayout.$off("Action", this.BenefitSummaryAction);
		document.removeEventListener("keyup", this._keydownCallback);
	}

	private allProducts: any = {};
	private allMatchingproducts: any = [];

	FetchMaterialForReferral() {
		getPersistedState("Materials").then((res: any) => {
			this.allProducts = res;
			this.allMatchingproducts = this.allProducts
				.filter(
					(x: any) =>
						(x.CountryId == undefined ||
							x.CountryId == this.Presentation.CountryId) &&
						(x.PresentationTypeId == "" ||
							x.PresentationTypeId == this.Presentation.PresentationTypeId)
				)
				.sort((a: any, b: any) => a.priority - b.priority);
		});
	}

	@Watch("ssprogram")
	OnReferralPopupVisibilityChange(newValue: boolean, oldValue: boolean) {
		var RefInit: any = {};
		if (this.Presentation.Referrals) {
			RefInit = JSON.parse(this.Presentation.Referrals);
		} else {
			RefInit = {
				ReferredBy: "",
				Organization: "",
				Phone: "",
				Email: "",
				Date: "",
				ReferralsList: [],
				CCEmail: [],
			};
		}
		if (RefInit.ReferralsList.length > 0) {
			RefInit.ReferralsList.forEach((referral: any) => {
				referral.StateObj = this.lstStates.find(
					(x: any) => x.StateCode == referral.State
				);
			});
		}
		if (RefInit.ReferredBy == undefined || RefInit.ReferredBy == "")
			RefInit.ReferredBy = this.Person?.FirstName;

		if (
			RefInit.ReferredByLastName == undefined ||
			RefInit.ReferredByLastName == ""
		)
			RefInit.ReferredByLastName = this.Person?.LastName;
		if (RefInit.Phone == undefined || RefInit.Phone == "")
			RefInit.Phone = this.Person?.Phone;
		if (RefInit.Email == undefined || RefInit.Email == "")
			RefInit.Email = this.Person?.Email;
		if (
			this.Presentation.PresentedGroup != undefined &&
			this.Presentation.PresentedGroup != ""
		)
			RefInit.Organization = this.Presentation.PresentedGroup;
		if (RefInit.Date == undefined || RefInit.Date == "")
			RefInit.Date = moment(new Date()).format("MM/DD/YYYY");

		this.$data.Referrals = RefInit;
		if (newValue == true) {
			this.ResetReferrals = !this.ResetReferrals;
		}
	}

	CloseReferralPopup() {
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}

	isEmpty(referral: any) {
		if (referral.FirstName != null && referral.FirstName.trim() != "")
			return false;

		if (referral.LastName != null && referral.LastName.trim() != "")
			return false;

		if (referral.City != null && referral.City.trim() != "") return false;

		if (referral.State != null && referral.State.trim() != "") return false;

		if (referral.Phone != null && referral.Phone.trim() != "") return false;

		if (referral.RelToSponsor != null && referral.RelToSponsor.trim() != "")
			return false;

		if (referral.Occupation != null && referral.Occupation.trim() != "")
			return false;

		if (
			referral.SignificantOther != null &&
			referral.SignificantOther.trim() != ""
		)
			return false;

		return true;
	}

	SaveReferral(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
		this.ssprogram = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	SaveReferralsWithoutClosingPopup(ref: any) {
		if (ref != undefined) {
			ref = JSON.parse(ref);
			var nonEmptyReferrals: any = [];
			ref.ReferralsList.forEach((element: any) => {
				if (!this.isEmpty(element)) {
					nonEmptyReferrals.push(element);
				}
			});

			ref.ReferralsList = nonEmptyReferrals;
			this.$data.Referrals = JSON.parse(JSON.stringify(ref));
			this.Presentation.Referrals = JSON.stringify(this.$data.Referrals);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	loadbenefitssummary(presentplan: boolean = true) {
		var user = User.query().last();
		if (user && user.FirstName) {
			this.$data.UserName = user.fullShrinkname;
		}

		this.BenefitsSummaryPopupType = {
			PerProcedureCancer: BenefitsSummaryPopupType.PerProcedureCancer,
			LumpSumCancer: BenefitsSummaryPopupType.LumpSumCancer,
			CashValue: BenefitsSummaryPopupType.CashValue,
			PaidUpBenefits: BenefitsSummaryPopupType.PaidUpBenefits,
			TerminalIllnessRider: BenefitsSummaryPopupType.TerminalIllnessRider,
			StrikeWaiver: BenefitsSummaryPopupType.StrikeWaiver,
			LayoffWaiver: BenefitsSummaryPopupType.LayoffWaiver,
			BenefitsGuarenteed: BenefitsSummaryPopupType.BenefitsGuarenteed,
			DeathBenefit: BenefitsSummaryPopupType.DeathBenefit,
		};

		this.ispreplan = Boolean(this.$route.params.initiatePrePlan);
		this.ispresentplan = presentplan && Boolean(this.$route.params.presentplan);
		if (this.ispreplan) {
			this.loadPrePlan();
			this.LoadMedQues();
			return;
		}

		var benefi = "";

		if (!presentplan) {
			benefi = this.Person.BeneficiaryName;
		}

		this.pageLoadedTime = new Date();
		this.Person = PrimaryPerson.query().withAllRecursive().last();
		if (!presentplan) {
			this.Person.BeneficiaryName = benefi;
		}
		this.Presentation = Presentation.query().withAllRecursive().last();

		if (
			this.Presentation.BSMedicalQuestions &&
			this.Presentation.BSMedicalQuestions != ""
		) {
			this.$data.medicalquestions = JSON.parse(
				this.Presentation.BSMedicalQuestions
			);
		} else {
			this.LoadMedQues();
		}

		this.isDollarADay =
			this.Presentation.PremiumTypeId != undefined &&
			this.Presentation.PremiumTypeId == 3
				? true
				: false;

		if (
			this.Person.PlanSummary == undefined ||
			this.Person.PlanSummary == null
		) {
			this.Person.PlanSummary = new PlanSummary();
		}

		if (this.Presentation.PremiumPlan == undefined) {
			this.Presentation.PremiumPlan = new PremiumPlanEntity();
		}
	}

	loadPrePlan() {
		var preplan = JSON.parse(this.$route.params.preplan);
		this.Person = new PrimaryPerson(preplan.Person);
		this.Presentation = JSON.parse(preplan.Presentation);
		this.isDollarADay =
			this.Presentation.PremiumTypeId != undefined &&
			this.Presentation.PremiumTypeId == 3
				? true
				: false;
		this.Presentation.PlanOptions = preplan.Plans;
		this.Presentation.Protections = preplan.Protections;
		if (preplan.State) {
			this.CountryIdFromPreplan =
				preplan.State.CountryId != undefined ? preplan.State.CountryId : 1;
		}
	}

	onBodyResize() {
		if (this.$refs.itemRowFOCFirst != undefined) {
			this.ElemHeight = this.$refs.itemRowFOCFirst.clientHeight + "px";
			//this.ElemHeight1 = this.$refs.itemRowFOCFirst.clientHeight * 3 + "px";
		}
	}

	private ElemHeight: string = "";
	private ElemHeight1: string = "";

	mounted() {
		setTimeout(() => {
			if (this.$refs.itemRowFOCFirst != undefined) {
				this.ElemHeight = this.$refs.itemRowFOCFirst.clientHeight + "px";
				//this.ElemHeight1 = this.$refs.itemRowFOCFirst.clientHeight * 3 + "px";
			}
		}, 400);
	}
	// mounted() {

	//   setTimeout(() => {

	//     // if (right != undefined && right.length > 0)
	//     // {
	//     //   right.forEach((elem : any)=>
	//     //   {
	//     //     elem.clientHeight = left.offsetHeight;
	//     //   })
	//     // }
	//   }, 500);
	// }

	customEvent() {
		this.overflowhidden = true;
		// var tj = gsap.timeline();

		// tj.to(".circle",{duration:2, x:50})
		//     .to(".circle",{duration:1, y:100})

		gsap.from(".animationSlideUp", {
			opacity: 0,
			y: 300,
			duration: 0.4,
			scaleX: 0.85,
			scaleY: 0.85,
		});
		gsap.to(".animationSlideUp", {
			y: 0,
			duration: 0.4,
			scaleX: 0.85,
			scaleY: 0.85,
		});
		gsap.to(".animationSlideUp", {
			delay: 0.5,
			y: 0,
			duration: 0.4,
			scaleX: 1,
			scaleY: 1,
		});

		setTimeout(() => {
			this.overflowhidden = false;
		}, 1200);
	}

	initiateAnimationDivs() {
		this.$data.animationDivs = Array.from(
			document.getElementsByClassName("animationdivs")
		);
		if (this.ispresentplan && this.$data.animationDivs) {
			this.$data.animationDivs.forEach(function (element: any) {
				element.classList.remove("show");
			});
			this.$data.currentIndex = 0;
			this.$data.ERBIndex = 0;
			this.$data.DHBIndex = 1;
			this.$data.ICBIndex = 2;
			this.$data.AnyCauseIndex = 3;
			this.$data.AccidentIndex = 4;
			this.$data.AutoAccidentIndex = 5;
			this.$data.CommonCarrierIndex = 6;
			this.$data.MonthlyIncomeIndex = 7;
			this.$data.MonthlyIncomeADBIndex = 8;
			if (
				(this.$data.BenefitSummary.ShowUnemploymentRow &&
					this.$data.BenefitSummary.ShowADBRow) ||
				(this.$data.BenefitSummary.ShowUnemploymentRow &&
					!this.$data.BenefitSummary.ShowADBRow)
			) {
				this.$data.MonthlyIncomeIndex = 7;
				this.$data.MonthlyIncomeADBIndex = 8;
			} else if (
				!this.$data.BenefitSummary.ShowUnemploymentRow &&
				this.$data.BenefitSummary.ShowADBRow
			) {
				this.$data.MonthlyIncomeADBIndex = 7;
				this.$data.MonthlyIncomeIndex = 8;
			}

			if (!this.$data.BenefitSummary) return;
			if (
				this.$data.BenefitSummary.ShowHospitalBenefits &&
				this.$data.BenefitSummary.ShowFreedomOfChoice &&
				this.$data.BenefitSummary.ShowRCAmount
			) {
				this.$data.ERBIndex = 0;
				this.$data.DHBIndex = 1;
				this.$data.ICBIndex = 2;
				this.$data.AnyCauseIndex = 3;
				this.$data.AccidentIndex = 4;
				this.$data.AutoAccidentIndex = 5;
				this.$data.CommonCarrierIndex = 6;
			} else if (
				this.$data.BenefitSummary.ShowHospitalBenefits &&
				!this.$data.BenefitSummary.ShowFreedomOfChoice &&
				this.$data.BenefitSummary.ShowRCAmount
			) {
				this.$data.ERBIndex = 0;
				this.$data.DHBIndex = 1;
				this.$data.ICBIndex = 2;
				// this.$data.AnyCauseIndex = 3;
				// this.$data.AccidentIndex = 4;
				// this.$data.AutoAccidentIndex = 5;
				// this.$data.CommonCarrierIndex = 6;
				this.$data.MonthlyIncomeIndex = 3;
				this.$data.MonthlyIncomeADBIndex = 4;

				if (
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						this.$data.BenefitSummary.ShowADBRow) ||
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						!this.$data.BenefitSummary.ShowADBRow)
				) {
					this.$data.MonthlyIncomeIndex = 3;
					this.$data.MonthlyIncomeADBIndex = 4;
				} else if (
					!this.$data.BenefitSummary.ShowUnemploymentRow &&
					this.$data.BenefitSummary.ShowADBRow
				) {
					this.$data.MonthlyIncomeADBIndex = 3;
					this.$data.MonthlyIncomeIndex = 4;
				}
			} else if (
				(this.IsVeteranSMBAlphaType ||
					this.IsServiceMemberBenefitsSVLAlphaType ||
					this.IsServiceMemberBenefitsVFLAlphaType) &&
				!this.$data.BenefitSummary.ShowHospitalBenefits &&
				this.$data.BenefitSummary.ShowFreedomOfChoice &&
				this.$data.BenefitSummary.ShowRCAmount
			) {
				var IndexCount = 0;
				this.$data.AnyCauseIndex = IndexCount++;
				if (this.ShowAccidentRow) {
					this.$data.AccidentIndex = IndexCount++;
				}
				if (this.ShowAutoAccidentRow) {
					this.$data.AutoAccidentIndex = IndexCount++;
				}
				if (this.ShowCommonCarrierRow) {
					this.$data.CommonCarrierIndex = IndexCount++;
				}

				if (
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						this.$data.BenefitSummary.ShowADBRow) ||
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						!this.$data.BenefitSummary.ShowADBRow)
				) {
					this.$data.MonthlyIncomeIndex = IndexCount++;
					this.$data.MonthlyIncomeADBIndex = IndexCount++;
				} else if (
					!this.$data.BenefitSummary.ShowUnemploymentRow &&
					this.$data.BenefitSummary.ShowADBRow
				) {
					this.$data.MonthlyIncomeADBIndex = IndexCount++;
					this.$data.MonthlyIncomeIndex = IndexCount++;
				}
			} else if (
				!this.$data.BenefitSummary.ShowHospitalBenefits &&
				this.$data.BenefitSummary.ShowFreedomOfChoice &&
				this.$data.BenefitSummary.ShowRCAmount
			) {
				// this.$data.ERBIndex = 0;
				// this.$data.DHBIndex = 1;
				// this.$data.ICBIndex = 2;
				this.$data.AnyCauseIndex = 0;
				this.$data.AccidentIndex = 1;
				this.$data.AutoAccidentIndex = 2;
				this.$data.CommonCarrierIndex = 3;

				if (
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						this.$data.BenefitSummary.ShowADBRow) ||
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						!this.$data.BenefitSummary.ShowADBRow)
				) {
					this.$data.MonthlyIncomeIndex = 4;
					this.$data.MonthlyIncomeADBIndex = 5;
				} else if (
					!this.$data.BenefitSummary.ShowUnemploymentRow &&
					this.$data.BenefitSummary.ShowADBRow
				) {
					this.$data.MonthlyIncomeADBIndex = 4;
					this.$data.MonthlyIncomeIndex = 5;
				}
			} else if (
				!this.$data.BenefitSummary.ShowHospitalBenefits &&
				!this.$data.BenefitSummary.ShowFreedomOfChoice &&
				this.$data.BenefitSummary.ShowRCAmount
			) {
				// this.$data.ERBIndex = 0;
				// this.$data.DHBIndex = 1;
				// this.$data.ICBIndex = 2;
				// this.$data.AnyCauseIndex = 0;
				// this.$data.AccidentIndex = 1;
				// this.$data.AutoAccidentIndex = 2;
				// this.$data.CommonCarrierIndex = 3;
				this.$data.MonthlyIncomeIndex = 0;
				this.$data.MonthlyIncomeADBIndex = 1;
				if (
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						this.$data.BenefitSummary.ShowADBRow) ||
					(this.$data.BenefitSummary.ShowUnemploymentRow &&
						!this.$data.BenefitSummary.ShowADBRow)
				) {
					this.$data.MonthlyIncomeIndex = 0;
					this.$data.MonthlyIncomeADBIndex = 1;
				} else if (
					!this.$data.BenefitSummary.ShowUnemploymentRow &&
					this.$data.BenefitSummary.ShowADBRow
				) {
					this.$data.MonthlyIncomeADBIndex = 0;
					this.$data.MonthlyIncomeIndex = 1;
				}
			} else if (
				this.$data.BenefitSummary.ShowHospitalBenefits &&
				this.$data.BenefitSummary.ShowFreedomOfChoice &&
				!this.$data.BenefitSummary.ShowRCAmount
			) {
				this.$data.ERBIndex = 0;
				this.$data.DHBIndex = 1;
				this.$data.ICBIndex = 2;
				this.$data.AnyCauseIndex = 3;
				this.$data.AccidentIndex = 4;
				this.$data.AutoAccidentIndex = 5;
				this.$data.CommonCarrierIndex = 6;
				// this.$data.MonthlyIncomeIndex = 7;
				// this.$data.MonthlyIncomeADBIndex = 8;
			}

			if (
				this.$data.BenefitSummary.IsVeteran &&
				this.$data.BenefitSummary.ShowFreedomOfChoice &&
				this.$data.BenefitSummary.ShowHospitalBenefits
			) {
				var tempArray = [];
				tempArray.push(...this.$data.animationDivs.slice(3, 7));
				tempArray.push(...this.$data.animationDivs.slice(0, 3));
				tempArray.push(
					...this.$data.animationDivs.slice(7, this.$data.animationDivs.length)
				);
				this.$data.animationDivs = tempArray;
				this.$data.BenefitSummary.ShowFreedomFirst = true;
				this.$data.ERBIndex = 4;
				this.$data.DHBIndex = 5;
				this.$data.ICBIndex = 6;
				this.$data.AnyCauseIndex = 0;
				this.$data.AccidentIndex = 1;
				this.$data.AutoAccidentIndex = 2;
				this.$data.CommonCarrierIndex = 3;
			}
			this.$data.animationDivsList = [];
			this.$data.animationDivs.forEach((element: any) => {
				this.$data.animationDivsList.push({ show: false });
			});
		}
	}

	overflowhidden: boolean = false;

	loadmounted() {
		if (this.Presentation) {
			if (this.Presentation.PlanOptions) {
				this.$data.Plans = JSON.parse(this.Presentation.PlanOptions);
			}

			if (
				this.Presentation.PresentationTypeId == PresentationType.Veteran ||
				this.Presentation.PresentationTypeId ==
					PresentationType.ServiceMemberBenefits
			)
				this.backgroundimage = process.env?.VUE_APP_PRESENT_VETERAL_PLAN_IMG;
			else this.backgroundimage = process.env?.VUE_APP_PRESENT_PLAN_IMG;

			if (this.Presentation.CurrentPlan) {
				this.loadPlan(
					this.$data.Plans.find((x: any) => x.isSelected == true),
					true
				);
			} else {
				this.loadPlan(this.$data.Plans[0], true);
			}
		}

		this.$nextTick(function () {
			if (this.$refs.beneficiaryName != undefined)
				this.$refs.beneficiaryName.value = this.Person.BeneficiaryName;
			this.initiateAnimationDivs();
			this.loaded = true;
		});
	}

	UnSelectAllPlans() {
		if (this.$data.Plans != undefined && this.$data.Plans.length > 0) {
			this.$data.Plans.forEach((pln: any) => {
				pln.isSelected = false;
			});
		}
	}

	loadBgImagePerPresentationType(presId: number) {
		var pres = listofPresentationType.find((x: any) => x.id == presId);
		if (!pres) return "";
		return pres.plan_background_image;
	}

	get checkedPlans() {
		return this.$data.Plans.filter((x: any) => x.IsChecked == true);
	}
	get BenefitSummarySideImage() {
		this.IsVeteranType = false;
		this.IsVeteranSMBAlphaType = false;
		this.IsMcGruffType = false;
		this.IsUnionType = false;
		this.IsPOSType = false;
		this.IsAssociationType = false;
		this.IsGlobeLifeType = false;
		this.IsServiceMemberBenefitsType = false;
		this.IsServiceMemberBenefitsSVLAlphaType = false;
		this.IsServiceMemberBenefitsVFLAlphaType = false;
		var CurrentPresentation = Presentation.query().last();
		if (CurrentPresentation == null) {
			return true;
		}
		if (this.$route.params != undefined) {
			if (
				Object.keys(this.$route.params)?.length != 0 &&
				this.$route.params.ExcludeFromPresentationCycle
			) {
				return true;
			}
		}
		if (CurrentPresentation != null) {
			this.IsVeteranType =
				PresentationType.Veteran == CurrentPresentation.PresentationTypeId;
			this.IsVeteranSMBAlphaType =
				PresentationType.Veteran == CurrentPresentation.PresentationTypeId &&
				PresentationSubType.ServiceMemberBenefitsLeadAlpha ==
					CurrentPresentation.PresentationSubTypeId;
			this.IsMcGruffType =
				PresentationType.McGruffSafeKids ==
				CurrentPresentation.PresentationTypeId;
			this.IsPOSType =
				PresentationType.POS == CurrentPresentation.PresentationTypeId;
			this.IsUnionType =
				PresentationType.Union == CurrentPresentation.PresentationTypeId;
			this.IsAssociationType =
				PresentationType.Association == CurrentPresentation.PresentationTypeId;
			this.IsGlobeLifeType =
				PresentationType.GlobeLife == CurrentPresentation.PresentationTypeId;
			this.IsServiceMemberBenefitsType =
				PresentationType.ServiceMemberBenefits ==
				CurrentPresentation.PresentationTypeId;
			this.IsServiceMemberBenefitsSVLAlphaType =
				PresentationType.ServiceMemberBenefits ==
					CurrentPresentation.PresentationTypeId &&
				(PresentationSubType.SMBSponsoredVeteranLeadAlpha ==
					CurrentPresentation.PresentationSubTypeId ||
					PresentationSubType.ServiceMemberBenefitNotification ==
						CurrentPresentation.PresentationSubTypeId);
			this.IsServiceMemberBenefitsVFLAlphaType =
				PresentationType.ServiceMemberBenefits ==
					CurrentPresentation.PresentationTypeId &&
				(PresentationSubType.SMBVeteranFamilyLeadAlpha ==
					CurrentPresentation.PresentationSubTypeId ||
					PresentationSubType.Civilian ==
						CurrentPresentation.PresentationSubTypeId);
			if (
				this.IsVeteranType ||
				this.IsVeteranSMBAlphaType ||
				this.IsMcGruffType ||
				this.IsUnionType ||
				this.IsPOSType ||
				this.IsAssociationType ||
				this.IsGlobeLifeType ||
				this.IsServiceMemberBenefitsType ||
				this.IsServiceMemberBenefitsSVLAlphaType ||
				this.IsServiceMemberBenefitsVFLAlphaType
			) {
				return false;
			} else return true;
		}
	}

	loadPlan(plan: any, firsttime: boolean) {
		if (!firsttime) {
			LoggingHelper.getInstance().LogEvent(LoggingConstants.PlanOptionChange);
			this.customEvent();
		}
		this.$data.selected = 0;
		this.$data.CurrentSelectedPlan = plan;
		this.UnSelectAllPlans();
		if (plan != undefined) plan.isSelected = true;
		if (this.$data.CurrentSelectedPlan != undefined) {
			this.Presentation.CurrentPlan = this.$data.CurrentSelectedPlan.Name;
			this.CalculateBenefitsSummary(plan);

			var planOptionPremPlan = JSON.parse(JSON.stringify(plan.PremiumPlan));
			if (planOptionPremPlan != undefined) {
				this.Presentation.NeedAmount = planOptionPremPlan.needPremium;
				if (this.Person != undefined) {
					this.Person.DollarADayAmount = planOptionPremPlan.PrimaryDollarADay;
					if (this.Person.Spouse != undefined)
						this.Person.Spouse.DollarADayAmount =
							planOptionPremPlan.SpouseDollarADay;
				}
			}

			if (this.$data.Plans != undefined && this.$data.Plans.length > 0) {
				this.Presentation.PlanOptions = JSON.stringify(this.$data.Plans);
			}
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}

	selectunselectrow(row: number): void {
		if (this.$data.selected == row) {
			this.$data.selected = 0;
			return;
		}

		this.$data.selected = row;
	}

	ShowHousePaymentRider() {
		this.chequeimghouse = true;
		this.$data.monthlytab2 = 0;
		if (this.$refs.chequeWindow3 != undefined)
			this.$refs.chequeWindow3.updateMultiple();
		if (this.$refs.chequeWindow4 != undefined)
			this.$refs.chequeWindow4.updateMultiple();
	}

	CalculateMonthlyPremium(plan: any) {
		return EntityHelper.CalculateMonthlyPremium(plan);
	}

	CalculateBenefitsSummary(plan: any) {
		LoadBenefitSummaryAsPerPlan(
			this.bsRates,
			this.lstStates,
			this.$data.BenefitSummary,
			plan,
			this.Presentation,
			this.Person,
			"--",
			this.ispresentplan
		);
		plan.BenefitsCalculation = JSON.parse(
			JSON.stringify(this.$data.BenefitSummary)
		);
		setTimeout(() => {
			if (this.loaded) {
				this.initiateAnimationDivs();
			}
		}, 10);

		setTimeout(() => {
			var el = document.getElementsByClassName("lblWeeklyData");
			if (el != null && el.length > 0) {
				if (this.ispresentplan) {
					el[0].innerHTML = this.getPresentPlanText();
				} else {
					el[0].innerHTML = this.$t("BenefitsSummary.Heading").toString();
				}
			}
		}, 10);

		setTimeout(() => {
			if (this.$refs.lumsumpopup) {
				this.$refs.lumsumpopup.$data.key++;
				this.$refs.lumsumpopup.$props.BenefitSummary =
					this.$data.BenefitSummary;
			}
			if (this.$refs.perprocpopup) {
				this.$refs.perprocpopup.$data.key++;
				this.$refs.perprocpopup.$props.BenefitSummary =
					this.$data.BenefitSummary;
			}
		}, 10);
	}

	getPresentPlanText(): string {
		var spendText = "";

		switch (this.Presentation.PremiumTypeId) {
			case 1:
				spendText =
					"$" +
					this.$data.BenefitSummary.UsedWeeklyPremium +
					" " +
					this.$t("BenefitsSummary.Weekly");
				break;
			case 2:
				spendText =
					"$" +
					this.$data.BenefitSummary.UsedMonthlyPremium +
					" " +
					this.$t("BenefitsSummary.Monthly");
				break;
			case 3:
				if (this.Person.isSpouseExists) {
					var combindedDollarADay = (
						this.$data.BenefitSummary.UsedDailyPremium / 2
					).toFixed(2);
					spendText =
						"$" + combindedDollarADay + " " + this.$t("BenefitsSummary.Each");
				} else
					spendText =
						"$" +
						this.$data.BenefitSummary.UsedDailyPremium +
						" " +
						this.$t("BenefitsSummary.ADay");
				break;
			case 4:
				spendText =
					"$" +
					this.$data.BenefitSummary.UsedMonthlyPremium +
					" " +
					this.$t("BenefitsSummary.Monthly");
				break;
		}

		return spendText;
	}
	SaveWillKitDataToPresentation(vetWillkitObj: any) {
		if (vetWillkitObj != undefined) {
			this.Presentation.PresentationWillKitVeterans =
				JSON.stringify(vetWillkitObj);
			Presentation.update({
				where: this.Presentation.Id,
				data: this.Presentation,
			});
		}
	}
	closeProtection() {
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	closeBurialCremationExpense() {
		this.funeralexpense = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	closeUnderwriting() {
		this.showuw = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	closeCementingPopup() {
		this.CementingPopup = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	closeEappPopup() {
		this.eappimage = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	CloseThreeImportantFactsSMB() {
		this.showVeteranThreeImportantFactsSMBPDF = false;
		this.Presentation.PresentationWillKitVeterans = JSON.stringify(
			this.PresentationWillKitVeterans
		);
		this.custompdfhtml = false;
		this.$store.state.OpenedPopupName = "";
		EventBusLayout.$emit("CloseSubMenuPopups");
	}
	DownloadPDF() {
		if (
			this.$refs.VeteranThreeImportantFactsSMBComp != undefined &&
			this.showVeteranThreeImportantFactsSMBPDF == true
		) {
			this.$refs.VeteranThreeImportantFactsSMBComp.DownloadPDF();
		}
	}
}
