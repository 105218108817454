var CryptoJS = require("crypto-js");

export function EncryptFunction(plainText: string) {

    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCRYPTION_KEY),
        {
            keySize: 128 / 8,
            iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCRYPTION_IV),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    var base64 = CryptoJS.enc.Base64.stringify(encryptedlogin.ciphertext);
    return base64;
}

// function decrypt(cipherText, outputEncoding: string = "utf8") {
//     const cipher = crypto.createDecipheriv("aes-128-ecb", KEY, null);
//     let encrypted = cipher.update(cipherText)
//     encrypted += cipher.final(outputEncoding);
//     return encrypted;
// }

