export enum PresentationSubType {
	//[Description("Online McGruff Lead")]
	OnlineMCGruffLead = 1,
	//[Description("Sponsored McGruff Lead")]
	SponsoredMcGruffLead,
	//[Description("Online Child Safe Lead")]
	OnlineChildSafeLead,
	//[Description("Sponsored Child Safe Lead")]
	SponsoredChildSafeLead,
	//[Description("Sponsored Veteran Lead")]
	SponsoredVeteranLead,
	//[Description("Veteran Family Lead")]
	VeteranFamilyLead,
	//[Description("Return Card")]
	ReturnCard,
	//[Description("Buriel Will Kit For Veteran")]
	BurielWillKitForVeteran,
	//[Description("Sponsored No Cost Law")]
	SponsoredNoCostLaw,
	//[Description("Online No Cost Law")]
	OnlineNoCostLaw,
	//[Description("Sponsored Final Expense")]
	SponsoredFinalExpense,
	//[Description("Online Final Expense")]
	OnlineFinalExpense,
	//[Description("Afford")]
	Afford,
	//[Description("Think")]
	Think,
	//[Description("Qualify")]
	Qualify,
	//[Description("Not Interested")]
	NotInterested,
	//[Description("Sale")]
	Sale,
	//[Description("Report Form Request")]
	ReportFormRequest,
	//[Description("Sponsored Credit Union Benefits")]
	SponsoredCreditUnionBenefits,
	//[Description("Online Credit Union Benefits USA")]
	OnlineCreditUnionBenefits,
	//[Description("Online Credit Union Benefits CA")]
	OnlineCreditUnionBenefitsCA,
	//[Description("Return Card")]
	ReturnCardNoCost,
	//[Description("Service Member Benefits Lead")]
	ServiceMemberBenefitsLead,
	//[Description("Market Union/Assoc Member")]
	MarketUnionAssocMember,
	//[Description("Market Union/Assoc Family/Friend")]
	MarketUnionAssocFamilyFriend,
	//[Description("Return Card")]
	ReturnCardUnion,
	//[Description("Globe Web Application")]
	GlobeWebApplication,
	//[Description("Globe Customer")]
	GlobeCustomer,
	//[Description("Globe Plus")]
	GlobePlus,
	//[Description("Globe Inquiry")]
	GlobeInquiry,
	//[Description("Service Member Benefits Lead (Alpha)")]
	ServiceMemberBenefitsLeadAlpha,
	//[Description("SMB Sponsored Veteran Lead")]
	SMBSponsoredVeteranLead,
	//[Description("SMB Veteran Family Lead")]
	SMBVeteranFamilyLead,
	//[Description("SMB Sponsored Veteran Lead (Alpha)")]
	SMBSponsoredVeteranLeadAlpha,
	//[Description("SMB Veteran Family Lead (Alpha)")]
	SMBVeteranFamilyLeadAlpha,
	//[Description("Civilian")]
	Civilian,
	//[Description("Service Member Benefit Notification")]
	ServiceMemberBenefitNotification
}
