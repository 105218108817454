import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core';
import FamilyCareSurvey from './FamilyCareSurvey';

export default class FamilyCareSurveyRelativeInfo extends Model {
	static entity = 'FamilyCareSurveyRelativeInfo';
	static primaryKey = 'Id';
    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            FirstName: this.string(''),
            LastName: this.string(''),
            BranchOfService: this.string(null).nullable(),
            WarService: this.attr([]).nullable(),
            RelationshipToYou: this.string(null).nullable(),
            CampaignMedalsReceived: this.attr([]).nullable(),
            AuxiliaryMembership: this.string(null).nullable(),
            CurrentVeteranStatus: this.string(null).nullable(),
            FamilyCareSurvey_Id: this.attr(null),
            Parent: this.belongsTo(FamilyCareSurvey, "FamilyCareSurvey_Id")
        }
    }


    public FirstName!: string
    public LastName!: string
    public BranchOfService!: string
    public WarService!: any
    public RelationshipToYou!: string
    public CampaignMedalsReceived!: string
    public AuxiliaryMembership!: string
    public CurrentVeteranStatus!: string
    public Id!: string

}