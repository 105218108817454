import APIResponse from '@/models/APIResponse';
import GenericKeyPair from '@/models/GenericKeyPair';
import axios from 'axios';
import { getPersistedState, setPersistedState } from './StateMapper';

const API_BASE = process.env.VUE_APP_API_LINK;

export function GetRequestWithAuthorization(
	actionName: string,
	parameters: GenericKeyPair[],
	timeout: number = 120000
) {
	let querystring = '';
	let i = 0;
	if (parameters != undefined && parameters.length > 0) {
		querystring += '?';

		parameters.forEach((element) => {
			if (i != 0) querystring += '&';
			querystring += element.Key + '=' + element.Value;
			i++;
		});
	}
	const storedUser = localStorage.getItem('user');
	let AuthorizationToken: string = '';
	if (storedUser != undefined) AuthorizationToken = JSON.parse(storedUser).access_token;

	let config = {
		headers: {
			'Content-type': 'application/json',
			Authorization: 'bearer ' + AuthorizationToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		},
		timeout: timeout
	};

	const axiosApiInstance = axios.create();
	axiosApiInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function(error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const tokenInfo = await refreshAccessToken();
				if (tokenInfo != undefined && tokenInfo.status == true) {
					originalRequest.headers['Authorization'] = 'Bearer ' + tokenInfo.Result.access_token;
					return axiosApiInstance(originalRequest);
				} else {
					localStorage.removeItem('user');
					window.open(window.origin);
					Promise.reject(error);
				}
			}
			return Promise.reject(error);
		}
	);

	return axiosApiInstance
		.get(API_BASE + actionName + querystring, config)
		.then((response) => {
			let apiResponse: APIResponse = new APIResponse();
			if (apiResponse != undefined) {
				apiResponse.status = true;
				apiResponse.Result = response.data;
			}
			return Promise.resolve(apiResponse);
		})
		.catch((ex) => {
			let apiResponse: APIResponse = new APIResponse();
			apiResponse.status = false;
			if (ex.response != undefined) apiResponse.Result = ex.response.data;
			return Promise.resolve(apiResponse);
		});
}

export function GetRequestWithAuthorizationFromAccessToken(
	actionName: string,
	parameters: GenericKeyPair[],
	accesstoken: string,
	timeout: number = 120000
) {
	let querystring = '';
	let i = 0;
	if (parameters != undefined && parameters.length > 0) {
		querystring += '?';

		parameters.forEach((element) => {
			if (i != 0) querystring += '&';
			querystring += element.Key + '=' + element.Value;
			i++;
		});
	}
	// const storedUser = localStorage.getItem('user');
	// let AuthorizationToken: string = "";
	// if (storedUser != undefined)
	//     AuthorizationToken = JSON.parse(storedUser).access_token;

	let config = {
		headers: {
			'Content-type': 'application/json',
			Authorization: 'bearer ' + accesstoken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		},
		timeout: timeout
	};

	const axiosApiInstance = axios.create();
	axiosApiInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function(error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const tokenInfo = await refreshAccessToken();
				if (tokenInfo != undefined && tokenInfo.status == true) {
					originalRequest.headers['Authorization'] = 'Bearer ' + tokenInfo.Result.access_token;
					return axiosApiInstance(originalRequest);
				} else {
					localStorage.removeItem('user');
					window.open(window.origin);
					Promise.reject(error);
				}
			}
			return Promise.reject(error);
		}
	);

	return axiosApiInstance
		.get(API_BASE + actionName + querystring, config)
		.then((response) => {
			let apiResponse: APIResponse = new APIResponse();
			if (apiResponse != undefined) {
				apiResponse.status = true;
				apiResponse.Result = response.data;
			}
			return Promise.resolve(apiResponse);
		})
		.catch((ex) => {
			let apiResponse: APIResponse = new APIResponse();
			apiResponse.status = false;
			if (ex.response != undefined) apiResponse.Result = ex.response.data;
			return Promise.resolve(apiResponse);
		});
}

export function refreshAccessToken() {
	const storedUser = localStorage.getItem('user');
	let refreshToken: string = '';
	if (storedUser != undefined) refreshToken = JSON.parse(storedUser).refresh_token;
	var data: any = {
		applicationid: '22',
		refresh_token: refreshToken,
		grant_type: 'refresh_token'
	};
	var formBody: any = [];
	for (var property in data) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(data[property]);
		formBody.push(encodedKey + '=' + encodedValue);
	}
	formBody = formBody.join('&');

	// let config = {
	//     headers: {
	//         "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
	//         "Access-Control-Allow-Origin": "*",
	//         "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
	//         "Access-Control-Allow-Headers": "x-access-token, Origin, X-Requested-With, Content-Type, Accept"
	//     }
	// }

	// let request = axios.post(API_BASE + 'OAuth/Authenticate', formBody, {
	//     headers: {
	//         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	//         "Access-Control-Allow-Origin": "*",
	//         "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
	//         "Access-Control-Allow-Headers": "x-access-token, Origin, X-Requested-With, Content-Type, Accept"
	//     }
	// })
	let request = axios
		.post(API_BASE + 'OAuth/Authenticate', formBody, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			}
		})
		.then((response) => {
			if (response != undefined && response.data != undefined && response.data.access_token != '') {
				var existingItem: any = localStorage.getItem('user');
				response.data.UserDetail = JSON.parse(existingItem).UserDetail;
				//console.log("Saving User Refresh token");
				localStorage.setItem('user', JSON.stringify(response.data));
			}
			return { status: true, Result: response.data };
		})
		.catch((ex) => {
			localStorage.removeItem('user');
			window.location.href = window.origin;
			console.log('error: ' + ex);
			if (ex != undefined && ex.response.data != undefined) return { status: false, Result: ex.response.data };
			else return null;
		});
	return request;
}

export function PostRequestWithAuthorization(
	actionName: string,
	parameters: GenericKeyPair[],
	objectData: any,
	timeout: number = 120000
) {
	let querystring = '';
	let i = 0;
	if (parameters != undefined && parameters.length > 0) {
		querystring += '?';

		parameters.forEach((element) => {
			if (i != 0) querystring += '&';
			querystring += element.Key + '=' + element.Value;
			i++;
		});
	}

	const storedUser = localStorage.getItem('user');
	let AuthorizationToken: string = '';
	if (storedUser != undefined) AuthorizationToken = JSON.parse(storedUser).access_token;

	let config = {
		headers: {
			'Content-type': 'application/json',
			Authorization: 'bearer ' + AuthorizationToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		},
		timeout: timeout
	};

	const axiosApiInstance = axios.create();

	axiosApiInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function(error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const tokenInfo = await refreshAccessToken();
				if (tokenInfo != undefined && tokenInfo.status == true) {
					originalRequest.headers['Authorization'] = 'Bearer ' + tokenInfo.Result.access_token;
					return axiosApiInstance(originalRequest);
				} else {
					localStorage.removeItem('user');
					window.open(window.origin);
					Promise.reject(error);
				}
			}
			return Promise.reject(error);
		}
	);

	return axiosApiInstance
		.post(API_BASE + actionName + querystring, objectData, config)
		.then((res) => {
			return res;
		})
		.catch((ex) => {
			var exJson = ex.toJSON();
			if (exJson.status == 401 && exJson.config._retry == true) {
			} else if (exJson.status == 401) {
			} else Promise.reject(ex);
		});
}

export async function PostAsyncRequestWithAuthorization(
	actionName: string,
	parameters: GenericKeyPair[],
	objectData: any,
	timeout: number = 120000
) {
	let querystring = '';
	let i = 0;
	if (parameters != undefined && parameters.length > 0) {
		querystring += '?';

		parameters.forEach((element) => {
			if (i != 0) querystring += '&';
			querystring += element.Key + '=' + element.Value;
			i++;
		});
	}

	const storedUser = localStorage.getItem('user');
	let AuthorizationToken: string = '';
	if (storedUser != undefined) AuthorizationToken = JSON.parse(storedUser).access_token;

	let config = {
		headers: {
			'Content-type': 'application/json',
			Authorization: 'bearer ' + AuthorizationToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		},
		timeout: timeout
	};

	const axiosApiInstance = axios.create();

	axiosApiInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function(error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const tokenInfo = await refreshAccessToken();
				if (tokenInfo != undefined && tokenInfo.status == true) {
					originalRequest.headers['Authorization'] = 'Bearer ' + tokenInfo.Result.access_token;
					return axiosApiInstance(originalRequest);
				} else {
					localStorage.removeItem('user');
					window.open(window.origin);
					Promise.reject(error);
				}
			}
			return Promise.reject(error);
		}
	);

	return await axiosApiInstance
		.post(API_BASE + actionName + querystring, objectData, config)
		.then((res) => {
			return res;
		})
		.catch((ex) => {
			var exJson = ex.toJSON();
			if (exJson.status == 401 && exJson.config._retry == true) {
			} else if (exJson.status == 401) {
			} else Promise.reject(ex);
		});
}
export async function PatchRequestWithAuthorization(
	actionName: string,
	parameters: GenericKeyPair[],
	objectData: any,
	timeout: number = 120000
) {
	let querystring = '';
	let i = 0;
	if (parameters != undefined && parameters.length > 0) {
		querystring += '?';

		parameters.forEach((element) => {
			if (i != 0) querystring += '&';
			querystring += element.Key + '=' + element.Value;
			i++;
		});
	}

	const storedUser = localStorage.getItem('user');
	let AuthorizationToken: string = '';
	if (storedUser != undefined) AuthorizationToken = JSON.parse(storedUser).access_token;

	let config = {
		headers: {
			'Content-type': 'application/json',
			Authorization: 'bearer ' + AuthorizationToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		},
		timeout: timeout
	};

	const axiosApiInstance = axios.create();

	axiosApiInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function(error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const tokenInfo = await refreshAccessToken();
				if (tokenInfo != undefined && tokenInfo.status == true) {
					originalRequest.headers['Authorization'] = 'Bearer ' + tokenInfo.Result.access_token;
					return axiosApiInstance(originalRequest);
				} else {
					localStorage.removeItem('user');
					window.open(window.origin);
					Promise.reject(error);
				}
			}
			return Promise.reject(error);
		}
	);

	return await axiosApiInstance
		.patch(API_BASE + actionName + querystring, objectData, config)
		.then((res) => {
			return res;
		})
		.catch((ex) => {
			var exJson = ex.toJSON();
			if (exJson.status == 401 && exJson.config._retry == true) {
			} else if (exJson.status == 401) {
			} else Promise.reject(ex);
		});
}

export function FormPostRequestWithAuthorization(
	actionName: string,
	parameters: GenericKeyPair[],
	objectData: any,
	timeout: number = 120000
) {
	let querystring = '';
	let i = 0;
	if (parameters != undefined && parameters.length > 0) {
		querystring += '?';

		parameters.forEach((element) => {
			if (i != 0) querystring += '&';
			querystring += element.Key + '=' + element.Value;
			i++;
		});
	}

	const storedUser = localStorage.getItem('user');
	let AuthorizationToken: string = '';
	if (storedUser != undefined) AuthorizationToken = JSON.parse(storedUser).access_token;

	let config = {
		headers: {
			'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
			Authorization: 'bearer ' + AuthorizationToken,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		},
		timeout: timeout
	};

	const axiosApiInstance = axios.create();

	axiosApiInstance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function(error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				const tokenInfo = await refreshAccessToken();
				if (tokenInfo != undefined && tokenInfo.status == true) {
					originalRequest.headers['Authorization'] = 'Bearer ' + tokenInfo.Result.access_token;
					return axiosApiInstance(originalRequest);
				} else {
					localStorage.removeItem('user');
					window.open(window.origin);
					Promise.reject(error);
				}
			}
			return Promise.reject(error);
		}
	);

	return axiosApiInstance
		.post(API_BASE + actionName + querystring, objectData, config)
		.then((res) => {
			return res;
		})
		.catch((ex) => {
			var exJson = ex.toJSON();
			if (exJson.status == 401 && exJson.config._retry == true) {
			} else if (exJson.status == 401) {
			} else Promise.reject(ex);
		});
}

export function GetApiDataOrCheckLocal(apiUrl: string, parameters: GenericKeyPair[], key: string) {
	return GetRequestWithAuthorization(apiUrl, parameters)
		.then(async (res) => {
			if (res != undefined && res.status) {
				var reslt = res.Result;
				if (reslt != undefined && reslt.length > 0) {
					await setPersistedState(new GenericKeyPair(key, reslt));
					return reslt;
				}
			} else {
				var lclreslt = getPersistedState(key);
				return lclreslt;
			}
			return [];
		})
		.catch((ex) => {
			var res = getPersistedState(key);
			return res;
		});
}

export function GetLocalDataOrCallAPI(apiUrl: string, parameters: GenericKeyPair[], key: string) {
	var lclreslt = getPersistedState(key);
	if (lclreslt && lclreslt != undefined && lclreslt != null) {
		return lclreslt;
	} else {
		return GetRequestWithAuthorization(apiUrl, parameters)
			.then(async (res) => {
				if (res != undefined && res.status) {
					var reslt = res.Result;
					if (reslt != undefined && reslt.length > 0) {
						await setPersistedState(new GenericKeyPair(key, reslt));
						return reslt;
					}
				}
				return [];
			})
			.catch((ex) => {
				return [];
			});
	}
}

export function DownloadFile(fileUrl: string) {
	axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'arraybuffer',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
			'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept'
		}
	})
		.then((response) => {
			var fileURL = window.URL.createObjectURL(new Blob([ response.data ]));

			var fileLink = document.createElement('a');

			fileLink.href = fileURL;

			fileLink.setAttribute('download', 'file.pdf');

			document.body.appendChild(fileLink);

			fileLink.click();
		})
		.catch((ex: any) => {});
}

export function GetRequestAsBlob(compUrl: string, timeout: number = 120000) {
	// let config = {
	//     headers: {
	//         "Access-Control-Allow-Origin": "*",
	//         "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
	//         "Access-Control-Allow-Headers": "x-access-token, Origin, X-Requested-With, Content-Type, Accept"
	//     },
	//     timeout: timeout,
	//     response
	// }

	const axiosApiInstance = axios.create();

	return axiosApiInstance
		.get(compUrl, { responseType: 'blob' })
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((ex) => {
			return Promise.resolve(ex);
		});
}
