






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import MenuComponent from "../components/menu.vue";
import moment, { months } from "moment";
import {
	ConvertIntoAmountFormatWithDecimal,
	ConvertIntoAmountFormatWithNegativeDecimal,
	GetDecimalvaluefromString,
} from "@/helpers/CalculationHelper";
import Finances from "../entities/Finances";
import VueHtml2pdf from "vue-html2pdf";
import Calculator from "../entities/Calculator";
import GamePlanEntity from "../entities/GamePlan";
import Goals from "../entities/Goals";
import Calendar from "../entities/Calendar";
import {
	deletePersistedState,
	getPersistedState,
	setPersistedState,
} from "../helpers/StateMapper";
import GenericKeyPair from "@/models/GenericKeyPair";
import {
	GetRequestWithAuthorization,
	PostRequestWithAuthorization,
} from "@/helpers/APIHelper";
import { APIService } from "@/services/APIService";
import { EventBusLayout } from "../bus/EventBusLayout";

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		VueHtml2pdf,
	},
})
export default class GamePlanPage extends Vue {
	private CurrentTime: any;
	private ListOfYears: any = [];
	private SelectedYear: any;
	private SelectedMonth: any = {};
	private router: any;
	private CurrentScreen: any;
	private showFinanceScreen: boolean = false;
	private showGoalsScreen: boolean = false;
	private showCalendarScreen: boolean = false;
	private showMyFiveScreen: boolean = false;
	private showCalculatorScreen: boolean = false;
	private GoalsMilestonesOption: any = [];
	private isGamePlanAvailable: any;
	private isfirsttimeCalculating: boolean = true;
	private weeksinMonth: any = [];
	private MonthsOfYear: any = [
		{ id: 1, month: "January", view: false },
		{ id: 2, month: "February", view: false },
		{ id: 3, month: "March", view: false },
		{ id: 4, month: "April", view: false },
		{ id: 5, month: "May", view: false },
		{ id: 6, month: "June", view: false },
		{ id: 7, month: "July", view: false },
		{ id: 8, month: "August", view: false },
		{ id: 9, month: "September", view: false },
		{ id: 10, month: "October", view: false },
		{ id: 11, month: "November", view: false },
		{ id: 12, month: "December", view: false },
	];

	private IsNewGameplan: boolean = false;
	private GamePlanList: any = {};
	private GamePlan: any = {};
	private Finances: any = {};
	private Goals: any = {};
	private MyWhyDelete: boolean = false;
	private CareerGoalsDelete: boolean = false;
	private Calendar: any = {};
	private Calculator: any = {};
	private updatedIncome: any = {};
	private attendanceOptions: any = [
		{ Id: 1, Title: "Field Day", text: "F" },
		{ Id: 2, Title: "Vacation", text: "V" },
		{ Id: 3, Title: "Off", text: "O" },
	];
	private SelectedOptions: any = [];
	private totalMonthDays: number = 0;
	private GamePlantoPrint: any = {};
	private FinancestoPrint: any = {};
	private GoalstoPrint: any = {};
	private CalendartoPrint: any = {};
	private CalculatortoPrint: any = {};
	private MonthtoPrint: any = {};
	private weeksinMonthtoPrint: any = {};
	private totalDaystoPrint: number = 0;
	private GoalsMilestonesOptiontoPrint: any = {};
	private firstTimePopulate: boolean = false;

	$refs!: {
		financeform: any;
		goalsform: any;
		html2Pdf: any;
	};
	data() {
		return {
			addlanguageclasses: false,
			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => !!v?.trim() || "Field is required",
			],
			IncomeRequired: [
				(v: any) => !!v || "Field is required",
				(v: any) => !!v?.trim() || "Field is required",
				(v: any) => {
					if (v != undefined && v != "0.00") return true;
					else return false;
				},
			],
			RequiredPercentNumber: [
				(v: any) => {
					if (
						v == undefined ||
						(v != undefined && parseFloat(v) >= 0 && parseFloat(v) <= 100.0) ||
						v == ""
					)
						return true;
					else {
						return false;
					}
				},
			],
			financevalid: false,
			goalsvalid: false,
			WhyMyLength: 3,
			CareerGoalLength: 3,
			pdfoptions: {
				margin: 6,
				filename: "GamePlan.pdf",
				// pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
			},
			GamePlanList: this.GamePlanList,
			GamePlan: this.GamePlan,
			Finances: this.Finances,
			Calculator: this.Calculator,
			finishscroll: {
				scrollPanel: {
					scrollingX: false,
				},
			},
		};
	}

	async created() {
		this.isfirsttimeCalculating = true;
		this.router = this.$router;
		EventBusLayout.$on("RoutedToEvt", this.GamePlanRoutedToEvt);
		this.CurrentTime = new Date();
		this.SelectedYear = this.CurrentTime.getFullYear();
		this.$data.addlanguageclasses = this.$i18n.locale != "en";
		await this.loadLocalGamePlans();
		this.Goals = {};
		this.Calendar = {};
		this.Calculator = {};

		this.ListOfYears = [
			this.CurrentTime.getFullYear() - 1,
			this.CurrentTime.getFullYear(),
			this.CurrentTime.getFullYear() + 1,
		];
		// this.OnChangeGamePlanYear();
		// var selectedmonth = Object.assign({ id: 12 });
		// this.returnDate(selectedmonth);
	}
	GamePlanRoutedToEvt(route: any) {
		this.RoutedTo(route);
	}
	async beforeDestroy() {
		if (this.GamePlan != null && this.GamePlan.Id != undefined) {
			await this.AutoSaveGamePlan();
		}
		GamePlanEntity.deleteAll();
		await EventBusLayout.$off("RoutedToEvt", this.GamePlanRoutedToEvt);
	}

	get isMyWhyDeleteDisable() {
		if (this.Goals?.MyWhy?.length == 1) {
			return true;
		} else false;
	}
	get isCareerGoalsDeleteDisable() {
		if (this.Goals?.CareerGoals?.length == 1) {
			return true;
		} else false;
	}

	get FinanceTotalMonthlyObligations() {
		let test = 0;
		test += GetDecimalvaluefromString(this.Finances.HousePayment);
		test += GetDecimalvaluefromString(this.Finances.Utilities);
		test += GetDecimalvaluefromString(this.Finances.CableInternet);
		test += GetDecimalvaluefromString(this.Finances.CellPhone);
		test += GetDecimalvaluefromString(this.Finances.MonthlyCarPayment);
		test += GetDecimalvaluefromString(this.Finances.CarInsurance);
		test += GetDecimalvaluefromString(this.Finances.Gas);
		test += GetDecimalvaluefromString(this.Finances.Travel);
		test += GetDecimalvaluefromString(this.Finances.MinDebtPay);
		test += GetDecimalvaluefromString(this.Finances.FoodEntertainment);
		test += GetDecimalvaluefromString(this.Finances.Insurance);
		test += GetDecimalvaluefromString(this.Finances.ChildCare);
		test += GetDecimalvaluefromString(this.Finances.Misc1);
		test += GetDecimalvaluefromString(this.Finances.Misc2);
		test += GetDecimalvaluefromString(this.Finances.RecruitingInvestment);
		test += GetDecimalvaluefromString(this.Finances.AgencyInvestment);
		test += GetDecimalvaluefromString(this.Finances.Mortgage);
		test += GetDecimalvaluefromString(this.Finances.CreditCards);
		test += GetDecimalvaluefromString(this.Finances.CarPayment);
		test += GetDecimalvaluefromString(this.Finances.LinesOfCredit);
		test += GetDecimalvaluefromString(this.Finances.PersonalLoans);
		test += GetDecimalvaluefromString(this.Finances.Other1);
		test += GetDecimalvaluefromString(this.Finances.Other2);

		return ConvertIntoAmountFormatWithDecimal(test.toFixed(2).toString());
	}

	get FinanceTotalCashNeeded() {
		let test = 0;
		test =
			GetDecimalvaluefromString(this.FinanceTotalMonthlyObligations) -
			GetDecimalvaluefromString(this.Finances.LessOtherIncome);
		let test1 = ConvertIntoAmountFormatWithNegativeDecimal(
			test.toFixed(2).toString()
		);
		this.updatedIncome = test1;
		if (test1.includes("-")) {
			ConvertIntoAmountFormatWithDecimal("0.00");
		} else {
			return ConvertIntoAmountFormatWithNegativeDecimal(
				test.toFixed(2).toString()
			);
		}
	}

	get GetNetAlp() {
		let NetAlp = 0;
		NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);

		if (this.Calculator.IsIncomeSelected) {
			if (!isNaN(NetAlp) && NetAlp > 0) {
				this.Calculator.Alp = ConvertIntoAmountFormatWithDecimal(
					NetAlp.toFixed(2).toString()
				);
			} else {
				if (this.Calculator.Income != null)
					this.Calculator.Alp = ConvertIntoAmountFormatWithDecimal("0.00");
			}
		} else {
			let Income =
				GetDecimalvaluefromString(this.Calculator.Alp) *
				((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
					100 /
					(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
					GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
			if (
				this.Calculator.Alp ==
					ConvertIntoAmountFormatWithDecimal(NetAlp.toFixed(2).toString()) &&
				this.Calculator.Income ==
					ConvertIntoAmountFormatWithDecimal(Income.toFixed(2).toString())
			) {
				return ConvertIntoAmountFormatWithDecimal(NetAlp.toFixed(2).toString());
			} else if (
				GetDecimalvaluefromString(this.Calculator.Alp) !=
					GetDecimalvaluefromString(NetAlp.toFixed(2)) &&
				GetDecimalvaluefromString(this.Calculator.Income) !=
					GetDecimalvaluefromString(Income.toFixed(2)) &&
				Income != 0 &&
				NetAlp != 0
			) {
				if (this.isfirsttimeCalculating) {
					this.isfirsttimeCalculating = false;
					this.Calculator.Alp = ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
					return ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
				} else {
					NetAlp = GetDecimalvaluefromString(this.Calculator.Alp);
					this.Calculator.Income = ConvertIntoAmountFormatWithDecimal(
						Income.toFixed(2).toString()
					);
					return ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
				}
			}
			if (!isNaN(Income) && Income > 0) {
				NetAlp = GetDecimalvaluefromString(this.Calculator.Alp);
				this.Calculator.Income = ConvertIntoAmountFormatWithDecimal(
					Income.toFixed(2).toString()
				);
			} else {
				if (
					this.Calculator.Alp == "0.00" &&
					Income == 0 &&
					GetDecimalvaluefromString(this.Calculator.Income) > 0
				) {
					this.Calculator.Alp = ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
					return ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
				}
				this.Calculator.Income = ConvertIntoAmountFormatWithDecimal("0.00");
				return ConvertIntoAmountFormatWithDecimal("0.00");
			}
		}

		if (isNaN(NetAlp) && this.Calculator.Income != null) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}
		if (!isNaN(NetAlp))
			return ConvertIntoAmountFormatWithDecimal(NetAlp.toFixed(2).toString());
	}

	get GetGrossAlp() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(GrossAlp.toFixed(2).toString());
	}
	get GetAdvance() {
		let Advance = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		Advance =
			(NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100)) *
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) / 100);
		if (isNaN(Advance)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(Advance.toFixed(2).toString());
	}
	get GetBonus() {
		let Bonus = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);

		Bonus = NetAlp * (GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		if (isNaN(Bonus)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(Bonus.toFixed(2).toString());
	}

	get GetTotalIncome() {
		let TotalIncome = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		TotalIncome =
			(NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100)) *
				((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) / 100) +
			NetAlp * (GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		if (isNaN(TotalIncome)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(
			TotalIncome.toFixed(2).toString()
		);
	}

	get GetAlpPerMonth() {
		let AlpPerMonth = this.GetGrossAlp;
		return AlpPerMonth;
	}

	get GetSalesPerMonth() {
		let SalesPerMonth = 0;
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);

		SalesPerMonth =
			GrossAlp / GetDecimalvaluefromString(this.Calculator.AlpPerSale);

		if (
			isNaN(SalesPerMonth) ||
			SalesPerMonth == Infinity ||
			SalesPerMonth == 0
		) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}
		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(SalesPerMonth).toString()
		);
	}
	get GetPresPerMonth() {
		let PresPerMonth = 0;
		let SalesPerMonth = 0;
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);

		SalesPerMonth =
			GrossAlp / GetDecimalvaluefromString(this.Calculator.AlpPerSale);
		PresPerMonth =
			SalesPerMonth / (GetDecimalvaluefromString(this.Calculator.Close) / 100);

		if (isNaN(PresPerMonth) || PresPerMonth == Infinity || PresPerMonth == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}
		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(PresPerMonth).toString()
		);
	}
	get GetApptsPerMonth() {
		let ApptsPerMonth = 0;
		let PresPerMonth = 0;
		let SalesPerMonth = 0;
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Calculator.Income) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);
		SalesPerMonth =
			GrossAlp / GetDecimalvaluefromString(this.Calculator.AlpPerSale);
		PresPerMonth =
			SalesPerMonth / (GetDecimalvaluefromString(this.Calculator.Close) / 100);

		ApptsPerMonth =
			PresPerMonth / (GetDecimalvaluefromString(this.Calculator.Show) / 100);

		if (
			isNaN(ApptsPerMonth) ||
			ApptsPerMonth == Infinity ||
			ApptsPerMonth == 0
		) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}
		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(ApptsPerMonth).toString()
		);
	}

	get GetAlpPerWeek() {
		let AlpPerWeek = 0;
		AlpPerWeek = GetDecimalvaluefromString(this.GetAlpPerMonth) / 4.33;

		if (isNaN(AlpPerWeek) || AlpPerWeek == Infinity) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(AlpPerWeek.toFixed(2).toString());
	}

	get GetSalesPerWeek() {
		let SalesPerWeek = 0;
		SalesPerWeek = GetDecimalvaluefromString(this.GetSalesPerMonth) / 4.33;

		if (isNaN(SalesPerWeek) || SalesPerWeek == Infinity || SalesPerWeek == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(SalesPerWeek).toString()
		);
	}

	get GetPresPerWeek() {
		let PresPerWeek = 0;
		PresPerWeek = GetDecimalvaluefromString(this.GetPresPerMonth) / 4.33;

		if (isNaN(PresPerWeek) || PresPerWeek == Infinity || PresPerWeek == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(PresPerWeek).toString()
		);
	}
	get GetApptsPerWeek() {
		let ApptsPerWeek = 0;
		ApptsPerWeek = GetDecimalvaluefromString(this.GetApptsPerMonth) / 4.33;

		if (isNaN(ApptsPerWeek) || ApptsPerWeek == Infinity || ApptsPerWeek == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(ApptsPerWeek).toString(),
			false
		);
	}

	get GetAlpPerDay() {
		let AlpPerDay = 0;
		AlpPerDay =
			GetDecimalvaluefromString(this.GetAlpPerMonth) / this.GetFieldDays;

		if (isNaN(AlpPerDay) || AlpPerDay == Infinity) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(AlpPerDay.toFixed(2).toString());
	}

	get GetSalesPerDay() {
		let SalesPerDay = 0;
		SalesPerDay =
			GetDecimalvaluefromString(this.GetSalesPerMonth) / this.GetFieldDays;

		if (isNaN(SalesPerDay) || SalesPerDay == Infinity || SalesPerDay == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(SalesPerDay).toString()
		);
	}

	get GetPresPerDay() {
		let PresPerDay = 0;
		PresPerDay =
			GetDecimalvaluefromString(this.GetPresPerMonth) / this.GetFieldDays;

		if (isNaN(PresPerDay) || PresPerDay == Infinity || PresPerDay == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(Math.ceil(PresPerDay).toString());
	}

	get GetApptsPerDay() {
		let ApptsPerDay = 0;
		ApptsPerDay =
			GetDecimalvaluefromString(this.GetApptsPerMonth) / this.GetFieldDays;

		if (isNaN(ApptsPerDay) || ApptsPerDay == Infinity || ApptsPerDay == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(ApptsPerDay).toString()
		);
	}

	get totalProductionCommitment() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Finances.IncomeCommitment) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}
		return ConvertIntoAmountFormatWithDecimal(GrossAlp.toFixed(2).toString());
	}
	get totalProductionGoal() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.Finances.IncomeGoal) /
			((0.65 * GetDecimalvaluefromString(this.Calculator.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.Calculator.Ntg) / 100) +
				GetDecimalvaluefromString(this.Calculator.Bonus) / 100);
		GrossAlp = NetAlp / (GetDecimalvaluefromString(this.Calculator.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}
		return ConvertIntoAmountFormatWithDecimal(GrossAlp.toFixed(2).toString());
	}

	async ShowPage(pageName: any, month: any, monthId: any) {
		month = this.$t(`GamePlanPage.Months.${month}`);
		this.CurrentScreen = pageName;

		this.SelectedMonth = {
			Name: month,
			Id: monthId,
		};
		if (this.GamePlan.MonthId == null || this.GamePlan.MonthId != monthId) {
			if (this.GamePlan != null && this.GamePlan.Id != undefined) {
				await this.AutoSaveGamePlan();
			}
			// this.updatedIncome = "0.00";
			this.isGamePlanAvailable = true;
			this.firstTimePopulate = true;
			this.GamePlan = this.GamePlanList.find(
				(x: { MonthId: any }) => x.MonthId == monthId
			);
			this.returnDate(this.SelectedMonth);
			if (
				this.GamePlan.Finances == null ||
				this.GamePlan.Finances == undefined
			) {
				this.Finances = new Finances();
			} else {
				this.Finances = Object.assign({ ...this.GamePlan.Finances });
			}
			if (this.GamePlan.Goals == null || this.GamePlan.Goals == undefined) {
				this.Goals = new Goals();
				this.Goals.MyWhy = ["", "", ""];
				this.Goals.CareerGoals = ["", "", ""];
			} else {
				this.Goals = Object.assign({ ...this.GamePlan.Goals });
				this.Goals.MyWhy = this.GamePlan.Goals.MyWhy.slice(0);
				this.Goals.CareerGoals = this.GamePlan.Goals.CareerGoals.slice(0);
				this.Goals.MyWhy.length <= 0
					? this.Goals.MyWhy.push("")
					: this.Goals.MyWhy;
				this.Goals.CareerGoals.length <= 0
					? this.Goals.CareerGoals.push("")
					: this.Goals.CareerGoals;
				if (this.Goals.CareerGoals.length == 1) this.CareerGoalsDelete = true;
				else this.CareerGoalsDelete = false;
				await this.GetGoalsMilestones(JSON.parse(this.Goals.MilestonesOption));
			}
			if (
				this.GamePlan.Calculator == null ||
				this.GamePlan.Calculator == undefined
			) {
				this.Calculator = new Calculator();
				this.Calculator.Income = this.GamePlan.Finances.IncomeGoal;
			} else {
				this.Calculator = Object.assign({ ...this.GamePlan.Calculator });
			}
			if (
				this.GamePlan.Calendar == null ||
				this.GamePlan.Calendar == undefined
			) {
				this.Calendar = new Calendar();
				this.SelectedOptions = new Array(this.totalMonthDays).fill("F");
				this.Calendar.FieldDays = this.totalMonthDays;
			} else {
				this.Calendar = Object.assign({ ...this.GamePlan.Calendar });
			}
			if (this.Calendar.SelectedOptions != null) {
				this.SelectedOptions = this.Calendar.SelectedOptions;
				this.SelectedOptions = this.Calendar.SelectedOptions.slice(0);
			}
		}

		switch (pageName) {
			case "MyFive":
				this.showMyFiveScreen = true;
				this.showFinanceScreen = false;
				this.showGoalsScreen = false;
				this.showCalendarScreen = false;
				this.showCalculatorScreen = false;
				break;
			case "Finance":
				this.Finances.IncomeGoal = this.Calculator.Income;
				this.showMyFiveScreen = false;
				this.showFinanceScreen = true;
				this.showGoalsScreen = false;
				this.showCalendarScreen = false;
				this.showCalculatorScreen = false;
				break;
			case "Goals":
				this.showMyFiveScreen = false;
				this.showFinanceScreen = false;
				this.showGoalsScreen = true;
				this.showCalendarScreen = false;
				this.showCalculatorScreen = false;
				break;
			case "Calendar":
				this.showMyFiveScreen = false;
				this.showFinanceScreen = false;
				this.showGoalsScreen = false;
				this.showCalendarScreen = true;
				this.showCalculatorScreen = false;
				break;
			case "Calculator":
				this.Calculator.Income = this.Finances.IncomeGoal;
				this.isfirsttimeCalculating = true;
				this.showMyFiveScreen = false;
				this.showFinanceScreen = false;
				this.showGoalsScreen = false;
				this.showCalendarScreen = false;
				this.showCalculatorScreen = true;
				break;
			default:
				break;
		}
	}

	async OnClickAddGamePlan(month: any, monthId: any) {
		month = this.$t(`GamePlanPage.Months.${month}`);

		if (
			this.GamePlan != null &&
			this.GamePlan.Id != undefined &&
			this.GamePlan.MonthId != monthId
		) {
			await this.AutoSaveGamePlan();
		}
		this.$refs?.financeform?.resetValidation();
		this.$refs?.goalsform?.resetValidation();
		this.CurrentScreen = "Finance";
		this.isGamePlanAvailable = false;
		this.SelectedMonth = {
			Name: month,
			Id: monthId,
		};
		await this.GetGoalsMilestones(null);
		this.returnDate(this.SelectedMonth);
		this.SelectedOptions = new Array(this.totalMonthDays).fill("F");
		this.IsNewGameplan = true;
		this.GamePlan = new GamePlanEntity();
		this.GamePlan.Finances = new Finances();
		this.GamePlan.Goals = new Goals();
		this.GamePlan.Calculator = new Calculator();
		this.GamePlan.Calendar = new Calendar();
		this.GamePlan.Year = this.SelectedYear;
		this.GamePlan.MonthId = monthId;
		this.GamePlan.Month = month;

		this.Finances = Object.assign({ ...this.GamePlan.Finances });
		this.Goals = Object.assign({ ...this.GamePlan.Goals });
		this.Calculator = Object.assign({ ...this.GamePlan.Calculator });
		this.Calendar = Object.assign({ ...this.GamePlan.Calendar });
		this.Goals.MyWhy = ["", "", ""];
		this.Goals.CareerGoals = ["", "", ""];

		this.Calendar.FieldDays = this.totalMonthDays;
		this.firstTimePopulate = true;
		this.showMyFiveScreen = false;
		this.showFinanceScreen = true;
		this.showGoalsScreen = false;
		this.showCalendarScreen = false;
		this.showCalculatorScreen = false;
	}

	async AutoSaveGamePlan() {
		if (this.isGamePlanAvailable) {
			let GamePlanUpdateRequest = this.CreateGamePlanUpdateRequest();
			if (GamePlanUpdateRequest != null) {
				await this.UpdateGamePlanCall(GamePlanUpdateRequest);
			}
		} else {
			let GamePlanSaveRequest = this.CreateGamePlanSaveRequest(false);
			if (GamePlanSaveRequest != null) {
				if (
					GamePlanSaveRequest.Finances.IncomeGoal !=
					GamePlanSaveRequest.Calculator.Income
				) {
					if (this.CurrentScreen == "Finance") {
						if (GamePlanSaveRequest.Finances.IncomeGoal != null) {
							GamePlanSaveRequest.Calculator.Income =
								GamePlanSaveRequest.Finances.IncomeGoal;
						}
					} else if (this.CurrentScreen == "Calculator") {
						if (GamePlanSaveRequest.Calculator.Income != null) {
							GamePlanSaveRequest.Finances.IncomeGoal =
								GamePlanSaveRequest.Calculator.Income;
						}
					} else {
						if (GamePlanSaveRequest.Calculator.Income != null) {
							GamePlanSaveRequest.Finances.IncomeGoal =
								GamePlanSaveRequest.Calculator.Income;
						} else if (GamePlanSaveRequest.Finances.IncomeGoal != null) {
							GamePlanSaveRequest.Calculator.Income =
								GamePlanSaveRequest.Finances.IncomeGoal;
						}
					}
					// if (GamePlanSaveRequest.Calculator.Income != null) {
					// 	GamePlanSaveRequest.Finances.IncomeGoal =
					// 		GamePlanSaveRequest.Calculator.Income;
					// } else if (GamePlanSaveRequest.Finances.IncomeGoal != null) {
					// 	GamePlanSaveRequest.Calculator.Income =
					// 		GamePlanSaveRequest.Finances.IncomeGoal;
					// }
				}
				await this.saveGamePlanCall(GamePlanSaveRequest);
			}
		}
	}

	async GetGoalsMilestones(gamePlanMilestones: any) {
		var allMilestonesOptions: any = await getPersistedState("MilestonesOption");
		if (allMilestonesOptions != undefined && allMilestonesOptions.length > 0) {
			var monthId = this.SelectedMonth.Id;
			var year = this.SelectedYear;

			var CurrrentScenarioReportOptions = allMilestonesOptions.filter(
				(x: any) => {
					if (
						(x.Year == undefined || x.Year == year) &&
						(x.MonthId == undefined || x.MonthId == monthId)
					)
						return x;
				}
			);

			if (
				CurrrentScenarioReportOptions != undefined &&
				CurrrentScenarioReportOptions.length > 0
			) {
				if (gamePlanMilestones != null) {
					gamePlanMilestones.forEach((milestone: any) => {
						CurrrentScenarioReportOptions.filter((x: any) => {
							if (
								x.MilestonesOptionId == milestone.MilestonesOptionId &&
								milestone.Value == true
							)
								x.Value = true;
						});
					});
				}

				this.GoalsMilestonesOption = CurrrentScenarioReportOptions.sort(
					(a: any, b: any) => a.SortOrder - b.SortOrder
				);
			}
		}
	}
	async GetMilestoneOptionsToPrint(gamePlanMilestonestoPrint: any) {
		var allMilestonesOptions: any = await getPersistedState("MilestonesOption");
		if (allMilestonesOptions != undefined && allMilestonesOptions.length > 0) {
			var monthId = this.MonthtoPrint.Id;
			var year = this.SelectedYear;

			var CurrrentScenarioReportOptions = allMilestonesOptions.filter(
				(x: any) => {
					if (
						(x.Year == undefined || x.Year == year) &&
						(x.MonthId == undefined || x.MonthId == monthId)
					)
						return x;
				}
			);

			if (
				CurrrentScenarioReportOptions != undefined &&
				CurrrentScenarioReportOptions.length > 0
			) {
				if (gamePlanMilestonestoPrint != null) {
					gamePlanMilestonestoPrint.forEach((milestone: any) => {
						CurrrentScenarioReportOptions.filter((x: any) => {
							if (
								x.MilestonesOptionId == milestone.MilestonesOptionId &&
								milestone.Value == true
							)
								x.Value = true;
						});
					});
				}

				this.GoalsMilestonesOptiontoPrint = CurrrentScenarioReportOptions.sort(
					(a: any, b: any) => a.SortOrder - b.SortOrder
				);
			}
		}
	}

	GoBacktoPreviousScreen(pageName: any) {
		switch (pageName) {
			case "Goals":
				if (this.Calculator?.Income != undefined) {
					this.Finances.IncomeGoal = this.Calculator?.Income;
				}
				this.CurrentScreen = "Finance";
				this.showFinanceScreen = true;
				this.showGoalsScreen = false;
				break;
			case "Calendar":
				if (this.Calculator?.Income != undefined) {
					this.Finances.IncomeGoal = this.Calculator?.Income;
				}
				this.CurrentScreen = "Goals";
				this.showGoalsScreen = true;
				this.showCalendarScreen = false;
				break;
			case "Calculator":
				if (this.Calculator?.Income != undefined) {
					this.Finances.IncomeGoal = this.Calculator?.Income;
				}
				this.CurrentScreen = "Calendar";
				this.showCalendarScreen = true;
				this.showCalculatorScreen = false;
				break;
			default:
				break;
		}
	}

	async loadLocalGamePlans() {
		await getPersistedState("GamePlans").then((val: any) => {
			if (val != null && val != undefined && val != "") {
				GamePlanEntity.deleteAll();
				val.forEach((element: any) => {
					GamePlanEntity.insertOrUpdate({ data: element });
				});
				this.OnChangeGamePlanYear();
			} else {
				this.OnChangeGamePlanYear();
			}
		});
	}

	GotoNextScreen(pageName: any) {
		switch (pageName) {
			case "Finance":
				if (!this.$data.financevalid) {
					this.$refs.financeform.validate();
					this.$store.state.messageText = "Please fill the required fields";
					this.$store.state.showMessage = true;
					return;
				}
				if (this.Finances?.IncomeGoal != undefined) {
					if (this.Calculator?.IsIncomeSelected == undefined) {
						this.Calculator.IsIncomeSelected = true;
					}
					this.Calculator.Income = this.Finances?.IncomeGoal;
				}
				this.CurrentScreen = "Goals";
				this.showFinanceScreen = false;
				this.showGoalsScreen = true;
				break;
			case "Goals":
				if (!this.$data.goalsvalid) {
					this.$refs.goalsform.validate();
					this.$store.state.messageText = "Please fill the required fields";
					this.$store.state.showMessage = true;
					return;
				}
				this.CurrentScreen = "Calendar";
				this.showGoalsScreen = false;
				this.showCalendarScreen = true;
				break;
			case "Calendar":
				if (this.Finances?.IncomeGoal != undefined) {
					if (this.Calculator?.IsIncomeSelected == undefined) {
						this.Calculator.IsIncomeSelected = true;
					}
					this.Calculator.Income = this.Finances?.IncomeGoal;
				}
				this.isfirsttimeCalculating = true;
				this.CurrentScreen = "Calculator";
				this.showCalendarScreen = false;
				this.showCalculatorScreen = true;
				break;
			default:
				break;
		}
	}

	saveData(pageName: any) {
		if (pageName == "MyFive") {
			this.showMyFiveScreen = false;
		} else if (pageName == "Calculator") {
			if (this.Finances.IncomeGoal != this.Calculator.Income) {
				this.Finances.IncomeGoal = this.Calculator.Income;
			}

			let GamePlanSaveRequest = this.CreateGamePlanSaveRequest(true);
			if (GamePlanSaveRequest != null) {
				if (this.isGamePlanAvailable) {
					this.UpdateGamePlanCall(GamePlanSaveRequest);
				} else {
					this.saveGamePlanCall(GamePlanSaveRequest);
				}
			}

			// let LocalGamePlan = new GenericKeyPair(
			// 	"GamePlans",
			// 	GamePlanEntity.query().withAllRecursive().get()
			// );
			// setPersistedState(LocalGamePlan);
			// this.OnChangeGamePlanYear();
			// this.$store.state.messageText = "Game Plan is saved successfully";
			// this.$store.state.showMessage = true;
			// this.showCalculatorScreen = false;
		}
		//this.showCalculatorScreen = false;
		this.CurrentScreen = {};
	}

	CreateGamePlanSaveRequest(isSavePressed: boolean) {
		if (!isSavePressed) {
			let orgFinances = JSON.stringify(this.GamePlan.Finances);
			let updatedFinances = JSON.stringify(this.Finances);
			let isFinacesUpdated = orgFinances != updatedFinances;

			this.Calculator.Alp =
				this.GamePlan.Calculator.Alp == null && this.Calculator.Alp == "0.00"
					? this.GamePlan.Calculator.Alp
					: this.Calculator.Alp;
			let orgCalculator = JSON.stringify(this.GamePlan.Calculator);
			let updatedCalculator = JSON.stringify(this.Calculator);
			let isCalculatorUpdated = orgCalculator != updatedCalculator;

			let milestoneOptionsUpdated = false;
			if (
				this.GamePlan.Goals.MilestonesOption != null &&
				this.GamePlan.Goals.MilestonesOption != ""
			) {
				let oldMilestoneOptions = JSON.parse(
					this.GamePlan.Goals.MilestonesOption
				);
				this.GoalsMilestonesOption.forEach((options: any) => {
					let old = oldMilestoneOptions.find(
						(x: any) => x.MilestonesOptionId == options.MilestonesOptionId
					);
					if (
						old != null &&
						old != undefined &&
						old.Value != undefined &&
						old.Value != Boolean(options.Value)
					) {
						milestoneOptionsUpdated = true;
					}
				});
			} else {
				milestoneOptionsUpdated =
					this.GoalsMilestonesOption.find((x: any) => x.Value == true) != null;
			}
			this.Goals.MyWhy = this.Goals.MyWhy.filter((x: any) => x !== "");
			this.Goals.CareerGoals = this.Goals.CareerGoals.filter(
				(x: any) => x !== ""
			);
			// this.Goals.MyWhy.length <= 0
			// 	? this.Goals.MyWhy.push("")
			// 	: this.Goals.MyWhy;
			// this.Goals.CareerGoals.length <= 0
			// 	? this.Goals.CareerGoals.push("")
			// 	: this.Goals.CareerGoals;
			let orgGoals = JSON.stringify(this.GamePlan.Goals);
			let updatedGoals = JSON.stringify(this.Goals);
			let isGoalsUpdated = orgGoals != updatedGoals;
			let selectedoptionchange =
				this.SelectedOptions.includes(this.attendanceOptions[1].text) ||
				this.SelectedOptions.includes(this.attendanceOptions[2].text);
			this.Calendar.SelectedOptions = selectedoptionchange
				? this.SelectedOptions
				: this.Calendar.SelectedOptions;
			// this.Calendar.FieldDays = selectedoptionchange
			// 	? this.Calendar.FieldDays
			// 	: 0;
			if (this.GamePlan.Calendar.FieldDays == 0) {
				this.GamePlan.Calendar.FieldDays = this.Calendar.FieldDays;
			}
			let orgCalendar = JSON.stringify(this.GamePlan.Calendar);
			let updatedCalendar = JSON.stringify(this.Calendar);
			let isCalendarUpdated = orgCalendar != updatedCalendar;

			if (
				!(
					isFinacesUpdated ||
					isCalculatorUpdated ||
					isGoalsUpdated ||
					milestoneOptionsUpdated ||
					isCalendarUpdated
				)
			) {
				return null;
			}
		}

		this.GamePlan.Finances_Id = this.Finances.Id;
		this.GamePlan.Calculator_Id = this.Calculator.Id;
		this.GamePlan.Goals_Id = this.Goals.Id;
		this.GamePlan.Calendar_Id = this.Calendar.Id;
		this.Calendar.SelectedOptions = this.SelectedOptions;
		this.Goals.MyWhy = this.Goals.MyWhy.filter((x: any) => x !== "");
		this.Goals.CareerGoals = this.Goals.CareerGoals.filter(
			(x: any) => x !== ""
		);
		this.Goals.MyWhy.length <= 0 ? this.Goals.MyWhy.push("") : this.Goals.MyWhy;
		this.Goals.CareerGoals.length <= 0
			? this.Goals.CareerGoals.push("")
			: this.Goals.CareerGoals;
		this.Goals.MilestonesOption = JSON.stringify(this.GoalsMilestonesOption);
		this.GamePlan.Finances = this.Finances;
		this.GamePlan.Goals = this.Goals;
		this.GamePlan.Calculator = this.Calculator;
		this.GamePlan.Calendar = this.Calendar;

		let GamePlan: any = {};
		GamePlan.Id = this.GamePlan.Id;
		GamePlan.IncomeGoal = this.GamePlan.IncomeGoal;
		GamePlan.Year = this.GamePlan.Year;
		GamePlan.Month = this.GamePlan.Month;
		GamePlan.MonthId = this.GamePlan.MonthId;

		if (
			this.GamePlan.Finances != null &&
			this.GamePlan.Finances.Id != undefined
		) {
			var GameplanFinances = this.GamePlan.Finances;

			let Finance: any = {};
			Finance.Id = GameplanFinances.Id;
			Finance.HousePayment = GameplanFinances.HousePayment;
			Finance.Utilities = GameplanFinances.Utilities;
			Finance.CableInternet = GameplanFinances.CableInternet;
			Finance.CellPhone = GameplanFinances.CellPhone;
			Finance.MonthlyCarPayment = GameplanFinances.MonthlyCarPayment;
			Finance.CarInsurance = GameplanFinances.CarInsurance;
			Finance.Gas = GameplanFinances.Gas;
			Finance.Travel = GameplanFinances.Travel;
			Finance.MinDebtPay = GameplanFinances.MinDebtPay;
			Finance.FoodEntertainment = GameplanFinances.FoodEntertainment;
			Finance.Insurance = GameplanFinances.Insurance;
			Finance.ChildCare = GameplanFinances.ChildCare;
			Finance.Misc1 = GameplanFinances.Misc1;
			Finance.Misc2 = GameplanFinances.Misc2;
			Finance.RecruitingInvestment = GameplanFinances.RecruitingInvestment;
			Finance.AgencyInvestment = GameplanFinances.AgencyInvestment;
			Finance.Mortgage = GameplanFinances.Mortgage;
			Finance.CreditCards = GameplanFinances.CreditCards;
			Finance.CarPayment = GameplanFinances.CarPayment;
			Finance.LinesOfCredit = GameplanFinances.LinesOfCredit;
			Finance.PersonalLoans = GameplanFinances.PersonalLoans;
			Finance.Other1 = GameplanFinances.Other1;
			Finance.Other2 = GameplanFinances.Other2;
			Finance.IncomeGoal = GameplanFinances.IncomeGoal;
			// Finance.ProductionGoals = GameplanFinances.ProductionGoals;
			Finance.IncomeCommitment = GameplanFinances.IncomeCommitment;
			// Finance.ProductionCommitment = GameplanFinances.ProductionCommitment;
			Finance.LessOtherIncome = GameplanFinances.LessOtherIncome;

			GamePlan.Finances = Finance;
		}
		if (
			this.GamePlan.Calculator != null &&
			this.GamePlan.Calculator.Id != undefined
		) {
			var GameplanCalculator = this.GamePlan.Calculator;
			let Calculator: any = {};

			Calculator.Id = GameplanCalculator.Id;
			Calculator.IsIncomeSelected = GameplanCalculator.IsIncomeSelected;
			Calculator.Income = GameplanCalculator.Income;
			Calculator.Alp = GameplanCalculator.Alp;
			Calculator.Contract = GameplanCalculator.Contract;
			Calculator.AlpPerSale = GameplanCalculator.AlpPerSale;
			Calculator.Show = GameplanCalculator.Show;
			Calculator.Bonus = GameplanCalculator.Bonus;
			Calculator.Close = GameplanCalculator.Close;
			Calculator.Ntg = GameplanCalculator.Ntg;
			Calculator.SponsorCollection = GameplanCalculator.SponsorCollection;
			Calculator.Quality = GameplanCalculator.Quality;
			Calculator.MarketBackground = GameplanCalculator.MarketBackground;
			Calculator.VeteranFamilyLeads = GameplanCalculator.VeteranFamilyLeads;
			Calculator.ScriptMemorization = GameplanCalculator.ScriptMemorization;
			Calculator.ProductKnowledge = GameplanCalculator.ProductKnowledge;
			Calculator.Activity = GameplanCalculator.Activity;
			Calculator.MobilePlanet = GameplanCalculator.MobilePlanet;
			Calculator.Presenting = GameplanCalculator.Presenting;
			Calculator.AlpPerSaleSelected = GameplanCalculator.AlpPerSaleSelected;
			Calculator.SellingIndividuals = GameplanCalculator.SellingIndividuals;
			Calculator.DownClosing = GameplanCalculator.DownClosing;
			Calculator.SellingSeniors = GameplanCalculator.SellingSeniors;
			Calculator.PhoneSetting = GameplanCalculator.PhoneSetting;
			Calculator.HpPro = GameplanCalculator.HpPro;
			Calculator.LeadershipDevelopment =
				GameplanCalculator.LeadershipDevelopment;
			Calculator.EApp = GameplanCalculator.EApp;
			Calculator.HowGetPaid = GameplanCalculator.HowGetPaid;
			Calculator.FieldTraining = GameplanCalculator.FieldTraining;
			Calculator.ZoomTime = GameplanCalculator.ZoomTime;
			Calculator.ConvertingSponsors = GameplanCalculator.ConvertingSponsors;

			GamePlan.Calculator = Calculator;
		}
		if (this.GamePlan.Goals != null && this.GamePlan.Goals.Id != undefined) {
			var GameplanGoals = this.GamePlan.Goals;
			let Goals: any = {};

			Goals.Id = GameplanGoals.Id;
			Goals.MyWhy = GameplanGoals.MyWhy;
			Goals.CareerGoals = GameplanGoals.CareerGoals;
			Goals.MilestonesOption = GameplanGoals.MilestonesOption;

			GamePlan.Goals = Goals;
		}
		if (
			this.GamePlan.Calendar != null &&
			this.GamePlan.Calendar.Id != undefined
		) {
			var GameplanCalendar = this.GamePlan.Calendar;
			let Calendar: any = {};

			Calendar.Id = GameplanCalendar.Id;
			Calendar.SelectedOptions = this.SelectedOptions; //GameplanCalendar.SelectedOptions;
			Calendar.FieldDays = GameplanCalendar.FieldDays;
			Calendar.OffDays = GameplanCalendar.OffDays;
			Calendar.VacationDays = GameplanCalendar.VacationDays;
			Calendar.Notes = GameplanCalendar.Notes;

			GamePlan.Calendar = Calendar;
		}

		return GamePlan;
	}

	CreateGamePlanUpdateRequest() {
		let isFinacesUpdated = false;
		let isCalculatorUpdated = false;
		let isGoalsUpdated = false;
		let isCalendarUpdated = false;
		let GamePlan: any = {};
		GamePlan.Id = this.GamePlan.Id;
		GamePlan.IncomeGoal = this.GamePlan.IncomeGoal;
		GamePlan.Year = this.GamePlan.Year;
		GamePlan.Month = this.GamePlan.Month;
		GamePlan.MonthId = this.GamePlan.MonthId;

		if (
			this.GamePlan.Finances != null &&
			this.GamePlan.Finances.Id != undefined
		) {
			let orgFinances = JSON.stringify(this.GamePlan.Finances);
			let updatedFinances = JSON.stringify(this.Finances);
			if (orgFinances != updatedFinances) {
				this.GamePlan.Finances_Id = this.Finances.Id;
				this.GamePlan.Finances = this.Finances;
				isFinacesUpdated = true;

				GamePlan.Finances = this.GetUpdatedFinances(this.GamePlan.Finances);
			}
		}
		if (
			this.GamePlan.Calculator != null &&
			this.GamePlan.Calculator.Id != undefined
		) {
			if (
				this.GamePlan.Calculator.Alp == null &&
				this.Calculator.Alp == "0.00"
			) {
				this.Calculator.Alp = this.GamePlan.Calculator.Alp;
			}
			let orgCalculator = JSON.stringify(this.GamePlan.Calculator);
			let updatedCalculator = JSON.stringify(this.Calculator);
			if (orgCalculator != updatedCalculator) {
				this.GamePlan.Calculator_Id = this.Calculator.Id;
				this.GamePlan.Calculator = this.Calculator;
				isCalculatorUpdated = true;

				GamePlan.Calculator = this.GetUpdatedCalculator(
					this.GamePlan.Calculator
				);
			}
		}
		if (this.GamePlan.Goals != null && this.GamePlan.Goals.Id != undefined) {
			let milestoneOptionsUpdated = false;
			if (
				this.GamePlan.Goals.MilestonesOption != null &&
				this.GamePlan.Goals.MilestonesOption != ""
			) {
				let oldMilestoneOptions = JSON.parse(
					this.GamePlan.Goals.MilestonesOption
				);
				this.GoalsMilestonesOption.forEach((options: any) => {
					let old = oldMilestoneOptions.find(
						(x: any) => x.MilestonesOptionId == options.MilestonesOptionId
					);
					if (
						old != null &&
						(((old.Value == undefined || old.Value == false) &&
							options.Value == true) ||
							(old.Value == true &&
								(options.Value == undefined || options.Value == false))) &&
						old.Value != options.Value
					) {
						milestoneOptionsUpdated = true;
					}
				});
			} else {
				milestoneOptionsUpdated =
					this.GoalsMilestonesOption.find((x: any) => x.Value == true) != null;
			}

			this.Goals.MyWhy = this.Goals.MyWhy.filter((x: any) => x !== "");
			this.Goals.CareerGoals = this.Goals.CareerGoals.filter(
				(x: any) => x !== ""
			);
			this.Goals.MyWhy.length <= 0
				? this.Goals.MyWhy.push("")
				: this.Goals.MyWhy;
			this.Goals.CareerGoals.length <= 0
				? this.Goals.CareerGoals.push("")
				: this.Goals.CareerGoals;
			let orgGoals = JSON.stringify(this.GamePlan.Goals);
			let updatedGoals = JSON.stringify(this.Goals);
			if (orgGoals != updatedGoals || milestoneOptionsUpdated) {
				this.Goals.MilestonesOption = JSON.stringify(
					this.GoalsMilestonesOption
				);

				this.GamePlan.Goals_Id = this.Goals.Id;
				this.GamePlan.Goals = this.Goals;
				isGoalsUpdated = true;

				GamePlan.Goals = this.GetUpdatedGoals(this.GamePlan.Goals);
			}
		}
		if (
			this.GamePlan.Calendar != null &&
			this.GamePlan.Calendar.Id != undefined
		) {
			this.Calendar.SelectedOptions = this.SelectedOptions;
			let orgCalendar = JSON.stringify(this.GamePlan.Calendar);
			let updatedCalendar = JSON.stringify(this.Calendar);
			if (orgCalendar != updatedCalendar) {
				this.GamePlan.Calendar_Id = this.Calendar.Id;
				this.GamePlan.Calendar = this.Calendar;
				isCalendarUpdated = true;
				GamePlan.Calendar = this.GetUpdatedCalendar(this.GamePlan.Calendar);
			}
		}
		if (
			isFinacesUpdated ||
			isCalculatorUpdated ||
			isGoalsUpdated ||
			isCalendarUpdated
		) {
			if (isFinacesUpdated && isCalculatorUpdated) {
				if (GamePlan.Finances.IncomeGoal != GamePlan.Calculator.Income) {
					if (this.CurrentScreen == "Finance") {
						if (GamePlan.Finances.IncomeGoal != null) {
							GamePlan.Calculator.Income = GamePlan.Finances.IncomeGoal;
						}
					} else if (this.CurrentScreen == "Calculator") {
						if (GamePlan.Calculator.Income != null) {
							GamePlan.Finances.IncomeGoal = GamePlan.Calculator.Income;
						}
					} else {
						if (GamePlan.Calculator.Income != null) {
							GamePlan.Finances.IncomeGoal = GamePlan.Calculator.Income;
						} else if (GamePlan.Finances.IncomeGoal != null) {
							GamePlan.Calculator.Income = GamePlan.Finances.IncomeGoal;
						}
					}
					// if (GamePlanSaveRequest.Calculator.Income != null) {
					// 	GamePlanSaveRequest.Finances.IncomeGoal =
					// 		GamePlanSaveRequest.Calculator.Income;
					// } else if (GamePlanSaveRequest.Finances.IncomeGoal != null) {
					// 	GamePlanSaveRequest.Calculator.Income =
					// 		GamePlanSaveRequest.Finances.IncomeGoal;
					// }
				}
				return GamePlan;
			} else if (isFinacesUpdated && !isCalculatorUpdated) {
				this.Calculator.Income = this.Finances.IncomeGoal;
				this.GamePlan.Calculator_Id = this.Calculator.Id;
				this.GamePlan.Calculator = this.Calculator;
				isCalculatorUpdated = true;

				GamePlan.Calculator = this.GetUpdatedCalculator(
					this.GamePlan.Calculator
				);
			} else if (!isFinacesUpdated && isCalculatorUpdated) {
				this.Finances.IncomeGoal = this.Calculator.Income;
				this.GamePlan.Finances_Id = this.Finances.Id;
				this.GamePlan.Finances = this.Finances;
				isFinacesUpdated = true;

				GamePlan.Finances = this.GetUpdatedFinances(this.GamePlan.Finances);
			}
			return GamePlan;
		}

		return null;
	}

	GetUpdatedFinances(GameplanFinances: any) {
		let Finance: any = {};
		Finance.Id = GameplanFinances.Id;
		Finance.HousePayment = GameplanFinances.HousePayment;
		Finance.Utilities = GameplanFinances.Utilities;
		Finance.CableInternet = GameplanFinances.CableInternet;
		Finance.CellPhone = GameplanFinances.CellPhone;
		Finance.MonthlyCarPayment = GameplanFinances.MonthlyCarPayment;
		Finance.CarInsurance = GameplanFinances.CarInsurance;
		Finance.Gas = GameplanFinances.Gas;
		Finance.Travel = GameplanFinances.Travel;
		Finance.MinDebtPay = GameplanFinances.MinDebtPay;
		Finance.FoodEntertainment = GameplanFinances.FoodEntertainment;
		Finance.Insurance = GameplanFinances.Insurance;
		Finance.ChildCare = GameplanFinances.ChildCare;
		Finance.Misc1 = GameplanFinances.Misc1;
		Finance.Misc2 = GameplanFinances.Misc2;
		Finance.RecruitingInvestment = GameplanFinances.RecruitingInvestment;
		Finance.AgencyInvestment = GameplanFinances.AgencyInvestment;
		Finance.Mortgage = GameplanFinances.Mortgage;
		Finance.CreditCards = GameplanFinances.CreditCards;
		Finance.CarPayment = GameplanFinances.CarPayment;
		Finance.LinesOfCredit = GameplanFinances.LinesOfCredit;
		Finance.PersonalLoans = GameplanFinances.PersonalLoans;
		Finance.Other1 = GameplanFinances.Other1;
		Finance.Other2 = GameplanFinances.Other2;
		Finance.IncomeGoal = GameplanFinances.IncomeGoal;
		// Finance.ProductionGoals = GameplanFinances.ProductionGoals;
		Finance.IncomeCommitment = GameplanFinances.IncomeCommitment;
		// Finance.ProductionCommitment = GameplanFinances.ProductionCommitment;
		Finance.LessOtherIncome = GameplanFinances.LessOtherIncome;
		return Finance;
	}

	GetUpdatedGoals(GameplanGoals: any) {
		let Goals: any = {};

		Goals.Id = GameplanGoals.Id;
		Goals.MyWhy = GameplanGoals.MyWhy;
		Goals.CareerGoals = GameplanGoals.CareerGoals;
		Goals.MilestonesOption = GameplanGoals.MilestonesOption;

		return Goals;
	}

	GetUpdatedCalculator(GameplanCalculator: any) {
		let Calculator: any = {};

		Calculator.Id = GameplanCalculator.Id;
		Calculator.IsIncomeSelected = GameplanCalculator.IsIncomeSelected;
		Calculator.Income = GameplanCalculator.Income;
		Calculator.Alp = GameplanCalculator.Alp;
		Calculator.Contract = GameplanCalculator.Contract;
		Calculator.AlpPerSale = GameplanCalculator.AlpPerSale;
		Calculator.Show = GameplanCalculator.Show;
		Calculator.Bonus = GameplanCalculator.Bonus;
		Calculator.Close = GameplanCalculator.Close;
		Calculator.Ntg = GameplanCalculator.Ntg;
		Calculator.SponsorCollection = GameplanCalculator.SponsorCollection;
		Calculator.Quality = GameplanCalculator.Quality;
		Calculator.MarketBackground = GameplanCalculator.MarketBackground;
		Calculator.VeteranFamilyLeads = GameplanCalculator.VeteranFamilyLeads;
		Calculator.ScriptMemorization = GameplanCalculator.ScriptMemorization;
		Calculator.ProductKnowledge = GameplanCalculator.ProductKnowledge;
		Calculator.Activity = GameplanCalculator.Activity;
		Calculator.MobilePlanet = GameplanCalculator.MobilePlanet;
		Calculator.Presenting = GameplanCalculator.Presenting;
		Calculator.AlpPerSaleSelected = GameplanCalculator.AlpPerSaleSelected;
		Calculator.SellingIndividuals = GameplanCalculator.SellingIndividuals;
		Calculator.DownClosing = GameplanCalculator.DownClosing;
		Calculator.SellingSeniors = GameplanCalculator.SellingSeniors;
		Calculator.PhoneSetting = GameplanCalculator.PhoneSetting;
		Calculator.HpPro = GameplanCalculator.HpPro;
		Calculator.LeadershipDevelopment = GameplanCalculator.LeadershipDevelopment;
		Calculator.EApp = GameplanCalculator.EApp;
		Calculator.HowGetPaid = GameplanCalculator.HowGetPaid;
		Calculator.FieldTraining = GameplanCalculator.FieldTraining;
		Calculator.ZoomTime = GameplanCalculator.ZoomTime;
		Calculator.ConvertingSponsors = GameplanCalculator.ConvertingSponsors;

		return Calculator;
	}

	GetUpdatedCalendar(GameplanCalendar: any) {
		let Calendar: any = {};

		Calendar.Id = GameplanCalendar.Id;
		Calendar.SelectedOptions = GameplanCalendar.SelectedOptions;
		Calendar.FieldDays = GameplanCalendar.FieldDays;
		Calendar.OffDays = GameplanCalendar.OffDays;
		Calendar.VacationDays = GameplanCalendar.VacationDays;
		Calendar.Notes = GameplanCalendar.Notes;

		return Calendar;
	}

	get MonthsOfYearList() {
		let gameplanlist = GamePlanEntity.query()
			.where("Year", this.SelectedYear)
			.withAllRecursive()
			.get();
		this.GamePlanList = gameplanlist;

		this.MonthsOfYear.forEach((element: { view: any; id: any }) => {
			var ifmonthexist = this.GamePlanList.find(
				(x: { MonthId: any }) => x.MonthId == element.id
			);
			element.view = ifmonthexist != undefined ? true : false;
		});

		return this.MonthsOfYear;
	}

	OnChangeGamePlanYear() {
		this.showFinanceScreen = false;
		this.showGoalsScreen = false;
		this.showCalendarScreen = false;
		this.showMyFiveScreen = false;
		this.showCalculatorScreen = false;
		this.GamePlanList = {};
		this.GamePlan = {};
		let gameplanlist = GamePlanEntity.query()
			.where("Year", this.SelectedYear)
			.withAllRecursive()
			.get();
		this.GamePlanList = gameplanlist;

		this.MonthsOfYear.forEach((element: { view: any; id: any }) => {
			var ifmonthexist = this.GamePlanList.find(
				(x: { MonthId: any }) => x.MonthId == element.id
			);
			element.view = ifmonthexist != undefined ? true : false;
		});
	}

	async FetchGamePlans() {
		if (this.GamePlan != null && this.GamePlan.Id != undefined) {
			await this.AutoSaveGamePlan();
		}
		this.$store.state.loader = true;
		var gameplanslist: any = await getPersistedState("GamePlans");
		var ifgameplansexist = gameplanslist.find(
			(x: any) => x.Year == this.SelectedYear
		);
		if (ifgameplansexist) {
			this.$store.state.loader = false;
			this.OnChangeGamePlanYear();
		} else {
			let years = [this.SelectedYear];
			APIService.GetGamePlansByYear(years).then(async (res: any) => {
				if (res != undefined && res.Result != undefined) {
					let gamePlans = [];
					deletePersistedState("GamePlans");
					GamePlanEntity.deleteAll();
					gamePlans.push(...gameplanslist);
					gamePlans.push(...res.Result);
					this.$store.state.loader = false;
					let GamePlanList = new GenericKeyPair("GamePlans", gamePlans);
					GamePlanEntity.insertOrUpdate({ data: gamePlans });
					setPersistedState(GamePlanList);
					this.OnChangeGamePlanYear();
				}
			});
		}
	}
	returnDate(selectedMonth: any, PrintCalendar: boolean = false) {
		if (!PrintCalendar) {
			this.weeksinMonth = [];
			var totalDays = moment(
				selectedMonth.Id + " - " + this.SelectedYear,
				"MM-YYYY"
			).daysInMonth();
			var firstDay = moment(
				selectedMonth.Id + " - " + this.SelectedYear,
				"MM-YYYY"
			).startOf("month");
			this.totalMonthDays = totalDays;
			var weekdays = [];
			var count = [];
			var days = 0;
			var test = Object.assign({ id: 0, day: [], daynumber: [] });
			var weekdays = [];
			var weeknumber, prevweeknumber;
			for (let i = 0; i < totalDays; i++) {
				weeknumber = moment(firstDay)
					.add(i + 1, "days")
					.isoWeek();
				if (weekdays.indexOf(weeknumber) === -1) {
					if (test.day.length > 0) this.weeksinMonth.push(test);
					var test = Object.assign({ id: 0, day: [], daynumber: [] });
					weekdays.push(weeknumber);
					test.id = weeknumber;
					prevweeknumber = weeknumber;
					console.log(prevweeknumber);
				}

				days = moment(firstDay).add(i, "days").day();
				test.day[days] = moment(firstDay).add(i, "days").format("D");
				test.daynumber[days] = moment(firstDay).add(i, "days").day();
				if (i == totalDays - 1) {
					if (test.day.length > 0) this.weeksinMonth.push(test);
				}
			}
			console.log("blurred ", this.weeksinMonth);
			return this.weeksinMonth;
		} else {
			this.weeksinMonthtoPrint = [];
			var totalDays = moment(
				selectedMonth.Id + " - " + this.SelectedYear,
				"MM-YYYY"
			).daysInMonth();
			var firstDay = moment(
				selectedMonth.Id + " - " + this.SelectedYear,
				"MM-YYYY"
			).startOf("month");
			this.totalDaystoPrint = totalDays;
			var weekdays = [];
			var count = [];
			var days = 0;
			var test = Object.assign({ id: 0, day: [], daynumber: [] });
			var weekdays = [];
			var weeknumber, prevweeknumber;
			for (let i = 0; i < totalDays; i++) {
				weeknumber = moment(firstDay)
					.add(i + 1, "days")
					.isoWeek();
				if (weekdays.indexOf(weeknumber) === -1) {
					if (test.day.length > 0) this.weeksinMonthtoPrint.push(test);
					var test = Object.assign({ id: 0, day: [], daynumber: [] });
					weekdays.push(weeknumber);
					test.id = weeknumber;
					prevweeknumber = weeknumber;
					console.log(prevweeknumber);
				}

				days = moment(firstDay).add(i, "days").day();
				test.day[days] = moment(firstDay).add(i, "days").format("D");
				test.daynumber[days] = moment(firstDay).add(i, "days").day();
				if (i == totalDays - 1) {
					if (test.day.length > 0) this.weeksinMonthtoPrint.push(test);
				}
			}
			console.log("blurred ", this.weeksinMonthtoPrint);
			return this.weeksinMonthtoPrint;
		}
	}
	hasDownloaded() {
		this.$store.state.loader = false;
	}
	isNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			return true;
		}
	}
	isPercentNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			var resValue = evt.target.value + evt.key;
			if (resValue <= 100) return true;
			else evt.preventDefault();
		}
	}

	ApplyValuebyFieldString(fieldString: string, value: string) {
		var properties = fieldString.split(".");
		var setPropertyvalue: any = this.$data;
		if (properties != undefined && properties.length > 0) {
			var i = 1;
			properties.forEach((element: any) => {
				if (i == properties.length) {
					setPropertyvalue[element] = value;
				} else {
					setPropertyvalue = setPropertyvalue[element];
				}
				i++;
			});
		}
	}

	AmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}
		var newValue = value;

		if (value == "" || value == undefined) {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		} else {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal(
					Number(value.replaceAll(",", "")).toFixed(2),
					true,
					true
				)
			);
		}
	}
	CalendarNotesBlur(evt: any) {
		let value = evt.target.value;
		if (value == "" || !value.replace(/\s/g, "").length) {
			this.Calendar.Notes = null;
		}
	}
	GoalsFieldBlur(evt: any, index: any, fieldName: any) {
		let value = evt.target.value;
		if (!value.replace(/\s/g, "").length) {
			if (fieldName == "MyWhy") {
				this.Goals.MyWhy[index] = "";
			} else if (fieldName == "CareerGoals") {
				this.Goals.CareerGoals[index] = "";
			}
		}
	}
	PercentageAmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}
		var newValue = value;

		if (value == "" || value == undefined) {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		}
		//  else if (value == "100") {
		// 	this.ApplyValuebyFieldString(
		// 		fieldString,
		// 		ConvertIntoAmountFormatWithDecimal(
		// 			value.replaceAll(",", "").toFixed(0),
		// 			true,
		// 			false
		// 		)
		// 	);
		// }
		else {
			if (value.split(".")[1]?.length > 2) {
				this.ApplyValuebyFieldString(
					fieldString,
					ConvertIntoAmountFormatWithDecimal(
						Number(value.replaceAll(",", "")).toFixed(2),
						true,
						false
					)
				);
			} else if (value.split(".")[1]?.length == undefined) {
				this.ApplyValuebyFieldString(
					fieldString,
					ConvertIntoAmountFormatWithDecimal(
						Number(value.replaceAll(",", "")).toFixed(0),
						true,
						false
					)
				);
			} else {
				this.ApplyValuebyFieldString(
					fieldString,
					ConvertIntoAmountFormatWithDecimal(
						Number(value.replaceAll(",", "")).toFixed(
							value.split(".")[1]?.length
						),
						true,
						false
					)
				);
			}
		}
	}

	// PercentageAmountFieldBlur(evt: any, valuae: any) {
	// 	let value = evt.target.value;
	// 	if (value == "") {
	// 		this.ApplyValuebyFieldString(valuae, "0%");
	// 	} else
	// 		this.ApplyValuebyFieldString(
	// 			valuae,
	// 			Number.parseFloat(evt.target.value).toFixed(2) + "%"
	// 		);
	// }

	InputAmountField(evt: any, fieldName: string = "") {
		if (
			evt.target != undefined &&
			evt.target.value != undefined &&
			evt.target.value != ""
		) {
			if (evt.target.value != "0") {
				var previusSelectionIndex = evt.target.selectionStart;
				evt.target.value = ConvertIntoAmountFormatWithDecimal(evt.target.value);
				var newSelectionIndex = evt.target.selectionStart;
				if (newSelectionIndex > previusSelectionIndex + 1) {
					evt.target.setSelectionRange(
						previusSelectionIndex,
						previusSelectionIndex
					);
				}
			}
		}
	}
	onAddMyWhyFields() {
		// if (this.Goals.MyWhy.length == 1) this.MyWhyDelete = false;
		this.Goals.MyWhy.push("");

		setTimeout(() => {
			const ele: any = document.getElementById("Goals-MyWhy");
			ele.scrollTop = ele.scrollHeight;
		}, 10);
	}
	onAddCareerGoalsFields() {
		if (this.Goals.CareerGoals.length == 1) this.CareerGoalsDelete = false;
		this.Goals.CareerGoals.push("");
		setTimeout(() => {
			const ele: any = document.getElementById("Goals-CareerGoals");
			ele.scrollTop = ele.scrollHeight;
		}, 10);
	}
	onDeleteMyWhyFields(location: any) {
		if (this.Goals.MyWhy.length > 1) this.Goals.MyWhy.splice(location, 1);
		// else this.MyWhyDelete = false;
	}
	onDeleteCareerGoalsFields(location: any) {
		if (this.Goals.CareerGoals.length > 1)
			this.Goals.CareerGoals.splice(location, 1);
		// else this.CareerGoalsDelete = false;
	}

	onUpdateAttendanceOption(monthday: any, selectedOption: any) {
		this.SelectedOptions[Number(monthday) - 1] = selectedOption;
		this.UpdateDaysCount();
	}
	UpdateDaysCount() {
		var FieldDays = 0;
		var OffDays = 0;
		var VacationDays = 0;
		this.SelectedOptions.forEach((element: any) => {
			if (element == this.attendanceOptions[0].text) FieldDays++;
			else if (element == this.attendanceOptions[1].text) VacationDays++;
			else if (element == this.attendanceOptions[2].text) OffDays++;
		});
		this.Calendar.FieldDays = FieldDays;
		this.Calendar.OffDays = OffDays;
		this.Calendar.VacationDays = VacationDays;

		return this.SelectedOptions;
	}

	get GetFieldDays() {
		return this.Calendar.FieldDays;
	}
	get GetOffDays() {
		return this.Calendar.OffDays;
	}
	get GetVacationDays() {
		return this.Calendar.VacationDays;
	}
	get MonthlyObligationsToPrint() {
		{
			let test = 0;
			test += GetDecimalvaluefromString(this.FinancestoPrint.HousePayment);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Utilities);
			test += GetDecimalvaluefromString(this.FinancestoPrint.CableInternet);
			test += GetDecimalvaluefromString(this.FinancestoPrint.CellPhone);
			test += GetDecimalvaluefromString(this.FinancestoPrint.MonthlyCarPayment);
			test += GetDecimalvaluefromString(this.FinancestoPrint.CarInsurance);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Gas);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Travel);
			test += GetDecimalvaluefromString(this.FinancestoPrint.MinDebtPay);
			test += GetDecimalvaluefromString(this.FinancestoPrint.FoodEntertainment);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Insurance);
			test += GetDecimalvaluefromString(this.FinancestoPrint.ChildCare);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Misc1);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Misc2);
			test += GetDecimalvaluefromString(
				this.FinancestoPrint.RecruitingInvestment
			);
			test += GetDecimalvaluefromString(this.FinancestoPrint.AgencyInvestment);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Mortgage);
			test += GetDecimalvaluefromString(this.FinancestoPrint.CreditCards);
			test += GetDecimalvaluefromString(this.FinancestoPrint.CarPayment);
			test += GetDecimalvaluefromString(this.FinancestoPrint.LinesOfCredit);
			test += GetDecimalvaluefromString(this.FinancestoPrint.PersonalLoans);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Other1);
			test += GetDecimalvaluefromString(this.FinancestoPrint.Other2);

			return ConvertIntoAmountFormatWithDecimal(test.toFixed(2).toString());
		}
	}
	get CashNeedtoPrint() {
		let test = 0;
		test =
			GetDecimalvaluefromString(this.MonthlyObligationsToPrint) -
			GetDecimalvaluefromString(this.FinancestoPrint.LessOtherIncome);
		let test1 = ConvertIntoAmountFormatWithNegativeDecimal(
			test.toFixed(2).toString()
		);
		if (test1.includes("-")) return ConvertIntoAmountFormatWithDecimal("0.00");
		else return test1;
	}
	get ProductionCommitmenttoPrint() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.FinancestoPrint.IncomeCommitment) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		GrossAlp =
			NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}
		return ConvertIntoAmountFormatWithDecimal(GrossAlp.toFixed(2).toString());
	}
	get ProductionGoaltoPrint() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.FinancestoPrint.IncomeGoal) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		GrossAlp =
			NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}
		return ConvertIntoAmountFormatWithDecimal(GrossAlp.toFixed(2).toString());
	}
	get GetNetAlptoPrint() {
		let NetAlp = 0;
		NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);

		if (this.CalculatortoPrint.IsIncomeSelected) {
			if (!isNaN(NetAlp) && NetAlp > 0) {
				this.CalculatortoPrint.Alp = ConvertIntoAmountFormatWithDecimal(
					NetAlp.toFixed(2).toString()
				);
			} else {
				if (this.CalculatortoPrint.Income != null)
					this.CalculatortoPrint.Alp =
						ConvertIntoAmountFormatWithDecimal("0.00");
			}
		} else {
			let Income =
				GetDecimalvaluefromString(this.CalculatortoPrint.Alp) *
				((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
					100 /
					(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
					GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
			if (
				this.CalculatortoPrint.Alp ==
					ConvertIntoAmountFormatWithDecimal(NetAlp.toFixed(2).toString()) &&
				this.CalculatortoPrint.Income ==
					ConvertIntoAmountFormatWithDecimal(Income.toFixed(2).toString())
			) {
				return ConvertIntoAmountFormatWithDecimal(NetAlp.toFixed(2).toString());
			} else if (
				GetDecimalvaluefromString(this.CalculatortoPrint.Alp) !=
					GetDecimalvaluefromString(NetAlp.toFixed(2)) &&
				GetDecimalvaluefromString(this.CalculatortoPrint.Income) !=
					GetDecimalvaluefromString(Income.toFixed(2)) &&
				Income != 0 &&
				NetAlp != 0
			) {
				if (this.isfirsttimeCalculating) {
					this.isfirsttimeCalculating = false;
					this.CalculatortoPrint.Alp = ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
					return ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
				} else {
					NetAlp = GetDecimalvaluefromString(this.CalculatortoPrint.Alp);
					this.CalculatortoPrint.Income = ConvertIntoAmountFormatWithDecimal(
						Income.toFixed(2).toString()
					);
					return ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
				}
			}
			if (!isNaN(Income) && Income > 0) {
				NetAlp = GetDecimalvaluefromString(this.CalculatortoPrint.Alp);
				this.CalculatortoPrint.Income = ConvertIntoAmountFormatWithDecimal(
					Income.toFixed(2).toString()
				);
			} else {
				if (
					this.CalculatortoPrint.Alp == "0.00" &&
					Income == 0 &&
					GetDecimalvaluefromString(this.CalculatortoPrint.Income) > 0
				) {
					this.CalculatortoPrint.Alp = ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
					return ConvertIntoAmountFormatWithDecimal(
						NetAlp.toFixed(2).toString()
					);
				}
				this.CalculatortoPrint.Income =
					ConvertIntoAmountFormatWithDecimal("0.00");
				return ConvertIntoAmountFormatWithDecimal("0.00");
			}
		}

		if (isNaN(NetAlp) && this.CalculatortoPrint.Income != null) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}
		if (!isNaN(NetAlp))
			return ConvertIntoAmountFormatWithDecimal(NetAlp.toFixed(2).toString());
	}
	get GetGrossAlptoPrint() {
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		GrossAlp =
			NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100);
		if (isNaN(GrossAlp)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(GrossAlp.toFixed(2).toString());
	}

	get GetAdvancetoPrint() {
		let Advance = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		Advance =
			(NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100)) *
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100);
		if (isNaN(Advance)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(Advance.toFixed(2).toString());
	}
	get GetBonustoPrint() {
		let Bonus = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);

		Bonus =
			NetAlp * (GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		if (isNaN(Bonus)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(Bonus.toFixed(2).toString());
	}
	get GetAlpPerMonthtoPrint() {
		let AlpPerMonth = this.GetGrossAlptoPrint;
		return AlpPerMonth;
	}

	get GetSalesPerMonthtoPrint() {
		let SalesPerMonth = 0;
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		GrossAlp =
			NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100);

		SalesPerMonth =
			GrossAlp / GetDecimalvaluefromString(this.CalculatortoPrint.AlpPerSale);

		if (
			isNaN(SalesPerMonth) ||
			SalesPerMonth == Infinity ||
			SalesPerMonth == 0
		) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}
		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(SalesPerMonth).toString()
		);
	}
	get GetPresPerMonthtoPrint() {
		let PresPerMonth = 0;
		let SalesPerMonth = 0;
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		GrossAlp =
			NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100);

		SalesPerMonth =
			GrossAlp / GetDecimalvaluefromString(this.CalculatortoPrint.AlpPerSale);
		PresPerMonth =
			SalesPerMonth /
			(GetDecimalvaluefromString(this.CalculatortoPrint.Close) / 100);

		if (isNaN(PresPerMonth) || PresPerMonth == Infinity || PresPerMonth == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}
		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(PresPerMonth).toString()
		);
	}
	get GetApptsPerMonthtoPrint() {
		let ApptsPerMonth = 0;
		let PresPerMonth = 0;
		let SalesPerMonth = 0;
		let GrossAlp = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		GrossAlp =
			NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100);
		SalesPerMonth =
			GrossAlp / GetDecimalvaluefromString(this.CalculatortoPrint.AlpPerSale);
		PresPerMonth =
			SalesPerMonth /
			(GetDecimalvaluefromString(this.CalculatortoPrint.Close) / 100);

		ApptsPerMonth =
			PresPerMonth /
			(GetDecimalvaluefromString(this.CalculatortoPrint.Show) / 100);

		if (
			isNaN(ApptsPerMonth) ||
			ApptsPerMonth == Infinity ||
			ApptsPerMonth == 0
		) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}
		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(ApptsPerMonth).toString()
		);
	}

	get GetAlpPerWeektoPrint() {
		let AlpPerWeek = 0;
		AlpPerWeek = GetDecimalvaluefromString(this.GetAlpPerMonthtoPrint) / 4.33;

		if (isNaN(AlpPerWeek) || AlpPerWeek == Infinity) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(AlpPerWeek.toFixed(2).toString());
	}

	get GetSalesPerWeektoPrint() {
		let SalesPerWeek = 0;
		SalesPerWeek =
			GetDecimalvaluefromString(this.GetSalesPerMonthtoPrint) / 4.33;

		if (isNaN(SalesPerWeek) || SalesPerWeek == Infinity || SalesPerWeek == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(SalesPerWeek).toString()
		);
	}

	get GetPresPerWeektoPrint() {
		let PresPerWeek = 0;
		PresPerWeek = GetDecimalvaluefromString(this.GetPresPerMonthtoPrint) / 4.33;

		if (isNaN(PresPerWeek) || PresPerWeek == Infinity || PresPerWeek == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(PresPerWeek).toString()
		);
	}
	get GetApptsPerWeektoPrint() {
		let ApptsPerWeek = 0;
		ApptsPerWeek =
			GetDecimalvaluefromString(this.GetApptsPerMonthtoPrint) / 4.33;

		if (isNaN(ApptsPerWeek) || ApptsPerWeek == Infinity || ApptsPerWeek == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(ApptsPerWeek).toString(),
			false
		);
	}

	get GetAlpPerDaytoPrint() {
		let AlpPerDay = 0;
		AlpPerDay =
			GetDecimalvaluefromString(this.GetAlpPerMonthtoPrint) /
			this.CalendartoPrint.FieldDays;

		if (isNaN(AlpPerDay) || AlpPerDay == Infinity) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(AlpPerDay.toFixed(2).toString());
	}

	get GetSalesPerDaytoPrint() {
		let SalesPerDay = 0;
		SalesPerDay =
			GetDecimalvaluefromString(this.GetSalesPerMonthtoPrint) /
			this.CalendartoPrint.FieldDays;

		if (isNaN(SalesPerDay) || SalesPerDay == Infinity || SalesPerDay == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(SalesPerDay).toString()
		);
	}

	get GetPresPerDaytoPrint() {
		let PresPerDay = 0;
		PresPerDay =
			GetDecimalvaluefromString(this.GetPresPerMonthtoPrint) /
			this.CalendartoPrint.FieldDays;

		if (isNaN(PresPerDay) || PresPerDay == Infinity || PresPerDay == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(Math.ceil(PresPerDay).toString());
	}

	get GetApptsPerDaytoPrint() {
		let ApptsPerDay = 0;
		ApptsPerDay =
			GetDecimalvaluefromString(this.GetApptsPerMonthtoPrint) /
			this.CalendartoPrint.FieldDays;

		if (isNaN(ApptsPerDay) || ApptsPerDay == Infinity || ApptsPerDay == 0) {
			return ConvertIntoAmountFormatWithDecimal("0.00", false);
		}

		return ConvertIntoAmountFormatWithDecimal(
			Math.ceil(ApptsPerDay).toString()
		);
	}

	get GetTotalIncometoPrint() {
		let TotalIncome = 0;
		let NetAlp =
			GetDecimalvaluefromString(this.CalculatortoPrint.Income) /
			((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
				100 /
				(GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100) +
				GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		TotalIncome =
			(NetAlp / (GetDecimalvaluefromString(this.CalculatortoPrint.Ntg) / 100)) *
				((0.65 * GetDecimalvaluefromString(this.CalculatortoPrint.Contract)) /
					100) +
			NetAlp * (GetDecimalvaluefromString(this.CalculatortoPrint.Bonus) / 100);
		if (isNaN(TotalIncome)) {
			return ConvertIntoAmountFormatWithDecimal("0.00");
		}

		return ConvertIntoAmountFormatWithDecimal(
			TotalIncome.toFixed(2).toString()
		);
	}

	@Watch("updatedIncome")
	updatedIncomeChange(newValue: any, oldValue: any) {
		if (!this.firstTimePopulate) {
			if (
				GetDecimalvaluefromString(newValue) >
				GetDecimalvaluefromString(this.Finances.IncomeGoal)
			)
				this.Finances.IncomeGoal = newValue;
			if (newValue.includes("-")) this.Finances.IncomeCommitment = "0.00";
			else this.Finances.IncomeCommitment = newValue;
		} else {
			this.firstTimePopulate = false;
		}
	}

	async RoutedTo(route: String) {
		if (route != "/gameplan") {
			if (this.GamePlan != null && this.GamePlan.Id != undefined) {
				await this.AutoSaveGamePlan();
			}
			GamePlanEntity.deleteAll();
			this.$router.push(route.toString());
		}
	}

	async saveGamePlanCall(GamePlanSaveRequest: any) {
		this.$store.state.loader = true;
		var GenericKeyPairobj: GenericKeyPair[] = [];
		// let RequestObj = Object.assign({ ...GamePlanSaveRequest });
		// RequestObj.Id = null;
		// RequestObj.Finances.Id = null;
		// RequestObj.Goals.Id = null;
		// RequestObj.Calendar.Id = null;
		// RequestObj.Calculator.Id = null;
		await APIService.SaveGamePlan(GamePlanSaveRequest, GenericKeyPairobj).then(
			async (res: any) => {
				if (res != undefined && res.data != null && res.data == true) {
					this.$store.state.loader = false;
					GamePlanEntity.insertOrUpdate({
						data: GamePlanSaveRequest,
					});
					let LocalGamePlan = new GenericKeyPair(
						"GamePlans",
						GamePlanEntity.query().withAllRecursive().get()
					);
					setPersistedState(LocalGamePlan);
					this.OnChangeGamePlanYear();
					this.$store.state.messageText = "Game Plan is saved successfully";
					this.$store.state.showMessage = true;
				} else {
					this.$store.state.loader = false;
					this.$store.state.messageText = "Unable to save Game Plan";
					this.$store.state.showMessage = true;
				}
				this.GamePlan = {};
			}
		);
	}

	async UpdateGamePlanCall(GamePlanSaveRequest: any) {
		this.$store.state.loader = true;
		//var promises: Promise<any>[] = [];
		var GenericKeyPairobj: GenericKeyPair[] = [];
		// let RequestObj = Object.assign({ ...GamePlanSaveRequest });
		// RequestObj.Id = null;
		// RequestObj.Finances.Id = null;
		// RequestObj.Goals.Id = null;
		// RequestObj.Calendar.Id = null;
		// RequestObj.Calculator.Id = null;
		await APIService.UpdateGamePlan(
			GamePlanSaveRequest,
			GenericKeyPairobj
		).then(async (res: any) => {
			if (res != undefined && res.data != null && res.data == true) {
				this.$store.state.loader = false;
				GamePlanEntity.insertOrUpdate({
					data: GamePlanSaveRequest,
				});
				let LocalGamePlan = new GenericKeyPair(
					"GamePlans",
					GamePlanEntity.query().withAllRecursive().get()
				);
				setPersistedState(LocalGamePlan);
				this.OnChangeGamePlanYear();
				this.$store.state.messageText = this.$t("GamePlanPage.SavedText");
				this.$store.state.showMessage = true;
			} else {
				this.$store.state.loader = false;
				this.$store.state.messageText = this.$t("GamePlanPage.NotSavedText");
				this.$store.state.showMessage = true;
			}
			this.GamePlan = {};
		});
	}

	async PrintGamePlan(month: any, monthId: any) {
		// if (this.GamePlan.MonthId == null || this.GamePlan.MonthId != monthId) {

		month = this.$t(`GamePlanPage.Months.${month}`);
		this.$store.state.loader = true;
		this.GamePlantoPrint = this.GamePlanList.find(
			(x: { MonthId: any }) => x.MonthId == monthId
		);
		this.MonthtoPrint = {
			Name: month.toUpperCase(),
			Id: monthId,
		};
		this.FinancestoPrint = Object.assign({ ...this.GamePlantoPrint.Finances });
		var obj = this.FinancestoPrint;
		Object.keys(obj).forEach((key) => {
			if (!obj[key]) obj[key] = "0.00";
		});
		this.GoalstoPrint = Object.assign({ ...this.GamePlantoPrint.Goals });
		this.GoalstoPrint.MyWhy = this.GamePlantoPrint.Goals.MyWhy.slice(0);
		this.GoalstoPrint.CareerGoals =
			this.GamePlantoPrint.Goals.CareerGoals.slice(0);
		await this.GetMilestoneOptionsToPrint(
			JSON.parse(this.GoalstoPrint.MilestonesOption)
		);
		// this.GoalstoPrint.MyWhy.length <= 0
		// 	? this.GoalstoPrint.MyWhy.push("a")
		// 	: this.GoalstoPrint.MyWhy;
		// this.GoalstoPrint.CareerGoals.length <= 0
		// 	? this.GoalstoPrint.CareerGoals.push("a")
		// 	: this.GoalstoPrint.CareerGoals;
		this.CalendartoPrint = Object.assign({ ...this.GamePlantoPrint.Calendar });
		this.returnDate(this.MonthtoPrint, true);
		this.CalendartoPrint.SelectedOptions =
			this.GamePlantoPrint.Calendar.SelectedOptions.slice(0);
		this.CalculatortoPrint = Object.assign({
			...this.GamePlantoPrint.Calculator,
		});
		// }
		var obj1 = this.CalculatortoPrint;
		Object.keys(obj1).forEach((key) => {
			if (obj1[key] == null) obj1[key] = "0.00";
		});
		this.$data.pdfoptions.filename =
			this.MonthtoPrint.Name + "-" + this.SelectedYear;
		setTimeout(() => {
			this.$refs.html2Pdf.generatePdf();
			// this.$store.state.loader = false;
		}, 3000);
	}
}
