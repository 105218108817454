export enum VeteranCurrentVSOMember {
	//[Description("N/A")]
	na,
	//[Description("African American PTSD Association")]
	african_american_ptsd_association,
	//[Description("Alabama Department of Veterans Affairs")]
	alabama_department_of_veterans_affairs,
	//[Description("American Legion")]
	american_legion,
	//[Description("American Red Cross")]
	american_red_cross,
	//[Description("American Veterans")]
	american_veterans,
	//[Description("Arizona Department of Veterans Services")]
	arizona_department_of_veterans_services,
	//[Description("Arkansas Department of Veterans Affairs")]
	arkansas_department_of_veterans_affairs,
	//[Description("Armed Forces Services Corporation")]
	armed_forces_services_corporation,
	//[Description("Army and Navy Union")]
	army_and_navy_union,
	//[Description("Blinded Veterans Association")]
	blinded_veterans_association,
	//[Description("California Department of Veterans Services")]
	california_department_of_veterans_services,
	//[Description("Catholic War Veterans of the USA")]
	catholic_war_veterans_of_the_usa,
	//[Description("Colorado Division of Veterans Affairs")]
	colorado_division_of_veterans_affairs,
	//[Description("Commonwealth of the Northern Mariana Islands Division")]
	commonwealth_of_the_northern_mariana_islands_division,
	//[Description("Connecticut Department of Veterans Affairs")]
	connecticut_department_of_veterans_affairs,
	//[Description("Dale K. Graham Veterans Foundation")]
	dale_k_graham_veterans_foundation,
	//[Description("Delaware Commission of Veterans Affairs")]
	delaware_commission_of_veterans_affairs,
	//[Description("Disabled American Veterans")]
	disabled_american_veterans,
	//[Description("Fleet Reserve Association")]
	fleet_reserve_association,
	//[Description("Florida Department of Veterans Affairs")]
	florida_department_of_veterans_affairs,
	//[Description("Gold Star Wives of America (G.S.W.A)")]
	gold_star_wives_of_america_gswa,
	//[Description("Georgia Department of Veterans Service")]
	georgia_department_of_veterans_service,
	//[Description("Gila River Indian Community Vet.&Fam. Svcs Office")]
	gila_river_indian_community_vetfam_svcs_office,
	//[Description("Green Beret Foundation")]
	green_beret_foundation,
	//[Description("Guam Office of Veterans Affairs")]
	guam_office_of_veterans_affairs,
	//[Description("Hawaii Office of Veterans Services")]
	hawaii_office_of_veterans_services,
	//[Description("Idaho Division of Veterans Services")]
	idaho_division_of_veterans_services,
	//[Description("Illinois Department of Veterans Affairs")]
	illinois_department_of_veterans_affairs,
	//[Description("Indiana Department of Veterans Affairs")]
	indiana_department_of_veterans_affairs,
	//[Description("Iowa Department of Veterans Affairs")]
	iowa_department_of_veterans_affairs,
	//[Description("Italian American War Veterans of the US")]
	italian_american_war_veterans_of_the_us,
	//[Description("Jewish War Veterans of the USA")]
	jewish_war_veterans_of_the_usa,
	//[Description("Kansas Commission on Veterans' Affairs")]
	kansas_commission_on_veterans_affairs,
	//[Description("Kentucky Department of Veterans Affairs")]
	kentucky_department_of_veterans_affairs,
	//[Description("Legion of Valor of the USA, Inc.")]
	legion_of_valor_of_the_usa_inc,
	//[Description("Louisiana Department of Veterans Affairs")]
	louisiana_department_of_veterans_affairs,
	//[Description("Maine Veterans' Services")]
	maine_veterans_services,
	//[Description("Marine Corps League")]
	marine_corps_league,
	//[Description("Maryland Department of Veterans Affairs")]
	maryland_department_of_veterans_affairs,
	//[Description("Massachusetts Department of Veterans' Services")]
	massachusetts_department_of_veterans_services,
	//[Description("Michigan Veterans Affairs Agency")]
	michigan_veterans_affairs_agency,
	//[Description("Military Officers Association of America")]
	military_officers_association_of_america,
	//[Description("Minnesota Department of Veterans Affairs")]
	minnesota_department_of_veterans_affairs,
	//[Description("Mississippi Veterans Affairs")]
	mississippi_veterans_affairs,
	//[Description("Missouri Veterans Commission")]
	missouri_veterans_commission,
	//[Description("Montana Veterans Affairs (MVAD)")]
	montana_veterans_affairs_mvad,
	//[Description("National Association for Black Veterans")]
	national_association_for_black_veterans,
	//[Description("National Association of County Veterans Service Officers")]
	national_association_of_county_veterans_service_officers,
	//[Description("National Law School Veterans Clinic Consortium")]
	national_law_school_veterans_clinic_consortium,
	//[Description("National Montford Point Marine Association")]
	national_montford_point_marine_association,
	//[Description("National Veterans Legal Services Program")]
	national_veterans_legal_services_program,
	//[Description("National Veterans Organization of America")]
	national_veterans_organization_of_america,
	//[Description("Navajo Nation Veterans Administration")]
	navajo_nation_veterans_administration,
	//[Description("Navy Mutual Aid Association")]
	navy_mutual_aid_association,
	//[Description("Nebraska Department of Veterans Affairs")]
	nebraska_department_of_veterans_affairs,
	//[Description("Nevada Department of Veterans Services")]
	nevada_department_of_veterans_services,
	//[Description("New Hampshire Division of Veteran Services")]
	new_hampshire_division_of_veteran_services,
	//[Description("New Jersey Department of Military and Veterans Affairs")]
	new_jersey_department_of_military_and_veterans_affairs,
	//[Description("New Mexico Department of Veterans Services")]
	new_mexico_department_of_veterans_services,
	//[Description("New York State Department of Veterans' Services")]
	new_york_state_department_of_veterans_services,
	//[Description("North Carolina Dept Military and Veterans Affairs")]
	north_carolina_dept_military_and_veterans_affairs,
	//[Description("North Dakota Department Veterans Affairs")]
	north_dakota_department_veterans_affairs,
	//[Description("Office of Veterans Affairs American Samoa Government")]
	office_of_veterans_affairs_american_samoa_government,
	//[Description("Ohio Department of Veterans Services")]
	ohio_department_of_veterans_services,
	//[Description("Oklahoma Department of Veterans Affairs")]
	oklahoma_department_of_veterans_affairs,
	//[Description("Oregon Department of Veterans Affairs")]
	oregon_department_of_veterans_affairs,
	//[Description("Paralyzed Veterans of America")]
	paralyzed_veterans_of_america,
	//[Description("Pennsylvania Department of Military and Veterans Affairs")]
	pennsylvania_department_of_military_and_veterans_affairs,
	//[Description("Polish Legion of American Veterans")]
	polish_legion_of_american_veterans,
	//[Description("Puerto Rico Veterans Advocate Office")]
	puerto_rico_veterans_advocate_office,
	//[Description("Rhode Island Office of Veterans Services (RIVETS)")]
	rhode_island_office_of_veterans_services_rivets,
	//[Description("South Dakota Department of Veterans Affairs")]
	south_dakota_department_of_veterans_affairs,
	//[Description("Swords to Plowshares")]
	swords_to_plowshares,
	//[Description("Tennessee Department of Veterans Services")]
	tennessee_department_of_veterans_services,
	//[Description("Texas Veterans Commission")]
	texas_veterans_commission,
	//[Description("The Retired Enlisted Association")]
	the_retired_enlisted_association,
	//[Description("The South Carolina Department of Veterans Affairs")]
	the_south_carolina_department_of_veterans_affairs,
	//[Description("United Spanish War Veterans")]
	united_spanish_war_veterans,
	//[Description("United Spinal Asst ")]
	united_spinal_asst_,
	//[Description("Utah Department of Veterans and Military Affairs")]
	utah_department_of_veterans_and_military_affairs,
	//[Description("Vermont Office of Veterans Affairs")]
	vermont_office_of_veterans_affairs,
	//[Description("Veterans of Foreign Wars")]
	veterans_of_foreign_wars,
	//[Description("Veterans of the Vietnam War")]
	veterans_of_the_vietnam_war,
	//[Description("Veterans of WW I of the USA")]
	veterans_of_ww_i_of_the_usa,
	//[Description("Veterans' Voice of America")]
	veterans_voice_of_america,
	//[Description("Vietnam Veterans of America")]
	vietnam_veterans_of_america,
	//[Description("Virgin Islands Office of Veterans Affairs")]
	virgin_islands_office_of_veterans_affairs,
	//[Description("Virginia Department of Veterans Services")]
	virginia_department_of_veterans_services,
	//[Description("Washington Department of Veterans Affairs")]
	washington_department_of_veterans_affairs,
	//[Description("West Virginia Dept of Veterans Assistance")]
	west_virginia_dept_of_veterans_assistance,
	//[Description("Wisconsin Department of Veterans Affairs")]
	wisconsin_department_of_veterans_affairs,
	//[Description("Wounded Warrior Project")]
	wounded_warrior_project,
	//[Description("Wyoming Veterans Commission")]
	wyoming_veterans_commission,
	//[Description("The Veterans Coalition")]
	the_veterans_coalition,
	//[Description("VFW of Alabama")]
	vfw_of_alabama,
	//[Description("VFW of Alaska")]
	vfw_of_alaska,
	//[Description("VFW of Arizona")]
	vfw_of_arizona,
	//[Description("VFW of Arkansas")]
	vfw_of_arkansas,
	//[Description("VFW of California")]
	vfw_of_california,
	//[Description("VFW of Colorado")]
	vfw_of_colorado,
	//[Description("VFW of Connecticut")]
	vfw_of_connecticut,
	//[Description("VFW of Delaware")]
	vfw_of_delaware,
	//[Description("VFW of Europe")]
	vfw_of_europe,
	//[Description("VFW of Florida")]
	vfw_of_florida,
	//[Description("VFW of Georgia")]
	vfw_of_georgia,
	//[Description("VFW of Hawaii")]
	vfw_of_hawaii,
	//[Description("VFW of Iowa")]
	vfw_of_iowa,
	//[Description("VFW of Idaho")]
	vfw_of_idaho,
	//[Description("VFW of Illinois")]
	vfw_of_illinois,
	//[Description("VFW of Indiana")]
	vfw_of_indiana,
	//[Description("VFW of Kansas")]
	vfw_of_kansas,
	//[Description("VFW of Kentucky")]
	vfw_of_kentucky,
	//[Description("VFW of Maine")]
	vfw_of_maine,
	//[Description("VFW of Maryland")]
	vfw_of_maryland,
	//[Description("VFW of Massachusetts")]
	vfw_of_massachusetts,
	//[Description("VFW of Michigan")]
	vfw_of_michigan,
	//[Description("VFW of Minnesota")]
	vfw_of_minnesota,
	//[Description("VFW of Mississippi")]
	vfw_of_mississippi,
	//[Description("VFW of Missouri")]
	vfw_of_missouri,
	//[Description("VFW of Montana")]
	vfw_of_montana,
	//[Description("VFW of Nebraska")]
	vfw_of_nebraska,
	//[Description("VFW of New Jersey")]
	vfw_of_new_jersey,
	//[Description("VFW of New Mexico")]
	vfw_of_new_mexico,
	//[Description("VFW of New York")]
	vfw_of_new_york,
	//[Description("VFW of New Hampshire")]
	vfw_of_new_hampshire,
	//[Description("VFW of North Carolina")]
	vfw_of_north_carolina,
	//[Description("VFW of North Dakota")]
	vfw_of_north_dakota,
	//[Description("VFW of Nevada")]
	vfw_of_nevada,
	//[Description("VFW of Ohio")]
	vfw_of_ohio,
	//[Description("VFW of Oklahoma")]
	vfw_of_oklahoma,
	//[Description("VFW of Oregon")]
	vfw_of_oregon,
	//[Description("VFW of Pacific Areas")]
	vfw_of_pacific_areas,
	//[Description("VFW of Pennsylvania")]
	vfw_of_pennsylvania,
	//[Description("VFW of Rhode Island")]
	vfw_of_rhode_island,
	//[Description("VFW of South Carolina")]
	vfw_of_south_carolina,
	//[Description("VFW of South Dakota")]
	vfw_of_south_dakota,
	//[Description("VFW of Tennessee")]
	vfw_of_tennessee,
	//[Description("VFW of Texas")]
	vfw_of_texas,
	//[Description("VFW of Utah")]
	vfw_of_utah,
	//[Description("VFW of Vermont")]
	vfw_of_vermont,
	//[Description("VFW of Virginia")]
	vfw_of_virginia,
	//[Description("VFW of Washington")]
	vfw_of_washington,
	//[Description("VFW of West Virginia")]
	vfw_of_west_virginia,
	//[Description("VFW of Wisconsin")]
	vfw_of_wisconsin,
	//[Description("VFW of Wyoming")]
	vfw_of_wyoming,
	//[Description("VFW of Alabama VFW Auxiliary")]
	vfw_of_alabama_vfw_auxiliary,
	//[Description("VFW of Alaska VFW Auxiliary")]
	vfw_of_alaska_vfw_auxiliary,
	//[Description("VFW of Arizona VFW Auxiliary")]
	vfw_of_arizona_vfw_auxiliary,
	//[Description("VFW of Arkansas VFW Auxiliary")]
	vfw_of_arkansas_vfw_auxiliary,
	//[Description("VFW of California VFW Auxiliary")]
	vfw_of_california_vfw_auxiliary,
	//[Description("VFW of Colorado VFW Auxiliary")]
	vfw_of_colorado_vfw_auxiliary,
	//[Description("VFW of Connecticut VFW Auxiliary")]
	vfw_of_connecticut_vfw_auxiliary,
	//[Description("VFW of Delaware VFW Auxiliary")]
	vfw_of_delaware_vfw_auxiliary,
	//[Description("VFW of Europe VFW Auxiliary")]
	vfw_of_europe_vfw_auxiliary,
	//[Description("VFW of Florida VFW Auxiliary")]
	vfw_of_florida_vfw_auxiliary,
	//[Description("VFW of Georgia VFW Auxiliary")]
	vfw_of_georgia_vfw_auxiliary,
	//[Description("VFW of Hawaii VFW Auxiliary")]
	vfw_of_hawaii_vfw_auxiliary,
	//[Description("VFW of Iowa VFW Auxiliary")]
	vfw_of_iowa_vfw_auxiliary,
	//[Description("VFW of Idaho VFW Auxiliary")]
	vfw_of_idaho_vfw_auxiliary,
	//[Description("VFW of Illinois VFW Auxiliary")]
	vfw_of_illinois_vfw_auxiliary,
	//[Description("VFW of Indiana VFW Auxiliary")]
	vfw_of_indiana_vfw_auxiliary,
	//[Description("VFW of Kansas VFW Auxiliary")]
	vfw_of_kansas_vfw_auxiliary,
	//[Description("VFW of Kentucky VFW Auxiliary")]
	vfw_of_kentucky_vfw_auxiliary,
	//[Description("VFW of Maine VFW Auxiliary")]
	vfw_of_maine_vfw_auxiliary,
	//[Description("VFW of Maryland VFW Auxiliary")]
	vfw_of_maryland_vfw_auxiliary,
	//[Description("VFW of Massachusetts VFW Auxiliary")]
	vfw_of_massachusetts_vfw_auxiliary,
	//[Description("VFW of Michigan VFW Auxiliary")]
	vfw_of_michigan_vfw_auxiliary,
	//[Description("VFW of Minnesota VFW Auxiliary")]
	vfw_of_minnesota_vfw_auxiliary,
	//[Description("VFW of Mississippi VFW Auxiliary")]
	vfw_of_mississippi_vfw_auxiliary,
	//[Description("VFW of Missouri VFW Auxiliary")]
	vfw_of_missouri_vfw_auxiliary,
	//[Description("VFW of Montana VFW Auxiliary")]
	vfw_of_montana_vfw_auxiliary,
	//[Description("VFW of Nebraska VFW Auxiliary")]
	vfw_of_nebraska_vfw_auxiliary,
	//[Description("VFW of New Jersey VFW Auxiliary")]
	vfw_of_new_jersey_vfw_auxiliary,
	//[Description("VFW of New Mexico VFW Auxiliary")]
	vfw_of_new_mexico_vfw_auxiliary,
	//[Description("VFW of New York VFW Auxiliary")]
	vfw_of_new_york_vfw_auxiliary,
	//[Description("VFW of New Hampshire VFW Auxiliary")]
	vfw_of_new_hampshire_vfw_auxiliary,
	//[Description("VFW of North Carolina VFW Auxiliary")]
	vfw_of_north_carolina_vfw_auxiliary,
	//[Description("VFW of North Dakota VFW Auxiliary")]
	vfw_of_north_dakota_vfw_auxiliary,
	//[Description("VFW of Nevada VFW Auxiliary")]
	vfw_of_nevada_vfw_auxiliary,
	//[Description("VFW of Ohio VFW Auxiliary")]
	vfw_of_ohio_vfw_auxiliary,
	//[Description("VFW of Oklahoma VFW Auxiliary")]
	vfw_of_oklahoma_vfw_auxiliary,
	//[Description("VFW of Oregon VFW Auxiliary")]
	vfw_of_oregon_vfw_auxiliary,
	//[Description("VFW of Pacific Areas VFW Auxiliary")]
	vfw_of_pacific_areas_vfw_auxiliary,
	//[Description("VFW of Pennsylvania VFW Auxiliary")]
	vfw_of_pennsylvania_vfw_auxiliary,
	//[Description("VFW of Rhode Island VFW Auxiliary")]
	vfw_of_rhode_island_vfw_auxiliary,
	//[Description("VFW of South Carolina VFW Auxiliary")]
	vfw_of_south_carolina_vfw_auxiliary,
	//[Description("VFW of South Dakota VFW Auxiliary")]
	vfw_of_south_dakota_vfw_auxiliary,
	//[Description("VFW of Tennessee VFW Auxiliary")]
	vfw_of_tennessee_vfw_auxiliary,
	//[Description("VFW of Texas VFW Auxiliary")]
	vfw_of_texas_vfw_auxiliary,
	//[Description("VFW of Utah VFW Auxiliary")]
	vfw_of_utah_vfw_auxiliary,
	//[Description("VFW of Vermont VFW Auxiliary")]
	vfw_of_vermont_vfw_auxiliary,
	//[Description("VFW of Virginia VFW Auxiliary")]
	vfw_of_virginia_vfw_auxiliary,
	//[Description("VFW of Washington VFW Auxiliary")]
	vfw_of_washington_vfw_auxiliary,
	//[Description("VFW of West Virginia VFW Auxiliary")]
	vfw_of_west_virginia_vfw_auxiliary,
	//[Description("VFW of Wisconsin VFW Auxiliary")]
	vfw_of_wisconsin_vfw_auxiliary,
	//[Description("VFW of Wyoming VFW Auxiliary")]
	vfw_of_wyoming_vfw_auxiliary,
	//[Description("African American PTSD Association Auxiliary")]
	african_american_ptsd_association_auxiliary,
	//[Description("Alabama Department of Veterans Affairs Auxiliary")]
	alabama_department_of_veterans_affairs_auxiliary,
	//[Description("American Legion Auxiliary")]
	american_legion_auxiliary,
	//[Description("American Red Cross Auxiliary")]
	american_red_cross_auxiliary,
	//[Description("American Veterans Auxiliary")]
	american_veterans_auxiliary,
	//[Description("Arizona Department of Veterans Services Auxiliary")]
	arizona_department_of_veterans_services_auxiliary,
	//[Description("Arkansas Department of Veterans Affairs Auxiliary")]
	arkansas_department_of_veterans_affairs_auxiliary,
	//[Description("Armed Forces Services Corporation Auxiliary")]
	armed_forces_services_corporation_auxiliary,
	//[Description("Army and Navy Union Auxiliary")]
	army_and_navy_union_auxiliary,
	//[Description("Blinded Veterans Association Auxiliary")]
	blinded_veterans_association_auxiliary,
	//[Description("California Department of Veterans Services Auxiliary")]
	california_department_of_veterans_services_auxiliary,
	//[Description("Catholic War Veterans of the USA Auxiliary")]
	catholic_war_veterans_of_the_usa_auxiliary,
	//[Description("Colorado Division of Veterans Affairs Auxiliary")]
	colorado_division_of_veterans_affairs_auxiliary,
	//[Description("Commonwealth of the Northern Mariana Islands Division Auxiliary")]
	commonwealth_of_the_northern_mariana_islands_division_auxiliary,
	//[Description("Connecticut Department of Veterans Affairs Auxiliary")]
	connecticut_department_of_veterans_affairs_auxiliary,
	//[Description("Dale K. Graham Veterans Foundation Auxiliary")]
	dale_k_graham_veterans_foundation_auxiliary,
	//[Description("Delaware Commission of Veterans Affairs Auxiliary")]
	delaware_commission_of_veterans_affairs_auxiliary,
	//[Description("Disabled American Veterans Auxiliary")]
	disabled_american_veterans_auxiliary,
	//[Description("Fleet Reserve Association Auxiliary")]
	fleet_reserve_association_auxiliary,
	//[Description("Florida Department of Veterans Affairs Auxiliary")]
	florida_department_of_veterans_affairs_auxiliary,
	//[Description("Gold Star Wives of America (G.S.W.A) Auxiliary")]
	gold_star_wives_of_america_gswa_auxiliary,
	//[Description("Georgia Department of Veterans Service Auxiliary")]
	georgia_department_of_veterans_service_auxiliary,
	//[Description("Gila River Indian Community Vet.&Fam. Svcs Office Auxiliary")]
	gila_river_indian_community_vetfam_svcs_office_auxiliary,
	//[Description("Green Beret Foundation Auxiliary")]
	green_beret_foundation_auxiliary,
	//[Description("Guam Office of Veterans Affairs Auxiliary")]
	guam_office_of_veterans_affairs_auxiliary,
	//[Description("Hawaii Office of Veterans Services Auxiliary")]
	hawaii_office_of_veterans_services_auxiliary,
	//[Description("Idaho Division of Veterans Services Auxiliary")]
	idaho_division_of_veterans_services_auxiliary,
	//[Description("Illinois Department of Veterans Affairs Auxiliary")]
	illinois_department_of_veterans_affairs_auxiliary,
	//[Description("Indiana Department of Veterans Affairs Auxiliary")]
	indiana_department_of_veterans_affairs_auxiliary,
	//[Description("Iowa Department of Veterans Affairs Auxiliary")]
	iowa_department_of_veterans_affairs_auxiliary,
	//[Description("Italian American War Veterans of the US Auxiliary")]
	italian_american_war_veterans_of_the_us_auxiliary,
	//[Description("Jewish War Veterans of the USA Auxiliary")]
	jewish_war_veterans_of_the_usa_auxiliary,
	//[Description("Kansas Commission on Veterans' Affairs Auxiliary")]
	kansas_commission_on_veterans_affairs_auxiliary,
	//[Description("Kentucky Department of Veterans Affairs Auxiliary")]
	kentucky_department_of_veterans_affairs_auxiliary,
	//[Description("Legion of Valor of the USA Auxiliary")]
	legion_of_valor_of_the_usa_auxiliary,
	//[Description("Louisiana Department of Veterans Affairs Auxiliary")]
	louisiana_department_of_veterans_affairs_auxiliary,
	//[Description("Maine Veterans' Services Auxiliary")]
	maine_veterans_services_auxiliary,
	//[Description("Marine Corps League Auxiliary")]
	marine_corps_league_auxiliary,
	//[Description("Maryland Department of Veterans Affairs Auxiliary")]
	maryland_department_of_veterans_affairs_auxiliary,
	//[Description("Massachusetts Department of Veterans' Services Auxiliary")]
	massachusetts_department_of_veterans_services_auxiliary,
	//[Description("Michigan Veterans Affairs Agency Auxiliary")]
	michigan_veterans_affairs_agency_auxiliary,
	//[Description("Military Officers Association of America Auxiliary")]
	military_officers_association_of_america_auxiliary,
	//[Description("Minnesota Department of Veterans Affairs Auxiliary")]
	minnesota_department_of_veterans_affairs_auxiliary,
	//[Description("Mississippi Veterans Affairs Auxiliary")]
	mississippi_veterans_affairs_auxiliary,
	//[Description("Missouri Veterans Commission Auxiliary")]
	missouri_veterans_commission_auxiliary,
	//[Description("Montana Veterans Affairs (MVAD) Auxiliary")]
	montana_veterans_affairs_mvad_auxiliary,
	//[Description("National Association for Black Veterans Auxiliary")]
	national_association_for_black_veterans_auxiliary,
	//[Description("National Association of County Veterans Service Officers Auxiliary")]
	national_association_of_county_veterans_service_officers_auxiliary,
	//[Description("National Law School Veterans Clinic Consortium Auxiliary")]
	national_law_school_veterans_clinic_consortium_auxiliary,
	//[Description("National Montford Point Marine Association Auxiliary")]
	national_montford_point_marine_association_auxiliary,
	//[Description("National Veterans Legal Services Program Auxiliary")]
	national_veterans_legal_services_program_auxiliary,
	//[Description("National Veterans Organization of America Auxiliary")]
	national_veterans_organization_of_america_auxiliary,
	//[Description("Navajo Nation Veterans Administration Auxiliary")]
	navajo_nation_veterans_administration_auxiliary,
	//[Description("Navy Mutual Aid Association Auxiliary")]
	navy_mutual_aid_association_auxiliary,
	//[Description("Nebraska Department of Veterans Affairs Auxiliary")]
	nebraska_department_of_veterans_affairs_auxiliary,
	//[Description("Nevada Department of Veterans Services Auxiliary")]
	nevada_department_of_veterans_services_auxiliary,
	//[Description("New Hampshire Division of Veteran Services Auxiliary")]
	new_hampshire_division_of_veteran_services_auxiliary,
	//[Description("New Jersey Department of Military and Veterans Affairs Auxiliary")]
	new_jersey_department_of_military_and_veterans_affairs_auxiliary,
	//[Description("New Mexico Department of Veterans Services Auxiliary")]
	new_mexico_department_of_veterans_services_auxiliary,
	//[Description("New York State Department of Veterans' Services Auxiliary")]
	new_york_state_department_of_veterans_services_auxiliary,
	//[Description("North Carolina Dept Military and Veterans Affairs Auxiliary")]
	north_carolina_dept_military_and_veterans_affairs_auxiliary,
	//[Description("North Dakota Department Veterans Affairs Auxiliary")]
	north_dakota_department_veterans_affairs_auxiliary,
	//[Description("Office of Veterans Affairs American Samoa Government Auxiliary")]
	office_of_veterans_affairs_american_samoa_government_auxiliary,
	//[Description("Ohio Department of Veterans Services Auxiliary")]
	ohio_department_of_veterans_services_auxiliary,
	//[Description("Oklahoma Department of Veterans Affairs Auxiliary")]
	oklahoma_department_of_veterans_affairs_auxiliary,
	//[Description("Oregon Department of Veterans Affairs Auxiliary")]
	oregon_department_of_veterans_affairs_auxiliary,
	//[Description("Paralyzed Veterans of America Auxiliary")]
	paralyzed_veterans_of_america_auxiliary,
	//[Description("Pennsylvania Department of Military and Veterans Affairs Auxiliary")]
	pennsylvania_department_of_military_and_veterans_affairs_auxiliary,
	//[Description("Polish Legion of American Veterans Auxiliary")]
	polish_legion_of_american_veterans_auxiliary,
	//[Description("Puerto Rico Veterans Advocate Office Auxiliary")]
	puerto_rico_veterans_advocate_office_auxiliary,
	//[Description("Rhode Island Office of Veterans Services (RIVETS) Auxiliary")]
	rhode_island_office_of_veterans_services_rivets_auxiliary,
	//[Description("South Dakota Department of Veterans Affairs Auxiliary")]
	south_dakota_department_of_veterans_affairs_auxiliary,
	//[Description("Swords to Plowshares Auxiliary")]
	swords_to_plowshares_auxiliary,
	//[Description("Tennessee Department of Veterans Services Auxiliary")]
	tennessee_department_of_veterans_services_auxiliary,
	//[Description("Texas Veterans Commission Auxiliary")]
	texas_veterans_commission_auxiliary,
	//[Description("The Retired Enlisted Association Auxiliary")]
	the_retired_enlisted_association_auxiliary,
	//[Description("The South Carolina Department of Veterans Affairs Auxiliary")]
	the_south_carolina_department_of_veterans_affairs_auxiliary,
	//[Description("United Spanish War Veterans Auxiliary")]
	united_spanish_war_veterans_auxiliary,
	//[Description("United Spinal Asst  Auxiliary")]
	united_spinal_asst__auxiliary,
	//[Description("Utah Department of Veterans and Military Affairs Auxiliary")]
	utah_department_of_veterans_and_military_affairs_auxiliary,
	//[Description("Vermont Office of Veterans Affairs Auxiliary")]
	vermont_office_of_veterans_affairs_auxiliary,
	//[Description("Veterans of Foreign Wars Auxiliary")]
	veterans_of_foreign_wars_auxiliary,
	//[Description("Veterans of the Vietnam War Auxiliary")]
	veterans_of_the_vietnam_war_auxiliary,
	//[Description("Veterans of WW I of the USA Auxiliary")]
	veterans_of_ww_i_of_the_usa_auxiliary,
	//[Description("Veterans' Voice of America Auxiliary")]
	veterans_voice_of_america_auxiliary,
	//[Description("Vietnam Veterans of America Auxiliary")]
	vietnam_veterans_of_america_auxiliary,
	//[Description("Virgin Islands Office of Veterans Affairs Auxiliary")]
	virgin_islands_office_of_veterans_affairs_auxiliary,
	//[Description("Virginia Department of Veterans Services Auxiliary")]
	virginia_department_of_veterans_services_auxiliary,
	//[Description("Washington Department of Veterans Affairs Auxiliary")]
	washington_department_of_veterans_affairs_auxiliary,
	//[Description("West Virginia Dept of Veterans Assistance Auxiliary")]
	west_virginia_dept_of_veterans_assistance_auxiliary,
	//[Description("Wisconsin Department of Veterans Affairs Auxiliary")]
	wisconsin_department_of_veterans_affairs_auxiliary,
	//[Description("Wounded Warrior Project Auxiliary")]
	wounded_warrior_project_auxiliary,
	//[Description("Wyoming Veterans Commission Auxiliary")]
	wyoming_veterans_commission_auxiliary,
	//[Description("The Veterans Coalition Auxiliary")]
	the_veterans_coalition_auxiliary
}

export const VeteranCurrentVSOMemberDescription = new Map<number, string>([
	[ VeteranCurrentVSOMember.na, 'N/A' ],
	[ VeteranCurrentVSOMember.african_american_ptsd_association, 'African American PTSD Association' ],
	[ VeteranCurrentVSOMember.alabama_department_of_veterans_affairs, 'Alabama Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.american_legion, 'American Legion' ],
	[ VeteranCurrentVSOMember.american_red_cross, 'American Red Cross' ],
	[ VeteranCurrentVSOMember.american_veterans, 'American Veterans' ],
	[ VeteranCurrentVSOMember.arizona_department_of_veterans_services, 'Arizona Department of Veterans Services' ],
	[ VeteranCurrentVSOMember.arkansas_department_of_veterans_affairs, 'Arkansas Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.armed_forces_services_corporation, 'Armed Forces Services Corporation' ],
	[ VeteranCurrentVSOMember.army_and_navy_union, 'Army and Navy Union' ],
	[ VeteranCurrentVSOMember.blinded_veterans_association, 'Blinded Veterans Association' ],
	[ VeteranCurrentVSOMember.california_department_of_veterans_services, 'California Department of Veterans Services' ],
	[ VeteranCurrentVSOMember.catholic_war_veterans_of_the_usa, 'Catholic War Veterans of the USA' ],
	[ VeteranCurrentVSOMember.colorado_division_of_veterans_affairs, 'Colorado Division of Veterans Affairs' ],
	[
		VeteranCurrentVSOMember.commonwealth_of_the_northern_mariana_islands_division,
		'Commonwealth of the Northern Mariana Islands Division'
	],
	[ VeteranCurrentVSOMember.connecticut_department_of_veterans_affairs, 'Connecticut Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.dale_k_graham_veterans_foundation, 'Dale K. Graham Veterans Foundation' ],
	[ VeteranCurrentVSOMember.delaware_commission_of_veterans_affairs, 'Delaware Commission of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.disabled_american_veterans, 'Disabled American Veterans' ],
	[ VeteranCurrentVSOMember.fleet_reserve_association, 'Fleet Reserve Association' ],
	[ VeteranCurrentVSOMember.florida_department_of_veterans_affairs, 'Florida Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.gold_star_wives_of_america_gswa, 'Gold Star Wives of America (G.S.W.A)' ],
	[ VeteranCurrentVSOMember.georgia_department_of_veterans_service, 'Georgia Department of Veterans Service' ],
	[
		VeteranCurrentVSOMember.gila_river_indian_community_vetfam_svcs_office,
		'Gila River Indian Community Vet.&Fam. Svcs Office'
	],
	[ VeteranCurrentVSOMember.green_beret_foundation, 'Green Beret Foundation' ],
	[ VeteranCurrentVSOMember.guam_office_of_veterans_affairs, 'Guam Office of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.hawaii_office_of_veterans_services, 'Hawaii Office of Veterans Services' ],
	[ VeteranCurrentVSOMember.idaho_division_of_veterans_services, 'Idaho Division of Veterans Services' ],
	[ VeteranCurrentVSOMember.illinois_department_of_veterans_affairs, 'Illinois Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.indiana_department_of_veterans_affairs, 'Indiana Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.iowa_department_of_veterans_affairs, 'Iowa Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.italian_american_war_veterans_of_the_us, 'Italian American War Veterans of the US' ],
	[ VeteranCurrentVSOMember.jewish_war_veterans_of_the_usa, 'Jewish War Veterans of the USA' ],
	[ VeteranCurrentVSOMember.kansas_commission_on_veterans_affairs, "Kansas Commission on Veterans' Affairs" ],
	[ VeteranCurrentVSOMember.kentucky_department_of_veterans_affairs, 'Kentucky Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.legion_of_valor_of_the_usa_inc, 'Legion of Valor of the USA, Inc.' ],
	[ VeteranCurrentVSOMember.louisiana_department_of_veterans_affairs, 'Louisiana Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.maine_veterans_services, "Maine Veterans' Services" ],
	[ VeteranCurrentVSOMember.marine_corps_league, 'Marine Corps League' ],
	[ VeteranCurrentVSOMember.maryland_department_of_veterans_affairs, 'Maryland Department of Veterans Affairs' ],
	[
		VeteranCurrentVSOMember.massachusetts_department_of_veterans_services,
		"Massachusetts Department of Veterans' Services"
	],
	[ VeteranCurrentVSOMember.michigan_veterans_affairs_agency, 'Michigan Veterans Affairs Agency' ],
	[ VeteranCurrentVSOMember.military_officers_association_of_america, 'Military Officers Association of America' ],
	[ VeteranCurrentVSOMember.minnesota_department_of_veterans_affairs, 'Minnesota Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.mississippi_veterans_affairs, 'Mississippi Veterans Affairs' ],
	[ VeteranCurrentVSOMember.missouri_veterans_commission, 'Missouri Veterans Commission' ],
	[ VeteranCurrentVSOMember.montana_veterans_affairs_mvad, 'Montana Veterans Affairs (MVAD)' ],
	[ VeteranCurrentVSOMember.national_association_for_black_veterans, 'National Association for Black Veterans' ],
	[
		VeteranCurrentVSOMember.national_association_of_county_veterans_service_officers,
		'National Association of County Veterans Service Officers'
	],
	[
		VeteranCurrentVSOMember.national_law_school_veterans_clinic_consortium,
		'National Law School Veterans Clinic Consortium'
	],
	[ VeteranCurrentVSOMember.national_montford_point_marine_association, 'National Montford Point Marine Association' ],
	[ VeteranCurrentVSOMember.national_veterans_legal_services_program, 'National Veterans Legal Services Program' ],
	[ VeteranCurrentVSOMember.national_veterans_organization_of_america, 'National Veterans Organization of America' ],
	[ VeteranCurrentVSOMember.navajo_nation_veterans_administration, 'Navajo Nation Veterans Administration' ],
	[ VeteranCurrentVSOMember.navy_mutual_aid_association, 'Navy Mutual Aid Association' ],
	[ VeteranCurrentVSOMember.nebraska_department_of_veterans_affairs, 'Nebraska Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.nevada_department_of_veterans_services, 'Nevada Department of Veterans Services' ],
	[ VeteranCurrentVSOMember.new_hampshire_division_of_veteran_services, 'New Hampshire Division of Veteran Services' ],
	[
		VeteranCurrentVSOMember.new_jersey_department_of_military_and_veterans_affairs,
		'New Jersey Department of Military and Veterans Affairs'
	],
	[ VeteranCurrentVSOMember.new_mexico_department_of_veterans_services, 'New Mexico Department of Veterans Services' ],
	[
		VeteranCurrentVSOMember.new_york_state_department_of_veterans_services,
		"New York State Department of Veterans' Services"
	],
	[
		VeteranCurrentVSOMember.north_carolina_dept_military_and_veterans_affairs,
		'North Carolina Dept Military and Veterans Affairs'
	],
	[ VeteranCurrentVSOMember.north_dakota_department_veterans_affairs, 'North Dakota Department Veterans Affairs' ],
	[
		VeteranCurrentVSOMember.office_of_veterans_affairs_american_samoa_government,
		'Office of Veterans Affairs American Samoa Government'
	],
	[ VeteranCurrentVSOMember.ohio_department_of_veterans_services, 'Ohio Department of Veterans Services' ],
	[ VeteranCurrentVSOMember.oklahoma_department_of_veterans_affairs, 'Oklahoma Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.oregon_department_of_veterans_affairs, 'Oregon Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.paralyzed_veterans_of_america, 'Paralyzed Veterans of America' ],
	[
		VeteranCurrentVSOMember.pennsylvania_department_of_military_and_veterans_affairs,
		'Pennsylvania Department of Military and Veterans Affairs'
	],
	[ VeteranCurrentVSOMember.polish_legion_of_american_veterans, 'Polish Legion of American Veterans' ],
	[ VeteranCurrentVSOMember.puerto_rico_veterans_advocate_office, 'Puerto Rico Veterans Advocate Office' ],
	[
		VeteranCurrentVSOMember.rhode_island_office_of_veterans_services_rivets,
		'Rhode Island Office of Veterans Services (RIVETS)'
	],
	[
		VeteranCurrentVSOMember.south_dakota_department_of_veterans_affairs,
		'South Dakota Department of Veterans Affairs'
	],
	[ VeteranCurrentVSOMember.swords_to_plowshares, 'Swords to Plowshares' ],
	[ VeteranCurrentVSOMember.tennessee_department_of_veterans_services, 'Tennessee Department of Veterans Services' ],
	[ VeteranCurrentVSOMember.texas_veterans_commission, 'Texas Veterans Commission' ],
	[ VeteranCurrentVSOMember.the_retired_enlisted_association, 'The Retired Enlisted Association' ],
	[
		VeteranCurrentVSOMember.the_south_carolina_department_of_veterans_affairs,
		'The South Carolina Department of Veterans Affairs'
	],
	[ VeteranCurrentVSOMember.united_spanish_war_veterans, 'United Spanish War Veterans' ],
	[ VeteranCurrentVSOMember.united_spinal_asst_, 'United Spinal Asst ' ],
	[
		VeteranCurrentVSOMember.utah_department_of_veterans_and_military_affairs,
		'Utah Department of Veterans and Military Affairs'
	],
	[ VeteranCurrentVSOMember.vermont_office_of_veterans_affairs, 'Vermont Office of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.veterans_of_foreign_wars, 'Veterans of Foreign Wars' ],
	[ VeteranCurrentVSOMember.veterans_of_the_vietnam_war, 'Veterans of the Vietnam War' ],
	[ VeteranCurrentVSOMember.veterans_of_ww_i_of_the_usa, 'Veterans of WW I of the USA' ],
	[ VeteranCurrentVSOMember.veterans_voice_of_america, "Veterans' Voice of America" ],
	[ VeteranCurrentVSOMember.vietnam_veterans_of_america, 'Vietnam Veterans of America' ],
	[ VeteranCurrentVSOMember.virgin_islands_office_of_veterans_affairs, 'Virgin Islands Office of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.virginia_department_of_veterans_services, 'Virginia Department of Veterans Services' ],
	[ VeteranCurrentVSOMember.washington_department_of_veterans_affairs, 'Washington Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.west_virginia_dept_of_veterans_assistance, 'West Virginia Dept of Veterans Assistance' ],
	[ VeteranCurrentVSOMember.wisconsin_department_of_veterans_affairs, 'Wisconsin Department of Veterans Affairs' ],
	[ VeteranCurrentVSOMember.wounded_warrior_project, 'Wounded Warrior Project' ],
	[ VeteranCurrentVSOMember.wyoming_veterans_commission, 'Wyoming Veterans Commission' ],
	[ VeteranCurrentVSOMember.the_veterans_coalition, 'The Veterans Coalition' ],
	[ VeteranCurrentVSOMember.vfw_of_alabama, 'VFW of Alabama' ],
	[ VeteranCurrentVSOMember.vfw_of_alaska, 'VFW of Alaska' ],
	[ VeteranCurrentVSOMember.vfw_of_arizona, 'VFW of Arizona' ],
	[ VeteranCurrentVSOMember.vfw_of_arkansas, 'VFW of Arkansas' ],
	[ VeteranCurrentVSOMember.vfw_of_california, 'VFW of California' ],
	[ VeteranCurrentVSOMember.vfw_of_colorado, 'VFW of Colorado' ],
	[ VeteranCurrentVSOMember.vfw_of_connecticut, 'VFW of Connecticut' ],
	[ VeteranCurrentVSOMember.vfw_of_delaware, 'VFW of Delaware' ],
	[ VeteranCurrentVSOMember.vfw_of_europe, 'VFW of Europe' ],
	[ VeteranCurrentVSOMember.vfw_of_florida, 'VFW of Florida' ],
	[ VeteranCurrentVSOMember.vfw_of_georgia, 'VFW of Georgia' ],
	[ VeteranCurrentVSOMember.vfw_of_hawaii, 'VFW of Hawaii' ],
	[ VeteranCurrentVSOMember.vfw_of_iowa, 'VFW of Iowa' ],
	[ VeteranCurrentVSOMember.vfw_of_idaho, 'VFW of Idaho' ],
	[ VeteranCurrentVSOMember.vfw_of_illinois, 'VFW of Illinois' ],
	[ VeteranCurrentVSOMember.vfw_of_indiana, 'VFW of Indiana' ],
	[ VeteranCurrentVSOMember.vfw_of_kansas, 'VFW of Kansas' ],
	[ VeteranCurrentVSOMember.vfw_of_kentucky, 'VFW of Kentucky' ],
	[ VeteranCurrentVSOMember.vfw_of_maine, 'VFW of Maine' ],
	[ VeteranCurrentVSOMember.vfw_of_maryland, 'VFW of Maryland' ],
	[ VeteranCurrentVSOMember.vfw_of_massachusetts, 'VFW of Massachusetts' ],
	[ VeteranCurrentVSOMember.vfw_of_michigan, 'VFW of Michigan' ],
	[ VeteranCurrentVSOMember.vfw_of_minnesota, 'VFW of Minnesota' ],
	[ VeteranCurrentVSOMember.vfw_of_mississippi, 'VFW of Mississippi' ],
	[ VeteranCurrentVSOMember.vfw_of_missouri, 'VFW of Missouri' ],
	[ VeteranCurrentVSOMember.vfw_of_montana, 'VFW of Montana' ],
	[ VeteranCurrentVSOMember.vfw_of_nebraska, 'VFW of Nebraska' ],
	[ VeteranCurrentVSOMember.vfw_of_new_jersey, 'VFW of New Jersey' ],
	[ VeteranCurrentVSOMember.vfw_of_new_mexico, 'VFW of New Mexico' ],
	[ VeteranCurrentVSOMember.vfw_of_new_york, 'VFW of New York' ],
	[ VeteranCurrentVSOMember.vfw_of_new_hampshire, 'VFW of New Hampshire' ],
	[ VeteranCurrentVSOMember.vfw_of_north_carolina, 'VFW of North Carolina' ],
	[ VeteranCurrentVSOMember.vfw_of_north_dakota, 'VFW of North Dakota' ],
	[ VeteranCurrentVSOMember.vfw_of_nevada, 'VFW of Nevada' ],
	[ VeteranCurrentVSOMember.vfw_of_ohio, 'VFW of Ohio' ],
	[ VeteranCurrentVSOMember.vfw_of_oklahoma, 'VFW of Oklahoma' ],
	[ VeteranCurrentVSOMember.vfw_of_oregon, 'VFW of Oregon' ],
	[ VeteranCurrentVSOMember.vfw_of_pacific_areas, 'VFW of Pacific Areas' ],
	[ VeteranCurrentVSOMember.vfw_of_pennsylvania, 'VFW of Pennsylvania' ],
	[ VeteranCurrentVSOMember.vfw_of_rhode_island, 'VFW of Rhode Island' ],
	[ VeteranCurrentVSOMember.vfw_of_south_carolina, 'VFW of South Carolina' ],
	[ VeteranCurrentVSOMember.vfw_of_south_dakota, 'VFW of South Dakota' ],
	[ VeteranCurrentVSOMember.vfw_of_tennessee, 'VFW of Tennessee' ],
	[ VeteranCurrentVSOMember.vfw_of_texas, 'VFW of Texas' ],
	[ VeteranCurrentVSOMember.vfw_of_utah, 'VFW of Utah' ],
	[ VeteranCurrentVSOMember.vfw_of_vermont, 'VFW of Vermont' ],
	[ VeteranCurrentVSOMember.vfw_of_virginia, 'VFW of Virginia' ],
	[ VeteranCurrentVSOMember.vfw_of_washington, 'VFW of Washington' ],
	[ VeteranCurrentVSOMember.vfw_of_west_virginia, 'VFW of West Virginia' ],
	[ VeteranCurrentVSOMember.vfw_of_wisconsin, 'VFW of Wisconsin' ],
	[ VeteranCurrentVSOMember.vfw_of_wyoming, 'VFW of Wyoming' ],
	[ VeteranCurrentVSOMember.vfw_of_alabama_vfw_auxiliary, 'VFW of Alabama VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_alaska_vfw_auxiliary, 'VFW of Alaska VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_arizona_vfw_auxiliary, 'VFW of Arizona VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_arkansas_vfw_auxiliary, 'VFW of Arkansas VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_california_vfw_auxiliary, 'VFW of California VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_colorado_vfw_auxiliary, 'VFW of Colorado VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_connecticut_vfw_auxiliary, 'VFW of Connecticut VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_delaware_vfw_auxiliary, 'VFW of Delaware VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_europe_vfw_auxiliary, 'VFW of Europe VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_florida_vfw_auxiliary, 'VFW of Florida VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_georgia_vfw_auxiliary, 'VFW of Georgia VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_hawaii_vfw_auxiliary, 'VFW of Hawaii VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_iowa_vfw_auxiliary, 'VFW of Iowa VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_idaho_vfw_auxiliary, 'VFW of Idaho VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_illinois_vfw_auxiliary, 'VFW of Illinois VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_indiana_vfw_auxiliary, 'VFW of Indiana VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_kansas_vfw_auxiliary, 'VFW of Kansas VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_kentucky_vfw_auxiliary, 'VFW of Kentucky VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_maine_vfw_auxiliary, 'VFW of Maine VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_maryland_vfw_auxiliary, 'VFW of Maryland VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_massachusetts_vfw_auxiliary, 'VFW of Massachusetts VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_michigan_vfw_auxiliary, 'VFW of Michigan VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_minnesota_vfw_auxiliary, 'VFW of Minnesota VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_mississippi_vfw_auxiliary, 'VFW of Mississippi VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_missouri_vfw_auxiliary, 'VFW of Missouri VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_montana_vfw_auxiliary, 'VFW of Montana VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_nebraska_vfw_auxiliary, 'VFW of Nebraska VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_new_jersey_vfw_auxiliary, 'VFW of New Jersey VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_new_mexico_vfw_auxiliary, 'VFW of New Mexico VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_new_york_vfw_auxiliary, 'VFW of New York VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_new_hampshire_vfw_auxiliary, 'VFW of New Hampshire VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_north_carolina_vfw_auxiliary, 'VFW of North Carolina VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_north_dakota_vfw_auxiliary, 'VFW of North Dakota VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_nevada_vfw_auxiliary, 'VFW of Nevada VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_ohio_vfw_auxiliary, 'VFW of Ohio VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_oklahoma_vfw_auxiliary, 'VFW of Oklahoma VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_oregon_vfw_auxiliary, 'VFW of Oregon VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_pacific_areas_vfw_auxiliary, 'VFW of Pacific Areas VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_pennsylvania_vfw_auxiliary, 'VFW of Pennsylvania VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_rhode_island_vfw_auxiliary, 'VFW of Rhode Island VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_south_carolina_vfw_auxiliary, 'VFW of South Carolina VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_south_dakota_vfw_auxiliary, 'VFW of South Dakota VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_tennessee_vfw_auxiliary, 'VFW of Tennessee VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_texas_vfw_auxiliary, 'VFW of Texas VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_utah_vfw_auxiliary, 'VFW of Utah VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_vermont_vfw_auxiliary, 'VFW of Vermont VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_virginia_vfw_auxiliary, 'VFW of Virginia VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_washington_vfw_auxiliary, 'VFW of Washington VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_west_virginia_vfw_auxiliary, 'VFW of West Virginia VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_wisconsin_vfw_auxiliary, 'VFW of Wisconsin VFW Auxiliary' ],
	[ VeteranCurrentVSOMember.vfw_of_wyoming_vfw_auxiliary, 'VFW of Wyoming VFW Auxiliary' ],
	[
		VeteranCurrentVSOMember.african_american_ptsd_association_auxiliary,
		'African American PTSD Association Auxiliary'
	],
	[
		VeteranCurrentVSOMember.alabama_department_of_veterans_affairs_auxiliary,
		'Alabama Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.american_legion_auxiliary, 'American Legion Auxiliary' ],
	[ VeteranCurrentVSOMember.american_red_cross_auxiliary, 'American Red Cross Auxiliary' ],
	[ VeteranCurrentVSOMember.american_veterans_auxiliary, 'American Veterans Auxiliary' ],
	[
		VeteranCurrentVSOMember.arizona_department_of_veterans_services_auxiliary,
		'Arizona Department of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.arkansas_department_of_veterans_affairs_auxiliary,
		'Arkansas Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.armed_forces_services_corporation_auxiliary,
		'Armed Forces Services Corporation Auxiliary'
	],
	[ VeteranCurrentVSOMember.army_and_navy_union_auxiliary, 'Army and Navy Union Auxiliary' ],
	[ VeteranCurrentVSOMember.blinded_veterans_association_auxiliary, 'Blinded Veterans Association Auxiliary' ],
	[
		VeteranCurrentVSOMember.california_department_of_veterans_services_auxiliary,
		'California Department of Veterans Services Auxiliary'
	],
	[ VeteranCurrentVSOMember.catholic_war_veterans_of_the_usa_auxiliary, 'Catholic War Veterans of the USA Auxiliary' ],
	[
		VeteranCurrentVSOMember.colorado_division_of_veterans_affairs_auxiliary,
		'Colorado Division of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.commonwealth_of_the_northern_mariana_islands_division_auxiliary,
		'Commonwealth of the Northern Mariana Islands Division Auxiliary'
	],
	[
		VeteranCurrentVSOMember.connecticut_department_of_veterans_affairs_auxiliary,
		'Connecticut Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.dale_k_graham_veterans_foundation_auxiliary,
		'Dale K. Graham Veterans Foundation Auxiliary'
	],
	[
		VeteranCurrentVSOMember.delaware_commission_of_veterans_affairs_auxiliary,
		'Delaware Commission of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.disabled_american_veterans_auxiliary, 'Disabled American Veterans Auxiliary' ],
	[ VeteranCurrentVSOMember.fleet_reserve_association_auxiliary, 'Fleet Reserve Association Auxiliary' ],
	[
		VeteranCurrentVSOMember.florida_department_of_veterans_affairs_auxiliary,
		'Florida Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.gold_star_wives_of_america_gswa_auxiliary,
		'Gold Star Wives of America (G.S.W.A) Auxiliary'
	],
	[
		VeteranCurrentVSOMember.georgia_department_of_veterans_service_auxiliary,
		'Georgia Department of Veterans Service Auxiliary'
	],
	[
		VeteranCurrentVSOMember.gila_river_indian_community_vetfam_svcs_office_auxiliary,
		'Gila River Indian Community Vet.&Fam. Svcs Office Auxiliary'
	],
	[ VeteranCurrentVSOMember.green_beret_foundation_auxiliary, 'Green Beret Foundation Auxiliary' ],
	[ VeteranCurrentVSOMember.guam_office_of_veterans_affairs_auxiliary, 'Guam Office of Veterans Affairs Auxiliary' ],
	[
		VeteranCurrentVSOMember.hawaii_office_of_veterans_services_auxiliary,
		'Hawaii Office of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.idaho_division_of_veterans_services_auxiliary,
		'Idaho Division of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.illinois_department_of_veterans_affairs_auxiliary,
		'Illinois Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.indiana_department_of_veterans_affairs_auxiliary,
		'Indiana Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.iowa_department_of_veterans_affairs_auxiliary,
		'Iowa Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.italian_american_war_veterans_of_the_us_auxiliary,
		'Italian American War Veterans of the US Auxiliary'
	],
	[ VeteranCurrentVSOMember.jewish_war_veterans_of_the_usa_auxiliary, 'Jewish War Veterans of the USA Auxiliary' ],
	[
		VeteranCurrentVSOMember.kansas_commission_on_veterans_affairs_auxiliary,
		"Kansas Commission on Veterans' Affairs Auxiliary"
	],
	[
		VeteranCurrentVSOMember.kentucky_department_of_veterans_affairs_auxiliary,
		'Kentucky Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.legion_of_valor_of_the_usa_auxiliary, 'Legion of Valor of the USA Auxiliary' ],
	[
		VeteranCurrentVSOMember.louisiana_department_of_veterans_affairs_auxiliary,
		'Louisiana Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.maine_veterans_services_auxiliary, "Maine Veterans' Services Auxiliary" ],
	[ VeteranCurrentVSOMember.marine_corps_league_auxiliary, 'Marine Corps League Auxiliary' ],
	[
		VeteranCurrentVSOMember.maryland_department_of_veterans_affairs_auxiliary,
		'Maryland Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.massachusetts_department_of_veterans_services_auxiliary,
		"Massachusetts Department of Veterans' Services Auxiliary"
	],
	[ VeteranCurrentVSOMember.michigan_veterans_affairs_agency_auxiliary, 'Michigan Veterans Affairs Agency Auxiliary' ],
	[
		VeteranCurrentVSOMember.military_officers_association_of_america_auxiliary,
		'Military Officers Association of America Auxiliary'
	],
	[
		VeteranCurrentVSOMember.minnesota_department_of_veterans_affairs_auxiliary,
		'Minnesota Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.mississippi_veterans_affairs_auxiliary, 'Mississippi Veterans Affairs Auxiliary' ],
	[ VeteranCurrentVSOMember.missouri_veterans_commission_auxiliary, 'Missouri Veterans Commission Auxiliary' ],
	[ VeteranCurrentVSOMember.montana_veterans_affairs_mvad_auxiliary, 'Montana Veterans Affairs (MVAD) Auxiliary' ],
	[
		VeteranCurrentVSOMember.national_association_for_black_veterans_auxiliary,
		'National Association for Black Veterans Auxiliary'
	],
	[
		VeteranCurrentVSOMember.national_association_of_county_veterans_service_officers_auxiliary,
		'National Association of County Veterans Service Officers Auxiliary'
	],
	[
		VeteranCurrentVSOMember.national_law_school_veterans_clinic_consortium_auxiliary,
		'National Law School Veterans Clinic Consortium Auxiliary'
	],
	[
		VeteranCurrentVSOMember.national_montford_point_marine_association_auxiliary,
		'National Montford Point Marine Association Auxiliary'
	],
	[
		VeteranCurrentVSOMember.national_veterans_legal_services_program_auxiliary,
		'National Veterans Legal Services Program Auxiliary'
	],
	[
		VeteranCurrentVSOMember.national_veterans_organization_of_america_auxiliary,
		'National Veterans Organization of America Auxiliary'
	],
	[
		VeteranCurrentVSOMember.navajo_nation_veterans_administration_auxiliary,
		'Navajo Nation Veterans Administration Auxiliary'
	],
	[ VeteranCurrentVSOMember.navy_mutual_aid_association_auxiliary, 'Navy Mutual Aid Association Auxiliary' ],
	[
		VeteranCurrentVSOMember.nebraska_department_of_veterans_affairs_auxiliary,
		'Nebraska Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.nevada_department_of_veterans_services_auxiliary,
		'Nevada Department of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.new_hampshire_division_of_veteran_services_auxiliary,
		'New Hampshire Division of Veteran Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.new_jersey_department_of_military_and_veterans_affairs_auxiliary,
		'New Jersey Department of Military and Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.new_mexico_department_of_veterans_services_auxiliary,
		'New Mexico Department of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.new_york_state_department_of_veterans_services_auxiliary,
		"New York State Department of Veterans' Services Auxiliary"
	],
	[
		VeteranCurrentVSOMember.north_carolina_dept_military_and_veterans_affairs_auxiliary,
		'North Carolina Dept Military and Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.north_dakota_department_veterans_affairs_auxiliary,
		'North Dakota Department Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.office_of_veterans_affairs_american_samoa_government_auxiliary,
		'Office of Veterans Affairs American Samoa Government Auxiliary'
	],
	[
		VeteranCurrentVSOMember.ohio_department_of_veterans_services_auxiliary,
		'Ohio Department of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.oklahoma_department_of_veterans_affairs_auxiliary,
		'Oklahoma Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.oregon_department_of_veterans_affairs_auxiliary,
		'Oregon Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.paralyzed_veterans_of_america_auxiliary, 'Paralyzed Veterans of America Auxiliary' ],
	[
		VeteranCurrentVSOMember.pennsylvania_department_of_military_and_veterans_affairs_auxiliary,
		'Pennsylvania Department of Military and Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.polish_legion_of_american_veterans_auxiliary,
		'Polish Legion of American Veterans Auxiliary'
	],
	[
		VeteranCurrentVSOMember.puerto_rico_veterans_advocate_office_auxiliary,
		'Puerto Rico Veterans Advocate Office Auxiliary'
	],
	[
		VeteranCurrentVSOMember.rhode_island_office_of_veterans_services_rivets_auxiliary,
		'Rhode Island Office of Veterans Services (RIVETS) Auxiliary'
	],
	[
		VeteranCurrentVSOMember.south_dakota_department_of_veterans_affairs_auxiliary,
		'South Dakota Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.swords_to_plowshares_auxiliary, 'Swords to Plowshares Auxiliary' ],
	[
		VeteranCurrentVSOMember.tennessee_department_of_veterans_services_auxiliary,
		'Tennessee Department of Veterans Services Auxiliary'
	],
	[ VeteranCurrentVSOMember.texas_veterans_commission_auxiliary, 'Texas Veterans Commission Auxiliary' ],
	[ VeteranCurrentVSOMember.the_retired_enlisted_association_auxiliary, 'The Retired Enlisted Association Auxiliary' ],
	[
		VeteranCurrentVSOMember.the_south_carolina_department_of_veterans_affairs_auxiliary,
		'The South Carolina Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.united_spanish_war_veterans_auxiliary, 'United Spanish War Veterans Auxiliary' ],
	[ VeteranCurrentVSOMember.united_spinal_asst__auxiliary, 'United Spinal Asst  Auxiliary' ],
	[
		VeteranCurrentVSOMember.utah_department_of_veterans_and_military_affairs_auxiliary,
		'Utah Department of Veterans and Military Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.vermont_office_of_veterans_affairs_auxiliary,
		'Vermont Office of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.veterans_of_foreign_wars_auxiliary, 'Veterans of Foreign Wars Auxiliary' ],
	[ VeteranCurrentVSOMember.veterans_of_the_vietnam_war_auxiliary, 'Veterans of the Vietnam War Auxiliary' ],
	[ VeteranCurrentVSOMember.veterans_of_ww_i_of_the_usa_auxiliary, 'Veterans of WW I of the USA Auxiliary' ],
	[ VeteranCurrentVSOMember.veterans_voice_of_america_auxiliary, "Veterans' Voice of America Auxiliary" ],
	[ VeteranCurrentVSOMember.vietnam_veterans_of_america_auxiliary, 'Vietnam Veterans of America Auxiliary' ],
	[
		VeteranCurrentVSOMember.virgin_islands_office_of_veterans_affairs_auxiliary,
		'Virgin Islands Office of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.virginia_department_of_veterans_services_auxiliary,
		'Virginia Department of Veterans Services Auxiliary'
	],
	[
		VeteranCurrentVSOMember.washington_department_of_veterans_affairs_auxiliary,
		'Washington Department of Veterans Affairs Auxiliary'
	],
	[
		VeteranCurrentVSOMember.west_virginia_dept_of_veterans_assistance_auxiliary,
		'West Virginia Dept of Veterans Assistance Auxiliary'
	],
	[
		VeteranCurrentVSOMember.wisconsin_department_of_veterans_affairs_auxiliary,
		'Wisconsin Department of Veterans Affairs Auxiliary'
	],
	[ VeteranCurrentVSOMember.wounded_warrior_project_auxiliary, 'Wounded Warrior Project Auxiliary' ],
	[ VeteranCurrentVSOMember.wyoming_veterans_commission_auxiliary, 'Wyoming Veterans Commission Auxiliary' ],
	[ VeteranCurrentVSOMember.the_veterans_coalition_auxiliary, 'The Veterans Coalition Auxiliary' ]
]);
