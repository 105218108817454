import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'
import PrimaryPerson from './PrimaryPerson'

export default class AdultChildren extends Model {
    static entity = 'adultChildrens'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            FirstName: this.string(''),
            LastName: this.string(''),
            MiddleName: this.string(''),
            DateOfBirth: this.string(null).nullable(),
            AgePerDOB: this.number(null).nullable(),
            IsLiving: this.boolean(null).nullable(),
            Gender: this.string(''),
            IsAdult: this.boolean(false).nullable(),
            ReferralAdded: this.boolean(false).nullable(),
            Email: this.string(''),
            State: this.string('').nullable(),
            ZipCode: this.string(null).nullable(),
            City: this.string(null).nullable(),
            Phone: this.string(null).nullable(),
            SpouseFirstName: this.string(''),
            SpouseLastName: this.string(''),
            Parent_Id: this.attr(null),
            Parent: this.belongsTo(PrimaryPerson, "Parent_Id")
        }
    }

    public FirstName!: string
    public LastName!: string
    public MiddleName!: string
    public DateOfBirth!: string
    public AgePerDOB!: number
    public Gender!: string
    public Parent_Id!: string
    public IsLiving!: boolean
    public IsAdult!: boolean
    public Email!: string
    public State!: string
    public ZipCode!: string
    public City!: string
    public Phone!: string
    public DOBPopup: boolean = false
    public DOBPicker: string = "1990-01-01"
    public ReferralAdded!: boolean
    public SpouseFirstName!: string
    public SpouseLastName!: string
    public Id!: string

    get IsNotEmpty() {
        return false;
    }
}