




































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class MedicalQuestionsComponent extends Vue {
  created() {
    this.$data.tab = 0;
  }
  changeTab(tabIndex: number) {
    this.$data.tab = tabIndex;
  }
  @Prop({ default: undefined })
  medicalQuestions!: any;

  data() {
    return {
      tab: null,
      questions: JSON.parse(JSON.stringify(this.$props.medicalQuestions)),
    };
  }
  close() {
    var medQues = JSON.stringify(this.$data.questions);
    this.$emit("ClosePopup", this.$data.questions);
  }
}
