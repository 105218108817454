
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class StrikeWaiverComponent extends Vue {
  data() {
    return {};
  }
}
