














































import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetLocalDataOrCallAPI } from "@/helpers/APIHelper";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({})
export default class UWPopup extends Vue {
  private UWTypes: any = null;

  created() {
    GetLocalDataOrCallAPI(
      ApiEndPointConstants.UWQuestionaireTemplatesEndpoint,
      [],
      IndexDBConstants.UWQuestionaireTempaltesKey
    ).then((result) => {
      this.UWTypes = result;
    });
  }
  changedUWType() {
    var type = this.$data.selectedUWType.Type
      ? this.$data.selectedUWType.Type
      : this.$data.selectedUWType;
    var uwType = this.UWTypes.find((x: any) => x.Type == type);

    if (!uwType) {
      uwType = this.UWTypes.find(
        (x: any) =>
          x.Keywords && x.Keywords.split("|").includes(type.toUpperCase())
      );
      this.$data.selectedUWType = uwType;
    }

    this.$data.selectedTemplate = uwType.Template;

    var svview = document.getElementsByClassName("formscroll");
    if (
      svview != undefined &&
      svview.length > 0 &&
      svview[0].children.length > 0
    ) {
      {
        setTimeout(() => {
          svview[0].children[svview[0].children.length - 1].scrollIntoView(
            true
          );
        }, 50);
      }
    }
  }
  data() {
    return {
      selectedTemplate: "",
      selectedUWType: undefined,
    };
  }
  closePopup() {
    this.$emit("closePopup", "");
  }
}
