import { Model } from '@vuex-orm/core';
import { EntityHelper } from '@/helpers/EntityHelper';
import Finances from './Finances';
import Goals from './Goals'
import Calculator from './Calculator';
import Calendar from './Calendar';

export default class GamePlan extends Model {
	static entity = 'gameplan';
	static primaryKey = 'Id';
	static fields() {
		return {
			Id: this.uid(() => EntityHelper.uuid()),
			Finances_Id: this.attr(null),
			Goals_Id: this.attr(null),
			Calculator_Id: this.attr(null),
			Calendar_Id: this.attr(null),
			Finances: this.belongsTo(Finances, 'Finances_Id'),
			Goals: this.belongsTo(Goals,'Goals_Id'),
			Calculator: this.belongsTo(Calculator,'Calculator_Id'),
			Calendar: this.belongsTo(Calendar,'Calendar_Id'),
			IncomeGoal: this.string('').nullable(),
			Year: this.number(0),
			Month: this.string(''),
			MonthId: this.number(0),
			// IsUpdated: this.boolean(false)
		};
	}
	Id : any
	Finances_Id : any
	Goals_Id : any
	Calculator_Id: any
	Finances !: Finances
	Goals !: Goals
	IncomeGoal !: string
	Year!: number
	Month !: string
	MonthId !: number
	// Isupdated !: boolean
	Calculator !: Calculator
	Calendar !: Calendar
}
