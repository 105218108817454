




















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import User from "./entities/User";
const Auth = namespace("Auth");
import Vuexfrom from "vuex";
import localforage from "localforage";
import { getPersistedState, setPersistedState } from "./helpers/StateMapper";
import Axios from "axios";
import GenericKeyPair from "./models/GenericKeyPair";
import { FetchInitialAppData, FetchUserLevelData } from "./helpers/SyncHelper";
import PresentationSyncing from "./helpers/PresentationSync";
import HelpPopup from "./components/popups/HelpPopup.vue";
import { isLoggedIn } from "./helpers/APIExtentions";
import LoggingHelper from "./helpers/LoggingHelper";
import { APIService } from "./services/APIService";
import GeneralConstants from "./constants/GeneralConstants";
@Component({ components: { HelpPopup } })
export default class App extends Vue {
	[x: string]: any;
	@Auth.State("user")
	private currentUser!: any;

	@Auth.Action
	private signOut!: () => void;

	private cronJobAppSyncInterval: number = process.env.VUE_APP_SYNC_INTERVAL;
	private cronJobPresSyncInterval: number =
		process.env.VUE_APP_PRES_SYNC_INTERVAL;

	data() {
		return {
			ShowHelpPopup: false,
		};
	}

	logOut() {
		this.signOut();
		User.deleteAll();
		this.$router.push("/login");
	}

	HelpPressed(evt: any) {
		console.log("F1 Pressed");
	}

	created() {
		document.addEventListener("gesturestart", (e) => e.preventDefault());

		//this.FillPresentationifNotExists();
		//FetchAppData();
		FetchInitialAppData();
		LoggingHelper.getInstance().DeleteLogForMorethanParticularDays();
		if (process.env.NODE_ENV != "development") this.RegisterCronJob();

		window.onkeydown = (evt: any) => {
			switch (evt.keyCode) {
				case 112:
					if (isLoggedIn()) {
						this.$data.ShowHelpPopup = true;
						console.log("F1 Pressed");
						return false;
					}
					return true;
			}
			return true;
		};
	}

	mounted() {
		if (!this.currentUser && this.$router.currentRoute.path != "/") {
			this.$router.push("/");
		}
	}

	RegisterCronJob() {
		let presMin = Math.floor(this.cronJobPresSyncInterval / 60);
		let presSec = this.cronJobPresSyncInterval - presMin * 60;
		let appMin = Math.floor(this.cronJobAppSyncInterval / 60);
		let appSec = this.cronJobAppSyncInterval - appMin * 60;

		let result = this.$crontab.addJob([
			{
				name: "PresSync",
				interval: {
					milliseconds: "0",
					seconds: presSec.toString(),
					minutes: "/" + presMin.toString(),
				},
				job: this.ExecutePresSyncCronJob,
			},
			{
				name: "AppSync",
				interval: {
					milliseconds: "0",
					seconds: appSec.toString(),
					minutes: "/" + appMin.toString(),
				},
				job: this.ExecuteAppSyncCronJob,
			},
		]);
		console.log(result);
	}

	async ExecutePresSyncCronJob() {
		console.log(
			"Pres Sync Cron Job Executed " + new Date().toLocaleTimeString()
		);
		await PresentationSyncing.getInstance().SyncPresentation();
	}

	async ExecuteAppSyncCronJob() {
		console.log(
			"App Sync Cron Job Executed " + new Date().toLocaleTimeString()
		);
		await this.GetNewWebVersion();
	}

	FillPresentationifNotExists() {
		try {
			getPersistedState("PresentationsList").then((val) => {
				if (val == null || val == undefined || val == "") {
					Axios.get(
						window.location.origin + "/static/sample-presentation-list.txt"
					)
						.then((val) => {
							if (val != undefined && val.data != undefined && val.data != "") {
								var saveData = new GenericKeyPair(
									"PresentationsList",
									val.data
								);
								setPersistedState(saveData);
							}
						})
						.catch((ex) => {});
				}
			});
		} catch {}
	}
	CloseHelpPopup() {
		this.$data.ShowHelpPopup = false;
	}

	async GetNewWebVersion() {
		var test = PresentationSyncing.getInstance().isSyncing;
		if (
			this.$route.name != undefined &&
			PresentationSyncing.getInstance().isSyncing == false
		) {
			if (!GeneralConstants.PresentationTypesPages.includes(this.$route.name)) {
				await APIService.GetCurrentAppVersion().then((res: any) => {
					if (
						res != undefined &&
						res.Result != undefined &&
						res.Result > process.env?.VUE_APP_VERSION
					)
						window.location.reload();
				});
			}
		}
	}
}
