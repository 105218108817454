import { Model } from '@vuex-orm/core';
import { EntityHelper } from '@/helpers/EntityHelper';

export default class Finances extends Model {
	static entity = 'Finances';
	static primaryKey = 'Id';
	static fields() {
		return {
			Id: this.uid(() => EntityHelper.uuid()),
			HousePayment: this.string(null).nullable(),
			Utilities: this.string(null).nullable(),
			CableInternet: this.string(null).nullable(),
			CellPhone: this.string(null).nullable(),
			MonthlyCarPayment: this.string(null).nullable(),
			CarInsurance: this.string(null).nullable(),
			Gas: this.string(null).nullable(),
			Travel: this.string(null).nullable(),
			MinDebtPay: this.string(null).nullable(),
			FoodEntertainment: this.string(null).nullable(),
			Insurance: this.string(null).nullable(),
			ChildCare: this.string(null).nullable(),
			Misc1: this.string(null).nullable(),
			Misc2: this.string(null).nullable(),
			RecruitingInvestment: this.string(null).nullable(),
			AgencyInvestment: this.string(null).nullable(),
			Mortgage: this.string(null).nullable(),
			CreditCards: this.string(null).nullable(),
			CarPayment: this.string(null).nullable(),
			LinesOfCredit: this.string(null).nullable(),
			PersonalLoans: this.string(null).nullable(),
			Other1: this.string(null).nullable(),
			Other2: this.string(null).nullable(),
			IncomeGoal: this.string(null).nullable(),
			// ProductionGoals: this.string(null).nullable(),
			IncomeCommitment: this.string(null).nullable(),
			// ProductionCommitment: this.string(null).nullable(),
			LessOtherIncome: this.string(null).nullable()
		};
	}
            Id: any
			HousePayment !:  string
			Utilities !:  string
			CableInternet !:  string
			CellPhone !:  string
			MonthlyCarPayment !:  string
			CarInsurance !:  string
			Gas !:  string
			Travel !:  string
			MinDebtPay !:  string
			FoodEntertainment !:  string
			Insurance !:  string
			ChildCare !:  string
			Misc1 !:  string
			Misc2 !:  string
			RecruitingInvestment !:  string
			AgencyInvestment !:  string
			Mortgage !:  string
			CreditCards !:  string
			CarPayment !:  string
			LinesOfCredit !:  string
			PersonalLoans !:  string
			Other1 !:  string
			Other2 !:  string
			IncomeGoal !: string
			// ProductionGoals !: string
			IncomeCommitment !: string
			// ProductionCommitment !: string
			LessOtherIncome !: string
}
