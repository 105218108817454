



















































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { gsap } from "gsap";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Spouse from "@/entities/Spouse";
// import PresentationWillKitVeterans from "@/entities/PresentationWillKitVeterans";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { GetApiDataOrCheckLocal } from "@/helpers/APIHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";
import Presentation from "@/entities/Presentation";
let RelationshipList = require("@/assets/pre-fill-data/relation_sponsor.json");
import VueHtml2pdf from "vue-html2pdf";
import { PrefillDocumentType } from "@/enumerations/PrefillDocumentType";
import { videoPlayer } from "vue-video-player";

@Component({ components: { VueHtml2pdf, videoPlayer } })
export default class VeteranThreeImportantFactsSMBPdfHtml extends Vue {
	private preDisable: boolean = true;
	private nextDisable: boolean = false;
	// private pageNumbers: Number = 1;
	// private totalNumbers: Number = 1;
	// private totalPages: any = 0;
	private personNotifiedItems: boolean = true;
	private veteransInfoItems: boolean = true;
	private insurancePolItems: boolean = true;
	private funeralInstItems: boolean = true;
	private disabledNavButton: boolean = false;
	private Person: any;
	private Spouse: any;
	// private PresentationWillKitVeterans: any;
	private ReferralsOtherService: any[] = [];
	private ReferralsOtherServiceCount: number = 1;
	private StateList: any = [];
	private stateListPromise: any = [];
	private freedomchoice: boolean = false;
	private additionalGroup: any = [];
	private serviceGroup: any = [];
	private UserNameForFredomofChoise = GetAscFullNameWithCamelCase();
	private BranchItems = require("../../assets/pre-fill-data/branch-of-service-types.json");
	private InvalidEmail = false;
	private PageNumbers: any = [1, 2];
	private Presentation: any = [];
	private RelationshiptoSp: any = RelationshipList;
	private ShowVideo: boolean = false;
	private InitialPerson: any;
	private InitialSpouse: any;
	private InitialVeteranObj: any;

	private FirstTimePopupShown: boolean = false;
	private AnyChangesMade: boolean = false;
	private VimeoVideoUrl: string = "";
	private ShowVimeoVideo: boolean = false;
	private _keyupCallback: any;

	@Prop({ default: undefined })
	private PresentationWillKitVeterans: any;

	data() {
		return {
			willKey: 1,
			familylength: 1,
			additionallength: 1,
			FamilyPdfTab: null,
			preDisable: true,
			nextDisable: false,
			CurrentDivIndex: 0,
			CurrentRowIndex: 0,
			CurrentPageNumbers: 1,
			totalPageCount: 2,
			PagesZoomIndex: [],
			ThreeImpFactAnimationDivList: [],
			ThreeImpFactAnimationRowList: [],
			FirstImpFactRow1Index: 0,
			FirstImpFactRow2Index: 1,
			SecondImpFactRow1Index: 2,
			SecondImpFactRow2Index: 3,
			ThirdImpFactRow1Index: 4,
			ThirdImpFactRow2Index: 5,
			PMCIndex: 0,
			BurialFlagsIndex: 1,
			BurialIndex: 2,
			pdfoptions: {
				margin: 6,
				filename: "hehehe.pdf",
				pagebreak: { mode: ["css"] },
				html2canvas: { useCORS: true, scale: 4 },
			},
			playerOptions: {
				// videojs options
				muted: true,
				language: "en",
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: "video/mp4",
						src: "static/ailvideo.mp4",
					},
				],
				autoplay: true,
				poster: "/static/images/author.jpg",
			},
		};
	}

	EmptyCommand(evt: any) {
		evt.stopImmediatePropagation();
	}

	created() {
		this.Presentation = Presentation.query().withAllRecursive().last();

		let parameters: GenericKeyPair[] = [];
		this.$store.state.loader = true;
		GetApiDataOrCheckLocal(
			ApiEndPointConstants.ReferralGroupCodeEndpoint,
			parameters,
			IndexDBConstants.ReferralGroupCode
		)
			.then((res: any) => {
				this.$store.state.loader = false;

				if (res != undefined) {
					this.additionalGroup = res.find((x: any) => x.GroupCode == "IGVFL");
					this.serviceGroup = res.find((x: any) => x.GroupCode == "IGSVL");
				}
			})
			.catch();

		this.loadDropdown();
		this.Person = PrimaryPerson.query().last();
		this.Spouse = Spouse.query().last();

		this.$data.ThreeImpFactAnimationDivList = Array.from(
			document.getElementsByClassName("threeimpfactanimationdivs")
		);

		this.$data.ThreeImpFactAnimationRowList = Array.from(
			document.getElementsByClassName("threeimpfactanimationrows")
		);

		if (this.$data.ThreeImpFactAnimationDivList != undefined) {
			this.$data.ThreeImpFactAnimationDivList.forEach(function (element: any) {
				element.classList.remove("show");
			});
		}

		if (this.$data.ThreeImpFactAnimationRowList != undefined) {
			this.$data.ThreeImpFactAnimationRowList.forEach(function (element: any) {
				element.classList.remove("show");
			});
		}

		setTimeout(() => {
			let sliderContainer =
				document.getElementsByClassName("PdfHtmlContainer")[0].children;
			for (let index = 0; index < this.$data.totalPageCount; index++) {
				this.$data.PagesZoomIndex.push(0);
			}
			if (
				this.PresentationWillKitVeterans?.DocumentTypeIdList?.indexOf(
					PrefillDocumentType.ThreeImportantFactsSmb
				) == -1
			) {
				this.PresentationWillKitVeterans?.DocumentTypeIdList?.push(
					PrefillDocumentType.ThreeImportantFactsSmb
				);
			}
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
			this.InitialSpouse = JSON.stringify(this.Spouse);
			this.Person.Spouse = this.Spouse;
			this.InitialPerson = JSON.stringify(this.Person);
		}, 100);

		this._keyupCallback = (event: any) => {
			this.$data.ThreeImpFactAnimationDivList = Array.from(
				document.getElementsByClassName("threeimpfactanimationdivs")
			);

			this.$data.ThreeImpFactAnimationRowList = Array.from(
				document.getElementsByClassName("threeimpfactanimationrows")
			);

			if (event.code == "Escape") {
				if (this.ShowVideo) {
					this.ShowVideo = false;
					return;
				} else if (this.ShowVimeoVideo) {
					this.ShowVimeoVideo = false;
					return;
				}
			}

			if (this.ShowVideo || this.ShowVimeoVideo) {
				return;
			}

			if (
				event.code == "ArrowUp" ||
				event.code == "ArrowLeft" ||
				event.code == "Delete" ||
				event.code == "Backspace"
			) {
				if (this.$data.CurrentPageNumbers == 1) {
					if (this.$data.CurrentDivIndex <= 0) {
						this.$data.CurrentDivIndex = 0;
						return;
					}

					if (
						this.$data.CurrentDivIndex >
						this.$data.ThreeImpFactAnimationDivList.length
					) {
						this.$data.CurrentDivIndex =
							this.$data.ThreeImpFactAnimationDivList.length;
					}

					this.$data.CurrentDivIndex--;
					var curitem =
						this.$data.ThreeImpFactAnimationDivList[this.$data.CurrentDivIndex];
					if (curitem) {
						this.$data.ThreeImpFactAnimationDivList[
							this.$data.CurrentDivIndex
						].show = false;
					}
				} else if (this.$data.CurrentPageNumbers == 2) {
					if (this.$data.CurrentRowIndex <= 0) {
						this.$data.CurrentRowIndex = 0;
						return;
					}

					if (
						this.$data.CurrentRowIndex >
						this.$data.ThreeImpFactAnimationRowList.length
					) {
						this.$data.CurrentRowIndex =
							this.$data.ThreeImpFactAnimationRowList.length;
					}

					this.$data.CurrentRowIndex--;
					var curitem =
						this.$data.ThreeImpFactAnimationRowList[this.$data.CurrentRowIndex];
					if (curitem) {
						this.$data.ThreeImpFactAnimationRowList[
							this.$data.CurrentRowIndex
						].show = false;
					}
				}
			} else {
				if (this.$data.CurrentPageNumbers == 1) {
					if (this.$data.CurrentDivIndex < 0) {
						this.$data.CurrentDivIndex = 0;
					}

					if (
						this.$data.CurrentDivIndex >
						this.$data.ThreeImpFactAnimationDivList.length - 1
					) {
						return;
					}

					var curitem =
						this.$data.ThreeImpFactAnimationDivList[this.$data.CurrentDivIndex];
					if (curitem) {
						this.$data.ThreeImpFactAnimationDivList[
							this.$data.CurrentDivIndex
						].show = true;
						this.$data.CurrentDivIndex++;
					}
				} else if (this.$data.CurrentPageNumbers == 2) {
					if (this.$data.CurrentRowIndex < 0) {
						this.$data.CurrentRowIndex = 0;
					}

					if (
						this.$data.CurrentRowIndex >
						this.$data.ThreeImpFactAnimationRowList.length - 1
					) {
						return;
					}

					var curitem =
						this.$data.ThreeImpFactAnimationRowList[this.$data.CurrentRowIndex];
					if (curitem) {
						this.$data.ThreeImpFactAnimationRowList[
							this.$data.CurrentRowIndex
						].show = true;
						this.$data.CurrentRowIndex++;
					}
				}
			}
		};

		document.addEventListener("keyup", this._keyupCallback);
	}

	beforeDestroy() {
		document.removeEventListener("keyup", this._keyupCallback);
	}

	async loadDropdown() {
		this.StateList = await getPersistedState("StateList");
		this.stateListPromise = this.StateList.filter((x: any) => x.CountryId != 3);
		if (
			this.Person.State != undefined &&
			this.Person.State != "" &&
			this.Person.State != 0
		) {
			this.PresentationWillKitVeterans.State = this.stateListPromise.find(
				(x: any) => x.StateId == this.Person.State
			);
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
			// if (this.PresentationWillKitVeterans.State != undefined && typeof(this.PresentationWillKitVeterans.State) != "string")
			//   this.PresentationWillKitVeterans.State = this.PresentationWillKitVeterans.State.StateCode;
		}
		if (this.Person.City != undefined && this.Person.City != "") {
			this.PresentationWillKitVeterans.City = this.Person.City;
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
		}
	}

	ZoomContainerClick(pageIndex: number) {
		var currentPageZoomIndex = this.$data.PagesZoomIndex[pageIndex];
		if (currentPageZoomIndex >= 2) this.$data.PagesZoomIndex[pageIndex] = 0;
		else
			this.$data.PagesZoomIndex[pageIndex] =
				this.$data.PagesZoomIndex[pageIndex] + 1;

		this.$data.PagesZoomIndex = JSON.parse(
			JSON.stringify(this.$data.PagesZoomIndex)
		);
	}

	next() {
		let sliderContainer =
			document.getElementsByClassName("PdfHtmlContainer")[0].children;

		gsap.from(".PdfHtmlPage", {
			opacity: 0,
			x: 500,
			duration: 0.4,
		});

		for (let item = 0; item < sliderContainer.length; item++) {
			let active = document.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item++;
				sliderContainer[item].classList.add("active");
				let pageItem = item;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				this.preDisable = false;
				if (item == sliderContainer.length - 1) {
					this.nextDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 400);
	}

	prev() {
		let sliderContainer =
			document.getElementsByClassName("PdfHtmlContainer")[0].children;
		let getActive = document.getElementsByClassName("PdfHtmlContainer")[0];

		gsap.from(".PdfHtmlPage", {
			opacity: 0,
			x: -500,
		});
		for (let item = sliderContainer.length - 1; item >= 0; item--) {
			let active = getActive.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item--;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				sliderContainer[item].classList.add("active");
				this.nextDisable = false;
				if (item == 0) {
					this.preDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 400);
	}

	ContainerDrag(evt: any) {
		const ele: any = document.getElementById("container");

		const dx = evt.clientX - ele.scrollTop;
		const dy = evt.clientY - ele.scrollLeft;

		// Scroll the element
		ele.scrollTop = ele.clientTop - dy;
		ele.scrollLeft = ele.clientLeft - dx;

		evt.preventDefault();
	}

	PreventClickCmd(evt: any) {
		evt.stopPropagation();
		// evt.preventDefault();
	}

	startdrag() {
		const ele: any = document.getElementById("container");
		ele.style.cursor = "grab";

		let pos = { top: 0, left: 0, x: 0, y: 0 };

		const mouseDownHandler = function (e: any) {
			ele.style.cursor = "grabbing";
			ele.style.userSelect = "none";

			pos = {
				left: ele.scrollLeft,
				top: ele.scrollTop,
				// Get the current mouse position
				x: e.clientX,
				y: e.clientY,
			};

			document.addEventListener("mousemove", mouseMoveHandler);
			document.addEventListener("mouseup", mouseUpHandler);
		};

		const mouseMoveHandler = function (e: any) {
			// How far the mouse has been moved
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			// Scroll the element
			e.scrollTop = pos.top - dy;
			e.scrollLeft = pos.left - dx;
		};

		const mouseUpHandler = function () {
			ele.style.cursor = "grab";
			ele.style.removeProperty("user-select");

			document.removeEventListener("mousemove", mouseMoveHandler);
			document.removeEventListener("mouseup", mouseUpHandler);
		};

		// Attach the handler
		ele.addEventListener("mousedown", mouseDownHandler);
	}

	SaveWillKitWithPresentation() {
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	$refs!: {
		html2Pdf: any;
		videoPlayer: any;
	};

	DownloadPDF() {
		this.$store.state.loader = true;
		// this.SaveWillKitWithPresentation
		setTimeout(() => {
			if (
				this.Person != undefined &&
				this.Person.FirstName != undefined &&
				this.Person.FirstName != ""
			)
				this.$data.pdfoptions.filename =
					"Three Important Facts - " + this.Person.FirstName;
			else this.$data.pdfoptions.filename = "Three Important Facts";
			this.$refs.html2Pdf.downloadPdf().then(() => {
				this.$store.state.loader = false;
			});
			this.FirstTimePopupShown = true;
		}, 500);
	}

	public isClosable(): boolean {
		if (
			((JSON.stringify(this.PresentationWillKitVeterans) !=
				this.InitialVeteranObj &&
				this.InitialVeteranObj != "") ||
				JSON.stringify(this.Person) != this.InitialPerson ||
				JSON.stringify(this.Spouse) != this.InitialSpouse ||
				(this.Presentation.LeadId != undefined &&
					this.Presentation.LeadId != "" &&
					this.Presentation.isWillKitFormDownloaded == false)) &&
			this.FirstTimePopupShown == false
		) {
			if (this.Presentation.LeadId != undefined) {
				this.Presentation = Presentation.query().withAllRecursive().last();
				this.Presentation.isWillKitFormDownloaded = true;
				this.FirstTimePopupShown = true;
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
			}
			return false;
		} else if (
			JSON.stringify(this.PresentationWillKitVeterans) ==
				this.InitialVeteranObj &&
			this.InitialVeteranObj != ""
		) {
			this.Presentation = Presentation.query().withAllRecursive().last();
			if (
				this.Presentation.PresentationWillKitVeterans != null &&
				this.Presentation.PresentationWillKitVeterans != undefined &&
				this.Presentation.PresentationWillKitVeterans != "" &&
				this.Presentation.PresentationWillKitVeterans !=
					JSON.stringify(this.PresentationWillKitVeterans)
			) {
				this.Presentation.PresentationWillKitVeterans = JSON.stringify(
					this.PresentationWillKitVeterans
				);
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
			}
		}
		return true;
	}

	async PlayVideo() {
		this.ShowVideo = false;
		this.ShowVimeoVideo = false;
		this.PresentationWillKitVeterans.VeteranVideoClicked = true;
		this.SaveWillKitWithPresentation();
		// if (this.$store.state.SGNumber == "WWI01") {
		// 	this.VimeoVideoUrl =
		// 		"https://player.vimeo.com/video/904833766?h=3a5397ed63&title=0&byline=0&portrait=0";
		// 	this.ShowVimeoVideo = true;
		// } else if (this.$store.state.SGNumber == "WWA01") {
		// 	this.VimeoVideoUrl =
		// 		"https://player.vimeo.com/video/914993064?h=55487fb966&title=0&byline=0&portrait=0";
		// 	this.ShowVimeoVideo = true;
		// } else {
		this.VimeoVideoUrl =
			"https://player.vimeo.com/video/920095184?h=ae3e1e7f38&title=0&byline=0&portrait=0";
		this.ShowVimeoVideo = true;
		// }
		// else {
		// 	this.$data.playerOptions = {
		// 		// videojs options
		// 		muted: false,
		// 		language: "en",
		// 		playbackRates: [0.7, 1.0, 1.5, 2.0],
		// 		sources: [
		// 			{
		// 				type: "video/mp4",
		// 				src: "https://hp-pro-materials-dev.s3.amazonaws.com/static/3ImportantFacts-kim-video-1-v2.MOV",
		// 			},
		// 		],
		// 		autoplay: false,
		// 		poster: "/static/images/author.jpg",
		// 	};
		// 	this.ShowVideo = true;
		// }
	}

	CloseVideo() {
		this.ShowVideo = false;
		this.ShowVimeoVideo = false;
	}
}
