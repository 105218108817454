





























































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Children from "@/entities/Children";
import moment from "moment";
import FamilyCareSurveyInsurance from "@/entities/FamilyCareSurveyInsurance";
import { ConvertIntoAmountFormatWithDecimal } from "@/helpers/CalculationHelper";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class AddFamilyCareSurveyInsuranceComponent extends Vue {
	private person: any;
	private gender = listofGenderTypes;
	private isDialogOpen: boolean = false;
	private InsuranceTypes = ["WHL", "TERM", "ADB"];

	@Prop({ default: "Input Property" })
	primaryName!: string;
	@Prop({ default: "Input Property" })
	spouseName!: string;

	@Prop({ default: "Input Property" })
	lstPrimaryInsuranceThroughWork!: Array<FamilyCareSurveyInsurance>;

	@Prop({ default: "Input Property" })
	lstPrimaryInsuranceOutsideWork!: Array<FamilyCareSurveyInsurance>;

	@Prop({ default: "Input Property" })
	lstSpouseInsuranceThroughWork!: Array<FamilyCareSurveyInsurance>;

	@Prop({ default: "Input Property" })
	lstSpouseInsuranceOutsideWork!: Array<FamilyCareSurveyInsurance>;

	@Prop({ default: "Input Property" })
	truncateProperties!: boolean;

	@Prop({ default: false })
	IsVeteranAssessment!: boolean;

	@Prop({ default: false })
	IsNeedAnalysis!: boolean;

	data() {
		return {
			lstPrimaryInsuranceThroughWork: this.lstPrimaryInsuranceThroughWork,
			lstPrimaryInsuranceOutsideWork: this.lstPrimaryInsuranceOutsideWork,
			lstSpouseInsuranceThroughWork: this.lstSpouseInsuranceThroughWork,
			lstSpouseInsuranceOutsideWork: this.lstSpouseInsuranceOutsideWork,
		};
	}

	AddPrimaryInsuranceThroughWork() {
		this.lstPrimaryInsuranceThroughWork.push(new FamilyCareSurveyInsurance());
	}
	AddPrimaryInsuranceOutsideWork() {
		this.lstPrimaryInsuranceOutsideWork.push(new FamilyCareSurveyInsurance());
	}
	AddSpouseInsuranceThroughWork() {
		this.lstSpouseInsuranceThroughWork.push(new FamilyCareSurveyInsurance());
	}
	AddSpouseInsuranceOutsideWork() {
		this.lstSpouseInsuranceOutsideWork!.push(new FamilyCareSurveyInsurance());
	}

	RemovePrimaryInsuranceThroughWork(id: number) {
		this.lstPrimaryInsuranceThroughWork.splice(id, 1);
	}
	RemovePrimaryInsuranceOutsideWork(id: number) {
		this.lstPrimaryInsuranceOutsideWork.splice(id, 1);
	}
	RemoveSpouseInsuranceThroughWork(id: number) {
		this.lstSpouseInsuranceThroughWork.splice(id, 1);
	}
	RemoveSpouseInsuranceOutsideWork(id: number) {
		this.lstSpouseInsuranceOutsideWork.splice(id, 1);
	}

	save() {
		this.$emit("saveInsurance");
	}
	cancel() {
		this.$emit("cancelInsurance");
	}

	created() {
		this.UpdateInsuranceType(this.lstPrimaryInsuranceThroughWork);
		this.UpdateInsuranceType(this.lstPrimaryInsuranceOutsideWork);
		this.UpdateInsuranceType(this.lstSpouseInsuranceThroughWork);
		this.UpdateInsuranceType(this.lstSpouseInsuranceOutsideWork);
		this.isDialogOpen = true;
	}
	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelInsurance");
		}, 500);
	}

	UpdateInsuranceType(insurance: any) {
		for (let index = 0; index < insurance.length; index++) {
			if (index == 0) {
				insurance[index].InsuranceType = "WHL";
			}
			if (index == 1) {
				insurance[index].InsuranceType = "TERM";
			}
			if (index == 2) {
				insurance[index].InsuranceType = "ADB";
			}
		}
	}

	isNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	ApplyValuebyFieldString(fieldString: string, index: any, value: string) {
		var properties = fieldString.split(".")[1];
		switch (fieldString.split(".")[0]) {
			case "PrimaryInsuranceThroughWork":
				var setPropertyvalue: any =
					this.$data.lstPrimaryInsuranceThroughWork[index];
				if (properties != undefined) {
					setPropertyvalue[properties] = value;
				}
				this.lstPrimaryInsuranceThroughWork[index] = setPropertyvalue;
				break;
			case "PrimaryInsuranceOutsideWork":
				var setPropertyvalue: any =
					this.$data.lstPrimaryInsuranceOutsideWork[index];
				if (properties != undefined) {
					setPropertyvalue[properties] = value;
				}
				this.lstPrimaryInsuranceOutsideWork[index] = setPropertyvalue;
				break;
			case "SpouseInsuranceThroughWork":
				var setPropertyvalue: any =
					this.$data.lstSpouseInsuranceThroughWork[index];
				if (properties != undefined) {
					setPropertyvalue[properties] = value;
				}
				this.lstSpouseInsuranceThroughWork[index] = setPropertyvalue;
				break;
			case "SpouseInsuranceOutsideWork":
				var setPropertyvalue: any =
					this.$data.lstSpouseInsuranceOutsideWork[index];
				if (properties != undefined) {
					setPropertyvalue[properties] = value;
				}
				this.lstSpouseInsuranceOutsideWork[index] = setPropertyvalue;
				break;
		}
	}

	AmountFieldBlur(evt: any, index: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}

		if (value == "") {
			this.ApplyValuebyFieldString(
				fieldString,
				index,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		} else {
			this.ApplyValuebyFieldString(
				fieldString,
				index,
				ConvertIntoAmountFormatWithDecimal(
					Number(value.replaceAll(",", "")).toFixed(2),
					true,
					true
				)
			);
		}
	}
	InputAmountField(evt: any) {
		if (
			evt.target != undefined &&
			evt.target.value != undefined &&
			evt.target.value != ""
		) {
			if (evt.target.value != "0") {
				var previusSelectionIndex = evt.target.selectionStart;
				evt.target.value = ConvertIntoAmountFormatWithDecimal(evt.target.value);
				var newSelectionIndex = evt.target.selectionStart;
				if (newSelectionIndex > previusSelectionIndex + 1) {
					evt.target.setSelectionRange(
						previusSelectionIndex,
						previusSelectionIndex
					);
				}
			}
		}
	}
}
