



























































































































































































































































































































































































































































































































































































import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Report from "@/entities/Report";
import { setPersistedState } from "@/helpers/StateMapper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import MenuComponent from "@/components/menu.vue";
import SponsershipCard from "@/components/SponsershipCard.vue";
import Sponsership from "@/entities/Sponsership";
import ShortUserDetailView from "@/components/UserShortDetailView.vue";
import Spouse from "@/entities/Spouse";
import { getPersistedState } from "@/helpers/StateMapper";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import ReferralPopup from "@/components/popups/ReferralPopup.vue";
import moment from "moment";
import { PresentationType } from "@/enumerations/PresentationType";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
import LoggingHelper from "@/helpers/LoggingHelper";
import { EventBusLayout } from "@/bus/EventBusLayout";

@Component({
	components: {
		ShortUserDetailView,
		MenuComponent,
		SponsershipCard,
		ReferralPopup,
	},
})
export default class ReportCard extends Vue {
	private Person: any;
	private Presentation: any;
	private allPresTypes: any = [];
	private allReportConfigs: any = [];
	private pageLoadedTime: any;
	private backgroundimage: string = "";
	private valid: boolean = false;
	private Groups: any = [];
	private MemberCareerSponser: Sponsership = new Sponsership();
	private MemberSpouseSponser: Sponsership = new Sponsership();
	private noCostReportConfigs: any = [];
	private permanentReportConfigs: any = [];
	private moreInfoReportConfigs: any = [];
	private overlay: boolean = false;
	private OtherCareerOption: boolean = false;
	private statesList: any = [];
	private currentItem: any;
	private ssprogram: boolean = false;
	private somemodal: boolean = false;
	private basicDetailRequired: boolean = false;
	private VSOQuestionRequired: boolean = false;
	private ResetReferrals: boolean = false;
	private browserCloseEvent: boolean = false;

	private VeteranPresentation: any = PresentationType.Veteran;
	private ServiceMemberBenefitsPresentation: any =
		PresentationType.ServiceMemberBenefits;

	private SponserShipList: Sponsership[] = [];
	$refs!: {
		reportForm: any;
		groupComboBox: any;
		groupNameComboBox: any;
		CareerSponsorForm: any;
		VSOForm: any;
	};

	get BackgroundImagesOnPresentationType() {
		var DefaultType = true;
		this.IsVeteranType = false;
		this.IsVeteranSMBAlphaType = false;
		this.IsMcGruffType = false;
		this.IsUnionType = false;
		this.IsPOSType = false;
		this.IsAssociationType = false;
		this.IsGlobeLifeType = false;
		this.IsServiceMemberBenefitsType = false;
		this.IsServiceMemberBenefitsSVLAlphaType = false;
		this.IsServiceMemberBenefitsVFLAlphaType = false;
		this.PresentationforReport = this.PresentationData.Presentation;
		if (this.PresentationforReport != null) {
			this.IsVeteranType =
				PresentationType.Veteran ==
				this.PresentationforReport.PresentationTypeId;
			this.IsVeteranSMBAlphaType =
				PresentationType.Veteran ==
					this.PresentationforReport.PresentationTypeId &&
				PresentationSubType.ServiceMemberBenefitsLeadAlpha ==
					this.PresentationforReport.PresentationSubTypeId;
			this.IsMcGruffType =
				PresentationType.McGruffSafeKids ==
				this.PresentationforReport.PresentationTypeId;
			this.IsUnionType =
				PresentationType.Union == this.PresentationforReport.PresentationTypeId;
			this.IsPOSType =
				PresentationType.POS == this.PresentationforReport.PresentationTypeId;
			this.IsAssociationType =
				PresentationType.Association ==
				this.PresentationforReport.PresentationTypeId;
			this.IsGlobeLifeType =
				PresentationType.GlobeLife ==
				this.PresentationforReport.PresentationTypeId;
			this.IsServiceMemberBenefitsType =
				PresentationType.ServiceMemberBenefits ==
				this.PresentationforReport.PresentationTypeId;
			this.IsServiceMemberBenefitsSVLAlphaType =
				PresentationType.ServiceMemberBenefits ==
					this.PresentationforReport.PresentationTypeId &&
				(PresentationSubType.SMBSponsoredVeteranLeadAlpha ==
					this.PresentationforReport.PresentationSubTypeId ||
					PresentationSubType.ServiceMemberBenefitNotification ==
						this.PresentationforReport.PresentationSubTypeId);
			this.IsServiceMemberBenefitsVFLAlphaType =
				PresentationType.ServiceMemberBenefits ==
					this.PresentationforReport.PresentationTypeId &&
				(PresentationSubType.SMBVeteranFamilyLeadAlpha ==
					this.PresentationforReport.PresentationSubTypeId ||
					PresentationSubType.Civilian ==
						this.PresentationforReport.PresentationSubTypeId);
			if (
				this.IsVeteranType ||
				this.IsVeteranSMBAlphaType ||
				this.IsMcGruffType ||
				this.IsUnionType ||
				this.IsPOSType ||
				this.IsAssociationType ||
				this.IsGlobeLifeType ||
				this.IsServiceMemberBenefitsType ||
				this.IsServiceMemberBenefitsSVLAlphaType ||
				this.IsServiceMemberBenefitsVFLAlphaType
			) {
				DefaultType = false;
			} else {
				this.IsVeteranType = false;
				this.IsVeteranSMBAlphaType = false;
				this.IsMcGruffType = false;
				this.IsUnionType = false;
				this.IsPOSType = false;
				this.IsAssociationType = false;
				this.IsGlobeLifeType = false;
				this.IsServiceMemberBenefitsType = false;
				this.IsServiceMemberBenefitsSVLAlphaType = false;
				this.IsServiceMemberBenefitsVFLAlphaType = false;
				DefaultType = true;
			}
		}
		return DefaultType;
	}

	LoadDropDown() {
		new Promise(async () => {
			this.$store.state.loader = true;
			var allgrps = await getPersistedState("PresentationGroups");
			this.Groups = allgrps;

			var statesfromDB = await getPersistedState("StateList");

			this.statesList = statesfromDB;
			var state = this.statesList.filter((x: any) => {
				if (x.Name == this.PresentationData.Presentation.State) return x;
			});

			var allReportOptions: any = await getPersistedState("ReportOptions");

			if (allReportOptions != undefined && allReportOptions.length > 0) {
				var countryId = this.PresentationData.Presentation.CountryId;
				var presentationId =
					this.PresentationData.Presentation.PresentationTypeId;
				var presentationSubTypeId =
					this.PresentationData.Presentation.PresentationSubTypeId;

				var CurrrentScenarioReportOptions = allReportOptions.filter(
					(x: any) => {
						if (
							x.PresentationTypeId == presentationId &&
							(x.CountryId == undefined || x.CountryId == countryId)
						)
							return x;
					}
				);

				if (
					CurrrentScenarioReportOptions != undefined &&
					CurrrentScenarioReportOptions.length > 0
				) {
					for (let i = 0; i < this.ReportCardData.length; i++) {
						CurrrentScenarioReportOptions.find((element: any) => {
							if (
								element.ReportOptionId ==
									this.ReportCardData[i].ReportCardConfigId &&
								this.ReportCardData[i].Value == true
							) {
								element.selected = true;
							}
						});
					}
					this.noCostReportConfigs = CurrrentScenarioReportOptions.filter(
						(x: any) => x.ReportCardSectionTypeId == 1
					).sort((a: any, b: any) => a.SortOrder - b.SortOrder);

					this.permanentReportConfigs = CurrrentScenarioReportOptions.filter(
						(x: any) => x.ReportCardSectionTypeId == 2
					).sort((a: any, b: any) => a.SortOrder - b.SortOrder);

					this.moreInfoReportConfigs = CurrrentScenarioReportOptions.filter(
						(x: any) => x.ReportCardSectionTypeId == 3
					).sort((a: any, b: any) => a.SortOrder - b.SortOrder);

					if (this.noCostReportConfigs != undefined) {
						if (
							(presentationId == PresentationType.Veteran &&
								presentationSubTypeId !=
									PresentationSubType.ServiceMemberBenefitsLeadAlpha) ||
							(presentationId == PresentationType.ServiceMemberBenefits &&
								presentationSubTypeId !=
									PresentationSubType.SMBSponsoredVeteranLeadAlpha &&
								presentationSubTypeId !=
									PresentationSubType.SMBVeteranFamilyLeadAlpha &&
								presentationSubTypeId != PresentationSubType.Civilian &&
								presentationSubTypeId !=
									PresentationSubType.ServiceMemberBenefitNotification)
						) {
							this.noCostReportConfigs = this.noCostReportConfigs
								.filter((x: any) => x.ReportOptionId != 20)
								.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
						}
					}

					if (this.moreInfoReportConfigs != undefined) {
						if (
							(presentationId == PresentationType.Veteran &&
								presentationSubTypeId ==
									PresentationSubType.ServiceMemberBenefitsLeadAlpha) ||
							(presentationId == PresentationType.ServiceMemberBenefits &&
								(presentationSubTypeId ==
									PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
									presentationSubTypeId ==
										PresentationSubType.SMBVeteranFamilyLeadAlpha ||
									presentationSubTypeId == PresentationSubType.Civilian ||
									presentationSubTypeId ==
										PresentationSubType.ServiceMemberBenefitNotification))
						) {
							this.moreInfoReportConfigs = this.moreInfoReportConfigs
								.filter(
									(x: any) => x.ReportOptionId != 17 && x.ReportOptionId != 18
								)
								.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
						}

						var k = this.moreInfoReportConfigs.find((x: any) => {
							if (x.Name == "BECOMING A ") {
								x.ShowVSOBox = true;
							}
						});
					}
				}
			}
			this.GetLeadSource(this.PresentationData.Presentation);
			this.allPresTypes = await getPersistedState(
				IndexDBConstants.PresentationTypesKey
			);
			if (this.allPresTypes != undefined && this.allPresTypes.length > 0) {
				var currPresType = this.allPresTypes.find(
					(x: any) =>
						x.Id == this.PresentationData.Presentation.PresentationTypeId
				);
				this.backgroundimage = currPresType?.ReportBackgroundImage;
			}
			this.$store.state.loader = false;
			this.isDialogOpen = true;
		});
	}

	created() {
		this.LoadDropDown();
		if (
			this.PresentationData.Presentation != undefined &&
			this.PresentationData.Presentation.Report != undefined
		) {
			if (
				this.PresentationData.Presentation.Report.VSOQuestionnaire !=
					undefined &&
				this.PresentationData.Presentation.Report.VSOQuestionnaire != ""
			) {
				this.VSOQuestions = JSON.parse(
					this.PresentationData.Presentation.Report.VSOQuestionnaire
				);
			}
		}
		if (
			this.PresentationData.Presentation != undefined &&
			this.PresentationData.Presentation.PresentedTo != undefined
		) {
			this.PersonInfo = this.PresentationData.Presentation.PresentedTo;
		}
		if (this.PresentationData.Presentation != undefined) {
			var StartDate = moment(
				String(this.PresentationData.Presentation.StartTime)
			);
			var diff = moment().diff(StartDate, "days");
			if (diff > 90) {
				this.ShowMemberIfValid = false;
			}
		}
	}

	GetLeadSource(presentation: any) {
		this.GroupCode = "";
		this.GroupCodeName = "";
		if (
			presentation != undefined &&
			presentation.PresentedGroupId != null &&
			presentation.PresentedGroupId != 0
		) {
			if (this.Groups != undefined && this.Groups.length > 0) {
				let Group = this.Groups.find((x: any) => {
					if (x.Id == presentation.PresentedGroupId) return x;
				});
				if (Group != undefined) {
					this.GroupCode = Group.GroupCode;
					this.GroupCodeName = Group.GroupName;
				}
			}
		}
	}
	data() {
		return {
			ops: {
				scrollPanel: {
					scrollingX: false,
				},
			},
			componentKey: 0,
			Report: null,
			counrteousRating: null,
			programofValue: false,
			timeRating: null,
			RepCourtiousVali: "white",
			RepTimeVali: "white",
			ProgValueVali: "white",
			Group: null,
			SGNumber: null,
			Referrals: {},

			Required: [
				(v: any) => !!v || "Field is required",
				(v: any) => !!v.trim() || "Field is required",
			],
			RequiredGroup: [(v: any) => !!v || "Field is required"],
			PrimaryRadioRequired: [(v: any) => v != undefined || "Field is required"],
			careerupgradeshown: false,
			VSOpopupshown: false,
			PhoneRules: [(v: any) => (!!v && v.length > 13) || "Phone must be valid"],
			PhoneRulesNotRequired: [
				(v: any) =>
					v == undefined || v == "" || v.length > 13 || "Phone must be valid",
			],
			EmailRules: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return false;
					}
				},
			],
			EmailRulesNotRequired: [
				(value: any) => {
					if (value.length > 0) {
						const pattern =
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return pattern.test(value) || "Invalid e-mail.";
					} else {
						return true;
					}
				},
			],
			RequiredZip: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						var zipFormat = this.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			ZipFormat: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						if (v.trim().length == 0) return true;
						var zipFormat = this.Presentation.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
		};
	}
	reset() {
		this.$refs.reportForm.reset();
		this.$data.timeRating = null;
		this.$data.counrteousRating = null;
	}

	private allProducts: any = {};
	private allMatchingproducts: any = [];
	private isDialogOpen: boolean = false;
	private GroupCode: string = "";
	private GroupCodeName: string = "";
	private VSOQuestions: any = {};
	private PersonInfo: any = {};
	private ShowMemberIfValid: boolean = true;
	private PresentationforReport: any = {};
	private IsVeteranType: boolean = false;
	private IsVeteranSMBAlphaType: boolean = false;
	private IsMcGruffType: boolean = false;
	private IsUnionType: boolean = false;
	private IsPOSType: boolean = false;
	private IsAssociationType: boolean = false;
	private IsGlobeLifeType: boolean = false;
	private IsServiceMemberBenefitsType: boolean = false;
	private IsServiceMemberBenefitsSVLAlphaType: boolean = false;
	private IsServiceMemberBenefitsVFLAlphaType: boolean = false;

	@Prop({ default: undefined })
	private PresentationData: any;

	@Prop({ default: undefined })
	private ReportCardData: any;

	loadCheckBoxPopup(item: any) {
		item.selected = !item.selected;
		if (item.selected) item.isBold = true;
		else item.isBold = false;
	}

	CheckBoxChange(item: any) {
		if (item.selected) item.isBold = true;
		else item.isBold = false;
	}

	CancelClick() {
		this.Presentation.TimeOnReportCard = (
			(parseInt(this.Presentation.TimeOnReportCard) || 0) +
			Math.abs(Date.now() - this.pageLoadedTime)
		).toString();

		Presentation.update({
			where: this.Presentation.Id,
			data: this.Presentation,
		});
		this.$router.go(-1);
	}

	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelreportcard");
		}, 500);
	}

	cancel() {
		this.$emit("cancelreportcard");
	}
}
