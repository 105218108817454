import { localForageService } from '../services/localForageService';
import localforageGetItems from 'localforage-getitems';
import GenericKeyPair from '../models/GenericKeyPair';

export const setPersistedState = (state: GenericKeyPair) => {
    const persistedState = mapToPersistedState(state);
};

export const getPersistedState = (fetchKey: string) => {
    if (fetchKey) {
        return localForageService.getItem(fetchKey).catch((ex: any) => {
            console.log("Exception on IndexedDB" + ex)
        });
    } else {
        // get everything!!
        return localForageService.getItems().then(resultObj => {
            return Promise.resolve(Object.values(resultObj));
        }).catch((ex: any) => {
            console.log("Exception on IndexedDB" + ex)
        });
    }
}

export const deletePersistedState = (deletekey: string) => {
    // u decide how to delete cache
    localForageService.removeItem(deletekey).catch((ex: any) => {
        console.log("Exception on IndexedDB" + ex)
    });
}

export const deleteAllPersistedState = () => {
    // u decide how to delete cache
    localForageService.clear().catch((ex: any) => {
        console.log("Exception on IndexedDB" + ex)
    });
}

export const mapToPersistedState = (state: GenericKeyPair) => {
    // whatever your business logic is.

    localForageService.setItem(state.Key, state.Value, function () {
        console.log('Of the driver options given, localforage is using:' + localForageService.driver());
    }).catch((ex: any) => {
        console.log("Exception on IndexedDB" + ex)
    });;
};


// export const appendToPersistedState = (state: GenericKeyPair) => {
//     // whatever your business logic is.

//     localForageService.setItem(state.Key, state.Value, function () {
//         console.log('Of the driver options given, localforage is using:' + localForageService.driver());
//     }).catch((ex: any) => {
//         console.log("Exception on IndexedDB" + ex)
//     });;

// };