import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'
export default class WillKitChildren extends Model {
    static entity = 'WillKitChildren'
    static primaryKey = 'Id'
    static fields() {
        return {
             Id: this.uid(() => EntityHelper.uuid()),
            FirstName: this.string(''),
            LastName: this.string(''),
            MiddleName: this.string(''),
            DateOfBirth: this.string(null).nullable(),
            AgePerDOB: this.number(null).nullable(),
            IsLiving: this.boolean(null).nullable(),
            Gender: this.string(''),
            IsAdult: this.boolean(false).nullable(),
            ReferralAdded: this.boolean(false).nullable(),
            Email: this.string(''),
            State: this.string('').nullable(),
            ZipCode: this.string(null).nullable(),
            City: this.string(null).nullable(),
            Phone: this.string(null).nullable(),
            SpouseFirstName: this.string(''),
            SpouseLastName: this.string(''),
            GroupId: this.number(null).nullable(),
            GroupCode: this.string(null).nullable(),
            GroupName: this.string(null).nullable(),
            IsValid: this.boolean(true).nullable(),
        }
    }

     public FirstName!: string
    public LastName!: string
    public MiddleName!: string
    public DateOfBirth!: string
    public AgePerDOB!: number
    public Gender!: string
    public IsLiving!: boolean
    public IsAdult!: boolean
    public Email!: string
    public State!: string
    public ZipCode!: string
    public City!: string
    public Phone!: string
    public DOBPopup: boolean = false
    public DOBPicker: string = "1990-01-01"
    public ReferralAdded!: boolean
    public SpouseFirstName!: string
    public SpouseLastName!: string
    public GroupId!: number
    public GroupCode!: string
    public GroupName!: string
    public IsValid!: boolean
    public Id!: string
}