import Presentation from "@/entities/Presentation";
import GenericKeyPair from "@/models/GenericKeyPair";
import { APIService } from "@/services/APIService";
import { GetHpProUserId, GetUserId, isLoggedIn } from "./APIExtentions";
import { PostRequestWithAuthorization } from "./APIHelper";
import { PrsentationEntityToDTO } from "./MappingHelper";
import { getPersistedState, setPersistedState } from "./StateMapper";

export default class PresentationSyncing {
    private static _instance: PresentationSyncing = new PresentationSyncing();
    constructor() {
        if (PresentationSyncing._instance) {
            throw new Error("Error: Instantiation failed: Use PresentationSyncing.getInstance() instead of new.");
        }
        PresentationSyncing._instance = this;
    }

    public static getInstance(): PresentationSyncing {
        return PresentationSyncing._instance;
    }

    // static instance: PresentationSyncing;
    // private constructor() {

    // }

    // public static Instance(): PresentationSyncing {
    //     if (this.instance == null)
    //         this.instance = new PresentationSyncing();
    //     return this.instance;
    // }

    public isSyncing: boolean = false;

    public SyncPresentation = async () => {
        if (isLoggedIn() && !this.isSyncing) {
            try {
                this.isSyncing = true;
                var presentationList: any = await getPersistedState("PresentationList");
                if (presentationList != undefined && presentationList.length > 0) {

                    console.log("Presentation to be Synced: " + presentationList.length)
                    var promises: Promise<any>[] = [];
                    presentationList.forEach((presentation: any) => {
                        var GenericKeyPairobj: GenericKeyPair[] = [];
                        var pres = new Presentation(presentation);
                        var mapPresDTO = PrsentationEntityToDTO(pres);
                        if (mapPresDTO.PresentationTypeId == null || mapPresDTO.PresentationTypeId == "" || mapPresDTO.PresentationTypeId == 0) {
                            this.DeletePresentationIfSynced(presentation.Id);
                        }
                        else {
                            promises.push(APIService.SavePresentation(mapPresDTO, GenericKeyPairobj).then(async (res: any) => {
                                if (res != undefined && res.data == true)
                                    await this.DeletePresentationIfSynced(presentation.Id);
                            }));
                        }
                    });
                    if (promises != undefined && promises.length > 0) {
                        Promise.all(promises).then(() => {
                            this.isSyncing = false;
                        });
                    }
                }
                else {
                    this.isSyncing = false;
                    //this.GetNewWebVersion();
                }
            }
            catch (ex: any) {
                this.isSyncing = false;
            }
        }
        else if (!this.isSyncing) {
            //this.GetNewWebVersion();
        }
    }

    

    async DeletePresentationIfSynced(presentationId: any) {
        var presentationList: any = await getPersistedState("PresentationList");
        if (presentationList != undefined && presentationList.length > 0) {
            var presentations: any = [];
            presentationList.forEach((pres: any) => {
                if (pres.Id != presentationId)
                    presentations.push(pres);
            });
            setPersistedState(new GenericKeyPair("PresentationList", presentations));
        }

    }

    // MaptoPresentationDTO() {

    // }
}

