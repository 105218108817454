






































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Children from "@/entities/Children";
import moment from "moment";
import { EntityHelper } from "@/helpers/EntityHelper";
import FamilyCareSurveyChildren from "@/entities/FamilyCareSurveyChildren";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class AddDependentChildrenComponent extends Vue {
	private person: any;
	private gender = listofGenderTypes;
	private isDialogOpen: boolean = false;

	@Prop({ default: "Input Property" })
	lstChildren!: Array<FamilyCareSurveyChildren>;

	@Prop({ default: "Input Property" })
	truncateProperties!: boolean;

	@Prop({ default: false })
	IsVeteranAssessment!: boolean;

	data() {
		return {
			DOBRequired: [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						// const pattern =
						//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			],
		};
	}

	created() {
		this.lstChildren.forEach((element) => {
			this.DOBChange(element);
		});
		this.isDialogOpen = true;
	}

	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelchildren");
		}, 500);
	}

	add() {
		this.lstChildren.push(new FamilyCareSurveyChildren());
	}

	DOBChange(item: any) {
		if (item.DateOfBirth != undefined && item.DateOfBirth != "") {
			item.DOBPicker = moment(item.DateOfBirth).format("YYYY-MM-DD");
			item.AgePerDOB = EntityHelper.calculate_age(item.DateOfBirth);
		}
	}

	saveDOB(date: any, item: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MM/DD/YYYY") : "";
		item.DateOfBirth = tempdate;
		item.DOBPopup = false;
	}

	save() {
		this.$emit("saveChildren");
	}
	cancel() {
		this.$emit("cancelchildren");
	}
	remove(id: number) {
		this.lstChildren.splice(id, 1);
		// setTimeout(() => {
		//   this.lstChildren.splice(id, 1);
		// }, 500);
	}
}
