


























































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ShortUserDetailView from "../components/UserShortDetailView.vue";
import MenuComponent from "../components/menu.vue";

import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
let mktTypeList = require("../assets/pre-fill-data/training-market-types.json");
let mktTypeSteps = require("../assets/pre-fill-data/training-market-steps.json");
let StateList = require("../assets/pre-fill-data/Veteran-Fact-Sheets-By-State.json");
import Flipbook from "flipbook-vue";
import User from "../entities/User";
import { getPersistedState, setPersistedState } from "../helpers/StateMapper";
import GenericKeyPair from "../models/GenericKeyPair";
import { changeLocale } from "@/plugins/i18n";
import axios from "axios";
import { DownloadFile, GetRequestWithAuthorization } from "@/helpers/APIHelper";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import {EventBusLayout} from "../bus/EventBusLayout";

@Component({
  components: {
    ShortUserDetailView,
    MenuComponent,
    videoPlayer,
    CKEditor,
    DocumentEditor,
    VuePdfApp,
    Flipbook,
  },
})
export default class TrainingPage extends Vue {
  private marketTypes: any = mktTypeList;
  private States: any = StateList;
  private SelectedState: any = "Arizona";
  private selectedMarketType: any;
  private percentcompleted: number = 0;
  private percentcompletedDisplay: string = "0";
  private showPDF: boolean = false;
  private showQuestionaire: boolean = false;
  private showVideo: boolean = false;
  private showStateDropDown: boolean = false;
  private showNotes: boolean = false;
  private showSave: boolean = false;
  private listexpanded: boolean = true;
  private cuurentSelectedMarket: any;
  private user: any;
  private mktSteps: any = mktTypeSteps;
  private correctAnswers: any = 0;
  private flipBookPages: Array<any> = [];
  private savedTrainingSteps = "";
  private PDFUrl = "";
  private fileName = "";

  loadSteps() {
    this.showStateDropDown = false;
    setTimeout(() => {
      this.percentcompleted = 0;
      this.percentcompletedDisplay = "0";
      this.$data.steps = null;
      this.$data.backgroundimage = null;
      this.$data.foregroundimage = null;
    }, 0);
    setTimeout(() => {
      this.$data.currentActiveStep = null;
      this.$refs.marketCombo.blur();
      this.$data.backgroundimage = this.selectedMarketType.background_image;
      this.$data.foregroundimage = this.selectedMarketType.foreground_image;
      this.cuurentSelectedMarket = this.mktSteps.find(
        (x: any) => x.markettypeid == this.selectedMarketType.id
      );
      this.percentcompletedDisplay =
        this.cuurentSelectedMarket.percentcompleted;
      this.percentcompleted = parseInt(
        this.cuurentSelectedMarket.percentcompleted
      );
      this.$data.steps = this.cuurentSelectedMarket.steps;
    }, 0);
  }

  completeStep() {
    this.$data.currentActiveStep.isactive = false;

    if (
      !this.$data.currentActiveStep.iscompleted &&
      !this.$data.currentActiveStep.selfcompleted
    ) {
      this.percentcompleted += this.cuurentSelectedMarket.percentaddoneachstep;
      this.percentcompletedDisplay = this.percentcompleted.toFixed(0);
      this.cuurentSelectedMarket.percentcompleted =
        this.percentcompleted.toFixed(0);
      if (this.percentcompleted > 100) {
        this.percentcompletedDisplay = "100";
        this.cuurentSelectedMarket.percentcompleted = "100";
      }
    }

    if (this.$data.currentActiveStep.steps.length == 0) {
      this.$data.currentActiveStep.iscompleted = true;
    } else if (this.$data.currentActiveStep.doctype != "none") {
      this.$data.currentActiveStep.selfcompleted = true;
    }

    if (this.$data.currentActiveStep.islastitem) {
      var parentStep = this.lookForKey(
        this.$data.steps,
        this.$data.currentActiveStep.parentid
      );
      if (parentStep != undefined) parentStep.iscompleted = true;
    }
    this.SavedSteps();

    var nextStep = this.lookForKey(
      this.$data.steps,
      parseInt(this.$data.currentActiveStep.id) + 1
    );
    if (nextStep.doctype == "none") {
      nextStep = this.lookForKey(
        this.$data.steps,
        parseInt(this.$data.currentActiveStep.id) + 2
      );
    }
    this.$data.currentActiveStep = nextStep;
    this.expandParents(nextStep);

    // this.user.TrainingConfiguration = JSON.stringify(this.$data.steps);
    // User.insertOrUpdate({
    //   data: this.user,
    // });
  }

  onStateDropDownChange(event: any) {
    this.flipBookPages = event.flipbookPage;
    this.PDFUrl = event.pdfURL;
    this.fileName = event.title;
    this.$refs.flipbook.goToPage(1);
  }

  expandParents(step: any) {
    if (!step || !step.parentid) return;

    var parent = this.lookForKey(this.$data.steps, parseInt(step.parentid));

    if (parent && !parent.isexpanded) {
      parent.isexpanded = true;
      this.expandParents(parent);
    }
  }

  DownloadPDF() {
    var parameters: GenericKeyPair[] = [];

    parameters.push(new GenericKeyPair("URL", this.PDFUrl));
    this.$store.state.loader = true;
    GetRequestWithAuthorization(
      ApiEndPointConstants.GetTrainingPDFEndPoint,
      parameters
    ).then((res) => {
      const downloadLink = document.createElement("a");
      const fileName = this.fileName + ".pdf";
      const linkSource = `data:application/pdf;base64,${res.Result}`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.$store.state.loader = false;
    });
  }

  @Watch("currentActiveStep")
  onActiveStepChanged(newValue: any, oldValue: any) {
    if (newValue == null) {
      this.showVideo = false;
      this.showQuestionaire = false;
      this.showPDF = false;
      this.showNotes = false;
      this.showSave = false;
      return;
    }

    if (!newValue.iscompleted) newValue.isactive = true;
    if (oldValue != null) oldValue.isactive = false;
    this.showVideo = false;
    this.showQuestionaire = false;
    this.showPDF = false;
    this.showNotes = false;
    this.showSave = false;

    if (
      newValue.ShowSateDropDown != undefined &&
      newValue.ShowSateDropDown != null &&
      newValue.ShowSateDropDown == true
    ) {
      this.showStateDropDown = true;
    } else {
      this.showStateDropDown = false;
    }

    switch (newValue.doctype) {
      case "video":
        this.showVideo = true;
        this.ChangePlayerOptions(newValue.videosource);
        this.showNotes = true;
        this.showSave = true;
        break;
      case "pdf":
        this.showPDF = true;
        this.showNotes = false;
        var elements: any = document.getElementsByClassName("ck-toolbar");
        var i = 0;
        for (i = 0; i < elements.length; i++) {
          elements[i].style.display = "none";
        }

        if (this.$refs.flipbook != undefined) {
          this.$refs.flipbook.goToPage(1);
        }
        this.flipBookPages = newValue.flipbookPage;
        this.showSave = true;
        this.PDFUrl = newValue.pdfURL;
        this.fileName = newValue.title;
        break;
      case "questionaire":
        this.showQuestionaire = true;
        this.showSave = true;
        this.showNotes = false;
        this.answerChanged(true);
        break;
    }
  }

  onReady(editor: any) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  answerChanged(onlyinccorrectanswers: boolean = false) {
    this.correctAnswers = 0;

    this.$data.currentActiveStep.questions.forEach((question: any) => {
      var isAnswerCorrect = false;
      question.answers.forEach((element: any) => {
        if (!onlyinccorrectanswers) {
          element.ischecked = false;
          if (element.answer == question.currentselectedanswer) {
            element.ischecked = true;
          }

          if (element.iscorrect && !element.ischecked) {
            element.addgreenclass = true;
          }
        }

        if (element.ischecked && element.iscorrect) {
          this.correctAnswers++;
          isAnswerCorrect = true;
        }
      });
      if (question != undefined) {
        if (isAnswerCorrect) question.isCorrect = true;
        else question.isCorrect = false;
      }
    });
  }

  data() {
    return {
      trainingscroll: {
        scrollPanel: {
          scrollingX: false,
        },
      },
      content: "",
      editor: DocumentEditor,
      editorConfig: {
        plugins: [],
        extraPlugins: "font,colorbutton",
        toolbar: [
          {
            name: "clipboard",
            items: ["Undo", "Redo"],
          },
          {
            name: "forms",
            items: [
              "Form",
              "Checkbox",
              "Radio",
              "TextField",
              "Textarea",
              "Select",
              "Button",
              "ImageButton",
              "HiddenField",
            ],
          },
          {
            name: "basicstyles",
            items: [
              "Bold",
              "Italic",
              "Underline",
              "Strike",
              "Subscript",
              "Superscript",
              "-",
            ],
          },
          {
            name: "paragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "Blockquote",
              "CreateDiv",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
              "BidiLtr",
              "BidiRtl",
              "Language",
            ],
          },
          { name: "links", items: ["Link", "Unlink", "Anchor"] },
          {
            name: "insert",
            items: ["Table", "HorizontalRule", "Smiley"],
          },
          {
            name: "styles",
            items: ["Styles", "Format", "Font", "FontSize"],
          },
          { name: "colors", items: ["TextColor", "BGColor"] },
          { name: "tools", items: ["Maximize", "ShowBlocks"] },
        ],
      },
      currentActiveStep: null,
      backgroundimage: "",
      foregroundimage: "",
      steps: [],
      isPDF: false,
      PdfUrl: "/static/AIL_Plus_Program.pdf",
      pagescale: "page-fit",
      config: {
        toolbar: {
          toolbarViewerRight: false,
          secondaryToolbarToggle: false,
        },
      },
      playerOptions: {
        // videojs options
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "static/ailvideo.mp4",
          },
        ],
        autoplay: true,
        poster: "/static/images/author.jpg",
      },
      VideoSource: "",
    };
  }
  $refs!: {
    videoPlayer: any;
    marketCombo: any;
    flipbook: any;
    videohtml: any;
  };

  private VideoFirstTimeWidth = undefined;

  ChangePlayerOptions(imageLink: string) {
    if (imageLink != undefined && imageLink != "") {
      console.log(imageLink);
      if (this.$refs.videohtml != undefined) {
        this.$refs.videohtml.getElementsByTagName("source")[0].src = imageLink;
        this.$refs.videohtml.load();
        this.$refs.videohtml.play();
      } else {
        this.$nextTick(() => {
          if (this.$refs.videohtml != undefined) {
            // this.$refs.videohtml.addEventListener(
            //   "loadedmetadata",
            //   (e: any) => {
            //     let player = e.target;
            //     player.width = player.offsetWidth;
            //   }
            // );

            this.$refs.videohtml.getElementsByTagName("source")[0].src =
              imageLink;
            this.$refs.videohtml.load();
            this.$refs.videohtml.play();
          }
        });
      }
    }
  }

  KeyDownPress(evt: any) {
    if (evt.code == "Space") {
      var parentElement = evt.target.getElementsByClassName("singletitle");
      if (parentElement != null && parentElement.length > 0)
        parentElement[0].click();
      else {
        parentElement = evt.target.getElementsByClassName("v-list-item__title");
        if (parentElement != null && parentElement.length > 0)
          parentElement[0].click();
      }
    }
  }

  stepClicked(step: any) {
    step.isexpanded = !step.isexpanded;
    if (step.doctype == "none" || step.doctype == undefined) return;
    var currentStepId = step.id;
    if (currentStepId != 1) {
      var prevStep = this.lookForKey(this.$data.steps, currentStepId - 1);
      if (prevStep && prevStep.doctype == "none") {
        prevStep = this.lookForKey(this.$data.steps, currentStepId - 2);
      }
      if (
        prevStep &&
        !prevStep.selfcompleted &&
        !prevStep.iscompleted &&
        prevStep.isRequired
      ) {
        this.$store.state.messageText =
          "Please complete the previous steps first.";
        this.$store.state.showMessage = true;
        return;
      }
    }
    setTimeout(() => {
      this.$data.currentActiveStep = step;
    }, 0);
  }

  hyperlink(stepId: any) {
    this.stepClicked(this.lookForKey(this.$data.steps, stepId));
  }

  lookForKey(subMenuItems: any, id: any): any {
    if (subMenuItems) {
      for (var i = 0; i < subMenuItems.length; i++) {
        if (subMenuItems[i].id == id) {
          return subMenuItems[i];
        }
        var found = this.lookForKey(subMenuItems[i].steps, id);
        if (found) return found;
      }
    }
  }

  created() {
    this.user = User.query().last();
    EventBusLayout.$on('RoutedToEvt',this.TrainingRoutedToEvt);
    getPersistedState(
      "../assets/pre-fill-data/training-market-steps.json"
    ).then((res: any) => {
      if (res != undefined && res != "") this.mktSteps = JSON.parse(res);
    });

    setTimeout(() => {
      try {
        var element = document.getElementsByClassName("cke_wysiwyg_frame");

        if (
          element != undefined &&
          element.length > 0 &&
          element[0] != undefined
        ) {
          var css = document.createElement("style");
          css.type = "text/css";

          var styles = fetch("../static/styles/editor.css")
            .then((response) => response.text())
            .then((data) => {
              css.appendChild(document.createTextNode(data));
              var frameElement: any = element[0];
              frameElement.contentDocument.head.appendChild(css);
            });
        }
      } catch (ex: any) {
        console.log(ex);
      }
    }, 500);

    var _keydownCallback = (event: any) => {
      if (
        this.showVideo &&
        event.code == "Space" &&
        this.$refs.videoPlayer != undefined &&
        document.activeElement != undefined &&
        document.activeElement.id != undefined &&
        document.activeElement.id.includes("vjs_video_")
      ) {
        if (
          this.$refs.videoPlayer != undefined &&
          this.$refs.videoPlayer.player != undefined &&
          !this.$refs.videoPlayer.player.paused()
        )
          this.$refs.videoPlayer.player.pause();
        else if (
          this.$refs.videoPlayer != undefined &&
          this.$refs.videoPlayer.player != undefined
        )
          this.$refs.videoPlayer.player.play_();
        //this.$refs.videoPlayer.pause();
      }

      if (this.showPDF && this.$refs.flipbook != undefined) {
        if (event.code == "ArrowLeft") {
          this.$refs.flipbook.flipLeft();
        }

        if (event.code == "ArrowRight") {
          this.$refs.flipbook.flipRight();
        }
      }
    };

    document.addEventListener("keyup", _keydownCallback);
  }

  RoutedTo(route: String) {
    if (route != "StartPresentation") {
      this.$router.push(route.toString());
    }
  }
  TrainingRoutedToEvt(route: any){
    this.RoutedTo(route);
  }
  beforeDestroy() {
	EventBusLayout.$off('RoutedToEvt',this.TrainingRoutedToEvt);
  }

  SavedSteps() {
    setPersistedState(
      new GenericKeyPair("savedTrainingSteps", JSON.stringify(this.mktSteps))
    );
    //}
  }
}
