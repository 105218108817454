




























































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Children from "@/entities/Children";
import moment from "moment";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { EntityHelper } from "@/helpers/EntityHelper";
import FamilyCareSurveyChildren from "@/entities/FamilyCareSurveyChildren";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class AddAdultChildrenComponent extends Vue {
	private person: any;
	private gender = listofGenderTypes;
	private stateListPromise: any = [];
	private basicDetailRequired: boolean = false;
	private isDialogOpen: boolean = false;

	$refs!: {
		emailfield: any;
		phonefield: any;
	};

	@Prop({ default: "Input Property" })
	lstChildren!: Array<FamilyCareSurveyChildren>;

	// @Prop({ default: "Imput Property" })
	// ZipCodeFormat!: string;

	@Prop({ default: 1 })
	private CountryId!: number;

	@Prop({ default: "Input Property" })
	truncateProperties!: boolean;

	@Prop({ default: false })
	IsVeteranAssessment!: boolean;

	get isCanadaState() {
		if (this.CountryId == 2) return true;
		else return false;
	}
	get ZipCodeFormat() {
		if (this.isCanadaState) return "XXX XXX";
		else return "#####-####";
	}

	add() {
		this.lstChildren.push(new FamilyCareSurveyChildren());
		// setTimeout(() => {
		//   this.lstChildren.push(new Children());
		// }, 500);
	}

	DOBChange(item: any) {
		if (item.DateOfBirth != undefined && item.DateOfBirth != "") {
			item.DOBPicker = moment(item.DateOfBirth).format("YYYY-MM-DD");
			item.AgePerDOB = EntityHelper.calculate_age(item.DateOfBirth);
		}
	}

	saveDOB(date: any, item: any): void {
		var tempdate =
			date != undefined ? moment(String(date)).format("MM/DD/YYYY") : "";
		item.DateOfBirth = tempdate;
		item.DOBPopup = false;
	}

	save() {
		this.$emit("saveChildren");
	}

	cancel() {
		this.$emit("cancelchildren");
	}
	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelchildren");
		}, 500);
	}

	async loadStates() {
		this.stateListPromise = await getPersistedState("StateList");
	}

	validate(value: string, index: number, type: string) {
		if (!this.basicDetailRequired && value == "") {
			return;
		}

		if (type == "email") {
			var valid =
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) &&
				value.trim() != "";
			if (!valid) this.$refs.emailfield[index].$el.classList.add("error--text");
			else this.$refs.emailfield[index].$el.classList.remove("error--text");
		}
		if (type == "phone") {
			var valid = value.length > 13;
			if (!valid) this.$refs.phonefield[index].$el.classList.add("error--text");
			else this.$refs.phonefield[index].$el.classList.remove("error--text");
		}
	}

	remove(id: number) {
		this.lstChildren.splice(id, 1);
		// setTimeout(() => {
		//   this.lstChildren.splice(id, 1);
		// }, 500);
	}
	data() {
		return {
			RequiredZip: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined) {
						var zipFormat = this.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
					}
					return false;
				},
			],
			ZipFormat: [
				(v: any) => {
					if (v != undefined) {
						if (v.trim().length == 0) return true;
						var zipFormat = this.ZipCodeFormat;
						if (zipFormat.length == 10) {
							if (v.length == 5 || v.length == 10) return true;
						}

						if (zipFormat.length == 7) {
							if (v.length == 3 || v.length == 7) return true;
						}
						return false;
					}
					return true;
				},
			],
			DOBRequired: [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						// const pattern =
						//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			],
		};
	}

	created() {
		this.lstChildren.forEach((element) => {
			this.DOBChange(element);
		});
		this.isDialogOpen = true;
		this.basicDetailRequired = true;
		this.loadStates();
	}
}
