import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'



export default class PresentationWillKitVeterans extends Model {
    static entity = 'PresentationWillKitVeterans'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            ServiceNumber: this.string(''),
            VAClaimNumber: this.string(''),
            BranchOfService: this.string(null).nullable(),
            NameOfWar: this.attr([]).nullable(),
            AdditionalNameOfWar: this.string(''),
            EnlistmentDatesFrom: this.string(''),
            EnlistmentDatesTo: this.string(null).nullable(),
            PlaceOfEnlistment: this.string(null).nullable(),
            RankRateAtDischarge: this.string(null).nullable(),
            PlaceOfDischarge: this.string(null).nullable(),
            VGLIPolicyNumber: this.string(''),
            DischargePapers: this.string(null).nullable(),
            DischargeStatus: this.string(null).nullable(),
            PolicyAmount: this.string(''),
            Location: this.string(''),
            CampaignMedalsReceived: this.attr([]).nullable(),
            AdditionalCampaignMedalsReceived: this.string(''),
            FuneralType: this.string(null).nullable(),
            SpouseFuneralType: this.string(null).nullable(),
            VeteransType: this.string(null).nullable(),
            SpouseVeteransType: this.string(null).nullable(),
            VeteransLocation: this.string(null).nullable(),
            SpouseVeteransLocation: this.string(null).nullable(),
            InCaseOfDeath: this.string(null).nullable(),
            SpouseInCaseOfDeath: this.string(null).nullable(),
            PolicyNumber: this.string(''),
            CoverageAmount: this.string(null).nullable(),
            FreedomOfChoiceLocation: this.string(''),
            ReferralsFamily: this.string(''),
            ReferralsAdditional: this.string(''),
            ReferralsService: this.string(''),
            ReferralsOtherService: this.string(''),
            ReferralsMembers: this.string(''),
            ReferralsChildrenFamily: this.string(''),
            UnionAssociationMemberInfo: this.string(''),
            WillKitChildren:this.string(''),
            State: this.string(''),
            City: this.string(''),
            DocumentTypeIdList: this.attr([]).nullable(),
            PrimaryGender: this.string(''),
            SpouseGender: this.string(''),
            VeteranVideoClicked: this.boolean(false).nullable(),
			CurrentVSOMember: this.attr([]).nullable(),
            VSOPost: this.string(null).nullable(),
            VSOMembership: this.string(null).nullable(),
            SigOtherType: this.string(''),
            SigOtherText: this.string(''),
            ServiceInfoAndNotes: this.attr([]).nullable(),
            VSOInfoAndNotes: this.attr([]).nullable(),
            BeneficiaryName: this.string(''),
            BeneficiaryRelation: this.string(''),
            SpouseState: this.string(''),
            SpouseCity: this.string(''),
            SpouseBeneficiaryName: this.string(''),
            SpouseBeneficiaryRelation: this.string(''),
            SponsorName: this.string(''),
            SponsorRelation: this.string(''),
            isPlanCalculationNeeded:this.boolean(false),
        }
    }

    public Id !: any
    public ServiceNumber!: string
    public VAClaimNumber!: string
    public BranchOfService!: string
    public NameOfWar!: any
    public AdditionalNameOfWar!: string
    public EnlistmentDatesFrom!: string
    public EnlistmentDatesTo!: string
    public PlaceOfEnlistment!: string
    public RankRateAtDischarge!: string
    public PlaceOfDischarge!: string
    public VGLIPolicyNumber !: string
    public DischargePapers!: string
    public DischargeStatus!: string
    public PolicyAmount!: string
    public Location!: string
    public CampaignMedalsReceived!: any
    public AdditionalCampaignMedalsReceived!: string
    public FuneralType !: string
    public SpouseFuneralType!: string
    public VeteransType  !: string
    public SpouseVeteransType  !: string
    public VeteransLocation  !: string
    public SpouseVeteransLocation  !: string
    public InCaseOfDeath!: string
    public SpouseInCaseOfDeath!: string
    public PolicyNumber !: string
    public CoverageAmount!: string
    public FreedomOfChoiceLocation !: string
    public ReferralsFamily !: string
    public ReferralsAdditional !: string
    public ReferralsService !: string
    public ReferralsOtherService !: string
    public ReferralsMembers !: string
    public ReferralsChildrenFamily !: string
    public UnionAssociationMemberInfo !: string
    public WillKitChildren!: string
    public State !: string
    public City !: string
    public DocumentTypeIdList!: any 
    public PrimaryGender!: string
    public SpouseGender!: string
    public VeteranVideoClicked!: boolean
    public CurrentVSOMember!: any
    public VSOPost !: string
    public VSOMembership!: string
    public SigOtherType!: string
    public SigOtherText!: string
    public ServiceInfoAndNotes !: any
    public VSOInfoAndNotes !: any
    public BeneficiaryName!: string
    public BeneficiaryRelation!: string
    public SpouseState !: string
    public SpouseCity !: string
    public SpouseBeneficiaryName!: string
    public SpouseBeneficiaryRelation!: string
    public SponsorName!: string
    public SponsorRelation!: string
    public isPlanCalculationNeeded!:boolean
}