export class IndexDBConstants {
	static SyncVersionKey: string = 'SyncVersions';
	static AssociateCodedHierarchyKey: string = 'AssociateHierarchy';
	static PresentationTypesKey: string = 'PresentationTypesVeteran';
	static MaterialsKey: string = 'Materials';
	static PresentationGroupsKey: string = 'PresentationGroups';
	static StateListKey: string = 'StateList';
	static CountryListKey: string = 'CountryList';
	static OfficeListKey: string = 'OfficeList';
	static UnionCodeListKey: string = 'UnionCodeList';
	static PositionListKey: string = 'PositionList';
	static MedicalQuestionsKey: string = 'MedicalQuestions';
	static ReportOptionsKey: string = 'ReportOptions';
	static MilestonesOptionKey: string = 'MilestonesOption';
	static ProductsListKey: string = 'ProductsList';
	static RatesListKey: string = 'RatesList';
	static BenefitSummaryRatesKey: string = 'BenefitSummaryRates';
	static RequiredPresentationStepConfigKey: string = 'RequiredPresentationStep';
	static UWQuestionaireTempaltesKey: string = 'UWQuestionaireTempaltes';
	static GeneralMedicalQuestionKey: string = 'GeneralMedicalQuestion';
	static ReferralGroupCode: string = 'ReferralGroupCodes';
	static UserList: string = 'AltigUserList';
	static PresentationList: string = 'PresentationList';
	static CreditUnionKey: string = 'CreditUnion';
	static LogEvent: string = 'UserEvents';
}
