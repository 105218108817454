import { EntityHelper } from '../helpers/EntityHelper'
import { Model } from '@vuex-orm/core'

import {
    ConvertIntoAmountFormatWithDecimal,
    ConvertIntoAmountFormat
} from "../helpers/CalculationHelper";
export default class RateBookEntitity extends Model {
    static entity = 'RateBookEntitity'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            Age: this.number(0),
            Gender: this.string(''),
            Product: this.string(''),
            Coverage: this.string(''),
            Option: this.string(''),
            MDB: this.string(''),
            Wage: this.number(0),
            WageType: this.string(''),
            TobaccoUse: this.boolean(false),
            hasChildren: this.boolean(false),
            State: this.string(''),
            PresentAs: this.string(''),
            AddAnnualRate: this.boolean(false),
            CurrentProduct: this.attr(null),
            PGName: this.string(""),
            PresentAsOptions: this.attr(null),
        }
    }

    public CurrentProduct: any = 0;
    public Age: any = 0;
    public Gender!: string
    public AddAnnualRate!: boolean
    public Product!: string
    public Wage!: number
    public WageType!: string
    public Coverage!: string
    public Option!: string
    public MDB!: string
    public PGName!: string
    public PresentAs!: string
    public TobaccoUse!: boolean
    public hasChildren!: boolean
    public State!: string
    public CompleteProduct!: any
    public PresentAsOptions!: any
    public CompleteOption!: any
    public CompleteCoverageOption!: any
    public CurrentCoverageType: number = 1


    get isChild() {
        if (this.Age != undefined && this.Age < 18)
            return true;
        return false;
    }

    get isOld() {
        if (this.Age != undefined && this.Age > 59)
            return true;
        return false;
    }

    get isProductDisabled() {
        if (this.Age == undefined || this.Age === "" || this.Age === -1)
            return true;
        if (this.State != undefined && this.State != "" && this.Gender != undefined && this.Gender != "")
            return false;
        return true;
    }

    get isAmountFieldDisabled() {
        if (!this.isProductDisabled && this.CompleteProduct != undefined)
            return false;
        return true;
    }

    get disableMBD() {
        return this.CompleteProduct && this.CompleteProduct.disableMBDField;
    }

    //isUpdated = false;
    get isProductDetailDisabled() {
        if (!this.isAmountFieldDisabled)
            return false;

        return true;
    }

    get MBDString() {
        return ConvertIntoAmountFormatWithDecimal(
            this.MDB);
    }

    get CoverageString() {
        if (
            this.Coverage != undefined &&
            typeof this.Coverage == "string" &&
            this.Coverage.includes(".")
        )
            return ConvertIntoAmountFormatWithDecimal(
                this.Coverage
            );
        else
            try {
                return ConvertIntoAmountFormat(
                    this.Coverage.toString()
                );
            } catch (ex: any) {
                console.log("Error on Changing Coverage" + ex);
            }

        return ConvertIntoAmountFormatWithDecimal(
            this.Coverage
        );
    }


    public Reset() {
        this.Age = 0;
        this.Gender = '';
        this.Product = '';
        this.Coverage = '';
        this.Option = '';
        this.MDB = '';
        this.TobaccoUse = false;
        this.hasChildren = false;
        this.State = "";
    }
}