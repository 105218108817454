import { HpProHouseHoldType } from '@/enumerations/HpProHouseHoldType';
import { PresentationSubType } from '@/enumerations/PresentationSubType';
import { PresentationType } from '@/enumerations/PresentationType';
import { WhatHappened } from '@/enumerations/WhatHappened';

export function UpdatePresentationWhatHappenedCount(presWhatHappened: any, PresentationCountByWhatHappened: any) {
	switch (presWhatHappened) {
		case WhatHappened.Enrollment:
			PresentationCountByWhatHappened[WhatHappened.Enrollment] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.Enrollment] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.Enrollment] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.NotInterested:
			PresentationCountByWhatHappened[WhatHappened.NotInterested] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.NotInterested] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.NotInterested] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.Think:
			PresentationCountByWhatHappened[WhatHappened.Think] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.Think] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.Think] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.JustNoCost:
			PresentationCountByWhatHappened[WhatHappened.JustNoCost] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.JustNoCost] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.JustNoCost] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.Qualify:
			PresentationCountByWhatHappened[WhatHappened.Qualify] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.Qualify] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.Qualify] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.Training:
			PresentationCountByWhatHappened[WhatHappened.Training] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.Training] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.Training] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.Afford:
			PresentationCountByWhatHappened[WhatHappened.Afford] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.Afford] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.Afford] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.Postponed:
			PresentationCountByWhatHappened[WhatHappened.Postponed] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.Postponed] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.Postponed] = 1);
			return PresentationCountByWhatHappened;
		case WhatHappened.NeedsAnalysisOnly:
			PresentationCountByWhatHappened[WhatHappened.NeedsAnalysisOnly] != undefined
				? (PresentationCountByWhatHappened[WhatHappened.NeedsAnalysisOnly] += 1)
				: (PresentationCountByWhatHappened[WhatHappened.NeedsAnalysisOnly] = 1);
			return PresentationCountByWhatHappened;
		default:
			return PresentationCountByWhatHappened;
	}
}

export function UpdatePresentationSubTypeCount(presubType: any, PresentationCountBySubType: any) {
	switch (presubType) {
		case PresentationSubType.OnlineMCGruffLead:
			PresentationCountBySubType[PresentationSubType.OnlineMCGruffLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.OnlineMCGruffLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.OnlineMCGruffLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SponsoredMcGruffLead:
			PresentationCountBySubType[PresentationSubType.SponsoredMcGruffLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.SponsoredMcGruffLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.SponsoredMcGruffLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.OnlineChildSafeLead:
			PresentationCountBySubType[PresentationSubType.OnlineChildSafeLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.OnlineChildSafeLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.OnlineChildSafeLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SponsoredChildSafeLead:
			PresentationCountBySubType[PresentationSubType.SponsoredChildSafeLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.SponsoredChildSafeLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.SponsoredChildSafeLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SponsoredVeteranLead:
			PresentationCountBySubType[PresentationSubType.SponsoredVeteranLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.SponsoredVeteranLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.SponsoredVeteranLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.VeteranFamilyLead:
			PresentationCountBySubType[PresentationSubType.VeteranFamilyLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.VeteranFamilyLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.VeteranFamilyLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ReturnCard:
			PresentationCountBySubType[PresentationSubType.ReturnCard] != undefined
				? (PresentationCountBySubType[PresentationSubType.ReturnCard] += 1)
				: (PresentationCountBySubType[PresentationSubType.ReturnCard] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.BurielWillKitForVeteran:
			PresentationCountBySubType[PresentationSubType.BurielWillKitForVeteran] != undefined
				? (PresentationCountBySubType[PresentationSubType.BurielWillKitForVeteran] += 1)
				: (PresentationCountBySubType[PresentationSubType.BurielWillKitForVeteran] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SponsoredNoCostLaw:
			PresentationCountBySubType[PresentationSubType.SponsoredNoCostLaw] != undefined
				? (PresentationCountBySubType[PresentationSubType.SponsoredNoCostLaw] += 1)
				: (PresentationCountBySubType[PresentationSubType.SponsoredNoCostLaw] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.OnlineNoCostLaw:
			PresentationCountBySubType[PresentationSubType.OnlineNoCostLaw] != undefined
				? (PresentationCountBySubType[PresentationSubType.OnlineNoCostLaw] += 1)
				: (PresentationCountBySubType[PresentationSubType.OnlineNoCostLaw] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SponsoredFinalExpense:
			PresentationCountBySubType[PresentationSubType.SponsoredFinalExpense] != undefined
				? (PresentationCountBySubType[PresentationSubType.SponsoredFinalExpense] += 1)
				: (PresentationCountBySubType[PresentationSubType.SponsoredFinalExpense] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.OnlineFinalExpense:
			PresentationCountBySubType[PresentationSubType.OnlineFinalExpense] != undefined
				? (PresentationCountBySubType[PresentationSubType.OnlineFinalExpense] += 1)
				: (PresentationCountBySubType[PresentationSubType.OnlineFinalExpense] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.Afford:
			PresentationCountBySubType[PresentationSubType.Afford] != undefined
				? (PresentationCountBySubType[PresentationSubType.Afford] += 1)
				: (PresentationCountBySubType[PresentationSubType.Afford] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.Think:
			PresentationCountBySubType[PresentationSubType.Think] != undefined
				? (PresentationCountBySubType[PresentationSubType.Think] += 1)
				: (PresentationCountBySubType[PresentationSubType.Think] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.Qualify:
			PresentationCountBySubType[PresentationSubType.Qualify] != undefined
				? (PresentationCountBySubType[PresentationSubType.Qualify] += 1)
				: (PresentationCountBySubType[PresentationSubType.Qualify] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.NotInterested:
			PresentationCountBySubType[PresentationSubType.NotInterested] != undefined
				? (PresentationCountBySubType[PresentationSubType.NotInterested] += 1)
				: (PresentationCountBySubType[PresentationSubType.NotInterested] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.Sale:
			PresentationCountBySubType[PresentationSubType.Sale] != undefined
				? (PresentationCountBySubType[PresentationSubType.Sale] += 1)
				: (PresentationCountBySubType[PresentationSubType.Sale] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ReportFormRequest:
			PresentationCountBySubType[PresentationSubType.ReportFormRequest] != undefined
				? (PresentationCountBySubType[PresentationSubType.ReportFormRequest] += 1)
				: (PresentationCountBySubType[PresentationSubType.ReportFormRequest] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SponsoredCreditUnionBenefits:
			PresentationCountBySubType[PresentationSubType.SponsoredCreditUnionBenefits] != undefined
				? (PresentationCountBySubType[PresentationSubType.SponsoredCreditUnionBenefits] += 1)
				: (PresentationCountBySubType[PresentationSubType.SponsoredCreditUnionBenefits] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.OnlineCreditUnionBenefits:
			PresentationCountBySubType[PresentationSubType.OnlineCreditUnionBenefits] != undefined
				? (PresentationCountBySubType[PresentationSubType.OnlineCreditUnionBenefits] += 1)
				: (PresentationCountBySubType[PresentationSubType.OnlineCreditUnionBenefits] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.OnlineCreditUnionBenefitsCA:
			PresentationCountBySubType[PresentationSubType.OnlineCreditUnionBenefitsCA] != undefined
				? (PresentationCountBySubType[PresentationSubType.OnlineCreditUnionBenefitsCA] += 1)
				: (PresentationCountBySubType[PresentationSubType.OnlineCreditUnionBenefitsCA] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ReturnCardNoCost:
			PresentationCountBySubType[PresentationSubType.ReturnCardNoCost] != undefined
				? (PresentationCountBySubType[PresentationSubType.ReturnCardNoCost] += 1)
				: (PresentationCountBySubType[PresentationSubType.ReturnCardNoCost] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ServiceMemberBenefitsLead:
			PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitsLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitsLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitsLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ServiceMemberBenefitsLeadAlpha:
			PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitsLeadAlpha] != undefined
				? (PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitsLeadAlpha] += 1)
				: (PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitsLeadAlpha] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.MarketUnionAssocMember:
			PresentationCountBySubType[PresentationSubType.MarketUnionAssocMember] != undefined
				? (PresentationCountBySubType[PresentationSubType.MarketUnionAssocMember] += 1)
				: (PresentationCountBySubType[PresentationSubType.MarketUnionAssocMember] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.MarketUnionAssocFamilyFriend:
			PresentationCountBySubType[PresentationSubType.MarketUnionAssocFamilyFriend] != undefined
				? (PresentationCountBySubType[PresentationSubType.MarketUnionAssocFamilyFriend] += 1)
				: (PresentationCountBySubType[PresentationSubType.MarketUnionAssocFamilyFriend] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ReturnCardUnion:
			PresentationCountBySubType[PresentationSubType.ReturnCardUnion] != undefined
				? (PresentationCountBySubType[PresentationSubType.ReturnCardUnion] += 1)
				: (PresentationCountBySubType[PresentationSubType.ReturnCardUnion] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.GlobeWebApplication:
			PresentationCountBySubType[PresentationSubType.GlobeWebApplication] != undefined
				? (PresentationCountBySubType[PresentationSubType.GlobeWebApplication] += 1)
				: (PresentationCountBySubType[PresentationSubType.GlobeWebApplication] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.GlobeCustomer:
			PresentationCountBySubType[PresentationSubType.GlobeCustomer] != undefined
				? (PresentationCountBySubType[PresentationSubType.GlobeCustomer] += 1)
				: (PresentationCountBySubType[PresentationSubType.GlobeCustomer] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.GlobePlus:
			PresentationCountBySubType[PresentationSubType.GlobePlus] != undefined
				? (PresentationCountBySubType[PresentationSubType.GlobePlus] += 1)
				: (PresentationCountBySubType[PresentationSubType.GlobePlus] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.GlobeInquiry:
			PresentationCountBySubType[PresentationSubType.GlobeInquiry] != undefined
				? (PresentationCountBySubType[PresentationSubType.GlobeInquiry] += 1)
				: (PresentationCountBySubType[PresentationSubType.GlobeInquiry] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SMBSponsoredVeteranLead:
			PresentationCountBySubType[PresentationSubType.SMBSponsoredVeteranLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.SMBSponsoredVeteranLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.SMBSponsoredVeteranLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SMBVeteranFamilyLead:
			PresentationCountBySubType[PresentationSubType.SMBVeteranFamilyLead] != undefined
				? (PresentationCountBySubType[PresentationSubType.SMBVeteranFamilyLead] += 1)
				: (PresentationCountBySubType[PresentationSubType.SMBVeteranFamilyLead] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SMBSponsoredVeteranLeadAlpha:
			PresentationCountBySubType[PresentationSubType.SMBSponsoredVeteranLeadAlpha] != undefined
				? (PresentationCountBySubType[PresentationSubType.SMBSponsoredVeteranLeadAlpha] += 1)
				: (PresentationCountBySubType[PresentationSubType.SMBSponsoredVeteranLeadAlpha] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.SMBVeteranFamilyLeadAlpha:
			PresentationCountBySubType[PresentationSubType.SMBVeteranFamilyLeadAlpha] != undefined
				? (PresentationCountBySubType[PresentationSubType.SMBVeteranFamilyLeadAlpha] += 1)
				: (PresentationCountBySubType[PresentationSubType.SMBVeteranFamilyLeadAlpha] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.Civilian:
			PresentationCountBySubType[PresentationSubType.Civilian] != undefined
				? (PresentationCountBySubType[PresentationSubType.Civilian] += 1)
				: (PresentationCountBySubType[PresentationSubType.Civilian] = 1);
			return PresentationCountBySubType;
		case PresentationSubType.ServiceMemberBenefitNotification:
			PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitNotification] != undefined
				? (PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitNotification] += 1)
				: (PresentationCountBySubType[PresentationSubType.ServiceMemberBenefitNotification] = 1);
			return PresentationCountBySubType;
		default:
			return PresentationCountBySubType;
	}
}

export function UpdatePresentationTypeCount(presType: any, PresentationCountByPresType: any) {
	switch (presType) {
		case PresentationType.Referral:
			PresentationCountByPresType[PresentationType.Referral] != undefined
				? (PresentationCountByPresType[PresentationType.Referral] += 1)
				: (PresentationCountByPresType[PresentationType.Referral] = 1);
			return PresentationCountByPresType;
		case PresentationType.McGruffSafeKids:
			PresentationCountByPresType[PresentationType.McGruffSafeKids] != undefined
				? (PresentationCountByPresType[PresentationType.McGruffSafeKids] += 1)
				: (PresentationCountByPresType[PresentationType.McGruffSafeKids] = 1);
			return PresentationCountByPresType;
		case PresentationType.ChildSafe:
			PresentationCountByPresType[PresentationType.ChildSafe] != undefined
				? (PresentationCountByPresType[PresentationType.ChildSafe] += 1)
				: (PresentationCountByPresType[PresentationType.ChildSafe] = 1);
			return PresentationCountByPresType;
		case PresentationType.POS:
			PresentationCountByPresType[PresentationType.POS] != undefined
				? (PresentationCountByPresType[PresentationType.POS] += 1)
				: (PresentationCountByPresType[PresentationType.POS] = 1);
			return PresentationCountByPresType;
		case PresentationType.Union:
			PresentationCountByPresType[PresentationType.Union] != undefined
				? (PresentationCountByPresType[PresentationType.Union] += 1)
				: (PresentationCountByPresType[PresentationType.Union] = 1);
			return PresentationCountByPresType;
		case PresentationType.Association:
			PresentationCountByPresType[PresentationType.Association] != undefined
				? (PresentationCountByPresType[PresentationType.Association] += 1)
				: (PresentationCountByPresType[PresentationType.Association] = 1);
			return PresentationCountByPresType;
		case PresentationType.CreditUnion:
			PresentationCountByPresType[PresentationType.CreditUnion] != undefined
				? (PresentationCountByPresType[PresentationType.CreditUnion] += 1)
				: (PresentationCountByPresType[PresentationType.CreditUnion] = 1);
			return PresentationCountByPresType;
		case PresentationType.Other:
			PresentationCountByPresType[PresentationType.Other] != undefined
				? (PresentationCountByPresType[PresentationType.Other] += 1)
				: (PresentationCountByPresType[PresentationType.Other] = 1);
			return PresentationCountByPresType;
		case PresentationType.Veteran:
			PresentationCountByPresType[PresentationType.Veteran] != undefined
				? (PresentationCountByPresType[PresentationType.Veteran] += 1)
				: (PresentationCountByPresType[PresentationType.Veteran] = 1);
			return PresentationCountByPresType;
		case PresentationType.WillKitNoCostLaw:
			PresentationCountByPresType[PresentationType.WillKitNoCostLaw] != undefined
				? (PresentationCountByPresType[PresentationType.WillKitNoCostLaw] += 1)
				: (PresentationCountByPresType[PresentationType.WillKitNoCostLaw] = 1);
			return PresentationCountByPresType;
		case PresentationType.FinalExpense:
			PresentationCountByPresType[PresentationType.FinalExpense] != undefined
				? (PresentationCountByPresType[PresentationType.FinalExpense] += 1)
				: (PresentationCountByPresType[PresentationType.FinalExpense] = 1);
			return PresentationCountByPresType;
		case PresentationType.Demo:
			PresentationCountByPresType[PresentationType.Demo] != undefined
				? (PresentationCountByPresType[PresentationType.Demo] += 1)
				: (PresentationCountByPresType[PresentationType.Demo] = 1);
			return PresentationCountByPresType;
		case PresentationType.ProLead:
			PresentationCountByPresType[PresentationType.ProLead] != undefined
				? (PresentationCountByPresType[PresentationType.ProLead] += 1)
				: (PresentationCountByPresType[PresentationType.ProLead] = 1);
			return PresentationCountByPresType;
		case PresentationType.GlobeLife:
			PresentationCountByPresType[PresentationType.GlobeLife] != undefined
				? (PresentationCountByPresType[PresentationType.GlobeLife] += 1)
				: (PresentationCountByPresType[PresentationType.GlobeLife] = 1);
			return PresentationCountByPresType;
		case PresentationType.ServiceMemberBenefits:
			PresentationCountByPresType[PresentationType.ServiceMemberBenefits] != undefined
				? (PresentationCountByPresType[PresentationType.ServiceMemberBenefits] += 1)
				: (PresentationCountByPresType[PresentationType.ServiceMemberBenefits] = 1);
			return PresentationCountByPresType;
		default:
			return PresentationCountByPresType;
	}
}

export function UpdatePresentationHouseHoldCount(presType: any, PresentationCountByHouseHold: any) {
	switch (presType) {
		case HpProHouseHoldType.single:
			PresentationCountByHouseHold[HpProHouseHoldType.single] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.single] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.single] = 1);
			return PresentationCountByHouseHold;

		case HpProHouseHoldType.single_parent:
			PresentationCountByHouseHold[HpProHouseHoldType.single_parent] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.single_parent] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.single_parent] = 1);
			return PresentationCountByHouseHold;

		case HpProHouseHoldType.married:
			PresentationCountByHouseHold[HpProHouseHoldType.married] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.married] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.married] = 1);
			return PresentationCountByHouseHold;

		case HpProHouseHoldType.family:
			PresentationCountByHouseHold[HpProHouseHoldType.family] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.family] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.family] = 1);
			return PresentationCountByHouseHold;

		case HpProHouseHoldType.retired:
			PresentationCountByHouseHold[HpProHouseHoldType.retired] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.retired] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.retired] = 1);
			return PresentationCountByHouseHold;
		case HpProHouseHoldType.single_retired:
			PresentationCountByHouseHold[HpProHouseHoldType.single_retired] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.single_retired] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.single_retired] = 1);
			return PresentationCountByHouseHold;
		case HpProHouseHoldType.married_retired:
			PresentationCountByHouseHold[HpProHouseHoldType.married_retired] != undefined
				? (PresentationCountByHouseHold[HpProHouseHoldType.married_retired] += 1)
				: (PresentationCountByHouseHold[HpProHouseHoldType.married_retired] = 1);
			return PresentationCountByHouseHold;
		default:
			return PresentationCountByHouseHold;
	}
}

export function GetHEXColorByPresentationWhatHappened(presWhatHappened: any) {
	switch (presWhatHappened) {
		case WhatHappened.Enrollment:
			return '#949483';
		case WhatHappened.NotInterested:
			return '#f47b7b';
		case WhatHappened.Think:
			return '#9f1f5c';
		case WhatHappened.JustNoCost:
			return '#ef9020';
		case WhatHappened.Qualify:
			return '#319b42';
		case WhatHappened.Training:
			return '#85b7e2';
		case WhatHappened.Afford:
			return '#00568b';
		case WhatHappened.Postponed:
			return '#ffd616';
		case WhatHappened.NeedsAnalysisOnly:
			return '#36a2eb';
		default:
			return '#ffd616';
	}
}

export function GetHEXColorByPresentationSubType(presubType: any) {
	switch (presubType) {
		case PresentationSubType.OnlineMCGruffLead:
			return '#949483';
		case PresentationSubType.SponsoredMcGruffLead:
			return '#f47b7b';
		case PresentationSubType.OnlineChildSafeLead:
			return '#9f1f5c';
		case PresentationSubType.SponsoredChildSafeLead:
			return '#ef9020';
		case PresentationSubType.SponsoredVeteranLead:
			return '#319b42';
		case PresentationSubType.VeteranFamilyLead:
			return '#85b7e2';
		case PresentationSubType.ReturnCard:
			return '#00568b';
		case PresentationSubType.BurielWillKitForVeteran:
			return '#ffd616';
		case PresentationSubType.ServiceMemberBenefitsLead:
			return '#ff6384';
		case PresentationSubType.ServiceMemberBenefitsLeadAlpha:
			return '#b2efba';
		case PresentationSubType.SponsoredNoCostLaw:
			return '#e5352b';
		case PresentationSubType.OnlineNoCostLaw:
			return '#0081b4';
		case PresentationSubType.SponsoredFinalExpense:
			return '#96cbb3';
		case PresentationSubType.OnlineFinalExpense:
			return '#91be3e';
		case PresentationSubType.Afford:
			return '#39a6dd';
		case PresentationSubType.Think:
			return '#eb0973';
		case PresentationSubType.Qualify:
			return '#dde2e0';
		case PresentationSubType.NotInterested:
			return '#333c41';
		case PresentationSubType.Sale:
			return '#c13832';
		case PresentationSubType.ReportFormRequest:
			return '#5c92fa';
		case PresentationSubType.SponsoredCreditUnionBenefits:
			return '#5c92fa';
		case PresentationSubType.OnlineCreditUnionBenefits:
			return '#333c41';
		case PresentationSubType.OnlineCreditUnionBenefitsCA:
			return '#dde2e0';
		case PresentationSubType.ReturnCardNoCost:
			return '#eb0973';
		case PresentationSubType.MarketUnionAssocMember:
			return '#ff9f40';
		case PresentationSubType.MarketUnionAssocFamilyFriend:
			return '#4bc0c0';
		case PresentationSubType.ReturnCardUnion:
			return '#36a2eb';
		case PresentationSubType.GlobeWebApplication:
			return '#800020';
		case PresentationSubType.GlobeCustomer:
			return '#808000';
		case PresentationSubType.GlobePlus:
			return '#A064A0';
		case PresentationSubType.GlobeInquiry:
			return '#C8B575';
		case PresentationSubType.SMBSponsoredVeteranLead:
			return '#319b42';
		case PresentationSubType.SMBVeteranFamilyLead:
			return '#85b7e2';
		case PresentationSubType.SMBSponsoredVeteranLeadAlpha:
			return '#8ae698';
		case PresentationSubType.SMBVeteranFamilyLeadAlpha:
			return '#3079b8';
		case PresentationSubType.Civilian:
			return '#A064A0';
		case PresentationSubType.ServiceMemberBenefitNotification:
			return '#C8B575';
		default:
			return '#ffffff';
	}
}

export function GetHEXColorByPresentationSubTypeName(presubType: any) {
	switch (presubType.replaceAll(' ', '').toLowerCase()) {
		case 'OnlineMCGruffLead'.toLowerCase():
			return '#949483';
		case 'SponsoredMcGruffLead'.toLowerCase():
			return '#f47b7b';
		case 'OnlineChildSafeLead'.toLowerCase():
			return '#9f1f5c';
		case 'SponsoredChildSafeLead'.toLowerCase():
			return '#ef9020';
		case 'SponsoredVeteranLead'.toLowerCase():
			return '#319b42';
		case 'SponsoredVeteranLead(Alpha)'.toLowerCase():
			return '#8ae698';
		case 'VeteranFamilyLead'.toLowerCase():
			return '#85b7e2';
		case 'VeteranFamilyLead(Alpha)'.toLowerCase():
			return '#3079b8';
		case 'ReturnCard'.toLowerCase():
			return '#00568b';
		case 'BurielWillKitForVeteran'.toLowerCase():
			return '#ffd616';
		case 'BURIAL&WILLKITFORVETERANS'.toLowerCase():
			return '#ffd616';
		case 'ServiceMemberBenefitsLead'.toLowerCase():
			return '#ff6384';
		case 'ServiceMemberBenefitsLead(Alpha)'.toLowerCase():
			return '#b2efba';
		case 'SponsoredNoCostLaw'.toLowerCase():
			return '#e5352b';
		case 'OnlineNoCostLaw'.toLowerCase():
			return '#0081b4';
		case 'SponsoredFinalExpense:SDLI'.toLowerCase():
			return '#96cbb3';
		case 'OnlineFinalExpense:ODLI'.toLowerCase():
			return '#91be3e';
		case 'Afford'.toLowerCase():
			return '#39a6dd';
		case 'Think'.toLowerCase():
			return '#eb0973';
		case 'Qualify'.toLowerCase():
			return '#dde2e0';
		case 'NotInterested'.toLowerCase():
			return '#333c41';
		case 'Sale'.toLowerCase():
			return '#c13832';
		case 'ReportFormRequest'.toLowerCase():
			return '#5c92fa';
		case 'SponsoredCreditUnionBenefits'.toLowerCase():
			return '#5c92fa';
		case 'OnlineCreditUnionBenefits'.toLowerCase():
			return '#333c41';
		case 'OnlineCreditUnionBenefitsCA'.toLowerCase():
			return '#dde2e0';
		case 'ReturnCardWILLKIT(NOCOSTLAW)'.toLowerCase():
			return '#eb0973';
		case 'MarketUnion/AssocMember'.toLowerCase():
			return '#ff9f40';
		case 'MarketUnion/AssocFamily/Friend'.toLowerCase():
			return '#4bc0c0';
		case 'ReturnCardUnion'.toLowerCase():
			return '#36a2eb';
		case 'GlobeWebApplication'.toLowerCase():
			return '#800020';
		case 'GlobeCustomer'.toLowerCase():
			return '#808000';
		case 'GlobePlus'.toLowerCase():
			return '#A064A0';
		case 'GlobeInquiry'.toLowerCase():
			return '#C8B575';
		case 'Civilian'.toLowerCase():
			return '#A064A0';
		case 'ServiceMemberBenefitNotification'.toLowerCase():
			return '#C8B575';
		default:
			return '#ffffff';
	}
}

export function GetHEXColorByPresentationType(presType: any) {
	switch (presType) {
		case PresentationType.Referral:
			return '#fcdd5b';
		case PresentationType.McGruffSafeKids:
			return '#5ba2fd';
		case PresentationType.ChildSafe:
			return '#9f1f5c';
		case PresentationType.POS:
			return '#e87e05';
		case PresentationType.Union:
			return '#89d76c';
		case PresentationType.Association:
			return '#85b7e2';
		case PresentationType.CreditUnion:
			return '#00568b';
		case PresentationType.Other:
			return '#cf76e1';
		case PresentationType.Veteran:
			return '#db0a5b';
		case PresentationType.WillKitNoCostLaw:
			return '#0081b4';
		case PresentationType.FinalExpense:
			return '#96cbb3';
		case PresentationType.Demo:
			return '#91be3e';
		case PresentationType.ProLead:
			return '#39a6dd';
		case PresentationType.GlobeLife:
			return '#275670';
		case PresentationType.ServiceMemberBenefits:
			return '#C8B575';
		default:
			return '#FFFFFF';
	}
}

export function GetHEXColorByHouseHold(presType: any) {
	switch (presType) {
		case HpProHouseHoldType.single:
			return '#fcdd5b';
		case HpProHouseHoldType.single_parent:
			return '#5ba2fd';
		case HpProHouseHoldType.married:
			return '#9f1f5c';
		case HpProHouseHoldType.family:
			return '#e87e05';
		case HpProHouseHoldType.retired:
			return '#89d76c';
		case HpProHouseHoldType.single_retired:
			return '#85b7e2';
		case HpProHouseHoldType.married_retired:
			return '#00568b';
		default:
			return '#FFFFFF';
	}
}
