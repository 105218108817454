import { Model } from '@vuex-orm/core'
import User from './User'
import PrimaryPerson from './PrimaryPerson'
import { EntityHelper } from '@/helpers/EntityHelper'
import { PlanEntity, PresentationPlans, PersonPlan, SpousePlan } from './PlanEntity'
import PremiumPlanEntity from './PremiumPlanEntity'
import Report from './Report'
import moment from 'moment'
import GenericKeyPair from '@/models/GenericKeyPair'
import { setPersistedState } from '@/helpers/StateMapper'
import { FieldTrainingType } from '@/enumerations/FieldTrainingType'
let listofPremiumApproachType = require("../assets/pre-fill-data/premium-approach-type.json");
import { CountryType } from "../enumerations/CountryType"
import PresentationWillKitVeterans from './PresentationWillKitVeterans'

export default class Presentation extends Model {
  static entity = 'presentations'
  static primaryKey = 'Id'

  static fields() {
    return {
      Id: this.uid(() => EntityHelper.uuid()),
      User_Id: this.attr(null),
      Person_Id: this.attr(null),
      Report_Id: this.attr(null),
      PresentedBy: this.belongsTo(User, 'User_Id'),
      PresentedTo: this.belongsTo(PrimaryPerson, 'Person_Id'),
      Report: this.belongsTo(Report, 'Report_Id'),
      StartTime: this.string(''),
      EndTime: this.string(''),
      CurrentStepCompleted: this.number(0),
      PresentationTypeId: this.number(0),
      PresentedGroup: this.string(''),
      CombinedPremiumAmount: this.number(0),
      PremiumTypeId: this.number(1),
      SubType!: this.string(''),
      WhatHappened!: this.string(''),
      NANotes!: this.string(''),
      FPNotes!: this.string(''),
      FieldTraining!: this.string('ALONE'),
      FieldTrainee!: this.string(''),
      VideoReviewCompleted: this.boolean(null).nullable(),
      TimeOnNoCostBenefits: this.string(''),
      TimeOnNeedAnalysis: this.string(''),
      TimeOnPlanGenerator: this.string(''),
      TimeOnBenefitSummary: this.string(''),
      TimeOnPresentPlan: this.string(''),
      TimeOnReportCard: this.string(''),
      PremiumPlanEntity_Id: this.attr(null),
      PremiumPlan: this.belongsTo(PremiumPlanEntity, 'PremiumPlanEntity_Id'),
      Plans: this.belongsToMany(PlanEntity, PresentationPlans, 'presentationId', 'planId'),
      SGNumber: this.string(''),
      Language: this.string(''),
      State: this.string(''),
      NoCostMaterialsTime: this.string(''),
      AllSponserships: this.string(''),
      isNeedAnalysisCompleted: this.boolean(false),
      isReportCardCompleted: this.boolean(false),
      isPlanGeneratorCompleted: this.boolean(false),
      isBenefitsSummaryCompleted: this.boolean(false),
      isPresentPlanCompleted: this.boolean(false),
      isNoCostCompleted: this.boolean(false),
      MedicalQuestionsNA: this.string(''),
      isSuperCombo: this.boolean(true),
      Code: this.string(null).nullable(),
      ParentCode: this.string(null).nullable(),
      PlanOptions: this.string(null).nullable(),
      Referrals: this.string(null).nullable(),
      WageType: this.string('HOURLY').nullable(),
      Protections: this.string(null).nullable(),
      LicenseNumber: this.string(null).nullable(),
      CurrentPlan: this.string(''),
      LanguageFlag: this.string('en'),
      NeedAmount: this.string('2%'),
      BSMedicalQuestions: this.string(''),
      CountryId: this.number(null).nullable(),
      StateId: this.number(null).nullable(),
      LanguageId: this.number(null).nullable(),
      LeadId: this.string(null).nullable(),
      PremiumValuesCustomized: this.boolean(false),
      WageValuesCustomized: this.boolean(false),
      CustomAmountCustomized: this.boolean(false),
      PresentedGroupId: this.number(null).nullable(),
      PresentationTypeString: this.string(null).nullable(),
      BenefitsSummaryCalculationVersion: this.number(null).nullable(),
      ProductListVersion: this.number(null).nullable(),
      RateListVersion: this.number(null).nullable(),
      PresentationSubTypeId: this.number(null).nullable(),
      DollarADayCustomized: this.boolean(false),
      AgentCode: this.string(''),
      LicenseId: this.number(null).nullable(),
      FieldTrainingAssociateId: this.number(null).nullable(),
      PresentationWillKitVeterans: this.string(''),
      MemberSurvey: this.string(''),
      MemberQuestionnaire: this.string(''),
      MemberLookupTypeId: this.number(null).nullable(),
      BackupPresentedGroupId : this.number(null).nullable(),
      FamilyCareSurvey: this.string(''),
      AnyProductChecked: this.boolean(null).nullable(),
      EmailMaterialsAttachments: this.string(''),
      EmailPresComponentAttachments: this.string(''),
      BenefitAndDocumentEmailSentCount: this.number(0).nullable(),
      BenefitAndDocumentEmailFirstTimeOpen: this.boolean(null).nullable(),
      IsGiftCertificateFirstTimeOpen: this.boolean(true).nullable(),
      isPlanCalculationNeeded:this.boolean(false)
    }
  }

  get TimeOnNA() {
    if (!this.TimeOnNeedAnalysis || this.TimeOnNeedAnalysis == "")
      return "00:00:00";
    return this.msToTime(parseInt(this.TimeOnNeedAnalysis));
  }

  get TimeOnPG() {
    if (!this.TimeOnPlanGenerator || this.TimeOnPlanGenerator == "")
      return "00:00:00";
    return this.msToTime(parseInt(this.TimeOnPlanGenerator));
  }

  get TimeOnBS() {
    if (!this.TimeOnBenefitSummary || this.TimeOnBenefitSummary == "")
      return "00:00:00";
    return this.msToTime(parseInt(this.TimeOnBenefitSummary));
  }

  get TimeOnPP() {
    if (!this.TimeOnPresentPlan || this.TimeOnPresentPlan == "")
      return "00:00:00";
    return this.msToTime(parseInt(this.TimeOnPresentPlan));
  }

  get TimeOnNoCost() {
    if (!this.TimeOnNoCostBenefits || this.TimeOnNoCostBenefits == "")
      return "00:00:00";
    return this.msToTime(parseInt(this.TimeOnNoCostBenefits));
  }

  get TimeOnRC() {
    if (!this.TimeOnReportCard || this.TimeOnReportCard == "")
      return "00:00:00";
    return this.msToTime(parseInt(this.TimeOnReportCard));
  }

  get StartTimeString() {

    return moment(String(this.StartTime)).format('MMM DD YY hh:mm A')
  }


  get StartTimeStringWithoutYear() {

    return moment(String(this.StartTime)).format('MMM DD hh:mm A')
  }

  get PresentationDay() {

    return moment(String(this.StartTime)).format('MM/DD/YYYY')
  }


  get generatedCode() {
    if (!this.Code) {
      this.Code = this.getRandomString(6);
      Presentation.update({
        where: this.Id,
        data: this,
      });
      var saveData = new GenericKeyPair(
        "PresentationsList",
        JSON.stringify(Presentation.query().withAllRecursive().all())
      );
      setPersistedState(saveData);
    }
    return this.Code;
  }

  // get presTypeName() {
  //   var self = this;
  //   var pres = listofPresentationType.find(function (
  //     item: any
  //   ) {
  //     return item.id == self.PresentationTypeId;
  //   });
  //   return pres?.name;
  // }

  get premiumApproachName() {
    var self = this;
    var pres = listofPremiumApproachType.find(function (
      item: any
    ) {
      return item.id == self.PremiumTypeId;
    });
    return pres?.type;
  }

  get TotalTime() {
    if (!this.EndTime || this.EndTime == "")
      return "00:00";

    return this.dateDifference(this.EndTime, this.StartTime);
  }

  // get ZipCodeFormat() {
  //   if (this.CountryId == 2) return "XXX XXX";
  //   else if (this.CountryId == 3) return "XXXX";
  //   else return "XXXXX";
  // }

  dateDifference(date1: any, date2: any) {
    var ms = moment(date1, "MM/DD/YYYY HH:mm:ss tt").diff(
      moment(date2, "MM/DD/YYYY HH:mm:ss tt")
    );
    var d = moment.duration(ms);
    var s = this.millisToMinutesAndSeconds(ms);
    return s;
  }



  millisToMinutesAndSeconds(millis: any) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    if (minutes > 59) {
      var hour = minutes / 60;
      minutes = minutes % 60;
      return hour.toFixed() + ":" + minutes + ":" + seconds;
    }

    let min = minutes.toString();
    if (min.length < 2)
      min = "0" + minutes;

    let sec = seconds.toString();
    if (sec.length < 2)
      sec = "0" + seconds;

    return min + ":" + sec;
  }

  getRandomString(length: number): string {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  FieldTrainingTypeId() {
    if (this.FieldTraining == "ALONE")
      return FieldTrainingType.ALONE;
    else return FieldTrainingType.WITHSOMEONE;
  }

  get ZipCodeFormat() {
    if (this.CountryId == CountryType.Canada) return "XXX XXX";
    else if (this.CountryId == CountryType.UnitedStates) return "#####-####";

    else return "#####-####";
  }

  get ZipCodeText() {
    if (this.CountryId == CountryType.Canada) return "Report Form.Postal";
    else if (this.CountryId == CountryType.UnitedStates) return "Report Form.ZipCode";
    else return "Report Form.ZipCode";
  }

  get isDollarADay() {
    if (
      this.PremiumTypeId != undefined &&
      this.PremiumTypeId == 3
    ) {
      return true;
    } else {
      return false;
    }
  }


  msToTime(s: number) {
    let a = moment(s);
    return a.utc().format("HH:mm:ss");
  }

  public Id!: any
  public Selected!: any
  public Report!: any
  public User_Id!: any
  public PremiumPlanEntity_Id!: any
  public Person_Id!: any
  public Report_Id!: any
  public Plans!: any
  public PresentedBy!: string
  public CurrentPlan!: string
  public WhatHappened!: string
  public PlanOptions!: string
  public Protections!: string
  public PresentedTo!: PrimaryPerson
  public BSMedicalQuestions!: string
  public StartTime!: string
  public EndTime!: string
  public Referrals!: string
  public CurrentStepCompleted!: number
  public PresentationTypeId!: number
  public PresentedGroup!: string
  public CombinedPremiumAmount!: number
  public PremiumTypeId!: number
  public SubType!: string
  public FieldTraining!: string
  public FieldTrainee!: string
  public VideoReviewCompleted!: boolean
  public NANotes!: string
  public FPNotes!: string
  public TimeOnNeedAnalysis!: string
  public TimeOnPlanGenerator!: string
  public TimeOnBenefitSummary!: string
  public TimeOnPresentPlan!: string
  public TimeOnReportCard!: string
  public TimeOnNoCostBenefits!: string
  public PremiumPlan!: PremiumPlanEntity
  public SGNumber!: string
  public MedicalQuestionsNA!: string
  public Language!: string
  public State!: string
  public NoCostMaterialsTime!: string
  public AllSponserships!: string
  public isNeedAnalysisCompleted!: boolean
  public isReportCardCompleted!: boolean
  public isPlanGeneratorCompleted!: boolean
  public isBenefitsSummaryCompleted!: boolean
  public isPresentPlanCompleted!: boolean
  public isSuperCombo!: boolean
  public Code!: string
  public ParentCode!: string
  public WageType!: string
  public LanguageFlag!: string
  public NeedAmount!: number
  public LicenseNumber!: string
  public CountryId!: number
  public StateId!: number
  public LanguageId!: number
  public LeadId!: string
  //public Plans!: PlanEntity[]
  public PremiumValuesCustomized!: boolean
  public WageValuesCustomized!: boolean
  public CustomAmountCustomized!: boolean;
  public TotalTimeString!: string
  public PresentedGroupId!: number
  public isNoCostCompleted!: boolean
  public PresentationTypeString!: string
  public BenefitsSummaryCalculationVersion!: number
  public ProductListVersion!: number
  public RateListVersion!: number
  public CombinedAnnualIncome!: string
  public ExistingCoverage!: string
  public RecommendedCoverage!: string
  public RemainingCoverage!: string
  public PresentationSubTypeId!: number
  public DollarADayCustomized!: boolean
  public AgentCode!: string
  public LicenseId!: number
  public FieldTrainingAssociateId!: number
  public PresentationWillKitVeterans!: string
  public MemberSurvey!: string
  public MemberQuestionnaire!: string
  public isWillKitFormDownloaded!:boolean
  public MemberLookupTypeId!: number
  public BackupPresentedGroupId!: number
  public FamilyCareSurvey!: string
  public AnyProductChecked!: boolean
  public EmailMaterialsAttachments!: string
  public EmailPresentationAttachments!: string
  public BenefitAndDocumentEmailSentCount!: number
  public BenefitAndDocumentEmailFirstTimeOpen!: boolean
  public IsGiftCertificateFirstTimeOpen!: boolean
  public isPlanCalculationNeeded!:boolean
}
