import { IndexDBConstants } from './../constants/IndexDBConstants';
import { getPersistedState } from './StateMapper';
import { Camelize, isJson } from './ArrayHelper';

export function isLoggedIn() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) return true;
}

export function GetUserId() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) return userDetail.UserDetail.UserId;
		else return 0;
	}
	return 0;
}

export function GetHpProUserId() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) return userDetail.UserDetail.Id;
		else return 0;
	}
	return 0;
}

export function GetAssociateId() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) return userDetail.UserDetail.AssociateId;
		else return 0;
	}
	return 0;
}

export function GetFullName() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) {
			var fullname: string = '';
			if (userDetail.UserDetail.LastName != undefined) fullname += userDetail.UserDetail.LastName.toUpperCase();
			fullname += ', ';
			if (userDetail.UserDetail.FirstName != undefined) fullname += userDetail.UserDetail.FirstName.toUpperCase();
			return fullname;
		} else return '';
	}
	return '';
}

export function GetAscFullName() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) {
			var fullname: string = '';
			if (userDetail.UserDetail.FirstName != undefined) fullname += userDetail.UserDetail.FirstName + ' ';

			if (userDetail.UserDetail.LastName != undefined) fullname += userDetail.UserDetail.LastName;
			return fullname;
		} else return '';
	}
	return '';
}

export function GetAscFullNameWithCamelCase() {
	var name = GetAscFullName();

	if (name != undefined) {
		return Camelize(name);
	}
	return '';
}

export function GetUserEmail() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) {
			var email: string = '';
			if (userDetail.UserDetail.Email != undefined) email = userDetail.UserDetail.Email;
			return email;
		} else return '';
	}
	return '';
}

export function GetPrimaryLicense() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) {
			if (userDetail.UserDetail.Licenses != undefined && userDetail.UserDetail.Licenses.length > 0) {
				var lic = undefined;
				var lic = userDetail.UserDetail.Licenses.filter((i: { IsPrimary: boolean }) => i.IsPrimary == true);
				if (lic != undefined && lic.length > 0) return lic[0].LicenseNumber + ' ' + lic[0].StateCode;
				return '';
			}
		} else return '';
	}
	return '';
}

export async function GetUserCountry() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) {
			var stateid = userDetail.UserDetail.StateId;
			var statelist: any = await getPersistedState(IndexDBConstants.StateListKey);
			if (statelist != null) {
				var country = statelist.find((x: any) => x.StateId == stateid);

				return country.CountryId;
			} else {
				return 1;
			}
		} else return 1;
	}
	return 1;
}

export function GetAgentCode() {
	const storedUser = localStorage.getItem('user');
	if (storedUser != undefined && storedUser != '' && isJson(storedUser)) {
		var userDetail = JSON.parse(storedUser);
		if (userDetail.UserDetail != undefined) {
			var agentNumber: string = '';
			if (userDetail.UserDetail.AgentNumber != undefined) agentNumber = userDetail.UserDetail.AgentNumber;
			return agentNumber;
		} else return '';
	}
	return '';
}
