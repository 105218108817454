import axios from 'axios';
import SyncModel from '../models/SyncModel';

const API_URL = 'http://altimpapiqa.zaifworks.com/api/view/';

class SyncService {


    SyncData(model: SyncModel) {

        //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        let request = axios
            .post(API_URL + 'InsertHPProData', model)
            .then(response => {

                console.log(response.data);
                return response.data;
            }).catch(ex => {
                console.log(ex);
            });
        // header('Access-Control-Allow-Origin: *');
        // header('Access-Control-Allow-Methods: GET, PUT, POST, DELETE, OPTIONS, post, get');
        // header("Access-Control-Max-Age", "3600");
        // header('Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token');
        // header("Access-Control-Allow-Credentials", "true");

        return request;
    }
}

// , {

//     headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Content-type": "application/json",
//         'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
//         "Access-Control-Max-Age": "3600",
//         "Access-Control-Allow-Credentials": "true"
//     }
// }
export default new SyncService();