import { ApiEndPointConstants } from '@/constants/ApiEndPointConstants';
import {
	GetRequestWithAuthorization,
	PatchRequestWithAuthorization,
	PostRequestWithAuthorization,
	PostAsyncRequestWithAuthorization
} from '@/helpers/APIHelper';
import GenericKeyPair from '@/models/GenericKeyPair';

export class APIService {
	public static SavePresentation = (presentation: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return PostRequestWithAuthorization(
			ApiEndPointConstants.SyncPresentationEndpoint,
			GenericKeyPairobj,
			presentation
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static SaveRefferals = (Refferals: any, GenericKeyPairobj: GenericKeyPair[]) => {
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair('presDto', Refferals));
		return PostRequestWithAuthorization(
			ApiEndPointConstants.AddReferralEndpoint,
			GenericKeyPairobj,
			Refferals
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static SaveGamePlan = async (GamePlan: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PostAsyncRequestWithAuthorization(
			ApiEndPointConstants.AddGamePlanEndPoint,
			GenericKeyPairobj,
			GamePlan
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static UpdatePresentationDetail = async (Pres: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return PatchRequestWithAuthorization('Presentation/UpdatePresentationDetailById', GenericKeyPairobj, Pres);
	};

	public static UpdateGamePlan = async (GamePlan: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PatchRequestWithAuthorization(
			ApiEndPointConstants.UpdateGamePlanEndPoint,
			GenericKeyPairobj,
			GamePlan
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static GetGamePlansByYear = (years: number[]) => {
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair('year', years));
		return GetRequestWithAuthorization(ApiEndPointConstants.GetGamePlansByYearEndPoint, params).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static GetPresentationById = (presentationId: number) => {
		var params: GenericKeyPair[] = [];
		params.push(new GenericKeyPair('presentationId', presentationId));
		return GetRequestWithAuthorization(
			ApiEndPointConstants.GetPresentationDetailByIdEndPoint,
			params
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static GetCurrentAppVersion = () => {
		var params: GenericKeyPair[] = [];
		return GetRequestWithAuthorization(ApiEndPointConstants.GetCurrentAppVersionEndPoint, params).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static SendClientOrGroupSMS = async (Request: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PostAsyncRequestWithAuthorization(
			ApiEndPointConstants.ClientOrGroupSMSEndpoint,
			GenericKeyPairobj,
			Request
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static SendClientOrGroupMMS = async (Request: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PostAsyncRequestWithAuthorization(
			ApiEndPointConstants.ClientOrGroupMMSEndpoint,
			GenericKeyPairobj,
			Request
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	public static SendClientOrGroupEmail = async (Request: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PostAsyncRequestWithAuthorization(
			ApiEndPointConstants.ClientOrGroupEmailEndpoint,
			GenericKeyPairobj,
			Request
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};
	public static SendBenefitAndDocumentEmail = async (Request: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PostAsyncRequestWithAuthorization(
			ApiEndPointConstants.BenefitsAndDocumentEmailEndpoint,
			GenericKeyPairobj,
			Request
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};

	// public static ProcessClientOrGroupMessage = async (Request: any, GenericKeyPairobj: GenericKeyPair[]) => {
	// 	return await PostAsyncRequestWithAuthorization(
	// 		'HpProMessaging/ProcessClientOrGroupMMS',
	// 		GenericKeyPairobj,
	// 		Request
	// 	).then((res: any) => {
	// 		return Promise.resolve(res);
	// 	});
	// };

	public static ProcessBenefitsAndDocumentEmail = async (Request: any, GenericKeyPairobj: GenericKeyPair[]) => {
		return await PostAsyncRequestWithAuthorization(
			'Presentation/ProcessBenefitsAndDocumentEmail',
			GenericKeyPairobj,
			Request
		).then((res: any) => {
			return Promise.resolve(res);
		});
	};
}
