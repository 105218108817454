import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'



export default class MemberSurvey extends Model {
    static entity = 'MemberSurvey'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            MemberFirstName: this.string(null).nullable(),
            MemberLastName: this.string(null).nullable(),
            AddSpouse: this.boolean(null).nullable(),
            SpouseFirstName: this.string(null).nullable(),
            SpouseLastName: this.string(null).nullable(),
            CreditUnion: this.string(null).nullable(),
            MemberDuration: this.string(null).nullable(),
            OnlineBanking: this.boolean(null).nullable(),
            OnlineBankingMoreInfo: this.boolean(null).nullable(),
            MobileBanking: this.boolean(null).nullable(),
            MobileBankingMoreInfo: this.boolean(null).nullable(),
            EStatement: this.boolean(null).nullable(),
            EStatementMoreInfo: this.boolean(null).nullable(),
            BillPay: this.boolean(null).nullable(),
            BillPayMoreInfo: this.boolean(null).nullable(),
            BranchService: this.boolean(null).nullable(),
            BranchServiceMoreinfo: this.boolean(null).nullable(),
            CheckingAccount: this.boolean(null).nullable(),
            CheckingAccountMoreinfo: this.boolean(null).nullable(),
            SavingAccount: this.boolean(null).nullable(),
            SavingAccountMoreinfo: this.boolean(null).nullable(),
            CertificateOfDeposit: this.boolean(null).nullable(),
            CertificateOfDepositMoreInfo: this.boolean(null).nullable(),
            MoneyMarketAccount: this.boolean(null).nullable(),
            MoneyMarketAccountMoreInfo: this.boolean(null).nullable(),
            IRARetirement: this.boolean(null).nullable(),
            IRARetirementMoreInfo: this.boolean(null).nullable(),
            PersonlCreditCard: this.boolean(null).nullable(),
            PersonalCreditCardMoreInfo: this.boolean(null).nullable(),
            HomeLoan: this.boolean(null).nullable(),
            HomeLoanMoreInfo: this.boolean(null).nullable(),
            AutoEveLoan: this.boolean(null).nullable(),
            AutoEveLoanMoreInfo: this.boolean(null).nullable(),
            PersonalLineOfCredit: this.boolean(null).nullable(),
            PersonalLineOfCreditMoreInfo: this.boolean(null).nullable(),
            IsMemberofUnionOrVeteran: this.boolean(null).nullable(),
            GroupName: this.string(null).nullable(),
            ADnDBenifit: this.boolean(null).nullable(),
            ADnDBenifitIssueToday: this.boolean(null).nullable(),
            FIG: this.boolean(null).nullable(),
            FIGIssueToday: this.boolean(null).nullable(),
            NoCostLastWill: this.boolean(null).nullable(),
            NoCostLastWillIssueToday: this.boolean(null).nullable(),
            ChildSafeKit: this.boolean(null).nullable(),
            ChildSafeKitIssueToday: this.boolean(null).nullable(),
            NeedAnalysis: this.boolean(null).nullable(),
            NeedAnalysisIssueToday: this.boolean(null).nullable(),
            FinalExpense: this.boolean(null).nullable(),
            FinalExpenseIssueToday: this.boolean(null).nullable(),
            Experince: this.number(0),
            AddMessageOrQuestion: this.string(null).nullable(),
            PhoneNumber: this.string('').nullable(),
            Email: this.string('').nullable(),
            Sponsor: this.string(null).nullable(),
            RelationShip: this.string(null).nullable(),
            IsFormSubmited: this.boolean(null).nullable(),
            CurrentFinancialInstitution: this.string(null).nullable()
        }
    }

    public Id !: any
    public MemberFirstName!: string
    public MemberLastName!: string
    public AddSpouse!: boolean
    public SpouseFirstName!: string
    public SpouseLastName!: string
    public CreditUnion!: string
    public MemberDuration!: string
    public OnlineBanking!: boolean
    public OnlineBankingMoreInfo!: boolean
    public MobileBanking!: boolean
    public MobileBankingMoreInfo!: boolean
    public EStatement!: boolean
    public EStatementMoreInfo!: boolean
    public BillPay!: boolean
    public BillPayMoreInfo!: boolean
    public BranchService!: boolean
    public BranchServiceMoreinfo!: boolean
    public CheckingAccount!: boolean
    public CheckingAccountMoreinfo!: boolean
    public SavingAccount!: boolean
    public SavingAccountMoreinfo!: boolean
    public CertificateOfDeposit!: boolean
    public CertificateOfDepositMoreInfo!: boolean
    public MoneyMarketAccount!: boolean
    public MoneyMarketAccountMoreInfo!: boolean
    public IRARetirement!: boolean
    public IRARetirementMoreInfo!: boolean
    public PersonlCreditCard!: boolean
    public PersonalCreditCardMoreInfo!: boolean
    public HomeLoan!: boolean
    public HomeLoanMoreInfo!: boolean
    public AutoEveLoan!: boolean
    public AutoEveLoanMoreInfo!: boolean
    public PersonalLineOfCredit!: boolean
    public PersonalLineOfCreditMoreInfo!: boolean
    public IsMemberofUnionOrVeteran!: boolean
    public GroupName!: string
    public ADnDBenifit!: boolean
    public ADnDBenifitIssueToday!: boolean
    public FIG!: boolean
    public FIGIssueToday!: boolean
    public NoCostLastWill!: boolean
    public NoCostLastWillIssueToday!: boolean
    public ChildSafeKit!: boolean
    public ChildSafeKitIssueToday!: boolean
    public NeedAnalysis!: boolean
    public NeedAnalysisIssueToday!: boolean
    public FinalExpense!: boolean
    public FinalExpenseIssueToday!: boolean
    public Experince!: number
    public AddMessageOrQuestion!: string
    public PhoneNumber!: string
    public Email!: string
    public Sponsor!: string
    public RelationShip!: string
    public IsFormSubmited!: boolean
    public CurrentFinancialInstitution!: string


    get CreditFinancialPlaceholder()
    {
        if (this.CreditUnion == "N/A" || this.MemberDuration == '0 (benefits were extended to me)')
            return this.CurrentFinancialInstitution;
        else
            return this.CreditUnion;
    }

}