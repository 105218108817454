export default class SyncModel {
    JsonString: string = '';
    ObjectKey: string = '';
    userGuid: string = '';

    constructor(Key: string, jsonString: string) {
        this.ObjectKey = Key;
        this.JsonString = jsonString;
        this.userGuid = ""
    }

}