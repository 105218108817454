import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import Login from "../views/LoginPage.vue";
import StartPresentation from "../views/StartPresentationPage.vue";
// import NeedAnalysis from "../views/NeedAnalysisPageOld.vue";
import NeedAnalysis from "../views/NeedAnalysisPage.vue";
// import PlanGenerator from "../views/PlanGeneratorPage.vue";
import PlanGenerator from "../views/PlanGeneratorPage.vue";
import BenefitsSummary from "../views/BenefitsSummaryPage.vue";
import PresentPlan from "../views/PresentPlanPage.vue";
import FinishPresentation from "../views/FinishPresentationPage.vue";
import ProfilePage from "../views/ProfilePage.vue";
import HomePage from "../views/HomePage.vue";
import GamePlanPage from "../views/GamePlanPage.vue";
import NoCostBenefits from "../views/NoCostBenefitsPage.vue";
import ReportFormPage from "../views/ReportFormPage.vue";
import PresentationHistoryPage from "../views/PresentationHistoryPage.vue";
import PresentationDetailPage from "../views/PresentationDetailPage.vue";
import PrintPresentationPage from "../views/PrintPresentationPage.vue";
import DashboardPage from "../views/DashboardPage.vue";
import Layout from "../views/Shared/Layout.vue";
import NotFoundPage from "../views/Shared/NotFoundPage.vue";
import { deletePersistedState, getPersistedState, setPersistedState } from "../helpers/StateMapper";
import Presentation from "@/entities/Presentation";
import PrimaryPerson from '@/entities/PrimaryPerson';
import Spouse from '@/entities/Spouse';
import TrainingPage from "../views/TrainingPage.vue";
import User from "../entities/User";
import { changeLocale, SetUserLanguage } from "../plugins/i18n.js";
import { isLoggedIn } from "@/helpers/APIExtentions";
import GenericKeyPair from "@/models/GenericKeyPair";
import LoggingHelper from "@/helpers/LoggingHelper";
import LoggingConstants from "@/constants/LoggingConstants";
import GeneralConstants from "@/constants/GeneralConstants";
import store from '@/store/index';


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { navTitle: "LOGIN" }
  },
  {
    name: "layout",
    path: "/#",
    component: Layout,
    children: [
      {
        path: "/StartPresentation",
        component: StartPresentation,
        name: "StartPresentation",
        props: true,
        meta: { navTitle: "HOME" }
      },
      {
        path: "/needanalysis",
        name: "NeedAnalysis",
        component: NeedAnalysis,
        meta: { navTitle: "NEEDS ANALYSIS" }
      },
      {
        path: "/plangenerator",
        name: "PlanGenerator",
        component: PlanGenerator,
        meta: { navTitle: "PLAN GENERATOR" },
        props: true
      },
      {
        path: "/benefitssummary",
        name: "BenefitsSummary",
        component: BenefitsSummary,
        meta: { navTitle: "BENEFITS SUMMARY" },
        props: true

      },
      {
        path: "/finishpresentation",
        name: "FinishPresentation",
        component: FinishPresentation,
        meta: { navTitle: "FINISH PRESENTATION" },
        props: true
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardPage,
        meta: { navTitle: "DASHBOARD", showLogout: true }
      },
      {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
        meta: { navTitle: "PROFILE", showLogout: true }
      },
      {
        path: "/home",
        name: "Hour Power Pro",
        component: HomePage,
        meta: { navTitle: "HOUR POWER PRO", showLogout: true }
      },
      {
        path: "/gameplan",
        name: "GamePlanPage",
        component: GamePlanPage,
        meta: { navTitle: "GAME PLAN", showLogout: false }
      },
      {
        path: "/nocostbenefits",
        name: "No Cost Benefits",
        component: NoCostBenefits,
        meta: { navTitle: "INTRODUCTION BENEFITS" },
        props: true
      },
      {
        path: "/report",
        name: "Report Form",
        component: ReportFormPage,
        meta: { navTitle: "REPORT FORM" }
      },
      {
        path: "/print",
        name: "Print Presentation",
        component: PrintPresentationPage,
        props: route => ({ presId: route.query.id }),
        meta: { navTitle: "PRINT PRESENTATION" }
      },
      {
        path: "/presentationhistorydetail",
        name: "PresentationHistoryDetail",
        component: PresentationDetailPage,
        props: true,
        meta: { navTitle: "PRESENTATION DETAIL" }
      },
      {
        path: "/presentationhistory",
        name: "PresentationHistory",
        component: PresentationHistoryPage,
        props: route => ({ presId: route.query.id }),
        meta: { navTitle: "PRESENTATION HISTORY" }
      },
      {
        path: "/training",
        name: "Training Page",
        component: TrainingPage,
        meta: { navTitle: "TRAINING" }
      }
    ]
  },
  { path: "*", name: "Not Found Page", component: NotFoundPage }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  var excludeFromPC: any = false;
  if (to != undefined && to.params != undefined && to.params.ExcludeFromPresentationCycle != undefined)
    excludeFromPC = to.params.ExcludeFromPresentationCycle;
  FetchNecessaryData(to.name, next, authRequired, !!loggedIn, excludeFromPC);
  if(to.path == '/StartPresentation'){
    store.state.SGNumber = "";
		store.state.PresentedGroup = "";
  }
  // if (authRequired && !loggedIn) {
  //   next("/");
  // } else {
  //   next();
  // }
});

function FetchNecessaryData(routeName: any, next: any, authRequired: boolean, isLoggedIn: boolean, excludeFromPC: boolean) {
  
  var presentationPage = false;
  if (GeneralConstants.PresentationTypesPages.includes(routeName)) presentationPage = true;
  if (presentationPage && excludeFromPC) presentationPage = false;

  FetchPresentation(presentationPage, next, authRequired, isLoggedIn);
  checkLanguageFlag(routeName, presentationPage);
}

function NavigatePage(next: any, authRequired: boolean, isLoggedIn: boolean, navigateToHome: boolean = false) {
  if (navigateToHome)
    next("/StartPresentation")
  if (authRequired && !isLoggedIn) {
    next("/");
  } else {
    next();
  }
}

function FetchPresentation(isPresentationPage: boolean, next: any, authRequired: boolean, isLoggedIn: boolean) {
  if (isPresentationPage) {

    if (Presentation.query().count() == 0) {
      getPersistedState("CurrentPresentation").then((result: any) => {
        if (result != undefined) {
          var pres = new Presentation(result);
          Presentation.insert({ data: pres });
          NavigatePage(next, authRequired, isLoggedIn)
        }
        else {
          NavigatePage(next, authRequired, isLoggedIn, true)
        }
      });
      return;
    }
    else {
      NavigatePage(next, authRequired, isLoggedIn)
    }
  }
  else {
     let test = store.state.SGNumber;
    getPersistedState("CurrentPresentation").then(async (result: any) => {
      if (result != undefined && result != 'null') {
        if(typeof result == "string" && result != ""){
          result = JSON.parse(result);
        }
        var pres = new Presentation(result);
        if(pres.SGNumber != undefined)
        {
            pres.SGNumber = test;
        }
        if (pres.EndTime == undefined || pres.EndTime == '') {
          pres.EndTime = new Date().toLocaleString();
        }
        var presentations: any = await getPersistedState("PresentationList");
        if (presentations != undefined && presentations.length > 0) {
          if (pres.PresentationTypeId > 0)
            presentations.push(pres);
          else LoggingHelper.getInstance().LogEvent(LoggingConstants.PresentationDiscarded);
        }
        else {
          if (pres.PresentationTypeId > 0)
            presentations = [pres];
          else LoggingHelper.getInstance().LogEvent(LoggingConstants.PresentationDiscarded);
        }
        await setPersistedState(new GenericKeyPair("PresentationList", presentations));
        await deletePersistedState("CurrentPresentation");
        Presentation.deleteAll();
        PrimaryPerson.deleteAll();
        Spouse.deleteAll();
        //Presentation.insert({ data: pres });
      }
      NavigatePage(next, authRequired, isLoggedIn)
    });

  }
}

function checkLanguageFlag(routerName: any, isPresentationPage: boolean) {
  if (isPresentationPage) {
    if (Presentation.query().count() == 0) {
      SetUserLanguage();
    }
    else {
      var lastPresentation = Presentation.query().last();
      if (lastPresentation != undefined) {
        changeLocale((Presentation.query().last()?.LanguageFlag != null) ? lastPresentation.LanguageFlag : 'en');
      }
      else {
        SetUserLanguage();
      }
    }
  }
  else {
    SetUserLanguage();
  }
}

export default router;

