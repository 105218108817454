var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"\n\t\tbottomnavigation\n\t\ttext-center text-capitalize\n\t\talign-center\n\t\tmx-n3\n\t\tmb-n3\n\t\tpx-3\n\t",class:{ accent: !_vm.$route.params.presentplan },attrs:{"no-gutters":""}},[(_vm.ShowHomeVisibility)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/StartPresentation', '', undefined, 'Start Presentation')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$homeicon")])],1)]}}],null,false,1575569179)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Home")))])]),_c('v-dialog',{attrs:{"max-width":"30vw"},model:{value:(_vm.confirmationDialogHome),callback:function ($$v) {_vm.confirmationDialogHome=$$v},expression:"confirmationDialogHome"}},[_c('v-card',[_c('v-card-title',{staticClass:"fw-700 xlg--text px-6 pt-6 pb-3"},[_vm._v(_vm._s(_vm.$t("General.ConfirmationHeading")))]),_c('v-card-text',{staticClass:"md--text"},[_vm._v(_vm._s(_vm.$t("General.ConfirmationText")))]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"md--text px-6",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){_vm.confirmationDialogHome = false}}},[_vm._v(_vm._s(_vm.$t("General.No")))]),_c('v-btn',{staticClass:"md--text px-6",attrs:{"color":"accent","small":"","depressed":""},on:{"click":function($event){return _vm.ConfirmHome()}}},[_vm._v(_vm._s(_vm.$t("General.Yes")))])],1)],1)],1)],1):_vm._e(),(_vm.NoCostVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/nocostbenefits', '', undefined, 'No Cost Benefits')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$nocostna")])],1)]}}],null,false,3557562106)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.NoCost")))])])],1):_vm._e(),(_vm.NeedAnalysisVisibilty)?_c('v-col',{attrs:{"icon":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","block":"","icon":"","large":"","tile":"","depressed":"","disabled":_vm.NeedAnalysisDisabled},on:{"click":function($event){return _vm.RouteTo('/needanalysis', '', undefined, 'Need Analysis')}}},'v-btn',attrs,false),on),[_c('v-icon',{class:{
							'white--text accent': _vm.NeedAnalysisActive,
							'white--text': !_vm.NeedAnalysisActive,
						}},[_vm._v("$baricon")])],1)]}}],null,false,821790054)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.NeedAnalysis")))])])],1):_vm._e(),(_vm.PGIconVisiblity)?_c('v-col',{attrs:{"icon":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","block":"","icon":"","large":"","tile":"","depressed":"","disabled":_vm.PGIconDisabled},on:{"click":function($event){return _vm.RouteTo(
							'/benefitssummary',
							'BenefitsSummary',
							{
								initiatePrePlan: false,
								presentplan: true,
							},
							'Present Plan'
						)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:{
							'white--text accent': _vm.PGIconActive,
							'white--text': !_vm.PGIconActive,
						}},[_vm._v("$tvicon")])],1)]}}],null,false,3342721016)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.PresentPlan")))])])],1):_vm._e(),(_vm.MyDashboardVisibility)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/dashboard', '', undefined, 'Dashboard')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$windowicon")])],1)]}}],null,false,1195761176)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Dashboard")))])])],1):_vm._e(),(_vm.PrePlanVisibility)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"preplannav",attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo(
							'/plangenerator',
							'PlanGenerator',
							{
								initiatePrePlan: true,
								ExcludeFromPresentationCycle: true,
							},
							'Pre Plan'
						)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$calculatoricon")])],1)]}}],null,false,210345061)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.PrePlan")))])])],1):_vm._e(),(_vm.GamePlanVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/gameplan', '', undefined, 'GamePlanPage')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$calendericon")])],1)]}}],null,false,2852487633)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Gameplans")))])])],1):_vm._e(),(_vm.PresHistoryVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"opacity-4 context-menu",attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$clockicon")])],1)]}}],null,false,1512625317)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.PresentationHistory")))])])],1):_vm._e(),(_vm.ProfileVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/profile', '', undefined, 'Profile')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$usericon")])],1)]}}],null,false,73044933)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Profile")))])])],1):_vm._e(),(_vm.PlanGeneratorVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"plangennav",attrs:{"plain":"","block":"","icon":"","large":"","tile":"","depressed":"","disabled":_vm.PlanGeneratorDisabled},on:{"click":function($event){return _vm.RouteTo(
							'/plangenerator',
							'PlanGenerator',
							undefined,
							'Plan Generator'
						)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:{
							'white--text accent': _vm.PlanGeneratorActive,
							'white--text': !_vm.PlanGeneratorActive,
						}},[_vm._v("$calculatoricon")])],1)]}}],null,false,1966317087)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.PlanGenerator")))])])],1):_vm._e(),(_vm.EappSummaryVisiblity)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","block":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('eappsummary', 'Eapp Summary opened')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("$eappsummary")])],1)]}}],null,false,1308949303)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.EAppSummary")))])])],1):_vm._e(),(_vm.BeneficiaryVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","block":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('beneficiary', 'beneficiary click')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("$beneficiary")])],1)]}}],null,false,3972540423)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Beneficiary")))])])],1):_vm._e(),(_vm.BenefitsSummaryVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":"","block":"","large":"","tile":"","depressed":"","disabled":_vm.BenefitsSummaryDisabled || _vm.personexist},on:{"click":function($event){return _vm.RouteTo(
							'/benefitssummary',
							'BenefitsSummary',
							undefined,
							'Benefits Summary'
						)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:{
							'white--text': _vm.BenefitsSummaryActive,
							'white--text': !_vm.BenefitsSummaryActive,
						}},[_vm._v("$threebaricon")])],1)]}}],null,false,3212822658)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.BenefitSummary")))])])],1):_vm._e(),(_vm.ProtectionVisibility)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","block":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('protectionopt', 'protection benefits opened')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("$protectionicon")])],1)]}}],null,false,4142460184)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Protection")))])])],1):_vm._e(),(_vm.RateBookVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ratebooknav",attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('ratebook', '')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$spiralicon")])],1)]}}],null,false,3053969649)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.RateBook")))])])],1):_vm._e(),(_vm.SPIconVisiblity)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/ssprogram', '', undefined, 'Sponsorship popup opened')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$spprogram")])],1)]}}],null,false,2532234797)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.SponsorshipProgram")))])])],1):_vm._e(),(_vm.MaterialsVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('materials', 'Material Hide/Show')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$foldericon")])],1)]}}],null,false,2487372084)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Materials")))])])],1):_vm._e(),(_vm.ReportCardVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.RouteTo('/report', '', undefined, 'Report Screen')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$fileicon")])],1)]}}],null,false,3146492894)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.ReportCard")))])])],1):_vm._e(),(_vm.UWManualVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('underwriting', 'Underwriting opened')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$addbookicon")])],1)]}}],null,false,3459396192)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.UnderwritingManuals")))])])],1):_vm._e(),(_vm.MedicalQuestionVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('medical', 'medical popup opened')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$searchusericon")])],1)]}}],null,false,490001277)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.MedicalQuestions")))])])],1):_vm._e(),(_vm.CementingVideoVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"block":"","plain":"","icon":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('cementingvideo', 'cementing video opened')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$cementingvideo")])],1)]}}],null,false,3876152540)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.CementingVideo")))])])],1):_vm._e(),(_vm.FinishPresVisibilty)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":"","block":"","large":"","tile":"","depressed":"","disabled":_vm.FinsihPresentationDisabled},on:{"click":function($event){return _vm.RouteTo(
							'/finishpresentation',
							'',
							undefined,
							'Finish Presentation'
						)}}},'v-btn',attrs,false),on),[_c('v-icon',{class:{
							'white--text accent': _vm.FinsihPresentationActive,
							'white--text': !_vm.FinsihPresentationActive,
						}},[_vm._v("$checkdoubleicon")])],1)]}}],null,false,764826067)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.FinishPresentation")))])])],1):_vm._e(),(_vm.showTraining)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"opacity-4 context-menu",attrs:{"plain":"","icon":"","block":"","large":"","tile":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$trainingicon")])],1)]}}],null,false,147794733)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Training")))])])],1):_vm._e(),(_vm.showAchievements)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"opacity-4 context-menu",attrs:{"plain":"","icon":"","block":"","large":"","tile":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$achivementicon")])],1)]}}],null,false,3798202959)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Achievements")))])])],1):_vm._e(),(_vm.showLogout)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":"","block":"","large":"","tile":"","depressed":""},on:{"click":function($event){return _vm.Action('logOut', '')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$logouticon")])],1)]}}],null,false,3560050781)},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("BottomNavigation.Logout")))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }