




















































import { Component, Vue } from "vue-property-decorator";

@Component({
  props: ["PrimaryTIRAmount", "SpouseTIRAmount", "PrimaryName", "SpouseName","IsSpouse"],
})
export default class TerminalIllnessComponent extends Vue {
  data() {
    return {};
  }
}
