










import { Component, Vue } from "vue-property-decorator";

@Component
export default class BenefitGuranteedComponent extends Vue {

    data() {
        return {

        };
    }

}
