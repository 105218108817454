var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.path != '/')?_c('LeftMenu'):_vm._e(),(_vm.$route.path != '/print')?_c('AppBar'):_vm._e(),_c('v-row',{staticClass:"fill-height p-relative",class:{
			defaultbg: _vm.BackgroundImagesOnPresentationType,
			veteranbg:
				(_vm.IsVeteranType && !_vm.IsVeteranSMBAlphaType) ||
				(_vm.IsServiceMemberBenefitsType &&
					!_vm.IsServiceMemberBenefitsSVLAlphaType &&
					!_vm.IsServiceMemberBenefitsVFLAlphaType),
			veteranburialbg:
				_vm.IsVeteranSMBAlphaType ||
				_vm.IsServiceMemberBenefitsSVLAlphaType ||
				_vm.IsServiceMemberBenefitsVFLAlphaType,
			mcgruffbg: _vm.IsMcGruffType,
			preplanbg: _vm.IsPrePlanPage,
			Unionbg: _vm.IsUnionType || _vm.IsAssociationType,
			POSbg: _vm.IsPOSType || _vm.IsGlobeLifeType,
		},attrs:{"no-gutters":""}},[_c('v-col',[_c('router-view')],1)],1),_c('v-snackbar',{attrs:{"min-height":"40","timeout":2000,"color":"primary","content-class":"white--text py-2 text-center md--text"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(_vm.loader)?_c('v-overlay',{attrs:{"value":_vm.overlay,"opacity":"0.6"}},[_c('img',{staticStyle:{"width":"3.5vw"},attrs:{"src":require("../../assets/loader.gif")}})]):_vm._e(),_c('v-snackbar',{attrs:{"min-height":"40","timeout":2000,"color":"success","content-class":"white--text px-20 py-5 text-center lg--text"},model:{value:(_vm.Largesnackbar),callback:function ($$v) {_vm.Largesnackbar=$$v},expression:"Largesnackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(_vm.$route.path != '/print')?_c('app-bar-footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }