import GenericKeyPair from '@/models/GenericKeyPair';
import { GetAssociateId, isLoggedIn } from './APIExtentions';
import { GetRequestWithAuthorization } from './APIHelper';
import { getPersistedState, setPersistedState } from './StateMapper';
import { ApiEndPointConstants } from '@/constants/ApiEndPointConstants';
import { IndexDBConstants } from '@/constants/IndexDBConstants';

export function FetchInitialAppData() {
	let parameters: GenericKeyPair[] = [];
	GetRequestWithAuthorization(ApiEndPointConstants.SyncVersionEndpoint, parameters)
		.then(async (res) => {
			if (res != undefined && res.status) {
				var reslt = res.Result;
				if (reslt != undefined && reslt.length > 0) {
					var existingSyncVer: any = await getPersistedState(IndexDBConstants.SyncVersionKey);
					reslt.forEach((syncElement: any) => {
						if (existingSyncVer != undefined) {
							var elemetPresent = existingSyncVer.find((x: any) => x.Name == syncElement.Name);
							if (elemetPresent == undefined || elemetPresent.Version < syncElement.Version) {
								SyncData(syncElement);
							}
						} else {
							SyncData(syncElement);
						}
					});
				}
			} else {
				console.log(res.ErrorMessage);
			}
		})
		.catch((ex) => {});
}

export function FetchUserLevelData() {
	if (isLoggedIn()) {
		let parameters: GenericKeyPair[] = [];
		parameters.push(new GenericKeyPair('associateId', GetAssociateId()));
		GetRequestWithAuthorization(ApiEndPointConstants.AssociateCodedHeirarchyEndpoint, parameters)
			.then(async (res) => {
				if (res != undefined && res.status) {
					var reslt = res.Result;
					if (reslt != undefined && reslt.length > 0) {
						await setPersistedState(new GenericKeyPair(IndexDBConstants.AssociateCodedHierarchyKey, reslt));
					}
				} else {
					console.log(res.ErrorMessage);
				}
			})
			.catch((ex) => {});
	}
}

function SyncData(syncType: any) {
	switch (syncType.Name) {
		case 'BasicInfo':
			FetchAppData(syncType);
			break;
		case 'PresentationType':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.PresentationTypesEndpoint,
				IndexDBConstants.PresentationTypesKey
			);
			break;
		case 'Material':
			SyncDataForEndPointInKey(syncType, ApiEndPointConstants.MaterialsEndpoint, IndexDBConstants.MaterialsKey);
			break;
		case 'PresentationGroups':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.PresentationGroupsEndpoint,
				IndexDBConstants.PresentationGroupsKey
			);
			break;
		case 'MedicalQuestions':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.MedicalQuestionsEndpoint,
				IndexDBConstants.MedicalQuestionsKey
			);
			break;
		case 'ReportCardOptions':
			SyncDataForEndPointInKey(syncType, ApiEndPointConstants.ReportOptionsEndpoint, IndexDBConstants.ReportOptionsKey);
			break;
		case 'MilestonesOption':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.MilestonesOptionEndpoint,
				IndexDBConstants.MilestonesOptionKey
			);
			break;
		case 'RatesList':
			SyncDataForEndPointInKey(syncType, ApiEndPointConstants.RatesListEndpoint, IndexDBConstants.RatesListKey);
			break;
		case 'ProductsList':
			SyncDataForEndPointInKey(syncType, ApiEndPointConstants.ProductsListEndpoint, IndexDBConstants.ProductsListKey);
			break;
		case 'BenefitSummaryRates':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.BenefitSummaryRatesEndpoint,
				IndexDBConstants.BenefitSummaryRatesKey
			);
			break;
		case 'RequiredPresentationStep':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.RequiredPresentationStepConfigEndpoint,
				IndexDBConstants.RequiredPresentationStepConfigKey
			);
			break;
		case 'UWQuestionaireTemplates':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.UWQuestionaireTemplatesEndpoint,
				IndexDBConstants.UWQuestionaireTempaltesKey
			);
			break;
		case 'GeneralMedicalQuestion':
			SyncDataForEndPointInKey(
				syncType,
				ApiEndPointConstants.GeneralMedicalQuestionEndpoint,
				IndexDBConstants.GeneralMedicalQuestionKey
			);
			break;
		case 'CreditUnion':
			SyncDataForEndPointInKey(syncType, ApiEndPointConstants.CreditUnionEndpoint, IndexDBConstants.CreditUnionKey);
			break;
	}
}

function SyncDataForEndPointInKey(syncType: any, endPoint: string, indexDbKey: string) {
	let parameters: GenericKeyPair[] = [];
	GetRequestWithAuthorization(endPoint, parameters)
		.then((res) => {
			if (res != undefined && res.status) {
				var reslt = res.Result;
				if (reslt != undefined) {
					let saveData = new GenericKeyPair(indexDbKey, reslt);
					setPersistedState(saveData);
					CheckAndSaveSyncType(syncType);
				}
			} else {
				console.log(res.ErrorMessage);
			}
		})
		.catch((ex) => {});
}

function FetchAppData(syncType: any) {
	let parameters: GenericKeyPair[] = [];
	GetRequestWithAuthorization(ApiEndPointConstants.AppInitialDataEndpoint, parameters)
		.then((res) => {
			if (res != undefined && res.status) {
				var reslt = res.Result;
				if (reslt != undefined) {
					//Saving States in IndexedDB
					if (reslt.ListOfStates != undefined && reslt.ListOfStates.length > 0) {
						let saveData = new GenericKeyPair(IndexDBConstants.StateListKey, reslt.ListOfStates);
						setPersistedState(saveData);
					}
					//Saving Countries in IndexedDB
					if (reslt.ListofCountry != undefined && reslt.ListofCountry.length > 0) {
						let saveData = new GenericKeyPair(IndexDBConstants.CountryListKey, reslt.ListofCountry);
						setPersistedState(saveData);
					}
					//Saving Offices in IndexedDB
					if (reslt.ListofOffices != undefined && reslt.ListofOffices.length > 0) {
						let saveData = new GenericKeyPair(IndexDBConstants.OfficeListKey, reslt.ListofOffices);
						setPersistedState(saveData);
					}
					//Saving Union Types in IndexedDB
					if (reslt.ListofUnionCode != undefined && reslt.ListofUnionCode.length > 0) {
						let saveData = new GenericKeyPair(IndexDBConstants.UnionCodeListKey, reslt.ListofUnionCode);
						setPersistedState(saveData);
					}
					//Saving Position Types
					if (reslt.ListOfRoles != undefined && reslt.ListOfRoles.length > 0) {
						let saveData = new GenericKeyPair(IndexDBConstants.PositionListKey, reslt.ListOfRoles);
						setPersistedState(saveData);
					}
					CheckAndSaveSyncType(syncType);
				}
			} else {
				console.log(res.ErrorMessage);
			}
		})
		.catch((ex) => {});
}

export function SyncPresentations(syncType: any) {
	let parameters: GenericKeyPair[] = [];
	GetRequestWithAuthorization(ApiEndPointConstants.SyncPresentationEndpoint, parameters)
		.then((res) => {
			if (res != undefined && res.status) {
				var reslt = res.Result;
				if (reslt != undefined) {
					// let saveData = new GenericKeyPair(
					//     "d",
					//     reslt
					// );
					// setPersistedState(saveData);
					// CheckAndSaveSyncType(syncType);
				}
			} else {
				console.log(res.ErrorMessage);
			}
		})
		.catch((ex) => {});
}

async function CheckAndSaveSyncType(syncType: any) {
	var existingSyncVer: any = await getPersistedState(IndexDBConstants.SyncVersionKey);
	if (existingSyncVer != undefined && existingSyncVer.length > 0) {
		var existingSyncCurrentType = existingSyncVer.filter((x: any) => {
			if (x.Name == syncType.Name) return x;
		});
		if (existingSyncCurrentType != undefined && existingSyncCurrentType.length > 0) {
			var saveSyncVer: any = [];
			existingSyncVer.forEach((element: any) => {
				if (element.Name == syncType.Name) saveSyncVer.push(syncType);
				else saveSyncVer.push(element);
			});
			SaveSyncType(saveSyncVer);
		} else {
			existingSyncVer.push(syncType);
			SaveSyncType(existingSyncVer);
		}
	} else {
		let syncVersion: any = [ syncType ];
		SaveSyncType(syncVersion);
	}
}

function SaveSyncType(syncVersion: any) {
	let saveData = new GenericKeyPair(IndexDBConstants.SyncVersionKey, syncVersion);
	setPersistedState(saveData);
}
