import { EntityHelper } from '../helpers/EntityHelper'
import { Model } from '@vuex-orm/core'


export default class Sponsership extends Model {
    static entity = 'sponsership'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            MemberCareerOption: this.boolean(false),
            FirstName: this.string(''),
            LastName: this.string(''),
            Relationship: this.string(''),
            City: this.string(''),
            Phone: this.string(''),
            Email: this.string(''),
        }
    }
    
    public Id!: any
    public MemberCareerOption!: boolean
    public FirstName!: string
    public LastName!: string
    public Relationship!: string
    public Phone!: string
    public City!: string
    public Email!: string
}