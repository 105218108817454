
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { PlanEntity } from "@/entities/PlanEntity";
import PlanSummary from "@/entities/PlanSummary";
import { PlanCategoryType } from "@/enumerations/PlanCategoryType";
import { PresentationType } from "@/enumerations/PresentationType";
import { Plan } from "@/models/Plan";
import PremiumPlan from "@/models/PremiumPlan";
import i18n from "@/plugins/i18n";
import { GetLocalDataOrCallAPI } from "./APIHelper";
import { EntityHelper } from "./EntityHelper";
import { ChangeDayOptionWithLanguage } from "./UIHelper";

export function CalculateBenefits(listofgeneralPlan: PlanEntity[], listofplans: PlanEntity[], planCategoryType: PlanCategoryType): PlanSummary {
    let tempSummary: PlanSummary = new PlanSummary();

    listofgeneralPlan.forEach((planItem) => {
        switch (planItem.PlanCode) {
            case "A71000":
                switch (planCategoryType) {
                    case PlanCategoryType.Primary:
                        tempSummary.EmergencyRoomBenfits = planItem.Coverage / 2;
                        tempSummary.DailyHospitalBenfits = planItem.Coverage;
                        tempSummary.IntensiveCareBenefits = planItem.Coverage * 2;
                        break;
                    case PlanCategoryType.Spouse:
                        if (planItem.SelectedOption != undefined && planItem.SelectedOption != "") {
                            var SelectedOption = JSON.parse(planItem.SelectedOption);
                            if (SelectedOption.id == 4 || SelectedOption.id == 5 || SelectedOption.id == 6) {
                                tempSummary.EmergencyRoomBenfits = planItem.Coverage / 2;
                                tempSummary.DailyHospitalBenfits = planItem.Coverage;
                                tempSummary.IntensiveCareBenefits = planItem.Coverage * 2;
                            }
                        }
                        break;
                    case PlanCategoryType.Children:
                        if (planItem.SelectedOption != undefined && planItem.SelectedOption != "") {
                            var SelectedOption = JSON.parse(planItem.SelectedOption);
                            if (SelectedOption.id == 4 || SelectedOption.id == 5 || SelectedOption.id == 6) {
                                tempSummary.EmergencyRoomBenfits = (planItem.Coverage / 2) / 2;
                                tempSummary.DailyHospitalBenfits = planItem.Coverage / 2;
                                tempSummary.IntensiveCareBenefits = (planItem.Coverage * 2) / 2;
                            }
                        }
                        break;
                }
                break;
        }
    });

    listofplans.forEach((planItem) => {

        switch (planItem.PlanCode) {
            case "WL":
                tempSummary.CauseOfDeathAmt = planItem.Coverage;
                tempSummary.AccidentAmt = planItem.Coverage * 2;
                tempSummary.AutoAccidentAmt = tempSummary.AccidentAmt * 2;
                tempSummary.CommonCarrier = planItem.Coverage * 10;

                break;
            case "10RC":
                tempSummary.hasRCPlan = true;
                tempSummary.MonthlyIncome = planItem.Coverage / 2;
                if (planItem.SelectedOption != undefined && planItem.SelectedOption != "") {
                    var SelectedOption = JSON.parse(planItem.SelectedOption);
                }
                tempSummary.TotalMonths = SelectedOption.id * 12;
                break;
            case "CR":
                if (planCategoryType == PlanCategoryType.Children) {
                    tempSummary.CauseOfDeathAmt = (planItem.Coverage * 2) / 3;
                    tempSummary.AccidentAmt = (planItem.Coverage * 2) / 5;
                    tempSummary.AutoAccidentAmt = (tempSummary.AccidentAmt * 2) / 5;
                    tempSummary.CommonCarrier = (planItem.Coverage * 10) / 5;
                }
                break;
            case "ADB":
                if (tempSummary.CauseOfDeathAmt == 0) {
                    tempSummary.CauseOfDeathAmt = planItem.Coverage;
                    tempSummary.AccidentAmt = planItem.Coverage * 2;
                    tempSummary.AutoAccidentAmt = tempSummary.AccidentAmt * 1.5;
                    tempSummary.CommonCarrier = planItem.Coverage * 10;
                }
                else {
                    tempSummary.AccidentAmt = tempSummary.AccidentAmt * 3.25;
                    tempSummary.AutoAccidentAmt = tempSummary.AutoAccidentAmt * 3.33;
                    tempSummary.CommonCarrier = tempSummary.CommonCarrier * 2;
                }
                break;
            case "CNN":
                break;
        }
    });

    if (planCategoryType == PlanCategoryType.Children) {
        tempSummary.CauseOfDeathAmt = tempSummary.CauseOfDeathAmt / 2;
        tempSummary.AccidentAmt = tempSummary.AccidentAmt / 2;
        tempSummary.AutoAccidentAmt = tempSummary.AutoAccidentAmt / 2;
        tempSummary.CommonCarrier = tempSummary.CommonCarrier / 2;
    }
    tempSummary.BeneficiaryAmount = (tempSummary.EmergencyRoomBenfits +
        tempSummary.DailyHospitalBenfits +
        tempSummary.IntensiveCareBenefits +
        tempSummary.CauseOfDeathAmt +
        tempSummary.AccidentAmt +
        tempSummary.AutoAccidentAmt +
        tempSummary.CommonCarrier) * 100;
    return tempSummary;
}

export function LoadPremiumAmountForPrinting(presentationPDF: any) {
    try {
        var monthlyMBD = presentationPDF.Presentation?.PremiumPlan?.UsedMonthlyPremium;
        if (monthlyMBD == undefined) monthlyMBD = 0;
        switch (presentationPDF.Presentation.PremiumTypeId) {
            case 1:
                presentationPDF.PremiumTypeDesc = "HOUR POWER";
                presentationPDF.PremiumAmountDesc = "Combined $" + (monthlyMBD / 4.333).toFixed(2) + " per Week";
                break;
            case 2:
                presentationPDF.PremiumTypeDesc = "MONTHLY";
                presentationPDF.PremiumAmountDesc = "Combined $" + monthlyMBD.toFixed(2) + " per Month";
                break;
            case 3:
                presentationPDF.PremiumTypeDesc = "DOLLAR A DAY";
                presentationPDF.PremiumAmountDesc = "";
                // var dollarADayAmountCombined = presentationPDF.Presentation.PresentedTo.DollarADayAmount;
                // if (presentationPDF.Presentation.isSpouseExistForCalculation)
                //     dollarADayAmountCombined += presentationPDF.Presentation.PresentedTo.Spouse.DollarADayAmount;
                presentationPDF.PremiumAmountDesc =
                    "Combined $" + (monthlyMBD / 30).toFixed(2) + " per Day";
                break;
            case 4:
                presentationPDF.PremiumTypeDesc = "NEED";
                presentationPDF.PremiumAmountDesc =
                    presentationPDF.Presentation.NeedAmount + "% of Annual Income";
                break;
        }
    }
    catch (ex: any) {

    }
}

export function LoadPrintPDFData(presentationPDF: any, bsRates: any, lstStates: any) {
    try {


        switch (presentationPDF.Presentation.PremiumTypeId) {
            case 1:
                presentationPDF.PremiumTypeDesc = "HOUR POWER";
                presentationPDF.PremiumAmountDesc = "Combined " + (+presentationPDF.Presentation.PresentedTo.Premium + + presentationPDF.Presentation.PresentedTo.Spouse.Premium) + " per Week";
                // if (presentationPDF.Presentation.PremiumPlan) {
                //     if (presentationPDF.Presentation.PremiumPlan.HPPremiumType == 1) {
                //         presentationPDF.PremiumAmountDesc = presentationPDF.PremiumAmountDesc.concat("COMBINED");
                //     } else {
                //         presentationPDF.PremiumAmountDesc = presentationPDF.PremiumAmountDesc.concat("CUSTOM");
                //     }
                // }
                // presentationPDF.PremiumAmountDesc = presentationPDF.PremiumAmountDesc.concat(
                //     " ",
                //     presentationPDF.Presentation.CombinedPremiumAmount + " per Week"
                // );
                break;
            case 2:
                presentationPDF.PremiumTypeDesc = "MONTHLY";
                presentationPDF.PremiumAmountDesc = "Combined " + (+presentationPDF.Presentation.PresentedTo.Premium + + presentationPDF.Presentation.PresentedTo.Spouse.Premium) + " per Month";

                // if (presentationPDF.Presentation.PremiumPlan) {
                //     if (presentationPDF.Presentation.PremiumPlan.MonthlyPremiumType == 1) {
                //         presentationPDF.PremiumAmountDesc = presentationPDF.PremiumAmountDesc.concat("COMBINED");
                //     } else {
                //         presentationPDF.PremiumAmountDesc = presentationPDF.PremiumAmountDesc.concat("CUSTOM");
                //     }
                // }
                // presentationPDF.PremiumAmountDesc = presentationPDF.PremiumAmountDesc.concat(
                //     " ",
                //     presentationPDF.Presentation.CombinedPremiumAmount + " per Month"
                // );
                break;
            case 3:
                presentationPDF.PremiumTypeDesc = "DOLLAR A DAY";
                presentationPDF.PremiumAmountDesc = "";
                var dollarADayAmountCombined = presentationPDF.Presentation.PresentedTo.DollarADayAmount + presentationPDF.Presentation.PresentedTo.Spouse.DollarADayAmount;

                presentationPDF.PremiumAmountDesc =
                    "Combined $" + dollarADayAmountCombined.toFixed(2) + " per Day";
                break;
            case 4:
                presentationPDF.PremiumTypeDesc = "NEED";
                presentationPDF.PremiumAmountDesc =
                    presentationPDF.Presentation.NeedAmount + " of Annual Income";
                break;
        }
    }
    catch (ex: any) {

    }
    if (presentationPDF.Presentation.PresentedTo.ChildrenString != undefined && presentationPDF.Presentation.PresentedTo.ChildrenString != "")
        presentationPDF.Childrens = JSON.parse(presentationPDF.Presentation.PresentedTo.ChildrenString);

    if (presentationPDF.Presentation.PlanOptions != undefined && presentationPDF.Presentation.PlanOptions != "") {

        var planOptions = JSON.parse(presentationPDF.Presentation.PlanOptions);
        var plan = planOptions[0];
        if (presentationPDF.Presentation.CurrentPlan) {
            plan = planOptions.find(
                (x: any) => x.Name == presentationPDF.Presentation.CurrentPlan
            )
        }
        LoadBenefitSummaryAsPerPlan(bsRates, lstStates, presentationPDF.BenefitSummary, plan, presentationPDF.Presentation, presentationPDF.Presentation.PresentedTo, "");
    }

    var tempPersonAnnualIncome = 0;
    if (typeof presentationPDF.Presentation.PresentedTo.Wage == "string")
        tempPersonAnnualIncome = presentationPDF.Presentation.PresentedTo.Wage.replaceAll(",", "");
    else
        tempPersonAnnualIncome = presentationPDF.Presentation.PresentedTo.Wage;

    var tempSpouseAnnualIncome = 0;
    if (typeof presentationPDF.Presentation.PresentedTo.Spouse.Wage == "string")
        tempSpouseAnnualIncome = presentationPDF.Presentation.PresentedTo.Spouse.Wage.replaceAll(
            ",",
            ""
        );
    else
        tempPersonAnnualIncome = presentationPDF.Presentation.PresentedTo.Spouse.Wage;

    var tempincome = Number(
        (+tempPersonAnnualIncome + +tempSpouseAnnualIncome).toFixed()
    );

    presentationPDF.AnnualIncome = ConvertIntoAmountFormat(tempincome.toFixed());

    var tempRecommendedLifeIns = tempincome * 10;
    presentationPDF.RecommendedLifeIns = ConvertIntoAmountFormat(
        tempRecommendedLifeIns.toFixed()
    );

    var tempExistingLifeIns = Number(
        (
            +(presentationPDF.Presentation.PresentedTo.InsuranceThroughWorkWholeLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.InsuranceThroughWorkWholeLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.InsuranceThroughWorkTermLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.InsuranceThroughWorkTermLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.InsuranceOutsideWorkWholeLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.InsuranceOutsideWorkWholeLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.InsuranceOutsideWorkTermLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.InsuranceOutsideWorkTermLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.Spouse.InsuranceThroughWorkWholeLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.Spouse.InsuranceThroughWorkWholeLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.Spouse.InsuranceThroughWorkTermLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.Spouse.InsuranceThroughWorkTermLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkWholeLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0) +
            +(presentationPDF.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkTermLife != undefined
                ? Number.parseFloat(
                    presentationPDF.Presentation.PresentedTo.Spouse.InsuranceOutsideWorkTermLife.toString().replaceAll(
                        ",",
                        ""
                    )
                )
                : 0)
        ).toFixed()
    );

    presentationPDF.ExistingLifetIns = ConvertIntoAmountFormat(
        tempExistingLifeIns.toFixed()
    );

    var tempRemainingIns = +tempRecommendedLifeIns - +tempExistingLifeIns;

    presentationPDF.RemainingLifeIns = ConvertIntoAmountFormat(tempRemainingIns.toFixed());
}

export function LoadPlansForFinishPresentation(plans: any) {
    plans.forEach((plan: any) => {
        var ahpProducts = [];
        var alpProducts = [];
        var products = null;
        products = plan.Products.filter((x: any) => x.Selected == true && x.ProductAvailable != false);

        if (products && products.length > 0) {
            ahpProducts.push(...products)
        }

        products = null;
        products = plan.AdditionalProduct.PrimaryProducts.AHPProduct.filter((x: any) => x.Selected == true);
        if (products && products.length > 0) {
            ahpProducts.push(...products)
        }

        products = null;
        products = plan.AdditionalProduct.SpouseProducts.AHPProduct.filter((x: any) => x.Selected == true);
        if (products && products.length > 0) {
            ahpProducts.push(...products)
        }

        products = null;
        products = plan.PrimaryProducts.filter((x: any) => x.Selected == true);

        if (products && products.length > 0) {
            alpProducts.push(...products)
        }

        products = null;
        products = plan.SpouseProducts.filter((x: any) => x.Selected == true);

        if (products && products.length > 0) {
            alpProducts.push(...products)
        }

        products = null;
        products = plan.AdditionalProduct.PrimaryProducts.ALPProduct.filter((x: any) => x.Selected == true);
        if (products && products.length > 0) {
            alpProducts.push(...products)
        }

        products = null;
        products = plan.AdditionalProduct.SpouseProducts.ALPProduct.filter((x: any) => x.Selected == true);
        if (products && products.length > 0) {
            alpProducts.push(...products)
        }

        plan.TotalAHP = 0.00;
        plan.TotalALP = 0.00;

        if (ahpProducts && ahpProducts.length > 0) {
            ahpProducts.forEach((prod: any) => {
                plan.TotalAHP += Number(prod.MBD);
            });
        }

        if (alpProducts && alpProducts.length > 0) {
            alpProducts.forEach((prod: any) => {
                plan.TotalALP += Number(prod.MBD);
            });
        }
        plan.TotalAHP = ConvertIntoAmountFormatWithDecimal((plan.TotalAHP * 12).toFixed(2), true, true);
        plan.TotalALP = ConvertIntoAmountFormatWithDecimal((plan.TotalALP * 12).toFixed(2), true, true);
    });
}

export function LoadBenefitSummaryAsPerPlan(bsRates: any, statesList: any, benefitSummary: any, plan: any, presentation: any, person: any, emptyString: string = "--", isPresentPlan: boolean = false) {
    if (plan == undefined)
    return {};
    
    var protections = JSON.parse(presentation.Protections);
    benefitSummary.IsVeteran = presentation.PresentationTypeId == PresentationType.Veteran || presentation.PresentationTypeId == PresentationType.ServiceMemberBenefits;
    benefitSummary.UsedDailyPremium = "0.00";
    benefitSummary.UsedMonthlyPremium = "0.00";
    benefitSummary.UserWeeklyPremium = "0.00";
    if (plan != undefined && plan.PremiumPlan) {
        benefitSummary.UsedDailyPremium = ConvertIntoAmountFormatWithDecimal(plan.PremiumPlan.usedDailyPremium.toFixed(2), true, true);
        benefitSummary.UsedMonthlyPremium = ConvertIntoAmountFormatWithDecimal(plan.PremiumPlan.usedMonthlyPremium.toFixed(2), true, true);
        benefitSummary.UsedYearlyPremium = ConvertIntoAmountFormatWithDecimal((plan.PremiumPlan.usedMonthlyPremium * 12).toFixed(2), true, true);
        benefitSummary.UsedWeeklyPremium = ConvertIntoAmountFormatWithDecimal(plan.PremiumPlan.usedWeeklyPremium.toFixed(2), true, true);
    }


    benefitSummary.BeneficiaryAmount = 0;
    var whlPrimaryProduct = plan.PrimaryProducts.find((x: any) => x.IsWHL == true && x.Selected == true && x.ProductAvailable != false);
    var whlSpouseProduct = person.isSpouseExistForBS ? plan.SpouseProducts.find((x: any) => x.IsWHL == true && x.Selected == true && x.ProductAvailable != false) : undefined;
    var childRider = plan.PrimaryProducts.find((x: any) => x.ProductId == 13 && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.DeathBenefits = [];
    benefitSummary.ShowChildren = person.HasChildrenUnder18;

    benefitSummary.ShowFreedomOfChoice = whlPrimaryProduct || whlSpouseProduct;

    benefitSummary.PrimaryProducts = [];
    benefitSummary.SpouseProducts = [];

    var products = plan.Products.filter((x: any) => x.Selected == true && x.ProductAvailable != false);
    if (products && products.length > 0) {
        benefitSummary.PrimaryProducts.push(...products);
    }

    var primarySelected = plan.PrimaryProducts.filter((x: any) => x.Selected == true && x.ProductAvailable != false);
    if (primarySelected && primarySelected.length > 0) {
        benefitSummary.PrimaryProducts.push(...primarySelected);
    }

    var primaryALPSelected = plan.AdditionalProduct.PrimaryProducts.ALPProduct.filter((x: any) => x.Selected == true && x.ProductAvailable != false);
    if (primaryALPSelected && primaryALPSelected.length > 0) {
        benefitSummary.PrimaryProducts.push(...primaryALPSelected);
    }

    var primaryAHPSelected = plan.AdditionalProduct.PrimaryProducts.AHPProduct.filter((x: any) => x.Selected == true && x.ProductAvailable != false);
    if (primaryAHPSelected && primaryAHPSelected.length > 0) {
        benefitSummary.PrimaryProducts.push(...primaryAHPSelected);
    }
    var spouseSelected = person.isSpouseExistForBS ? plan.SpouseProducts.filter((x: any) => x.Selected == true && x.ProductAvailable != false) : undefined;
    if (spouseSelected && spouseSelected.length > 0) {
        benefitSummary.SpouseProducts.push(...spouseSelected);
    }

    var spouseALPSelected = plan.AdditionalProduct.SpouseProducts.ALPProduct.filter((x: any) => x.Selected == true && x.ProductAvailable != false);
    if (spouseALPSelected && spouseALPSelected.length > 0) {
        benefitSummary.SpouseProducts.push(...spouseALPSelected);
    }

    var spouseAHPSelected = plan.AdditionalProduct.SpouseProducts.AHPProduct.filter((x: any) => x.Selected == true && x.ProductAvailable != false);
    if (spouseAHPSelected && spouseAHPSelected.length > 0) {
        benefitSummary.SpouseProducts.push(...spouseAHPSelected);
    }

    if (benefitSummary.PrimaryProducts && benefitSummary.PrimaryProducts.length > 0) {
        benefitSummary.PrimaryProducts.forEach((prod: any) => {
            if (!prod.Plan.CompleteCoverageOption && prod.Coverage) {
                prod.Coverage = ConvertIntoAmountFormat(prod.Coverage.toString().replaceAll(',', ''))
            }
            prod.MBD = ConvertIntoAmountFormatWithDecimal(Number(prod.MBD.toString().replaceAll(',', '')).toFixed(2), true, true);
        });
    }

    if (benefitSummary.SpouseProducts && benefitSummary.SpouseProducts.length > 0) {

        benefitSummary.SpouseProducts.forEach((prod: any) => {
            if (!prod.Plan.CompleteCoverageOption && prod.Coverage) {
                prod.Coverage = ConvertIntoAmountFormat(prod.Coverage.toString().replaceAll(',', ''))
            }
            prod.MBD = ConvertIntoAmountFormatWithDecimal(Number(prod.MBD.toString().replaceAll(',', '')).toFixed(2), true, true);
        });
    }


    var rcMonthlyProduct = plan.PrimaryProducts.find((x: any) => x.ProductId == 6 && x.Plan.PresentAs == "MONTHLY INCOME" && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.rcMonthlyProduct = rcMonthlyProduct;
    var rcHouseProduct = plan.PrimaryProducts.find((x: any) => x.ProductId == 6 && x.Plan.PresentAs == "HOUSE PAYMENT RIDER" && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.rcHouseProduct = rcHouseProduct;

    var rcDeathBenefitPrimary = plan.PrimaryProducts.filter((x: any) => x.ProductId == 6 && x.Selected == true && x.Plan.PresentAs != "MONTHLY INCOME" && x.Plan.PresentAs != "HOUSE PAYMENT RIDER" && x.ProductAvailable != false);

    if (rcDeathBenefitPrimary && rcDeathBenefitPrimary.length > 0) {
        rcDeathBenefitPrimary.forEach((rcProd: any) => {
            var deathBenefit = {
                Title: rcProd.Plan.PresentAs,
                Name: person.GetNameHolder,
                Coverage: ConvertIntoAmountFormatWithDecimal(Number(rcProd.Coverage.toString().replaceAll(',', '')).toFixed(2).toString()),
                isADB: false
            };
            benefitSummary.DeathBenefits.push(deathBenefit);
        });
    }

    var rcDeathBenefitSpouse = plan.SpouseProducts.filter((x: any) => x.ProductId == 6 && x.Selected == true && x.Plan.PresentAs != "MONTHLY INCOME" && x.Plan.PresentAs != "HOUSE PAYMENT RIDER" && x.ProductAvailable != false);
    var rcDeathBenefitSpouseAdditional = plan.AdditionalProduct.SpouseProducts.ALPProduct.find((x: any) => x.ProductId == 6 && x.Selected == true && x.Plan.PresentAs != "MONTHLY INCOME" && x.Plan.PresentAs != "HOUSE PAYMENT RIDER" && x.ProductAvailable != false);
    if (rcDeathBenefitSpouseAdditional) {
        rcDeathBenefitSpouse.push(rcDeathBenefitSpouseAdditional);
    }
    if (rcDeathBenefitSpouse && rcDeathBenefitSpouse.length > 0) {
        rcDeathBenefitSpouse.forEach((rcProd: any) => {
            var deathBenefit = {
                Title: rcProd.Plan.PresentAs,
                Name: person.Spouse.GetNameHolder,
                IsSpouse: true,
                Coverage: ConvertIntoAmountFormatWithDecimal(Number(rcProd.Coverage.toString().replaceAll(',', '')).toFixed(2).toString()),
                isADB: false
            };
            benefitSummary.DeathBenefits.push(deathBenefit);
        });
    }

    var adbPrimaryMonthly = plan.PrimaryProducts.find((x: any) => x.ProductId == 14 && x.Plan.PresentAs == "MONTHLY INCOME" && x.Selected == true && x.ProductAvailable != false);
    var adbSpouseMonthly = plan.SpouseProducts.find((x: any) => x.ProductId == 14 && x.Plan.PresentAs == "MONTHLY INCOME" && x.Selected == true && x.ProductAvailable != false);
    var adbPrimaryHouse = plan.PrimaryProducts.find((x: any) => x.ProductId == 14 && x.Plan.PresentAs == "HOUSE PAYMENT RIDER" && x.Selected == true && x.ProductAvailable != false);
    var adbSpouseHouse = plan.SpouseProducts.find((x: any) => x.ProductId == 14 && x.Plan.PresentAs == "HOUSE PAYMENT RIDER" && x.Selected == true && x.ProductAvailable != false);

    benefitSummary.adbPrimaryMonthly = adbPrimaryMonthly;
    benefitSummary.adbSpouseMonthly = adbSpouseMonthly;
    benefitSummary.adbPrimaryHouse = adbPrimaryHouse;
    benefitSummary.adbSpouseHouse = adbSpouseHouse;

    var rcMonthlySpouseProduct = plan.SpouseProducts.find((x: any) => x.ProductId == 6 && x.Plan.PresentAs == "MONTHLY INCOME" && x.Selected == true && x.ProductAvailable != false) || plan.AdditionalProduct.SpouseProducts.ALPProduct.find((x: any) => x.ProductId == 6 && x.Plan.PresentAs == "MONTHLY INCOME" && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.rcMonthlySpouseProduct = rcMonthlySpouseProduct;

    var spouseTemmProduct = plan.SpouseProducts.find((x: any) => x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false) || plan.AdditionalProduct.SpouseProducts.ALPProduct.find((x: any) => x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false);

    var rcHouseSpouseProduct = plan.SpouseProducts.find((x: any) => x.ProductId == 6 && x.Plan.PresentAs == "HOUSE PAYMENT RIDER" && x.Selected == true && x.ProductAvailable != false) || plan.AdditionalProduct.SpouseProducts.ALPProduct.find((x: any) => x.ProductId == 6 && x.Plan.PresentAs == "HOUSE PAYMENT RIDER" && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.rcHouseSpouseProduct = rcHouseSpouseProduct;

    benefitSummary.ShowRCAmount = rcMonthlyProduct || adbPrimaryMonthly || rcMonthlySpouseProduct || adbSpouseMonthly || rcHouseProduct || adbPrimaryHouse || rcHouseSpouseProduct || adbSpouseHouse;
    benefitSummary.ShowHousePaymentRider = (rcMonthlyProduct || rcMonthlySpouseProduct || adbPrimaryMonthly || adbSpouseMonthly) && (rcHouseProduct || rcHouseSpouseProduct || adbPrimaryHouse || adbSpouseHouse);

    benefitSummary.RCAmount = 0;
    benefitSummary.RCAmountADB = 0;
    benefitSummary.PrimaryRCAmount = emptyString;
    benefitSummary.PrimaryRCAmountADB = emptyString;
    benefitSummary.PrimaryRCTenure = "";
    benefitSummary.PrimaryRCTenureADB = "";
    benefitSummary.RCHeading = i18n.t('BenefitsSummary.MonthlyIncome');
    benefitSummary.ShowADBRow = false;
    benefitSummary.ShowUnemploymentRow = false;
    benefitSummary.ShowRCSection = false;

    var rcAmountTenureOptions = [];
    var rcAmountTenureOptionsADB = [];

    benefitSummary.ShowFuneralBenefitOnly = !rcMonthlyProduct && !rcHouseProduct && !rcHouseSpouseProduct && !spouseTemmProduct;
    benefitSummary.ShowMonthltInFreedomOfChoice = rcMonthlyProduct;
    benefitSummary.ShowHouseInFreedomOfChoice = rcHouseProduct;
    benefitSummary.PrimaryCheques = [];
    benefitSummary.SpouseCheques = [];

    if (rcMonthlyProduct || rcMonthlySpouseProduct || adbPrimaryMonthly || adbSpouseMonthly) {
        benefitSummary.ShowRCSection = true;
        benefitSummary.RCHeading = i18n.t('BenefitsSummary.MonthlyIncome');

        if (rcMonthlyProduct || adbPrimaryMonthly) {

            if (rcMonthlyProduct) {
                var monthlyWage = Number(rcMonthlyProduct.Coverage.toString().replaceAll(',', '')) / rcMonthlyProduct.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmount += monthlyWage;
                benefitSummary.PrimaryRCAmount = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false);
                benefitSummary.RCAmountTenure = ChangeDayOptionWithLanguage(rcMonthlyProduct.Plan.CompleteOption.Name);
                rcAmountTenureOptions.push(rcMonthlyProduct.Plan.CompleteOption);
                benefitSummary.PrimaryRCTenure = ChangeDayOptionWithLanguage(rcMonthlyProduct.Plan.CompleteOption.Name);
                //for (var i = 1; i <= rcMonthlyProduct.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    NoOfMonth: rcMonthlyProduct.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.PrimaryCheques.push(cheque);
                benefitSummary.ShowUnemploymentRow = true;

                //}
            }
            if (adbPrimaryMonthly) {
                var monthlyWage = Number(adbPrimaryMonthly.Coverage.toString().replaceAll(',', '')) / adbPrimaryMonthly.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmountADB += monthlyWage;
                benefitSummary.PrimaryRCAmountADB = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false);
                benefitSummary.RCAmountTenureADB = ChangeDayOptionWithLanguage(adbPrimaryMonthly.Plan.CompleteOption.Name);
                rcAmountTenureOptionsADB.push(adbPrimaryMonthly.Plan.CompleteOption);
                benefitSummary.PrimaryRCTenureADB = ChangeDayOptionWithLanguage(adbPrimaryMonthly.Plan.CompleteOption.Name);
                //for (var i = 1; i <= adbPrimaryMonthly.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque2 = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    IsADB: true,
                    NoOfMonth: adbPrimaryMonthly.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque2.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.PrimaryCheques.push(cheque2);
                benefitSummary.ShowADBRow = true;

                //}
            }
        }

        benefitSummary.SpouseRCAmount = emptyString;
        benefitSummary.SpouseRCAmountADB = emptyString;
        benefitSummary.SpouseRCTenure = "";
        benefitSummary.SpouseRCTenureADB = "";

        if (rcMonthlySpouseProduct || adbSpouseMonthly) {

            if (rcMonthlySpouseProduct && rcMonthlySpouseProduct.Plan.CompleteOption != undefined) {
                var monthlyWage = Number(rcMonthlySpouseProduct.Coverage.toString().replaceAll(',', '')) / rcMonthlySpouseProduct.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmount += monthlyWage;
                benefitSummary.SpouseRCAmount = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false)
                benefitSummary.RCAmountTenure = ChangeDayOptionWithLanguage(rcMonthlySpouseProduct.Plan.CompleteOption.Name);
                rcAmountTenureOptions.push(rcMonthlySpouseProduct.Plan.CompleteOption);
                benefitSummary.SpouseRCTenure = ChangeDayOptionWithLanguage(rcMonthlySpouseProduct.Plan.CompleteOption.Name);
                //for (var i = 1; i <= rcMonthlySpouseProduct.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    NoOfMonth: rcMonthlySpouseProduct.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.SpouseCheques.push(cheque);
                benefitSummary.ShowUnemploymentRow = true;

                //}
            }

            if (adbSpouseMonthly) {
                var monthlyWage = Number(adbSpouseMonthly.Coverage.toString().replaceAll(',', '')) / adbSpouseMonthly.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmountADB += monthlyWage;
                benefitSummary.SpouseRCAmountADB = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false)
                benefitSummary.RCAmountTenureADB = ChangeDayOptionWithLanguage(adbSpouseMonthly.Plan.CompleteOption.Name);
                rcAmountTenureOptionsADB.push(adbSpouseMonthly.Plan.CompleteOption);
                benefitSummary.SpouseRCTenureADB = ChangeDayOptionWithLanguage(adbSpouseMonthly.Plan.CompleteOption.Name);
                //for (var i = 1; i <= adbSpouseMonthly.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque2 = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    IsADB: true,
                    NoOfMonth: adbSpouseMonthly.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque2.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.SpouseCheques.push(cheque2);
                //}
                benefitSummary.ShowADBRow = true;

            }
        }
    }
    else if ((rcHouseProduct || adbPrimaryHouse || rcHouseSpouseProduct || adbSpouseHouse) && !benefitSummary.ShowHousePaymentRider) {
        benefitSummary.ShowRCSection = true;
        benefitSummary.RCHeading = i18n.t("BenefitsSummary.HomePayment");
        if (rcHouseProduct || adbPrimaryHouse) {
            if (rcHouseProduct) {
                var monthlyWage = Number(rcHouseProduct.Coverage.toString().replaceAll(',', '')) / rcHouseProduct.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmount += monthlyWage;
                benefitSummary.PrimaryRCAmount = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false);
                benefitSummary.RCAmountTenure = ChangeDayOptionWithLanguage(rcHouseProduct.Plan.CompleteOption.Name);
                rcAmountTenureOptions.push(rcHouseProduct.Plan.CompleteOption);
                benefitSummary.PrimaryRCTenure = ChangeDayOptionWithLanguage(rcHouseProduct.Plan.CompleteOption.Name);
                //for (var i = 1; i <= rcHouseProduct.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    NoOfMonth: rcHouseProduct.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.PrimaryCheques.push(cheque);
                benefitSummary.ShowUnemploymentRow = true;

                //}
            }
            if (adbPrimaryHouse) {

                var monthlyWage = Number(adbPrimaryHouse.Coverage.toString().replaceAll(',', '')) / adbPrimaryHouse.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmountADB += monthlyWage;
                benefitSummary.PrimaryRCAmountADB = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false);
                benefitSummary.RCAmountTenureADB = ChangeDayOptionWithLanguage(adbPrimaryHouse.Plan.CompleteOption.Name);
                rcAmountTenureOptionsADB.push(adbPrimaryHouse.Plan.CompleteOption);
                benefitSummary.PrimaryRCTenureADB = ChangeDayOptionWithLanguage(adbPrimaryHouse.Plan.CompleteOption.Name);
                //for (var i = 1; i <= adbPrimaryHouse.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque2 = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    IsADB: true,
                    NoOfMonth: adbPrimaryHouse.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque2.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.PrimaryCheques.push(cheque2);
                benefitSummary.ShowADBRow = true;
                //}
            }
        }
        if (rcHouseSpouseProduct || adbSpouseHouse) {

            if (rcHouseSpouseProduct && rcHouseSpouseProduct.Plan.CompleteOption != undefined) {
                var monthlyWage = Number(rcHouseSpouseProduct.Coverage.toString().replaceAll(',', '')) / rcHouseSpouseProduct.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmount += monthlyWage;
                benefitSummary.SpouseRCAmount = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false)
                benefitSummary.RCAmountTenure = ChangeDayOptionWithLanguage(rcHouseSpouseProduct.Plan.CompleteOption.Name);
                rcAmountTenureOptions.push(rcHouseSpouseProduct.Plan.CompleteOption);
                benefitSummary.SpouseRCTenure = ChangeDayOptionWithLanguage(rcHouseSpouseProduct.Plan.CompleteOption.Name);
                //for (var i = 1; i <= rcHouseSpouseProduct.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    NoOfMonth: rcHouseSpouseProduct.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.SpouseCheques.push(cheque);
                benefitSummary.ShowUnemploymentRow = true;

                //}
            }

            if (adbSpouseHouse) {
                var monthlyWage = Number(adbSpouseHouse.Coverage.toString().replaceAll(',', '')) / adbSpouseHouse.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmountADB += monthlyWage;
                benefitSummary.SpouseRCAmountADB = ConvertIntoAmountFormatWithDecimal(monthlyWage.toFixed(2), true, false)
                benefitSummary.RCAmountTenureADB = ChangeDayOptionWithLanguage(adbSpouseHouse.Plan.CompleteOption.Name);
                rcAmountTenureOptionsADB.push(adbSpouseHouse.Plan.CompleteOption);
                benefitSummary.SpouseRCTenureADB = ChangeDayOptionWithLanguage(adbSpouseHouse.Plan.CompleteOption.Name);
                //for (var i = 1; i <= adbSpouseHouse.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque2 = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    IsADB: true,
                    NoOfMonth: adbSpouseHouse.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque2.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.SpouseCheques.push(cheque2);
                benefitSummary.ShowADBRow = true;
                //}
            }
        }
    }
    benefitSummary.PrimaryHouseCheques = [];
    benefitSummary.SpouseHouseCheques = [];
    if (benefitSummary.ShowHousePaymentRider) {
        benefitSummary.RCHouseHeading = i18n.t("BenefitsSummary.HomePayment");

        if (rcHouseProduct || adbPrimaryHouse) {
            benefitSummary.ShowRCSection = true;
            if (rcHouseProduct) {
                var monthlyWage = Number(rcHouseProduct.Coverage.toString().replaceAll(',', '')) / rcHouseProduct.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmount += monthlyWage;
                rcAmountTenureOptions.push(rcHouseProduct.Plan.CompleteOption);
                //for (var i = 1; i <= rcHouseProduct.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    NoOfMonth: rcHouseProduct.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.PrimaryHouseCheques.push(cheque);
                //}
            }

            if (adbPrimaryHouse) {
                benefitSummary.ShowRCSection = true;
                var monthlyWage = Number(adbPrimaryHouse.Coverage.toString().replaceAll(',', '')) / adbPrimaryHouse.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmountADB += monthlyWage;
                rcAmountTenureOptionsADB.push(adbPrimaryHouse.Plan.CompleteOption);
                for (var i = 1; i <= adbPrimaryHouse.Plan.CompleteOption.NoOfMonths; i++) {

                    var cheque2 = {
                        ChequeAmount: '',
                        ChequeAmountInWords: '',
                        IsADB: true,
                        NoOfMonth: adbPrimaryHouse.Plan.CompleteOption.NoOfMonths
                    };

                    var chequeAmountNumber =
                        monthlyWage;
                    if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                    cheque2.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                        chequeAmountNumber.toFixed(2)
                    );
                    var converter = require("number-to-words");
                    var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                    var decimalValueStr = (decimalValue * 100).toFixed();

                    if (Number.parseInt(decimalValueStr) > 0) {
                        cheque2.ChequeAmountInWords =
                            EntityHelper.capitalizeTheFirstLetterOfEachWord(
                                converter.toWords(chequeAmountNumber)
                            ) +
                            " and " +
                            decimalValueStr +
                            "/100";
                    } else {
                        cheque2.ChequeAmountInWords =
                            EntityHelper.capitalizeTheFirstLetterOfEachWord(
                                converter.toWords(chequeAmountNumber)
                            ) + " only";
                    }
                    benefitSummary.PrimaryHouseCheques.push(cheque2);
                }
            }
        }
        if (rcHouseSpouseProduct || adbSpouseHouse) {
            benefitSummary.ShowRCSection = true;
            if (rcHouseSpouseProduct && rcHouseSpouseProduct.Plan.CompleteOption != undefined) {
                var monthlyWage = Number(rcHouseSpouseProduct.Coverage.toString().replaceAll(',', '')) / rcHouseSpouseProduct.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmount += monthlyWage;
                rcAmountTenureOptions.push(rcHouseSpouseProduct.Plan.CompleteOption);
                //for (var i = 1; i <= rcHouseSpouseProduct.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    NoOfMonth: rcHouseSpouseProduct.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.SpouseHouseCheques.push(cheque);
                //}
            }

            if (adbSpouseHouse) {
                benefitSummary.ShowRCSection = true;
                var monthlyWage = Number(adbSpouseHouse.Coverage.toString().replaceAll(',', '')) / adbSpouseHouse.Plan.CompleteOption.NoOfMonths;
                benefitSummary.RCAmountADB += monthlyWage;
                rcAmountTenureOptionsADB.push(adbSpouseHouse.Plan.CompleteOption);

                //for (var i = 1; i <= adbSpouseHouse.Plan.CompleteOption.NoOfMonths; i++) {

                var cheque2 = {
                    ChequeAmount: '',
                    ChequeAmountInWords: '',
                    IsADB: true,
                    NoOfMonth: adbSpouseHouse.Plan.CompleteOption.NoOfMonths
                };

                var chequeAmountNumber =
                    monthlyWage;
                if (isNaN(chequeAmountNumber)) chequeAmountNumber = 0;
                cheque2.ChequeAmount = ConvertIntoAmountFormatWithDecimal(
                    chequeAmountNumber.toFixed(2)
                );
                var converter = require("number-to-words");
                var decimalValue = chequeAmountNumber - Math.floor(chequeAmountNumber);
                var decimalValueStr = (decimalValue * 100).toFixed();

                if (Number.parseInt(decimalValueStr) > 0) {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) +
                        " and " +
                        decimalValueStr +
                        "/100";
                } else {
                    cheque2.ChequeAmountInWords =
                        EntityHelper.capitalizeTheFirstLetterOfEachWord(
                            converter.toWords(chequeAmountNumber)
                        ) + " only";
                }
                benefitSummary.SpouseHouseCheques.push(cheque2);
                //}
            }
        }
    }


    benefitSummary.RCAmount = ConvertIntoAmountFormatWithDecimal(benefitSummary.RCAmount.toFixed(2).toString());
    benefitSummary.RCAmountADB = ConvertIntoAmountFormatWithDecimal(benefitSummary.RCAmountADB.toFixed(2).toString());
    var leastTenure = { Name: "" };
    if (rcAmountTenureOptions && rcAmountTenureOptions.length > 0)
        leastTenure = rcAmountTenureOptions.reduce(function (prev, curr) {
            return prev.Id < curr.Id ? prev : curr;
        });

    benefitSummary.RCAmountTenure = ChangeDayOptionWithLanguage(leastTenure?.Name);
    leastTenure = { Name: "" };
    if (rcAmountTenureOptionsADB && rcAmountTenureOptionsADB.length > 0)
        leastTenure = rcAmountTenureOptionsADB.reduce(function (prev, curr) {
            return prev.Id < curr.Id ? prev : curr;
        });
    benefitSummary.RCAmountTenureADB = ChangeDayOptionWithLanguage(leastTenure?.Name);
    var hospProduct = plan.Products.find((x: any) => x.Selected == true && x.ProductAvailable != false);
    benefitSummary.ShowHospitalBenefits = true;
    if (!hospProduct) {
        benefitSummary.ShowHospitalBenefits = false;
    }
    benefitSummary.ShowFreedomOfChoice = benefitSummary.ShowFreedomOfChoice || hospProduct;
    var spouseRiderAdded = plan.AdditionalProduct.PrimaryProducts.ALPProduct.find((x: any) => x.ProductId == 12 && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.ShowSpouse = spouseRiderAdded || whlSpouseProduct || spouseTemmProduct || rcHouseSpouseProduct || rcDeathBenefitSpouse.length > 0;
    benefitSummary.focanydeathcausePrimary = 0;
    benefitSummary.focanydeathcauseSpouse = 0;
    benefitSummary.focanydeathcauseChildren = 0;
    benefitSummary.focaccidentalPrimary = 0;
    benefitSummary.focaccidentalSpouse = 0;
    benefitSummary.focaccidentalChildren = 0;
    benefitSummary.focautoPrimary = 0;
    benefitSummary.focautoSpouse = 0;
    benefitSummary.focautoChildren = 0;
    benefitSummary.foccommmoncarrierPrimary = 0;
    benefitSummary.foccommmoncarrierSpouse = 0;
    benefitSummary.foccommmoncarrierChildren = 0;
    var rcPrimaryMonthlyProduct = plan.PrimaryProducts.find((x: any) => x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false);
    var adbPrimary = plan.PrimaryProducts.find((x: any) => x.ProductId == 14 && x.Selected == true && x.ProductAvailable != false);
    var adbSpouse = plan.SpouseProducts.find((x: any) => x.ProductId == 14 && x.Selected == true && x.ProductAvailable != false);

    var presState = statesList.find((x: any) => x.Name == presentation.State)?.StateCode;
    LoadA7100CalculationsInBenefitSummary(bsRates, benefitSummary, hospProduct, presState, emptyString);
    if (whlPrimaryProduct) {
        benefitSummary.focanydeathcausePrimary = Number(whlPrimaryProduct.Coverage.toString().replaceAll(',', ''));
    }

    if (whlSpouseProduct) {
        benefitSummary.focanydeathcauseSpouse = Number(whlSpouseProduct.Coverage.toString().replaceAll(',', ''));
    }

    if (spouseRiderAdded) {
        benefitSummary.focanydeathcauseSpouse = Number(spouseRiderAdded.Coverage.toString().replaceAll(',', ''));
    }

    if (childRider) {
        benefitSummary.focanydeathcauseChildren = Number(childRider.Coverage.toString().replaceAll(',', ''));
    }




    if (adbPrimary) {
        if (adbPrimary.Plan.PresentAs == "WITH A71000" || adbPrimary.Plan.PresentAs == "AS ACCIDENTAL DEATH BENEFIT") {
            benefitSummary.ShowFreedomOfChoice = benefitSummary.ShowFreedomOfChoice || adbPrimary;
            benefitSummary.focaccidentalPrimary += Number(adbPrimary.Coverage.toString().replaceAll(',', ''));
            benefitSummary.focautoPrimary += Number(adbPrimary.Coverage.toString().replaceAll(',', ''));
            benefitSummary.foccommmoncarrierPrimary += Number(adbPrimary.Coverage.toString().replaceAll(',', ''));
        }
        else if (adbPrimary.Plan.PresentAs == "MORTGAGE PROTECTION" || adbPrimary.Plan.PresentAs == "CREDIT CARD DEBT") {
            var deathBenefitadb = {
                Title: adbPrimary.Plan.PresentAs + " ",
                Name: person.GetNameHolder,
                Coverage: ConvertIntoAmountFormatWithDecimal(Number(adbPrimary.Coverage.toString().replaceAll(',', '')).toFixed(2).toString()),
                isADB: true
            };
            benefitSummary.DeathBenefits.push(deathBenefitadb);
        }
    }

    if (adbSpouse) {
        if (adbSpouse.Plan.PresentAs == "WITH A71000" || adbSpouse.Plan.PresentAs == "AS ACCIDENTAL DEATH BENEFIT") {
            benefitSummary.ShowFreedomOfChoice = benefitSummary.ShowFreedomOfChoice || adbSpouse;
            benefitSummary.focaccidentalSpouse += Number(adbSpouse.Coverage.toString().replaceAll(',', ''));
            benefitSummary.focautoSpouse += Number(adbSpouse.Coverage.toString().replaceAll(',', ''));
            benefitSummary.foccommmoncarrierSpouse += Number(adbSpouse.Coverage.toString().replaceAll(',', ''));
        } else if (adbSpouse.Plan.PresentAs == "MORTGAGE PROTECTION" || adbSpouse.Plan.PresentAs == "CREDIT CARD DEBT") {
            var deathBenefitadb2 = {
                Title: adbSpouse.Plan.PresentAs + " ",
                Name: person.Spouse.GetNameHolder,
                IsSpouse: true,
                Coverage: ConvertIntoAmountFormatWithDecimal(Number(adbSpouse.Coverage.toString().replaceAll(',', '')).toFixed(2).toString()),
                isADB: true
            };
            benefitSummary.DeathBenefits.push(deathBenefitadb2);
        }
    }


    var b2000Primary = plan.AdditionalProduct.PrimaryProducts.ALPProduct.find((x: any) => x.ProductId == 15 && x.Selected == true && x.ProductAvailable != false);
    var b2000Spouse = plan.AdditionalProduct.SpouseProducts.ALPProduct.find((x: any) => x.ProductId == 15 && x.Selected == true && x.ProductAvailable != false);

    if (b2000Primary) {
        benefitSummary.ShowFreedomOfChoice = benefitSummary.ShowFreedomOfChoice || b2000Primary;
        if (b2000Primary.CoverageOption != undefined) {
            benefitSummary.focaccidentalPrimary += Number(b2000Primary.CoverageOption.Value.toString().replaceAll(',', ''));
            benefitSummary.focautoPrimary += (Number(b2000Primary.CoverageOption.Value.toString().replaceAll(',', '')) * 2.5);
            benefitSummary.foccommmoncarrierPrimary += (Number(b2000Primary.CoverageOption.Value.toString().replaceAll(',', '')) * 5);
        }
    }

    if (b2000Spouse) {
        benefitSummary.ShowFreedomOfChoice = benefitSummary.ShowFreedomOfChoice || b2000Spouse;
        if (b2000Spouse.CoverageOption != undefined) {
            benefitSummary.focaccidentalSpouse += Number(b2000Spouse.CoverageOption.Value.toString().replaceAll(',', ''));
            benefitSummary.focautoSpouse += (Number(b2000Spouse.CoverageOption.Value.toString().replaceAll(',', '')) * 2.5);
            benefitSummary.foccommmoncarrierSpouse += (Number(b2000Spouse.CoverageOption.Value.toString().replaceAll(',', '')) * 5);
        }
    }

    if (benefitSummary.ShowFreedomOfChoice) {
        benefitSummary.BeneficiaryAmount = benefitSummary.focanydeathcausePrimary + benefitSummary.focautoPrimary;
        if (benefitSummary.ShowSpouse)
            benefitSummary.BeneficiaryAmount += benefitSummary.focautoSpouse + benefitSummary.focanydeathcauseSpouse;
        benefitSummary.BeneficiaryAmount = ConvertIntoAmountFormatWithDecimal(benefitSummary.BeneficiaryAmount.toFixed(2).toString());
    }
    if (benefitSummary.PrimaryIntensiveCareBenefits != undefined && benefitSummary.PrimaryIntensiveCareBenefits != 0 && benefitSummary.PrimaryIntensiveCareBenefits != emptyString) {

        benefitSummary.PrimaryIntensiveCareBenefits = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.PrimaryIntensiveCareBenefits.toString()
        );
    }

    if ( benefitSummary.SpouseIntensiveCareBenefits != undefined && benefitSummary.SpouseIntensiveCareBenefits != 0 && benefitSummary.SpouseIntensiveCareBenefits != emptyString) {
        benefitSummary.SpouseIntensiveCareBenefits = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.SpouseIntensiveCareBenefits.toString()
        );
    }

    if (benefitSummary.ChildrenIntensiveCareBenefits != undefined &&  benefitSummary.ChildrenIntensiveCareBenefits != 0 && benefitSummary.ChildrenIntensiveCareBenefits != emptyString) {
        benefitSummary.ChildrenIntensiveCareBenefits = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.ChildrenIntensiveCareBenefits.toString()
        );
    }


    if (benefitSummary.focanydeathcausePrimary != 0) {
        benefitSummary.focanydeathcausePrimary = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focanydeathcausePrimary.toFixed(2)
        );
    }
    else {
        benefitSummary.focanydeathcausePrimary = "";
    }
    if (benefitSummary.focanydeathcauseSpouse != 0) {
        benefitSummary.focanydeathcauseSpouse = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focanydeathcauseSpouse.toFixed(2)
        );
    }
    else {
        benefitSummary.focanydeathcauseSpouse = "";
    }

    if (benefitSummary.focanydeathcauseChildren != 0) {
        benefitSummary.focanydeathcauseChildren = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focanydeathcauseChildren.toFixed(2)
        );
    }
    else {
        benefitSummary.focanydeathcauseChildren = "";
    }

    if (benefitSummary.focaccidentalPrimary != 0) {

        benefitSummary.focaccidentalPrimary = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focaccidentalPrimary.toFixed(2)
        );
    }
    else {
        benefitSummary.focaccidentalPrimary = "";
    }
    if (benefitSummary.focaccidentalSpouse != 0) {

        benefitSummary.focaccidentalSpouse = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focaccidentalSpouse.toFixed(2)
        );
    }
    else {
        benefitSummary.focaccidentalSpouse = "";
    }

    if (benefitSummary.focaccidentalChildren != 0) {

        benefitSummary.focaccidentalChildren = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focaccidentalChildren.toFixed(2)
        );
    }
    else {
        benefitSummary.focaccidentalChildren = "";
    }

    if (benefitSummary.focautoPrimary != 0) {

        benefitSummary.focautoPrimary = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focautoPrimary.toFixed(2)
        );
    }
    else {
        benefitSummary.focautoPrimary = "";
    }

    if (benefitSummary.focautoSpouse != 0) {

        benefitSummary.focautoSpouse = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focautoSpouse.toFixed(2)
        );
    }
    else {
        benefitSummary.focautoSpouse = "";
    }
    if (benefitSummary.focautoChildren != 0) {

        benefitSummary.focautoChildren = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.focautoChildren.toFixed(2)
        );
    }
    else {
        benefitSummary.focautoChildren = "";
    }
    if (benefitSummary.foccommmoncarrierPrimary != 0) {

        benefitSummary.foccommmoncarrierPrimary = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.foccommmoncarrierPrimary.toFixed(2)
        );
    }
    else {
        benefitSummary.foccommmoncarrierPrimary = "";
    }
    if (benefitSummary.foccommmoncarrierSpouse != 0) {

        benefitSummary.foccommmoncarrierSpouse = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.foccommmoncarrierSpouse.toFixed(2)
        );
    }
    else {
        benefitSummary.foccommmoncarrierSpouse = "";
    }
    if (benefitSummary.foccommmoncarrierChildren != 0) {

        benefitSummary.foccommmoncarrierChildren = ConvertIntoAmountFormatWithDecimal(
            benefitSummary.foccommmoncarrierChildren.toFixed(2)
        );
    }
    else {
        benefitSummary.foccommmoncarrierChildren = "";
    }

    var whlOrTermSelected = whlPrimaryProduct || whlSpouseProduct || spouseTemmProduct || rcPrimaryMonthlyProduct;

    var tirselected = protections.find((x: any) => x.ProtectionId == 3 && x.Checked == true);

    benefitSummary.ShowTIR = tirselected && whlOrTermSelected;
    benefitSummary.PrimaryTIRAmount = 0;
    benefitSummary.SpouseTIRAmount = 0;
    if (benefitSummary.ShowTIR) {
        if (whlPrimaryProduct) {
            benefitSummary.PrimaryTIRAmount += Number(whlPrimaryProduct.Coverage.replaceAll(',', ''));
        }

        var rcPrimaryProducts = plan.PrimaryProducts.filter((x: any) => x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false);

        if (rcPrimaryProducts) {
            rcPrimaryProducts.forEach((element: any) => {
                benefitSummary.PrimaryTIRAmount += Number(element.Coverage.toString().replaceAll(',', ''));
            });
        }
        benefitSummary.PrimaryTIRAmount = benefitSummary.PrimaryTIRAmount / 2;
        benefitSummary.PrimaryTIRAmount = ConvertIntoAmountFormatWithDecimal(benefitSummary.PrimaryTIRAmount.toFixed(2).toString());


        if (whlSpouseProduct) {
            benefitSummary.SpouseTIRAmount += Number(whlSpouseProduct.Coverage.toString().replaceAll(',', ''));
        }

        var rcSpouseProducts = plan.SpouseProducts.filter((x: any) => x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false);
        var spTerm = plan.AdditionalProduct.SpouseProducts.ALPProduct.find((x: any) => x.ProductId == 6 && x.Selected == true && x.ProductAvailable != false);
        if (spTerm) {
            rcSpouseProducts.push(spTerm);
        }
        if (rcSpouseProducts) {
            rcSpouseProducts.forEach((element: any) => {
                benefitSummary.SpouseTIRAmount += Number(element.Coverage.toString().replaceAll(',', ''));
            });
        }
        benefitSummary.SpouseTIRAmount = benefitSummary.SpouseTIRAmount / 2;

        benefitSummary.SpouseTIRAmount = ConvertIntoAmountFormatWithDecimal(benefitSummary.SpouseTIRAmount.toFixed(2).toString());

    }
    var cashValueSelected = protections.find((x: any) => x.ProtectionId == 1 && x.Checked == true);
    benefitSummary.ShowCashValue = cashValueSelected && whlOrTermSelected;
    var paidUpSelected = protections.find((x: any) => x.ProtectionId == 2 && x.Checked == true);
    benefitSummary.ShowPaidUpValue = paidUpSelected && whlOrTermSelected;
    var strikeWaiverSelected = protections.find((x: any) => x.ProtectionId == 4 && x.Checked == true);
    var layoffWaiverSelected = protections.find((x: any) => x.ProtectionId == 5 && x.Checked == true);
    benefitSummary.ShowStrikeWaiver = strikeWaiverSelected && whlOrTermSelected;
    benefitSummary.ShowLayoffWaiver = layoffWaiverSelected && whlOrTermSelected;
    var cancerSelected = true;
    var c2000PrimaryAdded = plan.AdditionalProduct.PrimaryProducts.AHPProduct.find((x: any) => x.ProductId == 18 && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.ShowPrimaryLumpSum = cancerSelected && c2000PrimaryAdded;
    var c2000SpouseAdded = plan.AdditionalProduct.SpouseProducts.AHPProduct.find((x: any) => x.ProductId == 18 && x.Selected == true && x.ProductAvailable != false);;
    benefitSummary.ShowSpouseLumpSum = cancerSelected && c2000SpouseAdded;
    if (benefitSummary.ShowPrimaryLumpSum) {
        benefitSummary.PrimaryLumpSumCoverage = c2000PrimaryAdded.Plan.CompleteCoverageOption != undefined ? c2000PrimaryAdded.Plan.CompleteCoverageOption.Name : '';
        benefitSummary.PrimaryLumpSumText = person.GetNameHolder;
        if (c2000PrimaryAdded.Plan.CompleteOption.Id == 2) {
            benefitSummary.PrimaryLumpSumText = benefitSummary.PrimaryLumpSumText + " & Children";
        }
    }

    if (benefitSummary.ShowSpouseLumpSum) {
        benefitSummary.SpouseLumpSumCoverage = c2000SpouseAdded.Plan.CompleteCoverageOption != undefined ? c2000SpouseAdded.Plan.CompleteCoverageOption.Name : '';
        benefitSummary.SpouseLumpSumText = person.Spouse.GetNameHolder;
    }

    var cnmSelected = true;
    var cnmPrimaryAdded = plan.AdditionalProduct.PrimaryProducts.AHPProduct.find((x: any) => x.ProductId == 19 && x.Selected == true && x.ProductAvailable != false);
    var cnmSpouseAdded = plan.AdditionalProduct.SpouseProducts.AHPProduct.find((x: any) => x.ProductId == 19 && x.Selected == true && x.ProductAvailable != false);
    benefitSummary.ShowPerProcPrimary = cnmSelected && cnmPrimaryAdded;
    benefitSummary.ShowPerProcSpouse = cnmSelected && cnmSpouseAdded;

    var presentCNM = getPerProcedureText(presentation.LanguageFlag);

    var presentCNMPrimary = JSON.parse(JSON.stringify(presentCNM));
    var presentCNMSpouse = JSON.parse(JSON.stringify(presentCNM));

    if (benefitSummary.ShowPerProcPrimary) {
        var bsCNMRates = bsRates.find((x: any) => x.ProductListId == 19 && (!x.IncludedStates || x.IncludedStates.length == 0 || x.IncludedStates.includes(presState)) && (!x.ExcludedStates || x.ExcludedStates.length == 0 || !x.ExcludedStates.includes(presState)))
        var benefit = bsCNMRates.benefits;
        presentCNMPrimary.totalMax = 0;
        for (const [key, value] of Object.entries(presentCNMPrimary.display)) {
            if (!presentCNMPrimary.display[key]) { continue; }
            var ben = presentCNMPrimary.display[key];
            if (benefit[key]) {
                ben.note = (benefit[key].a) ? ben.note.replace('##' + key + '-' + 'a##', benefit[key].a[cnmPrimaryAdded.Plan.CompleteCoverageOption.Value.toString()]) : ben.note;
                ben.note = (benefit[key].b) ? ben.note.replace('##' + key + '-' + 'b##', benefit[key].b[cnmPrimaryAdded.Plan.CompleteCoverageOption.Value.toString()]) : ben.note;
                ben.max = benefit[key].max[cnmPrimaryAdded.Plan.CompleteCoverageOption.Value.toString()];
                var max = parseFloat(ben.max.replaceAll(',', '').replaceAll('$', ''));
                if (isNaN(max))
                    max = 0;
                presentCNMPrimary.totalMax += max;
            }
            else {
                delete presentCNMPrimary.display[key];
            }
        }
        presentCNMPrimary.totalMax = ConvertIntoAmountFormatWithDecimal(presentCNMPrimary.totalMax.toFixed(2).toString(), true, true);

        benefitSummary.PerProcPrimary = presentCNMPrimary;
        benefitSummary.PerProcPrimary.Units = cnmPrimaryAdded.Plan.CompleteCoverageOption.Value.toString();

        switch (cnmPrimaryAdded.Plan.CompleteOption.Id) {
            case 1:
                benefitSummary.PerProcPrimary.NameText = person.GetNameHolder;
                break;

            case 2:
                benefitSummary.PerProcPrimary.NameText = person.GetNameHolder + " & " + person.Spouse.GetNameHolder;

                break;

            case 3:
                benefitSummary.PerProcPrimary.NameText = person.GetNameHolder + " & Children.";
                break;

            case 4:
                benefitSummary.PerProcPrimary.NameText = person.GetNameHolder + " & " + person.Spouse.GetNameHolder + " & Children.";
                break;
        }
    }


    if (benefitSummary.ShowPerProcSpouse) {
        var bsCNMRates = bsRates.find((x: any) => x.ProductListId == 19 && (!x.IncludedStates || x.IncludedStates.length == 0 || x.IncludedStates.includes(presState)) && (!x.ExcludedStates || x.ExcludedStates.length == 0 || !x.ExcludedStates.includes(presState)))
        var benefit = bsCNMRates.benefits;
        presentCNMSpouse.totalMax = 0;
        for (const [key, value] of Object.entries(presentCNMSpouse.display)) {
            if (!presentCNMSpouse.display[key]) { continue; }
            var ben = presentCNMSpouse.display[key];
            if (benefit[key]) {
                if (cnmSpouseAdded.Plan.CompleteCoverageOption != undefined) {
                    ben.note = (benefit[key].a) ? ben.note.replace('##' + key + '-' + 'a##', benefit[key].a[cnmSpouseAdded.Plan.CompleteCoverageOption.Value.toString()]) : ben.note;
                    ben.note = (benefit[key].b) ? ben.note.replace('##' + key + '-' + 'b##', benefit[key].b[cnmSpouseAdded.Plan.CompleteCoverageOption.Value.toString()]) : ben.note;
                    ben.max = benefit[key].max[cnmSpouseAdded.Plan.CompleteCoverageOption.Value.toString()];
                    var max = parseFloat(ben.max.replaceAll(',', '').replaceAll('$', ''));
                    if (isNaN(max))
                        max = 0;
                    presentCNMSpouse.totalMax += max;
                }
            }
            else {
                delete presentCNMSpouse.display[key];
            }
        }
        presentCNMSpouse.totalMax = ConvertIntoAmountFormatWithDecimal(presentCNMSpouse.totalMax.toFixed(2).toString(), true, true);
        benefitSummary.PerProcSpouse = presentCNMSpouse;
        benefitSummary.PerProcSpouse.Units = cnmSpouseAdded.Plan.CompleteCoverageOption.Value.toString();
        benefitSummary.PerProcSpouse.NameText = person.Spouse.GetNameHolder;
    }

    //benefitSummary.DeathBenefits = groupBy(benefitSummary.DeathBenefits, 'Title');
    if (benefitSummary.DeathBenefits && benefitSummary.DeathBenefits.length > 0) {
        var sideMenuBenefits: any = [];
        var benefitsGrouped = groupBy(benefitSummary.DeathBenefits, 'Title');

        for (var prop in benefitsGrouped) {
            var product: any = {};
            product.Title = prop;
            benefitsGrouped[prop].forEach((element: any) => {
                if (element.IsSpouse) product.SpouseAmount = element.Coverage;
                else product.PrimaryAmount = element.Coverage;
                product.isADB = element.isADB;
                if (product.PrimaryAmount == undefined) product.PrimaryAmount = 0;
                if (product.SpouseAmount == undefined) product.SpouseAmount = 0;
                product.Amount = Number(product.PrimaryAmount.toString().replaceAll(',', '')) + Number(product.SpouseAmount.toString().replaceAll(',', ''));
                product.Amount = ConvertIntoAmountFormatWithDecimal(product.Amount.toFixed(2).toString(), false);
            });

            sideMenuBenefits.push(product);
        }
        benefitSummary.SideMenuBenefits = sideMenuBenefits;
    }


    //if (!isPresentPlan) {
    // benefitSummary.ShowHospitalBenefits = true;
    benefitSummary.ShowFreedomOfChoice = true;
    benefitSummary.ShowRCAmount = true;
    //}
}

export function groupBy(xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export function getPerProcedureText(lanFlag: string) {
    switch (lanFlag) {
        case "es":
            return {
                display: {
                    hospital: { title: "HOSPITAL", note: "##hospital-a## / Día por doce días ##hospital-b## / día siguiente" },
                    drugs: { title: "DROGAS Y MEDICAMENTOS", note: "10% del beneficio de hospitalización" },
                    surgical: { title: "QUIRÚRGICA", note: "##surgical-a## para ##surgical-b## por operación como se describe en el Programa de operaciones" },
                    anesthesia: { title: "ANESTHESIA", note: "No exceder ##anesthesia-a## por operación" },
                    radiation: { title: "RADIOTERAPIA Y QUIMIOTERAPIA", note: "Cargo Usual y Acostumbrado" },
                    nursing: { title: "ENFERMERÍA", note: "##nursing-a## / Día cuando se requiera" },
                    attending: { title: "MÉDICO ADMINISTRADOR", note: "##attending-a## / Día" },
                    plasma: { title: "PLASMA SANGUÍNEO", note: "Cargo Usual y Acostumbrado" },
                    ambulance: { title: "AMBULANCIA", note: "No exceder ##ambulance-a## cada internamiento" },
                    transportation: { title: "TRANSPORTE", note: "Cargo Usual y Acostumbrado" },
                    // extended: { title: "EXTENDED BENEFITS", note: "##extended-a## per month beginning on the 91st day of continuous hospital confinement" }
                }
            };
        case "fr":
            return {
                display: {
                    hospital: { title: "HÔPITAL", note: "##hospital-a## / Jour pendant douze jours ##hospital-b## / Le lendemain" },
                    drugs: { title: "DROGUES ET MÉDICAMENTS", note: "10% de l'indemnité d'hospitalisation" },
                    surgical: { title: "CHIRURGICALE", note: "##surgical-a## to ##surgical-b## par opération, comme indiqué dans le calendrier des opérations" },
                    anesthesia: { title: "ANESTHÉSIE", note: " Ne pas dépasser ##anesthesia-a## par opération" },
                    radiation: { title: "RADIOTHÉRAPIE ET ​​CHIMIOTHÉRAPIE", note: "Frais habituels et coutumiers" },
                    nursing: { title: "ALLAITEMENT", note: "##nursing-a## / Jour si nécessaire" },
                    attending: { title: "MÉDECIN TRAITANT", note: "##attending-a## / Jour" },
                    plasma: { title: "PLASMA SANGUIN", note: "Frais habituels et coutumiers" },
                    ambulance: { title: "AMBULANCE", note: "Ne pas dépasser##ambulance-a## à chaque confinement" },
                    transportation: { title: "TRANSPORT", note: "Frais habituels et coutumiers" },
                    // extended: { title: "EXTENDED BENEFITS", note: "##extended-a## per month beginning on the 91st day of continuous hospital confinement" }
                }
            }
        default:
            return {
                display: {
                    hospital: { title: "HOSPITAL", note: "##hospital-a## / Day for Twelve Days ##hospital-b## / Day thereafter" },
                    drugs: { title: "DRUGS AND MEDICINES", note: "10% of hospital confinement benefit" },
                    surgical: { title: "SURGICAL", note: "##surgical-a## to ##surgical-b## per operation as outlined in Schedule of Operations" },
                    anesthesia: { title: "ANESTHESIA", note: "Not to exceed ##anesthesia-a## per operation" },
                    radiation: { title: "RADIATION THERAPY & CHEMOTHERAPY", note: "Usual and Customary Charge" },
                    nursing: { title: "NURSING", note: "##nursing-a## / Day when required" },
                    attending: { title: "ATTENDING PHYSICIAN", note: "##attending-a## / Day" },
                    plasma: { title: "BLOOD PLASMA", note: "Usual & Customary Charge" },
                    ambulance: { title: "AMBULANCE", note: "Not to exceed ##ambulance-a## each confinement" },
                    transportation: { title: "TRANSPORTATION", note: "Usual & Customary Charge" },
                    // extended: { title: "EXTENDED BENEFITS", note: "##extended-a## per month beginning on the 91st day of continuous hospital confinement" }
                }
            }
    }
}

export function LoadA7100CalculationsInBenefitSummary(lstBSRates: any, benefitSummary: any, hospProduct: any, presState: string, emptyString: string = "--"): any {
    if (!hospProduct) {
        benefitSummary.PrimaryEmergencyRoomBenefits = emptyString
        benefitSummary.PrimaryDailyHospitalBenfits = emptyString
        benefitSummary.PrimaryIntensiveCareBenefits = emptyString
        benefitSummary.SpouseEmergencyRoomBenefits = emptyString
        benefitSummary.SpouseDailyHospitalBenfits = emptyString
        benefitSummary.SpouseIntensiveCareBenefits = emptyString
        benefitSummary.ChildrenEmergencyRoomBenefits = emptyString
        benefitSummary.ChildrenDailyHospitalBenfits = emptyString
        benefitSummary.ChildrenIntensiveCareBenefits = emptyString
        return;
    }

    if (hospProduct.Plan.CompleteOption != undefined) {
        var ahpRates = lstBSRates.find((x: any) => x.ProductListId == 17 && x.OptionIds && x.OptionIds.includes(hospProduct.Plan.CompleteOption.Id) && (!x.IncludedStates || x.IncludedStates.length == 0 || x.IncludedStates.includes(presState)) && (!x.ExcludedStates || x.ExcludedStates.length == 0 || !x.ExcludedStates.includes(presState)));
        if (ahpRates != undefined) {

            benefitSummary.PrimaryEmergencyRoomBenefits = ahpRates.ERB;
            benefitSummary.PrimaryDailyHospitalBenfits = ahpRates.DHB;
            benefitSummary.PrimaryIntensiveCareBenefits = ahpRates.ICB;
            benefitSummary.focaccidentalPrimary = ahpRates.Accident;
            benefitSummary.focautoPrimary = ahpRates.Auto;
            benefitSummary.foccommmoncarrierPrimary = ahpRates.Common;

            if (hospProduct.Plan.CompleteOption.DependOnChildrenOrSpouse == true) {
                if (benefitSummary.ShowSpouse) {
                    benefitSummary.SpouseEmergencyRoomBenefits = ahpRates.ERB;
                    benefitSummary.SpouseDailyHospitalBenfits = ahpRates.DHB;
                    benefitSummary.SpouseIntensiveCareBenefits = ahpRates.ICB;
                    benefitSummary.focaccidentalSpouse = ahpRates.Accident;
                    benefitSummary.focautoSpouse = ahpRates.Auto;
                    benefitSummary.foccommmoncarrierSpouse = ahpRates.Common;
                } else {
                    benefitSummary.SpouseEmergencyRoomBenefits = emptyString
                    benefitSummary.SpouseDailyHospitalBenfits = emptyString
                    benefitSummary.SpouseIntensiveCareBenefits = emptyString
                }
                if (benefitSummary.ShowChildren) {
                    benefitSummary.ChildrenEmergencyRoomBenefits = ahpRates.ERB;
                    benefitSummary.ChildrenDailyHospitalBenfits = ahpRates.DHB;
                    benefitSummary.ChildrenIntensiveCareBenefits = ahpRates.ICB;
                    benefitSummary.focaccidentalChildren = ahpRates.ChildAccident;
                    benefitSummary.focautoChildren = ahpRates.ChildAuto;
                    benefitSummary.foccommmoncarrierChildren = ahpRates.ChildCommon;
                }
                else {
                    benefitSummary.ChildrenEmergencyRoomBenefits = emptyString
                    benefitSummary.ChildrenDailyHospitalBenfits = emptyString
                    benefitSummary.ChildrenIntensiveCareBenefits = emptyString
                }
            }
            else {
                benefitSummary.SpouseEmergencyRoomBenefits = emptyString
                benefitSummary.SpouseDailyHospitalBenfits = emptyString
                benefitSummary.SpouseIntensiveCareBenefits = emptyString
                benefitSummary.ChildrenEmergencyRoomBenefits = emptyString
                benefitSummary.ChildrenDailyHospitalBenfits = emptyString
                benefitSummary.ChildrenIntensiveCareBenefits = emptyString
            }
        }
    }
    return ahpRates;
}

export function CalculatePlanOptionChange(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    if (tmpPlan.DependentOnSalary) {
        tmpPlan.Coverage = tmpPlan.IncomeSalary * tmpPlan.SelectedOption.id;
    } else if (tmpPlan.NameDependentonOption) {
        tmpPlan.Name = tmpPlan.SelectedOption.text;
    } else if (tmpPlan.ChangeCoverageAsPerOptionSelection) {
        tmpPlan.Coverage = tmpPlan.SelectedOption.CoverageValue;
    }
    CalculateCoverageChange(premiumPlan, tmpPlan);
}

export function CalculateCoverageChange(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    tmpPlan.MonthlyCharge = Number.parseFloat((tmpPlan.Coverage * tmpPlan.FormulaRatio).toFixed(2));
    tmpPlan.DailyCharge = Number.parseFloat((tmpPlan.MonthlyCharge / 4.3333).toFixed(2));
    tmpPlan.HourCharge = Number.parseFloat((tmpPlan.MonthlyCharge * 12).toFixed(2));
    ChangeUsedPremium(premiumPlan, tmpPlan);
}

export function CalculateMonthlyChange(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    tmpPlan.Coverage = Number.parseFloat((tmpPlan.MonthlyCharge / tmpPlan.FormulaRatio).toFixed(2));
    tmpPlan.DailyCharge = Number.parseFloat((tmpPlan.MonthlyCharge / 4.3333).toFixed(2));
    tmpPlan.HourCharge = Number.parseFloat((tmpPlan.MonthlyCharge * 12).toFixed(2));
    ChangeUsedPremium(premiumPlan, tmpPlan);
}

export function CalculateDailyChange(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    tmpPlan.MonthlyCharge = Number.parseFloat((tmpPlan.DailyCharge * 4.3333).toFixed(2));
    tmpPlan.HourCharge = Number.parseFloat((tmpPlan.MonthlyCharge * 12).toFixed(2));
    tmpPlan.Coverage = Number.parseFloat((tmpPlan.MonthlyCharge / tmpPlan.FormulaRatio).toFixed(2));
    ChangeUsedPremium(premiumPlan, tmpPlan);
}

export function CalculateHourlyChange(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    tmpPlan.MonthlyCharge = Number.parseFloat((tmpPlan.HourCharge / 12).toFixed(2));
    tmpPlan.DailyCharge = Number.parseFloat((tmpPlan.MonthlyCharge / 4.3333).toFixed(2));
    tmpPlan.Coverage = Number.parseFloat((tmpPlan.MonthlyCharge / tmpPlan.FormulaRatio).toFixed(2));
    ChangeUsedPremium(premiumPlan, tmpPlan);
}

export function CalculatePlanSelectionChanged(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    tmpPlan.PreviouslyChargedDaily = 0;
    tmpPlan.PreviousChargedMonthly = 0;
    if (tmpPlan.Selected && tmpPlan.SelectedOption == undefined)
        tmpPlan.SelectedOption = tmpPlan.options[0];
    else
        tmpPlan.SelectedOption = undefined;
    ChangeUsedPremium(premiumPlan, tmpPlan);
}

export function ChangeUsedPremium(premiumPlan: PremiumPlan, tmpPlan: Plan) {
    let dailyChgr = tmpPlan.DailyCharge != undefined ? tmpPlan.DailyCharge : 0;
    let monthlyChgr =
        tmpPlan.MonthlyCharge != undefined ? tmpPlan.MonthlyCharge : 0;

    if (tmpPlan.Selected) {
        if (
            tmpPlan.PreviouslyChargedDaily != undefined &&
            tmpPlan.PreviousChargedMonthly != undefined
        ) {
            premiumPlan.UsedDailyPremium -= Number(
                tmpPlan.PreviouslyChargedDaily
            );
            premiumPlan.UsedMonthlyPremium -= Number(
                tmpPlan.PreviousChargedMonthly
            );
        }
        premiumPlan.UsedDailyPremium += Number(dailyChgr);
        premiumPlan.UsedMonthlyPremium += Number(monthlyChgr);
        tmpPlan.PreviouslyChargedDaily = dailyChgr;
        tmpPlan.PreviousChargedMonthly = monthlyChgr;
    } else if (
        premiumPlan.UsedDailyPremium > 0 &&
        premiumPlan.UsedMonthlyPremium > 0
    ) {
        premiumPlan.UsedDailyPremium =
            Number(premiumPlan.UsedDailyPremium) - Number(dailyChgr);
        premiumPlan.UsedMonthlyPremium =
            Number(premiumPlan.UsedMonthlyPremium) - +Number(monthlyChgr);
    }
    RoundAllPlanValues(tmpPlan);
}

export function RoundAllPlanValues(tmpPlan: Plan) {
    if (tmpPlan.Coverage != undefined)
        tmpPlan.Coverage = Number(Number(tmpPlan.Coverage).toFixed(2));
    if (tmpPlan.MonthlyCharge != undefined)
        tmpPlan.MonthlyCharge = Number(Number(tmpPlan.MonthlyCharge).toFixed(2));
    if (tmpPlan.DailyCharge != undefined)
        tmpPlan.DailyCharge = Number(Number(tmpPlan.DailyCharge).toFixed(2));
    if (tmpPlan.HourCharge != undefined)
        tmpPlan.HourCharge = Number(Number(tmpPlan.HourCharge).toFixed(2));
}

export function ConvertIntoAmountFormat(value: any) {
    try {
        var result = "";
        if (value != undefined && typeof value != "string") {
            value = value.toFixed();
        }
        result = value.replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (value.includes('-'))
            result = "-" + result;

        return result;
    }
    catch (ex: any) {
        console.log(value + " Exception: " + ex);
        return value;
    }
}

export function ConvertIntoAmountFormatWithDecimal(value: string, addZeros: boolean = true, addZerosIfStringEmpty: boolean = false) {
    var result = value;
    if (value == "0")
        return "0.00";
    if (value != undefined && typeof value == "string" && value.includes('.')) {
        var numeralValue = value.split('.');
        var result = numeralValue[0].replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (numeralValue.length > 1 && numeralValue[1] != undefined) {
            numeralValue[1] = numeralValue[1].replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
        if (addZeros) {
            result += "."
            if (!addZerosIfStringEmpty)
                result += (numeralValue[1] != undefined || numeralValue[1] == "") ? numeralValue[1] : "00";
            else
                result += (numeralValue[1] != undefined && numeralValue[1] != "") ? numeralValue[1] : "00";
        }
    }
    else if (value != undefined && typeof value == "string") {
        result = value.replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (addZerosIfStringEmpty)
            result += ".00"
    } return result;
}

export function ConvertIntoAmountFormatWithNegativeDecimal(value: string, addZeros: boolean = true, addZerosIfStringEmpty: boolean = false) {
    var result = value;
    if (value == "0")
        return "0.00";
    if (value != undefined && typeof value == "string" && value.includes('.')) {
        var numeralValue = value.split('.');
        var result = numeralValue[0].replace(/-\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (addZeros) {
            result += "."
            if (!addZerosIfStringEmpty)
                result += (numeralValue[1] != undefined) ? numeralValue[1] : "00";
            else
                result += (numeralValue[1] != undefined && numeralValue[1] != "") ? numeralValue[1] : "00";
        }
    }
    else if (value != undefined && typeof value == "string") {
        result = value.replace(/-\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (addZerosIfStringEmpty)
            result += ".00"
    } return result;
}

export function CalculateMBD(coverage: number, annualRate: number, rate: number) {
    if (coverage == undefined || rate == undefined || coverage == 0 || rate == 0)
        return 0;
    if (coverage == 0 || coverage == null || isNaN(coverage))
        return 0;
    coverage = coverage / 1000;
    var value = ((coverage * rate) + annualRate) / 12;

    if (value > 0.00) {
        var decimalValue = Number.parseFloat(value.toFixed(2));
        var valueUptoFiveDecimal = Number.parseFloat(value.toFixed(5));
        if (valueUptoFiveDecimal > decimalValue)
            return Math.ceil((value + Number.EPSILON) * 100) / 100
        else
            return decimalValue;
    }
    value = Number.parseFloat((value).toFixed(2));
    return ConvertIntoAmountFormatWithDecimal(value.toString(), true, true);
}

export function RoundUpper(value: number) {
    return Math.ceil(value);
}

export function RoundUpperDecimal(value: number) {
    if (value > 0.00) {
        var decimalValue = Number.parseFloat(value.toFixed(2));
        var valueUptoFiveDecimal = Number.parseFloat(value.toFixed(5));
        if (valueUptoFiveDecimal > decimalValue)
            return Math.ceil((value + Number.EPSILON) * 100) / 100
        else
            return decimalValue;
    }
    return 0;
}

export function GetDecimalvaluefromString(value: any) {
    if(value != null && value != undefined)
    {
        value = value.replaceAll(',','');
        if (value == '' || value == '0.00') {
            return 0.00
             
        }
        else{
            var decimalValue = Number.parseFloat(value);
            return decimalValue > 0 ? decimalValue : 0.00;
        }

    }
    // if (value > 0.00) {
    //     var decimalValue = Number.parseFloat(value.toFixed(2));
    //     var valueUptoFiveDecimal = Number.parseFloat(value.toFixed(5));
    //     if (valueUptoFiveDecimal > decimalValue)
    //         return Math.ceil((value + Number.EPSILON) * 100) / 100
    //     else
    //         return decimalValue;
    // }
    return 0;
}

export function CalculateCoverageFromMBDByCoverageType(mbd: number, annualRate: number, rate: number, rate2: number, breachNumber: number) {
    if (mbd == undefined || rate == undefined || mbd == 0 || rate == 0)
        return 0;
    if (mbd == 0 || mbd == null || isNaN(mbd))
        return 0;

    var value = 1000 * ((12 * mbd) - annualRate) / rate;
    var value2 = 0;
    if (rate2 > 0) {
        value2 = 1000 * ((12 * mbd) - annualRate) / rate2;
        value2 = Number.parseInt(value2.toFixed());
    }

    if (value >= breachNumber && value2 != 0) {
        value = value2;
    }
    value = Number.parseFloat((value).toFixed());

    return value;
}

export function CalculateCoverageFromMBD(mbd: number, annualRate: number, rate: number) {
    if (mbd == undefined || rate == undefined || mbd == 0 || rate == 0)
        return 0;
    if (mbd == 0 || mbd == null || isNaN(mbd))
        return 0;

    var value = 1000 * ((12 * mbd) - annualRate) / rate;

    value = Number.parseFloat((value).toFixed());
    return value;
}

export function CalculateCoveregeOnSubType(HonorableDischarge: boolean, FuneralType: string, PlanNumber: number, ExpenseValue: string, StateCode: string){
		var coverageValue = "";
		var VaProvidedBenefit = HonorableDischarge ? 948 : 0;
		var VaProvidedHeadstone = HonorableDischarge ? 231 : 0;
		var Multiplier = 0;
		if (PlanNumber != undefined) {
			if (PlanNumber == 1) {
				Multiplier = 3;
			} else if (PlanNumber == 2) {
				Multiplier = 1.5;
			} else {
				Multiplier = 0;
			}
		}
			let val = require("../assets/pre-fill-data/burial-and-cremation-rates.json");
			if (val != undefined && val.length > 0) {
				var value = val.filter((x: any) => {
					if (x.StateName.toLowerCase() == StateCode.toLowerCase()) {
						return x;
					}
				});
				var FuneralRate =
					FuneralType != undefined &&
					FuneralType == "Cremation"
						? value[0].Cremation
						: value[0].Burial;
				coverageValue = (
					Number(FuneralRate.replaceAll(",", "")) +
					RoundUpper(Multiplier *
						(ExpenseValue != undefined &&
							  ExpenseValue != ""
								? Number(ExpenseValue.replaceAll(",", ""))
								: 0
							)) -
					VaProvidedBenefit -
					VaProvidedHeadstone
				).toString();
			}
		return coverageValue;
	}

let VeteranStatusCheckForPlanOptions: any = [
		"active duty",
		"general, under honorable conditions",
		"honorable discharge",
	];

export function IsPrimaryVeteranAndHonorablyDischarged(FamilyCareSurvey: any, PresentationWillKitVeterans: any)
{
    if (
        FamilyCareSurvey != undefined &&
        FamilyCareSurvey != ""
    ) {
        var FamilyCare = JSON.parse(FamilyCareSurvey);
        return (
            FamilyCare.IsPrimaryVeteran &&
            VeteranStatusCheckForPlanOptions.includes(
                FamilyCare.CurrentVeteranStatus?.toLowerCase()
            ) && FamilyCare.PrimaryCemeteryType == 'Private'
        );
    }
    if (
        PresentationWillKitVeterans != undefined &&
        PresentationWillKitVeterans != ""
    ) {
        var WillKit = JSON.parse(PresentationWillKitVeterans);
        return ( 
            FamilyCare != undefined &&
            FamilyCare.IsPrimaryVeteran &&
            VeteranStatusCheckForPlanOptions.includes(
                WillKit.DischargeStatus?.toLowerCase()
            ) && WillKit.VeteransType == 'Private'
        );
    }

	return false;
}

export function IsSpouseVeteranAndHonorablyDischarged(FamilyCareSurvey: any, PresentationWillKitVeterans: any)
{
    if (
			FamilyCareSurvey != undefined &&
			FamilyCareSurvey != ""
		) {
			var FamilyCare = JSON.parse(FamilyCareSurvey);
			return (
				FamilyCare.IsSpouseVeteran &&
				VeteranStatusCheckForPlanOptions.includes(
					FamilyCare.SpouseCurrentVeteranStatus?.toLowerCase()
				) && FamilyCare.SpouseCemeteryType == 'Private'
			);
		}
		return false;
}

export function GetPrimaryFuneralType(FamilyCareSurvey: any, PresentationWillKitVeterans: any)
{
    if (
        FamilyCareSurvey != undefined &&
        FamilyCareSurvey != ""
    ) {
        var FamilyCare = JSON.parse(FamilyCareSurvey);
        if (FamilyCare.PrimaryFuneralType != undefined && FamilyCare.PrimaryFuneralType != null && FamilyCare.PrimaryFuneralType != '') {
            if(FamilyCare.PrimaryFuneralType == "Other")
            {
                return "Cremation";
            }
            return FamilyCare.PrimaryFuneralType;
        }
    }
    if (
        PresentationWillKitVeterans != undefined &&
        PresentationWillKitVeterans != ""
    ) {
        var WillKit = JSON.parse(PresentationWillKitVeterans);
        if (WillKit.PrimaryFuneralType != undefined && WillKit.PrimaryFuneralType != null && WillKit.PrimaryFuneralType != '' ) {
            if(WillKit.PrimaryFuneralType == "Other")
            {
                return "Cremation";
            }
            return WillKit.PrimaryFuneralType;
        }
    }    
	return 'Burial';
}

export function GetSpouseFuneralType(FamilyCareSurvey: any, PresentationWillKitVeterans: any)
{
    if (
        FamilyCareSurvey != undefined &&
        FamilyCareSurvey != ""
    ) {
        var FamilyCare = JSON.parse(FamilyCareSurvey);
        if (FamilyCare.SpouseFuneralType != undefined && FamilyCare.SpouseFuneralType != null && FamilyCare.SpouseFuneralType != '') {
            if (FamilyCare.SpouseFuneralType == "Other") {
                return "Cremation";
            }
            return FamilyCare.SpouseFuneralType;
        }
    }
    if (
        PresentationWillKitVeterans != undefined &&
        PresentationWillKitVeterans != ""
    ) {
        var WillKit = JSON.parse(PresentationWillKitVeterans);
        if (WillKit.SpouseFuneralType != undefined && WillKit.SpouseFuneralType != null && WillKit.SpouseFuneralType != '') {
            if( WillKit.SpouseFuneralType == "Other"){
                return "Cremation";
            }
            return  WillKit.SpouseFuneralType;
        }
    }
    
    return 'Burial';
}

