import { deletePersistedState } from '@/helpers/StateMapper';
import User from '@/store/modules/auth.module';
import axios from 'axios';
import Presentation from "@/entities/Presentation";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Spouse from "@/entities/Spouse";
import { Model } from 'vue-property-decorator';
import { EncryptFunction } from '../helpers/EncryptionHelper'

// const API_URL = 'http://localhost:3000/';

let apiLink = process.env.VUE_APP_API_LINK;

class AuthService {
  login(username: string, password: string) {
    let encryptedpassword = EncryptFunction(password);
    var data: any = {
      'username': username,
      'password': encryptedpassword,
      'applicationid': '22',
      'grant_type': 'password',
      'agencyid': '1'
    };
    var formBody: any = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    if (window.navigator.onLine) {
      let request = axios.post(apiLink + 'OAuth/Authenticate', formBody, { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } })
        .then((response) => {
          console.log("Response: " + response.data);
          this.SaveLoggedInUser(username, encryptedpassword, response.data, new Date().toDateString())
          return { status: true, Result: response.data };
        })
        .catch(ex => {
          console.log("error: " + ex);
          if (ex != undefined && ex.response.data != undefined)
            return { status: false, Result: ex.response.data };
          else
            return null;
        });
      return request;
    }
    else {
      var userlistStr = localStorage.getItem("userList");
      if (userlistStr != undefined && userlistStr != "" && userlistStr != "[]") {
        var userlist = JSON.parse(userlistStr);
        var searchedUser = userlist.filter((x: any) => x.username == username && x.password == encryptedpassword);
        if (searchedUser != undefined && searchedUser.length > 0) {
          //console.log("Saving User offline auth service");
          localStorage.setItem("user", JSON.stringify(searchedUser[0].tokenDetail));
          return Promise.resolve({ status: true, Result: {} });
        }
        else {
          return Promise.resolve({ status: false, Result: { error_description: "Please connect your internet to sign in" } });
        }
      }
      else {
        return Promise.resolve({ status: false, Result: { error_description: "Please connect your internet to sign in" } });
      }
    }
  }

  SaveLoggedInUser(username: string, encryptedpwd: string, tokenDetail: object, datetime: string) {
    var userlistStr = localStorage.getItem("userList");
    var userObj = {
      "username": username,
      "password": encryptedpwd,
      "createDate": datetime,
      "tokenDetail": JSON.stringify(tokenDetail),
      "detail": ""
    };
    if (userlistStr != undefined && userlistStr != "" && userlistStr != "[]") {
      var userlist = JSON.parse(userlistStr);
      var otherUsers = userlist.filter((x: any) => x.username != userObj.username);
      // if (existingSavedUser != undefined && existingSavedUser.username != undefined)
      //   userlist.removeItem(existingSavedUser);
      otherUsers.push(userObj);
      localStorage.setItem("userList", JSON.stringify(otherUsers));
    } else {
      var userlist: any = [];
      userlist.push(userObj);
      localStorage.setItem("userList", JSON.stringify(userlist));
    }
  }

  logout() {
    localStorage.removeItem('user');
    // localStorage.removeItem('profileImage');
    deletePersistedState("AssociateHierarchy");
    deletePersistedState('PresentationList');
    Presentation.deleteAll();
    PrimaryPerson.deleteAll();
    Spouse.deleteAll();
  }
}

export default new AuthService();
