import { Model } from '@vuex-orm/core';
import { EntityHelper } from '@/helpers/EntityHelper';

export default class Calendar extends Model {
	static entity = 'calendar';
	static primaryKey = 'Id';
    static fields() {
		return {
            Id: this.uid(() => EntityHelper.uuid()),
            SelectedOptions: this.attr([]).nullable(),
            FieldDays: this.number(0).nullable(),
            OffDays: this.number(0).nullable(),
            VacationDays: this.number(0).nullable(),
            Notes: this.string(null).nullable(),
        }
    }
    Id: any
    SelectedOptions !: string
    FieldDays !: number
    OffDays !: number
    VacationDays !: number
    Notes !: string
}