import { EntityHelper } from '@/helpers/EntityHelper'
import { Model } from '@vuex-orm/core'
import Presentation from './Presentation'


export default class Report extends Model {
    static entity = 'reports'
    static primaryKey = 'Id'

    static fields() {
        return {
            Id: this.uid(() => EntityHelper.uuid()),
            CourteousRating: this.number(0),
            TimeRating: this.number(0),
            ProgramValueRating: this.number(0),
            Comments: this.string(''),
            FirstName: this.string(''),
            LastName: this.string(''),
            Zip: this.string(''),
            Phone: this.string(''),
            Email: this.string(''),
            NoCostBenefits: this.string(''),
            VSOQuestionnaire: this.string(''),
            PermanentCoverages: this.string(''),
            MoreInfoOn: this.string(''),
            Presentation_Id: this.attr(null),
            Presentation: this.belongsTo(Presentation, "Presentation_Id"),
        }
    }

    public Presentation_Id!: any
    public CourteousRating!: number
    public TimeRating!: number
    public ProgramValueRating!: number
    public Comments!: string
    public FirstName!: string
    public LastName!: string
    public Zip!: string
    public Phone!: string
    public Email!: string
    public NoCostBenefits!: string
    public VSOQuestionnaire!: string
    public PermanentCoverages!: string
    public MoreInfoOn!: string
}