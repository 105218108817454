

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";
import { Camelize } from "@/helpers/ArrayHelper";
import AddAdultChildrenComponent from "../components/popups/AddAdultChildren.vue";
import AddDependentChildrenComponent from "../components/popups/AddDependentChildren.vue";
import AddFamilyCareSurveyPropertyComponent from "../components/popups/AddFamilyCareSurveyProperty.vue";
import AddFamilyCareInsuranceComponent from "../components/popups/AddFamilyCareSurveyInsurance.vue";
import AddFamilyCareSurveyNotesComponent from "../components/popups/AddFamilyCareSurveyNotes.vue";
import { HpProHouseHoldTypeDiscription } from "@/enumerations/HpProHouseHoldType";
import {
	FamilyCareVeteranCurrentStatusDiscription,
	FamilyCareVeteranVSOMembership,
	FamilyCareVeteranVSOMembershipDiscription,
	FamilyCareVeteranWarServiceDescription,
	FamilyCareVeteranCurrentVSOMemberDescription,
	FamilyCareVeteranVALifeInsuranceCoverageDescription,
	FamilyCareVeteranRankRateAtDischargeDescription,
	FamilyCareVeteranCampaignMedalsReceivedDescription,
	FamilyCareVeteranAuxiliaryMembershipDiscription,
	FamilyCareVeteranRelationShipToYouDiscription,
} from "@/enumerations/FamilyCareVeteranEnums";
import {
	VeteranCurrentVSOMember,
	VeteranCurrentVSOMemberDescription,
} from "@/enumerations/VeteranCurrentVSOMemberEnum";
import FamilyCareSurvey from "@/entities/FamilyCareSurvey";
import {
	ConvertIntoAmountFormat,
	ConvertIntoAmountFormatWithDecimal,
} from "@/helpers/CalculationHelper";
import FamilyCareSurveyChildren from "@/entities/FamilyCareSurveyChildren";
import Presentation from "@/entities/Presentation";
import FamilyCareSurveyProperty from "@/entities/FamilyCareSurveyProperty";
import FamilyCareSurveyInsurance from "@/entities/FamilyCareSurveyInsurance";
import FamilyCareSurveyRelativeInfo from "@/entities/FamilyCareSurveyRelativeInfo";
import PrimaryPerson from "@/entities/PrimaryPerson";
import { EventBusLayout } from "@/bus/EventBusLayout";
import { PresentationType } from "@/enumerations/PresentationType";
import { PresentationSubType } from "@/enumerations/PresentationSubType";
@Component({
	components: {
		AddAdultChildrenComponent,
		AddDependentChildrenComponent,
		AddFamilyCareSurveyPropertyComponent,
		AddFamilyCareInsuranceComponent,
		AddFamilyCareSurveyNotesComponent,
	},
})
export default class FamilyCareVeteran extends Vue {
	private Presentation: any;
	private Person: any;
	private TodayDate: string = moment(new Date()).format("MM / DD / YYYY");
	private UserNameForFredomofChoise = GetAscFullNameWithCamelCase();
	private listofGenderTypes = require("../assets/pre-fill-data/gender-types.json");
	private isRefFormValid: boolean = false;
	private isMemberInformationFormValid: boolean = false;
	private isInsuranceInformationFormValid: boolean = false;
	private isPropertyInformationFormValid: boolean = false;
	private isBankingInformationFormValid: boolean = false;
	private collapsablePanelList = [
		"MemberInformationForm",
		"InsuranceInformationForm",
		"PropertyInformationForm",
		"BankingInformationForm",
	];
	private panel: any = [];
	private listofHouseHoldType: any = [];
	private listofLifeChagingEvent: any = [];
	private listofEmploymentType: any = [];
	private listofBranchofService = require("../assets/pre-fill-data/prefills-branch-of-service-types.json");
	private listofVeteranCurrentStatus: any = [];
	private listofVeteranCurrentVSOList: any = [];
	private listofWarService: any = [];
	private listofWarServiceForSpouse: any = [];
	private listofWarServiceForRelative: any = [];
	private listofVSOMembership: any = [];
	private listofVALifeInsuranceCoverage: any = [];
	private listofRankRateAtDischarge: any = [];
	private listofCampaignMedalsReceived: any = [];
	private listofAuxiliaryMembership: any = [];
	private listofRelationshipToYou: any = [];
	private surveyform: boolean = true;
	private viewRecommendation: boolean = false;
	private addNotes: boolean = false;
	private addDependentChildren: boolean = false;
	private addAdultChildren: boolean = false;
	private addProperties: boolean = false;
	private addInsurance: boolean = false;
	private basicDetailRequired: boolean = false;
	private ischeckboxchecked: boolean = true;
	private isbanklocallychecked: boolean = true;
	private ishonrableservicechecked: boolean = true;
	private isserviceinwarchecked: boolean = true;
	private isPropertyAdded: boolean = true;
	private isRentSelected: boolean = false;
	private isRentOwnChecked: boolean = true;
	private isInsuranceAdded: boolean = true;
	private isFormValidated: boolean = true;
	// private isMemberInformationFormValidated: boolean = true;
	private isInsuranceInformationFormValidated: boolean = true;
	private isPropertyInformationFormValidated: boolean = true;
	private isBankingInformationFormValidated: boolean = true;
	private showCompleteButton: boolean = true;
	private DependentChildrens: Array<FamilyCareSurveyChildren> = [];
	private TempDependentChildrens: Array<FamilyCareSurveyChildren> = [];
	private AdultChildrens: Array<FamilyCareSurveyChildren> = [];
	private TempAdultChildrens: Array<FamilyCareSurveyChildren> = [];
	private FamilyCareProperties: Array<FamilyCareSurveyProperty> = [];
	private TempFamilyCareProperties: Array<FamilyCareSurveyProperty> = [];
	private PrimaryInsuranceThroughWork: Array<FamilyCareSurveyInsurance> = [];
	private TempPrimaryInsuranceThroughWork: Array<FamilyCareSurveyInsurance> =
		[];
	private PrimaryInsuranceOutsideWork: Array<FamilyCareSurveyInsurance> = [];
	private TempPrimaryInsuranceOutsideWork: Array<FamilyCareSurveyInsurance> =
		[];
	private SpouseInsuranceThroughWork: Array<FamilyCareSurveyInsurance> = [];
	private TempSpouseInsuranceThroughWork: Array<FamilyCareSurveyInsurance> = [];
	private SpouseInsuranceOutsideWork: Array<FamilyCareSurveyInsurance> = [];
	private TempSpouseInsuranceOutsideWork: Array<FamilyCareSurveyInsurance> = [];
	private FamilyCareNotes: string = "";
	private ShowVideo: boolean = false;
	private ShowFullSurveyInformation: boolean = false;
	private VeteranRelativeInfo!: any;
	private PrimaryFuneralSection: boolean = false;
	private SpouseFuneralSection: boolean = false;
	private veteranstatusCheckForVeteranImages: any = [
		"active duty",
		"general, under honorable conditions",
		"honorable discharge",
	];
	private relationshipCheckForVeteranImages: any = [
		"parent",
		"grandparent",
		"child",
		"sibling",
		"widow/widower",
	];
	$refs!: {
		emailfield: any;
		phonefield: any;
		personDOB: any;
		spouseDOB: any;
		naForm: any;
		dobTextField: any;
		ReferralForm: any;
		MemberInformationForm: any;
		InsuranceInformationForm: any;
		PropertyInformationForm: any;
		BankingInformationForm: any;
	};
	data() {
		return {
			NameRules: [
				(v: any) => !!v || "Name is required",
				(v: any) => !!v.trim() || "Name is required",
				(v: any) => v.length <= 35 || "Name must be less than 20 characters",
			],
			SpouseNameRules: [
				(v: any) => v.length <= 35 || "Name must be less than 20 characters",
			],
			Required: [(v: any) => !!v || "Field is required"],
			SpouseRequired: [],
			SpouseRequiredNumber: [],
			SpouseComboxRequired: [],
			RequiredNumber: [
				//(v: any) => !!v || "Field is required",
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			],
			DOBRequired: [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						// const pattern =
						//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			],
			SpouseDOBRequired: [],
			EligibilityQuestionsRequired: [
				(v: any) => v != null || "Field is required",
			],
			ComboxRequired: [
				(v: any) => (v != null && v.length > 0) || "Field is required",
			],
			FamilyCareObj: this.FamilyCareObj,
			VeteranRelativeInfo: this.VeteranRelativeInfo,
		};
	}
	@Prop({ default: new FamilyCareSurvey() })
	private FamilyCareObj: any;

	@Prop({ default: 1 })
	private CountryId!: number;

	@Prop({ default: false })
	private OutsidePresentation: any;

	ConvertIntoAmountFormatWithDecimalUI(event: any) {
		if (event == null) return "0.00";
		return ConvertIntoAmountFormatWithDecimal(event.toFixed(2));
	}
	created() {
		EventBusLayout.$on("CloseForm", this.CloseForm);
		this.basicDetailRequired = true;
		this.FamilyCareObj.isPlanCalculationNeeded = false;
		this.Presentation = Presentation.query().withAllRecursive().last();
		this.Person = PrimaryPerson.query().withAllRecursive().last();

		if (
			this.FamilyCareObj.FamilyCareVeteranRelativeInfoString != undefined &&
			this.FamilyCareObj.FamilyCareVeteranRelativeInfoString != ""
		) {
			this.VeteranRelativeInfo = JSON.parse(
				this.FamilyCareObj.FamilyCareVeteranRelativeInfoString
			);
		} else {
			this.VeteranRelativeInfo = new FamilyCareSurveyRelativeInfo();
		}

		this.CreateHouseHoldTypeList();
		this.CreateEmploymentTypeList();
		// this.CreateBranchofServiceList();
		this.CreateVSOMembershipList();
		this.CreateVeteranCurrentStatusList();
		this.CreateCurrentVSOMemberList();
		this.CreateWarServiceList();
		this.CreateVALifeCoverageList();
		this.CreateRankRateDischargeList();
		this.CreateCampaignMedalsReceivedList();
		this.CreateAuxiliaryMembershipList();
		this.CreateRelationshipToYouList();
		this.PrimaryDOBChange(true);
		this.SpouseDOBChange(true);
		this.FamilyCareObj.IsVeteran = true;

		if (
			this.FamilyCareObj.IsPrimaryVeteran == null ||
			this.FamilyCareObj.IsPrimaryVeteran == undefined
		) {
			if (!this.OutsidePresentation) {
				if (
					this.Presentation.PresentationSubTypeId ==
						PresentationSubType.VeteranFamilyLead ||
					this.Presentation.PresentationSubTypeId ==
						PresentationSubType.SMBVeteranFamilyLead ||
					this.Presentation.PresentationSubTypeId ==
						PresentationSubType.SMBVeteranFamilyLeadAlpha ||
					this.Presentation.PresentationSubTypeId ==
						PresentationSubType.Civilian
				) {
					this.FamilyCareObj.IsPrimaryVeteran = false;
				} else {
					this.FamilyCareObj.IsPrimaryVeteran = true;
				}
			} else {
				this.FamilyCareObj.IsPrimaryVeteran = true;
			}
		}

		if (!this.OutsidePresentation) {
			this.FamilyCareNotes = this.FamilyCareObj.Notes;
			this.FamilyCareObj.CheckingAccountCount = isNaN(
				this.FamilyCareObj.CheckingAccountCount
			)
				? null
				: this.FamilyCareObj.CheckingAccountCount;
			this.FamilyCareObj.SavingAccountCount = isNaN(
				this.FamilyCareObj.SavingAccountCount
			)
				? null
				: this.FamilyCareObj.SavingAccountCount;
			this.FamilyCareObj.InvestmentAccountCount = isNaN(
				this.FamilyCareObj.InvestmentAccountCount
			)
				? null
				: this.FamilyCareObj.InvestmentAccountCount;

			if (this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) {
				this.FamilyCareObj.HonorableService =
					this.FamilyCareObj.HonorableService == undefined
						? true
						: this.FamilyCareObj.HonorableService;
				this.FamilyCareObj.ServiceInWar =
					this.FamilyCareObj.ServiceInWar == undefined
						? true
						: this.FamilyCareObj.ServiceInWar;
				this.FamilyCareObj.YesContactMe =
					this.FamilyCareObj.YesContactMe == undefined
						? true
						: this.FamilyCareObj.YesContactMe;
			}
			this.CheckAndFillData();
		}
		this.FamilyCareObj.IsVeteranSurveyFirstTimeOpen = false;

		var _keydownCallback = (event: any) => {
			if (event.code == "Escape") {
				if (this.ShowVideo) {
					this.ShowVideo = false;
				} else if (
					!(
						this.addDependentChildren ||
						this.addAdultChildren ||
						this.addInsurance ||
						this.addProperties ||
						this.addNotes
					)
				) {
					this.CloseFormInside();
				}
			}
		};
		document.addEventListener("keyup", _keydownCallback);
	}
	beforeDestroy() {
		EventBusLayout.$off("CloseForm", this.CloseForm);
	}
	CheckAndFillData() {
		if (this.Person != undefined) {
			if (this.Person.FirstName != undefined && this.Person.FirstName != "")
				this.FamilyCareObj.PrimaryFirstName = this.Person.FirstName;
			if (this.Person.LastName != undefined && this.Person.LastName != "")
				this.FamilyCareObj.PrimaryLastName = this.Person.LastName;
			if (this.Person.Email != undefined && this.Person.Email != "")
				this.FamilyCareObj.PrimaryEmail = this.Person.Email;
			if (this.Person.DateOfBirth != undefined && this.Person.DateOfBirth != "")
				this.FamilyCareObj.PrimaryDateofBirth = this.Person.DateOfBirth;
			if (this.Person.Gender != undefined && this.Person.Gender != "")
				this.FamilyCareObj.PrimaryGender = this.Person.Gender;
			if (this.Person.Occupation != undefined && this.Person.Occupation != "")
				this.FamilyCareObj.PrimaryOccupation = this.Person.Occupation;
			if (
				this.Person.AnnualIncome != null &&
				this.Person.AnnualIncome != undefined &&
				this.Person.Wage != ""
			) {
				this.FamilyCareObj.PrimaryAnnualIncome = this.Person.AnnualIncome;
			}
			HpProHouseHoldTypeDiscription.forEach((discription, value) => {
				if (value == this.Person.HouseHoldType) {
					this.FamilyCareObj.HouseHoldType = discription.toLowerCase();
				}
			});
			this.FamilyCareObj.HasCheckingAccount = this.Person.Checking;
			this.FamilyCareObj.HasSavingAccount = this.Person.Saving;
			this.FamilyCareObj.SavingAccountCount = !this.FamilyCareObj
				.SavingAccountChecked
				? null
				: this.FamilyCareObj.SavingAccountCount;
			this.FamilyCareObj.CheckingAccountCount = !this.FamilyCareObj
				.CheckingAccountChecked
				? null
				: this.FamilyCareObj.CheckingAccountCount;
			if (
				this.FamilyCareObj.CheckingAccountChecked ||
				this.FamilyCareObj.SavingAccountChecked
			) {
				this.FamilyCareObj.NoBankAccountChecked = false;
			}

			if (
				this.Person.HasChildrenUnder18 &&
				this.Person.ChildrenString != undefined &&
				this.Person.ChildrenString != ""
			) {
				let children = JSON.parse(this.Person.ChildrenString);

				if (children.length > 0) {
					this.DependentChildrens = children;
					this.DependentChildrens.sort(
						(a: any, b: any) => b.AgePerDOB - a.AgePerDOB
					);
					if (this.DependentChildrens.length > 0) {
						this.FamilyCareObj.DependentChildrenString = JSON.stringify(
							this.DependentChildrens
						);
					} else {
						this.FamilyCareObj.DependentChildrenString = "";
					}
				} else {
					this.FamilyCareObj.DependentChildrenString = "";
				}
			} else {
				this.FamilyCareObj.DependentChildrenString = "";
			}

			// if (
			// 	this.FamilyCareObj.DependentChildrenString != undefined &&
			// 	this.FamilyCareObj.DependentChildrenString != ""
			// ) {
			// 	this.DependentChildrens = JSON.parse(
			// 		this.FamilyCareObj.DependentChildrenString
			// 	);
			// 	this.DependentChildrens.sort(
			// 		(a: any, b: any) => b.AgePerDOB - a.AgePerDOB
			// 	);
			// }

			if (
				this.Person.AdultChildrenString != undefined &&
				this.Person.AdultChildrenString != ""
			) {
				let children = JSON.parse(this.Person.AdultChildrenString);

				if (children.length > 0) {
					this.AdultChildrens = children;
					this.AdultChildrens.sort(
						(a: any, b: any) => b.AgePerDOB - a.AgePerDOB
					);
					if (this.AdultChildrens.length > 0) {
						this.FamilyCareObj.AdultChildrenString = JSON.stringify(
							this.AdultChildrens
						);
					} else {
						this.FamilyCareObj.AdultChildrenString = "";
					}
				} else {
					this.FamilyCareObj.AdultChildrenString = "";
				}
			} else {
				this.FamilyCareObj.AdultChildrenString = "";
			}

			// if (
			// 	this.FamilyCareObj.AdultChildrenString != undefined &&
			// 	this.FamilyCareObj.AdultChildrenString != ""
			// ) {
			// 	this.AdultChildrens = JSON.parse(
			// 		this.FamilyCareObj.AdultChildrenString
			// 	);
			// 	this.AdultChildrens.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);
			// }
			if (
				this.Person.InsuranceThroughWorkString != undefined &&
				this.Person.InsuranceThroughWorkString != ""
			) {
				this.FamilyCareObj.PrimaryInsuranceThroughWork =
					this.Person.InsuranceThroughWorkString;
			}
			if (
				this.Person.InsuranceOutsideWorkString != undefined &&
				this.Person.InsuranceOutsideWorkString != ""
			) {
				this.FamilyCareObj.PrimaryInsuranceOutsideWork =
					this.Person.InsuranceOutsideWorkString;
			}
			if (
				this.Person.NeedAnalysisPropertiesString != undefined &&
				this.Person.NeedAnalysisPropertiesString != ""
			) {
				this.FamilyCareObj.FamilyCareSurveyPropertyString =
					this.Person.NeedAnalysisPropertiesString;
			}

			let properties: any = [];
			if (
				this.FamilyCareObj.FamilyCareSurveyPropertyString != undefined &&
				this.FamilyCareObj.FamilyCareSurveyPropertyString != "" &&
				this.FamilyCareObj.FamilyCareSurveyPropertyString != "[]"
			) {
				properties = JSON.parse(
					this.FamilyCareObj.FamilyCareSurveyPropertyString
				);
			}

			// if (
			// 	properties.length <= 1 &&
			// 	(this.Person.OwnHome || this.Person.RentHome)
			// 	// this.Person.OwnOrRent != "" &&
			// 	// this.Person.OwnOrRent != null
			// ) {
			// 	if (properties.length == 0) {
			// 		properties.push(this.GetUpdatedProperty());
			// 		this.FamilyCareObj.FamilyCareSurveyPropertyString =
			// 			JSON.stringify(properties);
			// 	} else if (
			// 		properties.length == 1 &&
			// 		!(
			// 			(properties[0].OwnOrRent != null &&
			// 				properties[0].OwnOrRent != "") ||
			// 			(properties[0].MonthlyRent != null &&
			// 				properties[0].MonthlyRent != "") ||
			// 			(properties[0].MortgageBalance != null &&
			// 				properties[0].MortgageBalance != "") ||
			// 			(properties[0].MortgageInterestRate != null &&
			// 				properties[0].MortgageInterestRate != "") ||
			// 			(properties[0].MortgageYearsRemaining != null &&
			// 				properties[0].MortgageYearsRemaining != "")
			// 		)
			// 	) {
			// 		properties.push(this.GetUpdatedProperty());
			// 		this.FamilyCareObj.FamilyCareSurveyPropertyString =
			// 			JSON.stringify(properties);
			// 	}
			// }
		}
		if (this.Person.Spouse != undefined) {
			if (
				this.Person.Spouse.FirstName != undefined &&
				this.Person.Spouse.FirstName != ""
			)
				this.FamilyCareObj.SpouseFirstName = this.Person.Spouse.FirstName;
			if (
				this.Person.Spouse.LastName != undefined &&
				this.Person.Spouse.LastName != ""
			)
				this.FamilyCareObj.SpouseLastName = this.Person.Spouse.LastName;
			if (
				this.Person.Spouse.DateOfBirth != undefined &&
				this.Person.Spouse.DateOfBirth != ""
			)
				this.FamilyCareObj.SpouseDateofBirth = this.Person.Spouse.DateOfBirth;
			if (
				this.Person.Spouse.Gender != undefined &&
				this.Person.Spouse.Gender != ""
			)
				this.FamilyCareObj.SpouseGender = this.Person.Spouse.Gender;
			if (
				this.Person.Spouse.Occupation != undefined &&
				this.Person.Spouse.Occupation != ""
			)
				this.FamilyCareObj.SpouseOccupation = this.Person.Spouse.Occupation;
			if (
				this.Person.Spouse.AnnualIncome != null &&
				this.Person.Spouse.AnnualIncome != undefined &&
				this.Person.Spouse.Wage != ""
			) {
				this.FamilyCareObj.SpouseAnnualIncome = this.Person.Spouse.AnnualIncome;
				// this.Person.Spouse.Wage = (
				// 	Number(FamilyCare.SpouseAnnualIncome.replaceAll(",", "")) /
				// 	12 /
				// 	4.33 /
				// 	5 /
				// 	8
				// ).toFixed(2);
			}
			if (
				this.Person.Spouse.InsuranceThroughWorkString != undefined &&
				this.Person.Spouse.InsuranceThroughWorkString != ""
			) {
				this.FamilyCareObj.SpouseInsuranceThroughWork =
					this.Person.Spouse.InsuranceThroughWorkString;
			}
			if (
				this.Person.Spouse.InsuranceOutsideWorkString != undefined &&
				this.Person.Spouse.InsuranceOutsideWorkString != ""
			) {
				this.FamilyCareObj.SpouseInsuranceOutsideWork =
					this.Person.Spouse.InsuranceOutsideWorkString;
			}
		}

		if (this.FamilyCareObj.FamilyCareSurveyPropertyString != "")
			this.FamilyCareProperties = JSON.parse(
				this.FamilyCareObj.FamilyCareSurveyPropertyString
			);
		if (this.FamilyCareObj.PrimaryInsuranceThroughWork != "")
			this.PrimaryInsuranceThroughWork = JSON.parse(
				this.FamilyCareObj.PrimaryInsuranceThroughWork
			);
		if (this.FamilyCareObj.PrimaryInsuranceOutsideWork != "")
			this.PrimaryInsuranceOutsideWork = JSON.parse(
				this.FamilyCareObj.PrimaryInsuranceOutsideWork
			);
		if (this.FamilyCareObj.SpouseInsuranceThroughWork != "")
			this.SpouseInsuranceThroughWork = JSON.parse(
				this.FamilyCareObj.SpouseInsuranceThroughWork
			);
		if (this.FamilyCareObj.SpouseInsuranceOutsideWork != "")
			this.SpouseInsuranceOutsideWork = JSON.parse(
				this.FamilyCareObj.SpouseInsuranceOutsideWork
			);

		if (
			this.Presentation.PresentationWillKitVeterans != null &&
			this.Presentation.PresentationWillKitVeterans != undefined &&
			this.Presentation.PresentationWillKitVeterans != ""
		) {
			let willkit = JSON.parse(this.Presentation.PresentationWillKitVeterans);
			this.FamilyCareObj.PrimaryFuneralType = willkit.FuneralType;
			this.FamilyCareObj.SpouseFuneralType = willkit.SpouseFuneralType;
			this.FamilyCareObj.PrimaryCemeteryType = willkit.VeteransType;
			this.FamilyCareObj.SpouseCemeteryType = willkit.SpouseVeteransType;
			this.FamilyCareObj.BranchOfService = willkit.BranchOfService;
			this.FamilyCareObj.CurrentVeteranStatus = willkit.DischargeStatus;
			this.FamilyCareObj.CampaignMedalsReceived =
				willkit.CampaignMedalsReceived;
			this.FamilyCareObj.WarService = willkit.NameOfWar;
			this.FamilyCareObj.RankRateAtDischarge = willkit.RankRateAtDischarge;
			this.FamilyCareObj.VSOMembership = willkit.VSOMembership;
			this.FamilyCareObj.CurrentVSOMember = willkit.CurrentVSOMember;
		}

		if (
			(this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) &&
			this.FamilyCareObj.IsVeteranSurveyFirstTimeOpen
		) {
			if (this.FamilyCareObj.VSOMembership == undefined) {
				this.FamilyCareObj.VSOMembership =
					this.listofVSOMembership[FamilyCareVeteranVSOMembership.life];
				this.VSOMMembershipChange(this.FamilyCareObj.VSOMembership);
			}

			if (
				this.FamilyCareObj.CurrentVSOMember != undefined &&
				this.FamilyCareObj.CurrentVSOMember.length == 0
			) {
				this.FamilyCareObj.CurrentVSOMember = [
					this.listofVeteranCurrentVSOList[
						VeteranCurrentVSOMember.vfw_of_washington
					],
				];
				this.CurrentVSOMemberChange(this.FamilyCareObj.CurrentVSOMember);
			}
		}
	}

	GetUpdatedProperty() {
		let property = new FamilyCareSurveyProperty();
		//property.OwnOrRent = this.Person.OwnOrRent;
		property.OwnHome = this.Person.OwnHome;
		property.RentHome = this.Person.RentHome;

		if (
			this.Person.MonthlyRent != null &&
			this.Person.MonthlyRent != undefined
		) {
			property.MonthlyRent = this.Person.MonthlyRent;
		}
		if (
			this.Person.MortgageBalance != null &&
			this.Person.MortgageBalance != undefined
		) {
			property.MortgageBalance = this.Person.MortgageBalance;
		}
		if (
			this.Person.MortgageInterestRate != null &&
			this.Person.MortgageInterestRate != undefined
		) {
			property.MortgageInterestRate = this.Person.MortgageInterestRate;
		}
		if (
			this.Person.MortgageYearsRemaining != null &&
			this.Person.MortgageYearsRemaining != undefined
		) {
			property.MortgageYearsRemaining = this.Person.MortgageYearsRemaining;
		}

		return property;
	}
	@Watch("FamilyCareObj.HasCheckingAccount")
	OnCheckingCheckChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) this.isbanklocallychecked = true;
	}
	@Watch("FamilyCareObj.HasSavingAccount")
	OnCSavingCheckChange(newValue: boolean, oldValue: boolean) {
		if (newValue == true) this.isbanklocallychecked = true;
	}

	@Watch("FamilyCareObj.SpouseFirstName")
	OnSpouseFirstNameChange(newValue: string, oldValue: string) {
		if (
			(newValue == undefined || newValue == "") &&
			(this.FamilyCareObj.SpouseFirstName == undefined ||
				this.FamilyCareObj.SpouseFirstName == "")
		) {
			this.$data.SpouseRequired = [];
			this.$data.SpouseRequiredNumber = [];
			this.$data.SpouseRadioRequired = [];
			this.$data.SpouseDOBRequired = [];
			this.$data.SpouseComboxRequired = [];
		} else if (this.$data.SpouseRequired.length == 0) {
			this.$data.SpouseRequired = [(v: any) => !!v || "Field is required"];
			this.$data.SpouseRadioRequired = [
				(v: any) => v != undefined || "Field is required",
			];
			this.$data.SpouseDOBRequired = [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			];
			this.$data.SpouseRequiredNumber = [
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			];
			this.$data.SpouseComboxRequired = [
				(v: any) => (v != null && v.length > 0) || "Field is required",
			];
		}
	}

	@Watch("FamilyCareObj.SpouseLastName")
	OnSpouseLastNameChange(newValue: string, oldValue: string) {
		if (
			(newValue == undefined || newValue == "") &&
			(this.FamilyCareObj.SpouseLastName == undefined ||
				this.FamilyCareObj.SpouseLastName == "")
		) {
			this.$data.SpouseRequired = [];
			this.$data.SpouseRequiredNumber = [];
			this.$data.SpouseRadioRequired = [];
			this.$data.SpouseDOBRequired = [];
			this.$data.SpouseComboxRequired = [];
		} else if (this.$data.SpouseRequired.length == 0) {
			this.$data.SpouseRequired = [(v: any) => !!v || "Field is required"];
			this.$data.SpouseRadioRequired = [
				(v: any) => v != undefined || "Field is required",
			];
			this.$data.SpouseDOBRequired = [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			];
			this.$data.SpouseRequiredNumber = [
				(v: any) => {
					if (v != undefined && parseFloat(v) >= 0) return true;
					else return false;
				},
			];
			this.$data.SpouseComboxRequired = [
				(v: any) => (v != null && v.length > 0) || "Field is required",
			];
		}
	}

	@Watch("addDependentChildren")
	OnaddDependentChildren(e: boolean) {
		if (e == true) {
			if (
				this.DependentChildrens == null ||
				this.DependentChildrens.length < 1
			) {
				this.DependentChildrens.push(
					new FamilyCareSurveyChildren(),
					new FamilyCareSurveyChildren(),
					new FamilyCareSurveyChildren(),
					new FamilyCareSurveyChildren()
				);
			}
			this.TempDependentChildrens = JSON.parse(
				JSON.stringify(this.DependentChildrens)
			);
		} else {
		}
	}

	@Watch("addAdultChildren")
	OnaddAdultChildren(e: boolean) {
		if (e == true) {
			if (this.AdultChildrens == null || this.AdultChildrens.length < 1) {
				this.AdultChildrens.push(
					new FamilyCareSurveyChildren(),
					new FamilyCareSurveyChildren(),
					new FamilyCareSurveyChildren(),
					new FamilyCareSurveyChildren()
				);
			}
			this.TempAdultChildrens = JSON.parse(JSON.stringify(this.AdultChildrens));
		} else {
		}
	}

	@Watch("addProperties")
	OnaddProperties(e: boolean) {
		if (e == true) {
			if (
				this.FamilyCareProperties == null ||
				this.FamilyCareProperties.length < 1
			) {
				this.FamilyCareProperties.push(new FamilyCareSurveyProperty());
			}
			this.TempFamilyCareProperties = JSON.parse(
				JSON.stringify(this.FamilyCareProperties)
			);
		} else {
		}
	}

	@Watch("addInsurance")
	OnaddInsurance(e: boolean) {
		if (e == true) {
			if (
				this.PrimaryInsuranceThroughWork == null ||
				this.PrimaryInsuranceThroughWork.length < 1
			) {
				this.PrimaryInsuranceThroughWork.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}

			if (
				this.PrimaryInsuranceOutsideWork == null ||
				this.PrimaryInsuranceOutsideWork.length < 1
			) {
				this.PrimaryInsuranceOutsideWork.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}

			if (
				this.SpouseInsuranceThroughWork == null ||
				this.SpouseInsuranceThroughWork.length < 1
			) {
				this.SpouseInsuranceThroughWork.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}

			if (
				this.SpouseInsuranceOutsideWork == null ||
				this.SpouseInsuranceOutsideWork.length < 1
			) {
				this.SpouseInsuranceOutsideWork.push(
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance(),
					new FamilyCareSurveyInsurance()
				);
			}
			this.TempPrimaryInsuranceThroughWork = JSON.parse(
				JSON.stringify(this.PrimaryInsuranceThroughWork)
			);
			this.TempPrimaryInsuranceOutsideWork = JSON.parse(
				JSON.stringify(this.PrimaryInsuranceOutsideWork)
			);
			this.TempSpouseInsuranceThroughWork = JSON.parse(
				JSON.stringify(this.SpouseInsuranceThroughWork)
			);
			this.TempSpouseInsuranceOutsideWork = JSON.parse(
				JSON.stringify(this.SpouseInsuranceOutsideWork)
			);
		}
	}

	saveDependentChildren(items: any) {
		this.addDependentChildren = false;
		this.DependentChildrens = JSON.parse(
			JSON.stringify(
				this.TempDependentChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName.trim() != "") ||
						(x.LastName && x.LastName.trim() != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);
		this.FamilyCareObj.DependentChildrenString = JSON.stringify(
			this.DependentChildrens
		);
		this.saveData();
	}

	saveAdultChildren(items: any) {
		this.addAdultChildren = false;
		this.AdultChildrens = JSON.parse(
			JSON.stringify(
				this.TempAdultChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName.trim() != "") ||
						(x.LastName && x.LastName.trim() != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);
		this.FamilyCareObj.AdultChildrenString = JSON.stringify(
			this.AdultChildrens
		);
		this.saveData();
	}

	saveProperties(items: any) {
		this.addProperties = false;
		this.FamilyCareProperties = JSON.parse(
			JSON.stringify(
				// this.TempFamilyCareProperties
				this.TempFamilyCareProperties.filter(
					(x: any) =>
						(x.OwnOrRent != null && x.OwnOrRent != "") ||
						(x.MonthlyRent != null && x.MonthlyRent != "") ||
						(x.MortgageBalance != null && x.MortgageBalance != "") ||
						(x.MortgageInterestRate != null && x.MortgageInterestRate != "") ||
						(x.MortgageYearsRemaining != null && x.MortgageYearsRemaining != "")
				)
			)
		);
		this.FamilyCareObj.FamilyCareSurveyPropertyString = JSON.stringify(
			this.FamilyCareProperties
		);
		this.ValidateProperties(this.FamilyCareProperties);
		this.GetTotalPayment;
		this.saveData();
	}

	saveInsurance(items: any) {
		this.addInsurance = false;
		this.PrimaryInsuranceThroughWork = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceThroughWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.PrimaryInsuranceOutsideWork = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceOutsideWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceThroughWork = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceThroughWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceOutsideWork = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceOutsideWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.FamilyCareObj.PrimaryInsuranceThroughWork = JSON.stringify(
			this.PrimaryInsuranceThroughWork
		);
		this.FamilyCareObj.PrimaryInsuranceOutsideWork = JSON.stringify(
			this.PrimaryInsuranceOutsideWork
		);
		this.FamilyCareObj.SpouseInsuranceThroughWork = JSON.stringify(
			this.SpouseInsuranceThroughWork
		);
		this.FamilyCareObj.SpouseInsuranceOutsideWork = JSON.stringify(
			this.SpouseInsuranceOutsideWork
		);
		this.GetPrimaryADB;
		this.saveData();
	}

	cancelDependentChildren() {
		this.addDependentChildren = false;
		this.DependentChildrens = JSON.parse(
			JSON.stringify(
				this.TempDependentChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);
		this.FamilyCareObj.DependentChildrenString = JSON.stringify(
			this.DependentChildrens
		);
		this.saveData();
	}

	cancelAdultChildren() {
		this.addAdultChildren = false;
		this.AdultChildrens = JSON.parse(
			JSON.stringify(
				this.TempAdultChildrens.filter(
					(x: any) =>
						(x.FirstName && x.FirstName != "") ||
						(x.LastName && x.LastName != "") ||
						(x.DateOfBirth && x.DateOfBirth != "") ||
						(x.Gender && x.Gender != "")
				)
			)
		);
		this.FamilyCareObj.AdultChildrenString = JSON.stringify(
			this.AdultChildrens
		);
		this.saveData();
	}

	saveFamilyCareNotes(NotesString: any) {
		this.addNotes = false;
		this.FamilyCareObj.Notes = NotesString;
		this.FamilyCareNotes = this.FamilyCareObj.Notes;
		this.saveData();
	}

	cancelProperties() {
		this.addProperties = false;
		this.FamilyCareProperties = JSON.parse(
			JSON.stringify(
				// this.TempFamilyCareProperties
				this.TempFamilyCareProperties.filter(
					(x: any) =>
						(x.OwnOrRent != null && x.OwnOrRent != "") ||
						(x.MonthlyRent != null && x.MonthlyRent != "") ||
						(x.MortgageBalance != null && x.MortgageBalance != "") ||
						(x.MortgageInterestRate != null && x.MortgageInterestRate != "") ||
						(x.MortgageYearsRemaining != null && x.MortgageYearsRemaining != "")
				)
			)
		);
		this.FamilyCareObj.FamilyCareSurveyPropertyString = JSON.stringify(
			this.FamilyCareProperties
		);
		this.ValidateProperties(this.FamilyCareProperties);
		this.GetTotalPayment;
		this.saveData();
	}

	cancelInsurance() {
		this.addInsurance = false;
		this.PrimaryInsuranceThroughWork = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceThroughWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.PrimaryInsuranceOutsideWork = JSON.parse(
			JSON.stringify(
				this.TempPrimaryInsuranceOutsideWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceThroughWork = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceThroughWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.SpouseInsuranceOutsideWork = JSON.parse(
			JSON.stringify(
				this.TempSpouseInsuranceOutsideWork.filter(
					(x: any) => x.InsuranceType != "" || x.InsuranceAmount != null
				)
			)
		);
		this.FamilyCareObj.PrimaryInsuranceThroughWork = JSON.stringify(
			this.PrimaryInsuranceThroughWork
		);
		this.FamilyCareObj.PrimaryInsuranceOutsideWork = JSON.stringify(
			this.PrimaryInsuranceOutsideWork
		);
		this.FamilyCareObj.SpouseInsuranceThroughWork = JSON.stringify(
			this.SpouseInsuranceThroughWork
		);
		this.FamilyCareObj.SpouseInsuranceOutsideWork = JSON.stringify(
			this.SpouseInsuranceOutsideWork
		);
		this.GetPrimaryADB;
		this.saveData();
	}

	cancelFamilyCareNotes(NotesString: any) {
		this.addNotes = false;
		this.FamilyCareObj.Notes = NotesString;
		this.FamilyCareNotes = this.FamilyCareObj.Notes;
		this.saveData();
	}

	isNumber(evt: any) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
			evt.preventDefault();
		} else {
			return true;
		}
	}

	CreateHouseHoldTypeList() {
		HpProHouseHoldTypeDiscription.forEach((discription, value) => {
			if (discription.toLowerCase() != "retired") {
				this.listofHouseHoldType.push(discription.toLowerCase());
			}
		});
	}

	// CreateBranchofServiceList() {
	// 	FamilyCareVeteranBranchOfServiceDiscription.forEach(
	// 		(description, value) => {
	// 			this.listofBranchofService.push(description.toLowerCase());
	// 		}
	// 	);
	// }

	CreateVSOMembershipList() {
		FamilyCareVeteranVSOMembershipDiscription.forEach((description, value) => {
			this.listofVSOMembership.push(description);
		});
		// if (
		// 	this.FamilyCareObj.VSOMembership != null &&
		// 	this.FamilyCareObj.VSOMembership != ""
		// ) {
		// 	if (
		// 		!this.listofVSOMembership.includes(this.FamilyCareObj.VSOMembership)
		// 	) {
		// 		this.listofVSOMembership.push(this.FamilyCareObj.VSOMembership);
		// 	}
		// }
	}

	CreateVeteranCurrentStatusList() {
		FamilyCareVeteranCurrentStatusDiscription.forEach((description, value) => {
			this.listofVeteranCurrentStatus.push(description);
		});
	}

	CreateCurrentVSOMemberList() {
		if (this.IsVeteranSMBAlpha || this.IsServiceMemberBenefitsAlpha) {
			VeteranCurrentVSOMemberDescription.forEach((description, value) => {
				this.listofVeteranCurrentVSOList.push(description);
			});
		} else {
			FamilyCareVeteranCurrentVSOMemberDescription.forEach(
				(description, value) => {
					this.listofVeteranCurrentVSOList.push(description);
				}
			);
		}

		if (
			this.FamilyCareObj.CurrentVSOMember != null &&
			this.FamilyCareObj.CurrentVSOMember.length > 0
		) {
			this.FamilyCareObj.CurrentVSOMember.forEach((event: any) => {
				if (!this.listofVeteranCurrentVSOList.includes(event)) {
					this.listofVeteranCurrentVSOList.push(event);
				}
			});
		}
	}

	CreateWarServiceList() {
		FamilyCareVeteranWarServiceDescription.forEach((description, value) => {
			this.listofWarService.push(description);
			this.listofWarServiceForSpouse.push(description);
			this.listofWarServiceForRelative.push(description);
		});
		// this.listofWarServiceForSpouse = this.listofWarService.slice(0);
		// this.listofWarServiceForRelative = this.listofWarService.slice(0);

		this.FamilyCareObj.WarService.forEach((event: any) => {
			if (!this.listofWarService.includes(event)) {
				this.listofWarService.push(event);
			}
		});
		this.FamilyCareObj.SpouseWarService.forEach((event: any) => {
			if (!this.listofWarServiceForSpouse.includes(event)) {
				this.listofWarServiceForSpouse.push(event);
			}
		});
		this.VeteranRelativeInfo.WarService.forEach((event: any) => {
			if (!this.listofWarServiceForRelative.includes(event)) {
				this.listofWarServiceForRelative.push(event);
			}
		});
	}

	CreateVALifeCoverageList() {
		FamilyCareVeteranVALifeInsuranceCoverageDescription.forEach(
			(description, value) => {
				this.listofVALifeInsuranceCoverage.push(description.toLowerCase());
			}
		);
	}

	CreateRankRateDischargeList() {
		FamilyCareVeteranRankRateAtDischargeDescription.forEach(
			(description, value) => {
				this.listofRankRateAtDischarge.push(description);
			}
		);
	}
	CreateCampaignMedalsReceivedList() {
		FamilyCareVeteranCampaignMedalsReceivedDescription.forEach(
			(description, value) => {
				this.listofCampaignMedalsReceived.push(description);
			}
		);
	}
	CreateAuxiliaryMembershipList() {
		FamilyCareVeteranAuxiliaryMembershipDiscription.forEach(
			(description, value) => {
				this.listofAuxiliaryMembership.push(description);
			}
		);
	}
	CreateRelationshipToYouList() {
		FamilyCareVeteranRelationShipToYouDiscription.forEach(
			(description, value) => {
				this.listofRelationshipToYou.push(description.toLowerCase());
			}
		);
	}

	CreateEmploymentTypeList() {
		this.listofEmploymentType.push("Full Time");
		this.listofEmploymentType.push("Part Time");
		this.listofEmploymentType.push("Unemployed");
		this.listofEmploymentType.push("Disability");
		this.listofEmploymentType.push("Retired");
	}

	private PrimaryDOBPicker = "1980-01-01";
	PrimaryDOBChange(firstTime: boolean = false) {
		if (
			this.FamilyCareObj.PrimaryDateOfBirthTemp != undefined &&
			this.FamilyCareObj.PrimaryDateOfBirthTemp != ""
		) {
			this.PrimaryDOBPicker = moment(
				this.FamilyCareObj.PrimaryDateOfBirthTemp
			).format("YYYY-MM-DD");
		}
		if (!firstTime) this.UpdatePlanAndSaveData();
	}

	private SpouseDOBPicker = "1980-01-01";
	SpouseDOBChange(firstTime: boolean = false) {
		if (
			this.FamilyCareObj.SpouseDateOfBirthTemp != undefined &&
			this.FamilyCareObj.SpouseDateOfBirthTemp != ""
		) {
			this.SpouseDOBPicker = moment(
				this.FamilyCareObj.SpouseDateOfBirthTemp
			).format("YYYY-MM-DD");
		}
		if (!firstTime) this.UpdatePlanAndSaveData();
	}

	showRecommendation() {
		// this.surveyform = false;
		this.viewRecommendation = true;
		// recommenditem
		// familyloader

		var familyloader = document.getElementsByClassName("familyloader");
		var recommenditem = document.getElementsByClassName("recommenditem");
		setTimeout(function () {
			let objDiv = document.getElementById("FamilyCareDiv");
			if (objDiv != null) objDiv.scrollTop = objDiv.scrollHeight;
		}, 100);
		setTimeout(function () {
			if (familyloader.length > 0) {
				familyloader[0].classList.add("d-none");
			}
			if (recommenditem.length > 0) {
				recommenditem[0].classList.remove("d-none");
				setTimeout(function () {
					let objDiv = document.getElementById("FamilyCareDiv");
					if (objDiv != null) objDiv.scrollTop = objDiv.scrollHeight;
					var items = document.getElementsByClassName("fade-item");
					for (let i = 0; i < items.length; ++i) {
						fadeIn(items[i], i * 200);
					}

					function fadeIn(item: any, delay: any) {
						setTimeout(() => {
							item.classList.add("slide-in-bottom");
						}, delay);
					}
				}, 200);
			}
		}, 3000);
	}

	closeRecommendation() {
		this.surveyform = true;
		this.viewRecommendation = false;
	}
	VeteranRelativeInfoChange() {
		this.FamilyCareObj.FamilyCareVeteranRelativeInfoString = JSON.stringify(
			this.VeteranRelativeInfo
		);
		this.saveData();
	}
	RelativeCampaignMedalsReceivedChange(evt: any) {
		if (evt[evt.length - 1] == "None") {
			this.VeteranRelativeInfo.CampaignMedalsReceived = ["None"];
		} else {
			const index =
				this.VeteranRelativeInfo.CampaignMedalsReceived.indexOf("None");
			if (index > -1)
				this.VeteranRelativeInfo.CampaignMedalsReceived.splice(index, 1);
		}
		this.VeteranRelativeInfoChange();
	}

	VSOMMembershipChange(evt: any) {
		if (evt != undefined) {
			this.FamilyCareObj.VSOMembership = evt;
		}
		this.saveData();
	}

	CurrentVSOMemberChange(evt: any) {
		if (evt[evt.length - 1] == "N/A")
			this.FamilyCareObj.CurrentVSOMember = ["N/A"];
		else {
			const index = this.FamilyCareObj.CurrentVSOMember.indexOf("N/A");
			if (index > -1) this.FamilyCareObj.CurrentVSOMember.splice(index, 1);
		}
		this.FamilyCareObj.CurrentVSOMember.forEach((event: any) => {
			if (!this.listofVeteranCurrentVSOList.includes(event)) {
				this.listofVeteranCurrentVSOList.push(event);
			}
		});
		this.saveData();
	}
	WarServiceChange(evt: any) {
		if (evt[evt.length - 1] == "N/A") this.FamilyCareObj.WarService = ["N/A"];
		else {
			if (evt[evt.length - 1] != undefined) {
				evt[evt.length - 1] = Camelize(evt[evt.length - 1]).trim();
			}
			const index = this.FamilyCareObj.WarService.indexOf("N/A");
			if (index > -1) this.FamilyCareObj.WarService.splice(index, 1);
		}
		this.FamilyCareObj.WarService.forEach((event: any) => {
			if (!this.listofWarService.includes(event)) {
				this.listofWarService.push(event);
			}
		});
		this.saveData();
	}

	CampaignMedalsReceivedChange(evt: any) {
		if (evt[evt.length - 1] == "None")
			this.FamilyCareObj.CampaignMedalsReceived = ["None"];
		else {
			const index = this.FamilyCareObj.CampaignMedalsReceived.indexOf("None");
			if (index > -1)
				this.FamilyCareObj.CampaignMedalsReceived.splice(index, 1);
		}
		this.saveData();
	}

	SpouseWarServiceChange(evt: any) {
		if (evt[evt.length - 1] == "N/A")
			this.FamilyCareObj.SpouseWarService = ["N/A"];
		else {
			if (evt[evt.length - 1] != undefined) {
				evt[evt.length - 1] = Camelize(evt[evt.length - 1]).trim();
			}
			const index = this.FamilyCareObj.SpouseWarService.indexOf("N/A");
			if (index > -1) this.FamilyCareObj.SpouseWarService.splice(index, 1);
		}
		this.FamilyCareObj.SpouseWarService.forEach((event: any) => {
			if (!this.listofWarServiceForSpouse.includes(event)) {
				this.listofWarServiceForSpouse.push(event);
			}
		});
		this.saveData();
	}

	SpouseCampaignMedalsReceivedChange(evt: any) {
		if (evt[evt.length - 1] == "None")
			this.FamilyCareObj.SpouseCampaignMedalsReceived = ["None"];
		else {
			const index =
				this.FamilyCareObj.SpouseCampaignMedalsReceived.indexOf("None");
			if (index > -1)
				this.FamilyCareObj.SpouseCampaignMedalsReceived.splice(index, 1);
		}
		this.saveData();
	}

	RelativeWarServiceChange(evt: any) {
		if (evt[evt.length - 1] == "N/A")
			this.VeteranRelativeInfo.WarService = ["N/A"];
		else {
			if (evt[evt.length - 1] != undefined) {
				evt[evt.length - 1] = Camelize(evt[evt.length - 1]).trim();
			}
			const index = this.VeteranRelativeInfo.WarService.indexOf("N/A");
			if (index > -1) this.VeteranRelativeInfo.WarService.splice(index, 1);
		}
		this.VeteranRelativeInfo.WarService.forEach((event: any) => {
			if (!this.listofWarServiceForRelative.includes(event)) {
				this.listofWarServiceForRelative.push(event);
			}
		});
		this.VeteranRelativeInfoChange();
	}

	UpdatePlanAndSaveData() {
		this.FamilyCareObj.isPlanCalculationNeeded = true;
		this.saveData();
	}

	@Watch("panel")
	OnChangePanelList(e: boolean) {
		this.showCompleteButton = true;
		this.viewRecommendation = false;
		if (this.panel.length > 0) {
			if (
				!this.panel.includes(
					this.collapsablePanelList.indexOf("MemberInformationForm")
				)
			) {
				this.$refs.MemberInformationForm?.resetValidation();
			}

			if (
				!this.panel.includes(
					this.collapsablePanelList.indexOf("InsuranceInformationForm")
				)
			) {
				this.$refs.InsuranceInformationForm?.resetValidation();
			}

			if (
				!this.panel.includes(
					this.collapsablePanelList.indexOf("PropertyInformationForm")
				)
			) {
				this.$refs.PropertyInformationForm?.resetValidation();
				this.isPropertyInformationFormValidated = true;
			}

			if (
				!this.panel.includes(
					this.collapsablePanelList.indexOf("BankingInformationForm")
				)
			) {
				this.$refs.BankingInformationForm?.resetValidation();
				this.isBankingInformationFormValidated = true;
			}
		} else {
			this.isPropertyInformationFormValidated = true;
			this.isBankingInformationFormValidated = true;
			this.$refs.MemberInformationForm?.resetValidation();
			this.$refs.InsuranceInformationForm?.resetValidation();
			this.$refs.PropertyInformationForm?.resetValidation();
			this.$refs.BankingInformationForm?.resetValidation();
		}
		return this.panel;
	}

	get IsNotesAdded() {
		if (
			this.FamilyCareObj.Notes != undefined &&
			this.FamilyCareObj.Notes != null &&
			this.FamilyCareObj.Notes != ""
		) {
			return true;
		}
		return false;
	}

	get LanguageFlag() {
		if (this.Presentation != undefined && this.Presentation != null) {
			if (this.Presentation.LangaugeFlag != "en") return true;
		} else return false;
	}

	get getDependentChildrenCount() {
		if (
			this.FamilyCareObj.DependentChildrenString != undefined &&
			this.FamilyCareObj.DependentChildrenString != ""
		) {
			var familychildren = JSON.parse(
				this.FamilyCareObj.DependentChildrenString
			);
			return familychildren.length;
		}
	}

	get getAdultChildrenCount() {
		if (
			this.FamilyCareObj.AdultChildrenString != undefined &&
			this.FamilyCareObj.AdultChildrenString != ""
		) {
			var familyAdultChildren = JSON.parse(
				this.FamilyCareObj.AdultChildrenString
			);
			return familyAdultChildren.length;
		}
	}

	get IsPrimaryMarried() {
		if (
			this.FamilyCareObj.HouseHoldType == "married" ||
			this.FamilyCareObj.HouseHoldType == "family" ||
			this.FamilyCareObj.HouseHoldType == "married/ retired"
		) {
			return true;
		}
		return false;
	}
	// get IsPrimaryVeteran() {
	// 	if (this.FamilyCareObj.IsPrimaryVeteran == true) {
	// 		return true;
	// 	}
	// 	return false;
	// }
	get PrimaryHasVeteranSpouse() {
		if (this.IsPrimaryMarried && this.FamilyCareObj.IsSpouseVeteran) {
			return true;
		}
		return false;
	}

	get IsPrimaryVSOEligibleForVFW() {
		if (this.FamilyCareObj.IsPrimaryVeteran) {
			if (
				this.CurrentStatusExistsForImages &&
				this.IsPrimaryCampaignOrWarSeviceNotNone
			) {
				return true;
			}
		}
		return false;
	}
	get IsPrimaryVSOEligibleForLegionOrAmvets() {
		if (this.FamilyCareObj.IsPrimaryVeteran) {
			if (this.CurrentStatusExistsForImages) {
				return true;
			}
		}
		return false;
	}

	get IsSpouseVSOEligibleForVFW() {
		if (this.PrimaryHasVeteranSpouse) {
			if (
				this.CurrentSpouseStatusExistsForImages &&
				this.IsSpouseCampaignOrWarSeviceNotNone
			) {
				return true;
			}
		}
		return false;
	}

	get IsSpouseVSOEligibleForLegionOrAmvets() {
		if (this.PrimaryHasVeteranSpouse) {
			if (this.CurrentSpouseStatusExistsForImages) {
				return true;
			}
		}
		return false;
	}

	get IsRelativeVSOEligibleForVFW() {
		if (!this.FamilyCareObj.IsPrimaryVeteran) {
			if (
				this.CurrentRelativeStatusExistsForImages &&
				this.RelationshipToYouExistsForImages &&
				this.IsVeteranRelativeCampaignOrWarSeviceNotNone
			) {
				return true;
			}
		}
		return false;
	}

	get IsRelativeVSOEligibleForLegionOrAmvets() {
		if (!this.FamilyCareObj.IsPrimaryVeteran) {
			if (
				this.veteranstatusCheckForVeteranImages.includes(
					this.VeteranRelativeInfo.CurrentVeteranStatus?.toLowerCase()
				)
			) {
				return true;
			}
		}
		return false;
	}

	get IsPrimaryCampaignOrWarSeviceNotNone() {
		var isPrimaryCampaignOrWarServiceValueNone =
			(this.FamilyCareObj.CampaignMedalsReceived.length > 0 &&
				this.FamilyCareObj.CampaignMedalsReceived[0] != "None") ||
			(this.FamilyCareObj.WarService.length > 0 &&
				this.FamilyCareObj.WarService[0] != "N/A");
		return isPrimaryCampaignOrWarServiceValueNone;
	}

	get IsVeteranRelativeCampaignOrWarSeviceNotNone() {
		var isRelativeCampaignOrWarServiceValueNone =
			(this.VeteranRelativeInfo.CampaignMedalsReceived.length > 0 &&
				this.VeteranRelativeInfo.CampaignMedalsReceived[0] != "None") ||
			(this.VeteranRelativeInfo.WarService.length > 0 &&
				this.VeteranRelativeInfo.WarService[0] != "N/A");

		return isRelativeCampaignOrWarServiceValueNone;
	}

	get IsSpouseCampaignOrWarSeviceNotNone() {
		var isSpouseCampaignOrWarServiceValueNone =
			(this.FamilyCareObj.SpouseCampaignMedalsReceived.length > 0 &&
				this.FamilyCareObj.SpouseCampaignMedalsReceived[0] != "None") ||
			(this.FamilyCareObj.SpouseWarService.length > 0 &&
				this.FamilyCareObj.SpouseWarService[0] != "N/A");
		return isSpouseCampaignOrWarServiceValueNone;
	}

	get RelationshipToYouExistsForImages() {
		var relForImages = this.relationshipCheckForVeteranImages.includes(
			this.VeteranRelativeInfo.RelationshipToYou
		);
		return relForImages;
	}
	get CurrentStatusExistsForImages() {
		var currentStatusForImages =
			this.veteranstatusCheckForVeteranImages.includes(
				this.FamilyCareObj.CurrentVeteranStatus?.toLowerCase()
			);
		return currentStatusForImages;
	}
	get CurrentSpouseStatusExistsForImages() {
		var currentSpouseStatusForImages =
			this.veteranstatusCheckForVeteranImages.includes(
				this.FamilyCareObj.SpouseCurrentVeteranStatus?.toLowerCase()
			);
		return currentSpouseStatusForImages;
	}

	get CurrentRelativeStatusExistsForImages() {
		var currentSpouseStatusForImages =
			this.veteranstatusCheckForVeteranImages.includes(
				this.VeteranRelativeInfo.CurrentVeteranStatus?.toLowerCase()
			);
		return currentSpouseStatusForImages;
	}

	get IsVSOSectionActive() {
		if (this.FamilyCareObj.IsPrimaryVeteran || this.PrimaryHasVeteranSpouse) {
			return true;
		}
		return false;
	}

	// get ShowVSOImages() {
	// 	if (this.IsVSOSectionActive) {
	// 		return (
	// 			this.FamilyCareObj.HonorableService == true ||
	// 			this.FamilyCareObj.HonorableService == null
	// 		);
	// 	} else {
	// 		return true;
	// 	}
	// }

	// get ShowAuxiliaryImages() {
	// 	if (this.IsPrimaryMarried) {
	// 		if (
	// 			!this.FamilyCareObj.IsPrimaryVeteran ||
	// 			!this.PrimaryHasVeteranSpouse
	// 		) {
	// 			return true;
	// 		}
	// 	} else {
	// 		if (!this.FamilyCareObj.IsPrimaryVeteran) return true;
	// 	}
	// 	return false;
	// }

	get FuneralInstructionsSectionActive() {
		if (this.FamilyCareObj.IsPrimaryVeteran) {
			this.PrimaryFuneralSection = true;
			if (!this.IsPrimaryMarried) {
				this.SpouseFuneralSection = false;
			} else if (this.IsPrimaryMarried) {
				this.SpouseFuneralSection = true;
			}
			return true;
		} else if (!this.FamilyCareObj.IsPrimaryVeteran) {
			if (this.PrimaryHasVeteranSpouse) {
				this.PrimaryFuneralSection = true;
				this.SpouseFuneralSection = true;
				return true;
			}
		}
		return false;
	}
	get IsHonorableServiceActiveAndFilled() {
		if (this.IsVSOSectionActive) {
			if (this.FamilyCareObj.HonorableService == true) {
				return true;
			}
			return false;
		}
		return true;
	}
	get IsServiceInWarActiveAndFilled() {
		if (this.IsVSOSectionActive) {
			if (this.FamilyCareObj.ServiceInWar == true) {
				return true;
			}
			return false;
		}
		return true;
	}
	get ActivateVFWImage() {
		if (this.IsPrimaryVSOEligibleForVFW || this.IsSpouseVSOEligibleForVFW) {
			return true;
		}
		return false;
	}
	get ActivateVFWAuxiliaryImage() {
		if (
			(this.IsPrimaryVSOEligibleForVFW && this.IsSpouseVSOEligibleForVFW) ||
			(this.IsPrimaryVSOEligibleForVFW && !this.IsPrimaryMarried)
		) {
			return false;
		}
		if (
			(this.IsPrimaryVSOEligibleForVFW && !this.IsSpouseVSOEligibleForVFW) ||
			(!this.IsPrimaryVSOEligibleForVFW && this.IsSpouseVSOEligibleForVFW) ||
			this.IsRelativeVSOEligibleForVFW
		) {
			return true;
		}

		// if (
		// 	!this.IsPrimaryVSOEligibleForVFW &&
		// 	(this.IsSpouseVSOEligibleForVFW || this.IsRelativeVSOEligibleForVFW)
		// ) {
		// 	return true;
		// }
		return false;
	}
	get ActivateAmericanLegionImage() {
		if (
			this.IsPrimaryVSOEligibleForLegionOrAmvets ||
			this.IsSpouseVSOEligibleForLegionOrAmvets
		) {
			return true;
		}
		return false;
	}
	get ActivateAuxiliaryLegionImage() {
		if (
			(this.IsPrimaryVSOEligibleForLegionOrAmvets &&
				this.IsPrimaryMarried &&
				!this.IsSpouseVSOEligibleForLegionOrAmvets) ||
			(!this.IsPrimaryVSOEligibleForLegionOrAmvets &&
				this.IsSpouseVSOEligibleForLegionOrAmvets) ||
			this.IsRelativeVSOEligibleForLegionOrAmvets
		) {
			return true;
		}
		return false;
	}

	get ActivateAmvetsImage() {
		if (
			this.IsPrimaryVSOEligibleForLegionOrAmvets ||
			this.IsSpouseVSOEligibleForLegionOrAmvets
		) {
			return true;
		} else {
			return false;
		}
	}
	get ActivateAmvetsAuxiliaryImage() {
		if (
			(this.IsPrimaryVSOEligibleForLegionOrAmvets &&
				this.IsPrimaryMarried &&
				!this.IsSpouseVSOEligibleForLegionOrAmvets) ||
			(!this.IsPrimaryVSOEligibleForLegionOrAmvets &&
				this.IsSpouseVSOEligibleForLegionOrAmvets) ||
			this.IsRelativeVSOEligibleForLegionOrAmvets
		) {
			return true;
		}
		return false;
	}
	get ActivateTextBelowImages() {
		if (
			(this.IsHonorableServiceActiveAndFilled &&
				(this.ActivateVFWImage || this.ActivateVFWAuxiliaryImage)) ||
			(this.IsServiceInWarActiveAndFilled &&
				(this.ActivateAmericanLegionImage ||
					this.ActivateAuxiliaryLegionImage ||
					this.ActivateAmvetsImage ||
					this.ActivateAmvetsAuxiliaryImage))
		) {
			return true;
		}
		return false;
	}

	AccountCheckboxChange(evt: any, fieldString: string) {
		if (fieldString == "Checking") {
			if (evt == false) {
				this.FamilyCareObj.CheckingAccountCount = null;
			} else this.FamilyCareObj.NoBankAccountChecked = false;
		}
		if (fieldString == "Savings") {
			if (evt == false) {
				this.FamilyCareObj.SavingAccountCount = null;
			} else this.FamilyCareObj.NoBankAccountChecked = false;
		}
		if (fieldString == "Investment") {
			if (evt == false) {
				this.FamilyCareObj.InvestmentAccountCount = null;
			} else this.FamilyCareObj.NoBankAccountChecked = false;
		}
		if (
			this.FamilyCareObj.SavingAccountChecked ||
			this.FamilyCareObj.InvestmentAccountChecked ||
			this.FamilyCareObj.CheckingAccountChecked
		) {
			this.ischeckboxchecked = true;
		}
		this.saveData();
	}

	AccountFieldsChange() {
		if (Number(this.FamilyCareObj.SavingAccountCount) > 0) {
			this.FamilyCareObj.SavingAccountChecked = true;
			this.FamilyCareObj.NoBankAccountChecked = false;
		}
		if (Number(this.FamilyCareObj.InvestmentAccountCount) > 0) {
			this.FamilyCareObj.InvestmentAccountChecked = true;
			this.FamilyCareObj.NoBankAccountChecked = false;
		}
		if (Number(this.FamilyCareObj.CheckingAccountCount) > 0) {
			this.FamilyCareObj.CheckingAccountChecked = true;
			this.FamilyCareObj.NoBankAccountChecked = false;
		}
		if (
			this.FamilyCareObj.SavingAccountChecked ||
			this.FamilyCareObj.InvestmentAccountChecked ||
			this.FamilyCareObj.CheckingAccountChecked
		) {
			this.ischeckboxchecked = true;
		}
		this.saveData();
	}

	NoAccountCheckChange(evt: any) {
		if (evt == true) {
			this.FamilyCareObj.CheckingAccountChecked = false;
			this.FamilyCareObj.CheckingAccountCount = null;
			this.FamilyCareObj.SavingAccountChecked = false;
			this.FamilyCareObj.SavingAccountCount = null;
			this.FamilyCareObj.InvestmentAccountChecked = false;
			this.FamilyCareObj.InvestmentAccountCount = null;
			this.ischeckboxchecked = true;
		}
		this.saveData();
	}
	get GetPrimaryADB() {
		if (
			this.PrimaryInsuranceThroughWork != null &&
			this.PrimaryInsuranceThroughWork != undefined &&
			this.PrimaryInsuranceThroughWork.length > 0
		) {
			let test = this.CalculateInsurances(
				this.PrimaryInsuranceThroughWork
			).split("|");
			// this.SumPrimaryInsuranceThroughWorkWHL =
			// 	test[0] != ""
			// 		? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
			// 		: null;
			// this.SumPrimaryInsuranceThroughWorkTERM =
			// 	test[1] != ""
			// 		? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
			// 		: null;
			// this.SumPrimaryInsuranceThroughWorkADB =
			// 	test[2] != ""
			// 		? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
			// 		: null;
			this.FamilyCareObj.SumPrimaryInsuranceThroughWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.FamilyCareObj.SumPrimaryInsuranceThroughWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.FamilyCareObj.SumPrimaryInsuranceThroughWorkADB =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}
		if (
			this.PrimaryInsuranceOutsideWork != null &&
			this.PrimaryInsuranceOutsideWork != undefined &&
			this.PrimaryInsuranceOutsideWork.length > 0
		) {
			let test = this.CalculateInsurances(
				this.PrimaryInsuranceOutsideWork
			).split("|");
			this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkADB =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}
		if (
			this.SpouseInsuranceThroughWork != null &&
			this.SpouseInsuranceThroughWork != undefined &&
			this.SpouseInsuranceThroughWork.length > 0
		) {
			let test = this.CalculateInsurances(
				this.SpouseInsuranceThroughWork
			).split("|");
			this.FamilyCareObj.SumSpouseInsuranceThroughWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.FamilyCareObj.SumSpouseInsuranceThroughWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.FamilyCareObj.SumSpouseInsuranceThroughWorkADB =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}
		if (
			this.SpouseInsuranceOutsideWork != null &&
			this.SpouseInsuranceOutsideWork != undefined &&
			this.SpouseInsuranceOutsideWork.length > 0
		) {
			let test = this.CalculateInsurances(
				this.SpouseInsuranceOutsideWork
			).split("|");
			this.FamilyCareObj.SumSpouseInsuranceOutsideWorkWholeLife =
				test[0] != ""
					? ConvertIntoAmountFormatWithDecimal(test[0], true, true)
					: null;
			this.FamilyCareObj.SumSpouseInsuranceOutsideWorkTermLife =
				test[1] != ""
					? ConvertIntoAmountFormatWithDecimal(test[1], true, true)
					: null;
			this.FamilyCareObj.SumSpouseInsuranceOutsideWorkADB =
				test[2] != ""
					? ConvertIntoAmountFormatWithDecimal(test[2], true, true)
					: null;
		}
		return this.FamilyCareObj.SumPrimaryInsuranceThroughWorkADB;
	}
	CalculateInsurances(insurance: any) {
		let WHL: any;
		let Term: any;
		let Adb: any;
		insurance.forEach((element: any) => {
			switch (element.InsuranceType) {
				case "WHL":
					if (element.InsuranceAmount != null) {
						WHL =
							WHL != undefined
								? (WHL += Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  ))
								: Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  );
					}
					break;
				case "TERM":
					if (element.InsuranceAmount != null) {
						Term =
							Term != undefined
								? (Term += Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  ))
								: Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  );
					}
					break;
				case "ADB":
					if (element.InsuranceAmount != null) {
						Adb =
							Adb != undefined
								? (Adb += Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  ))
								: Number.parseFloat(
										element.InsuranceAmount.toString().replaceAll(",", "")
								  );
					}
					break;
				default:
					break;
			}
		});
		let Result = "";
		// WHL = isNaN(WHL) ? 0 : WHL;
		// Term = isNaN(Term) ? 0 : Term;
		// Adb = isNaN(Adb) ? 0 : Adb;
		Result =
			WHL == null || WHL == undefined
				? Result.concat("|")
				: Result.concat(WHL.toString(), "|");
		Result =
			Term == null || Term == undefined
				? Result.concat("|")
				: Result.concat(Term.toString(), "|");
		Result =
			Adb == null || Adb == undefined
				? Result.concat("|")
				: Result.concat(Adb.toString(), "|");
		return Result;
	}
	get GetTotalPayment() {
		if (
			this.FamilyCareProperties != null &&
			this.FamilyCareProperties != undefined &&
			this.FamilyCareProperties.length > 0
		) {
			let own = false;
			let rent = false;
			let rentpayment = false;
			let rentmortgage = false;
			let rentrate = false;
			let rentyears = false;
			let payment = 0;
			let Mortgage = 0;
			let Rate = 0;
			let YrsRemaining = 0;
			this.FamilyCareProperties.forEach((property) => {
				if (property.OwnOrRent != null) {
					// if (property.OwnOrRent != null) {
					own = !own && property.OwnOrRent == "OWN" ? true : own;
					rent = !rent && property.OwnOrRent == "RENT" ? true : rent;
					// }
					//own and rent not available check
					if (property.MonthlyRent != null) {
						payment += Number.parseFloat(
							property.MonthlyRent.replaceAll(",", "")
						);
					}
					if (property.MortgageBalance != null) {
						Mortgage += Number.parseFloat(
							property.MortgageBalance.replaceAll(",", "")
						);
					}
					if (property.MortgageInterestRate != null) {
						let propertiesInterestrate = Number.parseFloat(
							property.MortgageInterestRate.replaceAll(",", "").replaceAll(
								"%",
								""
							)
						);
						Rate =
							Rate < propertiesInterestrate ? propertiesInterestrate : Rate;
					}
					if (property.MortgageYearsRemaining != null) {
						YrsRemaining =
							YrsRemaining <
							Number.parseFloat(
								property.MortgageYearsRemaining.replaceAll(",", "")
							)
								? Number.parseFloat(
										property.MortgageYearsRemaining.replaceAll(",", "")
								  )
								: YrsRemaining;
					}
					// if (property.OwnOrRent == "RENT") {
					if (property.MonthlyRent != null) rentpayment = true;
					if (property.MortgageBalance != null) rentmortgage = true;
					if (property.MortgageInterestRate != null) rentrate = true;
					if (property.MortgageYearsRemaining != null) rentyears = true;
					// }
				}
			});
			this.FamilyCareObj.FamilyCarePropertySum = rentpayment
				? ConvertIntoAmountFormatWithDecimal(payment.toFixed(2).toString())
				: null;
			this.FamilyCareObj.FamilyCarePropertyOwn = own;
			this.FamilyCareObj.FamilyCarePropertyRent = rent;
			let OwnHomeWithZeroMonthlyRent =
				this.FamilyCareObj.FamilyCarePropertySum == "0.00" &&
				this.FamilyCareObj.FamilyCarePropertyOwn;
			if (this.isRentSelected && rentmortgage == false) {
				this.FamilyCareObj.FamilyCarePropertyMortgage = null;
			} else {
				this.FamilyCareObj.FamilyCarePropertyMortgage =
					rentmortgage || OwnHomeWithZeroMonthlyRent
						? ConvertIntoAmountFormatWithDecimal(Mortgage.toFixed(2).toString())
						: null;
			}
			if (this.isRentSelected && rentrate == false) {
				this.FamilyCareObj.FamilyCarePropertyRate = null;
			} else {
				this.FamilyCareObj.FamilyCarePropertyRate =
					rentrate || OwnHomeWithZeroMonthlyRent
						? Rate.toFixed(2).toString() + "%"
						: null;
			}
			if (this.isRentSelected && rentyears == false) {
				this.FamilyCareObj.FamilyCarePropertyYrsRemaining = null;
			} else {
				this.FamilyCareObj.FamilyCarePropertyYrsRemaining =
					rentyears || OwnHomeWithZeroMonthlyRent
						? YrsRemaining.toString()
						: null;
			}
			return this.FamilyCareObj.FamilyCarePropertySum;
		}
		return null;
	}
	get GetAnnualAssessmentConsideration() {
		var tempPersonAnnualIncome = 0;
		if (typeof this.FamilyCareObj.PrimaryAnnualIncome == "string")
			tempPersonAnnualIncome =
				this.FamilyCareObj.PrimaryAnnualIncome.replaceAll(",", "");
		var tempSpouseAnnualIncome = 0;
		if (typeof this.FamilyCareObj.SpouseAnnualIncome == "string")
			tempSpouseAnnualIncome = this.FamilyCareObj.SpouseAnnualIncome.replaceAll(
				",",
				""
			);
		var tempincome = Number(
			(+tempPersonAnnualIncome + +tempSpouseAnnualIncome).toFixed()
		);
		var tempRecommendedLifeIns = tempincome * 10;
		var tempExistingLifeIns = Number(
			(
				+(this.FamilyCareObj.SumPrimaryInsuranceThroughWorkWholeLife !=
				undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumPrimaryInsuranceThroughWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumPrimaryInsuranceThroughWorkTermLife != undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumPrimaryInsuranceThroughWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkWholeLife !=
				undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkTermLife != undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumPrimaryInsuranceOutsideWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumSpouseInsuranceThroughWorkWholeLife != undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumSpouseInsuranceThroughWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumSpouseInsuranceThroughWorkTermLife != undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumSpouseInsuranceThroughWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumSpouseInsuranceOutsideWorkWholeLife != undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumSpouseInsuranceOutsideWorkWholeLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0) +
				+(this.FamilyCareObj.SumSpouseInsuranceOutsideWorkTermLife != undefined
					? Number.parseFloat(
							this.FamilyCareObj.SumSpouseInsuranceOutsideWorkTermLife.toString().replaceAll(
								",",
								""
							)
					  )
					: 0)
			).toFixed()
		);
		var tempRemainingIns = +tempRecommendedLifeIns - +tempExistingLifeIns;
		return ConvertIntoAmountFormat(tempRemainingIns.toFixed());
	}
	get GetShowAssessmentResults() {
		let assessment = Number.parseFloat(
			this.GetAnnualAssessmentConsideration.replaceAll(",", "")
		);
		if (assessment > 0) {
			return true;
		}
		return false;
	}
	get GetAssessmentResults() {
		let assessment = Number.parseFloat(
			this.GetAnnualAssessmentConsideration.replaceAll(",", "")
		);
		if (this.FamilyCareObj.CheckingAccountChecked && assessment > 0) {
			return true;
		}
		return false;
	}
	get IsVeteranSMBAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId == PresentationType.Veteran &&
			this.Presentation.PresentationSubTypeId ==
				PresentationSubType.ServiceMemberBenefitsLeadAlpha
		) {
			return true;
		}
		return false;
	}
	get IsServiceMemberBenefitsAlpha() {
		if (
			this.Presentation != undefined &&
			this.Presentation.PresentationTypeId ==
				PresentationType.ServiceMemberBenefits &&
			(this.Presentation.PresentationSubTypeId ==
				PresentationSubType.SMBSponsoredVeteranLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.SMBVeteranFamilyLeadAlpha ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.Civilian ||
				this.Presentation.PresentationSubTypeId ==
					PresentationSubType.ServiceMemberBenefitNotification)
		) {
			return true;
		}
		return false;
	}
	savePrimaryCivilianData() {
		if (this.FamilyCareObj.IsPrimaryCivilian) {
			this.viewRecommendation = false;
			this.showCompleteButton = true;
		}
	}
	checkFormValidation() {
		this.isFormValidated = true;
		// this.isMemberInformationFormValidated = true;
		this.isInsuranceInformationFormValidated = true;
		this.isPropertyInformationFormValidated = true;
		this.isBankingInformationFormValidated = true;
		this.ischeckboxchecked = true;
		this.isbanklocallychecked = true;
		this.ishonrableservicechecked = true;
		this.isserviceinwarchecked = true;
		// if (
		// 	this.FamilyCareObj.PrimaryFirstName == null ||
		// 	this.FamilyCareObj.PrimaryFirstName.trim() == ""
		// ) {
		// 	// this.isFormValidated = false;
		// 	this.isMemberInformationFormValidated = false;
		// }
		// if (
		// 	this.FamilyCareObj.PrimaryLastName == null ||
		// 	this.FamilyCareObj.PrimaryLastName.trim() == ""
		// ) {
		// 	// this.isFormValidated = false;
		// 	this.isMemberInformationFormValidated = false;
		// }
		if (this.FamilyCareObj.IsPrimaryCivilian) {
			return this.$refs.ReferralForm?.validate();
		}

		if (
			!(
				this.FamilyCareObj.CheckingAccountChecked ||
				this.FamilyCareObj.SavingAccountChecked ||
				this.FamilyCareObj.InvestmentAccountChecked ||
				this.FamilyCareObj.NoBankAccountChecked
			)
		) {
			this.ischeckboxchecked = false;
			// this.isFormValidated = false;
			this.isBankingInformationFormValidated = false;
		}
		if (this.IsVSOSectionActive) {
			if (this.FamilyCareObj.HonorableService == null) {
				this.ishonrableservicechecked = false;
				this.isFormValidated = false;
			}
			if (this.FamilyCareObj.ServiceInWar == null) {
				this.isserviceinwarchecked = false;
				this.isFormValidated = false;
			}
		}
		if (
			!(
				this.FamilyCareObj.HasCheckingAccount ||
				this.FamilyCareObj.HasSavingAccount
			)
		) {
			this.isbanklocallychecked = false;
			// this.isFormValidated = false;
			this.isBankingInformationFormValidated = false;
		}
		if (
			this.FamilyCareObj.FamilyCareSurveyPropertyString != null &&
			this.FamilyCareObj.FamilyCareSurveyPropertyString != ""
		) {
			let property = JSON.parse(
				this.FamilyCareObj.FamilyCareSurveyPropertyString
			);
			this.ValidateProperties(property);
		} else {
			this.isPropertyInformationFormValidated = false;
			this.isPropertyAdded = false;
			this.isRentOwnChecked = false;
		}

		let checkformdata = this.$refs.ReferralForm?.validate();
		let checkMemberInformationFormdata = true;
		let checkInsuranceInformationFormdata = true;
		let checkPropertyInformationFormdata = true;
		let checkBankingInformationFormdata = true;

		if (this.panel.length > 0) {
			if (
				!this.panel.includes(
					this.collapsablePanelList.indexOf("PropertyInformationForm")
				)
			) {
				this.isPropertyInformationFormValidated = true;
			}
			if (
				!this.panel.includes(
					this.collapsablePanelList.indexOf("BankingInformationForm")
				)
			) {
				this.isBankingInformationFormValidated = true;
			}

			this.panel.forEach((element: any) => {
				if (
					element == this.collapsablePanelList.indexOf("MemberInformationForm")
				) {
					checkMemberInformationFormdata =
						this.$refs.MemberInformationForm?.validate();
				}

				if (
					element ==
					this.collapsablePanelList.indexOf("InsuranceInformationForm")
				) {
					checkInsuranceInformationFormdata =
						this.$refs.InsuranceInformationForm?.validate() &&
						this.isInsuranceInformationFormValidated;
				}

				if (
					element ==
					this.collapsablePanelList.indexOf("PropertyInformationForm")
				) {
					checkPropertyInformationFormdata =
						this.$refs.PropertyInformationForm?.validate() &&
						this.isPropertyInformationFormValidated;
				}

				if (
					element == this.collapsablePanelList.indexOf("BankingInformationForm")
				) {
					checkBankingInformationFormdata =
						this.$refs.BankingInformationForm?.validate() &&
						this.isBankingInformationFormValidated;
				}
			});
		} else {
			this.isPropertyInformationFormValidated = true;
			this.isBankingInformationFormValidated = true;
		}

		return (
			checkformdata &&
			this.isFormValidated &&
			checkMemberInformationFormdata &&
			checkInsuranceInformationFormdata &&
			checkPropertyInformationFormdata &&
			checkBankingInformationFormdata
		);
	}
	ValidateProperties(properties: any) {
		this.isPropertyAdded = true;
		this.isRentSelected = true;
		this.isRentOwnChecked = false;
		if (properties.length > 0) {
			this.isPropertyAdded = false;
			for (let index = 0; index < properties.length; index++) {
				const element = properties[index];
				if (element.OwnOrRent == "OWN") {
					this.isRentSelected = false;
					this.isRentOwnChecked = true;
					if (
						element.MonthlyRent == 0 ||
						(element.MonthlyRent != null && element.MortgageBalance == 0) ||
						(element.MonthlyRent != null &&
							element.MortgageBalance != null &&
							element.MortgageInterestRate != null &&
							element.MortgageYearsRemaining != null)
					) {
						this.isPropertyAdded = true;
						break;
					}
				} else if (element.OwnOrRent == "RENT") {
					if (this.isRentSelected) {
						this.isRentSelected = true;
						this.isRentOwnChecked = true;
					}
					if (element.MonthlyRent != null) {
						let monthRent = Number.parseFloat(
							element.MonthlyRent.replaceAll(",", "")
						);
						if (monthRent == 0 || monthRent > 0) {
							this.isPropertyAdded = true;
							break;
						}
					}
				}
			}
		} else {
			this.isPropertyInformationFormValidated = false;
			this.isPropertyAdded = false;
			this.isRentSelected = false;
			this.FamilyCareObj.FamilyCarePropertyOwn = null;
			this.FamilyCareObj.FamilyCarePropertyRent = null;
			this.FamilyCareObj.FamilyCarePropertyMortgage = null;
			this.FamilyCareObj.FamilyCarePropertyRate = null;
			this.FamilyCareObj.FamilyCarePropertyYrsRemaining = null;
		}
	}
	InputAmountField(evt: any, fieldName: string = "") {
		if (
			evt.target != undefined &&
			evt.target.value != undefined &&
			evt.target.value != ""
		) {
			if (evt.target.value != "0") {
				var previusSelectionIndex = evt.target.selectionStart;
				evt.target.value = ConvertIntoAmountFormatWithDecimal(evt.target.value);
				var newSelectionIndex = evt.target.selectionStart;
				if (newSelectionIndex > previusSelectionIndex + 1) {
					evt.target.setSelectionRange(
						previusSelectionIndex,
						previusSelectionIndex
					);
				}
			}
		}
	}
	ApplyValuebyFieldString(fieldString: string, value: string) {
		var properties = fieldString.split(".");
		var setPropertyvalue: any = this.$data;
		if (properties != undefined && properties.length > 0) {
			var i = 1;
			properties.forEach((element: any) => {
				if (i == properties.length) {
					setPropertyvalue[element] = value;
				} else {
					setPropertyvalue = setPropertyvalue[element];
				}
				i++;
			});
		}
	}
	AmountFieldBlur(evt: any, fieldString: any) {
		let value = evt.target.value;
		if (value.includes("-")) {
			value = value.replaceAll("-", "");
		}
		var newValue = value;
		// if (
		// 	fieldString == "Person.Wage" &&
		// 	!this.Presentation.PremiumValuesCustomized
		// ) {
		// 	this.setPremiumasPerWageType(
		// 		Number(value.replaceAll(",", "")),
		// 		"Primary"
		// 	);
		// }
		// if (
		// 	fieldString == "Person.Spouse.Wage" &&
		// 	!this.Presentation.PremiumValuesCustomized
		// ) {
		// 	this.setPremiumasPerWageType(Number(value.replaceAll(",", "")), "Spouse");
		// }
		if (value == "") {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal("0", true, true)
			);
		} else {
			this.ApplyValuebyFieldString(
				fieldString,
				ConvertIntoAmountFormatWithDecimal(
					Number(value.replaceAll(",", "")).toFixed(2),
					true,
					true
				)
			);
		}
		this.saveData();
	}
	OpenSurveyInfoBTNPress() {
		this.ShowFullSurveyInformation = true;
		this.panel = [];
		setTimeout(function () {
			let objDiv = document.getElementById("FamilyCareDiv");
			if (objDiv != null) objDiv.scrollTop = objDiv.scrollHeight;
		}, 30);
	}
	CloseSurveyInfoBTNPress() {
		this.ShowFullSurveyInformation = false;
		this.panel = [];
	}
	CompleteBTNPress() {
		let AllFormValid = this.checkFormValidation();

		if (AllFormValid) {
			this.showCompleteButton = false;
		} else {
			this.showCompleteButton = true;
			this.$store.state.messageText = "Please fill the required fields";
			this.$store.state.showMessage = true;
		}
	}
	saveDataBTNPress() {
		let AllFormValid = this.checkFormValidation();
		// this.checkFormValidation();
		// let checkformdata = this.$refs.ReferralForm?.validate();
		// if (this.isFormValidated == true && checkformdata) {
		if (AllFormValid) {
			this.FamilyCareObj.IsFormSubmited = true;
			this.CloseFormInside();
			this.$store.state.messageText =
				"Last Will & Testament Preparation Survey Saved";
			this.$store.state.showMessage = true;
		} else {
			this.showCompleteButton = true;
			this.$store.state.messageText = "Please fill the required fields";
			this.$store.state.showMessage = true;
			setTimeout(() => {
				var svview = document.getElementsByClassName("error--text");
				if (svview != undefined && svview.length > 0) {
					{
						svview[0].scrollIntoView(true);
					}
				} else {
					var svview = document.getElementsByClassName("SponsorCheckValidate");
					if (svview != undefined && svview.length > 0) {
						{
							svview[0].scrollIntoView(true);
						}
					}
				}
			}, 500);
			this.$store.state.messageText = "Required Fields Missing";
			this.$store.state.showMessage = true;
		}
	}
	// get IsCivilianSelected() {
	// 	if (
	// 		this.FamilyCareObj.BranchOfService == "Civilian" ||
	// 		this.FamilyCareObj.BranchOfService == "Family Member Of A Veteran"
	// 	) {
	// 		this.FamilyCareObj.CurrentVeteranStatus = "not a veteran";
	// 		return true;
	// 	}
	// 	return false;
	// }

	saveData() {
		this.$emit("SaveFamilyCareSurvey", this.FamilyCareObj, false);
	}
	CloseForm(Obj: any) {
		this.$emit("SaveFamilyCareSurvey", this.FamilyCareObj, true);
	}
	CloseFormInside() {
		this.$emit("SaveFamilyCareSurvey", this.FamilyCareObj, true);
	}

	PlayVideo() {
		this.FamilyCareObj.VideoClicked = true;
		this.saveData();
		this.ShowVideo = true;
	}

	CloseVideo() {
		this.ShowVideo = false;
	}
}
