











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { gsap } from "gsap";
import PrimaryPerson from "@/entities/PrimaryPerson";
import Spouse from "@/entities/Spouse";
import WillKitChildren from "@/entities/WillKitChildren";
//import PresentationWillKitVeterans from "@/entities/PresentationWillKitVeterans";
import { getPersistedState, setPersistedState } from "@/helpers/StateMapper";
import { GetApiDataOrCheckLocal } from "@/helpers/APIHelper";
import GenericKeyPair from "@/models/GenericKeyPair";
import { ApiEndPointConstants } from "@/constants/ApiEndPointConstants";
import { IndexDBConstants } from "@/constants/IndexDBConstants";
import { GetAscFullNameWithCamelCase } from "@/helpers/APIExtentions";
import Presentation from "@/entities/Presentation";
let RelationshipList = require("@/assets/pre-fill-data/relation_sponsor.json");
import VueHtml2pdf from "vue-html2pdf";
import { PrefillDocumentType } from "@/enumerations/PrefillDocumentType";
import { EntityHelper } from "@/helpers/EntityHelper";

@Component({ components: { VueHtml2pdf } })
export default class VeteranLastWillAndTestamentPdfHtml extends Vue {
	private preDisable: boolean = true;
	private nextDisable: boolean = false;
	// private pageNumbers: Number = 1;
	// private totalNumbers: Number = 1;
	// private totalPages: any = 0;
	private personNotifiedItems: boolean = true;
	private veteransInfoItems: boolean = true;
	private insurancePolItems: boolean = true;
	private funeralInstItems: boolean = true;
	private disabledNavButton: boolean = false;
	private Person: any;
	private Spouse: any;
	//private PresentationWillKitVeterans: any;
	private ReferralsFamily: any[] = [];
	private ReferralsAdditional: any[] = [];
	private ReferralsService: any[] = [];
	private WillKitChildren: any[] = [];
	private ReferralsFamilyCount: number = 1;
	private ReferralsAdditionalCount: number = 1;
	private ReferralsServiceCount: number = 1;
	private WillKitChildrenCount: number = 1;
	private StateList: any = [];
	private stateListPromise: any = [];
	private freedomchoice: boolean = false;
	private additionalGroup: any = [];
	private serviceGroup: any = [];
	private UserNameForFredomofChoise = GetAscFullNameWithCamelCase();
	private BranchItems = require("../../assets/pre-fill-data/branch-of-service-types.json");
	private InvalidEmail = false;
	private PageNumbers: any = [1, 3, 5, 7, 9];
	private Presentation: any = [];
	private RelationshiptoSp: any = RelationshipList;
	private InitialPerson: any;
	private InitialSpouse: any;
	private InitialVeteranObj: any;

	private FirstTimePopupShown: boolean = false;
	private AnyChangesMade: boolean = false;

	@Prop({ default: undefined })
	private PresentationWillKitVeterans: any;

	@Prop({ default: 1 })
	private CountryId!: number;

	get isCanadaState() {
		if (this.CountryId == 2) return true;
		else return false;
	}

	data() {
		return {
			willKey: 1,
			familylength: 1,
			additionallength: 1,
			FamilyPdfTab: null,
			preDisable: true,
			nextDisable: false,
			CurrentPageNumbers: 1,
			totalPageCount: 9,
			PagesZoomIndex: [],
			VitalStatSectionDisabled: true,
			SpouseVitalStatSectionDisabled: true,
			ChildrenSectionDisabled: true,
			VeteranInfoSectionDisabled: true,
			VeteranInfoSectionDisabledService: true,
			PersonNotifySectionDisabled: true,
			pdfoptions: {
				margin: 6,
				filename: "hehehe.pdf",
				pagebreak: { mode: ["css"] },
				html2canvas: { useCORS: true, scale: 4 },
			},
			DOBRules: [
				(value: any) => {
					if (value != undefined && value.length > 0) {
						var currentDate = new Date();
						var currentYear = currentDate.getFullYear().toString();
						var yearString =
							"20[02][0-" + currentYear[currentYear.length - 1] + "]";
						var stringRegex =
							"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
							yearString +
							")\\b$";
						var regex = new RegExp(stringRegex);
						// const pattern =
						//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
						return regex.test(value) || "Invalid DOB.";
					} else {
						return false;
					}
				},
			],
		};
	}

	ShowFreedomOfChoicePopup(evt: any) {
		this.freedomchoice = true;
		evt.stopPropagation();
	}

	get ZipCodeFormat() {
		if (this.isCanadaState) return "XXX XXX";
		else return "#####-####";
	}

	RelationshiptoSpchange(event: any, itemRef: any) {
		itemRef.Relationship = event;
		if (event.text != undefined) itemRef.RelationshipObj = event.text;
		else itemRef.RelationshipObj = event;

		this.SaveReferals();
		this.SaveWillKitWithPresentation();
	}

	created() {
		this.Presentation = Presentation.query().withAllRecursive().last();
		if (
			this.Presentation != undefined &&
			this.Presentation.FamilyCareSurvey != "" &&
			this.Presentation.FamilyCareSurvey != undefined
		) {
			this.PresentationWillKitVeterans.FuneralType = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).PrimaryFuneralType;
			this.PresentationWillKitVeterans.VeteransType = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).PrimaryCemeteryType;
			this.PresentationWillKitVeterans.BranchOfService = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).BranchOfService;
		}
		let parameters: GenericKeyPair[] = [];
		this.$store.state.loader = true;
		GetApiDataOrCheckLocal(
			ApiEndPointConstants.ReferralGroupCodeEndpoint,
			parameters,
			IndexDBConstants.ReferralGroupCode
		)
			.then((res: any) => {
				this.$store.state.loader = false;

				if (res != undefined) {
					this.additionalGroup = res.find((x: any) => x.GroupCode == "IGVFL");

					this.serviceGroup = res.find((x: any) => x.GroupCode == "IGSVL");
					this.LoadPreloadReferals();
				}
			})
			.catch();
		this.isChildDOBValid = new Array(6).fill(true);
		this.loadDropdown();
		this.Person = PrimaryPerson.query().last();
		this.Spouse = Spouse.query().last();

		if (this.Person.Gender != "" && this.Person.Gender != undefined) {
			this.PresentationWillKitVeterans.PrimaryGender = this.Person.Gender;
			this.Person.IsNonBinary = null;
		}
		if (this.Spouse.Gender != "" && this.Spouse.Gender != undefined) {
			this.PresentationWillKitVeterans.SpouseGender = this.Spouse.Gender;
			this.Spouse.IsNonBinary = null;
		}
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		this.Person = PrimaryPerson.query().last();
		this.Spouse = Spouse.query().last();
		if (
			this.Presentation != undefined &&
			this.Presentation.FamilyCareSurvey != "" &&
			this.Presentation.FamilyCareSurvey != undefined
		) {
			this.PresentationWillKitVeterans.FuneralType = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).PrimaryFuneralType;
			this.PresentationWillKitVeterans.VeteransType = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).PrimaryCemeteryType;
			this.PresentationWillKitVeterans.BranchOfService = JSON.parse(
				this.Presentation.FamilyCareSurvey
			).BranchOfService;
		}

		setTimeout(() => {
			let sliderContainer =
				document.getElementsByClassName("PdfHtmlContainer")[0].children;
			for (let index = 0; index < this.$data.totalPageCount; index++) {
				this.$data.PagesZoomIndex.push(0);
			}
			if (
				this.PresentationWillKitVeterans?.DocumentTypeIdList?.indexOf(
					PrefillDocumentType.LastWillAndTestament
				) == -1
			) {
				this.PresentationWillKitVeterans?.DocumentTypeIdList?.push(
					PrefillDocumentType.LastWillAndTestament
				);
			}
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
			this.InitialSpouse = JSON.stringify(this.Spouse);
			this.Person.Spouse = this.Spouse;
			this.InitialPerson = JSON.stringify(this.Person);
		}, 100);
		//setTimeout(() => {
		//this.startdrag();
		//}, 1000);
	}

	DOBChange(evt: any, item: any, index: number) {
		if (item.DateOfBirth != undefined && item.DateOfBirth != "") {
			//item.DOBPicker = moment(item.DateOfBirth).format("YYYY-MM-DD");
			item.AgePerDOB = EntityHelper.calculate_age(item.DateOfBirth);
		}
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear().toString();
		var yearString = "20[02][0-" + currentYear[currentYear.length - 1] + "]";
		var stringRegex =
			"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
			yearString +
			")\\b$";
		var regex = new RegExp(stringRegex);
		// const pattern =
		//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
		var test = regex.test(evt.target.value); // || "Invalid DOB.";
		this.isChildDOBValid[index] = test;
		this.PresentationWillKitVeterans.WillKitChildren = JSON.stringify(
			this.WillKitChildren
		);
		this.$data.ChildrenSectionDisabled = true;
		this.$data.ChildrenSectionDisabled = false;
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	PrimaryGenderUpdate(e: any) {
		let gender = e.target.value;
		this.Person.IsNonBinary = null;
		if (gender != "NonBinary") {
			this.Person.Gender = gender;
		} else {
			this.Person.IsNonBinary = true;
		}
		this.PresentationWillKitVeterans.isPlanCalculationNeeded = true;
		this.PrimaryPersonUpdate(null);
		this.SaveWillKitWithPresentation();
	}

	SpouseGenderUpdate(e: any) {
		let gender = e.target.value;
		this.Spouse.IsNonBinary = null;
		if (gender != "NonBinary") {
			this.Spouse.Gender = gender;
		} else {
			this.Spouse.IsNonBinary = true;
		}
		this.PresentationWillKitVeterans.isPlanCalculationNeeded = true;
		this.SpouseUpdate();
		this.SaveWillKitWithPresentation();
	}

	SpouseMaritalStatusUpdate(e: any) {
		let MaritalStatus = e.target.value;
		this.Spouse.MaritalStatus = MaritalStatus;
		this.SpouseUpdate();
	}

	validateEmail() {
		if (this.Person.Email == "") {
			this.InvalidEmail = false;
			this.PrimaryPersonUpdate(null);
			return;
		}

		var valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
			this.Person.Email
		);
		if (!valid) this.InvalidEmail = true;
		else {
			this.InvalidEmail = false;
			this.PrimaryPersonUpdate(null);
		}
	}

	EnabledChildrenSectionClick(evt: any) {
		this.$data.ChildrenSectionDisabled = false;
		evt.stopPropagation();
	}

	LoadPreloadReferals() {
		if (this.PresentationWillKitVeterans.ReferralsFamily) {
			this.ReferralsFamily = JSON.parse(
				this.PresentationWillKitVeterans.ReferralsFamily
			);
		} else {
			for (
				this.ReferralsFamilyCount = 1;
				this.ReferralsFamilyCount <= 3;
				this.ReferralsFamilyCount++
			) {
				this.ReferralsFamily.push({
					Id: this.ReferralsFamilyCount,
					FirstName: "",
					LastName: "",
					GroupId: this.additionalGroup?.Id,
					GroupCode: this.additionalGroup?.GroupCode,
					GroupName: this.additionalGroup?.GroupName,
					Relationship: "",
					RelationshipObj: "",
					City: "",
					State: "",
					Zip: "",
					Phone: "",
					SignificantOther: "",
					SignificantOtherLast: "",
					BranchOfService: "",
					ReferralAdded: false,
					StateObj: "",
				});
			}
			this.PresentationWillKitVeterans.ReferralsFamily = JSON.stringify(
				this.ReferralsFamily
			);
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
			this.ReferralsFamilyCount = 3;
		}

		if (this.PresentationWillKitVeterans.ReferralsAdditional) {
			this.ReferralsAdditional = JSON.parse(
				this.PresentationWillKitVeterans.ReferralsAdditional
			);
		} else {
			for (
				this.ReferralsAdditionalCount = 1;
				this.ReferralsAdditionalCount <= 3;
				this.ReferralsAdditionalCount++
			) {
				this.ReferralsAdditional.push({
					Id: this.ReferralsAdditionalCount,
					FirstName: "",
					LastName: "",
					GroupId: this.additionalGroup?.Id,
					GroupCode: this.additionalGroup?.GroupCode,
					GroupName: this.additionalGroup?.GroupName,
					City: "",
					State: "",
					Relationship: "",
					RelationshipObj: "",
					Zip: "",
					Phone: "",
					SignificantOther: "",
					SignificantOtherLast: "",
					BranchOfService: "",
					ReferralAdded: false,
					StateObj: "",
				});
			}
			this.PresentationWillKitVeterans.ReferralsAdditional = JSON.stringify(
				this.ReferralsAdditional
			);
			this.ReferralsAdditionalCount = 3;
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
		}

		if (this.PresentationWillKitVeterans.ReferralsService) {
			this.ReferralsService = JSON.parse(
				this.PresentationWillKitVeterans.ReferralsService
			);
		} else {
			for (
				this.ReferralsServiceCount = 1;
				this.ReferralsServiceCount <= 2;
				this.ReferralsServiceCount++
			) {
				this.ReferralsService.push({
					Id: this.ReferralsServiceCount,
					FirstName: "",
					LastName: "",
					GroupId: this.serviceGroup?.Id,
					GroupCode: this.serviceGroup?.GroupCode,
					GroupName: this.serviceGroup?.GroupName,
					City: "",
					State: "",
					Zip: "",
					Phone: "",
					SignificantOther: "",
					SignificantOtherLast: "",
					BranchOfService: "",
					Relationship: "",
					RelationshipObj: "",
					ReferralAdded: false,
					Branch: "",
					StateObj: "",
				});
			}
			this.PresentationWillKitVeterans.ReferralsService = JSON.stringify(
				this.ReferralsService
			);
			this.ReferralsServiceCount = 2;
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
		}

		this.Person = PrimaryPerson.query().last();
		this.Person.Spouse = this.Spouse;
		if (
			this.Person.HasChildrenUnder18 &&
			this.Person.ChildrenString != undefined &&
			this.Person.ChildrenString != ""
		) {
			let depchildren = JSON.parse(this.Person.ChildrenString);
			let adultchildren: any = [];
			if (
				this.Person.AdultChildrenString != undefined &&
				this.Person.AdultChildrenString != ""
			) {
				adultchildren = JSON.parse(this.Person.AdultChildrenString);
			}

			let willkitchilds: any = [];
			if (depchildren.length > 0) {
				if (depchildren.length > 6) {
					willkitchilds.push(...depchildren.slice(0, 6 - depchildren.length));
				} else {
					willkitchilds = depchildren;
				}

				if (willkitchilds.length < 6) {
					if (adultchildren.length > 0) {
						willkitchilds.push(
							...adultchildren.slice(0, 6 - willkitchilds.length)
						);
					}
				}
			} else if (adultchildren.length > 0) {
				if (adultchildren.length > 6) {
					willkitchilds.push(
						...adultchildren.slice(0, 6 - adultchildren.length)
					);
				} else {
					willkitchilds = adultchildren;
				}
			}

			if (willkitchilds.length > 0) {
				willkitchilds.forEach((element: any) => {
					element.GroupId = this.additionalGroup?.Id;
					element.GroupCode = this.additionalGroup?.GroupCode;
					element.GroupName = this.additionalGroup?.GroupName;
				});

				for (
					this.WillKitChildrenCount = willkitchilds.length + 1;
					this.WillKitChildrenCount <= 6;
					this.WillKitChildrenCount++
				) {
					let willkitchild = new WillKitChildren();
					willkitchild.GroupId = this.additionalGroup?.Id;
					willkitchild.GroupCode = this.additionalGroup?.GroupCode;
					willkitchild.GroupName = this.additionalGroup?.GroupName;
					willkitchilds.push(willkitchild);
				}

				willkitchilds.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);

				this.PresentationWillKitVeterans.WillKitChildren =
					JSON.stringify(willkitchilds);
				this.WillKitChildren = JSON.parse(
					this.PresentationWillKitVeterans.WillKitChildren
				);

				this.WillKitChildrenCount = 6;
				this.InitialVeteranObj = JSON.stringify(
					this.PresentationWillKitVeterans
				);
			} else {
				for (
					this.WillKitChildrenCount = 1;
					this.WillKitChildrenCount <= 6;
					this.WillKitChildrenCount++
				) {
					let willkitchild = new WillKitChildren();
					willkitchild.GroupId = this.additionalGroup?.Id;
					willkitchild.GroupCode = this.additionalGroup?.GroupCode;
					willkitchild.GroupName = this.additionalGroup?.GroupName;
					this.WillKitChildren.push(willkitchild);
				}
				this.WillKitChildrenCount = 6;
				this.InitialVeteranObj = JSON.stringify(
					this.PresentationWillKitVeterans
				);
			}
		} else if (
			this.Person.AdultChildrenString != undefined &&
			this.Person.AdultChildrenString != ""
		) {
			let adultchildren: any = [];
			if (
				this.Person.AdultChildrenString != undefined &&
				this.Person.AdultChildrenString != ""
			) {
				adultchildren = JSON.parse(this.Person.AdultChildrenString);
			}

			let willkitchilds: any = [];
			if (adultchildren.length > 0) {
				if (adultchildren.length > 6) {
					willkitchilds.push(
						...adultchildren.slice(0, 6 - adultchildren.length)
					);
				} else {
					willkitchilds = adultchildren;
				}
			}

			if (willkitchilds.length > 0) {
				willkitchilds.forEach((element: any) => {
					element.GroupId = this.additionalGroup?.Id;
					element.GroupCode = this.additionalGroup?.GroupCode;
					element.GroupName = this.additionalGroup?.GroupName;
				});

				for (
					this.WillKitChildrenCount = willkitchilds.length + 1;
					this.WillKitChildrenCount <= 6;
					this.WillKitChildrenCount++
				) {
					let willkitchild = new WillKitChildren();
					willkitchild.GroupId = this.additionalGroup?.Id;
					willkitchild.GroupCode = this.additionalGroup?.GroupCode;
					willkitchild.GroupName = this.additionalGroup?.GroupName;
					willkitchilds.push(willkitchild);
				}

				willkitchilds.sort((a: any, b: any) => b.AgePerDOB - a.AgePerDOB);

				this.PresentationWillKitVeterans.WillKitChildren =
					JSON.stringify(willkitchilds);
				this.WillKitChildren = JSON.parse(
					this.PresentationWillKitVeterans.WillKitChildren
				);

				this.WillKitChildrenCount = 6;
				this.InitialVeteranObj = JSON.stringify(
					this.PresentationWillKitVeterans
				);
			} else {
				for (
					this.WillKitChildrenCount = 1;
					this.WillKitChildrenCount <= 6;
					this.WillKitChildrenCount++
				) {
					let willkitchild = new WillKitChildren();
					willkitchild.GroupId = this.additionalGroup?.Id;
					willkitchild.GroupCode = this.additionalGroup?.GroupCode;
					willkitchild.GroupName = this.additionalGroup?.GroupName;
					this.WillKitChildren.push(willkitchild);
				}
				this.WillKitChildrenCount = 6;
				this.InitialVeteranObj = JSON.stringify(
					this.PresentationWillKitVeterans
				);
			}
		} else {
			let willkitchilds = [];
			for (
				this.WillKitChildrenCount = 1;
				this.WillKitChildrenCount <= 6;
				this.WillKitChildrenCount++
			) {
				let willkitchild = new WillKitChildren();
				willkitchild.GroupId = this.additionalGroup?.Id;
				willkitchild.GroupCode = this.additionalGroup?.GroupCode;
				willkitchild.GroupName = this.additionalGroup?.GroupName;
				this.WillKitChildren.push(willkitchild);
			}
			this.WillKitChildrenCount = 6;
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
		}
	}

	addFamily(evt: any) {
		var res = this.ReferralsFamily[this.ReferralsFamily.length - 1];
		this.ReferralsFamilyCount = res.Id + 1;

		evt.stopPropagation();
		this.ReferralsFamily.push({
			Id: this.ReferralsFamilyCount,
			FirstName: "",
			LastName: "",
			GroupId: this.additionalGroup?.Id,
			GroupCode: this.additionalGroup?.GroupCode,
			GroupName: this.additionalGroup?.GroupName,
			City: "",
			State: "",
			Zip: "",
			Phone: "",
			SignificantOther: "",
			BranchOfService: "",
			Relationship: "",
			RelationshipObj: "",
			SignificantOtherLast: "",
			ReferralAdded: false,
			StateObj: "",
		});
	}

	addEmergency(evt: any) {
		var res = this.ReferralsAdditional[this.ReferralsAdditional.length - 1];
		this.ReferralsAdditionalCount = res.Id + 1;

		evt.stopPropagation();
		this.ReferralsAdditional.push({
			Id: this.ReferralsAdditionalCount,
			FirstName: "",
			LastName: "",
			GroupId: this.additionalGroup?.Id,
			GroupCode: this.additionalGroup?.GroupCode,
			GroupName: this.additionalGroup?.GroupName,
			City: "",
			State: "",
			Zip: "",
			Phone: "",
			SignificantOther: "",
			BranchOfService: "",
			SignificantOtherLast: "",
			Relationship: "",
			RelationshipObj: "",
			ReferralAdded: false,
			StateObj: "",
		});
	}

	addService(evt: any) {
		var res = this.ReferralsService[this.ReferralsService.length - 1];
		this.ReferralsServiceCount = res.Id + 1;

		evt.stopPropagation();
		this.ReferralsService.push({
			Id: this.ReferralsServiceCount,
			FirstName: "",
			LastName: "",
			GroupId: this.serviceGroup?.Id,
			GroupCode: this.serviceGroup?.GroupCode,
			GroupName: this.serviceGroup?.GroupName,
			City: "",
			State: "",
			Zip: "",
			Phone: "",
			SignificantOther: "",
			SignificantOtherLast: "",
			BranchOfService: "",
			RelationshipObj: "",
			Relationship: "",
			ReferralAdded: false,
			Branch: "",
			StateObj: "",
		});
	}

	async loadDropdown() {
		this.StateList = await getPersistedState("StateList");
		this.stateListPromise = this.StateList.filter((x: any) => x.CountryId != 3);
		if (
			this.Person.State != undefined &&
			this.Person.State != "" &&
			this.Person.State != 0
		) {
			this.PresentationWillKitVeterans.State = this.stateListPromise.find(
				(x: any) => x.StateId == this.Person.State
			);
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
			// if (this.PresentationWillKitVeterans.State != undefined && typeof(this.PresentationWillKitVeterans.State) != "string")
			//   this.PresentationWillKitVeterans.State = this.PresentationWillKitVeterans.State.StateCode;
		}
		if (this.Person.City != undefined && this.Person.City != "") {
			this.PresentationWillKitVeterans.City = this.Person.City;
			this.InitialVeteranObj = JSON.stringify(this.PresentationWillKitVeterans);
		}
	}

	ZoomContainerClick(pageIndex: number) {
		var currentPageZoomIndex = this.$data.PagesZoomIndex[pageIndex];
		if (currentPageZoomIndex >= 2) this.$data.PagesZoomIndex[pageIndex] = 0;
		else
			this.$data.PagesZoomIndex[pageIndex] =
				this.$data.PagesZoomIndex[pageIndex] + 1;

		this.$data.PagesZoomIndex = JSON.parse(
			JSON.stringify(this.$data.PagesZoomIndex)
		);
	}

	async PrimaryPersonUpdate(evt: any) {
		//setTimeout(() => {
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		this.$data.VitalStatSectionDisabled = true;
		this.$data.VitalStatSectionDisabled = false;

		//}, 50);
		this.FirstTimePopupShown = false;
	}

	private isPersonDOBValid = true;
	private isSpouseDOBValid = true;
	private isChildDOBValid: any = [];

	PrimaryPersonDateUpdate(evt: any) {
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear().toString();
		var yearString = "20[02][0-" + currentYear[currentYear.length - 1] + "]";
		var stringRegex =
			"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
			yearString +
			")\\b$";
		var regex = new RegExp(stringRegex);
		// const pattern =
		//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
		var test = regex.test(evt.target.value); // || "Invalid DOB.";

		this.isPersonDOBValid = test;

		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		this.FirstTimePopupShown = false;
	}

	PrimaryOrSpouseDOBChange() {
		this.PresentationWillKitVeterans.isPlanCalculationNeeded = true;
		this.PresentationWillKitVeteransUpdate();
	}

	SpouseUpdate(updateUI: boolean = true) {
		Spouse.update({
			where: this.Spouse.Id,
			data: this.Spouse,
		});
		this.Person.Spouse = this.Spouse;
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});

		if (updateUI) {
			this.$data.SpouseVitalStatSectionDisabled = true;
			this.$data.SpouseVitalStatSectionDisabled = false;
		}

		//this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SpouseDateDOBUpdate(evt: any) {
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear().toString();
		var yearString = "20[02][0-" + currentYear[currentYear.length - 1] + "]";
		var stringRegex =
			"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
			yearString +
			")\\b$";
		var regex = new RegExp(stringRegex);
		// const pattern =
		//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
		var test = regex.test(evt.target.value); // || "Invalid DOB.";

		this.isSpouseDOBValid = test;

		this.SpouseUpdate();
		this.FirstTimePopupShown = false;
	}
	PresentationWillKitVeteransUpdate() {
		this.$data.VeteranInfoSectionDisabledService = true;
		this.$data.VeteranInfoSectionDisabledService = false;

		this.SaveWillKitWithPresentation();
		// PresentationWillKitVeterans.update({
		//   where: this.PresentationWillKitVeterans.Id,
		//   data: this.PresentationWillKitVeterans,
		// });
		this.FirstTimePopupShown = false;
	}

	updateFuneralType(event: any) {
		var Burial = document.getElementById("Burial") as HTMLInputElement | null;
		var Cremation = document.getElementById(
			"Cremation"
		) as HTMLInputElement | null;
		var Mausoleum = document.getElementById(
			"Mausoleum"
		) as HTMLInputElement | null;
		if (event == "Burial") {
			if (Cremation != null) Cremation.checked = false;
			if (Mausoleum != null) Mausoleum.checked = false;
		} else if (event == "Cremation") {
			if (Burial != null) Burial.checked = false;
			if (Mausoleum != null) Mausoleum.checked = false;
		} else {
			//Mausoleum
			if (Burial != null) Burial.checked = false;
			if (Cremation != null) Cremation.checked = false;
		}

		this.PresentationWillKitVeterans.FuneralType = event;
		this.PresentationWillKitVeteransUpdate();
	}

	updateDischargePapers(event: any) {
		var Home = document.getElementById("Home") as HTMLInputElement | null;
		var Other = document.getElementById("Other") as HTMLInputElement | null;

		if (event == "Home") {
			if (Other != null) Other.checked = false;
		} else {
			//Private
			if (Home != null) Home.checked = false;
		}

		this.PresentationWillKitVeterans.DischargePapers = event;
		this.PresentationWillKitVeteransUpdate();
	}
	updateInCaseOfDeath(event: any) {
		this.PresentationWillKitVeteransUpdate();
	}
	updateVeteransType(event: any) {
		var National = document.getElementById(
			"National"
		) as HTMLInputElement | null;
		var State = document.getElementById("State") as HTMLInputElement | null;
		var Private = document.getElementById("Private") as HTMLInputElement | null;
		if (event == "National") {
			if (State != null) State.checked = false;
			if (Private != null) Private.checked = false;
		} else if (event == "State") {
			if (Private != null) Private.checked = false;
			if (National != null) National.checked = false;
		} else {
			//Private
			if (State != null) State.checked = false;
			if (National != null) National.checked = false;
		}

		State;
		this.PresentationWillKitVeterans.VeteransType = event;
		this.PresentationWillKitVeteransUpdate();
	}

	next() {
		let sliderContainer =
			document.getElementsByClassName("PdfHtmlContainer")[0].children;

		gsap.from(".PdfHtmlPage", {
			opacity: 0,
			x: 500,
			duration: 0.4,
		});

		for (let item = 0; item < sliderContainer.length; item++) {
			let active = document.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item++;
				sliderContainer[item].classList.add("active");
				let pageItem = item;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				this.preDisable = false;
				if (item == sliderContainer.length - 1) {
					this.nextDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 400);
	}

	prev() {
		let sliderContainer =
			document.getElementsByClassName("PdfHtmlContainer")[0].children;
		let getActive = document.getElementsByClassName("PdfHtmlContainer")[0];

		gsap.from(".PdfHtmlPage", {
			opacity: 0,
			x: -500,
		});
		for (let item = sliderContainer.length - 1; item >= 0; item--) {
			let active = getActive.getElementsByClassName("active")[0];
			if (sliderContainer[item] == active) {
				sliderContainer[item].classList.remove("active");
				item--;

				this.$data.CurrentPageNumbers = this.PageNumbers[item];
				sliderContainer[item].classList.add("active");
				this.nextDisable = false;
				if (item == 0) {
					this.preDisable = true;
				}
			}
		}

		this.disabledNavButton = true;
		setTimeout(() => {
			this.disabledNavButton = false;
		}, 400);
	}

	ContainerDrag(evt: any) {
		const ele: any = document.getElementById("container");

		const dx = evt.clientX - ele.scrollTop;
		const dy = evt.clientY - ele.scrollLeft;

		// Scroll the element
		ele.scrollTop = ele.clientTop - dy;
		ele.scrollLeft = ele.clientLeft - dx;

		evt.preventDefault();
	}

	PreventClickCmd(evt: any) {
		evt.stopPropagation();
		//evt.preventDefault();
	}

	startdrag() {
		const ele: any = document.getElementById("container");
		ele.style.cursor = "grab";

		let pos = { top: 0, left: 0, x: 0, y: 0 };

		const mouseDownHandler = function (e: any) {
			ele.style.cursor = "grabbing";
			ele.style.userSelect = "none";

			pos = {
				left: ele.scrollLeft,
				top: ele.scrollTop,
				// Get the current mouse position
				x: e.clientX,
				y: e.clientY,
			};

			document.addEventListener("mousemove", mouseMoveHandler);
			document.addEventListener("mouseup", mouseUpHandler);
		};

		const mouseMoveHandler = function (e: any) {
			// How far the mouse has been moved
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			// Scroll the element
			e.scrollTop = pos.top - dy;
			e.scrollLeft = pos.left - dx;
		};

		const mouseUpHandler = function () {
			ele.style.cursor = "grab";
			ele.style.removeProperty("user-select");

			document.removeEventListener("mousemove", mouseMoveHandler);
			document.removeEventListener("mouseup", mouseUpHandler);
		};

		// Attach the handler
		ele.addEventListener("mousedown", mouseDownHandler);
	}

	funeralInstItemsFalse(evt: any) {
		this.funeralInstItems = false;
		evt.stopPropagation();
	}

	funeralInstItemsTrue(evt: any) {
		this.$store.state.messageText = this.$t(
			"SponsorshipProgram.willKitDataSaved"
		);
		this.$store.state.showMessage = true;

		this.funeralInstItems = true;
		evt.stopPropagation();
		this.SaveWillKitWithPresentation();
	}

	EnabledVitalSectionClick(evt: any) {
		this.$data.VitalStatSectionDisabled = false;
		evt.stopPropagation();
	}

	SaveVitalSectionClick(evt: any) {
		this.validateEmail();
		if (this.InvalidEmail == true) {
			this.$store.state.messageText = this.$t(
				"FinishPresentation.EmailInvalid"
			);
			this.$store.state.showMessage = true;
			evt.stopPropagation();
			return;
		}

		this.$store.state.messageText = this.$t(
			"SponsorshipProgram.willKitDataSaved"
		);
		this.$store.state.showMessage = true;

		this.$data.VitalStatSectionDisabled = true;
		PrimaryPerson.update({
			where: this.Person.Id,
			data: this.Person,
		});
		evt.stopPropagation();
		this.SaveReferals();
		this.SaveWillKitWithPresentation();
	}

	EnabledSpouseVitalSectionClick(evt: any) {
		this.$data.SpouseVitalStatSectionDisabled = false;
		evt.stopPropagation();
	}

	SaveSpouseVitalSectionClick(evt: any) {
		this.$store.state.messageText = this.$t(
			"SponsorshipProgram.willKitDataSaved"
		);
		this.$store.state.showMessage = true;

		this.$data.SpouseVitalStatSectionDisabled = true;
		evt.stopPropagation();
		this.SpouseUpdate(false);
		this.SaveWillKitWithPresentation();
		this.SaveReferals();
	}

	EnabledVeteranInfoSectionClick(evt: any) {
		this.$data.VeteranInfoSectionDisabledService = false;
		evt.stopPropagation();
	}

	SaveVeteranInfoSectionClick(evt: any) {
		this.$store.state.messageText = this.$t(
			"SponsorshipProgram.willKitDataSaved"
		);
		this.$store.state.showMessage = true;

		this.$data.VeteranInfoSectionDisabledService = true;

		evt.stopPropagation();
		this.SaveWillKitWithPresentation();

		this.SaveReferals();
	}

	EnabledPersonNotifySectionClick(evt: any) {
		this.$data.PersonNotifySectionDisabled = false;
		evt.stopPropagation();
	}

	SavePersonNotifySectionClick(evt: any) {
		this.$store.state.messageText = this.$t(
			"SponsorshipProgram.willKitDataSaved"
		);
		this.$store.state.showMessage = true;
		this.SaveReferals();
		this.$data.PersonNotifySectionDisabled = true;
		evt.stopPropagation();
		this.SaveWillKitWithPresentation();
	}

	SaveWillKitChildrenSectionClick(evt: any) {
		var count = 0;
		for (let i = 0; i < this.WillKitChildren.length; i++) {
			if (
				this.WillKitChildren[i].FirstName != "" ||
				this.WillKitChildren[i].LastName != "" ||
				(this.WillKitChildren[i].DateOfBirth != "" &&
					this.WillKitChildren[i].DateOfBirth != null)
			) {
				if (
					this.WillKitChildren[i].DateOfBirth != "" &&
					this.WillKitChildren[i].DateOfBirth != null
				) {
					var currentDate = new Date();
					var currentYear = currentDate.getFullYear().toString();
					var yearString =
						"20[02][0-" + currentYear[currentYear.length - 1] + "]";
					var stringRegex =
						"^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\\d{2}|20[01][0-9]|" +
						yearString +
						")\\b$";
					var regex = new RegExp(stringRegex);
					// const pattern =
					//   /^(?:0[1-9]|1[012])[/](?:0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|20[02][0-2])\b$/;
					var test = regex.test(this.WillKitChildren[i].DateOfBirth); // || "Invalid DOB.";
					this.isChildDOBValid[i] = test;
				}
				if (
					this.WillKitChildren[i].FirstName == "" ||
					this.WillKitChildren[i].LastName == "" ||
					this.WillKitChildren[i].DateOfBirth == "" ||
					this.WillKitChildren[i].DateOfBirth == null ||
					!this.isChildDOBValid[i]
				) {
					this.WillKitChildren[i].IsValid = false;
					count += 1;
				}
			} else {
				this.WillKitChildren[i].IsValid = true;
				this.isChildDOBValid[i] = true;
			}
		}
		if (count > 0) {
			this.$store.state.messageText = "Required Fields Missing";
			this.$store.state.showMessage = true;
		} else {
			this.$store.state.messageText = this.$t(
				"SponsorshipProgram.willKitDataSaved"
			);
			this.$store.state.showMessage = true;
			this.SaveReferals();
			this.$data.ChildrenSectionDisabled = true;
			evt.stopPropagation();
			this.SaveWillKitWithPresentation();
		}
	}

	SaveWillKitWithPresentation() {
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveWillKitWithPresentationFamilyBlur() {
		this.PresentationWillKitVeterans.ReferralsFamily = JSON.stringify(
			this.ReferralsFamily
		);
		this.$data.PersonNotifySectionDisabled = true;
		this.$data.PersonNotifySectionDisabled = false;
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveWillKitPresentationChildrenBlur() {
		this.PresentationWillKitVeterans.WillKitChildren = JSON.stringify(
			this.WillKitChildren
		);
		this.$data.ChildrenSectionDisabled = true;
		this.$data.ChildrenSectionDisabled = false;
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveWillKitWithPresentationAdditionalBlur() {
		this.PresentationWillKitVeterans.ReferralsAdditional = JSON.stringify(
			this.ReferralsAdditional
		);
		this.$data.PersonNotifySectionDisabled = true;
		this.$data.PersonNotifySectionDisabled = false;
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveWillKitWithPresentationServiceBlur() {
		this.PresentationWillKitVeterans.ReferralsService = JSON.stringify(
			this.ReferralsService
		);
		this.$data.PersonNotifySectionDisabled = true;
		this.$data.PersonNotifySectionDisabled = false;
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	SaveReferals() {
		this.PresentationWillKitVeterans.ReferralsFamily = JSON.stringify(
			this.ReferralsFamily
		);
		this.PresentationWillKitVeterans.ReferralsAdditional = JSON.stringify(
			this.ReferralsAdditional
		);
		this.PresentationWillKitVeterans.ReferralsService = JSON.stringify(
			this.ReferralsService
		);

		this.PresentationWillKitVeterans.WillKitChildren = JSON.stringify(
			this.WillKitChildren
		);

		// PresentationWillKitVeterans.update({
		//   where: this.PresentationWillKitVeterans.Id,
		//   data: this.PresentationWillKitVeterans,
		// });
		this.$emit("SaveWillKitInfo", this.PresentationWillKitVeterans);
		this.FirstTimePopupShown = false;
	}

	Statechange(event: any, itemRef: any) {
		if (event != undefined)
			itemRef.State = this.StateList.find((x: any) => x.StateCode == event);
		this.SaveReferals();
		this.SaveWillKitWithPresentation();
	}

	$refs!: {
		html2Pdf: any;
	};

	DownloadPDF() {
		this.$store.state.loader = true;
		this.SpouseUpdate();
		this.SaveReferals();
		//this.SaveWillKitWithPresentation
		setTimeout(() => {
			if (
				this.Person != undefined &&
				this.Person.FirstName != undefined &&
				this.Person.FirstName != ""
			)
				this.$data.pdfoptions.filename =
					"Last Will and Testament Kit - " + this.Person.FirstName;
			else this.$data.pdfoptions.filename = "Last Will and Testament Kit";
			this.$refs.html2Pdf.downloadPdf().then(() => {
				this.$store.state.loader = false;
			});
			this.FirstTimePopupShown = true;
		}, 500);
	}

	public isClosable(): boolean {
		if (
			((JSON.stringify(this.PresentationWillKitVeterans) !=
				this.InitialVeteranObj &&
				this.InitialVeteranObj != "") ||
				JSON.stringify(this.Person) != this.InitialPerson ||
				JSON.stringify(this.Spouse) != this.InitialSpouse ||
				(this.Presentation.LeadId != undefined &&
					this.Presentation.LeadId != "" &&
					this.Presentation.isWillKitFormDownloaded == false)) &&
			this.FirstTimePopupShown == false
		) {
			if (this.Presentation.LeadId != undefined) {
				this.Presentation = Presentation.query().withAllRecursive().last();
				this.Presentation.isWillKitFormDownloaded = true;
				this.FirstTimePopupShown = true;
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
			}
			return false;
		} else if (
			JSON.stringify(this.PresentationWillKitVeterans) ==
				this.InitialVeteranObj &&
			this.InitialVeteranObj != ""
		) {
			this.Presentation = Presentation.query().withAllRecursive().last();
			if (
				this.Presentation.PresentationWillKitVeterans != null &&
				this.Presentation.PresentationWillKitVeterans != undefined &&
				this.Presentation.PresentationWillKitVeterans != "" &&
				this.Presentation.PresentationWillKitVeterans !=
					JSON.stringify(this.PresentationWillKitVeterans)
			) {
				this.Presentation.PresentationWillKitVeterans = JSON.stringify(
					this.PresentationWillKitVeterans
				);
				Presentation.update({
					where: this.Presentation.Id,
					data: this.Presentation,
				});
			}
		}
		return true;
	}
}
