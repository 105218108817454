






































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Children from "@/entities/Children";
import moment from "moment";
import { EntityHelper } from "@/helpers/EntityHelper";
import FamilyCareSurveyChildren from "@/entities/FamilyCareSurveyChildren";

let listofGenderTypes = require("../../assets/pre-fill-data/gender-types.json");

@Component({})
export default class AddFamilyCareSurveyNotesComponent extends Vue {
	private isDialogOpen: boolean = false;

	@Prop({ default: "Input Property" })
	notes!: String;

	@Prop({ default: false })
	IsVeteranAssessment!: boolean;

	data() {
		return {};
	}

	created() {
		this.isDialogOpen = true;
	}

	isEscapePressed() {
		this.isDialogOpen = false;
		setTimeout(() => {
			this.$emit("cancelnotes", this.notes);
		}, 500);
	}

	save() {
		this.$emit("savenotes", this.notes);
	}
	cancel() {
		this.$emit("cancelnotes", this.notes);
	}
}
