






















































































































































































































































































































































































































































































































































































































































































































import { getPersistedState } from "@/helpers/StateMapper";
import { Component, Prop, Vue } from "vue-property-decorator";
import Router from "vue-router";
import { namespace } from "vuex-class";
import LoggingConstants from "../constants/LoggingConstants";
import LoggingHelper from "../helpers/LoggingHelper";
const Auth = namespace("Auth");

@Component
export default class MenuComponent extends Vue {
	@Auth.Action
	private signOut!: () => void;

	private NeedAnalysisDisabled: boolean = true;
	private NeedAnalysisActive: boolean = false;
	private BenefitsSummaryActive: boolean = false;
	private presentationsActive: boolean = false;
	private FinsihPresentationActive: boolean = false;
	private PlanGeneratorActive: boolean = false;
	private PGIconActive: boolean = false;
	private PlanGeneratorDisabled: boolean = true;
	private BenefitsSummaryDisabled: boolean = true;
	private FinsihPresentationDisabled: boolean = true;
	private PGIconDisabled: boolean = true;
	private confirmationDialogHome: boolean = false;
	private confirmationDialogFinish: boolean = false;
	private ReportFormActive: boolean = false;
	private MyDashboardVisibility: boolean = false;
	private NeedAnalysisVisibilty: boolean = false;
	private GamePlanVisibilty: boolean = false;
	private PresHistoryVisibilty: boolean = false;
	private ProfileVisibilty: boolean = false;
	private PlanGeneratorVisibilty: boolean = false;
	private BenefitsSummaryVisibilty: boolean = false;
	private FinishPresVisibilty: boolean = false;
	private ShowHomeVisibility: boolean = false;
	private BeneficiaryVisibilty: boolean = false;
	private RateBookVisibilty: boolean = false;
	private MaterialsVisibilty: boolean = false;
	private ReportCardVisibilty: boolean = false;
	private UWManualVisibilty: boolean = false;
	private MedicalQuestionVisibilty: boolean = false;
	private PrePlanVisibility: boolean = false;
	private NoCostVisibilty: boolean = false;
	private SPIconVisiblity: boolean = false;
	private PGIconVisiblity: boolean = false;
	private showTraining: boolean = false;
	private showAchievements: boolean = false;
	private EappSummaryVisiblity: boolean = false;
	private CementingVideoVisibilty: boolean = false;
	private ProtectionVisibility: boolean = false;

	@Prop({ default: false })
	private personexist: any = false;

	// private ssProgram: boolean = false;

	// data() {
	// 	return {
	// 		ssProgram: false,
	// 	};
	// }

	logOut() {
		this.signOut();
		this.$router.push("/");
	}

	get showLogout() {
		if (this.$route != undefined && this.$route.meta != undefined)
			return this.$route.meta.showLogout;
		else return false;
	}

	RouteTo(
		route: string,
		name: string = "",
		props: any = null,
		logName: string = ""
	) {
		if (logName != "")
			LoggingHelper.getInstance().LogEvent(
				LoggingConstants.NavigateTo + logName
			);

		if (
			route == "/StartPresentation" &&
			(this.$route.name == "StartPresentation" ||
				this.$route.name == "Dashboard" ||
				this.$route.name == "Training Page" ||
				this.$route.name == "GamePlanPage")
		) {
			this.$router.push(route);
			//this.ConfirmHome();
			return;
		}

		var showAllMaterials = Boolean(this.$route.params.showallMaterials);

		if (this.$route.name == "Report Form") {
		} else if (
			route == "/StartPresentation" &&
			this.$route.name != "dashboard" &&
			this.$route.name != "Profile" &&
			!showAllMaterials
		) {
			this.confirmationDialogHome = true;
			return;
		}
		var ispresentplan = Boolean(this.$route.params.presentplan);
		if (ispresentplan && route == "/benefitssummary") {
			this.Action("benefitssummary", "Benefits summary opened");
			return;
		}

		var loadplanfromdb = false;
		if (this.$route.path == "/benefitssummary" && route == "/plangenerator") {
			loadplanfromdb = true;
		}
		var isPrePlan = Boolean(this.$route.params.initiatePrePlan);
		if (isPrePlan && route != "/ssprogram" && route != "/dashboard") {
			props = {
				initiatePrePlan: true,
				ExcludeFromPresentationCycle: true,
				loadplanfromdb: loadplanfromdb,
			};
			this.$emit("SavePrePlan", name);

			if (name == "PlanGenerator") {
				if (loadplanfromdb) {
					getPersistedState("PrePlan").then((result: any) => {
						if (result != undefined) {
							result = JSON.parse(result);
							props.preplan = result;
							this.$router.push({ name: name, params: props });
						}
					});
				} else {
					this.$router.push({ name: name, params: props });
				}
			}
			return;
		}
		if (!props) {
			this.$emit("RoutedToEvt", route);
			return;
		}

		if (this.$route.path == "/plangenerator") {
			var obj = {
				name: name,
				params: props,
			};
			this.$emit("RouteWithParams", obj);
			return;
		}

		this.$router.push({ name: name, params: props });
	}

	// ssprogramDialog() {
	// 	this.$emit("ssProgram", true);
	// }

	Action(actionName: string, logName: string = "") {
		if (actionName == "logOut" && this.$route.path != "/profile") {
			this.logOut();
			LoggingHelper.getInstance().LogEvent(LoggingConstants.Logout);
		}
		if (logName != undefined && logName != "") {
			LoggingHelper.getInstance().LogEvent(logName);
		}
		this.$emit("Action", actionName);
	}

	ConfirmHome() {
		this.confirmationDialogHome = false;
		this.$emit("RoutedToEvt", "StartPresentation");
		//this.$router.push("/StartPresentation");
	}

	mounted() {
		this.loadicons();
	}

	loadicons(checkpresentplan: boolean = true) {
		if (this.$route.name == "NeedAnalysis") {
			this.ShowHomeVisibility = true;
			this.NoCostVisibilty = true;
			this.PlanGeneratorVisibilty = true;
			this.PlanGeneratorDisabled = false;
			this.UWManualVisibilty = true;
			this.ReportCardVisibilty = true;
			this.FinishPresVisibilty = true;
			this.FinsihPresentationDisabled = false;
			this.SPIconVisiblity = true;
		}

		if (this.$route.name == "PlanGenerator") {
			var isPrePlan = Boolean(this.$route.params.initiatePrePlan);

			this.NeedAnalysisDisabled = false;
			this.PlanGeneratorActive = false;
			this.PlanGeneratorDisabled = false;
			this.BenefitsSummaryDisabled = false;
			this.FinsihPresentationDisabled = false;
			this.MyDashboardVisibility = false;
			this.GamePlanVisibilty = false;
			this.PresHistoryVisibilty = false;
			this.ProfileVisibilty = false;
			this.PlanGeneratorVisibilty = false;
			this.PGIconDisabled = false;
			this.MaterialsVisibilty = false;
			this.UWManualVisibilty = false;
			this.MedicalQuestionVisibilty = false;
			this.PrePlanVisibility = false;
			this.ShowHomeVisibility = true;
			this.NeedAnalysisVisibilty = !isPrePlan;
			this.PGIconVisiblity = !isPrePlan;
			this.BenefitsSummaryVisibilty = true;
			this.RateBookVisibilty = true;
			this.ReportCardVisibilty = !isPrePlan;
			this.FinishPresVisibilty = !isPrePlan;
			if (!isPrePlan) this.SPIconVisiblity = true;
		}

		if (this.$route.name == "BenefitsSummary") {
			var isPrePlan = Boolean(this.$route.params.initiatePrePlan);
			var presentplan =
				checkpresentplan && Boolean(this.$route.params.presentplan);

			this.BeneficiaryVisibilty = false;
			this.NeedAnalysisDisabled = false;
			this.BenefitsSummaryActive = true;
			this.PlanGeneratorDisabled = false;
			this.BenefitsSummaryDisabled = false;
			this.FinsihPresentationDisabled = false;
			this.MyDashboardVisibility = false;
			this.NeedAnalysisVisibilty = false;
			this.GamePlanVisibilty = false;
			this.PresHistoryVisibilty = false;
			this.ProfileVisibilty = false;
			this.BenefitsSummaryDisabled = false;
			this.RateBookVisibilty = false;
			this.MaterialsVisibilty = false;
			this.PrePlanVisibility = false;
			this.BenefitsSummaryVisibilty = false;
			this.PlanGeneratorDisabled = false;
			if (presentplan) {
				this.PlanGeneratorVisibilty = true;
				this.BenefitsSummaryVisibilty = true;
				this.BeneficiaryVisibilty = true;
			} else {
				this.PlanGeneratorVisibilty = true;
				this.CementingVideoVisibilty = !isPrePlan;
				this.ShowHomeVisibility = isPrePlan;
				this.EappSummaryVisiblity = !isPrePlan;
				this.ReportCardVisibilty = !isPrePlan;
				this.UWManualVisibilty = !isPrePlan;
				this.MedicalQuestionVisibilty = !isPrePlan;
				this.FinishPresVisibilty = !isPrePlan;
				if (!isPrePlan) this.SPIconVisiblity = true;
			}
			this.ProtectionVisibility = true;
		}

		if (this.$route.name == "FinishPresentation") {
			this.NeedAnalysisDisabled = false;
			this.PlanGeneratorDisabled = false;
			this.FinsihPresentationActive = false;
			this.BenefitsSummaryDisabled = false;
			this.FinsihPresentationDisabled = false;
			this.FinishPresVisibilty = false;
			this.MyDashboardVisibility = false;
			this.NeedAnalysisVisibilty = true;
			this.GamePlanVisibilty = false;
			this.PresHistoryVisibilty = false;
			this.ProfileVisibilty = false;
			this.PlanGeneratorVisibilty = true;
			this.BenefitsSummaryVisibilty = true;
			this.FinishPresVisibilty = false;
			this.ShowHomeVisibility = true;
			this.RateBookVisibilty = false;
			this.MaterialsVisibilty = false;
			this.ReportCardVisibilty = true;
			this.UWManualVisibilty = false;
			this.MedicalQuestionVisibilty = false;
			this.PrePlanVisibility = false;
			this.NoCostVisibilty = true;
			this.SPIconVisiblity = true;
		}

		if (this.$route.name == "Report Form") {
			this.ShowHomeVisibility = true;
			this.PlanGeneratorVisibilty = true;
			this.PlanGeneratorDisabled = false;
			this.BenefitsSummaryVisibilty = true;
			this.BenefitsSummaryDisabled = false;
			this.FinsihPresentationDisabled = false;
			this.NeedAnalysisVisibilty = true;
			this.NoCostVisibilty = true;
			this.NeedAnalysisDisabled = false;
			this.SPIconVisiblity = true;
		}

		if (this.$route.name == "Profile") {
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.ProfileVisibilty = true;
			this.ShowHomeVisibility = true;
		}

		if (this.$route.name == "Dashboard" || this.$route.name == "GamePlanPage") {
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.ProfileVisibilty = true;
			this.ShowHomeVisibility = true;
			this.showTraining = true;
			this.showAchievements = true;
		}

		if (this.$route.name == "StartPresentation") {
			this.MyDashboardVisibility = true;
			this.RateBookVisibilty = true;
			this.MaterialsVisibilty = true;
			this.UWManualVisibilty = true;
			this.MedicalQuestionVisibilty = true;
			this.PrePlanVisibility = true;
			this.SPIconVisiblity = true;
		}
		if (this.$route.name == "No Cost Benefits") {
			var showAll = Boolean(this.$route.params.showallMaterials);
			this.ShowHomeVisibility = true;
			this.MaterialsVisibilty = true;
			this.NeedAnalysisDisabled = false;
			this.NeedAnalysisVisibilty = !showAll;
			this.ReportCardVisibilty = !showAll;
			this.FinishPresVisibilty = !showAll;
			this.SPIconVisiblity = !showAll;
			this.FinsihPresentationDisabled = false;
		}

		if (this.$route.name == "Training Page") {
			this.MyDashboardVisibility = true;
			this.GamePlanVisibilty = true;
			this.PresHistoryVisibilty = true;
			this.ProfileVisibilty = true;
			this.ShowHomeVisibility = true;
		}
	}
}
