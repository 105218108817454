import Vue from 'vue';
import './css/site.scss';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import vuescroll from 'vuescroll';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import i18n from './plugins/i18n';
import VueCrontab from 'vue-crontab';
import vueDebounce from 'vue-debounce';

Vue.use(VueCrontab);
Vue.use(CKEditor);
Vue.use(vueDebounce);
Vue.use(VueTheMask);
Vue.config.productionTip = false;
Vue.use(vuescroll, {
	ops: {
		bar: {
			onlyShowBarOnScroll: false as boolean,
			keepShow: true as boolean,
			opacity: 0.7,
			background: '#c1c1c1'
		},
		vuescroll: {
			wheelScrollDuration: 300,
			wheelDirectionReverse: false
		}
	}
});

// Or if you want to pass in the lock option
Vue.use(vueDebounce, {
	lock: true
});

// Setting a different event to listen to
Vue.use(vueDebounce, {
	listenTo: 'v-text-field'
});

// Setting a default timer This is set to '300ms' if not specified
Vue.use(vueDebounce, {
	defaultTime: '500ms'
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
