import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const I18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: require("../locales/en.json"),
    fr: require("../locales/fr.json"),
    es: require("../locales/es.json")
  }
});

export function changeLocale(flag) {
  if (I18n != undefined) {
    I18n.locale = flag;
  }
}

export function GetParticularLanguageString(tag) {
  if (I18n != undefined) {
    return I18n.t(tag);
  }
}

export function SetUserLanguage() {
  var currentUserLanguage = localStorage.getItem("currentLanguageFlag");
  if (currentUserLanguage != undefined && currentUserLanguage != "") changeLocale(currentUserLanguage);
  else changeLocale('en');
}
export function ChecktoAddLangTruncateClass() {
  if (I18n != undefined && (I18n.locale == "es" || I18n.locale == "fr")) {
    return true;
  }
  return false;
}

export default I18n;
